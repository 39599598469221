import React, { useState } from 'react';
import { UserContext } from "./UserContext";

export const UserProvider = ({ children }) =>{

    const [login, setLogin] = useState(false);
    const [username, setUsername] = useState();
    const [autorizacion, setAutorizacion] = useState([]);


    return(
        <UserContext.Provider value={{login, setLogin, username, setUsername, autorizacion, setAutorizacion}}>
            {children}
        </UserContext.Provider>
    )
}