import React from "react";
import '../../../../styles/stylesheet.css'
import { FormContext } from './form_context';
import { FormContext2 } from './form_context2';

export default function ActualizarDD3({ history }) {
    const [value, setValue] = React.useContext(FormContext);
    const [value2, setValue2] = React.useContext(FormContext2);
    
    const guardarDatos = (e) => {
        const { name, value } = e.target;
        setValue2((prev) => {
            const datos = { ...prev.datos, [name]: value };
            return { ...prev, datos };
        });
    }

 

    return (
        <>
            <div className="card-body pt-3  ">
                <h5 className="text-royal-blue font-acumin-variable " >III. Procedencia de los recursos</h5>
                <div className="row mb-3 ">

                    <div className="col-md-12 form-floating">
                        <div className="form-floating mb-2">
                            {value.datos.establecerModificacion ?
                                <input placeholder="" type="text" className="form-control fs-5" value={value2.datos.origenRecursos} onChange={(e) => guardarDatos(e)} id="origenRecursos" name="origenRecursos" required />
                                :
                                <input  type="text" className="form-control fs-5" value={value.datos.origenRecursos} id="origenRecursos" name="origenRecursos" disabled />
                            }
                            <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Procedencia de los recursos *</label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

