//React
import React from "react";

//Componentes generales
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../ComponentesGenerales/ManejadorErrores';
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";

//APIs
import { traerSeries } from "../../../api/series/traerSeries";

//Hooks
import useValidateAuth from '../../../hooks/useSeriesColumns';


export default function SolicitudSeries({ history }) {
    const [copia, setCopia] = React.useState();
    const [data, setData] = React.useState([])
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()

    React.useEffect(() => {
        searchSeries();
    }, [])


    const searchSeries = async () => {
        const series = await traerSeries({ estatus: 1 });
        if (series) {
            setSpin(false)
            if ((isNaN(series.error)) === false) {
                setErroreshttp(series)
            } else {
                setData(series);
                setCopia(series)
            }
        }
    }



    //Catch information form the selected row
    const viewSerie = (row) => {
       //console.log(row.original.cehvalEmisionFormaRepresentacion)
        const serie = {

            cehvalSerieNombreSerie: row.original.cehvalSerieNombreSerie,
            cehvalSerieCodigo: row.original.cehvalSerieCodigo,
            cehvalSerieEstatus: row.original.cehvalSerieEstatus,
            cehvalSerieNombreEmi: row.original.cehvalSerieNombreEmi,
            cehvalEmisionCodigo: row.original.cehvalEmisionCodigo.cehvalEmisionCodigo,
            cehvalSerieCodigoIsin: row.original.cehvalSerieCodigoIsin,
            cehvalSerieFechaEmi: row.original.cehvalSerieFechaEmiCadena,
            cehvalSerieFechaVen: row.original.cehvalSerieFechaVenCadena,
            cehvalSerieTasIntNom: row.original.cehvalSerieTasIntNom,
            cehvalSerieMonto: row.original.cehvalSerieMontoCadena,
            cehvalSerieCantInstEmi: row.original.cehvalSerieCantInstEmi,
            cehvalSerieSpread: row.original.cehvalSerieSpread,
            cehvalSerieValorNomTitulo: row.original.cehvalSerieValorNomTituloCadena,
            catFormaColocacionCatForColId: row.original.catFormaColocacionCatForColId.catForColColocacion,
            cehvalSerieCatMonedaIdSerie: row.original.cehvalSerieCatMonedaIdSerie.nombre,
            
            periodicidad: row.original.cehvalSeriePeriodicidadId.catNumPerPeriodicidadNum,
            cehvalSerieCatMonedaIdLiq: row.original.cehvalSerieCatMonedaIdLiq.nombre,
            tipoTasaVariable: row.original.catTipoTasaVariableCatTipoTasaVariableId.catTipoTasaVariableTasaRefencia,
            tasaVariable:row.original.cehvalSerieCatTipTasId.catTipTasTasa, 
            PeriodoRevisionTasaVariable: row.original.catNumeroPeriocidadCatNumPerId.catNumPerPeriodicidad,
            cehvalSerieCantInstDisponibles:row.original.cehvalSerieCantInstDis,
            titulo: "Autorizar serie",
            ruta: "/solicitudSeries",
            id: row.original.cehvalSerieId,
            formaRepresentacion:row.original.cehvalEmisionFormaRepresentacion


         
        }

        //Mandar el objeto de la emision que el usuario desea ver
        history.push({
            pathname: '/verInfoSerie', state: { objSerie: serie }
        })
    }

    return (
        <>
            <div className="">
               {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }     
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Series por autorizar"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaUsuarios datos={data} displayInformation={viewSerie} useValidate={useValidateAuth} textoBoton={"Abrir"} textTitle="Click para ver la información de la serie"></TablaUsuarios>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    );
}





