import { instance } from '../apiGlobal';

export async function registrarSolicitudDepValFis (obj) {
      try {
        const response = await instance(`/depositantevf/registro`, {
          method:"post",
          data:obj
        })
        return response;
      } catch (error) {
        return error;
      }
}