//Formats number when user enters a value
const FormatNumberHyphen = (givenNumber) => {

    // Remove all characters other than digits
    let number = givenNumber.replace(/\D/g, '')
        .replace(/^(\d{3})(\d)/, "$1-$2") // Add Hyphen after first 3 digis
        .replace(/^(\d{3})-(\d{3})(\d)/, "$1-$2-$3") // Add hyphen after next 3 digits
        .replace(/^(\d{3})-(\d{3})-(\d{2})(\d)/, "$1-$2-$3-$4") //Add hyphen after next 2 digits
    
    let cleanNumber = number.replace(/\D/g, '');

    let objNumbers = {
        formattedNumber: number,
        cleanNumber: cleanNumber,
    }


    return objNumbers;
}


export default FormatNumberHyphen;

