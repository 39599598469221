

import { instanciaBitacora } from '../apiGlobal';

export async function traerInfoBitacoraOperaciones() {
  try {
 
    //const response = await instanciaBitacora.get(`/bitacora/consultar`, {
      const response = await instanciaBitacora.get(`/bitacora/consultar/todo`, {
      headers: {
        'Content-Type': 'application/json',
     
        
      }
    })
   
    return response.data
    
  } catch (error) {   
    return error
  }
}




