import React from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { mandarCorreoReseteoContrasena } from '../../../api/usuarios/mandarCorreoReseteoContrasena'


export default function ResetearContraseñaUsuarios({ history }) {

  const [nombreUsuario,setNombreUsuario]=React.useState();
  const [correoUsuario,setCorreoUsuario]=React.useState();
  const [nuevaContraseña,setNuevaContraseña]=React.useState();
  const [erroreshttp, setErroreshttp] = React.useState();
  const [mensaje, setMensaje] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [reseteoCorrecto,setReseteoCorrecto]=React.useState()
  const [reseteoIncorrecto,setReseteoIncorrecto]=React.useState()

  //**Inicia variables necesarias de AWS */
  var AWS = require("aws-sdk");

  // Set the AWS Region.
  AWS.config.update({

    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey
  });
  // Initialize CogntioIdentityServiceProvider SDK.
  var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
  //**Termina variables necesarias de AWS */



  const onSubmitForm = async (e) => {
    setLoading(true)
    e.preventDefault()
    const params = {
      UserPoolId: process.env.REACT_APP_UserPoolId,
      Username: nombreUsuario,
      Permanent: false,
      Password: nuevaContraseña,
    };
    
    cognitoidentityserviceprovider.adminSetUserPassword(
      params,
      function (err, data) {
        if (err) {
          setLoading(false)
          if (err.message.indexOf("User does not exist.") >= 0) {
            setMensaje("El Usuario no existe")
          }else if (err.message.indexOf("Password does not conform to policy") >= 0 ||err.message.indexOf("1 validation error detected") >= 0) {
            setMensaje("La contreseña debe una longitud mínima de 8 carácteres, debe contener al menos una mayúscula, al menos una minúscula, al menos un número, al menos un carácter especial")
          }
          //console.log(err, err.stack); // an error occurred
         // console.log(err)
          setReseteoIncorrecto(true)
         
        } else {

       enviarCorreo()


      }
    }
    );
  }

  const enviarCorreo=async()=>{

    const quitarPermisos = await mandarCorreoReseteoContrasena({
      usuario:nombreUsuario,
      email:correoUsuario,
      password:nuevaContraseña
  })

    if (quitarPermisos) {
      setLoading(false)
        if ((isNaN(quitarPermisos.error)) === false) {
            // respuestaincorrecta        
            setErroreshttp(quitarPermisos)
        } else {
            //respuesta correcta
            //console.log("exito")
           // setLoading(false)


            //setPermisos(catalogoPermisos)

            setReseteoCorrecto(true)
            setMensaje(`Se ha reseteado correctamente la contraseña del usuario ${nombreUsuario}, la nueva contraseña se le ha enviado por correo`)


          /*   setMensaje(`La nueva contraseña del usuario ${nombreUsuario} es: ${nuevaContraseña}, por favor mandar correo al usuario con su nueva contraseña`) */
         
        }
  

    }
  }



  const cerrarModal = () => {
    setReseteoCorrecto(false)
    setNombreUsuario("");
    setNuevaContraseña("");
    setCorreoUsuario("");
  };

  return (
    <>
      {erroreshttp && (
        <ManejadorErrores
          response={erroreshttp}
          mostrarModal={erroreshttp}
          funcionCierre={() => {
            setErroreshttp(false);
          }}
        ></ManejadorErrores>
      )}

      {loading && <PantallaCargando />}

      {reseteoCorrecto && (
        <ComponenteModal
          mostrarModal={reseteoCorrecto}
          cerrarModal={cerrarModal}
          titulo={mensaje}
          textoBoton="Entendido"
        ></ComponenteModal>
      )}

      {reseteoIncorrecto && (
        <ComponenteModal
          mostrarModal={reseteoIncorrecto}
          cerrarModal={()=>setReseteoIncorrecto(false)}
          titulo={mensaje}
          textoBoton="Entendido"
        ></ComponenteModal>
      )}


 
      <div className="fluid-container">
        {/* style={{ backgroundImage: `url(${fondo})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="fluid-container">*/}

        <main className="main-min-height mb-5">
          <div className="row row-wrap g-0">
            <div className="col-12">
              <div className="">
                <BannerTitulo titulo="Resetear contraseñas"></BannerTitulo>
                <div className="row mt-3 text-royal-blue">
                  <div className="col-lg-12 col-md-12 ">
                    <div className="container  ">
                      <div className="row mb-3 justify-content-center">
                        <div className="col-12 col-sm-12 col-md-10 col-lg-8">
                          <div className=" card " style={{ color: "#242848" }}>
                            <div className="card-body font-acumin-variable">
                              {/*<h3 className="text-center">Registro</h3>*/}
                              <form onSubmit={(e) => onSubmitForm(e)}>
                                <h6 className="font-acumin-variable text-royal-blue">
                                  * Estos campos son obligatorios
                                </h6>


                                <div className="row mb-3">
                                  <div className="col-12 mb-2">
                                    <div className="form-floating">
                                      <input
                                        type="email"
                                        id="correoUsuario"
                                        className="form-control"
                                        value={correoUsuario}
                                        placeholder=""
                                        onChange={(event) =>
                                          setCorreoUsuario(
                                            event.target.value
                                          )
                                        }
                                        required
                                      ></input>
                                      <label htmlFor="correoUsuario">Correo de usuario *</label>
                                    </div>
                                  </div>
                              
                                </div>

                                <div className="row mb-3">
                                  <div className="col-12 mb-2">
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        id="passwordtoreset"
                                        className="form-control"
                                        value={nombreUsuario}
                                        placeholder=""
                                        onChange={(event) =>
                                          setNombreUsuario(
                                            event.target.value
                                          )
                                        }
                                        required
                                      ></input>
                                      <label htmlFor="passwordtoreset">Nombre de usuario *</label>
                                    </div>
                                  </div>
                              
                                </div>


                                <div className="row mb-3">
                                  <div className="col-12 col-sm-12 mb-2">
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        id="nuevaContraseña"
                                        className="form-control"
                                        placeholder=""
                                        value={nuevaContraseña}
                                        onChange={(event) =>
                                          setNuevaContraseña(event.target.value)
                                        }
                                        required
                                      ></input>
                                      <label htmlFor="nuevaContraseña">
                                        Nueva contraseña *
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div className="row justify-content-end mt-4">
                                  <div className="col-auto">
                                    <button
                                      className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable"
                                      type="submit"
                                    >
                                      Resetear
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
