import { instance } from '../../../apiGlobal';

export async function rechazarPagoParaConciliar (obj) {
      try {
        const response = await instance(`/tarificacion/cancelar/pago/avisocobro`, {
          method:"post",
          data:obj,
        })
        return response;
      } catch (error) {
        return error;
      }
}


