import React, { useState, useEffect } from "react";



//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { traerMonedas } from '../../../api/catalogos/traerMonedas';
import { registroConciliacionOperadorCehval } from "../../../api/MercadoSecundario/registroConciliacionOperadorCehval";


//Functions
import formatNumber from "../../../functions/FormatNumber";

export default function RegistrarDisponibilidadFondosMS({ history }) {

    


    const [erroreshttp, setErroreshttp] = useState();


    //Catalogo 
    const [monedas, setMonedas] = useState([]);

    //Inputs
    const [folio, setFolio] = useState();
    const [moneda, setMoneda] = useState('');
    const [fecha, setFecha] = useState('');
    const [monto, setMonto] = useState();
    const [montoFront, setMontoFront] = React.useState();

    //Errors and messages
    const [loading, setLoading] = useState();
    const [procesoExitoso, setProcesoExitoso] = useState();
    const [procesoFallido, setProcesoFallido] = useState();
    const [mensaje, setMensaje] = useState();
    const [sesionCaducada, setSesionCaducada] = useState();

    useEffect(() => {
        traerDatos();
    }, []);


    const traerDatos = async () => {
        try {

            //Catalogo moneda
            const catalogoMonedas = await traerMonedas();
            //console.log(catalogoMonedas)
            if (catalogoMonedas) {
                if ((isNaN(catalogoMonedas.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(catalogoMonedas);
                } else {
                    setMonedas(catalogoMonedas);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    
    const onSubmitForm = async (e) => {
        e.preventDefault();

        const objetoUsuario = {
            folioBchTr: folio,
            moneda: moneda,
            monto: monto,
            fechaDeposito: fecha,
        }
        //console.log(objetoUsuario)
        setLoading(true);
        const response = await registroConciliacionOperadorCehval(objetoUsuario);
        if (response) {
            //setCargando(false)
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(response)
            } else {
                setLoading(false);

                if(response.error === 401){
                    setSesionCaducada(true);
        
                }else if (response.isSuccessful){
                    setProcesoExitoso(true);
                    //console.log("mensaje exitoso")
                    setMensaje("Disponiblidad de fondos registrada");
        
                } else  {
                    setProcesoFallido(true);
                    setMensaje(response.mensaje);
                }
            }
        }

     
    }
    

    // const scapeCharacters = (e) => {
    //     const { value } = e.target;
    //     let out = '';
    //     let filtro = '1234567890,.';

    //     for (let i = 0; i < value.length; i++)
    //     if (filtro.indexOf(value.charAt(i)) != -1){
    //         //Se añaden a la salida los caracteres validos
    //         out += value.charAt(i);
    //     }
    //     setMonto(out);
    // }
 

    const cerrarModal = () => {
        setProcesoExitoso(false);
        setProcesoFallido(false);
        setSesionCaducada(false);
        setFecha("");
        setMoneda("");
        setFolio("");
        setMonto("");
        setMontoFront("")
    }

    const handleInputMonto = (e) => {
        const { value } = e.target;

        const formattedNumber = formatNumber(value)

        // Remove all characters other than digits or decimal points
        // const montoReplaced = value.replace(/[^\d.]/g, '');

        //Setted the amount for the backend
        setMonto(formattedNumber.integerNumber);
        setMontoFront(formattedNumber.stringNumber);

        //Format number for the frontend
       // formatNumber(format, setMontoFront);
    }


 

    return (
        <>
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp}></ManejadorErrores>
            }
            {procesoExitoso &&
                <ComponenteModal mostrarModal={procesoExitoso} cerrarModal={cerrarModal} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {procesoFallido &&
                <ComponenteModal mostrarModal={procesoFallido} cerrarModal={cerrarModal} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }
            {sesionCaducada &&
                <ComponenteModal mostrarModal={sesionCaducada} cerrarModal={cerrarModal} titulo="Ups! Al parecer la sesión ha caducado. Vuelva a iniciar sesión o revise su conexión a internet" textoBoton="Entendido"></ComponenteModal>
            }
            {loading &&
                <PantallaCargando></PantallaCargando>
            }

            <div className="fluid-container">{/* style={{ backgroundImage: `url(${fondo})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="fluid-container">*/}
                <main className="">
                    <div className="row row-wrap g-0">
                         
                        <div className="col-12 mb-5 main-min-height">
                            <div className="">
                                <BannerTitulo titulo="Registrar disponibilidad de fondos"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-3">
                                        <section className="d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded">
                                                <div className="col-12">
                                                    <form onSubmit={onSubmitForm} className="needs-validation font-acumin-variable text-royal-blue">
                                                        <div className="row mt-4">
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Folio BCH-TR" className="form-control fs-5" value={folio} onChange={(e) => setFolio(e.target.value)}  maxLength={25} name="folioBCH" type="text" placeholder="Colonia o barrio" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="folioBCH">Folio BCH-TR</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Fecha en la que se realizó el depósito" className="form-control fs-5" value={fecha} onChange={(e) => setFecha(e.target.value)} name="fecha" type="date" placeholder="fecha" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="fecha">Fecha de depósito</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating">
                                                                    <select className="form-select" value={moneda} title="Moneda del depósito" onChange={(e) => setMoneda(e.target.value)} id="moneda" name="Moneda" required>
                                                                        <option value=""></option>
                                                                        {monedas &&
                                                                            monedas.map((item, index) => (
                                                                                <option key={index} value={item.id} >{item.nombre}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Moneda</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Monto del depósito" className="form-control fs-5" onInput={(e) => handleInputMonto(e)} value={montoFront} maxLength={20} name="monto" type="text" placeholder="Monto" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="monto">Monto</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                <button type="submit" className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-1 background-royal-blue text-white bg-hover-gold">Registrar</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

