import { instance } from '../apiGlobal';

export async function traerSeriesPorCodigoEmision(obj) {
    
  try {
    const response = await instance(`/serie/consulta/serie/codigoemision`, {
      method: "post",
      data: obj
    });
    
    return response.data;
  } catch (error) {
    return error
  }
}