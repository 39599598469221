//React
import React, { useState, useEffect } from "react";



//Componentes generales
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../ComponentesGenerales/ManejadorErrores';
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";

//APIs
import { consultaSolicitudesAsignacionIsin } from "../../../api/GeneracionAsignacionCodigosISIN/consultaSolicitudesAsignacionIsin";

//Hooks
import useOficioAsignacionISIN from '../../../hooks/SolicitudGeneracionCodigoISIN/useOficioAsignacionISIN';


export default function SolitudesCodigoISINAutorizadas ({ history }) {

    
    //Save loaded data
    const [copia, setCopia] = useState();
    const [data, setData] = useState([]);
    
    //Spinner
    const [spin, setSpin] = useState(true);

    //States to handle load data when loading page 
    const [erroreshttp, setErroreshttp] = useState();


    const [failLoadData, setFailLoadData] = useState();


    useEffect(() => {
        getAllApplications();
    }, []);


    const getAllApplications = async () => {
        try {
            const response = await consultaSolicitudesAsignacionIsin({ solicitudIsinEstado: 3 });
            if (response) {
                setSpin(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    if (response.data) {
                        setSpin(false);
                        setData(response.data);
                        setCopia(response.data)
                    }  else {
                        setFailLoadData("No se pudo cargar la información");
                    }
                }
            }

          

        } catch (err) {
            console.error(err);
        }
    }


    
    const viewRowInformation = (row) => {

        const rowInfo = row.original;

        //Mandar el objeto de la emision que el usuario desea ver
        history.push({
            pathname: '/revisionOficioCodigosISIN', state: { emisionInfo: rowInfo }
        });
    }
    




    const goBack = () => {
        history.push({
            pathname: '/inicioCehval',
        })
    }


    return (
        <>

           
            {failLoadData &&
                <ComponenteModal mostrarModal={failLoadData} cerrarModal={goBack} titulo={failLoadData} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="">
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <main className="min-height-40">
                    <div className="row row-wrap g-0">
                     
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Oficios de Generación y Asignación de Código ISIN"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaUsuarios datos={data} displayInformation={viewRowInformation} useValidate={useOficioAsignacionISIN} textoBoton={"Abrir"} textTitle="Click para ver la información"></TablaUsuarios>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}





