export const isAuthenticated = (user) => !!user;

export const isAllowed = (user, permisos) => {

  return  permisos.some(permiso => user.includes(permiso));
    //user.permisos.map(function (item) {
    //
    // //console.log(permisos == item.cehvalPermisoNombre)
    //return permisos == item.cehvalPermisoNombre
    //})
}

// //console.log(user.permisos[0].cehvalPermisoNombre)
//en la siguinete funcion: 
//user es un objeto, en donde ".roles" es un arreglo,donde estan todos los roles de un usuario registrado
//roles es un arreglo de un rol definido, que se le pasa a la funcion
//preguntamos si el rol del arreglo "roles" esta contenido en el arreglo ".roles" del objeto autorización 
//si esta contenido, retorna un true
export const hasRole = (user, roles) =>
  roles.some(rol => user.roles.includes(rol));