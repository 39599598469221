import React from "react";

import { FormContext } from "./form_context";

export default function RegistroDI5(props) {
    const [, setValue] = React.useContext(FormContext);
    const [contadorDocumentos, setContadorDocumentos] = React.useState(0)

    React.useEffect(() => {
        //console.log( props.info.cehvalFirmaAutCollection)
        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 1 };
            return { ...prev, datos };
        });

    }, [])


    const guardarDatos = (e) => {
        const { name, value } = e.target;
        //console.log(value)

        setValue((prev) => {
            const datos = { ...prev.datos, [name]: value };
            return { ...prev, datos };
        });
    }
    const guardarDocumento = (name) => {

        if (document.getElementById(name).checked) {
            setContadorDocumentos(contadorDocumentos + 1)
            //arregloPivote.push(parseInt(numero, 10))            
            setValue((prev) => {
                const datos = { ...prev.datos, [name]: 1 };
                return { ...prev, datos };
            });
        } else {
            setValue((prev) => {
                const datos = { ...prev.datos, [name]: 0 };
                return { ...prev, datos };
            });
            setContadorDocumentos(contadorDocumentos - 1)
            //  arregloPivote = arregloPermisos.filter((item) => item !== parseInt(numero, 10))
        }
        ////console.log(arregloPivote)
    }

    React.useEffect(() => {

        const checkCompleto = document.getElementById("cehvalDocumentoSoporExpedComp")
        if (contadorDocumentos == 4) {

            checkCompleto.checked = true
            setValue((prev) => {
                const datos = { ...prev.datos, ["cehvalDocumentoSoporExpedComp"]: 1 };
                return { ...prev, datos };
            });

        } else {
            checkCompleto.checked = false
            setValue((prev) => {
                const datos = { ...prev.datos, ["cehvalDocumentoSoporExpedComp"]: 0 };
                return { ...prev, datos };
            });

        }
    }, [contadorDocumentos])

    const checkboxFuncion = (id, nombreCheck) => {

        if (document.getElementById(nombreCheck).checked) {
            document.querySelectorAll(`#${id} input[type=checkbox]`).forEach(function (checkElement) {
                checkElement.checked = true;
                checkElement.disabled = true;
                setValue((prev) => {
                    const datos = { ...prev.datos, [checkElement.name]: 1 };
                    return { ...prev, datos };
                });
           
            });

            setValue((prev) => {
                const datos = { ...prev.datos, [id]: 1 };
                return { ...prev, datos };
            });
            setContadorDocumentos(4)
        } else {
            document.querySelectorAll(`#${id} input[type=checkbox]`).forEach(function (checkElement) {

                checkElement.checked = false;
                checkElement.disabled = false;
                setValue((prev) => {
                    const datos = { ...prev.datos, [checkElement.name]: 0 };
                    return { ...prev, datos };
                });

            });
            setContadorDocumentos(0)
            setValue((prev) => {
                const datos = { ...prev.datos, [id]: 0 };
                return { ...prev, datos };
            });
        }
        // setArregloPermisos(arregloPivote)
    }

    const guardarId=(id,numero)=>{
        //console.log(id,numero)
        if(numero===1){
            setValue((prev) => {
                const datos = { ...prev.datos, ["idFirma1"]: id };
                return { ...prev, datos };
            });
        }else{
            setValue((prev) => {
                const datos = { ...prev.datos, ["idFirma2"]: id };
                return { ...prev, datos };
            });
        }
     
    }


    return (
        <>
            <div className="card-body">
                <h5 className="textoTituloFormulario" >V. Firmas autorizadas</h5>
                {
                    props.info.cehvalFirmaAutCollection.map((item, index) => (
                        <>
                        {item.tipoFirma &&
                                 
                               
                                <div className="row mb-5">
                                    <div className="col-md-6">
                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">{index + 1}. Usuario operador</label>
                                        <input disabled type="text" value={item.nombre} className="form-control  border  " required />
                                    </div>

                                    <div className="col-md-6 ">
                                        <label className="mb-2">Estatus Firma *</label>
                                        <div className="d-flex justify-content-start">
                                            <div className="contenedorBotonesStteper">
                                                < input placeholder="" type="radio" className="form-check-input me-2" value={1} onChange={(e) => {guardarDatos(e)
                                                                                                   guardarId(item.idFirma,index+1)}} id={`firmado${index + 1}`} name={`estatusFirma${index + 1}`} required />
                                                <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor={`firmado${index + 1}`}>Firmado</label>
                                            </div>

                                            <div className="contenedorBotonesStteper">
                                                < input placeholder="" type="radio" className="form-check-input me-2" value={0} onChange={(e) => {guardarDatos(e)
                                                                                                                                                  guardarId(item.idFirma,index+1)}} id={`noFirmado${index + 1}`} name={`estatusFirma${index + 1}`} required />
                                                <label className="form-check-label textoFormularioDepositantedirecto  mt-1" htmlFor={`noFirmado${index + 1}`}>No firmado</label>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-md-6">
                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Tipo firma</label>
                                        <input disabled type="text" value={item.tipoFirma} className="form-control  border  " required />
                                    </div>
                                </div>
}
                        </>
                    ))
                }


                <br></br>
                <div className="row mb-3 ">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Lugar y fecha de registro</label>
                        <input disabled type="text" value={props.info.cehvalDepositanteLugarFecha} className="form-control  border  r" id="fechaRegistro" name="fechaRegistro" required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Casa de bolsa intermediaria</label>
                        <input disabled type="text" value={props.info.cehvalDepositantePersonaIcbiId.cehvalPersonaJurNombre} className="form-control  border" required />
                    </div>
                </div>

                <form name="f1" id='formualarioChecks'>
                    <h5 className="textoTituloFormulario my-5" >Documentación soporte</h5>
                    <div className="row mb-3 ">
                        <div className="col-md-4 mb-3">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumento(e.target.name)} id="cehvalDocumentoSoporCopiaDni" name="cehvalDocumentoSoporCopiaDni" />
                                <label className="form-check-label" htmlFor="cehvalDocumentoSoporCopiaDni">
                                    Copia DNI
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumento(e.target.name)} id="cehvalDocumentoSoporCopiaRtn" name="cehvalDocumentoSoporCopiaRtn" />
                                <label className="form-check-label" htmlFor="cehvalDocumentoSoporCopiaRtn">
                                    Copia de RTN
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumento(e.target.name)} id="cehvalDocumentoSoporPoderRepreAdmon" name="cehvalDocumentoSoporPoderRepreAdmon" />
                                <label className="form-check-label" htmlFor="cehvalDocumentoSoporPoderRepreAdmon">
                                    Poder de representación o administración
                                </label>
                            </div>
                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumento(e.target.name)} id="contratoDeDepositante" name="contratoDeDepositante" />
                                <label className="form-check-label" htmlFor="contratoDeDepositante">
                                    Contrato de depositante
                                </label>
                            </div>
                        </div>
                        
                    </div>
                </form>
                
                <div className="row mb-3 ">
                    <div className="col-md-6 mb-3">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value={1} onChange={() => checkboxFuncion(`formualarioChecks`, "cehvalDocumentoSoporExpedComp")} id="cehvalDocumentoSoporExpedComp" name="cehvalDocumentoSoporExpedComp" />
                            <label className="form-check-label" htmlFor="cehvalDocumentoSoporExpedComp">
                                Expediente completo
                            </label>
                        </div>
                    </div>
                </div>


            </div>

        </>
    );
}

