import React from 'react';

import { Switch, Route } from "react-router-dom";
import PrivateRoute from './Routes/PrivateRoute';
import PublicRoute from './Routes/PublicRoute'


import { UserProvider } from './UserProvider';

import Header from "./ComponentesGenerales/Header";

//pantallas generales
import HomePage from './pantallasCehval/HomePage';
import SolicitarRestablecerPassword from './pantallasCehval/login/SolicitarRestablecerPassword';
import RecuperarPassword from './pantallasCehval/login/RecuperarPassword';
import Login from './pantallasCehval/login/Login';
import SolicitudRegistroDepositanteDirecto from './pantallasCehval/SolicitudesRegistro/SolicitudRegistroDepositanteDirecto';
import NotFound from './ComponentesGenerales/NotFound';

//Generales
import HistorialOperacionesTLP from './pantallasCehval/HistorialOperaciones/HistorialOperacionesTLP';


//inicio pantallas cehval
import InicioCehval from './inicioCehval/InicioCehval';
//final pantallas cehval

//inicio pantallas emisor
import InventarioEmsiones from './pantallasCehval/Emisor/InventarioEmisiones';
import InventarioSeries from './pantallasCehval/Emisor/InventarioSeries';
import SolicitudGeneracionAsignacionCodigosISIN from './pantallasCehval/CodigosISIN/Emisor/SolicitudGeneracionAsignacionCodigosISIN';
import RegistrarPagoDividendos from './pantallasCehval/Emisor/PagoDerechosPatrimoniales/RegistrarPagoDividendos';
import FormularioRegistroPagosDividendos from './pantallasCehval/Emisor/PagoDerechosPatrimoniales/FormularioRegistroPagosDividendos';
import RegistrarTasaAplicable from './pantallasCehval/Emisor/PagoDerechosPatrimoniales/RegistrarTasaAplicable';
import ConfirmarPagosDerechosPartimoniales from './pantallasCehval/Emisor/PagoDerechosPatrimoniales/ConfirmarPagoDerechosPatrimoniales';
import ConfirmacionMontosPagosDerechosPatrimoniales from './pantallasCehval/Emisor/PagoDerechosPatrimoniales/ConfirmacionMontosPagosDerechosPatrimoniales';
import PagosPendientesDeposito from './pantallasCehval/Emisor/PagoDerechosPatrimoniales/PagosPendientesDeposito';
import NotificarDepositoFondosPDP from './pantallasCehval/Emisor/PagoDerechosPatrimoniales/NotificarDepositoFondosPDP';

//final pantallas emisor

//inicio pantallas depositante directo
import RegistroDepositanteIndirectoNatural from './pantallasCehval/DepositanteDirecto/DepositanteDirectoOperador/RegistroDepositanteIndirectoNatural';
import RegistroDepositanteIndirectoJuridico from './pantallasCehval/DepositanteDirecto/DepositanteDirectoOperador/RegistroDepositanteIndirectoJuridico';
import RegistrarClientes from './pantallasCehval/DepositanteDirecto/DepositanteDirectoOperador/RegistrarClientes';

import MenuTransferenciaLibrePago from './pantallasCehval/DepositanteDirecto/TraspasosLibresDePago/MenuTransferenciaLibreDePago';
import MisCuentasDD from './pantallasCehval/DepositanteDirecto/TraspasosLibresDePago/MisCuentas/MisCuentasDD';
import MisValoresDD from './pantallasCehval/DepositanteDirecto/TraspasosLibresDePago/MisCuentas/MisValoresDD';
import TransferenciaDD from './pantallasCehval/DepositanteDirecto/TraspasosLibresDePago/MisCuentas/TransferenciaDD';

import MisClientesDD from './pantallasCehval/DepositanteDirecto/TraspasosLibresDePago/MisClientes/MisClientesDD'
import CuentasClienteDD from './pantallasCehval/DepositanteDirecto/TraspasosLibresDePago/MisClientes/CuentasClienteDD';
import ValoresClienteDD from './pantallasCehval/DepositanteDirecto/TraspasosLibresDePago/MisClientes/ValoresClienteDD';
import TransferenciaClienteDD from './pantallasCehval/DepositanteDirecto/TraspasosLibresDePago/MisClientes/TransferenciaClienteDD';
import NotificarDepositoFondos from './pantallasCehval/DepositanteDirecto/MercadoPrimario/NotificarDepositoFondos';
import NotificarDepositoFondosMS from './pantallasCehval/DepositanteDirecto/MercadoSecundario/NotificarDepositoFondosMS';

import ConsultarSubastasLiquidadasOperadorDD from './pantallasCehval/DepositanteDirecto/MercadoPrimario/ConsultarSubastasLiquidadasOperadorDD';
import ConsultarOperacionesEjecutadasOperadorDD from './pantallasCehval/DepositanteDirecto/MercadoSecundario/ConsultarOperacionesEjecutadasOperadorDD';

//inicio pantallas depositante indirecto
import CuentaDeValoresDepIndirecto from './pantallasCehval/DepositanteIndirecto/CuentaDeValoreDepIndirecto';

//administrador de ususarios
import RegistroManual from './pantallasCehval/AdministradorUsuarios/RegistroManual';
import RolesYPermisos from './pantallasCehval/AdministradorUsuarios/RolesYPermisos/RolesYPermisos';
import CrearRolConPermisos from './pantallasCehval/AdministradorUsuarios/RolesYPermisos/CrearRoleConPermisos';
import QuitarPermisos from './pantallasCehval/AdministradorUsuarios/RolesYPermisos/QuitarPermisos';
import AgregarPermisos from './pantallasCehval/AdministradorUsuarios/RolesYPermisos/AgregarPermisos';
import BitacoraSistema from './pantallasCehval/bitacoras/BitacoraSistema';
import BajaUsuarios from './pantallasCehval/AdministradorUsuarios/AltaYBajaUsuarios/BajaUsuarios';
import AltaUsuarios from './pantallasCehval/AdministradorUsuarios/AltaYBajaUsuarios/AltaUsuario';
import AgregarRolUsuario from './pantallasCehval/AdministradorUsuarios/RolesYPermisos/AgregarRolUsuario';
import QuitarRolUsuario from './pantallasCehval/AdministradorUsuarios/RolesYPermisos/QuitarRolAUsuario';
import ResetearContraseñaUsuarios from './pantallasCehval/AdministradorUsuarios/RolesYPermisos/ResetearContraseñaUsuarios'

//operador cehval
import MenuRegistrosParaAbrirCuentas from './pantallasCehval/OperadorCehval/AbrirCuentasDepositantes/MenuRegistrosParaAbirCuentas';
import EnviarNotificacionesDeRegistroCorrecto from './pantallasCehval/OperadorCehval/AbrirCuentasDepositantes/EnviarNotificacionesDeRegistroCorrecto';

import MenuSolicitudesParaRevisar from './pantallasCehval/OperadorCehval/RevisarSolicitudesDepositantes/MenuSolicitudesParaRevisar';
import SolicitudesDepositantesParaRevisar from './pantallasCehval/OperadorCehval/RevisarSolicitudesDepositantes/SolicitudesDepositantesParaRevisar';

import MenuRegistrarEmisoresEmisionesSeries from './pantallasCehval/OperadorCehval/RegistrarEmisoresEmisionesYSeries/MenuRegistrarEmisoresEmisionesSeries';
import RegistroEmisiones from './pantallasCehval/OperadorCehval/RegistrarEmisoresEmisionesYSeries/RegistroEmisiones';
import RegistroSeries from './pantallasCehval/OperadorCehval/RegistrarEmisoresEmisionesYSeries/RegistroSeries';
import SolicitudRegistroEmisor from './pantallasCehval/OperadorCehval/RegistrarEmisoresEmisionesYSeries/SolicitudRegistroEmisor';


import MenuSeriesEmisionesAutorizadas from './pantallasCehval/OperadorCehval/EmisoresEmisionesYSeriesAutorizadas/MenuSeriesEmisionesAutorizadas';
import SeriesAutorizadas from './pantallasCehval/OperadorCehval/EmisoresEmisionesYSeriesAutorizadas/SeriesAutorizadas';
import EmisionesAutorizadas from './pantallasCehval/OperadorCehval/EmisoresEmisionesYSeriesAutorizadas/EmisionesAutorizadas';
import EmisoresParaAbrirCuentas from './pantallasCehval/OperadorCehval/EmisoresEmisionesYSeriesAutorizadas/EmisoresParaAbrirCuentas';


import MenuSeriesEmisionesrRechazadas from './pantallasCehval/OperadorCehval/EmisoresEmisionesYSeriesRechazadas/MenuSeriesEmisionesRechazadas';
import EmisionesRechazadas from './pantallasCehval/OperadorCehval/EmisoresEmisionesYSeriesRechazadas/emisionesRechazadas';
import SeriesRechazadas from './pantallasCehval/OperadorCehval/EmisoresEmisionesYSeriesRechazadas/seriesRechazadas';
import SolicitudesEmisoresRechazados from './pantallasCehval/OperadorCehval/EmisoresEmisionesYSeriesRechazadas/SolicitudesEmisoresRechazados';

import MenuEmisoresEmisionesYSeriesRegistradas from './pantallasCehval/OperadorCehval/EmisoresEmisionesYSeriesRegistradas/MenuEmisoresEmisionesYSeriesRegistradas';
import SeriesRegistradas from './pantallasCehval/OperadorCehval/EmisoresEmisionesYSeriesRegistradas/SeriesRegistradas';
import EmisoresRegistrados from './pantallasCehval/OperadorCehval/EmisoresEmisionesYSeriesRegistradas/EmisoresRegistrados';
import EmisionesRegistradas from './pantallasCehval/OperadorCehval/EmisoresEmisionesYSeriesRegistradas/EmisionesRegistradas';

import MenuSolicitudesDepositantesRechazadosParaNotificar from './pantallasCehval/OperadorCehval/SolicitudesDepositantesRechazadas/MenuSolicitudesDepositantesRechazadosParaNotificar';
import SolicitudesDepositantesRechazadasParaNotificar from './pantallasCehval/OperadorCehval/SolicitudesDepositantesRechazadas/SolicitudesDepositantesRechazadasParaNotificar';

import MenuDesmaterializacion from './pantallasCehval/OperadorCehval/Desmaterializacion/MenuDesmaterializacion';
import DesmaterializarValores from './pantallasCehval/OperadorCehval/Desmaterializacion/DesmaterializarValores';
import NotificarDesmaterializacion from './pantallasCehval/OperadorCehval/Desmaterializacion/NotificarDesmaterializacion';
import NotificarRechazoDesmaterializacion from './pantallasCehval/OperadorCehval/Desmaterializacion/NotificarRechazoDesmaterializacion';
import SolicitudDesmaterializacion from './pantallasCehval/OperadorCehval/Desmaterializacion/SolicitudDesmaterializacion';

import MenuEmisoresEmisionesSeriesBCV from './pantallasCehval/UsuariosBCV/RegistroEmisorBCV/MenuEmisoresEmisionesYSeriesBCV';
import RegistroEmisoresBCV from './pantallasCehval/UsuariosBCV/RegistroEmisorBCV/RegistroEmisoresBCV';
import RegistrarEmisorBCV from './pantallasCehval/UsuariosBCV/RegistroEmisorBCV/RegistrarEmisorBCV';
import RegistroEmisionesBCV from './pantallasCehval/UsuariosBCV/RegistroEmisorBCV/RegistroEmisionesBCV/RegistroEmisionesBCV';
import RegistroSeriesBCV from './pantallasCehval/UsuariosBCV/RegistroEmisorBCV/RegistrarSeriesBCV.js/RegistroSeriesBCV';

import MenuDepositantesRegistrados from './pantallasCehval/OperadorCehval/DepositantesRegistrados/MenuDepositantesRegistrados';
import DepositantesRegistrados from './pantallasCehval/OperadorCehval/DepositantesRegistrados/DepositanteRegistrados';

import RegistrarDisponibilidadFondos from './pantallasCehval/OperadorCehval/MercadoPrimario/RegistrarDisponibilidadFondos';
import FondosConciliadosYNoConciliados from './pantallasCehval/OperadorCehval/MercadoPrimario/FondosConciliadosYNoConciliados';
import CargarResultadoSubasta from './pantallasCehval/OperadorCehval/MercadoPrimario/CargarResultadoSubasta';
import MontosPorClientesYSubasta from './pantallasCehval/OperadorCehval/MercadoPrimario/MontosPorClienteYSubasta';
import ConsultarLiquidacionSubastasOperador from './pantallasCehval/OperadorCehval/MercadoPrimario/ConsultarLiquidacionSubastasOperador';

import MenuImpresionDepositantesAutorizados from './pantallasCehval/ImpresionDocumentos/DepositantesAutorizados/MenuImpresionDepositantesAutorizados';
import ImpresionDepositantesRegistrados from './pantallasCehval/ImpresionDocumentos/DepositantesAutorizados/ImpresionDepositantesRegistrados';
import MenuImpresionSolicitudDepositante from './pantallasCehval/ImpresionDocumentos/SolicitudDepositantes/MenuImpresionSolicitudDepositante';
import ImpresionSolicitudDepositante from './pantallasCehval/ImpresionDocumentos/SolicitudDepositantes/ImpresionSolicitudDepositante';

import InventarioValores from './pantallasCehval/OperadorCehval/CuentaDeValores/InventarioValores';
import BitacoraOperaciones from './pantallasCehval/bitacoras/BitacoraOperaciones';
import CuentaValoresTransito from './pantallasCehval/OperadorCehval/CuentaDeValores/CuentaValoresTransito';
import CuentaValoresReserva from './pantallasCehval/OperadorCehval/CuentaDeValores/CuentaValoresReserva';
import CuentaValoresPrendasGarantias from './pantallasCehval/OperadorCehval/CuentaDeValores/CuentaValoresPrendasGarantias';
import CuentaValoresReporto from './pantallasCehval/OperadorCehval/CuentaDeValores/CuentaValoresReporto';
import PrendasYGarantiasLiberadas from './pantallasCehval/OperadorCehval/CuentaDeValores/PrendasYGarantiasLiberadas';
import ConstitucionesRechazadas from './pantallasCehval/OperadorCehval/CuentaDeValores/ConstitucionesRechazadas';
import LiberacionesRechazadas from './pantallasCehval/OperadorCehval/CuentaDeValores/LiberacionesRechazadas';

import CatalogosCasaBolsa from './pantallasCehval/AdministradorUsuarios/SistemaInformacion/CatalagoCasasBolsa';
import MovimientosValores from './pantallasCehval/AdministradorUsuarios/SistemaInformacion/MovimientosValores';
import TraspasosValores from './pantallasCehval/AdministradorUsuarios/SistemaInformacion/TraspasosValores';
import DepositosYLiquidacionesFondos from './pantallasCehval/AdministradorUsuarios/SistemaInformacion/DepositosYLidacionesFondos';
import TenenciaValores from './pantallasCehval/AdministradorUsuarios/SistemaInformacion/TenenciaValores';
import ConsultarSeriesRegistradas from './pantallasCehval/AdministradorUsuarios/SistemaInformacion/ConsultarSeriesRegistradas';

import MenuVerCuentasDepositantes from './pantallasCehval/OperadorCehval/VerCuentasDepositantes/MenuVerCuentasDepositantes';
import VerCuentasDepositantes from './pantallasCehval/OperadorCehval/VerCuentasDepositantes/VerCuentasDepositantes';
import CuentasDepositantes from './pantallasCehval/OperadorCehval/VerCuentasDepositantes/CuentasDepositantes';
import ValoresDepositantes from './pantallasCehval/OperadorCehval/VerCuentasDepositantes/ValoresDepositantes';

//MERCADO SECUNDARIO
import RegistrarDisponibilidadFondosMS from './pantallasCehval/OperadorCehval/MercadoSecundario/RegistrarDisponibilidadFondosMS';
import CargarInformacionOperacionesMS from './pantallasCehval/OperadorCehval/MercadoSecundario/CargarInformacionOperacionesMS';
import InformacionOperacionMS from './pantallasCehval/OperadorCehval/MercadoSecundario/InformacionOperacionMS';
import ConciliarFondosMS from './pantallasCehval/OperadorCehval/MercadoSecundario/ConciliarFondosMS';
import ConsultarOperacionesActivas from './pantallasCehval/OperadorCehval/MercadoSecundario/ConsultarOperacionesActivas';
import HistoricoDepositosFondosConciliados from './pantallasCehval/OperadorCehval/MercadoSecundario/HistoricoDepositosFondosConciliados';
import DepositosFondosNoConciliadosEliminados from './pantallasCehval/OperadorCehval/MercadoSecundario/DepositosFondosNoConciliadosEliminados';
import OperacionesEjecutadas from './pantallasCehval/OperadorCehval/MercadoSecundario/OperacionesEjecutadas';
import ConsultarOperacionesActivasAutorizador from './pantallasCehval/AutorizadorCehval/MercadoSecundario/ConsultarOperacionesActivasAutorizador';
import ConsultarFondosVirtuales from './pantallasCehval/OperadorCehval/MercadoSecundario/ConsultarFondosVirtuales';
import MenuFondosVirtuales from './pantallasCehval/OperadorCehval/MercadoSecundario/MenuFondosVirtules';
import FormularioDevolucion from './pantallasCehval/OperadorCehval/MercadoSecundario/FormularioDevolucion';


//ADMINISTRACIÓN DE PRENDAS Y GARANTIAS 
import SolicitudConstitucionDeGarantia from './pantallasCehval/AdministracionPrendasYGarantias/SolicitudGarantia/SolicitarConstitucion/SolicitudConstitucionDeGarantia';
import SolicitudLiberacionDeGarantia from './pantallasCehval/AdministracionPrendasYGarantias/LiberacionGarantia/SolicitudLiberacionGarantia/SolicitudLiberacionDeGarantia';
import MenuNotificarSolicitudesConstitucionGarantia from './pantallasCehval/AdministracionPrendasYGarantias/SolicitudGarantia/NotificarSolicitudesConstitucion/MenuNotificarSolicitudesConstitucionGarantia';
import NotificarConstitucionesDeGarantia from './pantallasCehval/AdministracionPrendasYGarantias/SolicitudGarantia/NotificarSolicitudesConstitucion/NotificarConstitucionesDeGarantia';
import MenuNotificarSolicitudesLiberacionGarantia from './pantallasCehval/AdministracionPrendasYGarantias/LiberacionGarantia/NotificarSolicitudesLiberacion/MenuNotificarSolicitudesLiberacionGarantia';
import NotificarLiberacionesDeGarantia from './pantallasCehval/AdministracionPrendasYGarantias/LiberacionGarantia/NotificarSolicitudesLiberacion/NotificarLiberacionesDeGarantia';

//RESGUARDO VALORES FISICOS
import MenuResguardoDepositantesValoresFisicos from './pantallasCehval/ResguardoDeValoresFísicos/RegistroDeDepositanteResguardoValoresFisicos/MenuResguardoDepositantesValoresFisicos';
import SolicitarRegistroDepValoresFisicos from './pantallasCehval/ResguardoDeValoresFísicos/RegistroDeDepositanteResguardoValoresFisicos/SolicitarRegistroDepositanteValoresFisicos/SolicitarRegistroDepValoresFisicos';
import SolicitudesResguardoValoresFisicosAutorizar from './pantallasCehval/ResguardoDeValoresFísicos/RegistroDeDepositanteResguardoValoresFisicos/SolicitudesRegistroDepositantesValoresFisicos/SolicitudesResguardoValoresFisicosAutorizar';
import NotificarDepositantesValoresFisicos from './pantallasCehval/ResguardoDeValoresFísicos/RegistroDeDepositanteResguardoValoresFisicos/NotificarRegistroDepositantesValoresFisicos/NotificarSolicitudesDepositantesValoresFisicos';
import TablaDepositantesValoresFisicosRegistrados from './pantallasCehval/ResguardoDeValoresFísicos/RegistroDeDepositanteResguardoValoresFisicos/DepositantesValoresFisicosRegistrados/TablaDepositantesValoresFisicosRegistrados';
import DepositanteValFisSeleccionado from './pantallasCehval/ResguardoDeValoresFísicos/RegistroDeDepositanteResguardoValoresFisicos/DepositantesValoresFisicosRegistrados/DepositanteValFisSeleccionado';
import MenuDepositosValoresFisicos from './pantallasCehval/ResguardoDeValoresFísicos/RegistroDepositosValoresFisicos/MenuDepositosValoresFisicos';
import SolicitudDepositoValorFisico from './pantallasCehval/ResguardoDeValoresFísicos/RegistroDepositosValoresFisicos/SolicitarRegistroDeValoresFisicos/SolicitudDepositoValorFisico';
import SolicitudesDepositoValFisAutorizar from './pantallasCehval/ResguardoDeValoresFísicos/RegistroDepositosValoresFisicos/AutorizarSolicitudesDepositoValFis/SolicitudesDepositoValFisAutorizar';
import NotificarDepositoValoresFisicos from './pantallasCehval/ResguardoDeValoresFísicos/RegistroDepositosValoresFisicos/NotificarSolicitudesDepositosValFis/NotificarDepositoValoresFisicos';
import ValoresFisicosDepositados from './pantallasCehval/ResguardoDeValoresFísicos/RegistroDepositosValoresFisicos/TablaValoresDepositados/ValoresFisicosDepositados';
import VisualizarValorFisico from './pantallasCehval/ResguardoDeValoresFísicos/RegistroDepositosValoresFisicos/TablaValoresDepositados/VisualizarValorFisico';
import TablaValoresFisicosRetirados from './pantallasCehval/ResguardoDeValoresFísicos/RegistroDepositosValoresFisicos/ValoreFisicosRetirados/TablaValoresFisicosRetirados';
import VisualizarValorFisicoRetirado from './pantallasCehval/ResguardoDeValoresFísicos/RegistroDepositosValoresFisicos/ValoreFisicosRetirados/VisualizarValorFisicoRetirado';


//MANTENIMIENTO
import MenuActualizacionActores from './pantallasCehval/OperadorCehval/ActualizacionInformacion/MenuActualizacionActores';
import ActualizarDepositanteJuridico from './pantallasCehval/OperadorCehval/ActualizacionInformacion/DepositanteJuridico/ActualizarDepositanteJuridico';
import DepositantesParaActualizar from './pantallasCehval/OperadorCehval/ActualizacionInformacion/DepositantesParaActualizar';
import ActualizarDepositanteIndirectoNatural from './pantallasCehval/OperadorCehval/ActualizacionInformacion/DepositanteIndirecto/ActualizarDepositanteIndirecto';
import EmisoresParaActualizar from './pantallasCehval/OperadorCehval/ActualizacionInformacion/EmisoresParaActualizar';
import ActualizarEmisores from './pantallasCehval/OperadorCehval/ActualizacionInformacion/Emisores/ActualizarEmisores';
import DepositarRetirarValores from './pantallasCehval/OperadorCehval/DepositoRetiroValores/DepositarRetirarValores';
import HistoricoDepositosRetirosValores from './pantallasCehval/OperadorCehval/DepositoRetiroValores/HistoricoDepositosRetirosValores';

import MenuTLPOperador from './pantallasCehval/OperadorCehval/TraspasoLibrePago/MenuTLPOperador';
import CuentasCehval from './pantallasCehval/OperadorCehval/TraspasoLibrePago/CuentasCehval';
import CuentasValoresCehvalTLP from './pantallasCehval/OperadorCehval/TraspasoLibrePago/CuentasValoresCehvalTLP';
import DepositantesCehval from './pantallasCehval/OperadorCehval/TraspasoLibrePago/DepositantesCehval';
import CuentasDepositantesCehval from './pantallasCehval/OperadorCehval/TraspasoLibrePago/CuentasDepositantesCehval';
import ValoresDepositanteCehval from './pantallasCehval/OperadorCehval/TraspasoLibrePago/ValoresDepositanteCehval';
import TLPOperadorCehval from './pantallasCehval/OperadorCehval/TraspasoLibrePago/TLpOperadorCehval';
import FormTLPReporto from './pantallasCehval/OperadorCehval/TraspasoLibrePago/formTLPReporto/FormTLPReporto';

//GENERACIÓN Y ASIGNACIÓN DE CÓDIGOS ISIN
import SolicitudesAsignacionCodIsin from './pantallasCehval/CodigosISIN/OperadorCehval/SolicitudesAsignacionCodIsin';
import ValidacionSolicitudCodIsin from './pantallasCehval/CodigosISIN/OperadorCehval/ValidacionSolicitudCodIsin';
import NotificarSolicitudesRechazadasISIN from './pantallasCehval/CodigosISIN/OperadorCehval/NotificarSolicitudesRechazadasISIN';
import SolitudesCodigoISINAutorizadas from './pantallasCehval/CodigosISIN/OperadorCehval/SolitudesCodigoISINAutorizadas';
import RevisionOficioCodigosISIN from './pantallasCehval/CodigosISIN/OperadorCehval/RevisionOficioCodigosISIN';
import HistoricoCodigosISINGenerados from './pantallasCehval/CodigosISIN/OperadorCehval/HistoricoCodigosISINGenerados';
import HistoricoVisualizarInfoSeleccionado from './pantallasCehval/CodigosISIN/OperadorCehval/HistoricoVisualizarInfoSeleccionado';


//SALVAGUARDAS FINANCIERAS
import AsociarOperacionesForzadas from './pantallasCehval/OperadorCehval/SalvaguardasFinancieras/AsociarOperacionesForzadas';
import FondoLiquidacion from './pantallasCehval/OperadorCehval/SalvaguardasFinancieras/FondoLiquidacion';
import RetiroDepositoFondoLiquidacion from './pantallasCehval/OperadorCehval/SalvaguardasFinancieras/RetiroDepositoFondoLiquidacion';
import HistoricoDepositosRetirosFondoLiquidacion from './pantallasCehval/OperadorCehval/SalvaguardasFinancieras/HistoricoDepositosRetirosFondoLiquidacion';

import AgregarFechasPagoInteresesAmortizaciones from './pantallasCehval/OperadorCehval/PagoDerechosPatrimoniales/AgregarFechasPagoInteresesAmortizaciones';
import FormularioAgregarFechas from './pantallasCehval/OperadorCehval/PagoDerechosPatrimoniales/FormularioAgregarFechas';
import RegistrarDisponibilidadFondosPDP from './pantallasCehval/OperadorCehval/PagoDerechosPatrimoniales/RegistrarDisponibiliadFondosPDP';
import ConciliarFondosPDP from './pantallasCehval/OperadorCehval/PagoDerechosPatrimoniales/ConciliarFondosPDP';
import ConsultarHistoricoFondosConciliadosPDP from './pantallasCehval/OperadorCehval/PagoDerechosPatrimoniales/ConsultarHistoricoFondosConciliadosPDP';
import DepositosFondosEliminadosPDP from './pantallasCehval/OperadorCehval/PagoDerechosPatrimoniales/DepositosFondosEliminadosPDP';
import BuscarPagosPatrimoniales from './pantallasCehval/OperadorCehval/PagoDerechosPatrimoniales/BuscarPagosDerechosPatrimoniales';
import FormularioPagosEncontrados from './pantallasCehval/OperadorCehval/PagoDerechosPatrimoniales/FormularioPagosEncontrados';

//TARIFICACIÓN
import ActualizarVectorPrecios from './pantallasCehval/Tarificación/ConceptosNoPeriodicos/operadorCehval/actualizarVectorPrecios';
import GenerarCobrosNoPeriodicos from './pantallasCehval/Tarificación/ConceptosNoPeriodicos/operadorCehval/GenerarCobrosNoPeriodicos';
import RevisarAvisosCobroAutomaticos from './pantallasCehval/Tarificación/ConceptosAutomaticos/operadorCehval/RevisarAvisosCobroAutomaticos';
import AvisoCobroAutSeleccionado from './pantallasCehval/Tarificación/ConceptosAutomaticos/operadorCehval/AvisoCobroAutSeleccionado';
import DarAccesoISINSistemasInfo from './pantallasCehval/Tarificación/AccesoIsinYSistemasInformacion/DarAccesoISINSistemasInfo';
import RevocarAccesoISINSistInformacion from './pantallasCehval/Tarificación/AccesoIsinYSistemasInformacion/RevocarAccesoISINSistInformacion';
import NotificarDepositoFondosAvisoCobro from './pantallasCehval/Tarificación/NotificarDepositoFondos/depositateEmisor/NotificarDepositoFondosAvisoCobro';
import VerTodosAvisosCobroConciliar from './pantallasCehval/Tarificación/NotificarDepositoFondos/operadorCehval/VerTodosAvisosCobroConciliar';
import RevisarAvisoCobroParaConciliar from './pantallasCehval/Tarificación/NotificarDepositoFondos/operadorCehval/RevisarAvisoCobroParaConciliar';

//MANTENIMIENTO DE FACTURAS
import MenuMantenimientoFacturas from './pantallasCehval/MantenimientoFacturas/MenuMantenimientoFacturas';
import RegistrarDatosFacturacion from './pantallasCehval/MantenimientoFacturas/RegistrarDatosFacturacion';
import TablaDatosFacturacion from './pantallasCehval/MantenimientoFacturas/TablaDatosFacturacion';
import DesactivarActivarMantenimiento from './pantallasCehval/MantenimientoFacturas/DesactivarActivarMantenimiento';

//pantallas autorizador cehval
import MenuSolicitudesAutorizadorCehval from './pantallasCehval/AutorizadorCehval/SolicitudesDepositantes/MenuSolicitudesAutorizadorCehval';
import SolicitudesDepositantesParaAutorizar from './pantallasCehval/AutorizadorCehval/SolicitudesDepositantes/SolicitudesDepositantesParaAutorizar';

import MenuAutorizarEmisoresEmisionesSeries from './pantallasCehval/AutorizadorCehval/EmisoresEmisionesSeries/MenuAutorizarEmisionesSeries';
import SolicitudesEmisoresParaAutorizar from './pantallasCehval/AutorizadorCehval/EmisoresEmisionesSeries/SolicitudesEmisoresParaAutorizar';
import SolicitudEmisiones from './pantallasCehval/AutorizadorCehval/EmisoresEmisionesSeries/SolicitudEmisiones';
import SolicitudSeries from './pantallasCehval/AutorizadorCehval/EmisoresEmisionesSeries/SolicitudSeries';

import TablaSolicitudesConstitucionDeGarantia from './pantallasCehval/AdministracionPrendasYGarantias/SolicitudGarantia/AutorizarConstitucion/TablaSolicitudesConstitucionDeGarantia';
import AutorizarConstitucionDeGarantia from './pantallasCehval/AdministracionPrendasYGarantias/SolicitudGarantia/AutorizarConstitucion/AutorizarConstitucionDeGarantia';
import TablaSolicitudesLiberacionDeGarantia from './pantallasCehval/AdministracionPrendasYGarantias/LiberacionGarantia/AutorizarLiberacionGarantia/TablaSolicitudesLiberacionDeGarantia';
import AutorizarLiberacionDeGarantia from './pantallasCehval/AdministracionPrendasYGarantias/LiberacionGarantia/AutorizarLiberacionGarantia/AutorizarLiberacionDeGarantia';
import AutorizarDesmaterializacion from './pantallasCehval/AutorizadorCehval/Desmaterilaizacion/AutorizarDesmaterializacion';
import ConsultarLiquidacionSubastasAutorizador from './pantallasCehval/AutorizadorCehval/MercadoPrimario/ConsultarLiquidacionSubastasAutorizador';

import AutorizarSolicitudDepositanteValoresFisicos from './pantallasCehval/ResguardoDeValoresFísicos/RegistroDeDepositanteResguardoValoresFisicos/SolicitudesRegistroDepositantesValoresFisicos/AutorizarSolicitudDepositanteValoresFisicos';
import AutorizarSolicitudDepositoValorFisico from './pantallasCehval/ResguardoDeValoresFísicos/RegistroDepositosValoresFisicos/AutorizarSolicitudesDepositoValFis/AutorizarSolicitudDepositoValorFisico';

import PagosPorliquidar from './pantallasCehval/AutorizadorCehval/PagosDerechosPatrimoniales/PagosPorLiquidar';
import FormularioPagosPorLiquidar from './pantallasCehval/AutorizadorCehval/PagosDerechosPatrimoniales/FormularioPagosPorLiquidar';

import AutorizarDepositosRetirosValores from './pantallasCehval/AutorizadorCehval/DepositoRetiroValores/AutorizarDepositosRetirosValores';
import DepositosRetirosValoresPorAutorizar from './pantallasCehval/AutorizadorCehval/DepositoRetiroValores/DepositosRetirosValoresPorAutorizar';

//TARIFICACION
import SolicitudesAutorizarAvisosCobroNoPeriodicos from './pantallasCehval/Tarificación/ConceptosNoPeriodicos/autorizadorCehval/SolicitudesAutorizarAvisosCobroNoPeriodicos';
import AutorizarAvisoCobroNoPeriodicos from './pantallasCehval/Tarificación/ConceptosNoPeriodicos/autorizadorCehval/AutorizarAvisoCobroNoPeriodicos';
import AvisosCobroAutomaticosPorAutorizar from './pantallasCehval/Tarificación/ConceptosAutomaticos/autorizadorCehval/AvisosCobroAutomaticosPorAutorizar';
import AutorizarAvisoCobroConceptosAutomaticos from './pantallasCehval/Tarificación/ConceptosAutomaticos/autorizadorCehval/AutorizarAvisoCobroConceptosAutomaticos';
import GeneracionEstadosCuenta from './pantallasCehval/Tarificación/EstadosDeCuenta/GeneracionEstadosCuenta';
import DescargaEnvioEdosCuenta from './pantallasCehval/Tarificación/EstadosDeCuenta/DescargaEnvioEdosCuenta';

//Asignación códigos ISIN
import TodasSolicitudesCodigoISINPorAutorizar from "./pantallasCehval/CodigosISIN/AutorizadorCehval/TodasSolicitudesCodigoISINPorAutorizar";
import AutorizarSolicitudCodIsin from "./pantallasCehval/CodigosISIN/AutorizadorCehval/AutorizarSolicitudCodIsin";

//pantallas funcionarioCumplimiento
import MenuSolicitudesFuncionarioCumplimiento from './pantallasCehval/FuncionarioCumplimiento/SolicitudesDepositantes/MenuSolicitudesFuncionarioCumplimiento';
import SolicitudesDepositantesParaValidar from './pantallasCehval/FuncionarioCumplimiento/SolicitudesDepositantes/SolicitudesDepositantesParaValidar';

//mostrar informacion depositantes, emisor, emisiones, series
import VerInfoEmisor from './ComponentesGenerales/VerInfoEmisor/VerInfoEmisor';
import VerInfoDepIndPersonaNaturalDoc from './ComponentesGenerales/VerInfoDepPersonaNaturalDoc/VerInfoDepIndPersonaNaturalDoc';
import VerInfoDepositanteDirectoPerJuridicaDoc from './ComponentesGenerales/VerInfoDepDirPersoJurDoc/VerInfoDepositanteDirectoPerJuridicaDoc';
import VerInfoEmision from './ComponentesGenerales/VerInfoEmision/VerInfoEmision';
import VerInfoSerie from './ComponentesGenerales/VerInfoSerie/VerInfoSerie';
import VerInfoDesmaterializacion from './ComponentesGenerales/VerInfoDesmaterializacion/VerInfoDesmaterializacion';

//No autorizado//
import NoAutorizado from './pantallasCehval/NoAutorizado/NoAutorizado';



export default function Router() {
  return (
    <>
      <UserProvider>
        <Header />
        <Switch>
          {/*Ruta publica*/}
          <PublicRoute exact path="/login" component={Login} />

          {/*Inicia rutas libres de autenticacion*/}
          <Route exact path="/" component={HomePage} />
          <Route exact path="/solicitarRestablecerPassword" component={SolicitarRestablecerPassword} />
          <Route exact path="/recuperarPassword" component={RecuperarPassword} />
          <Route exact path="/solicitudRegistroDepositanteDirecto" component={SolicitudRegistroDepositanteDirecto} />

          {/*Rutas con autenticacion pero sin permisos, todos los usuarios pueden entrar*/}
          <PrivateRoute exact path="/inicioCehval" component={InicioCehval} />

          {/*INICIA RUTAS SUPERADMINISTRADOR*/}
          <PrivateRoute hasRole="SUPERADMINISTRADOR" hasPermission="ViewInterfazBitacoraSistema" exact path="/bitacoraSistema" component={BitacoraSistema} />

          {/*Inicia rutas ADMINISTRADOR_DE_USUARIO*/}
          <PrivateRoute hasRole="ADMINISTRADOR_DE_USUARIO" hasPermission="RegisterUsuariosInternos" exact path="/registroManual" component={RegistroManual} />
          <PrivateRoute hasRole="ADMINISTRADOR_DE_USUARIO" hasPermission="ViewInterfazMenuRolesYPermisos" exact path="/rolesYPermisos" component={RolesYPermisos} />
          <PrivateRoute hasRole="ADMINISTRADOR_DE_USUARIO" hasPermission="ViewInterfazAgregarPermisos" exact path="/agregarPermisos" component={AgregarPermisos} />
          <PrivateRoute hasRole="ADMINISTRADOR_DE_USUARIO" hasPermission="ViewInterfazQuitarpermisos" exact path="/quitarPermisos" component={QuitarPermisos} />
          <PrivateRoute hasRole="ADMINISTRADOR_DE_USUARIO" hasPermission="ViewInterfazCrearRolesConPermisos" exact path="/crearRolConPermisos" component={CrearRolConPermisos} />
          <PrivateRoute hasRole="ADMINISTRADOR_DE_USUARIO" hasPermission="ViewInterfazBajaUsuarios" exact path="/bajaUsuarios" component={BajaUsuarios} />
          <PrivateRoute hasRole="ADMINISTRADOR_DE_USUARIO" hasPermission="ViewInterfazAltaUsuarios" exact path="/altaUsuarios" component={AltaUsuarios} />
          <PrivateRoute hasRole="ADMINISTRADOR_DE_USUARIO" hasPermission="ViewInterfazAgregarRolUsuario" exact path="/agregarRolUsuario" component={AgregarRolUsuario} />
          <PrivateRoute hasRole="ADMINISTRADOR_DE_USUARIO" hasPermission="ViewInterfazQuitarRolUsuario" exact path="/quitarRolUsuario" component={QuitarRolUsuario} />
          <PrivateRoute hasRole="ADMINISTRADOR_DE_USUARIO" hasPermission="ViewInterfazResetearContraseñas" exact path="/resetearContrasenaUsuario" component={ResetearContraseñaUsuarios} />
          {/*INICIA RUTAS OPERADOR_CEHVAL*/}


          {/*REGISTRO DE DEPOSITANTES*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuSolicitudesDepositantesParaRevisar" exact path="/menuSolicitudesParaRevisar" component={MenuSolicitudesParaRevisar} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ReviewSolicitudesDepositantes" exact path="/solicitudesDepositantesParaRevisar" component={SolicitudesDepositantesParaRevisar} />

          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuDepositantesAutorizados" exact path="/menuRegistrosParaAbrirCuentas" component={MenuRegistrosParaAbrirCuentas} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifyAperturaCuentasDepositantes" exact path="/enviarNotificacionesDeRegistroCorrecto" component={EnviarNotificacionesDeRegistroCorrecto} />

          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuDepositantesRechazadosParaNotificar" exact path="/menuSolicitudesDepositantesRechazadosParaNotificar" component={MenuSolicitudesDepositantesRechazadosParaNotificar} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifySolicitudesRechazadasDepositantes" exact path="/solicitudesDepositantesRechazadasParaNotificar" component={SolicitudesDepositantesRechazadasParaNotificar} />

          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuDepositantesRegistrados" exact path="/menuDepositantesRegistrados" component={MenuDepositantesRegistrados} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazDepositantesRegistrados" exact path="/depositantesRegistrados" component={DepositantesRegistrados} />

          {/*REGISTRO DE EMISORES, EMISIONES Y SERIES POR CEHVAL*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuRegistrarEmisoresEmisionesSeries" exact path="/menuRegistrarEmisoresEmisionesSeries" component={MenuRegistrarEmisoresEmisionesSeries} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="RegisterEmisor" exact path="/solicitudRegistroEmisor" component={SolicitudRegistroEmisor} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="RegisterEmision" exact path="/registroEmisiones" component={RegistroEmisiones} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="RegisterSerie" exact path="/registroSeries" component={RegistroSeries} />

          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuEmisoresEmisionesSeriesAutorizadas" exact path="/menuSeriesEmisionesAutorizadas" component={MenuSeriesEmisionesAutorizadas} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifyAperturaCuentaEmisores" exact path="/emisoresParaAbrirCuentas" component={EmisoresParaAbrirCuentas} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifyEmisionRegistrada" exact path="/emisionesAutorizadas" component={EmisionesAutorizadas} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifySerieRegistrada" exact path="/seriesAutorizadas" component={SeriesAutorizadas} />

          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuEmisionesEmisionesSeriesRechazadas" exact path="/menuSeriesEmisionesRechazadas" component={MenuSeriesEmisionesrRechazadas} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifyEmisorRechazado" exact path="/emisoresRechazados" component={SolicitudesEmisoresRechazados} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifyEmisionRechazada" exact path="/emisionesRechazadas" component={EmisionesRechazadas} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifySerieRechazada" exact path="/seriesRechazadas" component={SeriesRechazadas} />

          {/*REGISTRO DE EMISORES, EMISIONES Y SERIES BCV*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuRegistroEmisoresEmisionesSeriesBCV" exact path="/menuEmisoresEmisionesSeriesBCV" component={MenuEmisoresEmisionesSeriesBCV} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="RegisterEmisionesBCV" exact path="/registroEmisionesBCV" component={RegistroEmisionesBCV} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="RegisterSeriesBCV" exact path="/registroSeriesBCV" component={RegistroSeriesBCV} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="RegisterEmisoresBCV" exact path="/registroEmisoresBCV" component={RegistroEmisoresBCV} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="RegisterEmisoresBCV" exact path="/registrarEmisorBCV" component={RegistrarEmisorBCV} />

          {/*EMISORES, EMISIONES Y SERIES REGISTRADOS*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuEmisoresEmisionesSeriesRegistrados" exact path="/menuEmisoresEmisionesYSeriesRegistradas" component={MenuEmisoresEmisionesYSeriesRegistradas} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazEmisionesRegistradas" exact path="/emisionesRegistradas" component={EmisionesRegistradas} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazSeriesRegistrados" exact path="/seriesRegistradas" component={SeriesRegistradas} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazEmisoresRegistrados" exact path="/emisoresRegistrados" component={EmisoresRegistrados} />

          {/*DESMATERIALIZACION, OPERACIONES CON VALORES*/}
          {/*}<PrivateRoute hasRole="OPERADOR_CEHVAL"  exact path="/verEmisoresParaDesmaterializar" component={VerEmisoresParaDesmaterializar} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" exact path="/verEmisionesParaDesmaterializar" component={VerEmisionesParaDesmaterializar} />*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuDesmaterializacion" exact path="/menuDesmaterializacion" component={MenuDesmaterializacion} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="RegisterSolicitudDesmaterializacion" exact path="/solicitudDesmaterializacion" component={SolicitudDesmaterializacion} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazDesmaterializarValores" exact path="/desmaterializarValores" component={DesmaterializarValores} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifyDesmaterializacionRealizada" exact path="/notificarDesmaterializacion" component={NotificarDesmaterializacion} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifyDesmaterializacionRechazada" exact path="/notificarRechazoDesmaterializacion" component={NotificarRechazoDesmaterializacion} />

          {/*HISTORIAL OPERACIONES TLP, tambien la puede ver el operador_DD y consultor_DI, OPERACIONES CON VALORES*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazHistorialTLP" exact path="/historialOperacionesTLP" component={HistorialOperacionesTLP} />

          {/*PRENDAS Y GARANTIAS, OPERACIONES CON VALORES*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="RegisterSolicitudConstitucionGarantia" exact path="/solicitudConstitucionDeGarantia" component={SolicitudConstitucionDeGarantia} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuSolicitudesConstitucionGarantia" exact path="/menuNotificarSolicitudesConstitucionGarantia" component={MenuNotificarSolicitudesConstitucionGarantia} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifyConstitucionGarantiaAceptadas" exact path="/notificarConstitucionGarantiaAceptadas" component={NotificarConstitucionesDeGarantia} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifyConstitucionGarantiaRechazadas" exact path="/notificarConstitucionGarantiaRechazadas" component={NotificarConstitucionesDeGarantia} />

          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="RegisterSolicitudLiberacionGarantia" exact path="/solicitudLiberacionDeGarantia" component={SolicitudLiberacionDeGarantia} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuSolicitudesLiberacionGarantia" exact path="/menuNotificarSolicitudesLiberacionGarantia" component={MenuNotificarSolicitudesLiberacionGarantia} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifyLiberacionGarantiaAceptadas" exact path="/notificarLiberacionesGarantiaAceptadas" component={NotificarLiberacionesDeGarantia} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifyLiberacionGarantiaRechazadas" exact path="/notificarLiberacionesGarantiaRechazadas" component={NotificarLiberacionesDeGarantia} />

          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazLiberacionesRechazadas" exact path="/liberacionesRechazadas" component={LiberacionesRechazadas} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazConstitucionesRechazadas" exact path="/constitucionesRechazadas" component={ConstitucionesRechazadas} />

          {/*MERCADO PRIMARIO*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="RegisterDisponiblidadFondosMP" exact path="/registrarDisponibilidadFondos" component={RegistrarDisponibilidadFondos} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazConciliacionMP" exact path="/fondosConciliadosYNoConciliados" component={FondosConciliadosYNoConciliados} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazCargarArchivoSubastaMP" exact path="/cargarResultadoSubasta" component={CargarResultadoSubasta} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMontosSubastaMP" exact path="/montosPorClientesYSubasta" component={MontosPorClientesYSubasta} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazInformacionLiquidacionSubastaMP" exact path="/consultarLiquidacionSubastasOperador" component={ConsultarLiquidacionSubastasOperador} />

          {/*MERCADO SECUNDARIO*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="RegisterDisponiblidadFondosMS" exact path="/registrarDisponibilidadFondosMS" component={RegistrarDisponibilidadFondosMS} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazCargarArchivoOperacionMS" exact path="/cargarInformacionOperacionesMS" component={CargarInformacionOperacionesMS} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazInformacionOperacionesMS" exact path="/informacionOperacionMS" component={InformacionOperacionMS} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazConciliarFondosMS" exact path="/conciliarFondos" component={ConciliarFondosMS} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazOperacionesActivasMS" exact path="/consultarOperacionesActivasMS" component={ConsultarOperacionesActivas} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazHistorialDeFondosConciliadosMS" exact path="/historicoDepositosFondosConciliados" component={HistoricoDepositosFondosConciliados} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazFondosNoConciliadosEliminadosMS" exact path="/depositosFondosNoConciliadosEliminados" component={DepositosFondosNoConciliadosEliminados} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazOperacionesEjecutadasMS" exact path="/operacionesEjecutadas" component={OperacionesEjecutadas} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazFondosVirtualesMS" exact path="/consultarFondosVirtuales" component={ConsultarFondosVirtuales} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuFondosVirtualesMS" exact path="/menuFondosVirtuales" component={MenuFondosVirtuales} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazFormularioDevolucionMS" exact path="/formularioDevolucion" component={FormularioDevolucion} />

          {/*RESGUARDO VALORES FISICOS*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuResguardoDepositantesValoresFisicos" exact path="/menuResguardoDepositantesValoresFisicos" component={MenuResguardoDepositantesValoresFisicos} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="RegisterSolicitudDepValoreFisicos" exact path="/solicitarRegistroDepValoresFisicos" component={SolicitarRegistroDepValoresFisicos} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifyDepValoreFisicosAutorizadas" exact path="/solicitudesDepValoresFisicosAutorizadas" component={NotificarDepositantesValoresFisicos} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifyDepValoreFisicosRechazadas" exact path="/solicitudesDepValoresFisicosRechazadas" component={NotificarDepositantesValoresFisicos} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazDepValoresFisicosRegistrados" exact path="/depositantesValoresFisicosRegistrados" component={TablaDepositantesValoresFisicosRegistrados} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazDepValoresFisicosSeleccionado" exact path="/depositanteValFisSeleccionado" component={DepositanteValFisSeleccionado} />

          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuDepositosValoresFisicos" exact path="/menuDepositosValoresFisicos" component={MenuDepositosValoresFisicos} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="RegisterDepositoValorFisico" exact path="/solicitudDepositoValorFisico" component={SolicitudDepositoValorFisico} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifyDepositoValoreFisicosAutorizados" exact path="/notificarSolicitudesDepositoValFisAutorizadas" component={NotificarDepositoValoresFisicos} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="NotifyDepositoValoreFisicosRechazadas" exact path="/notificarSolicitudesDepositoValFisRechazadas" component={NotificarDepositoValoresFisicos} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazValoresFisicosDepositados" exact path="/valoresFisicosDepositados" component={ValoresFisicosDepositados} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazVisualizarValoresFisicos" exact path="/visualizarValorFisico" component={VisualizarValorFisico} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewTablaValoresFisicosRetirados" exact path="/tablaValoresFisicosRetirados" component={TablaValoresFisicosRetirados} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazValorFisicoRetirado" exact path="/visualizarValorFisicoRetirado" component={VisualizarValorFisicoRetirado} />
          

          {/*CUENTAS DE VALORES DE CEHVAL, REQUERIMIENTO SISTEMAS DE INFORMACION*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazValoresTransito" exact path="/cuentaValoresTransito" component={CuentaValoresTransito} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazValoresReserva" exact path="/cuentaValoresReserva" component={CuentaValoresReserva} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazInventarioValores" exact path="/inventarioValores" component={InventarioValores} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazCuentaValoresReporto" exact path="/cuentaValoresReporto" component={CuentaValoresReporto} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazValoresPrendasGarantias" exact path="/cuentaValoresPrendasGarantias" component={CuentaValoresPrendasGarantias} />
          
          {/*OPERACIONES CON VALORES, REQUERIMIENTO SISTEMAS DE INFORMACION*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazPrendasGarantiasLiberadas" exact path="/prendasYGarantiasLiberadas" component={PrendasYGarantiasLiberadas} />

          {/*SISTEMA DE INFORMACION*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazCatalogosCasaBolsa" exact path="/catalogosCasaBolsa" component={CatalogosCasaBolsa} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMovimientosValores" exact path="/movimientosValores" component={MovimientosValores} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazTraspasoValores" exact path="/traspasosValores" component={TraspasosValores} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazDepositosYLiquidacionesFondos" exact path="/depositosYLiquidacionesFondos" component={DepositosYLiquidacionesFondos} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazTenenciaValores" exact path="/tenenciaValores" component={TenenciaValores} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazConsultarSeriesRegistradas" exact path="/consultarSeriesRegistradas" component={ConsultarSeriesRegistradas} />
          
          {/*BITACORAS*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazBitacoraOperaciones" exact path="/bitacoraOperaciones" component={BitacoraOperaciones} />

          {/*CUENTAS DE DEPOSITANTES, solo se ven las cuentas con los valores*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuCuentasDepositantes" exact path="/menuVerCuentasDepositantes" component={MenuVerCuentasDepositantes} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazVerCuentasDepositantesRegistrados" exact path="/verCuentasDepositantes" component={VerCuentasDepositantes} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazCuentasDepositantes" exact path="/cuentasDepositantes" component={CuentasDepositantes} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazValoresDepositantes" exact path="/valoresDepositantes" component={ValoresDepositantes} />

          {/*PAGOS PATRIMONIALES*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazSeriesSinFechasPago" exact path="/agregarFechasPagoInteresesAmortizaciones" component={AgregarFechasPagoInteresesAmortizaciones} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="RegisterSeriesFechasPago" exact path="/formularioAgregarFechas" component={FormularioAgregarFechas} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="RegisterDisponibilidadFondosDerechosPatrimoniales" exact path="/registrarDisponibilidadFondosPDP" component={RegistrarDisponibilidadFondosPDP} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazConciliarFondosDerechosPatrimoniales" exact path="/conciliarFondosPDP" component={ConciliarFondosPDP} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazHistoricoFondosConciliadosPDP" exact path="/consultarHistoricoFondosConciliadosPDP" component={ConsultarHistoricoFondosConciliadosPDP} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazDepositosEliminadosPDP" exact path="/depositosFondosEliminadosPDP" component={DepositosFondosEliminadosPDP} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazBuscarPagosDerechosPatrimoniales" exact path="/buscarPagosPatrimoniales" component={BuscarPagosPatrimoniales} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazInformacionPagosDerechosPatrimoniales" exact path="/formularioPagosEncontrados" component={FormularioPagosEncontrados} />

          {/*GENERACION Y ASIGNACION DE CODIGO ISIN */}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazSolicitudesAsignacionCodigoISIN" path="/solicitudesAsignacionCodIsin" component={SolicitudesAsignacionCodIsin} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewSolicitudSeleccionadaCodISINPorValidar" path="/validacionSolicitudCodIsin" component={ValidacionSolicitudCodIsin} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewNotificarSolicitudesRechazadasISIN" path="/notificarSolicitudesRechazadasISIN" component={NotificarSolicitudesRechazadasISIN} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewSolicitudesCodigosISINAutorizadas" path="/solitudesCodigoISINAutorizadas" component={SolitudesCodigoISINAutorizadas} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewGeneracionOficioCodigosISIN" path="/revisionOficioCodigosISIN" component={RevisionOficioCodigosISIN} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewHistoricoCodigosISINGenerados" path="/historicoCodigosISINGenerados" component={HistoricoCodigosISINGenerados} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInformacionDeHistoricoSeleccionada" path="/historicoVisualizarInfoSeleccionado" component={HistoricoVisualizarInfoSeleccionado} />

          {/*SALVAGUARDAS FINANCIERAS */}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewAsociarOperacionesForzadas" path="/asociarOperacionesForzadas" component={AsociarOperacionesForzadas} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazFondoDeLiquidacion" path="/fondoLiquidacion" component={FondoLiquidacion} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazRetiroDepositoFondoLiquidacion" path="/retiroDepositoFondoLiquidacion" component={RetiroDepositoFondoLiquidacion} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazHistoricoDepositosRetirosfondosLiquidacion" path="/historicoDepositosRetirosFondoLiquidacion" component={HistoricoDepositosRetirosFondoLiquidacion} />

          {/*ACTUALIZACION DE ACTORES*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewMenuActualizacionActores " path="/menuActualizacionActores" component={MenuActualizacionActores} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazDepositantesParaActualizar" path="/depositantesParaActualizar" component={DepositantesParaActualizar} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="UpdateDepositanteJuridico" path="/actualizarDepositanteJuridico" component={ActualizarDepositanteJuridico} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="UpdateDepositanteNatural" path="/actualizarDepositanteIndirectoNatural" component={ActualizarDepositanteIndirectoNatural} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazEmisoresParaActualizar" path="/emisoresParaActualizar" component={EmisoresParaActualizar} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="UpdateEmisor" path="/actualizarEmisores" component={ActualizarEmisores} />

          {/*DEPOSITO Y RETIRO DE VALORES*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazDepositoRetiroValores" path="/depositarRetirarValores" component={DepositarRetirarValores} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazHistoricoDepositosRetirosValores" path="/historicoDepositosRetirosValores" component={HistoricoDepositosRetirosValores} />

          {/*TLP OPERADOR*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuTlPOperadorCehval" path="/menuTLPOperador" component={MenuTLPOperador} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazCuentasCehval" path="/cuentasCehval" component={CuentasCehval} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazCuentasValoresCehval" path="/cuentasValoresCehvalTLP" component={CuentasValoresCehvalTLP} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewDepositantesCehval" path="/depositantesCehval" component={DepositantesCehval} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewCuentasDepositantesCehval" path="/cuentasDepositantesCehval" component={CuentasDepositantesCehval} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewValoresDepositantesCehval" path="/valoresDepositanteCehval" component={ValoresDepositanteCehval} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewFormularioTLPOperador" path="/TLPOperadorCehval" component={TLPOperadorCehval} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewFormularioTLPOperadorReporto" path="/formTLPReporto" component={FormTLPReporto} />

          {/*TARIFICACION*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewActualizarVectorPreciosYTipoCambio" path="/actualizarVectorPrecios" component={ActualizarVectorPrecios} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewGenerarMontoCobrarConceptosNoPeriodicos" path="/generarCobrosNoPeriodicos" component={GenerarCobrosNoPeriodicos} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewRevisarAvisosCobroAutomaticos" path="/revisarAvisosCobroAutomaticos" component={RevisarAvisosCobroAutomaticos} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewAvisoCobroAutSeleccionado" path="/avisoCobroAutSeleccionado" component={AvisoCobroAutSeleccionado} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewDarAccesoCodIsinYSisInformacion" path="/darAcesoISINSistemasInfo" component={DarAccesoISINSistemasInfo} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewRevocarAccesoCodISINYSistInformacion" path="/revocarAccesoISINSistInformacion" component={RevocarAccesoISINSistInformacion} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewNotificarDepFondosAvisoCobro" path="/notificarDepositoFondosAvisoCobro" component={NotificarDepositoFondosAvisoCobro} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="VIewTablaRevisarConciliarAvisosCobro" path="/verTodosAvisosCobroConciliar" component={VerTodosAvisosCobroConciliar} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewAvisoCobroParaConciliar" path="/revisarAvisoCobroParaConciliar" component={RevisarAvisoCobroParaConciliar} />
           
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewGeneracionEstadosCuenta" exact path="/generacionEstadosCuenta" component={GeneracionEstadosCuenta} />
          {/*tambien tienen este permiso emisores y depositantes directos*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewDescargaEnvioEdosCuenta" exact path="/descargaEnvioEdosCuenta" component={DescargaEnvioEdosCuenta} />

          {/*MANTENIMIENTO FACTURAS*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewMenuMantenimientoFacturas" path="/menuMantenimientoFacturas" component={MenuMantenimientoFacturas} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewRegistrarDatosFacturacion" path="/registrarDatosFacturacion" component={RegistrarDatosFacturacion} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewTablaDatosFacturacionRegistrados" path="/tablaDatosFacturacion" component={TablaDatosFacturacion} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewActivarDesactivarMantenimiento" path="/desactivarActivarMantenimiento" component={DesactivarActivarMantenimiento} />

          {/*Impresion*/}
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuImprimirDepositantesRegistrados" exact path="/menuImpresionDepositantesAutorizados" component={MenuImpresionDepositantesAutorizados} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazImprimirDepositantesRegistrados" exact path="/impresionDepositantesRegistrados" component={ImpresionDepositantesRegistrados} />

          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazMenuImprimirSolicitudesDepositantes" exact path="/menuImpresionSolicitudesDepositantes" component={MenuImpresionSolicitudDepositante} />
          <PrivateRoute hasRole="OPERADOR_CEHVAL" hasPermission="ViewInterfazImprimirSolicitudesDepositantes" exact path="/impresionSolicitudDepositante" component={ImpresionSolicitudDepositante} />


          {/*INICIA RUTAS OPERADOR_DD */}

          {/*REGISTRO DEPOSITANTES INDIRECTOS*/}
          <PrivateRoute hasRole="OPERADOR_DD" hasPermission="ViewInterfazMenuRegistrarDepositantesIndirectos" exact path="/registrarClientes" component={RegistrarClientes} />
          <PrivateRoute hasRole="OPERADOR_DD" hasPermission="RegisterDepPersonaNatural" exact path="/registroDepositanteIndirectoNatural" component={RegistroDepositanteIndirectoNatural} />
          <PrivateRoute hasRole="OPERADOR_DD" hasPermission="RegisterDepPersonaJuridica" exact path="/registroDepositanteIndirectoJuridica" component={RegistroDepositanteIndirectoJuridico} />

          {/*VER CUENTAS DEL DEPOSITANTE DIRECTO*/}
          <PrivateRoute hasRole="OPERADOR_DD" hasPermission="ViewInterfazCuentasDepDir" exact path="/misCuentasDD" component={MisCuentasDD} />
          <PrivateRoute hasRole="OPERADOR_DD" hasPermission="ViewInterfazValoresDepDir" exact path="/misValoresDD" component={MisValoresDD} />
          <PrivateRoute hasRole="OPERADOR_DD" hasPermission="ViewInterfazTransferenciasLibresDePagoDepDir" exact path="/transferenciaLibrePagoDD" component={TransferenciaDD} />

          {/*VER CUENTAS DEL CLIENTE*/}
          <PrivateRoute hasRole="OPERADOR_DD" hasPermission="ViewInterfazClientesDepDir" exact path="/misClientesDD" component={MisClientesDD} />
          <PrivateRoute hasRole="OPERADOR_DD" hasPermission="ViewInterfazCuentasClientesDepDir" exact path="/cuentasClienteDD" component={CuentasClienteDD} />
          <PrivateRoute hasRole="OPERADOR_DD" hasPermission="ViewInterfazValoresClientesDepDir" exact path="/valoresClienteDD" component={ValoresClienteDD} />
          <PrivateRoute hasRole="OPERADOR_DD" hasPermission="ViewInterfazTransferenciasLibresDePagoDepInd" exact path="/transferenciaLibrePagoDI" component={TransferenciaClienteDD} />

          {/*TLP*/}
          <PrivateRoute hasRole="OPERADOR_DD" hasPermission="ViewInterfazMenuTLP" exact path="/menuTransferenciaLibreDePago" component={MenuTransferenciaLibrePago} />

          {/*NOTIFICAR DEPOSITO MERCADO PRIMARIO*/}
          <PrivateRoute hasRole="OPERADOR_DD" hasPermission="ViewInterfazNotificarDepositoFondosMP" exact path="/notificarDepositoFondos" component={NotificarDepositoFondos} />
          <PrivateRoute hasRole="OPERADOR_DD" hasPermission="ViewInterfazNotificarDepositoFondosMS" exact path="/notificarDepositoFondosMS" component={NotificarDepositoFondosMS} />

          {/*NOTIFICAR DEPOSITO MERCADO SECUNDARIO*/}
          <PrivateRoute hasRole="OPERADOR_DD" hasPermission="ViewInterfazSubastasLiquidadasOperadorDepDirecto" exact path="/consultarSubastasLiquidadasOperadorDD" component={ConsultarSubastasLiquidadasOperadorDD} />
          <PrivateRoute hasRole="OPERADOR_DD" hasPermission="ViewInterfazOperacionesEjecutadasOperadorDepDirecto" exact path="/consultarOperacionesEjecutadasOperadorDD" component={ConsultarOperacionesEjecutadasOperadorDD} />


          {/*INICIA RUTAS CONSULTOR_DI*/}

          {/*VER CUENTAS DEL DEPOSITANTE INDIRECTO*/}
          <PrivateRoute hasRole="CONSULTOR_DI" hasPermission="ViewInterfazMenuCuentaValoresDepIndirecto" exact path="/cuentaDeValoresDepIndirecto" component={CuentaDeValoresDepIndirecto} />

          {/*INICIA RUTAS CONSULTOR_EMISOR*/}

          {/*VER EMISIONES DE UN EMISOR*/}
          <PrivateRoute hasRole="CONSULTOR_EMISOR" hasPermission="ViewInterfazInventarioEmisionesEmisor" exact path="/inventarioEmisiones" component={InventarioEmsiones} />
          <PrivateRoute hasRole="CONSULTOR_EMISOR" hasPermission="ViewInterfazInventarioSeriesEmisor" exact path="/inventarioSeries" component={InventarioSeries} />

          {/*GENERACION Y ASIGNACION DE CODIGO ISIN */}
          <PrivateRoute hasRole="CONSULTOR_EMISOR" hasPermission="ViewSolicitudGeneraciónyAsignacionCodigosISIN" exact path="/solicitudGeneracionAsignacionCodigosISIN" component={SolicitudGeneracionAsignacionCodigosISIN} />

          {/*PAGOS PATRIMONIALES*/}
          <PrivateRoute hasRole="CONSULTOR_EMISOR" hasPermission="ViewInterfazSeriesAccionesPagosDividendos" exact path="/registrarPagoDividendos" component={RegistrarPagoDividendos} />
          <PrivateRoute hasRole="CONSULTOR_EMISOR" hasPermission="RegisterPagosDividendos" exact path="/formularioRegistroPagosDividendos" component={FormularioRegistroPagosDividendos} />
          <PrivateRoute hasRole="CONSULTOR_EMISOR" hasPermission="RegisterTasaAplicable" exact path="/registrarTasaAplicable" component={RegistrarTasaAplicable} />
          <PrivateRoute hasRole="CONSULTOR_EMISOR" hasPermission="ViewInterfazTablaConfirmacionPagosDerechosPatrimoniales" exact path="/confirmarPagosDerechosPatrimoniales" component={ConfirmarPagosDerechosPartimoniales} />
          <PrivateRoute hasRole="CONSULTOR_EMISOR" hasPermission="RegisterConfirmacionFondosDerechosPatrimoniales" exact path="/confirmacionMontosPagosDerechosPatrimoniales" component={ConfirmacionMontosPagosDerechosPatrimoniales} />
          <PrivateRoute hasRole="CONSULTOR_EMISOR" hasPermission="ViewInterfazTablaAPagosPendientesDeposito" exact path="/pagosPendientesDeposito" component={PagosPendientesDeposito} />
          <PrivateRoute hasRole="CONSULTOR_EMISOR" hasPermission="NotifyPagosPendientesDeposito" exact path="/notificarDepositoFondosPDP" component={NotificarDepositoFondosPDP} />

          {/*INICIA RUTAS AUTORIZADOR_CEHVAL*/}

          {/*REGISTRO DE DEPOSITANTES*/}
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="ViewInterfazMenuSolicitudesDepositantesParaAutorizar" exact path="/menuSolicitudesAutorizadorCehval" component={MenuSolicitudesAutorizadorCehval} />
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="AuthDepositantes" exact path="/solicitudesDepositantesParaAutorizar" component={SolicitudesDepositantesParaAutorizar} />

          {/*REGISTRO DE EMISORES, EMISIONES Y SERIES POR CEHVAL*/}
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="ViewInterfazMenuEmisoresEmisionesSeriesParaAutorizar" exact path="/menuAutorizarEmisoresEmisionesYSeries" component={MenuAutorizarEmisoresEmisionesSeries} />
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="AuthEmisor" exact path="/solicitudesEmisoresParaAutorizar" component={SolicitudesEmisoresParaAutorizar} />
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="AuthEmision" exact path="/solicitudEmisiones" component={SolicitudEmisiones} />
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="AuthSerie" exact path="/solicitudSeries" component={SolicitudSeries} />

          {/*DESMATERIALIZACION*/}
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="AuthSolicitudDesmaterializacion" exact path="/autorizarDesmaterializacion" component={AutorizarDesmaterializacion} />
          
          {/*PRENDAS Y GARANTIAS, OPERACIONES CON VALORES*/}
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="ViewMenuAutorizarConstitucionGarantia" exact path="/tablaSolicitudesConstitucionDeGarantia" component={TablaSolicitudesConstitucionDeGarantia} />
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="AuthConstitucionGarantia" exact path="/autorizarConstitucionDeGarantia" component={AutorizarConstitucionDeGarantia} />

          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="ViewMenuAutorizarLiberacionGarantia" exact path="/tablaSolicitudesLiberacionDeGarantia" component={TablaSolicitudesLiberacionDeGarantia} />
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="AuthLiberacionGarantia" exact path="/autorizarLiberacionDeGarantia" component={AutorizarLiberacionDeGarantia} />
          
          {/*RESGUARDO VALORES FISICOS*/}
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="ViewInterfazDepValoresFisicosPorAutorizar" exact path="/solicitudesResguardoValoresFisicosAutorizar" component={SolicitudesResguardoValoresFisicosAutorizar} />
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="AuthSolicitudDepValoreFisicos" exact path="/autorizarSolicitudDepositanteValoresFisicos" component={AutorizarSolicitudDepositanteValoresFisicos} />

          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="ViewInterfazTablaValoresFisicosPorAutorizar" exact path="/solicitudesDepositoValFisAutorizar" component={SolicitudesDepositoValFisAutorizar} />
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="AuthSolicitudValoreFisicos" exact path="/autorizarSolicitudDepositoValorFisico" component={AutorizarSolicitudDepositoValorFisico} />
          
          {/*MERCADO PRIMARIO*/}
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="NotifyLiquidacionFondosSubastaMP" exact path="/consultarLiquidacionSubastasAutorizador" component={ConsultarLiquidacionSubastasAutorizador} />
          
          {/*MERCADO SECUNDARIO*/}
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="NotifyLiquidacionFondosOperacionesMS" exact path="/consultarOperacionesActivasAutorizadorMS" component={ConsultarOperacionesActivasAutorizador} />

          {/*Generación y Asignación códigos ISIN */}
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="ViewInterfazSolicitudesAsignacionISINPorAutorizar" exact path="/todasSolicitudesCodigoISINPorAutorizar" component={TodasSolicitudesCodigoISINPorAutorizar} />
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="ViewSolicitudSeleccionadaCodISINPorAutorizar" exact path="/autorizarSolicitudCodIsin" component={AutorizarSolicitudCodIsin} />
          
          {/*PAGOS PATRIMONIALES*/}
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="ViewInterfazTablaPagosPorLiquidarPDP" exact path="/pagosPorliquidar" component={PagosPorliquidar} />
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="RegisterPagosPorLiquidarPDP" exact path="/formularioPagosPorLiquidar" component={FormularioPagosPorLiquidar} />

          {/*Tarificación*/}
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="ViewTablaAutorizarAvisosCobroNoPeriodicos" exact path="/solicitudesAutorizarAvisosCobroNoPeriodicos" component={SolicitudesAutorizarAvisosCobroNoPeriodicos} />
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="ViewAutorizarAvisoCobroNoPeriodicos" exact path="/autorizarAvisoCobroNoPeriodicos" component={AutorizarAvisoCobroNoPeriodicos} />
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="ViewAvisosCobroAutomaticosPorAutorizar" exact path="/avisosCobroAutomaticosPorAutorizar" component={AvisosCobroAutomaticosPorAutorizar} />
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL" hasPermission="ViewAutorizarAvisoCobroConceptosAutomáticos" exact path="/autorizarAvisoCobroConceptosAutomaticos" component={AutorizarAvisoCobroConceptosAutomaticos} />
         
          {/*deposito y retiro de valores */}
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL"  exact path="/depositosRetirosValoresPorAutorizar" component={DepositosRetirosValoresPorAutorizar} />
          <PrivateRoute hasRole="AUTORIZADOR_CEHVAL"  exact path="/autorizarDepositosRetirosValores" component={AutorizarDepositosRetirosValores} />


          {/*Inicia rutas FUNCIONARIO_CUMPLIMIENTO*/}
          <PrivateRoute hasRole="FUNCIONARIO_CUMPLIMIENTO" hasPermission="ViewInterfazMenuValidarDepositantes" exact path="/menuSolicitudesFuncionarioCumplimiento" component={MenuSolicitudesFuncionarioCumplimiento} />
          <PrivateRoute hasRole="FUNCIONARIO_CUMPLIMIENTO" hasPermission="ValidateDepositantes" exact path="/solicitudesDepositantesParaValidar" component={SolicitudesDepositantesParaValidar} />

          {/*Ver informacion de depositantes, emisores,emisiones, series y desmaterializacion*/}
          <PrivateRoute exact path="/verInfoEmisor" component={VerInfoEmisor} />
          <PrivateRoute exact path="/verInfoDepDirectoPerJuridicoDoc" component={VerInfoDepositanteDirectoPerJuridicaDoc} />
          <PrivateRoute exact path="/verInfoDepIndPersonaNaturalDoc" component={VerInfoDepIndPersonaNaturalDoc} />
          <PrivateRoute exact path="/verInfoEmision" component={VerInfoEmision} />
          <PrivateRoute exact path="/verInfoSerie" component={VerInfoSerie} />
          <PrivateRoute exact path="/verInfoDesmaterializacion" component={VerInfoDesmaterializacion} />

          {/*Inicia ruta no autorizado*/}
          <PrivateRoute exact path="/noAutorizado" component={NoAutorizado} />

         
          

          {/*Página error 404*/}
          <Route path="*" component={NotFound} />
        </Switch>
      </UserProvider>
    </>
  );
}