import { useMemo } from "react";

export default function useHistoricoDepositosRetirosFondosLiquidacion() {
  const columns = useMemo(
    () => [
      {
        Header: "Fecha de registro de movimiento",
        accessor: "fechaRegistro"
      },
      {
        Header: "Folio",
        accessor: "folioBch"
      },
      {
        Header: "Fecha de depósito o retiro",
        accessor: "fechaDepositoStr"
      },
      {
        Header: "Monto",
        accessor: "montoStr"
      },
      {
        Header: "Moneda",
        accessor: "monedaStr"
      },
      {
        Header: "Tipo",
        accessor: "tipoStr"
      },
      {
        Header: "Código de depositante",
        accessor: "codigoDepositante"
      },
      {
        Header: "Nombre de depositante",
        accessor: "nombreDepositante"
      },
    ],
    []
  );

  return columns;
}
