import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';



//Componentes generales
import BannerTitulo from '../../../../ComponentesGenerales/Bannertitulo';
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";
import ModalConfirmAction from "../../../../ComponentesGenerales/ModalConfirmAction";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";

//APIs 
import { consultarCuentaReceptor } from '../../../../api/transferenciaLibreDePago/consultarCuentaReceptor';
import { TLPOperacionesReporto } from '../../../../api/transferenciaLibreDePago/TLPOperacionesReporto';


export default function FormTLPReporto(history) {

    const historyReact = useHistory();

    //Id de la operacion
    const [idValor, setIdValor] = useState();


    //Origin account data
    const [codigoPropietario, setCodigoPropietario] = useState();
    const [nombrePropietario, setNombrePropietario] = useState();
    const [numeroCuentaReporto, setNumeroCuentaReporto] = useState();
    const [moneda, setMoneda] = useState();
    const [isin, setIsin] = useState();

    //Destination account data
    const [numeroCuentaDestino, setNumeroCuentaDestino] = useState();
    const [codigoDepReceptor, setCodigoDepReceptor] = useState();
    const [nombreReceptor, setNombreDepReceptor] = useState();
    const [codigoDepDirecto, setCodigoDepDirecto] = useState();
    const [nombreDepDirecto, setNombreDepDirecto] = useState();
    const [monedaReceptor, setMonedaReceptor] = useState();


    //Cantidad a transferir
    const [cantidadTransferir, setCantidadTransferir] = useState();
    const [cantidadTransferirStr, setCantidadTransferirStr] = useState();

    //Type of participant that will receive the values
    const [isReceptorDI, setIsReceptorDI] = useState();
    const [isReceptorDD, setIsReceptorDD] = useState();


    //VAlid account to make transfer
    const [isValidAccount, setIsValidAccount] = useState(false);



    //loading states
    const [isLoading, setIsLoading] = useState();


    //Transfer success
    const [transferSuccess, setTransferSuccess] = useState();
    const [transferNotSuccessful, setTransferNotSuccessful] = useState();

    //Error handler
    const [isNotValidAccount, setIsNotValidAccount] = useState();
    const [isNotValidCurrency, setIsNotValidCurrency] = useState();
    const [erroreshttp, setErroreshttp] = useState()


    useEffect(() => {
        const data = history.location.state;
        if (data === undefined) {
            window.location.replace("/cuentasValoresCehvalTLP");

        } else {
            //Formatear numero de la cantidad de valores a transferir para que usuario vea número formateado
            const cantidadValores = data.rowData.cantidadValores;
            const stringCantidadValores = cantidadValores.toLocaleString();
            setCantidadTransferir(cantidadValores);
            setCantidadTransferirStr(stringCantidadValores);
            //Datos cuenta de origen
            setCodigoPropietario(data.rowData.codigoDepositantePropietario);
            setNombrePropietario(data.rowData.nombreDepositantePropietario);
            setNumeroCuentaReporto(data.rowData.codigoDepositante);
            setIsin(data.rowData.isinSerie);
            setMoneda(data.rowData.monedaSerie);
            setIdValor(data.rowData.idValor);
        }
    }, []);


    const handleSubmitCheckDestinationAccount = async (e) => {
        e.preventDefault();
        try {

            setIsLoading(true);
            const response = await consultarCuentaReceptor({ numeroCuentaValores: numeroCuentaDestino });
            setIsLoading(false);
            //console.log(response)

            if (isNaN(response.error) === false) {
                setErroreshttp(response);

            } else if (response.data) {
                //console.log(response.data.length)

                //Si en la respuesa solo hay un objeto quiere decir que la cuenta de destino es de un DepositanteDirecto
                if (response.data.length === 1) {
                    setIsReceptorDI(false);
                    setIsNotValidAccount(false);
                    filterTypeDepositante(response.data[0]);
                    setIsReceptorDD(true);


                    //Si en la respuesa solo hay un objeto quiere decir que la cuenta de destino es de un DepositanteIndirecto
                } else if (response.data.length > 1) {
                    setIsReceptorDD(false);
                    setIsNotValidAccount(false);
                    filterTypeDepositante(response.data[0]);
                    setIsReceptorDI(true);

                    //Si en la respuesta la lista esta vacía quiere decir que la cuenta no existe
                } else if (response.data.length === 0) {
                    setIsReceptorDI(false);
                    setIsReceptorDD(false);
                    setIsNotValidAccount(true);
                }
            }

        } catch (error) {
            //console.log(error);
        }
    }


    //Check if destination account bellongs to a Depositante Directo or Depositante Indirecto 
    const filterTypeDepositante = (depositanteReceptor) => {

        const codigoDepositanteReceptor = depositanteReceptor.cehvalDepositanteCodigoCehval;
        const nombreDepositanteReceptor = depositanteReceptor.cehvalDepositanteNombreCompleto;
        const codigoDepositanteCasaBolsa = depositanteReceptor.cehvalDepositanteCodigoDepositanteCB;
        const nombreDepositanteCasaBolsa = depositanteReceptor.cehvalDepositanteNombreDepositanteCB;
        const cuentasValReceptor = depositanteReceptor.cehvalCuentaValCollection;


        //Es depositante directo
        if (codigoDepositanteReceptor !== codigoDepositanteCasaBolsa) {
            //console.log("es depositante indirecto")
            setCodigoDepReceptor(codigoDepositanteReceptor);
            setNombreDepReceptor(nombreDepositanteReceptor);
            setCodigoDepDirecto(codigoDepositanteCasaBolsa);
            setNombreDepDirecto(nombreDepositanteCasaBolsa);
            checkTypeCurrency(numeroCuentaDestino, cuentasValReceptor);

            //Es depositante indirecto
        } else {
            //console.log("es directo")
            setCodigoDepReceptor(codigoDepositanteReceptor);
            setNombreDepReceptor(nombreDepositanteReceptor);
            checkTypeCurrency(numeroCuentaDestino, cuentasValReceptor);

        }
    }


    //Check whats the type of both currencies and if they dont match will show a message
    const checkTypeCurrency = (numeroCuentaDestino, cuentasReceptor) => {

        const filterTypeCurency = cuentasReceptor.filter(word => word.cehvalCuentaValNum === numeroCuentaDestino);
        const accountVal = filterTypeCurency[0];

        if (moneda === accountVal.catMonedaCatMonedaId.catMonedaIsoCod) {
            ////console.log("es la misma moneda")
            setMonedaReceptor(accountVal.catMonedaCatMonedaId.catMonedaIsoCod);
            setIsNotValidCurrency(false);
            setIsValidAccount(true);
        } else {
            ////console.log("no es la misma moneda")
            setMonedaReceptor(accountVal.catMonedaCatMonedaId.catMonedaIsoCod);
            setIsNotValidCurrency(true);
            setIsValidAccount(false);
        }
    }



    //If user delete one of the characters of the destination account the section will be cleaned and will hide the section
    const handleCheckKeyDown = (e) => {
        if (e.keyCode === 8) {
            setNumeroCuentaDestino(e.target.value);
            setCodigoDepReceptor("");
            setNombreDepReceptor("");
            setCodigoDepDirecto("");
            setNombreDepDirecto("");
            setMonedaReceptor("");
            setIsReceptorDI(false);
            setIsReceptorDD(false);
            setIsValidAccount(false);
        }
    }


    //Make the Transfer
    const submitTLP = async (e) => {
        try {
            e.preventDefault();
            //console.log("hacer transferencia")

            const objTLP = {
                cuentaOrigen: numeroCuentaReporto,
                cantidadInstrumentos: cantidadTransferir,
                cuentaDestino: numeroCuentaDestino,
                idValor: idValor,
                codigoDepositantePropietario: codigoPropietario,

            }
            //console.log(objTLP)

            setIsLoading(true);
            const response = await TLPOperacionesReporto(objTLP);
            setIsLoading(false);
            //console.log(response)

            if (isNaN(response.error) === false) {
                setErroreshttp(response);

            } else if (response.data.isSuccessful === false) {
                setTransferNotSuccessful(response.data.mensaje);

            } else if (response.data.isSuccessful === true) {
                setTransferSuccess(response.data.mensaje);

            }


        } catch (error) {
            //console.log(error);
        }
    }

    const goBack = () => {
        historyReact.goBack();
    }

    return (
        <>

            {transferSuccess &&
                <ComponenteModal mostrarModal={transferSuccess} cerrarModal={goBack} titulo={transferSuccess} textoBoton="Entendido" ></ComponenteModal>
            }
            {transferNotSuccessful &&
                <ComponenteModal mostrarModal={transferNotSuccessful} cerrarModal={() => { setTransferNotSuccessful(false) }} titulo={transferNotSuccessful} textoBoton="Entendido" ></ComponenteModal>
            }
            {isLoading &&
                <PantallaCargando />
            }
            {erroreshttp &&
                <ManejadorErrores />
            }
            <div className="fluid-container">
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Traspaso libre de pago"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded p-3">
                                            {/*Section ORIGIN ACCOUNT */}
                                            <section>
                                                <form >
                                                    <div className="row">
                                                        <h5 className='font-acumin-variable text-start text-royal-blue fw-bolder my-3 px-3'>Origen</h5>
                                                        <>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código del propietario" value={codigoPropietario} className="form-control text-uppercase font-acumin-variable" name="codigoDepTransferente" type="text" placeholder="Código del depositante" required disabled />
                                                                    <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoDepTransferente">Código propietario</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Nombre del propietario" value={nombrePropietario} className="form-control text-uppercase font-acumin-variable" name="nombreDepTransferente" type="text" placeholder="Nombre del propietario" required disabled />
                                                                    <label className="text-start font-acumin-variable text-royal-blue" htmlFor="nombreDepTransferente">Nombre del propietario</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Número de cuenta de origen" value={numeroCuentaReporto} className="form-control text-uppercase font-acumin-variable" name="numeroCuentaOrigen" type="text" placeholder="Número de cuenta de origen" required disabled />
                                                                    <label className="text-start font-acumin-variable text-royal-blue" htmlFor="numeroCuentaOrigen">Número de cuenta de valores en reporto</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Moneda" className="form-control text-uppercase font-acumin-variable" value={moneda} name="monedaTransferente" type="text" placeholder="Código ISIN" required disabled />
                                                                    <label className="text-start font-acumin-variable text-royal-blue" htmlFor="monedaTransferente">Moneda</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código ISIN" className="form-control text-uppercase font-acumin-variable" value={isin} name="codigoISIN" type="text" placeholder="Código ISIN" required disabled />
                                                                    <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoISIN">Código ISIN</label>
                                                                </div>
                                                            </div>
                                                        </>
                                                    </div>
                                                </form>
                                            </section>
                                            {/*CHECK DESTINATION ACCOUNT*/}
                                            <section className="mt-3">
                                                <form onSubmit={handleSubmitCheckDestinationAccount}>
                                                    <div className="row">
                                                        <div className="col-12 col-md-6 mb-3">
                                                            <div className="form-floating ">
                                                                <input title="Número de cuenta de destino" className="form-control text-uppercase font-acumin-variable" onChange={(e) => setNumeroCuentaDestino(e.target.value)} onKeyDown={(e) => handleCheckKeyDown(e)} maxLength={20} name="numeroCuentaDestino" type="text" placeholder="Número de cuenta de destino" required />
                                                                <label className="text-start font-acumin-variable text-royal-blue" htmlFor="numeroCuentaDestino">Ingrese la cuenta de destino</label>
                                                                {isNotValidAccount &&
                                                                    <span className="text-danger font-acumin-variable">La cuenta de destino no es válida</span>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mb-5">
                                                            <button type="submit" className="btn background-royal-blue text-white font-acumin-variable bg-hover-gold" >Consultar cuenta</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </section>

                                            {/*DESTINATION ACCOUNT DATA*/}
                                            <section >
                                                <div className="row">
                                                    {/*If receptor is a Depositante Directo */}
                                                    {isReceptorDD &&
                                                        <>
                                                            <h5 className='font-acumin-variable  text-start text-royal-blue fw-bolder my-3 px-3'>Receptor</h5>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Moneda de la cuenta de destino" className="form-control text-uppercase font-acumin-variable" value={monedaReceptor} name="idMonedaDestino" type="text" placeholder="Moneda cuenta destino" required disabled />
                                                                    <label className="text-start font-acumin-variable text-royal-blue" htmlFor="idMonedaDestino">Moneda</label>
                                                                </div>
                                                                {isNotValidCurrency &&
                                                                    <span className="text-danger font-acumin-variable">Las cuentas deben tener el mismo tipo de moneda</span>
                                                                }
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código del depositante" value={codigoDepReceptor} className="form-control text-uppercase font-acumin-variable" name="codigoDepReceptor" type="text" placeholder="Nombre del cliente (Depositante Indiectoa)" required disabled />
                                                                    <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoDepReceptor">Código del depositante directo</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Nombre del depositante" value={nombreReceptor} className="form-control text-uppercase font-acumin-variable" name="nombreDepTransferente" type="text" placeholder="Nombre del depositante" required disabled />
                                                                    <label className="text-start font-acumin-variable text-royal-blue" htmlFor="nombreDepTransferente">Nombre del depositante directo</label>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    {/*If receptor is a Depositante Indirecto */}
                                                    {isReceptorDI &&
                                                        <>
                                                            <h5 className='font-acumin-variable  text-start text-royal-blue fw-bolder my-3 px-3'>Receptor</h5>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Moneda de la cuenta de destino" className="form-control text-uppercase font-acumin-variable" value={monedaReceptor} name="idMonedaDestino" type="text" placeholder="Moneda cuenta destino" required disabled />
                                                                    <label className="text-start font-acumin-variable text-royal-blue" htmlFor="idMonedaDestino">Moneda</label>
                                                                </div>
                                                                {isNotValidCurrency &&
                                                                    <span className="text-danger font-acumin-variable">La moneda de destino es diferente a la de origen</span>
                                                                }
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código del depositante indirecto" value={codigoDepReceptor} className="form-control text-uppercase font-acumin-variable" name="codigoDepReceptor" type="text" placeholder="Nombre del cliente (Depositante Indiecto)" required disabled />
                                                                    <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoDepReceptor">Código del depositante indirecto</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Nombre del depositante indirecto" value={nombreReceptor} className="form-control text-uppercase font-acumin-variable" name="nombreDepTransferente" type="text" placeholder="Nombre del depositante" required disabled />
                                                                    <label className="text-start font-acumin-variable text-royal-blue" htmlFor="nombreDepTransferente">Nombre del depositante indirecto</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código depositante directo" value={codigoDepDirecto} className="form-control text-uppercase font-acumin-variable" name="codigoDepDirecto" type="text" placeholder="Código del depositante directo)" required disabled />
                                                                    <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoDepDirecto">Código del depositante directo</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Nombre depositante directo" value={nombreDepDirecto} className="form-control text-uppercase font-acumin-variable" name="nombreDepDirecto" type="text" placeholder="Nombre del depositante directo" required disabled />
                                                                    <label className="text-start font-acumin-variable text-royal-blue" htmlFor="nombreDepDirecto">Nombre del depositante directo</label>
                                                                </div>
                                                            </div>

                                                        </>
                                                    }
                                                </div>
                                            </section>
                                            {isValidAccount &&
                                                <section>
                                                    <div className="row">
                                                        <h5 className='font-acumin-variable text-start text-royal-blue fw-bolder my-3 px-3'>Cantidad de valores a transferir</h5>
                                                        <div className="col-12 col-md-6 mb-3">
                                                            <div className="form-floating ">
                                                                <input title="Cantidad a transferir" value={cantidadTransferirStr} className="form-control text-uppercase font-acumin-variable" name="cantidadTransferir" type="text" placeholder="Monto del trapaso" required disabled />
                                                                <label className="text-start font-acumin-variable text-royal-blue" htmlFor="cantidadTransferir">Cantidad a transferir</label>
                                                            </div>
                                                        </div>
                                                        {/*First button to confirm transaction */}
                                                        <div className="col-12 col-md-12 mb-3 mt-5  d-flex justify-content-center">
                                                            <div className="form-floating ">
                                                                <button type="button" data-bs-target="#confirmarTransferencia" data-bs-toggle="modal" className="btn background-royal-blue text-white px-5 py-2 fs-18 bg-hover-gold font-acumin-variable" >Confirmar</button>
                                                            </div>
                                                        </div>
                                                        <ModalConfirmAction idModal="confirmarTransferencia" confirmFunction={submitTLP} textModal="Esta operación es irreversible ¿Está seguro que desea continuar?" />
                                                    </div>
                                                </section>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>




    )
}