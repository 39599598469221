import { instance } from '../apiGlobal';


export async function traerMonedas(obj) {
    try {
      const response = await instance.get(`/cat/monedas`, {
        headers: {
          'Content-Type': 'application/json'          
        }
      })
      return response.data;
    } catch (error) {
      return error
    }
  }
  