import React from "react";

import { FormContext } from "./form_context";

export default function RegistroDI5SinDocumentos(props) {
    const [, setValue] = React.useContext(FormContext);
    const [expedienteCompleto2, setExpedienteCompleto2] = React.useState()
    const [renderizar, setRenderizar] = React.useState()
    const [contadorDocumentosFuncionario, setContadorDocumentosFuncionario] = React.useState(0)

    React.useEffect(() => {
        if (props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsEscrSoc && props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsPoderAdm && props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsCopiaRtn &&
            props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsCopiaDni && props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsCopiaRtnAut && props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsConstanciaExoVig
            && props.info.cehvalDepositanteContratoDepositante) {

            setExpedienteCompleto2(true)
            setRenderizar(true)
        } else {

            setRenderizar(true)
        }

        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 1 };
            return { ...prev, datos };
        });
    }, [])

    const guardarDocumento = (name) => {


        if (document.getElementById(name).checked) {
            //  setErrorSinPermisos(false)
            setContadorDocumentosFuncionario(contadorDocumentosFuncionario + 1)
            //arregloPivote.push(parseInt(numero, 10))            
            setValue((prev) => {
                const datos = { ...prev.datos, [name]: 1 };
                return { ...prev, datos };
            });
        } else {
            setValue((prev) => {
                const datos = { ...prev.datos, [name]: 0 };
                return { ...prev, datos };
            });
            setContadorDocumentosFuncionario(contadorDocumentosFuncionario + -1)
            //  arregloPivote = arregloPermisos.filter((item) => item !== parseInt(numero, 10))
        }
        ////console.log(arregloPivote)
    }



    React.useEffect(() => {
        if (contadorDocumentosFuncionario === 2) {
            setValue((prev) => {
                const datos = { ...prev.datos, ["errorDocumentacionIncompletaFuncionario"]: false };
                return { ...prev, datos };
            });
        } else {
            setValue((prev) => {
                const datos = { ...prev.datos, ["errorDocumentacionIncompletaFuncionario"]: true };
                return { ...prev, datos };
            });
        }

    }, [contadorDocumentosFuncionario])




    return (
        <>
            {renderizar &&
                <div className="card-body">
                    <h5 className="textoTituloFormulario" >V. Firmas autorizadas</h5>
                    {
                        props.info.cehvalFirmaAutCollection.map((item, index) => (
                            <>
                                {item.tipoFirma &&

                                    <div className="row mb-5 ">
                                        <div className="col-md-6">

                                            {props.info.cehvalDepositanteCatTipDepId.nombre === "DIRECTO" ?
                                                <>
                                                    {item.usuarioAdmin === 1 ?
                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">{index + 1}. Usuario administrador </label>

                                                        :
                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">{index + 1}. Usuario operador</label>

                                                    }
                                                </>
                                                :
                                                <>
                                                    <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">{index + 1}. Usuario operador</label>
                                                </>
                                            }
                                            <input disabled type="text" value={item.nombre} className="form-control border  " required />
                                        </div>
                                        {item.estatus === 0 && <div className="col-md-6">
                                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Estatus firma</label>
                                            <input disabled type="text" value={"No firmó"} className="form-control  border  " required />
                                        </div>}

                                        {item.estatus === 1 && <div className="col-md-6">
                                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Estatus firma</label>
                                            <input disabled type="text" value={"Sí firmó"} className="form-control  border  " required />
                                        </div>}



                                        <div className="col-md-6">
                                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Cargo</label>
                                            <input disabled type="text" value={item.cargo} className="form-control  border  " required />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Tipo firma</label>
                                            <input disabled type="text" value={item.tipoFirma} className="form-control  border  " required />
                                        </div>
                                    </div>



                                }

                            </>
                        ))
                    }


                    <br></br>
                    <div className="row mb-3">
                        <div className=" col-md-6">
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Lugar y fecha de registro</label>
                            <input disabled type="text" value={props.info.cehvalDepositanteLugarFecha} className="form-control  border  r" id="fechaRegistro" name="fechaRegistro" required />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Casa de bolsa intermediaria</label>
                            <input disabled type="text" value={props.info.cehvalDepositantePersonaIcbiId.cehvalPersonaJurNombre} className="form-control  border" required />
                        </div>
                    </div>

                    {(props.info.cehvalDepositanteFolio).indexOf("DDPJ") >= 0 ?
                        <h5 className="textoTituloFormulario my-5" >Documentación soporte instituciones supervisadas</h5>
                        :
                        <h5 className="textoTituloFormulario my-5" >Documentación soporte instituciones supervisadas y no supervisadas</h5>
                    }

                    <div className="row mb-3">
                        <div className="col-12 col-md-4 d-flex">
                            <div className="form-check">
                                <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsCopiaEscrSoc">
                                    Copia escritura social
                                </label>

                                {props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsEscrSoc === 1 ?
                                    <input className="form-check-input" disabled type="checkbox" checked />
                                    :
                                    <input className="form-check-input" disabled type="checkbox" />
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                            <div className="form-check">
                                <label className="form-check-label" htmlFor="">
                                    Copia poder de administración
                                </label>

                                {props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsPoderAdm === 1 ?
                                    <input className="form-check-input" disabled type="checkbox" checked />
                                    :
                                    <input className="form-check-input" disabled type="checkbox" />
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                            <div className="form-check">
                                <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsCopiaRtn">
                                    copia RTN
                                </label>
                                {props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsCopiaRtn === 1 ?
                                    <input className="form-check-input" disabled type="checkbox" checked />
                                    :
                                    <input className="form-check-input" disabled type="checkbox" />
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3 ">
                        <div className="col-12 col-md-4 d-flex">
                            <div className="form-check">
                                <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsCopiaDni">
                                    Copia DNI autorizados
                                </label>
                                {props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsCopiaDni === 1 ?
                                    <input className="form-check-input" disabled type="checkbox" checked />
                                    :
                                    <input className="form-check-input" disabled type="checkbox" />
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                            <div className="form-check">
                                <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsCopiaRtnAut">
                                    Copia RTN de autorizados
                                </label>
                                {props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsCopiaRtnAut === 1 ?
                                    <input className="form-check-input" disabled type="checkbox" checked />
                                    :
                                    <input className="form-check-input" disabled type="checkbox" />
                                }
                            </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                            <div className="form-check">
                                <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsConstanciaExoVig">
                                    Constancia de exoneración vigente
                                </label>
                                {props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsConstanciaExoVig === 1 ?
                                    <input className="form-check-input" disabled type="checkbox" checked />
                                    :
                                    <input className="form-check-input" disabled type="checkbox" />
                                }
                            </div>
                        </div>

                    </div>

                    {props.info.tipoParticipante === 1 ?
                        <>
                            <div className="row mb-3 ">
                                <div className="col-12 col-md-4 d-flex">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsFirmaAutExt">
                                            Contrato de depositante
                                        </label>
                                        {props.info.cehvalDepositanteContratoDepositante === 1 ?
                                            <input className="form-check-input" disabled type="checkbox" checked />
                                            :
                                            <input className="form-check-input" disabled type="checkbox" />
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 d-flex">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsNotRemiProgCump">
                                            Nota remisión programa de cumplimiento
                                        </label>
                                        {props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsNotRemiProgCump === 1 ?
                                            <input className="form-check-input" disabled type="checkbox" checked />
                                            :
                                            <input className="form-check-input" disabled type="checkbox" />
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 d-flex">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsCopiaNomFunCump">
                                            Copia nombramiento funcionario de cumplimiento
                                        </label>
                                        {props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsCopNomFunCump === 1 ?
                                            <input className="form-check-input" disabled type="checkbox" checked />
                                            :
                                            <input className="form-check-input" disabled type="checkbox" />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3 ">
                                <div className="col-12 col-md-4 d-flex">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsEntRegul">
                                            Ente regulador
                                        </label>
                                        {props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsEntRegul === 1 ?
                                            <input className="form-check-input" disabled type="checkbox" checked />
                                            :
                                            <input className="form-check-input" disabled type="checkbox" />
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 d-flex">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsFirmaAutExt">
                                            Firma auditora externa
                                        </label>
                                        {props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsFirmaAutExt === 1 ?
                                            <input className="form-check-input" disabled type="checkbox" checked />
                                            :
                                            <input className="form-check-input" disabled type="checkbox" />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3 mt-5">
                                <div className="col-3 col-md-5 d-flex ">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsExpComp">
                                            Documentación completa
                                        </label>
                                        {props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsExpComp === 1 ?
                                            <input className="form-check-input" disabled type="checkbox" checked />
                                            :
                                            <input className="form-check-input" disabled type="checkbox" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                        :

                        <>
                            <div className="row mb-3 ">
                                <div className="col-12 col-md-4 d-flex">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsFirmaAutExt">
                                            Contrato de depositante
                                        </label>
                                        {props.info.cehvalDepositanteContratoDepositante === 1 ?
                                            <input className="form-check-input" disabled type="checkbox" checked />
                                            :
                                            <input className="form-check-input" disabled type="checkbox" />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3 mt-5">
                                <div className="col-3 col-md-5 d-flex ">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsExpComp">
                                            Documentación completa
                                        </label>
                                        {expedienteCompleto2 ?
                                            <input className="form-check-input" disabled type="checkbox" checked />
                                            :
                                            <input className="form-check-input" disabled type="checkbox" />
                                        }
                                    </div>
                                </div>
                            </div>

                            {(props.info.cehvalDepositanteFolio).indexOf("DIPJ") >= 0 &&
                                <h5 className="textoTituloFormulario my-5" >Documentación soporte instituciones supervisadas</h5>

                            }

                            <div className="row mb-3 ">
                                <div className="col-12 col-md-4 d-flex">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsNotRemiProgCump">
                                            Nota remisión programa de cumplimiento
                                        </label>
                                        {props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsNotRemiProgCump === 1 ?
                                            <input className="form-check-input" disabled type="checkbox" checked />
                                            :
                                            <input className="form-check-input" disabled type="checkbox" />
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 d-flex">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsCopiaNomFunCump">
                                            Copia nombramiento funcionario de cumplimiento
                                        </label>
                                        {props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsCopNomFunCump === 1 ?
                                            <input className="form-check-input" disabled type="checkbox" checked />
                                            :
                                            <input className="form-check-input" disabled type="checkbox" />
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 d-flex">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsEntRegul">
                                            Ente regulador
                                        </label>
                                        {props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsEntRegul === 1 ?
                                            <input className="form-check-input" disabled type="checkbox" checked />
                                            :
                                            <input className="form-check-input" disabled type="checkbox" />
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3 ">
                                <div className="col-12 col-md-4 d-flex">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsFirmaAutExt">
                                            Firma auditora externa
                                        </label>
                                        {props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsFirmaAutExt === 1 ?
                                            <input className="form-check-input" disabled type="checkbox" checked />
                                            :
                                            <input className="form-check-input" disabled type="checkbox" />
                                        }
                                    </div>
                                </div>


                            </div>

                            <div className="row mb-3 mt-5">
                                <div className="col-3 col-md-5 d-flex ">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsExpComp">
                                            Documentación completa
                                        </label>
                                        {props.info.cehvalDepositanteDocumentoSoporNsId.cehvalDocumentoSoporNsExpComp === 1 ?
                                            <input className="form-check-input" disabled type="checkbox" checked />
                                            :
                                            <input className="form-check-input" disabled type="checkbox" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }



                    <h5 className="textoTituloFormulario my-5" >Unidad de cumplimiento</h5>

                    {props.info.cehvalDepositanteEstatus === 2 ?
                        <div className="row mb-3 ">
                            <div className="col-md-4 mb-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumento(e.target.name)} id="revisionListaOfac" name="revisionListaOfac" />
                                    <label className="form-check-label" htmlFor="revisionListaOfac">
                                        Revisión en lista OFAC
                                    </label>
                                </div>
                            </div>

                            {props.info.cehvalDepositanteCatTipDepId.nombre === "DIRECTO" ?
                                <div className="col-md-4 mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumento(e.target.name)} id="debidaDiligenciaCEHVAL" name="debidaDiligenciaCEHVAL" />
                                        <label className="form-check-label" htmlFor="debidaDiligenciaCEHVAL">
                                            Debida diligencia realizada por CEHVAL
                                        </label>
                                    </div>
                                </div>
                                :
                                <div className="col-md-4 mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumento(e.target.name)} id="certificacionDebidaDiligenciaRecibida" name="certificacionDebidaDiligenciaRecibida" />
                                        <label className="form-check-label" htmlFor="certificacionDebidaDiligenciaRecibida">
                                            Certificación de debida diligencia recibida
                                        </label>
                                    </div>
                                </div>

                            }


                        </div>
                        :

                        <div className="row mb-3 ">
                            <div className="col-12 col-md-4 d-flex">
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsFirmaAutExt">
                                        Revisión en lista OFAC
                                    </label>
                                    {props.info.cehvalDepositanteRevisionListaOfac === 1 ?
                                        <input className="form-check-input" disabled type="checkbox" checked />
                                        :
                                        <input className="form-check-input" disabled type="checkbox" />
                                    }
                                </div>
                            </div>

                            {props.info.cehvalDepositanteCatTipDepId.nombre === "DIRECTO" ?
                                <div className="col-12 col-md-4 d-flex">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsFirmaAutExt">
                                            Debida diligencia realizada por CEHVAL
                                        </label>
                                        {props.info.cehvalDepositanteDebidaDiligenciaCehval === 1 ?
                                            <input className="form-check-input" disabled type="checkbox" checked />
                                            :
                                            <input className="form-check-input" disabled type="checkbox" />
                                        }
                                    </div>
                                </div>
                                :
                                <div className="col-12 col-md-4 d-flex">
                                    <div className="form-check">
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsFirmaAutExt">
                                            Certificación de debida diligencia recibida
                                        </label>
                                        {props.info.cehvalDepositanteCertificacionDebidaDiligencia === 1 ?
                                            <input className="form-check-input" disabled type="checkbox" checked />
                                            :
                                            <input className="form-check-input" disabled type="checkbox" />
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }






                    {props.banderaDescripcion === 1 &&
                        <div className="row mb-3">
                            <div className="col-md-12">
                                <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Motivo del rechazo</label>
                                <textarea disabled type="text" value={props.info.cehvalDepositanteDescripcion} className="form-control" id="origenRecursos" name="origenRecursos" required style={{ height: "7rem" }} />
                            </div>
                        </div>
                    }


                </div>}



        </>
    );
}

