import React, { useState } from 'react';

export const FormContext = React.createContext();

export function FormProvider({ children }) {
  const [formValue, setFormValue] = useState({
    datos: {
      rtn:"",
      codigoDepositante: "",
      fechaAprobacion: "",
      fechaUltimaModificacion: "",
      primerNombre: "",
      segundoNombre: "",
      primerApellido: "",
      segundoApellido: "",
      apellidoCasada: "",
      tipoIdentidad: "",
      numeroIdentificacion: "",
      estadoCivil: "",
      genero: "",
      nacionalidad: "",
      fechaNacimiento: "",
      lugarNacimiento: "",
      actividadEconomica: "",
      telefonoResidencia: "",
      telefonoCelular: "",
      empresa: "",
      puesto: "",
      tiempoPuesto: "",
      telefonoLaboral: "",
      origenRecursos: "",
     
      pais: "",
      departamento: "",
      municipio: "",
      ciudad: "",
      colonia: "",
      calle: "",
      edificio: "",
      codigoPostal: "",
      departamentoManual:"",
      municipioManual:"",
      ciudadManual:"",
      
      numeroCuentaN: "",
      bancoN:"",
      monedaN: 1,
      tipoCuentaN: "",
      
      numeroCuentaE: "",
      bancoE:"",
      monedaE: 2,
      tipoCuentaE: "",


      nombreCompleto1: "",
      segundoNombre1:"",
      primerApellido1:"",
      segundoApellido1:"",
      correo1: "",

      nombreCompleto2: "",
      segundoNombre2:"",
      primerApellido2:"",
      segundoApellido2:"",
      correo2: "",
      
      nombreCompleto3: "",
      correo3: "",


      rutaS3_1: "",
      tipoFirma_1: "",
      nombreCompleto_1: "",
      cargo_1: "",

      rutaS3_2: "",
      tipoFirma_2: "",
      nombreCompleto_2: "",
      cargo_2: "",

      rutaS3_3: "",
      tipoFirma_3: "",
      nombreCompleto_3: "",
      cargo_3: "",

      rutaS3_4: "",
      tipoFirma_4: "",
      nombreCompleto_4: "",
      cargo_4: "",

     
    
      nombreBolsa: "",

      lugarRegistro:"",
      fechaRegistro:"",
      firmanteRepresentante:"",
      descripcionEstatus:"",
    }
  });

return (
  <FormContext.Provider value={[formValue, setFormValue]}>
    {children}
  </FormContext.Provider>
);
}
