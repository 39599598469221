import { useMemo } from "react";

export default function useLiberacionesRechazadas() {
    const columns = useMemo(
        () => [
            {
                Header: "Folio de liberación",
                accessor: "liberacionGarantiaFolio"
            },
            {
                Header: "Folio",
                accessor: "constitucionGarantiaFolio"
            },
            {
                Header: "Código del depositante receptor de los valores",
                accessor: "codigoDepositanteReceptorGarantia"
            },

            {
                Header: "Cuenta del depositante receptor de los valores",
                accessor: "cuentaReceptoraGarantia"
            },

        ],
        []
    );

    return columns;
}
