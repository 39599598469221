import { instance } from '../apiGlobal';

export async function traerInformacionAllEmisores(obj) {
  //console.log(obj)
      try {
        const response = await instance(`/emisor/consulta/emisor/codigo/estatus`, {
          method:"post",
          data:obj
        })
        return response.data
      } catch (error) {
       
        return error
      }
}



