import { instance } from '../apiGlobal';

export async function insertarActividadEconomica(obj) {
  try {
    const response = await instance(`/cat/add/actividadeseconomica`, {
      method:"post",
      data:obj
    })
    return response.data
  } catch (error) {
    return error
  }
}