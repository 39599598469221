//React
import React, { useState, useEffect } from "react";

//Componentes generales
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores';
import TablaUsuarios from "../../../../ComponentesGenerales/TablaUsuarios";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";

//APIs
import { traerTodasSolicitudesRegistroDepValFis } from "../../../../api/resguardoValoresFisicos/traerTodasSolicitudesRegistroDepValFis";

//Hooks
import useSolicitudesDepositantesValoresFisicos from '../../../../hooks/ResguardoValoresFisicos/useSolicitudesDepositantesValoresFisicos';


export default function TablaDepositantesValoresFisicosRegistrados({ history }) {


    const [failedLoadData, setFailedLoadData] = useState();

    const [copia, setCopia] = useState();
    const [data, setData] = useState([])
    const [spin, setSpin] = useState(true)
    const [erroreshttp, setErroreshttp] = useState()

    useEffect(() => {
        getAllDepositantsRegistered();
    }, []);

    //Handle api to get all applications to be a "depositante de valores fisicos"
    const getAllDepositantsRegistered = async () => {
        try {
            const response = await traerTodasSolicitudesRegistroDepValFis({ estatus: 3 });
            if (response) {
                setSpin(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    //console.log(response)
                    if (response.status === 200) {
                        setSpin(false);
                        setData(response.data);
                        setCopia(response.data);
                    } else {
                        setFailedLoadData(true);
                    }
        
                }
            }

          
        } catch (err) {
            console.error(err);
        }
    }

    //Handle when user click "Abrir solicitud"
    const viewSelectedRow = (row) => {

        //Filter the seleted row
        const depositanteResValFis = row.original.depositanteResgvalfisNombreCompleto;
        const selectedRowData = data.filter((row) => row.depositanteResgvalfisNombreCompleto === depositanteResValFis);

        //console.log(selectedRowData)
        //console.log("hola")
        //Send object of selected row to next screen
        history.push({
            pathname: '/depositanteValFisSeleccionado', state: { objRow: selectedRowData }
        });
    }

    //Shows modal when applications doesnt load
    const goHome = () => {
        setFailedLoadData(false);
        window.location.replace("/inicioCehval");
    }

    const goBack = () => {
        history.go(-1)
    }

    return (
        <>
            {failedLoadData &&
                <ComponenteModal mostrarModal={failedLoadData} cerrarModal={goHome} titulo={`Ocurrio algún error, será redireccionado al inicio. Revise su conexión o vuelva a iniciar sesión.`} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="">
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        {/*<div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 d-flex flex-column">
                            <BarraLateral2  ></BarraLateral2>
                        </div>*/}
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Depositantes de valores físicos registrados"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <>
                                                    <TablaUsuarios datos={data} displayInformation={viewSelectedRow} useValidate={useSolicitudesDepositantesValoresFisicos} textoBoton={"Abrir registro"} textTitle="Clic para ver la información de la solicitud"></TablaUsuarios>
                                                    <div className="col-12 col-md-12 px-3 d-flex justify-content-end justify-content-xl-end justify-content-md-end justify-content-sm-end my-3">
                                                        <button type="button" onClick={goBack} className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1 background-royal-blue text-white bg-hover-gold">Atrás</button>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    );
}





