import { instance } from '../../apiGlobal';

export async function verRolesParaRemover (obj) {
      try {
        const response = await instance(`/tarificacion/verificar/rolinfoisin/revocar`, {
          method:"post",
          data:obj,
        })
        return response;
      } catch (error) {
        return error;
      }
}


