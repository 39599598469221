/**
 export async function registrarSerie (obj) {
    
  try {
    const url = "http://10.1.4.248:8081/serie/registrar";
 
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "eyJraWQiOiJNQ3BwdE5HYTRsNnZydmo3ZTE4VENlR1llVHF5Ym1JTkZENmVhTnZWcDdNPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIzOGIwNDhjNS1jYmZjLTQ3NTgtYjVmMC0xYTQ4ZmQwZWRkMGUiLCJjb2duaXRvOmdyb3VwcyI6WyJGVU5DSU9OQVJJT19DVU1QTElNSUVOVE8iXSwiZW1haWxfdmVyaWZpZWQiOnRydWUsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX0JXdGFZQUVKbyIsImNvZ25pdG86dXNlcm5hbWUiOiJhbmdlbHxtb25kcmFnb258NG58Y29tfG14Iiwib3JpZ2luX2p0aSI6ImFkYTQ0ZjVlLThmYzEtNGQ4MS1iOTlkLTAxOTM1YzE0MjUzZCIsImF1ZCI6IjZiNmd0M3Z0c2liY21qbmw0ZG1ibTluNGVtIiwiZXZlbnRfaWQiOiI2MjRlOTQxOC0xZWY2LTRmMTktYTViYi1hYmZkM2ZhNDBlNTAiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTY2NjkwNDY4NiwiZXhwIjoxNjY2OTA4Mjg2LCJpYXQiOjE2NjY5MDQ2ODYsImp0aSI6IjY3ZmJlZmUwLThlMDktNGRkNS1iMTJkLWNkOGE1MjNiZjI4ZCIsImVtYWlsIjoiYW5nZWwubW9uZHJhZ29uQDRuLmNvbS5teCJ9.sHQC5eknbnPdN1Cmf1hRt2jVv8mvoeDK9hi_ORdFuQkqTEFkWSI4-ZZHBVUJzJBRQTSaU3Ayd2Ra3afkzq6m9IzhQlAMPbKHySVfKFpcFCRNCeo6GylB9Hn4ZemwQdwgeM_3HL2gvcUtkSMiRBb8iUnmKbC0vDGNZdn9O_mV-VUDX_Dyn7MJZydcevNr-YTvnTv3sZh7_yVtcOpu4HJsZgS5DExvjrWbhPHLyc1jmSNmcZk9rbPiDiMUT36RQ9Tfz-ESt1cvKkb6EdtcTauWF3-zwhc7NezCTRgpwFwI8KXpLiSzciNPDLiwHtEWv4kvI81tNo53NfTz7zllHhIqvA"
      },
      body: JSON.stringify(obj),
    };
    const response = await fetch(url, params);
    const result = await response.json();
   
    return result;
  } catch (error) {
    return null;
  }
}

 */



import { instance } from '../apiGlobal';

export async function registrarSerie(obj) {
    
  try {
    const response = await instance(`/serie/registrar`, {
      method: "post",
      data: obj
    });
    
    return response;
  } catch (error) {
    return error;
  }
}










