import React, { useState } from 'react';
import Stepper from 'react-stepper-horizontal';

//Steps
import RegistroDI1 from './RegistroDI1';
import REGISTRO_DI_1_5 from './REGISTRO_DI_1_5';
import RegistroDI2 from './RegistroDI2'
import RegistroDI3 from './RegistroDI3';
import RegistroDI4 from './RegistroDI4';
import RegistroDI5 from './RegistroDI5';

//Components
import BannerTitulo from '../Bannertitulo';
import ComponenteModal from '../ComponenteModal';
import SpinnerStart from '../SpinnerStart';
import PantallaCargando from '../PantallaCargando';


import ManejadorErrores from '../ManejadorErrores';


//Rect context
import { FormProvider, FormContext } from './form_context';

//APIs
import { cambiarEstatusDepositante } from '../../api/cambiarEstatus.js/cambiarEstatus';
import { actualizarEstatusYDocumentos } from '../../api/cambiarEstatus.js/actualizarEstatusYDocumentos';
import { traerInformacionDepositnate } from '../../api/VerInformacionUsuarios/TraerInformacionDepositante';
import { actualizarEstatusYDocumentosFuncionarioDeCumplimiento } from '../../api/cambiarEstatus.js/actualizarEstatusYDocumentosFuncionarioCumplimiento';
import RegistroDI5SinInformacion from './RegistroDI5SinInformacion';

const Form = ({ history }) => {
    if (history.location.state === undefined) {
        history.go(-1)
    }
    const [informacionUsuario, setInformnacionUsuario] = React.useState()
    const [validacionCorrecta, setValidacionCorrecta] = React.useState()
    const [validacionIncorrecta, setValidacionIncorrecta] = React.useState()
    const [value] = React.useContext(FormContext);
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()
    const [mensaje, setMensaje] = useState();
    const [descripcion, setDescripcion] = useState();
    const [descripcionVacia, setDescripcionVacia] = React.useState(false)
    const [desplegrarDescripcion, setDesplegarDescripcion] = React.useState(false)
    const [documentosIncompletos, setDocumentosIncompletos] = React.useState(false)
    const [mensajeModal, setMensajeModal] = React.useState("El proceso fue exitoso")
    const [documentosIncompletosFuncionario, setDocumentosIncompletosFuncionario] = React.useState(false)
    const [loading, setLoading] = React.useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    
    const banderaDescripcion=history.location.state.objMenu.banderaDescripcion
    const sections = [
        { title: 'Identificación del inversionista', onClick: () => setCurrentPage(1) },//, onClick: () => setCurrentPage(1) },
        { title: 'Información domicilio', onClick: () => setCurrentPage(2) },
        { title: 'Datos laborales del inversionista', onClick: () => setCurrentPage(3) },
        { title: 'Procedencia de los recursos', onClick: () => setCurrentPage(4) },//, onClick: () => setCurrentPage(3) },
        { title: 'Instrucciones especiales', onClick: () => setCurrentPage(5) },
        { title: 'Firmas autorizadas', onClick: () => setCurrentPage(6) }
    ];

    React.useEffect(() => {
        traerInfo()
    }, [])

    const traerInfo = async () => {
        const myObj = {
            folio: history.location.state.objMenu.folio,
            estatus: history.location.state.objMenu.estatus,
        }

        //console.log(myObj)
        const traerInformacion = await traerInformacionDepositnate(myObj);
        //console.log(traerInformacion)
        if (traerInformacion) {

            setSpin(false)
            if ((isNaN(traerInformacion.error)) === false) {
                setErroreshttp(traerInformacion)
            } else {
                setInformnacionUsuario(traerInformacion)
            }
        }
    }


    const next = () => setCurrentPage((prev) => prev + 1);
    const prev = () => setCurrentPage((prev) => prev - 1);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (value.datos.enviar === 0) {
            next()
        } else {
         setLoading(true)
            if (history.location.state.objMenu.documentos === "Si") {
                //si el registro es valido y esta toda la documentacion 
                if (mensaje === "valido") {
                    if (value.datos.cehvalDocumentoSoporExpedComp === 1) {
                        setLoading(true)
                        setDocumentosIncompletos(false)
                        const myObj =
                        {
                            codigo: history.location.state.objMenu.folio,
                            estatus: history.location.state.objMenu.estatus + 1,
                            cehvalDocumentoSoporCopiaDni: value.datos.cehvalDocumentoSoporCopiaDni,
                            cehvalDocumentoSoporCopiaRtn: value.datos.cehvalDocumentoSoporCopiaRtn,
                            cehvalDocumentoSoporPoderRepreAdmon: value.datos.cehvalDocumentoSoporPoderRepreAdmon,
                            cehvalDocumentoSoporExpedComp: value.datos.cehvalDocumentoSoporExpedComp,
                            contratoDeDepositante: value.datos.contratoDeDepositante,
                            descripcion: descripcion,
                            firmasStatus: [
                                {
                                    idFirma: value.datos.idFirma1,
                                    status: value.datos.estatusFirma1
                                },
                                {
                                    idFirma: value.datos.idFirma2,
                                    status: value.datos.estatusFirma2
                                }
                            ]
                        }
                        //console.log(myObj)

                        const modificarEstatusYDocumentos = await actualizarEstatusYDocumentos(myObj)
                        if (modificarEstatusYDocumentos) {
                            setLoading(false)
                            if ((isNaN(modificarEstatusYDocumentos.error)) === false) {
                                // respuestaincorrecta           
                                setErroreshttp(modificarEstatusYDocumentos)
                            } else {
                                setLoading(false)
                                if (modificarEstatusYDocumentos?.codigo === "000") {
                                    setValidacionCorrecta(true)

                                } else {
                                    setValidacionIncorrecta(true)
                                }
                            }
                        }
                    } else {

                        setDocumentosIncompletos(true)
                    }


                    //si el registro es invalido y no esta toda la documentacion 
                } else {
                    if (descripcion) {
                        setDescripcionVacia(false)
                        setLoading(true)
                        const myObj2 =
                        {
                            codigo: history.location.state.objMenu.folio,
                            estatus: 0,
                            cehvalDocumentoSoporCopiaDni: value.datos.cehvalDocumentoSoporCopiaDni,
                            cehvalDocumentoSoporCopiaRtn: value.datos.cehvalDocumentoSoporCopiaRtn,
                            cehvalDocumentoSoporPoderRepreAdmon: value.datos.cehvalDocumentoSoporPoderRepreAdmon,
                            cehvalDocumentoSoporExpedComp: value.datos.cehvalDocumentoSoporExpedComp,
                            contratoDeDepositante: value.datos.contratoDeDepositante,
                            descripcion: descripcion,
                            firmasStatus: [
                                {
                                    idFirma: value.datos.idFirma1,
                                    status: value.datos.estatusFirma1
                                },
                                {
                                    idFirma: value.datos.idFirma2,
                                    status: value.datos.estatusFirma2
                                }
                            ]
                        }
                        const modificarEstatusYDocumentos2 = await actualizarEstatusYDocumentos(myObj2)
                        if (modificarEstatusYDocumentos2) {
                            setLoading(false)
                            if ((isNaN(modificarEstatusYDocumentos2.error)) === false) {
                                // respuestaincorrecta           
                                setErroreshttp(modificarEstatusYDocumentos2)
                            } else {
                                setLoading(false)
                                if (modificarEstatusYDocumentos2?.codigo === "000") {
                                    setValidacionCorrecta(true)

                                } else {
                                    setValidacionIncorrecta(true)
                                }
                            }
                        }
                    } else {
                        setDescripcionVacia(true)
                    }

                }
            } else {

                if (mensaje === "valido") {


                    if (history.location.state.objMenu.estatus === 2) {
                        if (value.datos.errorDocumentacionIncompletaFuncionario) {
                            setDocumentosIncompletosFuncionario(true)
                        } else {
                            setLoading(true)
                            const myObj1 =
                            {
                                codigo: history.location.state.objMenu.folio,
                                estatus: history.location.state.objMenu.estatus + 1,
                                descripcion: descripcion,
                                revisionListaOfac: value.datos.revisionListaOfac,
                                certificacionDebidaDiligenciaRecibida: value.datos.certificacionDebidaDiligenciaRecibida

                            }

                            const modificarEstatusConDocumentos = await actualizarEstatusYDocumentosFuncionarioDeCumplimiento(myObj1)

                            if (modificarEstatusConDocumentos) {
                                setLoading(false)
                                if ((isNaN(modificarEstatusConDocumentos.error)) === false) {
                                    // respuestaincorrecta           
                                    setErroreshttp(modificarEstatusConDocumentos)
                                } else {
                                    setLoading(false)

                                    if (modificarEstatusConDocumentos?.codigo === "000") {
                                        setValidacionCorrecta(true)

                                    } else {
                                        setValidacionIncorrecta(true)
                                    }
                                }
                            }
                        }
                    } else {
                        let estatusAEnviar;
                        if (banderaDescripcion === 1) {
                            estatusAEnviar = 6
                        } else {
                            estatusAEnviar = history.location.state.objMenu.estatus + 1
                        }

                        const myObj =
                        {
                            folio: history.location.state.objMenu.folio,
                            estatus: estatusAEnviar,
                            descripcion: descripcion,
                        }
                        const modificarEstatus = await cambiarEstatusDepositante(myObj)
                        if (modificarEstatus) {
                            setLoading(false)
                            if ((isNaN(modificarEstatus.error)) === false) {
                                // respuestaincorrecta           
                                setErroreshttp(modificarEstatus)
                            } else {
                                setLoading(false)
                                if (modificarEstatus?.codigo === "000") {
                                    if (banderaDescripcion !== 1) {
                                        setMensajeModal(`Registro exitoso, código de depositante asignado ${modificarEstatus.mensaje}`)
                                    }

                                    setValidacionCorrecta(true)

                                } else {
                                    setValidacionIncorrecta(true)
                                }
                            }
                        }
                    }

                    //si el registro es invalido y no esta toda la documentacion 
                } else {
                    if (descripcion) {
                        setDescripcionVacia(false)
                        setLoading(true)
                        let modificarEstatus2;

                        if (history.location.state.objMenu.estatus === 2) {
                            const objeto1 =
                            {
                                codigo: history.location.state.objMenu.folio,
                                estatus: 0,
                                descripcion: descripcion,
                                revisionListaOfac: value.datos.revisionListaOfac,
                                // debidaDiligenciaCEHVAL: value.datos.debidaDiligenciaCEHVAL,
                                certificacionDebidaDiligenciaRecibida: value.datos.certificacionDebidaDiligenciaRecibida
                            }
                            modificarEstatus2 = await actualizarEstatusYDocumentosFuncionarioDeCumplimiento(objeto1)
                            if (modificarEstatus2) {
                                setLoading(false)
                                if ((isNaN(modificarEstatus2.error)) === false) {
                                    // respuestaincorrecta           
                                    setErroreshttp(modificarEstatus2)
                                } else {
                                    setLoading(false)
                                    if (modificarEstatus2?.codigo === "000") {
                                        setValidacionCorrecta(true)

                                    } else {
                                        setValidacionIncorrecta(true)
                                    }
                                }
                            }
                        } else {
                            const Obj2 =
                            {
                                folio: history.location.state.objMenu.folio,
                                estatus: 0,
                                descripcion: descripcion,
                            }
                            const modificarEstatus2 = await cambiarEstatusDepositante(Obj2)
                            if (modificarEstatus2) {
                                setLoading(false)
                                if ((isNaN(modificarEstatus2.error)) === false) {
                                    // respuestaincorrecta           
                                    setErroreshttp(modificarEstatus2)
                                } else {
                                    setLoading(false)
                                    if (modificarEstatus2?.codigo === "000") {
                                        setValidacionCorrecta(true)

                                    } else {
                                        setValidacionIncorrecta(true)
                                    }
                                }
                            }
                        }


                    } else {
                        setDescripcionVacia(true)
                    }


                }

            }
        }


    }

    const cancelarValidadcionCorrecta = () => {
        setLoading(false)
        setValidacionCorrecta(false)
        history.go(-1)
    }

    const cancelarValidadcionIncorrecta = () => {
        setLoading(false)
        setValidacionIncorrecta(false)
        history.go(-1)
    }

    const IrMenu = () => {
        history.go(-1)
    }

    return (
        <>

            <div className="fluid-container">
                {loading &&
                    <PantallaCargando />
                }

                {documentosIncompletos &&
                    <ComponenteModal mostrarModal={documentosIncompletos} cerrarModal={() => setDocumentosIncompletos(false)} titulo="
                    No puedes aceptar el registro si la documentación no esta completa" textoBoton="Entendido"></ComponenteModal>
                }

                {documentosIncompletosFuncionario &&
                    <ComponenteModal mostrarModal={documentosIncompletosFuncionario} cerrarModal={() => setDocumentosIncompletosFuncionario(false)} titulo="No se puede aceptar, sin validaciones de la unidad de cumplimiento" textoBoton="Entendido"></ComponenteModal>
                }

                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                {validacionCorrecta &&
                    <ComponenteModal mostrarModal={validacionCorrecta} cerrarModal={cancelarValidadcionCorrecta} titulo={mensajeModal} textoBoton="Entendido"></ComponenteModal>
                }
                {validacionIncorrecta &&
                    <ComponenteModal mostrarModal={validacionIncorrecta} cerrarModal={cancelarValidadcionIncorrecta} titulo="El proceso fallo, revise su información e intentelo de nuevo" textoBoton="Entendido"></ComponenteModal>
                }

                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo={history.location.state.objMenu.titulo}></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <div className='container'>
                                            <div className="row contenedorsteper" id='contenedorsteper'>
                                                <div className="col-12 contenedorsteper font-acumin-variable">
                                                    <div id="stepperComponent">
                                                        <Stepper
                                                            steps={sections}
                                                            activeStep={currentPage - 1}
                                                            activeColor="#F4CA85"
                                                            completeColor="#242848"
                                                            completeBarColor="#242848"
                                                            titleFontSize={15}
                                                            size={30}
                                                            circleFontSize={15}
                                                            className="steper"
                                                        />
                                                    </div>

                                                    <div className="claseSinBorder">
                                                        <div className="card-body">
                                                            <div className="">
                                                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                                                    <>
                                                                        {informacionUsuario &&
                                                                            <form onSubmit={handleSubmit}>
                                                                                <br></br>
                                                                                {currentPage === 1 && (
                                                                                    <>
                                                                                        <div id="primeraSeccion"  >
                                                                                            <div className="contenedorFormularioDepositanteDirecto card" >
                                                                                                <RegistroDI1 info={informacionUsuario} />

                                                                                                <div className='mb-3 text-end p-3'>
                                                                                                    <button type="button" className="btn botonFormularioDepositante" onClick={next}>Siguiente</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}

                                                                                {currentPage === 2 && (
                                                                                    <>
                                                                                        <div id="primeraSeccionIntermedia " >
                                                                                            <div className="contenedorFormularioDepositanteDirecto card " >
                                                                                                <REGISTRO_DI_1_5 info={informacionUsuario} />
                                                                                                <div className='contenedorBotonesStteper p-3' >
                                                                                                    <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                                                                                    <button type="button" className="btn botonFormularioDepositante" onClick={next}>Siguiente</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </>
                                                                                )}

                                                                                {currentPage === 3 && (
                                                                                    <>
                                                                                        <div id="segundaSeccion" >
                                                                                            <div className="contenedorFormularioDepositanteDirecto card " >
                                                                                                <RegistroDI2 info={informacionUsuario} />
                                                                                                <br></br>
                                                                                                <div className='contenedorBotonesStteper p-3' >
                                                                                                    <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                                                                                    <button type="button" className="btn botonFormularioDepositante" onClick={next}>Siguiente</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}

                                                                                {currentPage === 4 && (
                                                                                    <>
                                                                                        <div id="terceraSeccion" >
                                                                                            <div className="contenedorFormularioDepositanteDirecto card " >
                                                                                                <RegistroDI3 info={informacionUsuario} />

                                                                                                <div className='contenedorBotonesStteper p-3'>
                                                                                                    <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                                                                                    <button type="button" className="btn botonFormularioDepositante" onClick={next}>Siguiente</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}

                                                                                {currentPage === 5 && (
                                                                                    <>
                                                                                        <div id="cuartaSeccion" >
                                                                                            <div className="contenedorFormularioDepositanteDirecto card " >
                                                                                                <RegistroDI4 info={informacionUsuario} />
                                                                                                <br></br>
                                                                                                <div className='contenedorBotonesStteper p-3'>
                                                                                                    <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                                                                                    <button type="button" className="btn botonFormularioDepositante" onClick={next}>Siguiente</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}

                                                                                {currentPage === 6 && (
                                                                                    <>
                                                                                        <div id="quintaSeccion" >
                                                                                            <div className="card contenedorFormularioDepositanteDirecto card ">
                                                                                                {history.location.state.objMenu.documentos === "Si" ?
                                                                                                    <RegistroDI5 info={informacionUsuario} />
                                                                                                    :
                                                                                                    <RegistroDI5SinInformacion info={informacionUsuario} banderaDescripcion={banderaDescripcion} />
                                                                                                }

                                                                                                <br></br>


                                                                                                {history.location.state.objMenu.estatus === 5 ?
                                                                                                    <>
                                                                                                        <div className='contenedorBotonesStteper p-3' >
                                                                                                            <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                                                                                            <button type="button" className="btn botonFormularioDepositanteRechazar" onClick={() => {
                                                                                                                IrMenu()
                                                                                                            }}>{history.location.state.objMenu.textoBoton12}</button>
                                                                                                        </div></>
                                                                                                    :

                                                                                                    <>
                                                                                                        {banderaDescripcion === 1 ?
                                                                                                            <div className='contenedorBotonesStteper p-3' >
                                                                                                                <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                                                                                                <button type="submit" className="btn botonFormularioDepositanteAprobar" onClick={() => {
                                                                                                                    setMensaje("valido")

                                                                                                                }}>{history.location.state.objMenu.textoBoton1}</button>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <>
                                                                                                                {desplegrarDescripcion ?
                                                                                                                    <div className='p-3'>
                                                                                                                        <div class="form-floating">
                                                                                                                            <textarea class="form-control" placeholder="Leave a comment here" onChange={(e) => {
                                                                                                                                setDescripcion(e.target.value)
                                                                                                                            }} id="floatingTextarea2" style={{ height: "7rem" }} required></textarea>
                                                                                                                            <label for="floatingTextarea2">Escriba el motivo del rechazo</label>
                                                                                                                        </div>
                                                                                                                        <div className='contenedorBotonesStteper  '>
                                                                                                                            <button type="button" className="btn botonFormularioDepositanteRechazar mt-2" onClick={() => {
                                                                                                                                setDesplegarDescripcion(false)
                                                                                                                            }}>Cancelar</button>
                                                                                                                            <button type="submit" className="btn botonFormularioDepositante  mt-2" onClick={() => {
                                                                                                                                setMensaje("invalido")
                                                                                                                            }}>Enviar</button>
                                                                                                                        </div>


                                                                                                                        {descripcionVacia &&
                                                                                                                            <p className="error mt-2">Necesitas escribir el motivo del rechazo</p>

                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <div className='contenedorBotonesStteper p-3' >
                                                                                                                        <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                                                                                                        <button type="submit" className="btn botonFormularioDepositante" onClick={() => {
                                                                                                                            setMensaje("valido")
                                                                                                                        }}>{history.location.state.objMenu.textoBoton1}</button>

                                                                                                                        <button type="button" className="btn botonFormularioDepositante" onClick={() => {
                                                                                                                            setDesplegarDescripcion(true)
                                                                                                                        }}>{history.location.state.objMenu.textoBoton12}</button>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </>
                                                                                                        }
                                                                                                    </>

                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </form>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </div>

        </>
    );
};

export default function VerInfoDepIndPersonaNaturalDoc({ history }) {

    return (
        <FormProvider>
            <Form history={history} />
        </FormProvider>
    );
}
