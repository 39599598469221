import { useMemo } from "react";

export default function useDepositosRetirosPorAutorizar() {
    const columns = useMemo(
        () => [
            {
                Header: "Código Isin",
                accessor: "codigoIsin"
            },
            {
                Header: "Código de serie",
                accessor: "codigoSerie"
            },
            {
                Header: "Serie",
                accessor: "serie"
            },
            {
                Header: "Nombre de la emisión",
                accessor: "nombreEmision"
            },
            {
                Header: "Folio",
                accessor: "folio"
            },
            {
                Header: "Cantidad Instrumentos",
                accessor: "cantidadValores"
            },
            {
                Header: "Código de depositante",
                accessor: "codigoDepositante"
            },
            {
                Header: "Nombre de depositante",
                accessor: "nombreInstitucion"
            },
            {
                Header: "Saldo en valores",
                accessor: "saldoValores"
            },
            {
                Header: "No. de certififcado",
                accessor: "numeroCertificado"
            },
         


        ],
        []
    );

    return columns;
}
