//React
import React from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";

//APIs
import { traerEmisiones } from "../../../api/emision/traerEmisiones";


//Hooks
import useEmisionColumns from '../../../hooks/useEmisionColumns';


export default function EmisionesRechazadas({ history }) {
    //const estatus = 2;

    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()

    React.useEffect(() => {
        searchEmisions();
    }, [])


    const searchEmisions = async () => {
        try {
            const emisiones = await traerEmisiones({ estatus: 0 });
            if (emisiones) {
                setSpin(false)
                if ((isNaN(emisiones.error)) === false) {
                    // //console.log("erores")
                    ////console.log(response)
                    setErroreshttp(emisiones)
                    
                } else {
                    setData(emisiones);
                    setCopia(emisiones)
                    ////console.log(data)
                }
            }
        } catch (err) {
            console.error(err);
        }
    }


    //Catch information form the selected row
    const viewEmision = (row) => {
        //console.log(row.original.cehvalEmisionDescripcion)

        const emision = {
            cehvalNombreEmision: row.original.cehvalEmisionNomEmi,
            cehvalCodigoEmision: row.original.cehvalEmisionCodigo,
            cehvalEstatus: row.original.cehvalEmisionEstatus,
            cehvalNombreEmisor: row.original.cehvalEmisorCodigo.cehvalEmisorNombreCom,
            cehvalCodigoEmisor: row.original.cehvalEmisorCodigo.cehvalEmisorCodigo,
            cehvalMonto: row.original.cehvalEmisionMontoCadena,
            cehvalEmisionSeries: row.original.cehvalEmisionSeries,
            cehvalEmisionNumeroResol: row.original.cehvalEmisionNumeroResol,
            cehvalEmisionFechaRpmv: row.original.cehvalEmisionFechaRpmvCadena,
            cehvalEmisionRepresentanteComunObl: row.original.cehvalEmisionRepresentanteComunObl,
            cehvalEmisionTipoMoneda: row.original.catMonedaCatMonedaId.catMonedaDivisa,
            cehvalEmisionDesmaterializada: row.original.cehvalEmisionDesmaterializada,
            //cehvalEstatus:row.original.cehvalEmisionEstatus,
            id:row.original.cehvalEmisionId,
            ruta:"/emisionesRechazadas",
            titulo:"Notificar rechazo de solicitud de de emisión",
            descripcion:row.original.cehvalEmisionDescripcion
        }


        //Mandar el objeto de la emision que el usuario desea ver
        history.push({
            pathname: '/verInfoEmision', state: { objMenu: emision }
        })
    }

    return (
        <>

            <div className="fluid-container">
               {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }     

                <main className="min-height-40">
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <div className="">
                                <br></br>
                                <BannerTitulo titulo="Emisiones rechazadas"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container">
                                            {copia &&
                                                <TablaUsuarios datos={data} displayInformation={viewEmision} useValidate={useEmisionColumns} textoBoton={"Abrir"} textTitle="Click para ver la información de la emisión"></TablaUsuarios>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    );
}