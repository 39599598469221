import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';

//Components
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores'
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import { retirarValorFisico } from '../../../../api/resguardoValoresFisicos/registroValoresFisicos/retirarValorFisico';


//endpoints
import { imprimirValorFisicoDepositadoseleccionado }  from '../../../../api/impresionValoresFisicos/imprimirValorFisicoDepositadoSeleccionado'

export default function VisualizarValorFisico({ history }) {

    const historyReact = useHistory();


    const [loadDataRowFailed, setLoadDataRowFailed] = useState();
    const [loadDataSuccessful, setLoadDataSuccessful] = useState();

    //Disable inputs info
    const [codigoDepositanteValFis, setCodigoDepositanteValFis] = useState();
    const [nombreValorFisico, setNombreValorFisico] = useState();
    const [nombreDepositanteValFis, setNombreDepositanteValFis] = useState();
    const [descripcionValor, setDescripcionValor] = useState();
    const [valorFisicoLugar, setValorFisicoLugar] = useState();

    //Loading
    const [loading, setLoading] = useState();

    const [fechaDeposito, setFechaDeposito] = useState();
    const [fechaRetiro, setFechaRetiro] = useState();

    const[ operacionTerminada,setOperacionTerminada]=useState()
    const[ mensaje,setMensaje]=useState()

       //Errors and messages    
       const [erroreshttp, setErroreshttp] = useState();

       const roles=(JSON.parse(window.sessionStorage.getItem('usuarioRol'))).roles



    useEffect(() => {
     
        loadRowData();
    }, []);

    //Handle load the data of the selected row to show data in front
    const loadRowData = () => {

        if (history.location.state === undefined) {
            setLoadDataRowFailed(true);
            
        } else {

            const row = history.location.state.objRow;

            //Row values 
            setCodigoDepositanteValFis(row.depositoValorFisicoCodigoDepositante);
            setNombreValorFisico(row.depositoValorFisicoNomCompleto);
            setNombreDepositanteValFis(row.depositoValorFisicoNombreDepo);
            setDescripcionValor(row.depositoValorFisicoDescripcionValor);
            setValorFisicoLugar(row.depositoValorFisicoLugar);
            setFechaDeposito(row.depositoValorFisicoFechaDepoCadena);
            setFechaRetiro(row.depositoValorFisicoFechaRetiroCadena);

            //Show form once our states have the data row
            setLoadDataSuccessful(true);
        }
    }

    const agregarFecha=async()=>{
     
            const obj = {
                idSolicitud: history.location.state.objRow.depositoValorFisicoId,
             
            }
            //console.log(obj)

            setLoading(true);
            const response = await retirarValorFisico(obj);
            if (response) {
                setLoading(false);

                if (isNaN(response.error) === false) {
                    setErroreshttp(response);

                } else {
                    if(response.data.codigo === "000"){
                        setOperacionTerminada(true)
                        setMensaje("Se ha retirado el valor físico con exito")
                    }else{
                        setOperacionTerminada(true) 
                        setMensaje("Ha ocurrido un error al intentar retirar el valor")
                    }
                   
                }
            }

    }


    const regresarTabla=()=>{
        history.push({
            pathname: '/valoresFisicosDepositados',
        })

        
    }

    //Handle modal button, go back to the table of applications
    const goToInicioCehval = () => {
        history.push({
            pathname: '/menuDepositosValoresFisicos',
        })
    }

    const imprimir=async()=>{
        const obj = {
            idSolicitud: history.location.state.objRow.depositoValorFisicoId,
         
        }
        setLoading(true)
        const response = await imprimirValorFisicoDepositadoseleccionado(obj)
        if (response) {
            //setLoading(false)
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta      
                setLoading(false)
                setErroreshttp(response)
            } else {
                //console.log(response)
                const file = new Blob(
                    [response],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
                setLoading(false)
            }
        }
    }


    return (
        <>
         {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
           {loading &&
                <PantallaCargando></PantallaCargando>
            }

        {operacionTerminada&&
            <ComponenteModal mostrarModal={operacionTerminada} cerrarModal={regresarTabla} titulo={mensaje} textoBoton="Aceptar"></ComponenteModal>
        }
            
            {loadDataRowFailed &&
                <ComponenteModal mostrarModal={loadDataRowFailed} cerrarModal={goToInicioCehval} titulo={`Ocurrio algún error, será redireccionado al inicio. Revise su conexión o vuelva a iniciar sesión.`} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="fluid-container">
                
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        {/*<div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 d-flex flex-column">
                            <BarraLateral2  ></BarraLateral2>
                        </div>*/}
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Valor físico depositado"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <section className="p-5 d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded p-3">
                                                <div className="col-12">
                                                    {loadDataSuccessful &&
                                                        <form className="needs-validation font-acumin-variable text-royal-blue">
                                                            <div className="row mt-4">
                                                                <div className="col-12 col-md-12 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Código depositante transferente" value={nombreValorFisico} className="form-control text-royal-blue" name="nombreCompletoValor" type="text" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="nombreCompletoValor">Nombre completo del valor</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Código depositante transferente" value={codigoDepositanteValFis} className="form-control text-royal-blue" name="codigoDepositanteValoresFisicos" type="text" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="codigoDepositanteValoresFisicos">Código de depositante de valores físicos</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Código depositante transferente" value={nombreDepositanteValFis} className="form-control text-royal-blue" name="nombreDepositanteValoresFisicos" type="text" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="nombreDepositanteValoresFisicos">Nombre del depositante de valores físicos</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 form-floating mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" value={fechaDeposito} className="form-control border" id="fechaDeposito" name="fechaDeposito" required disabled />
                                                                        <label htmlFor="fechaDeposito" className="form-label ">Fecha de depósito </label>
                                                                    </div>
                                                                </div>
                                                                 {(roles.includes("OPERADOR_CEHVAL")|| roles.includes("SUPERADMINISTRADOR"))&& 
                                                                    <div className="col-12 col-md-6 mb-3">
                                                                      <button type="button" onClick={agregarFecha} className="btn col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 background-royal-blue text-white bg-hover-gold">Retirar valor</button>
                                                                    </div>
                                                                  } 
                                                              
                                                                
                                                               {/*} <div className="col-12 col-md-6 form-floating mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" value={fechaRetiro} className="form-control border" id="fechaRetiro" name="fechaRetiro" disabled />
                                                                        <label htmlFor="fechaRetiro" className="form-label ">Fecha de retiro</label>
                                                                    </div>
                                                                </div>*/}
                                                                <div className="col-12 col-md-12 mb-3">
                                                                    <div className="form-floating ">
                                                                        <textarea title="Cantidad de valores a transferir" value={descripcionValor} className="form-control fs-5 text-royal-blue rejectionReason" name="descripcionValor" type="text" required disabled ></textarea>
                                                                        <label className="text-start font-acumin-variable" htmlFor="descripcionValor">Descripción del valor</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                  <button type="button" className="btn col-6 col-sm-3 col-md-3 col-lg-3 col-xl-2 background-royal-blue text-white bg-hover-gold">Enviar</button>
                                                                </div>

                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Código depositante transferente" value={valorFisicoLugar} className="form-control text-royal-blue" name="lugarRegistro" type="text" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="lugarRegistro">Lugar del registro</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-12 d-flex justify-content-between mb-3">
                                                                    <button title="Imprimir valor físico depositado" type="button" onClick={imprimir} className="btn col-6 col-sm-4 col-md-3 col-lg-3 col-xl-1 background-royal-blue text-white bg-hover-gold me-2">Imprimir</button>
                                                                    <button title="Regresar" type="button" onClick={historyReact.goBack} className="btn col-6 col-sm-4 col-md-3 col-lg-3 col-xl-1 background-royal-blue text-white bg-hover-gold me-2">Regresar</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    )
}