import React from "react";
import '../styles/stylesheet.css'
import sinRegistros from '../assets/logosCehval/sinRegistros.jpg'

export default function SinRegistros({ history }) {
   




    return (
        <>
            <div className="fluid-container" >

            

                 
                <main className="min-height-40" >
                <div className="container text-center mt-5" >
                    <div className="mb-5 " >
                        <div className="card-body ">
                            <img className="rounded" src={sinRegistros} alt="imagenFondo" />
                        </div>
                    </div>
                    <h1 className="font-acumin-variable">Sin registros</h1>
                 
                
                </div>
                </main >  


            </div>



        </>
    );
}







