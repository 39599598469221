import React from "react";

export default function PantallaCargando() {
    return (
        <>
            <div className="modal background-royal-blue modalLoading d-block">
                <div className="modal-dialog modal-dialog-centered row justify-content-center">
                    <div className='text-royal-blue text-center row justify-content-center'>
                        <p className='col-12 text-center text-white fs-5'>Cargando...</p>
                        <div className='col-3 d-flex justify-content-center'>
                            <div className="loader m-0" id="loader"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}