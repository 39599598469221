import React, { useState } from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";

import formatNumber from "../../../functions/FormatNumber";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";



//APIs
import { registrarPagosDividendos } from "../../../api/PagoInteresesAmorDiv/registrarPagoDividendos";


export default function FormularioRegistroPagosDividendos({ history }) {


    const [registroExitoso, setRegistroExitoso] = React.useState()
    const [registroFallido, setRegistroFallido] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [mensaje, setMensaje] = React.useState()


    //The name of the users file

    const [nameFile1, setNameFile1] = useState("Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro");
    //The file

    const [file1, setFile1] = useState();
  
    const [loading, setLoading] = React.useState(false);

    const [errorExtensionDoc1, setErrorExtensionDoc1] = useState();

    const [archivoValido1, setArchivoValido1] = useState();

    const [invalidFile, setInvalidFile] = useState(false);


    const [errorSinDocumento, setErrorSinDocumento] = useState();

    const [datos, setDatos] = useState();

    const [proximaFechaPago, setProximaFechaPago] = useState();
    const [dividendoPorAccion, setDividendoPorAccion] = useState();
    const [dividendoPorAccionStr, setDividendoPorAccionStr] = useState();

    React.useEffect(() => {
        guardarDatos()
    }, [])

    const guardarDatos = async (e) => {
        if (history.location.state === undefined) {
            history.go(-1)
        } else {
            setDatos(history.location.state.objMenu)
        }
    }

    const onSubmitForm = async (e) => {

        e.preventDefault();


        if (file1) {
            setLoading(true)
            setErrorSinDocumento(false)
            const form = new FormData();

            form.append("isin", datos?.isin);
            form.append("fechaPago", proximaFechaPago);
            form.append("pagoDividendo", dividendoPorAccion);
            form.append("nombreSerie", datos?.nombreSerie);
            form.append("filedoc1", file1[0]);
            //console.log(file1[0])

            // isin,filedoc1,fechaPago,pagoDividendo,nombreSerie
            const enviarDatos = await registrarPagosDividendos(form)
            //console.log(enviarDatos)
            if (enviarDatos) {
                setLoading(false)
                if ((isNaN(enviarDatos.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(enviarDatos)
                } else {

                    if (enviarDatos?.codigo === "000") {
                        setRegistroExitoso(true)

                    } else {
                        setMensaje(enviarDatos.mensaje)
                        setRegistroFallido(true)
                    }
                }
            }
        } else {

            setErrorSinDocumento(true)
            setLoading(false)
        }
    }

    React.useEffect(() => {
        setLoading(false)
    }, [errorSinDocumento])



    //leer documentos
    //Open input file when click on h4 text
    const handleInputFile = (nombre) => {
        //Select our input tag 
        const input = document.querySelector(`#${nombre}`);
        //Give the event click to our input
        input.click();
    }

    // HANDLE WHEN USER SELECT A FILE WHEN CLICK ON TEXT
    const selectFile = (e) => {
        //Cleans error message if was one


        setErrorExtensionDoc1("");

        const file = e.target.files;
        //Name of the file to validate if has the correct extension
        const fileName = e.target.files[0].name;
        const fileSize = e.target.files[0].size;

        //Regex to validate the extension of the file
        const extensionsAllow = /(.pdf)$/i;

        ////console.log(pathFile.files)
        //If selected file doesnt have correct extension show user error message
        if (!extensionsAllow.exec(fileName)) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file


            setErrorExtensionDoc1("Selecciona un archivo válido");
            setArchivoValido1(false)
            //Clean our states if they had any information
            setFile1("");
            setNameFile1("Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro");



            return false;

        } else if (fileSize > 10_000_000) {
            //Shows modal with an error message
            setInvalidFile(true);
       
            //Clean file name if a file was selected before
            setNameFile1("");
            //Clean state if a file was selected before
            setFile1("");

        } else if (e.target.files && e.target.files[0]) {
            setErrorSinDocumento(false)
            //Shows the name of the selected file to the user
            setNameFile1(fileName);
            //Set FileList [File] to our state
            setFile1(file);
            setArchivoValido1(true)

        }
    }

    // HANDLE USER DROP FILE
    const dropFile = (e,dropArea, texto) => {

        e.preventDefault();
        //console.log("dropFile")

        //Removes the active class from container when user drops a file
        document.querySelector(`.${dropArea}`).classList.remove("active");
        //Shows the same text before dropping a file


        //Show user the selected file and validates in codition if file has a valid extension 
        const fileName = e.dataTransfer.files[0].name;
        const fileSize = e.dataTransfer.files[0].size;
        //Regex to validate file extension
        const extensionsAllow = /(.pdf)$/i;

        const inputField = document.querySelector(`#${texto}`);


        if (!extensionsAllow.exec(fileName)) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file

            setArchivoValido1(false)
            setErrorExtensionDoc1("Selecciona un archivo válido");
            setFile1("");
            setNameFile1("Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro");
            inputField.innerHTML = "Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro";
            return false;

        }else if (fileSize > 10_000_000) {
            //Shows modal with an error message
            setInvalidFile(true);
      
            //Clean file name if a file was selected before
            setNameFile1("");
            //Clean state if a file was selected before
            setFile1("");

        } else if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setErrorSinDocumento(false)

            setErrorExtensionDoc1(false);
            //Shows the name of the selected file to the user
            setNameFile1(fileName);
            //Set FileList [File] to our state
            setFile1(e.dataTransfer.files);
            setArchivoValido1(true)
            inputField.innerHTML = fileName;
        }



    }

    //Handle when user drag over a file 
    const dragOver = (e, nombre, dropArea) => {

        e.preventDefault();
        //console.log("dragOver")
        //Adds the active class to container to change the background

        const moveOver = document.querySelector(`.${dropArea}`);
        moveOver.classList.add("active");
        //Change the text inside box

        const inputField = document.querySelector(`#${nombre}`);
        inputField.innerHTML = "Suelte el archivo";
    }

    //Handle when user leaves the drop area whitout drop any file
    const dragLeave = (e, nombre, dropArea) => {
        e.preventDefault();
        //Removes the active class to container to change the background
        const moveLeave = document.querySelector(`.${dropArea}`);
        moveLeave.classList.remove("active");
        //Change the text inside box 
        const inputField = document.querySelector(`#${nombre}`);
        if (nombre === "text-click-uploadfile1") {
            if (file1) {
                inputField.innerHTML = nameFile1;
            } else {
                inputField.innerHTML = "Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro";
            }
        } 
    }

    const guardarCantidad = (e) => {
        //console.log(e)
        const numeroFormateado = formatNumber(e)
        //console.log(numeroFormateado)
        setDividendoPorAccionStr(numeroFormateado.stringNumber)
        setDividendoPorAccion(numeroFormateado.integerNumber)
    }


    const irInicio = () => {
        setRegistroExitoso(false)
        history.go(-1)
    }

    return (
        <div className="fluid-container">
            {loading &&
                <PantallaCargando />
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={irInicio} titulo="Pago de dividendos registrado" textoBoton="Entendido"></ComponenteModal>
            }

            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={() => { setRegistroFallido(false) }} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }
            {invalidFile &&
                <ComponenteModal mostrarModal={invalidFile} cerrarModal={() => { setInvalidFile(false) }} titulo="Archivo inválido. Por favor seleccione un archivo PDF y con un peso menor a 10 MB" textoBoton="Aceptar"></ComponenteModal>
            }

            


            <main className="mb-5">
                <div className="row row-wrap g-0">
                    <div className="col-12">
                        <div className="">
                            <div className="row d-flex justify-content-center g-0">
                                <div className="col-12">
                                    <BannerTitulo titulo="Registrar pago dividendos"></BannerTitulo>
                                    <div className="px-3 px-lg-4 px-xl-4 px-xxl-5 border-none">
                                        <div className="card-body ">
                                            <div className="col-12 mx-auto">
                                                <form className="row g-0 needs-validation" novalidate onSubmit={(e) => {
                                                    onSubmitForm(e)

                                                }}>
                                                    <div className="card">
                                                        {datos &&
                                                            <div className="card-body">
                                                                <div className="row mb-3">
                                                                    <div className="col-md-6 mb-3">
                                                                        <div className="form-floating">
                                                                            <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.isin} id="isin" name="isin"  required disabled />
                                                                            <label htmlFor="isin" className=" textoFormularioDepositantedirecto">ISIN</label>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6 mb-3">
                                                                        <div className="form-floating">
                                                                            <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.nombreSerie} id="nombreSerie" name="nombreSerie" required disabled />
                                                                            <label htmlFor="nombreSerie" className=" textoFormularioDepositantedirecto">Nombre serie</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-6 mb-3">
                                                                        <div className="form-floating">
                                                                            <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.nombreEmision} id="nombreEmision" name="nombreEmision" required disabled />
                                                                            <label htmlFor="nombreEmision" className=" textoFormularioDepositantedirecto">Nombre de la emisión</label>
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-md-6 mb-3">
                                                                        <div className="form-floating">
                                                                            <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.moneda} id="moneda" name="moneda"  required disabled />
                                                                            <label htmlFor="nombreEmision" className=" textoFormularioDepositantedirecto">Moneda</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mb-3">
                                                                    <div className="col-md-6 mb-3">
                                                                        <div className="form-floating">
                                                                            <input type="date" className="form-control textoFormularioDepositantedirecto" onChange={(e) => setProximaFechaPago(e.target.value)} value={proximaFechaPago} id="proximaFecha" name="proximaFecha" placeholder="" required />
                                                                            <label htmlFor="proximaFecha" className=" textoFormularioDepositantedirecto">Próxima fecha de pago de dividendos *</label>
                                                                        </div>
                                                                    </div>


                                                                    <div className="col-md-6 mb-3">
                                                                        <div className="form-floating">
                                                                            <input type="text" className="form-control textoFormularioDepositantedirecto" onChange={(e) => guardarCantidad(e.target.value)} value={dividendoPorAccionStr} id="dividendoPorAccion" name="dividendoPorAccion" placeholder="" required />
                                                                            <label htmlFor="dividendoPorAccion" className=" textoFormularioDepositantedirecto">Dividendo por acción *</label>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                <div className="row mb-3">
                                                                    <div className="col-md-6 mb-3">
                                                                        <div className="row justify-content-center">
                                                                            <div onClick={() => handleInputFile("input-file1")} className="col-12 row justify-content-center">
                                                                                <div id="" onDragOver={(e) => dragOver(e, "text-click-uploadfile1", "drop-area1")} onDragLeave={(e) => dragLeave(e, "text-click-uploadfile1", "drop-area1")} onDrop={(e) => dropFile(e, "drop-area1", "text-click-uploadfile1")} className="form-floating col-12 boton drop-area1 row row-wrap justify-content-center align-items-center border-dashed py-5 px-2 rounded">
                                                                                    <i className="ri-upload-2-fill ri-3x text-center text-royal-blue"></i>
                                                                                    <h4 id="text-click-uploadfile1" value={nameFile1} className="text-center font-acumin-variable text-royal-blue cursor-pointer text-underline">{nameFile1}</h4>
                                                                                    <input placeholder="" id="input-file1" type="file" onChange={(e) => selectFile(e)} hidden />
                                                                                    <label htmlFor="nombreEmision" className=" textoFormularioDepositantedirecto">Cargar dictamen de pago de dividendos *</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-12 row">
                                                                                <div id="preview-file" className="col-12  g-0 mt-1">
                                                                                    <span className="text-royal-blue font-acumin-variable">Seleccione un archivo pdf</span>
                                                                                  
                                                                                    {archivoValido1 &&
                                                                                        <p className="text-royal-blue text-success font-acumin-variable ">Archivo válido</p>
                                                                                    }
                                                                                    {errorExtensionDoc1 &&
                                                                                        <p className="text-danger font-acumin-variable ">{errorExtensionDoc1}</p>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            {errorSinDocumento &&
                                                                                <>
                                                                                    <p className="error">Seleccione un archivo</p>
                                                                                </>
                                                                            }

                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                    <button type="submit" className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-1 background-royal-blue text-white bg-hover-gold">Notificar</button>
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </div>
    );


}

