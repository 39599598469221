import React, { useState } from "react";
import { useLocation } from "react-router-dom";

//Componentes
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";



//Hooks
import useFondosVirtuales from "../../../hooks/MercadoSecundario/useFondosVirtuales";



//API's
import { consultarFondosVirtualesDolar } from "../../../api/MercadoSecundario/consultarFondosVirtualesDolar";
import { consultarFondosVirtualesLempira } from "../../../api/MercadoSecundario/consultarFondosVirtualesLempira";



//Muestra las cuentas de un depositante indirecto
export default function ConsultarFondosVirtuales({ history }) {
    const location = useLocation();
    const tipo = location.state;
   // const dataAlreadyLoaded = history.location.state;

    //If endpoint got the data will be true    


    const [erroreshttp, setErroreshttp] = useState();
    const [spin, setSpin] = React.useState(true)
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()




    React.useEffect(() => {
        traerInformacionFondosVirtuales()
    }, []);

  


    const traerInformacionFondosVirtuales = async () => {
        var response
        if(tipo===1){
          response = await consultarFondosVirtualesLempira()
        }else{
            response = await consultarFondosVirtualesDolar()
        }
       
        //console.log(response)
        if (response) {
            setSpin(false)
            if ((isNaN(response.error)) === false) {
                setErroreshttp(response)
            } else {
                setData(response.listaFondos)
                setCopia(response)
            }
        }
    }

    const devolucion = (row) => {
        //console.log(row.original)
        history.push({
            pathname: '/formularioDevolucion', state: { objMenu: row.original }

        })
    }


    return (
        <>
           
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            <div className="fluid-container">
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                         
                        <div className="col-12 mb-5">                        
                                 <BannerTitulo titulo="Fondos virtuales"></BannerTitulo>
                                    {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                        <div className="d-flex">
                                            <div className="container py-5">
                                                {copia &&
                                                    <>
                                                        <TablaMercado datos={data}  useValidate={useFondosVirtuales} displayInformation={devolucion}  textoBoton={"Devolución"} textTitle="Click para ver formulario de devolución" ></TablaMercado>
                                                    </>
                                                }
                                            
                                            </div>
                                        </div>
                                    }
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}


