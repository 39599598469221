import { useMemo } from "react";

export default function useEmisionColumns() {
  const columns = useMemo(
    () => [
      {
        Header: "Procedencia",
        accessor: "cehvalEmisionProcedenciaBcvStr"
      },
      {
        Header: "Nombre emisión",
        accessor: "cehvalEmisionNomEmi"
      },
      {
        Header: "Código emisión",
        accessor: "cehvalEmisionCodigo"
      },
    /*  {
        Header: "Estatus",
        accessor: "cehvalEmisionEstatus"
      },*/
      {
        Header: "Nombre emisor",
        accessor: "cehvalEmisorCodigo.cehvalEmisorNombreCom"
      },
      {
        Header: "Código emisor",
        accessor: "cehvalEmisorCodigo.cehvalEmisorCodigo"
      },
      {
        Header: "Monto",
        accessor: "cehvalEmisionMontoCadena"
      },
      {
        Header: "Series",
        accessor: "cehvalEmisionSeries"
      },
     /* {
        Header: "# Resolución",
        accessor: "cehvalEmisionNumeroResol"
      },
      {
        Header: "Fecha",
        accessor: "cehvalEmisionFechaRpmv"
      },
      {
        Header: "Repre. com",
        accessor: "cehvalEmisionRepresentanteComunObl"
      },
      {
        Header: "TIPO MONEDA",
        accessor: "catMonedaCatMonedaId.catMonedaDivisa"
      },
      {
        Header: "FORMA DE REPRESENTACIÓN",
        accessor: "cehvalEmisionDesmaterializada"
      },*/
    ],
    []
  );

  return columns;
}
