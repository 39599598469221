import React, { useState, useEffect } from "react";


//Components




import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";

//APIs
import { actualizarEstatusSolicitudDepositoValFis } from '../../../../api/resguardoValoresFisicos/registroValoresFisicos/actualizarEstatusSolicitudDepositoValFis';


export default function AutorizarSolicitudDepositanteValoresFisicos({ history }) {

    
    const [loadDataRowFailed, setLoadDataRowFailed] = useState();
    const [loadDataSuccessful, setLoadDataSuccessful] = useState();

    const [solicitudId, setSolicitudId] = useState();


    //Disable inputs info
    const [codigoDepositanteValFis, setCodigoDepositanteValFis] = useState();
    const [nombreValorFisico, setNombreValorFisico] = useState();
    const [nombreDepositanteValFis, setNombreDepositanteValFis] = useState();
    const [descripcionValor, setDescripcionValor] = useState();
    const [valorFisicoLugar, setValorFisicoLugar] = useState();


    //Input textarea reject field
    const [reasonReject, setReasonReject] = useState();
    //State to show textarea reject field
    const [showRejectField, setShowRejectField] = useState(false);

    //Loading
    const [loading, setLoading] = useState();

    //States to show modal when register is successful or not
    const [autorizacionExitosa, setAutorizacionExitosa] = useState();
    const [autorizacionFallida, setAutorizacionFallida] = useState();

    const [rechazoExitoso, setRechazoExitoso] = useState();
    const [rechazoFallido, setRechazoFallido] = useState();

    const [responseRequest, setResponseRequest] = useState();

    const [fechaDeposito, setFechaDeposito] = useState();
    const [fechaRetiro, setFechaRetiro] = useState();
    const [erroreshttp, setErroreshttp] = React.useState()



    useEffect(() => {
        loadRowData();
    }, []);

    //Handle load the data of the selected row to show data in front
    const loadRowData = () => {

        if (history.location.state === undefined) {
            setLoadDataRowFailed(true);
        } else {

            const row = history.location.state.objRow;

            //Row values 
            setCodigoDepositanteValFis(row.depositoValorFisicoCodigoDepositante);
            setNombreValorFisico(row.depositoValorFisicoNomCompleto);
            setNombreDepositanteValFis(row.depositoValorFisicoNombreDepo);
            setDescripcionValor(row.depositoValorFisicoDescripcionValor);
            setValorFisicoLugar(row.depositoValorFisicoLugar);
            setFechaDeposito(row.depositoValorFisicoFechaDepoCadena);
            setFechaRetiro(row.depositoValorFisicoFechaRetiroCadena);
            //Need the id to update the status of the applications
            setSolicitudId(row.depositoValorFisicoId);

            //Show form once our states have the data row
            setLoadDataSuccessful(true);
        }
    }



    //Handle authorize application 
    const authorizeRequest = async (e) => {
        e.preventDefault();

        try {
            const objAuthorization = {
                idSolicitud: solicitudId,
                estatus: 2,
            }
            //console.log(objAuthorization)
            setLoading(true);
            const response = await actualizarEstatusSolicitudDepositoValFis(objAuthorization);
            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    setLoading(false);
                    //console.log(response);

                    if (response.data.isSuccessful === true) {
                        setAutorizacionExitosa(true);
                        setResponseRequest(response.data.mensaje);
                    } else {
                        setLoading(false);
                        setAutorizacionFallida(true);
                        setResponseRequest(response.data.mensaje);
                    }
                }
            }


        } catch (error) {
            console.error(error);
        }
    }


    //Handle reject application 
    const rejectRequest = async (e) => {
        e.preventDefault();

        try {
            const objReject = {
                idSolicitud: solicitudId,
                descripcion: reasonReject,
                estatus: 0,
            }
            //console.log(objReject)

            setLoading(true);
            const response = await actualizarEstatusSolicitudDepositoValFis(objReject);

            if (response) {
                //setCargando(false)
                setLoading(false);
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    setLoading(false);
                    //console.log(response)

                    if (response.data.isSuccessful === true) {
                        setRechazoExitoso(true);
                        setResponseRequest(response.data.mensaje);
                    } else {
                        setRechazoFallido(true);
                        setResponseRequest(response.data.mensaje);
                    }
                }
            }


        } catch (error) {
            console.error(error);
        }
    }

    //Shows the reject field
    const showReject = () => {
        if (showRejectField === false) {
            setShowRejectField(true);
        } else {
            setShowRejectField(false);
        }
    }


    //Handle modal button, go back to the table of applications
    const goToInicioCehval = () => {
        setAutorizacionExitosa(false)
        history.push({
            pathname: '/solicitudesDepositoValFisAutorizar',
        })
    }


    return (
        <>
          {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {loading &&
                <PantallaCargando />
            }
            {loadDataRowFailed &&
                <ComponenteModal mostrarModal={loadDataRowFailed} cerrarModal={goToInicioCehval} titulo={`Ocurrio algún error, será redireccionado al inicio. Revise su conexión o vuelva a iniciar sesión.`} textoBoton="Aceptar"></ComponenteModal>
            }

            {autorizacionExitosa &&
                <ComponenteModal mostrarModal={autorizacionExitosa} cerrarModal={goToInicioCehval} titulo={responseRequest} textoBoton="Aceptar"></ComponenteModal>
            }
            {autorizacionFallida &&
                <ComponenteModal mostrarModal={autorizacionFallida} cerrarModal={() => { setAutorizacionFallida(false) }} titulo={responseRequest} textoBoton="Aceptar"></ComponenteModal>
            }
            {rechazoExitoso &&
                <ComponenteModal mostrarModal={rechazoExitoso} cerrarModal={goToInicioCehval} titulo={responseRequest} textoBoton="Aceptar"></ComponenteModal>
            }
            {rechazoFallido &&
                <ComponenteModal mostrarModal={rechazoFallido} cerrarModal={() => { setRechazoFallido(false) }} titulo={responseRequest} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="fluid-container">

                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        {/*<div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 d-flex flex-column">
                            <BarraLateral2  ></BarraLateral2>
                        </div>*/}
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Autorizar solicitud de depósito de valores físicos"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <section className="p-5 d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded p-3">
                                                <div className="col-12">
                                                    {loadDataSuccessful &&
                                                        <form className="needs-validation font-acumin-variable text-royal-blue">
                                                            <div className="row mt-4">
                                                                <div className="col-12 col-md-12 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Código depositante transferente" value={nombreValorFisico} className="form-control text-royal-blue" name="nombreCompletoValor" type="text" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="nombreCompletoValor">Nombre completo del valor</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Código depositante transferente" value={codigoDepositanteValFis} className="form-control text-royal-blue" name="codigoDepositanteValoresFisicos" type="text" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="codigoDepositanteValoresFisicos">Código de depositante de valores físicos</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Código depositante transferente" value={nombreDepositanteValFis} className="form-control text-royal-blue" name="nombreDepositanteValoresFisicos" type="text" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="nombreDepositanteValoresFisicos">Nombre del depositante de valores físicos</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 form-floating mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" value={fechaDeposito} className="form-control border" id="fechaDeposito" name="fechaDeposito" required disabled />
                                                                        <label htmlFor="fechaDeposito" className="form-label ">Fecha de depósito </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 form-floating mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" value={fechaRetiro} className="form-control border" id="fechaRetiro" name="fechaRetiro" disabled />
                                                                        <label htmlFor="fechaRetiro" className="form-label ">Fecha de retiro</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 mb-3">
                                                                    <div className="form-floating ">
                                                                        <textarea title="Cantidad de valores a transferir" value={descripcionValor} className="form-control fs-5 text-royal-blue rejectionReason" name="descripcionValor" type="text" required disabled ></textarea>
                                                                        <label className="text-start font-acumin-variable" htmlFor="descripcionValor">Descripción valor</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Código depositante transferente" value={valorFisicoLugar} className="form-control text-royal-blue" name="lugarRegistro" type="text" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="lugarRegistro">Lugar del registro</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                    <button type="button" onClick={showReject} className="btn col-6 col-sm-3 col-md-3 col-lg-3 col-xl-2 background-royal-blue text-white bg-hover-red me-2">Rechazar</button>
                                                                    <button type="button" onClick={authorizeRequest} className="btn col-6 col-sm-3 col-md-3 col-lg-3 col-xl-2 background-royal-blue text-white bg-hover-gold">Autorizar</button>
                                                                </div>
                                                                {showRejectField &&
                                                                    <>
                                                                        <div className="col-12 col-md-12 mb-3">
                                                                            <div className="form-floating ">
                                                                                <textarea title="Cantidad de valores a transferir" onChange={(e) => setReasonReject(e.target.value)} className="form-control fs-5 text-royal-blue rejectionReason" name="motivoRechazo" type="text" placeholder="" required ></textarea>
                                                                                <label className="text-start font-acumin-variable" htmlFor="motivoRechazo">Motivo del rechazo</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                            <button type="button" onClick={rejectRequest} className="btn background-royal-blue text-white bg-hover-gold" title="Clic para confirmar rechazo de solicitud">Confirmar</button>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </form>
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    )
}