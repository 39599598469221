import { useMemo } from "react";

export default function useColumns() {
 const columns = useMemo(
   () => [   
     
     {
       Header: "Nombre usuario",
       accessor: "usuario"
     },
     {
       Header: "Rol",
       accessor: "rol"
     }, 
     {
        Header:"Fecha",
        accessor: "fecha"
      },
      {
        Header: "Tipo de operación",
        accessor: "accion"
      },
      {
        Header:"IP",
        accessor: "ip"
      },
      {
        Header:"Estatus",
        accessor: "estatus"
      }
     
   ],
   []
 );

 return columns;
}