import { instance } from '../../../apiGlobal';

export async function registrarAvisoCobro (obj) {
      try {
        const response = await instance(`/tarificacion/registrar/avisocobro`, {
          method:"post",
          data:obj,
        })
        return response;
      } catch (error) {
        return error;
      }
}


