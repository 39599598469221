import { instance } from '../../apiGlobal';

export async function actualizarEstatusDeSolicitudLiberacion(obj) {
      try {
        const response = await instance(`/prendasgarantias/actualizar/liberacion/estatus`, {
          method:"post",
          data:obj
        })
        return response;
      } catch (error) {
        return error
      }
}
  