import React from 'react';

//Images
import noAutorizado from '../../assets/logosCehval/noAutorizado.jpg'



const NoAutorizado = ({ history }) => {

    const goBack = () => {
        //console.log("entra")
        history.go(-1);
    }
    return (
        <>

            
            <main className="min-height-40" >
            

                <div className="container text-center mt-5" >
                    <div className="mb-5 " >
                        <div className="card-body ">
                            <img className="rounded" src={noAutorizado} alt="imagenFondo" />
                        </div>
                    </div>
                    <h1>No autorizado</h1>
                    <br />
                    <p>No tienes acceso a esta página.</p>
                    <div className="flexGrow mb-5">
                        <button type="button" className="btn btn-outline-secondary" onClick={goBack}>Regresar</button>

                    </div>
                </div>
            </main >

            


        </>

    )
}

export default NoAutorizado