import { instance } from '../apiGlobal';

export async function consultarPagosDerechosPatrimoniales() {

      try {
        const response = await instance(`/pagoderechos/patrimoniales`, {
            headers: {
              method: 'get',
            }
          });
        return response.data;
      } catch (error) {
        return error;
      }
}