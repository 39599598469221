import React, { useState } from "react";
//import { Link, useHistory } from "react-router-dom";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
//import SpinnerSend from "../../../ComponentesGenerales/SpinnerSend";
import PantallaCargando from '../../../ComponentesGenerales/PantallaCargando'

//APIs
import { registrarDesmaterializacion } from "../../../api/desmaterializar/registrarDesmaterializacion";
import { traerEmisionesParaDesmaterializar } from "../../../api/desmaterializar/traerEmisionesParaDesmaterializar";


export default function SolicitudDesmaterializacion({ history }) {
    const [registroExitoso, setRegistroExitoso] = React.useState()
    const [registroFallido, setRegistroFallido] = React.useState()

    // const [nombreContacto, setNombreContacto] = React.useState()

    const [errorUsuarioExistente, setErrorUsuarioExistente] = React.useState();
    //const [spinSend, setSpinSend] = React.useState(false)
    const [erroreshttp, setErroreshttp] = React.useState()
    const [idEmisor, setIdEmisor] = React.useState()
    const [codigoEmisor, setCodigoEmisor] = React.useState()
    const [nombreEmisor, setNombreEmisor] = React.useState()
    const [codigoEmision, setCodigoEmision] = React.useState()
    const [errorcodigoEmision, setErrorCodigoEmision] = React.useState()
    const [nombreEmision, setNombreEmision] = React.useState()
    const [fechaDocumento1, setFechaDocumento1] = React.useState()
    const [fechaDocumento2, setFechaDocumento2] = React.useState()
    const [lugarRegistro, setLugarRegistro] = React.useState()
    const [mensaje, setMensaje] = React.useState()
    const [sinEmisiones, setSinEmisiones] = React.useState()
    const [loading, setLoading] = React.useState(false);



    //leer documentos
    //If endpoint got the data will be true
    const [getEmisionData, setGetEmisionData] = useState(false);

    //Stores the json with emisors  


    //The name of the users file
    //const [nameFile, setNameFile] = useState();
    const [nameFile1, setNameFile1] = useState("Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro");
    const [nameFile2, setNameFile2] = useState("Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro");
    //The file

    const [file1, setFile1] = useState();
    const [file2, setFile2] = useState();
    //Shows the loading screen when y set to true


    const [error, setError] = useState();
    const [errorExtensionDoc1, setErrorExtensionDoc1] = useState();
    const [errorExtensionDoc2, setErrorExtensionDoc2] = useState();
    const [archivoValido1, setArchivoValido1] = useState();
    const [archivoValido2, setArchivoValido2] = useState();
    const [invalidFile, setInvalidFile] = useState(false);
    const [emisionesDisponibles, setEmisionesDisponibles] = useState([]);
    const [errorSinDocumento, setErrorSinDocumento] = useState();


    //const [fechaLimiteDocumentos, setFechaLimiteDocumentos] = React.useState()

    const onSubmitForm = async (e) => {
        e.preventDefault();

        if (file1) {
            setLoading(false)
            setErrorSinDocumento(false)
            const form = new FormData();
            form.append("solicitudDesFechaDoc1", fechaDocumento1);
            if (fechaDocumento2) {
                form.append("solicitudDesFechaDoc2", fechaDocumento2);
            }

            form.append("solicitudDesLugarReg", lugarRegistro);
            form.append("solicitudDesCodigoEmision", codigoEmision);
            form.append("solicitudDesCodigoEmisor", idEmisor);
            form.append("filedoc1", file1[0]);
            if (file2) {
                form.append("filedoc2", file2[0]);
            }
            ////console.log(form)

            const enviarDatos = await registrarDesmaterializacion(form)
            //console.log(enviarDatos)
            if (enviarDatos) {
                setLoading(false)
                if ((isNaN(enviarDatos.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(enviarDatos)
                } else {

                    if (enviarDatos?.codigo === "000") {
                        setRegistroExitoso(true)

                    } else {
                        setMensaje(enviarDatos.mensaje)
                        setRegistroFallido(true)
                    }
                }
            }
        } else {
            //console.log("entra aqui")
            setErrorSinDocumento(true)
            setLoading(false)
        }
    }

    React.useEffect(() => {
        setLoading(false)
    }, [errorSinDocumento])





    const checkLenght = (e) => {

        if (codigoEmisor) {
            if (codigoEmisor.length > 5) {
                traerDatos();
            }
        }

    }

    const LlamarTraerDatos = () => {
        traerDatos()
    }




    const traerDatos = async () => {

        const emisionesDisponibles = await traerEmisionesParaDesmaterializar({
            codigo: codigoEmisor
        })

        //console.log(emisionesDisponibles)
        if (emisionesDisponibles) {
            if ((isNaN(emisionesDisponibles.error)) === false) {
              
                setErroreshttp(emisionesDisponibles)
                
            } else {
                if (emisionesDisponibles.codigoEmisor) {
                    setIdEmisor(emisionesDisponibles.idEmisor)
                    setNombreEmisor(emisionesDisponibles.nombreEmisor)
                    setEmisionesDisponibles(emisionesDisponibles.emisiones)
                    setErrorCodigoEmision(false)
                    if (emisionesDisponibles.emisiones.length === 0) {
                        setSinEmisiones(true)
                        setErrorCodigoEmision(false)
                    } else {
                        setSinEmisiones(false)
                    }
                } else {
                    setIdEmisor("")
                    setNombreEmisor("")
                    setEmisionesDisponibles([])
                    setErrorCodigoEmision(true)
                    setSinEmisiones(false)
                }

                // //console.log(traerInformacion)
                //setSectores(emisionesDisponibles)
            }
        }

    }



    //leer documentos
    //Open input file when click on h4 text
    const handleInputFile = (nombre) => {
        //Select our input tag 
        const input = document.querySelector(`#${nombre}`);
        //Give the event click to our input
        input.click();
    }

    // HANDLE WHEN USER SELECT A FILE WHEN CLICK ON TEXT
    const selectFile = (e, nombre) => {
        //Cleans error message if was one

        if (nombre === "doc1")
            setErrorExtensionDoc1("");
        else
            setErrorExtensionDoc2("");

        // setGetEmisionData(false);

        const file = e.target.files;
        //Name of the file to validate if has the correct extension
        const fileName = e.target.files[0].name;
        const fileSize = e.target.files[0].size;

        //Regex to validate the extension of the file
        const extensionsAllow = /(.pdf)$/i;

        ////console.log(pathFile.files)
        //If selected file doesnt have correct extension show user error message
        if (!extensionsAllow.exec(fileName)) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file

            if (nombre === "doc1") {
                setErrorExtensionDoc1("Selecciona un archivo válido");
                setArchivoValido1(false)
                //Clean our states if they had any information
                setFile1("");
                setNameFile1("Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro");
            } else {
                setErrorExtensionDoc2("Selecciona un archivo válido");
                setArchivoValido2(false)
                //Clean our states if they had any information
                setFile2("");
                setNameFile2("Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro");
            }


            return false;

        } else if (fileSize > 10_000_000) {
            //Shows modal with an error message
            setInvalidFile(true);

            //Clean file name if a file was selected before
            if (nombre === "doc1") {
                setErrorExtensionDoc1("El tamaño del archivo debe ser menor a 10 MB");
                setArchivoValido1(false)
                //Clean our states if they had any information
                setFile1("");
                setNameFile1("Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro");
            } else {
                setErrorExtensionDoc2("El tamaño del archivo debe ser menor a 10 MB");
                setArchivoValido2(false)
                //Clean our states if they had any information
                setFile2("");
                setNameFile2("Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro");
            }

        } else if (e.target.files && e.target.files[0]) {
            setErrorSinDocumento(false)
            //console.log(nombre)
            if (nombre === "doc1") {
                //Shows the name of the selected file to the user
                setNameFile1(fileName);
                //Set FileList [File] to our state
                setFile1(file);
                setArchivoValido1(true)
            } else {
                //Shows the name of the selected file to the user
                setNameFile2(fileName);
                //Set FileList [File] to our state
                setFile2(file);
                setArchivoValido2(true)
            }
        }
    }

    // HANDLE USER DROP FILE
    const dropFile = (e, nombre, dropArea, texto) => {

        e.preventDefault();
        //console.log("dropFile")
        //Cleans error message if was one
        setError("");
        setGetEmisionData(false);
        //Removes the active class from container when user drops a file
        document.querySelector(`.${dropArea}`).classList.remove("active");
        //Shows the same text before dropping a file


        //Show user the selected file and validates in codition if file has a valid extension 
        const fileName = e.dataTransfer.files[0].name;
        const fileSize = e.dataTransfer.files[0].size;
        //Regex to validate file extension
        const extensionsAllow = /(.pdf)$/i;

        const inputField = document.querySelector(`#${texto}`);


        if (!extensionsAllow.exec(fileName)) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file

            if (nombre === "doc1") {
                setArchivoValido1(false)
                setErrorExtensionDoc1("Selecciona un archivo válido");
                setFile1("");
                setNameFile1("Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro");
                inputField.innerHTML = "Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro";
            } else {
                setArchivoValido2(false)
                setErrorExtensionDoc2("Selecciona un archivo válido");
                setFile2("");
                setNameFile2("Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro");
                inputField.innerHTML = "Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro";
            }

            return false;

        } else if (fileSize > 10_000_000) {
            //Shows modal with an error message
            setInvalidFile(true);

            //Clean file name if a file was selected before
            if (nombre === "doc1") {
                setErrorExtensionDoc1("El tamaño del archivo debe ser menor a 10 MB");
                setArchivoValido1(false)
                //Clean our states if they had any information
                setFile1("");
                setNameFile1("Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro");
            } else {
                setErrorExtensionDoc2("El tamaño del archivo debe ser menor a 10 MB");
                setArchivoValido2(false)
                //Clean our states if they had any information
                setFile2("");
                setNameFile2("Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro");
            }

        } else if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setErrorSinDocumento(false)
            if (nombre === "doc1") {
                setErrorExtensionDoc1(false);
                //Shows the name of the selected file to the user
                setNameFile1(fileName);
                //Set FileList [File] to our state
                setFile1(e.dataTransfer.files);
                setArchivoValido1(true)
                inputField.innerHTML = fileName;
            } else {
                setErrorExtensionDoc2(false);
                //Shows the name of the selected file to the user
                setNameFile2(fileName);
                //Set FileList [File] to our state
                setFile2(e.dataTransfer.files);
                setArchivoValido2(true)
                inputField.innerHTML = fileName;
            }

        }



        // const inputField = document.querySelector('#text-click-uploadfile1');
        // inputField.innerHTML = nameFile1;
    }

    //Handle when user drag over a file 
    const dragOver = (e, nombre, dropArea) => {

        e.preventDefault();
        //console.log("dragOver")
        //Adds the active class to container to change the background

        const moveOver = document.querySelector(`.${dropArea}`);
        moveOver.classList.add("active");
        //Change the text inside box

        const inputField = document.querySelector(`#${nombre}`);
        inputField.innerHTML = "Suelte el archivo";
    }

    //Handle when user leaves the drop area whitout drop any file
    const dragLeave = (e, nombre, dropArea) => {
        e.preventDefault();
        //Removes the active class to container to change the background
        const moveLeave = document.querySelector(`.${dropArea}`);
        moveLeave.classList.remove("active");
        //Change the text inside box 
        const inputField = document.querySelector(`#${nombre}`);
        if (nombre === "text-click-uploadfile1") {
            if (file1) {
                inputField.innerHTML = nameFile1;
            } else {
                inputField.innerHTML = "Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro";
            }
        } else {
            if (file1) {
                inputField.innerHTML = nameFile2;
            } else {
                inputField.innerHTML = "Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro";
            }
        }

    }

    // HANDLE WHEN USER CLICK BUTTON TO READ THE FILE
    /*const readFile = async (e) => {
        e.preventDefault();
        try {
            //Shows error massage when user doesnt select any file and click on button
            if (file === undefined) {
                setError("Por favor seleccione un archivo");
                return;
            }
            //If file is not the right type of file doesnt allow user to click "leer registros"
            if (file[0] === undefined || file[0] === "" || file[0] === null || file[0] === "string") {
                setError("Por favor seleccione un archivo");
                //console.log("no hay archivo")
            } else {
                //Clean error message if showed 
                setError("");
                //Make the query to data base to read the file and return the json

                //Shows loading screen until get the response
                setLoading(true);

                let formFile = new FormData();
                formFile.append('file', file[0]);
                //  const response = await lecturaArchivoEmisiones(formFile);
                //  //console.log(response.data)
                //setEmisorsData(response.data);

                //When await gets the data hides loading screen
                setLoading(false);

                setGetEmisionData(true);
            }

        } catch (error) {
            console.error(error);
        }
    }*/

    const seleccionarEmision = (value) => {
        // //console.log(value)
        setCodigoEmision(value)
        for (let index = 0; index < emisionesDisponibles.length; index++) {
            if (emisionesDisponibles[index].idEmision == value) {
                setNombreEmision(emisionesDisponibles[index].nombreEmision)
            }

        }

        /*emisionesDisponibles.map((item) => {
            if (item.idEmision == value) {
                setNombreEmision(item.nombreEmision)
            }
        }
        )*/
    }



    const irInicio = () => {
        setRegistroExitoso(false)
        history.go(-1)
    }

    return (
        <div className="fluid-container">
            {invalidFile &&
                <ComponenteModal mostrarModal={invalidFile} cerrarModal={() => { setInvalidFile(false) }} titulo="Archivo inválido. Por favor seleccione un archivo PDF y con un peso menor a 10 MB" textoBoton="Aceptar"></ComponenteModal>
            }
            {loading &&
                <PantallaCargando />
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {errorUsuarioExistente &&
                <ComponenteModal mostrarModal={errorUsuarioExistente} cerrarModal={() => { setErrorUsuarioExistente(false) }} titulo="Este correo ya esta registrado, ingresa otro" textoBoton="Entendido"></ComponenteModal>
            }
            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={irInicio} titulo="Solicitud de desmaterialización enviada" textoBoton="Entendido"></ComponenteModal>
            }
            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={() => { setRegistroFallido(false) }} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }


            <main className="mb-5">
                <div className="row row-wrap g-0">

                    
                    <div className="col-12">
                        <div className="">
                            <div className="row d-flex justify-content-center g-0">
                                <div className="col-12">
                                    <BannerTitulo titulo="Solicitud de desmaterialización de valores"></BannerTitulo>
                                    <div className="px-3 px-lg-4 px-xl-4 px-xxl-5 border-none">
                                        <div className="card-body ">
                                            <div className="col-12 mx-auto">
                                                <form className="row g-0 needs-validation" novalidate onSubmit={(e) => {
                                                    onSubmitForm(e)
                                                    setLoading(true)
                                                }}>
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="row mb-3">
                                                                <div className="col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" value={codigoEmisor} onChange={(e) => setCodigoEmisor(e.target.value)} onKeyUp={(e) => checkLenght(e)} id="codigoEmisor" name="codigoEmisor" placeholder="" required />
                                                                        <label htmlFor="codigoEmisor" className=" textoFormularioDepositantedirecto">Código del emisor *</label>
                                                                        {errorcodigoEmision && !sinEmisiones &&
                                                                            <>
                                                                                <p className="error m-0 mt-1">Ingrese un código de emisor valido</p>
                                                                            </>
                                                                        }
                                                                        {sinEmisiones && !errorcodigoEmision &&
                                                                            <>
                                                                                <p className="error m-0 mt-1">Este emisor no tiene emisiones para desmaterializar</p>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" value={nombreEmisor} id="nombreEmisor" name="nombreEmisor"  required disabled />
                                                                        <label htmlFor="nombreEmisor" className=" textoFormularioDepositantedirecto">Nombre del emisor *</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-3">
                                                                <div className="col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <select className="form-select " value={codigoEmision} onChange={(e) => seleccionarEmision(e.target.value)} id="nacionalidadPersonaJuridica" name="nacionalidadPersonaJuridica" required>
                                                                            <option value=""> </option>
                                                                            {
                                                                                emisionesDisponibles.map((item, index) => (
                                                                                    <option key={index} value={item.idEmision} >{item.codigoEmision}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Código de la emisión *</label>
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" value={nombreEmision} id="nombreEmision" name="nombreEmision"  required disabled />
                                                                        <label htmlFor="nombreEmision" className=" textoFormularioDepositantedirecto">Nombre de la emisión *</label>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div className="row mb-3">
                                                                <div className="col-md-6 mb-3">
                                                                    <div className="row justify-content-center">
                                                                        <div onClick={() => handleInputFile("input-file1")} className="col-12 row justify-content-center">
                                                                            <div id="" onDragOver={(e) => dragOver(e, "text-click-uploadfile1", "drop-area1")} onDragLeave={(e) => dragLeave(e, "text-click-uploadfile1", "drop-area1")} onDrop={(e) => dropFile(e, "doc1", "drop-area1", "text-click-uploadfile1")} className="form-floating col-12 boton drop-area1 row row-wrap justify-content-center align-items-center border-dashed py-5 px-2 rounded">
                                                                                <i className="ri-upload-2-fill ri-3x text-center text-royal-blue"></i>
                                                                                <h4 id="text-click-uploadfile1" value={nameFile1} className="text-center font-acumin-variable text-royal-blue cursor-pointer text-underline">{nameFile1}</h4>
                                                                                <input placeholder="" id="input-file1" type="file" onChange={(e) => selectFile(e, "doc1")} hidden />
                                                                                <label htmlFor="nombreEmision" className=" textoFormularioDepositantedirecto">Carga del documento 1 *</label>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-12 row">
                                                                            <div id="preview-file" className="col-12  g-0 mt-1">
                                                                                <span className="text-royal-blue font-acumin-variable">Seleccione un archivo pdf</span>
                                                                                {/*}  {nameFile1 &&
                                                                                    <p className="text-royal-blue text-success font-acumin-variable fs-5">Archivo válido</p>
                                                                                }*/}
                                                                                {archivoValido1 &&
                                                                                    <p className="text-royal-blue text-success font-acumin-variable ">Archivo válido</p>
                                                                                }
                                                                                {errorExtensionDoc1 &&
                                                                                    <p className="text-danger font-acumin-variable ">{errorExtensionDoc1}</p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        {errorSinDocumento &&
                                                                            <>
                                                                                <p className="error">Seleccione un archivo</p>
                                                                            </>
                                                                        }

                                                                        {/*} <div className="col-12 row ">
                                                                            <div className="col-6 mt-1 g-0">
                                                                                <button onClick={readFile} className="btn background-royal-blue bg-hover-gold text-white font-acumin-variable">Leer registros</button>
                                                                            </div>
                                                                        </div>*/}
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6 mb-3">
                                                                    <div className="row justify-content-center">
                                                                        <div onClick={() => handleInputFile("input-file2")} className="col-12 row justify-content-center">
                                                                            <div id="" onDragOver={(e) => dragOver(e, "text-click-uploadfile2", "drop-area2")} onDragLeave={(e) => dragLeave(e, "text-click-uploadfile2", "drop-area2")} onDrop={(e) => dropFile(e, "doc2", "drop-area2", "text-click-uploadfile2")} className="col-12 form-floating boton drop-area2 row row-wrap justify-content-center align-items-center border-dashed py-5 px-2 rounded">
                                                                                <i className="ri-upload-2-fill ri-3x text-center text-royal-blue"></i>
                                                                                <h4 id="text-click-uploadfile2" value={nameFile2} className="text-center font-acumin-variable text-royal-blue cursor-pointer text-underline">{nameFile2}</h4>
                                                                                <input id="input-file2" type="file" onChange={(e) => selectFile(e, "doc2")} hidden />
                                                                                <label htmlFor="nombreEmision" className=" textoFormularioDepositantedirecto">Carga del documento 2</label>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-12 row">
                                                                            <div id="preview-file" className="col-12  g-0 mt-1">
                                                                                <span className="text-royal-blue font-acumin-variable">Seleccione un archivo pdf</span>
                                                                                {/*}  {nameFile1 &&
                                                                                    <p className="text-royal-blue text-success font-acumin-variable fs-5">Archivo válido</p>
                                                                                }*/}
                                                                                {archivoValido2 &&
                                                                                    <p className="text-royal-blue text-success font-acumin-variable ">Archivo válido</p>
                                                                                }
                                                                                {errorExtensionDoc2 &&
                                                                                    <p className="text-danger font-acumin-variable ">{errorExtensionDoc2}</p>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        {/*} <div className="col-12 row ">
                                                                            <div className="col-6 mt-1 g-0">
                                                                                <button onClick={readFile} className="btn background-royal-blue bg-hover-gold text-white font-acumin-variable">Leer registros</button>
                                                                            </div>
                                                                        </div>*/}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="date" className="form-control textoFormularioDepositantedirecto" value={fechaDocumento1} onChange={(e) => setFechaDocumento1(e.target.value)} id="fechaDocumento1" name="fechaDocumento1" placeholder="" required />
                                                                        <label htmlFor="fechaDocumento1" className=" textoFormularioDepositantedirecto">Fecha documento 1*</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        {file2 ?
                                                                            <>
                                                                                <input type="date" className="form-control textoFormularioDepositantedirecto" value={fechaDocumento2} onChange={(e) => setFechaDocumento2(e.target.value)} id="fechaDocumento2" name="fechaDocumento2" placeholder="" required />
                                                                                <label htmlFor="fechaDocumento2" className=" textoFormularioDepositantedirecto">Fecha documento 2*</label>
                                                                            </>

                                                                            :
                                                                            <>
                                                                                <input type="date" className="form-control textoFormularioDepositantedirecto" value={fechaDocumento2} onChange={(e) => setFechaDocumento2(e.target.value)} id="fechaDocumento2" name="fechaDocumento2" placeholder="" />
                                                                                <label htmlFor="fechaDocumento2" className=" textoFormularioDepositantedirecto">Fecha documento 2</label>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-md-6 m">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" value={lugarRegistro} onChange={(e) => setLugarRegistro(e.target.value)} id="lugarRegistro" name="lugarRegistro" placeholder="" required />
                                                                        <label htmlFor="codigoEmision" className=" textoFormularioDepositantedirecto">Lugar de registro *</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-12 mb-3 row g-0 g-md-4 g-lg-4 g-xl-4 g-xxl-4 justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end">
                                                            <div className="form-floating  row col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-1 px-3">
                                                                <button type="button" onClick={irInicio} className="btn background-royal-blue text-white bg-hover-gold p-0" >Regresar</button>
                                                            </div>
                                                            <div className="form-floating  row col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 col-xxl-1 px-3">
                                                                <button type="submit" className="btn background-royal-blue text-white bg-hover-gold" >Enviar</button>
                                                            </div>
                                                        </div>
                                                        {/*{spinSend &&
                                                            <SpinnerSend textoSpinner="Enviando..."></SpinnerSend>
                                                        }*/}
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </div>
    );


}

