import React from "react";
import { Modal } from "react-bootstrap";
import { useRef } from 'react';
import jsPDF from 'jspdf';

//Components

import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../../ComponentesGenerales/SpinnerStart";
//import ModalConSpinner from "../../../../ComponentesGenerales/ModalConSpinner";
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";

import ActualizarUsuarios from "../ActualizarUsuarios";
import ActualizarCorreo from '../ActualizarCorreo'


import { FormProvider, FormContext } from './form_context';
import { FormProvider2, FormContext2 } from './form_context2';
import { FormProvider3, FormContext3 } from './form_context3';


//APIs
//import { enviarRegistroEmisor } from "../../../../api/emisor/enviarRegistroEmisor";
import { traerNacionalidad } from "../../../../api/catalogos/traerNacionalidad";
import { traerSectores } from "../../../../api/catalogos/traerSectores";
import { traerSubSectores } from "../../../../api/catalogos/traerSubSectores";
import { ususarioExistente } from "../../../../api/UsuarioExistente/usuarioExistente";
import { traerBancos } from "../../../../api/catalogos/traerBancos";
import { traerTiposCuenta } from "../../../../api/catalogos/traerTiposCuenta";
import { traerDatosDepositanteParaEmisor } from "../../../../api/emisor/traerDatosDepositnateParaEmisor";
import { traerCasasBolsa } from '../../../../api/catalogos/traerCasasBolsa'
import { traerInformacionEmisorCodigo } from "../../../../api/VerInformacionDeEmisor.js/traerInformacionEmisorCodigo";
import { actualizarEmisor } from "../../../../api/mantenimientoCargaValores/actualizarEmisor";
import { imprimirEmisorRegistrado } from "../../../../api/Documentos/imprimirEmisorRegistrado";


const Form = ({ history }) => {
    const [value, setValue] = React.useContext(FormContext);
    const [value2, setValue2] = React.useContext(FormContext2)
    const [value3, setValue3] = React.useContext(FormContext3)

    const [sectores, setSectores] = React.useState([])
    const [subSectores, setSubSectores] = React.useState([])
    const [nacionalidades, setNacionalidades] = React.useState([])
    const [casasBolsa, setCasasBolsa] = React.useState([])
    const [modificarCorreo,setModificarCorreo]=React.useState()

    //  const [codigo, setCodigo] = React.useState("")
    //const [nombreCompletoEmisor, setNombreCompletoEmisor] = React.useState()
    // const [rtn, setRtn] = React.useState()
    //const [sector, setSector] = React.useState()
    //const [nacionalidad, setNacionalidad] = React.useState()
    //const [subSector, setSubSector] = React.useState()
    //const [paginaWeb, setPaginaWeb] = React.useState()
    const [bancos, setBancos] = React.useState([]);
    const [tiposCuenta, setTiposCuenta] = React.useState([]);
    //const [bancoNacional, setBancoNacional] = React.useState()
    //const [bancoExtranjero, setBancoExtranjero] = React.useState()
    //const [tipoCuentaNacional, setTipoCuentaNacional] = React.useState()
    //const [tipoCuentaExtranjera, setTipoCuentaExtranjera] = React.useState()
    //const [numeroCuentaNacional, setNumeroCuentaNacional] = React.useState()
    //const [numeroCuentaExtranjera, setNumerocuentaExtranjera] = React.useState()


    // const [email, setEmail] = React.useState()
    //const [codigoDepositante, setCodigoDepositante] = React.useState()

    //const [registroExitoso, setRegistroExitoso] = React.useState()
    //const [registroFallido, setRegistroFallido] = React.useState()

    const [errorUsuarioExistente, setErrorUsuarioExistente] = React.useState();
    //const [spinSend, setSpinSend] = React.useState(false)
    const [erroreshttp, setErroreshttp] = React.useState()
    //const [correoContacto, setCorreoContacto] = React.useState()
    //const [nombreContacto, setNombreContacto] = React.useState()
    //const [depositante, setDepositnate] = React.useState("")

    //const [errorCodigo, setErrorCodigo] = React.useState()
    //const [nombreCompletoRepresentanteLegal, setNombreCompletoRepresentanteLegal] = React.useState()
    //const [mensaje, setMensaje] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [informacionUsuario, setInformnacionUsuario] = React.useState()
    
    const [sinCambios, setSinCambios] = React.useState()
    const [sinCambiosImpresion, setSinCambiosImpresion] = React.useState()
    const [confirmacionCancelar, setConfirmacionCancelar] = React.useState()
    const [actualizacionCorrecta, setActualizacionCorrecta] = React.useState(false)
    const [actualizacionIncorrecta, setActualizacionIncorrecta] = React.useState(false)
    const [contador, setContador] = React.useState(0)
    const [condicion, setCondicion] = React.useState()
    const [correo, setCorreo] = React.useState(value2.datos.correo1)
    const [loading, setLoading] = React.useState(false);
    const [cambiosHechos, setCambiosHechos] = React.useState()
    const [actualizarUsuario, setActualizarUsuario] = React.useState()
    // const [informacionUsuario, setInformnacionUsuario] = React.useState()
    const [contactosOriginales, setContactosOriginales] = React.useState()
    const [contactosValidar, setContactosValidar] = React.useState()
    const [tipoParticipante, setTipoParticipante] = React.useState()
    const [representanteLegalObligatorio, setRepresentanteLegalObligatorio] = React.useState()

    const [generacionUsuario, setGeneracionUsuario] = React.useState()
    const [consultaGeneracionUsusario, setConsultaGeneracionUsusario] = React.useState()
    






    //const [fechaLimiteDocumentos, setFechaLimiteDocumentos] = React.useState()

    const guardarDatos = (e) => {
        const { name, value } = e.target;
        setCambiosHechos(true)

        if (name.indexOf("correo") >= 0) {
            //validarUsuario(name, value)
            setValue2((prev) => {
                const datos = { ...prev.datos, [name]: value.toLowerCase() };
                return { ...prev, datos };
            });

        } else {
            setValue2((prev) => {
                //const datos = { ...prev.datos, [name]: value };
                const datos = { ...prev.datos, [name]: value };
                return { ...prev, datos };
            });
        }

    }

    const validarUsuario = async (name, value) => {
        if (value != value2.datos.correoRepresentanteLegal) {

            validacionCorreoFinal(name, value)
        }
    }

    const validacionCorreoFinal = async (name, value) => {
        value = value.replace(/\s+/g, '')

        const obj = {
            correo: value
        }
        const resultado = await ususarioExistente(obj)
        //console.log(resultado)

        if (resultado) {
            //setSpin(false)
            if ((isNaN(resultado.error)) === false) {
              
                setErroreshttp(resultado)
                
            } else {

                if (resultado?.codigo === "000") {
                    // //console.log(name 
                    setCorreo("")
                    //console.log(name)
                    document.getElementById(name).value = "";
                    setErrorUsuarioExistente(true)
                } else {

                    if (correoValido(value)) {
                        setValue2((prev) => {
                            const datos = { ...prev.datos, [name]: value.toLowerCase() };
                            return { ...prev, datos };
                        });
                    }

                }
            }
        }
    }

    const correoValido = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }


    React.useEffect(() => {
        async function registrarActualizacion() {

            const myObj = {
                emisorCodigo: value2.datos.codigoEmisor,
                nombreCompletoEmisor: value3.datos.nombreEmisor,
                sector: value3.datos.sector,
                subsector: value3.datos.subSector,
                nacionalidad: value3.datos.nacionalidad,
                rtn: value3.datos.rtn,
                paginaWeb: value3.datos.paginaWeb,

                nombreCompletoRepreLeg: value3.datos.nombreRepresentanteLegal,
                correoLaboral: value3.datos.correoRepresentanteLegal,
                emisorNombreContacto: "",
                emisorCorreoContacto: "",
                emisorCodigoDepositante: "",

                estatusActividad: "",
                estatusRegistro: "",

                cuentaNacional: {
                    moneda: "",
                    tipoCuenta: value3.datos.tipoCuentaN,
                    banco: value3.datos.bancoN,
                    numeroCuenta: value3.datos.numeroCuentaN

                },
                cuentaExtranjera: {
                    moneda: "",
                    tipoCuenta: value3.datos.tipoCuentaE,
                    banco: value3.datos.bancoE,
                    numeroCuenta: value3.datos.numeroCuentaE
                }
            }

            const myObj2 = {
                nombreCompletoRepreLeg: value3.datos.nombreRepresentanteLegal,
                correoLaboral: value3.datos.correoRepresentanteLegal,
            }

            const myObj3 = {
                nombreCompletoRepreLeg: value2.datos.nombreRepresentanteLegal,
                correoLaboral: value2.datos.correoRepresentanteLegal,
            }

            const myObj4 = {
                nombreCompletoRepreLeg: value.datos.nombreRepresentanteLegal,
                correoLaboral: value.datos.correoRepresentanteLegal,
            }


            //console.log(myObj)
            setLoading(true)
            const mandarInformacionEmisor = await actualizarEmisor(myObj)
            //console.log(mandarInformacionEmisor)
            if (mandarInformacionEmisor) {
                setLoading(false)
                if ((isNaN(mandarInformacionEmisor.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(mandarInformacionEmisor)
                } else {
                    if (mandarInformacionEmisor?.codigo === "000") {
                        //guardamos la informacion para gnerar un  uevo usuario
                        setInformnacionUsuario(myObj2)
                        setContactosOriginales(myObj3)
                        setContactosValidar(myObj4)
                        
                        //si se modifico el correo generampos un nuevo usuario sin preguntar
                     /*    if ( (value.datos.correoRepresentanteLegal != value2.datos.correoRepresentanteLegal)) {
                            setActualizarUsuario(true)
                            setActualizacionCorrecta(true)
                            //si solo se modifico el nombre preguntamos si queremos generar un nuevo usuario
                        }else */ if ((value.datos.nombreRepresentanteLegal != value2.datos.nombreRepresentanteLegal)|| (value.datos.correoRepresentanteLegal != value2.datos.correoRepresentanteLegal)) {
                            setConsultaGeneracionUsusario(true)
                        }else{
                            setActualizacionCorrecta(true)
                        }

                    } else {
                        setActualizacionIncorrecta(true)
                    }
                }
            }
            
        }

        if (contador > 0) {
            registrarActualizacion(); // Llamas la función
        }
        //  //console.log(contador)

    }, [value3])

    const onSubmitForm = async (e) => {
        e.preventDefault();
        if (condicion) {
            ////console.log(e.target.value)

            //  let objs = [value.datos, value2.datos]
            let values1 = Object.values(value.datos)
            let values2 = Object.values(value2.datos)
            let keys1 = Object.keys(value.datos);
            let contadorLocal = 0;

            for (let index = 0; index < keys1.length; index++) {
                if ((values1[index] != values2[index]) && (keys1[index] != "establecerModificacion")) {


                    //console.log(keys1[index])


                    if (!values2[index]) {
                        setValue3((prev) => {
                            const datos = { ...prev.datos, [keys1[index]]: " " };
                            return { ...prev, datos };
                        });
                    } else {
                        if(values2[index]===true||values2[index]===false){
                            setValue3((prev) => {
                                const datos = { ...prev.datos, [keys1[index]]: values2[index]};
                                return { ...prev, datos };
                            });
                        }else{
                            setValue3((prev) => {
                                const datos = { ...prev.datos, [keys1[index]]: values2[index].trim() };
                                return { ...prev, datos };
                            });
                        }

                     
                    }

                    contadorLocal++;
                }
            }

            setContador(contadorLocal)

            if (contadorLocal < 1) {
                setSinCambios(true);
            }
        }

    }

    const checarNumeros = (e) => {
        const { value, name } = e.target;
        let out = '';
        var filtro = '1234567890';
        var filtro2 = '1234567890-';


        for (var i = 0; i < value.length; i++)
            if (filtro2.indexOf(value.charAt(i)) != -1)
                //Se añaden a la salida los caracteres validos
                out += value.charAt(i);
        setValue2((prev) => {
            const datos = { ...prev.datos, [name]: out };
            return { ...prev, datos };
        });

        //   if (e.keyCode > 64 && e.keyCode < 91) {
        //     setRtn("");
        // }

    }

    React.useEffect(() => {
        traerDatos()
    }, [])




    const traerDatos = async () => {

        const traerInformacion = await traerInformacionEmisorCodigo({ codigo: history.location.state.objMenu.codigo });
        //console.log(traerInformacion)
        if (traerInformacion) {
            setSpin(false)
            if ((isNaN(traerInformacion.error)) === false) {
              
                setErroreshttp(traerInformacion)
                
            } else {
                // //console.log(traerInformacion)
                // setInformnacionUsuario(traerInformacion)
                setTipoParticipante(traerInformacion.tipoParticipante)
                guardarEnContexto(traerInformacion)
            }
        }



        const catalogosSectores = await traerSectores()
        //console.log(catalogosSectores)
        if (catalogosSectores) {
            if ((isNaN(catalogosSectores.error)) === false) {
              
                setErroreshttp(catalogosSectores)
                
            } else {
                // //console.log(traerInformacion)
                setSectores(catalogosSectores)
            }
        }

        const catalogosSubSectores = await traerSubSectores()
        if (catalogosSubSectores) {
            if ((isNaN(catalogosSubSectores.error)) === false) {
              
                setErroreshttp(catalogosSubSectores)
                
            } else {
                // //console.log(traerInformacion)
                setSubSectores(catalogosSubSectores)
            }
        }

        const catalogosNacionalidades = await traerNacionalidad()
        if (catalogosNacionalidades) {
            if ((isNaN(catalogosNacionalidades.error)) === false) {
              
                setErroreshttp(catalogosNacionalidades)
                
            } else {
                // //console.log(traerInformacion)
                setNacionalidades(catalogosNacionalidades)
            }
        }

        const catalogoBancos = await traerBancos()
        if (catalogoBancos) {
            //setSpin(false)
            if ((isNaN(catalogoBancos.error)) === false) {
              
                setErroreshttp(catalogoBancos)
                
            } else {
                // //console.log(traerInformacion)
                setBancos(catalogoBancos)
            }
        }

        const catalogoTiposCuenta = await traerTiposCuenta()
        if (catalogoTiposCuenta) {
            //setSpin(false)
            if ((isNaN(catalogoTiposCuenta.error)) === false) {
              
                setErroreshttp(catalogoTiposCuenta)
                
            } else {
                // //console.log(traerInformacion)
                //setActividadEconomica(catalogoNacionalidad)

                setTiposCuenta(catalogoTiposCuenta)
            }
        }

        const catalogoCasasBolsa = await traerCasasBolsa()
        //console.log(catalogoCasasBolsa)
        if (catalogoCasasBolsa) {
            //setSpin(false)
            if ((isNaN(catalogoCasasBolsa.error)) === false) {
              
                setErroreshttp(catalogoCasasBolsa)
                
            } else {
                // //console.log(traerInformacion)
                setCasasBolsa(catalogoCasasBolsa)

            }
        }


    }

    const guardarEnContexto = (info) => {
        //console.log(info)
        const objetos = {
            datos: {
                codigoEmisor: info.cehvalEmisorCodigo,
                codigoDepositante: "",
                nombreContacto: "",
                correoContacto: "",

                nombreEmisor: info.cehvalEmisorNombreCom,
                sector: info.cehvalEmisorCatSectorId.nombre,
                subSector: info.cehvalEmisorCatSubSecId.nombre,

                nacionalidad: info.cehvalEmisorNacionalidadId.nombre,
                rtn: info.cehvalEmisorRtn,
                paginaWeb: info.cehvalEmisorPagWeb,

                nombreRepresentanteLegal: info.cehvalEmisorNombreCompletoRepreLeg,
                correoRepresentanteLegal: info.cehvalEmisorCoreoElecRepreLeg,

                numeroCuentaN: info.cehvalCuentaBanCollection[0]?.cehvalCuentaBanNumCue,
                bancoN: info.cehvalCuentaBanCollection[0]?.catBancoCatBancoId.catBancoNombre,
                monedaN: 1,
                tipoCuentaN: info.cehvalCuentaBanCollection[0]?.catTipoCuentaCatTipCueId.catTipCueCuenta,

                numeroCuentaE: info.cehvalCuentaBanCollection[1]?.cehvalCuentaBanNumCue,
                bancoE: info.cehvalCuentaBanCollection[1]?.catBancoCatBancoId.catBancoNombre,
                monedaE: 2,
                tipoCuentaE: info.cehvalCuentaBanCollection[1]?.catTipoCuentaCatTipCueId.catTipCueCuenta,

                //nuevos
                sectorInicio: true,
                subSectorInicio: true,
                nacionalidadInicio: true,


                bancoNInicio: true,
                bancoEInicio: true,
                tipoCuentaNInicio: true,
                tipoCuentaEInicio: true,
                exencionImpuestosInicio: true,

                paisInicio: true,
                departamentoInicio: true,
                municipioInicio: true,
                ciudadInicio: true,


            }
        }
        setValue(objetos)
        setValue2(objetos)
        setSpin(false)
    }

    /* const traerDatosDelDepositante = async (codigo) => {
         const obj1 = {
             codigo: codigo
         }
         const datosDepositante = await traerDatosDepositanteParaEmisor(obj1)
         if (datosDepositante) {
             if ((isNaN(datosDepositante.error)) === false) {
                 // //console.log("erores")
                 ////console.log(response)
                 setErroreshttp(datosDepositante)
                 
             } else {
                 if (datosDepositante.codigo === null) {
                     setErrorCodigo(true)
                 } else {
                     setErrorCodigo(false)
                     setCodigoDepositante(datosDepositante.codigo)
                     setCorreoContacto(datosDepositante.correo)
                     setNombreContacto(datosDepositante.nommbre)
                     setDepositnate(datosDepositante)
                 }
                 // //console.log(traerInformacion)
             }
         }
     }*/




    const imprimir = async () => {
        //console.log("imprimir")
        setActualizacionCorrecta(false)
        setLoading(true)
        const response = await imprimirEmisorRegistrado({
            emisorCodigo: value.datos.codigoEmisor
        })
        if (response) {
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta    
                setLoading(false)
                setErroreshttp(response)
            } else {
                const file = new Blob(
                    [response],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
                setLoading(false)
                history.go(-1)
            }
        }
    }

    /*const cancelar = () => {
        history.push({
            pathname: '/emisoresParaActualizar'
        })
    }*/

    const establecerConfirmacion = () => {
        setValue((prev) => {
            const datos = { ...prev.datos, ["establecerModificacion"]: true };
            return { ...prev, datos };
        });
    }


    const reportTemplateRef = useRef(null);

    const handleGeneratePdf = async () => {
        /*const doc = new jsPDF({
            orientation:"landscape",
            unit: 'px',
            format: 'a1',
        });*/

        const doc = new jsPDF("p", "pt", "a4");

        // Adding the fonts.
        //doc.setFont('Inter-Regular', 'normal');

        doc.html(reportTemplateRef.current, {
            async callback(doc) {
                await doc.save('document');
            },
        });
    };

    const cancelar = () => {
        //console.log(contador)
        if (cambiosHechos) {
            setConfirmacionCancelar(true)
        } else {
            history.go(-1)
        }
    }

    const consola = (e) => {
        //console.log(e)
    }

    const resetear = () => {
        setActualizarUsuario(false)
    }

    const validarRepresentanteLegal = (e) => {
        const nombreRepresentanteLegal = document.getElementById("nombreRepresentanteLegal").value;
        const correo = document.getElementById("correoRepresentanteLegal").value;

        if (nombreRepresentanteLegal || correo) {
            //console.log("obligatorio")
            setRepresentanteLegalObligatorio(true)
        } else {
            //console.log("opcional")
            setRepresentanteLegalObligatorio(false)
        }
    }

    const modificarCorreoCognito=()=>{
     

        if((value.datos.correoRepresentanteLegal != value2.datos.correoRepresentanteLegal)){
            //console.log("modifficar correo")
            setModificarCorreo(true)
        }
    }

    return (
        <div className="fluid-container">
              {modificarCorreo&&
                <ActualizarCorreo resetear={resetear} informacion={informacionUsuario} contactosOriginales={contactosOriginales} contactosValidar={contactosValidar} codigo={value.datos.codigoEmisor} tipoParticipante={tipoParticipante}></ActualizarCorreo>

                }

            {actualizarUsuario &&
                <ActualizarUsuarios resetear={resetear} informacion={informacionUsuario} contactosOriginales={contactosOriginales} contactosValidar={contactosValidar} codigo={value2.datos.codigoEmisor} tipoParticipante={tipoParticipante} ></ActualizarUsuarios>
            }

            <Modal show={consultaGeneracionUsusario} animation={false} centered >
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => { history.go(-1) }} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >Se ha modificado información del representante legal, ¿desea generar un nuevo usuario con estos datos?</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => { setActualizacionCorrecta(true)
                        setActualizarUsuario(true)
                        setConsultaGeneracionUsusario(false)}} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Generar</button>
                      
                        <button onClick={() => { setActualizacionCorrecta(true)
                        setConsultaGeneracionUsusario(false)
                        modificarCorreoCognito()}} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >No generar</button>
                    </div>
                </div>
            </Modal>


            <Modal show={confirmacionCancelar} animation={false} centered >
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => setConfirmacionCancelar(false)} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >Se descartarán los cambios hechos ¿Desea continuar?</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => setConfirmacionCancelar(false)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Cancelar</button>
                        <button onClick={() => { history.go(-1) }} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Aceptar</button>
                    </div>
                </div>
            </Modal>



            <Modal show={actualizacionCorrecta} animation={false} centered >
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => { history.go(-1) }} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >Se ha actualizado la información del emisor</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => { history.go(-1) }} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Terminar</button>
                        <button onClick={imprimir} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Imprimir</button>
                    </div>
                </div>
            </Modal>

            {loading &&
                <PantallaCargando />
            }

            

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {errorUsuarioExistente &&
                <ComponenteModal mostrarModal={errorUsuarioExistente} cerrarModal={() => { setErrorUsuarioExistente(false) }} titulo="Este correo ya esta registrado, ingresa otro" textoBoton="Entendido"></ComponenteModal>
            }

            {sinCambios &&
                <ComponenteModal mostrarModal={sinCambios} cerrarModal={() => { setSinCambios(false) }} titulo="No se realizó ningun cambio, no se puede confirmar" textoBoton="Entendido"></ComponenteModal>
            }

            {sinCambiosImpresion &&
                <ComponenteModal mostrarModal={sinCambiosImpresion} cerrarModal={() => { setSinCambiosImpresion(false) }} titulo="No se ha confirmado ningun cambio, no se puede imprimir" textoBoton="Entendido"></ComponenteModal>
            }

            {/*} {actualizacionCorrecta &&
                <ComponenteModal mostrarModal={actualizacionCorrecta} cerrarModal={() => { setActualizacionCorrecta(false) }} titulo="Se ha actualizado la información del emisor" textoBoton="Entendido"></ComponenteModal>
            }*/}

            {actualizacionIncorrecta &&
                <ComponenteModal mostrarModal={actualizacionIncorrecta} cerrarModal={() => { setActualizacionIncorrecta(false) }} titulo="No se ha podido actualizar la información del emisor" textoBoton="Entendido"></ComponenteModal>
            }




            <main className='container-min-height'>
                <div className="row row-wrap g-0">

                    


                    <div className="col-12">
                        <div className="">
                            <BannerTitulo titulo="Actualizar emisor"></BannerTitulo>
                            {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                <div className="row d-flex">

                                    <div className="col-12">
                                        <div className="mb-5">
                                            <div className="card-body ">
                                                <div className="d-grid gap-1 col-10 mx-auto">
                                                    <form className="row g-3 needs-validation" novalidate onSubmit={(e) => {
                                                        onSubmitForm(e)

                                                    }}>
                                                        <div className="card" style={{ color: "#242848" }}>
                                                            <div className="card-body">

                                                                <div ref={reportTemplateRef}>
                                                                    {value.datos.establecerModificacion ?
                                                                        <>
                                                                            {/*} <h5 className="mt-3 text-royal-blue font-acumin-variable">Información de contacto</h5>
                                                                            <div className="row mb-3">
                                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                                    <div className="form-floating">
                                                                                        <input type="text" className="form-control fs-5 textoFormularioDepositantedirecto" disabled value={nombreContacto} onChange={(e) => setNombreContacto(e.target.value)} id="nombreContacto" name="nombreContacto" placeholder="" required />
                                                                                        <label htmlFor="cehvalDepositanteNombreConf" className=" textoFormularioDepositantedirecto">Nombre completo *</label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                                    <div className="form-floating">
                                                                                        <input placeholder="" type="email" className="form-control fs-5 " disabled value={correoContacto} onChange={(e) => setCorreoContacto(e.target.value)} id="correoContacto" name="correoContacto" required />
                                                                                        <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Correo electrónico *</label>
                                                                                    </div>
                                                                                </div>


                                                                                <div className="col-md-12 col-lg-4 form-floating mb-3">
                                                                                    <div className="form-floating mb-3">
                                                                                        <select className="form-select fs-5 border  " onChange={(e) => traerDatosDelDepositante(e.target.value)} id="nombreBolsa" name="nombreBolsa" required>
                                                                                            <option value=""> </option>
                                                                                            {
                                                                                                casasBolsa.map((item, index) => (
                                                                                                    <option key={index} value={item.cehvalDepositanteCodigoCehval} >{item.cehvalDepositanteCodigoCehval}</option>
                                                                                                ))
                                                                                            }
                                                                                        </select>
                                                                                        <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Código depositante *</label>
                                                                                    </div>
                                                                                </div>

                                                                                {errorCodigo &&
                                                                                    <>
                                                                                        <p className="error">El código no existe</p>
                                                                                    </>
                                                                                }
                                                                            </div>*/}


                                                                            <h5 className="mt-3 text-royal-blue font-acumin-variable">I. Identificación del emisor</h5>

                                                                            <div className="row mb-3">
                                                                                <div className="col-12 "  >
                                                                                    <div className="form-floating">
                                                                                        <input disabled value={value2.datos.nombreEmisor} onChange={(e) => guardarDatos(e)} type="text" className="form-control fs-5 textoFormularioDepositantedirecto" id="nombreEmisor" name="nombreEmisor" placeholder="nombre" required />
                                                                                        <label className="form-label fs-6 textoFormularioDepositantedirecto" htmlFor="nombreCompleto">Nombre completo del emisor *</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row mb-3">
                                                                                <div className="col-md-12 col-lg-6 mb-3">
                                                                                    <div className="form-floating">
                                                                                        {value2.datos.sectorInicio ?
                                                                                            <select className="form-select fs-5 textoFormularioDepositantedirecto" value={value2.datos.sector} onChange={(e) => {
                                                                                                guardarDatos(e)
                                                                                                setValue2((prev) => {
                                                                                                    const datos = { ...prev.datos, ["sectorInicio"]: false };
                                                                                                    return { ...prev, datos };
                                                                                                });
                                                                                                consola(e.target.value)
                                                                                            }} id="sector" name="sector" required>
                                                                                                <option value={value2.datos.sector} >{value2.datos.sector}</option>
                                                                                                {
                                                                                                    sectores.filter((item => item.nombre !== value2.datos.sector)).map((item, index) => (
                                                                                                        <option key={index} value={item.id} >{item.nombre}</option>
                                                                                                    ))
                                                                                                }

                                                                                            </select>
                                                                                            :
                                                                                            <select className="form-select fs-5 textoFormularioDepositantedirecto" value={value2.datos.sector} onChange={(e) => {
                                                                                                guardarDatos(e)
                                                                                                consola(e.target.value)
                                                                                            }} id="sector" name="sector" required>
                                                                                                <option value=""> </option>
                                                                                                {
                                                                                                    sectores.map((item, index) => (
                                                                                                        <option key={index} value={item.id} >{item.nombre}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                        }

                                                                                        <label htmlFor="sector" className="form-label fs-6 textoFormularioDepositantedirecto">Sector *</label>
                                                                                    </div>
                                                                                </div>


                                                                                <div className="col-md-12 col-lg-6 mb-3"  >
                                                                                    <div className="form-floating">
                                                                                        {
                                                                                            value2.datos.subSectorInicio ?
                                                                                                <select className="form-select fs-5 textoFormularioDepositantedirecto " value={value2.datos.subSector} onChange={(e) => {
                                                                                                    guardarDatos(e)
                                                                                                    setValue2((prev) => {
                                                                                                        const datos = { ...prev.datos, ["subSectorInicio"]: false };
                                                                                                        return { ...prev, datos };
                                                                                                    });

                                                                                                }} id="subSector" name="subSector" required>
                                                                                                    <option value={value2.datos.subSector}>{value2.datos.subSector}</option>
                                                                                                    {
                                                                                                        subSectores.filter((item => item.nombre !== value2.datos.subSector)).map((item, index) => (
                                                                                                            <option key={index} value={item.id} >{item.nombre}</option>
                                                                                                        ))
                                                                                                    }
                                                                                                </select>
                                                                                                :
                                                                                                <select className="form-select fs-5 textoFormularioDepositantedirecto " value={value2.datos.subSector} onChange={(e) => guardarDatos(e)} id="subSector" name="subSector" required>
                                                                                                    <option value=""> </option>
                                                                                                    {
                                                                                                        subSectores.map((item, index) => (
                                                                                                            <option key={index} value={item.id} >{item.nombre}</option>
                                                                                                        ))
                                                                                                    }
                                                                                                </select>
                                                                                        }

                                                                                        <label htmlFor="subSector" className="form-label fs-6 textoFormularioDepositantedirecto">Sub-sector *</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row mb-3">
                                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                                    <div className="form-floating" >
                                                                                        {value2.datos.nacionalidadInicio ?
                                                                                            <select className="form-select fs-5 " value={value2.datos.nacionalidad} onChange={(e) => {
                                                                                                guardarDatos(e)
                                                                                                setValue2((prev) => {
                                                                                                    const datos = { ...prev.datos, ["nacionalidadInicio"]: false };
                                                                                                    return { ...prev, datos };
                                                                                                });
                                                                                            }} id="nacionalidad" name="nacionalidad" required>
                                                                                                <option value={value2.datos.nacionalidad}>{value2.datos.nacionalidad}</option>
                                                                                                {
                                                                                                    nacionalidades.filter((item => item.nombre !== value2.datos.nacionalidad)).map((item, index) => (
                                                                                                        <option key={index} value={item.id} >{item.nombre}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                            :
                                                                                            <select className="form-select fs-5 " value={value2.datos.nacionalidad} onChange={(e) => guardarDatos(e)} id="nacionalidad" name="nacionalidad" required>
                                                                                                <option value=""> </option>
                                                                                                {
                                                                                                    nacionalidades.map((item, index) => (
                                                                                                        <option key={index} value={item.id} >{item.nombre}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                        }
                                                                                        <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Nacionalidad *</label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                                    <div className=" form-floating">
                                                                                        <input disabled value={value2.datos.rtn} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e)} type="text" maxLength={14} className="form-control fs-5 textoFormularioDepositantedirecto" id="rtn" name="rtn" placeholder="rtn" />
                                                                                        <label htmlFor="rtn" className="form-label fs-6 textoFormularioDepositantedirecto">RTN</label>

                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-12 col-lg-4">
                                                                                    <div className="form-floating">
                                                                                        <input value={value2.datos.paginaWeb} onChange={(e) => guardarDatos(e)} type="text" className="form-control fs-5 textoFormularioDepositantedirecto" id="paginaWeb" name="paginaWeb" placeholder="paginaWeb" />
                                                                                        <label htmlFor="paginaWeb" className="form-label fs-6 textoFormularioDepositantedirecto">Página web</label>                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <h5 className="mt-5 mb-3 text-royal-blue font-acumin-variable" >II. Datos del representante legal</h5>
                                                                            {representanteLegalObligatorio ?
                                                                                <div className="row mb-3">
                                                                                    <div className="col-md-12 col-lg-8 col-xl-8 mb-3"  >
                                                                                        <div className="form-floating">
                                                                                            <input value={value2.datos.nombreRepresentanteLegal} onChange={(e) => {guardarDatos(e)
                                                                                            validarRepresentanteLegal(e)}} type="text" className="form-control fs-5 textoFormularioDepositantedirecto" id="nombreRepresentanteLegal" name="nombreRepresentanteLegal" placeholder="nombreRepresentanteLegal" required/>
                                                                                            <label htmlFor="nombreCompletoRepresentanteLegal" className="textoFormularioDepositantedirecto">Nombre completo del representante legal</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-lg-4 col-xl-4"  >
                                                                                        <div className="form-floating">
                                                                                            <input style={{ textTransform: "lowercase" }} value={correo} onChange={(e) => {setCorreo(e.target.value)
                                                                                            validarRepresentanteLegal(e)}} onBlur={(e) => guardarDatos(e)} onMouseOut={(e) => guardarDatos(e)} type="email" className="form-control fs-5 textoFormularioDepositantedirecto" id="correoRepresentanteLegal" name="correoRepresentanteLegal" placeholder="name@example.com" required/>
                                                                                            <label htmlFor="email" className="form-label fs-6 textoFormularioDepositantedirecto">Correo electrónico</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className="row mb-3">
                                                                                    <div className="col-md-12 col-lg-8 col-xl-8 mb-3"  >
                                                                                        <div className="form-floating">
                                                                                            <input value={value2.datos.nombreRepresentanteLegal} onChange={(e) => {guardarDatos(e)
                                                                                            validarRepresentanteLegal(e)}} type="text" className="form-control fs-5 textoFormularioDepositantedirecto" id="nombreRepresentanteLegal" name="nombreRepresentanteLegal" placeholder="nombreRepresentanteLegal" />
                                                                                            <label htmlFor="nombreCompletoRepresentanteLegal" className="textoFormularioDepositantedirecto">Nombre completo del representante legal</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-lg-4 col-xl-4"  >
                                                                                        <div className="form-floating">
                                                                                            <input style={{ textTransform: "lowercase" }} value={correo} onChange={(e) => {setCorreo(e.target.value)
                                                                                            validarRepresentanteLegal(e)}} onBlur={(e) => guardarDatos(e)} onMouseOut={(e) => guardarDatos(e)} type="email" className="form-control fs-5 textoFormularioDepositantedirecto" id="correoRepresentanteLegal" name="correoRepresentanteLegal" placeholder="name@example.com" />
                                                                                            <label htmlFor="email" className="form-label fs-6 textoFormularioDepositantedirecto">Correo electrónico</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }



                                                                            <h5 className="mt-5 mb-3 text-royal-blue font-acumin-variable" >III. Instrucciones especiales (cuentas bancarias)</h5>
                                                                            <h6 className="text-royal-blue font-acumin-variable">Nacional</h6>

                                                                            <div className="row mb-3">
                                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                                    <div className="form-floating mb-3">
                                                                                        < input type="text" disabled className="form-control fs-5 border  " value={"LEMPIRA"} required />
                                                                                        <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Moneda</label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                                    <div className="form-floating mb-3">
                                                                                        {value2.datos.bancoNInicio ?
                                                                                            <select className="form-select fs-5 border  " value={value2.datos.bancoN} onChange={(e) => {
                                                                                                guardarDatos(e)
                                                                                                setValue2((prev) => {
                                                                                                    const datos = { ...prev.datos, ["bancoNInicio"]: false };
                                                                                                    return { ...prev, datos };
                                                                                                });
                                                                                            }} id="bancoN" name="bancoN">
                                                                                                <option value={value2.datos.bancoN}>{value2.datos.bancoN} </option>
                                                                                                {
                                                                                                    bancos.filter((item => item.nombre !== value2.datos.bancoN)).map((item, index) => (
                                                                                                        <option key={index} value={item.id} >{item.nombre}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                            :
                                                                                            <select className="form-select fs-5 border  " value={value2.datos.bancoN} onChange={(e) => guardarDatos(e)} id="bancoN" name="bancoN">
                                                                                                <option value=""> </option>
                                                                                                {
                                                                                                    bancos.map((item, index) => (
                                                                                                        <option key={index} value={item.id} >{item.nombre}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                        }

                                                                                        <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Bancos</label>
                                                                                    </div>
                                                                                </div>


                                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                                    <div className="form-floating mb-3">
                                                                                        {value2.datos.tipoCuentaNInicio ?
                                                                                            <select className="form-select fs-5 border  " value={value2.datos.tipoCuentaN} onChange={(e) => {
                                                                                                guardarDatos(e)
                                                                                                setValue2((prev) => {
                                                                                                    const datos = { ...prev.datos, ["tipoCuentaNInicio"]: false };
                                                                                                    return { ...prev, datos };
                                                                                                });
                                                                                            }} id="tipoCuentaN" name="tipoCuentaN">
                                                                                                <option value={value2.datos.tipoCuentaN}>{value2.datos.tipoCuentaN}</option>
                                                                                                {
                                                                                                    tiposCuenta.filter((item => item.nombre !== value2.datos.tipoCuentaN)).map((item, index) => (
                                                                                                        <option key={index} value={item.id} >{item.nombre}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                            :
                                                                                            <select className="form-select fs-5 border  " value={value2.datos.tipoCuentaN} onChange={(e) => guardarDatos(e)} id="tipoCuentaN" name="tipoCuentaN">
                                                                                                <option value=""> </option>
                                                                                                {
                                                                                                    tiposCuenta.map((item, index) => (
                                                                                                        <option key={index} value={item.id} >{item.nombre}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>

                                                                                        }

                                                                                        <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Tipo de cuenta</label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                                    <div className="form-floating mb-3">
                                                                                        < input placeholder="" type="text" maxLength={16} className="form-control fs-5 border  " value={value2.datos.numeroCuentaN} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e)} id="numeroCuentaN" name="numeroCuentaN" />
                                                                                        <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Número de cuenta</label>
                                                                                        <div id="" class="form-text fs-x-small">Solo números</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <h6 className="text-royal-blue font-acumin-variable" >Extranjera</h6>
                                                                            <div className="row mb-3">
                                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                                    <div className="form-floating mb-3">
                                                                                        < input type="text" disabled className="form-control fs-5 border  " value={"DÓLAR"} />
                                                                                        <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Moneda</label>
                                                                                    </div>
                                                                                </div>


                                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                                    <div className="form-floating mb-3">
                                                                                        {value2.datos.bancoEInicio ?
                                                                                            <select className="form-select fs-5 border  " value={value2.datos.bancoE} onChange={(e) => {
                                                                                                guardarDatos(e)
                                                                                                setValue2((prev) => {
                                                                                                    const datos = { ...prev.datos, ["bancoEInicio"]: false };
                                                                                                    return { ...prev, datos };
                                                                                                });
                                                                                            }} id="bancoE" name="bancoE">
                                                                                                <option value={value2.datos.bancoE}>{value2.datos.bancoE}</option>
                                                                                                {
                                                                                                    bancos.filter((item => item.nombre !== value2.datos.bancoE)).map((item, index) => (
                                                                                                        <option key={index} value={item.id} >{item.nombre}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                            :
                                                                                            <select className="form-select fs-5 border  " value={value2.datos.bancoE} onChange={(e) => guardarDatos(e)} id="bancoE" name="bancoE">
                                                                                                <option value=""> </option>
                                                                                                {
                                                                                                    bancos.map((item, index) => (
                                                                                                        <option key={index} value={item.id} >{item.nombre}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                        }

                                                                                        <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Banco</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                                    <div className="form-floating mb-3">
                                                                                        {value2.datos.tipoCuentaEInicio ?
                                                                                            <select className="form-select fs-5 border  " value={value2.datos.tipoCuentaE} onChange={(e) => {
                                                                                                guardarDatos(e)
                                                                                                setValue2((prev) => {
                                                                                                    const datos = { ...prev.datos, ["tipoCuentaEInicio"]: false };
                                                                                                    return { ...prev, datos };
                                                                                                });
                                                                                            }} id="tipoCuentaE" name="tipoCuentaE">
                                                                                                <option value={value2.datos.tipoCuentaE}>{value2.datos.tipoCuentaE}</option>
                                                                                                {
                                                                                                    tiposCuenta.filter((item => item.nombre !== value2.datos.tipoCuentaE)).map((item, index) => (
                                                                                                        <option key={index} value={item.id} >{item.nombre}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                            :
                                                                                            <select className="form-select fs-5 border  " value={value2.datos.tipoCuentaE} onChange={(e) => guardarDatos(e)} id="tipoCuentaE" name="tipoCuentaE">
                                                                                                <option value=""> </option>
                                                                                                {
                                                                                                    tiposCuenta.map((item, index) => (
                                                                                                        <option key={index} value={item.id} >{item.nombre}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                        }

                                                                                        <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Tipo de cuenta</label>
                                                                                    </div>

                                                                                </div>
                                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                                    <div className="form-floating mb-3">
                                                                                        < input placeholder="" type="text" maxLength={16} className="form-control fs-5 border  " value={value2.datos.numeroCuentaE} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e)} id="numeroCuentaE" name="numeroCuentaE" />
                                                                                        <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Número de cuenta</label>
                                                                                        <div id="" class="form-text fs-x-small">Solo números</div>
                                                                                    </div>
                                                                                </div>

                                                                             


                                                                            </div>

                                                                            

                                                                           {/*} <div className="row mb-3">
                                                                             <div className="col-md-6 ">
                                                                                    <label className="mb-2">Si usted modifica el nombre o correo del representante legal, se puede generar un nuevo usuario con esos cambios, ¿Generar nuevo usuario?</label>
                                                                                    <div className="d-flex justify-content-start">
                                                                                        <div className="contenedorBotonesStteper">
                                                                                            < input placeholder="" type="radio" className="form-check-input me-2" value={true} onChange={(e) => {
                                                                                                setGeneracionUsuario(e.target.value)
                                                                                            }} id="validacionGeneracionUsuarioEmisorSi" name="generacionUsuarioEmisor" required />
                                                                                            <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="validacionGeneracionUsuarioEmisorSi">Generar</label>
                                                                                        </div>

                                                                                        <div className="contenedorBotonesStteper">
                                                                                            < input placeholder="" type="radio" className="form-check-input me-2" value={false} onChange={(e) => {
                                                                                               setGeneracionUsuario(e.target.value)
                                                                                            }} id="validacionGeneracionUsuarioEmisorNo" name="generacionUsuarioEmisor" required />
                                                                                            <label className="form-check-label textoFormularioDepositantedirecto  mt-1" htmlFor="validacionGeneracionUsuarioEmisorNo">No Generar</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>*/}
                                                                        </>
                                                                        :
                                                                        <>

                                                                            {/*} <h5 className="mt-3 text-royal-blue font-acumin-variable">Información de contacto</h5>
                                                                                <div className="row mb-3">
                                                                                    <div className="col-md-12 col-lg-4 mb-3">
                                                                                        <div className="form-floating">
                                                                                            <input type="text" className="form-control fs-5 textoFormularioDepositantedirecto" disabled value={nombreContacto} onChange={(e) => setNombreContacto(e.target.value)} id="nombreContacto" name="nombreContacto" placeholder="" required />
                                                                                            <label htmlFor="cehvalDepositanteNombreConf" className=" textoFormularioDepositantedirecto">Nombre completo *</label>
                                                                                        </div>
                                                                                    </div>
                    
                                                                                    <div className="col-md-12 col-lg-4 mb-3">
                                                                                        <div className="form-floating">
                                                                                            <input placeholder="" type="email" className="form-control fs-5 " disabled value={correoContacto} onChange={(e) => setCorreoContacto(e.target.value)} id="correoContacto" name="correoContacto" required />
                                                                                            <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Correo electrónico *</label>
                                                                                        </div>
                                                                                    </div>
                    
                    
                                                                                    <div className="col-md-12 col-lg-4 form-floating mb-3">
                                                                                        <div className="form-floating mb-3">
                                                                                            <select className="form-select fs-5 border  " onChange={(e) => traerDatosDelDepositante(e.target.value)} id="nombreBolsa" name="nombreBolsa" required>
                                                                                                <option value=""> </option>
                                                                                                {
                                                                                                    casasBolsa.map((item, index) => (
                                                                                                        <option key={index} value={item.cehvalDepositanteCodigoCehval} >{item.cehvalDepositanteCodigoCehval}</option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                            <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Código depositante *</label>
                                                                                        </div>
                                                                                    </div>
                    
                                                                                    {errorCodigo &&
                                                                                        <>
                                                                                            <p className="error">El código no existe</p>
                                                                                        </>
                                                                                    }
                                                                                </div>*/}


                                                                            <h5 className="mt-3 text-royal-blue font-acumin-variable">I. Identificación del emisor</h5>

                                                                            <div className="row mb-3">
                                                                                <div className="col-12 "  >
                                                                                    <div className="form-floating">
                                                                                        <input disabled value={value.datos.nombreEmisor} type="text" className="form-control fs-5 textoFormularioDepositantedirecto" id="nombreEmisor" name="nombreEmisor" placeholder="nombre" required />
                                                                                        <label className="form-label fs-6 textoFormularioDepositantedirecto" htmlFor="nombreCompleto">Nombre completo del emisor *</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row mb-3">
                                                                                <div className="col-md-12 col-lg-6 mb-3">
                                                                                    <div className="form-floating">
                                                                                        <input disabled value={value.datos.sector} type="text" className="form-control fs-5 textoFormularioDepositantedirecto" id="nombreEmisor" name="nombreEmisor" placeholder="nombre" required />
                                                                                        <label className="form-label fs-6 textoFormularioDepositantedirecto" htmlFor="nombreCompleto">Sector</label>
                                                                                    </div>
                                                                                </div>


                                                                                <div className="col-md-12 col-lg-6 mb-3"  >
                                                                                    <div className="form-floating">
                                                                                        <input disabled value={value.datos.subSector} type="text" className="form-control fs-5 textoFormularioDepositantedirecto" id="nombreEmisor" name="nombreEmisor" placeholder="nombre" required />
                                                                                        <label className="form-label fs-6 textoFormularioDepositantedirecto" htmlFor="nombreCompleto">Sub-sector</label>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                            <div className="row mb-3">
                                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                                    <div className="form-floating">
                                                                                        <input disabled value={value.datos.nacionalidad} type="text" className="form-control fs-5 textoFormularioDepositantedirecto" id="nombreEmisor" name="nombreEmisor" placeholder="nombre" required />
                                                                                        <label className="form-label fs-6 textoFormularioDepositantedirecto" htmlFor="nombreCompleto">Nacionalidad</label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                                    <div className=" form-floating">
                                                                                        <input disabled value={value.datos.rtn} type="text" maxLength={14} className="form-control fs-5 textoFormularioDepositantedirecto" id="rtn" name="rtn" placeholder="rtn" />
                                                                                        <label htmlFor="rtn" className="form-label fs-6 textoFormularioDepositantedirecto">RTN</label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-12 col-lg-4">
                                                                                    <div className="form-floating">
                                                                                        <input disabled value={value.datos.paginaWeb} type="text" className="form-control fs-5 textoFormularioDepositantedirecto" id="paginaWeb" name="paginaWeb" placeholder="paginaWeb" />
                                                                                        <label htmlFor="paginaWeb" className="form-label fs-6 textoFormularioDepositantedirecto">Página web</label>                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <h5 className="mt-5 mb-3 text-royal-blue font-acumin-variable" >II. Datos del representante legal</h5>

                                                                            <div className="row mb-3">
                                                                                <div className="col-md-12 col-lg-8 col-xl-8 mb-3"  >
                                                                                    <div className="form-floating">
                                                                                        <input disabled value={value.datos.nombreRepresentanteLegal} type="text" className="form-control fs-5 textoFormularioDepositantedirecto" id="nombreRepresentanteLegal" name="nombreRepresentanteLegal" placeholder="nombreRepresentanteLegal" />
                                                                                        <label htmlFor="nombreCompletoRepresentanteLegal" className="form-label fs-6 textoFormularioDepositantedirecto">Nombre completo del representante legal</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-12 col-lg-4 col-xl-4"  >
                                                                                    <div className="form-floating">
                                                                                        <input disabled value={value.datos.correoRepresentanteLegal} type="email" className="form-control fs-5 textoFormularioDepositantedirecto" id="correoRepresentanteLegal" name="correoRepresentanteLegal" placeholder="name@example.com" />
                                                                                        <label htmlFor="email" className="form-label fs-6 textoFormularioDepositantedirecto">Correo electrónico</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <h5 className="mt-5 mb-3 text-royal-blue font-acumin-variable" >III. Instrucciones especiales (cuentas bancarias)</h5>
                                                                            <h6 className="text-royal-blue font-acumin-variable">Nacional</h6>

                                                                            <div className="row mb-3">
                                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                                    <div className="form-floating mb-3">
                                                                                        < input type="text" disabled className="form-control fs-5 border  " value={"LEMPIRA"} required />
                                                                                        <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Moneda</label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                                    <div className="form-floating mb-3">
                                                                                        < input  type="text" disabled className="form-control fs-5 border  " value={value.datos.bancoN} required />
                                                                                        <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Bancos</label>
                                                                                    </div>
                                                                                </div>


                                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                                    <div className="form-floating mb-3">
                                                                                        < input type="text" disabled className="form-control fs-5 border  " value={value.datos.tipoCuentaN} required />
                                                                                        <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Tipo de cuenta</label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                                    <div className="form-floating mb-3">
                                                                                        < input disabled type="text" maxLength={16} className="form-control fs-5 border  " value={value.datos.numeroCuentaN} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e)} id="numeroCuentaN" name="numeroCuentaN" />
                                                                                        <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Número de cuenta</label>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <h6 className="text-royal-blue font-acumin-variable" >Extranjera</h6>
                                                                            <div className="row mb-3">
                                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                                    <div className="form-floating mb-3">
                                                                                        < input type="text" disabled className="form-control fs-5 border  " value={"DÓLAR"} />
                                                                                        <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Moneda</label>
                                                                                    </div>
                                                                                </div>


                                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                                    <div className="form-floating mb-3">
                                                                                        < input type="text" disabled className="form-control fs-5 border  " value={value.datos.bancoE} required />
                                                                                        <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Bancos</label>
                                                                                    </div>

                                                                                </div>
                                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                                    <div className="form-floating mb-3">
                                                                                        < input type="text" disabled className="form-control fs-5 border  " value={value.datos.tipoCuentaE} required />
                                                                                        <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Tipo de cuenta</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                                    <div className="form-floating mb-3">
                                                                                        < input disabled type="text" maxLength={16} className="form-control fs-5 border  " value={value.datos.numeroCuentaE} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e)} id="numeroCuentaE" name="numeroCuentaE" />
                                                                                        <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Número de cuenta</label>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>

                                                                <div className=" row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end font-acumin-variable">

                                                                    {value.datos.establecerModificacion ?
                                                                        <button title="Cancelar modificación" type='button' onClick={cancelar} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white  mb-2" >Cancelar</button>
                                                                        :
                                                                        <button title="Modificar información" type='button' onClick={establecerConfirmacion} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Modificar</button>
                                                                    }

                                                                    <button title="Confirmar modificación de información" onClick={() => setCondicion(true)} id="botonActualizar1" type='submit' className=" col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Confirmar</button>
                                                                    {/*   <button title="Imprimir" onClick={imprimir} type='button' className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Imprimir</button>*/}

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }
                        </div>
                    </div>



                </div >
            </main >

        </div >
    );


}

export default function ActualizarEmisores({ history }) {
    return (

        <FormProvider>
            <FormProvider2><FormProvider3>
                <Form history={history} />
            </FormProvider3></FormProvider2>
        </FormProvider>

    );
}

