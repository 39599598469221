import { instance } from '../apiGlobal';

export async function consultarEstatusSubastaConciliarFondos() {
      try {
          const response = await instance(`/subasta/obtener/todas/conciliacion`, {
          method:"get",
        })
        return response.data
      } catch (error) {
        return error
      }
}