import React from "react";
//import { Link } from 'react-router-dom';

//AWS
import { Auth } from 'aws-amplify';

//componentes
import ComponenteModal from "../../ComponentesGenerales/ComponenteModal";

//Images
import cajaFuerte from './../../assets/logosCehval/caja-fuerte.jpg';

//Icons
//import { BsFillEnvelopeFill } from "react-icons/bs";
import { FcBusinessman } from "react-icons/fc";


export default function SolicitarRestablecerPassword({ history }) {

   // const [isValidEmail, setIsValidEmail] = React.useState(true)
   // const [isValidPassword, setIsValidPassword] = React.useState(true)
    const [nombreUsuario, setNombreUsuario] = React.useState()
    const [nombreUsuarioRequerido, setNombreUsuarioRequerido] = React.useState()
    const [errorIntentosMaximos, setErrorIntentosMaximos] = React.useState();

    const onSubmitForm = async (e) => {
        e.preventDefault();
        //console.log("entra")
        if (!nombreUsuario) {
            setNombreUsuarioRequerido(true)

        } else {
            setNombreUsuarioRequerido(false)
        }


        if (nombreUsuario) {
            try {
                const data = await Auth.forgotPassword(nombreUsuario)
                history.push({
                    pathname: '/recuperarPassword', state: { objMenu: nombreUsuario }
                })

            } catch (err) {
                //console.log(err)
                if (err.message.indexOf("Attempt limit exceeded, please try after some time") >= 0) {
                    setErrorIntentosMaximos(true)
                }
            }
        }
    }



    return (
        <>
          {errorIntentosMaximos &&
                <ComponenteModal mostrarModal={errorIntentosMaximos} cerrarModal={() => { setErrorIntentosMaximos(false) }} titulo="Excedió el límite de intentos, inténtelo después"  textoBoton="Entendido"></ComponenteModal>
            }


            <main className=" p-0 m-0 g-0">
                <div className="row justify-content-between g-0">
                    <div className="col-12 col-md-12 col-lg-6 p-0 g-0 row m-0">
                        <img className="" alt="Imagen bóveda" id="loginImageContainer" src={cajaFuerte} />
                    </div>
                    <div className="col-12  col-md-12  col-lg-6 p-2 mt-8rem">
                        <div>
                            <form onSubmit={(e) => onSubmitForm(e)} className="p-4">
                                <div className="row justify-content-left mt-4 p-2 ">
                                    <div className="mb-5">
                                        <h4 className="fs-2 p-2 fw-bolder royal-blue-light">Restablece tu contraseña</h4>
                                        <p className="fs-5 p-2 fw-bold royal-blue-light mt-4">Ingresa tu nombre de usuario y te enviaremos un código a tu nombreUsuario con el que podrás restablecer tu contraseña.</p>
                                    </div>
                                    <div className="col-12 col-md-7 col-lg-10 mb-3">
                                        <div className="form-floating ">
                                            <input type="text" id="email" className="form-control" placeholder="name@example.com" value={nombreUsuario} onChange={(event) => setNombreUsuario(event.target.value)}></input>
                                            <label className="royal-blue-light" htmlFor="email"><FcBusinessman />&nbsp; Nombre de usuario</label>
                                            {nombreUsuarioRequerido &&
                                                <>
                                                    <p className="error">*Campo requerido</p>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 row justify-content-start p-3 ">
                                    <button className="col-5 col-md-3 col-lg-4 btn background-royal-blue text-white bg-hover-gold p-2" type="submit">Enviar código</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>

        </>
    );
}

