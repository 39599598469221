import React from "react";



import { FormContext } from "./form_context";

export default function RegistroDI5(props) {
    const [value, setValue] = React.useContext(FormContext);
    const [contadorDocumentosSupervisados, setContadorDocumentosSupervisados] = React.useState(0)
    const [contadorDocumentosNoSupervisados, setContadorDocumentosNosupervisados] = React.useState(0)

    React.useEffect(() => {
    
        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 1 };
            return { ...prev, datos };
        });

    }, [])



    const guardarDatos = (e) => {
        const { name, value } = e.target;
        //console.log(name, value)

        setValue((prev) => {
            const datos = { ...prev.datos, [name]: value };
            return { ...prev, datos };
        });
    }

    const guardarDocumentoNoSupervisados = (name) => {

        ////console.log(value)
        ////console.log(name)
        //pregunto si el cehck esta marcado
        if (document.getElementById(name).checked) {
            //setErrorSinPermisos(false)
            //aumentamos en uno contador
            setContadorDocumentosNosupervisados(contadorDocumentosNoSupervisados + 1)
            //arregloPivote.push(parseInt(numero, 10))            
            setValue((prev) => {
                const datos = { ...prev.datos, [name]: 1 };
                return { ...prev, datos };
            });
        } else {
            setValue((prev) => {
                const datos = { ...prev.datos, [name]: 0 };
                return { ...prev, datos };
            });
            setContadorDocumentosNosupervisados(contadorDocumentosNoSupervisados - 1)
            // arregloPivote = arregloPermisos.filter((item) => item !== parseInt(numero, 10))
        }
        ////console.log(arregloPivote)
    }


    const guardarDocumentoSupervisados = (name) => {

        ////console.log(value)
        ////console.log(name)
        //pregunto si el cehck esta marcado
        if (document.getElementById(name).checked) {
            //setErrorSinPermisos(false)
            //aumentamos en uno contador
            setContadorDocumentosSupervisados(contadorDocumentosSupervisados + 1)
            //arregloPivote.push(parseInt(numero, 10))            
            setValue((prev) => {
                const datos = { ...prev.datos, [name]: 1 };
                return { ...prev, datos };
            });
        } else {
            setValue((prev) => {
                const datos = { ...prev.datos, [name]: 0 };
                return { ...prev, datos };
            });
            setContadorDocumentosSupervisados(contadorDocumentosSupervisados - 1)
            // arregloPivote = arregloPermisos.filter((item) => item !== parseInt(numero, 10))
        }
        ////console.log(arregloPivote)
    }


    React.useEffect(() => {

        let checkCompleto;

        if (props.info.tipoParticipante === 1) {
            checkCompleto = document.getElementById("cehvalDocumentoSoporNsExpComp")
            if (contadorDocumentosSupervisados === 4 && contadorDocumentosNoSupervisados === 7) {
                checkCompleto.checked = true
                setValue((prev) => {
                    const datos = { ...prev.datos, ["cehvalDocumentoSoporNsExpComp"]: 1 };
                    return { ...prev, datos };
                });

                setValue((prev) => {
                    const datos = { ...prev.datos, ["errorDocumentacionIncompletaSupervisada"]: false };
                    return { ...prev, datos };
                });


            } else {
                checkCompleto.checked = false
                setValue((prev) => {
                    const datos = { ...prev.datos, ["cehvalDocumentoSoporNsExpComp"]: 0 };
                    return { ...prev, datos };
                });
                setValue((prev) => {
                    const datos = { ...prev.datos, ["errorDocumentacionIncompletaSupervisada"]: true };
                    return { ...prev, datos };
                });

            }

        } else {
            checkCompleto = document.getElementById("documentacionCompletaNoSupervisada")
            if (contadorDocumentosNoSupervisados === 7) {

                //console.log(contadorDocumentosSupervisados)
                checkCompleto.checked = true

                setValue((prev) => {
                    const datos = { ...prev.datos, ["errorDocumentacionIncompletaNoSupervisada"]: false };
                    return { ...prev, datos };
                });

                if (contadorDocumentosSupervisados > 0 && contadorDocumentosSupervisados < 4) {
                    //console.log("entra aqui")
                    setValue((prev) => {
                        const datos = { ...prev.datos, ["errorDocumentacionIncompletaSupervisada"]: true };
                        return { ...prev, datos };
                    });
                } else {
                    setValue((prev) => {
                        const datos = { ...prev.datos, ["errorDocumentacionIncompletaSupervisada"]: false };
                        return { ...prev, datos };
                    });
                }

            } else {
                checkCompleto.checked = false
                setValue((prev) => {
                    const datos = { ...prev.datos, ["documentacionCompletaNoSupervisada"]: 0 };
                    return { ...prev, datos };
                });
                setValue((prev) => {
                    const datos = { ...prev.datos, ["errorDocumentacionIncompletaNoSupervisada"]: true };
                    return { ...prev, datos };
                });
                if (contadorDocumentosSupervisados > 0 || contadorDocumentosSupervisados < 4) {
                    setValue((prev) => {
                        const datos = { ...prev.datos, ["errorDocumentacionIncompletaSupervisada"]: true };
                        return { ...prev, datos };
                    });
                } else {
                    setValue((prev) => {
                        const datos = { ...prev.datos, ["errorDocumentacionIncompletaSupervisada"]: false };
                        return { ...prev, datos };
                    });
                }

            }
        }

    }, [contadorDocumentosNoSupervisados])

    React.useEffect(() => {

        const checkCompleto = document.getElementById("cehvalDocumentoSoporNsExpComp")
        if (props.info.tipoParticipante === 1) {

            setValue((prev) => {
                const datos = { ...prev.datos, ["errorDocumentacionIncompletaNoSupervisada"]: false };
                return { ...prev, datos };
            });

            if (contadorDocumentosSupervisados === 4 && contadorDocumentosNoSupervisados === 7) {
                checkCompleto.checked = true
                setValue((prev) => {
                    const datos = { ...prev.datos, ["cehvalDocumentoSoporNsExpComp"]: 1 };
                    return { ...prev, datos };
                });

                setValue((prev) => {
                    const datos = { ...prev.datos, ["errorDocumentacionIncompletaSupervisada"]: false };
                    return { ...prev, datos };
                });


            } else {


                checkCompleto.checked = false
                setValue((prev) => {
                    const datos = { ...prev.datos, ["cehvalDocumentoSoporNsExpComp"]: 0 };
                    return { ...prev, datos };
                });

                setValue((prev) => {
                    const datos = { ...prev.datos, ["errorDocumentacionIncompletaSupervisada"]: true };
                    return { ...prev, datos };
                });

            }

        } else {

            //contador
            if (contadorDocumentosSupervisados === 4) {
                checkCompleto.checked = true
                setValue((prev) => {
                    const datos = { ...prev.datos, ["cehvalDocumentoSoporNsExpComp"]: 1 };
                    return { ...prev, datos };
                });


            } else {
                checkCompleto.checked = false
                setValue((prev) => {
                    const datos = { ...prev.datos, ["cehvalDocumentoSoporNsExpComp"]: 0 };
                    return { ...prev, datos };
                });

            }


            if (contadorDocumentosSupervisados > 0 && contadorDocumentosSupervisados < 4) {
                setValue((prev) => {
                    const datos = { ...prev.datos, ["errorDocumentacionIncompletaSupervisada"]: true };
                    return { ...prev, datos };
                });
            } else {
                setValue((prev) => {
                    const datos = { ...prev.datos, ["errorDocumentacionIncompletaSupervisada"]: false };
                    return { ...prev, datos };
                });
            }
        }

    }, [contadorDocumentosSupervisados])

    const checkboxFuncion = (id, nombreCheck) => {


        if (document.getElementById(nombreCheck).checked) {
          

            //console.log(id)
            //console.log(value.datos.cehvalDocumentoSoporNsExpComp)
            setValue((prev) => {
                const datos = { ...prev.datos, [nombreCheck]: 1 };
                return { ...prev, datos };
            });
            // setContadorDocumentos(10)
        } else {
          
            setValue((prev) => {
                const datos = { ...prev.datos, [nombreCheck]: 0 };
                return { ...prev, datos };
            });
        }
        // setArregloPermisos(arregloPivote)
    }

    const guardarId = (id, numero) => {
        //console.log(id, numero)
        if (numero === 1) {
            setValue((prev) => {
                const datos = { ...prev.datos, ["idFirma1"]: id };
                return { ...prev, datos };
            });
        } else {
            setValue((prev) => {
                const datos = { ...prev.datos, ["idFirma2"]: id };
                return { ...prev, datos };
            });
        }

    }



    return (
        <>
            <div className="card-body">
                <h5 className="textoTituloFormulario" >V. Firmas autorizadas</h5>

                {
                    props.info.cehvalFirmaAutCollection.map((item, index) => (
                        <>
                            {item.tipoFirma &&
                                <div className="row mb-5 ">
                                    <div className="col-md-6">
                                        {props.info.cehvalDepositanteCatTipDepId.nombre === "DIRECTO" ?
                                            <>
                                                {item.usuarioAdmin === 1 ?
                                                    <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">{index + 1}. Usuario administrador </label>

                                                    :
                                                    <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">{index + 1}. Usuario operador</label>

                                                }
                                            </>
                                            :
                                            <>
                                                <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">{index + 1}. Usuario operador</label>
                                            </>
                                        }
                                        <input disabled type="text" value={item.nombre} className="form-control  border  " required />
                                    </div>



                                    <div className="col-md-6 ">
                                        <label className="mb-2">Estatus Firma *</label>
                                        <div className="d-flex justify-content-start">
                                            <div className="contenedorBotonesStteper">
                                                < input placeholder="" type="radio" className="form-check-input me-2" value={1} onChange={(e) => {
                                                    guardarDatos(e)
                                                    guardarId(item.idFirma, index + 1)
                                                }} id={`firmado${index + 1}`} name={`estatusFirma${index + 1}`} required />
                                                <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor={`firmado${index + 1}`}>Firmado</label>
                                            </div>

                                            <div className="contenedorBotonesStteper">
                                                < input placeholder="" type="radio" className="form-check-input me-2" value={0} onChange={(e) => {
                                                    guardarDatos(e)
                                                    guardarId(item.idFirma, index + 1)
                                                }} id={`noFirmado${index + 1}`} name={`estatusFirma${index + 1}`} required />
                                                <label className="form-check-label textoFormularioDepositantedirecto  mt-1" htmlFor={`noFirmado${index + 1}`}>No firmado</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">cargo</label>
                                        <input disabled type="text" value={item.cargo} className="form-control  border  " required />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Tipo firma</label>
                                        <input disabled type="text" value={item.tipoFirma} className="form-control  border  " required />
                                    </div>
                                </div>
                            }

                        </>
                    ))
                }

                <br></br>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Lugar y fecha de registro</label>
                        <input disabled type="text" value={props.info.cehvalDepositanteLugarFecha} className="form-control  border  r" id="fechaRegistro" name="fechaRegistro" required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Casa de bolsa intermediaria</label>
                        <input disabled type="text" value={props.info.cehvalDepositantePersonaIcbiId.cehvalPersonaJurNombre} className="form-control  border" required />
                    </div>
                </div>



                <form name="f1" id='formualarioChecks1'>
                    {(props.info.cehvalDepositanteFolio).indexOf("DDPJ") >= 0 ?
                        <h5 className="textoTituloFormulario my-5" >Documentación soporte instituciones supervisadas</h5>
                        :
                        <h5 className="textoTituloFormulario my-5" >Documentación soporte instituciones supervisadas y no supervisadas</h5>
                    }


                    <div className="row mb-3 px-4">
                        <div className="col-md-4 mb-3">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumentoNoSupervisados(e.target.name)} id="cehvalDocumentoSoporNsCopiaEscrSoc" name="cehvalDocumentoSoporNsCopiaEscrSoc" />
                                <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsCopiaEscrSoc">
                                    Copia escritura social
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumentoNoSupervisados(e.target.name)} id="cehvalDocumentoSoporNsCopiaPoderAdm" name="cehvalDocumentoSoporNsCopiaPoderAdm" />
                                <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsCopiaPoderAdm">
                                    Copia poder de administración
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumentoNoSupervisados(e.target.name)} id="cehvalDocumentoSoporNsCopiaRtn" name="cehvalDocumentoSoporNsCopiaRtn" />
                                <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsCopiaRtn">
                                    Copia RTN
                                </label>
                            </div>
                        </div>

                    </div>

                    <div className="row mb-3 px-4">
                        <div className="col-md-4 mb-3">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumentoNoSupervisados(e.target.name)} id="cehvalDocumentoSoporNsCopiaDni" name="cehvalDocumentoSoporNsCopiaDni" />
                                <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsCopiaDni">
                                    Copia DNI Autorizados                                   </label>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumentoNoSupervisados(e.target.name)} id="cehvalDocumentoSoporNsCopiaRtnAut" name="cehvalDocumentoSoporNsCopiaRtnAut" />
                                <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsCopiaRtnAut">
                                    Copia RTN de autorizados
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4 mb-3">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumentoNoSupervisados(e.target.name)} id="cehvalDocumentoSoporNsConstanciaExoVig" name="cehvalDocumentoSoporNsConstanciaExoVig" />
                                <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsConstanciaExoVig">
                                    Constancia de exoneración vigente
                                </label>
                            </div>
                        </div>
                    </div>

                </form>

                {props.info.tipoParticipante === 1 ?

                    <>






                        <form name="f2" id='formualarioChecks2'>



                            <div className="row mb-3 px-4">
                                    <div className="col-md-4 mb-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumentoNoSupervisados(e.target.name)} id="contratoDeDepositante" name="contratoDeDepositante" />
                                            <label className="form-check-label" htmlFor="contratoDeDepositante">
                                                Contrato de depositante
                                            </label>
                                        </div>
                                    </div>
                            
                                <div className="col-md-4 mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumentoSupervisados(e.target.name)} id="cehvalDocumentoSoporNsNotRemiProgCump" name="cehvalDocumentoSoporNsNotRemiProgCump" />
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsNotRemiProgCump">
                                            Nota remisión programa de cumplimiento
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumentoSupervisados(e.target.name)} id="cehvalDocumentoSoporNsCopiaNomFunCump" name="cehvalDocumentoSoporNsCopiaNomFunCump" />
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsCopiaNomFunCump">
                                            Copia nombramiento funcionario de cumplimiento
                                        </label>
                                    </div>
                                </div>

                            </div>

                            <div className="row mb-3 px-4">
                                <div className="col-md-4 mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumentoSupervisados(e.target.name)} id="cehvalDocumentoSoporNsEntRegul" name="cehvalDocumentoSoporNsEntRegul" />
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsEntRegul">
                                            Ente regulador
                                        </label>
                                    </div>
                                </div>

                                <div className="col-md-4 mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumentoSupervisados(e.target.name)} id="cehvalDocumentoSoporNsFirmaAutExt" name="cehvalDocumentoSoporNsFirmaAutExt" />
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsFirmaAutExt">
                                            Firma auditora externa
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </form>

                    </>

                    :
                    <>
                        <div className="row mb-3 px-4">
                            <div className="col-md-4 mb-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumentoNoSupervisados(e.target.name)} id="contratoDeDepositante" name="contratoDeDepositante" />
                                    <label className="form-check-label" htmlFor="contratoDeDepositante">
                                        Contrato de depositante
                                    </label>
                                </div>
                            </div>
                        </div>

                        {props.info.cehvalDepositanteCatTipDepId.nombre === "INDIRECTO" &&
                            <div className="row mb-3 px-4">
                                <div className="col-md-6 mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value={1} disabled id="documentacionCompletaNoSupervisada" name="documentacionCompletaNoSupervisada" />
                                        <label className="form-check-label" htmlFor="documentacionCompletaNoSupervisada">
                                            Documentación completa
                                        </label>
                                    </div>
                                </div>
                            </div>
                        }



                        <form name="f2" id='formualarioChecks2'>
                            {(props.info.cehvalDepositanteFolio).indexOf("DIPJ") >= 0 &&
                                <h5 className="textoTituloFormulario my-5" >Documentación soporte instituciones supervisadas</h5>

                            }


                            <div className="row mb-3 px-4">
                                <div className="col-md-4 mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumentoSupervisados(e.target.name)} id="cehvalDocumentoSoporNsNotRemiProgCump" name="cehvalDocumentoSoporNsNotRemiProgCump" />
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsNotRemiProgCump">
                                            Nota remisión programa de cumplimiento
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumentoSupervisados(e.target.name)} id="cehvalDocumentoSoporNsCopiaNomFunCump" name="cehvalDocumentoSoporNsCopiaNomFunCump" />
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsCopiaNomFunCump">
                                            Copia nombramiento funcionario de cumplimiento
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumentoSupervisados(e.target.name)} id="cehvalDocumentoSoporNsEntRegul" name="cehvalDocumentoSoporNsEntRegul" />
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsEntRegul">
                                            Ente regulador
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3 px-4">

                                <div className="col-md-4 mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumentoSupervisados(e.target.name)} id="cehvalDocumentoSoporNsFirmaAutExt" name="cehvalDocumentoSoporNsFirmaAutExt" />
                                        <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsFirmaAutExt">
                                            Firma auditora externa
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </form>

                    </>

                }





                <div className="row mb-3 px-4">
                    <div className="col-md-6 mb-3">
                        <div className="form-check">
                            <input className="form-check-input" disabled type="checkbox" value={1} onChange={() => checkboxFuncion(`formualarioChecks`, "cehvalDocumentoSoporNsExpComp")} id="cehvalDocumentoSoporNsExpComp" name="cehvalDocumentoSoporNsExpComp" />
                            <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsExpComp">
                                Documentación completa
                            </label>
                        </div>
                    </div>
                </div>



            </div>

        </>
    );
}

