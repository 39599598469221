import { instance } from '../apiGlobal';

export async function traerSeries(obj) {
  
  try {
    const response = await instance(`/serie/consulta/todas`, {
      method: "post",
      data: obj
    })
    
    return response.data;
  } catch (error) {
    return error;
  }
}



