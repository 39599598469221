import React from "react";
import { useLocation } from "react-router-dom";


//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";

import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//Hooks
import useSolicitudesPerJuridica from '../../../hooks/useSolicitudesPerJuridica';
import useSolicitudesPerNatural from '../../../hooks/useSolicitudesPerNatural';

//APIs
import { traerInformacionAllDepositante } from "../../../api/VerInformacionUsuarios/traerInformacionAllDepositantes";
import { reporteDDPJ } from "../../../api/Documentos/reporteDDPJ";
import { reporteDIPJ } from "../../../api/Documentos/reporteDIPJ";
import { reporteDIPN } from "../../../api/Documentos/reporteDIPN";


export default function ImpresionsolicitudesDepositante({ history }) {
    const location = useLocation();
    const tipo = location.state;
    
    const estatus = 1;
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()
    
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        traerDepositantes()
    }, [])

    const traerDepositantes = async () => {
        const usuarios = await traerInformacionAllDepositante(estatus)
        let filtrados;
        if (usuarios) {
            setSpin(false)
            if ((isNaN(usuarios.error)) === false) {
              
                setErroreshttp(usuarios)
                
            } else {
                //console.log(usuarios)
                if (tipo === 1) {
                     filtrados = usuarios.filter(word => word.tipoParticipante === 1);
                } else if (tipo == 2) {
                     filtrados = usuarios.filter(word => word.tipoParticipante === 2);
                } else {
                     filtrados = usuarios.filter(word => word.tipoParticipante === 3);
                }
                setData(filtrados)
                setCopia(filtrados)
            }
        }
    }


    const continuarOferta = async (row) => {
        setLoading(true)
        const myobj = {
            codigo: row.original.cehvalDepositanteFolio,
            estatus: estatus
        }

        //console.log(myobj)
        var response;

        //VER SOLICITUD DE DEPOSITANTE DIRECTO // 
        if (row.original.tipoParticipante === 1) {  //mandar info depositante directo  
            response = await reporteDDPJ(myobj)

        } else if (row.original.tipoParticipante === 2) {
            response = await reporteDIPJ(myobj)

        } else {
            //console.log("entra aqui")
            response = await reporteDIPN(myobj)
            //console.log(response)
        }

        if (response) {
            setLoading(false)
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(response)
            } else {

                const file = new Blob(
                    [response],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
                setLoading(false)
            }
        }



    }




    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <div className="fluid-container">
                
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        

                        <div className="col-12">
                            <div className="">
                                {tipo === 1 &&
                                    <BannerTitulo titulo="Solicitudes de depositantes directos"></BannerTitulo>
                                }
                                {tipo === 2 &&
                                    <BannerTitulo titulo="Solicitudes de depositantes indirectos personas jurídicas"></BannerTitulo>
                                }
                                {tipo === 3 &&
                                    <BannerTitulo titulo="Solicitudes de depositantes indirectos personas naturales"></BannerTitulo>
                                }

                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <>
                                                    {tipo === 1 || tipo === 2 ?
                                                        <TablaUsuarios datos={data} displayInformation={continuarOferta} useValidate={useSolicitudesPerJuridica} textoBoton={"Imprimir"} textTitle="Click para ver imprimir la información del depositante"></TablaUsuarios>
                                                        :
                                                        <TablaUsuarios datos={data} displayInformation={continuarOferta} useValidate={useSolicitudesPerNatural} textoBoton={"Imprimir"} textTitle="Click para ver imprimir la información del depositante"></TablaUsuarios>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >

            </div>

        </>
    );
}
