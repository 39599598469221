import React, { useState } from "react";
//import { useLocation } from "react-router-dom";

//Componentes
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//Hooks
import useFondosConciliadosYEliminadosPDP from "../../../hooks/PagoDerechosPatrimoniales/useFondosConciliadosYEliminadosPDP";

//API's
import { consultarDepositosFondosEliminadosPDP } from "../../../api/PagoInteresesAmorDiv/consultarDepositosFondosEliminadosPDP";
import { imprimirTablaFondosConciliadosYEliminadosPDP } from "../../../api/PagoInteresesAmorDiv/imprimirTablaFondosConciliadosYEliminadosPDP";

//Muestra las cuentas de un depositante indirecto
export default function DepositosFondosEliminadosPDP({ history }) {
    //const location = useLocation();
    //If endpoint got the data will be true    
    const [erroreshttp, setErroreshttp] = useState();
    const [spin, setSpin] = React.useState(true)
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    
    const [loading, setLoading] = React.useState(false);


    React.useEffect(() => {
        traerInformacionFondosNoConciliados()
    }, []);

    const traerInformacionFondosNoConciliados = async () => {
        const response = await consultarDepositosFondosEliminadosPDP()
        //console.log(response)
        if (response) {
            setSpin(false)
            if ((isNaN(response.error)) === false) {
                setErroreshttp(response)
            } else {
                setData(response)
                setCopia(response)
            }
        }
    }

    const imprimir = async () => {
        setLoading(true)
        const myObj = {
            estatus: 4
        }

        const response = await imprimirTablaFondosConciliadosYEliminadosPDP(myObj)
        if (response) {

            //setLoading(false)
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta   
                setLoading(false)
                setErroreshttp(response)
            } else {
                const file = new Blob(
                    [response],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
                setLoading(false)
            }
        }
    }

    return (
        <>

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {loading &&
                <PantallaCargando />
            }


            <div className="fluid-container">
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        <div className="col-12 mb-5">
                            <BannerTitulo titulo="Depósitos de fondos no conciliados eliminados"></BannerTitulo>
                            {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                <div className="d-flex">
                                    <div className="container py-5">
                                        {copia &&
                                            <>
                                                <TablaMercado datos={data} useValidate={useFondosConciliadosYEliminadosPDP} mercadoPrimario={true} funcion1MercadoPrimario={imprimir} texto1MercadoPrimario="Imprimir"></TablaMercado>
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}
