//React
import React, { useState, useEffect } from "react";

//Componentes generales
import BannerTitulo from "../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../ComponentesGenerales/ManejadorErrores';


//APIs
import { traerFacturasRegistradas } from "../../api/MantenimientoFacturas/traerFacturasRegistradas";


//Hooks
import useTablaFacturasRegistradas from '../../hooks/MantenimientoFacturas/useTablaFacturasRegistradas';

//Componnetes de la tabla
import TablaUsuarios from "../../ComponentesGenerales/TablaUsuarios";


export default function TablaDatosFacturacion({ history }) {


    const [copia, setCopia] = useState();
    const [data, setData] = useState([]);

    const [spin, setSpin] = useState(true);
    const [erroreshttp, setErroreshttp] = useState();



    useEffect(() => {
        getData();
    }, [])




    const getData = async () => {
        try {
            setSpin(true);
            const response = await traerFacturasRegistradas();
            //console.log(response)
            if (isNaN(response.error) === false) {
                setErroreshttp(response);
                setSpin(false);

            } else {
                if (response.data) {
                    setData(response.data);
                    setCopia(response.data);
                    setSpin(false);

                }
            }

        } catch (error) {
            console.error(error);
        }
    }


    const handleViewData = (row) => {

        history.push({
            pathname: "/desactivarActivarMantenimiento",
            state: {
                rowData: row.original,
            }
        })
    }

    return (
        <>

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <div className="">
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Tabla de datos de facturación"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaUsuarios datos={data} displayInformation={handleViewData} useValidate={useTablaFacturasRegistradas} textoBoton={"Abrir"} textTitle="Click para notificar"></TablaUsuarios>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    );
}

