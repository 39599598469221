import { instance } from '../apiGlobal';

export async function consultarConciliacionesPendientes() {
      try {
        //const response = await instance(`/conciliacion/consultar/todas/pendientes`, {
          const response = await instance(`/conciliacion/consultar/todas/pendientesaprobadas`, {
          method:"get",
        })
        return response.data
      } catch (error) {
        return error
      }
}