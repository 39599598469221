import { useMemo } from "react";

export default function useSubastasLiquidadasDepositanteD() {
 const columns = useMemo(
   () => [   
     {
       Header:"Número de subasta",
       accessor: "numeroSubasta"
     },
     {
        Header:"Fecha",
        accessor: "fechaSubasta"
      },
      {
        Header:"Estatus",
        accessor: "estatusSubastaStr"
      },
  
   
   ],
   []
 );

 return columns;
}


