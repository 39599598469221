import React from "react";


import { FormContext } from "./form_context";

export default function RegistroDI5SinInformacion(props) {

    const [, setValue] = React.useContext(FormContext);
    const [contadorDocumentosFuncionario, setContadorDocumentosFuncionario] = React.useState(0);

    React.useEffect(() => {
        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 1 };
            return { ...prev, datos };
        });

    }, [])


    const guardarDocumento = (name) => {

        if (document.getElementById(name).checked) {
            //  setErrorSinPermisos(false)
            setContadorDocumentosFuncionario(contadorDocumentosFuncionario + 1)
            //arregloPivote.push(parseInt(numero, 10))            
            setValue((prev) => {
                const datos = { ...prev.datos, [name]: 1 };
                return { ...prev, datos };
            });
        } else {
            setValue((prev) => {
                const datos = { ...prev.datos, [name]: 0 };
                return { ...prev, datos };
            });
            setContadorDocumentosFuncionario(contadorDocumentosFuncionario + -1)
            //  arregloPivote = arregloPermisos.filter((item) => item !== parseInt(numero, 10))
        }
        ////console.log(arregloPivote)
    }

    React.useEffect(()=>{
        if(contadorDocumentosFuncionario===2){
            setValue((prev) => {
                const datos = { ...prev.datos, ["errorDocumentacionIncompletaFuncionario"]: false };
                return { ...prev, datos };
            });
        }else{
            setValue((prev) => {
                const datos = { ...prev.datos, ["errorDocumentacionIncompletaFuncionario"]: true };
                return { ...prev, datos };
            });
        }

    },[contadorDocumentosFuncionario])


    return (
        <>

            <div className="card-body">
                <h5 className="textoTituloFormulario" >V. Firmas autorizadas</h5>

                {
                    props.info.cehvalFirmaAutCollection.map((item, index) => (
                        <>
                            {item.tipoFirma &&

                                <div className="row mb-5 ">
                                    <div className="col-md-6">
                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Nombre completo {index + 1}</label>
                                        <input disabled type="text" value={item.nombre} className="form-control  border  " required />
                                    </div>
                                    {item.estatus === 0 && <div className="col-md-6">
                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Estatus firma</label>
                                        <input disabled type="text" value={"No firmó"} className="form-control  border  " required />
                                    </div>}

                                    {item.estatus === 1 && <div className="col-md-6">
                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Estatus firma</label>
                                        <input disabled type="text" value={"Sí firmó"} className="form-control  border  " required />
                                    </div>}



                             
                                    <div className="col-md-6">
                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Tipo firma</label>
                                        <input disabled type="text" value={item.tipoFirma} className="form-control  border  " required />
                                    </div>

                                </div>
                            }
                        </>
                    ))
                }


                <br></br>
                <div className="row mb-3 ">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Lugar y fecha de registro</label>
                        <input disabled type="text" value={props.info.cehvalDepositanteLugarFecha} className="form-control  border  r" id="fechaRegistro" name="fechaRegistro" required />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Casa de bolsa intermediaria</label>
                        <input disabled type="text" value={props.info.cehvalDepositantePersonaIcbiId.cehvalPersonaJurNombre} className="form-control  border" required />
                    </div>
                </div>

                <h5 className="textoTituloFormulario my-5" >Documentación soporte</h5>

                <div className="row mb-3 ">
                    <div className="col-md-4">
                        <div className="form-check">

                            <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsCopiaDni">
                                Copia de DNI
                            </label>

                            {props.info.cehvalDepositanteDocumentoSoporId.cehvalDocumentoSoporDni === 1 ?
                                <input className="form-check-input" disabled type="checkbox" checked />
                                :
                                <input className="form-check-input" disabled type="checkbox" />
                            }
                        </div>
                    </div>
                    <div className="col-md-4 ">
                        <div className="form-check">
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Copia de RTN
                            </label>
                            {props.info.cehvalDepositanteDocumentoSoporId.cehvalDocumentoSoporCopiaRtn === 1 ?
                                <input className="form-check-input" disabled type="checkbox" checked />
                                :
                                <input className="form-check-input" disabled type="checkbox" />
                            }
                        </div>
                    </div>
                    <div className="col-md-4  ">
                        <div className="form-check">
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Poder de representación o administración
                            </label>
                            {props.info.cehvalDepositanteDocumentoSoporId.cehvalDocumentoSoporPoderRepre === 1 ?
                                <input className="form-check-input" disabled type="checkbox" checked />
                                :
                                <input className="form-check-input" disabled type="checkbox" />
                            }
                        </div>
                    </div>
                    <div className="col-md-4 ">
                        <div className="form-check">
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                               Contrato de depositante
                            </label>
                            {props.info.cehvalDepositanteContratoDepositante === 1 ?
                                <input className="form-check-input" disabled type="checkbox" checked />
                                :
                                <input className="form-check-input" disabled type="checkbox" />
                            }
                        </div>
                    </div>

                </div>

                <div className="row  mb-3  ">
                    <div className="col-md-4 d-flex ">
                        <div className="form-check">
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Expediente completo
                            </label>
                            {props.info.cehvalDepositanteContratoDepositante === 1 ?
                                <input className="form-check-input" disabled type="checkbox" checked />
                                :
                                <input className="form-check-input" disabled type="checkbox" />
                            }
                        </div>
                    </div>
                </div>

                <h5 className="textoTituloFormulario my-5" >Unidad de cumplimiento</h5>

                {props.info.cehvalDepositanteEstatus === 2 ?
                    <div className="row mb-3 ">
                        <div className="col-md-4 mb-3">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumento(e.target.name)} id="revisionListaOfac" name="revisionListaOfac" />
                                <label className="form-check-label" htmlFor="revisionListaOfac">
                                    Revisión en lista OFAC
                                </label>
                            </div>
                        </div>

                      
                            <div className="col-md-4 mb-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={1} onChange={(e) => guardarDocumento(e.target.name)} id="certificacionDebidaDiligenciaRecibida" name="certificacionDebidaDiligenciaRecibida" />
                                    <label className="form-check-label" htmlFor="certificacionDebidaDiligenciaRecibida">
                                        Certificación de debida diligencia recibida
                                    </label>
                                </div>
                            </div>
                    </div>
                    :

                    <div className="row mb-3 ">
                        <div className="col-12 col-md-4 d-flex">
                            <div className="form-check">
                                <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsFirmaAutExt">
                                Revisión en lista OFAC
                                </label>
                                {props.info.cehvalDepositanteRevisionListaOfac === 1 ?
                                    <input className="form-check-input" disabled type="checkbox" checked />
                                    :
                                    <input className="form-check-input" disabled type="checkbox" />
                                }
                            </div>
                        </div>

                            <div className="col-12 col-md-4 d-flex">
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="cehvalDocumentoSoporNsFirmaAutExt">
                                    Certificación de debida diligencia recibida
                                    </label>
                                    {props.info.cehvalDepositanteCertificacionDebidaDiligencia === 1 ?
                                        <input className="form-check-input" disabled type="checkbox" checked />
                                        :
                                        <input className="form-check-input" disabled type="checkbox" />
                                    }
                                </div>
                            </div>
                      
                    </div>
                }

                {props.banderaDescripcion === 1 &&
                    <div className="row mb-3">
                        <div className="col-md-12">
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Motivo del rechazo</label>
                            <textarea disabled type="text" value={props.info.cehvalDepositanteDescripcion} className="form-control" id="origenRecursos" name="origenRecursos" required style={{ height: "7rem" }} />
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

