import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

//Componentes
import PantallaCargando from '../../../ComponentesGenerales/PantallaCargando';
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from '../../../ComponentesGenerales/ManejadorErrores';

//APIs
import { hacerTransferenciaLibrePago } from '../../../api/transferenciaLibreDePago/hacerTransferenciaLibrePago';
import { consultarCuentaReceptor } from '../../../api/transferenciaLibreDePago/consultarCuentaReceptor';


//Functions
import formatNumber from '../../../functions/FormatNumber';



export default function FormularioTraspasoLibrePago(props) {

    //If transferee is a Depositante Directo use this depositor code and name
    const codDepDirecto = props.codDepDirecto;
    const nameDepDirecto = props.nomDepDirecto;

    //Depositor code who transfer the values
    const depositorTransfereeCode = props.codDepositante;
    //Origin account information
    const isinSerie = props.isin;
    const codigoSerie = props.codSerie;
    const codigoEmision = props.codEmision;
    const cuentaOrigen = props.numeroCuenta;
    const idValor = props.idValor;

    //States to validate what type of depositor is the transferee
    const [isTransfereeDD, setIsTransfereeDD] = useState();
    const [isTransfereeDI, setIsTransfereeDI] = useState();

    //States to validate what type of Depositor is the receptor of the values
    const [isReceptorDI, setIsReceptorDI] = useState();
    const [isReceptorDD, setIsReceptorDD] = useState();

    //State to validate if receptors account is valid
    const [validAccountReceptor, setValidAccountReceptor] = useState(false);
    const [validTypeCurrency, setValidTypeCurrency] = useState(false);

    //States to manage errors
    const [error, setError] = useState();
    const [balanceError, setBalanceError] = useState();
    const [receptorAccountError, setReceptorAccountError] = useState();
    const [differentCurrencyError, setDifferentCurrencyError] = useState();
    const [invalidReceptorAccount, setInvalidReceptorAccount] = useState();

    //States to handle the transfer information
    const [loading, setLoading] = useState(false);
    const [codigoDepReceptor, setCodigoDepReceptor] = useState();
    const [nombreReceptor, setNombreDepReceptor] = useState();
    const [codigoDepDirecto, setCodigoDepDirecto] = useState();
    const [nombreDepDirecto, setNombreDepDirecto] = useState();
    const [monedaReceptor, setMonedaReceptor] = useState();
    const [idMonedaDestino, setIdMonedaDestino] = useState();
    //Para mostrar en el Front
    const [montoTransferir, setMontoTransferir] = useState();
    //Para mostrar en el back
    const [instrumentosTransferir, setInstrumentosTransferir] = useState();

    const [cuentaDepDestino, setCuentaDepDestino] = useState();


    //States to show modal when regiter is successful or not
    const [registroExitoso, setRegistroExitoso] = useState();
    const [registroFallido, setRegistroFallido] = useState();

    const [messageResponse, setMessageResponse] = useState();
    const [erroreshttp, setErroreshttp] = React.useState()

    useEffect(() => {
        validateTansferee();
    }, []);

    const historyReact = useHistory();


    //Validates if the transferee is a DD o DI when loads the page
    const validateTansferee = () => {
        try {

            const splitTransfereeCode = depositorTransfereeCode.match(/[a-z]+|[^a-z]+/gi).join(" ").replace(/\s+/g, " ");
            if (splitTransfereeCode.includes("DDIRPJ")) {
                setIsTransfereeDD(true);
                setIsTransfereeDI(false);
            } else {
                setIsTransfereeDD(false);
                setIsTransfereeDI(true);
            }

        } catch (error) {
            //console.log(error)
        }
    }



    //Query to get destination account information
    async function getReceptorAccount(numeroCuenta) {
        //Transferee account
        //console.log(numeroCuenta);
        // //console.log(props.numeroCuenta)
        const cuentaTransferente = props.numeroCuenta;
        ////console.log("cuenta origen", cuentaTransferente)
        try {

            //If destination account number is equal to the transferee 
            if (cuentaTransferente === numeroCuenta) {
                //Shows error message
                setError("La cuenta de destino no puede ser la misma que la de origen");
                //Unactivate confirm transaction button 
                document.querySelector("#btnTlpConfirm").disabled = true;

                //If account number is different then
            } else {

                //Shows loading screen until to get the destination account data
                setLoading(true);

                //Execute query to get destination account information
                const response = await consultarCuentaReceptor({ numeroCuentaValores: numeroCuenta });
                if (response) {
                    setLoading(false);
                    //setCargando(false)
                    if ((isNaN(response.error)) === false) {
                        // respuestaincorrecta           
                        setErroreshttp(response);
                    } else {
                        //console.log(response);
                        //When the await get the data hides loading screen
                        setLoading(false);

                        if (response.status === 200) {
                            //Clean error message if was any
                            setError("");
                            //Clear massage "La cuenta de destino es inválida"
                            setInvalidReceptorAccount(null);
                            //Set to true the valid account state
                            setValidAccountReceptor(true);
                            //Calls function to activate confirm button
                            //activateButton();
                            //Save data response
                            const depositorsData = response.data;


                            //START If destination account is from a despositante directo
                            const objetoDepositanteDirectoReceptor = depositorsData[0];
                            const codigoDDReceptor = objetoDepositanteDirectoReceptor.cehvalDepositanteCodigoCehval;


                            //Split letters of the depositor receptor code
                            const splitCodigoDDReceptor = codigoDDReceptor.match(/[a-z]+|[^a-z]+/gi).join(" ").replace(/\s+/g, " ");

                            //Validates if depositant receptor code has DDIRPJ initials
                            if (splitCodigoDDReceptor.includes("DDIRPJ")) {

                                //Set to true state to validate if destination account is from a Depositante Directo
                                setIsReceptorDD(true);
                                //Set to true state to validate if destination account is from a Depositante Indirecto
                                setIsReceptorDI(false);

                                //Receptors name
                                const nombreDDReceptor = objetoDepositanteDirectoReceptor.cehvalDepositantePersonaIcbiId.cehvalPersonaJurNombre;

                                //Filtra el array de cuentas del receptor para sacar la moneda de la cuenta destino
                                //Filters array to get the "Values account"
                                const searchTypeCurency = objetoDepositanteDirectoReceptor.cehvalCuentaValCollection.filter(word => word.cehvalCuentaValNum === numeroCuenta);
                                //Get the currency from that values account
                                const nombreMonedaReceptor = searchTypeCurency[0].catMonedaCatMonedaId.catMonedaDivisa;
                                //Get the currency id from that values account
                                const idMonedaReceptor = searchTypeCurency[0].catMonedaCatMonedaId.catMonedaId;

                                //Set destination depositor data to show in front
                                setCodigoDepReceptor(codigoDDReceptor);
                                setNombreDepReceptor(nombreDDReceptor);
                                setIdMonedaDestino(idMonedaReceptor);
                                setMonedaReceptor(nombreMonedaReceptor);

                                //Validate type o currency of both accounts  
                                validateTypeOfCurrency(nombreMonedaReceptor, props.monedaCuenta);
                            }
                            //END  If destination account is from a despositante directo
                            // START If destination account is from a despositante indirecto DINDPN or DINDPJ
                            //Get the depositante indirecto code
                            const codigoDepositanteReceptor = depositorsData[0].cehvalDepositanteCodigoCehval;

                            //Get the depositante directo code
                            const codigoDepositanteDirecto = depositorsData[1].cehvalDepositanteCodigoCehval;


                            //Split the two position array and get one array for each depositor
                            //Get a depositante indirecto array
                            const arrayDepositanteReceptor = depositorsData.filter(word => word.cehvalDepositanteCodigoCehval === codigoDepositanteReceptor);
                            //Get the depositante directo that owns the depositante indirecto
                            const arrayDepositanteDirecto = depositorsData.filter(word => word.cehvalDepositanteCodigoCehval === codigoDepositanteDirecto);

                            //Object from Depositante Indirecto DI
                            const objetoDepositanteReceptor = arrayDepositanteReceptor[0];

                            //Object from Depositante Directo
                            const objetoDepositanteDirecto = arrayDepositanteDirecto[0];


                            //Split letters of the depositor receptor code to validate what type of Depositante Indirecto is
                            const splitCodigoDepositanteReceptor = objetoDepositanteReceptor.cehvalDepositanteCodigoCehval.match(/[a-z]+|[^a-z]+/gi).join(" ").replace(/\s+/g, " ");


                            //Validates if is a Depositante Indirecto Persona Natural
                            if (splitCodigoDepositanteReceptor.includes("DINDPN")) {

                                ////console.log(row.original)
                                //State set to true when is  DIPN
                                setIsReceptorDI(true);
                                //State set to false if is a DIPJ
                                setIsReceptorDD(false);

                                //Get the names and first and second lastname of the depositante indirecto persona natural
                                const primerNombreDI = objetoDepositanteReceptor.cehvalDepositantePersonaNatId.cehvalPersonaNatPrimerNom;
                                const segundoNombreDI = objetoDepositanteReceptor.cehvalDepositantePersonaNatId.cehvalPersonaNatSegundoNom;
                                const primerApellidoDI = objetoDepositanteReceptor.cehvalDepositantePersonaNatId.cehvalPersonaNatPrimerApe;
                                const segundoApellidoDI = objetoDepositanteReceptor.cehvalDepositantePersonaNatId.cehvalPersonaNatSegundoApe;
                                //Full name of the depositante indirecto persona natural
                                const nombreDI = `${primerNombreDI} ${segundoNombreDI} ${primerApellidoDI} ${segundoApellidoDI}`;

                                //Filters receptor accounts array to get type of currency of destination account
                                const searchTypeCurency = objetoDepositanteReceptor.cehvalCuentaValCollection.filter(word => word.cehvalCuentaValNum === numeroCuenta);
                                const idMonedaReceptor = searchTypeCurency[0].catMonedaCatMonedaId.catMonedaId;
                                const nombreMonedaReceptor = searchTypeCurency[0].catMonedaCatMonedaId.catMonedaDivisa;

                                //Depositante indirecto data to show in front
                                setCodigoDepReceptor(objetoDepositanteReceptor.cehvalDepositanteCodigoCehval);
                                setNombreDepReceptor(nombreDI);
                                setIdMonedaDestino(idMonedaReceptor);
                                setMonedaReceptor(nombreMonedaReceptor);

                                //Validate type o currency of both accounts  
                                validateTypeOfCurrency(nombreMonedaReceptor, props.monedaCuenta);


                                //Get the Name and depositant code of the depositante directo  who holds this depositante indirecto
                                setCodigoDepDirecto(objetoDepositanteDirecto.cehvalDepositanteCodigoCehval);
                                setNombreDepDirecto(objetoDepositanteDirecto.cehvalDepositantePersonaJurId.cehvalPersonaJurNombre);


                                //Validates if is a Depositante Indirecto Persona Juridica
                            } else if (splitCodigoDepositanteReceptor.includes("DINDPJ")) {
                                setIsReceptorDI(true);
                                setIsReceptorDD(false);


                                //Filters receptor accounts array to get type of currency of destination account
                                const searchTypeCurency = objetoDepositanteReceptor.cehvalCuentaValCollection.filter(word => word.cehvalCuentaValNum === numeroCuenta);
                                const idMonedaReceptor = searchTypeCurency[0].catMonedaCatMonedaId.catMonedaId;
                                const nombreMonedaReceptor = searchTypeCurency[0].catMonedaCatMonedaId.catMonedaDivisa;


                                //Depositante indirecto data to show in front
                                setCodigoDepReceptor(objetoDepositanteReceptor.cehvalDepositanteCodigoCehval);
                                setNombreDepReceptor(objetoDepositanteReceptor.cehvalDepositantePersonaJurId.cehvalPersonaJurNombre);
                                setIdMonedaDestino(idMonedaReceptor);
                                setMonedaReceptor(nombreMonedaReceptor);

                                //Validate type o currency of both accounts  
                                validateTypeOfCurrency(nombreMonedaReceptor, props.monedaCuenta);

                                //Get the Name and depositant code of the depositante directo  who holds this depositante indirecto
                                setCodigoDepDirecto(objetoDepositanteDirecto.cehvalDepositanteCodigoCehval);
                                setNombreDepDirecto(objetoDepositanteDirecto.cehvalDepositantePersonaJurId.cehvalPersonaJurNombre);

                            }
                            // END If destination account is from a despositante directo DINDPN or DINDPJ
                        } else if (response.error === 500) {
                            setLoading(false);
                            setError("El número de cuenta no existe o es inválido");

                        } else if (response.error === 401) {
                            setLoading(false);
                            setError("La sesión ha caducado");
                        } else {
                            setLoading(false);
                            setError("No se pudo comunicar con el servidor, inténte de nuevo más tarde");
                        }
                    }
                }


            }
        } catch (err) {
            console.error(err.response);
        }
    }

    function handleConsultAccount(e) {
        e.preventDefault();


        if (cuentaDepDestino) {
            getReceptorAccount(cuentaDepDestino);

        } else if (cuentaDepDestino && validAccountReceptor) {
            document.querySelector("#btnTlpConfirm").disabled = true;
            

            //If click on "Consulta cuenta" without have entered and account show message 
        } else {
            setInvalidReceptorAccount("Ingrese una cuenta de destino");

        }
    }



    //Execute query to get information of destination account 
    const handleInputCuentaDestino = (e) => {
        //console.log(e.keyCode)
        if (e.keyCode === 8) {


            //Cleans error message "Ingrese cuenta de destino"
            setReceptorAccountError("");
            //Clear error messages
            setInvalidReceptorAccount(null);
            setMontoTransferir(null);
            //Set state to validate account receptor to false
            setValidAccountReceptor(false);

            //Clean error message
            setError("");
            //clean fields of the receptor section 
            setCodigoDepReceptor("");
            setNombreDepReceptor("");
            setMonedaReceptor("");
            //Hide fields of the receptor section
            setIsReceptorDI(false);
            setIsReceptorDD(false);

            //Set to false valid currency when clean receptor account input
            setValidTypeCurrency(false);
            //Clean error message
            setDifferentCurrencyError("");

        } 
    }


    //Send transaction when click to confirm button modal
    const sendTransaction = async (e) => {
        e.preventDefault();

        try {

            const dataTransaction = {
                cuentaOrigen: cuentaOrigen,
                isin: isinSerie,
                codigoSerie: codigoSerie,
                codigoEmision: codigoEmision,
                cantidadInstrumentos: parseInt(instrumentosTransferir),
                codigoDepositanteDestino: codigoDepReceptor,
                cuentaDestino: cuentaDepDestino?.toUpperCase(),
                idMoneda: idMonedaDestino,
                idValor: idValor,
            };

            //Shows loading screen
            setLoading(true);

            //Calls the API to do the transaction
            const response = await hacerTransferenciaLibrePago(dataTransaction);
            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    //console.log(response)

                    if (response.data) {
                        if (response.data.isSuccessful === true) {
                            setLoading(false);
                            setMessageResponse(response.data.mensaje);
                            //console.log("fue exitoso")
                            setRegistroExitoso(true);
                        }
                    } else{
                        //console.log("fue invalido")
                        setLoading(false);
                        setRegistroFallido(true);
                        //console.log(response)
                    } 
                }
            }

      

        } catch (err) {
            console.error(err);
        }
    }


    //Shows error message if doesnt enter a destination account yet
    const destinationAccountHandler = () => {
        if (!cuentaDepDestino || cuentaDepDestino === null || cuentaDepDestino === "") {
            setReceptorAccountError("Ingrese la cuenta de destino");
        }
    }


    //Shows an error message if currency of the accounts are different and set the state to true or false
    const validateTypeOfCurrency = (transfereeCurrency, receptorCurrency) => {

        //If currency are the same
        if (transfereeCurrency === receptorCurrency) {
            //Clean error message 
            setDifferentCurrencyError("");
            //Set to true the state validTypeCurrency
            setValidTypeCurrency(true);

            //If currency are different
        } else {
            //Shows error message
            setDifferentCurrencyError("Las cuentas deben tener el mismo tipo de moneda");
            //Set to false the state validTypeCurrency
            setValidTypeCurrency(false);
        }
    }


    //Activate the first button to confirm transaction
    const activateButton = (monto) => {

        //Validates if the amount to transfer is lower or equal to the account balance and if the destination account 
        //and type of currency are valid
        if (monto <= props.saldoDisponible && validAccountReceptor && validTypeCurrency) {
            setBalanceError("");
            setReceptorAccountError("");
            document.querySelector("#btnTlpConfirm").disabled = false;
        }
    }



    //Check if amount to transfer is a correct value and activate button to make transfer
    const validateAvailableBalance = (e) => {
        e.preventDefault();

        handleFormatNumber(e);

        //If amount to transfer is higher than the account balance
        if (e.target.value > props.saldoDisponible) {
            //show error message if destination account  has not been entered
            destinationAccountHandler();
            //Shows error message
            setBalanceError(`Cantidad de valores insuficiente. Ingresa una cantidad igual o menor a tu saldo de: ${props.saldoDisponible}`);
            //Unactivate confirm transaction button
            document.querySelector("#btnTlpConfirm").disabled = true;
            


            //If amount to transfer is 0
        } else if (e.target.value <= 0 || e.target.value === undefined || e.target.value === 0) {

            //show error message if destination account has not been entered
            destinationAccountHandler();
            //Shows a message to enter the amount to transfer
            setBalanceError("El importe debe ser mayor a 0");
            //Unactivate confirm transaction button
            document.querySelector("#btnTlpConfirm").disabled = true;
            

        } else if (e.target.value <= props.saldoDisponible && e.target.value > 0) {
            activateButton(e.target.value);
            destinationAccountHandler();
            setBalanceError(null);
        }
    }

    // Format number and prevent to enter letter, symbols and allow to enter decimals
    const handleFormatNumber = (e) => {

        //Value entered by user
        const { value } = e.target;

        const formattedNumber = formatNumber(value);
        setInstrumentosTransferir(formattedNumber.integerNumber);
        setMontoTransferir(formattedNumber.stringNumber);



    }

    

    //Handle modal when a transaction was successful or not
    const goBack = () => {
        historyReact.go(-1);
    }

    //Handle When press enter from the inputs to enter dereceptor account or the amount to transfer to show modal
    const handlePreventDefault = (e) => {

        if (validAccountReceptor) {
            if (e.keyCode === 13) {
                setInvalidReceptorAccount(null);
                e.preventDefault();
                const btnConfirm = document.querySelector("#btnTlpConfirm");
                btnConfirm.click();
            }

        } else {
            if (e.keyCode === 13) {
                e.preventDefault();
                setInvalidReceptorAccount("La cuenta de destino es inválida");
            }
        }
    }

    return (
        <>
            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={goBack} titulo={messageResponse} textoBoton="Aceptar"></ComponenteModal>
            }
            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={goBack} titulo={`Lo sentimos, no fué posible hacer la transferencia libre de pago`} textoBoton="Aceptar"></ComponenteModal>
            }
             {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded p-3">
                <form onSubmit={sendTransaction} >
                    <h5 className='font-acumin-variable text-start text-royal-blue fw-bolder my-3 px-3'>Transferente</h5>

                    <div className="row">
                        {/*If transferee is a Depositante Directo */}
                        {isTransfereeDD &&
                            <>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Código del depositante" value={props.codDepositante} className="form-control text-uppercase font-acumin-variable" name="codigoDepTransferente" type="text" placeholder="Código del depositante" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoDepTransferente">Código de depositante directo</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Número de cuenta de origen" value={props.numeroCuenta} className="form-control text-uppercase font-acumin-variable" name="numeroCuentaOrigen" type="text" placeholder="Número de cuenta de origen" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="numeroCuentaOrigen">Número de cuenta</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Número de cuenta de origen" value={props.nombreDepositante} className="form-control text-uppercase font-acumin-variable" name="nombreDepTransferente" type="text" placeholder="Nombre del depositante" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="nombreDepTransferente">Nombre del depositante</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Código ISIN" className="form-control text-uppercase font-acumin-variable" value={props.monedaCuenta} name="monedaTransferente" type="text" placeholder="Código ISIN" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="monedaTransferente">Moneda</label>
                                    </div>
                                </div>
                            </>
                        }
                        {/*If transferee is a Depositante Indirecto */}
                        {isTransfereeDI &&
                            <>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Código del depositante" value={props.codDepositante} className="form-control text-uppercase font-acumin-variable" name="codigoDepTransferente" type="text" placeholder="Código del depositante" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoDepTransferente">Código de depositante indirecto</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Número de cuenta de origen" value={props.nombreDepositante} className="form-control text-uppercase font-acumin-variable" name="nombreDepTransferente" type="text" placeholder="Nombre del depositante" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="nombreDepTransferente">Nombre del depositante indirecto</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Código depositante directo" value={codDepDirecto} className="form-control text-uppercase font-acumin-variable" name="codigoDepDirecto" type="text" placeholder="Código del depositante directo)" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoDepDirecto">Código del depositante directo</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Nombre depositante directo" value={nameDepDirecto} className="form-control text-uppercase font-acumin-variable" name="nombreDepDirecto" type="text" placeholder="Nombre del depositante directo" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="nombreDepDirecto">Nombre del depositante directo</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Número de cuenta de origen" value={props.numeroCuenta} className="form-control text-uppercase font-acumin-variable" name="numeroCuentaOrigen" type="text" placeholder="Número de cuenta de origen" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="numeroCuentaOrigen">Número de cuenta</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Código ISIN" className="form-control text-uppercase font-acumin-variable" value={props.monedaCuenta} name="monedaTransferente" type="text" placeholder="Código ISIN" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="monedaTransferente">Moneda</label>
                                    </div>
                                </div>

                            </>
                        }

                    </div>

                    <h5 className='font-acumin-variable  text-start text-royal-blue fw-bolder my-3 px-3'>Receptor</h5>


                    <div className="row">
                        {/*Input to enter the destination account*/}
                        <div className="col-12 col-md-6 mb-3">
                            <div className="form-floating ">
                                <input title="Número de cuenta de destino" className="form-control text-uppercase font-acumin-variable" onChange={(e) => setCuentaDepDestino(e.target.value.toUpperCase())} onKeyUp={(e) => handleInputCuentaDestino(e)} value={cuentaDepDestino} onKeyDown={(e) => handlePreventDefault(e)} maxLength={20} name="numeroCuentaDestino" type="text" placeholder="Número de cuenta de destino" required />
                                <label className="text-start font-acumin-variable text-royal-blue" htmlFor="numeroCuentaDestino">Ingrese la cuenta de destino</label>
                            </div>
                            {receptorAccountError && <div className="text-danger font-acumin-variable mt-1">{receptorAccountError}</div>}
                            {error && <div className="text-danger font-acumin-variable mt-1">{error}</div>}
                            {invalidReceptorAccount && <div className="text-danger mt-2">{invalidReceptorAccount}</div>}
                        </div>
                        <div className="col-12 mb-5">
                            <button onClick={handleConsultAccount} type="button" className="btn background-royal-blue text-white font-acumin-variable bg-hover-gold" >Consultar cuenta</button>
                        </div>

                        {/*If receptor is a Depositante Indirecto */}
                        {isReceptorDI &&
                            <>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Moneda de la cuenta de destino" className="form-control text-uppercase font-acumin-variable" value={monedaReceptor} name="idMonedaDestino" type="text" placeholder="Moneda cuenta destino" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="idMonedaDestino">Moneda</label>
                                    </div>
                                    {differentCurrencyError && <div className="text-danger font-acumin-variable mt-1">{differentCurrencyError}</div>}
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Código del depositante indirecto" value={codigoDepReceptor} className="form-control text-uppercase font-acumin-variable" name="codigoDepReceptor" type="text" placeholder="Nombre del cliente (Depositante Indiecto)" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoDepReceptor">Código del depositante indirecto</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Cuenta del depositante indirecto" value={nombreReceptor} className="form-control text-uppercase font-acumin-variable" name="nombreDepTransferente" type="text" placeholder="Nombre del depositante" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="nombreDepTransferente">Nombre del depositante indirecto</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Código depositante directo" value={codigoDepDirecto} className="form-control text-uppercase font-acumin-variable" name="codigoDepDirecto" type="text" placeholder="Código del depositante directo)" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoDepDirecto">Código del depositante directo</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Nombre depositante directo" value={nombreDepDirecto} className="form-control text-uppercase font-acumin-variable" name="nombreDepDirecto" type="text" placeholder="Nombre del depositante directo" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="nombreDepDirecto">Nombre del depositante directo</label>
                                    </div>
                                </div>

                            </>
                        }

                        {/*If receptor is a Depositante Directo */}
                        {isReceptorDD &&
                            <>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Moneda de la cuenta de destino" className="form-control text-uppercase font-acumin-variable" value={monedaReceptor} name="idMonedaDestino" type="text" placeholder="Moneda cuenta destino" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="idMonedaDestino">Moneda</label>
                                    </div>
                                    {differentCurrencyError && <div className="text-danger font-acumin-variable mt-1">{differentCurrencyError}</div>}
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Nombre del cliente" value={codigoDepReceptor} className="form-control text-uppercase font-acumin-variable" name="codigoDepReceptor" type="text" placeholder="Nombre del cliente (Depositante Indiectoa)" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoDepReceptor">Código del depositante directo</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Número de cuenta de origen" value={nombreReceptor} className="form-control text-uppercase font-acumin-variable" name="nombreDepTransferente" type="text" placeholder="Nombre del depositante" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="nombreDepTransferente">Nombre del depositante directo</label>
                                    </div>
                                </div>
                            </>
                        }

                    </div>



                    {validAccountReceptor &&
                        <>
                            <h5 className='font-acumin-variable text-start text-royal-blue fw-bolder my-3 px-3'>Cantidad de valores a transferir</h5>
                            <div className="row">
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Saldo disponible" className="form-control text-uppercase font-acumin-variable" value={props.saldoDisponible} name="saldoDisponible" type="text" placeholder="Saldo disponible" required disabled />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="saldoDisponible">Saldo disponible</label>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                    <div className="form-floating ">
                                        <input title="Cantidad a transferir" onChange={(e) => validateAvailableBalance(e)} value={montoTransferir} onKeyDown={(e) => handlePreventDefault(e)} className="form-control text-uppercase font-acumin-variable" name="montoTraspaso" type="text" placeholder="Monto del trapaso" required />
                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="montoTraspaso">Cantidad a transferir</label>
                                    </div>
                                    {balanceError && <div className="text-danger mt-1 font-acumin-variable">{balanceError}</div>}
                                </div>


                                {/*First button to confirm transaction */}
                                <div className="col-12 col-md-12 mb-3 mt-5  d-flex justify-content-center">
                                    <div className="form-floating ">
                                        <button id="btnTlpConfirm" type="button" data-bs-target="#confirmarTransferencia" data-bs-toggle="modal" className="btn background-royal-blue text-white px-5 py-2 fs-18 bg-hover-gold font-acumin-variable" disabled >Confirmar</button>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {/*Modal to confirm the transaction */}
                    <div id="confirmarTransferencia" className="modal" tabindex="-1">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title font-acumin-variable">Confirmar transferencia</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='cerrar'></button>
                                </div>
                                <div className="modal-body">
                                    <p className='font-acumin-variable'>¿Desea realizar la operación?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger font-acumin-variable" data-bs-dismiss="modal">Cancelar</button>
                                    <button type="submit" className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable" data-bs-dismiss="modal">Confirmar</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
                <div className="col-12 mt-5">
                    <button type="button" className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-1 background-royal-blue text-white font-acumin-variable" onClick={goBack}>Atrás</button>
                </div>
                {loading &&
                    <PantallaCargando />
                }
            </div>
        </>
    )
}