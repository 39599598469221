import React,{ useState } from "react";
import '../styles/stylesheet.css'

import { useAsyncDebounce } from "react-table";





export default function FiltroTabla({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const totalRegistersAvailable = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onFilterChange = useAsyncDebounce(
        (value) => setGlobalFilter(value || undefined),
        200
    );

    const handleInputChange = (e) => {
        setValue(e.target.value);
        onFilterChange(e.target.value);
    };


    return (
        <div className="cars-filter">
            <div className=" contenedorBotonesStteper row align-items-center">
                <div className="col-8  col-sm-10 col-md-5 mb-sm-3">
                    <div className="input-wrapper col-12 col-md-12 " >
                        <div className="input-group " style={{ position: "relative" }} >
                            <input type="text" className="form-control " size={20}
                                value={value || ""}
                                onChange={handleInputChange}
                                placeholder='Buscar'
                                aria-describedby="basic-addon1" />
                            <span className="" style={{ backgroundColor: "#242848", color: "white" }}> <i className="fa fa-search" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </div>



                <div className="col-8 col-sm-10 col-md-12 mt-4">
                    <p className="font-acumin-variable text-royal-blue fw-bolder text-score-registers m-0">Total de registros: {totalRegistersAvailable}</p>
                </div>


     

            </div>
        </div>
    );
}
