
import React, { useContext } from "react";
import { Link } from "react-router-dom";
//react context
import { UserContext } from '../UserContext';

export default function SubComponenteHeader2({ subgrupo, titulo,nav, id }) {
    const context = useContext(UserContext);
    const { autorizacion } = context;


    return (
        <>
            {autorizacion.filter(item => item.subGrupo === subgrupo).length > 0 &&
                <li className="nav-item mb-2">
                    <div className="border-none w-100" data-bs-toggle="collapse" data-bs-target={nav}   aria-controls={id} aria-expanded="false" aria-label="Toggle navigation">
                        <div className="container-fluid">
                            <div className="row align-items-center text-hover-blue-shadow w-100 cursor-pointer">
                                <div className="col-10 text-royal-blue navbar-brand fs-x-small m-0 text-start text-wrap">
                                    {titulo}
                                </div>
                                <div className="col-2 row text-royal-blue align-items-center justify-content-center">
                                    <i className="ri-arrow-down-s-line p-0"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="navbar-toggler ">
                        <nav className="navbar navbar-expand-lg bg-body-tertiary p-0">
                            <div className="collapse navbar-collapse ps-2" id={id}>
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0" data-bs-dismiss="offcanvas">
                                    {autorizacion.filter(item => item.subGrupo === subgrupo).map((item, index) => (
                                        <li key={index} className="nav-item">
                                            <Link to={item.url} className="nav-link text-start fon-royal-blue fs-xx-small ps-2" >{item.label}</Link>
                                        </li>
                                    ))
                                    }
                                </ul>
                            </div>
                        </ nav>
                    </div>
                </li>
            }
        </>
    )
}