import React, { useState, useEffect } from "react";

import { Modal } from "react-bootstrap";


//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";

import formatNumber from "../../../functions/FormatNumber";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { devolverFondosVirtuales } from "../../../api/MercadoSecundario/devolverFondosVirtuales";

export default function FormularioDevolucion({ history }) {

    


    const [erroreshttp, setErroreshttp] = useState();
    const [folio, setFolio] = useState();


    //Errors and messages
    const [procesoExitoso, setProcesoExitoso] = useState();
    const [procesoFallido, setProcesoFallido] = useState();
    const [mensaje, setMensaje] = useState();
    const [dobleConfirmacion, setDobleConfirmacion] = useState();
    const [fechaDeposito, setFechaDeposito] = React.useState()
    const [datos, setDatos] = React.useState()
    const [cantidadDevuelta, setCantidadDevuelta] = React.useState()
    const [cantidadDevueltaStr, setCantidadDevueltaStr] = React.useState()
    
    const [cantidadMayor, setCantidadMayor] = React.useState()
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        guardarDatos();
    }, []);




    const guardarDatos = async () => {
        if (history.location.state === undefined) {
            history.go(-1)
        } else {
            console.log(history.location.state.objMenu)
            setDatos(history.location.state.objMenu)
        }
    }


    const onSubmitForm = async (e) => {
        e.preventDefault();
        setDobleConfirmacion(true)
    }

    const devolver = async (e) => {

     //   //console.log(datos.fondosDisponibles)
       // //console.log(parseInt(cantidadDevuelta, 10))
       // //console.log(cantidadDevuelta)

        if (parseInt(cantidadDevuelta, 10) > datos.fondosDisponibles) {
            setCantidadMayor(true)
            setDobleConfirmacion(false)
            setCantidadDevuelta("")
        } else {
            setDobleConfirmacion(false)
            setLoading(true);
            const objetoUsuario = {
                codigoCliente: datos.codigoCliente,
                nombreCliente: datos.nombreCliente,
                fondosDisponiblesStr: datos.fondosDisponiblesStr,
                fondosDisponibles: datos.fondosDisponibles,
                fondosApartadosStr: datos.fondosApartadosStr,
                fondosApartados: datos.fondosApartados,
                monedaStr: datos.monedaStr,
                moneda: datos.moneda,
                direccion: datos.direccion,
                tipoCuenta: datos.tipoCuenta,
                rtn: datos.rtn,
                banco: datos.banco,
                cuentaBancaria: datos.cuentaBancaria,
                folioBch: folio,
                cantidadDevuelta: cantidadDevuelta,

            }

            const response = await devolverFondosVirtuales(objetoUsuario);
            //console.log(response)
            setLoading(false);
            if (response) {
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response);
                } else {
                    if (response.codigo === "000") {
                        setProcesoExitoso(true);
                        setMensaje("Devolución realizada con éxito");
                    } else {
                        setProcesoFallido(true);
                        setMensaje(response.mensaje);
                    }
                }
            }
        }
    }

    const cerrarModal = () => {
        let estado;
        if(datos.moneda===1){
           estado=1
        }else if(datos.moneda===2) {
          estado=2
        }
        //console.log("entrafondos virtuales")
        history.push({
            pathname: '/consultarFondosVirtuales',state: estado
        })
    }

    const onHideModal = () => {
    }

    const guardarCantidad = (e) => {
        //console.log(e)
        const numeroFormateado = formatNumber(e)
        //console.log(numeroFormateado)
        setCantidadDevueltaStr(numeroFormateado.stringNumber)
        setCantidadDevuelta(numeroFormateado.integerNumber)
    }

    return (
        <>
            {loading &&
                <PantallaCargando />
            }

            

            {cantidadMayor &&
                <ComponenteModal mostrarModal={cantidadMayor} cerrarModal={() => setCantidadMayor(false)} titulo="La cantidad a devolver no puede exceder a los fondos disponibles, ingrese una cantidad menor" textoBoton="Entendido"></ComponenteModal>
            }

            <Modal show={dobleConfirmacion} animation={false} centered onHide={onHideModal}>
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => setDobleConfirmacion(false)} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >¿Esta seguro de devolver {cantidadDevueltaStr} {datos?.monedaStr} al cliente CEHVAL {datos?.nombreCliente}?</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => setDobleConfirmacion(false)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Cancelar</button>
                        <button onClick={devolver} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Aceptar</button>
                    </div>
                </div>
            </Modal>


            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {procesoExitoso &&
                <ComponenteModal mostrarModal={procesoExitoso} cerrarModal={cerrarModal} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {procesoFallido &&
                <ComponenteModal mostrarModal={procesoFallido} cerrarModal={() => setProcesoFallido(false)} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            <div className="fluid-container">{/* style={{ backgroundImage: `url(${fondo})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="fluid-container">*/}
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 mb-5 main-min-height">
                            <div className="">
                                <BannerTitulo titulo="Devolución"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <section className="d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded">
                                                <div className="col-12">
                                                    {datos &&
                                                        <form onSubmit={onSubmitForm} className="needs-validation font-acumin-variable text-royal-blue">
                                                            <div className="row mt-4">
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input value={datos.codigoCliente} title="Código de cliente CEHVAL" className="form-control fs-5" name="Codigo de cliente CEHVAL" type="text" placeholder="Codigo de cliente CEHVAL" disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Codigo de cliente CEHVAL">Código de cliente CEHVAL</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 form-floating mb-3">
                                                                    <div className="form-floating">
                                                                        <input title="Nombre de cliente CEHVAL" type="text" className="form-control border" value={datos.nombreCliente} name="Nombre de cliente CEHVAL" disabled />
                                                                        <label htmlFor="Nombre de cliente CEHVAL" className="form-label ">Nombre de cliente CEHVAL</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Moneda" className="form-control fs-5" value={datos.monedaStr} name="Moneda" type="text" placeholder="Moneda" disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Moneda">Moneda</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Cantidad" className="form-control fs-5" onChange={(e) => guardarCantidad(e.target.value)} value={cantidadDevueltaStr} name="Cantidad" type="text" placeholder="Cantidad" required />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Cantidad">Cantidad *</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-12 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Dirección" className="form-control fs-5" value={datos.direccion} name="Direccion" type="text" placeholder="Direccion" disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Direccion">Dirección</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Tipo de cuenta" className="form-control text-uppercase" type="text" value={datos.tipoCuenta} name="Tipo de cuenta" placeholder="Tipo de cuenta" disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Tipo de cuenta">Tipo de cuenta</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="RTN" className="form-control text-uppercase" type="text" value={datos.rtn} name="RTN" placeholder="RTN" disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="RTN">RTN</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Banco" className="form-control text-uppercase" type="text" value={datos.banco} onChange={(event) => setFechaDeposito(event.target.value)} name="Banco" placeholder="Banco" disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Banco">Banco</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Cuenta bancaria" className="form-control text-uppercase" type="text" value={datos.cuentaBancaria} onChange={(event) => setFechaDeposito(event.target.value)} name="Cuenta bancaria" placeholder="Cuenta bancaria" disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Cuenta bancaria">Cuenta bancaria</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Folio" className="form-control text-uppercase" type="text" value={fechaDeposito} onChange={(event) => setFolio(event.target.value)} name="Folio" placeholder="Folio" required />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Folio">Folio *</label>
                                                                    </div>
                                                                </div>


                                                                <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                    <button type="submit" className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-1 background-royal-blue text-white bg-hover-gold">Devolver</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

