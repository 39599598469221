import { instanciaRegistroPDF } from '../apiGlobal';
export async function descargarPDF(obj) {
    try {
        const response = await instanciaRegistroPDF(`/prendasgarantias/descargar/solicitud`, {
            method: "post",
            data: obj,
            responseType: 'blob',
        })
        return response;
    } catch (error) {
        return error
    }
}

