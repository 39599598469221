import React, { useState } from 'react';

export const FormContext = React.createContext();

export function FormProvider({ children }) {
  const [formValue, setFormValue] = useState({
    datos: {
      codigoEmisor:"",
      codigoDepositante: "",
      nombreContacto: "",
      correoContacto: "",

      nombreEmisor:"",
      sector:"",
      subSector:"",

      nacionalidad: "",
      rtn:"",
      paginaWeb:"",

      nombreRepresentanteLegal:"",
      correoRepresentanteLegal:"",

      numeroCuentaN: "",
      bancoN:"",
      monedaN: 1,
      tipoCuentaN: "",
      
      numeroCuentaE: "",
      bancoE:"",
      monedaE: 2,
      tipoCuentaE: "",

      establecerModificacion:false
    }
  });

return (
  <FormContext.Provider value={[formValue, setFormValue]}>
    {children}
  </FormContext.Provider>
);
}
