import { instance } from '../api/apiGlobal';

export async function authRolApi(obj) {
  
      try {
        const response = await instance(`/auth_user/consultar`, {
          method:"post",
          data:obj
        })
        return response.data
      } catch (error) {
        return error
      }
}