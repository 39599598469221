
import { instance } from '../apiGlobal';

export async function TraerActividadEconomica() {
  try {
    const response = await instance.get(`/cat/actividadeseconomicas`, {
      headers: {
        'Content-Type': 'application/json'
        
      }
    })
  
    return response.data
   
  } catch (error) {
    return error
  }
}
