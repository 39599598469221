import React, { useState, useEffect } from "react";
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";


//se pasa import useSolicitudesPerJuridica from '../../../hooks/useSolicitudesPerJuridica'

import { BiFirstPage, BiLastPage } from "react-icons/bi";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";


import FiltroTabla from "./FiltroTabla";
import Dropdown from "react-bootstrap/Dropdown";
import SinRegistros from "./SinRegistros";


export default function TablaSalvaguardasFinancieras({ datos, getRowData, useValidate, ArrayOperacionesForzadas, optionNoSelected }) {

    useEffect(() => {
    }, [])



    const data=datos


    const columns = useValidate();//me lo pasan
    const table = useTable(
        {
            columns,
            data,// me lo pasan
            initialState: {
                pageSize: 10,
                pageIndex: 0
            }
        },
        useGlobalFilter,
        useSortBy,
        usePagination,

    );



    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter }
    } = table;


    //Handle the state of each select input
    const [selectValues, setSelectValues] = useState({});


    //Handle the value of each select input and adds it to the object of the row
    const handleSelectChange = (row, event) => {
        //Handle the values selected for the user
        const selectName = event.target.name;
        const selectValue = event.target.value;
        setSelectValues((prevState) => ({
            ...prevState,
            [selectName]: selectValue,
        }));

        //Objeto con la operación forzada
        const objOperacionForzada = row.original;
        //El folio seleccionado por el usuario
        const folioPorAsociar = selectValue;

        //Añadimos el folio seleccionado al objeto de la fila que tiene la operación forzada
        objOperacionForzada.folioAsociado = folioPorAsociar;
    };


    return (
        <>
            {preGlobalFilteredRows.length === 0 ?
                <SinRegistros></SinRegistros>
                :
                <div className="tabla">
                    <div className="row contenedorsteper g-0" id="contenedorsteper">
                        <div className="col-12 contenedorsteper">
                            <div style={{ border: "transparent" }} className="card ">
                                <div className="card-body">
                                    <FiltroTabla
                                        preGlobalFilteredRows={preGlobalFilteredRows}
                                        globalFilter={globalFilter}
                                        setGlobalFilter={setGlobalFilter}
                                    />
                                    {optionNoSelected &&
                                        <>
                                        <p className="text-danger">Por favor seleccione el folio que desea asociar</p>
                                        </>
                                    }
                                    {/* Añadimos las propiedades a nuestra tabla nativa */}
                                    <div className="table-responsive mb-3" >
                                        <table aria-describedby="tablaSalvaguardasFinancieras" className="table table-hover text-royal-white background-royal-blue text-center table-sm" {...getTableProps()} style={{ marginTop: 30, marginBottom: 20 }}>
                                            <thead>
                                                {
                                                    // Recorremos las columnas que previamente definimos
                                                    headerGroups.map(headerGroup => {
                                                        return (
                                                            // Añadimos las propiedades al conjunto de columnas
                                                            <tr className="rounded text-light background-royal-blue" {...headerGroup.getHeaderGroupProps()}>
                                                                {
                                                                    // Recorremos cada columna del conjunto para acceder a su información
                                                                    headerGroup.headers.map((column) => (


                                                                        // Añadimos las propiedades a cada celda de la cabecera
                                                                        <th {...column.getHeaderProps()}
                                                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                                                            className={
                                                                                column.isSorted
                                                                                    ? column.isSortedDesc
                                                                                        ? "descp-3 border text-center font-acumin-variable align-middle"
                                                                                        : "ascp-3 border text-center font-acumin-variable align-middle"
                                                                                    : "p-3 border text-center font-acumin-variable align-middle"
                                                                            }>
                                                                            {
                                                                                // Pintamos el título de nuestra columna (propiedad "Header")
                                                                                column.render("Header")
                                                                            }
                                                                        </th>
                                                                    ))
                                                                }


                                                                <th className="p-3 border text-center font-acumin-variable align-middle">Operación incumplida asociada</th>
                                                                <th className="p-3 border text-center font-acumin-variable align-middle">Acción</th>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </thead>
                                            {/* Añadimos las propiedades al cuerpo de la tabla */}
                                            <tbody {...getTableBodyProps()}>
                                                {
                                                    //Crea las filas de acuerdo el número de objetos que hay en el array que pasamos al prop useValidate 
                                                    page.map((row, index) => {

                                                        prepareRow(row);
                                                        return (
                                                            // Añadimos las propiedades a la fila
                                                            <tr key={row.id} {...row.getRowProps()}>
                                                                <>
                                                                    {
                                                                        // Recorremos cada celda de la fila
                                                                        row.cells.map((cell, ix) => {


                                                                            // Añadimos las propiedades a cada celda de la fila
                                                                            return (
                                                                                <>
                                                                                    <td key={ix} className="text-center p-3 align-middle border font-acumin-variable" {...cell.getCellProps()}>
                                                                                        {
                                                                                            // Pintamos el contenido de la celda
                                                                                            cell.render("Cell")
                                                                                        }
                                                                                    </td>

                                                                                </>
                                                                            );
                                                                        })


                                                                    }

                                                                    <td className="p-3 font-acumin-variable align-middle">
                                                                        <div className="form-floating">
                                                                            {
                                                                                //Itera sobre array de elementos que recibe la petición operacionesForzadasNoAsociadas();
                                                                                //El numero de elementos que haya sobre el array es el numero de inputs que se van a renderizar pero como solo 
                                                                                //queremos pintar un solo input tipo select por cada fila, entonces vamos a añadir las siguientes validaciones
                                                                                ArrayOperacionesForzadas.map((option, ix) => {

                                                                                    //Crea solamente un input select por cada fila validando el index del array y la fila en la que se encuentra
                                                                                    if (row.id == ix) {

                                                                                        //Itera sobre la longitud de array ArrayOperacionesForzadas donde cada objeto tiene dos atributos;
                                                                                        //operacionNoAsociada
                                                                                        //asociacionesDisponibles
                                                                                        for (let i = 0; i < ArrayOperacionesForzadas.length; i++) {
                                                                                            //Selecciono solo el objeto que pertenece a la fila
                                                                                            let objOperacionForzada = ArrayOperacionesForzadas[i];

                                                                                            //Si la posicion del elemento seleccionado es igual a la fila a la que pertenece entonces renderizamos el select
                                                                                            if (i == row.id) {

                                                                                                return (
                                                                                                    <select className="form-select"
                                                                                                        key={option.operacionNoAsociada.folio}
                                                                                                        name={option.operacionNoAsociada.folio}
                                                                                                        value={selectValues[option.operacionNoAsociada.folio] || ""}
                                                                                                        onChange={(e) => handleSelectChange(page[index], e)}
                                                                                                    >
                                                                                                        <option value=""></option>
                                                                                                        {
                                                                                                            //Iteramos sobre el atributo asociacionesDisponibles del objeto que seleccionamos
                                                                                                            //Mostramos las opciones que tendrá el input
                                                                                                            objOperacionForzada.asociacionesDisponibles.map((el) => {
                                                                                                                return (
                                                                                                                    <option key={el} value={el}>{el}</option>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </select>
                                                                                                )
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                            <label className="font-acumin-variable" htmlFor="codigoEmision">Asociaciones disponibles</label>
                                                                        </div>
                                                                    </td>

                                                                    <td className="p-3 font-acumin-variable align-middle">
                                                                        <button onClick={(e) => getRowData(page[index], e)} className="btn background-royal-blue bg-hover-white text-hover-blue text-f7" type="button" >Asociar</button>
                                                                    </td>
                                                                </>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" contenedorBotonesStteper row px-3 g-0">
                        <div className="col-12 col-md-6 col-sm-6">
                            <div className="card-body d-flex justify-content-start">
                                <nav aria-label="Page navigation example" >
                                    <ul className="pagination justify-content-center font-acumin-variable">
                                        <li className="page-item cursor-pointer">
                                            <button style={{ color: "#242848" }} className="page-link" onClick={(e) => gotoPage(0, e.preventDefault())} disabled={!canPreviousPage}>
                                                <BiFirstPage className="page-controller text-pagination" />
                                            </button>{" "}
                                        </li>

                                        <li className="page-item cursor-pointer">
                                            <button style={{ color: "#242848" }} className="page-link" onClick={(e) => previousPage(e.preventDefault())} disabled={!canPreviousPage}>
                                                <MdKeyboardArrowLeft className="page-controller text-pagination" />
                                            </button>{" "}
                                        </li>

                                        <li className="page-item cursor-pointer page-width">
                                            <span className="page-link text-pagination page-height" style={{ color: "#242848" }}>
                                                Página&nbsp;
                                            
                                                        <strong >
                                                            {pageIndex + 1} de {pageOptions.length}
                                                        </strong>{" "}
                                              
                                            </span>
                                        </li>

                                        <li className="page-item cursor-pointer">
                                            <button style={{ color: "#242848" }} className="page-link" onClick={(e) => nextPage(e.preventDefault())} disabled={!canNextPage}>
                                                <MdKeyboardArrowRight className="page-controller text-pagination" />
                                            </button>{" "}
                                        </li>
                                        <li className="page-item cursor-pointer">
                                            <button style={{ color: "#242848" }} className="page-link"
                                                onClick={(e) => gotoPage(pageCount - 1, e.preventDefault())}
                                                disabled={!canNextPage}
                                            >
                                                <BiLastPage className="page-controller text-pagination" />
                                            </button>{" "}
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-sm-6 mt-3">
                            <div className="card-body d-flex justify-content-start justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end m-0">
                                <Dropdown className="justify-content-end font-acumin-variable" >
                                    <select className="page-link m-0"
                                        value={pageSize}
                                        onChange={(e) => setPageSize(Number(e.target.value))}
                                        style={{ marginLeft: 100, color: "#242848", borderRadius: 10 }}

                                    >
                                        {[5, 10, 15, 20].map((pageSize) => (
                                            <option key={pageSize} value={pageSize}>
                                                {pageSize !== 16 ? `Mostrar ${pageSize}` : `Mostrar todo`}
                                            </option>
                                        ))}
                                    </select>
                                </Dropdown>
                            </div>
                        </div>

                    </div>
                </div>
            }

        </>

    );
}













