import React from "react";
import { Modal } from "react-bootstrap";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";


//APIs
import { actualizarRechazoDepositoRetiro } from "../../../api/mantenimientoCargaValores/actualizarRechazoDepositoRetiro";
import { actualizarAutorizacionDepositoRetiro } from "../../../api/mantenimientoCargaValores/actualizarAutorizacionDepositoRetiro";

export default function AutorizarDepositosRetirosValores({ history }) {
    if (history.location.state === undefined) {
        history.go(-1)
    }

    const [erroreshttp, setErroreshttp] = React.useState()
    const [mensaje, setMensaje] = React.useState()
    const [dobleConfirmacionRechazoDeposito, setDobleConfirmacionRechazoDeposito] = React.useState()
    const [dobleConfirmacionAutorizacionDeposito, setDobleConfirmacionAutorizacionDeposito] = React.useState()
    const [dobleConfirmacionRechazoRetiro, setDobleConfirmacionRechazoRetiro] = React.useState()
    const [dobleConfirmacionAutorizacionRetiro, setDobleConfirmacionAutorizacionRetiro] = React.useState()
    const [envioExitoso, setEnvioExitoso] = React.useState()
    const [envioFallido, setEnvioFallido] = React.useState()
    const [datosDepositoRetiro, setDatosDepositoRetiro] = React.useState()
    const [loading, setLoading] = React.useState(false);
    const [descripcion, setDescripcion] = React.useState();
    const [desplegrarDescripcion, setDesplegarDescripcion] = React.useState(false)
    const [constanteRechazo, setConstatenteRechazo] = React.useState()

    React.useEffect(() => {
        //console.log(history.location.state.objMenu)
        setDatosDepositoRetiro(history.location.state.objMenu)
    }, [])


    const Autorizar = () => {
        if (history.location.state.objMenu.tipoSolicitud === "DEPOSITO") {
            setDobleConfirmacionAutorizacionDeposito(true)
        } else {
            setDobleConfirmacionAutorizacionRetiro(true)
        }

    }

    const Rechazar = () => {
        if (history.location.state.objMenu.tipoSolicitud === "DEPOSITO") {
            setDobleConfirmacionRechazoDeposito(true)
        } else {
            setDobleConfirmacionRechazoRetiro(true)
        }

    }

    //peticion de autorizacion de deposito o retiro
    const autorizarDepositoRetiro = async (condicion) => {
        setLoading(true)
        setDobleConfirmacionAutorizacionDeposito(false)
        setDobleConfirmacionAutorizacionRetiro(false)
        const response = await actualizarAutorizacionDepositoRetiro({ id: datosDepositoRetiro.idSolicitud })
        //console.log(response)
        if (response) {
            setLoading(false)
            if ((isNaN(response.error)) === false) {
                setErroreshttp(response)
            } else {
                if (condicion === 1) {
                    if (response.codigo === "000") {
                        setMensaje("Depósito de valores autorizado. Notificación enviada")
                        setEnvioExitoso(true)
                    } else {
                        setMensaje(response.mensaje)
                        setEnvioFallido(true)
                    }
                } else {
                    if (response.codigo === "000") {
                        setMensaje("Retiro de valores autorizado. Notificación enviada")
                        setEnvioExitoso(true)
                    } else {
                        setMensaje(response.mensaje)
                        setEnvioFallido(true)
                    }
                }
            }
        }
    }

    //peticion de rechazo de deposito o retiro
    const rechazarDepositoRetiro = async (e) => {
        e.preventDefault()


        setLoading(true)
        setDobleConfirmacionRechazoDeposito(false)
        setDobleConfirmacionRechazoRetiro(false)
        const response = await actualizarRechazoDepositoRetiro({ id: datosDepositoRetiro.idSolicitud,descripcion:descripcion })
        if (response) {
            setLoading(false)
            if ((isNaN(response.error)) === false) {
                setErroreshttp(response)
            } else {
                if (constanteRechazo === 1) {
                    if (response.codigo === "000") {
                        //deposito rechazado 
                        setMensaje("Deposito de valores rechazado. Notificación enviada")
                        setEnvioExitoso(true)
                    } else {
                        setMensaje("No se ha podido rechazar el deposito, intente mas tarde")
                        setEnvioFallido(true)
                    }
                } else {
                    if (response.codigo === "000") {
                        //deposito autorizado 
                        setMensaje("Retiro de valores rechazado. Notificación enviada")
                        setEnvioExitoso(true)
                    } else {
                        setMensaje("No se ha podido rechazar el retiro, intente mas tarde")
                        setEnvioFallido(true)
                    }
                }
            }
        }
    }

    return (
        <div className="fluid-container">
            {loading &&
                <PantallaCargando />
            }

            {/*Doble confirmacion autorizacion deposito*/}
            <Modal show={dobleConfirmacionAutorizacionDeposito} animation={false} centered >
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => setDobleConfirmacionAutorizacionDeposito(false)} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >¿Está seguro que desea autorizar el depósito de  {datosDepositoRetiro?.cantidadValores} valores de la serie "{datosDepositoRetiro?.serie}" de la emisión {datosDepositoRetiro?.nombreEmision} a la cuenta del cliente {datosDepositoRetiro?.nombreInstitucion}?</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => setDobleConfirmacionAutorizacionDeposito(false)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Cancelar</button>
                        <button onClick={() => autorizarDepositoRetiro(1)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Aceptar</button>
                    </div>
                </div>
            </Modal>
           
           {/*Doble confirmacion autorizacion retiro*/}
            <Modal show={dobleConfirmacionAutorizacionRetiro} animation={false} centered >
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => setDobleConfirmacionAutorizacionRetiro(false)} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >¿Está seguro que desea autorizar el retiro de  {datosDepositoRetiro?.cantidadValores} valores de la serie "{datosDepositoRetiro?.serie}" de la emisión {datosDepositoRetiro?.nombreEmision} a la cuenta del cliente {datosDepositoRetiro?.nombreInstitucion}?</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => setDobleConfirmacionAutorizacionRetiro(false)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Cancelar</button>
                        <button onClick={() => autorizarDepositoRetiro(2)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Aceptar</button>
                    </div>
                </div>
            </Modal>
            
            {/*Doble confirmacion rechazo deposito*/}
            <Modal show={dobleConfirmacionRechazoDeposito} animation={false} centered >
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => setDobleConfirmacionRechazoDeposito(false)} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >¿Está seguro que desea rechazar el depósito de  {datosDepositoRetiro?.cantidadValores} valores de la serie "{datosDepositoRetiro?.serie}" de la emisión {datosDepositoRetiro?.nombreEmision} a la cuenta del cliente {datosDepositoRetiro?.nombreInstitucion}?</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => setDobleConfirmacionRechazoDeposito(false)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Cancelar</button>                      
                        <button onClick={() => {setConstatenteRechazo(1)
                                                setDesplegarDescripcion(true)
                                                setDobleConfirmacionRechazoDeposito(false)}} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Aceptar</button>
                      {/*}  <button onClick={() => rechazarDepositoRetiro(1)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Aceptar</button>*/}
                    </div>
                </div>
            </Modal>
            
            {/*Doble confirmacion rechazo retiro*/}
            <Modal show={dobleConfirmacionRechazoRetiro} animation={false} centered >
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => setDobleConfirmacionRechazoRetiro(false)} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >¿Está seguro que desea rechazar el retiro de  {datosDepositoRetiro?.cantidadValores} valores de la serie "{datosDepositoRetiro?.serie}" de la emisión {datosDepositoRetiro?.nombreEmision} a la cuenta del cliente {datosDepositoRetiro?.nombreInstitucion}?</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => setDobleConfirmacionRechazoRetiro(false)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Cancelar</button>
                        <button onClick={() => {setConstatenteRechazo(2)
                                                setDesplegarDescripcion(true)
                                                setDobleConfirmacionRechazoRetiro(false)}} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Aceptar</button>
                      {/*<button onClick={() => rechazarDepositoRetiro(2)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Aceptar</button>*/}
                    </div>
                </div>
            </Modal>

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {envioExitoso &&
                <ComponenteModal mostrarModal={envioExitoso} cerrarModal={() => history.go(-1)} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {envioFallido &&
                <ComponenteModal mostrarModal={envioFallido} cerrarModal={() => history.go(-1)} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            <main className='container-min-height' >
                <div className="row row-wrap g-0">
                    <div className="col-12">
                        <div className="">
                            <BannerTitulo titulo="Depositar o retirar valores"></BannerTitulo>
                            <div className="row d-flex">

                                <div className="col-12">
                                    <div className="mb-5">
                                        <div className="card-body ">
                                            <div className="d-grid gap-1 col-10 mx-auto">
                                                <form className="row g-3 needs-validation" novalidate >
                                                    <div className="card" style={{ color: "#242848" }}>
                                                        <div className="card-body">
                                                            <h5 className="mt-3 text-royal-blue font-acumin-variable">Datos de la serie</h5>

                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" disabled value={datosDepositoRetiro?.codigoIsin} id="isin" name="isin"  required />
                                                                        <label htmlFor="cehvalDepositanteNombreConf" className=" textoFormularioDepositantedirecto">Código ISIN *</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input  type="text" className="form-control " disabled value={datosDepositoRetiro?.serie} id="codigoSerie" name="codigoSerie" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Código serie</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-lg-3 mb-3">
                                                                    <div className="form-floating">
                                                                        <input disabled type="text" className="form-control " value={datosDepositoRetiro?.numeroCertificado} id="numeroCertificacion" name="numeroCertificacion" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Número certificacion</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-lg-1 mb-3">
                                                                    <div className="form-floating">
                                                                        <input disabled type="text" className="form-control " value={datosDepositoRetiro?.serie} id="serie" name="serie" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Serie</label>
                                                                    </div>
                                                                </div>



                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input  type="text" className="form-control " disabled value={datosDepositoRetiro?.nombreEmision} id="nombreEmision" name="nombreEmision" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Nombre de emisión</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control " disabled value={datosDepositoRetiro?.codigoEmision} id="codigoEmision" name="codigoEmision" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Código de emisión</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input  type="text" className="form-control " disabled value={datosDepositoRetiro?.formaRepresentacion} id="formaPresentacion" name="formaPresentacion" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Forma de representación</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input  type="text" className="form-control " disabled value={datosDepositoRetiro?.cantidadInstrumentos} id="cantidadInstrumentos" name="cantidadInstrumentos" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Cantidad de instrumentos emitidos</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input  type="text" className="form-control " disabled value={datosDepositoRetiro?.cantidadInstrumentosFueraCehval} id="cantidadInstrumentosFueraCehval" name="cantidadInstrumentosFueraCehval" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Cantidad de instrumentos fuera de CEHVAL</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input  type="text" className="form-control " disabled value={datosDepositoRetiro?.valorNominal} id="valorNominal" name="valornominal" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Valor nominal de cada titulo</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <h5 className="mt-3 text-royal-blue font-acumin-variable">Datos del depositante</h5>
                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input  disabled type="text" className="form-control" value={datosDepositoRetiro?.codigoDepositante} id="codigoDepositante" name="codigoDepositante" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Código depositante</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input  type="text" className="form-control " disabled value={datosDepositoRetiro?.tipoDepositante} id="tipoDepositante" name="tipoDepositante" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Tipo de depositante</label>
                                                                    </div>
                                                                </div>

                                                                {datosDepositoRetiro?.rtn ?
                                                                    <div className="col-md-12 col-lg-4 mb-3">
                                                                        <div className="form-floating">
                                                                            <input  type="text" className="form-control " disabled value={datosDepositoRetiro?.rtn} id="rtn" name="rtn" required />
                                                                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">RTN</label>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="col-md-12 col-lg-4 mb-3">
                                                                        <div className="form-floating">
                                                                            <input  type="text" className="form-control " disabled value="Sin RTN" id="rtn" name="rtn" required />
                                                                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">RTN</label>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input  type="text" className="form-control " disabled value={datosDepositoRetiro?.nombreInstitucion} id="nombreDepositante" name="nombreDepositante" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Nombre depositante</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input  type="text" className="form-control " disabled value={datosDepositoRetiro?.saldoValores} id="saldoEnValores" name="saldoEnValores" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Saldo en valores</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input  type="text" className="form-control " disabled value={datosDepositoRetiro?.nacionalidad} id="nacionalidad" name="nacionalidad" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Nacionalidad</label>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <h5 className="mt-3 text-royal-blue font-acumin-variable">Datos de los valores</h5>
                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input  disabled type="text" className="form-control" value={datosDepositoRetiro?.cantidadValores} id="cantidadInstrumentos" name="cantidadInstrumentos" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Cantidad de instrumentos</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input  disabled type="text" className="form-control" value={datosDepositoRetiro?.tipoSolicitud} id="tipoSolicitud" name="tipoSolicitud" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Tipo de solicitud</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="d-flex  font-acumin-variable">
                                                                        <button title="Autorizar deposito o retiro" type='button' onClick={Autorizar} className="me-auto p-2 col-8 col-sm-4  col-md-4 col-lg-4 col-xl-4 col-xxl-4 btn background-royal-blue bg-hover-gold text-white  mb-2" >Autorizar</button>
                                                                        <button title="Rechazar deposito o retiro" type='button' onClick={Rechazar} className="p-2 col-8 col-sm-4  col-md-4 col-lg-4 col-xl-4 col-xxl-4 btn background-royal-blue bg-hover-gold text-white  mb-2" >Rechazar</button>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-lg-8 mb-3">
                                                                    <div className="d-flex justify-content-end font-acumin-variable">
                                                                        <button title="Regresar" type='button' onClick={() => { history.go(-1) }} className="col-8 col-sm-4  col-md-4 col-lg-4 col-xl-4 col-xxl-4 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Regresar</button>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            {desplegrarDescripcion&&
                                                            <form onSubmit={rechazarDepositoRetiro}>
                                                              <div class="form-floating">
                                                              <textarea class="form-control" placeholder="Leave a comment here" onChange={(e) => {
                                                                  setDescripcion(e.target.value)
                                                              }} id="floatingTextarea2" style={{ height: "7rem" }} required></textarea>
                                                              <label for="floatingTextarea2">Escriba el motivo del rechazo</label>
                                                          </div>
                                                          <div className='contenedorBotonesStteper  '>
                                                              <button type="button" className="btn botonFormularioDepositanteRechazar mt-2" onClick={() => {
                                                                  setDesplegarDescripcion(false)
                                                              }}>Cancelar</button>
                                                              <button type="submit" className="btn botonFormularioDepositante  mt-2" >Enviar</button>
                                                          </div>
                                                          </form>
                                                            
                                                            }

                                                          
                                                            

                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            </main>

        </div>
    );


}

