import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'


//Components
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";


//APIs
import { actualizarEstatusDeSolicitudLiberacion } from '../../../../api/prendasYGarantias/SolicitudesDeLiberacionGarantia/actualizarEstatusSolicitudLiberacion';
import { descargarPDF } from '../../../../api/prendasYGarantias/descargarPDF';

export default function AutorizarLiberacionDeGarantia({ history }) {

    useEffect(() => {

        if (history.location.state === undefined) {
            history.go(-1)
        } else {
            //Stores history info
            const objLiberacion = history.location.state.objConstitution[0];

            setFolioConstitucion(objLiberacion.cehvalSolicitudLiberacionGarantiaFolioConstitucion.cehvalSolicitudGarantiaFolio);
            setFolioLiberacion(objLiberacion.cehvalSolicitudLiberacionGarantiaFolio);
            setCodigoDepositanteReceptor(objLiberacion.cehvalSolicitudLiberacionGarantiaCodigoDepositanteReceptor.cehvalDepositanteCodigoCehval);
            setCuentaValoresDepReceptor(objLiberacion.cehvalSolicitudLiberacionGarantiaCuentaValores.cehvalCuentaValNum);
            setIsin(objLiberacion.serieIsin);
            setCantidadValores(objLiberacion.cehvalSolicitudLiberacionGarantiaFolioConstitucion.cehvalSolicitudGarantiaCantidadInstrumentos);
        }
    }, []);

    //States for inputs value
    const [folioConstitucion, setFolioConstitucion] = useState();
    const [folioLiberacion, setFolioLiberacion] = useState();
    const [codigoDepositanteReceptor, setCodigoDepositanteReceptor] = useState();
    const [cuentaValoresDepReceptor, setCuentaValoresDepReceptor] = useState();
    const [isin, setIsin] = useState();
    const [cantidadValores, setCantidadValores] = useState();

    const [downloadFailed, setDownloadFailed] = useState();



    //Input reject application
    const [reasonReject, setReasonReject] = useState();
    const [showRejectField, setShowRejectField] = useState(false);

    //Loading
    const [loading, setLoading] = useState();

    //States to show modal when register is successful or not
    const [registroExitoso, setRegistroExitoso] = useState();
    const [registroFallido, setRegistroFallido] = useState();

    const [rechazoExitoso, setRechazoExitoso] = useState();
    const [rechazoFallido, setRechazoFallido] = useState();

    const [buttonRejectText, setButtonRejectText] = useState("Rechazar");
    const [buttonReject, setButtonReject] = useState();
    const [erroreshttp, setErroreshttp] = React.useState()



    //Handle the rejection of the constitution request
    const authorizeRequest = async (e) => {
        e.preventDefault();
        const objLiberacion = {
            folio: folioLiberacion,
            estatus: 2,
        }

        try {
            setLoading(true);
            const response = await actualizarEstatusDeSolicitudLiberacion(objLiberacion);
            //console.log(response);

            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {

                    if (response.data.isSuccessful === true) {
                        setLoading(false);
                        setRegistroExitoso("Liberación de garantía autorizada");
                    } else {
                        setLoading(false);
                        setRegistroFallido("No se pudo autorizar la liberación de garantía");
                    }
                }
            }


        } catch (error) {
            console.error(error);
        }
    }





    //Handle the rejection of the constitution request
    const rejectRequest = async (e) => {
        e.preventDefault();
        const objLiberacion = {
            folio: folioLiberacion,
            estatus: 0,
            descripcion: reasonReject,
        }
        //console.log(objLiberacion)

        try {
            setLoading(true);
            const response = await actualizarEstatusDeSolicitudLiberacion(objLiberacion);
            //console.log(response)

            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {

                    if (response.data.isSuccessful === true) {
                        setRechazoExitoso("Liberación de garantía rechazada");
                    } else {
                        setRechazoFallido("No se pudo rechazar la liberación de garantía");
                    }
                }
            }



        } catch (error) {
            console.error(error);
        }
    }


    //Shows the reject field
    const showReject = () => {
        if (showRejectField === false) {
            setShowRejectField(true);
            setButtonReject(true);
            setButtonRejectText("Cancelar");
        } else {
            setShowRejectField(false);
            setButtonReject(false);
            setButtonRejectText("Rechazar");
        }
    }

    const downloadConstitucionPDF = async (e) => {
        e.preventDefault();
        //console.log(folioConstitucion)

        try {
            setLoading(true);

            const response = await descargarPDF({ fileName: folioConstitucion });
            //console.log(response)

            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    if (response.data) {
                        setLoading(false);
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    } else {
                        setLoading(false);
                        setDownloadFailed("No fue posible descargar el archivo");
                    }
                }
            }



        } catch (error) {
            //console.log(error)
        }
    }


    const downloadLiberacionPDF = async (e) => {
        e.preventDefault();

        //console.log(folioLiberacion)
        try {
            setLoading(true);


            const response = await descargarPDF({ fileName: folioLiberacion });
            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    //console.log(response)
                    if (response.data) {
                        setLoading(false);
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    } else {
                        setLoading(false);
                        setDownloadFailed("No fue posible descargar el archivo");
                    }
                }
            }

            

        } catch (error) {
            //console.log(error)
        }
    }





    const goBack = () => {
        setRegistroExitoso(false);
        window.location.replace("/tablaSolicitudesLiberacionDeGarantia");
    }


    return (
        <>
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {loading &&
                <PantallaCargando />
            }
            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={goBack} titulo={registroExitoso} textoBoton="Aceptar"></ComponenteModal>
            }
            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={() => { setRegistroFallido(false) }} titulo={registroFallido} textoBoton="Aceptar"></ComponenteModal>
            }
            {downloadFailed &&
                <ComponenteModal mostrarModal={downloadFailed} cerrarModal={() => { setDownloadFailed(false) }} titulo={downloadFailed} textoBoton="Aceptar"></ComponenteModal>

            }


            {rechazoExitoso &&
                <ComponenteModal mostrarModal={rechazoExitoso} cerrarModal={goBack} titulo={rechazoExitoso} textoBoton="Aceptar"></ComponenteModal>
            }
            {rechazoFallido &&
                <ComponenteModal mostrarModal={rechazoFallido} cerrarModal={() => { setRechazoFallido(false) }} titulo={rechazoFallido} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="fluid-container">
                <main className="mb-5">
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Solicitud de liberación o ejecución de prenda o garantía por autorizar"></BannerTitulo>
                                <div className="d-flex justify-content-center px-3 mt-5">
                                    <div className="col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11 col-xxl-11">
                                        <section className="d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded p-3">
                                                <div className="col-12">
                                                    <form className="needs-validation font-acumin-variable text-royal-blue">
                                                        <div className="row mt-4">
                                                            <h3>Información para la liberación de garantía</h3>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Folio de la solicitud de liberación de garantía" className="form-control fs-5 text-royal-blue" value={folioLiberacion} name="folioLiberacionGarantia" type="text"  required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="folioLiberacionGarantia">Folio de la liberación de garantía</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Folio de la constitución de garantía" className="form-control fs-5 text-royal-blue" value={folioConstitucion} name="folioConstitucionGarantia" type="text"  required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="folioConstitucionGarantia">Folio de la constitución de garantía a liberar</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código depositante transferente" value={codigoDepositanteReceptor} className="form-control fs-5 text-royal-blue" name="codigoDepositanteReceptor" type="text" placeholder="Código de depositante transferente" required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="codigoDepositanteReceptor">Código de depositante receptor</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating">
                                                                    <input title="Cuenta valores del depositante receptor" value={cuentaValoresDepReceptor} className="form-control fs-5 text-royal-blue" type="text" name="cuentaValoresDepReceptor" placeholder="CUneta de valores del depositante receptor" required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="cuentaValoresDepReceptor">Cuenta de valores del depositante receptor</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código ISIN" value={isin} className="form-control fs-5 text-royal-blue" type="text" name="isin"  required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="isin">ISIN</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Cantidad de valores a transferir" value={cantidadValores} className="form-control fs-5 text-royal-blue" type="text" name="cantidadValores"  required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="cantidadValores">Cantidad de valores</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 mb-3">
                                                                <Link className="text-royal-blue text-shadow-blue text-no-decoration text-hover-underline" onClick={downloadConstitucionPDF} >Descargar solicitud de constitución en PDF</Link>
                                                            </div>
                                                            <div className="col-12 col-md-12 mb-3">
                                                                <Link className="text-royal-blue text-shadow-blue text-no-decoration text-hover-underline" onClick={downloadLiberacionPDF} >Descargar solicitud de liberación en PDF</Link>
                                                            </div>
                                                            <div className="col-12 col-md-12 d-flex justify-content-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end mb-3 mt-4">

                                                                <button type="button" onClick={showReject} className="btn background-royal-blue text-white bg-hover-red me-2">{buttonRejectText}</button>
                                                                {!buttonReject &&
                                                                    <button type="button" onClick={authorizeRequest} className="btn background-royal-blue text-white bg-hover-gold">Autorizar</button>
                                                                }
                                                            </div>

                                                            {showRejectField &&
                                                                <>
                                                                    <div className="col-12 col-md-12 mb-3">
                                                                        <div className="form-floating ">
                                                                            <textarea title="Cantidad de valores a transferir" onChange={(e) => setReasonReject(e.target.value)} className="form-control fs-5 text-royal-blue rejectionReason" name="motivoRechazo" type="text" placeholder="" required ></textarea>
                                                                            <label className="text-start font-acumin-variable" htmlFor="motivoRechazo">Motivo del rechazo</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                        <button type="button" onClick={rejectRequest} className="btn background-royal-blue text-white bg-hover-gold">Confirmar</button>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    )
}