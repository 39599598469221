import React from "react";
import ComponenteModal from "./ComponenteModal";
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";

export default function ManejadorErrores({ response,mostrarModal,funcionCierre}) {
  const history = useHistory();
    const [error,setError]=React.useState();


  React.useEffect(()=>{
    ////console.log("manejador de errores")
    if(response.error==0){
        setError(`El servicio actualmente no está disponible o no cuenta con conexión a internet`)
       // //console.log("error de red")

    }else if(response.error==1){
        setError("Error de red")
        ////console.log("error inesperado")
    }
    else if(response.error==401){
        setError("Sesión caducada, inicie sesión de nuevo")
        ////console.log("error de autorizacion")

    }else if(response.error>=500){
        setError("Error de servidor")
      //  //console.log("error de servidor")
    }
    else {
        setError("Error")
        ////console.log("error")
    }
    //setMostrarModal(true)
  },[])

  const signOut = async () => {
    try {
       await Auth.signOut();
      sessionStorage.clear();
      //window.location.href = '/login'
      history.push({
        pathname: '/login',
    })
    } catch (error) {
      //console.log('error signing out: ', error);
    }
  }

   const cerrarModal=()=>{
    //setMostrarModal(false)
    if(error==="Sesión caducada, inicie sesión de nuevo"){
      funcionCierre()
     signOut();
    }else{
      funcionCierre()
    }
   }

   
    return (
      
          <ComponenteModal  mostrarModal={mostrarModal} cerrarModal={cerrarModal} titulo={error} textoBoton="Entendido"></ComponenteModal>
       
    )
}

