import { instance } from '../../apiGlobal';

export async function lecturaArchivoXlsx(file) {
    try {
        const response = await instance(`/emisor/import/emisores`, {
            method: "post",
            data: file,
        })
        return response;
    } catch (error) {
        return error;
    }
}
