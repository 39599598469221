import { useMemo } from "react";

export default function useSolicitudesDepositosValFisAutorizar() {
  const columns = useMemo(
    () => [
      {
        Header: "Código del depositante de valores físicos",
        accessor: "depositoValorFisicoCodigoDepositante"
      },
      {
        Header: "Nombre del depositante de valores físicos",
        accessor: "depositoValorFisicoNombreDepo"
      },
      {
        Header: "Nombre completo del valor",
        accessor: "depositoValorFisicoNomCompleto"
      },
      {
        Header: "Fecha de depósito",
        accessor: "depositoValorFisicoFechaDepoCadena"
      },
      {
        Header: "Fecha de retiro",
        accessor: "depositoValorFisicoFechaRetiroCadena"
      },
    ],
    []
  );

  return columns;
}
