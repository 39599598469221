import React, { useState, useEffect } from "react";
//import { Modal } from "react-bootstrap";


//Componente
//import VerificarEmision from './RegistroSeries';

//APIs
import { traerMonedas } from '../../../api/catalogos/traerMonedas';
import { traerPeriodicidad } from '../../../api/catalogos/traerPeriodicidad';
import { traerTiposTasa } from '../../../api/catalogos/traerTiposTasa';
import { traerTiposTasaVariable } from '../../../api/catalogos/traerTiposTasaVariable';
import { traerFormasColocacion } from '../../../api/catalogos/traerFormasColocacion';
import { registrarSerie } from '../../../api/series/registrarSerie';
//import { consultarEmision } from '../../../api/emision/consultarEmision';


//importar modal
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
//import SpinnerSend from "../../../ComponentesGenerales/SpinnerSend";
import PantallaCargando from '../../../ComponentesGenerales/PantallaCargando'
//import { consultarConciliacionesPendientes } from "../../../api/Mercadoprimario/consultarConciliadcionesPendientes";


import formatNumber from "../../../functions/FormatNumber";

export default function SeriesForm(props) {
    const [fecha_emision, setFecha_Emision] = useState();
    const [fecha_vencimiento, setFecha_Vencimiento] = useState()


    //Catálogos
    const [catMonedas, setCatMonedas] = useState();
    const [catPeriodicidad, setCatPeriodicidad] = useState();
    const [catTiposTasa, setCatTiposTasa] = useState();
    const [catTiposTasaVariable, setCatTiposTasaVariable] = useState();
    const [catFormasColocacion, setCatFormasColocacion] = useState();
    //const [catSeriesDisponibles, setCatSeriesDisponibles] = useState();

    //Mostrar modal si el registro es exitoso
    const [registroExitoso, setRegistroExitoso] = useState(false);
    const [registroSinExito, setRegistroSinExito] = useState(false);



    const [serie, setSerie] = useState();
    const [codigoISIN, setCodigoISIN] = useState();
    const [fechaEmision, setFechaEmision] = useState();
    const [fechaVencimiento, setFechaVencimiento] = useState();

    //Monto de la serie para el back
    const [montoSerie, setMontoSerie] = useState();

    //Monto de la serie para mostrar en el front
    const [montoSerieFront, setMontoSerieFront] = useState();

    //Instrumentos para el back
    const [cantidadInstrumentosEmitidos, setcantidadInstrumentosEmitidos] = useState();

    //Instrumentos para el front
    const [cantidadInstrumentosEmitidosFront, setcantidadInstrumentosEmitidosFront] = useState();

    const [spreadTasa, setSpreadTasa] = useState();
    const [valorNominalTitulo, setValorNominalTitulo] = useState();
    const [valorNomTituloInput, setValorNomTituloInput] = useState();
    const [formaColocacion, setFormaColocacion] = useState(); //mejor esfuerzo y competitiva
    const [monedaLiquidacion, setMonedaLiquidacion] = useState();
    const [tipoTasa, setTipoTasa] = useState();
    const [tasaRefVariable, setTasaRefVariable] = useState(12);
    const [displayTasaVariable, setDisplayTasaVariable] = useState(false);
    const [tasaInteresNominal, setTasaInteresNominal] = useState();
    const [periodicidad, setPeriodicidad] = useState();
    const [periodoRevision, setPeriodoRevision] = useState();
    const [error, setError] = useState();
    const [montoError, setMontoError] = useState();
    //const [spinSend, setSpinSend] = React.useState(false)
    const [erroreshttp, setErroreshttp] = React.useState()
    const [mensaje, setMensaje] = React.useState()
    const [registroFallido, setRegistroFallido] = React.useState()
    const [errorFormatoFecha1, setErrorFormatoFecha1] = React.useState()
    const [errorFormatoFecha2, setErrorFormatoFecha2] = React.useState()
    const [errorFormatoFechaModal, setErrorFormatoFechaModal] = React.useState()
    const [loading, setLoading] = React.useState(false);


    useEffect(() => {
        //console.log(props.tipoRegistro)
        getCatData();
        establecerFecha()

    }, []);

    const establecerFecha = () => {
        const fecha = new Date();
       // console.log(fecha)
        const añoActual = fecha.getFullYear();
        let diaActual = fecha.getDate();
        let mesActual = fecha.getMonth() + 1;

        if (diaActual < 10) {
            diaActual = "0" + diaActual;
        }

        if (mesActual < 10) {
            mesActual = "0" + mesActual;
        }

        const fecha_limite = (añoActual) + "-" + mesActual + "-" + (diaActual);
        //console.log(fecha_limite)
        setFecha_Emision(fecha_limite);
    }

    const sumarDias = (fecha, dias) => {
        fecha.setDate(fecha.getDate() + dias);
        return fecha;
    }

    const establecerFechaVencimiento = (e) => {
        //console.log(fecha_emision)
        //console.log(e)

        var d = new Date(e);
        const fecha = sumarDias(d, 2)


        const añoActual = fecha.getFullYear();
        let diaActual = fecha.getDate();
        let mesActual = fecha.getMonth() + 1;

        //console.log(añoActual)
        if (añoActual < 1900) {
            setErrorFormatoFecha1(true)
        } else {
            setErrorFormatoFecha1(false)
        }

        if (diaActual < 10) {
            diaActual = "0" + diaActual;
        }

        if (mesActual < 10) {
            mesActual = "0" + mesActual;
        }

        const fecha_limite_vencimiento = (añoActual) + "-" + mesActual + "-" + (diaActual);
        // //console.log("hola")
        ////console.log(fecha_limite_vencimiento)
        setFecha_Vencimiento(fecha_limite_vencimiento)
        ////console.log(e)

    }

    const checarFormatoFecha = (e) => {
        var fecha = new Date(e);

        const añoActual = fecha.getFullYear();
        //console.log(añoActual)
        if (añoActual < 1900) {

            setErrorFormatoFecha2(true)


        } else {

            setErrorFormatoFecha2(false)

        }
    }

    //Trae los catalogos de la DBcatFormasColocacion
    const getCatData = async () => {
        const catalogoMonedas = await traerMonedas()
        // //console.log(catalogoMonedas)
        if (catalogoMonedas) {
            if ((isNaN(catalogoMonedas.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(catalogoMonedas)
            } else {
                setCatMonedas(catalogoMonedas);
            }
        }

        const catalogoPeriodicidad = await traerPeriodicidad()
        ////console.log(catalogoPeriodicidad)
        if (catalogoPeriodicidad) {
            if ((isNaN(catalogoPeriodicidad.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(catalogoPeriodicidad)
            } else {
                setCatPeriodicidad(catalogoPeriodicidad);
            }
        }

        const catalogoTiposTasa = await traerTiposTasa()
        ////console.log(catalogoTiposTasa)
        if (catalogoTiposTasa) {
            if ((isNaN(catalogoTiposTasa.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(catalogoTiposTasa)
            } else {
                setCatTiposTasa(catalogoTiposTasa);
            }
        }

        const catalogoTiposTasaVariable = await traerTiposTasaVariable()
        ////console.log(catalogoTiposTasaVariable)
        if (catalogoTiposTasaVariable) {
            if ((isNaN(catalogoTiposTasaVariable.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(catalogoTiposTasaVariable)
            } else {
                setCatTiposTasaVariable(catalogoTiposTasaVariable);
            }
        }


        const catalogoFormasColocacion = await traerFormasColocacion()
        //console.log(catalogoFormasColocacion)
        if (catalogoFormasColocacion) {
            if ((isNaN(catalogoFormasColocacion.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(catalogoFormasColocacion)
            } else {
                setCatFormasColocacion(catalogoFormasColocacion);
            }
        }
    }

    //const [montoRestanteEmision, setMontoRestanteEmision] = useState();

    //Despliega el input de tasa variable dependiendo del tipo de tasa 
    const checkTipoTasa = (e) => {

        if (e === "2") {
            setDisplayTasaVariable(true);

        } else if (e === "1") {
            setDisplayTasaVariable(false);
        }
    }





    //Envia los datos de la serie
    const sendSeriesForm = async (e) => {
        e.preventDefault();

        try {
            if (!serie || serie === null) {
                setError("Algunos campos estan vacíos, o los datos introducidos no son correctos");
                return;
            } else if (errorFormatoFecha1 || errorFormatoFecha2) {
                setErrorFormatoFechaModal(true)
            } else {
                setErrorFormatoFechaModal(false)

                let objSerie;
                //serie
                if(props.tipoRegistro==="1"){
                    objSerie = {
                        //cehvalSerieCodigo: "SERIE004",//codigoSerie,
                        cehvalSerieNombreEmi: props.nombreDeEmision,
                        cehvalSerieNombreSerie: serie,
                        cehvalSerieCodigoIsin: codigoISIN,
                        cehvalSerieFechaEmi: fechaEmision,
                        cehvalSerieFechaVen: fechaVencimiento,
                        cehvalSerieTasIntNom: tasaInteresNominal,
                        cehvalSerieMonto: montoSerie,
                        cehvalSerieCantInstEmi: cantidadInstrumentosEmitidos,
                        //cehvalSerieCantInstDis: 1500,
                        //cehvalSerieFechaReg: 1665596496000,
                        //cehvalSerieFechaMod: 1665596496000,
                        cehvalSerieSpread: spreadTasa,
                        cehvalSerieAutNeg: "N",
                        cehvalSerieValorNomTitulo: valorNominalTitulo,
                        cehvalSerieEstatus: 1,
                        catFormaColocacionCatForColId: {
                            catForColId: formaColocacion
                        },
                        catFormaNegociacionCatFormNegId: {
                            catFormNegId: 1
                        },
                        cehvalSerieCatMonedaIdSerie: {
                            id: props.idDeMoneda,
                            nombre: props.monedaDeLaEmision
                        },
                        cehvalSerieCatMonedaIdLiq: {
                            id: monedaLiquidacion,
                            nombre: "LEMPIRA"
                        },
                        catNumeroPeriocidadCatNumPerId: {
                            catNumPerId: periodoRevision
                        },
                        cehvalSeriePeriodicidadId: {
                            catNumPerId: periodicidad
                        },
                        cehvalSerieCatTipTasId: {
                            catTipTasId: tipoTasa
                        },
                        cehvalEmisionCodigo: {
                            cehvalEmisionCodigo: props.codigoDeEmision
                        },
                        catTipoTasaVariableCatTipoTasaVariableId: {
                            catTipoTasaVariableId: tasaRefVariable
                        },
    
                    }

                }else{
                    objSerie = {
                        //cehvalSerieCodigo: "SERIE004",//codigoSerie,
                        cehvalSerieNombreEmi: props.nombreDeEmision,
                        cehvalSerieNombreSerie: serie,
                        cehvalSerieCodigoIsin: codigoISIN,
                        cehvalSerieFechaEmi: fechaEmision,
                        cehvalSerieFechaVen: "",
                        cehvalSerieTasIntNom: "",
                        cehvalSerieMonto: montoSerie,
                        cehvalSerieCantInstEmi: cantidadInstrumentosEmitidos,
                        //cehvalSerieCantInstDis: 1500,
                        //cehvalSerieFechaReg: 1665596496000,
                        //cehvalSerieFechaMod: 1665596496000,
                        cehvalSerieSpread: "",
                        cehvalSerieAutNeg: "N",
                        cehvalSerieValorNomTitulo: valorNominalTitulo,
                        cehvalSerieEstatus: 1,
                        catFormaColocacionCatForColId: {
                            catForColId: formaColocacion
                        },
                        catFormaNegociacionCatFormNegId: {
                            catFormNegId: 1
                        },
                        cehvalSerieCatMonedaIdSerie: {
                            id: props.idDeMoneda,
                            nombre: props.monedaDeLaEmision
                        },
                        cehvalSerieCatMonedaIdLiq: {
                            id: monedaLiquidacion,
                            nombre: "LEMPIRA"
                        },
                        catNumeroPeriocidadCatNumPerId: {
                            catNumPerId: ""
                        },
                        cehvalSeriePeriodicidadId: {
                            catNumPerId: ""
                        },
                        cehvalSerieCatTipTasId: {
                            catTipTasId: ""
                        },
                        cehvalEmisionCodigo: {
                            cehvalEmisionCodigo: props.codigoDeEmision
                        },
                        catTipoTasaVariableCatTipoTasaVariableId: {
                            catTipoTasaVariableId: ""
                        },
    
                    }
                }

         



                //console.log(objSerie)

                const response = await registrarSerie(objSerie);
                //console.log(response)
                if (response) {
                    setLoading(false)
                    if ((isNaN(response.error)) === false) {
                        // respuestaincorrecta           
                        setErroreshttp(response)
                    } else {

                        if (response?.data.codigo === "000") {
                            setRegistroExitoso(true);
                        } else {
                            setMensaje(response?.data.mensaje)
                            setRegistroFallido(true)
                        }




                    }
                }

            }

        } catch (err) {
            setRegistroSinExito(true);
            console.error(err)
        }

    }





    const caculateValorNominal = (monto, instrumentos) => {

        //Calcula el valor nominal

        //Solo si se reciben los dos valores se mostrará el resultado en input, si no, el valor será 0
        if (monto && instrumentos) {
            let result = monto / instrumentos;
            //Imprime el valor formateado en el input
            setValorNomTituloInput(new Intl.NumberFormat('en-us', { style: 'decimal' }).format(result));
            //Manda el valor sin formato al objeto que irá a la base de datos
            setValorNominalTitulo(result);
        } else {
            setValorNomTituloInput(0);
        }
    }


    //console.log(montoSerie)
    //console.log(montoSerieFront)

    //Valida que no se ingresen letras y llama a función para calcular el valor nominal de cada titulo
    const handleCantidadInstrumentosEmitir = (e) => {

        //Value entered by user
        const { value } = e.target;

        const formattedNumber = formatNumber(value);

        const integerNumber = formattedNumber.integerNumber;
        const stringNumber = formattedNumber.stringNumber;

        //integer
        setcantidadInstrumentosEmitidos(integerNumber);
        caculateValorNominal(montoSerie, integerNumber);

        //string
        setcantidadInstrumentosEmitidosFront(stringNumber)

 
    }


    //No permite ingresar letras
    const checkSpreadTasa = (e) => {
        ////console.log(e.keyCode)
        if (e.keyCode > 64 && e.keyCode < 91) {
            setSpreadTasa("");
        }
    }

    const checarNumeros = (e) => {
        const { value } = e.target;
        let out = '';
        var filtro = '1234567890.';

        if (value == ".") {
            e.preventDefault();
        }

        for (var i = 0; i < value.length; i++)
            if (filtro.indexOf(value.charAt(i)) !== -1)
                //Se añaden a la salida los caracteres validos
                out += value.charAt(i);

        //setTasaInteresNominal(out)

        var contador = 0
        var condicion = 0;
        //console.log(out)
        var arreglo2 = out;

        for (var i = 0; i < out.length; i++) {
            //console.log(out.charAt(i))
            if (contador > 0 && out.charAt(i) == ".") {
                //console.log("to")
                //console.log("punto")
                arreglo2 = arreglo2.slice(0, -1)
                //console.log(arreglo2)
            }

            if (out.charAt(i) == ".") {
                ////console.log("entra a puntos")
                contador++
                //  //console.log(contador)
                condicion = 0
            }
        }

        setTasaInteresNominal(arreglo2);
    }



    const reloadPage = () => {
        setRegistroExitoso(false);
        setRegistroSinExito(false);
        window.location.reload();
    }

    // Format number and prevent to enter letter, symbols and allow to enter decimals
    const handleMontoSerieInput = (e) => {

        const emisionMontoRestante = props.montoRestante;

        //Value entered by user
        const { value } = e.target;

        const formattedNumber = formatNumber(value);
        const integerNumber = formattedNumber.integerNumber;
        const stringNumber = formattedNumber.stringNumber;

        // Remove all characters other than digits or decimal points
        // let numberSerieAmount = value.replace(/[^\d.]/g, '');

        //If amount of serie is bigger than the emision amount then show error message and reset inputs
        if (integerNumber > emisionMontoRestante) {
            setMontoError("Has superado el monto de la emisión");
            setMontoSerieFront("");
            setMontoSerie(0);
            setcantidadInstrumentosEmitidos("");
            setValorNomTituloInput(0);
            return;

            //If series amount if less than the emision amount, then set the number to states and call function caculateValorNominal()
        } else if (integerNumber <= emisionMontoRestante) {
            //limpia el mensaje de error
            setMontoError("");

            //Number without format that will be sended to the endpoint hacerTransferenciaLibrePago();
            setMontoSerie(integerNumber);
            setMontoSerieFront(stringNumber)
            caculateValorNominal(integerNumber, cantidadInstrumentosEmitidos);

        }
    }





    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={reloadPage} titulo="Serie registrada" textoBoton="Entendido"></ComponenteModal>
            }

            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={() => setRegistroFallido(false)} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }
            {registroSinExito &&
                <ComponenteModal mostrarModal={registroSinExito} cerrarModal={reloadPage} titulo="No se pudo realizar el registro" textoBoton="Entendido"></ComponenteModal>
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {errorFormatoFechaModal &&
                <ComponenteModal mostrarModal={errorFormatoFechaModal} cerrarModal={() => { setErrorFormatoFechaModal(false) }} titulo="El año de la fecha no puede ser menor a 1900" textoBoton="Entendido"></ComponenteModal>
            }

            <div id="formRegisterSeries" className="col-12 border-top">
                {/*}  <h6 className="mt-5 text-center  font-acumin-variable mb-5">Click dentro de un campo para editarlo</h6>*/}
                <form onSubmit={(e) => {
                    sendSeriesForm(e)
                    //  setSerie(e)
                }} className="font-acumin-variable">
                    <div>
                        <div className="row mt-4 mb-3 " >
                            {/*}  <div className="col-12 col-md-12 mb-5 d-flex justify-content-end">
                                <div className="form-floating  col-4">
                                    <input onChange={(e) => setCodigoSerie(e.target.value.toUpperCase())} title="Código de la serie" className="form-control text-uppercase" name="codigoSerie" type="text" placeholder="Código de serie" required />
                                    <label className="text-start font-acumin-variable" htmlFor="codigoSerie">Código de serie</label>
                                </div>
                            </div>*/}

                            <div className="col-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 mb-3">
                                <div className="form-floating ">
                                    <input value={props.codigoDeEmision} title="Código de la emisión" className="form-control text-uppercase" maxLength={10} name="codigoEmisionSerie" type="text" placeholder="Nombre completo de la emision" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="codigoEmisionSerie">Código de la emisión *</label>
                                    {error && <p className="mt-3 text-danger font-acumin-variable ">{error}</p>}
                                </div>
                            </div>



                            <div className="col-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 mb-5 mb-md-5 mb-lg-3 mb-xl-3 mb-xxl-3">
                                <div className="form-floating ">
                                    <input value={props.nombreDeEmision} title="Nombre completo de la emisión" className="form-control text-uppercase" name="nombreEmisionSerie" type="text" placeholder="Nombre completo de la emision" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="nombreEmisionSerie">Nombre completo de la emisión *</label>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 d-flex justify-content-center mb-3">
                                <div className="form-floating col-12 col-md-12">
                                    <select onChange={(e) => setSerie(e.target.value)} title="Primero ingrese el código de la emisión" className="form-select" name="serie" style={{ height: "56px" }} aria-label="Default select example" required>
                                        <option disabled selected></option>
                                        {
                                            props.availableSeries.map((serie, index) => (
                                                <option className="text-uppercase" key={serie}>{serie}</option>
                                            ))
                                        }
                                    </select>
                                    <label className="text-start font-acumin-variable" htmlFor="serie" >Elija la serie *</label>
                                </div>
                            </div>

                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                                <div className="form-floating ">
                                    <input onChange={(e) => setCodigoISIN(e.target.value.toUpperCase())} title="Código ISIN" className="form-control text-uppercase" name="codigoISIN" type="text" placeholder="Código ISIN" required />
                                    <label className="text-start font-acumin-variable" htmlFor="codigoISIN">Código ISIN *</label>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                                <div className="form-floating ">
                                    <input onChange={(e) => {
                                        setFechaEmision(e.target.value)
                                        establecerFechaVencimiento(e.target.value)
                                    }} max={fecha_emision} title="Fecha de emisión" className="form-control text-uppercase" type="date" name="fechaEmision" placeholder="Fecha de emisión" required />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Fecha de emisión *</label>
                                    {errorFormatoFecha1 && <p className="mt-3 text-danger font-acumin-variable ">El año de la fecha no puede ser menor a 1000</p>}
                                </div>
                            </div>


                            {props.tipoRegistro === "1" &&
                                <div className="col-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 mb-5 mb-md-5 mb-lg-3 mb-xl-3 mb-xxl-3">
                                    <div className="form-floating ">
                                        <input onChange={(e) => {
                                            setFechaVencimiento(e.target.value)
                                            checarFormatoFecha(e.target.value)
                                        }} min={fecha_vencimiento} title="Fecha de vecimiento" className="form-control text-uppercase" type="date" name="fechaVencimiento" placeholder="Fecha de vencimiento" required />
                                        <label className="text-start font-acumin-variable" htmlFor="fechaVencimiento">Fecha de vencimiento *</label>
                                        {errorFormatoFecha2 && <p className="mt-3 text-danger font-acumin-variable ">El año de la fecha no puede ser menor a 1000</p>}
                                    </div>
                                </div>

                            }



                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                                <div className="form-floating ">
                                    <input value={props.monedaDeLaEmision} title="Moneda de la serie" className="form-control text-uppercase" name="tipoMoneda" type="text" placeholder="Moneda de la serie" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="tipoMoneda">Moneda de la serie *</label>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 d-flex justify-content-center mb-3">
                                <div className="form-floating col-12 col-md-12 ">
                                    <select onChange={(e) => setMonedaLiquidacion(e.target.value)} title="Moneda de liquidación" className="form-select" name="monedaLiquidacion" style={{ height: "56px" }} aria-label="Default select example" required>
                                        <option disabled selected></option>
                                        {catMonedas &&
                                            catMonedas.map((objeto, index) => (
                                                <option key={objeto.nombre} className="text-uppercase" value={objeto.id}>{objeto.nombre}</option>
                                            ))
                                        }
                                    </select>
                                    <label className="text-start font-acumin-variable" htmlFor="monedaLiquidacion" >Moneda liquidación *</label>
                                </div>
                            </div>

                            {/*} <div className="col-12 col-md-4 mb-1">
                                <div className="form-floating ">
                                    <input onChange={(e) => setPeriodicidad(e.target.value)} value={periodicidad}  title="Periodicidad" className="form-control " type="number" name="Periodicidad" placeholder="Monto de la serie" required />
                                    <label className="text-start font-acumin-variable" htmlFor="montoSerie">Número de periodos</label>
                                </div>
                            </div>*/}


                            {props.tipoRegistro === "1" &&
                                <div className="col-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 d-flex justify-content-center mb-3">
                                    <div className="form-floating col-12 col-md-12">
                                        <select onChange={(e) => setPeriodicidad(e.target.value)} title="Periodicidad" className="form-select font-acumin-variable" name="periodicidad" style={{ height: "56px" }} aria-label="Default select example" required>
                                            <option selected disabled></option>
                                            {catPeriodicidad &&
                                                catPeriodicidad.map((objeto, index) => (
                                                    <option key={objeto.catNumPerPeriodicidad} className="text-uppercase" value={objeto.catNumPerId}>{objeto.catNumPerPeriodicidadNum}</option>
                                                ))
                                            }
                                        </select>
                                        <label className="text-start font-acumin-variable" htmlFor="periodicidad" >Número de periodos *</label>
                                    </div>
                                </div>
                            }

                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 d-flex justify-content-center mb-3">
                                <div className="form-floating col-12 col-md-12">
                                    <select onChange={(e) => setFormaColocacion(e.target.value)} title="formaColocacion" className="form-select font-acumin-variable" name="formaColocacion" style={{ height: "56px" }} aria-label="Default select example" required>
                                        <option selected disabled></option>
                                        {catFormasColocacion &&
                                            catFormasColocacion.map((objeto, index) => (
                                                <option key={objeto.catForColColocacion} className="text-uppercase" value={objeto.catForColId}>{objeto.catForColColocacion}</option>
                                            ))
                                        }
                                    </select>
                                    <label className="text-start font-acumin-variable" htmlFor="formaColocacion" >Forma colocación *</label>
                                </div>
                            </div>

                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                                <div className="form-floating ">
                                    <input value={props.formaRepresentacion} title="Nombre completo de la emisión" className="form-control text-uppercase" name="formaRepresentacion" type="text" placeholder="formaRepresentacion" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="formaRepresentacion">Forma de representación *</label>
                                </div>
                            </div>


                            {props.tipoRegistro === "1" &&
                                <div className="col-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                                    <div className="form-floating ">
                                        <input onChange={(e) => {
                                            setTasaInteresNominal(e.target.value)
                                            checarNumeros(e)
                                        }} value={tasaInteresNominal} title="Tasa de interés nominal" className="form-control text-uppercase" type="text" name="tasaInteresNominal" placeholder="Tasa de interés nominal" required />
                                        <label className="text-start font-acumin-variable" htmlFor="tasaInteresNominal">Tasa de interés nominal *<span className="text-muted">(%)</span></label>
                                    </div>
                                </div>
                            }


                            {props.tipoRegistro === "1" &&
                                <div className="col-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 d-flex mb-5 mb-md-5 mb-lg-3 mb-xl-3 mb-xxl-3">
                                    <div className="form-floating col-12 col-md-12 ">
                                        <select onChange={(e) => {
                                            setTipoTasa(e.target.value)
                                            checkTipoTasa(e.target.value)
                                        }} title="Tipo de tasa" className="form-select" name="tipoTasa" style={{ height: "56px" }} aria-label="Default select example" required>
                                            <option selected disabled></option>
                                            {catTiposTasa &&
                                                catTiposTasa.map((objeto, index) => (
                                                    <option key={objeto.catTipTasTasa} className="text-uppercase" value={objeto.catTipTasId}>{objeto.catTipTasTasa}</option>
                                                ))
                                            }
                                        </select>
                                        <label className="text-start font-acumin-variable" htmlFor="tipoTasa" >Tipo de tasa *</label>
                                    </div>
                                </div>
                            }

                            {displayTasaVariable && props.tipoRegistro==="1"&&
                                <>
                                    <div className="col-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 mb-3">
                                        <div className="form-floating ">
                                            <input onChange={(e) => setSpreadTasa(e.target.value)} value={spreadTasa} onKeyUp={(e) => checkSpreadTasa(e)} maxLength={10} title="Spread de tasa" className="form-control text-uppercase" name="rtnEmispreadTasasorSerie" type="text" placeholder="RTN Emisor" required />
                                            <label className="text-start font-acumin-variable" htmlFor="spreadTasa">Spread de tasa <span className="text-muted"> Ej: 0.02</span></label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-center mb-3">
                                        <div className="form-floating col-12 col-md-12 ">
                                            <select onChange={(e) => setPeriodoRevision(e.target.value)} title="Periodo revisión" className="form-select font-acumin-variable" name="periodoRevision" style={{ height: "56px" }} aria-label="Default select example" required>
                                                <option selected disabled></option>
                                                {catPeriodicidad &&
                                                    catPeriodicidad.map((objeto, index) => (
                                                        <option key={objeto.catNumPerPeriodicidad} className="text-uppercase" value={objeto.catNumPerId}>{objeto.catNumPerPeriodicidad}</option>
                                                    ))
                                                }
                                            </select>
                                            <label className="text-start font-acumin-variable" htmlFor="periodoRevision" >Período de revisión de tasa variable</label>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 d-flex  mb-1">
                                        <div className="form-floating col-12 col-md-12 ">
                                            <select onChange={(e) => setTasaRefVariable(e.target.value)} title="Tasa referencia de tasa variable" className="form-select font-acumin-variable" name="tasaReferencia" style={{ height: "56px" }} aria-label="Default select example" required>
                                                <option selected disabled></option>
                                                {catTiposTasaVariable &&
                                                    catTiposTasaVariable.map((el, index) => (

                                                        <option key={el.catTipoTasaVariableTasaRefencia} className="text-uppercase" value={el.catTipoTasaVariableId}>{el.catTipoTasaVariableTasaRefencia}</option>
                                                    ))
                                                }
                                            </select>
                                            <label className=" font-acumin-variable" htmlFor="tasaReferencia">Tipo de Tasa Variable</label>
                                        </div>
                                    </div>
                                </>

                            }


                            <div className="col-12 col-md-9">
                                <div className="form-floating ">
                                    <input value={new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' }).format(props.montoRestante)} title="Monto restante de la emisión" className="form-control text-uppercase" name="montoRestanteEmision" type="text" placeholder="RTN Emisor" disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="montoRestanteEmision">Monto Restante de la emisión</label>
                                    <p className="text-muted fs-15">A continuación ingrese el monto de la serie y la cantidad de instrumentos a emitir</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 mb-3">
                                <div className="form-floating ">
                                    <input onInput={(e) => handleMontoSerieInput(e)} value={montoSerieFront} title="Monto de la serie" className="form-control text-uppercase" type="text" name="montoSerie" placeholder="Monto de la serie" required />
                                    <label className="text-start font-acumin-variable" htmlFor="montoSerie">Monto de la serie *</label>
                                    {montoError && <p className="mt-1 text-danger font-acumin-variable ">{montoError}</p>}
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 mb-3">
                                <div className="form-floating">
                                    <input onInput={(e) => handleCantidadInstrumentosEmitir(e)} value={cantidadInstrumentosEmitidosFront} title="Cantidad de instrumentos emitidos" className="form-control" type="text" name="cantidadInstrumentosEmitidos" placeholder="Cantidad instrumento emitidos" required />
                                    <label className="text-start font-acumin-variable" htmlFor="cantidadInstrumentosEmitidos">Cantidad de instrumentos a emitir *</label>
                                </div>
                            </div>
                            <div className="col-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 mb-3">
                                <div className="form-floating ">
                                    <input value={valorNomTituloInput} title="Valor nominal de cada título. Ingrese monto de la serie y cantidad de instrumentos a emitir" className="form-control" type="text" name="valorNominalTitulo" placeholder="Valor nominal de cada título" required />
                                    <label className="text-start font-acumin-variable" htmlFor="valorNominalTitulo">Valor nominal de cada título</label>
                                </div>
                            </div>


                            <div className="col-md-12 mt-2 row justify-content-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end p-2 ">
                                <button className="col-8 col-sm-4 col-md-3 col-lg-2 col-xl-2 btn background-royal-blue text-white bg-hover-gold" type="submit">Registrar Serie</button>
                                {error && <p className="mt-3 text-danger font-acumin-variable">{error}</p>}
                            </div>
                            {/*{spinSend &&
                                <SpinnerSend textoSpinner="Enviando..."></SpinnerSend>
                            }*/}
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}