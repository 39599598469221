import { useMemo } from "react";

export default function useColumns() {
 const columns = useMemo(
   () => [  
    {
      Header: "Tipo de operación",
      accessor: "nombreOperacion",
    },
    {
      Header: "Fecha de operación",
      accessor: "operacionFecha",
    },
    {
      Header: "Código depositante contraparte",
      accessor: "destino",
    },
    {
      Header: "Nombre dep contraparte",
      accessor: "nombreDestino",
    },
    {
      Header: "ISIN",
      accessor: "isinSerie",
    },
    {
      Header: "Código serie",
      accessor: "codigoSerie",
    },
    {
      Header: "Nombre de serie",
      accessor: "",
    } 
    
   /*  {
       Header: "Tipo operación",
       accessor: "operacionTipo"
     },
     {
       Header: "Fecha operación",
       accessor: "operacionFecha"
     }, 
     {
        Header:"Emisor",
        accessor: "emisor"
      },
      {
        Header:"Comprador",
        accessor: "comprador"
      },
      {
        Header:"Vendedor",
        accessor: "vendedor"
      },
      {
        Header: "Emisión",
        accessor: "emision"
      },
      {
        Header: "ISIN",
        accessor: "isinSerie"
      },
      {
        Header: "Moneda",
        accessor: "monedaCatId"
      },      
      {
        Header: "Monto unitario",
        accessor: "montoUnitarioValor"
      }, 
      {
        Header: "Número de valores",
        accessor: "numeroValores"
      }, */
   ],
   []
 );

 return columns;
}
