import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";


//General components
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";

//APIs
import { traerNacionalidad } from "../../../../api/catalogos/traerNacionalidad";
import { traerPaises } from "../../../../api/catalogos/traerPaises";
import { traerDepartamentos } from "../../../../api/catalogos/traerDepartamentos";
import { traerMunicipios } from "../../../../api/catalogos/traerMunicipios";
import { traerCiudades } from "../../../../api/catalogos/traerCiudades";
import { traerTiposIdentificacion } from "../../../../api/catalogos/traerTiposIdentificacion";

import { registrarSolicitudDepValFis } from '../../../../api/resguardoValoresFisicos/registrarSolicitudDepValFis';

export default function SolicitarRegistroDepValoresFisicos({ history }) {

    useEffect(() => {
        traerDatos();
        establecerFecha();
    }, []);

    const historyReact = useHistory();

    //Inputs identificación del depositante
    const [nombreInstitucion, setNombreInstitucion] = useState();
    const [nacionalidad, setNacionalidad] = useState();
    const [fechaNacimiento, setFechaNacimiento] = useState();

    //Inputs Domicilio
    const [pais, setPais] = useState();
    const [colonia, setColonia] = useState();
    const [departamento, setDepartamento] = useState();
    const [calle, setCalle] = useState();
    const [municipio, setMunicipio] = useState();
    const [numeroCasa, setNumeroCasa] = useState();
    const [ciudad, setCiudad] = useState();
    const [ciudadId, setCiudadId] = useState();
    const [codigoPostal, setCodigoPostal] = useState();

    //Inputs representante legal
    const [nombreRepLegal, setNombreRepLegal] = useState();
    const [correoRepLegal, setCorreoRepLegal] = useState();
    const [nacionalidadRepresentanteLegal, setNacionalidadRepresentanteLegal] = useState();
    const [tipoIdentificacionRepLegal, setTipoIdentificacionRepLegal] = useState();
    const [fechaNacimientoRepLegal, setFechaNacimientoRepLegal] = useState();
    const [numIdentificacionRepLegal, setNumIdentificacionRepLegal] = useState();
    const [telefonoRepLegal, setTelefonoRepLegal] = useState();
    const [celularRepLegal, setCelularRepLegal] = useState();

    //Inputs Responsable de la solicitud de registro
    const [nombreResponsableRegistro, setNombreResponsableRegistro] = useState();
    const [correoResponsableRegistro, setCorreoResponsableRegistro] = useState();


    //Catálogos
    const [nacionalidades, setNacionalidades] = useState();
    const [paises, setPaises] = useState();
    const [departamentos, setDepartamentos] = useState();
    const [municipios, setMunicipios] = useState();
    const [ciudades, setCiudades] = useState([]);
    const [tiposIdentificacion, setTiposIdentificacion] = useState();


    //Si se escoge un pais diferente a honduras se muestran los inputs de tipo texto
    const [showInputsTypeText, setShowInputsTypeText] = useState();

    //Estado para limitar opciones en input tipo date
    const [fechaLimiteNacimiento, setFechaLimiteNacimiento] = useState();

    //INFO PARA PRUEBAS


    //States to show modal when regiter showInputsTypeText is successful or not
    const [registroExitoso, setRegistroExitoso] = useState();
    const [registroFallido, setRegistroFallido] = useState();

    //Shows loading screen when true
    const [loading, setLoading] = useState(false);

    //Messages user
    const [messageSuccessful, setMessageSuccessful] = useState();

    //Errores http
    const [erroreshttp, setErroreshttp] = useState();




    //Handle api to get all catalogs
    const traerDatos = async () => {

        ////console.log("catalogos")
        const catalogosNacionalidades = await traerNacionalidad();
        if (catalogosNacionalidades) {
            if ((isNaN(catalogosNacionalidades.error)) === false) {
              
                setErroreshttp(catalogosNacionalidades)
                
            } else {
                // //console.log(traerInformacion)
                setNacionalidades(catalogosNacionalidades)
            }
        }

        const catalogoPaises = await traerPaises();
        if (catalogoPaises) {
            if ((isNaN(catalogoPaises.error)) === false) {
              
                setErroreshttp(catalogoPaises)
                
            } else {
                // //console.log(traerInformacion)
                setPaises(catalogoPaises)
            }
        }

        const catalogoTipoIdentificacion = await traerTiposIdentificacion();
        if (catalogoTipoIdentificacion) {
            if ((isNaN(catalogoTipoIdentificacion.error)) === false) {
              
                setErroreshttp(catalogoTipoIdentificacion)
                
            } else {
                // //console.log(traerInformacion)
                setTiposIdentificacion(catalogoTipoIdentificacion)
            }
        }

    }

    const establecerFecha = () => {
        const fecha = new Date();
        const añoActual = fecha.getFullYear();
        let diaActual = fecha.getDate();
        let mesActual = fecha.getMonth() + 1;

        if (diaActual < 10) {
            diaActual = "0" + diaActual;
        }

        if (mesActual < 10) {
            mesActual = "0" + mesActual;
        }

        const fecha_limite_cumpleaños = (añoActual - 18) + "-" + mesActual + "-" + diaActual;

        setFechaLimiteNacimiento(fecha_limite_cumpleaños);
    }

    //Handle when user choose the country
    const obtenerCatalogosHonduras = async (event) => {
        try {
            //If user choose Honduras then load the select catalogs
            if (event == 3) {

                setCiudad("");
                setCiudadId("");
                setMunicipio("")

                setShowInputsTypeText(false);
                const catalogosDepartamentos = await traerDepartamentos(event)
                ////console.log(catalogosDepartamentos)
                if (catalogosDepartamentos) {
                    //setSpin(false)
                    if ((isNaN(catalogosDepartamentos.error)) === false) {
                        // //console.log("erores")
                        ////console.log(response)
                        setErroreshttp(catalogosDepartamentos)
                        
                    } else {
                        // //console.log(traerInformacion)

                        setDepartamentos(catalogosDepartamentos)
                    }
                }

                /*  const catalogosMunicipios = await traerMunicipios(event)
                 ////console.log(catalogosMunicipios)
                 if (catalogosMunicipios) {
                     //setSpin(false)
                     if ((isNaN(catalogosMunicipios.error)) === false) {
                         // //console.log("erores")
                         ////console.log(response)
                         setErroreshttp(catalogosMunicipios)
                         
                     } else {
                         // //console.log(traerInformacion)
                         setMunicipios(catalogosMunicipios)
 
                     }
                 }
 
                 const catalogosCiudades = await traerCiudades(event)
                 ////console.log(catalogosCiudades)
                 if (catalogosCiudades) {
                     //setSpin(false)
                     if ((isNaN(catalogosCiudades.error)) === false) {
                         // //console.log("erores")
                         ////console.log(response)
                         setErroreshttp(catalogosCiudades)
                         
                     } else {
                         // //console.log(traerInformacion)
                         setCiudades(catalogosCiudades)
                     }
                 }*/

                //Shows inputs type text instead of select tags
            } else {
                setShowInputsTypeText(true);

                //Clean states in case user wrote something before
                setPais("");
                setColonia("");
                setDepartamento("");
                setCalle("");
                setMunicipio("");
                setNumeroCasa("");
                setCiudad("");
                setCiudadId(0);
                setCodigoPostal("");
            }

        } catch (error) {
            console.error(error)

        }
    }

    const obtenerMunicipios = async (event) => {
        const catalogosMunicipios = await traerMunicipios(event)
        //console.log(catalogosMunicipios)
        if (catalogosMunicipios) {
            //setSpin(false)
            if ((isNaN(catalogosMunicipios.error)) === false) {
              
                setErroreshttp(catalogosMunicipios)
                
            } else {
                // //console.log(traerInformacion)
                setCiudadId("")
                setMunicipios(catalogosMunicipios)

            }
        }
    }

    const obtenerCiudades = async (event) => {

        const catalogosCiudades = await traerCiudades(event)
        //  //console.log(catalogosCiudades)
        if (catalogosCiudades) {
            //setSpin(false)
            if ((isNaN(catalogosCiudades.error)) === false) {
              
                setErroreshttp(catalogosCiudades)
                
            } else {
                // //console.log(traerInformacion)
                setCiudades(catalogosCiudades)
            }
        }
    }

    //Handle when user enter letters in telehone inputs
    const restrictUseOfLetters = (e) => {
        const { value } = e.target;
        let out = '';
        var filtro = '1234567890-';
        for (var i = 0; i < value.length; i++)
            if (filtro.indexOf(value.charAt(i)) != -1)
                //Se añaden a la salida los caracteres validos
                out += value.charAt(i);

        if (e.currentTarget.name === "telefonoRepLegal") {
            setTelefonoRepLegal(out);
        } else {
            setCelularRepLegal(out);
        }




        /*//console.log(e.keyCode)

        if (e.currentTarget.name === "telefonoRepLegal") {

            if (
                e.keyCode > 64 && e.keyCode < 67 ||
                e.keyCode > 67 && e.keyCode < 86 ||
                e.keyCode > 86 && e.keyCode < 91 ||
                e.target.value < 0 || e.keyCode === 222
            ) {
                setTelefonoRepLegal("");
            }
        }

        if (e.currentTarget.name === "telCelularRepLegal") {
            if (e.keyCode > 64 && e.keyCode < 67 ||
                e.keyCode > 67 && e.keyCode < 86 ||
                e.keyCode > 86 && e.keyCode < 91 ||
                e.target.value < 0 || e.keyCode === 222
            ) {
                setCelularRepLegal("");
            }
        }*/

    }

    //Handle when user click "Enviar"
    const sendRequest = async (e) => {
        e.preventDefault();
        try {
            const obj = {
                //son 21 campos
                //Identificación depositante de resguardo de valores físicos
                depositanteResgvalfisNombreCompleto: nombreInstitucion,
                depositanteResgvalfisNacionalidadId: nacionalidad,
                depositanteResgvalfisFechaConsNacimiento: fechaNacimiento,

                //Domicilio
                depositanteResgvalfisPaisId: pais,
                depositanteResgvalfisColonia: colonia,
                depositanteResgvalfisDepartamento: departamento,
                depositanteResgvalfisCalle: calle,
                depositanteResgvalfisMunicipio: municipio,
                depositanteResgvalfisNumeroCasa: numeroCasa,
                depositanteResgvalfisCiudad: ciudad,
                //Si es pais es Honduras entonces depositanteResgvalfisCiudadId: IdOfChoosenCity;
                //si el pais es diferente a Hondruas depositanteResgvalfisCiudadId: 0; 
                depositanteResgvalfisCiudadId: ciudadId,
                depositanteResgvalfisCodigoPostal: codigoPostal,

                //Representante legal
                depositanteResgvalfisNomRepresLeg: nombreRepLegal,
                depositanteResgvalfisCorreo: correoRepLegal,
                depositanteResgvalfisNacionalidadRepresLegId: nacionalidadRepresentanteLegal,
                depositanteResgvalfisTipoIdeId: tipoIdentificacionRepLegal,
                depositanteResgvalfisFechaNacimientoRepresLeg: fechaNacimientoRepLegal,
                depositanteResgvalfisNumIde: numIdentificacionRepLegal,
                depositanteResgvalfisTelefono: telefonoRepLegal,
                depositanteResgvalfisTelefonoCel: celularRepLegal,

                //Responsable del registro
                depositanteResgvalfisNomResp: nombreResponsableRegistro,
                depositanteResgvalfisCorreoResp: correoResponsableRegistro,
            }
            //console.log(obj)

            setLoading(true);
            const response = await registrarSolicitudDepValFis(obj);
            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    ////console.log(response);

                    if (response.data.isSuccessful) {
                        setLoading(false);
                        setMessageSuccessful(response.data.mensaje);
                        setRegistroExitoso(true);
                    } else {
                        setLoading(false);
                        setRegistroFallido(true);
                    }
                }
            }




        } catch (error) {
            console.error(error);
        }
    }

    ////console.log("**DOMICILIO***")
    ////console.log(pais)
    ////console.log(colonia)
    ////console.log(departamento)
    ////console.log(calle)
    ////console.log(municipio)
    ////console.log(numeroCasa)
    ////console.log("ciudad " + ciudad)
    ////console.log("ciudadId " + ciudadId)
    ////console.log(codigoPostal)
    ////console.log("**DATOS REP LEGAL**")
    ////console.log(nombreRepLegal)
    ////console.log(correoRepLegal)
    ////console.log(nacionalidadRepresentanteLegal)
    // //console.log(tipoIdentificacionRepLegal)
    ////console.log(fechaNacimientoRepLegal)
    ////console.log(numIdentificacionRepLegal)
    ////console.log(telefonoRepLegal)
    ////console.log(celularRepLegal)
    ////console.log("*********")

    const goToMenu = () => {
        history.push({
            pathname: '/menuResguardoDepositantesValoresFisicos',
        })
    }

    //Executes when the emitter was successfully registered
    const goHome = () => {
        setRegistroExitoso(false)
        history.push({
            pathname: '/inicioCehval',
        })
    }

    return (
        <>
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={goToMenu} titulo={`${messageSuccessful}`} textoBoton="Aceptar"></ComponenteModal>
            }
            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={goHome} titulo="Registro fallido." textoBoton="Aceptar"></ComponenteModal>
            }
            {loading &&
                <PantallaCargando />
            }
            <div className="fluid-container">

                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        {/*<div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 d-flex flex-column">
                            <BarraLateral2  ></BarraLateral2>
                        </div>*/}
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Solicitud de registro de depositante de valores físicos"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <section className="p-5 d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded p-3">
                                                <div className="col-12">
                                                    <form onSubmit={sendRequest} className="needs-validation font-acumin-variable text-royal-blue">
                                                        <div className="row mt-4">

                                                            {/*IDENTIFICACIÓN DEL DEPOSITANTE */}
                                                            <h5 className="text-royal-blue font-acumin-variable">I. Identificación del depositante de resguardo de valores físicos</h5>
                                                            <div className="col-12 col-md-12 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Nombre completo de institución" onChange={(e) => setNombreInstitucion(e.target.value)} className="form-control text-royal-blue" maxLength={100} name="nombreCompletoInstitución" type="text" placeholder="" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="nombreCompletoInstitución">Nombre completo del depositante *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating">
                                                                    <select className="form-select" onChange={(e) => setNacionalidad(e.target.value)} id="" name="nacionalidad" required>
                                                                        <option value=""> </option>
                                                                        {nacionalidades &&
                                                                            nacionalidades.map((item, index) => (
                                                                                <option key={index} value={item.id} >{item.nombre}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    <label htmlFor="validationCustom04" className="form-label ">Nacionalidad *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 form-floating mb-3">
                                                                <div className="form-floating">
                                                                    <input max={fechaLimiteNacimiento} type="date" className="form-control border" onChange={(e) => setFechaNacimiento(e.target.value)} id="fechaNacimiento" name="fechaNacimiento" required />
                                                                    <label htmlFor="fechaNacimiento" className="form-label ">Fecha de constitución/nacimiento *</label>
                                                                </div>
                                                            </div>

                                                            {/*DOMICILIO*/}
                                                            <h5 className="fs-6 text-royal-blue font-acumin-variable">Dirección completa del domicilio</h5>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating">
                                                                    <select className="form-select" onChange={(e) => setPais(e.target.value)} onInput={(e) => obtenerCatalogosHonduras(e.target.value)} id="" name="pais" required>
                                                                        <option value=""> </option>
                                                                        {paises &&
                                                                            paises.map((item, index) => (
                                                                                <option key={index} value={item.id} >{item.nombre}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">País *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Colonia o barrio" onChange={(e) => setColonia(e.target.value)} className="form-control fs-5" maxLength={50} name="coloniaBarrio" type="text" placeholder="Colonia o barrio" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="coloniaBarrio">Colonia o barrio *</label>
                                                                </div>
                                                            </div>
                                                            {!showInputsTypeText &&
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <select className="form-select" value={departamento} onChange={(e) => setDepartamento(e.target.value)} onInput={(e) => obtenerMunicipios(e.target.value)} id="" name="departamento" required>
                                                                            <option value=""> </option>
                                                                            {departamentos &&
                                                                                departamentos.map((item, index) => (
                                                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <label htmlFor="validationCustom04" className="form-label">Departamento *</label>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {showInputsTypeText &&
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Departamento" onChange={(e) => setDepartamento(e.target.value)} className="form-control fs-5" maxLength={40} name="departamento" type="text" placeholder="Departamento" required />
                                                                        <label className="text-start font-acumin-variable" htmlFor="departamento">Departamento *</label>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Calle avenida o bloque" onChange={(e) => setCalle(e.target.value)} className="form-control fs-5" maxLength={40} name="cantidadValores" type="text" placeholder="Calle, avenida o bloque" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="cantidadValores">Calle, avenida o bloque</label>
                                                                </div>
                                                            </div>
                                                            {!showInputsTypeText &&
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <select className="form-select" value={municipio} onChange={(e) => setMunicipio(e.target.value)} onInput={(e) => obtenerCiudades(e.target.value)} id="" name="municipio" required>
                                                                            <option value=""> </option>
                                                                            {municipios &&
                                                                                municipios.map((item, index) => (
                                                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Municipio *</label>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {showInputsTypeText &&
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Municipio" onChange={(e) => setMunicipio(e.target.value)} className="form-control fs-5" maxLength={40} name="municipio" type="text" placeholder="Municipio" required />
                                                                        <label className="text-start font-acumin-variable" htmlFor="municipio">Municipio *</label>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Número de casa" onChange={(e) => setNumeroCasa(e.target.value)} className="form-control fs-5" maxLength={20} name="numeroCasa" type="text" placeholder="Número de casa" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="cantidadValores">Número de casa</label>
                                                                </div>
                                                            </div>
                                                            {!showInputsTypeText &&
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <select className="form-select" value={ciudadId} onChange={(e) => setCiudadId(e.target.value)} id="" name="ciudad" required>
                                                                            <option value=""> </option>
                                                                            {
                                                                                ciudades.map((item, index) => (
                                                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Ciudad *</label>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {showInputsTypeText &&
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Ciudad" onChange={(e) => setCiudad(e.target.value)} className="form-control fs-5" maxLength={40} name="ciudad" type="text" placeholder="Ciudad" required />
                                                                        <label className="text-start font-acumin-variable" htmlFor="municipio">Ciudad *</label>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código postal" onChange={(e) => setCodigoPostal(e.target.value)} className="form-control fs-5" maxLength={10} name="cantidadValores" type="text" placeholder="Cantidad de valores" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="cantidadValores">Código postal</label>
                                                                </div>
                                                            </div>


                                                            {/*REPRESENTANTE LEGAL*/}
                                                            <h5 className="text-royal-blue font-acumin-variable mt-4">II. Datos del representante legal</h5>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Nombre completo del representante legal" onChange={(e) => setNombreRepLegal(e.target.value)} className="form-control text-royal-blue" maxLength={80} name="nombreRepLegal" type="text" placeholder="Nombre del representante legal" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="nombreRepLegal">Nombre completo *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="correo del representante legal" onChange={(e) => setCorreoRepLegal(e.target.value)} className="form-control text-royal-blue" maxLength={30} name="correoRepLegal" type="text" placeholder="Correo representalte legal" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="correoRepLegal">Correo electrónico *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 mb-3">
                                                                <div className="form-floating">
                                                                    <select className="form-select" title="Seleccione la nacionalidad del representante legal" onChange={(e) => setNacionalidadRepresentanteLegal(e.target.value)} id="nacionalidadRepLegal" name="Nacionalidad del representante legal" required>
                                                                        <option value=""> </option>
                                                                        {nacionalidades &&
                                                                            nacionalidades.map((item, index) => (
                                                                                <option key={index} value={item.id} >{item.nombre}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Nacionalidad *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 mb-3">
                                                                <div className="form-floating">
                                                                    <select className="form-select" title="Seleccione el tipo de identificación del representante legal" onChange={(e) => setTipoIdentificacionRepLegal(e.target.value)} id="tipoIdentificacionRepLegal" name="Tipo de identificación del representante legal" required>
                                                                        <option value=""> </option>
                                                                        {tiposIdentificacion &&
                                                                            tiposIdentificacion.map((item, index) => (
                                                                                <option key={index} value={item.id} >{item.nombre}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Tipo de identificación *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Ingrese número de identificación del representante legal" onChange={(e) => setNumIdentificacionRepLegal(e.target.value)} className="form-control text-royal-blue" maxLength={25} name="numIdentificacionRepLegal" type="text" placeholder="" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="numIdentificacionRepLegal">Número de identificación *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 form-floating mb-3">
                                                                <div className="form-floating">
                                                                    <input placeholder="" title="Fecha de nacimiento del representante legal" max={fechaLimiteNacimiento} type="date" className="form-control text-royal-blue border " onChange={(e) => setFechaNacimientoRepLegal(e.target.value)} name="fechaNacimientoRepLegal" required />
                                                                    <label htmlFor="fechaNacimientoRepLegal" className="form-label ">Fecha de nacimiento *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Teléfono celular del representante legal" className="form-control text-royal-blue" onChange={(e) => setCelularRepLegal(e.target.value)} value={celularRepLegal} onKeyUp={restrictUseOfLetters} maxLength={20} name="telCelularRepLegal" type="text" placeholder="" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="telCelularRepLegal">Teléfono celular *</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Teléfono del representante legal" className="form-control text-royal-blue" onChange={(e) => setTelefonoRepLegal(e.target.value)} value={telefonoRepLegal} onKeyUp={restrictUseOfLetters} maxLength={20} name="telefonoRepLegal" type="text" placeholder="" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="telefonoRepLegal">Teléfono</label>
                                                                </div>
                                                            </div>

                                                            <h5 className="text-royal-blue font-acumin-variable mt-4">III. Responsable de la solicitud de registro</h5>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Nombre del reponsable de la solicitud de registro" onChange={(e) => setNombreResponsableRegistro(e.target.value)} className="form-control text-royal-blue" maxLength={80} name="nombreResponsableRegistro" type="text" placeholder="Nombre responsable registro" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="nombreResponsableRegistro">Nombre completo *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Correo del responsable de la solicitud de registro" onChange={(e) => setCorreoResponsableRegistro(e.target.value)} className="form-control text-royal-blue" maxLength={30} name="correoResponsableRegistro" type="text" placeholder="Correo del responsable del registro" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="correoResponsableRegistro">Correo electrónico *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 d-flex justify-content-center justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end mb-3">
                                                                <button type="button" onClick={() => { historyReact.goBack() }} className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1 background-royal-blue text-white bg-hover-red me-2">Cancelar</button>
                                                                <button type="submit" className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1 background-royal-blue text-white bg-hover-gold">Enviar</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    )
}