import React from "react";
import { useTable, useSortBy, usePagination, useGlobalFilter } from "react-table";


//se pasa import useSolicitudesPerJuridica from '../../../hooks/useSolicitudesPerJuridica'

import { BiFirstPage, BiLastPage } from "react-icons/bi";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";


// se pasa import { traerInformacionAllDepositante } from "../../../api/VerInformacionUsuarios/traerInformacionAllDepositantes";

import FiltroTabla from "./FiltroTabla";
import Dropdown from "react-bootstrap/Dropdown";
import SinRegistros from "./SinRegistros";



export default function TablaUsuarios({ datos, displayInformation,displayInformation2, useValidate, textoBoton, textTitle, mercadoPrimario, imprimir, funcionMercadoPrimario, textoMercadoPrimario, columnaLiberacion }) {


    let data = datos;

    const columns = useValidate();//me lo pasan
    const table = useTable(
        {
            columns,
            data,// me lo pasan
            initialState: {
                pageSize: 10,
                pageIndex: 0
            }
        },
        useGlobalFilter,
        useSortBy,
        usePagination,

    );



    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter }
    } = table;




    return (
        <>
            {preGlobalFilteredRows.length === 0 ?
                <SinRegistros></SinRegistros>
                :

                <div className="tabla">
                    <div className="row contenedorsteper" id="contenedorsteper">
                        <div className="col-12 contenedorsteper">
                            <div style={{ border: "transparent" }} className="card ">

                                <div className="card-body">

                                    <FiltroTabla
                                        preGlobalFilteredRows={preGlobalFilteredRows}
                                        globalFilter={globalFilter}
                                        setGlobalFilter={setGlobalFilter}
                                    />

                                    {/* Añadimos las propiedades a nuestra tabla nativa */}
                                    <div className="table-responsive mb-3" >
                                        <table aria-describedby="tablaUsuarios" className="table table-hover text-royal-white background-royal-blue text-center table-sm" {...getTableProps()} style={{ marginTop: 30, marginBottom: 20 }}>
                                            <thead>

                                                {
                                                    // Recorremos las columnas que previamente definimos
                                                    headerGroups.map(headerGroup => (
                                                        // Añadimos las propiedades al conjunto de columnas

                                                        <tr className="rounded text-light background-royal-blue" {...headerGroup.getHeaderGroupProps()}>
                                                            {
                                                                // Recorremos cada columna del conjunto para acceder a su información
                                                                headerGroup.headers.map((column) => (
                                                                    // Añadimos las propiedades a cada celda de la cabecera
                                                                    <th {...column.getHeaderProps()}
                                                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                                                        className={
                                                                            column.isSorted
                                                                                ? column.isSortedDesc
                                                                                    ? "descp-3 border text-center font-acumin-variable align-middle"
                                                                                    : "ascp-3 border text-center font-acumin-variable align-middle"
                                                                                : "p-3 border text-center font-acumin-variable align-middle"
                                                                        }>
                                                                        {
                                                                            // Pintamos el título de nuestra columna (propiedad "Header")
                                                                            column.render("Header")
                                                                        }
                                                                    </th>
                                                                ))
                                                            }

                                                            {textoBoton != null &&
                                                                    <th className="p-3 border text-center font-acumin-variable align-middle">Acción</th>
                                                             

                                                            }

                                                            {columnaLiberacion != null &&
                                                                <th className="p-3 border text-center font-acumin-variable align-middle">Acción</th>

                                                            }



                                                        </tr>
                                                    ))
                                                }
                                            </thead>
                                            {/* Añadimos las propiedades al cuerpo de la tabla */}
                                            <tbody {...getTableBodyProps()}>
                                                {
                                                    // Recorremos las filas
                                                    page.map((row, index) => {
                                                        // Llamamos a la función que prepara la fila previo renderizado
                                                        prepareRow(row);
                                                        return (
                                                            // Añadimos las propiedades a la fila
                                                            <tr {...row.getRowProps()}>
                                                                {
                                                                    // Recorremos cada celda de la fila
                                                                    row.cells.map((cell) => {
                                                                        // Añadimos las propiedades a cada celda de la fila
                                                                        return (
                                                                            <>
                                                                                <td className="text-center p-3 align-middle border font-acumin-variable" {...cell.getCellProps()}>
                                                                                    {
                                                                                        // Pintamos el contenido de la celda
                                                                                        cell.render("Cell")

                                                                                    }
                                                                                </td>
                                                                            </>
                                                                        );
                                                                    })
                                                                }

                                                                {textoBoton != null &&
                                                                    <td className="p-3 font-acumin-variable align-middle">
                                                                        <button className="btn botonFormularioDepositante" title={textTitle} type="button" onClick={() => displayInformation(page[index])}>{textoBoton}</button>
                                                                    </td>
                                                                }
                                                                 {columnaLiberacion != null &&
                                                                    <td className="p-3 font-acumin-variable align-middle">
                                                                        <button className="btn botonFormularioDepositante" title="Click para descargar oficio" type="button" onClick={() => displayInformation2(page[index])}>Descargar oficio de liberación</button>
                                                                    </td>
                                                                }

                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {mercadoPrimario &&
                        <div className="row justify-content-end">
                            <div className="col-6 row justify-content-end px-3 me-2 font-acumin-variable">
                                <button title="imprimir" className="col-2 btn background-royal-blue bg-hover-gold text-white mx-2" onClick={imprimir}>Imprimir</button>
                                <button title="conciliar" className="col-2 btn background-royal-blue bg-hover-gold text-white mx-2 " onClick={funcionMercadoPrimario} >{textoMercadoPrimario}</button>

                            </div>
                        </div>

                    }





                    <div className=" contenedorBotonesStteper row px-3">
                        <div className="col-12 col-md-6 col-sm-6">
                            <div className="card-body d-flex justify-content-start">
                                <nav aria-label="Page navigation example" >
                                    <ul className="pagination justify-content-center font-acumin-variable">
                                        <li className="page-item cursor-pointer">
                                            <button style={{ color: "#242848" }} className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                                <BiFirstPage className="page-controller text-pagination" />
                                            </button>{" "}
                                        </li>

                                        <li className="page-item cursor-pointer">
                                            <button style={{ color: "#242848" }} className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                                <MdKeyboardArrowLeft className="page-controller text-pagination" />
                                            </button>{" "}
                                        </li>

                                        <li className="page-item cursor-pointer page-width">
                                            <span className="page-link text-pagination page-height" style={{ color: "#242848" }}>
                                                Página&nbsp;
                                            
                                                        <strong >
                                                            {pageIndex + 1} de {pageOptions.length}
                                                        </strong>{" "}
                                                    

                                                
                                            </span>
                                        </li>

                                        <li className="page-item cursor-pointer">
                                            <button style={{ color: "#242848" }} className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                                                <MdKeyboardArrowRight className="page-controller text-pagination" />
                                            </button>{" "}
                                        </li>
                                        <li className="page-item cursor-pointer">
                                            <button style={{ color: "#242848" }} className="page-link"
                                                onClick={() => gotoPage(pageCount - 1)}
                                                disabled={!canNextPage}
                                            >
                                                <BiLastPage className="page-controller text-pagination" />
                                            </button>{" "}
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-sm-6 mt-3">
                            <div className="card-body d-flex justify-content-start justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end m-0">
                                {/*<select
                            value={pageSize}
                            onChange={(e) => setPageSize(Number(e.target.value))}
                            style={{ marginLeft: 100 }}
                            >
                            {[2, 4, 10, 15].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                            {pageSize !== 15 ? `Mostrar ${pageSize}` : `Mostrar todos`}
                            </option>
                            ))}
                            </select>*/}

                                <Dropdown className="justify-content-end font-acumin-variable" >
                                    <select className="page-link m-0"
                                        value={pageSize}
                                        onChange={(e) => setPageSize(Number(e.target.value))}
                                        style={{ marginLeft: 100, color: "#242848", borderRadius: 10 }}

                                    >
                                        {[5, 10, 15, 20].map((pageSize) => (
                                            <option key={pageSize} value={pageSize}>
                                                {pageSize !== 16 ? `Mostrar ${pageSize}` : `Mostrar todo`}
                                            </option>
                                        ))}
                                    </select>
                                </Dropdown>
                            </div>
                        </div>

                    </div>
                </div>
            }

        </>

    );
}













