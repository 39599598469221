import React, { useState } from "react";


//Componentes
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";
//import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
//import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import ComponenteInformacionSubasta from "./ComponenteInformacionSubasta";

import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";


//Hooks
import useSubastasLiquidadas from "../../../hooks/MercadoPrimario/useSubastasLiquidadas";

//API's
import { consultarSubastasLiquidadas } from "../../../api/Mercadoprimario/consultarSubastasLiquidadas";
import { traerInfoSubastaLiquidada } from "../../../api/Mercadoprimario/trerInfoSubastaLiquida";




//Muestra las cuentas de un depositante indirecto
export default function ConsultarLiquidacionSubastasOperador({ history }) {

    //const dataAlreadyLoaded = history.location.state;

    //If endpoint got the data will be true    

    //Stores the json with emisors  
    const [condicionSubasta, setCondicionSubasta] = useState();

    const [erroreshttp, setErroreshttp] = useState();
    
    const [spin, setSpin] = React.useState(true)
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    const [informacionSubasta, setInformacionSubasta] = React.useState()
    const [loading, setLoading] = React.useState(false);

    
    React.useEffect(() => {
        traerSubastas()
    }, []);

    const traerSubastas = async () => {
        const numeroSubastas = await consultarSubastasLiquidadas()
        //console.log(numeroSubastas)
        if (numeroSubastas) {
            setSpin(false)
            if ((isNaN(numeroSubastas.error)) === false) {
                setErroreshttp(numeroSubastas)
            } else {

                var filtrados = numeroSubastas.filter(word => (word.estatusStr === "Liquidada" || word.estatusStr === "Valores liquidados"));
                setData(filtrados)
                setCopia(filtrados)
            }
        }
    }

    const traerUnaSubasta = async (row) => {
        setLoading(true)
        //console.log(row.original.codigoSubasta)

        const infoSubastaLiquidada = await traerInfoSubastaLiquidada({ codigoSubasta: row.original.codigoSubasta, moneda: row.original.monedaId })
        //console.log(infoSubastaLiquidada)
        if (infoSubastaLiquidada) {
            setLoading(false)
            if ((isNaN(infoSubastaLiquidada.error)) === false) {
                setErroreshttp(infoSubastaLiquidada)
            } else {
                setInformacionSubasta(infoSubastaLiquidada)
                setCondicionSubasta(true)
                //setData(infoSubastaLiquidada)
                setCopia(false)
            }
        }
    }

    const cerrar = () => {
        //console.log("entra")
        setCondicionSubasta(false)
        setInformacionSubasta("")
        setCopia(true)
    }

    return (
        <>
            {loading &&
                <PantallaCargando />
            }

            

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            <div className="fluid-container">
                <main className="main-min-height">
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 mb-5">
                            {condicionSubasta ?
                                <ComponenteInformacionSubasta subastaData={informacionSubasta} textoFuncion1="Imprimir" funcion2={cerrar} textoFuncion2="Cerrar" irDevuelta={cerrar}></ComponenteInformacionSubasta>
                                :
                                <> <BannerTitulo titulo="Subastas"></BannerTitulo>
                                    {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                        <div className="d-flex">
                                            <div className="container py-5">
                                                {copia &&
                                                    <>
                                                        <TablaMercado datos={data} displayInformation={traerUnaSubasta} useValidate={useSubastasLiquidadas} textoBoton={"Ver información"} textTitle="Click para ver información" textoHeader="Ver información"></TablaMercado>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                </>

                            }
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}


