import React, { useState } from "react";

//Components
import BannerTitulo from "../Bannertitulo";
import ComponenteModal from "../ComponenteModal";
import ManejadorErrores from "../ManejadorErrores";
import PantallaCargando from "../PantallaCargando";

//APIs
import { actualizarStatusEmision } from '../../api/emision/actualizarStatusEmision';
import { registrarEmisionBCV } from "../../api/emision/emisionBCV/registrarEmisionBCV";

export default function VerInfoEmision({ history }) {
    if (history.location.state === undefined) {
        history.go(-1)
    } 
    //Mostrar modal si el registro es exitoso
    const [successfulRegistration, setSuccessfulRegistration] = useState();
    const [registrationNotSuccessful, setRegistrationNotSuccessful] = useState();
    const [emisionRegistradaBCV, setEmisionRegistradaBCV] = useState()


    const [erroreshttp, setErroreshttp] = React.useState()
    const [mensaje, setMensaje] = React.useState()
    const [emisionYaRegistrada, setEmisionYaRegistrada] = React.useState()

    const [emisionesFiltradas, setEmisionesFiltradas] = React.useState()
    const [errorsinMotivoRechazo, setErrorSinMotivoDeRechazo] = React.useState()
    const [motivoRechazo, setMotivoRechazo] = React.useState();
    const [desplegarCampoParaTexto, setDesplegarCampoParaTexto] = React.useState();
    const [loading, setLoading] = React.useState(false);


    const nombreEmision = history.location.state.objMenu.cehvalNombreEmision;
    const codigoEmision = history.location.state.objMenu.cehvalCodigoEmision;
    const nombreEmisor = history.location.state.objMenu.cehvalNombreEmisor;
    const codigoEmisor = history.location.state.objMenu.cehvalCodigoEmisor;
    const montoEmision = history.location.state.objMenu.cehvalMonto;
    const seriesEmision = history.location.state.objMenu.cehvalEmisionSeries;
    const numResolucion = history.location.state.objMenu.cehvalEmisionNumeroResol;
    const fechaEmision = history.location.state.objMenu.cehvalEmisionFechaRpmv;
    const reprComun = history.location.state.objMenu.cehvalEmisionRepresentanteComunObl;
    const tipoMoneda = history.location.state.objMenu.cehvalEmisionTipoMoneda
    const desmaterializada = history.location.state.objMenu.cehvalEmisionDesmaterializada;
    const ruta = history.location.state.objMenu.ruta
    const descripcion = history.location.state.objMenu.descripcion



    const authorizedEmisionStatus = history.location.state.objMenu.cehvalEstatus + 1;
    const rejectEmisionStatus = 0;



    const authorizeEmision = async (e) => {
        e.preventDefault();
        const myobj = {
            idEmision: history.location.state.objMenu.id,
            estatus: authorizedEmisionStatus
        }
        //console.log(myobj)
        const emisionAuthorized = await actualizarStatusEmision(myobj);
        //console.log(emisionAuthorized)
        if (emisionAuthorized) {
            setLoading(false)
            if ((isNaN(emisionAuthorized.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(emisionAuthorized)
            } else {


                if (emisionAuthorized?.codigo === "000") {
                    if (authorizedEmisionStatus === 2) {
                        setMensaje("Emisión autorizada con codigo" + " " + emisionAuthorized.mensaje)
                    } else {
                        setMensaje("Notificación de registro enviada")
                    }

                    setSuccessfulRegistration(true);

                } else {
                    // setValidacionIncorrecta(true)
                }
            }
        }

    }

    const rejectEmision = async (e) => {
        e.preventDefault();
        const myobj = {
            idEmision: history.location.state.objMenu.id,
            estatus: rejectEmisionStatus,
            descripcion: motivoRechazo
        }

        if (motivoRechazo) {
            setLoading(true)
            //console.log(codigoEmision)
            const emisionRejected = await actualizarStatusEmision(myobj);
            //console.log(emisionRejected)
            if (emisionRejected) {
                if ((isNaN(emisionRejected.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(emisionRejected)
                } else {

                    setLoading(false)
                    if (emisionRejected?.codigo === "000") {

                        setRegistrationNotSuccessful(true)

                    } else {
                        // setValidacionIncorrecta(true)
                    }
                }
            }
        } else {
            setErrorSinMotivoDeRechazo(true)
        }


 
    }

    const notificarEmisionRechazada = async (e) => {
        //console.log("entra notificacion")
        e.preventDefault();
        const myobj = {
            idEmision: history.location.state.objMenu.id,
            estatus: 4,
        }

        const emisionAuthorized = await actualizarStatusEmision(myobj);

        if (emisionAuthorized) {
            setLoading(false)
            if ((isNaN(emisionAuthorized.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(emisionAuthorized)
            } else {

                setLoading(false)
                if (emisionAuthorized?.codigo === "000") {
                    setMensaje("Notificación de rechazo enviada")
                    setSuccessfulRegistration(true);

                } else {
                    // setValidacionIncorrecta(true)
                }
            }
        }

    }

    const registerEmisiondeBCV = async () => {

        const myObj = {
            codigoEmision: codigoEmision,
            codigoEmisor: codigoEmisor,
            descripcion: nombreEmision,
            //nombreDeLaEmision: nombreEmision,
            montoDeLaEmision: montoEmision,
            monedaDeLaEmision: tipoMoneda,
            numResolucionRPMV: numResolucion,
            fechaRegistro: fechaEmision,
            rco: reprComun,
            series: seriesEmision,
            formaDeRepresentacion: desmaterializada
        }
        //console.log(myObj)

        const emisionRegisterBCV = await registrarEmisionBCV(myObj);

        if (emisionRegisterBCV) {
            //console.log(emisionRegisterBCV)
            setLoading(false)
            if ((isNaN(emisionRegisterBCV.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(emisionRegisterBCV)
            } else {

                setLoading(false)
                if (emisionRegisterBCV?.codigo === "000") {
                    setMensaje("Emision proveniente de la BCV registrada")

                    const removesEmision = history.location.state.objEmisiones.filter((item) => ((item.codigoEmision !== codigoEmision)||(item.codigoEmisor!==codigoEmisor)));
                    //New array of emitters set to our state
                    setEmisionesFiltradas(removesEmision);
                    setEmisionRegistradaBCV(true)
                } else if (emisionRegisterBCV?.codigo === "001") {
                    setEmisionYaRegistrada(true)
                    setMensaje(emisionRegisterBCV?.mensaje)
                }
            }
        }
    }

    const irInicio = () => {
        setEmisionYaRegistrada(false)
        setSuccessfulRegistration(false);
        setRegistrationNotSuccessful(false);
        history.push({
            pathname: ruta
        });
    }

    const regresarAEmisionesBCV = () => {
        setEmisionRegistradaBCV(false);
        history.push({
            pathname: '/registroEmisionesBCV', state: { emisionesFiltradas: emisionesFiltradas }
        })
    }


    const regresar = () => {
        const removesEmision = history.location.state.objEmisiones.filter((item) => ((item.codigoEmision !== codigoEmision)||(item.codigoEmisor!==codigoEmisor)));
        // //console.log(removesEmision)
        history.push({
            pathname: '/registroEmisionesBCV',
            state: {
                emisionesFiltradas: removesEmision,
            }
        })
    }

    return (
        <>
            <div className="fluid-container">
            {loading &&
                        <PantallaCargando />
   }
            {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }

                {successfulRegistration &&
                    <ComponenteModal mostrarModal={successfulRegistration} cerrarModal={irInicio} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
                }

                {emisionYaRegistrada &&
                    <ComponenteModal mostrarModal={emisionYaRegistrada} cerrarModal={() => setEmisionYaRegistrada(false)} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
                }

                {emisionRegistradaBCV &&
                    <ComponenteModal mostrarModal={emisionRegistradaBCV} cerrarModal={regresarAEmisionesBCV} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
                }

                {registrationNotSuccessful &&
                    <ComponenteModal mostrarModal={registrationNotSuccessful} cerrarModal={irInicio} titulo="Emisión rechazada" textoBoton="Entendido"></ComponenteModal>
                }

                {registrationNotSuccessful &&
                    <ComponenteModal mostrarModal={registrationNotSuccessful} cerrarModal={irInicio} titulo="Emisión rechazada" textoBoton="Entendido"></ComponenteModal>
                }

                {errorsinMotivoRechazo &&
                    <ComponenteModal mostrarModal={errorsinMotivoRechazo} cerrarModal={() => setErrorSinMotivoDeRechazo(false)} titulo="Tienes que agregar un motivo de rechazo" textoBoton="Entendido"></ComponenteModal>
                }

                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        <div className="col-12 mb-3">
                            <div className="">
                                <BannerTitulo titulo={history.location.state.objMenu.titulo}></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container">
                                        <section className="pe-5 ps-5 d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded p-3">
                                                <div className="col-12">
                                                    <form className="font-acumin-variable">
                                                        <div className="row mt-4">
                                                            <div className="col-12 col-md-12 row justify-content-center mb-5 my-4 ">
                                                                <h3 className="text-center fs-2 text-royal-blue col-10  border-bottom">Emisión: {nombreEmision}</h3>
                                                            </div>

                                                            <div className="col-12 col-md-8 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={nombreEmision} title="Nombre completo de la emisión" className="form-control" name="nombreEmision" type="text" placeholder="Nombre completo de la emision" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="nombreEmision">Nombre completo de la emisión</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 mb-3">
                                                                <div className="form-floating">
                                                                    <input disabled value={codigoEmision} title="Código de la emisión" className="form-control" name="codigoEmision" type="text" placeholder="Código la emisión" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="codigoEmision">Código de emisión</label>
                                                                </div>
                                                            </div>

                                                            {nombreEmisor ?
                                                                <div className="col-12 col-md-8 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input disabled value={nombreEmisor} title="Nombre del emisor" className="form-control" type="text" name="nombreEmisor" placeholder="Nombre del emisor" />
                                                                        <label className="text-start font-acumin-variable" htmlFor="nombreEmisor">Nombre del emisor</label>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="col-12 col-md-8 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input disabled value="No proporcionado por la BCV" title="Nombre del emisor" className="form-control" type="text" name="nombreEmisor" placeholder="Nombre del emisor" />
                                                                        <label className="text-start font-acumin-variable" htmlFor="nombreEmisor">Nombre del emisor</label>
                                                                    </div>
                                                                </div>
                                                            }


                                                            <div className="col-12 col-md-4 mb-3">
                                                                <div className="form-floating">
                                                                    <input disabled value={codigoEmisor} title="Código del emisor" className="form-control" type="text" name="codigoEmisor" placeholder="Codigo del emisor" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="codigoEmisor">Código del emisor</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={reprComun} title="Representante común de los obligacionistas" className="form-control" type="text" name="representanteObligacionistas" placeholder="Representante común de los obligacionistas" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="representanteObligacionistas">Representante común de los obligacionistas</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={montoEmision} title="Monto total de la emisión" className="form-control" name="montoEmision" type="text" placeholder="Nombre completo de la emision" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="montoEmision">Monto total de la emisión</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={tipoMoneda} title="Monto total de la emisión" className="form-control" name="monedaEmision" type="text" placeholder="Moneda de la emision" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="monedaEmision">Moneda de la emisión</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={numResolucion} title="Número de resolución RPMV" className="form-control" min={0} pattern="^[A-Z,0-9]+" type="text" name="numeroDeResolucionRPMV" placeholder="No. de Resolución RPMV" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="numeroDeResolucionRPMV">No. de Resolución RPMV</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={fechaEmision} title="Fecha registro RPMV" className="form-control" type="text" name="fechaDeRegistroRPMV" placeholder="Fecha de registro RPMV" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="fechaDeRegistroRPMV">Fecha de registro RPMV</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={desmaterializada} title="Forma de representación" className="form-control" type="text" name="desmaterializada" placeholder="Desmaterializada" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="desmaterializada">Forma de representación</label>
                                                                </div>
                                                            </div>

                                                          {/*}  {estatusEmision===5&& <div className="col-12 col-md-12 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={descripcionEmision} title="Descripcion" className="form-control" type="text" name="desmaterializada" placeholder="Desmaterializada" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="desmaterializada">Descripción</label>
                                                                </div>
                                                            </div>} /*}

                                                           


                                                            <h5>Series de la emisión</h5>
                                                            <div  >
                                                                <p className="px-3 py-3" style={{ backgroundColor: "#efefef", borderRadius: 5 }}>{seriesEmision}</p>
                                                            </div>


                                                            {/*   <div className="col-12 col-md-12 mb-3">
                                                                <div className="form-floating " >
                                                                    <input style={{height:"10rem"}} disabled value={seriesEmision} title="Series de la emisión" className="form-control" type="text" name="series" placeholder="Series emisión" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="series">Series de la emisión</label>
                                                                </div>
                                                            </div>*/}

                                                            {history.location.state.objMenu.cehvalEstatus === 0 &&
                                                                <div className="row mb-3">
                                                                    <div className="col-md-12">
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Motivo del rechazo</label>
                                                                        <textarea disabled type="text" value={descripcion} className="form-control" id="origenRecursos" name="origenRecursos" required style={{ height: "7rem" }} />
                                                                    </div>
                                                                </div>
                                                            }


                                                            <div className="col-12 col-md-12 mb-3 mt-5 row justify-content-center">
                                                                {/*cuando vienen de la cehval*/}
                                                                {history.location.state.objMenu.cehvalEstatus === 5 ?
                                                                    <>
                                                                        <div className="form-floating mx-5 row col-8 col-md-3 justify-content-center marginButtonAprove">
                                                                            <button className="btn background-royal-blue text-white bg-hover-gold col-12 col-md-12" type="button" onClick={registerEmisiondeBCV}>Registrar</button>
                                                                        </div>

                                                                        <div className="form-floating mx-5 row col-8 col-md-3 justify-content-center">
                                                                            <button className="btn background-red text-f7 col-12 col-md-12" onClick={regresar}>Cancelar</button>

                                                                        </div>
                                                                    </>
                                                                    :

                                                                    <>
                                                                        {history.location.state.objMenu.cehvalEstatus === 1 ?

                                                                            <>
                                                                                {desplegarCampoParaTexto ?
                                                                                    <>


                                                                                        <div class="form-floating">
                                                                                            <textarea class="form-control" placeholder="Leave a comment here" onChange={(e) => {
                                                                                                setMotivoRechazo(e.target.value)
                                                                                            }} id="floatingTextarea2" style={{ height: "7rem" }} required></textarea>
                                                                                            <label for="floatingTextarea2" className="ms-2">Escriba el motivo del rechazo</label>
                                                                                        </div>

                                                                                        <div className='contenedorBotonesStteper  '>
                                                                                            <button type="button" onClick={() => { setDesplegarCampoParaTexto(false) }}
                                                                                                className="btn botonFormularioDepositanteRechazar mt-2">Cancelar</button>

                                                                                            <button type="submit" className="btn botonFormularioDepositante  mt-2" onClick={(e) => {
                                                                                                rejectEmision(e)
                                                                                            }}>Enviar</button>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <div className="form-floating mx-5 row col-8 col-md-3 justify-content-center marginButtonAprove">
                                                                                            <button onClick={(e) => {
                                                                                                authorizeEmision(e)
                                                                                                setLoading(true)
                                                                                            }} className="btn background-royal-blue text-white bg-hover-gold col-12 col-md-12" >Autorizar </button>
                                                                                        </div>
                                                                                        <div className="form-floating mx-5 row col-8 col-md-3 justify-content-center">
                                                                                            <button type="button" onClick={() => { setDesplegarCampoParaTexto(true) }}
                                                                                                className="btn background-red text-f7 col-12 col-md-12" >Rechazar </button>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                            </>
                                                                            :

                                                                            <>
                                                                                {history.location.state.objMenu.cehvalEstatus === 2 ?

                                                                                    <div className="form-floating mx-5 row col-8 col-md-3 justify-content-center marginButtonAprove">
                                                                                        <button onClick={(e) => {
                                                                                            authorizeEmision(e)
                                                                                            setLoading(true)
                                                                                        }} className="btn background-royal-blue text-white bg-hover-gold col-12 col-md-12" >Notificar Registro</button>
                                                                                    </div>

                                                                                    :
                                                                                    <>
                                                                                        {history.location.state.objMenu.cehvalEstatus === 0 ?
                                                                                            <div className="form-floating mx-5 row col-8 col-md-3 justify-content-center marginButtonAprove">
                                                                                                <button onClick={(e) => {
                                                                                                    notificarEmisionRechazada(e)
                                                                                                    setLoading(true)
                                                                                                }} className="btn background-royal-blue text-white bg-hover-gold col-12 col-md-12" >Notificar rechazo</button>
                                                                                            </div>
                                                                                            :
                                                                                            <div className="form-floating mx-5 row col-8 col-md-3 justify-content-center marginButtonAprove">
                                                                                                <button onClick={(e) => {
                                                                                                    irInicio()
                                                                                                }} className="btn background-royal-blue text-white bg-hover-gold col-12 col-md-12" >Cerrar</button>
                                                                                            </div>

                                                                                        }

                                                                                    </>
                                                                                }
                                                                            </>

                                                                        }
                                                                    </>
                                                                }

                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    )
}