import React from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import TablaUsuarios from '../../../ComponentesGenerales/TablaUsuarios';
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";

//Hooks
import useBajaAltaUsuarios from "../../../hooks/BajaAltaUsuarios/useBajaAltaUsuarios";

//APIs
import { cambiarEstatusUsuarioReemplazado } from "../../../api/mantenimientoCargaValores/cambiarEstatusUsuarioReemplazado";
import { consultarUsuariosPorEstatus } from "../../../api/altaBajaUsuarios/consultarUsuariosPorEstatus";

export default function BajaUsuarios({ history }) {
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState();
    const [erroreshttp, setErroreshttp] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [loading, setLoading] = React.useState();
    const [procesoExitoso, setProcesoExitoso] = React.useState();
    const [procesoFallido, setProcesoFallido] = React.useState();
    const [mensaje, setMensaje] = React.useState();

    React.useEffect(() => {
        traerUsuarios()
    }, [])

    const traerUsuarios = async () => {
        const response = await consultarUsuariosPorEstatus({
            estatus:1
        })
        //console.log(response)

        if (response) {
            setSpin(false)
            if ((isNaN(response.error)) === false) {
                setErroreshttp(response)
            } else {
                // //console.log(respuesta)
                setData(response)
                setCopia(response)
            }
        }
    }

    const darDeBaja = async (row) => {
        //console.log(row.original.usuario)
        setLoading(true)

        //desabilitar usuario en la base
        const respuestaUsuario = await cambiarEstatusUsuarioReemplazado({
            cehvalUsuarioUsuario: row.original.usuario,
            cehvalUsuarioEstado: 0,
        })
        // //console.log(respuestaUsuario)
        if (respuestaUsuario) {
            setLoading(false)
            //entra a qui si hay un error http
            if ((isNaN(respuestaUsuario.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(respuestaUsuario)
            } else {
                if (respuestaUsuario.codigo === "000") {
                    setMensaje(`Se ha dado de baja al usuario ${row.original.usuario}`)
                    setProcesoExitoso(true)
                }else{
                    setMensaje(`No se ha podido dar de baja al usuario ${row.original.usuario}, intentelo mas tarde`)
                    setProcesoFallido(true)
                }
            }
        }
    }


    return (
        <>
            <div className="fluid-container">
                {loading &&
                    <PantallaCargando />
                }

                {procesoExitoso &&
                    <ComponenteModal mostrarModal={procesoExitoso} cerrarModal={() => {history.go()}} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
                }
                 {procesoFallido &&
                    <ComponenteModal mostrarModal={procesoFallido} cerrarModal={() => setProcesoFallido(false)} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
                }

                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }

                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        <div className="col-12 ">
                            <div className="">
                                <br></br>
                                <BannerTitulo titulo="Baja de usuarios"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart>
                                    :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaUsuarios datos={data} useValidate={useBajaAltaUsuarios} displayInformation={darDeBaja} textoBoton={"Dar de baja"} textTitle="Click para dar de baja al usuario"></TablaUsuarios>
                                            }
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    );
}



