import { useMemo } from "react";

export default function useSubastasLiquidadasOperadorDD() {
 const columns = useMemo(
   () => [   
     {
       Header:"Código de cliente CEHVAL",
       accessor: "codigoCliente"
     },
     {
        Header:"Nombre cliente CEHVAL",
        accessor: "nombreCliente"
      },
      {
        Header:"Código de casa de bolsa",
        accessor: "codigoCasaBolsa"
      },
      {
        Header:"Nombre de casa de bolsa",
        accessor: "nombreCasaBolsa"
      },
      {
        Header:"Cantidad de bonos adjudicados",
        accessor: "cantidadBonosAdjudicados"
      },
      {
        Header:"Precio sucio",
        accessor: "precioSucioStr"
      },
      {
        Header:"Monto a pagar",
        accessor: "montoAPagarStr"
      },
      {
        Header:"Estatus",
        accessor: "descripcion"
      },
   ],
   []
 );

 return columns;
}

