import { useMemo } from "react";

export default function useTablaFacturasRegistradas () {
  const columns = useMemo(
    () => [
      {
        Header: "Fecha límite de emisión",
        accessor: "fechaLimiteStr"
      },
      {
        Header: "Rango autorizado",
        accessor: "rangoAutorizado"
      },
      {
        Header: "No. Factura (Dígitos comunes)",
        accessor: "prefijo"
      },
      {
        Header: "Factor I.S.V",
        accessor: "isv"
      },
      {
        Header: "Estado",
        accessor: "estadoStr"
      }    
    ],
    []
  );

  return columns;
}
