//React
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

//Componentes generales
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../ComponentesGenerales/ManejadorErrores';
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";

import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { eliminarConciliacionesPDP } from "../../../api/PagoInteresesAmorDiv/eliminarConciliacionesPDP";
import { consultarConciliacionesPendientesPDP } from "../../../api/PagoInteresesAmorDiv/consultarConciliacionesPendientesPDP";
import { conciliarFondosPDP } from "../../../api/PagoInteresesAmorDiv/conciliarFondosPDP";

//Hooks
import useFondosNoConciliadosPDP from "../../../hooks/PagoDerechosPatrimoniales/useFondosNoConciliadosPDP";

export default function ConciliarFondosPDP({ history }) {

    //States
    const [showConfirmDeleteRow, setShowConfirmDeleteRow] = useState();
    const [deleteObjRow, setDeleteObjRow] = useState(null);
    const [deletionSuccess, setDeletionSuccess] = useState();
    const [conciliacionExitosa, setConciliacionExitosa] = useState();
    const [conciliacionFallida, setConciliacionFallida] = useState();
    
    const [validacionEliminar, setValidacionEliminar] = React.useState()
    const [loading, setLoading] = React.useState(false);
    const [borradoInvalido, setBorradoInvalido] = React.useState(false);

    //Data to fill the table
    const [copia, setCopia] = useState();
    const [copia2, setCopia2] = useState();
    const [data, setData] = useState([]);


    //Spinner
    const [spin, setSpin] = useState(true);

    //Show messages to user
    const [message, setMessage] = useState();
    const [messageConciliacion, setMessageConciliacion] = useState();
    const [bodyMensaje, setbodyMensaje] = useState();

    //Manage errors 
    //const [ErrorLoadData, setErrorLoadData] = useState();
    const [failDeleteRow, setFailDeleteRow] = useState();
    const [erroreshttp, setErroreshttp] = useState();

    useEffect(() => {
        getAllFondosNoConciliados();
    }, []);


    const getAllFondosNoConciliados = async () => {

        try {
            const response = await consultarConciliacionesPendientesPDP();
            //console.log(response)
            if (response) {
                setSpin(false)
                if ((isNaN(response.error)) === false) {
                    setErroreshttp(response)
                } else {
                    setData(response)
                    setCopia(response)
                }
            }
        } catch (err) {
            console.error(err);
        }
    }


    //Catch information form the selected row
    const deleteRow = async (row) => {

        if(row.original.tipoDePago==="-"){
            const objRow = {
                conciliacionId: row.original.conciliacionId
            }
            //Show modal to confirm deletion
            setShowConfirmDeleteRow(true);
            //Pass to state object to query the deletion in function confirmDeletingRow
            setDeleteObjRow(objRow);
        }else{
            setBorradoInvalido(true)
        }
    }

    const confirmDeleteRow = async () => {
        setShowConfirmDeleteRow(false);
        setLoading(true);
        try {

            const response = await eliminarConciliacionesPDP(deleteObjRow);
            if (response) {
                setLoading(false)
                //setLoading(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    setLoading(false)
                    //console.log(response);

                    if (response.codigo === "000") {
                        //console.log(deleteObjRow.conciliacionId)
                        let filtrados = data.filter(word => word.conciliacionId !== deleteObjRow.conciliacionId)
                        //console.log(filtrados)
                        setData(filtrados)
                        if (copia) {
                            setCopia(false)
                            setCopia2(true)
                        } else {
                            setCopia(true)
                            setCopia2(false)
                        }

                        setDeletionSuccess(true);
                        setMessage(response.mensaje);
                        setDeleteObjRow(null);

                    } else {
                        setFailDeleteRow(true)
                        setDeleteObjRow(null);
                    }

                    //If the row was removed
                    /*  if (response.data.conciliaciones) {
          
                          setLoading(false);
                          //Give the new array to update table
                          setData(response.data.conciliaciones);
                          if (copia) {
                              setCopia(false)
                              setCopia2(true)
                          } else {
                              setCopia(true)
                              setCopia2(false)
                          }
          
                          // setCopia(response.data.conciliaciones);
          
                          //Show modal with message of successful
                          setDeletionSuccess(true);
                          setMessage(response.data.mensaje);
          
          
          
                          //Clear state that stores obj to the query to delete a row
                          setDeleteObjRow(null);
          
                          //If the row wasnt removed
                      } else if (!response.data.conciliaciones) {
                          setFailDeleteRow(true);
                          setDeleteObjRow(null);
                      }*/


                }
            }


        } catch (error) {
            setFailDeleteRow(true)
            setDeleteObjRow(null);
        }
    }

    const conciliarFondos = async () => {
        setLoading(true)
        try {
            //console.log(data)

            const mandarConciliacion = await conciliarFondosPDP(data);
            //console.log(mandarConciliacion);
            if (mandarConciliacion) {
                setLoading(false)

                if ((isNaN(mandarConciliacion.error)) === false) {
                    // //console.log("erores")
                    ////console.log(response)
                    setErroreshttp(mandarConciliacion)
                    
                } else {
                    if (mandarConciliacion.codigo === "000") {
                        const mensajeRespuesta = mandarConciliacion.mensaje
                        var mensajeSplit = mensajeRespuesta.split("*")
                        setbodyMensaje(mensajeSplit)


                        //setMessageConciliacion(mandarConciliacion.mensaje)
                        setData(mandarConciliacion.conciliarFondos);
                        //setCopia(false);
                        //setCopia2(mandarConciliacion.conciliaciones)
                        setConciliacionExitosa(true)

                        // setData(mandarConciliacion)
                        if (copia) {
                            setCopia(false)
                            setCopia2(true)
                        } else {
                            setCopia(true)
                            setCopia2(false)
                        }
                    } else {
                        setMessageConciliacion(mandarConciliacion.mensaje)
                        setConciliacionFallida(true)

                    }



                }
            }


        } catch (error) {
            console.error(error);
            setMessage("Error al hacer la conciliación")
            setConciliacionFallida(true)
        }
    }


    const onHideModal = () => {
    }

    return (
        <>
            <Modal show={showConfirmDeleteRow} animation={false} centered onHide={onHideModal}>
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => setShowConfirmDeleteRow(false)} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >Esta acción es irreversible. ¿Estas seguro que deseas continuar?</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => setShowConfirmDeleteRow(false)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >No</button>
                        <button onClick={confirmDeleteRow} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Si</button>
                    </div>
                </div>
            </Modal>

            {borradoInvalido &&
                <ComponenteModal mostrarModal={borradoInvalido} cerrarModal={() => setBorradoInvalido(false)} titulo="No se puede eliminar este tipo de pago" textoBoton="Entendido"></ComponenteModal>
            }

            {/*} {showConfirmDeleteRow &&
                <ComponenteModal mostrarModal={showConfirmDeleteRow} cerrarModal={confirmDeleteRow} titulo="Esta acción es irreversible. ¿Estas seguro que deseas continuar?" textoBoton="Entendido"></ComponenteModal>
            }*/}

            {loading &&
                <PantallaCargando />
            }

            

            {validacionEliminar &&
                <ComponenteModal mostrarModal={validacionEliminar} cerrarModal={() => setValidacionEliminar(false)} titulo="Este registro ya esta conciliado, no se puede eliminar" textoBoton="Entendido"></ComponenteModal>
            }



            {failDeleteRow &&
                <ComponenteModal mostrarModal={failDeleteRow} cerrarModal={() => setFailDeleteRow(false)} titulo="No se pudo eliminar el registro. Inténtelo de nuevo o vuelva a iniciar sesión." textoBoton="Entendido"></ComponenteModal>
            }
            {deletionSuccess &&
                <ComponenteModal mostrarModal={deletionSuccess} cerrarModal={() => setDeletionSuccess(false)} titulo={message} textoBoton="Entendido"></ComponenteModal>
            }

            {conciliacionExitosa &&
                <ComponenteModal mostrarModal={conciliacionExitosa} cerrarModal={() => setConciliacionExitosa(false)} body={bodyMensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {conciliacionFallida &&
                <ComponenteModal mostrarModal={conciliacionFallida} cerrarModal={() => setConciliacionFallida(false)} titulo={messageConciliacion} textoBoton="Entendido"></ComponenteModal>
            }

            <div className="">

                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <main className="">
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Depósitos de fondos no conciliados pago de derechos patrimoniales"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex container-max-height main-content">
                                        <div className="container py-2 main-min-height main-content">
                                            {copia &&
                                                <>
                                                    <TablaMercado datos={data} displayInformation={deleteRow} useValidate={useFondosNoConciliadosPDP} textoBoton={"Eliminar"} textTitle="Clic para eliminar" mercadoPrimario={true} funcion1MercadoPrimario={conciliarFondos} texto1MercadoPrimario="Conciliar fondos"></TablaMercado>
                                                </>
                                            }
                                            {copia2 &&
                                                <>
                                                    <TablaMercado datos={data} displayInformation={deleteRow} useValidate={useFondosNoConciliadosPDP} textoBoton={"Eliminar"} textTitle="Clic para eliminar" mercadoPrimario={true} funcion1MercadoPrimario={conciliarFondos} texto1MercadoPrimario="Conciliar fondos"></TablaMercado>
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}





