import { useMemo } from "react";

export default function usePagosPendientesConfirmacion() {
    const columns = useMemo(
        () => [
            {
                Header: "ISIN",
                accessor: "isin"
            },
            {
                Header: "Nombre de la emisión",
                accessor: "nombreEmision"
            },

            {
                Header: "Nombre de la serie",
                accessor: "nombreSerie"
            },
            {
                Header: "Fecha de pago",
                accessor: "fechaPago"
            },
            {
                Header: "Tipo de pago",
                accessor: "tipoPago"
            },
        ],
        []
    );

    return columns;
}