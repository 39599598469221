import React, { useState } from 'react';

export const FormContext = React.createContext();

export function FormProvider({ children }) {
  const [formValue, setFormValue] = useState({
    datos: {
      cehvalDepositanteNombreConf:"",
      cehvalDepositanteCorreoConf:"",
      codigoDepositante: "",
      rtn: "",
      rtnRepresentante:"",
      nombre: "",
      actividadEconomica: "",
      nacionalidad: "",
      fechaConstitucion: "",
      numeroEscritura: "",
      tomoFolio: "",
      fechaUltimaModificacion: "",

      pais: "",
      departamento: "",
      municipio: "",
      ciudad: "",
      colonia: "",
      calle: "",
      edificio: "",
      codigoPostal: "",
      departamentoManual:"",
      municipioManual:"",
      ciudadManual:"",

      paisHonduras:true,




      primerNombre: "",
      segundoNombre: "",
      primerApellido: "",
      segundoApellido: "",
      apellidoCasada: "",
      tipoIdentificacion: "",
      numeroIdentificacion: "",
      estadoCivil: "",
      genero: true,
      nacionalidadPersonaJuridica: "",
      fechaNacimiento: "",
      lugarNacimiento: "",
      puesto: "",
      tiempoPuesto: "",
      telefono: "",
      correoLab: "",
      fechaRepr: "",
      telefonoCelular: "",
      telefonoResidencia: "",
      origenRecursos: "",

      numeroCuentaN: "",
      tipoCuentaN: "",
      bancoN: "",
      monedaN:1,

      numeroCuentaE: "",
      tipoCuentaE: "",
      bancoE: "",
      monedaE:2,

      nombreCompleto1: "",
      segundoNombre1:"",
      primerApellido1:"",
      segundoApellido1:"",
      correo1: "",

      nombreCompleto2: "",
      segundoNombre2:"",
      primerApellido2:"",
      segundoApellido2:"",
      correo2: "",

      /*nombreCompleto3: "",
      correo3: "",

      nombreCompleto4: "",
      correo4: "",

      nombreCompleto5: "",
      correo5: "",

      nombreCompleto6: "",
      correo6: "",*/


      rutaS3_1: "",
      tipoFirma_1: "",
      nombreCompleto_1: "",
      cargo_1: "",

      rutaS3_2: "",
      tipoFirma_2: "",
      nombreCompleto_2: "",
      cargo_2: "",

      rutaS3_3: "",
      tipoFirma_3: "",
      nombreCompleto_3: "",
      cargo_3: "",

      rutaS3_4: "",
      tipoFirma_4: "",
      nombreCompleto_4: "",
      cargo_4: "",

      rutaS3_5: "",
      tipoFirma_5: "",
      nombreCompleto_5: "",
      cargo_5: "",

      rutaS3_6: "",
      tipoFirma_6: "",
      nombreCompleto_6: "",
      cargo_6: "",

      nombreBolsa: "",
      lugarRegistro:"",
      fechaRegistro:"",
      
      descripcionEstatus:"",

      actividadEconomicaPersonaJuridica:"",
      firmaRepresentante:"",

      exencionInpuestos:"",
      enviar:0

    }
  });

  return (
    <FormContext.Provider value={[formValue, setFormValue]}>
      {children}
    </FormContext.Provider>
  );
}
