//Formats number when a user enters a value
const formatNumber = (givenNumber) => {

    // Remove all characters other than digits or decimal points
    let number = givenNumber.replace(/[^\d.]/g, '');

    //Formats as a type number and includes decimals if has any
    const integer = parseFloat(number);

    // Divide the number into two parts: the integer part and the decimal part
    let splitNumber = number.split('.');

    let integerPart = splitNumber[0];

    //Check number of positions of array and add ".splitNumber" if array has more than one position
    let decimalPart = splitNumber.length > 1 ? `.${splitNumber[1]}` : '';

    // Add commas as thousands separators in the integer part
    const addCommas = insertCommas(integerPart);

    // Combine the integer part and the decimal part into a single formatted number
    let formattedNumber = addCommas + decimalPart;

    const objNumber = {
        stringNumber: formattedNumber,
        integerNumber: integer,
    };
    //console.log(objNumber)



    return objNumber;
}

function insertCommas(number) {
    const reversedNumber = String(number).split('').reverse().join('');
    const regex = /(\d{3})(?=\d)/g;
    const formattedNumber = reversedNumber.replace(regex, '$1,');
    return formattedNumber.split('').reverse().join('');
}

export default formatNumber;

