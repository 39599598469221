import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";


//General components
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";

//APIs
import { registrarSolicitudDepositoValorFisico } from '../../../../api/resguardoValoresFisicos/registroValoresFisicos/registrarSolicitudDepositoValorFisico';
import { consultaNombreDepositanteValoresFisicos } from '../../../../api/resguardoValoresFisicos/registroValoresFisicos/consultaNombreDepositanteValoresFisicos';



export default function SolicitudDepositoValorFisico({ history }) {

    useEffect(() => {
        setDate();
    }, []);

    const historyReact = useHistory();

    //Inputs 
    const [nombreValor, setNombreValor] = useState();
    const [codigoDepValFis, setCodigoDepValFis] = useState();
    const [nombreDepValFis, setNombreDepValFis] = useState();
    const [descripcionValor, setDescripcionValor] = useState();
    const [fechaDeposito, setFechaDeposito] = useState();
    const [fechaRetiro, setFechaRetiro] = useState();
    const [lugarRegistro, setLugarRegistro] = useState();

    //Limit the input type date
    const [fechaMinima, setFechaMinima] = useState();


    //States to show modal when regiter showInputsTypeText is successful or not
    const [registroExitoso, setRegistroExitoso] = useState();
    const [registroFallido, setRegistroFallido] = useState();

    //Shows loading screen when true
    const [loading, setLoading] = useState(false);

    //Messages user
    const [message, setMessage] = useState();
    const [messageSuccessful, setMessageSuccessful] = useState();
    const [messageFailed, setMessageFailed] = useState();
    const [failLoadData, setFailLoadData] = useState();

    const [depositanteValido, setDepositanteValido] = useState();
    const [depositanteInvalido, setDepositanteInvalido] = useState();

    const [erroreshttp, setErroreshttp] = useState();

    const [fecha_Retiro,setFecha_Retiro]=useState()


    const setDate = () => {
        // Get current date
        const date = new Date();
        date.setDate(date.getDate() - 1);
        const minDate = date.toISOString().split('T')[0];

        //Asing min value to input type date
        setFechaMinima(minDate);
    }



    //Handle api to get depositant name when user enter the depositant code
    const getDepositantName = async () => {
        try {
            const response = await consultaNombreDepositanteValoresFisicos({ codigo: codigoDepValFis });
            if (response) {
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    //console.log(response)

                    if (response.data === "undefined") {
                        setDepositanteValido(false);
                        setDepositanteInvalido(true);
                        setMessage("El código de depositante no existe, por favor ingrese un código de depositante válido.");
        
                    } else if (response.status === 200) {
                        setDepositanteInvalido(false);
                        setDepositanteValido(true);
                        setNombreDepValFis(response.data);
                        setMessage("Depositante válido");
                    } else {
                        setFailLoadData(true);
                    }
                }
            }

           
        } catch (error) {
            console.error(error)
        }
    }


    //Executes the function getDepositantName(); when length of input value is equal to 8
    const executeGetDepositantName = (e) => {
        if (e.target.value.length === 8 || e.target.value.length === 10) {
            getDepositantName();
        } else {
            setNombreDepValFis("");
            setDepositanteValido(false);
            setDepositanteInvalido(false);
            setMessage("");
        }
    }


    //Handle when user click "Enviar"
    const sendRequest = async (e) => {
        e.preventDefault();
        try {
            const obj = {
                depositoValorFisicoId: "",
                depositoValorFisicoNomCompleto: nombreValor,
                depositoValorFisicoCodigoDepositante: codigoDepValFis,
                depositoValorFisicoNombreDepo: nombreDepValFis,
                depositoValorFisicoFechaDepo: fechaDeposito,
                depositoValorFisicoFechaRetiro: fechaRetiro,
                depositoValorFisicoDescripcionValor: descripcionValor,
                depositoValorFisicoLugar: lugarRegistro,
                depositoValorFisicoDepoId: ""
            }
            //console.log(obj)

            setLoading(true);
            const response = await registrarSolicitudDepositoValorFisico(obj);
            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    //console.log(response);
                    if (response.data.isSuccessful) {
                        setLoading(false);
                        setMessageSuccessful(response.data.mensaje);
                        setRegistroExitoso(true);
                    } else {
                        setLoading(false);
                        setMessageFailed(response.data.mensaje);
                        setRegistroFallido(true);
                    }
                }
            }

          
        } catch (error) {
            console.error(error);
        }
    }

    const establecerFechaRetiro = (e) => {
        ////console.log(fecha_emision)
        ////console.log(e)



        var d = new Date(e);
        const fecha = sumarDias(d, 2)


        const añoActual = fecha.getFullYear();
        let diaActual = fecha.getDate();
        let mesActual = fecha.getMonth() + 1;

        //console.log(añoActual)
     //   if (añoActual < 1900) {
       //     setErrorFormatoFecha1(true)
        //} else {
          //  setErrorFormatoFecha1(false)
        //}

        if (diaActual < 10) {
            diaActual = "0" + diaActual;
        }

        if (mesActual < 10) {
            mesActual = "0" + mesActual;
        }

        const fecha_limite_vencimiento = (añoActual) + "-" + mesActual + "-" + (diaActual);
       // //console.log("hola")
        ////console.log(fecha_limite_vencimiento)
        setFecha_Retiro(fecha_limite_vencimiento)
        ////console.log(e)
    }

    const sumarDias = (fecha, dias) => {
        fecha.setDate(fecha.getDate() + dias);
        return fecha;
    }


    //Executes when the emitter was successfully registered
    const goHome = () => {
        //console.log("limpiar")
        setRegistroExitoso(false)
        setNombreDepValFis("")
        setNombreValor("")
        setCodigoDepValFis("")
        setDescripcionValor("")
        setFechaDeposito("")
        setFechaRetiro("")
        setLugarRegistro("")
        setDepositanteInvalido("")
        setDepositanteValido("")

        setFechaDeposito("")
        setFechaRetiro("")
        setFecha_Retiro("")
        setCodigoDepValFis("")
        setDescripcionValor("")
        setLugarRegistro("")

      
        //history.push({
          //  pathname: '/inicioCehval',
        //})
    }

    return (
        <>
            {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={goHome} titulo={`${messageSuccessful}`} textoBoton="Aceptar"></ComponenteModal>
            }
            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={() => setRegistroFallido(false)} titulo={messageFailed} textoBoton="Aceptar"></ComponenteModal>
            }
            {loading &&
                <PantallaCargando />
            }
            {failLoadData &&
                <ComponenteModal mostrarModal={failLoadData} cerrarModal={goHome} titulo={`Ocurrió un error, verifica tu conexión o vuelve a iniciar sesión`} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="fluid-container">
                
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        {/*<div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 d-flex flex-column">
                            <BarraLateral2  ></BarraLateral2>
                        </div>*/}
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Solicitud de depósito de valores físicos"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <section className="p-5 d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded p-3">
                                                <div className="col-12">
                                                    <form onSubmit={sendRequest} className="needs-validation font-acumin-variable text-royal-blue">
                                                        <div className="row mt-4">

                                                            <h6 className="text-muted">campos obligatorios *</h6>
                                                            <div className="col-12 col-md-12 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código depositante transferente" value={nombreValor} onChange={(e) => setNombreValor(e.target.value)} className="form-control text-royal-blue" maxLength={90} name="nombreCompletoValor" type="text" placeholder="" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="nombreCompletoValor">Nombre completo del valor *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código depositante transferente" value={codigoDepValFis} onChange={(e) => setCodigoDepValFis(e.target.value)} onKeyUp={(e) => executeGetDepositantName(e)} maxLength={10} className="form-control text-royal-blue" name="codigoDepositanteValoresFisicos" type="text" placeholder="" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="codigoDepositanteValoresFisicos">Código de depositante de valores físicos *</label>
                                                                    {depositanteValido &&
                                                                        <span className="text-success text-size-12px">{message}</span>
                                                                    }
                                                                    {depositanteInvalido &&
                                                                        <span className="text-danger text-size-12px">{message}</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código depositante transferente" value={nombreDepValFis} className="form-control text-royal-blue" name="nombreDepositanteValoresFisicos" type="text" required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="nombreDepositanteValoresFisicos">Nombre del depositante de valores físicos *</label>
                                                                    <span className="text-muted text-size-10px">Ingrese código de depositante de valores físicos</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 form-floating mb-3">
                                                                <div className="form-floating">
                                                                    <input min={fechaMinima} value={fechaDeposito} type="date" className="form-control border" onChange={(e) => {setFechaDeposito(e.target.value)
                                                                                                                                                            establecerFechaRetiro(e.target.value)}} id="fechaDeposito" name="fechaDeposito" required />
                                                                    <label htmlFor="fechaDeposito" className="form-label ">Fecha de depósito *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código depositante transferente"  value={lugarRegistro} onChange={(e) => setLugarRegistro(e.target.value)} maxLength={50} className="form-control text-royal-blue" name="lugarRegistro" type="text" placeholder="" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="lugarRegistro">Lugar del registro *</label>
                                                                </div>
                                                            </div>
                                                           {/*} <div className="col-12 col-md-6 form-floating mb-3">
                                                                <div className="form-floating">
                                                                    <input min={fecha_Retiro}  value={fechaRetiro} type="date" className="form-control border" onChange={(e) => setFechaRetiro(e.target.value)} id="fechaRetiro" name="fechaRetiro" />
                                                                    <label htmlFor="fechaRetiro" className="form-label ">Fecha de retiro</label>
                                                                </div>
                                                                    </div>*/}
                                                            <div className="col-12 col-md-12 mb-3">
                                                                <div className="form-floating ">
                                                                    <textarea title="Cantidad de valores a transferir"  value={descripcionValor} onChange={(e) => setDescripcionValor(e.target.value)} maxLength={250} className="form-control fs-5 text-royal-blue rejectionReason" name="descripcionValor" type="text" placeholder="" required ></textarea>
                                                                    <label className="text-start font-acumin-variable" htmlFor="descripcionValor">Descripción valor *</label>
                                                                </div>
                                                            </div>
                                                          
                                                            <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                <button type="button" onClick={() => { historyReact.goBack() }} className="btn col-6 col-sm-3 col-md-3 col-lg-3 col-xl-2 background-royal-blue text-white bg-hover-red me-2">Cancelar</button>
                                                                <button type="submit" className="btn col-6 col-sm-3 col-md-3 col-lg-3 col-xl-2 background-royal-blue text-white bg-hover-gold">Enviar</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    )
}