import React, {useState, useEffect} from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";
import RegistroAutomatico from "../AbrirCuentasDepositantes/RegistroAutomatico";

//APIs
import { traerInformacionEmisorCodigo } from "../../../api/VerInformacionDeEmisor.js/traerInformacionEmisorCodigo";
import { traerInformacionAllEmisores } from "../../../api/VerInformacionDeEmisor.js/traerInformacionAllEmisores";

//Hooks
import useValidateEmision from '../../../hooks/useValidateEmision'


export default function EmisoresParaAbrirCuentas({ history }) {
    const obj = {
        estatus: 2
    };
    const estatus = 2;

    const [data, setData] = useState([]);
    const [copia, setCopia] = useState();
    const [spin, setSpin] = useState(true);
    const [erroreshttp, setErroreshttp] = useState();
    const [registrarCuentas, setRegistrarCuentas] = useState();
    const [informacionUsuario, setInformnacionUsuario] = useState();
    const [codigo, setCodigo] = useState(true);
    const [tipoParticipante, setTipoParticipante] = useState(true);
    const [idEmisor, setIdEmisor] = useState(true);

    useEffect(() => {
        traerEmisores();
    }, [])

    const traerEmisores = async () => {
        const usuarios = await traerInformacionAllEmisores(obj)
        if (usuarios) {
            setSpin(false)
            if ((isNaN(usuarios.error)) === false) {
              
                setErroreshttp(usuarios)
                
            } else {
                setData(usuarios)
                setCopia(usuarios)
            }
        }

    }

    const continuarOferta = async (row) => {
        setCodigo(row.original.cehvalEmisorCodigo)
        setTipoParticipante(row.original.tipoParticipante)
        setIdEmisor(row.original.cehvalEmisorId)
        ////console.log(history.location.state.objMenu.id)
        var traerInformacion = await traerInformacionEmisorCodigo({ codigo: row.original.cehvalEmisorCodigo });
        //console.log(traerInformacion)

        if (traerInformacion) {
            setSpin(false)
            if ((isNaN(traerInformacion.error)) === false) {
              
                setErroreshttp(traerInformacion)
                
            } else {
                // //console.log(traerInformacion)
                setInformnacionUsuario(traerInformacion)
                setRegistrarCuentas(true)
            }
        }
    }

    const resetear=()=>{
         setRegistrarCuentas(false)
    }




    return (
        <>
            <div className="fliud-container" >
                {registrarCuentas &&
                    <RegistroAutomatico resetear={resetear} informacion={informacionUsuario} estatus={estatus} codigo={codigo} idEmisor={idEmisor} tipoParticipante={tipoParticipante}></RegistroAutomatico>
                }
               {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }    
                 {/*}  <Header history={history} ></Header>*/}
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Solicitudes de emisores para abrir cuentas"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">

                                            {copia &&
                                                <TablaUsuarios datos={data} displayInformation={continuarOferta} useValidate={useValidateEmision} textoBoton={"Enviar credenciales"} textTitle="Click para enviar credenciales al emisor"></TablaUsuarios>
                                            }

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}