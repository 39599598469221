//React
import React, { useState } from "react";

//Componentes generales
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
//import SpinnerSend from "../../../ComponentesGenerales/SpinnerSend";
import PantallaCargando from '../../../ComponentesGenerales/PantallaCargando'

//API consultar emision
import { consultarEmision } from '../../../api/emision/consultarEmision';


//Formulario de series
import SeriesForm from './SeriesForm';


export default function RegistroSeries(props) {


    //input props
    const [displaySeries, setDisplaySeries] = useState(false);
    const [codigoEmision, setCodigoEmision] = useState();
    const [nombreDeLaEmision, setNombreDeLaEmision] = useState();
    const [monedaDeEmision, setMonedaDeEmision] = useState();
    const [montoRestanteEmision, setMontoRestanteEmision] = useState();
    const [idMoneda, setIdMoneda] = useState();
    const [formaRepresentacion, setFormaRepresentacion] = useState();
    const [arraySeries, setArraySeries] = useState();
    const [seriesDisponibles, setSeriesDisponibles] = useState();
    const [loading, setLoading] = React.useState(false);

    //Spinner
    //const [spinSend, setSpinSend] = React.useState(false)

    //Show modal if emision registration is susccessful
    const [registroExitoso, setRegistroExitoso] = useState();
    const [registroSinExito, setRegistroSinExito] = useState();

    //States to handle errors
    const [erroreshttp, setErroreshttp] = React.useState();
    const [errorMessage, setErrorMessage] = useState(null);

    const [tipoRegistro, setTipoRegistro] = useState(null);


    //Consulta si existe una emisión en la base de datos
    const searchEmision = async (e) => {
        e.preventDefault();

        //  let seriesAvailable;

        if (!codigoEmision) {
            setErrorMessage("Por favor ingrese el código de la emisión");
            setSeriesDisponibles(null);
            document.querySelector("#formRegisterSeries").style.display = "none";
            return;

        } else if (codigoEmision) {

            try {
                //Objeto para hacer la petición a la DB
                const emisionObj = { codigo: codigoEmision, estatus: 1 }


                //Consulta emision a la DB
                const response = await consultarEmision(emisionObj);
                //console.log(response.data)
                if (response) {
                    //console.log(response.data)
                    setLoading(false)
                    if ((isNaN(response.error)) === false) {
                        // //console.log("erores")
                        ////console.log(response)
                        setErroreshttp(response)
                        
                    } else {
                        //console.log(response)
                        ////console.log(response.statusCode)
                        //Verifica si la emisión existe
                        if (response.data.cantidaddisponible === -1) {
                            setErrorMessage("La emisión no existe, verifica que el nombre de la emisión sea correcto");
                            document.querySelector("#formRegisterSeries").style.display = "none";
                            setSeriesDisponibles(null);
                            return;

                            //Verifica si la emision tiene series disponibles
                        } else if (response.data.cantidaddisponible === -2) {
                            setErrorMessage("La emisión consultada fue registrada a través de la BCV, favor de registrar la serie con el mismo método");
                            document.querySelector("#formRegisterSeries").style.display = "none";
                            setSeriesDisponibles(null);
                            return;
                        } else if (response.data.cantidaddisponible === 0) {
                            setSeriesDisponibles(null);
                            setErrorMessage("No hay series disponibles para esta emisión");
                            document.querySelector("#formRegisterSeries").style.display = "none";

                        } else {

                            const nameEmision = response.data.nombreEmision;
                            const monedaEmision = response.data.monedaEmision;
                            const idDeMoneda = response.data.idMoneda;

                            //Borra el mensaje de error si antes se ingreso un codigo invalido
                            setErrorMessage(null);

                            //Muestra las series disponibles, nombre de emisión y la moneda
                            //seriesAvailable = response.data.cantidaddisponible;
                            setSeriesDisponibles(response.data.cantidaddisponible);
                            setNombreDeLaEmision(nameEmision);
                            setMonedaDeEmision(monedaEmision);
                            setIdMoneda(idDeMoneda);
                            setArraySeries(response.data.nombresDisponibles);
                            setDisplaySeries(true);
                            setMontoRestanteEmision(response.data.montoRestante)
                            setFormaRepresentacion(response.data.formaRepresentacionEmision)


                            //Despliega el formulario para registrar una serie
                            document.querySelector("#formRegisterSeries").style.display = "block";
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }
        } else {
            setErrorMessage(null);
        }
    }



    const irInicio = () => {
        setRegistroExitoso(false);
        setRegistroSinExito(false);
        window.location.reload();
    }

    return (
        <>
            <div className="fluid-container">
                {loading &&
                    <PantallaCargando />
                }
                {registroExitoso &&
                    <ComponenteModal mostrarModal={registroExitoso} cerrarModal={irInicio} titulo="Registro exitoso" textoBoton="Entendido"></ComponenteModal>
                }
                {registroSinExito &&
                    <ComponenteModal mostrarModal={registroSinExito} cerrarModal={irInicio} titulo="Esta serie ya fué registrada" textoBoton="Entendido"></ComponenteModal>
                }
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }


                <main className="container-min-height mb-5">
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Registro de series"></BannerTitulo>
                                <div className="d-flex justify-content-center">
                                    <div className="col-12 col-md-10">
                                        <section className="p-2 d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 col-lg-12 row justify-content-center border rounded p-3">
                                                <div className="col-12">
                                                    <div className="">
                                                        <div className="">
                                                            <h4 className="text-royal-blue mb-3 font-acumin-variable fs-20-r">Ingrese el código de la emisión</h4>
                                                            <form id="searchEmision" onSubmit={(e) => {
                                                                searchEmision(e)
                                                                setLoading(true)
                                                            }} className="needs-validation font-acumin-variable">
                                                                <div className="row">
                                                                    <div className=" col-md-6 mb-3">
                                                                        <div className="form-floating ">
                                                                            <input className="form-control" onChange={(e) => { setCodigoEmision(e.target.value.toUpperCase()) }} maxLength={14} type="search" id="search" name="search" placeholder="Nombre de la emisión" required />
                                                                            <label className="form-label text-start font-acumin-variable " htmlFor="search">Código de la emisión <span className="text-muted">14 caracteres</span></label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 form-floating">
                                                                        <div className="form-floating mb-2">
                                                                            <select className="form-select fs-5 border  " value={tipoRegistro} onChange={(e) => setTipoRegistro(e.target.value)} id="tipoRegistro" name="tipoRegistro" required>
                                                                                <option value=""> </option>
                                                                                <option value={1}>Series</option>
                                                                                <option value={2}>Acciones</option>
                                                                            </select>
                                                                            <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Tipo de registro</label>
                                                                        </div>
                                                                    </div>
                                                                    {errorMessage &&
                                                                        <p className="text-danger col-12 col-md-12 col-lg-12">{errorMessage}</p>
                                                                    }

                                                                    <div className="col-10 d-flex align-items-center">
                                                                        <button className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable" type="submit">Consultar emisión </button>
                                                                    </div>
                                                                    {/*{spinSend &&
                                                                        <SpinnerSend textoSpinner="Enviando..."></SpinnerSend>
                                                                     }*/}



                                                                </div>
                                                            </form>
                                                            <div className="mt-4">
                                                                {seriesDisponibles &&
                                                                    <p className="text-royal-blue font-acumin-variable">Series disponibles de la emisión: <span className="text-success">{seriesDisponibles}</span></p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {displaySeries &&
                                                    <SeriesForm
                                                        codigoDeEmision={codigoEmision}
                                                        nombreDeEmision={nombreDeLaEmision}
                                                        montoRestante={montoRestanteEmision}
                                                        monedaDeLaEmision={monedaDeEmision}
                                                        idDeMoneda={idMoneda}
                                                        formaRepresentacion={formaRepresentacion}
                                                        availableSeries={arraySeries}
                                                        displaySeries={displaySeries}
                                                        tipoRegistro={tipoRegistro}>
                                                    </SeriesForm>
                                                }
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    )
}