import React from "react";

import { FormContext } from "./form_context";

export default function RegistroDI1(props) {
    const [ ,setValue] = React.useContext(FormContext);

    React.useEffect(() => {
        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 0 };
            return { ...prev, datos };
        });

    }, [])

    const convertirTime = (info) => {
        let date = new Date((info / 1000) * 1000);
        return date.toLocaleDateString("en-GB")
    }


    return (
        <>

            <div className="card-body">
                <h5 className="textoTituloFormulario" >I. Identificación de la institución</h5>


                <div className="row mb-3  justify-content-end">

                    {props.info.cehvalDepositanteEstatus > 3 &&
                        <div className="col-md-3">
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto" >Código</label>
                            <input value={props.info.cehvalDepositanteCodigoCehval} disabled id="codigoDepositante" name="codigoDepositante" type="text" className="form-control " required />
                        </div>}


                </div>


                <div className="row mb-3">
                    <div className="col-md-12">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Nombre completo de la institución</label>
                        <input value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaJurNombre} disabled type="text" className="form-control " id="nombre" name="nombre" required />
                    </div>
                </div>

                <div className="row mb-3">
                  
                    <div className="col-md-4">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Actividad económica</label>
                        <input value={props.info.cehvalDepositantePersonaJurId.catActividadEcoId.nombre} disabled type="text" className="form-control " id="actividadEconomica" name="actividadEconomica" required />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">RTN (si aplica)</label>
                        <input value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaJurRtn} disabled type="text" className="form-control " id="rtn" name="rtn" required />
                    </div>

                    <div className="col-md-4">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Nacionalidad</label>
                        <input value={props.info.cehvalDepositantePersonaJurId.catNacionalidadId.nombre} disabled type="text" className="form-control " id="nacionalidad" name="nacionalidad" required />
                    </div>
                </div>

                <div className="row mb-3">
                   
                    <div className="col-md-4">
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Fecha de constitución</label>
                        <input value={convertirTime(props.info.cehvalDepositantePersonaJurId.cehvalPersonaJurFecCon)} disabled type="text" className="form-control " id="fechaConstitucion" name="fechaConstitucion" required />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">No. de escritura original</label>
                        <input value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaJurNumeroEscriSocOri} disabled type="text" className="form-control " id="numeroEscritura" name="numeroEscritura" required />

                    </div>
                    <div className="col-md-4">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Tomo/folio</label>
                        <input value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaJurTomFol} disabled type="text" className="form-control " id="tomoFolio" name="tomoFolio" required />

                    </div>
                </div>
                <div className="row mb-3">
                  

                    <div className="col-md-4">
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Fecha de última modificación</label>
                        <input value={convertirTime(props.info.cehvalDepositantePersonaJurId.cehvalPersonaJurFechaModForm)} disabled type="text" className="form-control " id="fechaUltimaModificacion" name="fechaUltimaModificacion" required />

                    </div>
                </div>

            </div>
            <br></br>
        </>
    );
}

