import { useMemo } from "react";

export default function useColumns() {
 const columns = useMemo(
   () => [   
    
 
     {
       Header:"Nombre de la institución",
       accessor: "cehvalDepositantePersonaJurId.cehvalPersonaJurNombre"
     },
  
     {
       Header: "Tipo",
       accessor: "cehvalDepositanteCatTipDepId.nombre"
     },
     
   /*  {
       Header: "ID REGISTRO",
       accessor: "cehvalDepositanteCodigo"
     }, */
   ],
   []
 );

 return columns;
}
