import { instance } from '../apiGlobal';

export async function actualizarSolicitudRegistroDepValFis (obj) {
      try {
        const response = await instance(`/depositantevf/actualizar`, {
          method:"post",
          data:obj
        })
        return response;
      } catch (error) {
        return error;
      }
}