import React from "react";
import '../../../../styles/stylesheet.css'
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";
import { FormContext } from './form_context';
import { FormContext2 } from './form_context2';




export default function ActualizarDI5({ tipo }) {
    const [value,] = React.useContext(FormContext);
    const [value2, setValue2] = React.useContext(FormContext2);
    const [erroreshttp, setErroreshttp] = React.useState()
    const [tipoFirmaInicio1, setTipoFirmaInicio1] = React.useState(true)
    const [tipoFirmaInicio2, setTipoFirmaInicio2] = React.useState(true)


    const guardarDatos = (e) => {
        const { name, value } = e.target;


        setValue2((prev) => {
            const datos = { ...prev.datos, [name]: value };
            return { ...prev, datos };
        });
    }




    React.useEffect(() => {

        if ((value.datos.nombreCompleto1 !== value2.datos.nombreCompleto1) || (value.datos.segundoNombre1 !== value2.datos.segundoNombre1) || (value.datos.primerApellido1 !== value2.datos.primerApellido1) || (value.datos.segundoApellido1 !== value2.datos.segundoApellido1)) {
            const nombreCompleto1 = value2.datos.nombreCompleto1.trim() + " " + value2.datos.segundoNombre1.trim() + " " + value2.datos.primerApellido1.trim() + " " + value2.datos.segundoApellido1.trim()
            setValue2((prev) => {
                const datos = { ...prev.datos, ["nombreCompleto_1"]: nombreCompleto1 };
                return { ...prev, datos };
            });
        }

        if ((value.datos.nombreCompleto2 !== value2.datos.nombreCompleto2) || (value.datos.segundoNombre2 !== value2.datos.segundoNombre2) || (value.datos.primerApellido2 !== value2.datos.primerApellido2) || (value.datos.segundoApellido2 !== value2.datos.segundoApellido2)) {
            const nombreCompleto2 = value2.datos.nombreCompleto2.trim() + " " + value2.datos.segundoNombre2.trim() + " " + value2.datos.primerApellido2.trim() + " " + value2.datos.segundoApellido2.trim()
            setValue2((prev) => {
                const datos = { ...prev.datos, ["nombreCompleto_2"]: nombreCompleto2 };
                return { ...prev, datos };
            });
        }


    }, [])

 

    return (
        <>
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <div className="card-body pt-3">

                <h5 className="text-royal-blue font-acumin-variable mb-4" >V. Firmas autorizadas</h5>
                {tipo === "depositanteDirecto" ? <h6 className="textoTituloFormulario" >1. Usuario administrador *</h6> :
                    <h6 className="textoTituloFormulario" >1. Usuario operador *</h6>
                }
                {value.datos.establecerModificacion ?
                    <>
                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input  type="text" className="form-control fs-5 border  " value={value2.datos.nombreCompleto_1} onChange={(e) => guardarDatos(e)} id="nombreCompleto_1" name="nombreCompleto_1" disabled />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Nombre completo </label>
                                </div>
                            </div>


                            <div className="col-md-4 mt-3 mb-4">
                                <label>Tipo de firma </label>
                                {tipoFirmaInicio1 ?
                                    <div className="d-flex justify-content-start">
                                        <div className="contenedorBotonesStteper ">
                                            {value2.datos.tipoFirma_1 === "INDIVIDUAL" ?
                                                < input placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => {
                                                    guardarDatos(e)
                                                    setTipoFirmaInicio1(false)
                                                }} id="individual1" name="tipoFirma_1" checked />
                                                :
                                                < input placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => {
                                                    guardarDatos(e)
                                                    setTipoFirmaInicio1(false)
                                                }} id="individual1" name="tipoFirma_1" required />

                                            }

                                            <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="individual1">Individual</label>
                                        </div>

                                        <div className="contenedorBotonesStteper">
                                            {value2.datos.tipoFirma_1 === "INDIVIDUAL" ?
                                                < input placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => {
                                                    guardarDatos(e)
                                                    setTipoFirmaInicio1(false)
                                                }} id="mancomunada1" name="tipoFirma_1" required />
                                                :
                                                < input placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => {
                                                    guardarDatos(e)
                                                    setTipoFirmaInicio1(false)
                                                }} id="mancomunada1" name="tipoFirma_1" checked />
                                            }
                                            <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="mancomunada1">Mancomunada</label>

                                        </div>
                                    </div>
                                    :
                                    <div className="d-flex justify-content-start">
                                        <div className="contenedorBotonesStteper ">
                                            < input placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => guardarDatos(e)} id="individual1" name="tipoFirma_1" required />
                                            <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="individual1">Individual</label>
                                        </div>

                                        <div className="contenedorBotonesStteper">
                                            < input placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => guardarDatos(e)} id="mancomunada1" name="tipoFirma_1" required />
                                            <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="mancomunada1">Mancomunada</label>

                                        </div>
                                    </div>

                                }

                            </div>
                        </div>

                        {value.datos.nombreCompleto_2.length > 0 &&
                            <>
                                <h6 className="textoTituloFormulario" >2. Usuario operador </h6>
                                <div className="row mb-3">
                                    <div className="col-md-4 form-floating mb-3">
                                        <div className="form-floating">
                                            < input type="text" className="form-control fs-5 border  " value={value2.datos.nombreCompleto_2} onChange={(e) => guardarDatos(e)} id="nombreCompleto_2" name="nombreCompleto_2" disabled />
                                            <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Nombre completo </label>
                                        </div>
                                    </div>




                                    <div className="col-md-4 mt-3">
                                        <label>Tipo de firma </label>
                                        {tipoFirmaInicio2 ?
                                            <div className="d-flex justify-content-start">
                                                <div className="contenedorBotonesStteper">
                                                    {value2.datos.tipoFirma_2 === "INDIVIDUAL" ?
                                                        < input placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => {
                                                            guardarDatos(e)
                                                            setTipoFirmaInicio2(false)
                                                        }} id="individual2" name="tipoFirma_2" checked />
                                                        :
                                                        < input placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => {
                                                            guardarDatos(e)
                                                            setTipoFirmaInicio2(false)
                                                        }} id="individual2" name="tipoFirma_2" />
                                                    }

                                                    <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="individual2">Individual</label>
                                                </div>

                                                <div className="contenedorBotonesStteper">
                                                    {value2.datos.tipoFirma_2 === "INDIVIDUAL" ?
                                                        < input placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => {
                                                            guardarDatos(e)
                                                            setTipoFirmaInicio2(false)
                                                        }} id="mancomunada2" name="tipoFirma_2" />
                                                        :
                                                        <>
                                                            {
                                                                value2.datos.tipoFirma_2 === "MANCOMUNADA" ?
                                                                    < input placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => {
                                                                        guardarDatos(e)
                                                                        setTipoFirmaInicio2(false)
                                                                    }} id="mancomunada2" name="tipoFirma_2" checked />
                                                                    :
                                                                    < input placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => {
                                                                        guardarDatos(e)
                                                                        setTipoFirmaInicio2(false)
                                                                    }} id="mancomunada2" name="tipoFirma_2" />
                                                            }
                                                        </>
                                                    }

                                                    <label className="form-check-label textoFormularioDepositantedirecto  mt-1" htmlFor="mancomunada2">Mancomunada</label>
                                                </div>
                                            </div>
                                            :
                                            <div className="d-flex justify-content-start">

                                                <div className="contenedorBotonesStteper">
                                                    < input placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => guardarDatos(e)} id="individual2" name="tipoFirma_2" />
                                                    <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="individual2">Individual</label>
                                                </div>

                                                <div className="contenedorBotonesStteper">
                                                    < input placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => guardarDatos(e)} id="mancomunada2" name="tipoFirma_2" />
                                                    <label className="form-check-label textoFormularioDepositantedirecto  mt-1" htmlFor="mancomunada2">Mancomunada</label>
                                                </div>
                                            </div>


                                        }

                                    </div>
                                </div>
                            </>
                        }




                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5 border" value={value2.datos.lugarRegistro} onChange={(e) => guardarDatos(e)} id="lugarRegistro" name="lugarRegistro" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Lugar de registro </label>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input  type="text" className="form-control fs-5 border  " value={value.datos.nombreCompleto_1} id="nombreCompleto_1" name="nombreCompleto_1" disabled />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Nombre completo </label>
                                </div>
                            </div>

                            <div className="col-md-4 mt-3 mb-4">
                                <label>Tipo de firma </label>
                                <div className="d-flex justify-content-start">
                                    <div className="contenedorBotonesStteper ">
                                        {value.datos.tipoFirma_1 === "INDIVIDUAL" ?
                                            < input disabled placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => guardarDatos(e)} id="individual1" name="tipoFirma_1" checked />
                                            :
                                            < input disabled placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => guardarDatos(e)} id="individual1" name="tipoFirma_1" required />

                                        }

                                        <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="individual1">Individual</label>
                                    </div>

                                    <div className="contenedorBotonesStteper">
                                        {value.datos.tipoFirma_1 === "INDIVIDUAL" ?
                                            < input disabled placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => guardarDatos(e)} id="mancomunada1" name="tipoFirma_1" required />
                                            :
                                            < input disabled placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => guardarDatos(e)} id="mancomunada1" name="tipoFirma_1" checked />
                                        }

                                        <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="mancomunada1">Mancomunada</label>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {value.datos.nombreCompleto2 &&
                            <>
                                <h6 className="textoTituloFormulario" >2. Usuario operador </h6>
                                <div className="row mb-3">
                                    <div className="col-md-4 form-floating mb-3">
                                        <div className="form-floating">
                                            < input  type="text" className="form-control fs-5 border  " value={value.datos.nombreCompleto_2} onChange={(e) => guardarDatos(e)} id="nombreCompleto_2" name="nombreCompleto_2" disabled />
                                            <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Nombre completo </label>
                                        </div>
                                    </div>

                                    <div className="col-md-4 mt-3">
                                        <label>Tipo de firma </label>
                                        <div className="d-flex justify-content-start">
                                            <div className="contenedorBotonesStteper">
                                                {value.datos.tipoFirma_2 === "INDIVIDUAL" ?
                                                    < input disabled placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => guardarDatos(e)} id="individual2" name="tipoFirma_2" checked />
                                                    :
                                                    < input disabled placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => guardarDatos(e)} id="individual2" name="tipoFirma_2" required />
                                                }

                                                <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="individual2">Individual</label>
                                            </div>

                                            <div className="contenedorBotonesStteper">
                                                {value.datos.tipoFirma_2 === "INDIVIDUAL" ?
                                                    < input disabled placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => guardarDatos(e)} id="mancomunada2" name="tipoFirma_2" required />
                                                    :
                                                    < input disabled placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => guardarDatos(e)} id="mancomunada2" name="tipoFirma_2" checked />
                                                }

                                                <label className="form-check-label textoFormularioDepositantedirecto  mt-1" htmlFor="mancomunada2">Mancomunada</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        <div className="row mb-3">
                            <div className="col-md-8 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input disabled  type="text" className="form-control fs-5 border" value={value.datos.lugarRegistro} onChange={(e) => guardarDatos(e)} id="lugarRegistro" name="lugarRegistro" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Lugar de registro </label>
                                </div>
                            </div>
                        </div>
                    </>
                }




            </div>
        </>
    );
}

