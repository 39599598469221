import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';


//Components
import ComponenteModal from '../../../../ComponentesGenerales/ComponenteModal';


export default function FormInformacionOperacion(props) {


    //Funcion props
    const { funcion1, funcion2, funcion3 } = props;

    const historyReact = useHistory();


    //Form information


    //Operacion
    const folioOperacion = props.datos.folio;
    const fechaEjecucion = props.datos.fechaEjecucionInicioStr;
    const fechaOperacion = props.datos.fechaOperacionStr;
    const tipoOperacion = props.datos.tipoStr;
    const estatus = props.datos.estatusOperacionStr;
    const tipoCambio = props.datos.tipoCambioStr
    const plazoDias = props.datos.plazoDiasStr
    const [folioAsociadoCVIncumplida, setFolioAsociadoCVIncumplida] = useState();
    const [folioAsociadoReportoIncumplido, setFolioAsociadoReportoIncumplido] = useState();



    //IF IS REPORTO
    const fechaEjecucionVencimiento = props.datos.fechaEjecucionVencimientoStr;


    //Emision
    const codigoEmision = props.datos.codigoEmision;
    const nombreEmisor = props.datos.nombreEmisor;

    //Serie
    const ISIN = props.datos.isin;
    const codigoSerie = props.datos.codigoSerie;
    const serie = props.datos.nombreSerie;
    const monedaSerie = props.datos.monedaSerieStr;
    const monedaLiquidacion = props.datos.monedaLiquidacionStr;

    //Valores negociados
    const cantidadValores = props.datos.numeroValoresStr;
    // const precioUnitario = props.datos.precioUnitarioInicioStr;
    const montoTotal = props.datos.montoTotalPagarInicioStr;
    //IF IS REPORTO
    // const precioUnitarioVencimiento = props.datos.precioUnitarioVencimientoStr;
    const montoTotalVencimiento = props.datos.montoTotalPagarVencimientoStr;

    //Vendedor
    const vendedorCodCasaBolsa = props.datos.codigoCasaBolsaVendedor;
    const vendedorCodClienteCehval = props.datos.codigoClienteVendedor;
    const vendedorNombreClienteCehval = props.datos.nombreClienteVendedor;

    //Comprador
    const compradorCodCasaBolsa = props.datos.codigoCasaBolsaComprador;
    const compradorCodClienteCehval = props.datos.codigoClienteComprador;
    const compradorNombreClienteCehval = props.datos.nombreClienteComprador;

    //informacionpara liquidacion de fondos


    //Boolean States
    const isCompraventa = props.isCompraventa;
    const isReporto = props.isReporto;

    //New states for module (Salvaguardas financieras) operaciones forzadas
    const isCompraventaForzada = props.isCompreventaForzada;
    const isReportoForzado = props.isReportoForzado;

    //const isRegistered = props.isRegistered;
    const botones = props.botones;
    const componenteExtra = props.componenteExtra;
    const botonComponenteExtra = props.botonComponenteExtra;
    //const montoTotalFondos = props.montoTotalFondos;
    //const botonRegresar=props.botonRegresar

    //informacion Liquidacion de fondos
    const nombreClienteCehval = props.datos.infoLiqNombreClienteCehval;
    const direccion = props.datos.infoLiqDireccion;
    const tipoCuenta = props.datos.infoLiqTipoCuenta;
    const rtn = props.datos.infoLiqRtn;
    const cuentaBCH = props.datos.infoLiqCuentaBCHTR;
    const montoTotalAPagarliquidacion = props.datos.infoLiqMontoTotalAPagar;

    //New data for module (Salvaguardas financieras) operaciones forzadas
    const difPrecioContraCompraVIncumplida = props.datos.infoLiqDiferenciaCv;

    const banco = props.datos.infoLiqBanco;


    //objetopara regresar a cargar archivo
    const objetoOperaciones = props.infoOperaciones
    const [cancelOperation, setCancelOperation] = useState();


    useEffect(() => {
        if (isCompraventaForzada) {
            if (props.datos.infoLiqFolioOperacionAsociada === null) {
                setFolioAsociadoCVIncumplida("No ha sido asociado");
            } else {
                setFolioAsociadoCVIncumplida(props.datos.infoLiqFolioOperacionAsociada);
            }
        } else if (isReportoForzado) {
            if (props.datos.infoLiqFolioOperacionAsociada === null) {
                setFolioAsociadoReportoIncumplido("No ha sido asociado");
            } else {
                setFolioAsociadoReportoIncumplido(props.datos.infoLiqFolioOperacionAsociada);
            }
        }

    }, [])

    const goBack = () => {
        historyReact.push({
            pathname: '/cargarInformacionOperacionesMS', state: { operaciones: objetoOperaciones }

        })
        //historyReact.goBack();
    }



    return (
        <>
            {cancelOperation &&
                <ComponenteModal mostrarModal={cancelOperation} cerrarModal={goBack} titulo="¿Desea cancelar la operación?" textoBoton="Entendido" />
            }


            <div className="row mt-4">
                {/*COMPRAVENTA*/}
                <h4 className="text-royal-blue font-acumin-variable ">Operación</h4>
                <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                    <div className="form-floating ">
                        <input value={folioOperacion} title="Folio de compraventa" className="form-control fs-5" maxLength={25} name="folioCompraventa" type="text" placeholder="Folio de compraventa" required disabled />
                        <label className="text-start font-acumin-variable" htmlFor="folioCompraventa">Folio de la operación</label>
                    </div>
                </div>
                {(isCompraventa || isCompraventaForzada) &&
                    <>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 form-floating mb-3">
                            <div className="form-floating">
                                <input value={fechaEjecucion} title="Fecha operación" type="text" className="form-control fs-5 border" name="fechaOperacion" placeholder="Fecha de operación" required disabled />
                                <label htmlFor="fechaOperacion" className="form-label ">Fecha de ejecución</label>
                            </div>
                        </div>
                    </>
                }

                {isCompraventaForzada &&
                    <>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={folioAsociadoCVIncumplida} title="Folio de la compraventa incumplida asociada" className="form-control fs-5" maxLength={25} name="folioAsociadoCompraventaForzada" type="text" placeholder="Folio de la compraventa incumplida asociada" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="folioAsociadoCompraventaForzada">Folio de la compraventa incumplida asociada</label>
                            </div>
                        </div>
                    </>
                }

                {isReportoForzado &&
                    <>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={folioAsociadoReportoIncumplido} title="Folio del reporto incumplido asociado" className="form-control fs-5" maxLength={25} name="folioAsociadoReportoForzado" type="text" placeholder="Folio del reporto incumplido asociado" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="folioAsociadoReportoForzado">Folio del reporto incumplido asociado</label>
                            </div>
                        </div>
                    </>
                }
                {(isReporto || isReportoForzado)&&
                    <>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating">
                                <input value={fechaEjecucion} title="Fecha operación (inicio)" type="text" className="form-control fs-5" name="fechaOperacionInicio" placeholder="Fecha de operación" required disabled />
                                <label htmlFor="fechaOperacionInicio" className="form-label ">Fecha de ejecución &#40;Inicio&#41;</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={fechaEjecucionVencimiento} title="Fecha operación (vencimiento)" className="form-control fs-5" name="fechaOperacionVencimiento" type="text" placeholder="fechaOperacionVencimiento" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="fechaOperacionVencimiento">Fecha de ejecución &#40;Recompra&#41;</label>
                            </div>
                        </div>
                    </>
                }

                <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                    <div className="form-floating ">
                        <input value={fechaOperacion} title="Fecha de operación" className="form-control fs-5" name="tipoOperacion" type="text" placeholder="Tipo de operación" required disabled />
                        <label className="text-start font-acumin-variable" htmlFor="tipoOperacion">Fecha de operación</label>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                    <div className="form-floating ">
                        <input value={tipoCambio} title="Tipo de cambio" className="form-control fs-5" name="tipoOperacion" type="text" placeholder="Tipo de operación" required disabled />
                        <label className="text-start font-acumin-variable" htmlFor="tipoOperacion">Tipo de cambio</label>
                    </div>
                </div>
                {(isReporto ||isReportoForzado) &&
                    <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                        <div className="form-floating ">
                            <input value={plazoDias} title="Plazo de días" className="form-control fs-5" name="tipoOperacion" type="text" placeholder="Tipo de operación" required disabled />
                            <label className="text-start font-acumin-variable" htmlFor="tipoOperacion">Plazo de días</label>
                        </div>
                    </div>
                }


                <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                    <div className="form-floating ">
                        <input value={tipoOperacion} title="Tipo de operación" className="form-control fs-5" name="tipoOperacion" type="text" placeholder="Tipo de operación" required disabled />
                        <label className="text-start font-acumin-variable" htmlFor="tipoOperacion">Tipo de operación</label>
                    </div>
                </div>

                <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                    <div className="form-floating ">
                        <input value={estatus} title="Estatus" className="form-control fs-5" name="statusOperacionReporto" type="text" placeholder="Estatus" required disabled />
                        <label className="text-start font-acumin-variable" htmlFor="statusOperacionReporto">Estatus</label>
                    </div>
                </div>

                {/*EMISION*/}
                <h4 className="text-royal-blue font-acumin-variable mt-3">Emisión</h4>
                <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                    <div className="form-floating ">
                        <input value={codigoEmision} title="Código de emisión" className="form-control fs-5" maxLength={20} name="codigoEmision" type="text" placeholder="Código de emisión" required disabled />
                        <label className="text-start font-acumin-variable" htmlFor="codigoEmision">Código de emisión</label>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                    <div className="form-floating ">
                        <input value={nombreEmisor} title="Código de emisión" className="form-control fs-5" maxLength={20} name="nombreEmisor" type="text" placeholder="Nombre de emisor" required disabled />
                        <label className="text-start font-acumin-variable" htmlFor="nombreEmisor">Nombre de emisor</label>
                    </div>
                </div>

                {/*SERIE*/}
                <h4 className="text-royal-blue font-acumin-variable mt-3">Serie</h4>
                <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                    <div className="form-floating ">
                        <input value={ISIN} title="Código ISIN" className="form-control fs-5" maxLength={20} name="isin" type="text" placeholder="Código ISIN" required disabled />
                        <label className="text-start font-acumin-variable" htmlFor="isin">ISIN</label>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                    <div className="form-floating ">
                        <input value={codigoSerie} title="Código de emisión" className="form-control fs-5" maxLength={20} name="codigoSerie" type="text" placeholder="Código de serie" required disabled />
                        <label className="text-start font-acumin-variable" htmlFor="codigoSerie">Código de serie</label>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                    <div className="form-floating ">
                        <input value={serie} title="Código de emisión" className="form-control fs-5" maxLength={20} name="serie" type="text" placeholder="Código de serie" required disabled />
                        <label className="text-start font-acumin-variable" htmlFor="serie">Serie</label>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                    <div className="form-floating ">
                        <input value={monedaSerie} title="Moneda de la serie" className="form-control fs-5" maxLength={20} name="monedaSerie" type="text" placeholder="Moneda de la serie" required disabled />
                        <label className="text-start font-acumin-variable" htmlFor="monedaSerie">Moneda de la serie</label>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                    <div className="form-floating ">
                        <input value={monedaLiquidacion} title="Moneda de liquidación" className="form-control fs-5" maxLength={20} name="monedaLiquidacion" type="text" placeholder="Moneda de liquidación" required disabled />
                        <label className="text-start font-acumin-variable" htmlFor="monedaLiquidacion">Moneda de liquidación</label>
                    </div>
                </div>

                {/*VALORES NEGOCIADOS*/}
                <h4 className="text-royal-blue font-acumin-variable mt-3">Valores negociados</h4>
                <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                    <div className="form-floating ">
                        <input value={cantidadValores} title="Cantidad de valores negociados" className="form-control fs-5" maxLength={20} name="valNegociadosCantidad" type="text" placeholder="Cantidad" required disabled />
                        <label className="text-start font-acumin-variable" htmlFor="valNegociadosCantidad">Cantidad de valores</label>
                    </div>
                </div>
                {(isCompraventa ||isCompraventaForzada)&&
                    <>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={montoTotal} title="Monto total a pagar" className="form-control fs-5" maxLength={20} name="montoTotal" type="text" placeholder="Monto total" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="montoTotal">Monto total a pagar</label>
                            </div>
                        </div>
                    </>
                }


                {(isReporto||isReportoForzado) &&
                    <>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={montoTotal} title="Monto total a pagar (inicio)" className="form-control fs-5" maxLength={20} name="montoTotalPagaraInicio" type="text" placeholder="Monto total a pagar (inicio)" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="montoTotalPagaraInicio">Monto total a pagar &#40;Inicio&#41;</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={montoTotalVencimiento} title="Monto total a pagar (Vencimiento)" className="form-control fs-5" maxLength={20} name="montoTotalPagarVencimiento" type="text" placeholder="Monto total a pagar (Vencimiento)" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="montoTotalPagarVencimiento">Monto total a pagar &#40;Recompra&#41;</label>
                            </div>
                        </div>
                    </>
                }


                {/*VENDEDOR Y COMPRADOR, O  REPORTADO Y REPORTADORR */}
                {(isCompraventa ||isCompraventaForzada) &&
                    <>
                        {/*VENDEDOR COMPRAVENTA*/}
                        <h4 className="text-royal-blue font-acumin-variable mt-3">Vendedor</h4>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={vendedorCodCasaBolsa} title="Código de la casa de bolsa" className="form-control fs-5" maxLength={20} name="codigoCasaBolsaVendedorCompraventa" type="text" placeholder="Código de la casa de bolsa" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="codigoCasaBolsaVendedorCompraventa">Código de casa de bolsa</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={vendedorCodClienteCehval} title="Código de cliente CEHVAL" className="form-control fs-5" maxLength={20} name="codigoClienteCehvalVendedorCompraventa" type="text" placeholder="Código de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="codigoClienteCehvalVendedorCompraventa">Código de cliente CEHVAL</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={vendedorNombreClienteCehval} title="Nombre de cliente CEHVAL" className="form-control fs-5" maxLength={20} name="nombreClienteCehvalVendedorCompraventa" type="text" placeholder="Nombre de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="nombreClienteCehvalVendedorCompraventa">Nombre de cliente CEHVAL</label>
                            </div>
                        </div>

                        {/*COMPRADOR COMPRAVENTA*/}
                        <h4 className="text-royal-blue font-acumin-variable mt-3">Comprador</h4>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={compradorCodCasaBolsa} title="Código de la casa de bolsa" className="form-control fs-5" maxLength={20} name="codigoCasaBolsaCompradorCompraventa" type="text" placeholder="Código de la casa de bolsa" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="codigoCasaBolsaCompradorCompraventa">Código de casa de bolsa</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={compradorCodClienteCehval} title="Código de cliente CEHVAL" className="form-control fs-5" maxLength={20} name="codigoClienteCehvalCompradorCompraventa" type="text" placeholder="Código de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="codigoClienteCehvalCompradorCompraventa">Código de cliente CEHVAL</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={compradorNombreClienteCehval} title="Nombre de cliente CEHVAL" className="form-control fs-5" maxLength={20} name="nombreClienteCehvalCompradorCompraventa" type="text" placeholder="Nombre de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="nombreClienteCehvalCompradorCompraventa">Nombre de cliente CEHVAL</label>
                            </div>
                        </div>

                    </>
                }

                {(isReporto || isReportoForzado)&&
                    <>
                        {/*VENDEDOR REPORTO*/}
                        <h4 className="text-royal-blue font-acumin-variable mt-3">Reportado</h4>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={vendedorCodCasaBolsa} title="Código de la casa de bolsa" className="form-control fs-5" maxLength={20} name="codigoCasaBolsaVendedorReporto" type="text" placeholder="Código de la casa de bolsa" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="codigoCasaBolsaVendedorReporto">Código de casa de bolsa</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={vendedorCodClienteCehval} title="Código de cliente CEHVAL" className="form-control fs-5" maxLength={20} name="codigoClienteCehvalVendedorReporto" type="text" placeholder="Código de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="codigoClienteCehvalVendedorReporto">Código de cliente CEHVAL</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={vendedorNombreClienteCehval} title="Nombre de cliente CEHVAL" className="form-control fs-5" maxLength={20} name="nombreClienteCehvalVendedorReporto" type="text" placeholder="Nombre de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="nombreClienteCehvalVendedorReporto">Nombre de cliente CEHVAL</label>
                            </div>
                        </div>

                        {/*COMPRADOR REPORTO*/}
                        <h4 className="text-royal-blue font-acumin-variable mt-3">Reportador</h4>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={compradorCodCasaBolsa} title="Código de la casa de bolsa" className="form-control fs-5" maxLength={20} name="codigoCasaBolsaCompradorReporto" type="text" placeholder="Código de la casa de bolsa" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="codigoCasaBolsaCompradorReporto">Código de casa de bolsa</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={compradorCodClienteCehval} title="Código de cliente CEHVAL" className="form-control fs-5" maxLength={20} name="codigoClienteCehvalCompradorReporto" type="text" placeholder="Código de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="codigoClienteCehvalCompradorReporto">Código de cliente CEHVAL</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={compradorNombreClienteCehval} title="Nombre de cliente CEHVAL" className="form-control fs-5" maxLength={20} name="nombreClienteCehvalCompradorReporto" type="text" placeholder="Nombre de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="nombreClienteCehvalCompradorReporto">Nombre de cliente CEHVAL</label>
                            </div>
                        </div>
                    </>
                }

               {/*} {isCompraventaForzada &&
                    <>
                    
                        <h4 className="text-royal-blue font-acumin-variable mt-3">Vendedor</h4>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={vendedorCodCasaBolsa} title="Código de la casa de bolsa" className="form-control fs-5" maxLength={20} name="codigoCasaBolsaVendedorCompraventa" type="text" placeholder="Código de la casa de bolsa" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="codigoCasaBolsaVendedorCompraventa">Código de casa de bolsa</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={vendedorCodClienteCehval} title="Código de cliente CEHVAL" className="form-control fs-5" maxLength={20} name="codigoClienteCehvalVendedorCompraventa" type="text" placeholder="Código de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="codigoClienteCehvalVendedorCompraventa">Código de cliente CEHVAL</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={vendedorNombreClienteCehval} title="Nombre de cliente CEHVAL" className="form-control fs-5" maxLength={20} name="nombreClienteCehvalVendedorCompraventa" type="text" placeholder="Nombre de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="nombreClienteCehvalVendedorCompraventa">Nombre de cliente CEHVAL</label>
                            </div>
                        </div>

                  
                        <h4 className="text-royal-blue font-acumin-variable mt-3">Comprador</h4>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={compradorCodCasaBolsa} title="Código de la casa de bolsa" className="form-control fs-5" maxLength={20} name="codigoCasaBolsaCompradorCompraventa" type="text" placeholder="Código de la casa de bolsa" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="codigoCasaBolsaCompradorCompraventa">Código de casa de bolsa</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={compradorCodClienteCehval} title="Código de cliente CEHVAL" className="form-control fs-5" maxLength={20} name="codigoClienteCehvalCompradorCompraventa" type="text" placeholder="Código de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="codigoClienteCehvalCompradorCompraventa">Código de cliente CEHVAL</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={compradorNombreClienteCehval} title="Nombre de cliente CEHVAL" className="form-control fs-5" maxLength={20} name="nombreClienteCehvalCompradorCompraventa" type="text" placeholder="Nombre de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="nombreClienteCehvalCompradorCompraventa">Nombre de cliente CEHVAL</label>
                            </div>
                        </div>

                    </>
                }*/}

            </div>
            {componenteExtra &&
                <>
                    
                    <h4 className="text-royal-blue font-acumin-variable mt-3">Información para liquidación de fondos</h4>
                    <div className="row mt-4">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={nombreClienteCehval} title="Nombre del cliente" className="form-control fs-5" name="codigoCasaBolsaCompradorReporto" type="text" placeholder="Código de la casa de bolsa" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="codigoCasaBolsaCompradorReporto">Nombre de cliente CEHVAL</label>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 col-xl-8 mb-3">
                            <div className="form-floating ">
                                <input value={direccion} title="Dirección" className="form-control fs-5" name="codigoClienteCehvalCompradorReporto" type="text" placeholder="Código de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="codigoClienteCehvalCompradorReporto">Dirección</label>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={tipoCuenta} title="Tipo de cuenta" className="form-control fs-5" name="nombreClienteCehvalCompradorReporto" type="text" placeholder="Nombre de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="nombreClienteCehvalCompradorReporto">Tipo de cuenta</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={rtn} title="RTN" className="form-control fs-5" name="nombreClienteCehvalCompradorReporto" type="text" placeholder="Nombre de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="nombreClienteCehvalCompradorReporto">RTN</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={banco} title="Banco" className="form-control fs-5" name="nombreClienteCehvalCompradorReporto" type="text" placeholder="Nombre de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="nombreClienteCehvalCompradorReporto">Banco</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={cuentaBCH} title="Cuenta en BCH-TR" className="form-control fs-5" name="nombreClienteCehvalCompradorReporto" type="text" placeholder="Nombre de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="nombreClienteCehvalCompradorReporto">Cuenta en BCH-TR</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <div className="form-floating ">
                                <input value={montoTotalAPagarliquidacion} title="Monto total a pagar" className="form-control fs-5" name="nombreClienteCehvalCompradorReporto" type="text" placeholder="Nombre de cliente CEHVAL" required disabled />
                                <label className="text-start font-acumin-variable" htmlFor="nombreClienteCehvalCompradorReporto">Monto total a pagar</label>
                            </div>
                        </div>
                        {isCompraventaForzada &&
                            <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                                <div className="form-floating ">
                                    <input value={difPrecioContraCompraVIncumplida} title="Diferencia de precio contra la compraventa incumplida" className="form-control fs-5" name="diferenciaPrecioCompraventaIncumplida" type="text" placeholder="Diferencia de precio contra la compraventa incumplida" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="diferenciaPrecioCompraventaIncumplida">Diferencia de precio contra la compraventa incumplida</label>
                                </div>
                            </div>
                        }

                        {botonComponenteExtra &&
                            <div className="col-12 col-md-12 col-lg-12 col-xl-4 mb-3 d-flex justify-content-xl-end justify-content-md-end justify-content-sm-end ">
                                <button type="button" onClick={funcion3} className="btn background-royal-blue text-white bg-hover-gold">Fondos liquidados</button>
                            </div>
                        }
                    </div>

                </>
            }

            <div className="row mt-4">
                {botones &&
                    <div className="col-12 col-md-12 d-flex justify-content-xl-end justify-content-md-end justify-content-sm-end my-4">
                        <button type="button" onClick={funcion1} className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1 me-md-2 me-sm-2 me-1 background-royal-blue text-white bg-hover-gold">Regresar</button>
                        <button type="button" onClick={funcion2} className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1 background-royal-blue text-white bg-hover-gold">Imprimir</button>
                        {/*}  {!isRegistered &&
                            <>
                                <button type="button" onClick={funcion1} className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1 me-md-2 me-sm-2 me-1 background-royal-blue text-white bg-hover-red">Cancelar</button>
                                <button type="button" onClick={funcion2} className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1 background-royal-blue text-white bg-hover-gold">Registrar</button>
                            </>
                        }*/}

                        {/*}  {isRegistered &&
                            <>
                                <button type="button" onClick={funcion3} className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1 me-md-2 me-sm-2 me-1 background-royal-blue text-white bg-hover-red">Regresar</button>
                                <button type="button" onClick={funcion4} className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1 background-royal-blue text-white bg-hover-gold">Imprimir</button>

                                <>
                                    <button type="submit" className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1 background-royal-blue text-white bg-hover-gold">Imprimir</button>
                                 </>
                            </>
                        }*/}
                    </div>}
            </div>




        </>
    )
}