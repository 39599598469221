import { useMemo } from "react";

export default function useCuentaValoresPrendasGarantias() {
  const columns = useMemo(
    () => [
      {
        Header: "Folio",
        accessor: "constitucionGarantiaFolio"
      },
      {
        Header: "Código del depositante garante de los valores",
        accessor: "codigoDepositantePropietario"
      },
      {
        Header: "Nombre del depositante garante de los valores",
        accessor: "nombreDepositantePropietario"
      },
      {
        Header: "Beneficiario",
        accessor: "beneficiarioGarantia"
      },
      {
        Header: "Nombre de la emisión",
        accessor: "nombreEmision"
      },
      {
        Header: "Serie",
        accessor: "nombreSerie"
      },
      {
        Header: "ISIN",
        accessor: "isinSerie"
      },
      {
        Header: "Moneda",
        accessor: "monedaSerie"
      },
     
      {
        Header: "Cantidad valores en garantía",
        accessor: "cantidadValores"
      },
      
    ],
    []
  );

  return columns;
}
