//React
import React, {useState, useEffect} from "react";

//Componentes generales
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores';
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";


//APIs
import { traerSolicitudesDeLiberacion } from "../../../../api/prendasYGarantias/SolicitudesDeLiberacionGarantia/traerSolicitudesDeLiberacion";
import { actualizarEstatusDeSolicitudLiberacion } from '../../../../api/prendasYGarantias/SolicitudesDeLiberacionGarantia/actualizarEstatusSolicitudLiberacion';


//Hooks
import useSolicitudesLiberacionGarantia from '../../../../hooks/PrendasYGarantias/useSolicitudesLiberacionGarantia';

//Componnetes de la tabla
import TablaUsuarios from "../../../../ComponentesGenerales/TablaUsuarios";


export default function NotificarLiberacionesDeGarantia({ history }) {


    const [copia, setCopia] = useState();
    const [data, setData] = useState([]);
    const [spin, setSpin] = useState(true);
    const [erroreshttp, setErroreshttp] = useState();
    const [notificacionExitosa, setNotificacionExitosa] = useState();
    const [notificacionFallida, setNotificacionFallida] = useState();
    const [status, setStatus] = useState();


    const [tituloNotificaciones, setTituloNotificaciones] = useState();
    const [requestResponse, setRequestResponse] = useState();

    //Shows loading screen
    const [loading, setLoading] = useState();

    useEffect(() => {
        getConstitutionRequests();
    }, [])


    //Get authorized or rejected constitution requests depending on the pathname
    const getConstitutionRequests = async () => {

        try {
            let actualPathname = window.location.pathname;
            

            if (actualPathname === "/notificarLiberacionesGarantiaAceptadas") {
                setStatus(3);
                setTituloNotificaciones("Solicitudes autorizadas de liberación o ejecución de prenda o garantía por notificar");
                
                const getAuthorizedRequests = await traerSolicitudesDeLiberacion({ estatus: 2 });
                //console.log(getAuthorizedRequests)

               

                
                if (getAuthorizedRequests) {
                    setSpin(false)
                    if ((isNaN(getAuthorizedRequests.error)) === false) {
                        setErroreshttp(getAuthorizedRequests)
                    } else {
                        setData(getAuthorizedRequests.data);
                        setCopia(getAuthorizedRequests.data)
                    }
                }

            } else if (actualPathname === "/notificarLiberacionesGarantiaRechazadas") {
                setStatus(4);
                setTituloNotificaciones("Solicitudes rechazadas de liberación o ejecución de prenda o garantía por notificar");
                
                const getRejectedRequests = await traerSolicitudesDeLiberacion({ estatus: 0 });
                //console.log(getRejectedRequests)
                
                
                if (getRejectedRequests) {
                    setSpin(false)
                    if ((isNaN(getRejectedRequests.error)) === false) {
                        setErroreshttp(getRejectedRequests)
                    } else {
                        setData(getRejectedRequests.data);
                        setCopia(getRejectedRequests.data)
                    }
                }
            }

        } catch (error) {
            console.error(error)
        }
    }

    //Envia notificación de que la solicitud fue autorizada o rechazada
    const notify = async (row) => {
       
        const obj = {
            folio: row.original.cehvalSolicitudLiberacionGarantiaFolio,
            estatus: status,
        }

        setLoading(true);
        const response = await actualizarEstatusDeSolicitudLiberacion(obj);
   
        if (response) {
            setLoading(false);
            //setCargando(false)
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(response)
            } else {
                //console.log(response)
                if(response.data.isSuccessful === true){
                    setLoading(false);
                    setRequestResponse("Notificación enviada");
                    setNotificacionExitosa(true);
        
                } else {
                    setLoading(false);
                    setRequestResponse("No fue posible hacer la notificación, inténtelo de nuevo más tarde");
                    setNotificacionFallida(true);
                }
                ////console.log(response)
            }
        }      
    }


   const confirmModal = () => {
        setNotificacionExitosa(false);
        window.location.reload();
   }

    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {notificacionExitosa &&
                <ComponenteModal mostrarModal={notificacionExitosa} cerrarModal={() => confirmModal()} titulo={requestResponse} textoBoton="Aceptar"></ComponenteModal>
            }
            {notificacionFallida &&
                <ComponenteModal mostrarModal={notificacionFallida} cerrarModal={() => setNotificacionFallida(false)} titulo={requestResponse} textoBoton="Aceptar"></ComponenteModal>

            }
            <div className="">
               {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }     
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo={tituloNotificaciones}></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaUsuarios datos={data} displayInformation={notify} useValidate={useSolicitudesLiberacionGarantia} textoBoton={"Notificar"} textTitle="Click para notificar"></TablaUsuarios>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    );
}

