import { useMemo } from "react";

export default function useColumns() {
 const columns = useMemo(
   () => [   
     {
       Header:"Código emisión",
       accessor: "cehvalEmisionCodigo"
     },
     {
       Header: "Nombre emisión",
       accessor: "cehvalEmisionNomEmi"
     },
     {
       Header: "Número de resolución",
       accessor: "cehvalEmisionNumeroResol"
     }, 
    
      {
        Header: "Series",
        accessor: "cehvalEmisionSeries"
      },
      {
        Header: "Monto",
        accessor: "cehvalEmisionMonto"
      },
      {
        Header: "Representación",
        accessor: "cehvalEmisionDesmaterializada"
      },
     
   ],
   []
 );

 return columns;
}