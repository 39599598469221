
//React
import React, { useState, useEffect } from "react";


//Componentes generales
import ManejadorErrores from '../../../ComponentesGenerales/ManejadorErrores';
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";

//endpoints
import { traerRoles } from "../../../api/catalogos/traerRoles";
import { agregarRolAUsuario } from "../../../api/Permisos/agregarRolAUsuario";
import { consultarUsuariosPorEstatus } from "../../../api/altaBajaUsuarios/consultarUsuariosPorEstatus";



export default function AgregarRolUsuario({ history }) {
    //Inputs where user will enter a value
    const [nombreUsuario, setNombreUsuario] = useState();
    const [usuarios, setUsuarios] = useState([]);

    //Roles
    const [roles, setRoles] = React.useState([])
    const [rol, setRol] = React.useState()
    const [procesoExitoso, setProcesoExitoso] = React.useState()
    const [procesoFallido, setProcesoFallido] = React.useState()
    const [mensaje, setMensaje] = React.useState()


    //Spinner
    const [loading, setLoading] = useState();

    //States to handle load data when loading page 
    const [erroreshttp, setErroreshttp] = useState();

    useEffect(() => {
        traerCatalogoDeRoles()
    }, []);

    const traerCatalogoDeRoles = async () => {
        //traer Catalogos de roles
        const catalogosRoles = await traerRoles()
        // //console.log(catalogosRoles)
        if (catalogosRoles) {
            if ((isNaN(catalogosRoles.error)) === false) {
                setErroreshttp(catalogosRoles)
            } else {

                const filtrados = catalogosRoles.filter(word => (word.cehvalRolNombre !== "ACCESO_ISIN") && (word.cehvalRolNombre !== "ACCESO_SIST_INFO"))
                setRoles(filtrados)
            }
        }

        const response = await consultarUsuariosPorEstatus({
            estatus: 1
        })

        //console.log(response)

        if (response) {
            if ((isNaN(response.error)) === false) {

                setErroreshttp(response)
            } else {
                setUsuarios(response)
            }
        }

    }


    const onSubmitForm = async (e,) => {
        setLoading(true);
        e.preventDefault();
        //console.log(nombreUsuario)
        //console.log(rol)
        const obj = {
            usuario: nombreUsuario,
            rol: rol
        }

        const response = await agregarRolAUsuario(obj);
        setLoading(false);
        //console.log(response);

        if (isNaN(response.error) === false) {
            setErroreshttp(response);

        } else {
            if (response.codigo === "000") {
                setMensaje("Rol agregado")
                setProcesoExitoso(true)
            } else {
                setMensaje(response.mensaje)
                setProcesoFallido(true)
            }
        }
    }




    return (
        <>
            {loading &&
                <PantallaCargando />
            }

            {procesoExitoso &&
                <ComponenteModal mostrarModal={procesoExitoso} cerrarModal={() => history.go(-1)} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {procesoFallido &&
                <ComponenteModal mostrarModal={procesoFallido} cerrarModal={() => setProcesoFallido(false)} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <div className="fluid-container">
                <main className="min-height-40">
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Agregar rol a usuario"></BannerTitulo>
                                <div className="row mt-3 text-royal-blue g-0" >
                                    <div className="col-lg-12 col-md-12 ">
                                        <div className="container  ">
                                            <div className="row mb-3 justify-content-center">
                                                <div className="col-lg-10 col-md-11 col-sm-12 col-xs-12">
                                                    <div className=" card " style={{ color: "#242848" }}>
                                                        <div className="card-body font-acumin-variable">
                                                            <form onSubmit={onSubmitForm}>
                                                                <div className="row mt-4">
                                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                                        <div className="form-floating">
                                                                            <select title="Usuarios" className="form-select font-acumin-variable" onChange={(e) => setNombreUsuario(e.target.value)} name="Usuarios" style={{ height: "56px" }} aria-label="Default select example" required >
                                                                                <option value=""> </option>
                                                                                {
                                                                                    usuarios.map((item, index) => (
                                                                                        <option key={index} value={item.usuario} >{item.usuario}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                            <label className="font-acumin-variable" htmlFor="Usuarios">* Nombre de usuario</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 d-flex justify-content-center mb-3">
                                                                        <div className=" col-12 col-md-12 ">
                                                                            <div className="form-floating">
                                                                                <select title="roles para agregar" className="form-select font-acumin-variable" onChange={(e) => setRol(e.target.value)} name="roles" style={{ height: "56px" }} aria-label="Default select example" required >
                                                                                    <option value=""> </option>
                                                                                    {
                                                                                        roles.map((item, index) => (
                                                                                            <option key={index} value={item.cehvalRolNombre} >{item.cehvalRolNombre}</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                                <label className="font-acumin-variable" htmlFor="roles">* Rol</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-10 d-flex align-items-center">
                                                                        <button className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable" type="submit">Agregar</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>


        </>
    );
}