import React, { useState } from "react";
import { useHistory } from "react-router-dom";

//Componentes
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
//import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";


//Hooks
import useOperacionesMS from "../../../hooks/MercadoSecundario/useOperacionesMS";

//API's
import { lecturaArchivoOperacionesMS } from "../../../api/MercadoSecundario/lecturaArchivoOperacionesMS";
import { registrarOperacionesMS } from "../../../api/MercadoSecundario/registrarOperacionesMS";



//Muestra las cuentas de un depositante indirecto
export default function CargarInformacionOperacionesMS({ history }) {

    //const dataAlreadyLoaded = history.location.state;

    //If endpoint got the data will be true
    const [getOperationDara, setGetOperationData] = useState(false);
    const [getOperationDara2, setGetOperationData2] = useState(false);
    const [datosPreviamenteCargados, setDatosPreviamenteCargados] = useState(false);

    //Stores the json with emisors  
    const [operacionesData, setOperacionesData] = useState([]);

    //The name of the users file
    const [nameFile, setNameFile] = useState();
    //The file
    const [file, setFile] = useState(undefined);
    //Shows the loading screen when y set to true
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState();
    const [invalidFile, setInvalidFile] = useState(false);
    const [erroreshttp, setErroreshttp] = useState();
    //const [cargando, setCargando] = useState()
    const [registroOperacionesExitoso, setRegistroOperacionesExitoso] = useState(false)
    //const [mensajeRegistro, setMensajeRegistro] = useState()
    const [bodyMensaje, setbodyMensaje] = useState()
    const [archivoCargado, setArchivoCargado] = useState()


    
    //1 es compraventa en tipo
    //2 es reporto


    const [forzadas, setForzadas] = useState(false);



    React.useEffect(() => {
        viewAccounts();
    }, []);

    const viewAccounts = async () => {
        try {
            const dataAlreadyLoaded = history.location.state;
            //console.log("recargar")
            //console.log(dataAlreadyLoaded);

            if (dataAlreadyLoaded === undefined) {
                setDatosPreviamenteCargados(false)
            } /*else if (dataAlreadyLoaded.emisionesFiltradas.length === 0) {

                setDatosPreviamenteCargados(false)
            }*/ else {

                setDatosPreviamenteCargados(true);
                setOperacionesData(dataAlreadyLoaded.operaciones);
            }
            setGetOperationData(false);
            setGetOperationData2(false);
        } catch (err) {
            console.error(err);
        }
    }

    //Open input file when click on h4 text
    const handleInputFile = (e) => {
        e.preventDefault();
        //Select our input tag 
        const input = document.querySelector("#input-file");
        //Give the event click to our input
        input.click();
    }

    // HANDLE WHEN USER SELECT A FILE WHEN CLICK ON TEXT
    const selectFile = (e) => {
        //Cleans error message if was one
        setError("");
        setGetOperationData(false);

        const file = e.target.files;
        //Name of the file to validate if has the correct extension
        const fileName = e.target.files[0].name;
        const fileSize = e.target.files[0].size;

        //Regex to validate the extension of the file
        const extensionsAllow = /(.xlsx)$/i;

        ////console.log(pathFile.files)
        //If selected file doesnt have correct extension show user error message
        if (!extensionsAllow.exec(fileName)) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file
            setError("Selecciona un archivo válido");

            //Clean our states if they had any information
            setFile("");
            setNameFile("");
            return false;


        } else if (fileSize > 10_000_000) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file
            setError("El tamaño del archivo debe ser menor a 10 MB");
            //Clean file name if a file was selected before
            setNameFile("");
            //Clean state if a file was selected before
            setFile("");

        }
        else if (e.target.files && e.target.files[0]) {
            //Shows the name of the selected file to the user
            setNameFile(fileName);
            //Set FileList [File] to our state
            setFile(file);

        }
    }

    // HANDLE USER WHEN DROP FILE
    const dropFile = (e) => {
        e.preventDefault();
        //Cleans error message if was one
        setError("");
        setGetOperationData(false);
        //Removes the active class from container when user drops a file
        document.querySelector(".drop-area").classList.remove("active");
        //Shows the same text before dropping a file
        const inputField = document.querySelector('#text-click-uploadfile');
        inputField.innerHTML = "Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro";

        //Show user the selected file and validates in codition if file has a valid extension 
        const fileName = e.dataTransfer.files[0].name;
        const fileSize = e.dataTransfer.files[0].size;
        //Regex to validate file extension
        const extensionsAllow = /(.xlsx)$/i;

        if (!extensionsAllow.exec(fileName)) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file
            setError("Selecciona un archivo válido");
            setFile("");
            setNameFile("");
            return false;

        } else if (fileSize > 10_000_000) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file
            setError("El tamaño del archivo debe ser menor a 10 MB");
            //Clean file name if a file was selected before
            setNameFile("");
            //Clean state if a file was selected before
            setFile("");

        } else if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            //Shows the name of the selected file to the user
            setNameFile(fileName);
            //Set FileList [File] to our state
            setFile(e.dataTransfer.files);
        }
    }

    //Handle when user drag over a file 
    const dragOver = (e) => {
        e.preventDefault();
        //Adds the active class to container to change the background
        const moveOver = document.querySelector(".drop-area");
        moveOver.classList.add("active");
        //Change the text inside box
        const inputField = document.querySelector('#text-click-uploadfile');
        inputField.innerHTML = "Suelte el archivo";
    }

    //Handle when user leaves the drop area whitout drop any file
    const dragLeave = (e) => {
        e.preventDefault();
        //Removes the active class to container to change the background
        const moveLeave = document.querySelector(".drop-area");
        moveLeave.classList.remove("active");
        //Change the text inside box 
        const inputField = document.querySelector('#text-click-uploadfile');
        inputField.innerHTML = "Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro";
    }

    // HANDLE WHEN USER CLICK BUTTON TO READ THE FILE
    const readFile = async (e) => {
        //console.log("leer archivo")
        e.preventDefault();
        //Shows loading screen until get the response



        try {
            //Shows error massage when user doesnt select any file and click on button
            if (file === undefined) {
                setError("Por favor seleccione un archivo");
                return;
            }
            //If file is not the right type of file doesnt allow user to click "leer registros"
            if (file[0] === undefined || file[0] === "" || file[0] === null || file[0] === String) {
                setError("Por favor seleccione un archivo");
                //console.log("no hay archivo")
            } else {
                setLoading(true);
                //Clean error message if showed 
                setError("");
                //Make the query to data base to read the file and return the json



                let formFile = new FormData();
                formFile.append('file', file[0]);
                const response = await lecturaArchivoOperacionesMS(formFile);
                //console.log(response.data)
                if (response) {
                    setLoading(false);
                    if ((isNaN(response.error)) === false) {
                        // //console.log("erores")
                        ////console.log(response)
                        setLoading(false);
                        setErroreshttp(response)
                        
                    } else {
                        if (response.data.estatusValidacion === 1) {
                            setArchivoCargado(true)
                            var mensajeRespuesta;
                            if (response.data.mensaje) {
                                mensajeRespuesta = response.data.mensaje
                            } else {
                                mensajeRespuesta = "El documento cargado no trae información relacionada a mercado primario, intente con otro"
                            }
                            var mensajeSplit = mensajeRespuesta.split("*")
                            setbodyMensaje(mensajeSplit)

                        }
                        else {
                            //When await gets the data hides loading screen

                            setArchivoCargado(true)
                            const mensajeRespuesta = response.data.mensaje
                            var mensajeSplit = mensajeRespuesta.split("*")

                            setbodyMensaje(mensajeSplit)
                            setLoading(false);
                            setOperacionesData(response.data)
                            setGetOperationData(true)
                            setLoading(false);

                        }


                    }
                }
            }

        } catch (error) {
            console.error(error);
        }
    }

    const previewSelectedEmisor = (row) => {
        // //console.log(row.original)
        const emision = {
            cehvalNombreEmision: row.original.descripción,
            cehvalCodigoEmision: row.original.codigoEmision,
            cehvalEstatus: 5,//row.original.cehvalEmisionEstatus,
            cehvalNombreEmisor: "",//row.original.rco,
            cehvalCodigoEmisor: row.original.codigoEmisor,
            cehvalMonto: row.original.montoDeLaEmision,
            cehvalEmisionSeries: row.original.series,
            cehvalEmisionNumeroResol: row.original.numResolucionRPMV,
            cehvalEmisionFechaRpmv: row.original.fechaRegistro,
            cehvalEmisionRepresentanteComunObl: row.original.rco,
            cehvalEmisionTipoMoneda: row.original.monedaDeLaEmision,
            cehvalEmisionDesmaterializada: row.original.formaDeRepresentacion,
            titulo: "Registrar emisión",
            ruta: "/registroEmisionesBCV"
        }


        //  const codigoEmisor = row.original.emisorCodigo;

        //const objEmisorRow = operacionesData.filter(word => word.emisorCodigo === codigoEmisor);

        history.push({
            pathname: '/operacionesMS', state: { objMenu: emision, objEmisiones: operacionesData }
        })


    }

    const irDevuelta = () => {
        setGetOperationData(false);
        setGetOperationData2(false)
        setDatosPreviamenteCargados(false)
    }

  

    const vistaPrevia = async (row) => {
        //console.log(row.original)

        const objetoVariablesBooleanas = {
            botones: true,
            componenteExtra: false,
            botonComponenteExtra: false,
            ruta: '/cargarInformacionOperacionesMS'
        }
        history.push({
            pathname: '/informacionOperacionMS', state: { objMenu: row.original, operacionesInfo: operacionesData, tipoProcedencia: 1, objetoBooleano: objetoVariablesBooleanas }

        })


        /*  const response = await reporteDDPJ(myobj)
          const file = new Blob(
              [response],
              { type: 'application/pdf' });//Build a URL from the file
          const fileURL = URL.createObjectURL(file);//Open the URL on new Window
          window.open(fileURL);
          setLoading(true)
  
          history.push({
              pathname: '/'
          })*/

    }

    const registrarOperaciones = async (row) => {
        ////console.log(operacionesData.operaciones)
        // //console.log("registrarOperaciones")
        setLoading(true)

        const response = await registrarOperacionesMS(operacionesData);
        //console.log(response)
        if (response) {

            setLoading(false)
            if ((isNaN(response.error)) === false) {
              
                setErroreshttp(response)
                

                //Cuando tiene operaciones forzadas
            } else if (response.forzadasExistentes === 1) {

                const mensajeRespuesta = response.mensaje;
                let mensajeSplit = mensajeRespuesta.split("*");
                setbodyMensaje(mensajeSplit);
                setForzadas(response.mensaje);

                //Cuando no tiene operaciones forzadas
            } else if (response.forzadasExistentes === 0) {

                // const mensajeRespuesta="-Operación con folio AAAAA registrada con éxito.*-Operación con folio BBBBB registrada con éxito.*-Operación con folio BBBBB registrada con éxito."
                const mensajeRespuesta = response.mensaje
                var mensajeSplit = mensajeRespuesta.split("*")
                //console.log(mensajeSplit)
                //setMensajeRegistro()
                setbodyMensaje(mensajeSplit)
                setOperacionesData(response)

                if (getOperationDara) {
                    setGetOperationData(false)
                    setGetOperationData2(true)
                } else {
                    setGetOperationData(true)
                    setGetOperationData2(false)
                }
                setRegistroOperacionesExitoso(true)
            }
        }
    }

    const handleForzadas = () => {
        window.location.replace("/asociarOperacionesForzadas");
    }


    return (
        <>

            {forzadas &&
                <ComponenteModal mostrarModal={forzadas} cerrarModal={handleForzadas} body={bodyMensaje} textoBoton="Aceptar"></ComponenteModal>
            }
            {archivoCargado &&
                <ComponenteModal mostrarModal={archivoCargado} cerrarModal={() => { setArchivoCargado(false) }} body={bodyMensaje} textoBoton="Aceptar"></ComponenteModal>
            }
            {registroOperacionesExitoso &&
                <ComponenteModal mostrarModal={registroOperacionesExitoso} cerrarModal={() => { setRegistroOperacionesExitoso(false) }} body={bodyMensaje} textoBoton="Aceptar"></ComponenteModal>
            }

            {loading &&
                <PantallaCargando />
            }

            


            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {invalidFile &&
                <ComponenteModal mostrarModal={invalidFile} cerrarModal={() => { setInvalidFile(false) }} titulo="Archivo inválido. Por favor seleccione un archivo .xlsx y con un peso menor a 10 MB" textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="fluid-container">

                <main className="mb-5">
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 mb-4">
                            {datosPreviamenteCargados &&
                                <>
                                    <BannerTitulo titulo="Operaciones"></BannerTitulo>
                                    <div className="container py-4">
                                        <TablaMercado displayInformation={vistaPrevia} textoBoton="Vista previa" datos={operacionesData.operaciones} useValidate={useOperacionesMS} textTitle="Click para ver información" mercadoPrimario={true} funcion1MercadoPrimario={registrarOperaciones} texto1MercadoPrimario="Registrar"  ></TablaMercado>
                                        <div className="col-12 row justify-content-star mt-3 ms-3">
                                            <div className="col-6 mt-1 g-0">
                                                <button onClick={irDevuelta} className="btn background-royal-blue bg-hover-gold text-white font-acumin-variable">Regresar</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                            {getOperationDara && !datosPreviamenteCargados &&
                                <>
                                    <BannerTitulo titulo="Operaciones"></BannerTitulo>
                                    <div className="container py-4">
                                        <TablaMercado displayInformation={vistaPrevia} textoBoton="Vista previa" datos={operacionesData.operaciones} useValidate={useOperacionesMS} textTitle="Click para ver información" mercadoPrimario={true} funcion1MercadoPrimario={registrarOperaciones} texto1MercadoPrimario="Registrar"  ></TablaMercado>
                                        <div className="col-12 row justify-content-star mt-3 ms-3">
                                            <div className="col-6 mt-1 g-0">
                                                <button onClick={irDevuelta} className="btn background-royal-blue bg-hover-gold text-white font-acumin-variable">Regresar</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                            {getOperationDara2 && !datosPreviamenteCargados &&
                                <>
                                    <BannerTitulo titulo="Operaciones"></BannerTitulo>
                                    <div className="container py-4">
                                        <TablaMercado displayInformation={vistaPrevia} textoBoton="Vista previa" datos={operacionesData.operaciones} useValidate={useOperacionesMS} textTitle="Click para ver información" mercadoPrimario={true} funcion1MercadoPrimario={registrarOperaciones} texto1MercadoPrimario="Registrar"  ></TablaMercado>
                                        <div className="col-12 row justify-content-star mt-3 ms-3">
                                            <div className="col-6 mt-1 g-0">
                                                <button onClick={irDevuelta} className="btn background-royal-blue bg-hover-gold text-white font-acumin-variable">Regresar</button>
                                            </div>
                                        </div>
                                    </div >

                                </>
                            }

                            {
                                !getOperationDara && !getOperationDara2 && !datosPreviamenteCargados &&
                                <>
                                    <BannerTitulo titulo="Cargar archivo .xlsx con la información de las operaciones de mercado secundario"></BannerTitulo>
                                    <div className="row justify-content-center align-items-center g-0">
                                        <form>
                                            <div className="col-12 row justify-content-center g-0">
                                                <div id="fileDropArea" onDragOver={dragOver} onDragLeave={dragLeave} onDrop={(e) => dropFile(e)} className="col-11 col-sm-10 col-md-8 col-lg-5 drop-area row row-wrap justify-content-center align-items-center border-dashed py-5 px-2 rounded">
                                                    <i className="ri-upload-2-fill ri-3x text-center text-royal-blue"></i>
                                                    <h4 id="text-click-uploadfile" onClick={handleInputFile} className="text-center font-acumin-variable text-royal-blue">Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro</h4>
                                                    <input id="input-file" type="file" onChange={(e) => selectFile(e)} hidden required />
                                                </div>
                                            </div>

                                            <div className="col-12 row justify-content-center g-0">
                                                <div id="preview-file" className="col-11 col-sm-10 col-md-8 col-lg-5  row justify-content-center g-0 mt-1">
                                                    <span className="text-royal-blue font-acumin-variable">Seleccione un archivo excel <i>.xlsx</i></span>
                                                    {nameFile &&
                                                        <p className="text-royal-blue text-success font-acumin-variable fs-5">Archivo válido:<i> {nameFile}</i></p>
                                                    }
                                                    {error &&
                                                        <p className="text-danger font-acumin-variable fs-5">{error}</p>
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-12 row justify-content-center g-0">
                                                <div className="col-11 col-sm-10 col-md-8 col-lg-5 mt-1 g-0">
                                                    <button onClick={readFile} className="btn background-royal-blue bg-hover-gold text-white font-acumin-variable">Leer registros</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div >
                                </>
                            }


                        </div >
                    </div >
                    {loading &&
                        <PantallaCargando />
                    }
                </main >

            </div >
        </>
    );
}


