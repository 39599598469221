import { instance } from '../apiGlobal';

export async function consultarSubastasLiquidadasOperadorDD(obj) {
      try {
          const response = await instance(`/sistinfo/operaciones/mp/depositante`, {
          method:"post",
          data:obj
        })
        return response.data
      } catch (error) {
        return error
      }
}