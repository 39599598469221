import axios from 'axios'

export const instance = axios.create({
  /*test */
 // baseURL: `${process.env.REACT_APP_url_test}`,

  /*Desarrollo*/
  //baseURL: `${process.env.REACT_APP_url_desarrollo}`,

  /*produccion*/
   baseURL: `${process.env.REACT_APP_url_produccion}`,

   headers: {
    "Content-Type": "application/json",
  },
});

//instancia principal para todas las apis menos las bitacoras
//aqui metemos el token en todas las peticiones que hacemos
instance.interceptors.request.use(function (config) {
  const token = window.sessionStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    //window.location.href = '/login'
  }
  return config;
});

//aqui interceptamos la respuesta del as peticiones, si hay un error lo retornamos
instance.interceptors.response.use(
 
  (response) => response,
  (err) => {

    ////console.log(err)
    if (!err.response) {
      // //console.log("entra ala error")
      return Promise.reject({
        status: 999,
        timestamp: new Date(),
        message: "se ha produciod un error inesperado",
        error: 1
      });
    }

    return Promise.reject({
      message: "se ha producido un error",
      error: err.response.status
    }
    );
  }
);


//servicio de bitacora de operaciones
export const instanciaBitacora = axios.create({
 //  baseURL: "https://rw1845z0ok.execute-api.us-east-1.amazonaws.com/",
   baseURL: `${process.env.REACT_APP_url_bitacora_operaciones}`,

 // baseURL: "http://10.1.4.248:8082/",//alexis 
  headers: {
    "Content-Type": "application/json",
  },

})

//metemos el token en todas las peticiones para la bitácora de operaciones
instanciaBitacora.interceptors.request.use(function (config) {
  const token = window.sessionStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    //window.location.href = '/login'
  }
  return config;
});

//interceptamos la respuesta de las peticiopnes de la bitacora de operaciones y regresamos el error en caso de que exista
instanciaBitacora.interceptors.response.use(
  (response) => response,
  (err) => {
    ////console.log(err)
    if (!err.response) {
      // //console.log("entra ala error")
      return Promise.reject({
        status: 999,
        timestamp: new Date(),
        message: "se ha produciod un error inesperado",
        error: 1
      });
    }

    return Promise.reject({
      message: "se ha producido un error",
      error: err.response.status
    }
    );
  }
);


//servicio de bitacora de sistema
export const instanciaBitacoraSistema = axios.create({
  //baseURL: "https://3da0qgpae9.execute-api.us-east-1.amazonaws.com/",
  baseURL: `${process.env.REACT_APP_url_bitacora_sistema}`,
  headers: {
    "Content-Type": "application/json",
    // 'Authorization': 'Bearer '+ "eyJraWQiOiJNQ3BwdE5HYTRsNnZydmo3ZTE4VENlR1llVHF5Ym1JTkZENmVhTnZWcDdNPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJiODY5NWIyZi0zYzQ5LTQ0YWQtYjIyZi03OTkwOTk0OGMzMWIiLCJjb2duaXRvOmdyb3VwcyI6WyJPUEVSQURPUl9DRUhWQUwiXSwiZW1haWxfdmVyaWZpZWQiOnRydWUsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX0JXdGFZQUVKbyIsImNvZ25pdG86dXNlcm5hbWUiOiJqb3NlfHNhbnRvc3w0bnxjb218bXgiLCJvcmlnaW5fanRpIjoiZmJjZGNkOTUtYzE2Ny00MTZjLTliYTMtMzk4Mjc2ZWM5N2FjIiwiYXVkIjoiNmI2Z3QzdnRzaWJjbWpubDRkbWJtOW40ZW0iLCJldmVudF9pZCI6IjVlYWY5NDQ0LTdkOTEtNGVlMy1iM2JlLWFhNTE2ZmUxYTA5OSIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjY1NzY5NTc3LCJleHAiOjE2NjU3ODY3NDksImlhdCI6MTY2NTc4MzE0OSwianRpIjoiMmUyYjAxNjUtMTZmOS00NjE1LWI2ZDgtOGZiNmRlYWRiMDVlIiwiZW1haWwiOiJqb3NlLnNhbnRvc0A0bi5jb20ubXgifQ.fLqee_jecJYAgOdGw2tAYJ9ve-F858BGJhW5zTDyDrT-H9dNgvM7QfX1P1nhOTgnVnL21kw55m9BFRZv3Lso2cRg1xjphPL8q75t-hv6fgZ4r3YacopUoSg2h1b7jlu82huiHqA75UAAcYp6FikKVvm7f5a7nw7RojMzyAt43_QYCXzYWxgPgHAOZmHSxn6yJltye73-gK2REEI7_KhOz-Bt_HVuiewG3DidoiuhSRN_YBx4HTvS-Bs5sozXRS3Ou1TeszZ_C5VRM760ML1omOfhw9-YYBPGmEhSOjqtFbv9RXS1o98IswXA5W5ecqOw4WqY_vPQ7z5iiiPWMgTZKw"
  },

})

//metemos el token en todas las peticiones para la bitácora de operaciones
instanciaBitacoraSistema.interceptors.request.use(function (config) {
  const token = window.sessionStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    //window.location.href = '/login'
  }
  return config;
});

//interceptamos la respuesta de las peticiopnes de la bitacora de operaciones y regresamos el error en caso de que exista
instanciaBitacoraSistema.interceptors.response.use(
  (response) => response,
  (err) => {
    ////console.log(err)

    if (!err.response) {
      // //console.log("entra ala error")
      return Promise.reject({
        status: 999,
        timestamp: new Date(),
        message: "se ha produciod un error inesperado",
        error: 1
      });
    }

    return Promise.reject({
      message: "se ha producido un error",
      error: err.response.status
    }
    );
  }
);

//Instancia para subir y descargar pdfs
export const instanciaRegistroPDF = axios.create({
  baseURL: `${process.env.REACT_APP_url_pdfs}`,
//  baseURL: "https://8ezu6wc6y7.execute-api.us-east-1.amazonaws.com",
  headers: {
    "Content-Type": "application/json",
  },
});

instanciaRegistroPDF.interceptors.request.use(function (config) {
  const token = window.sessionStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    //window.location.href = '/login'
  }
  return config;
});

instanciaRegistroPDF.interceptors.response.use(
  (response) => response,
  (err) => {
    ////console.log(err)
    if (!err.response) {
      // //console.log("entra ala error")
      return Promise.reject({
        status: 999,
        timestamp: new Date(),
        message: "se ha producido un error inesperado",
        error: 1
      });
    }

    return Promise.reject({
      message: "se ha producido un error",
      error: err.response.status
    }
    );
  }
);

//Instancia para consultar las operaciones realizadas por un depositante
export const instanciaConsultaOperaciones = axios.create({
  baseURL: `${process.env.REACT_APP_url_operaciones_depositantes}`,
 // baseURL: "https://8l61cxekje.execute-api.us-east-1.amazonaws.com",
  headers: {
    "Content-Type": "application/json",
  },

});


instanciaConsultaOperaciones.interceptors.request.use(function (config) {
  const token = window.sessionStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    //window.location.href = '/login'
  }
  return config;
});


instanciaConsultaOperaciones.interceptors.response.use(
  (response) => response,
  (err) => {
    ////console.log(err)
    if (!err.response) {
      // //console.log("entra ala error")
      return Promise.reject({
        status: 999,
        timestamp: new Date(),
        message: "se ha produciod un error inesperado",
        error: 1
      });
    }

    return Promise.reject({
      message: "se ha producido un error",
      error: err.response.status
    }
    );
  }
);