import { instance } from '../../../apiGlobal';

export async function autorizarAvisoCobroNoPeriodicos (obj) {
      try {
        const response = await instance(`/tarificacion/autorizar/avisocobro/np`, {
          method:"post",
          data:obj,
        })
        return response;
      } catch (error) {
        return error;
      }
}