import { useMemo } from "react";

export default function usePrendasYGarantiasLiberadas() {
  const columns = useMemo(
    () => [
        {
            Header: "Folio de liberación",
            accessor: "liberacionGarantiaFolio"
          },
      {
        Header: "Folio",
        accessor: "constitucionGarantiaFolio"
      },
      {
        Header: "Código del depositante garante",
        accessor: "codigoDepositanteGarante"
      },
      {
        Header: "Nombre del depositante garante",
        accessor: "nombreDepositanteGarante"
      },
      {
        Header: "Beneficiario",
        accessor: "beneficiarioGarantia"
      },
      {
        Header: "Código del depositante receptor",
        accessor: "codigoDepositanteReceptorGarantia"
      },
      {
        Header: "Cuenta receptora",
        accessor: "cuentaReceptoraGarantia"
      },
      {
        Header: "Nombre de la emisión",
        accessor: "nombreEmision"
      },
      {
        Header: "Serie",
        accessor: "nombreSerie"
      },
      {
        Header: "ISIN",
        accessor: "isinSerie"
      },
      {
        Header: "Moneda",
        accessor: "monedaSerie"
      },
     
      {
        Header: "Cantidad valores en garantía",
        accessor: "cantidadValores"
      },
      
    ],
    []
  );

  return columns;
}
