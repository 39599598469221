import React, { useState, useEffect } from "react";
import {useHistory} from 'react-router-dom';
//import { Link } from 'react-router-dom'

//Components
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
//import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";

//APIs
//import { actualizarSolicitudRegistroDepValFis } from '../../../../api/resguardoValoresFisicos/actualizarSolicitudRegistroDepValFis';


export default function DepositanteValFisSeleccionado({ history }) {

    const historyReact = useHistory();

    const [loadDataRowFailed, setLoadDataRowFailed] = useState();
    const [loadDataSuccessful, setLoadDataSuccessful] = useState();

      //Input textarea reject field
      //const [reasonReject, setReasonReject] = useState();
      //State to show textarea reject field
      //const [showRejectField, setShowRejectField] = useState(false);
  
      //Loading
      //const [loading, setLoading] = useState();

    //const [solicitudId, setSolicitudId] = useState();

    //Depositante
    const [codigoDepositanteValFis, setCodigoDepositanteValFis] = useState();
    const [nombreInstitucion, setNombreInstitucion] = useState();
    const [nacionalidadDepResValFis, setNacionalidadDepResValFis] = useState();
    const [fechaNacimientoDepResValFis, SetfechaNacimientoDepResValFis] = useState();

    //Domicilio
    const [pais, setPais] = useState();
    const [departamento, setDepartamento] = useState();
    const [municipio, setMunicipio] = useState();
    const [ciudad, setCiudad] = useState();
    const [coloniaBarrio, setColoniaBarrio] = useState();
    const [calleAvenida, setCalleAvenida] = useState();
    const [numeroCasa, setNumeroCasa] = useState();
    const [codigoPostal, setCodigoPostal] = useState();

    //Datos representante legal
    const [nombreRepLegal, setNombreRepLegal] = useState();
    const [correoRepLegal, setCorreoRepLegal] = useState();
    const [nacionalidadRepLegal, setNacionalidadRepLegal] = useState();
    const [tipoIdentificacionRepLegal, setTipoIdentificacionRepLegal] = useState();
    const [fechaNacimientoRepLegal, setFechaNacimientoRepLegal] = useState();
    const [numeroIdenRepLegal, setNumeroIdenRepLegal] = useState();
    const [telefonoRepLegal, setTelefonoRepLegal] = useState();
    const [telCelularRepLegal, setTelCelularRepLegal] = useState();

    //Responsable de la solicitud
    const [nombreResponsableSol, setNombreResponsableSol] = useState();
    const [correoResponsableSol, setCorreoResponsableSol] = useState();


    useEffect(() => {
        loadRowData();
    }, []);

    const loadRowData = () => {

        if (history.location.state === undefined) {
            setLoadDataRowFailed(true);
        } else {
            const row = history.location.state.objRow[0];

            //setSolicitudId(row.depositanteResgvalfisId);
            setCodigoDepositanteValFis(row.depositanteResgvalfisCodigo)

            //Row values 
            //Identificaciòn del depositante
            setNombreInstitucion(row.depositanteResgvalfisNombreCompleto);
            setNacionalidadDepResValFis(row.depositanteResgvalfisNacionalidadId.nombre);
            SetfechaNacimientoDepResValFis(row.depositanteResgvalfisFechaConsNacimientoCadena);


            //Domicilio
            setColoniaBarrio(row.depositanteResgvalfisDirId.cehvalDireccionColonia);
            setCalleAvenida(row.depositanteResgvalfisDirId.cehvalDireccionCalle);
            setNumeroCasa(row.depositanteResgvalfisDirId.cehvalDireccionNumeroCasEdi);
            setCodigoPostal(row.depositanteResgvalfisDirId.cehvalDireccionCp);

            //When address is from Honduras
            if (row.depositanteResgvalfisDirId.cehvalDireccionModelo === null) {
                setPais(row.depositanteResgvalfisDirId.catCiudadCatCiudadId.catMunicipioCatMunicipioId.catDepartamentoCatDepartamentoId.catPaisCatPaisId.nombre)
                setDepartamento(row.depositanteResgvalfisDirId.catCiudadCatCiudadId.catMunicipioCatMunicipioId.catDepartamentoCatDepartamentoId.nombre)
                setMunicipio(row.depositanteResgvalfisDirId.catCiudadCatCiudadId.catMunicipioCatMunicipioId.nombre);
                setCiudad(row.depositanteResgvalfisDirId.catCiudadCatCiudadId.nombre);

            //When address is from another country
            } else {
                setPais(row.depositanteResgvalfisNacionalidadId.nombre);
                setDepartamento(row.depositanteResgvalfisDirId.cehvalDireccionModelo.cehvalDireccionManualDepartamento);
                setMunicipio(row.depositanteResgvalfisDirId.cehvalDireccionModelo.cehvalDireccionManualMunicipio);
                setCiudad(row.depositanteResgvalfisDirId.cehvalDireccionModelo.cehvalDireccionManualCiudad);
            }

            //Representante legal
            setNombreRepLegal(row.depositanteResgvalfisNomRepresLeg);
            setCorreoRepLegal(row.depositanteResgvalfisCorreoResp);
            setNacionalidadRepLegal(row.depositanteResgvalfisNacionalidadRepresLeg.nombre);
            setTipoIdentificacionRepLegal(row.depositanteResgvalfisTipoIdeId.nombre);
            setFechaNacimientoRepLegal(row.depositanteResgvalfisFechaNacimientoRepreLedCadena);
            setNumeroIdenRepLegal(row.depositanteResgvalfisNumIde);
            setTelefonoRepLegal(row.depositanteResgvalfisTelefono);
            setTelCelularRepLegal(row.depositanteResgvalfisTelefonoCel);

            //Responsable solicitud
            setNombreResponsableSol(row.depositanteResgvalfisNomResp);
            setCorreoResponsableSol(row.depositanteResgvalfisCorreoResp);

            setLoadDataSuccessful(true);
        }
    }


    const goToInicioCehval = () => {
        history.push({
            pathname: '/inicioCehval',
        })
    }


    //Executes when the emitter was successfully registered
    const goBack = () => {
        historyReact.goBack();
    }


    return (
        <>
            {/* {loading &&
                <PantallaCargando />
            } */}
            {loadDataRowFailed &&
                <ComponenteModal mostrarModal={loadDataRowFailed} cerrarModal={goToInicioCehval} titulo={`Ocurrio algún error, será redireccionado al inicio. Revise su conexión o vuelva a iniciar sesión.`} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="fluid-container">
                
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Solicitud de registo de depositante de valores físicos"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <section className="p-5 d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded p-3">
                                                <div className="col-12">
                                                    {loadDataSuccessful &&
                                                        <form className="needs-validation font-acumin-variable text-royal-blue">
                                                            <div className="row mt-4">

                                                                {codigoDepositanteValFis &&
                                                                  <h4 className="font-acumin-variable mb-3">Código de depositante de valores físicos: <span className="fw-bolder">{codigoDepositanteValFis}</span></h4>  
                                                                }
                                                                {/*IDENTIFICACIÓN DEL DEPOSITANTE */}
                                                                <h5 className="text-royal-blue font-acumin-variable">I. Identificación del depositante de resguardo de valores físicos</h5>
                                                                <div className="col-12 col-md-12 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Nombre completo institución" value={nombreInstitucion} className="form-control text-royal-blue" name="nombreCompletoInstitucion" type="text"  required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="nombreCompletoInstitucion">Nombre completo de la institución</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <select className="form-select" id="" name="nacionalidad" required disabled>
                                                                            <option>{nacionalidadDepResValFis}</option>
                                                                        </select>
                                                                        <label htmlFor="validationCustom04" className="form-label ">Nacionalidad </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 form-floating mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" value={fechaNacimientoDepResValFis} className="form-control border" name="fechaNacimiento" required disabled />
                                                                        <label htmlFor="fechaNacimiento" className="form-label ">Fecha de nacimiento </label>
                                                                    </div>
                                                                </div>

                                                                {/*DOMICILIO*/}
                                                                <h5 className="text-royal-blue font-acumin-variable">Dirección completa del domicilio</h5>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <select className="form-select" id="" name="pais" required disabled>
                                                                            <option>{pais}</option>
                                                                        </select>
                                                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">País</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Colonia o barrio" value={coloniaBarrio} className="form-control fs-5" name="coloniaBarrio" type="text" placeholder="Colonia o barrio" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="coloniaBarrio">Colonia o barrio</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Departamento" value={departamento} className="form-control fs-5" name="departamento" type="text" placeholder="Departamento" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="departamento">Departamento</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Calle, avenida o bloque" value={calleAvenida} className="form-control fs-5" name="cantidadValores" type="text" placeholder="Calle, avenida o bloque" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="cantidadValores">Calle, avenida o bloque</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Municipio" value={municipio} className="form-control fs-5" name="municipio" type="text" placeholder="Municipio" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="municipio">Municipio </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Número de casa" value={numeroCasa} className="form-control fs-5" name="numeroCasa" type="text" placeholder="Número de casa" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="cantidadValores">Número de casa</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Ciudad" value={ciudad} className="form-control fs-5" name="ciudad" type="text" placeholder="Ciudad" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="ciudad">Ciudad</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Código postal" value={codigoPostal} className="form-control fs-5" name="cantidadValores" type="text" placeholder="Cantidad de valores" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="cantidadValores">Código postal</label>
                                                                    </div>
                                                                </div>


                                                                {/*REPRESENTANTE LEGAL*/}
                                                                <h5 className="text-royal-blue font-acumin-variable mt-4">II. Datos del representante legal</h5>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Nombre completo del representante legal" value={nombreRepLegal} className="form-control text-royal-blue " name="nombreRepLegal" type="text" placeholder="Nombre del representante legal" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="nombreRepLegal">Nombre completo </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Correo del representante legal" value={correoRepLegal} className="form-control text-royal-blue " name="correoRepLegal" type="text" placeholder="Correo representalte legal" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="correoRepLegal">Correo electrónico</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <select className="form-select" title="Nacionalidad del representante legal" id="nacionalidadRepLegal" name="Nacionalidad del representante legal" required disabled>
                                                                            <option value="">{nacionalidadRepLegal}</option>
                                                                        </select>
                                                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Nacionalidad</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <select className="form-select" title="Tipo de identificación del representante legal" id="tipoIdentificacionRepLegal" name="Tipo de identificación del representante legal" required disabled>
                                                                            <option>{tipoIdentificacionRepLegal}</option>
                                                                        </select>
                                                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Tipo de identificación *</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 form-floating mb-3">
                                                                    <div className="form-floating">
                                                                        <input value={fechaNacimientoRepLegal} title="Fecha de nacimiento del representante legal" type="text" className="form-control text-royal-blue border " name="fechaNacimientoRepLegal"  required disabled />
                                                                        <label htmlFor="fechaNacimientoRepLegal" className="form-label ">Fecha de nacimiento</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Identificación del representante legal" value={numeroIdenRepLegal} className="form-control text-royal-blue" name="numIdentificacionRepLegal" type="text"  required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="numIdentificacionRepLegal">Número de identificación </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Teléfono del representante legal" className="form-control text-royal-blue" value={telefonoRepLegal} name="telefonoRepLegal" type="text"  required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="telefonoRepLegal">Teléfono</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Teléfono celular del representante legal" className="form-control text-royal-blue" value={telCelularRepLegal} name="telCelularRepLegal" type="text"  required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="telCelularRepLegal">Teléfono celular</label>
                                                                    </div>
                                                                </div>
                                                                <h5 className="text-royal-blue font-acumin-variable mt-4">III. Responsable de la solicitud de registro</h5>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Nombre del reponsable de la solicitud de registro" value={nombreResponsableSol} className="form-control text-royal-blue " name="nombreResponsableRegistro" type="text" placeholder="Nombre responsable registro" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="nombreResponsableRegistro">Nombre completo</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Correo del responsable de la solicitud de registro" value={correoResponsableSol} className="form-control text-royal-blue " name="correoResponsableRegistro" type="text" placeholder="Correo del responsable del registro" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="correoResponsableRegistro">Correo electrónico</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                    <button type="button" onClick={goBack} className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-1 background-royal-blue text-white bg-hover-gold">Atrás</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    )
}