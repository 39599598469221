import React from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from '../../../ComponentesGenerales/PantallaCargando'

//APIs
import { traerMonedas } from '../../../api/catalogos/traerMonedas';
import { registarDisponibilidadDeFondos } from "../../../api/Mercadoprimario/registrarDisponibilidadDeFondos";

import formatNumber from "../../../functions/FormatNumber";



export default function RegistrarDisponibilidadFondos({ history }) {

    const [erroreshttp, setErroreshttp] = React.useState()
    const [loading, setLoading] = React.useState(false);


    const [folio, setFolio] = React.useState()
    const [moneda, setMoneda] = React.useState()
    const [monedas, setMonedas] = React.useState([])
    //Number to send to backend
    const [monto, setMonto] = React.useState()
    //Formated number to show in frontend
    const [montoFront, setMontoFront] = React.useState();
    const [fechaDeposito, setFechaDeposito] = React.useState()


    const [procesoExitoso, setProcesoExitoso] = React.useState()
    const [procesoFallido, setProcesoFallido] = React.useState()
    const [mensaje, setMensaje] = React.useState()

    React.useEffect(() => {
        traerDatos();
    }, [])

    const traerDatos = async () => {

        const catalogoMonedas = await traerMonedas()
        //console.log(catalogoMonedas)
        if (catalogoMonedas) {
            if ((isNaN(catalogoMonedas.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(catalogoMonedas)
            } else {
                setMonedas(catalogoMonedas);
            }
        }
    }


    const onSubmitForm = async (e) => {
        e.preventDefault();

        const objetoUsuario = {
            folioBchTr: folio,
            moneda: moneda,
            monto: monto,
            fechaDeposito: fechaDeposito
        }

        //console.log(objetoUsuario)
        const respuestaUsuario = await registarDisponibilidadDeFondos(objetoUsuario)
        //console.log(respuestaUsuario)
        if (respuestaUsuario) {
            //entra a qui si hay un error http
            if ((isNaN(respuestaUsuario.error)) === false) {
                // respuestaincorrecta         
                setLoading(false)
                setErroreshttp(respuestaUsuario)
            } else {
                setLoading(false)
                //respuesta correcta
                //  setLoading(true)
                if (respuestaUsuario?.codigo === "000") {
                    setMensaje("Disponibilidad de fondos registrada")
                    ////console.log(respuestaUsuario.mensaje)
                    setProcesoExitoso(true)

                } else {
                    setMensaje(respuestaUsuario.mensaje)
                    setProcesoFallido(true)
                }
            }
        }
    }


    const cerrarModal = () => {
        setProcesoExitoso(false)
        setFolio("")
        setMoneda("")
        setMonto("")
        setFechaDeposito("")
        setMontoFront("")
        //history.go(-1)
    }

    const handleInputMonto = (e) => {
        const { value } = e.target;
        const formattedNumber = formatNumber(value);
        //Setted the amount for the backend
        setMonto(formattedNumber.integerNumber);
        setMontoFront(formattedNumber.stringNumber);
    }


    return (
        <>

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {loading &&
                <PantallaCargando />
            }
            {procesoExitoso &&
                <ComponenteModal mostrarModal={procesoExitoso} cerrarModal={cerrarModal} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }
            {procesoFallido &&
                <ComponenteModal mostrarModal={procesoFallido} cerrarModal={() => { setProcesoFallido(false) }} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            <div className="fluid-container">{/* style={{ backgroundImage: `url(${fondo})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="fluid-container">*/}
                <main className="min-height-40 mb-4">
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Registrar disponibilidad de fondos"></BannerTitulo>
                                <div className="d-flex mt-4">
                                    <div className="container">
                                        <section className="d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded p-3">
                                                <div className="col-12">
                                                    <form onSubmit={(e) => {
                                                        onSubmitForm(e)
                                                        setLoading(true)
                                                    }} className="needs-validation font-acumin-variable text-royal-blue">
                                                        <h6 className="font-acumin-variable text-royal-blue">* Estos campos son obligatorios</h6>
                                                        <div className="row mb-3">
                                                            <div className="col-md-6 col-sm-12 mb-3">
                                                                <div className="form-floating">
                                                                    <input title="Folio BCH-TR" type="text" id="email" className="form-control" placeholder="name@example.com" value={folio} onChange={(event) => setFolio(event.target.value)} required></input>
                                                                    <label htmlFor="email">Folio BCH-TR *</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 col-sm-12 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Fecha en la que se realizó el depósito" className="form-control text-uppercase" type="date" value={fechaDeposito} onChange={(event) => setFechaDeposito(event.target.value)} name="fechaEmision" placeholder="Fecha de emisión" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Fecha de depósito *</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <select title="Moneda del depósito" className="form-select" value={moneda} onChange={(event) => setMoneda(event.target.value)} id="moneda" name="moneda" required>
                                                                        <option value=""> </option>
                                                                        {
                                                                            monedas.map((item, index) => (
                                                                                <option key={index} value={item.id} >{item.nombre}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    <label htmlFor="contraseña">Moneda *</label>
                                                                </div>
                                                            </div>


                                                            <div className="col-md-6 col-sm-12 mb-3">
                                                                <div className="form-floating">
                                                                    <input title="Monto del depósito" type="text" id="email" className="form-control" placeholder="name@example.com" onInput={(e) => handleInputMonto(e)} value={montoFront} required></input>
                                                                    <label htmlFor="email">Monto *</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-12 mb-2 text-end align-self-end">
                                                                <button className=" btn  botonFormularioDepositanteAprobar bg-hover-blue-royal" type="submit">Registrar</button>
                                                            </div>
                                                        </div>


                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

