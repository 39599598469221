import React, { useState, useEffect } from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
//import SpinnerSend from "../../../ComponentesGenerales/SpinnerSend";
import PantallaCargando from '../../../ComponentesGenerales/PantallaCargando'


//APIs
import { traerMonedas } from '../../../api/catalogos/traerMonedas';
import { consultarEmisor } from '../../../api/emisor/consultarEmisor';
import { registrarEmision } from '../../../api/emisor/registrarEmision';

//Functions
import formatNumber from "../../../functions/FormatNumber";

export default function RegistroEmisiones({ history }) {

    //Catálogos
    const [catMonedas, setCatMonedas] = useState();

    //Despliega errores
    const [error, setError] = useState(null);
    const [errorCodigoEmisor, setErrorCodigoEmisor] = useState(null);

    //Actualizan el stado de cada campo del formulario
    const [monedaDeEmision, setMonedaDeEmision] = useState();
    //const [codigoEmision, setCodigoEmision] = useState();
    const [codigoEmisor, setCodigoEmisor] = useState();
    const [nombreEmision, setNombreEmision] = useState();
    const [nombreEmisor, setNombreEmisor] = useState();

    //Front
    const [montoEmision, setMontoEmision] = useState();
    const [montoEmisionBack, setMontoEmisionBack] = useState();
    const [numeroDeResolucionRPMV, setNumeroDeResolucionRPMV] = useState();
    const [fechaDeRegistroRPMV, setFechaDeRegistroRPMV] = useState();
    const [representanteObligacionistas, setRepresentanteObligacionistas] = useState();
    const [formaRepresentacion, setFormaRepresentacion] = useState("N");
    // const [displayModal, setDisplayModal] = useState();

    const [series, setSeries] = useState();

    //Almacena el numero de series que introduce el usuario en el input
    const [numberOfSeries, setNumberOfSeries] = useState();

    //Permite desplegar los inputs para ingresar las series
    const [seriesReady, setSeriesReady] = useState(false);


    //Crea el array con las posiciones especificas segun el numero de series que el usuario va a registrar
    let arraySeries = new Array(numberOfSeries);
    const [array, setArray] = useState(arraySeries);

    //Guarda el string de series que tendra la emision 
    const [stringSeries, setStringSeries] = useState();

    //Mostrar modal si el registro es exitoso
    const [registroExitoso, setRegistroExitoso] = useState();
    const [registroSinExito, setRegistroSinExito] = useState();
    const [errorSinSeries, setErrorSinSeries] = useState();


    //   const [fechaLimiteNacimiento, setFechaLimiteNacimiento] = React.useState()
    const [fechaLimite, setFechaLimite] = React.useState()

    //manja errores http
    // const [spinSend, setSpinSend] = React.useState(false)
    const [loading, setLoading] = React.useState(false);
    const [erroreshttp, setErroreshttp] = React.useState()
    const [serieUnica, setSerieUnica] = React.useState()

    useEffect(() => {
        //Load all Catalogs
        getCatData();
        establecerFecha()

    }, []);

    const establecerFecha = () => {
        const fecha = new Date();
        const añoActual = fecha.getFullYear();
        let diaActual = fecha.getDate();
        let mesActual = fecha.getMonth() + 1;

        if (diaActual < 10) {
            diaActual = "0" + diaActual;
        }

        if (mesActual < 10) {
            mesActual = "0" + mesActual;
        }
        const fecha_limite = (añoActual) + "-" + mesActual + "-" + (diaActual);
        setFechaLimite(fecha_limite);
    }


    //Trae los catalogos de la DB para renderizar en etiqueta select
    const getCatData = async () => {
        const catalogoMonedas = await traerMonedas()
        if (catalogoMonedas) {
            if ((isNaN(catalogoMonedas.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(catalogoMonedas)
            } else {
                setCatMonedas(catalogoMonedas);
            }
        }

    }


    {/*} const checkNumberOfSeries = () => {
        setNumberOfSeries("");
        let arraySeries = []
        
       if( series.length != 0 ){

            for(let i = 0; i < series; i++){
                arraySeries.push(i+1);
                setSeriesReady(false);
            }
            setSeriesReady(true)
            
            setNumberOfSeries(arraySeries)
            
       }
    }*/}

    //Hace la consulta a la base de datos y trae el nombre del emisor
    const queryEmisorName = async () => {
        const objEmisor = { codigo: codigoEmisor }
        try {
            const response = await consultarEmisor(objEmisor);
            if (response) {
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {

                    if (response.data.cehvalEmisorNombreCom === null) {
                        setErrorCodigoEmisor("El emisor no existe");
                        setNombreEmisor("")
                    } else {
                        setNombreEmisor(response.data.cehvalEmisorNombreCom);
                        setErrorCodigoEmisor("");
                    }

                }
            }

            //  //console.log(response)
            /*if (response.status === 400 || response.status === 401 || response.cehvalEmisorNombreCom === null) {
                setErrorCodigoEmisor("El emisor no existe o la sesión ha caducado");
                setNombreEmisor("")

            } else {
                setNombreEmisor(response.cehvalEmisorNombreCom);
                setErrorCodigoEmisor("");
            }*/

        } catch (error) {
            console.error(error);
        }
    }


    //Ejecuta la funcion queryEmisorName ingresar 10 digitos del codigo de emisor
    const checkLenght = () => {
        if (codigoEmisor) {
            if (codigoEmisor.length === 10 || codigoEmisor.length === 9) {
                queryEmisorName();
            }
        }

    }

    //Crea los input para agregar series segun el numero ingresado por el usuario
    const checkNumberOfSeries = (e) => {

        let arraySeries = [];

        //Si el input del usuario no es null itera sobre ese numero para crear el array
        if (e.target.value != null) {
            for (let i = 0; i < e.target.value; i++) {
                arraySeries.push(i + 1);
                setSeriesReady(false);
            }

            if (arraySeries.length === 1) {

                setStringSeries("U")
                setSeriesReady(false)
                setSerieUnica(true)
            } else {
                setSerieUnica(false)
                //Cuando el for deje de iterar seteamos a true setSeriesReady que va a desplegar los inputs
                setSeriesReady(true);
                //Envía el array creado en el for a nuestro estado numberOfSeries
                setNumberOfSeries(arraySeries);
                setStringSeries("")
            }

        } else {
            setSerieUnica(false)
            setSeriesReady(false);
        }
    }

    React.useEffect(() => {
        //console.log(monedaDeEmision)
    }, [monedaDeEmision])


    //Actualiza el valor de los input para ingresar las series
    const handleInputSeries = (e, index) => {

        const { value } = e.target;

        //Modifca la posicion del array 
        array[index] = value;

        //Crea el string de las series
        const strigSeries = array.join();

        setStringSeries(strigSeries.toUpperCase());
    };


    //Manda la emision a la DB
    const sendEmision = async (e) => {
        //console.log(monedaDeEmision)

        e.preventDefault();
        setLoading(true)
        //console.log(series)
        if (series === "0") {
            setErrorSinSeries(true)
            setLoading(false)
        } else {

            const emision = {
                // cehvalEmisionCodigo: codigoEmision,
                cehvalEmisionNomEmi: nombreEmision,
                cehvalEmisionMonto: montoEmisionBack,
                cehvalEmisionNumeroResol: numeroDeResolucionRPMV,
                cehvalEmisionFechaRpmv: fechaDeRegistroRPMV,
                cehvalEmisionRepresentanteComunObl: representanteObligacionistas,
                cehvalEmisionSeries: stringSeries,
                cehvalEmisionDesmaterializada: formaRepresentacion,
                catMercadoNegCatMercNegId: {
                    catMercNegId: 1,
                },

                catMonedaCatMonedaId: {
                    catMonedaId: monedaDeEmision,
                },
                cehvalEmisorCodigo: {
                    cehvalEmisorCodigo: codigoEmisor?.toUpperCase(),
                }
            }

            //console.log(emision)


            try {
                const sendEmision = await registrarEmision(emision);
                //console.log(sendEmision)
                if (sendEmision) {
                    if ((isNaN(sendEmision.error)) === false) {
                        // respuestaincorrecta     
                        setLoading(false)
                        setErroreshttp(sendEmision)
                    } else {
                        if (sendEmision.data.codigo === "000") {
                            setLoading(false)
                            setRegistroExitoso(true);
                        } else {
                            setLoading(false)
                            setRegistroSinExito(true);
                        }

                    }
                }


                //  //console.log(sendEmision)




                ////console.log(sendEmision);

            } catch (err) {
                setRegistroSinExito(true);
                console.error(err)
            }
        }


        //checkEmptyFields();


    }



    // Format number and prevent to enter letter, symbols and allow to enter decimals
    const handleMontoTotalEmision = (e) => {

        //Value entered by user
        const { value } = e.target;
        const formattedNumber = formatNumber(value);

        //Number without format that will be sended to the endpoint hacerTransferenciaLibrePago();
        setMontoEmisionBack(formattedNumber.integerNumber);
        setMontoEmision(formattedNumber.stringNumber);
    }


    const irInicio = () => {
        setNombreEmision("")
        setCodigoEmisor("")
        setNombreEmisor("")
        setMontoEmision("")
        setMonedaDeEmision("")
        setNumeroDeResolucionRPMV("")
        setFechaDeRegistroRPMV("")
        setRepresentanteObligacionistas("")
        setFormaRepresentacion("")
        setNumberOfSeries([])
        setSeries("")
        setSeriesReady(false)
        setSerieUnica(false)
        setStringSeries("")

        const elm = document.getElementsByName("formaRepresentacion");
        for (let e = 0; e < elm.length; e++) {
            elm[e].checked = false;
        }

        setRegistroExitoso(false);
        setRegistroSinExito(false);
        //window.location.reload();
    }

    return (
        <>
            <div className="fluid-container">
                {loading &&
                    <PantallaCargando />
                }
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                {registroExitoso &&
                    <ComponenteModal mostrarModal={registroExitoso} cerrarModal={irInicio} titulo="Emisión registrada" textoBoton="Entendido"></ComponenteModal>
                }
                {registroSinExito &&
                    <ComponenteModal mostrarModal={registroSinExito} cerrarModal={() => setRegistroSinExito(false)} titulo="No se pudo realizar el registro" textoBoton="Entendido"></ComponenteModal>
                }

                {errorSinSeries &&
                    <ComponenteModal mostrarModal={errorSinSeries} cerrarModal={() => setErrorSinSeries(false)} titulo="Necesita meter al menos una serie" textoBoton="Entendido"></ComponenteModal>
                }


                <main className="mb-5">
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Registro de emisiones"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container">
                                        <section className="d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 col-lg-12 row justify-content-center border rounded p-3">
                                                <div className="">
                                                    <form onSubmit={sendEmision} className="needs-validation font-acumin-variable text-royal-blue">
                                                        <div className="row mt-4">
                                                            <div className="col-12 col-md-12 mb-3">
                                                                <div className="form-floating ">
                                                                    <input onChange={(e) => setNombreEmision(e.target.value)} value={nombreEmision} title="Nombre completo de la emisión" className="form-control fs-5" name="nombreEmision" type="text" placeholder="Nombre completo de la emision" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="nombreEmision">Nombre completo de la emisión *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-4 col-xl-4 mb-3">
                                                                <div className="form-floating">
                                                                    <input onChange={(e) => setCodigoEmisor(e.target.value)} maxLength={10} value={codigoEmisor} onKeyUp={checkLenght} on title="Código del emisor" className="form-control fs-5" type="text" name="codigoEmisor" placeholder="Codigo del emisor" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="codigoEmisor">Código del emisor *</label>
                                                                </div>
                                                                {errorCodigoEmisor && <p className="text-danger mt-2 padding-text-left bold">{errorCodigoEmisor}</p>}
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-8 col-xl-8 mb-3">
                                                                <div className="form-floating ">

                                                                    <input value={nombreEmisor} title="Nombre del emisor" className="form-control fs-5 text-muted" type="text" name="nombreEmisor" placeholder="Nombre del emisor" required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="nombreEmisor">Nombre del emisor *</label>
                                                                    <p className="text-muted mb-0">&#40;Ingrese el código del emisor&#41;</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-4 col-xl-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input onInput={(e) => handleMontoTotalEmision(e)} value={montoEmision} title="Monto total de la emisión" className="form-control fs-5 text-uppercase" name="montoEmision" type="text" placeholder="Nombre completo de la emision" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="montoEmision">Monto total de la emisión *</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-12 col-lg-4 col-xl-4 d-flex justify-content-center mb-3">
                                                                <div className=" col-12 col-md-12 ">
                                                                    <div className="form-floating">
                                                                        <select onChange={(e) => setMonedaDeEmision(e.target.value)} value={monedaDeEmision} title="Moneda de la emisión" className="form-select font-acumin-variable" name="monedaDeEmision" style={{ height: "56px" }} aria-label="Default select example" required>

                                                                            <option value=""> </option>
                                                                            {catMonedas &&
                                                                                catMonedas.map((moneda, index) => (
                                                                                    <option key={moneda.nombre} className="text-uppercase" value={index + 1}>{moneda.nombre}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <label className="font-acumin-variable" htmlFor="monedaDeEmision">Moneda de la emisión *</label>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-4 col-xl-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input onChange={(e) => setNumeroDeResolucionRPMV(e.target.value)} title="Número de resolución RPMV" value={numeroDeResolucionRPMV} className="form-control fs-5 text-uppercase" min={0} type="text" name="numeroDeResolucionRPMV" placeholder="No. de Resolución RPMV" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="numeroDeResolucionRPMV">No. de Resolución RPMV</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-4 col-xl-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input onChange={(e) => setFechaDeRegistroRPMV(e.target.value)} value={fechaDeRegistroRPMV} max={fechaLimite} title="Fecha registro RPMV" className="form-control fs-5 text-uppercase" type="date" name="fechaDeRegistroRPMV" placeholder="Fecha de registro RPMV" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="fechaDeRegistroRPMV">Fecha de registro RPMV</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-8 col-xl-8 mb-3">
                                                                <div className="form-floating ">
                                                                    <input onChange={(e) => setRepresentanteObligacionistas(e.target.value)} value={representanteObligacionistas} title="Representante común de los obligacionistas" className="form-control fs-5 fs-5" type="text" name="representanteObligacionistas" placeholder="Representante común de los obligacionistas" />
                                                                    <label className="text-start font-acumin-variable" htmlFor="representanteObligacionistas">Representante común de los obligacionistas</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-4 col-xl-4 mb-3">
                                                                <div className="border rounded px-3 py-2">
                                                                    <h5 className="fs-15">Forma de representación *</h5>
                                                                    <div className="form-Value ps-2">
                                                                        <input className="form-Value-input cursor-pointer" onChange={(e) => setFormaRepresentacion(e.target.value)} value="F" type="radio" name="formaRepresentacion" id="materializada" required />
                                                                        <label className="form-Value-label ms-2 cursor-pointer" htmlFor="materializada">
                                                                            Física
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-Value ps-2">
                                                                        <input className="form-Value-input cursor-pointer" onChange={(e) => setFormaRepresentacion(e.target.value)} value="D" type="radio" name="formaRepresentacion" id="desmaterializada" required />
                                                                        <label className="form-check-label ms-2 cursor-pointer" htmlFor="desmaterializada">
                                                                            Desmaterializada
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-4 col-xl-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input onChange={(e) => {
                                                                        checkNumberOfSeries(e)
                                                                        setSeries(e.target.value)
                                                                    }} value={series} title="Número de series" maxLength={2} className="form-control fs-5 text-uppercase" type="text" name="series" placeholder="Número de series a emitir" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="series">Agregue la cantidad de series *</label>
                                                                </div>
                                                            </div>
                                                            {/*Desplegar inputs para agregar series*/}
                                                            <div className="row justify-content-center mt-3 mb-3">
                                                                <div className="col-11 border-top"></div>
                                                            </div>
                                                            {seriesReady && !serieUnica &&
                                                                numberOfSeries?.map((numeroSerie, index) => (
                                                                    <div key={numeroSerie} className="col-12 col-md-12 col-lg-2 col-xl-2 mt-3">
                                                                        <div className="form-floating ">
                                                                            <input id={`serie${numeroSerie}`} onChange={(e) => { handleInputSeries(e, index) }} title="Número de series" className="form-control fs-5 text-uppercase" type="text" name="serie" placeholder="Serie" required />
                                                                            <label className="text-start font-acumin-variable" htmlFor="serie">Serie {numeroSerie}</label>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }

                                                            {!seriesReady && serieUnica &&

                                                                <div className="col-12 col-md-2 mt-3">
                                                                    <div className="form-floating ">
                                                                        <input value={stringSeries} title="Número de series" className="form-control fs-5 text-uppercase" type="text" name="serie" placeholder="Serie" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="serie">Serie 1</label>
                                                                    </div>
                                                                </div>

                                                            }


                                                            {error && <p id="inputsRequired" className="text-danger m-3">{error}</p>}
                                                            <div className="col-12 col-md-12 mt-5 d-flex justify-content-center">
                                                                <div className="form-floating ">
                                                                    <button type="submit" className="btn background-royal-blue text-white bg-hover-gold" >Registrar Emisión</button>
                                                                </div>
                                                            </div>
                                                            {/*{spinSend &&
                                                                <SpinnerSend textoSpinner="Enviando..."></SpinnerSend>
                                                            }*/}
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    )
}