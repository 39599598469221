import React, { useState, useEffect } from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";


//Hooks
import useSolicitudGeneracionCodigoISIN from "../../../hooks/SolicitudGeneracionCodigoISIN/useSolicitudGeneracionCodigoISIN";

//API's
import { generarOficio } from "../../../api/GeneracionAsignacionCodigosISIN/generarOficio";



//Muestra las cuentas de un depositante indirecto
export default function HistoricoVisualizarInfoSeleccionado({ history }) {

    useEffect(() => {

        if (history.location.state === undefined) {
            history.go(-1)
        } else {


            const applicationData = history.location.state.emisionInfo;
            //Datos emisor y emision
            setCodigoEmisor(applicationData.solicitudIsinCodigoEmisor);
            setNombreEmisor(applicationData.solicitudNombreEmisor);
            setCodigoEmision(applicationData.solicitudIsinCodigoEmision);
            setNombreEmision(applicationData.solicitudNombreEmision);
            setInstrumentoFinanciero({ ...instrumentoFinanciero, tipo: applicationData.cehvalSolicitudIsinInstrumetoFinanciero.tipo, id: applicationData.cehvalSolicitudIsinInstrumetoFinanciero.id });
            setNumeroResRPMV(applicationData.resolucion);
            setSolicitudIsinId(applicationData.solicitudIsinId);
            setFechaRegistroISIN(applicationData.fechaRegistro);

            const series = applicationData.solicitudIsinSeries.split(",");
            createSeriesArray(series);

            //Responsable
            setResponsableNombre(applicationData.solicitudIsinResponsableNombre);
            setPuestoResponsable({ ...puestoResponsable, nombre: applicationData.cehvalSolicitudIsinResponsablePuesto.nombre, id: applicationData.cehvalSolicitudIsinResponsablePuesto.id });
            setResponsableCorreo(applicationData.solicitudIsinResponsableCorreo);

            //Signatario
            setSignatario({...signatario,signatarioNombre: applicationData.solicitudIsinSignatarioNombre, signatarioPuesto: applicationData.cehvalSolicitudIsinResponsablePuesto.nombre });
        }

    }, []);

    //Create series array to fill the table
    const createSeriesArray = (series) => {
        const arraySeries = series.map((el) => {
            return { serie: el }
        });
        setArraySeries(arraySeries);
    }




    //All emision serie
    const [arraySeries, setArraySeries] = useState();


    //Datos emisor y emision 
    const [nombreEmisor, setNombreEmisor] = useState("");
    const [codigoEmisor, setCodigoEmisor] = useState("");
    const [codigoEmision, setCodigoEmision] = useState();
    const [nombreEmision, setNombreEmision] = useState("");
    const [instrumentoFinanciero, setInstrumentoFinanciero] = useState({
        tipo: "",
        id: "",
    });
    const [numeroResRPMV, setNumeroResRPMV] = useState("");

    const [fechaRegistroISIN, setFechaRegistroISIN] = useState();

    //Datos responsable
    const [responsableNombre, setResponsableNombre] = useState();
    const [responsableCorreo, setResponsableCorreo] = useState();
    const [puestoResponsable, setPuestoResponsable] = useState({
        nombre: "",
        id: "",
    });

    //Datos signatario
    const [signatario, setSignatario] = useState({
        signatarioNombre: "",
        signatarioPuesto: "",
    });

    const [solicitudIsinId, setSolicitudIsinId] = useState();
    



    const [downloadFailed, setDownloadFailed] = useState();


    //Request messages
    const [statusRequest, setStatusRequest] = useState();



    //Shows the loading screen when is set to true
    const [loading, setLoading] = useState(false);
    const [erroreshttp, setErroreshttp] = useState();



    const downloadPDF = async () => {

        try {
            setLoading(true);
            const response = await generarOficio({ idSolicitud: solicitudIsinId });
            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    //console.log(response);
                    if (response.data) {
                        setLoading(false);
        
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                        URL.revokeObjectURL(fileURL);
        
                    } else {
                        setLoading(false);
                        setDownloadFailed("No fue posible descargar el archivo");
                    }
                }
            }

     

        } catch (error) {
            //console.log(error)
        }
    }






    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {statusRequest &&
                <ComponenteModal mostrarModal={statusRequest} cerrarModal={() => setStatusRequest(false)} titulo={statusRequest} textoBoton="Aceptar"></ComponenteModal>
            }

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }



            {downloadFailed &&
                <ComponenteModal mostrarModal={downloadFailed} cerrarModal={() => setDownloadFailed(false)} titulo={downloadFailed} textoBoton="Aceptar"></ComponenteModal>
            }

            <div className="fluid-container">
                <main className="mb-5 min-height-40">
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Visualización de información y descarga del oficio con códigos ISIN generados"></BannerTitulo>
                                <div className="d-flex px-3">
                                    <div className="needs-validation font-acumin-variable text-royal-blue container border rounded px-2 px-md-5 px-lg-5 px-xl-5 px-xxñ-5">
                                        <form>
                                            <section>
                                                <div className="row mt-4">
                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                        <div className="form-floating">
                                                            <input title="Código del emisor" className="form-control fs-5" value={codigoEmisor} type="text" name="codigoEmisor" placeholder="Codigo del emisor" required disabled />
                                                            <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoEmisor">Código del emisor</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                        <div className="form-floating">
                                                            <input title="Nombre del emisor" className="form-control fs-5" value={nombreEmisor} type="text" name="nombreEmisor" placeholder="Nombre del emisor" required disabled />
                                                            <label className="text-start font-acumin-variable" htmlFor="nombreEmisor">Nombre del emisor</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                        <div className="form-floating">
                                                            <input title="Código de emisión" className="form-control fs-5" value={codigoEmision} type="text" name="codigoEmision" placeholder="Código de emisión" required disabled />
                                                            <label className="text-start font-acumin-variable" htmlFor="codigoEmision">Código emisión</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                        <div className="form-floating">
                                                            <input title="Nombre de la emisión" className="form-control fs-5" value={nombreEmision} type="text" name="nombreEmision" placeholder="Nombre de la emisión" required disabled />
                                                            <label className="text-start font-acumin-variable" htmlFor="nombreEmision">Código emisión</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                        <div className="form-floating">
                                                            <input title="No. Resolución RPMV" className="form-control fs-5" value={numeroResRPMV} type="text" name="numeroResolucionRPMV" placeholder="No. Resolución RPMV" required disabled />
                                                            <label className="text-start font-acumin-variable" htmlFor="numeroResolucionRPMV">No. Resolución RPMV</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                        <div className="form-floating">
                                                            <input title="Fecha de registro" className="form-control fs-5" value={fechaRegistroISIN} type="text" name="fechaRegistro" placeholder="Fecha de registro" required disabled />
                                                            <label className="text-start font-acumin-variable" htmlFor="fechaRegistro">Fecha de registro</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center mb-3">
                                                        <div className=" col-12 col-md-12 ">
                                                            <div className="form-floating">
                                                                <select title="Tipo de instrumento financiero" className="form-select font-acumin-variable" name="tipoInstrumentoFinanciero" style={{ height: "56px" }} aria-label="Default select example" disabled required>
                                                                    <option value={instrumentoFinanciero.id}>{instrumentoFinanciero.tipo}</option>
                                                                </select>
                                                                <label className="font-acumin-variable" htmlFor="tipoInstrumentoFinanciero">Tipo de instrumento financiero</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section>
                                                <div className="d-flex">
                                                    <div className="container py-5">
                                                        {arraySeries &&
                                                            <TablaUsuarios datos={arraySeries} useValidate={useSolicitudGeneracionCodigoISIN}  ></TablaUsuarios>
                                                        }
                                                    </div>
                                                </div>
                                            </section>
                                            <section className="row justify-content-center align-items-start g-0 mt-5 mb-3 p-2">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 font-acumin-variable text-royal-blue mt-5 mt-sm-5 mt-md-5 mt-lg-0 mt-xl-0 mt-xxl-0">
                                                    <div className="row justify-content-center mt-4">
                                                        <h5 className="font-acumin-variable text-royal-blue text-start text-sm-start text-md-start text-lg-center text-xl-center text-xxl-center mb-3 fw-bolder">Solicitante responsable</h5>
                                                        <div className="col-12 col-md-12 col-lg-10 col-xl-10 mb-3">
                                                            <div className="form-floating">
                                                                <input title="Nombre del responsable" className="form-control fs-5" type="text" value={responsableNombre} name="nombreResponsable" placeholder="Nombre del responsable" required disabled />
                                                                <label className="text-start font-acumin-variable" htmlFor="nombreResponsable">Nombre del responsable</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-10 col-xl-10 d-flex justify-content-center mb-3">
                                                            <div className=" col-12 col-md-12 ">
                                                                <div className="form-floating">
                                                                    <select title="Puesto que desempeña" className="form-select font-acumin-variable" name="puestoResponsable" style={{ height: "56px" }} aria-label="Default select example" required disabled>
                                                                        <option className="text-uppercase" value={puestoResponsable.id}>{puestoResponsable.nombre}</option>
                                                                    </select>
                                                                    <label className="font-acumin-variable" htmlFor="puestoResponsable">Puesto que desempeña</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-12 col-lg-10 col-xl-10 mb-3">
                                                            <div className="form-floating">
                                                                <input title="Correo electrónico" className="form-control fs-5" type="email" value={responsableCorreo} name="correoResponsable" placeholder="Correo electrónico" required disabled />
                                                                <label className="text-start font-acumin-variable" htmlFor="correoResponsable">Correo electrónico</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 font-acumin-variable text-royal-blue mt-5 mt-sm-5 mt-md-5 mt-lg-0 mt-xl-0 mt-xxl-0">
                                                    <div className="row justify-content-center mt-4">
                                                        <h5 className="font-acumin-variable text-royal-blue text-start text-sm-start text-md-start text-lg-center text-xl-center text-xxl-center mb-3 fw-bolder">Datos del signatario</h5>
                                                        <div className="col-12 col-md-12 col-lg-10 col-xl-10 mb-3">
                                                            <div className="form-floating">
                                                                <input title="Signatario" className="form-control fs-5" type="text" value={signatario.signatarioNombre} name="signatarioNombre" placeholder="Signatario" required disabled />
                                                                <label className="text-start font-acumin-variable text-royal-blue" htmlFor="signatarioNombre">Signatario</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-10 col-xl-10 d-flex justify-content-center mb-3">
                                                            <div className=" col-12 col-md-12 ">
                                                                <div className="form-floating">
                                                                    <select title="Signatario puesto" value={signatario.signatarioPuesto} className="form-select font-acumin-variable" name="signatarioPuestoId" style={{ height: "56px" }} aria-label="Default select example" required disabled>
                                                                        <option value={26}>{signatario.signatarioPuesto}</option>
                                                                    </select>
                                                                    <label className="font-acumin-variable" htmlFor="signatarioPuestoId">Puesto que desempeña</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section className="my-5">
                                                <div className="row justify-content-center g-0 px-2">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                                        <div className="col-12 row justify-content-center justity-content-md-start justity-content-lg-start justity-content-xl-start justity-content-xxl-start g-0">
                                                            <div id="fileDropAreaISIN" className="col-12 col-sm-12 col-md-8 col-lg-8 drop-area row row-wrap justify-content-center align-items-center border-dashed py-5 px-2 rounded" >
                                                                <i className="ri-download-2-fill ri-3x text-center text-royal-blue"></i>
                                                                <p id="text-click-uploadfile" onClick={downloadPDF} className="text-center font-acumin-variable text-royal-blue">Descargar Oficio</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </section>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}


