//React
import React from "react";
import ComponenteMenuEmsoresEmisionesSeries from "../../../ComponentesGenerales/ComponenteMenuEmsoresEmisionesSeries";

export default function MenuEmisoresEmisionesYSeriesRegistradas({ history }) {

    return (
           <ComponenteMenuEmsoresEmisionesSeries titulo="Emisores, emisiones y series registradas" ruta1="/emisoresRegistrados" ruta2="/emisionesRegistradas" ruta3="/seriesRegistradas" ></ComponenteMenuEmsoresEmisionesSeries>
    );
}

