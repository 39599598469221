import React from "react";

//Components
import BannerTitulo from "../Bannertitulo";
import ComponenteModal from "../ComponenteModal";
import ManejadorErrores from "../ManejadorErrores";
import PantallaCargando from "../PantallaCargando";

//APIs
import { descargarDocDesmaterializacion } from "../../api/desmaterializar/descargarDocDesmaterializacion";
import { actualizarDesmaterializacion } from "../../api/desmaterializar/actualizarDesmaterializacion";


export default function VerInfoDesmaterializacion({ history }) {
    if (history.location.state === undefined) {
        history.go(-1)
    }

    const titulo = history.location.state.objMenu.titulo;
    const codigoEmisor = history.location.state.objMenu.codigoEmisor
    const nombreEmisor = history.location.state.objMenu.nombreEmisor
    const codigoEmision = history.location.state.objMenu.codigoEmision
    const nombreEmision = history.location.state.objMenu.nombreEmision
    const fechaDoc1 = history.location.state.objMenu.fechaDoc1
    const fechaDoc2 = history.location.state.objMenu.fechaDoc2
    const lugarRegistro = history.location.state.objMenu.lugarRegistro
    const estatus = history.location.state.objMenu.estatus
    const nombreDoc1 = history.location.state.objMenu.nombreDoc1
    const nombreDoc2 = history.location.state.objMenu.nombreDoc2
    const idSolicitud = history.location.state.objMenu.idSolicitud


    const [registroExitoso, setRegistroExitoso] = React.useState()
    const [registroFallido, setRegistroFallido] = React.useState()


    const [erroreshttp, setErroreshttp] = React.useState()
    const [loading, setLoading] = React.useState(false);


    const [errorsinMotivoRechazo, setErrorSinMotivoDeRechazo] = React.useState()
    const [motivoRechazo, setMotivoRechazo] = React.useState();
    const [desplegarCampoParaTexto, setDesplegarCampoParaTexto] = React.useState();

    const [mensaje, setMensaje] = React.useState()



    const descargarDoc = async (nombreDoc) => {


        const myObj = {
            fileName: nombreDoc
        }

        const descargar = await descargarDocDesmaterializacion(myObj)

        if (descargar) {
            setLoading(false)
            if ((isNaN(descargar.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(descargar)
            } else {


                // setRegistroExitoso(true)
                //console.log("descargado")
                const file = new Blob(
                    [descargar],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);


            }
        }
    }

    const rechazarDesmaterializacion = async (e) => {
        e.preventDefault();
        //console.log("rechazar  desma")

        const myObj = {
            estado: 0,
            idSolicitud: idSolicitud,
            descripcion: motivoRechazo
        }

        if (motivoRechazo) {
            const enviarDatos = await actualizarDesmaterializacion(myObj)
            if (enviarDatos) {
                setLoading(false)
                if ((isNaN(enviarDatos.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(enviarDatos)
                } else {

                    if (enviarDatos?.codigo === "000") {
                        setMensaje("Solicitud de desmaterialización rechazada")
                        setRegistroExitoso(true)
                    } else {
                        setMensaje(enviarDatos.mensaje)
                        setRegistroFallido(true)
                    }
                }
            }
        } else {
            setLoading(false)
            setErrorSinMotivoDeRechazo(true)
        }
    }

    const autorizarDesmaterializacion = async (e) => {
        e.preventDefault();
        //console.log("autorizar  desma")

        const myObj = {
            estado: 2,
            idSolicitud: idSolicitud,
            descripcion: motivoRechazo
        }

        const enviarDatos = await actualizarDesmaterializacion(myObj)
        //console.log(enviarDatos)
        if (enviarDatos) {
            setLoading(false)
            if ((isNaN(enviarDatos.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(enviarDatos)
            } else {

                if (enviarDatos?.codigo === "000") {
                    setMensaje("Solicitud de desmaterialización autorizada")
                    setRegistroExitoso(true)

                } else {
                    setMensaje(enviarDatos.mensaje)
                    setRegistroFallido(true)
                }
            }
        }

    }

    const desmaterializarValores = async (e) => {
        e.preventDefault();
        const myObj = {
            estado: 3,
            idSolicitud: idSolicitud,
            descripcion: motivoRechazo
        }

        const enviarDatos = await actualizarDesmaterializacion(myObj)
        if (enviarDatos) {
            setLoading(false)
            if ((isNaN(enviarDatos.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(enviarDatos)
            } else {

                if (enviarDatos?.codigo === "000") {
                    setMensaje("Se desmaterializó la emisión " + codigoEmision + " y las series pertenecientes a ella")
                    setRegistroExitoso(true)

                } else {
                    setMensaje(enviarDatos.mensaje)
                    setRegistroFallido(true)
                }
            }
        }
    }





    const irInicio = () => {
        setRegistroExitoso(false)
        history.go(-1)
    }

    return (
        <div className="fluid-container">
            {loading &&
                <PantallaCargando />
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }


            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={irInicio} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }
            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={() => { setRegistroFallido(false) }} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {errorsinMotivoRechazo &&
                <ComponenteModal mostrarModal={errorsinMotivoRechazo} cerrarModal={() => setErrorSinMotivoDeRechazo(false)} titulo="Tienes que agregar un motivo de rechazo" textoBoton="Entendido"></ComponenteModal>
            }

            <main className="min-height-40" >
                <div className="row row-wrap g-0">
                    <div className="col-11">
                        <div className="padding-r-1rem">

                            <div className="row d-flex padding-l-1rem">

                                <div className="col-11">
                                    <BannerTitulo titulo={titulo}></BannerTitulo>
                                    <div style={{ border: "transparent" }} className="card ">

                                        <div className="card-body ">
                                            <div className="d-grid gap-1 col-10 mx-auto">
                                                <form className="row g-3 needs-validation" novalidate >
                                                    <div className="card" style={{ color: "#242848" }}>
                                                        <div className="card-body">
                                                            <div className="row mb-3">
                                                                <div className="col-md-6 ">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" value={codigoEmisor} id="codigoEmisor" name="codigoEmisor"  disabled required />
                                                                        <label htmlFor="codigoEmisor" className=" textoFormularioDepositantedirecto">Código del emisor</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 ">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" value={nombreEmisor} id="nombreEmisor" name="nombreEmisor" required disabled />
                                                                        <label htmlFor="nombreEmisor" className=" textoFormularioDepositantedirecto">Nombre del emisor </label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-md-6 ">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" value={codigoEmision} id="codigoEmision" name="codigoEmision" required disabled />
                                                                        <label htmlFor="codigoEmision" className=" textoFormularioDepositantedirecto">Código de la emisión </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 ">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" value={nombreEmision} id="nombreEmision" name="nombreEmision" required disabled />
                                                                        <label htmlFor="nombreEmision" className=" textoFormularioDepositantedirecto">Nombre de la emisión </label>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div className="row mb-3">
                                                                <div className="col-md-6 ">
                                                                    <div className="row justify-content-center">
                                                                        <div onClick={() => descargarDoc(nombreDoc1)} className="col-12 row justify-content-center">
                                                                            <button type="button" class="btn btn-light col-12 form-floating boton drop-area2 row row-wrap justify-content-center align-items-center border-dashed py-5 px-2 rounded">

                                                                                <i className="ri-download-2-line ri-3x text-center text-royal-blue"></i>

                                                                                <input placeholder="" id="input-file1" type="file" hidden />
                                                                                <label htmlFor="nombreEmision" className=" textoFormularioDepositantedirecto">Dictamen 1</label>
                                                                            </button>
                                                                        </div>

                                                                        <div className="col-12 row">
                                                                            <div id="preview-file" className="col-12  g-0 mt-1">
                                                                                <span className="text-royal-blue font-acumin-variable">Descargar pdf</span>
                                                                           

                                                                            </div>
                                                                        </div>



                                                                    </div>
                                                                </div>
                                                                {nombreDoc2 ?
                                                                    <div className="col-md-6 ">
                                                                        <form className="row justify-content-center">
                                                                            <div onClick={() => descargarDoc(nombreDoc2)} className="col-12 row justify-content-center">
                                                                                <button type="button" class="btn btn-light col-12 form-floating boton drop-area2 row row-wrap justify-content-center align-items-center border-dashed py-5 px-2 rounded">


                                                                                    <i className="ri-download-2-line ri-3x text-center text-royal-blue"></i>

                                                                                    <input id="input-file2" type="file" hidden />
                                                                                    <label htmlFor="nombreEmision" className=" textoFormularioDepositantedirecto">Dictamen 2</label>

                                                                                </button>
                                                                            </div>

                                                                            <div className="col-12 row">
                                                                                <div id="preview-file" className="col-12  g-0 mt-1">
                                                                                    <span className="text-royal-blue font-acumin-variable">Descargar pdf</span>

                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    :
                                                                    <div className="col-md-6 text-center align-self-center">
                                                                        <h4 className="text-royal-blue font-acumin-variable">No hay dictamen 2</h4>
                                                                    </div>
                                                                }


                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-md-6 ">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" value={fechaDoc1} id="fechaDocumento1" name="fechaDocumento1"  required disabled />
                                                                        <label htmlFor="fechaDocumento1" className=" textoFormularioDepositantedirecto">Fecha documento 1</label>
                                                                    </div>
                                                                </div>
                                                                {fechaDoc2 &&
                                                                    <div className="col-md-6 ">
                                                                        <div className="form-floating">
                                                                            <input type="text" className="form-control textoFormularioDepositantedirecto" value={fechaDoc2} id="fechaDocumento2" name="fechaDocumento2"  required disabled />
                                                                            <label htmlFor="fechaDocumento2" className=" textoFormularioDepositantedirecto">Fecha documento 2</label>
                                                                        </div>
                                                                    </div>
                                                                }

                                                            </div>


                                                            <div className="row mb-3">
                                                                <div className="col-md-6 ">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" value={lugarRegistro} id="lugarRegistro" name="lugarRegistro"  required disabled />
                                                                        <label htmlFor="codigoEmision" className=" textoFormularioDepositantedirecto">Lugar de registro</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-12 mb-3 row justify-content-start">
                                                            {estatus === 1 ?
                                                                <>
                                                                    {desplegarCampoParaTexto ?
                                                                        <div className="ms-3">


                                                                            <div class="form-floating">
                                                                                <textarea class="form-control" placeholder="Leave a comment here" onChange={(e) => {
                                                                                    setMotivoRechazo(e.target.value)
                                                                                }} id="floatingTextarea2" style={{ height: "7rem" }} required></textarea>
                                                                                <label for="floatingTextarea2" className="">Escriba el motivo del rechazo</label>
                                                                            </div>

                                                                            <div className='contenedorBotonesStteper  '>
                                                                                <button type="button" onClick={() => { setDesplegarCampoParaTexto(false) }}
                                                                                    className="btn botonFormularioDepositanteRechazar mt-2">Cancelar</button>

                                                                                <button type="submit" className="btn botonFormularioDepositante  mt-2" onClick={(e) => {
                                                                                    rechazarDesmaterializacion(e)
                                                                                    setLoading(true)
                                                                                }}>Enviar</button>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <>
                                                                            <div className="form-floating mx-3 row col-4 col-md-3  marginButtonAprove">
                                                                                <button onClick={(e) => {
                                                                                    autorizarDesmaterializacion(e)
                                                                                    setLoading(true)
                                                                                }} className="btn background-royal-blue text-white bg-hover-gold col-12 col-md-12" >Autorizar </button>
                                                                            </div>

                                                                            <div className="form-floating  row col-4 col-md-3">
                                                                                <button type="button" onClick={() => { setDesplegarCampoParaTexto(true) }}
                                                                                    className="btn background-red text-f7 col-12 col-md-12" >Rechazar </button>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </>
                                                                :

                                                                <>

                                                                    <div className="form-floating mx-3  row col-4 col-md-3  marginButtonAprove">
                                                                        <button onClick={(e) => {
                                                                            desmaterializarValores(e)
                                                                            setLoading(true)
                                                                        }} className="btn background-royal-blue text-white bg-hover-gold col-12 col-md-12" >Desmaterializar</button>
                                                                    </div>

                                                                    <div className="form-floating  row col-4 col-md-3">
                                                                        <button type="button" onClick={irInicio}
                                                                            className="btn background-royal-blue text-white bg-hover-gold col-12 col-md-12" >Regresar </button>
                                                                    </div>

                                                                </>

                                                            }
                                                        </div>

                                                     s
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );


}
