import React from "react";
import '../../../../styles/stylesheet.css'
import { FormContext } from './form_context';
import { TraerActividadEconomica } from "../../../../api/catalogos/traerActividadEconomica";
import { traerNacionalidad } from "../../../../api/catalogos/traerNacionalidad";
import { insertarActividadEconomica } from "../../../../api/InsertarACatalogos/insertarActividadEconomica";

import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";
import SpinnerSend from "../../../../ComponentesGenerales/SpinnerSend";

export default function RegistroDI1(props) {

    const [value, setValue] = React.useContext(FormContext);
    const [actividadEconomica, setActividadEconomica] = React.useState([])
    const [nacionalidad, setNacionalidad] = React.useState([])
    const [agregarActividadEconomica, setAgregarActividadEconomica] = React.useState()
    const [actividadNueva, setActividadNueva] = React.useState()
    const [fechaLimiteDocumentos, setFechaLimiteDocumentos] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [spinSend, setSpinSend] = React.useState(false)


    const guardarDatos = (e) => {

        const { name, value } = e.target;

        if (name === "actividadEconomicaPersonaJuridica") {
            if (value === "OTRO") {
                setAgregarActividadEconomica(true)
            } else {
                setValue((prev) => {
                    const datos = { ...prev.datos, [name]: value };
                    return { ...prev, datos };
                });
                setAgregarActividadEconomica(false)
            }

        } else {
            setValue((prev) => {
                const datos = { ...prev.datos, [name]: value };
                return { ...prev, datos };
            });
        }


    }

    React.useEffect(() => {

        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 0 };
            return { ...prev, datos };
        });

        establecerFecha()
        traerDatos()
    }, [])

    const establecerFecha = () => {
        const fecha = new Date()
        const añoActual = fecha.getFullYear();
        let diaActual = fecha.getDate();
        let mesActual = fecha.getMonth() + 1;
        if (diaActual < 10) {
            diaActual = "0" + diaActual
        }
        if (mesActual < 10) {
            mesActual = "0" + mesActual
        }
        const fecha_limite_documentos = (añoActual) + "-" + mesActual + "-" + (diaActual)
        setFechaLimiteDocumentos(fecha_limite_documentos)
    }


    const traerDatos = async () => {
        const catalogoActividad = await TraerActividadEconomica()
        if (catalogoActividad) {
            //setSpin(false)
            if ((isNaN(catalogoActividad.error)) === false) {
                setErroreshttp(catalogoActividad)
            } else {
                setActividadEconomica(catalogoActividad)
            }
        }

        const catalogoNacionalidad = await traerNacionalidad()
        if (catalogoNacionalidad) {
            if ((isNaN(catalogoNacionalidad.error)) === false) {
                setErroreshttp(catalogoNacionalidad)
            } else {
                setNacionalidad(catalogoNacionalidad)
            }
        }


    }




    const checarNumeros = (e) => {
        const { name, value } = e.target;
        let out = '';
        let filtro;
        if(name==="tomoFolio"){
            filtro = '1234567890/';//Caracteres validos para tomo/folio
        }else{
            filtro = '1234567890';//Caracteres validos para rtn
        }
       
        //Recorrer el texto y verificar si el caracter se encuentra en la lista de validos 
        for (let i = 0; i < value.length; i++)
            if (filtro.indexOf(value.charAt(i)) != -1)
                //Se añaden a la salida los caracteres validos
                out += value.charAt(i);
         //guarda el resultado en la variable correspondiente
        setValue((prev) => {
            const datos = { ...prev.datos, [name]: out };
            return { ...prev, datos };
        });
    }

    const otraActividad = async () => {
        const obj = {
            nombre: actividadNueva.toUpperCase()
        }
        const agregarNuevaActividadEconomica = await insertarActividadEconomica(obj)
        if (agregarNuevaActividadEconomica) {
            setSpinSend(false)
            if ((isNaN(agregarNuevaActividadEconomica.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(agregarNuevaActividadEconomica)
            } else {
                if (agregarNuevaActividadEconomica?.codigo === "000") {

                    //setActividadEconomica(await TraerActividadEconomica())
                    const catalogoActividadEconomica = await TraerActividadEconomica()
                    if (catalogoActividadEconomica) {
                        if ((isNaN(catalogoActividadEconomica.error)) === false) {
                            // respuestaincorrecta           
                            setErroreshttp(catalogoActividadEconomica)
                        } else {
                            setActividadEconomica(catalogoActividadEconomica)
                            setAgregarActividadEconomica(false)
                            const myObj = {
                                target: {
                                    value: (actividadEconomica[actividadEconomica.length - 1].id) + 1,
                                    name: "actividadEconomicaPersonaJuridica"
                                }
                            }
                            guardarDatos(myObj)
                        }
                    }
                }
            }
        }
    }

    const abrirOtra = () => {
        //console.log("entra")
        setAgregarActividadEconomica(true)
    }



    return (
        <>

            <div className="card-body  pt-3">
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }

                <h5 className="text-royal-blue  font-acumin-variable" >I. Identificación de la institución</h5>
             
                <div className="row mb-3">
                    <div className="col-md-6 mb-3">
                        <div className="form-floating">
                            <input placeholder="" type="text" className="form-control" value={value.datos.nombre} onChange={(e) => guardarDatos(e)} id="nombre" name="nombre" required />
                            <label htmlFor="validationCustom02" className="form-label  textoFormularioDepositantedirecto">Nombre completo de la institución *</label>
                        </div>
                    </div>
                    <div className="col-md-6  ">
                        <div className="form-floating">
                            <select className="form-select" value={value.datos.actividadEconomicaPersonaJuridica} onChange={(e) => guardarDatos(e)} id="actividadEconomicaPersonaJuridica" name="actividadEconomicaPersonaJuridica" required>
                                <option value=""> </option>
                                {
                                    actividadEconomica.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                                <option onclick={() => abrirOtra()}  >OTRO</option>
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Actividad económica *</label>
                        </div>
                    </div>
                </div>


                {agregarActividadEconomica &&
                    <>
                        {spinSend ?
                            <div className="row mb-3 d-flex justify-content-end">
                                <div className="col-md-6 text-center">
                                    <SpinnerSend textoSpinner="Enviando..."></SpinnerSend>
                                </div>
                            </div>
                            :
                            <div className="row mb-3 d-flex justify-content-end">
                                <div className="col-md-4 ">
                                    <div className="form-floating">
                                        < input placeholder="" type="text" className="form-control border  r" onChange={(e) => setActividadNueva(e.target.value)} required />
                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Ingrese actividad económica *</label>
                                    </div>
                                </div>
                                <div class="col-md-2  d-flex align-items-end">

                                    <button type="button" onClick={() => {
                                        setSpinSend(true)
                                        otraActividad()
                                    }} className="btn botonFormularioDepositante" >Ingresar</button>
                                </div>
                            </div>
                        }
                    </>
                }



                <div className="row mb-3">
                    <div className="col-md-6 mb-3">
                        <div className="form-floating">
                            <input placeholder="" type="text" value={value.datos.rtn} maxLength={14} className="form-control"  onChange={(e) => checarNumeros(e)} id="rtn" name="rtn" />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto"> Registro tributario nacional (si aplica)</label>
                            <div id="emailHelp" class=" emailHelp form-text ps-1">Solo números</div>
                        </div>
                    </div>

                    <div className="col-md-6 ">
                        <div className="form-floating">
                            <select className="form-select " value={value.datos.nacionalidadPersonaJuridica} onChange={(e) => guardarDatos(e)} id="nacionalidadPersonaJuridica" name="nacionalidadPersonaJuridica" required>
                                <option value=""> </option>
                                {
                                    nacionalidad.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Nacionalidad *</label>
                        </div>
                    </div>
                </div>


                <div className="row mb-3">
                    <div className="col-md-6 mb-3">
                        <div className="form-floating">
                            <input placeholder="" type="date" max={fechaLimiteDocumentos} className="form-control" value={value.datos.fechaConstitucion} onChange={(e) => guardarDatos(e)} id="fechaConstitucion" name="fechaConstitucion" required />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Fecha de constitución *</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating">
                            <input placeholder="" type="texto" className="form-control" maxLength={30} value={value.datos.numeroEscritura} onChange={(e) => checarNumeros(e)}  id="numeroEscritura" name="numeroEscritura" required />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Número de escritura original *</label>
                            <div id="emailHelp" class="emailHelp form-text ps-1">Solo números</div>
                        </div>
                  
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-6 mb-3">
                        <div className="form-floating">
                            <input placeholder="" type="text" className="form-control" value={value.datos.tomoFolio} onChange={(e) => checarNumeros(e)} id="tomoFolio" name="tomoFolio" required />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Tomo/folio *</label>
                            <div id="emailHelp" class=" emailHelp form-text ps-1">Ejemplo: 1234/1839</div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-floating">
                            <input placeholder="" type="date" className="form-control" max={fechaLimiteDocumentos} value={value.datos.fechaUltimaModificacion} onChange={(e) => guardarDatos(e)} id="fechaUltimaModificacion" name="fechaUltimaModificacion" required />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Fecha última modificación *</label>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

