import { instance } from '../apiGlobal';

export async function enviarRegistroEmisor(obj) {
   
      try {
        const response = await instance(`/emisor/registro`, {
          method:"post",
          data:obj
        })
       
        return response.data
      } catch (error) {
        return error
      }
}