
import { instanciaBitacoraSistema } from '../apiGlobal';


export async function traerInfoBitacoraSistema() {
  try {
    const response = await instanciaBitacoraSistema.get(`/bitacorasistema/consultar`, {
      headers: {
        'Content-Type': 'application/json',   
        
      }
    })
    //console.log(response)
    return response.data
    
  } catch (error) {
    return error
  }
}
