import React from "react";
import '../../../../styles/stylesheet.css'
import { FormContext } from "./form_context";
import { traerPaises } from '../../../../api/catalogos/traerPaises'
import { traerDepartamentos } from '../../../../api/catalogos/traerDepartamentos'
import { traerCiudades } from '../../../../api/catalogos/traerCiudades'
import { traerMunicipios } from '../../../../api/catalogos/traerMunicipios'
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";

export default function REGISTRO_DI_1_5({ history }) {
    const [value, setValue] = React.useContext(FormContext);
    const [paises, setPaises] = React.useState([])
    const [departamentos, setDepartamentos] = React.useState([])
    const [municipios, setMunicipios] = React.useState([])
    const [ciudades, setCiudades] = React.useState([])
    const [erroreshttp, setErroreshttp] = React.useState()

    const guardarDatos = (e) => {
        const { name, value } = e.target;
        if (name === "pais") {          
            if(value==3){
                //console.log("entra")
                limpiarDomicilioManual()
             
                setValue((prev) => {
                    const datos = { ...prev.datos, ["paisHonduras"]: true };
                    return { ...prev, datos };
                });
            }else {
                limpiarDomicilioAutomatico()
                setValue((prev) => {
                    const datos = { ...prev.datos, ["paisHonduras"]: false };
                    return { ...prev, datos };
                });
               
            }       
        } 

        setValue((prev) => {
            const datos = { ...prev.datos, [name]: value };
            return { ...prev, datos };
        });
    }

    const limpiarDomicilioAutomatico=()=>{
        setValue((prev) => {
            const datos = { ...prev.datos, ["departamento"]: "" };
            return { ...prev, datos };
        });

        setValue((prev) => {
            const datos = { ...prev.datos, ["municipio"]: "" };
            return { ...prev, datos };
        });

        setValue((prev) => {
            const datos = { ...prev.datos, ["ciudad"]: "" };
            return { ...prev, datos };
        });
        
    }

    const limpiarDomicilioManual=()=>{
        setValue((prev) => {
            const datos = { ...prev.datos, ["departamentoManual"]: "" };
            return { ...prev, datos };
        });

        setValue((prev) => {
            const datos = { ...prev.datos, ["municipioManual"]: "" };
            return { ...prev, datos };
        });

        setValue((prev) => {
            const datos = { ...prev.datos, ["ciudadManual"]: "" };
            return { ...prev, datos };
        });

    }

    React.useEffect(() => {
    
            setValue((prev) => {
                const datos = { ...prev.datos, ["enviar"]: 0 };
                return { ...prev, datos };
            });  

        traerDatos()
    }, [])

    const traerDatos = async () => {
        const catalogoPaises = await traerPaises();
        if (catalogoPaises) {
            //setSpin(false)
            if ((isNaN(catalogoPaises.error)) === false) {
              
                setErroreshttp(catalogoPaises)
                
            } else {
                // //console.log(traerInformacion)
                setPaises(await traerPaises())
            }
        }

        if(value.datos.paisHonduras){
            if (!(isNaN(parseInt(value.datos.pais, 10)))) {

                obtenerDepartamentos({ target: { value: value.datos.pais } })
                obtenerMunicipios({ target: { value: value.datos.departamento } })
                obtenerCiudades({ target: { value: value.datos.municipio } })
            }
        }
    }

    const obtenerDepartamentos = async (e) => {
        // //console.log(e.target.value)      
        const catalogosDepartamentos = await traerDepartamentos(e.target.value)
        if (catalogosDepartamentos) {
            //setSpin(false)
            if ((isNaN(catalogosDepartamentos.error)) === false) {
              
                setErroreshttp(catalogosDepartamentos)
                
            } else {
                // //console.log(traerInformacion)
                setDepartamentos(catalogosDepartamentos)

            }
        }
    }

    const obtenerMunicipios = async (e) => {
        const catalogoMunicipios = await traerMunicipios(e.target.value)
        if (catalogoMunicipios) {
            //setSpin(false)
            if ((isNaN(catalogoMunicipios.error)) === false) {
              
                setErroreshttp(catalogoMunicipios)
                
            } else {
                // //console.log(traerInformacion)
                setMunicipios(catalogoMunicipios)
            }
        }
    }

    const obtenerCiudades = async (e) => {

        const catalogoCiudades = await traerCiudades(e.target.value)
        if (catalogoCiudades) {
            //setSpin(false)
            if ((isNaN(catalogoCiudades.error)) === false) {
              
                setErroreshttp(catalogoCiudades)
                
            } else {
                // //console.log(traerInformacion)
                setCiudades(catalogoCiudades)
            }
        }
    }

    return (

        <div className="card-body pt-3">
            {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
            <h5 className="text-royal-blue font-acumin-variable ">Dirección completa del domicilio</h5>
            <h6 className="text-royal-blue font-acumin-variable ">Inicie con el país </h6>
            <div className="row mb-3">

                <div className="col-md-6 ">
                    <div className="form-floating mb-3">
                        <select className="form-select " value={value.datos.pais} onChange={(e) => guardarDatos(e)} onInput={(e) => obtenerDepartamentos(e)} id="pais" name="pais" required>
                            <option value=""> </option>
                            {
                                paises.map((item, index) => (
                                    <option key={index} value={item.id} >{item.nombre}</option>
                                ))
                            }
                        </select>
                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">País *</label>
                    </div>
                </div>

                <div className="col-md-6 ">
                    <div className="form-floating mb-3">
                        <input placeholder="" type="text" className="form-control " value={value.datos.colonia} onChange={(e) => guardarDatos(e)} id="colonia" name="colonia" required />
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Colonia o barrio *</label>
                    </div>
                </div>

                {value.datos.paisHonduras ?
                    <div className="col-md-6 ">
                        <div className="form-floating mb-3">
                            <select className="form-select " value={value.datos.departamento} onChange={(e) => guardarDatos(e)} onInput={(e) => obtenerMunicipios(e)} id="departamento" name="departamento" required>
                                <option value=""> </option>
                                {
                                    departamentos.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Departamento *</label>
                        </div>
                    </div>
                    :
                    <div className="col-md-6 ">
                        <div className="form-floating mb-3">
                            <input placeholder="" type="text" className="form-control" value={value.datos.departamentoManual} onChange={(e) => guardarDatos(e)} id="departamentoManual" name="departamentoManual" />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Departamento</label>
                        </div>
                    </div>}




                <div className="col-md-6 ">
                    <div className="form-floating mb-3">
                        <input placeholder="" type="text" className="form-control" value={value.datos.calle} onChange={(e) => guardarDatos(e)} id="calle" name="calle" />
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Calle, avenida o bloque</label>
                    </div>
                    
                </div>

                {value.datos.paisHonduras ?
                    <div className="col-md-6 ">
                        <div className="form-floating mb-3">
                            <select className="form-select " value={value.datos.municipio} onChange={(e) => guardarDatos(e)} onInput={(e) => obtenerCiudades(e)} id="municipio" name="municipio" required>
                                <option value=""> </option>
                                {
                                    municipios.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Municipio *</label>
                        </div>
                    </div> :
                    <div className="col-md-6 ">
                        <div className="form-floating mb-3">
                            <input placeholder="" type="text" className="form-control" value={value.datos.municipioManual} onChange={(e) => guardarDatos(e)} id="municipioManual" name="municipioManual" />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Municipio</label>
                        </div>
                    </div>}


                <div className="col-md-6 ">
                    <div className="form-floating mb-3">
                        <input placeholder="" type="text" className="form-control" value={value.datos.edificio} onChange={(e) => guardarDatos(e)} id="edificio" name="edificio" />
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Número de casa</label>
                    </div>
                </div>

                {value.datos.paisHonduras ?
                    <div className="col-md-6 ">
                        <div className="form-floating mb-3">
                            <select className="form-select " value={value.datos.ciudad} onChange={(e) => guardarDatos(e)} id="ciudad" name="ciudad" required>
                                <option value=""> </option>
                                {
                                    ciudades.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Ciudad *</label>
                        </div>
                    </div> :
                    <div className="col-md-6 ">
                        <div className="form-floating mb-3">
                            <input placeholder="" type="text" className="form-control" value={value.datos.ciudadManual} onChange={(e) => guardarDatos(e)} id="ciudadManual" name="ciudadManual" required />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Ciudad *</label>
                        </div>
                    </div>}



                <div className="col-md-6 form-floating mb-3">
                    <div className="form-floating mb-3">
                        <input placeholder="" type="text" maxLength={10} className="form-control" value={value.datos.codigoPostal} onChange={(e) => guardarDatos(e)} id="codigoPostal" name="codigoPostal" />
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Código postal</label>
                    </div>
                </div>
            </div>
        </div>


    );
}

