
import { instance } from '../apiGlobal';

export async function traerEmisiones(obj) {
  
  try {
    const response = await instance(`/emision/consultar/todas/estatus`, {
      method: "post",
      data: obj
    })
    return response.data;
  } catch (error) {
    return error;
  }
}
