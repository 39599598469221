import React from "react";
import { Link } from "react-router-dom";

import logoBlancoBCV from "../assets/logosCehval/LOGO BLANCO-01-12.png";
import logoBlancoCehval from '../assets/logosCehval/CEHVAL_001.png'

export default function Footer() {
  return (
    <>
      <footer className="footer text-center text-lg-start  textoFooter background-royal-blue">
        <div className="container-fluid pt-3">

          <div className="row font-acumin-variable">
            <div className="col-md-4 col-lg-4 col-xl-3 text-center mb-4">
              <img id="logo-cehval-header"  style={{ width: process.env.REACT_APP_logo_footer_width,height: process.env.REACT_APP_logo_footer_height}} className="logoFooter" src={process.env.REACT_APP_logo_footer} alt="Cehval" />
             {/*} <img id="" className="logoFooterCehval" src={logoBlancoCehval} alt="Cehval" />*/}
            </div>
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6 className=" fs-5 fw-bold mb-2 tituloFooter ">Encuéntranos</h6>
              <p className="textoFooter  fs-6">
                Col. Lomas del Guijarro Sur Boulevard San Juan Bosco, edificio Torre Alianza II (anexo Torre Alianza), 5to nivel Tegucigalpa Francisco Morazán 3885 Honduras.
              </p>
            </div>
            <div className="col-md-2 col-lg-3 col-xl-3 mx-auto mb-4">
              <h6 className="fs-5 fw-bold mb-2 tituloFooter">Horarios de atención</h6>
              <p className="textoFooter fs-6">
                Lunes a viernes  8:30 A.M. - 5:00 P.M.
              </p>
            </div>
          </div>
          
          <div className="d-none d-lg-flex row justify-content-start align-items-center px-5 g-0">
              {/*Enlaces para redes sociales escritorio*/}
            <span className="col-auto fs-6 font-acumin-variable ">Siguenos en nuestras redes sociales:</span>
            <div className="col-6 p-0">
              <Link className="text-white text-no-decoration mx-1">
                <i className="ri-facebook-circle-fill fs-medium align-middle"></i>
              </Link>
              <Link className="text-white text-no-decoration mx-1">
                <i className="ri-linkedin-box-fill fs-medium align-middle"></i>
              </Link>
              <Link className="text-white text-no-decoration mx-1">
                <i className="ri-instagram-fill fs-medium align-middle"></i>
              </Link>
            </div>
          </div>
          <div className="d-md-flex d-lg-none row justify-content-center align-items-center px-5 g-0">
              {/*Enlaces para redes sociales movil*/}
            <div className="col-6 p-0">
              <Link className="text-white text-no-decoration mx-1">
                <i className="ri-facebook-circle-fill fs-medium align-middle"></i>
              </Link>
              <Link className="text-white text-no-decoration mx-1">
                <i className="ri-linkedin-box-fill fs-medium align-middle"></i>
              </Link>
              <Link className="text-white text-no-decoration mx-1">
                <i className="ri-instagram-fill fs-medium align-middle"></i>
              </Link>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-3 font-acumin-variable g-0" >
          <p className="col-12 col-md-10 text-center m-0 p-2"> © 2021 Todos los derechos reservados</p>
        </div>
      </footer>

    </>
  );
}



