import React from "react";
import '../../../styles/stylesheet.css'


import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
//import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";
import useFondosPorSubastaYCliente from "../../../hooks/MercadoPrimario/useFondosPorSubastaYCliente";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";

import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";
//import { useLocation } from "react-router-dom";




//import { traerInformacionAllDepositante } from "../../../api/VerInformacionUsuarios/traerInformacionAllDepositantes";
import { consultarMontosAcumuladosClienteFondeo } from "../../../api/Mercadoprimario/consultarMontosAcumuladosClienteFondeo";
import { imprimirTablaMontosAcumulados } from "../../../api/Mercadoprimario/imprimirTablaMontosAcumulados";

export default function MontosPorClientesYSubasta({ history }) {
    if (history.location.state === undefined) {
        history.go(-1)
    }
    
    //const location = useLocation();
    //const estatus = 4;
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    //const [copia2, setCopia2] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()
    
    //const [tablaCancelar, setTablaCancelar] = React.useState()
    const [loading, setLoading] = React.useState(false);



    React.useEffect(() => {
        traerInformacion()

    }, [])




    const traerInformacion = async () => {

        const myobj = {
            codigoSubasta: history.location.state.objMenu.codigoSubasta,
            monedaId: history.location.state.objMenu.monedaId
        }
        //console.log(history.location.state.objMenu.monedaId)
        const info = await consultarMontosAcumuladosClienteFondeo(myobj)
        setSpin(false)
        //console.log(info)
        if (info) {
            setSpin(false)
            if ((isNaN(info.error)) === false) {
              
                setErroreshttp(info)
                
            } else {
                setData(info)
                setCopia(info)
            }
        }
    }



    const imprimir = async () => {
        //console.log("imprimir")
        setLoading(true)
        const myobj = {
            codigoSubasta: history.location.state.objMenu.codigoSubasta,
            monedaId: history.location.state.objMenu.monedaId
        }

        const response = await imprimirTablaMontosAcumulados(myobj)
        if (response) {
            //setLoading(false)
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta   
                setLoading(false)
                setErroreshttp(response)
            } else {
                //console.log(response)
                const file = new Blob(
                    [response],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
                setLoading(false)

            }
        }



    }

    const regresar = async () => {
        history.go(-1)
    }





    return (
        <>
            {loading &&
                <PantallaCargando />
            }

            

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            <div className="fluid-container">

                <main className="min-height-40" >
                    <div className="row row-wrap g-0 ">
                        
                        <div className="col-11 col-md-11 w-100">
                            <div className="">
                                <BannerTitulo titulo="Montos disponibles por clientes CEHVAL"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <>
                                                    <div className="row ms-2 font-acumin-variable">
                                                        <div className="col-md-2">  <h5>Subasta </h5></div>
                                                        <div className="col-12 col-md-3 mb-3">
                                                            <input disabled value={data.numeroSubasta} title="Nombre completo de la emisión" className="form-control" name="nombreEmision" type="text" placeholder="Nombre completo de la emision" />
                                                        </div>
                                                    </div>

                                                    <div className="row ms-2 font-acumin-variable">
                                                        <div className="col-md-2">  <h5>Moneda</h5></div>
                                                        <div className="col-12 col-md-3 mb-3">
                                                            <input disabled value={data.moneda} title="Nombre completo de la emisión" className="form-control" name="nombreEmision" type="text" placeholder="Nombre completo de la emision" />
                                                        </div>
                                                    </div>
                                                    <TablaMercado datos={data.depositantes} useValidate={useFondosPorSubastaYCliente} mercadoPrimario={true} funcion1MercadoPrimario={imprimir} funcion2MercadoPrimario={regresar} texto1MercadoPrimario="Imprimir" texto2MercadoPrimario="Regresar" ></TablaMercado>

                                                </>

                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    );
}













