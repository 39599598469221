import React from "react";
import { Link } from 'react-router-dom';


export default function HomePage({ history }) {

    return (
        <>
            <main className="container-min-height">
                <div id="banner" className="row row-wrap  justify-content-center align-items-center g-0">
                    <div id="cardContainerSolicitudRegistro" className="col-10 col-md-6 col-lg-4 d-flex font-acumin-variable" >
                        <div className="card-banner row rounded p-3" >
                            <div className="col-12 row justify-content-center text-white font-acumin-variable text-center g-0">
                                <h5 className="col-12 card-title fw-bold fs-3 border-bottom border-white p-2 text-white">Solicita tu registro</h5>
                                <div className="col-12 p-3">
                                    <p className="text-start fs-5 font-acumin-variable text-white">Si eres una institución financiera o gubernamental, solicita tu registro como depositante directo en este portal.</p>
                                   
                                </div>
                                <Link to="/solicitudRegistroDepositanteDirecto" className=" col-6 btn btn-primary background-gold text-royal-blue button-hover ">Solicitar registro</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

