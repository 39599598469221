import { useMemo } from "react";

export default function useSolicitudGeneracionCodigoISIN() {
  const columns = useMemo(
    () => [
      {
        Header: "Serie",
        accessor: "serie"
      }
    ],
    []
  );

  return columns;
}
