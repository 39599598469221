import { useMemo } from "react";

export default function useOperacionesTLP () {
  const columns = useMemo(
    () => [
      {
        Header: "Fecha de operación",
        accessor: "operacionFecha"
      },
      {
        Header: "Código depositante destino",
        accessor: "destino"
      },
      {
        Header: "Nombre depositante destino",
        accessor: "nombreDestino"
      },
      {
        Header: "ISIN",
        accessor: "isinSerie"
      },
      {
        Header: "Código de serie",
        accessor: "codigoSerie"
      },
      {
        Header: "Nombre de serie",
        accessor: "nombreSerie"
      },
      {
        Header: "Cantidad valores transferidos",
        accessor: "numeroValores"
      },
    ],
    []
  );

  return columns;
}
