import { useMemo } from "react";

export default function useHistoricoDepositoFondosConciliados () {
  const columns = useMemo(
    () => [
      {
        Header: "Folio BCH-TR",
        accessor: "folioBchTr"
      },
      {
        Header: "Código de depositante comprador",
        accessor: "codigoDepositante"
      },
      {
        Header: "Nombre de depositante comprador",
        accessor: "nombreDepositante"
      },
      {
        Header: "Monto",
        accessor: "monto"
      },
      {
        Header: "Moneda",
        accessor: "monedaStr"
      }  ,
      {
        Header: "Fecha de depósito",
        accessor: "fechaDepositoStr"
      }           
    ],
    []
  );

  return columns;
}
