import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";


//Components
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import TablaUsuarios from "../../../../ComponentesGenerales/TablaUsuarios";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";

//APIs
import { consultarCuentaDepositante } from '../../../../api/transferenciaLibreDePago/consultarCuentaDepositante';

//Hooks
import useCuentasClienteDD from "../../../../hooks/useCuentasClienteDD";


export default function CuentasClienteDD({ history }) {
    if (history.location.state === undefined) {
         history.go(-1) 
    }
    //States to show modal when data doesnt load
    const [resquestFail, setRequestFail] = useState();

    const [objetoClienteDD, setObjetoClienteDD] = useState();
    const [nombreClienteSeleccionado, setNombreClienteSeleccionado] = useState();
    const [codigoClienteSeleccionado, setCodigoClienteSeleccionado] = useState();

    const [data, setData] = useState([]);
    const [copia, setCopia] = useState();
    const [erroreshttp, setErroreshttp] = React.useState()

   

    const tipo=history.location.state.objClienteRow.tipo

    useEffect(() => {
        verCuentas();
    }, []);

    const historyReact = useHistory();

    //Handle api to get all client accounts 
    const verCuentas = async () => {

        try {

            //If try to access directly history.location.state will be undefined and will be redirected to /misClientesDD
            if (history.location.state === undefined) {
            } else {

                //Stores the information of the selected row 
                const objetoClienteDD = history.location.state.objClienteRow;
                const { codigoDepositanteIndirecto, nombreDepositanteIndirecto } = objetoClienteDD;

                //States
                setObjetoClienteDD(objetoClienteDD);
                setNombreClienteSeleccionado(nombreDepositanteIndirecto);
                setCodigoClienteSeleccionado(codigoDepositanteIndirecto);

                const response = await consultarCuentaDepositante({ codigo: codigoDepositanteIndirecto });
                if (response) {
                    //setCargando(false)
                    if ((isNaN(response.error)) === false) {
                        // respuestaincorrecta           
                        setErroreshttp(response)
                    } else {
                        if (response.status === 200) {
                            setData(response.data);
                            setCopia(response.data);
                        } else {
                            setRequestFail(true);
                        }
                    }
                }
                
              
            }

        } catch (error) {
            //console.log(error)
        }
    }


    //Handle when user click on "Ver cuenta"
    const verValoresCuenta = (row) => {

        //Identificamos el número de cuenta y lo envíamos a la siguiente pantalla
        const numeroCuenta = row.original.cehvalCuentaValNum;
        const monedaCuenta = row.original.catMonedaCatMonedaId.catMonedaDivisa;

        ////console.log(numeroCuenta)
        const objetoCliente = {
            numeroCuenta: numeroCuenta,
            monedaCuenta: monedaCuenta,
            objCliente: objetoClienteDD,
            tipo:tipo
        }

        history.push({
            pathname: '/valoresClienteDD', state: { datosCliente: objetoCliente }
        });
    }

    //Handle modal if data for table doesn load
    const goHome = () => {
        setRequestFail(false);
        window.location.replace("/inicioCehval");
    }


    return (
        <>
            {resquestFail &&
                <ComponenteModal mostrarModal={resquestFail} cerrarModal={goHome} titulo={`Ocurrio algún error, será redireccionado al inicio. Revise su conexión o vuelva a iniciar sesión.`} textoBoton="Aceptar"></ComponenteModal>
            }
             {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <div className="fluid-container">
                
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Cuentas del cliente"></BannerTitulo>
                                <div className="row justify-content-center align-items-center table-container g-0">

                                    {copia ?

                                        <div className="container py-5">
                                            <form className="px-3 font-acumin-variable">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <div className="form-floating ">
                                                            <input title="Código del depositante" value={codigoClienteSeleccionado} className="form-control text-uppercase" name="codigoDepTransferente" type="text" placeholder="Código del depositante" required disabled />
                                                            <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoDepTransferente">Código del depositante indirecto</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <div className="form-floating ">
                                                            <input title="Número de cuenta de origen" value={nombreClienteSeleccionado} className="form-control text-uppercase" name="nombreDepTransferente" type="text" placeholder="Nombre del depositante" required disabled />
                                                            <label className="text-start font-acumin-variable text-royal-blue" htmlFor="nombreDepTransferente">Nombre del depositante indirecto</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <TablaUsuarios datos={data} useValidate={useCuentasClienteDD} displayInformation={verValoresCuenta} textoBoton={"Ver cuenta"} textTitle="Ver valores de la cuenta" ></TablaUsuarios>
                                            <div className="p-3">
                                                <button className="btn background-royal-blue text-white font-acumin-variable" onClick={() => { historyReact.goBack() }}>Atrás</button>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className="row row-wrap justify-content-center">
                                                <h4 className="col-12 text-center">Cargando...</h4>
                                                <div className="col-12 spinner-border" role="status"></div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    );
}


