import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";


//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";
import ManejadorErrores from '../../../ComponentesGenerales/ManejadorErrores';

//APIs
import { ejecutarTipoOperacionDepositoRetiro } from "../../../api/SalvaguardasFinancieras/ejecutarTipoOperacionDepositoRetiro";
import { obtenerDepositantes } from "../../../api/SalvaguardasFinancieras/obtenerDepositantes";

//Functions
import formatNumber from "../../../functions/FormatNumber";

export default function RetiroDepositoFondoLiquidacion({ history }) {


    const historyReact = useHistory();

    //Array of all depositors
    const [depositantes, setDepositantes] = useState();

    //Inputs
    const [codigoDepositante, setCodigoDepositante] = useState();
    const [nombreDepositante, setNombreDepositante] = useState();
    const [monto, setMonto] = useState();
    const [montoStr, setMontoStrt] = useState();
    
    const [tipo, setTipo] = useState();
    const [folioBCH, setFolioBCH] = useState();
    const [fechaDepositoRetiro, setFechaDepositoRetiro] = useState();
    const [fechaRegistroMovimiento, setFechaRegistroMovimiento] = useState();

    //Show message if transaction was successful
    const [responseRequest, setResponseRequest] = useState();

    //Loading
    const [loading, setLoading] = useState();

    //States to handle load data when loading page 
    const [erroreshttp, setErroreshttp] = useState();


    useEffect(() => {
        establecerFecha();
        getDepositors();
    }, []);

    //Set todays date to input fecha de registro de movimiento
    const establecerFecha = () => {
        const date = new Date();
        const currentDate = date.toLocaleDateString();
        setFechaRegistroMovimiento(currentDate)
    }

    //Gets all depositors that are available to make transactions in fondo de liquidación
    const getDepositors = async () => {
        try {
            const response = await obtenerDepositantes();

            if ((isNaN(response.error)) === false) {
                setErroreshttp(response);

            } else {
                setDepositantes(response.data);

            }
        } catch (error) {
            console.error(error);
        }
    }

    //Handle the input monto
    const handleMonto = (e) => {
        const { value } = e.target;
        const formattedNumber = formatNumber(value);
        
        setMonto(formattedNumber.integerNumber);
        setMontoStrt(formattedNumber.stringNumber);
    }




    const send = async (e) => {
        e.preventDefault();
        try {
            const obj = {
                monto: monto,
                tipo: parseInt(tipo),
                codigoDepositante: codigoDepositante,
                folioBch: folioBCH,
                fechaOperacion: fechaDepositoRetiro,
            }
            //console.log(obj)

            setLoading(true);
            const response = await ejecutarTipoOperacionDepositoRetiro(obj);
            setLoading(false);
            //console.log(response);

            if ((isNaN(response.error)) === false) {
                setErroreshttp(response);

            } else if (response.data) {
                setResponseRequest(response.data.mensaje);
            }





        } catch (error) {
            console.error(error);
        }
    }


    const handleDepositantes = (e) => {
        const { value } = e.target;
        //Codigo de depositante seleccionado
        const codDepositante = value;
        setCodigoDepositante(codDepositante);

        //Agarramos el objeto del depositante seleccionado para mostrar el nombre del depositante
        const filterDepositantes = depositantes?.filter((el) => el.codigoDepositante === codDepositante);
        setNombreDepositante(filterDepositantes[0].nombreDepositante);
    }

    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {responseRequest &&
                <ComponenteModal mostrarModal={responseRequest} cerrarModal={() => historyReact.goBack()} titulo={responseRequest} textoBoton="Aceptar"></ComponenteModal>
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            <div className="fluid-container">
                <main className="mb-5 min-height-40">
                    <div className="row row-wrap g-0">
                     
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Depósito/Retiro a fondo de liquidación"></BannerTitulo>
                                <div className="d-flex justify-content-center px-3 mt-5">
                                    <div className="col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11 col-xxl-11">
                                        <section className="d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded p-3">
                                                <div className="col-12">
                                                    <form onSubmit={send} className="needs-validation font-acumin-variable text-royal-blue">
                                                        <div className="row mt-4">
                                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center mb-3">
                                                                <div className=" col-12 col-md-12 ">
                                                                    <div className="form-floating">
                                                                        <select title="Depositante" className="form-select font-acumin-variable" onChange={(e) => handleDepositantes(e)} value={codigoDepositante} name="depositante" style={{ height: "56px" }} aria-label="Default select example" required>
                                                                            <option value=""> </option>
                                                                            {depositantes &&
                                                                                depositantes.map((registro, index) => (
                                                                                    <option key={registro.codigoDepositante} className="text-uppercase" value={registro.codigoDepositante}>{registro.codigoDepositante}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <label className="font-acumin-variable" htmlFor="depositante">Código de depositante</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Nombre del depositante" className="form-control fs-5 text-royal-blue" value={nombreDepositante} name="nombreDepositante" type="text" placeholder="Nombre del depositante" required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="nombreDepositante">Nombre depositante </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Monto" className="form-control fs-5 text-royal-blue" onChange={handleMonto} value={montoStr} name="monto" type="text" placeholder="Monto" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="monto">Monto </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center mb-3">
                                                                <div className=" col-12 col-md-12 ">
                                                                    <div className="form-floating">
                                                                        <select title="Tipo" className="form-select font-acumin-variable" name="tipo" style={{ height: "56px" }} aria-label="Default select example" required disabled>
                                                                            <option value={1}> USD</option>
                                                                        </select>
                                                                        <label className="font-acumin-variable" htmlFor="tipo">Moneda</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center mb-3">
                                                                <div className=" col-12 col-md-12 ">
                                                                    <div className="form-floating">
                                                                        <select title="Tipo" className="form-select font-acumin-variable" onChange={(e) => setTipo(e.target.value)} name="tipo" style={{ height: "56px" }} aria-label="Default select example" required>
                                                                            <option value=""> </option>
                                                                            <option value={1}>Depósito &#40;Pago&#41;</option>
                                                                            <option value={4}>Depósito &#40;Recomposición&#41;</option>
                                                                            <option value={2}>Retiro &#40;Incumplimiento&#41;</option>
                                                                            <option value={3}>Retiro &#40;Recomposición&#41;</option>
                                                                        </select>
                                                                        <label className="font-acumin-variable" htmlFor="tipo">Tipo de operación</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Folio BCH-TR" className="form-control fs-5 text-royal-blue" onChange={(e) => setFolioBCH(e.target.value)} value={folioBCH} type="text" name="folioBCHTR" placeholder="Folio BCH-TR" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="folioBCHTR">Folio BCH-TR</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Fecha depósito/retiro" className="form-control fs-5 text-royal-blue" onChange={(e) => setFechaDepositoRetiro(e.target.value)} value={fechaDepositoRetiro} type="date" name="fechaDepositoRetiro" placeholder="Fecha depósito retiro" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="fechaDepositoRetiro">Fecha depósito/retiro</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Fecha de registro de movimiento" className="form-control fs-5 text-royal-blue" value={fechaRegistroMovimiento} type="text" name="fechaMovimiento" placeholder="Fecha de registro de movimiento" required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="fechaMovimiento">Fecha de registro de movimiento</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 d-flex justify-content-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end mb-3 mt-4">
                                                                <button type="button" onClick={() => { historyReact.goBack() }} className="btn background-royal-blue text-white bg-hover-red me-2">Regresar</button>
                                                                <button type="submit" className="btn background-royal-blue text-white bg-hover-gold me-2">Enviar</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    )
}