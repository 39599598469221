import React, { useEffect, useState } from "react";
//import { useHistory } from "react-router-dom";

//Componentes generales
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";

//APIs
import { traerTiposCuenta } from "../../../api/catalogos/traerTiposCuenta";
import { traerBancos } from "../../../api/catalogos/traerBancos";
import { registrarEmisorBCV } from "../../../api/emisor/RegistroEmisorBCV/registrarEmisorBCV"


export default function RegistrarEmisorBCV({ history }) {
    if (history.location.state === undefined) {
        history.go(-1);

    }
    

    //EMISOR DATA
    const objEmisores = history.location.state.objEmisores;
    const objEmisor = history.location.state.emisorRow[0];
    const emisorCodigo = objEmisor.emisorCodigo;
    const emisorNombre = objEmisor.emisorNombreCompleto;
    const emisorSector = objEmisor.emisorCatSectorId;
    const emisorSubSector = objEmisor.emisorCatSubSecId;
    const emisorNacionalidad = objEmisor.emisorNacionalidadId;
    const emisorRTN = objEmisor.emisorRtn;
    const emisorPaginaWeb = objEmisor.emisorPagWeb;
    const emisorEstado=objEmisor.emisorEstado

    //REPRESENTANTE LEGAL DATA
    const nombreRepresentanteLegal = objEmisor.emisorNombreCompletoRepreLeg;
    const correoRepresentanteLegal = objEmisor.emisorCorreo;

    //Shows loading screen when executing querys
    const [loading, setLoading] = useState();

    //States to show modal when register is successful or not
    const [registroExitoso, setRegistroExitoso] = useState();
    const [registroExitosoSinCuentasBancarias, setRegistroExitosoSinCuentasBancarias] = useState();
    const [registroFallido, setRegistroFallido] = useState();
    const [erroreshttp, setErroreshttp] = useState();
    const [reasonRegisterFailed, setReasonRegisterFailed] = useState();


    //Catálogos
    const [catTiposDeCuenta, setCatTiposDeCuenta] = useState();
    const [catBancos, setCatBancos] = useState();


    useEffect(() => {
        getCatData();
    }, []);

    //Get the catalogues 
    const getCatData = async () => {

        //Get the type of accounts catalogue
        const catalogoTiposCuenta = await traerTiposCuenta()
        if (catalogoTiposCuenta) {
            //setSpin(false)
            if ((isNaN(catalogoTiposCuenta.error)) === false) {
              
                setErroreshttp(catalogoTiposCuenta)
                
            } else {
                // //console.log(traerInformacion)
                //setActividadEconomica(catalogoNacionalidad)

                setCatTiposDeCuenta(catalogoTiposCuenta)
            }
        }

        //Get the banks catalogue
        const catalogoBancos = await traerBancos();
        if (catalogoBancos) {
            //setSpin(false)
            if ((isNaN(catalogoBancos.error)) === false) {
              
                setErroreshttp(catalogoBancos);
                
            } else {
                // //console.log(traerInformacion)
                setCatBancos(catalogoBancos);
            }
        }
    }


    //ACCOUNT DATA THAT USER FIll
    const [tipoCuentaNacional, setTipoCuentaNacional] = useState("");
    const [bancoNacional, setBancoNacional] = useState("");
    const [numeroCuentaNacional, setNumeroCuentaNacional] = useState("");

    const [tipoCuentaExtranjera, setTipoCuentaExtranjera] = useState("");
    const [bancoExtranjero, setBancoExtranjero] = useState("");
    const [numeroCuentaExtranjera, setNumeroCuentaExtranjera] = useState("");

    const [filteredEmitters, setFilteredEmitters] = useState();

    //Filter the emitters array and pull out the emitter just registered
    const filterEmittersData = () => {
        //Removes the emisor registered from the array of emisores
        const removesEmisor = objEmisores.filter((item) => item.emisorCodigo !== emisorCodigo);
        //New array of emitters set to our state
        //console.log(removesEmisor)
        setFilteredEmitters(removesEmisor);
    }

    const registerWithBankAccounts = () => {
        //Obj to send request
        const emitterWithBankAccounts = {
            emisorCodigo: emisorCodigo,
            emisorNombreCompleto: emisorNombre,
            emisorCatSectorId: emisorSector,
            emisorCatSubSecId: emisorSubSector,
            emisorNacionalidadId: emisorNacionalidad,
            emisorRtn: emisorRTN,
            emisorPagWeb: emisorPaginaWeb,
            emisorNombreCompletoRepreLeg: nombreRepresentanteLegal,
            emisorCorreo: correoRepresentanteLegal,
            emisorEstado: emisorEstado,
            cuentaNacional: {
                monedaId: 1,
                tipoCuentaId: tipoCuentaNacional,
                bancoId: bancoNacional,
                numeroCuenta: numeroCuentaNacional,

            },
            cuentaExtranjera: {
                monedaId: 2,
                tipoCuentaId: tipoCuentaExtranjera,
                bancoId: bancoExtranjero,
                numeroCuenta: numeroCuentaExtranjera,
            }
        }

        return emitterWithBankAccounts;
    }

    const registerWithoutBankAccounts = () => {
         //Obj to send request
         const emitterWithoutBankAccounts = {
            emisorCodigo: emisorCodigo,
            emisorNombreCompleto: emisorNombre,
            emisorCatSectorId: emisorSector,
            emisorCatSubSecId: emisorSubSector,
            emisorNacionalidadId: emisorNacionalidad,
            emisorRtn: emisorRTN,
            emisorPagWeb: emisorPaginaWeb,
            emisorNombreCompletoRepreLeg: nombreRepresentanteLegal,
            emisorCorreo: correoRepresentanteLegal,
            emisorEstado: emisorEstado,
        }

        return emitterWithoutBankAccounts;
    }

    //Send query to register the emitter
    const emitterRegistration = async (e) => {
        e.preventDefault();

        
        if(
            tipoCuentaNacional === "" ||
            tipoCuentaExtranjera === "" || 
            bancoNacional === "" || 
            bancoExtranjero === "" || 
            numeroCuentaNacional === "" || 
            numeroCuentaExtranjera === ""
        ){
            try {
                //console.log(registerWithoutBankAccounts())

                setLoading(true);
                const sendEmitterRegistration = await registrarEmisorBCV(registerWithoutBankAccounts());

                if (sendEmitterRegistration) {

                    if ((isNaN(sendEmitterRegistration.error)) === false) {

                        // //console.log("erores")
                        ////console.log(response)
                        setLoading(false);
                        setErroreshttp(sendEmitterRegistration)
                        
                    } else {
                        setLoading(false);
                        //console.log(sendEmitterRegistration)
            
            
                        if (sendEmitterRegistration.data.isSuccessful === true) {
                            setRegistroExitosoSinCuentasBancarias(true);
                            //Removes the emitter from the emitters board
                            filterEmittersData();
            
                        } else if(sendEmitterRegistration.data.isSuccessful === false) {
                            setRegistroFallido(true);
                            setReasonRegisterFailed(sendEmitterRegistration.data.mensaje);
                        }
                    }

                }

            
    
            } catch (error) {
                console.error(error);
            }
        } 
        
        else if(
        tipoCuentaNacional &&
        tipoCuentaExtranjera && 
        bancoNacional && 
        bancoExtranjero && 
        numeroCuentaNacional && 
        numeroCuentaExtranjera
        ){
            try {

                setLoading(true);
                //console.log(registerWithBankAccounts())

                const sendEmitterRegistration = await registrarEmisorBCV(registerWithBankAccounts());

                if (sendEmitterRegistration) {

                    if ((isNaN(sendEmitterRegistration.error)) === false) {

                        // //console.log("erores")
                        ////console.log(response)
                        setLoading(false);
                        setErroreshttp(sendEmitterRegistration)
                        
                    } else {
                        setLoading(false);
                        //console.log(sendEmitterRegistration)
            
            
                        if (sendEmitterRegistration.data.isSuccessful === true) {
                            setRegistroExitoso(true);
                            //Removes the emitter from the emitters board
                            filterEmittersData();
            
                        } else if(sendEmitterRegistration.data.isSuccessful === false) {
                            setRegistroFallido(true);
                            setReasonRegisterFailed(sendEmitterRegistration.data.mensaje);
                        }
                    }

                }

             
    
            } catch (error) {
                console.error(error);
            }
        }
       
    }

    //Executes when the emitter was successfully registered
    const goToEmittersBoard = () => {
        setRegistroExitoso(false);
        history.push({
            pathname: '/registroEmisoresBCV',
            state: {
                objEmisores: filteredEmitters,
            }
        })
    }

    ///Validates if user enters \ or numbers lower than 0
    const restrictUseBackSlash = (e) => {

        if(e.target.name === "numeroCuentaNacional"){
            if (e.keyCode === 225 || e.target.value < 0) {
                setNumeroCuentaNacional("");
            }
        }
        if(e.target.name === "numeroCuentaExtranjera"){
            if (e.keyCode === 225 ||e.target.value < 0) {
                setNumeroCuentaExtranjera("");
            }
        }
    }

    const regresar=()=>{
       
        const removesEmisor = objEmisores.filter((item) => item.emisorCodigo !== emisorCodigo);
        history.push({
            pathname: '/registroEmisoresBCV',
            state: {
                objEmisores: removesEmisor,
            }
        })
    }
    


    return (

        <>
        
            {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={goToEmittersBoard} titulo="Emisor registrado correctamente" textoBoton="Aceptar"></ComponenteModal>
            }
            {registroExitosoSinCuentasBancarias &&
                <ComponenteModal mostrarModal={registroExitosoSinCuentasBancarias} cerrarModal={goToEmittersBoard} titulo="Emisor registrado correctamente sin cuentas bancarias, podrás agregar sus cuentas posteriormente" textoBoton="Aceptar"></ComponenteModal>
            }
            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={() => { setRegistroFallido(false) }} titulo={`Error al hacer el registro. ${reasonRegisterFailed}.`} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="fluid-container">
                
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Registro de emisores provenientes de la BCV"></BannerTitulo>
                                <div className="row mt-3 g-0 px-5">
                                    <form className="font-acumin-variable text-royal-blue" onSubmit={(e) => emitterRegistration(e)}>
                                        <div className="row mt-3">
                                            <h3>I. Identificación del emisor</h3>
                                            <div className="col-12 col-md-6 mb-3">
                                                <div className="form-floating ">
                                                    <input title="Código del emisor" value={emisorCodigo} className="form-control text-uppercase" type="text" name="codigoEmisor" placeholder="Código emisor" required disabled />
                                                    <label className="text-start font-acumin-variable" htmlFor="codigoEmisor">Código del emisor</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <div className="form-floating ">
                                                    <input title="Nombre del emisor" value={emisorNombre} className="form-control text-uppercase" type="text" name="nombreEmisor" placeholder="Nombre emisor" required disabled />
                                                    <label className="text-start font-acumin-variable" htmlFor="nombreEmisor">Nombre completo del emisor</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <div className="form-floating ">
                                                    <input title="Sector del emisor" value={emisorSector} className="form-control text-uppercase" type="text" name="emisorSector" placeholder="Sector del emisor" required disabled />
                                                    <label className="text-start font-acumin-variable" htmlFor="emisorSector">Sector</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <div className="form-floating ">
                                                    <input title="SubSector del emisor" value={emisorSubSector} className="form-control text-uppercase" type="text" name="emisorSubSector" placeholder="SubSector del emisor" required disabled />
                                                    <label className="text-start font-acumin-variable" htmlFor="emisorSubSector">Subsector</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 mb-3">
                                                <div className="form-floating ">
                                                    <input title="Nacionalidad del emisor" value={emisorNacionalidad} className="form-control text-uppercase" type="text" name="emisorNacionalidad" placeholder="Nacionalidad del emisor" required disabled />
                                                    <label className="text-start font-acumin-variable" htmlFor="emisorNacionalidad">Nacionalidad</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 mb-3">
                                                <div className="form-floating ">
                                                    <input title="RTN del emisor" value={emisorRTN} className="form-control text-uppercase" type="text" name="emisorRTN" placeholder="RTN del emisor" required disabled />
                                                    <label className="text-start font-acumin-variable" htmlFor="emisorRTN">RTN</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 mb-3">
                                                <div className="form-floating ">
                                                    <input title="Página web del emisor" value={emisorPaginaWeb} className="form-control text-uppercase" type="text" name="emisorPaginaWeb" placeholder="Emisor página web" required disabled />
                                                    <label className="text-start font-acumin-variable" htmlFor="emisorPaginaWeb">Página web</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 mb-3">
                                                <div className="form-floating ">
                                                    <input title="Página web del emisor" value={emisorEstado} className="form-control text-uppercase" type="text" name="emisorPaginaWeb" placeholder="Emisor página web" required disabled />
                                                    <label className="text-start font-acumin-variable" htmlFor="emisorPaginaWeb">Estado</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <h3>II. Datos del representate legal</h3>
                                            <div className="col-12 col-md-6 mb-3">
                                                <div className="form-floating ">
                                                    <input title="Nombre del representante legal" value={nombreRepresentanteLegal} className="form-control text-uppercase" type="text" name="nombreRepresentateLegal" placeholder="Nombre representante legal" required disabled />
                                                    <label className="text-start font-acumin-variable" htmlFor="nombreRepresentateLegal">Nombre completo</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 mb-3">
                                                <div className="form-floating ">
                                                    <input title="Correo del representante legal" value={correoRepresentanteLegal} className="form-control " type="text" name="correoRepresentanteLegal" placeholder="Correo del representante legal" required disabled />
                                                    <label className="text-start font-acumin-variable" htmlFor="correoRepresentanteLegal">Correo electrónico</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <h3>III. Cuentas bancarias</h3>
                                            <div className="col-12 col-md-3 mb-3">
                                                <div className="form-floating ">
                                                    <input title="Número de cuenta extranjera" className="form-control text-uppercase" value={"Lempiras"} name="monedaNacional" type="text" placeholder="Numero cuenta extranjera" disabled/>
                                                    <label className="text-start font-acumin-variable" htmlFor="monedaNacional">Moneda nacional </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 d-flex justify-content-center mb-3">
                                                <div className="form-floating col-12 col-md-12">
                                                    <select onChange={(e) => setTipoCuentaNacional(e.target.value)} title="Seleccione el tipo de cuenta" className="form-select" name="tipoCuentaNacional" style={{ height: "56px" }} aria-label="Default select example">
                                                        <option disabled selected></option>
                                                        {catTiposDeCuenta &&
                                                            catTiposDeCuenta.map((el, index) => (
                                                                <option className="text-uppercase" value={el.id} key={index}>{el.nombre}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <label className="text-start font-acumin-variable" htmlFor="serie" >Tipo de cuenta &#40;Opcional&#41;</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 d-flex justify-content-center mb-3">
                                                <div className="form-floating col-12 col-md-12">
                                                    <select onChange={(e) => setBancoNacional(e.target.value)} title="Seleccione el banco" className="form-select" name="bancoNacional" style={{ height: "56px" }} aria-label="Default select example">
                                                        <option disabled selected></option>
                                                        {catBancos &&
                                                            catBancos.map((el, index) => (
                                                                <option className="text-uppercase" value={el.id} key={index}>{el.nombre}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <label className="text-start font-acumin-variable" htmlFor="serie" >Banco &#40;Opcional&#41;</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 mb-3">
                                                <div className="form-floating ">
                                                    <input title="Número de cuenta nacional" onChange={(e) => setNumeroCuentaNacional(e.target.value)} value={numeroCuentaNacional} onKeyUp={restrictUseBackSlash} className="form-control text-uppercase" name="numeroCuentaNacional" type="text" maxLength={18} placeholder="Número de cuenta nacional" />
                                                    <label className="text-start font-acumin-variable" htmlFor="numeroCuentaNacional">Número de cuenta &#40;Opcional&#41;</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 mb-3">
                                                <div className="form-floating ">
                                                    <input title="Número de cuenta extranjera" className="form-control text-uppercase" value={"Dólares"} name="monedaExtranjera" type="text" placeholder="Numero cuenta extranjera" disabled/>
                                                    <label className="text-start font-acumin-variable" htmlFor="monedaExtranjera">Moneda extranjera </label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 d-flex justify-content-center mb-3">
                                                <div className="form-floating col-12 col-md-12">
                                                    <select onChange={(e) => setTipoCuentaExtranjera(e.target.value)} title="Seleccione el tipo de cuenta" className="form-select" name="tipoCuentaExtranjera" style={{ height: "56px" }} aria-label="Default select example">
                                                        <option disabled selected></option>
                                                        {catTiposDeCuenta &&
                                                            catTiposDeCuenta.map((el, index) => (
                                                                <option className="text-uppercase" value={el.id} key={index}>{el.nombre}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <label className="text-start font-acumin-variable" htmlFor="serie" >Tipo de cuenta &#40;Opcional&#41;</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 d-flex justify-content-center mb-3">
                                                <div className="form-floating col-12 col-md-12">
                                                    <select onChange={(e) => setBancoExtranjero(e.target.value)} title="Seleccione el banco" className="form-select" name="bancoExtranjero" style={{ height: "56px" }} aria-label="Default select example">
                                                        <option disabled selected></option>
                                                        {catBancos &&
                                                            catBancos.map((el, index) => (
                                                                <option className="text-uppercase" value={el.id} key={index}>{el.nombre}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <label className="text-start font-acumin-variable" htmlFor="serie" >Banco &#40;Opcional&#41;</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-3 mb-3">
                                                <div className="form-floating ">
                                                    <input title="Número de cuenta extranjera" className="form-control text-uppercase" onChange={(e) => setNumeroCuentaExtranjera(e.target.value)} value={numeroCuentaExtranjera} onKeyUp={restrictUseBackSlash} name="numeroCuentaExtranjera" type="text" maxLength={18} placeholder="Numero cuenta extranjera" />
                                                    <label className="text-start font-acumin-variable" htmlFor="numeroCuentaExtranjera">Número de cuenta &#40;Opcional&#41;</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row justify-content-end mt-3 mb-3">
                                            <div className="col-6 row justify-content-end px-3">
                                                <button className="col-2 btn background-royal-blue bg-hover-red text-white mx-2" onClick={regresar}>Cancelar</button>
                                                <button className="col-2 btn background-royal-blue bg-hover-gold text-white mx-2" type="submit">Registrar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loading &&
                        <PantallaCargando />
                    }
                </main >
                
            </div>
        </>

    )
}