import React from "react";
import '../../../styles/stylesheet.css'
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";
import { useHistory } from 'react-router-dom';

//endpoints
import { crearUsuario } from '../../../api/usuarios/crearUsuario'
import { consultarUsuariosDeDepositante } from "../../../api/mantenimientoCargaValores/consultarUsuariosDeDepositante";
import { cambiarEstatusUsuarioReemplazado } from "../../../api/mantenimientoCargaValores/cambiarEstatusUsuarioReemplazado";
import { actualizarCorreoUsuario } from '../../../api/usuarios/actualizarCorreoUsuario'

export default function ActualizarCorreo(props) {
    const history = useHistory();
    const [errorUsuarioExistente, setErrorUsuarioExistente] = React.useState()
    const [usuarioRegistrado, setUsuarioRegistrado] = React.useState()
    const [usuarioNoRegistrado, setUsuarioNoRegistrado] = React.useState()
    const [registroCompleto, setRegistroCompleto] = React.useState();

    const [variableComparacion, setVariableComparacion] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [mensaje, setMensaje] = React.useState()
    const [loading, setLoading] = React.useState(true);

    let contador = 0;

    //**Inicia variables necesarias de AWS */
    const AWS = require('aws-sdk');

    // Set the AWS Region.
    AWS.config.update({
        region: process.env.REACT_APP_region,
        accessKeyId: process.env.REACT_APP_accessKeyId,
        secretAccessKey: process.env.REACT_APP_secretAccessKey,
    });

    // Initialize CogntioIdentityServiceProvider SDK.
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    //**Termina variables necesarias de AWS */

    React.useEffect(() => {

        //declaramos una variable para saber cuantos usuarios hay que registrar
        // si es un emisor, solo es un usuario, si es un depositnate puede ser 1 o 2 usuarios
        if (props.tipoParticipante === 4 || props.tipoParticipante === 5) {
            setVariableComparacion(1)
        } else {
            let i = 0
            props.informacion.contactos.map(function () {
                i++;
            })
 
            setVariableComparacion(i);
        }
    }, [])

    React.useEffect(() => {
        //vamos a funcion traer datos hasta que la variable de comparacaion sea mayor a cero
        if (variableComparacion > 0) {
            traerUsuariosDepositantes()
        }
    }, [variableComparacion])
    
    const traerUsuariosDepositantes = async () => {
        setLoading(true)
        //traer usuarios de un depositante
        const respuestaUsuario = await consultarUsuariosDeDepositante({ cehvalUsuarioUsuario: props.codigo })
        //console.log(respuestaUsuario)
        if (respuestaUsuario) {
            //entra a qui si hay un error http
            if ((isNaN(respuestaUsuario.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(respuestaUsuario)
            } else {
                //inscribimos a los usuarios con su correo, primer nombre,segundo nombre, primer apellido, segundo apellido y codigo.
                //inscribir a emisor automaticamente con rol CONSULTOR_EMISOR
                if (props.tipoParticipante === 4 || props.tipoParticipante === 5) {
                    //solo entra si se cambio la info de representante legal
                    if (props.informacion.nombreCompletoRepreLeg || props.informacion.correoLaboral) {
                        //setSinRepresentante(false)

                    
                        actualizarCorreo(respuestaUsuario, props.informacion.correoLaboral,0)

                    } else {
                        setLoading(false)
                        //   actualizarEstatus()
                    }
                }
                //inscribir depositantes
                else {
                    //console.log(props.contactosOriginales)
                    props.informacion.contactos.map(function (item, index) {
                     
                        if (item.correo || item.nombre || item.primerApellido) {
                     
                        actualizarCorreo(respuestaUsuario,item.correo,index)
                        }
                    
                    })
                }
            }
        }

    }

 

    const actualizarCorreo = async (usuarios, correo,index) => {
        // //console.log(usuarios, correo, primerNombre, segundoNombre, primerApellido, segundoApellido, rol, codigo, correoVAlidacion)
         

        let usuarioAModificar;
        if (props.tipoParticipante === 4 || props.tipoParticipante === 5) {
         
            usuarioAModificar = usuarios[0].usuario

        } else {
           
            usuarioAModificar = usuarios[index].usuario
        }



        const respuestaUsuario = await actualizarCorreoUsuario({
           
                cehvalUsuarioUsuario:usuarioAModificar,
                cehvalUsuarioCorreo:correo
            
        })

        // //console.log(respuestaUsuario)
        if (respuestaUsuario) {

            if ((isNaN(respuestaUsuario.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(respuestaUsuario)
            } else {
               
             
                      //variables para enviar a cognito 
                      const params = {
                        UserPoolId: process.env.REACT_APP_UserPoolId,
                        Username: usuarioAModificar,
                      
            
                      //  TemporaryPassword: password(4),
                        UserAttributes: [
                            {
                                Name: "email",
                                Value: correo
                            },
                            {
                                Name: "email_verified",
                                Value: "true"
                              }
                         
                        ]
                    }
    
                    cognitoidentityserviceprovider.adminUpdateUserAttributes(params, function (err, data) {
                        if (err) {
                          //  console.log(err)
                            //console.log(err.message); // an error occurred
            
                         /*    if (err.message === "User account already exists") {
                                setLoading(false)
                                setMensaje("El correo: " + correo + " ya registrado")
                                setErrorUsuarioExistente(true)
                            } else {
                                setLoading(false)
                                setMensaje("El correo: " + correo + " ya esta registrado")
                                setErrorUsuarioExistente(false)
                            } */
                        }
            
                       
                    });
            }
        }
    }

  

    const cancelarModal = () => {
        setUsuarioRegistrado(false)
        history.go()
    }

    return (
        <>
            {loading &&
                <PantallaCargando />
            }

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
{/* 
            {registroCompleto && !loading &&
                <ComponenteModal mostrarModal={registroCompleto} cerrarModal={imprimirReporte} titulo="Se han registrado a los usuarios que podrán ver las cuentas de valores de este depositante" textoBoton="Imprimir"></ComponenteModal>
            }

            {usuarioNoRegistrado &&
                <ComponenteModal mostrarModal={usuarioNoRegistrado} cerrarModal={() => { setUsuarioNoRegistrado(false) }} titulo="No se pudo registrar a este usuario, revisa los  datos e intentalo de nuevo" textoBoton="Entendido"></ComponenteModal>
            } */}

          

            {usuarioRegistrado && !loading &&
                <ComponenteModal mostrarModal={usuarioRegistrado} cerrarModal={cancelarModal} titulo="Se registro con éxito a este usuario" textoBoton="Entendido"></ComponenteModal>
            }

            {errorUsuarioExistente &&
                <ComponenteModal mostrarModal={errorUsuarioExistente} cerrarModal={() => {
                    setErrorUsuarioExistente(false)
                    props.resetear()
                }} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }
        </>
    );
}

