import React, { useState, useEffect } from "react";

//Components
import BannerTitulo from "../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../ComponentesGenerales/PantallaCargando";
import FormatNumberHyphen from "../../functions/FormatNumberHyphen";
import AllowOnlyNumbers from "../../functions/AllowOnlyNumbers";

//APIs
import { registrarDatosFacturacion } from "../../api/MantenimientoFacturas/registrarDatosFacturacion";



export default function RegistrarDatosFacturacion({ history }) {
    //form inputs
    const [numeroFacturaFormatted, setNumeroFacturaFormatted] = useState();
    const [fechaLimite, setFechaLimite] = useState();
    const [rangoAutorizadoInicio, setRangoAutorizadoInicio] = useState();
    const [rangoAutorizadoFin, setRangoAutorizadoFin] = useState();
    const [isv, setIsv] = useState("");
    const [cai, setCai] = useState();


    //Responses from backend
    const [requestStatus, setRequestStatus] = useState();


    //Show loading screen
    const [loading, setLoading] = useState();



    //Errors and messages    
    const [erroreshttp, setErroreshttp] = useState();



    useEffect(() => {
    }, []);


    //Send form data to endpoint 
    const submitDatosFacturacion = async (e) => {
        try {
            e.preventDefault();
            //Object with form data to send 
            const objAviso = {
                prefijo: numeroFacturaFormatted,
                fechaLimite: fechaLimite,
                limiteInferior: parseInt(rangoAutorizadoInicio),
                limiteSuperior: parseFloat(rangoAutorizadoFin),
                isv: parseFloat(isv),
                cai: cai,
            }
            //console.log(objAviso);

            setLoading(true);
            const response = await registrarDatosFacturacion(objAviso);
            setLoading(false);
            //console.log(response)


            if (isNaN(response.error) === false) {
                setErroreshttp(response);

            } else if (!response.isSuccessful) {
                setRequestStatus(response.mensaje);

            } else if (response.isSuccessful) {
                setRequestStatus(response.mensaje);
            }

        } catch (error) {
            //console.log(error);
        }
    }


    //Handle when user type in input No Factura
    const handleInputNoFactura = (e) => {
        const value = e.target.value;
        const formattedNumber = FormatNumberHyphen(value);

        setNumeroFacturaFormatted(formattedNumber.formattedNumber);

        if (formattedNumber.formattedNumber.length === 12) {

            const selectInputs = document.querySelectorAll(".habilitarRangoAutorizado");
            selectInputs.forEach((element) => {
                element.removeAttribute('disabled');
            });

        } else if (formattedNumber.formattedNumber.length < 12) {

            const selectInputs = document.querySelectorAll(".habilitarRangoAutorizado");
            selectInputs.forEach((element) => {
                element.setAttribute('disabled', 'disabled');
            });
        }
    }

    //Hnandle inputs rangoAutorizadoInicio and rangoAutorizadoFin to allow to type only numbers
    const handleOnlyNumbers = (e) => {
        const inputName = e.target.name;
        const value = e.target.value;
        
        const allowJustNumbers = AllowOnlyNumbers(value);

        if (inputName === "rangoAutorizadoInicio") {
            setRangoAutorizadoInicio(allowJustNumbers);

        } else if (inputName === "rangoAutorizadoFin") {
            setRangoAutorizadoFin(allowJustNumbers);
        }
    }


    const goToTable = () => {
        history.push({
            pathname: "/tablaDatosFacturacion"
        })
    }


    return (
        <>


            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {requestStatus &&
                <ComponenteModal mostrarModal={requestStatus} cerrarModal={goToTable} titulo={requestStatus} textoBoton="Entendido"></ComponenteModal>
            }

            {loading &&
                <PantallaCargando></PantallaCargando>
            }

            <div className="fluid-container">{/* style={{ backgroundImage: `url(${fondo})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="fluid-container">*/}
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        <div className="col-12 mb-5 main-min-height">
                            <div className="">
                                <BannerTitulo titulo="Registrar datos de facturación"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <section className="d-flex justify-content-center">
                                            <form onSubmit={submitDatosFacturacion} className="col-12 col-sm-12 col-md-12 row justify-content-center border rounded text-royal-blue">
                                                <div className="row justify-content-start p-0">
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <div className="form-floating ">
                                                            <input title="Número de factura" value={numeroFacturaFormatted} onChange={handleInputNoFactura} className="form-control fs-5" maxLength={12} name="numeroFactura" type="text" placeholder="Número de factura" required />
                                                            <label className="text-start font-acumin-variable" htmlFor="numeroFactura">No. factura &#40;Dígitos comunes&#41;</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <h5 className="font-acumin-variable text-royal-blue p-0 ps-2">Rango autorizado</h5>
                                                    <div className="row g-0 col-12 col-sm-12 col-md-6 pe-md-2">
                                                        <span className="font-acumin-variable text-muted ps-2 d-md-none">Del:</span>
                                                        <div className="col-12 col-sm-12 col-md-6 mb-3 mb-md-0 pe-md-1">
                                                            <div className="form-floating ">
                                                                <input title="Rango autorizado" className="form-control fs-5" value={numeroFacturaFormatted} maxLength={25} name="rangoAutorizado" type="text" placeholder="Rango autorizado inicio" required disabled />
                                                                <label className="text-start font-acumin-variable" htmlFor="rangoAutorizadoDel"></label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-6 mb-3 mb-md-0 pe-md-1">
                                                            <div className="form-floating ">
                                                                <input title="Rango autorizado del" className="form-control fs-5 habilitarRangoAutorizado" onChange={(e) => handleOnlyNumbers(e)} value={rangoAutorizadoInicio} maxLength={7} name="rangoAutorizadoInicio" type="text" placeholder="Rango autorizado del" required disabled />
                                                                <label className="text-start font-acumin-variable" htmlFor="rangoAutorizadoInicio"></label>
                                                            </div>
                                                        </div>
                                                        <span className="font-acumin-variable text-muted ps-2 d-none d-md-block">Del:</span>
                                                    </div>
                                                    <div className="row g-0 col-12 col-sm-12 col-md-6 ps-md-2">
                                                        <span className="font-acumin-variable text-muted ps-2 d-md-none">Al:</span>
                                                        <div className="col-12 col-sm-12 col-md-6 mb-3 mb-md-0 ps-md-1">
                                                            <div className="form-floating ">
                                                                <input title="Rango autorizado" className="form-control fs-5" value={numeroFacturaFormatted} maxLength={25} name="rangoAutorizadoAl" type="text" placeholder="Rango autorizado fin" required disabled />
                                                                <label className="text-start font-acumin-variable" htmlFor="rangoAutorizadoAl"></label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-6 mb-3 mb-md-0 ps-md-1">
                                                            <div className="form-floating ">
                                                                <input title="Rango autorizado al" className="form-control fs-5 habilitarRangoAutorizado" onChange={(e) => handleOnlyNumbers(e)} value={rangoAutorizadoFin} maxLength={7} name="rangoAutorizadoFin" type="text" placeholder="Rango autorizado al" required disabled />
                                                                <label className="text-start font-acumin-variable" htmlFor="rangoAutorizadoFin"></label>
                                                            </div>
                                                        </div>
                                                        <span className="font-acumin-variable text-muted ps-2 d-none d-md-block">Al:</span>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-start p-0">
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <div className="form-floating">
                                                            <input title="Fecha límite de emisión" type="date" className="form-control border" onChange={(e) => setFechaLimite(e.target.value)} name="fechaLimiteEmision" placeholder="Fecha límite de emisión" required />
                                                            <label htmlFor="fechaLimiteEmision" className="form-label ">Fecha límite de emisión</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 d-flex justify-content-center mb-3">
                                                                <div className=" col-12 col-md-12 ">
                                                                    <div className="form-floating">
                                                                        <select onChange={(e) => setIsv(e.target.value)}  title="Factor I.S.V" className="form-select font-acumin-variable" name="factorISV" style={{ height: "56px" }} aria-label="Default select example" required>

                                                                            <option value=""> </option>
                                                                            <option value="0">0% </option>
                                                                            <option value="15">15%</option>
                                                                          
                                                                        </select>
                                                                        <label className="font-acumin-variable" htmlFor="factorISV">Factor I.S.V</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <div className="form-floating ">
                                                            <input title="C.A.I" className="form-control fs-5" onChange={(e) => setCai(e.target.value)} value={cai} maxLength={50} name="CAI" type="text" placeholder="C.A.I" required />
                                                            <label className="text-start font-acumin-variable" htmlFor="CAI">C.A.I</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                        <button type="submit" className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-1 background-royal-blue text-white bg-hover-gold habilitarRangoAutorizado" disabled>Activar</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

