import { instance } from '../apiGlobal';

export async function consultaSolicitudesAsignacionIsin (obj) {
    
  try {
    const response = await instance(`/isin/consulta/solicitudes`, {
      method: "post",
      data: obj,
    });
    
    return response;
  } catch (error) {
    return error;
  }
}