//React
import React from "react";
import { Link, useHistory } from 'react-router-dom';

//AWS
import { Auth } from 'aws-amplify';
import { Amplify } from 'aws-amplify';
import QRCode from 'qrcode.react';
import { authRolApi } from "../../authRole/authApi";
import awsConfig from '../../amplify-config';

//Components
import ComponenteModal from "../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../ComponentesGenerales/ManejadorErrores";
import PantallaCargando from "../../ComponentesGenerales/PantallaCargando";
//import CompletarPerfil from "../../ComponentesGenerales/CompletarPerfil";

//React context
import { FormContext } from '../../ContextoGlobal'

//Images
import cajaFuerte from './../../assets/logosCehval/caja-fuerte.jpg';

//Icons
import { RiLockFill } from "react-icons/ri";
import { FcBusinessman } from "react-icons/fc";
import { ImClock2 } from "react-icons/im";
import { RiLockLine } from "react-icons/ri";


Amplify.configure(awsConfig);

//const isAuthenticated = () => Amplify.Auth.user !== null;

export default function Login() {
    const [value, setValue] = React.useContext(FormContext);
    const history = useHistory();

    const [contrasena, setContrasena] = React.useState();
    const [user, setUser] = React.useState();
    const [nombreUsuario, setNombreUsuario] = React.useState();

    const [errorLogin, setErrorLogin] = React.useState();
    const [codigoQR, setCodigoQR] = React.useState();
    const [establecerMfa, setEstablecerMfa] = React.useState();
    const [contraseñaTemporal, setContraseñaTemporal] = React.useState("");
    const [errorContraseñaTemporal, setErrorContraseñaTemporal] = React.useState();
    const [noMostrarQr, setNoMostrarQr] = React.useState();

    const [cambiarContraseña, setCambiarContraseña] = React.useState()
    const [nuevaContraseña, setNuevaContraseña] = React.useState("")
    const [nuevaContraseña2, setNuevaContraseña2] = React.useState("")


    const [errorFormatoNuevaContraseña, setErrorFormatoNuevaContraseña] = React.useState()

    const [contrasenasNoCoinciden, setContrasenasNoCoinciden] = React.useState()
    const [cambioExitoso, setCambioExitoso] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [errorIntentosMaximos, setErrorIntentosMaximos] = React.useState()
    const [sesionExpirada, setSesionExpirada] = React.useState()
    const [loading, setLoading] = React.useState(false);
    const [usuarioBloqueado, setUsuarioBloqueado] = React.useState();
    const [errorContraseñaExpirada, setErrorContraseñaExpirada] = React.useState();

    /* const objAut = {
         usuario: null,
         permisos: [],
         roles: []
     }*/

    // const [autorizacion, setAutorizacion] = React.useState(objAut);
    //Consulta usuario aun no se usa

    React.useEffect(() => {
        setValue((prev) => {
            const datos = { ...prev.datos, ["usuario"]: "Iniciar sesión" };
            return { ...prev, datos };
        });
    }, [])

    const onSubmitMfa = async (e) => {
        e.preventDefault();
        setLoading(true)
        //entra aqui cuando ya no hay código QR, a partir del tercer ingreso
        if (noMostrarQr) {
            ////console.log(user)
            // //console.log("usuario")
            ////console.log("generacion token")
            //entra aqui cuando se genera el qr

            const code = contraseñaTemporal//guardamos el código generado por la aplicación
            const mfaType = "SOFTWARE_TOKEN_MFA"//establecemos el modo del mfa
            // If MFA is enabled, sign-in should be confirmed with the confirmation code
            ////console.log(Amplify.Auth.user)

            try {
                //mandamos la configuracion del mfa
                const loggedUser = await Auth.confirmSignIn(
                    user, // Return object from Auth.signIn()
                    code, // Confirmation code
                    mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
                );
                ////console.log(loggedUser)



                const session = await Auth.currentSession(); //esta no estaba comentada 
                //console.log('Cognito User Access Token:', session.getAccessToken().getJwtToken());
                //si salio bien la peticion de mandar el mfa, guardamos el accestoken de cognito en el session storage
                window.sessionStorage.setItem("accessToken", session.getIdToken().getJwtToken());
                //si salio bien la peticion de mandar el mfa, guardamos el refrechtoken de cognito en el session storage
                window.localStorage.setItem("refreshToken", loggedUser.signInUserSession.refreshToken.token);


                //setAutorizacion(usuarioB);
                //traemos la informacion del usuario de la base
                const usuarioB = await authRolApi({
                    usuario: user.username
                });

                //console.log(usuarioB)

                if (usuarioB) {
                    setLoading(false)
                    if ((isNaN(usuarioB.error)) === false) {
                        // //console.log("erores")
                        ////console.log(response)
                        setErroreshttp(usuarioB)
                        
                    } else {
                        //si salio bien la peticion de mandar el mfa, guardamos al usuario en el session storage
                        window.sessionStorage.setItem("usuario", JSON.stringify(user));
                        //si la peticion contesta bien guardamos la informacion del usuario de la base y vamos a la pantalla de inicio
                        window.sessionStorage.setItem("usuarioRol", JSON.stringify(usuarioB));
                        history.push({
                            pathname: '/inicioCehval',
                        })
                    }
                } else {
                    setLoading(false)
                    setUsuarioBloqueado(true)
                    ////console.log("usuario bloqueado")
                    //history.go()

                }
            } catch (err) {
                setLoading(false)
                //si la contraseña generada por el qr no coincide
                //console.log(err.message)
                if (err.message === "Invalid session for the user, session is expired.") {
                    setSesionExpirada(true)
                } else {
                    setErrorContraseñaTemporal(true)
                }

            }

        }   //entra aqui cuando hay código QR, en el segundo ingreso, despues de haber cambiado la contraseña
        else {
            //console.log("con qr")
            try {
                ////console.log(user)
                //mandamos la petición del mfa con user y contraseña
                const usuarioVerificado = await Auth.verifyTotpToken(user, contraseñaTemporal)
                ////console.log(usuarioVerificado)
                // don't forget to set TOTP as the preferred MFA method
                //establecemos TOTP como metodo de MFA
                Auth.setPreferredMFA(user, 'TOTP');

                // //console.log(Amplify.Auth.user)
                ////console.log("qrcorecto")
                //guardamos la session
                const session = await Auth.currentSession(); //esta no estaba comentada
                //guardamos el accestoken en el sesionStorage
                window.sessionStorage.setItem("accessToken", session.getIdToken().getJwtToken());

                const usuarioB = await authRolApi({
                    usuario: user.username
                });

                //console.log(usuarioB)
                if (usuarioB) {
                    setLoading(false)
                    if ((isNaN(usuarioB.error)) === false) {
                        // //console.log("erores")
                        ////console.log(response)
                        setErroreshttp(usuarioB)
                        
                    } else {
                        //guardamos la informacion de suario de la sesion de cognito
                        window.sessionStorage.setItem("usuario", JSON.stringify(user));
                        //si la petición es correcta, guardamos la informacion de usuario, en el sessionStorage 
                        window.sessionStorage.setItem("usuarioRol", JSON.stringify(usuarioB));
                        history.push({
                            //pathname: '/completarInformacion',
                            pathname: '/inicioCehval',
                        })
                    }
                } else {
                    setLoading(false)
                    setUsuarioBloqueado(true)
                    ////console.log("usuario bloqueado")
                    //history.go()

                }
            }
            catch (err) {
                setLoading(false)
                //si la contraseña generada por el qr no coincide
                //console.log(err.message)
                if (err.message === "Invalid session for the user, session is expired.") {
                    setSesionExpirada(true)
                } else {
                    setErrorContraseñaTemporal(true)
                }
            }
        }



    }

    const onSubmitNewPassword = async (e) => {
        //funcion para el cambio de contraseña en el primer ingreso
        e.preventDefault();

        if (nuevaContraseña === nuevaContraseña2) {
            setLoading(true)
            //const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
            // You need to get the new password and required attributes from the UI inputs
            // and then trigger the following function with a button click
            // For example, the email and phone_number are required attributes
            // const { username, email, phone_number } = getInfoFromUserInput();

            try {
                //mandamos la nueva contraseña
                const loggedUser = await Auth.completeNewPassword(
                    user, // the Cognito User Object
                    nuevaContraseña, // the new password
                );
                //console.log(loggedUser)
                //cambio exitoso de contraseña
                // if (loggedUser.challengeName === "MFA_SETUP") {
                //   setCambioExitoso(true)
                //}
                //setCambioExitoso(true)

                //si hay respuesta hay cambio exitoso de contraseña
                if (loggedUser) {
                    //refrescaremos la pagina para iniciar sesion con la nueva contraseña
                    setLoading(false)
                    setCambioExitoso(true)
                }
            }
            catch (err) {//si hay error
                setLoading(false)
                ////console.log(err.message)
                //error en el formato de la contraseña
                if (err.message.indexOf("Password does not conform to policy:") >= 0) {
                    //setErrorContraseñaTemporal(true)
                    setErrorFormatoNuevaContraseña(true)

                } else if (err.message.indexOf("Invalid session for the user, session is expired.") >= 0) {
                    //error si la sesion expira, se tardo demasiado en poner la nueva contraseña
                    setSesionExpirada(true)
                }
            }
        } else {
            setContrasenasNoCoinciden(true)
        }
    }


    const onSubmitForm = async (e) => {
        setLoading(true)
        e.preventDefault();
        try {
            //inicia sesion
            const userObject = await Auth.signIn(
                nombreUsuario,
                contrasena
            );
            //console.log(userObject.challengeName)
            setUser(userObject)
            setLoading(false)
            //en el primer ingreso se tiene que cambiar al contraseña asignada por el sistema 
            if (userObject.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setCambiarContraseña(true)
            }  //en el segundo ingreso generamos un codigo qr para nuestra aplicacion de MFA
            else if (!userObject.challengeName) {
                //generamos el qr y establecemos el MFA
                const code = await Auth.setupTOTP(userObject);
                //const str = "otpauth://totp/AWSCognito:" + nombreUsuario.replace(/[@.]/g, '|') + "?secret=" + code;
                const str = "otpauth://totp/AWSCognito:" + nombreUsuario + "?secret=" + code;
                setCodigoQR(str)
                setEstablecerMfa(true)
            } //a partir del tercer ingreso entramos con la contraseña temporal que genera la aplicacion MFA
            else if (userObject.challengeName === 'SMS_MFA' || userObject.challengeName === 'SOFTWARE_TOKEN_MFA') {
                setNoMostrarQr(true)
            }


            // No remaining auth challenges need to be satisfied
            //actualizamos el inicio de sesion
            //const session = await Auth.currentSession(); //esta no estaba comentada
            // //console.log('Cognito User Access Token:', session.getAccessToken().getJwtToken());
            //este es el bueno //console.log('Cognito User Identity Token:', session.getIdToken().getJwtToken()); //esta no estaba comentada
            // //console.log('Cognito User Refresh Token', session.getRefreshToken().getToken());
            /* this.setState({ stage: 0, email: '', password: '', code: '' });*/ //esta no estaba comentada
            // this.props.history.replace('/app');

        } catch (err) {
            setLoading(false)
            //entra a errores, 
            //console.log(err.message)
            //si la contraseña o usuario no coinciden
            if (err.message === "Incorrect username or password.") {
                setErrorLogin(true)
                //entra aqui si excedes el número de intentos (6)
            } else if (err.message === "Password attempts exceeded.") {
                setErrorIntentosMaximos(true)

            }else if(err.message.indexOf("Temporary password has expired and must be reset by an administrator.") >= 0) {
             
                setErrorContraseñaExpirada(true)
            }
        

        }
    }

    /*React.useEffect(() => {
        ////console.log(noMostrarQr)
    }, [noMostrarQr])*/


    //funcion que valida que la nueva contraseña sea correcta


    //actualiza la pagina despues del cambio de contraseña
    const cancelarModalCambio = () => {
        setCambioExitoso(false)
        setSesionExpirada(false)
        history.go();
    }

    const showPassword = (e) => {
        e.preventDefault();
        let inputPassword = document.querySelector(".show-password");
        if (inputPassword.type === "password") {
            inputPassword.type = "text";
        } else {
            inputPassword.type = "password";
        }
    }

    const cleanPasswordFocus = () => {
        document.querySelector("#qr").value = "";
    }

    const signOut = async () => {
        try {
            //cerramos sesion 
            await Auth.signOut();
            //limpiamos el sessionStorage
            sessionStorage.clear();
            //vamos de nuevo a la ruta raiz
            window.location.replace('/')
            // window.location.href = '/login'
        } catch (error) {
            window.location.replace('/')
            //console.log(error);
        }
    }

    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {usuarioBloqueado &&
                <ComponenteModal mostrarModal={usuarioBloqueado} cerrarModal={signOut} titulo="No existe este usuario" textoBoton="Entendido"></ComponenteModal>
            }
            {cambioExitoso &&
                <ComponenteModal mostrarModal={cambioExitoso} cerrarModal={cancelarModalCambio} titulo="Cambio exitoso de contraseña, inicie sesión con la nueva contraseña" textoBoton="Entendido"></ComponenteModal>
            }

            {errorLogin &&
                <ComponenteModal mostrarModal={errorLogin} cerrarModal={() => { setErrorLogin(false) }} titulo="Nombre de usuario o contraseña invalidos" textoBoton="Entendido"></ComponenteModal>
            }
            {errorIntentosMaximos &&
                <ComponenteModal mostrarModal={errorIntentosMaximos} cerrarModal={() => { setErrorIntentosMaximos(false) }} titulo="Alcanzaste el límite de intentos de inicio de sesión, intenta de nuevo mañana" textoBoton="Entendido"></ComponenteModal>
            }

            {contrasenasNoCoinciden &&
                <ComponenteModal mostrarModal={contrasenasNoCoinciden} cerrarModal={() => { setContrasenasNoCoinciden(false) }} titulo="Las contraseñas no coinciden" textoBoton="Entendido"></ComponenteModal>
            }

            {sesionExpirada &&
                <ComponenteModal mostrarModal={sesionExpirada} cerrarModal={cancelarModalCambio} titulo="Se ha agotado el tiempo de la sesión, ingrese de nuevo usuario y contraseña" textoBoton="Entendido"></ComponenteModal>
            }

            {errorFormatoNuevaContraseña &&
                <ComponenteModal mostrarModal={errorFormatoNuevaContraseña} cerrarModal={() => { setErrorFormatoNuevaContraseña(false) }} titulo="Error en el formato de la contraseña, revise las especificaciones de la contraseña" textoBoton="Entendido"></ComponenteModal>
            }

            {errorContraseñaTemporal &&
                <ComponenteModal mostrarModal={errorContraseñaTemporal} cerrarModal={() => { setErrorContraseñaTemporal(false) }} titulo="La contraseña de un solo uso no coincide" textoBoton="Entendido"></ComponenteModal>
            }

            {errorContraseñaExpirada &&
                <ComponenteModal mostrarModal={errorContraseñaExpirada} cerrarModal={() => { setErrorContraseñaExpirada(false) }} titulo="Su contraseña ha expirado, por favor, mandar correo a soporte de CEHVAL" textoBoton="Entendido"></ComponenteModal>
            }



            <main className=" p-0 m-0 g-0">
                <div className="row justify-content-between  justify-content-lg-center g-0">
                    <div className="col-12 col-md-12 col-lg-6 p-0 g-0 row m-0">
                        <img className="" id="loginImageContainer" src={cajaFuerte} alt="Imagen de caja fuerte" />
                    </div>
                    <div className="col-12  col-md-12  col-lg-6 p-2 mt-8rem">
                        <div>
                            {/*Este es el formulario login que siempre va a rendizar al principio el principal*/}
                            {!establecerMfa && !noMostrarQr && !cambiarContraseña &&
                                <form onSubmit={(e) => onSubmitForm(e)} className="p-4 font-acumin-variable">
                                    <div className="row justify-content-sm-left mt-4 p-2  ">
                                        <div className="col-12 col-md-7 col-lg-10 mb-2 p-0">
                                            <h4 className="fs-2  fw-bolder royal-blue-light ">Inicio de sesión</h4>
                                            <p className="fs-5  fw-bold royal-blue-light ">A continuación introduce tu nombre de usuario y contraseña</p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-10 mb-2 p-0">
                                            <div className="form-floating ">
                                                <input required type="text" id="nombreUsuario" className="form-control" placeholder="name@example.com" value={nombreUsuario} onChange={(event) => setNombreUsuario(event.target.value)} />
                                                <label className="text-start royal-blue-light font-acumin-variable " htmlFor="email"><FcBusinessman /> &nbsp; Nombre de usuario</label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-10 mb-2 row border rounded p-0 g-0">
                                            <div className="form-floating col-11 p-0">
                                                <input required id="contraseña" type="password" className="form-control show-password border-0" placeholder="Contraseña" value={contrasena} onChange={(event) => setContrasena(event.target.value)} />
                                                <label className="text-start royal-blue-light font-acumin-variable" htmlFor="password"><RiLockFill /> &nbsp; Contraseña</label>
                                            </div>
                                            <button type="button" className="col-1 p-0 button-show-password rounded" onClick={showPassword}>
                                                <i className="ri-eye-off-fill ri-lg align-middle text-royal-blue"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="mt-4 row p-2 ">
                                        <button className="col-8 col-sm-6 col-md-3 col-lg-4 btn background-royal-blue text-white bg-hover-gold p-2" type="submit">Iniciar sesión</button>
                                    </div>
                                    <div className="mt-5 row justify-content-between border-top border-1 p-3">
                                        <div className="col-auto">
                                            <small><Link to="/solicitarRestablecerPassword" className="text-no-decoration">¿Olvidaste tu contraseña?</Link></small>
                                        </div>
                                    </div>
                                </form>
                            }

                            {/*Formulario para cambio de contraseña para el primer ingreso*/}
                            {cambiarContraseña &&
                                <form onSubmit={(e) => onSubmitNewPassword(e)} className="p-4 font-acumin-variable">
                                    <div className="row justify-content-sm-left mt-4 p-2 ">
                                        <div className="mb-5">
                                            <h4 className="fs-2 p-2 fw-bolder royal-blue-light text-sm-left">Es obligatorio que cambies tu contraseña en tu primer ingreso.</h4>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-10 mb-3">
                                            <div className="form-floating mb-3">
                                                <input required type="password" id="nuevaContraseña" className="form-control" placeholder="contraseña" value={nuevaContraseña} onChange={(event) => setNuevaContraseña(event.target.value)} />
                                                <label htmlFor="nuevaContraseña" className=" royal-blue-light font-acumin-variable "><RiLockLine />&nbsp; Nueva contraseña</label>
                                                <div id="emailHelp" className="form-text">La contraseña debe tener minimo 8 caracteres, un número, una letra minúscula, una letra mayúscula y un caracter especial como !"·$%&/()=?</div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-10 mb-3">
                                            <div className="form-floating mb-3">
                                                <input required type="password" id="nuevaContraseña2" className="form-control" placeholder="confirmar nueva contraseña" value={nuevaContraseña2} onChange={(event) => setNuevaContraseña2(event.target.value)} />
                                                <label htmlFor="nuevaContraseña2" className="royal-blue-light font-acumin-variable "><RiLockFill />&nbsp; Confirmar nueva contraseña</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-4 row justify-content-start p-3 ">
                                        <button className="col-5 col-md-3 col-lg-4 btn background-royal-blue text-white bg-hover-gold p-2" type="submit">Confirmar</button>
                                    </div>
                                </form>
                            }


                            {/*Formulario que muestra el codigo QR*/}
                            {
                                establecerMfa &&
                                <form onSubmit={(e) => onSubmitMfa(e)} className="p-4 font-acumin-variable" >
                                    <div className="row justify-content-sm-left mt-4 p-2 ">
                                        <div className="mb-5">
                                            <h4 className="fs-2  fw-bolder royal-blue-light text-sm-left">Verifícate</h4>
                                            <p className="fs-5  fw-bold royal-blue-light text-sm-left">Para tu primer ingreso, descarga una aplicación que genere una TOTP (Contraseña de un único uso basada en el tiempo), como Google Authenticator, después escanea el código QR e ingresa la contraseña temporal</p>
                                        </div>
                                        {/*MOSTRAR QR */}
                                        <div className="my-5 text-center">
                                            <QRCode value={codigoQR} />
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-10 mb-3 row border ms-2 rounded p-0 g-0">
                                            <div className="form-floating col-11 p-0">
                                                <input required id="qr" type="password" className="form-control show-password border-0" placeholder="Contraseña temporal" value={contraseñaTemporal} onChange={(event) => setContraseñaTemporal(event.target.value)}></input>
                                                <label htmlFor="password" className="royal-blue-light"><ImClock2 />&nbsp; Contraseña temporal</label>
                                            </div>
                                            <button type="button" className="col-1 p-0 button-show-password rounded" onClick={(e) => showPassword(e)}>
                                                <i className="ri-eye-off-fill ri-lg align-middle text-royal-blue"></i>
                                            </button>
                                        </div>

                                        <div className="mt-5 row justify-content-start ms-2 p-0 g-0">
                                            <button className="col-5 col-md-3 col-lg-4 btn background-royal-blue text-white bg-hover-gold p-2" type="submit">Verificar</button>
                                        </div>
                                    </div>
                                </form>

                            }


                            {/*Formulario para ingresar el codigo que genera la aplicación, cuando ya no hay QR*/}
                            {
                                noMostrarQr &&

                                <form onSubmit={(e) => onSubmitMfa(e)}>
                                    <div className="row justify-content-sm-center mt-4 p-2 font-acumin-variable ">
                                        <div className="mb-5">
                                            <h4 className="fs-2 p-2 fw-bolder royal-blue-light text-sm-left ">Verifícate con tu código MFA</h4>
                                            <p className="fs-5 p-2 fw-bold royal-blue-light text-sm-left">A continuación introduce el codigo que se muestra en tu aplicación multifactor</p>

                                            {/* Input para ingresar contraseña temporal */}
                                            <div className="col-12 col-md-7 col-lg-10 mb-3 row border rounded p-0 g-0">
                                                <div className="form-floating col-11 p-0">
                                                    <input required id="qr" type="password" className="form-control show-password border-0" maxLength={6} placeholder="Contraseña temporal" value={contraseñaTemporal} onChange={(event) => setContraseñaTemporal(event.target.value)} onFocus={cleanPasswordFocus} />
                                                    <label htmlFor="password" className="royal-blue-light font-acumin-variable"><ImClock2 />&nbsp; Contraseña temporal</label>
                                                </div>
                                                <button type="button" className="col-1 p-0 button-show-password rounded" onClick={(e) => showPassword(e)}>
                                                    <i className="ri-eye-off-fill ri-lg align-middle text-royal-blue"></i>
                                                </button>
                                            </div>
                                            <div className="mt-4 row justify-content-start px-2">
                                                <button className="col-5 col-md-3 col-lg-4 btn background-royal-blue text-white bg-hover-gold p-2" type="submit">Continuar</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </main>


        </>
    );
}

