//React
import React from "react";

import ComponenteMenuEmsoresEmisionesSeries from "../../../ComponentesGenerales/ComponenteMenuEmsoresEmisionesSeries";

export default function MenuRegistrarEmisoresEmisionesSeries({ history }) {


    return (
        <ComponenteMenuEmsoresEmisionesSeries titulo="Registrar emisores, emisiones y series" ruta1="/solicitudRegistroEmisor" ruta2="/registroEmisiones" ruta3="/registroSeries" ></ComponenteMenuEmsoresEmisionesSeries>
    );
}

