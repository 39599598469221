import { useMemo } from "react";

export default function useOperacionesEjecutadasOperadorDD() {
  const columns = useMemo(
    () => [
      {
        Header: "Fecha de ejecución",
        accessor: "fechaEjecucionOperacionStr"
      },
      {
        Header: "Folio de operación",
        accessor: "folio"
      },
      {
        Header: "Tipo",
        accessor: "tipoStr"
      },
      {
        Header: "Emisión",
        accessor: "nombreEmision"
      },
      {
        Header: "Serie",
        accessor: "nombreSerie"
      },
      {
        Header: "ISIN",
        accessor: "isin"
      },
      {
        Header: "Valores",
        accessor: "numeroValoresStr"
      },
      {
        Header: "Monto de la operación",
        accessor: "montoTotalPagarOperacionStr"
      },
      {
        Header: "Moneda de liquidación",
        accessor: "monedaLiquidacionStr"
      },
      {
        Header: "Código del vendedor o reportado",
        accessor: "codigoClienteVendedor"
      },
      {
        Header: "Nombre del vendedor o reportado",
        accessor: "nombreClienteVendedor"
      },
      {
        Header: "Código del comprador o reportador",
        accessor: "codigoClienteComprador"
      },
      {
        Header: "Nombre del comprador o reportador",
        accessor: "nombreClienteComprador"
      },
      {
        Header: "Estatus",
        accessor: "estatusOperacionStr"
      }
    ],
    []
  );

  return columns;
}
