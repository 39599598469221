import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

//react context
import { UserContext } from '../UserContext';

//AWS
import { Auth } from 'aws-amplify';


//Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SubComponenteHeader from "./SubComponenteHeader";
import SubComponenteHeader2 from "./SubComponenteHeader2";

//logos
import logoCehvalBlue from '../assets/logosCehval/CEHVAL_000.png';
import logoBCVAzul from '../assets/logosCehval/Logo_Azul.png'
import logoBcv from '../assets/logosCehval/Logo-BCV-blanco.png'
import logoCehvalBlanco from '../assets/logosCehval/CEHVAL_001.png'






import {
   faFacebook,
   faTwitter,
   faYoutube,
   faInstagram,
} from "@fortawesome/free-brands-svg-icons";


import ComponenteModal from "./ComponenteModal";


export default function Header() {


   //Context to handle header when user is logged in or out
   const context = useContext(UserContext);
   const { login, setLogin, username, setUsername, setAutorizacion } = context;

   const [sinUsuario, setSinUsuario] = useState();


   useEffect(() => {
      const username = JSON.parse(window.sessionStorage.getItem('usuario'));

      //If thers a session in progress shows in header the username and the option to end session
      if (username != null) {
         setLogin(true);
         setUsername(username.username);
         const arregloPermisos = JSON.parse(window.sessionStorage.getItem('usuarioRol'));
         if (arregloPermisos === null) {
            setSinUsuario(true);
         } else {
            const result = arregloPermisos.permisos.filter(word => word.icon);
            setAutorizacion(result);
    
         }

         //If theres no session in progress shows in header option to login 
      } else if (username === null) {
         setLogin(false);
      }
   }, []);



   const signOut = async () => {
      try {
         await Auth.signOut();
         sessionStorage.clear();
         window.location.replace('/')
         // window.location.href = '/login'
      } catch (error) {
         //console.log('error signing out: ', error);
      }
   }

   //Shows menu when click "Acciones en CEHVAL"
   const showDropDown = () => {
      //Ul with all menu options
      const menuEl = document.querySelector("#cehval-menu");
      //Text "Acciones en CEHVAL"
      const liEl = document.querySelector(".drop-cehval-menu");

      //Hides menu
      if (menuEl.classList.contains("d-block")) {
         menuEl.classList.remove("d-block");
         menuEl.classList.add("d-none");

         //Change text color "Acciones CEHVAL"
         liEl.classList.add("text-white");
         liEl.classList.remove("text-gold");

         //Shows menu
      } else if (menuEl.classList.contains("d-none")) {
         menuEl.classList.remove("d-none");
         menuEl.classList.add("d-block");

         //Change text color "Acciones CEHVAL"
         liEl.classList.remove("text-white");
         liEl.classList.add("text-gold");
      }
   }

   //Hides menu when user choose an option from the submenu to navigate
   const hideCehvalMenu = () => {
      const menuEl = document.querySelector("#cehval-menu");
      menuEl.classList.remove("d-block");
      menuEl.classList.add("d-none");
   }

   //Hides dropdown menu when click outside of it
   document.addEventListener('click', function (event) {

      //Check if the click event contains any of the classes do nothing
      if (
         event.target.classList.contains("drop-cehval-menu") ||
         event.target.classList.contains("cehval-menu-option-text") ||
         event.target.classList.contains("ri-arrow-right-s-line") ||
         event.target.classList.contains("cehval-menu-option") ||
         event.target.classList.contains("no-close") ||
         event.target.classList.contains("submenu-dropdown") ||
         event.target.id === "cehval-menu"
      ) {
         //If contains classes, do nothing

      } else {
         //If not contains the class, validates if theres a current session 

         //When user is logged hides menu when click outside
         if (login) {
            //Hides menu
            const menuEl = document.querySelector("#cehval-menu");
            menuEl.classList.remove("d-block");
            menuEl.classList.add("d-none");

            //Change text color "Acciones en CEHVAL" to white 
            const liEl = document.querySelector(".drop-cehval-menu");
            liEl.classList.add("text-white");
            liEl.classList.remove("text-gold");
         }
      }
   });

   return (
      <>

         {sinUsuario &&
            <ComponenteModal mostrarModal={sinUsuario} cerrarModal={signOut} titulo="Usuario deshabilitado" textoBoton="Entendido"></ComponenteModal>
         }

         <header id="header" className="container-fluid p-0 ">
            <nav className="">
               <div className="row align-items-center background-royal-blue border-bottom-gold py-2 g-0">
                  <div className="col-6 ">
                     <Link className="" to="/">
                       <img id="logo-cehval-header" style={{ width: process.env.REACT_APP_logo_header_width,height: process.env.REACT_APP_logo_header_height}} className="logo-header offset-2 p-1" src={`${process.env.REACT_APP_logo_header}`} alt="Logo de CEHVAL" />
                        {/*<img id="logo-cehval-header" className="logo-header-cehval offset-2 p-1" src={logoCehvalBlanco} alt="Logo de CEHVAL" />*/}
                     </Link>
                  
                  </div>
                  <div className="col-4 offset-sm-2 offset-1 row justify-content-end ">
                     <div className="col-12 d-flex text-color-gray d-none d-md-block">
                        <div className="col-12 text-end">
                           {/* If user is logged in shows username and option to end session */}
                           {login &&
                              <>
                                 <Link className="px-2 text-decoration-none font-acumin-variable text-white text-shadow-white" to="/inicioCehval">{username}</Link>
                                 /
                                 <span className="px-2 text-decoration-none font-acumin-variable text-white text-shadow-white cursor-pointer" onClick={signOut}>Cerrar sesión</span>
                              </>
                           }

                           {!login &&
                              <>
                                 <Link className="px-2 text-decoration-none font-acumin-variable text-white text-shadow-white" to="/login">Iniciar sesión</Link>
                                 /
                                 <Link className="px-2 text-decoration-none font-acumin-variable text-white text-shadow-white" to="/solicitudRegistroDepositanteDirecto">Registrarse</Link>
                              </>
                           }
                        </div>
                     </div>
                     {/*Button to display SideNavbar in small screens*/}
                     <div id="btnSideNavbarContainer" className="col-4 navbar d-block d-md-none">
                        <div id="sideNavbarDiv" className="col-8">
                           <button id="btnSideNavbar" className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSideNavbar" aria-controls="offcanvasSideNavbar">
                              <span className="navbar-toggler-icon"></span>
                           </button>
                        </div>
                     </div>
                  </div>
               </div>

               <div className="row justify-content-center background-royal-blue-2  g-0">
                  <div className="col-12 row justify-content-center ">

                     {/*Header options when theres a session in progress*/}
                     {login &&
                        <>
                           <ul id="acciones-cehval" className="col-sm-6 col-md-6 col-lg-4 p-2 text-no-decoration d-flex justify-content-center font-acumin-variable m-0 p-0 fs-medium fs-small">
                              <li className="cursor-pointer">
                                 <span id="" onClick={showDropDown} className="text-white drop-cehval-menu p-2 rounded fw-bold text-no-decoration text-hover-gold-2 mx-3" >
                                    Acciones en CEHVAL
                                    <i className="ri-arrow-down-s-line drop-cehval-menu"></i>
                                 </span>
                              </li>

                              {/*DROPDOWN MENU CEHVAL */}
                              <ul id="cehval-menu" className="text-start shadow border rounded py-2 d-none cursor-pointer">
                                 <SubComponenteHeader subgrupo={1} titulo="Administración de depositantes" funcion={hideCehvalMenu}></SubComponenteHeader>
                                 <SubComponenteHeader subgrupo={2} titulo="Administración de valores físicos" funcion={hideCehvalMenu}></SubComponenteHeader>
                                 <SubComponenteHeader subgrupo={3} titulo="Administración de usuarios" funcion={hideCehvalMenu}></SubComponenteHeader>
                                 <SubComponenteHeader subgrupo={4} titulo="Cuentas de valores" funcion={hideCehvalMenu}></SubComponenteHeader>
                                 <SubComponenteHeader subgrupo={5} titulo="Administración de emisores, emisiones y series" funcion={hideCehvalMenu}></SubComponenteHeader>
                                 <SubComponenteHeader subgrupo={6} titulo="Registro emisores, emisiones y series BCV" funcion={hideCehvalMenu}></SubComponenteHeader>
                                 <SubComponenteHeader subgrupo={7} titulo="Operaciones con valores" funcion={hideCehvalMenu}></SubComponenteHeader>
                                 <SubComponenteHeader subgrupo={8} titulo="Mercado primario" funcion={hideCehvalMenu}></SubComponenteHeader>
                                 <SubComponenteHeader subgrupo={9} titulo="Mercado secundario" funcion={hideCehvalMenu}></SubComponenteHeader>
                                 <SubComponenteHeader subgrupo={10} titulo="Bitácoras" funcion={hideCehvalMenu}></SubComponenteHeader>
                                 <SubComponenteHeader subgrupo={11} titulo="Impresión" funcion={hideCehvalMenu}></SubComponenteHeader>
                                 <SubComponenteHeader subgrupo={12} titulo="Códigos ISIN" funcion={hideCehvalMenu}></SubComponenteHeader>
                                 <SubComponenteHeader subgrupo={13} titulo="Pagos de derechos patrimoniales" funcion={hideCehvalMenu}></SubComponenteHeader>
                                 <SubComponenteHeader subgrupo={14} titulo="Salvaguardas financieras" funcion={hideCehvalMenu}></SubComponenteHeader>
                                 <SubComponenteHeader subgrupo={15} titulo="Sistema de información" funcion={hideCehvalMenu}></SubComponenteHeader>
                                 <SubComponenteHeader subgrupo={16} titulo="Tarificación" funcion={hideCehvalMenu}></SubComponenteHeader>
                                 <SubComponenteHeader subgrupo={17} titulo="Mantenimiento y carga de valores" funcion={hideCehvalMenu}></SubComponenteHeader>
                              </ul>
                           </ul>

                           <ul className="col-12 col-sm-8 col-md-6 col-lg-8 p-2 text-no-decoration d-flex justify-content-center align-items-end font-acumin-variable m-0 p-0 fs-medium fs-small cursor-pointer">
                              <li><Link className="text-white p-2 rounded fw-bold text-no-decoration text-hover-gold-2 mx-3" to="/inicioCehval">Inicio</Link></li>
                              <li><span className="text-white p-2 rounded fw-bold text-no-decoration text-hover-gold-2 mx-3" href="#">Acerca de</span></li>
                              <li><span className="text-white p-2 rounded fw-bold text-no-decoration text-hover-gold-2 mx-3" href="#">Contacto</span></li>
                           </ul>
                        </>
                     }

                     {/*Header options when there isnt a session in progress*/}
                     {!login &&
                        <ul className="col-12 p-2 text-no-decoration d-flex justify-content-center align-items-center font-acumin-variable m-0 p-0 fs-medium">
                           <li><span className="text-white p-2 rounded fw-bold text-no-decoration text-hover-gold-2 mx-3 fs-small" href="#">Marco legal</span></li>
                           <li><span className="text-white p-2 rounded fw-bold text-no-decoration text-hover-gold-2 mx-3 fs-small" href="#">Acerca de</span></li>
                           <li><span className="text-white p-2 rounded fw-bold text-no-decoration text-hover-gold-2 mx-3 fs-small" href="#">Contacto</span></li>
                        </ul>
                     }
                  </div>
               </div>

               {/*Side Navbar */}
               <div id="navbarSidebar" className="container-fluid">
                  <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasSideNavbar" aria-labelledby="offcanvasSideNavbarLabel">
                     <div className="offcanvas-header border-bottom border-muted d-flex">
                          <img id="logoSideNavbar"  style={{ width: process.env.REACT_APP_logo_movil_header_width,height: process.env.REACT_APP_logo_movil_header_height}} className="logoSideNavbar" src={process.env.REACT_APP_logo_movil_header} alt="logo azul" />
                           {/*   <img id="logoSideNavbarCehval" className="logoSideNavbarCehval" src={logoCehvalBlue} alt="logo azul"/>*/}
                        <button type="button" className="btn-close align-start" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                     </div>
                     <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3 font-acumin-variable">
                           {/*Lista opciones del menu*/}
                           <li className="nav-item mb-3" data-bs-dismiss="offcanvas">
                              {/*If user is not logged*/}
                              {!login &&
                                 <div className="row align-items-center text-hover-blue-shadow g-0">
                                    <div className="col-2 p-0 row text-royal-blue align-items-center">
                                       <i className="ri-user-line p-0 text-center pe-1"></i>
                                    </div>
                                    <Link to="/login" className="col-6 text-royal-blue navbar-brand fs-x-small ps-3">
                                       Iniciar sesión
                                    </Link>
                                 </div>
                              }
                              {/*If user is logged*/}
                              {login &&
                                 <div className="row align-items-center g-0">
                                    <div className="col-6 p-0 row text-royal-blue align-items-center text-hover-blue-shadow" data-bs-dismiss="offcanvas">
                                       <i className="col-3 ri-user-line p-0 text-end pe-1"></i>
                                       <Link to="/inicioCehval" className="col-6 text-royal-blue navbar-brand fs-x-small ps-3">
                                          {username}
                                       </Link>
                                    </div>
                                    <div className="col-6 text-end" data-bs-dismiss="offcanvas">
                                       <span className="text-royal-blue navbar-brand fs-x-small text-hover-blue-shadow cursor-pointer" onClick={signOut}>
                                          Cerrar sesión
                                       </span>
                                    </div>
                                 </div>
                              }
                           </li>
                           {/*CEHVAL OPTIONS*/}
                           <SubComponenteHeader2 subgrupo={1} titulo="Administración de depositantes" nav="#nav-option-1" id="nav-option-1"></SubComponenteHeader2>
                           <SubComponenteHeader2 subgrupo={2} titulo="Administración de valores físicos" nav="#nav-option-2" id="nav-option-2"></SubComponenteHeader2>
                           <SubComponenteHeader2 subgrupo={3} titulo="Administración de usuarios" nav="#nav-option-4" id="nav-option-4"></SubComponenteHeader2>
                           <SubComponenteHeader2 subgrupo={4} titulo="Cuentas de valores" nav="#nav-option-6" id="nav-option-6"></SubComponenteHeader2>
                           <SubComponenteHeader2 subgrupo={5} titulo="Administración de emisores, emisiones y series" nav="#nav-option-3" id="nav-option-3"></SubComponenteHeader2>
                           <SubComponenteHeader2 subgrupo={6} titulo="Registro emisores, emisiones y series BCV" nav="#nav-option-11" id="nav-option-11"></SubComponenteHeader2>
                           <SubComponenteHeader2 subgrupo={7} titulo="Operaciones con valores" nav="#nav-option-10" id="nav-option-10"></SubComponenteHeader2>
                           <SubComponenteHeader2 subgrupo={8} titulo="Mercado primario" nav="#nav-option-8" id="nav-option-8"></SubComponenteHeader2>
                           <SubComponenteHeader2 subgrupo={9} titulo="Mercado secundario" nav="#nav-option-9" id="nav-option-9"></SubComponenteHeader2>
                           <SubComponenteHeader2 subgrupo={10} titulo="Bitácoras" nav="#nav-option-5" id="nav-option-5"></SubComponenteHeader2>
                           <SubComponenteHeader2 subgrupo={11} titulo="Impresión" nav="#nav-option-7" id="nav-option-7"></SubComponenteHeader2>
                           <SubComponenteHeader2 subgrupo={12} titulo="Códigos ISIN" nav="#nav-option-12" id="nav-option-12"></SubComponenteHeader2>
                           <SubComponenteHeader2 subgrupo={13} titulo="Pagos de derechos patrimoniales" nav="#nav-option-13" id="nav-option-13"></SubComponenteHeader2>
                           <SubComponenteHeader2 subgrupo={14} titulo="Salvaguardas financieras" nav="#nav-option-14" id="nav-option-14"></SubComponenteHeader2>
                           <SubComponenteHeader2 subgrupo={15} titulo="Sistema de información" nav="#nav-option-15" id="nav-option-15"></SubComponenteHeader2>
                           <SubComponenteHeader2 subgrupo={16} titulo="Tarificación" nav="#nav-option-16" id="nav-option-16"></SubComponenteHeader2>
                           <SubComponenteHeader2 subgrupo={17} titulo="Mantenimiento y carga de valores" nav="#nav-option-17" id="nav-option-17"></SubComponenteHeader2>
                        </ul>
                        {/*
                        <form className="d-flex mt-3" role="search">
                           <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                           <button className="btn background-gold" type="submit">Search</button>
                        </form>
                        */}
                     </div>

                     {/*ICONOS REDES SOCIALES */}
                     <div id="redes-sociales" className="col-12 d-flex row">
                        <ul className="list-unstyled d-flex align-items-center justify-content-center m-0 p-2">
                           <li id="twitter" className="ms-3 ">
                              <Link className="link-dark" to="#">
                                 <FontAwesomeIcon
                                    icon={faTwitter}
                                    className="twitter divRedesSocialesEncabezado change-color-icons fs-6 text-royal-blue "
                                 />
                              </Link>
                           </li>
                           <li id="facebook" className="ms-3 ">
                              <Link className="link-dark" to="#">
                                 <FontAwesomeIcon
                                    icon={faFacebook}
                                    className="facebook divRedesSocialesEncabezado change-color-icons fs-6 text-royal-blue "
                                 />
                              </Link>
                           </li>
                           <li id="instagram" className="ms-3 ">
                              <Link className="link-dark" to="#">
                                 <FontAwesomeIcon
                                    icon={faInstagram}
                                    className="instagram divRedesSocialesEncabezado change-color-icons fs-6 text-royal-blue "
                                 />
                              </Link>
                           </li>
                           <li id="youtube" className="ms-3 ">
                              <Link className="link-dark" to="#">
                                 <FontAwesomeIcon
                                    icon={faYoutube}
                                    className="youtube divRedesSocialesEncabezado change-color-icons fs-6 text-royal-blue "
                                 />
                              </Link>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </nav>
         </header>
      </>

   )

}