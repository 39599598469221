import React, { useState } from "react";


//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";

import TablaMercado from "../../../ComponentesGenerales/TablaMercado";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";


//APIs
import { consultarPagosDerechosPatrimonialesId } from "../../../api/PagoInteresesAmorDiv/consultarPagosPatrimonialesId";
import { confirmarPagosDerechosPatrimoniales } from "../../../api/PagoInteresesAmorDiv/confirmarPagosDerechosPatrimoniales";
import { imprimirConfirmacionPagos } from "../../../api/PagoInteresesAmorDiv/imprimirConfirmacionPagos";

//Hooks
import useConfirmacionMontos from "../../../hooks/PagoDerechosPatrimoniales/useConfirmacionMontos";


export default function ConfirmacionMontosPagosDerechosPatrimoniales({ history }) {


    const [registroExitoso, setRegistroExitoso] = React.useState()
    const [registroFallido, setRegistroFallido] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [mensaje, setMensaje] = React.useState()
    const [spin, setSpin] = React.useState(true)

    const [datos, setDatos] = useState()
    const [arregloRetenciones, setArregloRetenciones] = useState([])
    const [arregloMontos, setArregloMontos] = useState([])
    const [arregloRetencionesFront, setArregloRetencionesFront] = useState([])
    const [arregloMontosFront, setArregloMontosFront] = useState([])
    const [desplegarCampo, setDesplegarCampo] = useState()
    const [desplegarRetenciones, setDesplegarRetenciones] = useState(true)
    const [desplegarRetenciones2, setDesplegarRetenciones2] = useState()
    const [tabla, setTabla] = useState(true)
    const [tabla2, setTabla2] = useState()
    const [montoFaltante, setMontoFaltante] = useState()
    const [loading, setLoading] = React.useState(false);



    React.useEffect(() => {
        guardarDatos();
    }, []);



    const guardarDatos = async () => {
        if (history.location.state === undefined) {
            history.go(-1)
        } else {
            const response = await consultarPagosDerechosPatrimonialesId({
                id: history.location.state.objMenu.codigoId
            })

            //console.log(response)
            setSpin(false)
            if (response) {
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    setDatos(response)
                }
            }
        }
    }


    const confirmar = async (e) => {
        setLoading(true)
        const response = await confirmarPagosDerechosPatrimoniales(datos)
        setSpin(false)
        if (response) {
            setLoading(false)
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(response)
            } else {
                if (response.codigo === "000") {
                    setMensaje("Montos confirmados")
                    setRegistroExitoso(true)
                } else {
                    setMensaje(response.mensaje)
                    setRegistroFallido(true)
                }

            }
        }


    }


    const noConfirmar = async (e) => {
       
        let arrayPivote = datos?.depositantesPagos;

      //  let element=document.getElementById(`rentencionesInput`)
        //console.log(element)
        
        for (let i = 0; i < arrayPivote.length; i++) {
            arregloMontos[i] = arrayPivote[i].montoAPagar
            arregloMontosFront[i] = arrayPivote[i].montoAPagarStr
            arregloRetenciones[i] = arrayPivote[i].retencionesPorCehval
            arregloRetencionesFront[i] = arrayPivote[i].retencionesPorCehvalStr

           // let element=document.getElementById(`rentencionesInput${i}`)
           // element.value=arrayPivote[i].retencionesPorCehvalStr;
        }



        setDesplegarCampo(true)
    }


    const guardarMonto = (e, index) => {
     
        //console.log("guardar")
        let porcentaje = datos?.porcentajeRetencion
        let arregloPivoteMontos = arregloMontos;
        let arregloPivoteRetenciones = arregloRetenciones;
        const montoReplaced = e.replace(/[^\d.]/g, '');
        let montoRetenciones;

        //le quitamos las comas
        arregloPivoteMontos[index] = montoReplaced;
        if (datos?.tipoPago.indexOf("AMORTIZACIONES") >= 0) {
            montoRetenciones = 0 * (porcentaje / 100)
            montoRetenciones = montoRetenciones.toFixed(2)
            arregloPivoteRetenciones[index] = 0;
        } else if (datos?.depositantesPagos[index].exentoImpuestos === "SI") {
            montoRetenciones = 0 * (porcentaje / 100)
            montoRetenciones = montoRetenciones.toFixed(2)
            arregloPivoteRetenciones[index] = 0;
        } else {
            montoRetenciones = montoReplaced * (porcentaje / 100)
            montoRetenciones = montoRetenciones.toFixed(2)
            arregloPivoteRetenciones[index] = montoReplaced * (porcentaje / 100)
        }



        setArregloMontos(arregloPivoteMontos)
        formatNumber(montoReplaced, setArregloMontosFront, index, arregloMontosFront);

       
        setArregloRetenciones(arregloPivoteRetenciones)
        //formatearRentencion(montoRetenciones.toString())
       formatNumber(montoRetenciones.toString(), setArregloRetencionesFront, index, arregloRetencionesFront);

     if (desplegarRetenciones) {
            setDesplegarRetenciones(false)
            setDesplegarRetenciones2(true)
        } else {
            setDesplegarRetenciones(true)
            setDesplegarRetenciones2(false)
        }


    }

    const formatNumber = (numberReplaced, setState, index, arregloEnviado) => {
       /* console.log(numberReplaced)
        console.log(setState)
        console.log(index)
        console.log(arregloEnviado)
        //console.log(numberReplaced)*/
        let arregloPivote = arregloEnviado;

        // Divide the numberSerieAmount into two parts: the integer part and the decimal part
        let splitNumber = numberReplaced.split('.');
        let integerPart = splitNumber[0];

        //Check number of positions of array and add ".numbersOfSecondPosition" if array has more than one position
        let decimalPart = splitNumber.length > 1 ? `.${splitNumber[1]}` : '';

        // Add commas as thousands separators in the integer part
        const addCommas = insertCommas(integerPart);

        // Combine the integer part and the decimal part into a single formatted number
        let formatedNumber = addCommas + decimalPart;

        //Use setMontoFront() to set state
        if (numberReplaced === "0" && setState === setArregloRetencionesFront) {
            formatedNumber = "0.00"
        }
        arregloPivote[index] = formatedNumber;

        setState(arregloPivote);
    }

    function insertCommas(number) {
        const reversedNumber = String(number).split('').reverse().join('');
        const regex = /(\d{3})(?=\d)/g;
        const formattedNumber = reversedNumber.replace(regex, '$1,');
        return formattedNumber.split('').reverse().join('');
    }

    const formatearRentencion=(e)=>{
        let elemento=document.getElementById("rentencionesInput")

        let splitNumber = e.split('.');
        let integerPart = splitNumber[0];

        //Check number of positions of array and add ".numbersOfSecondPosition" if array has more than one position
        let decimalPart = splitNumber.length > 1 ? `.${splitNumber[1]}` : '';

        // Add commas as thousands separators in the integer part
        const addCommas = insertCommas(integerPart);

        // Combine the integer part and the decimal part into a single formatted number
        let formatedNumber = addCommas + decimalPart;

        //Use setMontoFront() to set state
        if (e === "0" ) {
            formatedNumber = "0.00"
        }

        elemento.value=formatedNumber;
    }

    const guardarRetenciones=(e, index)=>{

      // formatearRentencion(e)



       let arregloPivoteRetenciones = arregloRetenciones;
        const montoReplaced = e.replace(/[^\d.]/g, '');
        let montoRetenciones;

        arregloPivoteRetenciones[index]=montoReplaced;
        //montoRetenciones = montoReplaced.toFixed(2)


        setArregloRetenciones(arregloPivoteRetenciones)
        formatNumber(montoReplaced.toString(), setArregloRetencionesFront, index, arregloRetencionesFront);

        if (desplegarRetenciones) {
            setDesplegarRetenciones(false)
            setDesplegarRetenciones2(true)
        } else {
            setDesplegarRetenciones(true)
            setDesplegarRetenciones2(false)
        }
    }


    const actualizarDatos = () => {
        let objetoPivote = datos
        let condicion = true;
        let suma = 0;

        //si falta algun monto mandamos un error
        for (let i = 0; i < objetoPivote.depositantesPagos.length; i++) {
            if (!arregloMontos[i]) {
                condicion = false
                setMontoFaltante(true)
            }
        }

        //entra a qui si no hya ningun error
        if (condicion) {
            for (let i = 0; i < objetoPivote.depositantesPagos.length; i++) {
                //console.log(suma)
                suma = parseFloat(suma) + parseFloat(arregloMontos[i]);
                //arraySeries.push(i + 1);
                //setSeriesReady(false);
                objetoPivote.depositantesPagos[i].montoAPagar = arregloMontos[i]
                objetoPivote.depositantesPagos[i].montoAPagarStr = arregloMontosFront[i];
                objetoPivote.depositantesPagos[i].retencionesPorCehval = arregloRetenciones[i]
                objetoPivote.depositantesPagos[i].retencionesPorCehvalStr = arregloRetencionesFront[i]
                // arrayPivote[i].retencionesPorCehval = arregloRetenciones[i].toString()
            }

            suma = suma.toFixed(2);
            objetoPivote.montoTotal = suma

            let splitNumber = suma.toString().split('.');
            let integerPart = splitNumber[0];

            //Check number of positions of array and add ".numbersOfSecondPosition" if array has more than one position
            let decimalPart = splitNumber.length > 1 ? `.${splitNumber[1]}` : '';

            // Add commas as thousands separators in the integer part
            const addCommas = insertCommas(integerPart);

            // Combine the integer part and the decimal part into a single formatted number
            let formatedNumber = addCommas + decimalPart;



            objetoPivote.montoTotalStr = formatedNumber


            setDatos(objetoPivote)



            if (tabla) {
                setTabla(false)
                setTabla2(true)
            } else {
                setTabla(true)
                setTabla2(false)
            }

            setDesplegarCampo(false)

        }
    }

    const irInicio = () => {
        setRegistroExitoso(false)
        imprimir()
    }



    const imprimir = async (row) => {
        setLoading(true)

        const response = await imprimirConfirmacionPagos(datos)
        if (response) {
            setLoading(false)
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta           
                setLoading(false)
                setErroreshttp(response)
            } else {
                const file = new Blob(
                    [response],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
                history.go(-1)
            }
        }
    }

 
    return (
        <div className="fluid-container">
            {loading &&
                <PantallaCargando />
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={irInicio} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={() => { setRegistroFallido(false) }} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {montoFaltante &&
                <ComponenteModal mostrarModal={montoFaltante} cerrarModal={() => { setMontoFaltante(false) }} titulo="Ingrese todos los montos" textoBoton="Entendido"></ComponenteModal>
            }

            


            <main className="mb-5">
                <div className="row row-wrap g-0">
                    <div className="col-12">
                        <div className="">
                            <div className="row d-flex justify-content-center g-0">
                                <div className="col-12" id="divToPrint">
                                    <BannerTitulo titulo="Confirmación de montos para pagos de derechos patrimoniales"></BannerTitulo>
                                    <div className="px-3 px-lg-4 px-xl-4 px-xxl-5 border-none">
                                        {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                            <div className="card-body ">
                                                <div className="col-12 mx-auto">
                                                    {datos &&
                                                        <>
                                                            <div className="card">

                                                                <div className="card-body">
                                                                    <form className="row g-0 needs-validation" novalidate >

                                                                        <div className="row mb-3">
                                                                            <div className="col-md-6 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.isin} id="isin" name="isin"  required disabled />
                                                                                    <label htmlFor="isin" className=" textoFormularioDepositantedirecto">ISIN</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-6 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.serie} id="nombreSerie" name="nombreSerie" required disabled />
                                                                                    <label htmlFor="nombreSerie" className=" textoFormularioDepositantedirecto">Nombre serie</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row mb-3">
                                                                            <div className="col-md-6 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.montoTotalStr} id="montoTotal" name="montoTotal"  required disabled />
                                                                                    <label htmlFor="montoTotal" className=" textoFormularioDepositantedirecto">Monto total *</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-6 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.moneda} id="moneda" name="moneda" required disabled />
                                                                                    <label htmlFor="moneda" className=" textoFormularioDepositantedirecto">Moneda</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row mb-3">
                                                                            <div className="col-md-6 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.tipoPago} id="tipoPago" name="tipoPago"  required disabled />
                                                                                    <label htmlFor="tipoPago" className=" textoFormularioDepositantedirecto">Tipo de pago</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-6 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.fechaPago} id="periodoRevisionTfechaPagoasaVariable" name="fechaPago"  required disabled />
                                                                                    <label htmlFor="fechaPago" className=" textoFormularioDepositantedirecto">Fecha de pago</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                   
                                                                    </form>
                                                                </div>
                                                                {desplegarCampo ?

                                                                    <TablaMercado datos={datos.depositantesPagos} useValidate={useConfirmacionMontos} ></TablaMercado>
                                                                    :
                                                                    <>
                                                                        {tabla &&
                                                                            <TablaMercado datos={datos.depositantesPagos} useValidate={useConfirmacionMontos} mercadoPrimario={true} funcion1MercadoPrimario={noConfirmar} funcion2MercadoPrimario={confirmar} texto1MercadoPrimario="No confirmar pagos" texto2MercadoPrimario="Confirmar pagos" ></TablaMercado>
                                                                        }
                                                                        {tabla2 &&
                                                                            <TablaMercado datos={datos.depositantesPagos} useValidate={useConfirmacionMontos} mercadoPrimario={true} funcion1MercadoPrimario={noConfirmar} funcion2MercadoPrimario={confirmar} texto1MercadoPrimario="No confirmar pagos" texto2MercadoPrimario="Confirmar pagos" ></TablaMercado>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        </>
                                                    }

                                                    {desplegarCampo &&
                                                        <>
                                                            <div className="mt-5">
                                                                <div className="row mb-3">
                                                                    <div className="col-md-3 d-none d-md-block   mb-3">
                                                                        <h5>Nombre cliente</h5>
                                                                    </div>
                                                                    <div className="col-md-3 d-none d-md-block mb-3">
                                                                        <h5>Monto a pagar</h5>
                                                                    </div>
                                                                    <div className="col-md-3 d-none d-md-block mb-3">
                                                                        <h5> Retenciones</h5>
                                                                    </div>
                                                                </div>
                                                                {datos?.depositantesPagos.map((item, index) => (
                                                                    <div className="row mb-3">

                                                                        <h4 className="d-block d-sm-block d-md-none text-royal-blue font-acumin-variable mt-3">Pago {index + 1}</h4>
                                                                        <div className="col-md-3 mb-3">
                                                                            <div className="form-floating">
                                                                                <input type="text" className="form-control textoFormularioDepositantedirecto" value={item.nombreClienteCehval} id="nombreSerie" name="nombreSerie" required disabled />
                                                                                <label htmlFor="nombreSerie" className=" textoFormularioDepositantedirecto">Nombre de cliente CEHVAL</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3 mb-3">
                                                                            <div className="form-floating">
                                                                                <input type="text" className="form-control textoFormularioDepositantedirecto" value={arregloMontosFront[index]} onChange={(e) => guardarMonto(e.target.value, index)} id="nombreSerie" name="nombreSerie" placeholder="" required />
                                                                                <label htmlFor="nombreSerie" className=" textoFormularioDepositantedirecto">Monto</label>
                                                                            </div>
                                                                        </div>

                             
                                                                   
                                                                            <div className="col-md-3 mb-3">                                                                                                                    
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={arregloRetencionesFront[index]} onChange={(e) => guardarRetenciones(e.target.value, index)} id={`rentencionesInput`} name={`rentencionesInput${index}`} required  />
                                                                                    <label htmlFor="rentencionesInput" className=" textoFormularioDepositantedirecto">Retención</label>
                                                                                </div>
                                                                            </div>
                                                                        

                                                                     { /*  {desplegarRetenciones2 &&
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={arregloRetencionesFront[index]}   onChange={(e) => guardarRetenciones(e.target.value, index)}  id={`rentencionesInput`} name={`rentencionesInput${index}`} required  />
                                                                                    <label htmlFor="rentencionesInput" className=" textoFormularioDepositantedirecto">Retención</label>
                                                                                </div>
                                                                            </div>
                                                                        }*/}

                                                                       {/*{desplegarRetenciones &&
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={arregloRetencionesFront[index]} onChange={(e) => guardarRetenciones(e.target.value, index)} id="nombreSerie" name="nombreSerie" required  />
                                                                                    <label htmlFor="nombreSerie" className=" textoFormularioDepositantedirecto">Retención</label>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                        {desplegarRetenciones2 &&
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={arregloRetencionesFront[index]} onChange={(e) => guardarRetenciones(e.target.value, index)}  id="nombreSerie" name="nombreSerie" required  />
                                                                                    <label htmlFor="nombreSerie" className=" textoFormularioDepositantedirecto">Retención</label>
                                                                                </div>
                                                                            </div>
                                                                        }*/}

                                                                        {index + 1 === datos.depositantesPagos.length &&
                                                                            <div className="col-12 col-md-3 d-flex justify-content-between mb-3">
                                                                                <button type="button" onClick={() => setDesplegarCampo(false)} className="btn  col-5 col-sm-5 col-md-5 col-lg-5 col-xl-4 background-royal-blue text-white bg-hover-gold">Cancelar</button>
                                                                                <button type="button" onClick={actualizarDatos} className="btn  col-5 col-sm-5 col-md-5 col-lg-5 col-xl-4 background-royal-blue text-white bg-hover-gold">Aceptar</button>
                                                                            </div>

                                                                        }
                                                                    </div>
                                                                ))
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main >

        </div >
    );


}

