import React, { useState, useEffect } from 'react';

//Components
import BannerTitulo from '../../../../ComponentesGenerales/Bannertitulo';

//Form to do TLP
import FormularioTraspasoLibrePago from '../FormularioTraspasoLibrePago';


export default function TransferenciaDD({ history }) {
 
    //Sacamos del local storage el nombre y codigo de depositante
    const codigoDepositante = (JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo;
    const primerNombreDepositante = (JSON.parse(window.sessionStorage.getItem('usuarioRol'))).nombre;
    const primerApellidoDepositante = (JSON.parse(window.sessionStorage.getItem('usuarioRol'))).primerApellido;
    const nombreDepositante = `${primerNombreDepositante} ${primerApellidoDepositante}`;



    const [monedaCuenta, setMonedaCuenta] = useState();
    const [numeroCuenta, setNumeroCuenta] = useState();
    const [saldo, setSaldo] = useState();
    const [isin, setIsin] = useState();
    const [codSerie, setCodSerie] = useState();
    const [codEmision, setCodEmision] = useState();
    const [idValor, setIdValor] = useState();


    useEffect(() => {
        getTransactionRowInfo();
    }, []);

    const getTransactionRowInfo = () => {
        try {

            if (history.location.state === undefined) {
                history.go(-1)

            } else {
                const datosTransferente = history.location.state.datosTransferente;

                //datos del transferente
                setMonedaCuenta(datosTransferente.moneda);
                setNumeroCuenta(datosTransferente.numeroCuenta);
                setSaldo(datosTransferente.saldoDisponible);
                setIsin(datosTransferente.isinSerie);
                setCodSerie(datosTransferente.codSerie);
                setCodEmision(datosTransferente.codEmision);
                setIdValor(datosTransferente.idValor);
            }

        } catch (error) {
            console.error(error);
        }
    }


    return (
        <>
            <div className="fluid-container">
                
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                         
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Datos de la transferencia"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <FormularioTraspasoLibrePago
                                            codDepositante={codigoDepositante}
                                            numeroCuenta={numeroCuenta}
                                            nombreDepositante={nombreDepositante}
                                            monedaCuenta={monedaCuenta}
                                            saldoDisponible={saldo}
                                            isin={isin}
                                            codSerie={codSerie}
                                            codEmision={codEmision}
                                            idValor={idValor}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    )
}