import React from "react";
import { FormContext } from "./form_context";

export default function RegistroDI4(props) {
    const [, setValue] = React.useContext(FormContext);
    React.useEffect(() => {
            setValue((prev) => {
                const datos = { ...prev.datos, ["enviar"]: 0 };
                return { ...prev, datos };
            });


    }, [])



    return (
        <>


            <div className="card-body">
                <h5 className="textoTituloFormulario" >IV. Instrucciones especiales (cuentas bancarias)</h5>
                {props.info.cehvalCuentaBanCollection.length > 0 ?
                    <>
                        {
                            props.info.cehvalCuentaBanCollection.map((item, index) => (
                                <>
                                    {item.catMonedaCatMonedaId.catMonedaDivisa === "LEMPIRA" ?
                                        <h6 className="textoTituloFormulario">Nacional</h6> :
                                        <h6 className="textoTituloFormulario">Extranjera</h6>

                                    }


                                    <div className="row mb-3">
                                        <div className="col-md-3">
                                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Moneda</label>
                                            <input disabled type="text" value={item.catMonedaCatMonedaId.catMonedaDivisa} className="form-control  border  " />

                                        </div>

                                        <div className="col-md-3">
                                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Banco</label>
                                            <input disabled type="text" value={item.catBancoCatBancoId.catBancoNombre} className="form-control  border  " />
                                        </div>

                                        <div className="col-md-3">
                                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Tipo de cuenta</label>
                                            <input disabled type="text" value={item.catTipoCuentaCatTipCueId.catTipCueCuenta} className="form-control  border  " />
                                        </div>

                                        <div className="col-md-3">
                                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Número de cuenta</label>
                                            <input disabled type="text" value={item.cehvalCuentaBanNumCue} className="form-control  border  " id="numeroCuentaN" name="numeroCuentaN" required />
                                        </div>
                                    </div>

                                </>
                            ))
                        }
                    </>
                    :
                    <h5>No hay cuentas registradas</h5>
                }

                <br></br>

                <h5 className="text-royal-blue font-acumin-variable " >IV. Instrucciones especiales (exención de impuestos en operaciones con valores)</h5>
                <div className="col-md-7 mb-5">
                    <label>¿La institución esta eximida de impuestos en operaciones con valores?</label>
                    {props.info.cehvalDepositantePersonaJurId.cehvalPersonaJurExencionImpuestos === 1 ?

                        <input disabled type="text" value={"Si"} className="form-control  border  " required />
                        :

                        <input disabled type="text" value={"No"} className="form-control  border  " required />

                    }
                </div>

                <h5 className="textoTituloFormulario" >IV. Instrucciones especiales (usuarios)</h5>

                {
                    props.info.cehvalContactoCollection.map((item, index) => (
                        <>
                            {props.info.cehvalContactoCollection[index].nombre &&
                                <>
                                    {props.info.cehvalDepositanteCatTipDepId.nombre === "DIRECTO" ?
                                        <>
                                            {item.usuarioAdmin === 1 ?
                                                <h5 className="textoTituloFormulario" >{index + 1}. Usuario administrador</h5>
                                                :
                                                <h5 className="textoTituloFormulario" >{index + 1}. Usuario operador</h5>
                                            }
                                        </>
                                        :
                                        <>
                                            <h5 className="textoTituloFormulario" >{index + 1}. Usuario operador</h5>
                                        </>
                                    }


                                    <div className="row mb-3">
                                        <div className="col-md-4">
                                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Primer nombre</label>
                                            <input disabled type="text" value={props.info.cehvalContactoCollection[index].nombre} className="form-control " id="nombreCompleto1" name="nombreCompleto1" required />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Segundo nombre</label>
                                            <input disabled type="text" value={props.info.cehvalContactoCollection[index].segundoNombre} className="form-control " id="nombreCompleto1" name="nombreCompleto1" required />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Primer apellido</label>
                                            <input disabled type="text" value={props.info.cehvalContactoCollection[index].primerApellido} className="form-control " id="nombreCompleto1" name="nombreCompleto1" required />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-4">
                                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Segundo apellido</label>
                                            <input disabled type="text" value={props.info.cehvalContactoCollection[index].segundoApellido} className="form-control " id="nombreCompleto1" name="nombreCompleto1" required />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="exampleInputEmail1" className="form-label textoFormularioDepositantedirecto">Correo electrónico</label>
                                            <input disabled type="text" value={props.info.cehvalContactoCollection[index].correo} className="form-control " id="correo1" name="correo1" aria-describedby="emailHelp" required />
                                        </div>
                                    </div>
                                </>
                            }


                        </>
                    ))
                }
            </div>

        </>
    );
}

