import { instance } from '../apiGlobal';

export async function imprimirTablaLiquidacionSubastas(obj) {
      try {
        const response = await instance('/reporte/liquidacion/subasta', {
          method:"post",
          data:obj,
          responseType: 'blob',
        })
        return response.data
      } catch (error) {
        return error
      }
}