//React
import React from "react";
import ComponenteMenuEmsoresEmisionesSeries from "../../../ComponentesGenerales/ComponenteMenuEmsoresEmisionesSeries";

export default function MenuAutorizarEmisoresEmisionesSeries({ history }) {


    return (

        <ComponenteMenuEmsoresEmisionesSeries titulo="Autorizar emisores, emisiones y series" ruta1="/solicitudesEmisoresParaAutorizar" ruta2="/solicitudEmisiones" ruta3="/solicitudSeries" ></ComponenteMenuEmsoresEmisionesSeries>
     
    );
}

