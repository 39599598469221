import { useMemo } from "react";

export default function useOperacionesMS () {
  const columns = useMemo(
    () => [
      {
        Header: "Folio de operación",
        accessor: "folio"
      },
      {
        Header: "ISIN",
        accessor: "isin"
      },
      {
        Header: "Valores",
        accessor: "numeroValoresStr"
      },
      {
        Header: "Moneda de liquidación",
        accessor: "monedaLiquidacionStr"
      },
      {
        Header: "Tipo",
        accessor: "tipoStr"
      },
      {
        Header: "Estatus",
        accessor: "estatusValidacionStr"
      }             
    ],
    []
  );

  return columns;
}
