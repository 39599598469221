import React from "react";
import '../../../../styles/stylesheet.css'
import { FormContext } from './form_context';
import { traerPuestos } from "../../../../api/catalogos/traerPuestos";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";

export default function RegistroDI5({ tipo }) {
    const [value, setValue] = React.useContext(FormContext);

    const [erroreshttp, setErroreshttp] = React.useState()
    const [puestos, setPuestos] = React.useState([])
    const guardarDatos = (e) => {
        const { name, value } = e.target;



        setValue((prev) => {
            const datos = { ...prev.datos, [name]: value };
            return { ...prev, datos };
        });
    }

    const guardarDatosFiltrados = (name, value) => {
        setValue((prev) => {
            const datos = { ...prev.datos, [name]: value };
            return { ...prev, datos };
        });
    }

    React.useEffect(() => {


        guardarDatosFiltrados("nombreCompleto1", value.datos.nombreCompleto1.trim())
        guardarDatosFiltrados("segundoNombre1", value.datos.segundoNombre1.trim())
        guardarDatosFiltrados("primerApellido1", value.datos.primerApellido1.trim())
        guardarDatosFiltrados("segundoApellido1", value.datos.segundoApellido1.trim())
        guardarDatosFiltrados("correo1", value.datos.correo1.trim())
        guardarDatosFiltrados("nombreCompleto2", value.datos.nombreCompleto2.trim())
        guardarDatosFiltrados("segundoNombre2", value.datos.segundoNombre2.trim())
        guardarDatosFiltrados("primerApellido2", value.datos.primerApellido2.trim())
        guardarDatosFiltrados("segundoApellido2", value.datos.segundoApellido2.trim())
        guardarDatosFiltrados("correo2", value.datos.correo2.trim())




        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 0 };
            return { ...prev, datos };
        });

        const nombreCompleto1 = value.datos.nombreCompleto1 + " " + value.datos.segundoNombre1 + " " + value.datos.primerApellido1 + " " + value.datos.segundoApellido1
        setValue((prev) => {
            const datos = { ...prev.datos, ["nombreCompleto_1"]: nombreCompleto1 };
            return { ...prev, datos };
        });

        const nombreCompleto2 = value.datos.nombreCompleto2 + " " + value.datos.segundoNombre2 + " " + value.datos.primerApellido2 + " " + value.datos.segundoApellido2
        setValue((prev) => {
            const datos = { ...prev.datos, ["nombreCompleto_2"]: nombreCompleto2 };
            return { ...prev, datos };
        });
        traerDatos()

    }, [])

    const traerDatos = async () => {

        //console.log()
        const catalogoPuestos = await traerPuestos()
        //console.log(catalogoPuestos)
        if (catalogoPuestos) {
            if ((isNaN(catalogoPuestos.error)) === false) {
                setErroreshttp(catalogoPuestos)
            } else {
                setPuestos(catalogoPuestos)
            }
        }
    }






    return (
        <>
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <div className="card-body pt-3">

                <h5 className="text-royal-blue font-acumin-variable mb-4" >V. Firmas autorizadas</h5>
                {tipo === "depositanteDirecto" ? <h6 className="textoTituloFormulario" >1. Usuario administrador *</h6> :
                    <h6 className="textoTituloFormulario" >1. Usuario operador *</h6>
                }


                <div className="row mb-3">
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating">
                            < input  type="text" className="form-control border  " value={value.datos.nombreCompleto_1} onChange={(e) => guardarDatos(e)} id="nombreCompleto_1" name="nombreCompleto_1" disabled />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Nombre completo *</label>
                        </div>
                    </div>

                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating">
                            <select className="form-select border" value={value.datos.cargo_1} onChange={(e) => guardarDatos(e)} id="cargo_1" name="cargo_1" required>
                                <option value=""> </option>
                                {
                                    puestos.map((item, index) => (
                                        <option key={index} value={item.nombre} >{item.nombre}</option>
                                    ))
                                }

                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Cargo *</label>
                        </div>
                    </div>
                    <div className="col-md-4 mt-3 mb-4">
                        <label>Tipo de firma *</label>
                        <div className="d-flex justify-content-start">
                            <div className="contenedorBotonesStteper ">
                                < input placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => guardarDatos(e)} id="individual1" name="tipoFirma_1" required />
                                <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="individual1">Individual</label>
                            </div>

                            <div className="contenedorBotonesStteper">
                                < input placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => guardarDatos(e)} id="mancomunada1" name="tipoFirma_1" required />
                                <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="mancomunada1">Mancomunada</label>

                            </div>
                        </div>
                    </div>
                </div>
                <h6 className="textoTituloFormulario" >2. Usuario operador *</h6>
                <div className="row mb-3">
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating">
                            < input  type="text" className="form-control border  " value={value.datos.nombreCompleto_2} onChange={(e) => guardarDatos(e)} id="nombreCompleto_2" name="nombreCompleto_2" disabled />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Nombre completo *</label>
                        </div>
                    </div>
                    <div className="col-md-4 form-floating">
                        <div className="form-floating">
                            <select className="form-select border" value={value.datos.cargo_2} onChange={(e) => guardarDatos(e)} id="cargo_2" name="cargo_2" required>
                                <option value=""> </option>
                                {
                                    puestos.map((item, index) => (
                                        <option key={index} value={item.nombre} >{item.nombre}</option>
                                    ))
                                }

                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Cargo *</label>
                        </div>
                    </div>


                    <div className="col-md-4 mt-3">
                        <label>Tipo de firma *</label>
                        <div className="d-flex justify-content-start">
                            <div className="contenedorBotonesStteper">
                                < input placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => guardarDatos(e)} id="individual2" name="tipoFirma_2" required />
                                <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="individual2">Individual</label>
                            </div>

                            <div className="contenedorBotonesStteper">
                                < input placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => guardarDatos(e)} id="mancomunada2" name="tipoFirma_2" required />
                                <label className="form-check-label textoFormularioDepositantedirecto  mt-1" htmlFor="mancomunada2">Mancomunada</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            < input placeholder="" type="text" className="form-control border" value={value.datos.lugarRegistro} onChange={(e) => guardarDatos(e)} id="lugarRegistro" name="lugarRegistro" required />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Lugar de registro *</label>
                        </div>
                    </div>
                </div>

                <br></br>
            </div>
        </>
    );
}

