import React from "react";
import { useLocation } from "react-router-dom";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";

import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { traerSolicitudesDesmaterializacion } from "../../../api/desmaterializar/traerSolicitudesDesmaterializacion";
import { actualizarDesmaterializacion } from "../../../api/desmaterializar/actualizarDesmaterializacion";

//Hooks
import useDesmaterializaciones from "../../../hooks/Desmaterializacion/useDesmaterializaciones";

export default function NotificarDesmaterializacion({ history }) {
    
    const [registroExitoso, setRegistroExitoso] = React.useState()
    const [registroFallido, setRegistroFallido] = React.useState()
    
    const [mensaje, setMensaje] = React.useState()
    const [loading, setLoading] = React.useState(false);

    const location = useLocation();
    //const tipo = location.state;
    //const estado = 3;
    const estatus = {
        estado: 3
    };


    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()
    // const tipo=history.location.state.tipo;


    React.useEffect(() => {
        traerSolicitudes()
    }, [])

    const traerSolicitudes = async () => {
        const solicitudes = await traerSolicitudesDesmaterializacion(estatus)
        //console.log(solicitudes)
        if (solicitudes) {
            setSpin(false)
            if ((isNaN(solicitudes.error)) === false) {
              
                setErroreshttp(solicitudes)
                
            } else {
                setData(solicitudes)
                setCopia(solicitudes)
            }
        }
    }

    const notificarRegistro = async (row) => {
        setLoading(true)
        const myObj = {
            estado: 4,
            idSolicitud: row.original.solicitudDesmaterealizacionId,
        }

        const enviarDatos = await actualizarDesmaterializacion(myObj)
        if (enviarDatos) {
            setLoading(false)
            if ((isNaN(enviarDatos.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(enviarDatos)
            } else {

                if (enviarDatos?.codigo === "000") {
                    setMensaje("Desmaterialización notificada")
                    setRegistroExitoso(true)

                } else {
                    setMensaje(enviarDatos.mensaje)
                    setRegistroFallido(true)
                }
            }
        }
    }


    const irInicio = () => {
        setRegistroExitoso(false)
        history.go(-1)
    }


    return (
        <>
            {loading &&
                <PantallaCargando />
            }

            
            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={irInicio} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }
            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={() => { setRegistroFallido(false) }} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <div className="fluid-container">

                <main className="main-min-height">
                    <div className="row row-wrap g-0">
                        

                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Notificar desmaterialización de valores autorizadas"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaUsuarios datos={data} displayInformation={notificarRegistro} useValidate={useDesmaterializaciones} textoBoton={"Enviar correo"} textTitle="Click para enviar correo"></TablaUsuarios>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    );
}













