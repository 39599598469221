import { useMemo } from "react";

export default function useVerCuentasDepositanteDirecto() {
  const columns = useMemo(
    () => [
      {
        Header: "Número de cuenta",
        accessor: "cehvalCuentaValNum"
      },
      {
        Header: "Moneda",
        accessor: "catMonedaCatMonedaId.catMonedaDivisa"
      }
    ],
    []
  );

  return columns;
}
