import React from "react";
import '../../../../styles/stylesheet.css'
import { FormContext } from "./form_context";
import { FormContext2 } from "./form_context2";
import { traerPaises } from '../../../../api/catalogos/traerPaises'
import { traerDepartamentos } from '../../../../api/catalogos/traerDepartamentos'
import { traerCiudades } from '../../../../api/catalogos/traerCiudades'
import { traerMunicipios } from '../../../../api/catalogos/traerMunicipios'
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";

export default function ActualizarDI1_5({ history }) {
    const [value,] = React.useContext(FormContext);
    const [value2, setValue2] = React.useContext(FormContext2);
    const [paises, setPaises] = React.useState([])
    const [departamentos, setDepartamentos] = React.useState([])
    const [municipios, setMunicipios] = React.useState([])
    const [ciudades, setCiudades] = React.useState([])
    const [erroreshttp, setErroreshttp] = React.useState()

    const guardarDatos = (e) => {
        const { name, value } = e.target;
        //console.log(name,value)
        if (name === "pais") {
            if (value == 3) {

                setValue2((prev) => {
                    const datos = { ...prev.datos, ["paisHonduras"]: true };
                    return { ...prev, datos };
                });
            } else {
                //limpiarDomicilioAutomatico()
                setValue2((prev) => {
                    const datos = { ...prev.datos, ["paisHonduras"]: false };
                    return { ...prev, datos };
                });

            }
        }

        setValue2((prev) => {
            const datos = { ...prev.datos, [name]: value };
            return { ...prev, datos };
        });
    }

    React.useEffect(() => {
        traerDatos()
    }, [])

    const traerDatos = async () => {
        const catalogoPaises = await traerPaises();
        if (catalogoPaises) {
            //setSpin(false)
            if ((isNaN(catalogoPaises.error)) === false) {
              
                setErroreshttp(catalogoPaises)
                
            } else {
                // //console.log(traerInformacion)
                setPaises(await traerPaises())
            }
        }

    }

    const obtenerDepartamentos = async (e) => {
  
        const catalogosDepartamentos = await traerDepartamentos(e.target.value)
        if (catalogosDepartamentos) {
            if ((isNaN(catalogosDepartamentos.error)) === false) {
              
                setErroreshttp(catalogosDepartamentos)
                
            } else {
                setDepartamentos(catalogosDepartamentos)

            }
        }
    }

    const obtenerMunicipios = async (e, condicion) => {
       //console.log(e.target.value)
        const catalogoMunicipios = await traerMunicipios(e.target.value)
        if (catalogoMunicipios) {
            //setSpin(false)
            if ((isNaN(catalogoMunicipios.error)) === false) {
              
                setErroreshttp(catalogoMunicipios)
                
            } else {
                // //console.log(traerInformacion)
                setMunicipios(catalogoMunicipios)
                if (condicion === 1) {
                    //console.log("borrar municipios")
                    setValue2((prev) => {
                        const datos = { ...prev.datos, ["municipio"]: "" };
                        return { ...prev, datos };
                    });
                }
            }
        }
    }

    const obtenerCiudades = async (e,condicion) => {
        const catalogoCiudades = await traerCiudades(e.target.value)
        if (catalogoCiudades) {
            //setSpin(false)
            if ((isNaN(catalogoCiudades.error)) === false) {
              
                setErroreshttp(catalogoCiudades)
                
            } else {
                // //console.log(traerInformacion)
                if (condicion === 1) {
                    //console.log("borrar ciudades")
                    setValue2((prev) => {
                        const datos = { ...prev.datos, ["ciudad"]: false };
                        return { ...prev, datos };
                    });
                }
                setCiudades(catalogoCiudades)
            }
        }
    }

    React.useEffect(() => {
        if (value.datos.establecerModificacion&&value2.datos.paisHonduras) {
            if(value2.datos.departamentoInicio||value2.datos.paisInicio){
                obtenerDepartamentos({ target: { value: value2.datos.idPais } })
            }else{
                obtenerDepartamentos({ target: { value: value2.datos.pais } })
            }

            if(value2.datos.municipioInicio){
                obtenerMunicipios({ target: { value: value2.datos.idDepartamento } })
            }else{
                obtenerMunicipios({ target: { value: value2.datos.departamento } })
            }

            if(value2.datos.ciudadInicio){
                obtenerCiudades({ target: { value: value2.datos.idMunicipio } })
            }else{
                obtenerCiudades({ target: { value: value2.datos.municipio } })
            }
            
        }

    }, [value.datos.establecerModificacion])


    return (

        <div className="card-body pt-3">
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <h5 className="text-royal-blue font-acumin-variable ">Dirección completa del domicilio</h5>
            <h6 className="text-royal-blue font-acumin-variable ">Inicie con el país </h6>


            {value.datos.establecerModificacion ?
                <div className="row mb-3">

                    <div className="col-md-6 ">
                        <div className="form-floating mb-3">
                            {value2.datos.paisInicio ?
                                <select className="form-select fs-5 " value={value2.datos.pais} onChange={(e) => {
                                    guardarDatos(e)
                                    setValue2((prev) => {
                                        const datos = { ...prev.datos, ["paisInicio"]: false };
                                        return { ...prev, datos };
                                    });
                                }} onInput={(e) => obtenerDepartamentos(e)} id="pais" name="pais" required>
                                    <option value={value2.datos.pais} >{value2.datos.pais}</option>
                                    {
                                        paises.filter((item => item.nombre !== value2.datos.pais)).map((item, index) => (
                                            <option key={index} value={item.id} >{item.nombre}</option>
                                        ))
                                    }
                                </select>
                                :
                                <select className="form-select fs-5 " value={value2.datos.pais} onChange={(e) => guardarDatos(e)} onInput={(e) => obtenerDepartamentos(e)} id="pais" name="pais" required>
                                    <option value=""> </option>
                                    {
                                        paises.map((item, index) => (
                                            <option key={index} value={item.id} >{item.nombre}</option>
                                        ))
                                    }
                                </select>

                            }

                            <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">País *</label>
                        </div>
                    </div>



                    <div className="col-md-6 ">
                        <div className="form-floating mb-3">
                            <input placeholder="" type="text" className="form-control fs-5 " value={value2.datos.colonia} onChange={(e) => guardarDatos(e)} id="colonia" name="colonia" required />
                            <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Colonia o barrio *</label>
                        </div>
                    </div>

                    {value2.datos.paisHonduras ?
                        <div className="col-md-6 ">
                            <div className="form-floating mb-3">
                                {value2.datos.departamentoInicio ?
                                    <select className="form-select fs-5 " value={value2.datos.departamento} onChange={(e) => {
                                        guardarDatos(e)
                                        setValue2((prev) => {
                                            const datos = { ...prev.datos, ["departamentoInicio"]: false };
                                            return { ...prev, datos };
                                        });
                                    }} onInput={(e) => obtenerMunicipios(e,1)} id="departamento" name="departamento" required>
                                        <option value={value2.datos.departamento}> {value2.datos.departamento}</option>
                                        {
                                            departamentos.filter((item => item.nombre !== value2.datos.departamento)).map((item, index) => (
                                                <option key={index} value={item.id} >{item.nombre}</option>
                                            ))
                                        }
                                    </select>
                                    :
                                    <select className="form-select fs-5 " value={value2.datos.departamento} onChange={(e) => guardarDatos(e)} onInput={(e) => obtenerMunicipios(e,1)} id="departamento" name="departamento" required>
                                        <option value=""> </option>
                                        {
                                            departamentos.map((item, index) => (
                                                <option key={index} value={item.id} >{item.nombre}</option>
                                            ))
                                        }
                                    </select>


                                }

                                <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Departamento *</label>
                            </div>
                        </div>

                        :
                        <div className="col-md-6 ">
                            <div className="form-floating mb-3">
                                <input placeholder="" type="text" className="form-control fs-5" value={value2.datos.departamentoManual} onChange={(e) => guardarDatos(e)} id="departamentoManual" name="departamentoManual" />
                                <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Departamento</label>
                            </div>
                        </div>
                    }




                    <div className="col-md-6 ">
                        <div className="form-floating mb-3">
                            <input placeholder="" type="text" className="form-control fs-5" value={value2.datos.calle} onChange={(e) => guardarDatos(e)} id="calle" name="calle" />
                            <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Calle, avenida o bloque</label>
                        </div>
                    </div>

                    {value2.datos.paisHonduras ?
                        <div className="col-md-6 ">
                            <div className="form-floating mb-3">
                                {value2.datos.municipioInicio ?
                                    <select className="form-select fs-5 " value={value2.datos.municipio} onChange={(e) => {
                                        guardarDatos(e)
                                        setValue2((prev) => {
                                            const datos = { ...prev.datos, ["municipioInicio"]: false };
                                            return { ...prev, datos };
                                        });
                                    }} onInput={(e) => obtenerCiudades(e,1)} id="municipio" name="municipio" required>
                                        <option value={value2.datos.municipio}> {value2.datos.municipio}</option>
                                        {
                                            municipios.filter((item => item.nombre !== value2.datos.municipio)).map((item, index) => (
                                                <option key={index} value={item.id} >{item.nombre}</option>
                                            ))
                                        }
                                    </select>
                                    :
                                    <select className="form-select fs-5 " value={value2.datos.municipio} onChange={(e) => guardarDatos(e)} onInput={(e) => obtenerCiudades(e,1)} id="municipio" name="municipio" required>
                                        <option value=""> </option>
                                        {
                                            municipios.map((item, index) => (
                                                <option key={index} value={item.id} >{item.nombre}</option>
                                            ))
                                        }
                                    </select>

                                }

                                <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Municipio *</label>
                            </div>
                        </div>



                        :
                        <div className="col-md-6 ">
                            <div className="form-floating mb-3">
                                <input placeholder="" type="text" className="form-control fs-5" value={value2.datos.municipioManual} onChange={(e) => guardarDatos(e)} id="municipioManual" name="municipioManual" />
                                <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Municipio</label>
                            </div>
                        </div>}


                    <div className="col-md-6 ">
                        <div className="form-floating mb-3">
                            <input placeholder="" type="text" className="form-control fs-5" value={value2.datos.edificio} onChange={(e) => guardarDatos(e)} id="edificio" name="edificio" />
                            <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Número de casa</label>
                        </div>
                    </div>

                    {value2.datos.paisHonduras ?
                        <div className="col-md-6 ">
                            <div className="form-floating mb-3">
                                {value2.datos.ciudadInicio ?
                                    <select className="form-select fs-5 " value={value2.datos.ciudad} onChange={(e) => {
                                        guardarDatos(e)
                                        setValue2((prev) => {
                                            const datos = { ...prev.datos, ["ciudadInicio"]: false };
                                            return { ...prev, datos };
                                        });
                                    }} id="ciudad" name="ciudad" required>
                                        <option value={value2.datos.ciudad}>{value2.datos.ciudad} </option>
                                        {
                                            ciudades.filter((item => item.nombre !== value2.datos.ciudad)).map((item, index) => (
                                                <option key={index} value={item.id} >{item.nombre}</option>
                                            ))
                                        }
                                    </select>
                                    :
                                    <select className="form-select fs-5 " value={value2.datos.ciudad} onChange={(e) => guardarDatos(e)} id="ciudad" name="ciudad" required>
                                        <option value=""> </option>
                                        {
                                            ciudades.map((item, index) => (
                                                <option key={index} value={item.id} >{item.nombre}</option>
                                            ))
                                        }
                                    </select>

                                }

                                <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Ciudad *</label>
                            </div>
                        </div>
                        :
                        <div className="col-md-6 ">
                            <div className="form-floating mb-3">
                                <input placeholder="" type="text" className="form-control fs-5" value={value2.datos.ciudadManual} onChange={(e) => guardarDatos(e)} id="ciudadManual" name="ciudadManual" required />
                                <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Ciudad *</label>
                            </div>
                        </div>
                    }

                    <div className="col-md-6 form-floating mb-3">
                        <div className="form-floating mb-3">
                            <input placeholder="" type="text" maxLength={10} className="form-control fs-5" value={value2.datos.codigoPostal} onChange={(e) => guardarDatos(e)} id="codigoPostal" name="codigoPostal" />
                            <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Código postal</label>
                        </div>
                    </div>
                </div>
                :
                <div className="row mb-3">
                    <div className="col-md-6 ">
                        <div className="form-floating mb-3">
                            <input disabled  type="text" className="form-control fs-5 " value={value.datos.pais} onChange={(e) => guardarDatos(e)} id="colonia" name="colonia" required />
                            <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Pais </label>
                        </div>
                    </div>

                    <div className="col-md-6 ">
                        <div className="form-floating mb-3">
                            <input disabled type="text" className="form-control fs-5 " value={value.datos.colonia} onChange={(e) => guardarDatos(e)} id="colonia" name="colonia" required />
                            <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Colonia o barrio </label>
                        </div>
                    </div>

                    <div className="col-md-6 ">
                        <div className="form-floating mb-3">
                            {value.datos.departamento ?
                                <input disabled  type="text" className="form-control fs-5 " value={value.datos.departamento} onChange={(e) => guardarDatos(e)} id="colonia" name="colonia" required />
                                :
                                <input disabled type="text" className="form-control fs-5 " value={value.datos.departamentoManual} onChange={(e) => guardarDatos(e)} id="colonia" name="colonia" required />
                            }

                            <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Departamento </label>
                        </div>
                    </div>

                    <div className="col-md-6 ">
                        <div className="form-floating mb-3">
                            <input disabled  type="text" className="form-control fs-5" value={value.datos.calle} onChange={(e) => guardarDatos(e)} id="calle" name="calle" />
                            <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Calle, avenida o bloque</label>
                        </div>
                    </div>

                    <div className="col-md-6 ">
                        <div className="form-floating mb-3">
                            {value.datos.municipio ?
                                <input disabled  type="text" className="form-control fs-5" value={value.datos.municipio} onChange={(e) => guardarDatos(e)} id="calle" name="calle" />
                                :
                                <input disabled type="text" className="form-control fs-5" value={value.datos.municipioManual} onChange={(e) => guardarDatos(e)} id="calle" name="calle" />
                            }

                            <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Municipio</label>
                        </div>
                    </div>




                    <div className="col-md-6 ">
                        <div className="form-floating mb-3">
                            <input disabled type="text" className="form-control fs-5" value={value.datos.edificio} onChange={(e) => guardarDatos(e)} id="edificio" name="edificio" />
                            <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Número de casa</label>
                        </div>
                    </div>

                    <div className="col-md-6 ">
                        <div className="form-floating mb-3">
                            {value.datos.ciudad ?
                                <input disabled type="text" className="form-control fs-5" value={value.datos.ciudad} onChange={(e) => guardarDatos(e)} id="edificio" name="edificio" />
                                :
                                <input disabled type="text" className="form-control fs-5" value={value.datos.ciudadManual} onChange={(e) => guardarDatos(e)} id="edificio" name="edificio" />
                            }
                            <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Ciudad</label>
                        </div>
                    </div>

                    <div className="col-md-6 form-floating mb-3">
                        <div className="form-floating mb-3">
                            <input disabled type="text" maxLength={10} className="form-control fs-5" value={value.datos.codigoPostal} onChange={(e) => guardarDatos(e)} id="codigoPostal" name="codigoPostal" />
                            <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Código postal</label>
                        </div>
                    </div>
                </div>


            }





        </div>






    );
}

