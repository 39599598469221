import { instance } from '../apiGlobal';

export async function consultarEmision(obj) {
  ////console.log(obj)
  try {
    const response = await instance(`/serie/consulta/numseries/registrodisponible`, {
      method: "post",
      data: obj
    })
    ////console.log(response)
    return response;
  } catch (error) {
    return error;
  }
}


