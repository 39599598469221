import React, { useState, useEffect } from "react";

//General components
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores';
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";



//Functions
import formatNumber from "../../../../functions/FormatNumber";

//APIs
import { registrarAvisoCobro } from "../../../../api/tarificacion/ConceptosNoPeriodicos/generarCobrosNoPeriodicos/registrarAvisoCobro";


//class to create a single object for each charge concept
class CreateChargeConcept {
    constructor(tipoConcepto, nuevaBaseCalculo) {
        this.tipoConcepto = tipoConcepto;
        this.nuevaBaseCalculo = nuevaBaseCalculo;
    }
}


export default function FormGenerarConceptos({ catConceptos, numberOfConcepts, codigoParticipante, nombreParticipante, tipoParticipante }) {


    //Handle select input for each generated row
    const [selectedConcept, setSelectedConcept] = useState([]);
    //Handle the input to set the base calculo for each generated input
    const [baseCalculo, setBaseCalculo] = useState([]);

    //Array of numbers to generate N row with 2 inputs on each row
    const [arrayNumberInputs, setArrayNumberInputs] = useState([]);

    const [tipoCambio, setTipoCambio] = useState();
    const [tipoCambioStr, setTipoCambioStr] = useState();

    //Show loading screen
    const [loading, setLoading] = useState();

    //Show modal with error if any
    const [erroreshttp, setErroreshttp] = useState();


    const [requestStatus, setRequestStatus] = useState();




    ////console.log(baseCalculo)

    useEffect(() => {

        //Generate N number of rows with 2 inputs on each row
        if (numberOfConcepts > 0) {
            let arrayNumbers = [];
            for (let i = 0; i < numberOfConcepts; i++) {
                let inputs = {
                    concepto: i + 1
                }
                arrayNumbers.push(inputs);
            }
            setArrayNumberInputs(arrayNumbers);
        }

    }, [numberOfConcepts, selectedConcept, baseCalculo]);


    const handleTipoCambio = (e) => {
        const { value } = e.target;
        const formattedNumber = formatNumber(value);

        setTipoCambio(formattedNumber.integerNumber);
        setTipoCambioStr(formattedNumber.stringNumber);

    }


    //Handle the value of each select input and adds it to the object of the row
    const handleSelectChange = (event) => {
        //Handle the values selected for the user
        const selectName = event.target.name;
        const selectValue = event.target.value;

        //console.log(selectName)
        // //console.log(selectValue)


        //Set the concept selected by the user
        setSelectedConcept((prevState) => ({
            ...prevState,
            [`${selectName}`]: selectValue
        }));



        const getInputNumber = selectName.match(/\d+/g).join("")

        //console.log(getInputNumber)

        //Update the specific input of that row with '0' or '1'
        if (selectValue === "7" || selectValue === "8" || selectValue === "9" || selectValue === "10") {
            setBaseCalculo((prevState) => ({
                ...prevState,
                [`baseCalculo${getInputNumber}`]: "0",
            }));
        } else {
            setBaseCalculo((prevState) => ({
                ...prevState,
                [`baseCalculo${getInputNumber}`]: "1",
            }));
        }
    };




    //Handle the value of each select input and adds it to the object of the row
    const handleInputChange = (event) => {
        //Handle the values selected for the user
        const selectName = event.target.name;
        const selectValue = event.target.value;

        const formattedNumber = formatNumber(selectValue);


        setBaseCalculo((prevState) => ({
            ...prevState,
            [selectName]: formattedNumber.stringNumber,
        }));
    };



    const createArrayConcepts = () => {

        //Object with each of selected concepts
        const myConceptsArray = Object.entries(selectedConcept);
        //Object with each base calculo
        const myBaseCalculoArray = Object.entries(baseCalculo);


        //Creates the list of concepts will send to request 
        const mappedArray = myConceptsArray.map(([key, value]) => {
            const integerConcept = parseInt(value);
            const arrayObj = new CreateChargeConcept(integerConcept);
            return arrayObj;
        });

        //Update the created object
        const newObj = myBaseCalculoArray.map(([key, value]) => {
            
            //Catch value for the base calculo and clean string
            const cleanNumber = value.replace(/[^\d.]/g, '');

            //Convert string to a integer
            const baseCalculoInteger = parseFloat(cleanNumber);

            //We just take the number to select the index in the mapped array
            const index = key.match(/\d+/g).join("")

            //Select index in the mappedArray
            const obj = mappedArray[index - 1];
            //Update attribute "nuevaBaseCalculo" of  each object in the array 
            obj.nuevaBaseCalculo = baseCalculoInteger;

            return mappedArray

        });

        //Select the array in position 0
        const conceptsList = newObj[0];

        return conceptsList;
    }



    const generarAvisoCobro = async (e) => {
        e.preventDefault();
        try {

            //List of concepts created by user in form
            const conceptsList = createArrayConcepts();

            //Participant data
            const datosParticipante = {
                codigoDepositante: codigoParticipante,
                nombreDepositante: nombreParticipante,
                tipoParticipante: tipoParticipante,
            }

            //Complete object
            const obj = {
                datosParticipante: datosParticipante,
                conceptos: conceptsList,
                tipoCambio: tipoCambio,
            }
            
            setLoading(true);
            const response = await registrarAvisoCobro(obj);
            setLoading(false);

            if(isNaN(response.error) === false){
                setErroreshttp(response);

            } else if(response.data.isSuccessful) {
                setRequestStatus(response.data.mensaje);

            }  else {
                setRequestStatus(response.data.mensaje);

            }

        } catch (error) {
            //console.log(error);
        }
    }

    const reloadPage = () => {
        window.location.reload();
    }


    return (
        <>
            {loading &&
                <PantallaCargando></PantallaCargando>
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {requestStatus &&
                <ComponenteModal mostrarModal={requestStatus} cerrarModal={reloadPage} titulo={requestStatus} textoBoton="Aceptar"></ComponenteModal>
            }

            <div>
                <form onSubmit={generarAvisoCobro}>
                    <h4 className="font-acumin-variable mt-5">Tipo de cambio</h4>
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                            <div className="form-floating">
                                <input title="Tipo de cambio HNL/USD" onChange={(e) => handleTipoCambio(e)} value={tipoCambioStr} className="form-control fs-5" type="text" name="tipoCambioHnlUsd" placeholder="Tipo de cambio HNL/USD" required />
                                <label className="text-start font-acumin-variable text-royal-blue" htmlFor="tipoCambioHnlUsd">Tipo de cambio HNL/USD</label>
                            </div>
                        </div>

                    </div>

                    <h4 className="font-acumin-variable mt-5">Generar aviso de cobro manualmente</h4>

                    {
                        //Itera sobre array de numeros
                        //El numero de elementos que haya sobre el array es el numero de inputs que se van a renderizar pero como solo 
                        //queremos pintar un solo input tipo select por cada fila, entonces vamos a añadir las siguientes validaciones
                        arrayNumberInputs.map((option, ix) => {

                            return (
                                <>
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-6 col-xl-6 d-flex justify-content-end mt-2">
                                            <div className=" col-12 col-md-12 ">
                                                <div className="form-floating">
                                                    <select className="form-select"
                                                        key={option.concepto}
                                                        name={`concepto${option.concepto}`}
                                                        value={selectedConcept[`concepto${option.concepto}`] || ""}
                                                        onChange={(e) => handleSelectChange(e)}
                                                        required
                                                    >
                                                        <option value=""></option>
                                                        {
                                                            //Iteramos sobre el atributo asociacionesDisponibles del objeto que seleccionamos
                                                            //Mostramos las opciones que tendrá el input
                                                            catConceptos.map((el, ix) => {
                                                                return (
                                                                    <option className="fs-15" key={el.tipoConcepto} value={el.tipoConcepto}>{`${el.tipoConcepto}-${el.nombreConcepto}`}</option>
                                                                )

                                                            })
                                                        }
                                                    </select>
                                                    <label className="font-acumin-variable" htmlFor="concepto">Concepto</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-6 col-xl-6 mt-2">
                                            <div className="form-floating">
                                                <input id={`value${option.concepto}`} title="Base de cálculo" onChange={(e) => handleInputChange(e)} name={`baseCalculo${option.concepto}`} value={baseCalculo[`baseCalculo${option.concepto}`] || ""} className="form-control fs-5" type="text" placeholder="Base de cálculo" required />
                                                <label className="text-start font-acumin-variable" htmlFor="baseCalculo">Base de cálculo</label>
                                            </div>
                                        </div>
                                    </div>

                                </>

                            )


                        })
                    }
                    <div className="col-12 d-flex align-items-end justify-content-end mt-5">
                        <button className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable" type="submit">Generar monto a cobrar</button>
                    </div>
                </form>
            </div>
        </>

    );
}







