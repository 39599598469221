import { instance } from '../apiGlobal';

  export async function traerRoles(obj) {
    try {
      const response = await instance.post(`/auth_user/consultarRoles`, {
        headers: {
          'Content-Type': 'application/json'          
        }
      })
      return response.data
    } catch (error) {
      return error
    }
}

