
  import { instance } from '../apiGlobal';

  export async function EnviarRegistroDepositanteIndirectoJuridico(obj) {
        try {
          const response = await instance(`/regdp/ddpj`, {
            method:"post",
            data:obj
          })
          return response.data
        } catch (error) {
          return error
        }
  }