//React
import React from "react";

import ComponenteMenuEmsoresEmisionesSeries from "../../../ComponentesGenerales/ComponenteMenuEmsoresEmisionesSeries";

export default function MenuEmisoresEmisionesSeriesBCV({ history }) {


    return (
        <ComponenteMenuEmsoresEmisionesSeries titulo="Emisores, emisiones y series desde la BCV" ruta1="/registroEmisoresBCV" ruta2="/registroEmisionesBCV" ruta3="/registroSeriesBCV" ></ComponenteMenuEmsoresEmisionesSeries>
    );
}

