import React, { useState, useEffect } from "react";


//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import TablaSolicitarISIN from "../../../ComponentesGenerales/TablaSolicitarISIN";
import SpinnerStart from '../../../ComponentesGenerales/SpinnerStart';


//Hooks
import useSolicitudGeneracionCodigoISIN from "../../../hooks/SolicitudGeneracionCodigoISIN/useSolicitudGeneracionCodigoISIN";

//API's
import { consultaEmisionesGenerarISIN } from "../../../api/GeneracionAsignacionCodigosISIN/consultaEmisionesGenerarISIN";
import { registrarSolicitudCodigoISIN } from "../../../api/GeneracionAsignacionCodigosISIN/registrarSolicitudCodigoISIN";

//Catalogs
import { traerTipoInstrumentoFinanciero } from "../../../api/catalogos/traerTipoInstrumentoFinanciero";
import { traerPuestos } from "../../../api/catalogos/traerPuestos";



//Muestra las cuentas de un depositante indirecto
export default function SolicitudGeneracionAsignacionCodigosISIN({ history }) {


    //Array of series
    const [checkedValues, setCheckedValues] = useState([]);


    //All the emisions of one single emisor
    const [emisionCollection, setEmisionCollection] = useState();
    const [sinEmisiones, setSinEmisiones] = useState();


    //All series of the selected emision
    const [dataTable, setDataTable] = useState();


    //Inputs where user will enter a value
    const [formState, setFormState] = useState({
        codigoEmisor: "",
        nombreResponsable: "",
        correoResponsable: "",
    });

    // update state based on form input changes
    const handleInputs = (event) => {
        setMessageError(false);
        setFormState({ ...formState, [event.target.name]: event.target.value });
    };


    //Inputs 
    const [nombreEmisor, setNombreEmisor] = useState("");
    const [codigoEmisor, setCodigoEmisor] = useState("");
    const [codigoEmision, setCodigoEmision] = useState();
    const [nombreEmision, setNombreEmision] = useState("");
    const [numeroResRPMV, setNumeroResRPMV] = useState("");
    const [puestoResponsableId, setPuestoResponsableId] = useState();

    const [noSelectedFile, setNoSelected] = useState();

    //Input type file
    //The seleted file by user
    const [file, setFile] = useState(undefined);
    //The name of the users file


    const [validEmisor, setValidEmisor] = useState();
    const [showTable, setShowTable] = useState(false);


    const [instrumentoFinanciero, setInstrumentoFinanciero] = useState();


    //Catalogs
    const [catTipoInstrumento, setCatTipoInstrumento] = useState();
    const [catPuestos, setCatPuestos] = useState();


    //True or false states
    const [solicitudEnviada, setSolicitudEnviada] = useState();
    const [errorNoSeries, setErrorNoSeries] = useState(false);
    const [invalidFile, setInvalidFile] = useState(false);
    const [showNextForm, setShowNextForm] = useState(false);

    //Shows the loading screen when is set to true
    const [loading, setLoading] = useState(false);


    const [menssageError, setMessageError] = useState();
    const [erroreshttp, setErroreshttp] = useState();
    const [solicitudFallida,setSolicitudFallida]=useState()



    useEffect(() => {

        //Update the series table when choose another emision
        if (!showTable) {
            setShowTable(true);
        }

        if (!catPuestos || !catTipoInstrumento) {
            getCatalogs();
        }
    }, [showTable]);


    const getCatalogs = async () => {
        try {
            const catalogoInstrumentoFinanciero = await traerTipoInstrumentoFinanciero();
            if (catalogoInstrumentoFinanciero) {
                //setCargando(false)
                if ((isNaN(catalogoInstrumentoFinanciero.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(catalogoInstrumentoFinanciero)
                } else {
               
                    if (catalogoInstrumentoFinanciero.data) {
                        setCatTipoInstrumento(catalogoInstrumentoFinanciero.data);

                    } 
                }
            }



            const catalogoPuestos = await traerPuestos();
            if (catalogoPuestos) {
                //setCargando(false)
                if ((isNaN(catalogoPuestos.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(catalogoPuestos)
                } else {
                    setCatPuestos(catalogoPuestos);
                }
            }


        } catch (error) {
            console.error(error);
        }
    }

    //Executes when user type the Código del emisor and then clicks in "Consultar emisor"
    const getEmisions = async (e) => {
        e.preventDefault();
        try {

            if (formState.codigoEmisor === "") {
                setMessageError("Por favor ingrese el código del emisor");

            } else {
                setValidEmisor(false);
                setEmisionCollection("");
                setCodigoEmisor("");
                setNombreEmisor("");

                setLoading(true);
                //Get all emisions from an emisor
                const response = await consultaEmisionesGenerarISIN({ codigo: formState.codigoEmisor });
                setLoading(false);
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {

                    if (response.data.cehvalEmisorCodigo === null) {
                        setMessageError("El código del emisor no existe");

                    } else if (response.data.cehvalEmisorCodigo) {
                        setValidEmisor(true);
                        setEmisionCollection(response.data.cehvalEmisionCollection);
                        setCodigoEmisor(response.data.cehvalEmisorCodigo);
                        setNombreEmisor(response.data.cehvalEmisorNombreCom);
                        ////console.log(response.data.cehvalEmisionCollection)
                    }
                }
            }

        } catch (error) {
            console.error(error);
        }
    }

    //Executes when user selects an emision form the select input
    const getEmisionSeries = async (e) => {

        try {
            const { value } = e.target;
            const emisionSelected = value;


            if (emisionSelected) {
                //State to update the series table with useEffect()
                setShowTable(false);
                //Show html
                setShowNextForm(false);
                //Set our state to save the emision code
                setCodigoEmision(emisionSelected);

                //Get the object of the selected emision
                const filterEmisionCollection = emisionCollection?.filter(emision => emision.cehvalEmisionCodigo === emisionSelected);
                //Desestructure array to get just the emision object
                const [emisionData] = filterEmisionCollection;

                //Get the array of series
                const arraySeries = emisionData.series;

                //Call function to convert array of series to array of objects
                convertSeriesArray(arraySeries);

                setNombreEmision(emisionData.cehvalEmisionNomEmi);
                setNumeroResRPMV(emisionData.cehvalEmisionNumeroResol);

            } else if (value === "") {
                setDataTable(null);
                setShowNextForm(false);
                setNombreEmision("");
                setNumeroResRPMV("");
                setShowTable(false);
            }

        } catch (error) {
            //console.log(error);
        }
    }

    //Create the array the we'll send to the table so show the series
    const convertSeriesArray = (array) => {
        setShowNextForm(false)
        //Creates an object for each serie, and adds that obj into the new array
        const newSeriesArray = array.map((el, ix) => {
            return {
                serie: el,
            }
        });
        //Pass the new series array so the user will see the series the table
        setDataTable(newSeriesArray);
        setShowNextForm(true);
    }


    // ****************** START HANDLE INPUT TYPE FILE


    //Open input file when click on h4 text
    const handleInputFile = (e) => {
        e.preventDefault();
        //Select our input tag 
        const input = document.querySelector("#input-file");
        //Give the event click to our input
        input.click();
    }


    // HANDLE WHEN USER SELECT A FILE WHEN CLICK ON TEXT
    const selectFile = (e) => {

        const file = e.target.files;
        //Name of the file to validate if has the correct extension
        const fileName = e.target.files[0].name;

        const fileSize = e.target.files[0].size;

        //Regex to validate the extension of the file
        const extensionsAllow = /(.pdf)$/i;

        ////console.log(pathFile.files)
        //If selected file doesnt have correct extension show user error message
        if (!extensionsAllow.exec(fileName)) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file
            // setError("Selecciona un archivo válido");

            //Clean our states if they had any information
            setFile("");
            return false;

        } else if (fileSize > 10_000_000) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file
            // setError("El tamaño del archivo debe ser menor a 10 MB");
            //Clean file name if a file was selected before
            //setNameFile("");
            //Clean state if a file was selected before
            setFile("");

        } else if (e.target.files && e.target.files[0]) {
            //Shows the name of the selected file to the user

            setFile(file);
            setNoSelected(null);

            const boxText = document.querySelector("#text-click-uploadfile");
            boxText.innerHTML = fileName;

        }
    }


    // HANDLE USER DROP FILE
    const dropFile = (e) => {
        e.preventDefault();
        //Cleans error message if was one
        // setError("");
        //Removes the active class from container when user drops a file
        document.querySelector(".drop-area").classList.remove("active");
        //Shows the same text before dropping a file
        const inputField = document.querySelector('#text-click-uploadfile');
        inputField.innerHTML = "Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro";

        //Show user the selected file and validates in codition if file has a valid extension 
        const fileName = e.dataTransfer.files[0].name;

        const fileSize = e.dataTransfer.files[0].size;
        //Regex to validate file extension
        const extensionsAllow = /(.pdf)$/i;

        if (!extensionsAllow.exec(fileName)) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file
            // setError("Selecciona un archivo válido");
            setFile("");
            return false;

        } else if (fileSize > 10_000_000) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file
            // setError("El tamaño del archivo debe ser menor a 10 MB");
            //Clean file name if a file was selected before
            //Clean state if a file was selected before
            setFile("");

        } else if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            //Shows the name of the selected file to the user

            setFile(e.dataTransfer.files[0]);
            setNoSelected(null);

            const boxText = document.querySelector("#text-click-uploadfile");
            boxText.innerHTML = fileName;
        }
    }


    //Handle when user drag over a file 
    const dragOver = (e) => {
        e.preventDefault();
        //Adds the active class to container to change the background
        const moveOver = document.querySelector(".drop-area");
        moveOver.classList.add("active");
        //Change the text inside box
        const inputField = document.querySelector('#text-click-uploadfile');
        inputField.innerHTML = "Suelte el archivo";
    }


    //Handle when user leaves the drop area whitout drop any file
    const dragLeave = (e) => {
        e.preventDefault();
        //Removes the active class to container to change the background
        const moveLeave = document.querySelector(".drop-area");
        moveLeave.classList.remove("active");
        //Change the text inside box 
        const inputField = document.querySelector('#text-click-uploadfile');
        inputField.innerHTML = "Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro";
    }

    // ****************** END HANDLE INPUT TYPE FILE



    const handleCheckbox = (e, event) => {

        //Get the name of serie in the selected row
        const selectedSerie = event.target.value;

        const isChecked = event.target.checked;

        //Add a serie
        if (isChecked) {
            setCheckedValues([...checkedValues, selectedSerie]);

            //Remove serie when uncheck    
        } else {
            setCheckedValues(checkedValues.filter((serie) => serie !== selectedSerie));
        }
    }


    const sendForm = async (e) => {
        e.preventDefault();
        //Get the number of series
        const cantidadSeries = checkedValues.length;
        //Covert to string the array of series
        const stringSeries = checkedValues.join(",");

        if (file === undefined) {
            setNoSelected("Seleccione un archivo por favor");

        } else if (cantidadSeries === 0) {
            setErrorNoSeries(true);

        } else {

            let formFile = new FormData();
            formFile.append('solicitudIsinCodigoEmisor', codigoEmisor);
            formFile.append('solicitudIsinCodigoEmision', codigoEmision);
            formFile.append('solicitudIsinResponsableNombre', formState.nombreResponsable);
            formFile.append('solicitudIsinResponsableCorreo', formState.correoResponsable);
            formFile.append('solicitudIsinCantidadSeries', cantidadSeries);
            formFile.append('solicitudIsinSeries', stringSeries);
            formFile.append('cehvalSolicitudIsinInstrumetoFinancieroId', instrumentoFinanciero);
            formFile.append('cehvalSolicitudIsinResponsablePuestoId', puestoResponsableId);
            formFile.append('filedoc1', file[0]);


            setLoading(true);
            const response = await registrarSolicitudCodigoISIN(formFile)
            if(response){
                setLoading(false);
                //console.log(response);
    
    
                if (isNaN(response.error) === false) {
                    setErroreshttp(response);
    
                } else if (response.data.isSuccessful) {
                    setSolicitudEnviada(true);
                }else {
                    setSolicitudFallida(true)
                }

            }

        
        }
    }



    const reloadPage = () => {
        window.location.reload();
    }



    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {solicitudEnviada &&
                <ComponenteModal mostrarModal={solicitudEnviada} cerrarModal={reloadPage} titulo="La solicitud para la generación y asignación de códigos ISIN fue exitosa" textoBoton="Aceptar"></ComponenteModal>
            }

{solicitudFallida &&
                <ComponenteModal mostrarModal={solicitudFallida} cerrarModal={()=> {setSolicitudFallida(false)}} titulo="La solicitud para la generación y asignación de códigos ISIN fallo, intente mas tarde" textoBoton="Aceptar"></ComponenteModal>
            }

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {invalidFile &&
                <ComponenteModal mostrarModal={invalidFile} cerrarModal={() => { setInvalidFile(false) }} titulo="Archivo inválido. Por favor seleccione un archivo PDF y con un peso menor a 10 MB" textoBoton="Aceptar"></ComponenteModal>
            }
        

            {sinEmisiones &&
                <ComponenteModal mostrarModal={sinEmisiones} cerrarModal={() => setSinEmisiones(false)} titulo={sinEmisiones} textoBoton="Aceptar"></ComponenteModal>
            }

            <div className="fluid-container">
                <main className="mb-5 min-height-40">
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Solicitud de Generación y Asignación de Códigos ISIN"></BannerTitulo>
                                <div className="d-flex px-3">
                                    <div className="needs-validation font-acumin-variable text-royal-blue container border rounded">
                                        <section className="d-flex justify-content-center mb-4 px-4">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 row justify-content-center">
                                                <div className="p-0">
                                                    <div className="needs-validation font-acumin-variable text-royal-blue">
                                                        <form >
                                                            <div className="row mt-4">
                                                                <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <input title="Código del emisor" onChange={(e) => handleInputs(e)} value={formState.codigoEmisor.toUpperCase()} className="form-control fs-5" type="text" name="codigoEmisor" placeholder="Codigo del emisor" required />
                                                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoEmisor">Código del emisor</label>
                                                                        {menssageError &&
                                                                            <p className="text-danger mt-2">{menssageError}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-10 d-flex align-items-center">
                                                                    <button onClick={getEmisions} className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable" type="submit">Consultar emisor</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <form onSubmit={sendForm}>
                                            {validEmisor &&
                                                <>
                                                    <section className="px-4">
                                                        <div className="row mt-4">
                                                            <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                                <div className="form-floating">
                                                                    <input title="Nombre del emisor" className="form-control fs-5" type="text" value={nombreEmisor} name="nombreEmisor" placeholder="Nombre del emisor" required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="nombreEmisor">Nombre del emisor</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-6 col-xl-6 d-flex justify-content-center mb-3">
                                                                <div className=" col-12 col-md-12 ">
                                                                    <div className="form-floating">
                                                                        <select title="Código de emisión" onChange={getEmisionSeries} className="form-select font-acumin-variable" name="codigoEmision" style={{ height: "56px" }} aria-label="Default select example" required>
                                                                            <option value=""> </option>
                                                                            {emisionCollection &&
                                                                                emisionCollection.map((emision, index) => (
                                                                                    <option key={emision.cehvalEmisionId} className="text-uppercase" value={emision.cehvalEmisionCodigo}>{emision.cehvalEmisionCodigo}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <label className="font-acumin-variable" htmlFor="codigoEmision">Código de emisión</label>
                                                                    </div>
                                                                    <span className="text-muted font-acumin-variable mt-2">&#40;Seleccione la emisión&#41;</span>
                                                                </div>
                                                            </div>
                                                            {showNextForm &&
                                                                <>
                                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                                        <div className="form-floating">
                                                                            <input title="Nombre de la emisión" className="form-control fs-5" type="text" name="nombreEmision" value={nombreEmision} placeholder="Nombre de la emisión" required disabled />
                                                                            <label className="text-start font-acumin-variable" htmlFor="nombreEmision">Nombre de la emisión</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 d-flex justify-content-center mb-3">
                                                                        <div className=" col-12 col-md-12 ">
                                                                            <div className="form-floating">
                                                                                <select title="Tipo de instrumento financiero" onChange={(e) => setInstrumentoFinanciero(e.target.value)} className="form-select font-acumin-variable" name="tipoInstrumentoFinanciero" style={{ height: "56px" }} aria-label="Default select example" required>
                                                                                    <option value=""> </option>
                                                                                    {catTipoInstrumento &&
                                                                                        catTipoInstrumento.map((el, index) => (
                                                                                            <option key={el.id} className="text-uppercase" value={el.id}>{el.tipo}</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                                <label className="font-acumin-variable" htmlFor="tipoInstrumentoFinanciero">Tipo de instrumento financiero</label>
                                                                            </div>
                                                                            <span className="text-muted font-acumin-variable mt-2">&#40;Seleccione el tipo de instrumento financiero&#41;</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                                        <div className="form-floating">
                                                                            <input title="No. Resolución RPMV" className="form-control fs-5" value={numeroResRPMV} type="text" name="numeroResolucionRPMV" placeholder="No. Resolución RPMV" required disabled />
                                                                            <label className="text-start font-acumin-variable" htmlFor="numeroResolucionRPMV">No. Resolución RPMV</label>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </section>
                                                </>
                                            }

                                            {showNextForm &&
                                                <>
                                                    <section>
                                                        <div className="d-flex">
                                                            <div className="container py-5">
                                                                {showTable ? (

                                                                    <>
                                                                        <TablaSolicitarISIN datos={dataTable} useValidate={useSolicitudGeneracionCodigoISIN} getRowData={handleCheckbox} isChecked={checkedValues} ></TablaSolicitarISIN>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <SpinnerStart ></SpinnerStart>
                                                                    </>
                                                                )
                                                                }

                                                                {errorNoSeries &&
                                                                    <>
                                                                        <div className="col-12 row justify-content-center g-0 mt-3 fs-4">
                                                                            <span className="text-danger text-center">Para realizar la solicitud de código ISIN seleccione al menos una serie</span>
                                                                        </div>
                                                                    </>
                                                                }

                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section className="row justify-content-center align-items-center g-0 mt-5 mb-3 p-2">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                                            <div className="col-12 row justify-content-center justity-content-md-start justity-content-lg-start justity-content-xl-start justity-content-xxl-start g-0">
                                                                <h5 className="font-acumin-variable text-royal-blue col-11 col-sm-10 col-md-9 col-lg-8 text-center">Cargar archivo PDF de la solicitud-nota requerida por la CEHVAL </h5>
                                                                <div id="fileDropAreaISIN" className="col-12 col-sm-12 col-md-8 col-lg-8 drop-area row row-wrap justify-content-center align-items-center border-dashed py-5 px-2 rounded" onDragOver={dragOver} onDragLeave={dragLeave} onDrop={(e) => dropFile(e)}>
                                                                    <i className="ri-upload-2-fill ri-3x text-center text-royal-blue"></i>
                                                                    <p id="text-click-uploadfile" onClick={handleInputFile} className="text-center font-acumin-variable text-royal-blue">Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro</p>
                                                                    <input id="input-file" type="file" onChange={(e) => selectFile(e)} hidden />
                                                                </div>
                                                                {noSelectedFile &&
                                                                    <h5 className="font-acumin-variable text-danger col-11 col-sm-10 col-md-9 col-lg-8 text-center mt-3">{noSelectedFile} </h5>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 font-acumin-variable text-royal-blue mt-5 mt-sm-5 mt-md-5 mt-lg-0 mt-xl-0 mt-xxl-0">
                                                            <div className="row justify-content-center mt-4">
                                                                <h5 className="font-acumin-variable text-royal-blue text-center mb-3 fw-bolder">Solicitante responsable</h5>
                                                                <div className="col-12 col-md-12 col-lg-10 col-xl-10 mb-3">
                                                                    <div className="form-floating">
                                                                        <input title="Nombre del responsable" className="form-control fs-5" type="text" onChange={(e) => handleInputs(e)} value={formState.nombreResponsable} name="nombreResponsable" placeholder="Nombre del responsable" required />
                                                                        <label className="text-start font-acumin-variable" htmlFor="nombreResponsable">Nombre del responsable</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 col-lg-10 col-xl-10 d-flex justify-content-center mb-3">
                                                                    <div className=" col-12 col-md-12 ">
                                                                        <div className="form-floating">
                                                                            <select title="Puesto que desempeña" onChange={(e) => setPuestoResponsableId(e.target.value)} className="form-select font-acumin-variable" name="puestoResponsable" style={{ height: "56px" }} aria-label="Default select example" required>
                                                                                <option value=""> </option>
                                                                                {catPuestos &&
                                                                                    catPuestos.map((el, index) => (
                                                                                        <option key={el.id} className="text-uppercase" value={el.id}>{el.nombre}</option>
                                                                                    ))
                                                                                }
                                                                                <option value={28}>OTRO</option>
                                                                            </select>
                                                                            <label className="font-acumin-variable" htmlFor="puestoResponsable">Puesto que desempeña</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-12 col-lg-10 col-xl-10 mb-3">
                                                                    <div className="form-floating">
                                                                        <input title="Correo electrónico" className="form-control fs-5" type="email" onChange={(e) => handleInputs(e)} value={formState.correoResponsable} name="correoResponsable" placeholder="Correo electrónico" required />
                                                                        <label className="text-start font-acumin-variable" htmlFor="correoResponsable">Correo electrónico</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11 d-flex align-items-center mt-4 justify-content-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end">
                                                            <button className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable" type="submit" >Enviar</button>
                                                        </div>
                                                    </section>
                                                </>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}


