import React, { useState, useEffect } from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import TablaUsuarios from '../../../ComponentesGenerales/TablaUsuarios';

//Hooks
import useTenenciaValores from "../../../hooks/SistemasInformacion/useTenenciaValores";

//APIs
import { consultarTenenciaValores } from "../../../api/SistemasInformacion/consultarTenenciaValores";

export default function TenenciaValores({ history }) {
    const [data, setData] = useState([]);
    const [copia, setCopia] = useState();
    const [erroreshttp, setErroreshttp] = useState();
    const [spin, setSpin] = useState(true);


    useEffect(() => {
        verTenenciaValores();
    }, [])

    const verTenenciaValores = async () => {
        const response = await consultarTenenciaValores();
        //console.log(response)

        if (response) {
            setSpin(false);
            if ((isNaN(response.error)) === false) {
                setErroreshttp(response);
            } else {
                setData(response);
                setCopia(response);
            }
        }
    }

    return (
        <>
            <div className="fluid-container">

                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }

                <main className="min-height-40">
                    <div className="row row-wrap g-0">
                        <div className="col-12 ">
                            <div className="">
                                <br></br>
                                <BannerTitulo titulo="Tenencia de valores"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart>
                                    :
                                    <div className="">
                                        <div className="px-5 py-5 ">
                                            {copia &&
                                                <TablaUsuarios datos={data} useValidate={useTenenciaValores} ></TablaUsuarios>
                                            }
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    );
}



