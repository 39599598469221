import { instance } from '../apiGlobal';

export async function imprimirInformacionOperacionConFondos(obj) {
  try {
    const response = await instance('/reportems/operacion/info/liq', {
      method: "post",
      data: obj,
      responseType: 'blob',
    })
    return response.data
  } catch (error) {
    return error
  }
}