import React from "react";
import { FormContext } from "./form_context";


export default function REGISTRO_DI_1_5(props) {
    const [ ,setValue] = React.useContext(FormContext);

    React.useEffect(() => {
        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 0 };
            return { ...prev, datos };
        });

    }, [])


    return (
        <>

            {props.info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.catMunicipioCatMunicipioId === null ?
             <>
                <div className="card-body">
                    <h5 className="textoTituloFormulario" >Dirección completa del domicilio</h5>
                    <div className="row mb-3">

                        <div className="col-md-4">
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">País</label>
                            <input disabled type="text" value={props.info.cehvalDepositanteDireccionId.datosDireccion.catPaisPais} className="form-control " id="pais" name="pais" required />
                        </div>

                        <div className="col-md-4">
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Colonia o barrio</label>
                            <input disabled type="text" value={props.info.cehvalDepositanteDireccionId.cehvalDireccionColonia} className="form-control " id="colonia" name="colonia" required />
                        </div>

                        <div className="col-md-4">
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Departamento</label>
                            <input disabled type="text" value={props.info.cehvalDepositanteDireccionId.datosDireccion.catDepartamentoDepto} className="form-control " id="departamento" name="departamento" required />
                        </div>


                    </div>

                    <div className="row mb-3">
                        <div className="col-md-4">
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Calle, avenida o bloque</label>
                            <input disabled type="text" value={props.info.cehvalDepositanteDireccionId.cehvalDireccionCalle} className="form-control " id="calle" name="calle" />
                        </div>


                        <div className="col-md-4">
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Municipio</label>
                            <input disabled type="text" value={props.info.cehvalDepositanteDireccionId.datosDireccion.catMunicipioDescripcion} className="form-control " id="municipio" name="municipio" />
                        </div>


                        <div className="col-md-4">
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Número de casa</label>
                            <input disabled type="text" value={props.info.cehvalDepositanteDireccionId.cehvalDireccionNumeroCasEdi} className="form-control " id="edificio" name="edificio" />
                        </div>
                    </div>
                    <div className="row mb-3">

                        <div className="col-md-4">
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Ciudad</label>
                            <input disabled type="text" value={props.info.cehvalDepositanteDireccionId.datosDireccion.catCiudadDescripcion} className="form-control " id="ciudad" name="ciudad" />
                        </div>



                        <div className="col-md-4">
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Código postal</label>
                            <input disabled type="text" value={props.info.cehvalDepositanteDireccionId.cehvalDireccionCp} className="form-control " id="codigoPostal" name="codigoPostal" />
                        </div>
                    </div>

                </div>
              </>
             
                :

                <div className="card-body">
                    <h5 className="textoTituloFormulario" >Dirección completa del domicilio</h5>
                    <div className="row mb-3">

                        <div className="col-md-4">
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">País</label>
                            <input disabled type="text" value={props.info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.catMunicipioCatMunicipioId.catDepartamentoCatDepartamentoId.catPaisCatPaisId.nombre} className="form-control " id="pais" name="pais" required />
                        </div>

                        <div className="col-md-4">
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Colonia o barrio</label>
                            <input disabled type="text" value={props.info.cehvalDepositanteDireccionId.cehvalDireccionColonia} className="form-control " id="colonia" name="colonia" required />
                        </div>

                        <div className="col-md-4">
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Departamento</label>
                            <input disabled type="text" value={props.info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.catMunicipioCatMunicipioId.catDepartamentoCatDepartamentoId.nombre} className="form-control " id="departamento" name="departamento" required />
                        </div>


                    </div>

                    <div className="row mb-3">
                        <div className="col-md-4">
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Calle, avenida o bloque</label>
                            <input disabled type="text" value={props.info.cehvalDepositanteDireccionId.cehvalDireccionCalle} className="form-control " id="calle" name="calle" />
                        </div>


                        <div className="col-md-4">
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Municipio</label>
                            <input disabled type="text" value={props.info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.catMunicipioCatMunicipioId.nombre} className="form-control " id="municipio" name="municipio" />
                        </div>


                        <div className="col-md-4">
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Número de casa</label>
                            <input disabled type="text" value={props.info.cehvalDepositanteDireccionId.cehvalDireccionNumeroCasEdi} className="form-control " id="edificio" name="edificio" />
                        </div>
                    </div>
                    <div className="row mb-3">

                        <div className="col-md-4">
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Ciudad</label>
                            <input disabled type="text" value={props.info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.nombre} className="form-control " id="ciudad" name="ciudad" />
                        </div>



                        <div className="col-md-4">
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Código postal</label>
                            <input disabled type="text" value={props.info.cehvalDepositanteDireccionId.cehvalDireccionCp} className="form-control " id="codigoPostal" name="codigoPostal" />
                        </div>
                    </div>

                </div>

            }



        </>
    );
}

