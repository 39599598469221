import React from "react";
import { useHistory } from "react-router-dom";


//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";


//APIs
import { traerRoles } from '../../../api/catalogos/traerRoles';
import { traerPermisosPorRol } from "../../../api/Permisos/traerPermisosPorRol";
import { quitarPermisosARolExistente } from "../../../api/Permisos/quitarPermisosARolExistente";

export default function QuitarPermisos() {
    const history = useHistory();
    const [errorUsuarioExistente, setErrorUsuarioExistente] = React.useState()
    const [roles, setRoles] = React.useState([])
    const [rol, setRol] = React.useState()
    const [usuarioNoRegistrado, setUsuarioNoRegistrado] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [permisosPorRol, setPermisosPorRol] = React.useState([])
    const [arregloPermisos, setArregloPermisos] = React.useState([])
    const [errorSinPermisos, setErrorSinPermisos] = React.useState()
    const [permisosRegistrados, setPermisosRegistrados] = React.useState()
    const [loading, setLoading] = React.useState(false);


    React.useEffect(() => {
        traerCatalogoDeRoles()
    }, [])

    const traerCatalogoDeRoles = async () => {
        //traer Catalogos de roles
        const catalogosRoles = await traerRoles()
        if (catalogosRoles) {
            if ((isNaN(catalogosRoles.error)) === false) {
                // respuestaincorrecta     
                //se desplega un error como modal      
                setErroreshttp(catalogosRoles)
            } else {
                //respuesta correcta
                //console.log(catalogosRoles)
                setRoles(catalogosRoles)
            }
        }
    }

    const onSubmitForm = async (e) => {
        e.preventDefault();
        //console.log(arregloPermisos)
        //console.log(rol)

        if (arregloPermisos.length > 0) {
            setLoading(true)
            setErrorSinPermisos(false)

            const quitarPermisos = await quitarPermisosARolExistente({
                idRol: rol,
                listPermisos: arregloPermisos
            })

            if (quitarPermisos) {
                setLoading(false)
                if ((isNaN(quitarPermisos.error)) === false) {
                    // respuestaincorrecta        
                    setErroreshttp(quitarPermisos)
                } else {
                    //respuesta correcta
                    //console.log("exito")
                    setLoading(false)
                    setPermisosRegistrados(quitarPermisos)
                    //setPermisos(catalogoPermisos)
                }
            }
        } else {
            setErrorSinPermisos(true)

        }
    }

    const traerPermisosDelRol = async (e) => {
        setRol(e)
        const catalologoPermisosPorRol = await traerPermisosPorRol({ idRol: e })
        if (catalologoPermisosPorRol) {
            setLoading(false)
            if ((isNaN(catalologoPermisosPorRol.error)) === false) {
                // respuestaincorrecta     
                //se desplega un error como modal      
                setErroreshttp(catalologoPermisosPorRol)
            } else {
                //respuesta correcta
                //console.log(catalologoPermisosPorRol)
                setPermisosPorRol(catalologoPermisosPorRol)
            }
        }
    }

    const cerrarModal = () => {
        history.go(-1)
    }

    const guardarArreglo = (numero, id) => {
        let arregloPivote = arregloPermisos
        if (document.getElementById(id).checked) {
            setErrorSinPermisos(false)
            arregloPivote.push(parseInt(numero, 10))
        } else {
            arregloPivote = arregloPermisos.filter((item) => item !== parseInt(numero, 10))
        }
        //console.log(arregloPivote)
        setArregloPermisos(arregloPivote)
    }



    return (
        <>
          {loading &&
                        <PantallaCargando />
   }
            {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }

            {errorUsuarioExistente &&
                <ComponenteModal mostrarModal={errorUsuarioExistente} cerrarModal={() => { setErrorUsuarioExistente(false) }} titulo="Este usuario ya existe" textoBoton="Entendido"></ComponenteModal>
            }

            {usuarioNoRegistrado &&
                <ComponenteModal mostrarModal={usuarioNoRegistrado} cerrarModal={() => { setUsuarioNoRegistrado(false) }} titulo="No se pudo registrar a este usuario, revisa los  datos e intentalo de nuevo" textoBoton="Entendido"></ComponenteModal>
            }

            {permisosRegistrados &&
                <ComponenteModal mostrarModal={permisosRegistrados} cerrarModal={cerrarModal} titulo="Los permisos se eliminarón con éxito" textoBoton="Entendido"></ComponenteModal>
            }
            <div className="fluid-container">
                <main className="min-height-40">
                    <div className="row row-wrap g-0">
  
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Quitar permisos a rol existente"></BannerTitulo>
                                <div className="row mt-3 text-royal-blue g-0" >
                                    <div className="col-lg-12 col-md-12 ">
                                        <div className="container  ">
                                            <div className="row mb-3 justify-content-center g-0">
                                                <div className="col-lg-10 col-md-11 col-sm-12 col-xs-12">
                                                    <div className=" card " style={{ color: "#242848" }}>
                                                        <div className="card-body font-acumin-variable">
                                                            <form onSubmit={(e) => onSubmitForm(e)}>
                                                                <h6 className="font-acumin-variable text-royal-blue">* Estos campos son obligatorios</h6>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-12">
                                                                        <div className="form-floating ">
                                                                            <select className="form-select" onChange={(event) => {
                                                                                traerPermisosDelRol(event.target.value)
                                                                                setLoading(true)
                                                                            }} id="rol" name="rol" required>
                                                                                <option value=""> </option>
                                                                                {
                                                                                    roles.map((item, index) => (
                                                                                        <option key={index} value={item.cehvalRolId} >{item.cehvalRolNombre}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                            <label htmlFor="contraseña">Rol *</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {rol &&
                                                                    <>
                                                                        <p>Selecciona los permisos que quieres eliminar</p>
                                                                        {permisosPorRol.length > 0 && permisosPorRol ?
                                                                            <div className="card mt-2">
                                                                                {
                                                                                    permisosPorRol.map((item, index1) => (
                                                                                        <form name="f1" className="mt-3" id={`formulario${index1}`}>
                                                                                            <div className="ms-3">
                                                                                                <div className="row">
                                                                                                    <div className="col-lg-6">
                                                                                                        <div class="form-check ms-3">
                                                                                                            <input class="form-check-input" value={item.id} onChange={(e) => guardarArreglo(e.target.value, item.nombre)} type="checkbox" id={item.nombre} />
                                                                                                            <label class="form-check-label" for={item.nombre}>
                                                                                                                {item.nombre}
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-lg-6">
                                                                                                        <p>{item.descripcion}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                <h4>Sin permisos que quitar</h4>
                                                                            </>
                                                                        }

                                                                    </>
                                                                }

                                                                {errorSinPermisos &&
                                                                    <>
                                                                        <p className="error mt-2">Tiene que seleccionar al menos un permiso</p>
                                                                    </>
                                                                }

                                                                <div className="row  mt-4">
                                                                    <div className="col-md-5 ">
                                                                        <button className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable" type="submit">Eliminar</button>
                                                                    </div>
                                                                </div>


                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

