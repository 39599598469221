import React from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";

//APIs
import { traerInformacionAllEmisores } from "../../../api/VerInformacionDeEmisor.js/traerInformacionAllEmisores";

//Hooks
import useValidateEmision from '../../../hooks/useValidateEmision';



export default function SolicitudesEmisoresParaAutorizar({ history }) {
    const obj = {
        estatus: 1
    };

    const estatus = 1;

    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()

    React.useEffect(() => {
        traerEmisores()
    }, [])

    const traerEmisores = async () => {
        const usuarios = await traerInformacionAllEmisores(obj)
        //console.log(usuarios)
        if (usuarios) {
            setSpin(false)
            if ((isNaN(usuarios.error)) === false) {
                setErroreshttp(usuarios)
            } else {
                setData(usuarios)
                setCopia(usuarios)
            }
        }

    }


    const continuarOferta = (row) => {
        //console.log(row.original)

        //VER SOLICITUD DE DEPOSITANTE DIRECTO // 
        const objetodirecto = {
            titulo: "Autorizar solicitud de emisor",
            codigo: row.original.cehvalEmisorCodigo,//row.cells[2].value,
            id: row.original.cehvalEmisorId,
            estatus: estatus,
            ruta: "/solicitudesEmisoresParaAutorizar",

        }

        //mandar info depositante directo            
        history.push({
            pathname: '/verInfoEmisor', state: { objMenu: objetodirecto }
        })

    }


    return (
        <>
            <div className="fliud-container" >
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Solicitudes de emisores para autorizar"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">

                                            {copia &&
                                                <TablaUsuarios datos={data} displayInformation={continuarOferta} useValidate={useValidateEmision} textoBoton={"Abrir"} textTitle="Click para ver la información del emisor"></TablaUsuarios>
                                            }

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}















