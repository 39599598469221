import { instance } from '../apiGlobal';

export async function traerEmisionesParaDesmaterializar(obj) {
      try {
        const response = await instance(`/emisor/consulta/emisor/emisiones`, {
          method:"post",
          data:obj
        })
        return response.data
      } catch (error) {
        return error
      }
}