import { instance } from '../apiGlobal';

export async function imprimirValorFisicoDepositadoseleccionado(obj) {
      try {
        const response = await instance(`/reporte/reporte/form/valores/fisicos/depositados`, {
          method:"post",
          data:obj,
          responseType: 'blob',
        })
        return response.data
      } catch (error) {
        return error
      }
}