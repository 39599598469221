import { instance } from '../apiGlobal';

export async function consultarSubastasLiquidadas() {
      try {
          const response = await instance(`/subasta/obtener/todas/liquidacion`, {
          method:"get",
        })
        return response.data
      } catch (error) {
        return error
      }
}