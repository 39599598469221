import React from 'react';

import { BrowserRouter } from "react-router-dom";


import Footer from './ComponentesGenerales/Footer';


import Router from './Router';
import { FormProvider} from './ContextoGlobal'

export default function App() {
  return (
    <>
      <BrowserRouter>
        <FormProvider>       
          <Router></Router>
          <Footer />
        </FormProvider>
      </BrowserRouter >

    </>
  );
}