import { useMemo } from "react";

export default function useAutorizarAvisoCobroNoPeriodico () {
  const columns = useMemo(
    () => [
      {
        Header: "Fecha de realización",
        accessor: "fechaRealizacionStr"
      },
      {
        Header: "Fecha de cobro",
        accessor: "fechaCobroStr"
      },
      {
        Header: "Concepto",
        accessor: "nombreConcepto"
      },
      {
        Header: "No. de tarifa",
        accessor: "tipoConcepto"
      },
      {
        Header: "Tipo de cambio HNL/USD",
        accessor: "tipoCambioStr"
      },
      {
        Header: "Tarifa",
        accessor: "tarifaUSD"
      },
      {
        Header: "Base de cálculo",
        accessor: "baseCalculoStr"
      },
      {
        Header: "Monto base",
        accessor: "montoBaseUSDStr"
      },
      {
        Header: "Monto HNL",
        accessor: "montoHNLStr"
      },
      {
        Header: "¿Modificado?",
        accessor: "modificadoStr"
      }

    ],
    []
  );

  return columns;
}
