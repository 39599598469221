import React from "react";
import '../../../../styles/stylesheet.css'
import { FormContext } from './form_context';

export default function RegistroDI3({ history }) {
    const [value, setValue] = React.useContext(FormContext);




    const guardarDatos = (e) => {
        const { name, value } = e.target;

        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 0 };
            return { ...prev, datos };
        });  

        setValue((prev) => {
            const datos = { ...prev.datos, [name]: value };
            return { ...prev, datos };
        });
    }
    return (
        <>

            <div className="card-body pt-3">
                <h5 className="text-royal-blue font-acumin-variable" >III. Procedencia de los recursos</h5>
                <div className="row mb-3">
                    <div className="col-md-12 form-floating mb-3">
                        <div className="form-floating mb-2">
                        <input placeholder="" type="text" className="form-control " value={value.datos.origenRecursos} onChange={(e) => guardarDatos(e)} id="origenRecursos" name="origenRecursos" required />
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Procedencia de los recursos *</label>
                        </div>
                
                    </div>
                </div>
            </div>

        </>
    );
}

