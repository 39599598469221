import React from "react";

import logo from '../assets/logosCehval/Logo-BCV-blanco.png'

export default function NotFound() {
    return (
        <>
            <main className=" p-0 m-0 g-0">
                <div className="row justify-content-between g-0">
                    <div id="background-not-found" className="col-12 col-md-12 col-lg-12 p-0 g-0 row m-0">
                        <div className="row col-12 col-md-12 col-lg-12 align-items-center justify-content-center p-2">
                            <h3 className="col-6 fs-1 text-center font-acumin-variable text-white fw-bolder p-0 m-0">Lo sentimos, no encontramos la página que solicitaste</h3>
                        </div>
                        <div className="col-12  col-md-12  col-lg-12 p-2 mt-8rem">
                            <div className="row justify-content-center g-0">
                                <div id="backgroundNotFound" className="col-12 col-md-12 col-lg-12 p-0 g-0 row justify-content-center align-middle m-0 mb-3">
                                    <img className="" id="notFoundImage" src={logo} alt="ruta no existente" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}


