import { useMemo } from "react";

export default function useDepositantesCehval() {
 const columns = useMemo(
   () => [     
     {
       Header: "Código de depositante",
       accessor: "cehvalDepositanteCodigoCehval"
     },

     {
        Header:"Nombre de depositante",
        accessor: "cehvalDepositanteNombreCompleto"
      },
   ],
   []
 );

 return columns;
}
