//React
import React, { useState, useEffect } from "react";


//Componentes generales
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores';
import TablaConceptosCobroAutomaticos from "../../../../ComponentesGenerales/TablaConceptosCobroAutomaticos";
import ModalConfirmAction from "../../../../ComponentesGenerales/ModalConfirmAction";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";


//APIs
import { abrirAvisoPendientePago } from "../../../../api/tarificacion/ConceptosAutomaticos/AvisosPendientesPago/abrirAvisoPendientePago";
import { confirmarPagoParaConciliar } from "../../../../api/tarificacion/Conciliaciones/OperadorCehval/confirmarPagoParaConciliar";
import { rechazarPagoParaConciliar } from "../../../../api/tarificacion/Conciliaciones/OperadorCehval/rechazarPagoParaConciliar";

//Hooks
import useConceptosCobroAutomaticos from "../../../../hooks/Tarificacion/ConceptosAutomaticos/useConceptosCobroAutomaticos";


export default function RevisarAvisoCobroParaConciliar({ history }) {

    //Save loaded data
    const [conceptos, setConceptos] = useState();

    //Objeto del aviso de cobro
    const [objAvisoCobro, setObjAvisoCobro] = useState();

    //Id del aviso. Se ocupa para autorizar el aviso o rechazarlo
    const [idAviso, setIdAviso] = useState();

    //Datos del participante. Para pintar datos en front
    const [nombreParticipante, setNombreDepositante] = useState();
    const [codigoParticipante, setCodigoParticipante] = useState();
    const [tipoParticipante, setTipoParticipante] = useState();
    const [periodo, setPeriodo] = useState();
    const [tipoCambioStr, setTipoCambioStr] = useState();

    const [folioAvisoCobro, setfolioAvisoCobro] = useState();
    const [folioBHC, setFolioBHC] = useState();



    //Status del aviso de cobro
    const [statusAvisoStr, setStatusAvisoStr] = useState();

    //Monto total del aviso de cobro
    //const [montoTotal, setMontoTotal] = useState();
    const [montoTotalStr, setMontoTotalStr] = useState(null);

    const [montoTotalResumen, setMontoTotalResumen] = useState();
    const [montoTotalResumenStr, setMontoTotalResumenStr] = useState(null);

    //console.log(montoTotalStr)
    //console.log(montoTotalResumenStr)

    //const [montoDepositado, setMontoDepositado] = useState();
    const [montoDepositadoStr, setMontoDepositadoStr] = useState();


    //Spinner
    const [spin, setSpin] = useState(true);
    const [loading, setLoading] = useState();

    //Autorizar aviso
    const [autorizar, setAutorizar] = useState();

    //States to handle load data when loading page 
    const [erroreshttp, setErroreshttp] = useState();



    useEffect(() => {

        if (history.location.state === undefined) {
            window.location.replace("/verTodosAvisosCobroConciliar");
        } else {
            ////console.log(history.location.state)
            const idAviso = history.location.state.rowInfo.idAviso;
            setIdAviso(idAviso);
            getAllApplications(idAviso);
        }

    }, []);


    const getAllApplications = async (idAviso) => {

        try {

            const response = await abrirAvisoPendientePago({ idAviso: idAviso })
            //console.log(response)

            if (isNaN(response.error) === false) {
                setErroreshttp(response);
                setSpin(false);


            } else if (response.data) {
                setObjAvisoCobro(response.data);

                //Datos del aviso
                setNombreDepositante(response.data.datosParticipante.nombreDepositante);
                setCodigoParticipante(response.data.datosParticipante.codigoDepositante);
                setTipoParticipante(response.data.tipoParticipanteStr);
                setfolioAvisoCobro(response.data.folio);
                setStatusAvisoStr(response.data.estatusStr);


                setPeriodo(response.data.mesCobro);

                setTipoCambioStr(response.data.tipoCambioStr);
                setConceptos(response.data.conceptos);


                //Si monto total es null entonces el aviso de cobro es automatico 
                if (response.data.montoTotal === null) {
                    setMontoTotalResumen(response.data.montoTotalResumen);
                    setMontoTotalResumenStr(response.data.montoTotalResumenStr);

                } else {
                    //setMontoTotal(response.data.montoTotal);
                    setMontoTotalStr(response.data.montoTotalStr);

                }

                //Si no tiene folio BHC-TR asociado muestra en el campo el mensaje "Folio BCH-TR sin asociar"
                if (response.data.folioBCH === null) {
                    setFolioBHC("Folio BCH-TR sin asociar");
                    //Monto depositado
                    //setMontoDepositado("0.00");
                    setMontoDepositadoStr("0.00");

                } else {
                    setFolioBHC(response.data.folioBCH);
                    //Monto depositado
                    //setMontoDepositado(response.data.montoDepositado);
                    setMontoDepositadoStr(response.data.montoDepositadoStr);

                }
                setSpin(false);
            }

        } catch (err) {
            console.error(err);
        }
    }





    const confirmConciliacion = async (e) => {
        try {
            e.preventDefault();
            //console.log("se confirmo pago")

            setLoading(true);
            const response = await confirmarPagoParaConciliar({ idAviso: idAviso });
            setLoading(false);

            //console.log(response)

            if (isNaN(response.error) === false) {
                setErroreshttp(response);

            } else if (response.data.respuesta.isSuccessful) {
                setAutorizar(response.data.respuesta.mensaje);

            }


        } catch (error) {
            console.error(error);
        }
    }

    const rejectConciliacion = async (e) => {
        try {
            e.preventDefault();
            //console.log("Se rechazo pago")

            setLoading(true);
            const response = await rechazarPagoParaConciliar({ idAviso: idAviso });
            setLoading(false);

            //console.log(response)

            if (isNaN(response.error) === false) {
                setErroreshttp(response);

            } else if (response.data.respuesta.isSuccessful) {
                setAutorizar(response.data.respuesta.mensaje);

            }


        } catch (error) {
            console.error(error);
        }
    }



    const goBack = () => {
        history.push({
            pathname: '/verTodosAvisosCobroConciliar',
        });
    }


    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {autorizar &&
                <ComponenteModal mostrarModal={autorizar} cerrarModal={goBack} titulo={autorizar} textoBoton="Aceptar"></ComponenteModal>
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <div className="">
                <main className="min-height-40">
                    <div className="row row-wrap g-0">

                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Conciliar depósito de fondos para la factura seleccionada"></BannerTitulo>

                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex mt-5">
                                        <div className="container-fluid container-md-container container-lg-none container-xl-none container-xxl-none px-3">
                                            {objAvisoCobro &&
                                                <>
                                                    <div className="mb-5">
                                                        <section className="row ps-3">
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-bold">Nombre del participante</h5>
                                                                <p className="font-acumin-variable text-muted">{nombreParticipante}</p>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-bold">Código del participante</h5>
                                                                <p className="font-acumin-variable text-muted">{codigoParticipante}</p>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-bold">Tipo de participante</h5>
                                                                <p className="font-acumin-variable text-muted">{tipoParticipante}</p>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-bold">Periodo</h5>
                                                                <p className="font-acumin-variable text-muted">{periodo}</p>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-bold">Folio de la factura</h5>
                                                                <p className="font-acumin-variable text-muted">{folioAvisoCobro}</p>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-bold">Estatus de la factura</h5>
                                                                <p className="font-acumin-variable text-muted">{statusAvisoStr}</p>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-bold">Folio BCH</h5>
                                                                <p className="font-acumin-variable text-muted">{folioBHC}</p>
                                                            </div>
                                                        </section>
                                                        <section className="row ps-3">
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-bold">Tipo de cambio:</h5>
                                                                <p className="fs-5 font-acumin-variable text-muted fw-bolder"> HNL {tipoCambioStr}</p>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-bold">Monto depositado</h5>
                                                                <p className="fs-5 font-acumin-variable text-muted fw-bolder"> HNL {montoDepositadoStr}</p>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-bold">Total a cobrar &#40;HNL&#41;</h5>
                                                                {montoTotalStr === null
                                                                    ? <p className="fs-5 font-acumin-variable text-muted fw-bolder">HNL {montoTotalResumenStr}</p>
                                                                    : <p className="fs-5 font-acumin-variable text-muted fw-bolder">HNL {montoTotalStr}</p>
                                                                }

                                                            </div>
                                                        </section>
                                                    </div>

                                                    <TablaConceptosCobroAutomaticos datos={conceptos} useValidate={useConceptosCobroAutomaticos} textoBoton={"Abrir solicitud"} textTitle="Click para ver la información" />

                                                    <div className="col-12 col-md-12 d-flex justify-content-end mb-3 px-3 mt-5">
                                                        <div className="flex-fill justify-content-start">
                                                            <button type="button" onClick={goBack} className="btn ms-2 background-royal-blue text-white bg-hover-gold">Regresar</button>
                                                        </div>
                                                        <button type="button" data-bs-target="#rechazarPagoModal" data-bs-toggle="modal" className="btn ms-2 background-royal-blue text-white bg-hover-red">Rechazar pago</button>
                                                        <button type="button" data-bs-target="#confirmarPagoModal" data-bs-toggle="modal" className="btn ms-2 background-royal-blue text-white bg-hover-gold">Confirmar pago</button>
                                                    </div>

                                                    <ModalConfirmAction confirmFunction={confirmConciliacion} idModal="confirmarPagoModal" textModal="¿Está seguro que desea confirmar el pago de la factura? Esta acción no se puede deshacer" />
                                                    <ModalConfirmAction confirmFunction={rejectConciliacion} idModal="rechazarPagoModal" textModal="¿Está seguro que desea rechazar el pago de la factura? Esta acción no se puede deshacer" />
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}





