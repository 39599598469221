import React, { useState } from "react";

//Componentes

import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//import useLiquidarSubasta from "../../../hooks/MercadoPrimario/useLiquidarSubasta";
import useFondosRemanentes from "../../../hooks/MercadoPrimario/useFondosRemanentes";
import useLiquidarSubastaString from "../../../hooks/MercadoPrimario/useLiquidarSubastaString";


export default function ComponenteInformacionConciliacion({ funcion1, textoFuncion1, funcion2, textoFuncion2, subastaData, irDevuelta}) {

    const [erroreshttp, setErroreshttp] = useState()

    const objetoSubasta = subastaData
    return (
        <>
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

    

            <BannerTitulo titulo="Liquidación de la subasta"></BannerTitulo>
            <div className="container py-5 w-100">
                <div className="font-acumin-variable ms-3">
                    <h4>Subasta</h4>

                    <div className="row">
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Número de subasta" value={objetoSubasta.numeroSubasta} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Número de subasta</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Fecha de subasta" value={objetoSubasta.fechaSubasta} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Fecha de subasta</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">

                                    <input title="Estatus de la subasta" value={objetoSubasta.estatusSubastaStr} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />


                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Estatus de la subasta</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="font-acumin-variable ms-3">
                    <h4>Emisión</h4>

                    <div className="row">
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Código de emisión" value={objetoSubasta.codigoEmision} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Código de emisión</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Nombre de emisor" value={objetoSubasta.nombreEmisor} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Nombre de emisor</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Cuenta del emisor" value={objetoSubasta.cuentaEmisor} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Cuenta del emisor</label>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="font-acumin-variable ms-3">
                    <h4>Serie</h4>

                    <div className="row">
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Código de serie" value={objetoSubasta.codigoSerie} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Código de serie</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Serie" value={objetoSubasta.nombreSerie} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Serie</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="ISIN" value={objetoSubasta.isin} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">ISIN</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Cantidad de instrumentos" value={objetoSubasta.cantidadInstrumentosSerie} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Cantidad de instrumentos</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Moneda de liquidación" value={objetoSubasta.monedaLiquidacionSerie} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Moneda de liquidación</label>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="font-acumin-variable ms-3">
                    <h4>Pago al emisor</h4>

                    <div className="row">
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Monto total apagar" value={objetoSubasta.montoTotalAPagarStr} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Monto total a pagar</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">

                                    <input title="Estatus" value={objetoSubasta.estatusLiquidacionEmisor} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />

                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Estatus</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Cuenta del emisor" value={objetoSubasta.cuentaEmisor} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Cuenta del emisor</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Tipo de cuenta" value={objetoSubasta.tipoCuentaEmisor} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Tipo de cuenta del emisor</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Banco del emisor" value={objetoSubasta.bancoEmisor} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Banco del emisor</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h4 className="font-acumin-variable ms-3 mt-5">Ofertas</h4>
                <TablaMercado datos={objetoSubasta.ofertas} useValidate={useLiquidarSubastaString} ></TablaMercado>

                <h4 className="font-acumin-variable ms-3 mt-5">Fondos remanentes</h4>
                <TablaMercado datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={funcion1} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={funcion2} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
              
                <div className="col-12 row justify-content-star mt-3 ms-3">
                    <div className="col-6 mt-1 g-0">
                        <button title="Regresar a página anterior" onClick={irDevuelta} className="btn background-royal-blue bg-hover-gold text-white font-acumin-variable">Regresar</button>
                    </div>
                </div>
            </div>
        </>

    )
}