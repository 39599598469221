//React
import React, { useState, useEffect } from "react";

//Components
import BannerTitulo from "../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../ComponentesGenerales/ManejadorErrores';
import TablaUsuarios from "../../ComponentesGenerales/TablaUsuarios";
import ComponenteModal from "../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../ComponentesGenerales/PantallaCargando";

//AWS
import { Auth } from 'aws-amplify';

//APIs
import { consultarOperacionesDepositantes } from '../../api/consultarOperaciones/consultarOperacionesDepositantes';
import { consultaOperacionesTLPTodas } from '../../api/HistorialOperaciones/TransferenciaLibrePago/consultaOperacionesTLPTodas';
import { constanciaTLP } from '../../api/transferenciaLibreDePago/constanciaTLP';

//Hooks
import useOperacionesTLP from '../../hooks/TiposOperacionesRealizadas/useOperacionesTLP';


export default function HistorialOperacionesTLP({ history }) {

    const userRol = (JSON.parse(window.sessionStorage.getItem('usuarioRol'))).roles;
    const [rol] = userRol;

    const [sessionExpired, setSessionExpired] = useState();
    const [loading, setLoading] = useState();
    const [failedLoadData, setFailedLoadData] = useState();
    const [failedLoadPDF, setFailedLoadPDF] = useState();


    const [copia, setCopia] = useState();
    const [data, setData] = useState([]);
    const [spin, setSpin] = useState(true);
    const [erroreshttp, setErroreshttp] = useState();

    useEffect(() => {
        checkTypeOfUser();
    }, []);

    //Validate what type of user is entering to this page
    const checkTypeOfUser = async () => {
        //If user is a SUPERADMINISTRADOR or an OPERADOR_CEHVAL GET ALL TLP's
        if (rol === "SUPERADMINISTRADOR" || rol === "OPERADOR_CEHVAL"||rol==="AUTORIZADOR_CEHVAL") {
            //console.log("rol autorizado")
            const response = await consultaOperacionesTLPTodas({ codigoOperacion: 4 });
            if (response) {
                setSpin(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    //console.log(response)
                    if (response.status === 200) {
                        setSpin(false);
                        setData(response.data);
                        setCopia(response.data);
        
                    } else if (response.error === 401) {
                        setSessionExpired(true);
        
                    } else {
                        setFailedLoadData(true);
                    }
                }
            }
            
           

        } else {
            //Get Depositante Directo code from localstorage to use in query to get clients 
            const codigoDepositante = (JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo;
            //console.log(codigoDepositante)
            getHistorialTLPDepositante(codigoDepositante);
        }
    }

    //Handle api to get TLP operations for depositants
    const getHistorialTLPDepositante = async (codigoDep) => {
        try {
            const response = await consultarOperacionesDepositantes({ codigoDepositante: codigoDep, codigoOperacion: 4 });
            if (response) {
                setSpin(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    //console.log(response)
                    if (response.status === 200) {
                        setSpin(false);
                        setData(response.data);
                        setCopia(response.data);
                    } else {
                        setFailedLoadData(true);
                    }
                }
            }

           

        } catch (err) {
            console.error(err);
        }
    }

    //Handle when click "Constancia"
    const printConstancia = async (row) => {
        try {

            //console.log(row.original)
            setLoading(true);
            const response = await constanciaTLP({ partitionKey: row.original.partitionKey, sortKey: row.original.sortKey, codigoOperacion: 4 });
            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    setLoading(false);

                    //console.log(response)
        
                    //If recieve the data then create pdf
                    if (response.data) {
                        const blob = new Blob(
                            [response.data],
                            { type: 'application/pdf' },
                        );
                        //Creates a URL for the PDF
                        const fileURL = URL.createObjectURL(blob);
                        //Open PDF in new window
                        window.open(fileURL)
        
                        URL.revokeObjectURL(fileURL);
                    } else {
                        setLoading(false);
                        setFailedLoadPDF(true);
                    }
                }
            }

          

        } catch (err) {
            console.error(err);
        }
    }

    const signOut = async () => {
        try {
            await Auth.signOut();
            sessionStorage.clear();
            window.location.replace('/login');
        } catch (error) {
            //console.log('error signing out: ', error);
        }
    }

    //Shows modal when applications doesnt load
    const goHome = () => {
        setFailedLoadData(false);
        window.location.replace("/inicioCehval");
    }


    return (
        <>

            {sessionExpired &&
                <ComponenteModal mostrarModal={sessionExpired} cerrarModal={signOut} titulo={`La sesión ha caducado, vuelva a iniciar sesión`} textoBoton="Aceptar"></ComponenteModal>
            }
            {loading &&
                <PantallaCargando></PantallaCargando>
            }
            {failedLoadData &&
                <ComponenteModal mostrarModal={failedLoadData} cerrarModal={goHome} titulo={`Ocurrió algún error, será redireccionado al inicio. Revise su conexión o vuelva a iniciar sesión.`} textoBoton="Aceptar"></ComponenteModal>
            }
            {failedLoadPDF &&
                <ComponenteModal mostrarModal={failedLoadPDF} cerrarModal={() => setFailedLoadPDF(false)} titulo={`El archivo PDF actualmente no está disponible, por favor, inténtelo de nuevo mas tarde.`} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="">
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Historial de Traspasos Libres de Pago"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaUsuarios datos={data} displayInformation={printConstancia} useValidate={useOperacionesTLP} textoBoton={"Constancia"} textTitle="Imprimir constancia"></TablaUsuarios>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    );
}





