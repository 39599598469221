import { instance } from '../apiGlobal';

export async function consultarOperacionesEjecutadasOperadorDD(obj) {
  try {
    const response = await instance(`/sistinfo/operaciones/ms/depositante`, {
      method: "post",
      data: obj,
    })
    return response.data
  } catch (error) {
    return error
  }
}