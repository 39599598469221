import { instance } from '../apiGlobal';

export async function traerLiberacionesRechazadas() {
  try {
    const response = await instance.get(`/cuentavalores/consultar/liberacionesprendas/rechazadas`, {
      headers: {
        'Content-Type': 'application/json',   
      }
    })
  
    return response;
  } catch (error) {
    return error
  }

}
