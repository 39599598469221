import { instance } from '../apiGlobal';

export async function imprimirInformacionOperacionSinFondos(obj) {
      try {
        const response = await instance('/reportems/operacion/info', {
          method:"post",
          data:obj,
          responseType: 'blob',
        })
        return response.data
      } catch (error) {
        return error
      }
}