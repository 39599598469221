
import { instance } from '../apiGlobal';

export async function actualizarSolicitudCodIsin (obj) {
    
  try {
    const response = await instance(`/isin/actualizar/solicitud`, {
      method: "post",
      data: obj
    });
    
    return response;
  } catch (error) {
    return error;
  }
}