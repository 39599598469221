import React from "react";
import { Link } from 'react-router-dom';

//Images
import quitarPermisos from '../../../assets/imagenesRolesPermisos/quitarPermisos.jpg'
import crearRol from '../../../assets/imagenesRolesPermisos/crearRol.jpg'
import agregarRol from '../../../assets/imagenesRolesPermisos/agregarRol.jpg'
import agregarRolUsuario from "../../../assets/imagenesRolesPermisos/agregarRolUsuario.jpg";
import quitarRolUsuario from "../../../assets/imagenesRolesPermisos/quitarRolUsuario.jpg";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";


export default function RolesYPermisos({ history }) {
    return (
        <>

            <div className="fluid-container">
                <main className="main-min-height">
                    <div className="row row-wrap g-0">
                       
                            <div className="col-12 mb-5">
                                <div className="">
                                    <BannerTitulo titulo="Roles y permisos"></BannerTitulo>
                                    <div className="d-flex">
                                        <div className="container font-acumin-variable py-5 mb-5">

                                            <div className="row justify-content-center">
                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-3 ">
                                                    <Link to={{ pathname: "/agregarPermisos" }} className="text-decoration-none text-dark ">
                                                        <div className="card" >
                                                            <img style={{height:"15rem",width:"100%"}} className="" src={agregarRol} alt="imagenFondo" />
                                                            <div className="card-body text-center">
                                                                <h5 className="card-title">Agregar permisos a rol existente</h5>

                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
                                                    <Link to={{ pathname: "/quitarPermisos" }} className="text-decoration-none text-dark">
                                                        <div className="card" >
                                                            <img style={{height:"15rem",width:"100%"}} className="" src={quitarPermisos} alt="imagenFondo" />
                                                            <div className="card-body text-center">
                                                                <h5 className="card-title">Quitar permisos a rol existente</h5>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-3 ">
                                                    <Link to={{ pathname: "/crearRolConPermisos" }} className="text-decoration-none text-dark">
                                                        <div className="card " >
                                                            <img style={{height:"15rem",width:"100%"}} className="" src={crearRol} alt="imagenFondo" />
                                                            <div className="card-body text-center ">
                                                                <h5 className="card-title">Crear nuevo rol</h5>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                {/*
                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-3 ">
                                                    <Link to={{ pathname: "/agregarRolUsuario" }} className="text-decoration-none text-dark ">
                                                        <div className="card" >
                                                            <img style={{height:"15rem",width:"100%"}} className="" src={agregarRolUsuario} alt="imagenFondo" />
                                                            <div className="card-body text-center">
                                                                <h5 className="card-title">Agregar rol a un usuario</h5>

                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-3 ">
                                                    <Link to={{ pathname: "/quitarRolUsuario" }} className="text-decoration-none text-dark ">
                                                        <div className="card" >
                                                            <img style={{height:"15rem",width:"100%"}} className="" src={quitarRolUsuario} alt="imagenFondo" />
                                                            <div className="card-body text-center">
                                                                <h5 className="card-title">Quitar rol a un usuario</h5>

                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    </div>
                </main>
            </div>
        </>
    );
}

