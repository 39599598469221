//React
import React, { useState, useEffect } from "react";

//AWS
import { Auth } from 'aws-amplify';

//Componentes generales
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores';
import TablaTarificacionActualizarVectorPrecios from "../../../../ComponentesGenerales/TablaTarificacionActualizarVectorPrecios";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
//import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";
import formatNumber from "../../../../functions/FormatNumber";

//APIs
import { mostrarVectorPrecios } from "../../../../api/tarificacion/ConceptosNoPeriodicos/vectorPrecios/mostrarVectorPrecios";
import { actualizarVectorPrecios } from "../../../../api/tarificacion/ConceptosNoPeriodicos/vectorPrecios/actualizarVectorPrecios";
import { actualizarTipoCambio } from "../../../../api/tarificacion/ConceptosNoPeriodicos/vectorPrecios/actualizarTipoCambio";

//Hooks
import useActualizarVectorPrecios from "../../../../hooks/Tarificacion/ConceptosNoPeriodicos/useActualizarVectorPrecios";


export default function ActualizarVectorPrecios({ history }) {



    const [tipoCambioStr, setTipoCambioStr] = useState();
    const [tipoCambio, setTipoCambio] = useState();
    const [tipoCambioBaseDatos, setTipoCambioBase] = useState();


   

    //Save loaded data
    const [copia, setCopia] = useState();
    const [data, setData] = useState([]);

    //Spinner
    const [spin, setSpin] = useState(true);
  

    //States to handle load data when loading page 
    const [erroreshttp, setErroreshttp] = useState();
    const [error, setError] = useState();
    const [updateSuccess, setUpdateSuccess] = useState();

    //Shows modal uf the session has expired
    const [sessionExpired, setSessionExpired] = useState();
    const [failLoadData, setFailLoadData] = useState();
    const [errorNotNewAmount, setErrorNotNewAmount] = useState();


    useEffect(() => {
        getVectoresPrecio();
    }, []);


    //Gets all depositors that are in fondo de liquidacion
    const getVectoresPrecio = async () => {
        try {
            setSpin(true)
            const response = await mostrarVectorPrecios();
            //console.log(response)

            if (isNaN(response.error) === false) {
                setErroreshttp(response);

            } else if (response.data) {
                setData(response.data.registrosVectorPrecios);
                setCopia(response.data.registrosVectorPrecios);
                setTipoCambioBase(response.data.tipoCambioActualStr);
                setSpin(false);

            } else if (response.error === 401) {
                setSessionExpired(true);

            } else {
                setFailLoadData("No se pudo cargar la información");
            }

        } catch (err) {
            console.error(err);
        }
    }


    //Function to update the "Monto objetivo" column with the input
    const updateMontoObjetivo = async (row, e) => {

        try {

            //El monto que ingreso el usuario
            const newMonto = row.nuevaValuacion;
            //console.log(newMonto)

            if (newMonto === undefined || newMonto === "" || isNaN(newMonto)) {
                setErrorNotNewAmount(true);

            } else {

                setErrorNotNewAmount(false);

                //El objeto que envia en la petición para actualizar el monto objetivo
                row.original.nuevaValuacion = newMonto;
                const objActualizarValuacion = row.original;

                //console.log(objActualizarValuacion)

                setSpin(true);
                const response = await actualizarVectorPrecios(objActualizarValuacion);

                if ((isNaN(response.error)) === false) {
                    setErroreshttp(response);

                } else if (response.data.respuesta.isSuccessful) {

                    setData(response.data.vectorPrecios.registrosVectorPrecios);
                    setCopia(response.data.vectorPrecios.registrosVectorPrecios);
                    setUpdateSuccess(response.data.respuesta.mensaje);
                    setSpin(false);


                }
            }
        } catch (error) {
            console.error(error);
        }
    }



    //Handle input tipo de cambio
    const handleTipoCambio = (e) => {
        const { value } = e.target;

        //Usamos funcion para formatear numero
        const formattedNumber = formatNumber(value);

        //console.log(formattedNumber);

        //Pasamos al estado el numero para el front
        setTipoCambioStr(formattedNumber.stringNumber);
        //Pasamos al estado el numero para el back
        setTipoCambio(formattedNumber.integerNumber);
    }

    const handleUpdateTipoCambio = async () => {
        try {

            const objNewTipoCambio = {
                nuevoTipoCambio: tipoCambio,
            }

            setSpin(true)
            const response = await actualizarTipoCambio(objNewTipoCambio)
            //console.log(response)

            if ((isNaN(response.error)) === false) {
                setErroreshttp(response);

            } else if (response.data.respuesta.isSuccessful) {
                setTipoCambioStr("");
                setTipoCambio("");

                setData(response.data.vectorPrecios.registrosVectorPrecios);
                setCopia(response.data.vectorPrecios.registrosVectorPrecios);
                setTipoCambioBase(response.data.vectorPrecios.tipoCambioActualStr);
                setUpdateSuccess(response.data.respuesta.mensaje);
                setSpin(false);
            }


        } catch (error) {
            console.error(error);
        }
    }



    const signOut = async () => {
        try {
            await Auth.signOut();
            sessionStorage.clear();
            window.location.replace('/login')
        } catch (error) {
            //console.log('error signing out: ', error);
        }
    }


    const goBack = () => {
        history.push({
            pathname: '/inicioCehval',
        })
    }

    //Close modal when the monto objetivo was updated
    const closeMoldal = () => {
        setSpin(false);
        setUpdateSuccess(false);
    }


    return (
        <>

            {updateSuccess &&
                <ComponenteModal mostrarModal={updateSuccess} cerrarModal={closeMoldal} titulo={updateSuccess} textoBoton="Aceptar"></ComponenteModal>
            }

            {error &&
                <ComponenteModal mostrarModal={error} cerrarModal={() => setError(false)} titulo={error} textoBoton="Aceptar"></ComponenteModal>
            }

           
            {failLoadData &&
                <ComponenteModal mostrarModal={failLoadData} cerrarModal={goBack} titulo={failLoadData} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="">
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <main className="min-height-40">
                    <div className="row row-wrap g-0">
                     
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Actualizar vector de precios"></BannerTitulo>


                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <>
                                                    <div className="px-5">
                                                        <div className="row ">
                                                            <div className="col-12">
                                                                <h4 className="text-royal-blue font-acumin-variable px-2 mt-5">Tipo cambio actual: {tipoCambioBaseDatos} </h4>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-start align-items-end mt-5 mb-5 px-2">
                                                            <div className="col-12 col-md-6 col-lg-6 col-xl-4 ps-3">
                                                                <div className="form-floating ">
                                                                    <input title="Tipo de cambio HNL/USD" onChange={(e) => handleTipoCambio(e)} value={tipoCambioStr} className="form-control fs-5" maxLength={25} name="tipoCambio" type="text" placeholder="Tipo de cambio HNL/USD" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="tipoCambio">Actualizar tipo de cambio HNL/USD</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-3 row align-items-end justify-content-start justify-content-md-start justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                                                                <button onClick={handleUpdateTipoCambio} className="col-auto px-1 mx-2 btn background-royal-blue text-white font-acumin-variable bg-hover-gold align-end" type="button" >Actualizar tipo de cambio</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="px-5">
                                                        <TablaTarificacionActualizarVectorPrecios datos={data} getRowData={updateMontoObjetivo} useValidate={useActualizarVectorPrecios} textoBoton={"Actualizar"} showErrorNotAmount={errorNotNewAmount} textTitle="Clic para ver la información"></TablaTarificacionActualizarVectorPrecios>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                }



                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}





