import React from "react";
import { useLocation } from "react-router-dom";


//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import RegistroAutomatico from "./RegistroAutomatico";

//Hooks
import useSolicitudesPerJuridica from '../../../hooks/useSolicitudesPerJuridica'
import useSolicitudesPerNatural from '../../../hooks/useSolicitudesPerNatural'

//APIs
import { traerInformacionAllDepositante } from "../../../api/VerInformacionUsuarios/traerInformacionAllDepositantes";
import { traerInformacionDepositnate } from '../../../api/VerInformacionUsuarios/TraerInformacionDepositante';

export default function EnviarNotificacionesDeRegistroCorrecto({ history }) {
    
    const location = useLocation();
    const tipo = location.state;
    const estatus = 4;
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()
    const [registrarCuentas, setRegistrarCuentas] = React.useState()
    const [informacionUsuario, setInformnacionUsuario] = React.useState()
    const [codigo,setCodigo]=React.useState()
    const [tipoParticipante,setTipoParticipante]=React.useState()
    const [folio,setFolio]=React.useState()

    React.useEffect(() => {
        traerDepositantes()

    }, [])

    const traerDepositantes = async () => {
        const usuarios = await traerInformacionAllDepositante(estatus)
        //console.log(usuarios)
        let filtrados;
        if (usuarios) {
            setSpin(false)
            if ((isNaN(usuarios.error)) === false) {
              
                setErroreshttp(usuarios)
                
            } else {

                usuarios.map(function (num) {
                    //console.log("hola")
                    //console.log(num.tipoParticipante)
                });



                if (tipo === 1) {
                     filtrados = usuarios.filter(word => word.tipoParticipante === 1);
                } else if (tipo == 2) {
                     filtrados = usuarios.filter(word => word.tipoParticipante === 2);
                } else {
                     filtrados = usuarios.filter(word => word.tipoParticipante === 3);
                }

                setData(filtrados)
                setCopia(filtrados)
            }
        }
    }

    const continuarOferta = async (row) => {


        setCodigo(row.original.cehvalDepositanteCodigoCehval)
        setFolio(row.original.cehvalDepositanteFolio)
        setTipoParticipante(row.original.tipoParticipante)

        const myObj = {
            folio: row.original.cehvalDepositanteFolio,
            estatus: estatus,
        }

       const traerInformacion = await traerInformacionDepositnate(myObj);
        ////console.log(traerInformacion)
        if (traerInformacion) {
            setSpin(false)
            if ((isNaN(traerInformacion.error)) === false) {
              
                setErroreshttp(traerInformacion)
                
            } else {

                setInformnacionUsuario(traerInformacion)
                setRegistrarCuentas(true)
            }
        }
    }



     const resetear=()=>{
        setRegistrarCuentas(false)
   }

    return (
        <>
            {registrarCuentas &&
                <RegistroAutomatico resetear={resetear} informacion={informacionUsuario} estatus={estatus} codigo={codigo} tipoParticipante={tipoParticipante} folio={folio}></RegistroAutomatico>
            }
            {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <div className="fluid-container">
                
                <main className="container-min-height">
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <div className="">
                                {tipo === 1 &&
                                    <BannerTitulo titulo="Solicitudes de depositantes directos para abrir cuentas"></BannerTitulo>
                                }
                                {tipo === 2 &&
                                    <BannerTitulo titulo="Solicitudes de depositantes indirectos personas jurídicas para abrir cuentas"></BannerTitulo>
                                }
                                {tipo === 3 &&
                                    <BannerTitulo titulo="Solicitudes de depositantes indirectos personas naturales para abrir cuentas"></BannerTitulo>
                                }
                                {tipo === 4 &&
                                    <BannerTitulo titulo="Solicitudes de emisores para abrir cuentas"></BannerTitulo>
                                }

                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <>
                                                    {tipo === 1 || tipo === 2 ?
                                                        <TablaUsuarios datos={data} displayInformation={continuarOferta} useValidate={useSolicitudesPerJuridica} textoBoton={"Enviar credenciales"} textTitle="Click para enviar credenciales"></TablaUsuarios>
                                                        :
                                                        <TablaUsuarios datos={data} displayInformation={continuarOferta} useValidate={useSolicitudesPerNatural} textoBoton={"Enviar credenciales"} textTitle="Click para enviar credenciales"></TablaUsuarios>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    );
}













