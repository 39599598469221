import React from "react";
import { Link } from 'react-router-dom';

//Components
import BannerTitulo from "../../ComponentesGenerales/Bannertitulo";

//Images
import registrarDatos from '../../assets/imagenesTarificacion/registraDatos.jpg'
import tablaDatos from '../../assets/imagenesTarificacion/tablaDatos.jpg'


export default function MenuMantenimientoFacturas ({ history }) {
    return (
        <>

            <div className="fluid-container">
                
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                     
                            <div className="col-12 mb-5">
                                <div className="">
                                    <BannerTitulo titulo="Mantenimiento de facturas"></BannerTitulo>
                                    <div className="d-flex">
                                        <div className="container py-5 mb-5">
                                            <div className="row ">
                                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                                                    <Link  to={{
                                                        pathname: '/registrarDatosFacturacion',
                                                        state: 1
                                                    }}  className="text-decoration-none text-dark">
                                                        <div className="card" >
                                                            <img style={{height:"20rem",width:"100%"}} className="" src={registrarDatos} alt="imagenFondo" />
                                                            <div className="card-body text-center font-acumin-variable ">
                                                                <h5 className="card-title">Registrar datos de facturación</h5>

                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                                                <Link  to={{
                                                        pathname: '/tablaDatosFacturacion',
                                                        state: 2
                                                    }}  className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img style={{height:"20rem",width:"100%"}} className="" src={tablaDatos} alt="imagenFondo" />
                                                        <div className="card-body text-center font-acumin-variable">
                                                            <h5 className="card-title">Tabla de datos de facturación registrados</h5>
                                                        </div>
                                                    </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                    </div>
                </main >
                
            </div>
        </>
    );
}

