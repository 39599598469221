import { useMemo } from "react";

export default function useSolicitudesAutorizarAvisosCobroNoPeriodicos () {
  const columns = useMemo(
    () => [
      {
        Header: "Mes de cobro",
        accessor: "mesCobro"
      },
      {
        Header: "Código de participante",
        accessor: "datosParticipante.codigoDepositante"
      },
      {
        Header: "Nombre del participante",
        accessor: "datosParticipante.nombreDepositante"
      },
      {
        Header: "Tipo",
        accessor: "tipoParticipanteStr"
      },
      {
        Header: "Folio",
        accessor: "folio"
      }     
    ],
    []
  );

  return columns;
}
