import React from "react";
import { useHistory } from 'react-router-dom';


//Components
import BannerTitulo from "../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from "../../ComponentesGenerales/ManejadorErrores";
import TablaUsuarios from "../../ComponentesGenerales/TablaUsuarios";

//APIs
import { traerEmisionesCodigoEstatus } from '../../api/emisor/traerEmisionesCodigoEstatus'

//Hooks
import useInventarioEmisiones from "../../hooks/useInventarioEmisiones"

export default function InventarioEmisiones() {
    const history = useHistory();
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()

    React.useEffect(() => {
     
        traerEmisiones()
    }, [])

    const traerEmisiones = async () => {
        const obj = {
           codigoEmisor:(JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo,
            estatus: 3
        }
        const emisiones = await traerEmisionesCodigoEstatus(obj);
        if (emisiones) {
            setSpin(false)
            if ((isNaN(emisiones.error)) === false) {
                setErroreshttp(emisiones)
            } else {
                setData(emisiones)
                setCopia(emisiones)
            }
        }
    }



    const continuarOferta = (row) => {
        //console.log(row.original.cehvalEmisionCodigo)
        //VER SOLICITUD DE DEPOSITANTE DIRECTO // 

        const objetodirecto = {
            codigo: row.original.cehvalEmisionId
        }

        //mandar info depositante directo            
        history.push({
            pathname: '/inventarioSeries', state: { objMenu: objetodirecto }
        })
        

    }

    return (
        <>
            <div className="fluid-container">
           {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }     
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <div className="">
                                <br></br>
                                <BannerTitulo titulo="Inventario de emisiones"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                <div className="d-flex">
                                    <div className="container py-5">
                                        {copia &&
                                            <TablaUsuarios datos={data} displayInformation={continuarOferta} useValidate={useInventarioEmisiones} textoBoton={"Ver series"} textTitle="Click para ver las series"></TablaUsuarios>
                                        }
                                    </div>
                                </div>
}
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    );
}



