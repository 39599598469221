import React from "react";

//Components
import BannerTitulo from "../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../ComponenteModal";
import SpinnerStart from '../SpinnerStart';
import ManejadorErrores from '../ManejadorErrores';
import RegistroAutomatico from "../../pantallasCehval/OperadorCehval/AbrirCuentasDepositantes/RegistroAutomatico";
import PantallaCargando from "../PantallaCargando";


//APIs
import { traerInformacionEmisorId } from "../../api/VerInformacionDeEmisor.js/traerInformacionEmisorId";
import { traerInformacionEmisorCodigo } from "../../api/VerInformacionDeEmisor.js/traerInformacionEmisorCodigo";
import { cambiarEstatusEmisor } from "../../api/VerInformacionDeEmisor.js/cambiarEstatusEmisor";

export default function VerInfoEmisor({ history }) {
    if (history.location.state === undefined) {
        history.go(-1)
    }
    const [informacionUsuario, setInformnacionUsuario] = React.useState()
    const [validacionCorrecta, setValidacionCorrecta] = React.useState()
    const [validacionIncorrecta, setValidacionIncorrecta] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()
    const [registrarCuentas, setRegistrarCuentas] = React.useState()
    const [descripcion, setDescripcion] = React.useState();
    const [descripcionVacia, setDescripcionVacia] = React.useState(false)
    const [desplegrarDescripcion, setDesplegarDescripcion] = React.useState(false)
    const [mensajeModalExitoso, setMensajeModalExitoso] = React.useState("El proceso fue exitoso")
    const [loading, setLoading] = React.useState(false);




    React.useEffect(() => {
        // //console.log("HOLA")
        traerInfo()
    }, [])



    const traerInfo = async () => {
        ////console.log(history.location.state.objMenu.id)
        //console.log(history.location.state.objMenu.codigo)
        let traerInformacion;

        if (history.location.state.objMenu.estatus > 1) {
            traerInformacion = await traerInformacionEmisorCodigo({ codigo: history.location.state.objMenu.codigo });
        } else {
            traerInformacion = await traerInformacionEmisorId({ idEmisor: history.location.state.objMenu.id });
        }
        //console.log(traerInformacion)
        if (traerInformacion) {
            setSpin(false)
            if ((isNaN(traerInformacion.error)) === false) {
                setErroreshttp(traerInformacion)
            } else {
                setInformnacionUsuario(traerInformacion)
            }
        }
    }

    const enviarEstatus = async (mensaje) => {

        if (mensaje === "valido") {

            setLoading(true)

            const myObj =
            {
                idEmisor: history.location.state.objMenu.id,
                estatus: history.location.state.objMenu.estatus + 1,
                descripcion: descripcion
            }
            const modificarEstatus = await cambiarEstatusEmisor(myObj)
            //console.log(modificarEstatus)
            if (modificarEstatus) {
                if ((isNaN(modificarEstatus.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(modificarEstatus)
                } else {

                    setLoading(false)
                    if (modificarEstatus?.codigo === "000") {
                        //console.log(modificarEstatus.emisor)
                        if (history.location.state.objMenu.estatus === 1) {

                            setMensajeModalExitoso(`Registro exitoso, código de emisor asignado ${modificarEstatus.emisor}`)
                        }

                        setValidacionCorrecta(true)

                    } else {
                        setValidacionIncorrecta(true)
                    }
                }
            }

        } else {

            if (descripcion) {
                setLoading(true)
                const myObj2 =
                {
                    idEmisor: history.location.state.objMenu.id,
                    estatus: 0,
                    descripcion: descripcion
                }
                const modificarEstatus2 = await cambiarEstatusEmisor(myObj2)
                if (modificarEstatus2) {
                    if ((isNaN(modificarEstatus2.error)) === false) {
                        // respuestaincorrecta           
                        setErroreshttp(modificarEstatus2)
                    } else {
                        setLoading(false)
                        if (modificarEstatus2?.codigo === "000") {
                            setValidacionCorrecta(true)

                        } else {
                            setValidacionIncorrecta(true)
                        }
                    }
                }
            } else {
                setDescripcionVacia(true)
            }

        }
    }

    const enviarNotificacion = async (mensaje) => {
        setLoading(true)
        const myObj =
        {
            idEmisor: history.location.state.objMenu.id,
            estatus: 4,
        }
        const modificarEstatus = await cambiarEstatusEmisor(myObj)
        if (modificarEstatus) {
            if ((isNaN(modificarEstatus.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(modificarEstatus)
            } else {

                setLoading(false)
                if (modificarEstatus?.codigo === "000") {

                    setValidacionCorrecta(true)

                } else {
                    setValidacionIncorrecta(true)
                }
            }
        }


    }

    const cancelarValidadcionCorrecta = () => {
        setLoading(false)
        setValidacionCorrecta(false)
        history.push({
            pathname: history.location.state.objMenu.ruta
        })
    }

    const cancelarValidadcionIncorrecta = () => {
        setLoading(false)
        setValidacionIncorrecta(false)
        history.push({
            pathname: history.location.state.objMenu.ruta
        })
    }

    const IrMenu = () => {
        history.push({
            pathname: '/emisoresRegistrados'
        })
    }


    return (
        <>

            <div className="fluid-container">
            {loading &&
                        <PantallaCargando />
   }
                {registrarCuentas &&
                    <RegistroAutomatico informacion={informacionUsuario} estatus={history.location.state.objMenu.estatus} codigo={history.location.state.objMenu.codigo} idEmisor={history.location.state.objMenu.id} tipoParticipante={history.location.state.objMenu.tipoParticipante}></RegistroAutomatico>
                }

                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                {validacionCorrecta &&
                    <ComponenteModal mostrarModal={validacionCorrecta} cerrarModal={cancelarValidadcionCorrecta} titulo={mensajeModalExitoso} textoBoton="Entendido"></ComponenteModal>
                }
                {validacionIncorrecta &&
                    <ComponenteModal mostrarModal={validacionIncorrecta} cerrarModal={cancelarValidadcionIncorrecta} titulo="El proceso fallo, revise su información e intentelo de nuevo" textoBoton="Entendido"></ComponenteModal>
                }
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">

         
                        <div className="col-12">
                            <div className="padding-r-1rem">
                                <BannerTitulo titulo={history.location.state.objMenu.titulo}></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="row d-flex justify-content-center">

                                        <div className="col-12">
                                            <div style={{ border: "transparent" }} className="card ">
                                                <div className="card-body ">
                                                    <div className="d-grid gap-1 col-10 mx-auto">
                                                        {informacionUsuario &&
                                                            <form className="row g-3 needs-validation" novalidate >
                                                                <div className="card" style={{ color: "#242848" }}>
                                                                    <div className="card-body">
                                                                        {informacionUsuario.cehvalEmisorCodigo &&
                                                                            <div className="row mb-3  justify-content-end">
                                                                                <div className="col-md-3 form-floating">
                                                                                    <input disabled value={informacionUsuario.cehvalEmisorCodigo} id="codigoEmisor" name="codigoEmisor" type="text" className="form-control textoFormularioDepositantedirecto" />
                                                                                    <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto ms-3">Código</label>
                                                                                </div>
                                                                            </div>}


                                                                        <h5 className="mt-3 text-royal-blue font-acumin-variable">I. Identificacion del emisor</h5>




                                                                        <div className="row mb-3">
                                                                            <div className="col-md-12 form-floating"  >
                                                                                <input disabled value={informacionUsuario.cehvalEmisorNombreCom} type="text" className="form-control textoFormularioDepositantedirecto" id="nombreCompleto" placeholder="nombre" required />
                                                                                <label className="textoFormularioDepositantedirecto ms-2" htmlFor="nombreCompleto">Nombre completo del emisor</label>
                                                                            </div>
                                                                        </div>




                                                                        <div className="row mb-3">
                                                                            <div className="col-md-6 form-floating"  >
                                                                                <input disabled value={informacionUsuario.cehvalEmisorCatSectorId.nombre} type="text" className="form-control textoFormularioDepositantedirecto" id="nombreCompleto" placeholder="nombre" required />
                                                                                <label className="textoFormularioDepositantedirecto ms-2" htmlFor="nombreCompleto">Sector</label>
                                                                            </div>

                                                                            <div className="col-md-6 form-floating"  >
                                                                                <input disabled value={informacionUsuario.cehvalEmisorCatSubSecId.nombre} type="text" className="form-control textoFormularioDepositantedirecto" id="nombreCompleto" placeholder="nombre" required />
                                                                                <label className="textoFormularioDepositantedirecto ms-2" htmlFor="nombreCompleto">Subsector</label>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row mb-3">

                                                                            <div className="col-md-4 form-floating"  >
                                                                                <input disabled value={informacionUsuario.cehvalEmisorNacionalidadId.nombre} type="text" className="form-control textoFormularioDepositantedirecto" id="nombreCompleto" placeholder="nombre" required />
                                                                                <label className="textoFormularioDepositantedirecto ms-2" htmlFor="nombreCompleto">Nacionalidad</label>
                                                                            </div>

                                                                            <div className="col-md-4 form-floating"  >
                                                                                <input disabled value={informacionUsuario.cehvalEmisorRtn} type="text" className="form-control textoFormularioDepositantedirecto" id="rtn" placeholder="rtn" required />
                                                                                <label htmlFor="rtn" className="textoFormularioDepositantedirecto ms-2">RTN</label>
                                                                            </div>

                                                                            <div className="col-md-4 form-floating"  >
                                                                                <input disabled value={informacionUsuario.cehvalEmisorPagWeb} type="text" className="form-control textoFormularioDepositantedirecto" id="paginaWeb" placeholder="paginaWeb" required />
                                                                                <label htmlFor="paginaWeb" className="textoFormularioDepositantedirecto ms-2">Página web</label>
                                                                            </div>

                                                                        </div>

                                                                        <h5 className="mt-5 mb-3 text-royal-blue font-acumin-variable" >II. Datos del representante legal</h5>

                                                                        <div className="row mb-3">

                                                                            <div className="col-md-8 form-floating"  >
                                                                                <input disabled value={informacionUsuario.cehvalEmisorNombreCompletoRepreLeg} type="text" className="form-control textoFormularioDepositantedirecto" id="nombreCompletoRepresentanteLegal" placeholder="nombreCompletoRepresentanteLegal" required />
                                                                                <label htmlFor="nombreCompletoRepresentanteLegal" className="textoFormularioDepositantedirecto ms-2">Nombre completo del representante legal</label>
                                                                            </div>

                                                                            {/*} <div className="col-md-3 form-floating"  >
                                                                                <input disabled value={informacionUsuario.cehvalEmisorPriNombreRepreLeg} type="text" className="form-control textoFormularioDepositantedirecto" id="primerNombreRepresentanteLegal" placeholder="primerNombreRepresentanteLegal" required />
                                                                                <label htmlFor="primerNombreRepresentanteLegal" className="textoFormularioDepositantedirecto ms-2">Primer nombre</label>
                                                                            </div>
                                                                            <div className="col-md-3 form-floating"  >
                                                                                <input disabled value={informacionUsuario.cehvalEmisorSegNombreRepreLeg} type="text" className="form-control textoFormularioDepositantedirecto" id="segundoNombRerepresentanteLegal" placeholder="primerNombreRepresentanteLegal" required />
                                                                                <label htmlFor="segundoNombRerepresentanteLegal" className="textoFormularioDepositantedirecto ms-2">Segundo nombre</label>
                                                                            </div>
                                                                            <div className="col-md-3 form-floating"  >
                                                                                <input disabled value={informacionUsuario.cehvalEmisorPriApellidoRepreLeg} type="text" className="form-control textoFormularioDepositantedirecto" id="primerApellidoRepresentanteLegal" placeholder="primerNombreRepresentanteLegal" required />
                                                                                <label htmlFor="primerApellidoRepresentanteLegal" className="textoFormularioDepositantedirecto ms-2">Primer apellido</label>
                                                                            </div>
                                                                            <div className="col-md-3 form-floating"  >
                                                                                <input disabled value={informacionUsuario.cehvalEmisorSegApellidoRepreLeg} type="text" className="form-control textoFormularioDepositantedirecto" id="segundoApellidoRepresentanteLegal" placeholder="primerNombreRepresentanteLegal" required />
                                                                                <label htmlFor="segundoApellidoRepresentanteLegal" className="textoFormularioDepositantedirecto ms-2">Segundo apellido</label>
                                                                            </div>*/}
                                                                            <div className="col-md-4 form-floating"  >
                                                                                <input disabled value={informacionUsuario.cehvalEmisorCoreoElecRepreLeg} type="email" className="form-control textoFormularioDepositantedirecto" id="email" placeholder="name@example.com" required />
                                                                                <label htmlFor="email" className="textoFormularioDepositantedirecto ms-2">Correo electrónico</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-3">



                                                                            <div className="col-md-8 form-floating">
                                                                                <input disabled value={informacionUsuario.cehvalEmisorFechaHora} type="text" className="form-control border" id="lugarRegistro" name="lugarRegistro" required />
                                                                                <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto ms-2">Fecha y hora de registro</label>
                                                                            </div>
                                                                        </div>

                                                                        <h5 className="mt-5 mb-3 text-royal-blue font-acumin-variable" >III. Instrucciones especiales (cuentas bancarias)</h5>

                                                                        {informacionUsuario.cehvalCuentaBanCollection.length > 0 ?
                                                                            <>
                                                                                {
                                                                                    informacionUsuario.cehvalCuentaBanCollection.map((item, index) => (
                                                                                        <>
                                                                                            {item.catMonedaCatMonedaId.catMonedaDivisa === "LEMPIRA" ?
                                                                                                <h6 className="textoTituloFormulario">Nacional</h6> :
                                                                                                <h6 className="textoTituloFormulario">Extranjera</h6>

                                                                                            }


                                                                                            <div className="row mb-3">
                                                                                                <div className="col-md-3">
                                                                                                    <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Moneda</label>
                                                                                                    <input disabled type="text" value={item.catMonedaCatMonedaId.catMonedaDivisa} className="form-control  border  " />

                                                                                                </div>

                                                                                                <div className="col-md-3">
                                                                                                    <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Banco</label>
                                                                                                    <input disabled type="text" value={item.catBancoCatBancoId.catBancoNombre} className="form-control  border  " />
                                                                                                </div>

                                                                                                <div className="col-md-3">
                                                                                                    <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Tipo de cuenta</label>
                                                                                                    <input disabled type="text" value={item.catTipoCuentaCatTipCueId.catTipCueCuenta} className="form-control  border  " />
                                                                                                </div>

                                                                                                <div className="col-md-3">
                                                                                                    <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Número de cuenta</label>
                                                                                                    <input disabled type="text" value={item.cehvalCuentaBanNumCue} className="form-control  border  " id="numeroCuentaN" name="numeroCuentaN" required />
                                                                                                </div>
                                                                                            </div>

                                                                                        </>
                                                                                    ))
                                                                                }
                                                                            </>
                                                                            :
                                                                            <h5>No hay cuentas registradas</h5>
                                                                        }

                                                                        {informacionUsuario.cehvalEmisorEstatus === 0 &&
                                                                            <div className="row mb-3">
                                                                                <div className="col-md-12">
                                                                                    <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Motivo del rechazo</label>
                                                                                    <textarea disabled type="text" value={informacionUsuario.cehvalEmisorDescripcion} className="form-control" id="origenRecursos" name="origenRecursos" required style={{ height: "7rem" }} />
                                                                                </div>
                                                                            </div>
                                                                        }



                                                                        {history.location.state.objMenu.estatus === 1 ?

                                                                            <>

                                                                                {desplegrarDescripcion ?
                                                                                    <div className=''>
                                                                                        <div class="form-floating">
                                                                                            <textarea class="form-control" placeholder="Leave a comment here" onChange={(e) => {
                                                                                                setDescripcion(e.target.value)
                                                                                            }} id="floatingTextarea2" style={{ height: "7rem" }} required></textarea>
                                                                                            <label for="floatingTextarea2">Escriba el motivo del rechazo</label>
                                                                                        </div>

                                                                                        <div className='contenedorBotonesStteper  '>
                                                                                            <button type="button" className="btn background-royal-blue bg-hover-gold text-white mt-2" onClick={() => {
                                                                                                setDesplegarDescripcion(false)
                                                                                            }}>Cancelar</button>
                                                                                            <button type="button" className="btn background-royal-blue bg-hover-gold text-white mt-2" onClick={() => {

                                                                                                enviarEstatus("invalido")
                                                                                            }}>Enviar</button>
                                                                                        </div>

                                                                                        {descripcionVacia &&
                                                                                            <p className="error mt-2">Necesitas escribir el motivo del rechazo</p>
                                                                                        }
                                                                                    </div>
                                                                                    :

                                                                                    <div className="row mb-3 mt-5">
                                                                                        <div className="col-md-12 col-lg-12 mb-3">
                                                                                            <div className="d-flex  font-acumin-variable">
                                                                                                <button title="Autorizar solicitud" onClick={() => { enviarEstatus("valido") }} type='button' className="me-auto p-2 col-4 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white  mb-2" >Autorizar</button>
                                                                                                <button title="Rechazar solicitud" onClick={() => { setDesplegarDescripcion(true) }} type='button' className="p-2 col-4 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white  mb-2" >Rechazar</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {history.location.state.objMenu.estatus === 2 ?

                                                                                    <div className='d-flex justify-content-center p-3' >
                                                                                        <button type="button" className="btn botonFormularioDepositanteAprobar me-5 bg-hover-blue-royal" onClick={() => {
                                                                                            setRegistrarCuentas(true)

                                                                                        }}>Abrir cuenta</button>

                                                                                    </div>
                                                                                    :
                                                                                    <>
                                                                                        {history.location.state.objMenu.estatus === 3 ?

                                                                                            <div className="row mb-3 mt-5">
                                                                                                <div className="col-md-12 col-lg-12 mb-3">
                                                                                                    <div className="d-flex  font-acumin-variable">
                                                                                                        <button title="Cerrar información" onClick={() => { IrMenu() }} type='button' className="me-auto p-2 col-4 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white  mb-2" >Cerrar</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>


                                                                                            :
                                                                                            
                                                                                            <div className="row mb-3 mt-5">
                                                                                                <div className="col-md-12 col-lg-12 mb-3">
                                                                                                    <div className="d-flex  font-acumin-variable">
                                                                                                        <button title="Enviar notificación de rechazo" onClick={() => { enviarNotificacion() }} type='button' className="me-auto p-2 col-4 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white  mb-2" >Notificar</button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>


                                                                                        }

                                                                                    </>


                                                                                }

                                                                            </>

                                                                        }


                                                                    </div>

                                                  
                                                                </div>
                                                            </form>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}








