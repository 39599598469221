import { useMemo } from "react";

export default function useConceptosCobroAutomaticos () {
  const columns = useMemo(
    () => [
      {
        Header: "Descripción",
        accessor: "nombreConcepto"
      },
      {
        Header: "Periodicidad",
        accessor: "tipoPeriodicidadStr"
      },
      {
        Header: "Categoría No.",
        accessor: "tipoConcepto"
      },
      {
        Header: "Tarifa",
        accessor: "tarifaUSD"
      },
      {
        Header: "Base de cálculo",
        accessor: "baseCalculoStr"
      },
      {
        Header: "Pago en USD",
        accessor: "montoBaseUSDStr"
      },
      {
        Header: "Pago en HNL",
        accessor: "montoHNLStr"
      },
      {
        Header: "¿Modificado?",
        accessor: "modificadoStr"
      }
    ],
    []
  );

  return columns;
}
