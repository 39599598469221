import { instance } from '../../apiGlobal';

export async function registrarEmisorBCV(obj) {
    try {
        const response = await instance(`/emisor/registro/row`, {
            method: "post",
            data: obj,
        })
        return response;
    } catch (error) {
        return error;
    }
}
