import React from "react";
import { FormContext } from "./form_context";

export default function RegistroDI3(props) {
    const [, setValue] = React.useContext(FormContext);
    
    React.useEffect(()=>{
        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 0 };
            return { ...prev, datos };
        });

    },[])

    return (
        <>
           
                    <div className="card-body">
                        <h5 className="textoTituloFormulario" >III. Procedencia de los recursos</h5>
                        <div className="row mb-3">
                            <div className="col-md-12">
                                <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Procedencia de los recursos</label>
                                <input disabled type="text" value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaJurRecProc}  className="form-control"  id="origenRecursos" name="origenRecursos" required />                             
                            </div>
                        </div>
                    </div>

        </>
    );
}

