import React, { useState, useEffect } from "react";

import { Modal } from "react-bootstrap";


//Components

import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { traerMonedas } from '../../../api/catalogos/traerMonedas';
import { notificarDepositoFondosDD } from '../../../api/MercadoSecundario/notificarDepositoFondosDD';
import { consultarDepositanteMs } from "../../../api/MercadoSecundario/consultarDepositanteMs";

//Functions
import formatNumber from "../../../functions/FormatNumber";



export default function NotificarDepositoFondosMS({ history }) {
    const [erroreshttp, setErroreshttp] = useState();
    
    //Catalogo 
    const [monedas, setMonedas] = useState([]);

    //Inputs
    const [codigoDepositante, setCodigoDepositante] = useState();
    const [nombreDepositante, setNombreDepositante] = useState("");
    const [folio, setFolio] = useState();
    const [moneda, setMoneda] = useState();
    const [fecha, setFecha] = useState();
    const [monto, setMonto] = useState();
    const [montoFront, setMontoFront] =  useState();
    const [monedaStr, setMonedaStr] = useState();

    //Errors and messages
    const [procesoExitoso, setProcesoExitoso] = useState();
    const [procesoFallido, setProcesoFallido] = useState();
    const [sesionCaducada, setSesionCaducada] = useState();
    const [mensaje, setMensaje] = useState();
    const [loading, setLoading] = useState();
    const [depositantNoExists, setDepositantNoExists] = useState();
    const [depositantExists, setDepositantExists] = useState();
    const [dobleConfirmacion, setDobleConfirmacion] = useState();
    const [fechaDeposito, setFechaDeposito] = React.useState()

    const [errorCodigoDepositnate,setErrorCodigoDepositante]=React.useState();

    const codigoCasaBolsa=(JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo

    useEffect(() => {
        traerDatos();
    }, []);




    const traerDatos = async () => {
        try {

            //Catalogo moneda
            const catalogoMonedas = await traerMonedas();
            if (catalogoMonedas) {
                if ((isNaN(catalogoMonedas.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(catalogoMonedas);
                } else {
                    setMonedas(catalogoMonedas);
                }
            }

            //Establece la fecha de hoy en el input tipo fechas
            const fecha = new Date()
            const añoActual = fecha.getFullYear();
            let diaActual = fecha.getDate();
            let mesActual = fecha.getMonth() + 1;
    
            if (diaActual < 10) {
                diaActual = "0" + diaActual
            }
    
            if (mesActual < 10) {
                mesActual = "0" + mesActual
            }
    
          
            const fechaActual = diaActual+"/"+mesActual+"/"+añoActual
            setFecha(fechaActual);

        } catch (error) {
            console.error(error);
        }
    }


    const onSubmitForm = async (e) => {
        e.preventDefault();
        if(depositantNoExists){
          setErrorCodigoDepositante(true)

        }else{
            //console.log(moneda)
            if(moneda===1){
                setMonedaStr("HNL")
            }else{
                setMonedaStr("USD")
            }
            setDobleConfirmacion(true)
        }
     
    
    }

    const notificar=async(e)=>{
        setDobleConfirmacion(false)
        const objetoUsuario = {
            codigoDepositante: codigoDepositante,
            codigoCasaBolsa:codigoCasaBolsa,
            nombreDepositante: nombreDepositante,
            folioBchTr: folio,
            moneda: moneda,
            monto: monto,
            fechaDeposito: fechaDeposito,
        }
        //console.log(objetoUsuario);

        setLoading(true);
       

        const response = await notificarDepositoFondosDD(objetoUsuario);
        setLoading(false);

    
        if (response) {
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(response);
            } else {
                if (response.isSuccessful) {
                    //console.log("existoso")
                    setProcesoExitoso(true);
                    setMensaje("Depósito de fondos notificado");
        
                } else {
                    setProcesoFallido(true);
                    setMensaje(response.mensaje);
                }
            }
        }

    }



    //Handle function to get the depositante's name
    const checkLength = (e) => {

        if (e.length > 9) {
            getDepositantData(e);
        } else {
            setNombreDepositante("");
            setDepositantExists(false);
            setDepositantNoExists(false);
        }
    }

    //Get the depositante's name
    const getDepositantData = async (e) => {
        setLoading(true);
        //console.log(e)
        //console.log(codigoCasaBolsa)
        const infoDepositante = await consultarDepositanteMs({ codigo: e, codigoCasaBolsa:codigoCasaBolsa })
        //console.log(infoDepositante)
        
        if (infoDepositante) {
            setLoading(false);
            if ((isNaN(infoDepositante.error)) === false) {
             
                // respuestaincorrecta           
                setErroreshttp(infoDepositante)
            } else {
                if (infoDepositante.mensajeError) {
                    setDepositantExists(false);
                    setDepositantNoExists(true);
                   

                } else {
                    setDepositantNoExists(false);
                    setDepositantExists(true);
                    //console.log(infoDepositante.depositante.cehvalDepositanteNombreCompleto)
                    setNombreDepositante(infoDepositante.depositante.cehvalDepositanteNombreCompleto)
                }

            }
        }
    }


    const handleInputMonto = (e) => {

        const { value } = e.target;

        const formattedNumber = formatNumber(value);
 
        // Remove all characters other than digits or decimal points
        // const montoReplaced = value.replace(/[^\d.]/g, '');

        //Setted the amount for the backend
        setMonto(formattedNumber.integerNumber);
        setMontoFront(formattedNumber.stringNumber);

        //Format number for the frontend
        // formatNumber(montoReplaced, setMontoFront);
    }




    const cerrarModal = () => {
        setProcesoExitoso(false);
        setSesionCaducada(false);
        setCodigoDepositante("");
        setNombreDepositante("");
        setFolio("");
        setMoneda("");
        setMonto("");
        setDepositantExists("");
        setFechaDeposito("")
        setMontoFront("")
    }

    const onHideModal = () => {
    }


    return (
        <>
            <Modal show={dobleConfirmacion} animation={false} centered onHide={onHideModal}>
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => setDobleConfirmacion(false)} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >¿Desea continuar con la notificación a la CEHVAL del depósito de {montoFront} {monedaStr} con el folio {folio} realizado por {nombreDepositante}?</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => setDobleConfirmacion(false)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Cancelar</button>
                        <button onClick={notificar} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Aceptar</button>
                    </div>
                </div>
            </Modal>

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {procesoExitoso &&
                <ComponenteModal mostrarModal={procesoExitoso} cerrarModal={cerrarModal} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {procesoFallido &&
                <ComponenteModal mostrarModal={procesoFallido} cerrarModal={() => setProcesoFallido(false)} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {sesionCaducada &&
                <ComponenteModal mostrarModal={sesionCaducada} cerrarModal={cerrarModal} titulo="Ups! Al parecer la sesión ha caducado. Vuelva a iniciar sesión o revise su conexión a internet" textoBoton="Entendido"></ComponenteModal>
            }

            {errorCodigoDepositnate &&
                <ComponenteModal mostrarModal={errorCodigoDepositnate} cerrarModal={()=>setErrorCodigoDepositante(false)} titulo="El código del depositantes es inválido, intente con otro." textoBoton="Entendido"></ComponenteModal>
            }

            {loading &&
                <PantallaCargando></PantallaCargando>
            }

            <div className="fluid-container">{/* style={{ backgroundImage: `url(${fondo})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="fluid-container">*/}
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 mb-5 main-min-height">
                            <div className="">
                                <BannerTitulo titulo="Notificar depósito de fondos en BCH-TR a la CEHVAL"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <section className="d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded">
                                                <div className="col-12">
                                                    <form onSubmit={onSubmitForm} className="needs-validation font-acumin-variable text-royal-blue">
                                                        <div className="row mt-4">
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input value={codigoDepositante} onKeyUp={(e) => checkLength(e.target.value)} onChange={(event) => setCodigoDepositante(event.target.value)} title="Código del depositante" className="form-control fs-5" maxLength={25} name="codigoDepositante" type="text" placeholder="codigoDepositante" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="codigoDepositante">Código de depositante</label>
                                                                    {depositantNoExists &&
                                                                        <span className="text-danger fs-15 font-acumin-variable ms-3 mt-1">El código de depositante es inválido</span>
                                                                    }
                                                                    {depositantExists &&
                                                                        <span className="text-success fs-15 font-acumin-variable ms-3 mt-1">Código de depositante válido</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 form-floating mb-3">
                                                                <div className="form-floating">
                                                                    <input title="Nombre del depositante" type="text" className="form-control border" value={nombreDepositante} name="nombreDepositante" required disabled />
                                                                    <label htmlFor="nombreDepositante" className="form-label ">Nombre de depositante</label>
                                                                    <span className="text-muted fs-x-small ps-2"> &#40;Ingrese el código del depositante&#41;</span>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Folio BCH-TR" className="form-control fs-5" onChange={(e) => setFolio(e.target.value)} value={folio} maxLength={25} name="folioBCH" type="text" placeholder="Folio BCH-TR" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="folioBCH">Folio BCH-TR</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating">
                                                                    <select className="form-select" title="Seleccione la moneda del depósito" value={moneda} onChange={(e) => setMoneda(e.target.value)} id="moneda" name="Moneda" required>
                                                                        <option value=""></option>
                                                                        {monedas &&
                                                                            monedas.map((item, index) => (
                                                                                <option key={index} value={item.id} >{item.nombre}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Moneda</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Monto del depósito" className="form-control fs-5" onInput={(e) => handleInputMonto(e)} value={montoFront} maxLength={20} name="monto" type="text" placeholder="Monto" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="monto">Monto</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Fecha en la que se notifica el depósito" className="form-control fs-5" value={fecha} name="fecha" type="text" placeholder="fecha" required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="fecha">Fecha de notificación del depósito</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Fecha en la que se realizó el deposito" className="form-control text-uppercase" type="date" value={fechaDeposito} onChange={(event) => setFechaDeposito(event.target.value)} name="fechaEmision" placeholder="Fecha de emisión" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Fecha de depósito *</label>
                                                                </div>
                                                            </div>

                                                           
                                                            <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                <button type="submit"  className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-1 background-royal-blue text-white bg-hover-gold">Notificar</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

