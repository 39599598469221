import React from "react";
import { useLocation } from "react-router-dom";


//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";

//Hooks
import useSolicitudesPerJuridica from '../../../hooks/useSolicitudesPerJuridica';
import useSolicitudesPerNatural from '../../../hooks/useSolicitudesPerNatural';

//APIs
import { traerInformacionAllDepositante } from "../../../api/VerInformacionUsuarios/traerInformacionAllDepositantes";

export default function VerCuentasDepositantes({ history }) {
    const location = useLocation();
    const tipo = location.state;
    
    const estatus = 5;
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()

    React.useEffect(() => {
        traerDepositantes()
    }, [])

    const traerDepositantes = async () => {
        const usuarios = await traerInformacionAllDepositante(estatus)
        if (usuarios) {
            setSpin(false)
            if ((isNaN(usuarios.error)) === false) {
              
                setErroreshttp(usuarios)
                
            } else {
                //console.log(usuarios)
                if (tipo === 1) {
                    var filtrados = usuarios.filter(word => word.tipoParticipante===1);
                    filtrados=usuarios.filter(word=> (word.cehvalDepositanteCodigoCehval.indexOf("DDIRPJ") >= 0))
                } else if (tipo == 2) {
                    var filtrados = usuarios.filter(word => word.tipoParticipante===2);
                } else {
                    var filtrados = usuarios.filter(word => word.tipoParticipante===3);
                }
                setData(filtrados)
                setCopia(filtrados)
            }
        }
    }
    

    const viewClientAcount = (row) => {

        const nombreDepositanteIndirectoPJ = row.original.cehvalDepositantePersonaJurId.cehvalPersonaJurNombre;
        const nombreDepositanteIndirectoPN = row.original.cehvalDepositanteNombreCompleto;
        const codigoDepositante = row.original.cehvalDepositanteCodigoCehval;


        const splitcodigoDepositante = codigoDepositante.match(/[a-z]+|[^a-z]+/gi).join(" ").replace(/\s+/g, " ");

        //Si elije un depositante indirecto persona juridica
        if (splitcodigoDepositante.includes("DINDPJ")) {
            history.push({
                pathname: '/cuentasDepositantes',
                state: {
                    objClienteRow: {
                        tipo:tipo,
                        nombreDepositanteIndirecto: nombreDepositanteIndirectoPJ,
                        codigoDepositanteIndirecto: codigoDepositante,
                    },
                }
            });

            //Si elije un depositante indirecto persona natural
        } else {
            history.push({
                pathname: '/cuentasDepositantes',
                state: {
                    objClienteRow: {
                        tipo:tipo,
                        nombreDepositanteIndirecto: nombreDepositanteIndirectoPN,
                        codigoDepositanteIndirecto: codigoDepositante,
                    },
                }
            });
        }
    }

    const continuarOferta = (row) => {
        //console.log(row.original)
        //VER SOLICITUD DE DEPOSITANTE DIRECTO // 
        if (row.original.tipoParticipante===1) {  //mandar info depositante directo  
            const objetodirecto = {
                titulo: "Depositante directo",
                folio: row.original.cehvalDepositanteFolio,
                estatus: estatus,
                textoBoton12: "Cerrar",
            }
            //mandar info depositante directo            
            history.push({
                pathname: '/verInfoDepDirectoPerJuridicoDoc', state: { objMenu: objetodirecto }
            })
        } else if (row.original.tipoParticipante===2) {

            const objetodirecto = {
                titulo: "Depositante indirecto persona jurídica",
                folio: row.original.cehvalDepositanteFolio,
                estatus: estatus,
                textoBoton12: "Cerrar",
            }
            //mandar info depositante directo            
            history.push({
                pathname: '/verInfoDepDirectoPerJuridicoDoc', state: { objMenu: objetodirecto }
            })
        } else {
            const objetoIndirecto = {
                titulo: "Depositante indirecto persona natural",
                folio: row.original.cehvalDepositanteFolio,
                //codigo: row.cells[3].value,
                estatus: estatus,
                textoBoton12: "Cerrar",
            }
            //mandar info depositante indirecto            
            history.push({
                pathname: '/verInfoDepIndPersonaNaturalDoc', state: { objMenu: objetoIndirecto }
            })
        }
    }


    return (
        <>

            {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
            <div className="fluid-container">
                
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        

                        <div className="col-12">
                            <div className="">
                                {tipo === 1 &&
                                    <BannerTitulo titulo="Cuentas de depositantes directos registrados"></BannerTitulo>
                                }
                                {tipo === 2 &&
                                    <BannerTitulo titulo="Cuentas de depositantes indirectos personas jurídicas"></BannerTitulo>
                                }
                                {tipo === 3 &&
                                    <BannerTitulo titulo="Cuentas de depositantes indirectos personas naturales"></BannerTitulo>
                                }

                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <>
                                                    {tipo === 1 || tipo === 2 ?
                                                        <TablaUsuarios datos={data} displayInformation={viewClientAcount} useValidate={useSolicitudesPerJuridica} textoBoton={"Abrir"} textTitle="Click para ver la información del depositante"></TablaUsuarios>
                                                        :
                                                        <TablaUsuarios datos={data} displayInformation={viewClientAcount} useValidate={useSolicitudesPerNatural} textoBoton={"Abrir"} textTitle="Click para ver la información del depositante"></TablaUsuarios>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>

        </>
    );
}













