import React from "react";


//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";

//APIs
import { traerInformacionAllEmisores } from "../../../api/VerInformacionDeEmisor.js/traerInformacionAllEmisores";

//Hooks
import useValidateEmision from '../../../hooks/useValidateEmision';


export default function EmisoresParaActualizar({ history }) {
    const obj = {
        estatus: 3
    };
    const estatus = 3;

    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()

    React.useEffect(() => {
        traerEmisores()
    }, [])

    const traerEmisores = async () => {
        const usuarios = await traerInformacionAllEmisores(obj)
        //console.log(usuarios)
        if (usuarios) {
            setSpin(false)
            if ((isNaN(usuarios.error)) === false) {
              
                setErroreshttp(usuarios)
                
            } else {
                setData(usuarios)
                setCopia(usuarios)
            }
        }

    }


    const continuarOferta = (row) => {

        //VER SOLICITUD DE DEPOSITANTE DIRECTO // 
        const objetodirecto = {
            titulo: "Emisor",
            codigo: row.original.cehvalEmisorCodigo,//row.cells[2].value,
            estatus: estatus,
        }

        //mandar info depositante directo            
        history.push({
            pathname: '/actualizarEmisores', state: { objMenu: objetodirecto }
        })

    }


    return (
        <>
            <div className="fliud-container" >
               {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }    
                {/*}  <Header history={history} ></Header>*/}
                <main className='container-min-height'>
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Emisores para actualizar"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">

                                            {copia &&
                                                <TablaUsuarios datos={data} displayInformation={continuarOferta} useValidate={useValidateEmision} textoBoton={"Abrir"} textTitle="Click para ver la información del emisor"></TablaUsuarios>
                                            }

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}