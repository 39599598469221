import { instance } from '../apiGlobal';

export async function activarDesactivarMantenimiento (obj) {
      try {
        const response = await instance(`/mttofacturas/actdesact/registro`, {
          method:"post",
          data:obj
        })
        return response.data
      } catch (error) {
        return error
      }
}