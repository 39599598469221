import React, { useState } from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";

import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs

import { consultarSerieTasaVariable } from "../../../api/PagoInteresesAmorDiv/consultarSerieTasaVariable.js";
import { registrarTasaVariable } from "../../../api/PagoInteresesAmorDiv/registrarTasaVariable";


export default function RegistrarTasaAplicable({ history }) {


    const [registroExitoso, setRegistroExitoso] = React.useState()
    const [registroFallido, setRegistroFallido] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [mensaje, setMensaje] = React.useState()
    const [loading, setLoading] = React.useState(false);


    const [isin, setIsin] = useState()
    const [nombreSerie, setNombreSerie] = useState()
    const [nombreEmision, setNombreEmision] = useState()
    const [periodoRevisionTasaVariable, setPeriodoRevisionTasaVariable] = useState()
    const [periodicidad, setPeriodicidad] = useState()
    const [fechaPago, setFechaPago] = useState()
    const [tasaAplicada, setTasaAplicada] = useState()
    const [fechasDisponibles, setFechasDisponibles] = useState([])


    const traerDatosSerie = async (e) => {
        //console.log(e)

        if (e.length === 12) {
            setLoading(true)

            const enviarDatos = await consultarSerieTasaVariable({ isin: e })
            //console.log(enviarDatos)
            if (enviarDatos) {
                setLoading(false)
                if ((isNaN(enviarDatos.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(enviarDatos)
                } else {

                    if (enviarDatos?.codigo === "000") {
                        setNombreSerie(enviarDatos.codigoSerie)
                        setNombreEmision(enviarDatos.nombreEmision)
                        setFechasDisponibles(enviarDatos.fechasPago)
                        setPeriodicidad(enviarDatos.periodoPagoInteres)
                        setPeriodoRevisionTasaVariable(enviarDatos.periodoRevisionTasaVariable)

                    } else {
                        setMensaje(enviarDatos.mensaje + e)
                        setRegistroFallido(true)
                    }
                }
            }

        }

    }


    const onSubmitForm = async (e) => {
        e.preventDefault();
        setLoading(true)
        const obj = {
            isin: isin,
            fechaSeleccionTasaVariable: fechaPago,
            tasaAplicar: tasaAplicada
        }


        const enviarDatos = await registrarTasaVariable(obj)
        //console.log(enviarDatos)
        if (enviarDatos) {
            setLoading(false)
            if ((isNaN(enviarDatos.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(enviarDatos)
            } else {

                if (enviarDatos?.codigo === "000") {
                    setRegistroExitoso(true)
                    setMensaje(enviarDatos.mensaje)
                } else {
                    setMensaje(enviarDatos.mensaje)
                    setRegistroFallido(true)
                }
            }
        }

    }


    const irInicio = () => {
        setRegistroExitoso(false)
        setFechaPago("")
        setFechasDisponibles([])
        setIsin("")
        setNombreEmision("")
        setNombreSerie("")
        setPeriodicidad("")
        setPeriodoRevisionTasaVariable("")
        setTasaAplicada("")
    }

    return (
        <div className="fluid-container">
            {loading &&
                <PantallaCargando />
            }

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={irInicio} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={() => { setRegistroFallido(false) }} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            


            <main className="mb-5">
                <div className="row row-wrap g-0">
                    <div className="col-12">
                        <div className="">
                            <div className="row d-flex justify-content-center g-0">
                                <div className="col-12">
                                    <BannerTitulo titulo="Registrar tasa variable"></BannerTitulo>
                                    <div className="px-3 px-lg-4 px-xl-4 px-xxl-5 border-none">
                                        <div className="card-body ">
                                            <div className="col-12 mx-auto">
                                                <form className="row g-0 needs-validation" novalidate onSubmit={(e) => {
                                                    onSubmitForm(e)
                                                }}>
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="row mb-3">
                                                                <div className="col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" onChange={(e) => {
                                                                            setIsin(e.target.value)
                                                                            traerDatosSerie(e.target.value)
                                                                        }} value={isin} id="isin" name="isin" placeholder="" required  maxLength={13}/>
                                                                        <label htmlFor="isin" className=" textoFormularioDepositantedirecto">ISIN</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" value={nombreSerie} id="nombreSerie" name="nombreSerie"  required disabled />
                                                                        <label htmlFor="nombreSerie" className=" textoFormularioDepositantedirecto">Nombre serie</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-3">
                                                                <div className="col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" value={nombreEmision} id="nombreEmision" name="nombreEmision" required disabled />
                                                                        <label htmlFor="nombreEmision" className=" textoFormularioDepositantedirecto">Emisión</label>
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" value={periodoRevisionTasaVariable} id="periodoRevisionTasaVariable" name="periodoRevisionTasaVariable" required disabled />
                                                                        <label htmlFor="periodoRevisionTasaVariable" className=" textoFormularioDepositantedirecto">Periodo de revisión de la tasa variable</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" value={periodicidad} id="periodicidad" name="periodicidad" required disabled />
                                                                        <label htmlFor="periodicidad" className=" textoFormularioDepositantedirecto">Periodicidad de pago de intereses</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6 mb-3">

                                                                    <div className="form-floating">
                                                                        <select title="Fecha de pago" onChange={(e) => setFechaPago(e.target.value)} className="form-select font-acumin-variable" name="fechaPago" style={{ height: "56px" }} aria-label="Default select example" required>
                                                                            <option value=""> </option>
                                                                            {
                                                                                fechasDisponibles.map((item, index) => (
                                                                                    <option key={item} className="text-uppercase" value={item}>{item}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <label className="font-acumin-variable" htmlFor="puestoResponsable">Fecha de pago *</label>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" onChange={(e) => setTasaAplicada(e.target.value)} value={tasaAplicada} id="tasaAplicada" name="tasaAplicada" placeholder="" required />
                                                                        <label htmlFor="tasaAplicada" className=" textoFormularioDepositantedirecto">Tasa a aplicar *</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                <button type="submit" className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-1 background-royal-blue text-white bg-hover-gold">Registrar</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </div>
    );


}

