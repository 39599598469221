import React from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import TablaUsuarios from '../../../ComponentesGenerales/TablaUsuarios';

//Hooks
import useCuentaValoresReserva from "../../../hooks/useCuentaValoresReserva";

//APIs
import { traerCuentaValoresReserva } from "../../../api/CuentaValoresReservas/traerCuentaValoresReserva";


export default function CuentaValoresReserva({ history }) {
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState();
    const [erroreshttp, setErroreshttp] = React.useState()
    const [spin, setSpin] = React.useState(true)

    React.useEffect(() => {
        verCuentaValores()
    }, [])

    const verCuentaValores = async () => {
        // const respuesta = await traerOperaciones()


        const response = await traerCuentaValoresReserva()
        //console.log(response)

        ////console.log(typeof response.error)

        ////console.log(isNaN(response.error))

        if (response) {
            setSpin(false)
            if ((isNaN(response.error)) === false) {

              
                setErroreshttp(response)
                
            } else {
                // //console.log(respuesta)
                setData(response)
                setCopia(response)
            }

        }

    }


    return (
        <>
            <div className="fluid-container">
                

            {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }

                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 ">
                            <div className="">
                                <br></br>
                                <BannerTitulo titulo="Cuenta de valores en reserva"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart>
                                    :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaUsuarios datos={data} useValidate={useCuentaValoresReserva} ></TablaUsuarios>
                                            }
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    );
}



