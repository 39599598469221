import React from "react";
import { useHistory } from "react-router-dom";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { traerRoles } from '../../../api/catalogos/traerRoles';
import { traerPermisos } from "../../../api/Permisos/traerPermisos";
import { traerPermisosPorRol } from "../../../api/Permisos/traerPermisosPorRol";
import { agregarPermisosARolExistente } from "../../../api/Permisos/agregarPermisosARolExistente";

export default function AgregarPermisos() {
    const history = useHistory();
    const [roles, setRoles] = React.useState([])
    const [rol, setRol] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [permisos, setPermisos] = React.useState([])
    const [permisosPorRol, setPermisosPorRol] = React.useState()
    const [arregloPermisos, setArregloPermisos] = React.useState([])
    const [errorSinPermisos, setErrorSinPermisos] = React.useState()
    const [permisosRegistrados, setPermisosRegistrados] = React.useState()
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        traerCatalogoDeRoles()
    }, [])

    const traerCatalogoDeRoles = async () => {
        //traer Catalogos de roles
        const catalogosRoles = await traerRoles()
       // //console.log(catalogosRoles)
        if (catalogosRoles) {
            if ((isNaN(catalogosRoles.error)) === false) {
                // respuestaincorrecta     
                //se desplega un error como modal      
                setErroreshttp(catalogosRoles)
            } else {
                //respuesta correcta
            //    //console.log(catalogosRoles)
                setRoles(catalogosRoles)
            }
        }

        //traer catalogo de todos los roles
        const catalogoPermisos = await traerPermisos()
        ////console.log(catalogoPermisos)
    
        if (catalogoPermisos) {
            if ((isNaN(catalogoPermisos.error)) === false) {
                // respuestaincorrecta        
                setErroreshttp(catalogoPermisos)
            } else {
                //respuesta correcta
                // //console.log(catalogoPermisos)
                setPermisos(catalogoPermisos)

            }
        }
    }

    const onSubmitForm = async (e) => {
        e.preventDefault();
        //console.log(arregloPermisos.length)

        if (arregloPermisos.length > 0) {
            setLoading(true)
            setErrorSinPermisos(false)
            //console.log(arregloPermisos)
            //console.log(rol)

             const agregarPermisos = await agregarPermisosARolExistente({
                   idRol: rol,
                   listPermisos: arregloPermisos
               })
  
               if (agregarPermisos) {
                setLoading(false)
                   if ((isNaN(agregarPermisos.error)) === false) {
                       // respuestaincorrecta        
                       setErroreshttp(agregarPermisos)
                   } else {
                       //respuesta correcta
                       //console.log("exito")
                       setLoading(false)
                       setPermisosRegistrados(agregarPermisos)
                       //setPermisos(catalogoPermisos)
                   }
               }
        } else {
            setErrorSinPermisos(true)
        }

    }

    const traerPermisosDelRol = async (e) => {
        setRol(e)
     

        let arregloPivote = []
        const catalologoPermisosPorRol = await traerPermisosPorRol({ idRol: e })
        //console.log(catalologoPermisosPorRol)
        if (catalologoPermisosPorRol) {
            setLoading(false)
            if ((isNaN(catalologoPermisosPorRol.error)) === false) {
                // respuestaincorrecta     
                //se desplega un error como modal      
                setErroreshttp(catalologoPermisosPorRol)
            } else {
                //respuesta correcta
              //  //console.log(catalologoPermisosPorRol)
                catalologoPermisosPorRol.map((item, index) => {
                    arregloPivote.push(item.nombre)
                })
                //console.log(arregloPivote)
                setPermisosPorRol(arregloPivote)
            }
        }
    }

    const cerrarModal = () => {
     
        history.go(-1)
    }


    const guardarArreglo = (numero, id) => {
        let arregloPivote = arregloPermisos
        if (document.getElementById(id).checked) {
            setErrorSinPermisos(false)
            arregloPivote.push(parseInt(numero, 10))
        } else {
            arregloPivote = arregloPermisos.filter((item) => item !== parseInt(numero, 10))
        }
        //console.log(arregloPivote)
        setArregloPermisos(arregloPivote)
    }

    const checkboxFuncion = (id, grupo) => {
        let arregloPivote = arregloPermisos

        if (document.getElementById(grupo).checked) {
            document.querySelectorAll(`#${id} input[type=checkbox]`).forEach(function (checkElement) {
                checkElement.checked = true;
                checkElement.disabled = true;
                arregloPivote.push(parseInt(checkElement.value, 10))
                setErrorSinPermisos(false)
            });
        } else {
            document.querySelectorAll(`#${id} input[type=checkbox]`).forEach(function (checkElement) {
                checkElement.checked = false;
                checkElement.disabled = false;
                ////console.log(parseInt(checkElement.value, 10))
                arregloPivote = arregloPivote.filter((item) => item !== parseInt(checkElement.value, 10))
            });
        }
        setArregloPermisos(arregloPivote)
    }

    return (
        <>
            {loading &&
                        <PantallaCargando />
   }
            {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }

            {permisosRegistrados &&
                <ComponenteModal mostrarModal={permisosRegistrados} cerrarModal={cerrarModal} titulo="Los permisos se agregarón con éxito" textoBoton="Entendido"></ComponenteModal>
            }


            <div className="fluid-container">
                <main className="min-height-40">
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Agregar permisos a rol existente"></BannerTitulo>
                                <div className="row mt-3 text-royal-blue g-0" >
                                    <div className="col-lg-12 col-md-12 ">
                                        <div className="container  ">
                                            <div className="row mb-3 justify-content-center">
                                                <div className="col-lg-10 col-md-11 col-sm-12 col-xs-12">
                                                    <div className=" card " style={{ color: "#242848" }}>
                                                        <div className="card-body font-acumin-variable">
                                                            <form onSubmit={(e) => onSubmitForm(e)}>
                                                                <h6 className="font-acumin-variable text-royal-blue">* Estos campos son obligatorios</h6>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-12">
                                                                        <div className="form-floating ">
                                                                            <select className="form-select" onChange={(event) => {
                                                                                traerPermisosDelRol(event.target.value)
                                                                                setLoading(true)
                                                                            }} id="rol" name="rol" required>
                                                                                <option value=""> </option>
                                                                                {
                                                                                    roles.map((item, index) => (
                                                                                        <option key={index} value={item.cehvalRolId} >{item.cehvalRolNombre}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                            <label htmlFor="contraseña">Rol *</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {rol && permisosPorRol &&
                                                                    <>
                                                                        <h4>Agrega los nuevos permisos</h4>
                                                                        {
                                                                            permisos.map((item1, index1) => (
                                                                                <div className="card mt-2">
                                                                                    <div className="row mb-3">
                                                                                        <div className="col-lg-6">
                                                                                            <div class="form-check ms-3 mt-2">
                                                                                                <input class="form-check-input" type="checkbox" onChange={() => checkboxFuncion(`formulario${index1}`, item1.grupo)} value="" id={item1.grupo} />
                                                                                                <label class="form-check-label fw-bold" for={item1.grupo}>
                                                                                                    {item1.grupo}
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        permisos[index1].permisoModelo.map((item, index) => (
                                                                                            <>
                                                                                                {permisosPorRol.includes(item.cehvalPermisoNombre) === false &&
                                                                                                    <form name="f1" id={`formulario${index1}`}>
                                                                                                        {permisosPorRol.includes(item.cehvalPermisoNombre)}
                                                                                                        <div className="ms-3">
                                                                                                            <div className="row">
                                                                                                                <div className="col-lg-6">
                                                                                                                    <div class="form-check ms-3">
                                                                                                                        <input class="form-check-input" value={item.cehvalPermisoId} onChange={(e) => guardarArreglo(e.target.value, item.cehvalPermisoNombre)} type="checkbox" id={item.cehvalPermisoNombre} />
                                                                                                                        <label class="form-check-label" for={item.cehvalPermisoNombre}>
                                                                                                                            {item.cehvalPermisoNombre}
                                                                                                                        </label>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-lg-6">
                                                                                                                    <p>{item.cehvalPermisoDescripcion}</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </form>
                                                                                                }
                                                                                            </>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            ))
                                                                        }

                                                                    </>
                                                                }

                                                                {errorSinPermisos &&
                                                                    <>
                                                                        <p className="error mt-2">Tiene que seleccionar al menos un permiso</p>
                                                                    </>
                                                                }

                                                                <div className="row  mt-4">
                                                                    <div className="col-md-5 ">
                                                                        <button className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable" type="submit">Agregar</button>
                                                                    </div>
                                                                </div>

                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

