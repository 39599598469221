import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";


//Components

import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";


//APIs

import { notificarDepositosFondosPDP } from "../../../api/PagoInteresesAmorDiv/notificarDepositosFondosPDP";

//Functions
import formatNumber from "../../../functions/FormatNumber";

export default function NotificarDepositoFondosPDP({ history }) {

    const [erroreshttp, setErroreshttp] = useState();
    




    //Inputs
    const [folio, setFolio] = useState();
    const [monto, setMonto] = useState();
    const [montoFront, setMontoFront] = useState();
    const [datos, setDatos] = useState([]);

    //Errors and messages
    const [procesoExitoso, setProcesoExitoso] = useState();
    const [procesoFallido, setProcesoFallido] = useState();
    const [sesionCaducada, setSesionCaducada] = useState();
    const [mensaje, setMensaje] = useState();
    const [loading, setLoading] = useState();
    const [dobleConfirmacion, setDobleConfirmacion] = useState();
    const [fechaDeposito, setFechaDeposito] = React.useState()



    useEffect(() => {
        traerDatos();
    }, []);

    const traerDatos = async () => {
        if (history.location.state === undefined) {
            history.go(-1)
        } else {
            setDatos(history.location.state.objMenu)
        }
    }

    const onSubmitForm = async (e) => {
        e.preventDefault();
        setDobleConfirmacion(true)

    }

    const notificar = async (e) => {
        setDobleConfirmacion(false)
        const objetoUsuario = {
            codigoId: datos.codigoId,
            montoDepositado: monto,
            fechaDeposito: fechaDeposito,
            folioDeposito: folio
        }
        //console.log(objetoUsuario);
        setLoading(true)


        const response = await notificarDepositosFondosPDP(objetoUsuario);
        setLoading(false)
        if (response) {
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(response);
            } else {
                if (response.isSuccessful) {
                    setProcesoExitoso(true);
                    setMensaje("Se realizó la notificación de depósito de fondos");

                } else {
                    setProcesoFallido(true);
                    setMensaje(response.mensaje);
                }
            }
        }

    }


    const handleInputMonto = (e) => {

        const { value } = e.target;

        const formattedNumber = formatNumber(value);

        //Setted the amount for the backend
        setMonto(formattedNumber.integerNumber);
        setMontoFront(formattedNumber.stringNumber);

    }



    const cerrarModal = () => {
        setProcesoExitoso(false);
        setSesionCaducada(false);
        setFolio("");
        setMonto("")
        setMontoFront("")
        setFechaDeposito("")
        history.go(-1)
    }

    const onHideModal = () => {
    }


    return (
        <>
            <Modal show={dobleConfirmacion} animation={false} centered onHide={onHideModal}>
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => setDobleConfirmacion(false)} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >¿Esta seguro de realizar la notificación del depósito de {montoFront} {datos.moneda} para el {datos.tipoPago} de la serie con ISIN {datos.isin} correspondiente a la fecha {datos.fechaPago}?</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => setDobleConfirmacion(false)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Cancelar</button>
                        <button onClick={notificar} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Aceptar</button>
                    </div>
                </div>
            </Modal>

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {procesoExitoso &&
                <ComponenteModal mostrarModal={procesoExitoso} cerrarModal={cerrarModal} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {procesoFallido &&
                <ComponenteModal mostrarModal={procesoFallido} cerrarModal={() => setProcesoFallido(false)} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {sesionCaducada &&
                <ComponenteModal mostrarModal={sesionCaducada} cerrarModal={cerrarModal} titulo="Ups! Al parecer la sesión ha caducado. Vuelva a iniciar sesión o revise su conexión a internet" textoBoton="Entendido"></ComponenteModal>
            }

            {loading &&
                <PantallaCargando></PantallaCargando>
            }

            

            <div className="fluid-container">
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 mb-5 main-min-height">
                            <div className="">
                                <BannerTitulo titulo="Notificar depósito de fondos en BCH-TR a la CEHVAL"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <section className="d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded">
                                                <div className="col-12">
                                                    <form onSubmit={onSubmitForm} className="needs-validation font-acumin-variable text-royal-blue">
                                                        <div className="row mt-4">
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Folio BCH-TR" className="form-control fs-5" onChange={(e) => setFolio(e.target.value)} value={folio} maxLength={25} name="folioBCH" type="text" placeholder="Folio BCH-TR" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="folioBCH">Folio BCH-TR *</label>
                                                                </div>
                                                            </div>


                                                            <div className="col-12 col-md-6 form-floating mb-3">
                                                                <div className="form-floating">
                                                                    <input title="ISIN" type="text" className="form-control border" value={datos.isin} name="ISIN" required disabled />
                                                                    <label htmlFor="nombreDepositante" className="form-label ">ISIN</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6 form-floating mb-3">
                                                                <div className="form-floating">
                                                                    <input title="Fecha de pago" type="text" className="form-control border" value={datos.fechaPago} name="fechaPago" required disabled />
                                                                    <label htmlFor="fechaPago" className="form-label ">Fecha de pago</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6 form-floating mb-3">
                                                                <div className="form-floating">
                                                                    <input title="Serie" type="text" className="form-control border" value={datos.nombreSerie} name="serie" required disabled />
                                                                    <label htmlFor="serie" className="form-label ">Serie</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6 form-floating mb-3">
                                                                <div className="form-floating">
                                                                    <input title="Moneda" type="text" className="form-control border" value={datos.moneda} name="moneda" required disabled />
                                                                    <label htmlFor="moneda" className="form-label ">Moneda</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6 form-floating mb-3">
                                                                <div className="form-floating">
                                                                    <input title="Monto total a pagar" type="text" className="form-control border" value={datos.montoTotalStr} name="montoTotalAPagar" required disabled />
                                                                    <label htmlFor="montoTotalAPagar" className="form-label ">Monto total a pagar</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6 form-floating mb-3">
                                                                <div className="form-floating">
                                                                    <input title="Fecha de notificación del depósito" type="text" className="form-control border" value={datos.fechaDeNotificacion} name="fechaNotificacionDeposito" required disabled />
                                                                    <label htmlFor="fechaNotificacionDeposito" className="form-label ">Fecha de notificación del depósito</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6 form-floating mb-3">
                                                                <div className="form-floating">
                                                                    <input title="Tipo de pago" type="text" className="form-control border" value={datos.tipoPago} name="tipoPago" required disabled />
                                                                    <label htmlFor="tipoPago" className="form-label ">Tipo de pago</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Monto del depósito" className="form-control fs-5" onInput={(e) => handleInputMonto(e)} value={montoFront} maxLength={20} name="monto" type="text" placeholder="Monto" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="monto">Monto depositado *</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Fecha en la que se realizó el deposito" className="form-control text-uppercase" type="date" value={fechaDeposito} onChange={(event) => setFechaDeposito(event.target.value)} name="fechaEmision" placeholder="Fecha de emisión" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Fecha de depósito *</label>
                                                                </div>
                                                            </div>


                                                            <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                <button type="submit" className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-1 background-royal-blue text-white bg-hover-gold">Notificar</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

