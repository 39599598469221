import React from "react";

//Components
import BannerTitulo from "../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../ComponentesGenerales/PantallaCargando";

//APIs
import { traerRoles } from "../../api/catalogos/traerRoles";
import { crearUsuario } from "../../api/usuarios/crearUsuario";

export default function RegistroManual({ history }) {
  const [correo, setCorreo] = React.useState();
  const [telefono, setTelefono] = React.useState("");
  const [errorTelefono, setErrorTelefono] = React.useState();
  const [errorUsuarioExistente, setErrorUsuarioExistente] = React.useState();
  const [roles, setRoles] = React.useState([]);
  const [rol, setRol] = React.useState();
  const [usuarioRegistrado, setUsuarioRegistrado] = React.useState();
  const [usuarioNoRegistrado, setUsuarioNoRegistrado] = React.useState();
  const [nombre, setNombre] = React.useState("");
  const [segundoNombre, setSegundoNombre] = React.useState("");
  const [primerApellido, setPrimerApellido] = React.useState("");
  const [segundoApellido, setSegundoApellido] = React.useState("");
  const [erroreshttp, setErroreshttp] = React.useState();
  const [mensaje, setMensaje] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  //**Inicia variables necesarias de AWS */
  var AWS = require("aws-sdk");

  // Set the AWS Region.
  AWS.config.update({

    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey
  });
  // Initialize CogntioIdentityServiceProvider SDK.
  var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
  //**Termina variables necesarias de AWS */

  React.useEffect(() => {
    traerDatos();
  }, []);

  const traerDatos = async () => {
    //traer Catalogos de roles
    const rolin = await traerRoles();
    if (rolin) {
      if (isNaN(rolin.error) === false) {
        // respuestaincorrecta
        //se desplega un error como modal
        setErroreshttp(rolin);
      } else {
        //respuesta correcta
        console.log(rolin)
        const rolesfiltrados = rolin.filter(word => (word.cehvalRolNombre!=="OPERADOR_DD"&&word.cehvalRolNombre!=="ADMINISTRADOR_DD"&&word.cehvalRolNombre!=="CONSULTOR_DI"&&word.cehvalRolNombre!=="CONSULTOR_EMISOR"));
        //console.log(rolesfiltrados)
        setRoles(rolesfiltrados);
      }
    }
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    setLoading(true);

    //registrar usuario en la base
    const objetoUsuario = {
      // cehvalUsuarioUsuario: correo.replace(/[@.]/g, '|'),
      cehvalUsuarioCorreo: correo,
      cehvalUsuarioEstado: 1,
      cehvalUsuarioDescripcion: "bienvenido",
      cehvalUsuarioTipo: "",
      rol: rol,
      cehvalUsuarioNombreUsuario: nombre,
      cehvalUsuarioSegundoNombreUsuario: segundoNombre,
      cehvalUsuarioPrimerApellido: primerApellido,
      cehvalUsuarioSegundoApellido: segundoApellido
    };

    //console.log(objetoUsuario);

    const respuestaUsuario = await crearUsuario(objetoUsuario);
    //console.log(respuestaUsuario);
    if (respuestaUsuario) {
      setLoading(false);
      //entra a qui si hay un error http
      if (isNaN(respuestaUsuario.error) === false) {
        // respuestaincorrecta
        setErroreshttp(respuestaUsuario);
      } else {
        //respuesta correcta
        //  setLoading(true)
        if (respuestaUsuario?.codigo === "000") {
          ////console.log(respuestaUsuario.mensaje)
          guardarEnCognito(respuestaUsuario.mensaje);
        } else {
          setMensaje(respuestaUsuario.mensaje);
          setLoading(false);
          setErrorUsuarioExistente(true);
        }
      }
    }
  };

  const guardarEnCognito = async (nombreUsuario) => {
    //registar usuairo en cognito
    let params = {
      UserPoolId: process.env.REACT_APP_UserPoolId,
      //Username: correo.replace(/[@.]/g, '|'),
      Username: nombreUsuario,
      DesiredDeliveryMediums: ["EMAIL"],

      //TemporaryPassword: password(4),
      UserAttributes: [
        {
          Name: "email",
          Value: correo
        },
        {
          Name: "name",
          Value: nombre
        },
        {
          Name: "phone_number",
          Value: telefono
        },

        {
          Name: "email_verified",
          Value: "true"
        }
      ]
    };
    cognitoidentityserviceprovider.adminCreateUser(
      params,
      function (err, data) {
        if (err) {
          //console.log(err.message); // an error occurred
          setLoading(false);
          if (err.message.indexOf("Invalid phone number format") >= 0) {
            ////console.log("tel")
            setErrorTelefono(true);
          } else {
            setErrorTelefono(false);
          }

          if (err.message.indexOf("User account already exists") >= 0) {
            // //console.log("entra")
            setMensaje("Este usuario ya existe");
            setErrorUsuarioExistente(true);
          } else {
            setErrorUsuarioExistente(false);
          }
        } else {
          agregarGrupo(nombreUsuario);
        }
      }
    );
  };

  const agregarGrupo = async (nombreUsuario) => {
    const params = {
      GroupName: rol,
      UserPoolId: process.env.REACT_APP_UserPoolId,
      Username: nombreUsuario
    };

    cognitoidentityserviceprovider.adminAddUserToGroup(
      params,
      function (err, data) {
        if (err) {
          //console.log(err, err.stack); // an error occurred
          setLoading(false);
        } else {
          setLoading(false);
          setUsuarioRegistrado(true);
        } // successful response
      }
    );
  };

  const cerrarModal = () => {
    setUsuarioRegistrado(false);
    setCorreo("");
    setTelefono("");
    setNombre("");
    setRol("");
    setSegundoApellido("");
    setPrimerApellido("");
    setSegundoNombre("");
  };

  return (
    <>
      {erroreshttp && (
        <ManejadorErrores
          response={erroreshttp}
          mostrarModal={erroreshttp}
          funcionCierre={() => {
            setErroreshttp(false);
          }}
        ></ManejadorErrores>
      )}

      {loading && <PantallaCargando />}

      {errorUsuarioExistente && (
        <ComponenteModal
          mostrarModal={errorUsuarioExistente}
          cerrarModal={() => {
            setErrorUsuarioExistente(false);
          }}
          titulo={mensaje}
          textoBoton="Entendido"
        ></ComponenteModal>
      )}

      {usuarioNoRegistrado && (
        <ComponenteModal
          mostrarModal={usuarioNoRegistrado}
          cerrarModal={() => {
            setUsuarioNoRegistrado(false);
          }}
          titulo="No se pudo completar el registro"
          textoBoton="Entendido"
        ></ComponenteModal>
      )}

      {usuarioRegistrado && (
        <ComponenteModal
          mostrarModal={usuarioRegistrado}
          cerrarModal={cerrarModal}
          titulo="Se registro con éxito a este usuario"
          textoBoton="Entendido"
        ></ComponenteModal>
      )}

      <div className="fluid-container">
        {/* style={{ backgroundImage: `url(${fondo})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="fluid-container">*/}

        <main className="main-min-height mb-5">
          <div className="row row-wrap g-0">
            <div className="col-12">
              <div className="">
                <BannerTitulo titulo="Registrar usuarios"></BannerTitulo>
                <div className="row mt-3 text-royal-blue">
                  <div className="col-lg-12 col-md-12 ">
                    <div className="container  ">
                      <div className="row mb-3 justify-content-center">
                        <div className="col-12 col-sm-12 col-md-10 col-lg-8">
                          <div className=" card " style={{ color: "#242848" }}>
                            <div className="card-body font-acumin-variable">
                              {/*<h3 className="text-center">Registro</h3>*/}
                              <form onSubmit={(e) => onSubmitForm(e)}>
                                <h6 className="font-acumin-variable text-royal-blue">
                                  * Estos campos son obligatorios
                                </h6>
                                <div className="row mb-3">
                                  <div className="col-md-6 col-sm-12 mb-2">
                                    <div className="form-floating">
                                      <input
                                        type="email"
                                        id="email"
                                        className="form-control"
                                        placeholder="name@example.com"
                                        value={correo}
                                        onChange={(event) =>
                                          setCorreo(
                                            event.target.value.toLowerCase()
                                          )
                                        }
                                        required
                                      ></input>
                                      <label htmlFor="email">Correo *</label>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-12 ">
                                    <div className="form-floating ">
                                      <input
                                        type="text"
                                        id="nombre"
                                        className="form-control"
                                        placeholder="name@example.com"
                                        value={nombre}
                                        onChange={(event) =>
                                          setNombre(event.target.value)
                                        }
                                        required
                                      ></input>
                                      <label htmlFor="nombre">
                                        Primer nombre *
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-md-6 col-sm-12 mb-2">
                                    <div className="form-floating">
                                      <input
                                        type="texto"
                                        id="segundoNombre"
                                        className="form-control"
                                        placeholder=""
                                        value={segundoNombre}
                                        onChange={(event) =>
                                          setSegundoNombre(event.target.value)
                                        }
                                      ></input>
                                      <label htmlFor="segundoNombre">
                                        Segundo nombre{" "}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-12">
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        id="primerApellido"
                                        className="form-control"
                                        placeholder=""
                                        value={primerApellido}
                                        onChange={(event) =>
                                          setPrimerApellido(event.target.value)
                                        }
                                        required
                                      ></input>
                                      <label htmlFor="primerApellido">
                                        Primer apellido *
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                <div className="row ">
                                  <div className="col-md-6 col-sm-12 mb-2">
                                    <div className="form-floating">
                                      <input
                                        type="text"
                                        id="segundoApellido"
                                        className="form-control"
                                        placeholder=""
                                        value={segundoApellido}
                                        onChange={(event) =>
                                          setSegundoApellido(event.target.value)
                                        }
                                      ></input>
                                      <label htmlFor="segundoApellido">
                                        Segundo Apellido{" "}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-12">
                                    <div className="form-floating ">
                                      <input
                                        type="phone"
                                        id="telefono"
                                        className="form-control"
                                        placeholder="Telefono"
                                        value={telefono}
                                        onChange={(event) =>
                                          setTelefono(event.target.value)
                                        }
                                      ></input>
                                      <label htmlFor="telefono">
                                        Teléfono (opcional)
                                      </label>
                                      {errorTelefono && (
                                        <>
                                          <p className="error">
                                            Formato de teléfono inválido,
                                            ejemplo: +5215561245653
                                          </p>
                                        </>
                                      )}
                                      <div
                                        id="emailHelp"
                                        className="form-text ps-3"
                                      >
                                        <p style={{ fontSize: ".8rem" }}>
                                          Ejemplo: +52152345678
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row mb-3">
                                  <div className="col-md-12">
                                    <div className="form-floating ">
                                      <select
                                        className="form-select"
                                        value={rol}
                                        onChange={(event) =>
                                          setRol(event.target.value)
                                        }
                                        id="municipio"
                                        name="municipio"
                                        required
                                      >
                                        <option value=""> </option>
                                        {roles.map((item, index) => (
                                          <option
                                            key={index}
                                            value={item.cehvalRolNombre}
                                          >
                                            {item.cehvalRolNombre}
                                          </option>
                                        ))}
                                      </select>
                                      <label htmlFor="contraseña">Rol *</label>
                                    </div>
                                  </div>
                                </div>

                                <div className="row justify-content-end mt-4">
                                  <div className="col-auto">
                                    <button
                                      className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable"
                                      type="submit"
                                    >
                                      Registrar
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
