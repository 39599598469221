import { instance } from '../apiGlobal';

  export async function traerTipoInstrumentoFinanciero (obj) {
    try {
      const response = await instance.get(`/isin/consulta/instrumentos`, {
        headers: {
          'Content-Type': 'application/json'          
        }
      })
      return response;
    } catch (error) {
      return error
    }
}
  