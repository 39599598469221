import { instance } from '../../../apiGlobal';

  export async function traerSolAvisosCobroNoPeriodicos(obj) {
    try {
      const response = await instance.get(`/tarificacion/mostrar/avisoscobro/np/autorizar`, {
        headers: {
          'Content-Type': 'application/json',        
        }
      })
      return response;
    } catch (error) {
      return error
    }
}
  