import { instance } from '../apiGlobal';

export async function traerBancos() {
  try {
    const response = await instance.get(`/cat/bancos`, {
      headers: {
        'Content-Type': 'application/json'
        
      }
    })
    return response.data
  } catch (error) {
    return error
  }
}



  