//React
import React, { useState, useEffect } from "react";

//AWS
//import { Auth, input } from 'aws-amplify';

//Componentes generales
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores';
import FormGenerarConceptos from "./FormGenerarConceptos";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";

//APIs
import { buscarParticipante } from "../../../../api/tarificacion/ConceptosNoPeriodicos/generarCobrosNoPeriodicos/buscarParticipante";
import { taerTarificacionConceptosManuales } from "../../../../api/catalogos/taerTarificacionConceptosManuales";


//Hooks


export default function GenerarCobrosNoPeriodicos({ history }) {


    //Inputs where user will enter a value
    const [formState, setFormState] = useState({
        codigoDepositanteEmisor: "",
        cantidadConceptos: "",
    });

    const [codigoParticipante, setCodigoParticipante] = useState();
    const [nombreDepositante, setNombreDepositante] = useState();
    const [tipoParticipante, setTipoParticipante] = useState();

    //State stores the number of inputs the user want to create
    const [numberCreateInputs, setNumberCreateInputs] = useState();

    //Conceptos manuales
    const [conceptosManuales, setConceptosManuales] = useState();


    //show messages
    const [maxNumberInputs, setMaxNumberInputs] = useState();
    const [depositanteValido, setDepositanteValido] = useState();
    const [depositanteNoExiste, setDepositanteNoExiste] = useState();


    //Spinner
    const [spin, setSpin] = useState(true);
    const [loading, setLoading] = useState();

    //States to handle load data when loading page 
    const [erroreshttp, setErroreshttp] = useState();
    const [error, setError] = useState();


    //Shows modal if the session has expired
    //const [failLoadData, setFailLoadData] = useState();



    useEffect(() => {
        getConceptosCobro();
    }, []);


    //Gets all depositors that are in fondo de liquidacion
    const getConceptosCobro = async () => {
        try {
            setSpin(true);
            const response = await taerTarificacionConceptosManuales();
            setSpin(false);
            if (isNaN(response.error) === false) {
                setErroreshttp(response);

            } else if (response) {
                setConceptosManuales(response);
            }

        } catch (err) {
            console.error(err);
        }
    }




    //Set estates for the inputs above the table
    const handleInputs = (event) => {

        if (event.target.name === "cantidadConceptos") {

            //If user type a number bigger than 24 set state to "" and show an error message
            if (event.target.value > 24) {
                setMaxNumberInputs("El número máximo de conceptos es 24");
                setFormState({ ...formState, [event.target.name]: "24" });

                //If user type a valid number just set the number to our state
            } else {
                setMaxNumberInputs(false);
                setFormState({ ...formState, [event.target.name]: event.target.value });
            }

            //set value to input "Código del depositante/emisor"
        } else {
            //Set value the user enter
            setFormState({ ...formState, [event.target.name]: event.target.value });

            //Clean inputs if user delete a character if already was searched a depositante
            setCodigoParticipante(null);
            setNombreDepositante(null);
            setDepositanteNoExiste(false);
            setDepositanteValido(false);
        }
    };


    //consult the participant code in the database
    const getParticipante = async (e) => {
        e.preventDefault();
        try {

            setLoading(true);
            const response = await buscarParticipante({ codigoDepositante: formState.codigoDepositanteEmisor });
            setLoading(false);


            if (isNaN(response.error) === false) {
                setErroreshttp(response);

            } else if (response.data.codigoDepositante === null) {
                setDepositanteNoExiste(true);

            } else if (response.data) {
                setDepositanteValido(true);
                setNombreDepositante(response.data.nombreDepositante);
                setCodigoParticipante(response.data.codigoDepositante);
                setTipoParticipante(response.data.tipoParticipante);
            }

        } catch (error) {
            console.error(error);
        }
    }



    //Create the inputs according the number entered by the user
    const createTableInputs = () => {
        const numberInputs = formState.cantidadConceptos;
        if (numberInputs > 0) {
            setNumberCreateInputs(numberInputs);
        } else {
            setNumberCreateInputs(false);
        }
    }





    return (
        <>
            {loading &&
                <PantallaCargando />
            }

            {error &&
                <ComponenteModal mostrarModal={error} cerrarModal={() => setError(false)} titulo={error} textoBoton="Aceptar"></ComponenteModal>
            }

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }


            <div className="">



                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :

                    <>
                        {conceptosManuales &&
                            <main className="min-height-40">
                                <div className="row row-wrap g-0">
                                    <div className="col-12 mb-5">
                                        <div className="">
                                            <BannerTitulo titulo="Generar monto a cobrar de conceptos no periódicos"></BannerTitulo>
                                            <div className="d-flex px-3">
                                                <div className="needs-validation font-acumin-variable text-royal-blue container border rounded">
                                                    <section className="d-flex justify-content-center mb-4 px-4">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 row justify-content-center">
                                                            <div className="p-0">
                                                                <div className="needs-validation font-acumin-variable text-royal-blue">
                                                                    <form onSubmit={getParticipante}>
                                                                        <div className="row mt-4">
                                                                            <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input title="Código del depositante/emisor" onChange={(e) => handleInputs(e)} value={formState.codigoDepositanteEmisor.toUpperCase()} className="form-control fs-5" type="text" name="codigoDepositanteEmisor" placeholder="Código del depositante/emisor" required />
                                                                                    <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoDepositanteEmisor">Código del depositante/emisor</label>
                                                                                    {depositanteNoExiste &&
                                                                                        <p className="text-danger m-0 mt-1">El código del participante no existe</p>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-10 d-flex align-items-center">
                                                                                <button className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable" type="submit">Consultar</button>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>

                                                    {depositanteValido &&
                                                        <section className="d-flex justify-content-center mb-4 px-4">
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 row justify-content-center">
                                                                <div className="p-0">
                                                                    <div className="needs-validation font-acumin-variable text-royal-blue">
                                                                        <form>
                                                                            <div className="row mt-4">
                                                                                <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                                                    <div className="form-floating">
                                                                                        <input title="Nombre del depositante/emisor" value={nombreDepositante} className="form-control fs-5" type="text" name="nombreDepositanteEmisor" placeholder="Nombre del depositante/emisor" required disabled />
                                                                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="nombreDepositanteEmisor">Nombre del depositante/emisor</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                                                    <div className="form-floating">
                                                                                        <input title="Cantidad de conceptos a ingresar" onChange={(e) => handleInputs(e)} onKeyUp={createTableInputs} maxLength={2} value={formState.cantidadConceptos} className="form-control fs-5" type="text" name="cantidadConceptos" placeholder="Cantidad de conceptos a ingresar" required />
                                                                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="cantidadConceptos">Cantidad de conceptos a ingresar</label>
                                                                                        {maxNumberInputs &&
                                                                                            <p className="text-danger mt-2">{maxNumberInputs}</p>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                {numberCreateInputs &&
                                                                                    <FormGenerarConceptos
                                                                                        catConceptos={conceptosManuales}
                                                                                        numberOfConcepts={numberCreateInputs}
                                                                                        codigoParticipante={codigoParticipante}
                                                                                        nombreParticipante={nombreDepositante}
                                                                                        tipoParticipante={tipoParticipante}
                                                                                    >
                                                                                    </FormGenerarConceptos>
                                                                                }
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </main >
                        }
                    </>
                }
            </div >
        </>
    );
}





