import { instanciaRegistroPDF } from '../../apiGlobal';

export async function envioPDFLiberacion(obj) {
      try {
        const response = await instanciaRegistroPDF(`prendasgarantias/cargar/solicitudliberacion`, {
          method:"post",
          data:obj
        })
        return response;
      } catch (error) {
        return error
      }
}
  

