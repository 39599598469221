import React from "react";
import '../../../../styles/stylesheet.css'
import { FormContext } from './form_context';

import { TraerActividadEconomica } from "../../../../api/catalogos/traerActividadEconomica";
import { traerNacionalidad } from "../../../../api/catalogos/traerNacionalidad";
import { traerEstadosCiviles } from "../../../../api/catalogos/traerEstadosCiviles";
import { traerGeneros } from "../../../../api/catalogos/traerGeneros";
import { traerTiposIdentificacion } from "../../../../api/catalogos/traerTiposIdentificacion";

import { insertarActividadEconomica } from "../../../../api/InsertarACatalogos/insertarActividadEconomica";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";
import SpinnerSend from "../../../../ComponentesGenerales/SpinnerSend";

export default function RegistroDI1({ history }) {

    const [value, setValue] = React.useContext(FormContext);

    const [actividadEconomica, setActividadEconomica] = React.useState([])
    const [nacionalidad, setNacionalidad] = React.useState([])
    const [estadosCiviles, setEstadosCiviles] = React.useState([])
    const [generos, setGeneros] = React.useState([])
    const [tiposIdentificacion, setTiposIdentificaion] = React.useState([])

    const [fechaLimiteNacimiento, setFechaLimiteNacimiento] = React.useState()


    const [agregarActividadEconomica, setAgregarActividadEconomica] = React.useState()
    const [actividadNueva, setActividadNueva] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [spinSend, setSpinSend] = React.useState(false)

    const guardarDatos = (e) => {
        const { name, value } = e.target;
        if (name === "actividadEconomica") {
            if(value==="OTRO"){
                setAgregarActividadEconomica(true)
            }else{
                setValue((prev) => {
                    const datos = { ...prev.datos, [name]: value };
                    return { ...prev, datos };
                });
                setAgregarActividadEconomica(false)
            }
          
        }else{
            setValue((prev) => {
                const datos = { ...prev.datos, [name]: value };
                return { ...prev, datos };
            });
        }
    }

    React.useEffect(() => {
        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 0 };
            return { ...prev, datos };
        });  
        
        establecerFecha()
        traerDatos()
    }, [])

    const establecerFecha = () => {
        const fecha = new Date()
        const añoActual = fecha.getFullYear();
        let diaActual = fecha.getDate();
        let mesActual = fecha.getMonth() + 1;

        if (diaActual < 10) {
            diaActual = "0" + diaActual
        }

        if (mesActual < 10) {
            mesActual = "0" + mesActual
        }

        const fecha_limite_cumpleaños = (añoActual - 18) + "-" + mesActual + "-" + diaActual

        setFechaLimiteNacimiento(fecha_limite_cumpleaños)
       // setFechaLimiteDocumentos(fecha_limite_documentos)
    }

    const traerDatos = async () => {

        const catalogoActividadEconomica = await TraerActividadEconomica()
        if (catalogoActividadEconomica) {
            if ((isNaN(catalogoActividadEconomica.error)) === false) {
                setErroreshttp(catalogoActividadEconomica)
            } else {
                setActividadEconomica(catalogoActividadEconomica)
            }
        }

        const catalogoNacionalidad = await traerNacionalidad()
        if (catalogoNacionalidad) {
            if ((isNaN(catalogoNacionalidad.error)) === false) {
                setErroreshttp(catalogoNacionalidad)
            } else {
                setNacionalidad(catalogoNacionalidad)

            }
        }

        const catalogoEstadosCiviles = await traerEstadosCiviles()
        if (catalogoEstadosCiviles) {
            if ((isNaN(catalogoEstadosCiviles.error)) === false) {
                setErroreshttp(catalogoEstadosCiviles)
            } else {
                setEstadosCiviles(catalogoEstadosCiviles)

            }
        }

        const catalogoGeneros = await traerGeneros()
        if (catalogoGeneros) {
            //setSpin(false)
            if ((isNaN(catalogoGeneros.error)) === false) {
                setErroreshttp(catalogoGeneros)
            } else {
                setGeneros(catalogoGeneros)
            }
        }

        const catalogoTiposIdentificacion = await traerTiposIdentificacion()
        if (catalogoTiposIdentificacion) {
            if ((isNaN(catalogoTiposIdentificacion.error)) === false) {
                setErroreshttp(catalogoTiposIdentificacion)
            } else {
                setTiposIdentificaion(catalogoTiposIdentificacion)
            }
        }



    }

    const checarNumeros = (e) => {
        const { name, value } = e.target;
        let out = '';
        let filtro ;//Caracteres validos
        if(name==="rtn"){
            filtro = '1234567890';//Caracteres validos para rtn
        }else if(name==="numeroIdentificacion"){
            filtro = '1234567890-';//Caracteres validos numero de identificacion
        }else{
            filtro = '1234567890-+';//Caracteres validos para telefonos  
        }
        

        //Recorrer el texto y verificar si el caracter se encuentra en la lista de validos 
        for (let i = 0; i < value.length; i++)
            if (filtro.indexOf(value.charAt(i)) != -1)
                //Se añaden a la salida los caracteres validos
                out += value.charAt(i);

        setValue((prev) => {
            const datos = { ...prev.datos, [name]: out };
            return { ...prev, datos };
        });
    }

    const otraActividad = async () => {
        const obj = {
            nombre: actividadNueva.toUpperCase()
        }
        const agregarNuevaActividadEconomica = await insertarActividadEconomica(obj)
        if (agregarNuevaActividadEconomica) {
            setSpinSend(false)
            if ((isNaN(agregarNuevaActividadEconomica.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(agregarNuevaActividadEconomica)
            } else {
                if (agregarNuevaActividadEconomica?.codigo === "000") {

                    //setActividadEconomica(await TraerActividadEconomica())
                    const catalogoActividadEconomica = await TraerActividadEconomica()
                    if (catalogoActividadEconomica) {
                        if ((isNaN(catalogoActividadEconomica.error)) === false) {
                            // respuestaincorrecta           
                            setErroreshttp(catalogoActividadEconomica)
                        } else {
                            setActividadEconomica(catalogoActividadEconomica)
                            setAgregarActividadEconomica(false)
                            const myObj = {
                                target: {
                                    value: (actividadEconomica[actividadEconomica.length - 1].id) + 1,
                                    name: "actividadEconomica"
                                }
                            }
                            guardarDatos(myObj)
                        }
                    }


                }
            }
        }


    }

    return (
        <>
            <div className="card-body pt-3">
               {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }    
                 <h5 className="text-royal-blue font-acumin-variable" >I. Identificación del inversionista</h5>

       
                <div className="row mb-3">

                    <div className="col-md-3 col-sm-3 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <input placeholder="" value={value.datos.primerNombre} onChange={(e) => guardarDatos(e)} id="primerNombre" name="primerNombre" type="text" className="form-control " required />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Primer nombre *</label>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-3 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <input placeholder="" value={value.datos.segundoNombre} onChange={(e) => guardarDatos(e)} id="segundoNombre" name="segundoNombre" type="text" className="form-control" />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Segundo nombre</label>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-3 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <input placeholder="" value={value.datos.primerApellido} onChange={(e) => guardarDatos(e)} id="primerApellido" name="primerApellido" type="text" className="form-control " required />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Primer apellido *</label>
                        </div>


                    </div>

                    <div className="col-md-3 col-sm-3 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <input placeholder="" type="text" className="form-control" value={value.datos.segundoApellido} onChange={(e) => guardarDatos(e)} id="segundoApellido" name="segundoApellido" />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Segundo apellido</label>
                        </div>

                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <input placeholder="" type="text" className="form-control" value={value.datos.apellidoCasada} onChange={(e) => guardarDatos(e)} id="apellidoCasada" name="apellidoCasada" />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Apellido de casada (si aplica)</label>
                        </div>

                    </div>
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <select className="form-select " value={value.datos.tipoIdentidad} onChange={(e) => guardarDatos(e)} id="tipoIdentidad" name="tipoIdentidad" required>
                                <option value=""> </option>
                                {
                                    tiposIdentificacion.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Tipo de identificación *</label>
                        </div>
                    </div>


                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <input placeholder="" type="text" maxLength={22} className="form-control" required value={value.datos.numeroIdentificacion} onChange={(e) => checarNumeros(e)}  id="numeroIdentificacion" name="numeroIdentificacion" />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Número de identificacion *</label>
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <input placeholder="" type="text" maxLength={14} className="form-control" value={value.datos.rtn}  onChange={(e) => checarNumeros(e)} id="rtn" name="rtn" />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto"> RTN (si aplica)</label>
                            <div id="emailHelp" class="emailHelp form-text ps-1">Solo números</div>
                        </div>
                   
                    </div>
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <select className="form-select " value={value.datos.estadoCivil} onChange={(e) => guardarDatos(e)} id="estadoCivil" name="estadoCivil" required >
                                <option value=""> </option>
                                {
                                    estadosCiviles.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                   
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Estado civil *</label>
                        </div>
                    </div>
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <select className="form-select " value={value.datos.genero} onChange={(e) => guardarDatos(e)} id="genero" name="genero" required>
                                <option value=""> </option>
                                {
                                    generos.map((item, index) => (

                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                   
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Género *</label>
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <select className="form-select " value={value.datos.nacionalidad} onChange={(e) => guardarDatos(e)} id="nacionalidad" name="nacionalidad" required>
                                <option value=""> </option>
                                {
                                    nacionalidad.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
 
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Nacionalidad *</label>
                        </div>
                    </div>



                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <input placeholder="" max={fechaLimiteNacimiento} type="date" className="form-control " required value={value.datos.fechaNacimiento} onChange={(e) => guardarDatos(e)} id="fechaNacimiento" name="fechaNacimiento" />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Fecha de nacimiento *</label>
                        </div>
                    </div>

                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <input placeholder="" type="text" className="form-control " required value={value.datos.lugarNacimiento} onChange={(e) => guardarDatos(e)} id="lugarNacimiento" name="lugarNacimiento" />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Lugar de nacimiento *</label>
                        </div>

                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <select className="form-select " value={value.datos.actividadEconomica} onChange={(e) => guardarDatos(e)} id="actividadEconomica" name="actividadEconomica" required>
                                <option value=""> </option>
                                {
                                    actividadEconomica.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                                <option   >OTRO</option>
                            
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Actividad económica *</label>
                        </div>

                    </div>


                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <input placeholder="" type="text" maxLength={15} className="form-control " required value={value.datos.telefonoResidencia} onChange={(e) => checarNumeros(e)}  id="telefonoResidencia" name="telefonoResidencia" />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Teléfono de residencia *</label>
                            
                        </div>
                    </div>
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <input placeholder="" type="text" maxLength={15} className="form-control " value={value.datos.telefonoCelular} onChange={(e) => checarNumeros(e)}  id="telefonoCelular" name="telefonoCelular" />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Teléfono de celular</label>
                           
                        </div>
                    </div>
                </div>


                {agregarActividadEconomica &&
                    <> {spinSend ? <div className="row mb-3 d-flex">
                        <div className="col-md-4 text-center">
                            <SpinnerSend textoSpinner="Enviando..."></SpinnerSend>
                        </div>
                    </div> :
                        <div className="row mb-3 d-flex ">
                            <div className="col-md-4 ">
                                <div className="form-floating">
                                    < input placeholder="" type="text" className="form-control border  r" onChange={(e) => setActividadNueva(e.target.value)} required />
                                    <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Ingrese actividad económica *</label>
                                </div>
                            </div>
                            <div class="col-md-2  d-flex align-items-end">

                                <button type="button" onClick={() => {
                                    setSpinSend(true)
                                    otraActividad()
                                }} className="btn botonFormularioDepositante" >Ingresar</button>
                            </div>
                        </div>}
                    </>
                }
            </div>
        </>
    );
}

