import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';


//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import FormInformacionOperacion from './FormInformacionOperacion/FormInformacionOperacion';

import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { notificarLiquidacionFondos } from "../../../api/MercadoSecundario/notificarLiquidacionFondos";
import { imprimirInformacionOperacionSinFondos } from "../../../api/MercadoSecundario/imprimirInformacionOperacionSinFondos";
import { imprimirInformacionOperacionConFondos } from "../../../api/MercadoSecundario/imprimirInformacionOperacionConFondos";
import { notificarDepositoDeFondos } from "../../../api/Mercadoprimario/notificarDepositoDeFondos";


export default function InformacionOperacionMS({ history }) {

    const historyReact = useHistory();

    //Get the depositante directo code
    //const codigoDepositanteDirecto = (JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo;

    //States
    const [isReporto, setIsReporto] = useState();
    const [isCompraventa, setIsCompraventa] = useState();
    const [isCompraventaForzada, setIsCompraventaForzada] = useState();
    const [isReportoForzado, setIsReportoForzado] = useState();
    const [isRegistrada, setIsRegistrada] = useState();


    const [erroreshttp, setErroreshttp] = useState();
    


    //Inputs
    const [datos, setDatos] = useState()
    const [arregloDatos, setArregloDatos] = useState()

    //Errors and messages
    const [botones, setBotones] = useState()
    const [componenteExtra, setComponenteExtra] = useState()
    const [botonComponenteExtra, setBotonComponenteExtra] = useState(true)
    const [liquidacionFondos, setLiquidacionFondos] = useState(true)
    const [botonRegresar, setBotonRegresar] = useState()
    // const [operacionExitosa, setOperacionExitosa] = useState();
    // const [operacionLiquidada, setOperacionLiquidada] = useState();

    //const [cargando, setCargando] = useState()
    const [loading, setLoading] = React.useState(false);



    useEffect(() => {
        guardarDatos();
    }, []);

    const guardarDatos = async () => {
        if (history.location.state === undefined) {
            history.go(-1)
            //history.push({
            //  pathname: '/cargarInformacionOperacionesMS',
            //})

        } else {

            //  //console.log(history.location.state.objMenu)
            ////console.log("tipo")
            //console.log(history.location.state.objMenu.tipo)
            //console.log(history.location.state.objMenu.tipoStr)

            if (history.location.state.objMenu.tipoStr === "COMPRAVENTA") {
                setIsCompraventa(true);

            } else if (history.location.state.objMenu.tipoStr === "COMPRAVENTA FORZADA") {
                setIsCompraventaForzada(true);

            } else if (history.location.state.objMenu.tipoStr === "REPORTO (INICIO) FORZADO") {
                setIsReportoForzado(true);

            } else {
                setIsReporto(true);
            }

            ////console.log(history.location.state.operacionesInfo)

            setDatos(history.location.state.objMenu)
            setArregloDatos(history.location.state.operacionesInfo)
            setBotones(history.location.state.objetoBooleano.botones)
            setComponenteExtra(history.location.state.objetoBooleano.componenteExtra)
            setBotonComponenteExtra(history.location.state.objetoBooleano.botonComponenteExtra)
            setBotonRegresar(history.location.state.objetoBooleano.botonRegresar)
            setLiquidacionFondos(history.location.state.objetoBooleano.liquidacionFondos)
        }

    }

    const imprimir = async () => {
        //console.log("imprimir")
        setLoading(true)

        var response;
        if (liquidacionFondos) {
            response = await imprimirInformacionOperacionConFondos(datos)
        } else {
            response = await imprimirInformacionOperacionSinFondos(datos)
        }

        if (response) {
            //setLoading(false)
            if ((isNaN(response.error)) === false) {
                setLoading(false)
                // respuestaincorrecta           
                setErroreshttp(response)
            } else {
                //console.log(response)
                const file = new Blob(
                    [response],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
                setLoading(false)

                /*   history.push({
                       pathname: '/'
                   })*/
            }
        }
    }


    const notificarLiquidacion = async () => {

        if (datos?.estatusOperacionStr === "LIQUIDADA") {
            //setOperacionLiquidada(true);
        } else {

            setLoading(true);

            const response = await notificarLiquidacionFondos(datos);
            //console.log("respuesta")
            //console.log(response)
            if (response) {
                setLoading(false)

                if ((isNaN(response.error)) === false) {
                    // //console.log("erores")
                    ////console.log(response)
                    setErroreshttp(response)
                    
                } else {
                    //console.log("registrar")

                    // setIsRegistrada(true)
                    //console.log(response.resultado)
                    setDatos(response.resultado)


                    if (history.location.state.objMenu.tipo === 3) {
                        setIsCompraventa(true)
                    } else {
                        setIsReporto(true)
                    }

                    ////console.log(history.location.state.operacionesInfo)

                    //setDatos(history.location.state.objMenu)
                    setArregloDatos(history.location.state.operacionesInfo)
                    setBotones(history.location.state.objetoBooleano.botones)
                    setComponenteExtra(history.location.state.objetoBooleano.componenteExtra)
                    setBotonComponenteExtra(history.location.state.objetoBooleano.botonComponenteExtra)
                    setBotonRegresar(history.location.state.objetoBooleano.botonRegresar)
                    setLiquidacionFondos(history.location.state.objetoBooleano.liquidacionFondos)
                    //setOperacionExitosa(true)
                }
            }
        }



    }

    const goBack = () => {
        historyReact.push({
            pathname: history.location.state.objetoBooleano.ruta, state: { operaciones: arregloDatos }
        })
    }

    return (
        <>
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp}></ManejadorErrores>
            }
           

            {loading &&
                <PantallaCargando />
            }

            

            <div className="fluid-container">{/* style={{ backgroundImage: `url(${fondo})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="fluid-container">*/}
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-8 col-sm-9 col-md-10 col-lg-10 col-xl-10 mb-5 w-100">
                            <div className="">
                                <BannerTitulo titulo="Información de la operación"></BannerTitulo>
                                <div className="d-flex px-lg-5 px-md-4 px-sm-1">
                                    <div className="">
                                        <section className="d-flex justify-content-center ">
                                            <div className="col-12 col-sm-12 col-md-11 col-lg-10 col-xl-9 row justify-content-center border rounded p-2">
                                                <div className="col-12">
                                                    {datos &&
                                                        <form className="needs-validation font-acumin-variable text-royal-blue">
                                                            <FormInformacionOperacion
                                                                isRegistered={isRegistrada}
                                                                isCompraventa={isCompraventa}
                                                                isReporto={isReporto}
                                                                isCompreventaForzada={isCompraventaForzada}
                                                                isReportoForzado={isReportoForzado}
                                                                endpoint={notificarDepositoDeFondos}
                                                                funcion1={goBack}
                                                                funcion2={imprimir}
                                                                funcion3={notificarLiquidacion}
                                                                datos={datos}
                                                                infoOperaciones={arregloDatos}
                                                                botones={botones}
                                                                componenteExtra={componenteExtra}
                                                                botonComponenteExtra={botonComponenteExtra}
                                                                botonRegresar={botonRegresar}
                                                            />
                                                        </form>
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

