import React from "react";
import { Link } from 'react-router-dom';


//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";

//Images
import dipj from '../../../assets/imagenesDepositantes/dipj.jpg'
import dipn from '../../../assets/imagenesDepositantes/dipn.jpg'

export default function RegistrarClientes({ history }) {
    return (
        <>
            <div className="fluid-container">
                {/*Header*/}
                <main className="main-min-height">
                    <div className="row row-wrap g-0">
                        

                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo className="mb-5" titulo="Registrar clientes"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5 mb-5">
                                        <div className="row ">
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                <Link to={{ pathname: "/registroDepositanteIndirectoJuridica" }} className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="" src={dipj} alt="imagenFondo" />
                                                        <div className="card-body text-center">
                                                            <h5 className="card-title font-acumin-variable">Registrar persona jurídica</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                <Link to={{ pathname: "/registroDepositanteIndirectoNatural" }} className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="" src={dipn} alt="imagenFondo" />
                                                        <div className="card-body text-center">
                                                            <h5 className="card-title font-acumin-variable">Registrar persona natural</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
                {/*Footer*/}
            </div >

        </>
    );
}

