import React, { useState } from 'react';

export const FormContext = React.createContext();

export function FormProvider({ children }) {
  const [formValue, setFormValue] = useState({
    datos: {
      cehvalDocumentoSoporNsCopiaEscrSoc : 0,
      cehvalDocumentoSoporNsCopiaRtn : 0,
      cehvalDocumentoSoporNsEntRegul : 0,
      cehvalDocumentoSoporNsCopiaRtnAut : 0,
      cehvalDocumentoSoporNsEstatus : 0,
      cehvalDocumentoSoporNsNotRemiProgCump : 0,
      cehvalDocumentoSoporNsConstanciaExoVig : 0,
      cehvalDocumentoSoporNsFirmaAutExt : 0,
      cehvalDocumentoSoporNsCopiaNomFunCump : 0,
      cehvalDocumentoSoporNsCopiaDni : 0,
      cehvalDocumentoSoporNsExpComp : 0,
      cehvalDocumentoSoporNsCopiaPoderAdm : 0,
      contratoDeDepositante:0,
      revisionListaOfac:0,
      debidaDiligenciaCEHVAL : 0,
      certificacionDebidaDiligenciaRecibida:0,
      estatusFirma1:0,
      errorDocumentacionIncompletaSupervisada:true,
      errorDocumentacionIncompletaNoSupervisada:true,
      errorDocumentacionIncompletaFuncionario:true,
      idFirma1:"",
      estatusFirma2:0,
      idFirma2:"",
      enviar:0,
    }
  });

return (
  <FormContext.Provider value={[formValue, setFormValue]}>
    {children}
  </FormContext.Provider>
);
}
