import React, { useState, useEffect } from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import TablaUsuarios from '../../../ComponentesGenerales/TablaUsuarios';

import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//Hooks
import useLiberacionesRechazadas from "../../../hooks/PrendasYGarantias/useLiberacionesRechazadas";

//APIs
import { traerLiberacionesRechazadas } from "../../../api/cuentaValoresPrendasYGrantias/traerLiberacionesRechazadas";
import { descargarPDF } from '../../../api/prendasYGarantias/descargarPDF';

export default function LiberacionesRechazadas({ history }) {
    const [data, setData] = useState([]);
    const [copia, setCopia] = useState();
    const [erroreshttp, setErroreshttp] = useState();
    const [spin, setSpin] = useState(true);
    const [loading, setLoading] = React.useState(false);
    

    useEffect(() => {
        verCuentaValores();
    }, [])

    const verCuentaValores = async () => {

        const response = await traerLiberacionesRechazadas();
        //console.log(response.data)

        if (response) {
            setSpin(false);
            if ((isNaN(response.error)) === false) {

              
                setErroreshttp(response);
                
            } else {
                // //console.log(respuesta)
                setData(response.data);
                setCopia(response.data);
            }

        }

    }

    const descargarOficio = async (row) => {
        setLoading(true)
        //Download pdf with POST request. In body send folio
        const response = await descargarPDF({ fileName: row.original.liberacionGarantiaFolio });
        if (response) {
            setLoading(false)
            //setLoading(false)
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(response)
            } else {
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
            }
        }

    }


    return (
        <>
            <div className="fluid-container">
                {loading &&
                    <PantallaCargando />
                }

                
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }

                <main className="min-height-40">
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 ">
                            <div className="">
                                <br></br>
                                <BannerTitulo titulo="Solicitudes rechazadas de liberación de prendas y garantía"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart>
                                    :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaUsuarios datos={data} useValidate={useLiberacionesRechazadas} displayInformation2={descargarOficio} columnaLiberacion={true}></TablaUsuarios>
                                            }
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    );
}



