//React
import React, { useState, useEffect } from "react";

//AWS
import { Auth, input } from 'aws-amplify';

//Componentes generales
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../ComponentesGenerales/ManejadorErrores';
import TablaFondoLiquidacionOperacionesForzadas from "../../../ComponentesGenerales/TablaFondoLiquidacionOperacionesForzadas";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { traerTodoFondoLiquidacion } from "../../../api/SalvaguardasFinancieras/traerTodoFondoLiquidacion";
import { actualizarMontoObjetivo } from "../../../api/SalvaguardasFinancieras/actualizarMontoObjetivo";
import { imprimirReporteFondoLiquidacion } from "../../../api/SalvaguardasFinancieras/imprimirReporteFondoLiquidacion";


//Hooks
import useFondoLiquidacion from '../../../hooks/SalvaguardasFinancieras/useFondoLiquidacion';



export default function FondoLiquidacion({ history }) {



    const [montoAcumulado, setMontoAcumulado] = useState();

    //Save loaded data
    const [copia, setCopia] = useState();
    const [data, setData] = useState([]);

    //Spinner
    const [spin, setSpin] = useState(true);

    //States to handle load data when loading page 
    const [erroreshttp, setErroreshttp] = useState();
    const [error, setError] = useState();
    const [updateSuccess, setUpdateSuccess] = useState();

    //Shows modal uf the session has expired
    const [sessionExpired, setSessionExpired] = useState();
    const [failLoadData, setFailLoadData] = useState();
    const [errorNotNewAmount, setErrorNotNewAmount] = useState();


    useEffect(() => {
        getAllApplications();
    }, []);


    //Gets all depositors that are in fondo de liquidacion
    const getAllApplications = async () => {
        try {
            setSpin(true)
            const response = await traerTodoFondoLiquidacion();
            if (response) {
                setSpin(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    if (response.data) {
                        setData(response.data.registros);
                        setCopia(response.data.registros)
                        setMontoAcumulado(response.data.acumuladoUsdStr)
                        setSpin(false);
        
                    } else if (response.error === 401) {
                        setSessionExpired(true);
        
                    } else {
                        setFailLoadData("No se pudo cargar la información");
                    }
                }
            }

            if (response.data) {
                setData(response.data.registros);
                setCopia(response.data.registros)
                setMontoAcumulado(response.data.acumuladoUsdStr)
                setSpin(false);

            } else if (response.error === 401) {
                setSessionExpired(true);

            } else {
                setFailLoadData("No se pudo cargar la información");
            }

        } catch (err) {
            console.error(err);
        }
    }


    //Function to update the "Monto objetivo" column with the input
    const updateMontoObjetivo = async (row, e) => {

        try {

            //El monto que ingreso el usuario
            const newMonto = row.montoObjetivoActualizado;
            //console.log(newMonto)

            if (newMonto === undefined || newMonto === "" || isNaN(newMonto)) {
                setErrorNotNewAmount(true);

            } else {

                setErrorNotNewAmount(false);

                //El objeto que envia en la petición para actualizar el monto objetivo
                row.original.montoObjetivoUsd = newMonto;
                const objActualizarMonto = row.original;

                setSpin(true);
                const response = await actualizarMontoObjetivo(objActualizarMonto);

                if ((isNaN(response.error)) === false) {
                    setErroreshttp(response);

                } else if (response.data) {
                    setData(response.data.fondo.registros);
                    setCopia(response.data.fondo.registros);
                    setUpdateSuccess(response.data.respuesta.mensaje);
                }
            }

        } catch (error) {

        }
    }


    //Show the form to make a Deposito/Retiro from the fondo de liquidacion of one single depositor
    const goToDepositoRetiro = () => {
        history.push({
            pathname: '/retiroDepositoFondoLiquidacion',
            registros: data,
        })
    }



    const signOut = async () => {
        try {
            const data = await Auth.signOut();
            sessionStorage.clear();
            window.location.replace('/login')
        } catch (error) {
            //console.log('error signing out: ', error);
        }
    }

    const handleImprimirReporteTabla = async () => {
        try {
            const myObj = {
                autorizado: true,
                tipoValor: "FONDO_LIQUIDACION"
            }

            const response = await imprimirReporteFondoLiquidacion(myObj);
            if (response) {
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    const file = new Blob(
                        [response],
                        { type: 'application/pdf' });
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    window.open(fileURL);
                }
            }


         

        } catch (error) {
            console.error();
        }
    }


    const goBack = () => {
        history.push({
            pathname: '/inicioCehval',
        })
    }

    //Close modal when the monto objetivo was updated
    const closeMoldal = () => {
        setSpin(false);
        setUpdateSuccess(false);
    }


    return (
        <>

            {updateSuccess &&
                <ComponenteModal mostrarModal={updateSuccess} cerrarModal={closeMoldal} titulo={updateSuccess} textoBoton="Aceptar"></ComponenteModal>
            }

            {error &&
                <ComponenteModal mostrarModal={error} cerrarModal={() => setError(false)} titulo={error} textoBoton="Aceptar"></ComponenteModal>
            }

           
            {failLoadData &&
                <ComponenteModal mostrarModal={failLoadData} cerrarModal={goBack} titulo={failLoadData} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="">
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <main className="min-height-40">
                    <div className="row row-wrap g-0">
                     
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Fondo de liquidación"></BannerTitulo>


                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <>
                                                    <div className="px-5">
                                                        <div className="row mt-4  px-3 g-0">
                                                            <div className="col-12 col-md-4 col-lg-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código depositante transferente" className="form-control fs-5" value={montoAcumulado} maxLength={20} name="codigoDepositanteReceptor" type="text" placeholder="Código de depositante transferente" required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="codigoDepositanteReceptor">Monto acumulado &#40;USD&#41;</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <TablaFondoLiquidacionOperacionesForzadas datos={data} getRowData={updateMontoObjetivo} useValidate={useFondoLiquidacion} textoBoton={"Asociar"} showErrorNotAmount={errorNotNewAmount} textTitle="Clic para ver la información"></TablaFondoLiquidacionOperacionesForzadas>
                                                    </div>

                                                    <div className="col-12 px-3 px-lg-4 px-xl-5 row align-items-center mt-4 justify-content-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end">
                                                        <button onClick={handleImprimirReporteTabla} className="col-8 col-sm-5 col-md-4 col-lg-3 col-xl-2 col-xxl-2 px-1 mx-2 mt-2 btn background-royal-blue text-white font-acumin-variable bg-hover-gold" type="button"  >Imprimir</button>
                                                        <button onClick={goToDepositoRetiro} className="col-8 col-sm-5 col-md-4 col-lg-3 col-xl-2 col-xxl-2 px-1 mx-2 mt-2 btn background-royal-blue text-white font-acumin-variable bg-hover-gold" type="button" >Realizar depósito/retiro</button>
                                                    </div>

                                                </>
                                            }
                                        </div>
                                    </div>
                                }



                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}





