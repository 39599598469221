import React from "react";
import '../../../../styles/stylesheet.css'
import { FormContext } from './form_context';

export default function RegistroDI7({ tipo,nombreCompleto,correoElectronico }) {
    const [value, setValue] = React.useContext(FormContext);

    React.useEffect(() => {
        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 1 };
            return { ...prev, datos };
        });

        if (tipo === "depositanteIndirecto") {
            //console.log("entra a dep")
            setValue((prev) => {
                const datos = { ...prev.datos, ["cehvalDepositanteNombreConf"]: nombreCompleto };
                return { ...prev, datos };
            });

            setValue((prev) => {
                const datos = { ...prev.datos, ["cehvalDepositanteCorreoConf"]: correoElectronico };
                return { ...prev, datos };
            });
        }

    }, [])

    const guardarDatos = (e) => {
        const { name, value } = e.target;
        setValue((prev) => {
            const datos = { ...prev.datos, [name]: value };
            return { ...prev, datos };
        });
    }

    return (
        <>
            <div className="card-body pt-3 ">
                <h5 className="text-royal-blue font-acumin-variable " >VII. Responsable de la solicitud de registro</h5>
                {tipo === "depositanteDirecto" ?
                    <div className="row mb-3">
                        <div className="col-md-6 ">
                            <div className="form-floating">
                                <input type="text" className="form-control textoFormularioDepositantedirecto" value={value.datos.cehvalDepositanteNombreConf} onChange={(e) => guardarDatos(e)} id="cehvalDepositanteNombreConf" name="cehvalDepositanteNombreConf" placeholder="" required />
                                <label htmlFor="cehvalDepositanteNombreConf" className=" textoFormularioDepositantedirecto">Nombre completo *</label>
                            </div>
                        </div>

                        <div className="col-md-6 form-floating">
                            <div className="form-floating">
                                <input style={{textTransform:"lowercase"}} placeholder="" type="email" className="form-control " value={value.datos.cehvalDepositanteCorreoConf} onChange={(e) => guardarDatos(e)} id="cehvalDepositanteCorreoConf" name="cehvalDepositanteCorreoConf" required />
                                <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Correo electrónico *</label>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row mb-3">
                        <div className="col-md-6 ">
                            <div className="form-floating">
                                <input type="text" className="form-control textoFormularioDepositantedirecto" value={value.datos.cehvalDepositanteNombreConf} onChange={(e) => guardarDatos(e)} id="cehvalDepositanteNombreConf" name="cehvalDepositanteNombreConf" disabled />
                                <label htmlFor="cehvalDepositanteNombreConf" className=" textoFormularioDepositantedirecto">Nombre completo *</label>
                            </div>
                        </div>

                        <div className="col-md-6 form-floating">
                            <div className="form-floating">
                                <input type="email" className="form-control " value={value.datos.cehvalDepositanteCorreoConf} onChange={(e) => guardarDatos(e)} id="cehvalDepositanteCorreoConf" name="cehvalDepositanteCorreoConf" disabled />
                                <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Correo electrónico *</label>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

