import { instance } from '../apiGlobal';

export async function consultarHistoricoDepositoRetiroValores() {
      try {
        const response = await instance(`/deposito/retiro/consulta/historico`, {
          method:"get",
        })
        return response.data
      } catch (error) {
        return error
      }
}