import { useMemo } from "react";

export default function useClientesDD() {
  const columns = useMemo(
    () => [
      {
        Header: "Código del depositante",
        accessor: "cehvalDepositanteCodigoCehval"
      },
      {
        Header: "Nombre del depositante",
        accessor: "cehvalDepositanteNombreCompleto"
      },
    ],
    []
  );

  return columns;
}
