import { useMemo } from "react";

export default function useTenenciaValores() {
    const columns = useMemo(
        () => [
          {
            Header: "Código casa de bolsa del propietario de los valores",
            accessor: "codigoCasaBolsa"
          },
          {
            Header: "Nombre casa de bolsa del propietario de los valores",
            accessor: "nombreCasaBolsa"
          },
            {
              Header: "Código del propietario de los valores",
              accessor: "codigoDepositantePropietario"
            },
            {
                Header: "Nombre del propietario de los valores",
                accessor: "nombrePropietario"
            },
            {
                Header: "Nombre de la emisión",
                accessor: "nombreEmision"
            },
            {
                Header: "Serie",
                accessor: "nombreSerie"
              },
            {
              Header: "ISIN",
              accessor: "isin"
            },
            {
              Header: "Moneda",
              accessor: "monedaStr"
            },
            {
              Header: "Cantidad",
              accessor: "cantidad"
            },
            {
              Header: "Representación",
              accessor: "representacion"
            },
          
          ],
        []
      );

    return columns;
}
