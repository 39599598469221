import { instance } from '../apiGlobal';

export async function consultarUsuariosPorEstatus(obj) {
      try {
        const response = await instance(`/auth_user/consultar/usuarios`, {
          method:"post",
          data:obj
        })
        
        return response.data
      } catch (error) {
        return error
      }
}