import { instance } from '../../apiGlobal';

export async function lecturaArchivoEmisiones(file) {
    try {
        const response = await instance(`/emision/bcv/excel`, {
            method: "post",
            data: file,
        })
        return response;
    } catch (error) {
        return error;
    }
}