import React, { useState } from "react";
//import { useHistory } from "react-router-dom";

//Componentes
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import TablaUsuarios from "../../../../ComponentesGenerales/TablaUsuarios";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";

//Hooks
import useEmisionesBCV from "../../../../hooks/EmisonesBCV.js/useEmisionesBCV";

//APIs
import { lecturaArchivoEmisiones } from "../../../../api/emision/emisionBCV/lecturaArchivoEmisiones";


//Muestra las cuentas de un depositante indirecto
export default function RegistroEmisionesBCV({ history }) {

    //const dataAlreadyLoaded = history.location.state;

    //If endpoint got the data will be true
    const [getEmisionData, setGetEmisionData] = useState(false);
    const [datosPreviamenteCargados, setDatosPreviamenteCargados] = useState(false);
    //Stores the json with emisors  
    const [emisorsData, setEmisorsData] = useState([]);

    //The name of the users file
    const [nameFile, setNameFile] = useState();
    //The file
    const [file, setFile] = useState(undefined);
    //Shows the loading screen when y set to true
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState();
    const [invalidFile, setInvalidFile] = useState(false);
    const [erroreshttp, setErroreshttp] = useState();

    


    React.useEffect(() => {
        viewAccounts();
    }, []);

    const viewAccounts = async () => {
        try {
            const dataAlreadyLoaded = history.location.state;
            //console.log(dataAlreadyLoaded);

            if (dataAlreadyLoaded === undefined) {
                setDatosPreviamenteCargados(false)
            } else if (dataAlreadyLoaded.emisionesFiltradas.length === 0) {

                setDatosPreviamenteCargados(false)
            } else {

                setDatosPreviamenteCargados(true);
                setEmisorsData(dataAlreadyLoaded.emisionesFiltradas);
            }
            setGetEmisionData(false);
        } catch (err) {
            console.error(err);
        }
    }

    //Open input file when click on h4 text
    const handleInputFile = (e) => {
        e.preventDefault();
        //Select our input tag 
        const input = document.querySelector("#input-file");
        //Give the event click to our input
        input.click();
    }

    // HANDLE WHEN USER SELECT A FILE WHEN CLICK ON TEXT
    const selectFile = (e) => {
        //Cleans error message if was one
        setError("");
        setGetEmisionData(false);

        const file = e.target.files;
        //Name of the file to validate if has the correct extension
        const fileName = e.target.files[0].name;
        const fileSize = e.target.files[0].size;

        //Regex to validate the extension of the file
        const extensionsAllow = /(.xlsx)$/i;

        ////console.log(pathFile.files)
        //If selected file doesnt have correct extension show user error message
        if (!extensionsAllow.exec(fileName)) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file
            setError("Selecciona un archivo válido");

            //Clean our states if they had any information
            setFile("");
            setNameFile("");
            return false;

        } else if (fileSize > 10_000_000) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file
            setError("El tamaño del archivo debe ser menor a 10 MB");
            //Clean file name if a file was selected before
            setNameFile("");
            //Clean state if a file was selected before
            setFile("");

        } else if (e.target.files && e.target.files[0]) {
            //Shows the name of the selected file to the user
            setNameFile(fileName);
            //Set FileList [File] to our state
            setFile(file);

        }
    }

    // HANDLE USER DROP FILE
    const dropFile = (e) => {
        e.preventDefault();
        //Cleans error message if was one
        setError("");
        setGetEmisionData(false);
        //Removes the active class from container when user drops a file
        document.querySelector(".drop-area").classList.remove("active");
        //Shows the same text before dropping a file
        const inputField = document.querySelector('#text-click-uploadfile');
        inputField.innerHTML = "Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro";

        //Show user the selected file and validates in codition if file has a valid extension 
        const fileName = e.dataTransfer.files[0].name;
        const fileSize = e.dataTransfer.files[0].size;
        //Regex to validate file extension
        const extensionsAllow = /(.xlsx)$/i;

        if (!extensionsAllow.exec(fileName)) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file
            setError("Selecciona un archivo válido");
            setFile("");
            setNameFile("");
            return false;

        } else if (fileSize > 10_000_000) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file
            setError("El tamaño del archivo debe ser menor a 10 MB");
            //Clean file name if a file was selected before
            setNameFile("");
            //Clean state if a file was selected before
            setFile("");

        } else if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            //Shows the name of the selected file to the user
            setNameFile(fileName);
            //Set FileList [File] to our state
            setFile(e.dataTransfer.files);
        }
    }

    //Handle when user drag over a file 
    const dragOver = (e) => {
        e.preventDefault();
        //Adds the active class to container to change the background
        const moveOver = document.querySelector(".drop-area");
        moveOver.classList.add("active");
        //Change the text inside box
        const inputField = document.querySelector('#text-click-uploadfile');
        inputField.innerHTML = "Suelte el archivo";
    }

    //Handle when user leaves the drop area whitout drop any file
    const dragLeave = (e) => {
        e.preventDefault();
        //Removes the active class to container to change the background
        const moveLeave = document.querySelector(".drop-area");
        moveLeave.classList.remove("active");
        //Change the text inside box 
        const inputField = document.querySelector('#text-click-uploadfile');
        inputField.innerHTML = "Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro";
    }

    // HANDLE WHEN USER CLICK BUTTON TO READ THE FILE
    const readFile = async (e) => {
        e.preventDefault();
        try {
            //Shows error massage when user doesnt select any file and click on button
            if (file === undefined) {
                setError("Por favor seleccione un archivo");
                return;
            }
            //If file is not the right type of file doesnt allow user to click "leer registros"
            if (file[0] === undefined || file[0] === "" || file[0] === null || file[0] === "string") {
                setError("Por favor seleccione un archivo");
                //console.log("no hay archivo")
            } else {
                //Clean error message if showed 
                setError("");
                //Make the query to data base to read the file and return the json

                //Shows loading screen until get the response
                setLoading(true);

                let formFile = new FormData();
                formFile.append('file', file[0]);
                const response = await lecturaArchivoEmisiones(formFile);

                if (response) {

                    if ((isNaN(response.error)) === false) {

                        // //console.log("erores")
                        ////console.log(response)
                        setLoading(false);
                        setErroreshttp(response)
                        
                    } else {
                        setLoading(false);
                        //console.log(response.data)
                        setEmisorsData(response.data);

                        //When await gets the data hides loading screen
                        setLoading(false);

                        setGetEmisionData(true);
                    }

                }


            }

        } catch (error) {
            console.error(error);
        }
    }

    const previewSelectedEmisor = (row) => {
        //console.log(row.original)
        const emision = {
            cehvalNombreEmision: row.original.descripcion,
            cehvalCodigoEmision: row.original.codigoEmision,
            cehvalEstatus: 5,
            cehvalNombreEmisor: "",
            cehvalCodigoEmisor: row.original.codigoEmisor,
            cehvalMonto: row.original.montoDeLaEmision,
            cehvalEmisionSeries: row.original.series,
            cehvalEmisionNumeroResol: row.original.numResolucionRPMV,
            cehvalEmisionFechaRpmv: row.original.fechaRegistro,
            cehvalEmisionRepresentanteComunObl: row.original.rco,
            cehvalEmisionTipoMoneda: row.original.monedaDeLaEmision,
            cehvalEmisionDesmaterializada: row.original.formaDeRepresentacion,
            titulo: "Registrar emisión",
            ruta: "/registroEmisionesBCV",
            descripcionEmision: row.original.descripcion
        }


        //  const codigoEmisor = row.original.emisorCodigo;

        //const objEmisorRow = emisorsData.filter(word => word.emisorCodigo === codigoEmisor);

        history.push({
            pathname: '/verInfoEmision', state: { objMenu: emision, objEmisiones: emisorsData }
        })


        /*  history.push({
              pathname: '/verInfoEmision',
              state: {
                  emisorRow: objEmisorRow,
                  objEmisores: emisorsData,
              }
          });*/
    }

    const irDevuelta = () => {
        setGetEmisionData(false);
        setDatosPreviamenteCargados(false)
    }


    return (
        <>
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {invalidFile &&
                <ComponenteModal mostrarModal={invalidFile} cerrarModal={() => { setInvalidFile(false) }} titulo="Archivo inválido. Por favor seleccione un archivo .xlsx y con un peso menor a 10 MB" textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="fluid-container">

                <main className="mb-5">
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <BannerTitulo titulo="Registrar emisiones provenientes de la BCV"></BannerTitulo>
                            {getEmisionData || datosPreviamenteCargados ?
                                <div className="container py-5">
                                    <TablaUsuarios datos={emisorsData} useValidate={useEmisionesBCV} displayInformation={previewSelectedEmisor} textoBoton={"Vista previa"} textTitle="Ver información de la emisión" ></TablaUsuarios>
                                    <div className="col-12 row justify-content-star mt-3 ms-3">
                                        <div className="col-6 mt-1 g-0">
                                            <button onClick={irDevuelta} className="btn background-royal-blue bg-hover-gold text-white font-acumin-variable">Regresar</button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="row justify-content-center align-items-center g-0">
                                    <form>
                                        <div className="col-12 row justify-content-center g-0">
                                            <div id="fileDropArea" onDragOver={dragOver} onDragLeave={dragLeave} onDrop={(e) => dropFile(e)} className="col-11 col-sm-10 col-md-8 col-lg-6 drop-area row row-wrap justify-content-center align-items-center border-dashed py-5 px-2 rounded">
                                                <i className="ri-upload-2-fill ri-3x text-center text-royal-blue"></i>
                                                <h4 id="text-click-uploadfile" onClick={handleInputFile} className="text-center font-acumin-variable text-royal-blue">Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro</h4>
                                                <input id="input-file" type="file" onChange={(e) => selectFile(e)} hidden required />
                                            </div>
                                        </div>

                                        <div className="col-12 row justify-content-center g-0">
                                            <div id="preview-file" className="col-11 col-sm-10 col-md-8 col-lg-6 row justify-content-center g-0 mt-1">
                                                <span className="text-royal-blue font-acumin-variable">Seleccione un archivo excel <i>.xlsx</i></span>
                                                {nameFile &&
                                                    <p className="text-royal-blue text-success font-acumin-variable fs-5 text-overflow">Archivo válido:<i> {nameFile}</i></p>
                                                }
                                                {error &&
                                                    <p className="text-danger font-acumin-variable fs-5">{error}</p>
                                                }
                                            </div>
                                        </div>

                                        <div className="col-12 row justify-content-center g-0">
                                            <div className="col-11 col-sm-10 col-md-8 col-lg-6 mt-1 g-0">
                                                <button onClick={readFile} className="btn background-royal-blue bg-hover-gold text-white font-acumin-variable">Leer registros</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                    {loading &&
                        <PantallaCargando />
                    }
                </main >

            </div>
        </>
    );
}


