//React
import React, { useState, useEffect } from "react";

//Componentes generales
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores';
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";

//APIs
import { traerTodasSolicitudesRegistroDepValFis } from "../../../../api/resguardoValoresFisicos/traerTodasSolicitudesRegistroDepValFis";
import { actualizarSolicitudRegistroDepValFis } from '../../../../api/resguardoValoresFisicos/actualizarSolicitudRegistroDepValFis';

//Hooks
import useSolicitudesDepositantesValoresFisicos from '../../../../hooks/ResguardoValoresFisicos/useSolicitudesDepositantesValoresFisicos';

//Componnetes de la tabla
import TablaUsuarios from "../../../../ComponentesGenerales/TablaUsuarios";


export default function NotificarSolicitudesDepositantesValoresFisicos({ history }) {

    


    const [copia, setCopia] = useState();
    const [data, setData] = useState([]);
    const [spin, setSpin] = useState(true);


    const [notificacionExitosa, setNotificacionExitosa] = useState();
    const [notificacionFallida, setNotificacionFallida] = useState();


    const [tituloNotificaciones, setTituloNotificaciones] = useState();
    const [requestResponse, setRequestResponse] = useState();

    //Shows loading screen
    const [loading, setLoading] = useState();

    const [erroreshttp, setErroreshttp] = useState();

    useEffect(() => {
        getConstitutionRequests();
    }, [])


    //Get authorized or rejected constitution requests depending on the pathname
    const getConstitutionRequests = async () => {

        try {
            let actualPathname = window.location.pathname;

            if (actualPathname === "/solicitudesDepValoresFisicosAutorizadas") {
                setTituloNotificaciones("Solicitudes registro de depositantes de valores físicos autorizadas");


                //get all Authorized applications
                const response = await traerTodasSolicitudesRegistroDepValFis({ estatus: 2 });
                //console.log(response)
                if (response) {
                    setSpin(false)
                    if ((isNaN(response.error)) === false) {
                        // //console.log("erores")
                        ////console.log(response)
                        setErroreshttp(response)
                        
                    } else {
                        setData(response.data);
                        setCopia(response.data)
                    }
                }

            } else if (actualPathname === "/solicitudesDepValoresFisicosRechazadas") {
                setTituloNotificaciones("Solicitudes registro de depositantes de valores físicos rechazadas");


                //get all Rejected applications
                const response = await traerTodasSolicitudesRegistroDepValFis({ estatus: 0 });
                setLoading(false);

                if (response) {
                    setSpin(false)
                    if ((isNaN(response.error)) === false) {
                        // //console.log("erores")
                        ////console.log(response)
                        setErroreshttp(response)
                        
                    } else {
                        setData(response.data);
                        setCopia(response.data)
                    }
                }
            }

        } catch (error) {
            console.error(error)
        }
    }

    //Handle notifications 
    const notify = async (row) => {

        try {
            //Notify rejected applications
            if (row.original.depositanteResgvalfisCodigo === null) {
                const objNotify = {
                    idSolicitud: row.original.depositanteResgvalfisId,
                    estatus: 4,
                }

                setLoading(true);

                const response = await actualizarSolicitudRegistroDepValFis(objNotify);
                if (response) {
                    setLoading(false);
                    //setCargando(false)
                    if ((isNaN(response.error)) === false) {
                        // respuestaincorrecta           
                        setErroreshttp(response)
                    } else {
                        ////console.log(response)
                        if (response.data.isSuccessful === true) {
                            setLoading(false);
                            setRequestResponse(response.data.mensaje);
                            setNotificacionExitosa(true);

                        } else {
                            setLoading(false);
                            setRequestResponse(response.data.mensaje);
                            setNotificacionFallida(true);
                        }
                    }
                }



                //Notify authorized applications
            } else {
                const objNotify = {
                    idSolicitud: row.original.depositanteResgvalfisId,
                    estatus: 3,
                }

                setLoading(true);

                const response = await actualizarSolicitudRegistroDepValFis(objNotify);
                if (response) {
                    setLoading(false);
                    //setCargando(false)
                    if ((isNaN(response.error)) === false) {
                        // respuestaincorrecta           
                        setErroreshttp(response)
                    } else {
                        ////console.log(response)
                        if (response.data.isSuccessful === true) {
                            setLoading(false);
                            setRequestResponse(`${response.data.mensaje} para el depositante con código ${row.original.depositanteResgvalfisCodigo} `);
                            setNotificacionExitosa(true);

                        } else {
                            setLoading(false);
                            setRequestResponse(response.data.mensaje);
                            setNotificacionFallida(true);
                        }
                    }
                }


            }

        } catch (error) {
            console.error(error)
        }
    }


    //Handle 
    const confirmModal = () => {
        setNotificacionExitosa(false);
        window.location.reload();
    }

    const goBack = () => {
        history.go(-1)
    }

    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {notificacionExitosa &&
                <ComponenteModal mostrarModal={notificacionExitosa} cerrarModal={() => confirmModal()} titulo={requestResponse} textoBoton="Aceptar"></ComponenteModal>
            }
            {notificacionFallida &&
                <ComponenteModal mostrarModal={notificacionFallida} cerrarModal={() => setNotificacionFallida(false)} titulo={requestResponse} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="">
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        {/*<div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 d-flex flex-column">
                            <BarraLateral2  ></BarraLateral2>
                        </div>*/}
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo={tituloNotificaciones}></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <>
                                                    <TablaUsuarios datos={data} displayInformation={notify} useValidate={useSolicitudesDepositantesValoresFisicos} textoBoton={"Enviar email"} textTitle="Click para notificar"></TablaUsuarios>
                                                    <div className="col-12 col-md-12 px-3 d-flex justify-content-end justify-content-xl-end justify-content-md-end justify-content-sm-end my-3">
                                                        <button type="button" onClick={goBack} className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1 background-royal-blue text-white bg-hover-gold">Atrás</button>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    );
}

