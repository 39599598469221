import React, { useState } from "react";

//Componentes
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import InfromacionSubastasOperadorDD from "./InformacionSubastaOperadorDD";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";


//Hooks
import useSubastasLiquidadasDepositanteD from "../../../hooks/MercadoPrimario/useSubastasLiquidadasDepositanteD";

//API's
import { consultarSubastasLiquidadasOperadorDD } from "../../../api/Mercadoprimario/consultarSubastasLiquidadasOperadorDD";



//Muestra las cuentas de un depositante indirecto
export default function ConsultarSubastasLiquidadasOperadorDD({ history }) {
  //If endpoint got the data will be true    

    //Stores the json with emisors  
    const [condicionSubasta, setCondicionSubasta] = useState();
    const [erroreshttp, setErroreshttp] = useState();
    const [spin, setSpin] = React.useState(true)
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    const [informacionSubasta, setInformacionSubasta] = React.useState()

    const codigo = (JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo
   


    React.useEffect(() => {
        traerSubastas()
    }, []);



    const traerSubastas = async () => {
        const numeroSubastas = await consultarSubastasLiquidadasOperadorDD({codigoDepositante:codigo})
        //console.log(numeroSubastas)
        if (numeroSubastas) {
            setSpin(false)
            if ((isNaN(numeroSubastas.error)) === false) {
                setErroreshttp(numeroSubastas)
            } else {
                setData(numeroSubastas)
                setCopia(numeroSubastas)
            }
        }
    }

    const traerUnaSubasta = async (row) => {
        //console.log(row.original)
        setInformacionSubasta(row.original)
        setCondicionSubasta(true)
        //setData(infoSubastaLiquidada)
        setCopia(false)
    }

    const cerrar = () => {
        //console.log("entra")
        setCondicionSubasta(false)
        setInformacionSubasta("")
        setCopia(true)
    }

    return (
        <>
           

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            <div className="fluid-container">
                <main className="main-min-height">
                    <div className="row row-wrap g-0">
                         
                        <div className="col-12 mb-5">
                            {condicionSubasta ?
                                <InfromacionSubastasOperadorDD  subastaData={informacionSubasta} textoFuncion1="Imprimir"  funcion2={cerrar} textoFuncion2="Cerrar" irDevuelta={cerrar}></InfromacionSubastasOperadorDD>
                                :
                                <> <BannerTitulo titulo="Subastas liquidadas"></BannerTitulo>
                                    {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                        <div className="d-flex">
                                            <div className="container py-5">
                                                {copia &&
                                                    <>
                                                        <TablaMercado datos={data} displayInformation={traerUnaSubasta} useValidate={useSubastasLiquidadasDepositanteD} textoBoton={"Ver información"} textTitle="Click para ver información" textoHeader="Ver información"></TablaMercado>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                </>

                            }
                        </div>
                    </div>

                </main >

            </div>
        </>
    );
}


