
import { instance } from '../apiGlobal';

export async function registrarEmision(obj) {
    
  try {
    const response = await instance(`/emision/registrar`, {
      method: "post",
      data: obj
    });
    
    return response;
  } catch (error) {
    return error
  }
}










