import React, { useState } from 'react';
import Stepper from 'react-stepper-horizontal';

//Steps
import RegistroDI1 from './RegistroDI1';
import REGISTRO_DI_1_5 from './REGISTRO_DI_1_5';
import RegistroDI2 from './RegistroDI2'
import RegistroDI3 from './RegistroDI3';
import RegistroDI4 from './RegistroDI4';
import RegistroDI5 from './RegistroDI5';
import RegistroDI5SinDocumentos from './RegistroDI5SinDocumentos';

//Components
import BannerTitulo from '../Bannertitulo';
import ComponenteModal from '../ComponenteModal';
import SpinnerStart from '../SpinnerStart';
import ManejadorErrores from '../ManejadorErrores';
import PantallaCargando from '../PantallaCargando';

//React context
import { FormProvider, FormContext } from './form_context';

//APIs
import { cambiarEstatusDepositante } from '../../api/cambiarEstatus.js/cambiarEstatus';
import { actualizarEstatusYDocumentos } from '../../api/cambiarEstatus.js/actualizarEstatusYDocumentos';
import { actualizarEstatusYDocumentosFuncionarioDeCumplimiento } from '../../api/cambiarEstatus.js/actualizarEstatusYDocumentosFuncionarioCumplimiento';
import { traerInformacionDepositnate } from '../../api/VerInformacionUsuarios/TraerInformacionDepositante';

const Form = ({ history }) => {
    if (history.location.state === undefined) {
        history.go(-1)
    }
    const [informacionUsuario, setInformnacionUsuario] = React.useState()
    const [validacionCorrecta, setValidacionCorrecta] = React.useState()
    const [validacionIncorrecta, setValidacionIncorrecta] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()
    const [value] = React.useContext(FormContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [mensaje, setMensaje] = useState();
    const [descripcion, setDescripcion] = useState();
    const [descripcionVacia, setDescripcionVacia] = React.useState(false)
    const [desplegrarDescripcion, setDesplegarDescripcion] = React.useState(false)

    const [documentosIncompletosSupervisados, setDocumentosIncompletosSupervisados] = React.useState(false)
    const [documentosIncompletosNoSupervisados, setDocumentosIncompletosNoSupervisados] = React.useState(false)
    const [mensajeModal, setMensajeModal] = React.useState("El proceso fue exitoso")

    const [documentosIncompletosFuncionario, setDocumentosIncompletosFuncionario] = React.useState(false)
    const [loading, setLoading] = React.useState(false);

    const banderaDescripcion = history.location.state.objMenu.banderaDescripcion

    const sections = [
        { title: 'Identificacion de la institución', onClick: () => setCurrentPage(1) },//, onClick: () => setCurrentPage(1) },
        { title: 'Información domicilio', onClick: () => setCurrentPage(2) },
        { title: 'Datos del representante legal', onClick: () => setCurrentPage(3) },
        { title: 'Procedencia de los recursos', onClick: () => setCurrentPage(4) },//, onClick: () => setCurrentPage(3) },
        { title: 'Instrucciones especiales', onClick: () => setCurrentPage(5) },
        { title: 'Firmas autorizadas', onClick: () => setCurrentPage(6) }
    ];

    React.useEffect(() => {
        traerInfo()
    }, [])

    const traerInfo = async () => {
        ////console.log(history.location.state.objMenu.codigo)
        //traemos la informacion del depositante con el folio y estatus
        const myObj = {
            folio: history.location.state.objMenu.folio,
            estatus: history.location.state.objMenu.estatus,
        }

        const traerInformacion = await traerInformacionDepositnate(myObj);
        ////console.log(traerInformacion)
        if (traerInformacion) {
            setSpin(false)
            if ((isNaN(traerInformacion.error)) === false) {
                setErroreshttp(traerInformacion)
            } else {
                //console.log(traerInformacion)
                setInformnacionUsuario(traerInformacion)
            }
        }
    }


    const next = () => setCurrentPage((prev) => prev + 1);
    const prev = () => setCurrentPage((prev) => prev - 1);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (value.datos.enviar === 0) {
            next()
        } else {
            //cambio de esattus de 1 a 2
            setLoading(true)
            if (history.location.state.objMenu.documentos === "Si") {//si hay que agregar documentacion soporte

                if (mensaje === "valido") {//entra cuando validamos la solicitud

                    if (value.datos.errorDocumentacionIncompletaSupervisada || value.datos.errorDocumentacionIncompletaNoSupervisada) {
                        // //console.log(("entra 3333"))
                        if (value.datos.errorDocumentacionIncompletaNoSupervisada) {
                            //console.log("no supervisados")
                            setDocumentosIncompletosNoSupervisados(true)

                        } else {
                            setDocumentosIncompletosSupervisados(true)
                        }


                    } else {


                        setLoading(true)
                        setDocumentosIncompletosNoSupervisados(false)
                        setDocumentosIncompletosSupervisados(false)
                        const myObj =
                        {
                            codigo: history.location.state.objMenu.folio,
                            estatus: history.location.state.objMenu.estatus + 1,
                            cehvalDocumentoSoporNsCopiaEscrSoc: value.datos.cehvalDocumentoSoporNsCopiaEscrSoc,
                            cehvalDocumentoSoporNsCopiaRtn: value.datos.cehvalDocumentoSoporNsCopiaRtn,
                            cehvalDocumentoSoporNsEntRegul: value.datos.cehvalDocumentoSoporNsEntRegul,
                            cehvalDocumentoSoporNsCopiaRtnAut: value.datos.cehvalDocumentoSoporNsCopiaRtnAut,
                            cehvalDocumentoSoporNsEstatus: value.datos.cehvalDocumentoSoporNsEstatus,
                            cehvalDocumentoSoporNsNotRemiProgCump: value.datos.cehvalDocumentoSoporNsNotRemiProgCump,
                            cehvalDocumentoSoporNsConstanciaExoVig: value.datos.cehvalDocumentoSoporNsConstanciaExoVig,
                            cehvalDocumentoSoporNsFirmaAutExt: value.datos.cehvalDocumentoSoporNsFirmaAutExt,
                            cehvalDocumentoSoporNsCopiaNomFunCump: value.datos.cehvalDocumentoSoporNsCopiaNomFunCump,
                            cehvalDocumentoSoporNsCopiaDni: value.datos.cehvalDocumentoSoporNsCopiaDni,
                            cehvalDocumentoSoporNsExpComp: value.datos.cehvalDocumentoSoporNsExpComp,
                            cehvalDocumentoSoporNsCopiaPoderAdm: value.datos.cehvalDocumentoSoporNsCopiaPoderAdm,
                            contratoDeDepositante: value.datos.contratoDeDepositante,
                            descripcion: descripcion,
                            firmasStatus: [
                                {
                                    idFirma: value.datos.idFirma1,
                                    status: value.datos.estatusFirma1
                                },
                                {
                                    idFirma: value.datos.idFirma2,
                                    status: value.datos.estatusFirma2
                                }
                            ]
                        }
                        //console.log(myObj)
                        const modificarEstatusYDocumentos = await actualizarEstatusYDocumentos(myObj)


                        if (modificarEstatusYDocumentos) {
                            setLoading(false)
                            if ((isNaN(modificarEstatusYDocumentos.error)) === false) {
                                // respuestaincorrecta           
                                setErroreshttp(modificarEstatusYDocumentos)
                            } else {

                                if (modificarEstatusYDocumentos?.codigo === "000") {
                                    setValidacionCorrecta(true)
                                } else {
                                    setValidacionIncorrecta(true)
                                }
                            }
                        }
                    }



                } else {

                    if (descripcion) {
                        setDescripcionVacia(false)
                        setLoading(true)
                        const myObj2 =
                        {
                            codigo: history.location.state.objMenu.folio,
                            estatus: 0,
                            cehvalDocumentoSoporNsCopiaEscrSoc: value.datos.cehvalDocumentoSoporNsCopiaEscrSoc,
                            cehvalDocumentoSoporNsCopiaRtn: value.datos.cehvalDocumentoSoporNsCopiaRtn,
                            cehvalDocumentoSoporNsEntRegul: value.datos.cehvalDocumentoSoporNsEntRegul,
                            cehvalDocumentoSoporNsCopiaRtnAut: value.datos.cehvalDocumentoSoporNsCopiaRtnAut,
                            cehvalDocumentoSoporNsEstatus: value.datos.cehvalDocumentoSoporNsEstatus,
                            cehvalDocumentoSoporNsNotRemiProgCump: value.datos.cehvalDocumentoSoporNsNotRemiProgCump,
                            cehvalDocumentoSoporNsConstanciaExoVig: value.datos.cehvalDocumentoSoporNsConstanciaExoVig,
                            cehvalDocumentoSoporNsFirmaAutExt: value.datos.cehvalDocumentoSoporNsFirmaAutExt,
                            cehvalDocumentoSoporNsCopiaNomFunCump: value.datos.cehvalDocumentoSoporNsCopiaNomFunCump,
                            cehvalDocumentoSoporNsCopiaDni: value.datos.cehvalDocumentoSoporNsCopiaDni,
                            cehvalDocumentoSoporNsExpComp: value.datos.cehvalDocumentoSoporNsExpComp,
                            cehvalDocumentoSoporNsCopiaPoderAdm: value.datos.cehvalDocumentoSoporNsCopiaPoderAdm,
                            contratoDeDepositante: value.datos.contratoDeDepositante,
                            descripcion: descripcion,
                            firmasStatus: [
                                {
                                    idFirma: value.datos.idFirma1,
                                    status: value.datos.estatusFirma1
                                },
                                {
                                    idFirma: value.datos.idFirma2,
                                    status: value.datos.estatusFirma2
                                }
                            ]
                        }
                        const modificarEstatusYDocumentos2 = await actualizarEstatusYDocumentos(myObj2)
                        if (modificarEstatusYDocumentos2) {
                            setLoading(false)
                            if ((isNaN(modificarEstatusYDocumentos2.error)) === false) {
                                // respuestaincorrecta           
                                setErroreshttp(modificarEstatusYDocumentos2)
                            } else {

                                if (modificarEstatusYDocumentos2?.codigo === "000") {
                                    setValidacionCorrecta(true)
                                } else {
                                    setValidacionIncorrecta(true)
                                }
                            }
                        }

                    } else {
                        setDescripcionVacia(true)
                    }


                }
            } else {//sin documentacion
                if (mensaje === "valido") {//solicitud valida

                    //estatus de 2 a 3 agregamos dos documentos
                    if (history.location.state.objMenu.estatus === 2) {
                        //console.log(value.datos.errorDocumentacionIncompletaFuncionario)

                        if (value.datos.errorDocumentacionIncompletaFuncionario) {
                            setDocumentosIncompletosFuncionario(true)
                        } else {
                            setLoading(true)
                            const myObj1 =
                            {
                                codigo: history.location.state.objMenu.folio,
                                estatus: history.location.state.objMenu.estatus + 1,
                                descripcion: descripcion,
                                revisionListaOfac: value.datos.revisionListaOfac,
                                debidaDiligenciaCEHVAL: value.datos.debidaDiligenciaCEHVAL,
                                certificacionDebidaDiligenciaRecibida: value.datos.certificacionDebidaDiligenciaRecibida

                            }

                            const modificarEstatusConDocumentos = await actualizarEstatusYDocumentosFuncionarioDeCumplimiento(myObj1)

                            if (modificarEstatusConDocumentos) {
                                setLoading(false)
                                if ((isNaN(modificarEstatusConDocumentos.error)) === false) {
                                    // respuestaincorrecta           
                                    setErroreshttp(modificarEstatusConDocumentos)
                                } else {


                                    if (modificarEstatusConDocumentos?.codigo === "000") {
                                        setValidacionCorrecta(true)

                                    } else {
                                        setValidacionIncorrecta(true)
                                    }
                                }
                            }
                        }




                    } else {
                        let estatusAEnviar;
                        //cambia de 0 a 6
                        if (banderaDescripcion === 1) {
                            estatusAEnviar = 6
                        } else {
                            //cambia de n a n+1
                            estatusAEnviar = history.location.state.objMenu.estatus + 1
                        }

                        const myObj =
                        {
                            folio: history.location.state.objMenu.folio,
                            estatus: estatusAEnviar,
                            descripcion: descripcion,
                        }


                        const modificarEstatus = await cambiarEstatusDepositante(myObj)

                        if (modificarEstatus) {
                            setLoading(false)
                            if ((isNaN(modificarEstatus.error)) === false) {
                                // respuestaincorrecta           
                                setErroreshttp(modificarEstatus)
                            } else {

                                if (modificarEstatus?.codigo === "000") {
                                    if (banderaDescripcion !== 1) {
                                        setMensajeModal(`Registro exitoso, código de depositante asignado ${modificarEstatus.mensaje}`)
                                    }

                                    setValidacionCorrecta(true)


                                } else {
                                    setValidacionIncorrecta(true)
                                }
                            }
                        }
                    }



                } else {

                    if (descripcion) {
                        setDescripcionVacia(false)
                        setLoading(true)

                        let modificarEstatus2;

                        if (history.location.state.objMenu.estatus === 2) {

                            const objeto1 =
                            {
                                codigo: history.location.state.objMenu.folio,
                                estatus: 0,
                                descripcion: descripcion,
                                revisionListaOfac: value.datos.revisionListaOfac,
                                debidaDiligenciaCEHVAL: value.datos.debidaDiligenciaCEHVAL,
                                certificacionDebidaDiligenciaRecibida: value.datos.certificacionDebidaDiligenciaRecibida
                            }
                            modificarEstatus2 = await actualizarEstatusYDocumentosFuncionarioDeCumplimiento(objeto1)
                            if (modificarEstatus2) {
                                setLoading(false)
                                if ((isNaN(modificarEstatus2.error)) === false) {
                                    // respuestaincorrecta           
                                    setErroreshttp(modificarEstatus2)
                                } else {

                                    if (modificarEstatus2?.codigo === "000") {
                                        setValidacionCorrecta(true)

                                    } else {
                                        setValidacionIncorrecta(true)
                                    }
                                }
                            }

                        } else {
                            const objeto2 = {
                                folio: history.location.state.objMenu.folio,
                                estatus: 0,
                                descripcion: descripcion,
                            }


                            modificarEstatus2 = await cambiarEstatusDepositante(objeto2)
                            if (modificarEstatus2) {
                                setLoading(false)
                                if ((isNaN(modificarEstatus2.error)) === false) {
                                    // respuestaincorrecta           
                                    setErroreshttp(modificarEstatus2)
                                } else {

                                    if (modificarEstatus2?.codigo === "000") {
                                        setValidacionCorrecta(true)

                                    } else {
                                        setValidacionIncorrecta(true)
                                    }
                                }
                            }

                        }

                    }
                    else {
                        setDescripcionVacia(true)
                    }

                }
            }
        }

    }


    const cancelarValidadcionCorrecta = () => {
        setLoading(false)
        setValidacionCorrecta(false)
        history.go(-1)
    }

    const cancelarValidadcionIncorrecta = () => {
        setLoading(false)
        setValidacionIncorrecta(false)
        history.go(-1)
    }

    const IrMenu = () => {
        history.go(-1)
    }

    return (
        <>

            <div className='fluid-container'>
                {loading &&
                    <PantallaCargando />
                }


                {documentosIncompletosFuncionario &&
                    <ComponenteModal mostrarModal={documentosIncompletosFuncionario} cerrarModal={() => setDocumentosIncompletosFuncionario(false)} titulo="No se puede aceptar, sin validaciones de la unidad de cumplimiento" textoBoton="Entendido"></ComponenteModal>
                }
                {documentosIncompletosSupervisados &&
                    <ComponenteModal mostrarModal={documentosIncompletosSupervisados} cerrarModal={() => setDocumentosIncompletosSupervisados(false)} titulo="Falta documentación de instituciones supervisadas" textoBoton="Entendido"></ComponenteModal>
                }

                {documentosIncompletosNoSupervisados &&
                    <ComponenteModal mostrarModal={documentosIncompletosNoSupervisados} cerrarModal={() => setDocumentosIncompletosNoSupervisados(false)} titulo="Falta documentación de instituciones no supervisadas" textoBoton="Entendido"></ComponenteModal>
                }

                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }

                {validacionCorrecta &&
                    <ComponenteModal mostrarModal={validacionCorrecta} cerrarModal={cancelarValidadcionCorrecta} titulo={mensajeModal} textoBoton="Entendido"></ComponenteModal>
                }


                {validacionIncorrecta &&
                    <ComponenteModal mostrarModal={validacionIncorrecta} cerrarModal={cancelarValidadcionIncorrecta} titulo="El proceso fallo, revise su información e intentelo de nuevo" textoBoton="Entendido"></ComponenteModal>
                }


                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo={history.location.state.objMenu.titulo}></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <div className='container'>
                                            <div className="row contenedorsteper" id='contenedorsteper'>
                                                <div className="col-12 contenedorsteper font-acumin-variable">
                                                    <div id="stepperComponent">
                                                        <Stepper
                                                            steps={sections}
                                                            activeStep={currentPage - 1}
                                                            activeColor="#F4CA85"
                                                            completeColor="#242848"
                                                            completeBarColor="#242848"
                                                            titleFontSize={15}
                                                            size={30}
                                                            circleFontSize={15}
                                                            className="steper"
                                                        />
                                                    </div>



                                                    <div className="  claseSinBorder">
                                                        <div className="card-body">
                                                            <div className="">
                                                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                                                    <>
                                                                        {informacionUsuario &&
                                                                            <form onSubmit={handleSubmit} >
                                                                                <br></br>
                                                                                {currentPage === 1 && (
                                                                                    <>
                                                                                        <div id="primeraSeccion"  >

                                                                                            <div className="contenedorFormularioDepositanteDirecto card" >
                                                                                                <RegistroDI1 info={informacionUsuario} />
                                                                                                <div className='mb-3 text-end p-3'>
                                                                                                    <button type="button" className="btn botonFormularioDepositante font-acumin-variable" onClick={next}>Siguiente</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}

                                                                                {currentPage === 2 && (
                                                                                    <>
                                                                                        <div id="primeraSeccionIntermedia " >
                                                                                            <div className="contenedorFormularioDepositanteDirecto card " >
                                                                                                <REGISTRO_DI_1_5 info={informacionUsuario} />
                                                                                                <div className='contenedorBotonesStteper p-3' >
                                                                                                    <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                                                                                    <button type="button" className="btn botonFormularioDepositante" onClick={next}>Siguiente</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </>
                                                                                )}

                                                                                {currentPage === 3 && (
                                                                                    <>
                                                                                        <div id="segundaSeccion" >
                                                                                            <div className="contenedorFormularioDepositanteDirecto card " >
                                                                                                <RegistroDI2 info={informacionUsuario} />
                                                                                                <br></br>
                                                                                                <div className='contenedorBotonesStteper p-3' >
                                                                                                    <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                                                                                    <button type="button" className="btn botonFormularioDepositante" onClick={next}>Siguiente</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}

                                                                                {currentPage === 4 && (
                                                                                    <>
                                                                                        <div id="terceraSeccion" >
                                                                                            <div className="contenedorFormularioDepositanteDirecto card " >
                                                                                                <RegistroDI3 info={informacionUsuario} />

                                                                                                <div className='contenedorBotonesStteper p-3'>
                                                                                                    <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                                                                                    <button type="button" className="btn botonFormularioDepositante" onClick={next}>Siguiente</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}

                                                                                {currentPage === 5 && (
                                                                                    <>
                                                                                        <div id="cuartaSeccion" >
                                                                                            <div className="contenedorFormularioDepositanteDirecto card " >
                                                                                                <RegistroDI4 info={informacionUsuario} />
                                                                                                <br></br>
                                                                                                <div className='contenedorBotonesStteper p-3'>
                                                                                                    <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                                                                                    <button type="button" className="btn botonFormularioDepositante" onClick={next}>Siguiente</button>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}

                                                                                {currentPage === 6 && (
                                                                                    <>
                                                                                        <div id="quintaSeccion" >
                                                                                            <div className="card contenedorFormularioDepositanteDirecto card ">
                                                                                                {history.location.state.objMenu.documentos === "Si" ?
                                                                                                    <RegistroDI5 info={informacionUsuario} />
                                                                                                    :
                                                                                                    <RegistroDI5SinDocumentos info={informacionUsuario} banderaDescripcion={banderaDescripcion} />
                                                                                                }

                                                                                                <br></br>


                                                                                                {history.location.state.objMenu.estatus === 5 ?//depositantes registrados
                                                                                                    <>
                                                                                                        <div className='contenedorBotonesStteper p-3' >
                                                                                                            <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                                                                                            <button type="button" className="btn botonFormularioDepositanteAprobar" onClick={() => {
                                                                                                                IrMenu()
                                                                                                            }}>{history.location.state.objMenu.textoBoton12}</button>
                                                                                                        </div>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>

                                                                                                        {banderaDescripcion === 1 ? //solicitudes rechazadas con estatus 0 pasan a 6
                                                                                                            <div className='contenedorBotonesStteper p-3' >
                                                                                                                <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                                                                                                <button type="submit" className="btn botonFormularioDepositanteAprobar" onClick={() => {
                                                                                                                    setMensaje("valido")
                                                                                                                }}>{history.location.state.objMenu.textoBoton1}</button>

                                                                                                            </div>
                                                                                                            :
                                                                                                            <>
                                                                                                                {desplegrarDescripcion ?
                                                                                                                    <div className='p-3'>
                                                                                                                        <div class="form-floating">
                                                                                                                            <textarea class="form-control" placeholder="Leave a comment here" onChange={(e) => {
                                                                                                                                setDescripcion(e.target.value)
                                                                                                                            }} id="floatingTextarea2" style={{ height: "7rem" }} required></textarea>
                                                                                                                            <label for="floatingTextarea2">Escriba el motivo del rechazo</label>
                                                                                                                        </div>
                                                                                                                        <div className='contenedorBotonesStteper  '>
                                                                                                                            <button type="button" className="btn botonFormularioDepositanteRechazar mt-2" onClick={() => {
                                                                                                                                setDesplegarDescripcion(false)
                                                                                                                            }}>Cancelar</button>
                                                                                                                            <button type="submit" className="btn botonFormularioDepositante  mt-2" onClick={() => {
                                                                                                                                setMensaje("invalido")
                                                                                                                            }}>Enviar</button>
                                                                                                                        </div>

                                                                                                                        {descripcionVacia &&
                                                                                                                            <p className="error mt-2">Necesitas escribir el motivo del rechazo</p>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    <div className='contenedorBotonesStteper p-3' >
                                                                                                                        <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                                                                                                        <button type="submit" className="btn botonFormularioDepositante" onClick={() => {
                                                                                                                            setMensaje("valido")

                                                                                                                        }}>{history.location.state.objMenu.textoBoton1}</button>

                                                                                                                        <button type="button" className="btn botonFormularioDepositante" onClick={() => {
                                                                                                                            setDesplegarDescripcion(true)
                                                                                                                        }}>{history.location.state.objMenu.textoBoton12}</button>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </>
                                                                                                        }
                                                                                                    </>
                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </form>}
                                                                    </>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </>
    );
};

export default function VerInfoDepositanteDirectoPerJuridicaDoc({ history }) {
    return (

        <FormProvider>
            <Form history={history} />
        </FormProvider>

    );
}

