import React from "react";
import '../../../../styles/stylesheet.css'
import { traerCasasBolsa } from '../../../../api/catalogos/traerCasasBolsa'
import { FormContext } from './form_context';
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";

export default function RegistroDI6({ tipo }) {
    const [value, setValue] = React.useContext(FormContext);
    const [hayCasaBolsa, setHayCasaBolsa] = React.useState()
    const [casasBolsa, setCasasBolsa] = React.useState([])
    const [codigo, setCodigo] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [nombreCasaBolsa,setNombreCasaBolsa]=React.useState()

    const guardarDatos = (e) => {
        const { name, value } = e.target;
        if (name === "nombreBolsa") {
            //console.log(value)
            setCodigo(value)
        }
        setValue((prev) => {
            const datos = { ...prev.datos, [name]: value };
            return { ...prev, datos };
        });
    }

    const definirCasaBolsa = (e) => {
        //console.log(e.target.value)
        if (e.target.value === "SI") {
            setValue((prev) => {
                const datos = { ...prev.datos, ["nombreBolsa"]: "" };
                return { ...prev, datos };
            });
            setCodigo("")
            setHayCasaBolsa(false)

        } else {
            setHayCasaBolsa(true)
        }
    }

    React.useEffect(() => {
        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 0 };
            return { ...prev, datos };
        });

        traerCasasDeBolsa()
    }, [])

    const traerCasasDeBolsa = async () => {
        ////console.log(actividadE)
        const catalogoCasasBolsa = await traerCasasBolsa()
        //console.log(catalogoCasasBolsa)
        if (catalogoCasasBolsa) {
            //setSpin(false)
            if ((isNaN(catalogoCasasBolsa.error)) === false) {
              
                setErroreshttp(catalogoCasasBolsa)
                
            } else {
                // //console.log(traerInformacion)
                setCasasBolsa(catalogoCasasBolsa)
                if(tipo==="depositanteIndirecto"){
                    const codigoPivote=(JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo
                    const arregloCasaBolsa=catalogoCasasBolsa.filter(word => word.cehvalDepositanteCodigoCehval===codigoPivote)
                    setNombreCasaBolsa(arregloCasaBolsa[0].cehvalDepositantePersonaIcbiId.cehvalPersonaJurNombre)
                    setCodigo(codigoPivote)   
                    setValue((prev) => {
                        const datos = { ...prev.datos, ["nombreBolsa"]: codigoPivote };
                        return { ...prev, datos };
                    });
                }
            }
        }
    }


    return (
        <>
            {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }

            <div className="card-body pt-3 ">
                <h5 className="text-royal-blue font-acumin-variable " >VI. Casa de bolsa intermediaria</h5>

                {tipo === "depositanteDirecto" ?
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div className="border rounded p-3" >
                                <p>¿Eres una casa de bolsa?</p>
                                <div className="d-flex justify-content-start">
                                    <div className="contenedorBotonesStteper">
                                        < input placeholder="" type="radio" className="form-check-input me-2" value={"SI"} onChange={(e) => definirCasaBolsa(e)} id="existeCasaBolsa1" name="existeCasaBolsa" required />
                                        <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="existeCasaBolsa1">Si</label>
                                    </div>

                                    <div className="contenedorBotonesStteper">
                                        < input placeholder="" type="radio" className="form-check-input me-2" value={"NO"} onChange={(e) => definirCasaBolsa(e)} id="existeCasaBolsa2" name="existeCasaBolsa" required />
                                        <label className="form-check-label textoFormularioDepositantedirecto mt-1" htmlFor="existeCasaBolsa2">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {hayCasaBolsa &&
                            <>
                                <div className="col-md-4 form-floating mb-3">
                                    <p className="text-royal-blue font-acumin-variable text-start" >Selecciona la casa de bolsa</p>
                                    <div className="form-floating mb-2">
                                        <select className="form-select border  " value={value.datos.nombreBolsa} onChange={(e) => guardarDatos(e)} id="nombreBolsa" name="nombreBolsa" required>
                                            <option value=""> </option>
                                            {
                                                casasBolsa.map((item, index) => (
                                                    <option key={index} value={item.cehvalDepositanteCodigoCehval} >{item.cehvalDepositantePersonaIcbiId.cehvalPersonaJurNombre}</option>
                                                ))
                                            }
                                        </select>
                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Casa de bolsa intermediaria *</label>
                                    </div>
                                </div>

                                <div className="col-md-2 form-floating d-flex align-items-end mb-3">
                                    <div className="form-floating mb-2">
                                        < input disabled type="text" className="form-control border  " value={codigo} id="cargo_6" name="cargo_6" />
                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Código *</label>
                                    </div>

                                </div>
                            </>
                        }

                    </div>
                    :
                    <div className="row mb-3">
                        <>
                            <div className="col-md-4  mb-3">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control border  " value={nombreCasaBolsa} id="cargo_6" name="cargo_6" />
                                    <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Casa de bolsa intermediaria *</label>
                                </div>
                            </div>
                            
                            <div className="col-md-2 form-floating d-flex align-items-end mb-3">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control border  " value={codigo} id="cargo_6" name="cargo_6" />
                                    <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Código *</label>
                                </div>

                            </div>
                        </>
                    </div>
                }

            </div>
        </>
    );
}

