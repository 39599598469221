//React
import React from "react";
import { Link } from 'react-router-dom';

//Componentes generales
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";



//Imagenes
import imagenRegistroSolicitudDepositante from '../../../assets/resguardoValoresFisicos/registroSolicitudDepotitante.jpg';
import imagenDepositantesRegistrados from '../../../assets/resguardoValoresFisicos/depositantesRegistrados.jpg';
import imagenDepositantesAutorizados from '../../../assets/resguardoValoresFisicos/depositantesAutorizados.jpg';
import imagenDepositantesRechazados from '../../../assets/resguardoValoresFisicos/depositantesRechazados.jpg';


export default function MenuResguardoDepositantesValoresFisicos() {

    return (
        <>
            <div className="fluid-container">
                
                <main className="min-height-40">
                    <div className="row row-wrap g-0">
                        

                        <div className="col-12">
                            <div className="">
                                <BannerTitulo className="mb-5" titulo="Menú registro depositantes de valores físicos"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container font-acumin-variable py-5 mb-5">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-4">
                                                <Link to="/solicitarRegistroDepValoresFisicos" className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="" src={imagenRegistroSolicitudDepositante} alt="imagenFondo" />
                                                        <div className="card-body row align-items-center justity-content-center text-center height-150px">
                                                            <h5 className="card-title text-royal-blue">Solicitud de registro de depositante de valores físicos</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-4">
                                                <Link to="/depositantesValoresFisicosRegistrados" className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="" src={imagenDepositantesRegistrados} alt="imagenFondo" />
                                                        <div className="card-body row align-items-center justity-content-center text-center height-150px">
                                                            <h5 className="card-title text-royal-blue">Depositantes de valores fìsicos registrados</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-4">
                                                <Link to="/solicitudesDepValoresFisicosAutorizadas" className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="" src={imagenDepositantesAutorizados} alt="imagenFondo" />
                                                        <div className="card-body row align-items-center justity-content-center text-center height-150px">
                                                            <h5 className="card-title text-royal-blue">Notificación de solicitudes autorizadas de registro de depositantes de valores físicos</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-4">
                                                <Link to="/solicitudesDepValoresFisicosRechazadas" className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="" src={imagenDepositantesRechazados} alt="imagenFondo" />
                                                        <div className="card-body row align-items-center justity-content-center text-center height-150px">
                                                            <h5 className="card-title text-royal-blue">Notificación de solicitudes rechazadas de registro de depositantes de valores físicos</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    );
}

