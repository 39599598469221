//React
import React, { useState, useEffect } from "react";



//Componentes generales
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores';
import TablaUsuarios from "../../../../ComponentesGenerales/TablaUsuarios";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";

//APIs
import { traerSolicitudesDeLiberacion } from "../../../../api/prendasYGarantias/SolicitudesDeLiberacionGarantia/traerSolicitudesDeLiberacion";

//Hooks
import useSolicitudesLiberacionGarantia from '../../../../hooks/PrendasYGarantias/useSolicitudesLiberacionGarantia';


export default function TablaSolicitudesLiberacionDeGarantia({ history }) {


    //Save loaded data
    const [copia, setCopia] = useState();
    const [data, setData] = useState([]);

    //Spinner
    const [spin, setSpin] = useState(true);

    //States to handle load data when loading page 
    const [erroreshttp, setErroreshttp] = useState();


    const [failLoadData, setFailLoadData] = useState();


    useEffect(() => {
        getAllConstitutionRequests();
    }, []);


    const getAllConstitutionRequests = async () => {
        try {
            const response = await traerSolicitudesDeLiberacion({ estatus: 1 });
            if (response) {
                setSpin(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    if (response.data) {
                        setSpin(false);
                        setData(response.data);
                        setCopia(response.data)
                    }  else {
                        setFailLoadData("No se pudo cargar la información");
                    }

                }
            }


        } catch (err) {
            console.error(err);
        }
    }



    const viewRequestConstitucion = (row) => {

        const folio = row.original.cehvalSolicitudLiberacionGarantiaFolio;

        const selectedRowData = data.filter((row) => row.cehvalSolicitudLiberacionGarantiaFolio === folio);

        //Mandar el objeto de la emision que el usuario desea ver
        history.push({
            pathname: '/autorizarLiberacionDeGarantia', state: { objConstitution: selectedRowData }
        });
    }




    const goBack = () => {
        history.push({
            pathname: '/inicioCehval',
        })
    }


    return (
        <>

           
            {failLoadData &&
                <ComponenteModal mostrarModal={failLoadData} cerrarModal={goBack} titulo={failLoadData} textoBoton="Aceptar"></ComponenteModal>
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <div className="">

                <main className="main-min-height">
                    <div className="row row-wrap g-0">
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Solicitudes de liberación o ejecución de prenda o garantía por autorizar"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaUsuarios datos={data} displayInformation={viewRequestConstitucion} useValidate={useSolicitudesLiberacionGarantia} textoBoton={"Abrir solicitud"} textTitle="Click para ver la información de la serie"></TablaUsuarios>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}





