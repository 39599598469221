import React, { useState, useEffect } from "react";



//Components

import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";

import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { registrarSeriesConFechas } from "../../../api/PagoInteresesAmorDiv/registrarSeriesConFechas";

export default function FormularioAgregarFechas({ history }) {
    const [erroreshttp, setErroreshttp] = useState();
    //Errors and messages
    const [procesoExitoso, setProcesoExitoso] = useState();
    const [procesoFallido, setProcesoFallido] = useState();
    const [mensaje, setMensaje] = useState();

    const [datos, setDatos] = React.useState()
    const [fechaEmision, setFechaEmision] = React.useState()

    


    const [cantidadPagosIntereses, setCantidadPagosIntereses] = React.useState()
    const [diaMesIntereses, setDiaMesIntereses] = React.useState()
    const [arregloPagosIntereses, setArregloPagosIntereses] = React.useState()
    const [arregloFechasIntereses, setArregloFechasIntereses] = React.useState()
    const [sinPagosIntereses, setSinPagosIntereses] = React.useState()

    const [cantidadPagosAmortizaciones, setCantidadPagosAmortizaciones] = React.useState()
    const [diaMesAmortizaciones, setDiaMesAmortizaciones] = React.useState()
    const [arregloPagosAmortizaciones, setArregloPagosAmortizaciones] = React.useState()
    const [arregloFechasAmortizaciones, setArregloFechasAmortizaciones] = React.useState()
    const [sinPagosAmortizaciones, setSinPagosAmortizaciones] = React.useState()
    const [sinDiaAmortizaciones, setSinDiaAmortizaciones] = React.useState()





    const [variableInteres, setVariableInteres] = React.useState()
    const [variableInteres2, setVariableInteres2] = React.useState()

    const [variableAmortizacion, setVariableAmortizacion] = React.useState()
    const [variableAmortizacion2, setVariableAmortizacion2] = React.useState()

    const [valorIncorrecto, setValorIncorrecto] = React.useState()
    const [sinDatos, setSinDatos] = React.useState()
    const [loading, setLoading] = React.useState(false);



    useEffect(() => {
        guardarDatos();
    }, []);




    const guardarDatos = async () => {
        if (history.location.state === undefined) {
            history.go(-1)
        } else {

            //  const fecha=history.location.state.objMenu.fechaEmision.replaceAll('/', '-')
            //console.log(history.location.state.objMenu.fechaEmision)
            const arrarf = history.location.state.objMenu.fechaEmision.split("/");

            const fechaE = arrarf[1] + "/" + arrarf[0] + "/" + arrarf[2]
            //var fecha = new Date(history.location.state.objMenu.fechaEmision);
            var fecha = new Date(fechaE);
            //console.log(fecha)

            const añoActual = fecha.getFullYear();
            let diaActual = fecha.getDate();
            //console.log(diaActual)
            let mesActual = fecha.getMonth() + 1;
            //console.log(mesActual)

            if (diaActual < 10) {
                diaActual = "0" + diaActual;
            }

            if (mesActual < 10) {
                mesActual = "0" + mesActual;
            }

            setFechaEmision((añoActual) + "-" + mesActual + "-" + diaActual)
            setDatos(history.location.state.objMenu)
        }
    }


    const onSubmitForm = async (e) => {
        e.preventDefault();
        if (arregloFechasAmortizaciones && arregloFechasIntereses && diaMesAmortizaciones && diaMesIntereses) {
            setLoading(true);
            const myObj = {
                codigoSerie: datos.codigoSerie,
                nombreSerie: datos.nombreSerie,
                isin: datos.isin,
                nombreEmisor: datos.nombreEmisor,
                nombreEmision: datos.nombreEmision,
                fechaEmision: datos.fechaEmision,
                fechaVencimiento: datos.fechaVencimiento,
                periodicidad: datos.periodicidad,
                cantidadPagos: datos.cantidadPagos,
                diaMesPI: diaMesIntereses,
                diaMesPA: diaMesAmortizaciones,
                fechasPagoIntereses: arregloFechasIntereses,
                fechasPagoAmortizaciones: arregloFechasAmortizaciones
            }

            const response = await registrarSeriesConFechas(myObj);
            //console.log(response)
            setLoading(false);
            if (response) {
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response);
                } else {
                    if (response.codigo === "000") {
                        ////console.log("proceso exitoso")
                        setProcesoExitoso(true);
                        setMensaje("Fechas de pago de intereses y amortizaciones registradas");
                    } else {
                       // //console.log("proceso fallido")
                        setProcesoFallido(true);
                        setMensaje(response.mensaje);
                    }
                }
            }

            ////console.log(myObj)
            // setDobleConfirmacion(true)
        } else {
            setSinDatos(true)
        }


    }




    const cerrarModal = () => {
        //console.log("entrafondos virtuales")
        history.push({
            pathname: '/agregarFechasPagoInteresesAmortizaciones'
        })

    }

    const validarFecha = (fecha) => {
        const DATE_REGEX = /^(0[1-9]|[1-2]\d|3[01])(\/)(0[1-9]|1[012])\2(\d{4})$/
        //const CURRENT_YEAR = new Date().getFullYear()

        /* Comprobar formato dd/mm/yyyy, que el no sea mayor de 12 y los días mayores de 31 */
        if (!fecha.match(DATE_REGEX)) {
            return false
        }

        /* Comprobar los días del mes */
        const day = parseInt(fecha.split('/')[0])
        const month = parseInt(fecha.split('/')[1])
        const year = parseInt(fecha.split('/')[2])
        const monthDays = new Date(year, month, 0).getDate()
        if (day > monthDays) {
            return false
        }

        /* Comprobar que el año no sea superior al actual*/
        //  if (year > CURRENT_YEAR) {
        //  return false
        //}
        return true



    }


    const establecerNumeroPagosIntereses = () => {

        if (cantidadPagosIntereses) {
            if (cantidadPagosIntereses === "1") {
                ////console.log("pagos")
                let arregloNumeroFechas = [1]
                const arrarf = datos.fechaVencimiento.split("/");
                const fechaE = arrarf[1] + "/" + arrarf[0] + "/" + arrarf[2]

                var fecha = new Date(fechaE);
                //console.log(fecha)
                const añoActual = fecha.getFullYear();
                let diaActual = fecha.getDate();
                //var diaActual = diaMesIntereses
                let mesActual = fecha.getMonth() + 1;
                if (diaActual < 10) {
                    diaActual = "0" + diaActual;
                }

                if (mesActual < 10) {
                    mesActual = "0" + mesActual;
                }

                var arregloFechas = [añoActual + "-" + mesActual + "-" + diaActual];
                //console.log(arregloFechas)
                //console.log(arregloNumeroFechas)

                setDiaMesIntereses(diaActual)
                setArregloFechasIntereses(arregloFechas)
                //Envía el array creado en el for a nuestro estado numberOfSeries
                setArregloPagosIntereses(arregloNumeroFechas);
                setVariableInteres(true)
            } else {
                if (diaMesIntereses) {
                    if (diaMesIntereses > 31) {
                        setValorIncorrecto(true)
                    } else {
                        //console.log(datos.periodicidad)
                        let periodicidadMeses = 1;
                        if (datos.periodicidad === 0) {
                            periodicidadMeses = 1
                        } else if (datos.periodicidad === 1) {
                            periodicidadMeses = 1
                        } else if (datos.periodicidad === 2) {
                            periodicidadMeses = 6
                        } else if (datos.periodicidad === 3) {
                            periodicidadMeses = 4
                        } else if (datos.periodicidad === 4) {
                            periodicidadMeses = 3
                        } else if (datos.periodicidad === 6) {
                            periodicidadMeses = 2
                        } else if (datos.periodicidad === 12) {
                            periodicidadMeses = 1
                        }

                        const arrarf = datos.fechaEmision.split("/");
                        const fechaE = arrarf[1] + "/" + arrarf[0] + "/" + arrarf[2]

                        var fecha = new Date(fechaE);
                        //console.log(fecha)
                        const añoActual = fecha.getFullYear();
                        //let diaActual = fecha.getDate();
                        var diaActual = diaMesIntereses
                        let mesActual = fecha.getMonth() + 1;
                        //var mesActual = fecha.getDate();

                        // //console.log(mesActual)
                        /*  if (añoActual < 1900) {
                              setErrorFormatoFecha1(true)
                          } else {
                              setErrorFormatoFecha1(false)
                          }*/

                        if (diaActual < 10) {
                            diaActual = "0" + diaActual;
                        }

                        if (mesActual < 10) {
                            mesActual = "0" + mesActual;
                        }

                        /* const fecha_limite_vencimiento = (añoActual) + "-" + mesActual + "-" + (diaActual);
                         // //console.log("hola")
                         ////console.log(fecha_limite_vencimiento)
                         setFecha_Vencimiento(fecha_limite_vencimiento)*/
                        ////console.log(e)



                        let arrayNumeroFechas = [];
                        let arregloFechas = []
                        //Si el input del usuario no es null itera sobre ese numero para crear el array
                        if (cantidadPagosIntereses != null) {
                            let mesPivote = parseInt(mesActual, 10);
                            let añoPivote = parseInt(añoActual, 10);
                            let diaPivote = parseInt(diaActual, 10);
                            // //console.log(mesActual)
                            ////console.log(periodicidadMeses)
                            for (let i = 0; i < cantidadPagosIntereses; i++) {
                                diaPivote = parseInt(diaActual, 10)
                                ////console.log(mesPivote)
                                arrayNumeroFechas.push(i + 1);
                                mesPivote = mesPivote + periodicidadMeses
                                if (mesPivote > 12) {
                                    mesPivote = mesPivote - 12;
                                    añoPivote++
                                }
                                let variableValidacion = 0;

                                for (; variableValidacion === 0;) {
                                    if (validarFecha((diaPivote.toString().padStart(2, '0')) + "/" + (mesPivote.toString().padStart(2, '0')) + "/" + (añoPivote))) {
                                        //console.log("true")
                                        variableValidacion = 1
                                    } else {
                                        //console.log(diaPivote)
                                        // //console.log(mesPivote)
                                        ////console.log(añoPivote)
                                        //console.log("false")
                                        // variableValidacion=1
                                        diaPivote--;

                                    }
                                }

                                ////console.log(validarFecha((añoPivote) + "-" + mesPivote.toString().padStart(2, '0') + "-" + (diaActual)))


                                arregloFechas.push((añoPivote) + "-" + mesPivote.toString().padStart(2, '0') + "-" + (diaPivote.toString().padStart(2, '0')))
                                //setSeriesReady(false);
                            }

                            ////console.log(arrayNumeroFechas)
                            // //console.log(arregloFechas)
                            setArregloFechasIntereses(arregloFechas)

                            //Envía el array creado en el for a nuestro estado numberOfSeries
                            setArregloPagosIntereses(arrayNumeroFechas);
                            setVariableInteres(true)

                            //  setStringSeries("")
                        }

                    }
                } else {
                    setSinPagosIntereses(true)
                }

            }
        } else {
            setSinPagosIntereses(true)
        }





    }

    const establecerNumeroPagosAmortizaciones = (e) => {
        // setNumeroFechasAmortizaciones(e)

        if (cantidadPagosAmortizaciones) {

            if (diaMesAmortizaciones) {


                let periodicidadMeses = 1;
                const arrarf = datos.fechaEmision.split("/");
                const fechaE = arrarf[1] + "/" + arrarf[0] + "/" + arrarf[2]

                var fecha = new Date(fechaE);
                //console.log(fecha)
                const añoActual = fecha.getFullYear();
                //let diaActual = fecha.getDate();
                var diaActual = diaMesAmortizaciones
                let mesActual = fecha.getMonth() + 1;
                //var mesActual = fecha.getDate();

                // //console.log(mesActual)
                /*  if (añoActual < 1900) {
                      setErrorFormatoFecha1(true)
                  } else {
                      setErrorFormatoFecha1(false)
                  }*/

                if (diaActual < 10) {
                    diaActual = "0" + diaActual;
                }

                if (mesActual < 10) {
                    mesActual = "0" + mesActual;
                }

                /* const fecha_limite_vencimiento = (añoActual) + "-" + mesActual + "-" + (diaActual);
                 // //console.log("hola")
                 ////console.log(fecha_limite_vencimiento)
                 setFecha_Vencimiento(fecha_limite_vencimiento)*/
                ////console.log(e)



                let arrayNumeroFechas = [];
                let arregloFechas = []
                //Si el input del usuario no es null itera sobre ese numero para crear el array
                if (cantidadPagosAmortizaciones != null) {
                    let mesPivote = parseInt(mesActual, 10);
                    let añoPivote = parseInt(añoActual, 10);
                    let diaPivote = parseInt(diaActual, 10);
                    // //console.log(mesActual)
                    ////console.log(periodicidadMeses)
                    for (let i = 0; i < cantidadPagosAmortizaciones; i++) {
                        diaPivote = parseInt(diaActual, 10)
                        // //console.log(mesPivote)
                        arrayNumeroFechas.push(i + 1);
                        mesPivote = mesPivote + periodicidadMeses
                        if (mesPivote > 12) {
                            mesPivote = mesPivote - 12;
                            añoPivote++
                        }
                        let variableValidacion = 0;

                        for (; variableValidacion === 0;) {
                            if (validarFecha((diaPivote.toString().padStart(2, '0')) + "/" + (mesPivote.toString().padStart(2, '0')) + "/" + (añoPivote))) {
                                //console.log("true")
                                variableValidacion = 1
                            } else {
                                //console.log(diaPivote)
                                ////console.log(mesPivote)
                                ////console.log(añoPivote)
                                //console.log("false")
                                //variableValidacion=1
                                diaPivote--;

                            }
                        }

                        ////console.log(validarFecha((añoPivote) + "-" + mesPivote.toString().padStart(2, '0') + "-" + (diaActual)))


                        arregloFechas.push((añoPivote) + "-" + mesPivote.toString().padStart(2, '0') + "-" + (diaPivote.toString().padStart(2, '0')))
                        //setSeriesReady(false);
                    }

                    ////console.log(arrayNumeroFechas)
                    // //console.log(arregloFechas)
                    //setArregloFechasIntereses(arregloFechas)
                    setArregloFechasAmortizaciones(arregloFechas)
                    //Envía el array creado en el for a nuestro estado numberOfSeries
                    // setArregloPagosIntereses(arrayNumeroFechas);
                    setArregloPagosAmortizaciones(arrayNumeroFechas)
                    setVariableAmortizacion(true)
                    //setVariableInteres(true)

                    //  setStringSeries("")

                }

            } else {
                if (cantidadPagosAmortizaciones === "1") {
                    let arregloNumeroFechas = [1]
                    const arrarf = datos.fechaVencimiento.split("/");
                    const fechaE = arrarf[1] + "/" + arrarf[0] + "/" + arrarf[2]

                    var fecha = new Date(fechaE);
                    //console.log(fecha)
                    const añoActual = fecha.getFullYear();
                    let diaActual = fecha.getDate();
                    //var diaActual = diaMesIntereses
                    let mesActual = fecha.getMonth() + 1;

                    if (diaActual < 10) {
                        diaActual = "0" + diaActual;
                    }
    
                    if (mesActual < 10) {
                        mesActual = "0" + mesActual;
                    }

                    var arregloFechas = [añoActual + "-" + mesActual + "-" + diaActual];
                    
                    //  //console.log(arregloFechas)
                    ////console.log(arregloNumeroFechas)

                   //console.log(arregloFechas)
                    //setArregloFechasIntereses(arregloFechas)
                    setDiaMesAmortizaciones(diaActual)
                    setArregloFechasAmortizaciones(arregloFechas)
                    //setArregloPagosIntereses(arregloNumeroFechas);
                    setArregloPagosAmortizaciones(arregloNumeroFechas)
                    setVariableAmortizacion(true)
                    //setVariableInteres(true)
                } else {
                    if (!arregloPagosIntereses) {
                        setSinDiaAmortizaciones(true)
                    } else {
                        setArregloFechasAmortizaciones(arregloFechasIntereses)
                        setArregloPagosAmortizaciones(arregloPagosIntereses)
                        setDiaMesAmortizaciones(diaMesIntereses)
                        setVariableAmortizacion(true)
                    }
                }

            }
        } else {
            setSinPagosAmortizaciones(true)
        }




    }




    const modificarFechaIntereses = (e, index) => {

        //console.log("modificarFecha")

        const { value } = e.target;

        //console.log(value)
        //console.log(arregloFechasIntereses)

        //Modifca la posicion del array 
        let array = arregloFechasIntereses;
        array[index] = value;

        //console.log(array)

        //Crea el string de las series
        //const fechas = array.join();

        // setStringSeries(fechas);
        setArregloFechasIntereses(array)
        if (variableInteres) {
            setVariableInteres(false)
            setVariableInteres2(true)
        } else {
            setVariableInteres(true)
            setVariableInteres2(false)
        }

    }

    const modificarFechaAmortizaciones = (e, index) => {
        const { value } = e.target;
        //Modifca la posicion del array 
        let array = arregloFechasAmortizaciones;
        array[index] = value;


        setArregloFechasAmortizaciones(array)
        if (variableAmortizacion) {
            setVariableAmortizacion(false)
            setVariableAmortizacion2(true)
        } else {
            setVariableAmortizacion(true)
            setVariableAmortizacion2(false)
        }

    }




    return (
        <>
        

            {loading &&
                <PantallaCargando />
            }

            

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {procesoExitoso &&
                <ComponenteModal mostrarModal={procesoExitoso} cerrarModal={cerrarModal} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {procesoFallido &&
                <ComponenteModal mostrarModal={procesoFallido} cerrarModal={() => setProcesoFallido(false)} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {valorIncorrecto &&
                <ComponenteModal mostrarModal={valorIncorrecto} cerrarModal={() => setValorIncorrecto(false)} titulo="No puede ingresar un número mayor a 31" textoBoton="Entendido"></ComponenteModal>
            }

            {sinPagosIntereses &&
                <ComponenteModal mostrarModal={sinPagosIntereses} cerrarModal={() => setSinPagosIntereses(false)} titulo="Necesita ingresar un número de pagos de intereses y día de pago" textoBoton="Entendido"></ComponenteModal>
            }

            {sinPagosAmortizaciones &&
                <ComponenteModal mostrarModal={sinPagosAmortizaciones} cerrarModal={() => setSinPagosAmortizaciones(false)} titulo="Necesita ingresar un número de pagos de amortizaciones" textoBoton="Entendido"></ComponenteModal>
            }

            {sinDiaAmortizaciones &&
                <ComponenteModal mostrarModal={sinDiaAmortizaciones} cerrarModal={() => setSinDiaAmortizaciones(false)} titulo="Necesita ingresar un dia de pago de amortizaciones o llenar los campos de fechas de intereses" textoBoton="Entendido"></ComponenteModal>
            }

            {sinDatos &&
                <ComponenteModal mostrarModal={sinDatos} cerrarModal={() => setSinDatos(false)} titulo="Necesita generar fechas de pagos de intereses y de amortizaciones" textoBoton="Entendido"></ComponenteModal>
            }


            <div className="fluid-container">{/* style={{ backgroundImage: `url(${fondo})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="fluid-container">*/}
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 mb-5 main-min-height">
                            <div className="">
                                <BannerTitulo titulo="Registrar fechas"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <section className="d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded">
                                                <div className="col-12">
                                                    {datos &&
                                                        <form onSubmit={onSubmitForm} className="needs-validation font-acumin-variable text-royal-blue">
                                                            <h4 className="text-royal-blue font-acumin-variable mt-3">Información para liquidación de fondos</h4>
                                                            <div className="row mt-4">
                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input value={datos.codigoSerie} title="Código de serie" className="form-control fs-5" name="Codigo de serie" type="text" disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Codigo de serie">Código de serie</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 form-floating mb-3">
                                                                    <div className="form-floating">
                                                                        <input title="Nombre de la serie" type="text" className="form-control border" value={datos.nombreSerie} name="Nombre de la serie" disabled />
                                                                        <label htmlFor="Nombre de la serie" className="form-label ">Serie</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="ISIN" className="form-control fs-5" value={datos.isin} name="ISIN" type="text" placeholder="ISIN" disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="ISIN">ISIN</label>
                                                                    </div>
                                                                </div>



                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Nombre del emisor" className="form-control fs-5" value={datos.nombreEmisor} name="Nombre del emisor" type="text" placeholder="Nombre del emisor" disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Nombre del emisor">Nombre del emisor</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Nombre de la emisión" className="form-control fs-5" value={datos.nombreEmision} name="Nombre de la emisión" type="text" placeholder="Nombre de la emisión" disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Nombre del emisor">Nombre de la emisión</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Fecha de emisión" className="form-control text-uppercase" type="text" value={datos.fechaEmision} name="Fecha de emisión" placeholder="Fecha de emisión" disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Fecha de emisión">Fecha de emisión</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Fecha de vencimiento" className="form-control text-uppercase" type="text" value={datos.fechaVencimiento} name="Fecha de vencimiento" placeholder="Fecha de vencimiento" disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Fecha de vencimiento">Fecha de vencimiento</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Periodicidad" className="form-control text-uppercase" type="text" value={datos.periodicidad} name="Periodicidad" placeholder="Periodicidadco" disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Periodicidad">Periodicidad</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            
                                                            <h4 className="text-royal-blue font-acumin-variable mt-3">Agregar fechas de pagos de intereses</h4>

                                                            <div className="row mt-4">
                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Cantidad de pagos" className="form-control fs-5" onChange={(e) => {
                                                                            setCantidadPagosIntereses(e.target.value)
                                                                        }} value={cantidadPagosIntereses} name="Cantidad de pagos" type="text" placeholder="Cantidad de pagos" required />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Cantidad de pagos">Cantidad de pagos*</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        {cantidadPagosIntereses === "1" ?
                                                                            <input title="Dia del mes" className="form-control fs-5" onChange={(e) => setDiaMesIntereses(e.target.value)} value={diaMesIntereses} name="Dia del mes" type="text" placeholder="Dia del mes" required disabled />
                                                                            :
                                                                            <input title="Dia del mes" className="form-control fs-5" onChange={(e) => setDiaMesIntereses(e.target.value)} value={diaMesIntereses} name="Dia del mes" type="text" placeholder="Dia del mes" required />
                                                                        }


                                                                        <label className="text-start font-acumin-variable" htmlFor="Dia del mes">Día del mes *</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="col-12 col-md-12 d-flex justify-content-start mb-3">
                                                                        <button type="button" onClick={establecerNumeroPagosIntereses} className="btn col-6 col-sm-6 col-md-10 col-lg-4 col-xl-4 background-royal-blue text-white bg-hover-gold">Generar</button>
                                                                    </div>
                                                                </div>

                                                                <h4 className="text-royal-blue font-acumin-variable mt-3">Fechas de pago de intereses</h4>
                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Dia del mes" className="form-control fs-5" value={fechaEmision} name="Dia del mes" type="date" placeholder="Dia del mes" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Dia del mes">Fecha de inicio de pago de intereses</label>
                                                                    </div>
                                                                </div>
                                                                {variableInteres &&
                                                                    arregloPagosIntereses.map((item, index) => (
                                                                        <div className="col-12 col-md-4 mb-3">
                                                                            <div className="form-floating ">
                                                                                <input title="Dia del mes" className="form-control fs-5" value={arregloFechasIntereses[index]} onChange={(e) => {
                                                                                    modificarFechaIntereses(e, index)
                                                                                }} name="Dia del mes" type="date" placeholder="Dia del mes" required />
                                                                                <label className="text-start font-acumin-variable" htmlFor="Dia del mes">Fecha de pago de interes {index + 1} *</label>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                                {variableInteres2 &&
                                                                    arregloPagosIntereses.map((item, index) => (
                                                                        <div className="col-12 col-md-4 mb-3">
                                                                            <div className="form-floating ">
                                                                                <input title="Dia del mes" className="form-control fs-5" value={arregloFechasIntereses[index]} onChange={(e) => {
                                                                                    modificarFechaIntereses(e, index)
                                                                                }} name="Dia del mes" type="date" placeholder="Dia del mes" required />
                                                                                <label className="text-start font-acumin-variable" htmlFor="Dia del mes">Fecha de pago de interes {index + 1} *</label>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>

                                                           
                                                            <h4 className="text-royal-blue font-acumin-variable mt-3">Agregar fechas de pagos de amortizaciones</h4>

                                                            <div className="row mt-4">
                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Cantidad de pagos" className="form-control fs-5" onChange={(e) => setCantidadPagosAmortizaciones(e.target.value)} value={cantidadPagosAmortizaciones} name="Cantidad de pagos" type="text" placeholder="Cantidad de pagos" required />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Cantidad de pagos">Cantidad de pagos*</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        {cantidadPagosAmortizaciones === "1" ?
                                                                            <input title="Dia del mes" className="form-control fs-5" onChange={(e) => setDiaMesAmortizaciones(e.target.value)} value={diaMesAmortizaciones} name="Dia del mes" type="text" placeholder="Dia del mes" disabled />
                                                                            :
                                                                            <input title="Dia del mes" className="form-control fs-5" onChange={(e) => setDiaMesAmortizaciones(e.target.value)} value={diaMesAmortizaciones} name="Dia del mes" type="text" placeholder="Dia del mes" />
                                                                        }
                                                                        <label className="text-start font-acumin-variable" htmlFor="Dia del mes">Día del mes</label>
                                                                    </div>
                                                                </div>


                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="col-12 col-md-12 d-flex justify-content-start mb-3">
                                                                        <button type="button" onClick={establecerNumeroPagosAmortizaciones} className="btn col-6 col-sm-6 col-md-10 col-lg-4 col-xl-4 background-royal-blue text-white bg-hover-gold">Generar</button>
                                                                    </div>
                                                                </div>

                                                                <div className="col-auto">
                                                                    {/*}  <small><Link to="/restablecerContraseña" className="text-no-decoration">¿Olvidaste tu contraseña?</Link></small>*/}
                                                                    <small>Si el día del mes esta vacio las fechas serán las mismas que las fechas de pagos de intereses, si escoge solo un pago la fecha de pago de amortización será igual a la fecha de vencimiento</small>
                                                                </div>

                                                                <h4 className="text-royal-blue font-acumin-variable mt-3">Fechas de pagos de amortizaciones</h4>
                                                                {variableAmortizacion &&
                                                                    arregloPagosAmortizaciones.map((item, index) => (
                                                                        <div className="col-12 col-md-4 mb-3">
                                                                            <div className="form-floating ">
                                                                                <input title="Fechas de pagos" className="form-control fs-5" value={arregloFechasAmortizaciones[index]} onChange={(e) => {
                                                                                    modificarFechaAmortizaciones(e, index)
                                                                                }} name="Dia del mes" type="date" placeholder="Dia del mes" required />
                                                                                <label className="text-start font-acumin-variable" htmlFor="Dia del mes">Fecha de pago de amortización {index + 1} *</label>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }

                                                                {variableAmortizacion2 &&
                                                                    arregloPagosAmortizaciones.map((item, index) => (
                                                                        <div className="col-12 col-md-4 mb-3">
                                                                            <div className="form-floating ">
                                                                                <input title="Fecha de pago" className="form-control fs-5" value={arregloFechasAmortizaciones[index]} onChange={(e) => {
                                                                                    modificarFechaAmortizaciones(e, index)
                                                                                }} name="Dia del mes" type="date" placeholder="Dia del mes" required />
                                                                                <label className="text-start font-acumin-variable" htmlFor="Dia del mes">Fecha de pago de amortización {index + 1} *</label>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>

                                                            <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                <button type="submit" className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-1 background-royal-blue text-white bg-hover-gold">Confirmar</button>
                                                            </div>
                                                        </form>
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

