import React from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
//import SpinnerSend from "../../../ComponentesGenerales/SpinnerSend";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";


//APIs
import { enviarRegistroEmisor } from "../../../api/emisor/enviarRegistroEmisor";
import { traerNacionalidad } from "../../../api/catalogos/traerNacionalidad";
import { traerSectores } from "../../../api/catalogos/traerSectores";
import { traerSubSectores } from "../../../api/catalogos/traerSubSectores";
import { ususarioExistente } from "../../../api/UsuarioExistente/usuarioExistente";
import { traerBancos } from "../../../api/catalogos/traerBancos";
import { traerTiposCuenta } from "../../../api/catalogos/traerTiposCuenta";
import { traerDatosDepositanteParaEmisor } from "../../../api/emisor/traerDatosDepositnateParaEmisor";
import { traerCasasBolsa } from '../../../api/catalogos/traerCasasBolsa'


export default function SolicitudRegistroEmisor({ history }) {

    const [sectores, setSectores] = React.useState([])
    const [subSectores, setSubSectores] = React.useState([])
    const [nacionalidades, setNacionalidades] = React.useState([])
    const [casasBolsa, setCasasBolsa] = React.useState([])

    //  const [codigo, setCodigo] = React.useState("")
    const [nombreCompletoEmisor, setNombreCompletoEmisor] = React.useState()
    const [rtn, setRtn] = React.useState()
    const [sector, setSector] = React.useState()
    const [nacionalidad, setNacionalidad] = React.useState()
    const [subSector, setSubSector] = React.useState()
    const [paginaWeb, setPaginaWeb] = React.useState()
    const [bancos, setBancos] = React.useState([]);
    const [tiposCuenta, setTiposCuenta] = React.useState([]);
    const [bancoNacional, setBancoNacional] = React.useState()
    const [bancoExtranjero, setBancoExtranjero] = React.useState()
    const [tipoCuentaNacional, setTipoCuentaNacional] = React.useState()
    const [tipoCuentaExtranjera, setTipoCuentaExtranjera] = React.useState()
    const [numeroCuentaNacional, setNumeroCuentaNacional] = React.useState()
    const [numeroCuentaExtranjera, setNumerocuentaExtranjera] = React.useState()


    const [email, setEmail] = React.useState()
    const [codigoDepositante, setCodigoDepositante] = React.useState()

    const [registroExitoso, setRegistroExitoso] = React.useState()
    const [registroFallido, setRegistroFallido] = React.useState()

    const [errorUsuarioExistente, setErrorUsuarioExistente] = React.useState();
    //const [spinSend, setSpinSend] = React.useState(false)
    const [loading, setLoading] = React.useState(false);
    const [erroreshttp, setErroreshttp] = React.useState()
    const [correoContacto, setCorreoContacto] = React.useState()
    const [nombreContacto, setNombreContacto] = React.useState()
    //const [depositante, setDepositnate] = React.useState("")

    const [errorCodigo, setErrorCodigo] = React.useState()
    const [nombreCompletoRepresentanteLegal, setNombreCompletoRepresentanteLegal] = React.useState()
    const [mensaje, setMensaje] = React.useState()

    //const [fechaLimiteDocumentos, setFechaLimiteDocumentos] = React.useState()

    const onSubmitForm = async (e) => {
        e.preventDefault();
        //var hoy = new Date();
        //var hora = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
        ////console.log(lugarRegistro)
        const myObj = {
            nombreCompletoEmisor: nombreCompletoEmisor,
            sector: sector,
            subsector: subSector,
            nacionalidad: nacionalidad,
            rtn: rtn,
            paginaWeb: paginaWeb,
            // primerNombre: primerNombreRepresentanteLegal,
            // segundoNombre: segundoNombrerepresentanteLegal,
            // primerApellido: primerApellidoRepresentanteLegal,
            // segundoApellido: segundoApellidoRepresentanteLegal,
            nombreCompletoRepreLeg: nombreCompletoRepresentanteLegal,
            correoLaboral: email,
            emisorNombreContacto: nombreContacto,
            emisorCorreoContacto: correoContacto,
            emisorCodigoDepositante: codigoDepositante,
            //fechaRegistro: fechaRegistro,
            // lugarRegistro: lugarRegistro,
            //estatusActividad: 
            // horaRegistro: "a",
            estatusActividad: true,
            estatusRegistro: 1,
            //codigoCehval: codigo,
            cuentaNacional: {
                monedaId: 1,
                tipoCuentaId: tipoCuentaNacional,
                bancoId: bancoNacional,
                numeroCuenta: numeroCuentaNacional

            },
            cuentaExtranjera: {
                monedaId: 2,
                tipoCuentaId: tipoCuentaExtranjera,
                bancoId: bancoExtranjero,
                numeroCuenta: numeroCuentaExtranjera

            }
        }

     //   //console.log(myObj)
        const enviarDatos = await enviarRegistroEmisor(myObj)
        if (enviarDatos) {
            setLoading(false)
            if ((isNaN(enviarDatos.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(enviarDatos)
            } else {

                if (enviarDatos?.codigo === "000") {
                    setRegistroExitoso(true)

                } else {
                    setMensaje(enviarDatos.mensaje)
                    setRegistroFallido(true)
                }
            }
        }
        ////console.log(enviarDatos)


    }

    const checarNumeros = (e) => {
        const { value } = e.target;
        let out = '';
        var filtro = '1234567890';
        var filtro2 = '1234567890-';


        if (e.currentTarget.name === "rtn") {
            for (var i = 0; i < value.length; i++)
                if (filtro.indexOf(value.charAt(i)) !== -1)
                    //Se añaden a la salida los caracteres validos
                    out += value.charAt(i);
            setRtn(out);
        } else {
            for (var i = 0; i < value.length; i++)
                if (filtro2.indexOf(value.charAt(i)) !== -1)
                    //Se añaden a la salida los caracteres validos
                    out += value.charAt(i);
            if (e.currentTarget.name === "numeroCuentaN") {
                setNumeroCuentaNacional(out)
            } else {
                setNumerocuentaExtranjera(out)
            }

        }








        //   if (e.keyCode > 64 && e.keyCode < 91) {
        //     setRtn("");
        // }

    }

    React.useEffect(() => {
        traerDatos()
    }, [])




    const traerDatos = async () => {
        const catalogosSectores = await traerSectores()
        if (catalogosSectores) {
            if ((isNaN(catalogosSectores.error)) === false) {
              
                setErroreshttp(catalogosSectores)
                
            } else {
                // //console.log(traerInformacion)
                setSectores(catalogosSectores)
            }
        }

        const catalogosSubSectores = await traerSubSectores()
        if (catalogosSubSectores) {
            if ((isNaN(catalogosSubSectores.error)) === false) {
              
                setErroreshttp(catalogosSubSectores)
                
            } else {
                // //console.log(traerInformacion)
                setSubSectores(catalogosSubSectores)
            }
        }

        const catalogosNacionalidades = await traerNacionalidad()
        if (catalogosNacionalidades) {
            if ((isNaN(catalogosNacionalidades.error)) === false) {
              
                setErroreshttp(catalogosNacionalidades)
                
            } else {
                // //console.log(traerInformacion)
                setNacionalidades(catalogosNacionalidades)
            }
        }

        const catalogoBancos = await traerBancos()
        if (catalogoBancos) {
            //setSpin(false)
            if ((isNaN(catalogoBancos.error)) === false) {
              
                setErroreshttp(catalogoBancos)
                
            } else {
                // //console.log(traerInformacion)
                setBancos(catalogoBancos)
            }
        }

        const catalogoTiposCuenta = await traerTiposCuenta()
        if (catalogoTiposCuenta) {
            //setSpin(false)
            if ((isNaN(catalogoTiposCuenta.error)) === false) {
              
                setErroreshttp(catalogoTiposCuenta)
                
            } else {
                // //console.log(traerInformacion)
                //setActividadEconomica(catalogoNacionalidad)

                setTiposCuenta(catalogoTiposCuenta)
            }
        }

        const catalogoCasasBolsa = await traerCasasBolsa()
        //console.log(catalogoCasasBolsa)
        if (catalogoCasasBolsa) {
            //setSpin(false)
            if ((isNaN(catalogoCasasBolsa.error)) === false) {
              
                setErroreshttp(catalogoCasasBolsa)
                
            } else {
                // //console.log(traerInformacion)
                setCasasBolsa(catalogoCasasBolsa)

            }
        }


    }

    const traerDatosDelDepositante = async (codigo) => {
        const obj1 = {
            codigo: codigo
        }
        const datosDepositante = await traerDatosDepositanteParaEmisor(obj1)
        if (datosDepositante) {
            if ((isNaN(datosDepositante.error)) === false) {
              
                setErroreshttp(datosDepositante)
                
            } else {
                if (datosDepositante.codigo === null) {
                    setErrorCodigo(true)
                } else {
                    setErrorCodigo(false)
                    setCodigoDepositante(datosDepositante.codigo)
                    setCorreoContacto(datosDepositante.correo)
                    setNombreContacto(datosDepositante.nommbre)
                    //setDepositnate(datosDepositante)
                }
                // //console.log(traerInformacion)
            }
        }






    }



    const validarCorreo = (e) => {
        e.preventDefault();
        const { name, value } = e.target;

        async function validarUsuario() {
            const obj = {
                correo: value
            }
            //console.log(obj)
            const resultado = await ususarioExistente(obj)
            //console.log(resultado)
            if (resultado) {
                if ((isNaN(resultado.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(resultado)
                } else {
                    //setLoading(false)
                    //el usuario existe
                    if (resultado?.codigo === "000") {
                        document.getElementById(name).value = "";
                        setErrorUsuarioExistente(true)
                        //usuaior no existe
                    } else {
                        setEmail(value)
                    }
                }
            }
        }
        validarUsuario()
    }


    const irInicio = () => {
        setRegistroExitoso(false)
        history.go(-1)
    }

    return (
        <div className="fluid-container">
            {loading &&
                <PantallaCargando />
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {errorUsuarioExistente &&
                <ComponenteModal mostrarModal={errorUsuarioExistente} cerrarModal={() => { setErrorUsuarioExistente(false) }} titulo="Este correo ya esta registrado, ingresa otro" textoBoton="Entendido"></ComponenteModal>
            }
            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={irInicio} titulo="Registro exitoso de emisor" textoBoton="Entendido"></ComponenteModal>
            }
            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={() => { setRegistroFallido(false) }} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }



            <main className="min-height-40" >
                <div className="row row-wrap g-0">

                    


                    <div className="col-12">
                        <div className="">
                            <BannerTitulo titulo="Solicitud de registro para emisor"></BannerTitulo>
                            <div className="row d-flex">

                                <div className="col-12">
                                    <div className="mb-5">
                                        <div className="card-body ">
                                            <div className="d-grid gap-1 col-10 mx-auto">
                                                <form className="row g-3 needs-validation" novalidate onSubmit={(e) => {
                                                    onSubmitForm(e)
                                                    setLoading(true)
                                                }}>
                                                    <div className="card" style={{ color: "#242848" }}>
                                                        <div className="card-body">
                                                            <h5 className="mt-3 text-royal-blue font-acumin-variable">Información de contacto</h5>
                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" disabled value={nombreContacto} onChange={(e) => setNombreContacto(e.target.value)} id="nombreContacto" name="nombreContacto"  required />
                                                                        <label htmlFor="cehvalDepositanteNombreConf" className=" textoFormularioDepositantedirecto">Nombre completo *</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input  type="email" className="form-control " disabled value={correoContacto} onChange={(e) => setCorreoContacto(e.target.value)} id="correoContacto" name="correoContacto" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Correo electrónico *</label>
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-12 col-lg-4 form-floating mb-3">
                                                                    <div className="form-floating mb-3">
                                                                        <select className="form-select border  " onChange={(e) => traerDatosDelDepositante(e.target.value)} id="nombreBolsa" name="nombreBolsa" required>
                                                                            <option value=""> </option>
                                                                            {
                                                                                casasBolsa.map((item, index) => (
                                                                                    <option key={index} value={item.cehvalDepositanteCodigoCehval} >{item.cehvalDepositanteCodigoCehval}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Código depositante *</label>
                                                                    </div>
                                                                </div>

                                                                {errorCodigo &&
                                                                    <>
                                                                        <p className="error">El código no existe</p>
                                                                    </>
                                                                }
                                                            </div>


                                                            <h5 className="mt-3 text-royal-blue font-acumin-variable">I. Identificación del emisor</h5>


                                                            {/*}  <div className="row mb-3  justify-content-end">
                                                                <div className="col-md-3 form-floating">
                                                                    <input onChange={(e) => setCodigo(e.target.value)} id="codigoEmisor" name="codigoEmisor" type="text" className="form-control textoFormularioDepositantedirecto" />
                                                                    <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto"> Código</label>
                                                                </div>
                                                            </div>*/}

                                                            <div className="row mb-3">
                                                                <div className="col-12 "  >
                                                                    <div className="form-floating">
                                                                        <input onChange={(e) => setNombreCompletoEmisor(e.target.value)} type="text" className="form-control textoFormularioDepositantedirecto" id="nombreCompleto" placeholder="nombre" required />
                                                                        <label className="textoFormularioDepositantedirecto" htmlFor="nombreCompleto">Nombre completo del emisor *</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <select className="form-select textoFormularioDepositantedirecto" onChange={(e) => setSector(e.target.value)} id="sector" name="sector" required>
                                                                            <option value=""> </option>
                                                                            {
                                                                                sectores.map((item, index) => (
                                                                                    <option key={index} value={index + 1} >{item.nombre}</option>
                                                                                ))
                                                                            }

                                                                        </select>
                                                                        <label htmlFor="sector" className="form-label textoFormularioDepositantedirecto">Sector *</label>
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-12 col-lg-6 mb-3"  >
                                                                    <div className="form-floating">
                                                                        <select className="form-select textoFormularioDepositantedirecto " onChange={(e) => setSubSector(e.target.value)} id="subSector" name="subSector" required>
                                                                            <option value=""> </option>
                                                                            {
                                                                                subSectores.map((item, index) => (
                                                                                    <option key={index} value={index + 1} >{item.nombre}</option>
                                                                                ))
                                                                            }

                                                                        </select>
                                                                        <label htmlFor="subSector" className="form-label textoFormularioDepositantedirecto">Sub-sector *</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating" >
                                                                        <select className="form-select " onChange={(e) => setNacionalidad(e.target.value)} id="nacionalidad" name="nacionalidad" required>
                                                                            <option value=""> </option>
                                                                            {
                                                                                nacionalidades.map((item, index) => (
                                                                                    <option key={index} value={index + 1} >{item.nombre}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Nacionalidad *</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className=" form-floating">
                                                                        <input onChange={(e) => setRtn(e.target.value)} onKeyUp={(e) => checarNumeros(e)} value={rtn} type="text" maxLength={14} className="form-control textoFormularioDepositantedirecto" id="rtn" name="rtn" placeholder="rtn" />
                                                                        <label htmlFor="rtn" className="textoFormularioDepositantedirecto">RTN</label>
                                                                        <div id="" class="form-text ps-1 fs-x-small">Solo números</div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-lg-4">
                                                                    <div className="form-floating">
                                                                        <input onChange={(e) => setPaginaWeb(e.target.value)} type="text" className="form-control textoFormularioDepositantedirecto" id="paginaWeb" placeholder="paginaWeb" />
                                                                        <label htmlFor="paginaWeb" className="textoFormularioDepositantedirecto">Página web</label>                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <h5 className="mt-5 mb-3 text-royal-blue font-acumin-variable" >II. Datos del contacto</h5>

                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-8 col-xl-8 mb-3"  >
                                                                    <div className="form-floating">
                                                                        <input onChange={(e) => setNombreCompletoRepresentanteLegal(e.target.value)} type="text" className="form-control textoFormularioDepositantedirecto" id="nombreCompletoRepresentanteLegal" placeholder="nombreCompletoRepresentanteLegal" required/>
                                                                        <label htmlFor="nombreCompletoRepresentanteLegal" className="textoFormularioDepositantedirecto">Nombre de contacto *</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4 col-xl-4"  >
                                                                    <div className="form-floating">
                                                                        <input value={email} onChange={(e) => setEmail(e.target.value.toLowerCase())} onMouseOut={(e) => validarCorreo(e)} type="email" className="form-control textoFormularioDepositantedirecto" id="email" name="email" placeholder="name@example.com" required/>
                                                                        <label htmlFor="email" className="textoFormularioDepositantedirecto">Correo electrónico del contacto *</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <h5 className="mt-5 mb-3 text-royal-blue font-acumin-variable" >III. Instrucciones especiales (cuentas bancarias)</h5>
                                                            <h6 className="text-royal-blue font-acumin-variable">Nacional</h6>

                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                    <div className="form-floating mb-3">
                                                                        < input  type="text" disabled className="form-control border  " value={"LEMPIRA"} required />
                                                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Moneda</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                    <div className="form-floating mb-3">
                                                                        <select className="form-select border  " value={bancoNacional} onChange={(e) => setBancoNacional(e.target.value)} id="bancoN" name="bancoN">
                                                                            <option value=""> </option>
                                                                            {
                                                                                bancos.map((item, index) => (
                                                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Bancos</label>
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                    <div className="form-floating mb-3">
                                                                        <select className="form-select border  " value={tipoCuentaNacional} onChange={(e) => setTipoCuentaNacional(e.target.value)} id="tipoCuentaN" name="tipoCuentaN">
                                                                            <option value=""> </option>
                                                                            {
                                                                                tiposCuenta.map((item, index) => (
                                                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Tipo de cuenta</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                    <div className="form-floating mb-3">
                                                                        < input placeholder="" type="text" maxLength={16} className="form-control border  " value={numeroCuentaNacional} onChange={(e) => setNumeroCuentaNacional(e.target.value)} onKeyUp={(e) => checarNumeros(e)} id="numeroCuentaN" name="numeroCuentaN" />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Número de cuenta</label>
                                                                        <div id="" class="form-text fs-x-small">Solo números</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <h6 className="text-royal-blue font-acumin-variable" >Extranjera</h6>
                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                    <div className="form-floating mb-3">
                                                                        < input  type="text" disabled className="form-control border  " value={"DÓLAR"} />
                                                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Moneda</label>
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                    <div className="form-floating mb-3">
                                                                        <select className="form-select border  " value={bancoExtranjero} onChange={(e) => setBancoExtranjero(e.target.value)} id="bancoE" name="bancoE">
                                                                            <option value=""> </option>
                                                                            {
                                                                                bancos.map((item, index) => (
                                                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Banco</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                    <div className="form-floating mb-3">
                                                                        <select className="form-select border  " value={tipoCuentaExtranjera} onChange={(e) => setTipoCuentaExtranjera(e.target.value)} id="tipoCuentaE" name="tipoCuentaE">
                                                                            <option value=""> </option>
                                                                            {
                                                                                tiposCuenta.map((item, index) => (
                                                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Tipo de cuenta</label>
                                                                    </div>

                                                                </div>
                                                                <div className="col-md-12 col-lg-4 form-floating">
                                                                    <div className="form-floating mb-3">
                                                                        < input placeholder="" type="text" maxLength={16} className="form-control border  " value={numeroCuentaExtranjera} onChange={(e) => setNumerocuentaExtranjera(e.target.value)} onKeyUp={(e) => checarNumeros(e)} id="numeroCuentaE" name="numeroCuentaE" />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Número de cuenta</label>
                                                                        <div id="" class="form-text fs-x-small">Solo números</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 text-center mb-3">
                                                            <button type="submit" className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Enviar</button>
                                                            {/* <button className="btn btn-primary" type="submit">ENVIAR</button>*/}
                                                        </div>
                                                        {/*{spinSend &&
                                                            <SpinnerSend textoSpinner="Enviando..."></SpinnerSend>
                                                        }*/}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            </main>

        </div>
    );


}

