import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

//Components
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";

//APIs
import { actualizarEstatusDeSolicitud } from '../../../../api/prendasYGarantias/actualizarEstatusDeSolicitud';
import { descargarPDF } from '../../../../api/prendasYGarantias/descargarPDF';


export default function AutorizarConstitucionDeGarantia({ history }) {


    const [codigoDepositanteSolicitante, setCodigoDepositanteSolicitante] = useState();
    const [isin, setIsin] = useState();
    const [cantidadValores, setcantidadValores] = useState();
    const [nombreBeneficiario, setNombreBeneficiario] = useState();
    const [folio, setFolio] = useState();

    const [buttonRejectText, setButtonRejectText] = useState("Rechazar");
    const [buttonReject, setButtonReject] = useState();
    const [erroreshttp, setErroreshttp] = React.useState()


    useEffect(() => {

        if (history.location.state === undefined) {
            history.go(-1)
        } else {
            //Stores history info
            const arrayConstitutionInfo = history.location.state.objConstitution[0];

            setCodigoDepositanteSolicitante(arrayConstitutionInfo.cehvalSolicitudGarantiaDepositanteTransferente.cehvalDepositanteCodigoCehval);
            setIsin(arrayConstitutionInfo.cehvalSolicitudGarantiaSerie.cehvalSerieCodigoIsin);
            setcantidadValores(arrayConstitutionInfo.cehvalSolicitudGarantiaCantidadInstrumentos);
            setNombreBeneficiario(arrayConstitutionInfo.cehvalSolicitudGarantiaNombreBeneficiario);
            setFolio(arrayConstitutionInfo.cehvalSolicitudGarantiaFolio);
        }
    }, []);




    //Inputs
    const [reasonReject, setReasonReject] = useState();

    const [rejectField, setRejectField] = useState(false);

    //Loading
    const [loading, setLoading] = useState();

    //States to show modal when register is successful or not
    const [registroExitoso, setRegistroExitoso] = useState();
    const [registroFallido, setRegistroFallido] = useState();

    const [rechazoExitoso, setRechazoExitoso] = useState();
    const [rechazoFallido, setRechazoFallido] = useState();

    const [noFile, setNoFile] = useState();
    const [responseRequest, setResponseRequest] = useState();


    //Handle the authorization of the constitution request
    const authorizeRequest = async (e) => {
        e.preventDefault();


        try {
            const objConstitucion = {
                folio: folio,
                estatus: 2,
            }

            setLoading(true);
            const response = await actualizarEstatusDeSolicitud(objConstitucion);

            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    ////console.log(response)
                    if (response.data.isSuccessful === true) {
                        setLoading(false);
                        setResponseRequest("Solicitud de constitución de garantía autorizada")
                        setRegistroExitoso(true);

                    } else {
                        setLoading(false);
                        setResponseRequest("No se pudo realizar la autorización, inténtelo de nuevo más tarde")
                        setRegistroFallido(true);
                    }
                }
            }


        } catch (error) {
            console.error(error);
        }
    }


    //Handle the rejection of the constitution request
    const rejectRequest = async (e) => {
        e.preventDefault();

        try {
            const objConstitucion = {
                folio: folio,
                estatus: 0,
                descripcion: reasonReject,
            }

            setLoading(true);
            const response = await actualizarEstatusDeSolicitud(objConstitucion);
            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    ////console.log(response)
                    if (response.data.isSuccessful === true) {
                        setLoading(false);
                        setResponseRequest("Solicitud de constitución de garantía rechazada")
                        setRechazoExitoso(true);
                    } else {
                        setLoading(false);
                        setResponseRequest("No se pudo rechazar la constitución de garantía, inténtelo de nuevo más tarde")
                        setRechazoFallido(true);
                    }
                }
            }




        } catch (error) {
            console.error(error);
        }
    }


    //Shows the reject field
    const showRejectField = () => {
        if (!rejectField) {
            setRejectField(true);
            setButtonReject(true);
            setButtonRejectText("Cancelar");

        } else {
            setRejectField(false);
            setButtonReject(false);
            setButtonRejectText("Rechazar");
        }
    }

    const downloadConstitutionPDF = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);

            //Download pdf with POST request. In body send folio
            const response = await descargarPDF({ fileName: folio });
            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {

                    //Si la respuesta contiene la propiedad data
                    if (response.data) {
                        setLoading(false);
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' },
                        );
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);

                    } else {
                        setLoading(false);
                        setNoFile(true)
                    }
                }
            }


        } catch (error) {
            console.error(error)
        }
    }

    const goBack = () => {
        setRegistroExitoso(false)
        history.push({
            pathname: '/tablaSolicitudesConstitucionDeGarantia',
        })
    }


    return (
        <>
          {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {loading &&
                <PantallaCargando />
            }
            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={goBack} titulo={responseRequest} textoBoton="Aceptar"></ComponenteModal>
            }
            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={() => { setRegistroFallido(false) }} titulo={responseRequest} textoBoton="Aceptar"></ComponenteModal>
            }
            {rechazoExitoso &&
                <ComponenteModal mostrarModal={rechazoExitoso} cerrarModal={goBack} titulo={responseRequest} textoBoton="Aceptar"></ComponenteModal>
            }
            {rechazoFallido &&
                <ComponenteModal mostrarModal={rechazoFallido} cerrarModal={() => { setRechazoFallido(false) }} titulo={responseRequest} textoBoton="Aceptar"></ComponenteModal>
            }
            {noFile &&
                <ComponenteModal mostrarModal={noFile} cerrarModal={() => { setNoFile(false) }} titulo={`No se encontró el archivo con el folio ${folio}.`} textoBoton="Aceptar"></ComponenteModal>
            }


            <div className="fluid-container">

                <main className="min-height-40">
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Autorización de constitución de prenda o garantía"></BannerTitulo>
                                <div className="d-flex justify-content-center px-3 mb-5 mt-5">
                                    <div className="col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11 col-xxl-11">
                                        <section className="d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded p-3">
                                                <div className="col-12">
                                                    <form className="needs-validation font-acumin-variable text-royal-blue">
                                                        <div className="row mt-4">
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Folio de la constitución de garantía" value={folio} className="form-control fs-5" name="folioConstitucion" type="text"  required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="folioConstitucion">Folio constitución de garantía</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código depositante transferente" value={codigoDepositanteSolicitante} className="form-control fs-5" name="codigoDepositanteSolicitante" type="text" placeholder="Codigo depositante transferente" required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="codigoDepositanteSolicitante">Código de depositante directo solicitante</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código ISIN" value={isin} className="form-control fs-5" type="text" name="ISIN" placeholder="ISIN" required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="ISIN">ISIN</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Cantidad de valores a transferir" value={cantidadValores} className="form-control fs-5" name="cantidadValores" type="text" placeholder="Cantidad de valores a transferir" required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="cantidadValores">Cantidad de valores</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Nombre completo del beneficiario" value={nombreBeneficiario} className="form-control fs-5" name="nombreBeneficiario" type="text" placeholder="Nombre del beneficiario" required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="nombreBeneficiario">Nombre del beneficiario</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 mb-3">
                                                                <Link className="text-royal-blue text-shadow-blue text-no-decoration text-hover-underline" onClick={downloadConstitutionPDF} title="Clic para descargar el documento escaneado de la solicitud de constitución">Descargar constitución pdf</Link>
                                                            </div>
                                                            <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                <button type="button" onClick={showRejectField} className="btn background-royal-blue text-white bg-hover-red me-2">{buttonRejectText}</button>
                                                                {!buttonReject &&
                                                                    <button type="button" onClick={authorizeRequest} title={`Al dar clic, los valores del depositante ${codigoDepositanteSolicitante} pasarán a la cuenta de Valores en Prenda o Garantía de la CEHVAL`} className="btn background-royal-blue text-white bg-hover-gold">Autorizar</button>
                                                                }
                                                            </div>

                                                            {rejectField &&
                                                                <>
                                                                    <div className="col-12 col-md-12 mb-3">
                                                                        <div className="form-floating ">
                                                                            <textarea title="Agregue el motivo por el cual se rechaza la solicitud de constitución" onChange={(e) => setReasonReject(e.target.value)} className="form-control fs-5 rejectionReason" name="motivoRechazo" type="text" placeholder="Motivo del rechazo" required ></textarea>
                                                                            <label className="text-start font-acumin-variable" htmlFor="motivoRechazo">Motivo del rechazo</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                        <button type="button" onClick={rejectRequest} title="Al dar clic, el sistema enviará la solicitud a la tabla de solicitudes de constitución de garantía rechazadas" className="btn background-royal-blue text-white bg-hover-gold">Confirmar</button>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    )
}