import React, { useState, useEffect } from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";

//APIs
import { consultarHistoricoDepositoRetiroValores } from "../../../api/mantenimientoCargaValores/consultarHistoricoDepositoRetiroValores";

//Hooks
import useHistoricoDepositoRetiroValores from "../../../hooks/MantenimientoCargaValores/useHistoricoDepositoRetiroValores";


export default function HistoricoDepositosRetirosValores({ history }) {
 

    const [data, setData] = useState([]);
    const [copia, setCopia] = useState();
    const [spin, setSpin] = useState(true);
    const [erroreshttp, setErroreshttp] = useState();
  

    const datos = [{
      folio:"RVAL-0001",
      fecha:"11/04/2023",
      isin:"HNDKEKEK334f",
      serie:"A",
      codigoSerie:"CHEV-0001-0002",
      nombreEmision:"BONOS1",
      cantidadInstrumentos:"11",
      codigoDepositante:"DDIRP-J",
      nombreDepositante:"Peximus",
      numeroCertificado:"ABCD",
      tipoOperacion:"Retiro",
      estatus:"Autorizado"
    },
    {
        folio:"RVAL-0001",
        fecha:"11/04/2023",
        isin:"HNDKEKEK334f",
        serie:"B",
        codigoSerie:"CHEV-0001-0002",
        nombreEmision:"BONOS1",
        cantidadInstrumentos:"11",
        codigoDepositante:"DDIRP-J",
        nombreDepositante:"Peximus",
        numeroCertificado:"ABCD",
        tipoOperacion:"Retiro",
        estatus:"Autorizado"
    }

    ]

    useEffect(() => {
        consultarHistorico();
    }, [])

    const consultarHistorico = async () => {
        const response = await consultarHistoricoDepositoRetiroValores()
        //console.log(response)
        if (response) {
            setSpin(false)
            if ((isNaN(response.error)) === false) {
              
                setErroreshttp(response)
                
            } else {
                setData(response)
                setCopia(response)

              //  setData(datos)
              ///  setCopia(datos)
            }
        }
    
    }








    return (
        <>
            <div className="fliud-container" >

                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                {/*}  <Header history={history} ></Header>*/}
                <main className='container-min-height'>
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Histórico de depósitos y retiros de valores"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">

                                            {copia &&
                                                <TablaMercado datos={data} useValidate={useHistoricoDepositoRetiroValores} ></TablaMercado>
                                            }

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}