import React, { useState } from "react";
import { Modal } from "react-bootstrap";

//Components
import BannerTitulo from "../Bannertitulo";
import ComponenteModal from "../ComponenteModal";
import ManejadorErrores from "../ManejadorErrores";
import PantallaCargando from "../PantallaCargando";

//APIs
import { actualizarStatusSerie } from "../../api/series/actualizarStatusSerie";
import { registrarSerieBCV } from "../../api/series/SeriesBCV/registrarSerieBCV";
import { validarSerieBCV } from "../../api/series/SeriesBCV/validarSerieBCV";



export default function VerInfoSerie({ history }) {
    if (history.location.state === undefined) {
        history.go(-1)
    }

    //Mostrar modal si el registro es exitoso

    const [loading, setLoading] = React.useState(false);
    const [erroreshttp, setErroreshttp] = React.useState()
    const [serieRegistradaBCV, setSerieRegistradaBCV] = useState()

    const [mensaje, setMensaje] = React.useState()
    const [procesoTerminado, setProcesoTeminado] = React.useState()
    const [procesoTerminadoError, setProcesoTeminadoError] = React.useState()

    const [seriesFiltradas, setSeriesFiltradas] = React.useState()
    const [errorsinMotivoRechazo, setErrorSinMotivoDeRechazo] = React.useState()
    const [motivoRechazo, setMotivoRechazo] = React.useState();
    const [desplegarCampoParaTexto, setDesplegarCampoParaTexto] = React.useState();
    const [tipoDeCambio, setTipoDeCambio] = React.useState();
    const [desicionRegistroSerie, setDesicionRegistroSerie] = React.useState();


    const nombreSerie = history.location.state.objSerie.cehvalSerieNombreSerie;
    const codigoSerie = history.location.state.objSerie.cehvalSerieCodigo;
    const estatusSerie = history.location.state.objSerie.cehvalSerieEstatus;
    const nombreEmision = history.location.state.objSerie.cehvalSerieNombreEmi;
    const codigoEmision = history.location.state.objSerie.cehvalEmisionCodigo;
    const codigoISIN = history.location.state.objSerie.cehvalSerieCodigoIsin
    const fechaEmisionSerie = history.location.state.objSerie.cehvalSerieFechaEmi;
    const fechaVencimiento = history.location.state.objSerie.cehvalSerieFechaVen;
    const tasaInteresNominal = history.location.state.objSerie.cehvalSerieTasIntNom;
    const serieMonto = history.location.state.objSerie.cehvalSerieMonto;
    const instrumentosEmitidos = history.location.state.objSerie.cehvalSerieCantInstEmi;
    const instrumentosDisponibles = history.location.state.objSerie.cehvalSerieCantInstDisponibles;
    const spreadSerie = history.location.state.objSerie.cehvalSerieSpread;
    const valNominalPorTitulo = history.location.state.objSerie.cehvalSerieValorNomTitulo;
    const formaColocacion = history.location.state.objSerie.catFormaColocacionCatForColId;
    const monedaSerie = history.location.state.objSerie.cehvalSerieCatMonedaIdSerie;
    const monedaLiquidacion = history.location.state.objSerie.cehvalSerieCatMonedaIdLiq;
    const ruta = history.location.state.objSerie.ruta
    const titulo = history.location.state.objSerie.titulo

    const periodicidad = history.location.state.objSerie.periodicidad;
    const tipoTasaVariable = history.location.state.objSerie.tipoTasaVariable;

    const descripcion = history.location.state.objSerie.descripcion

    const PeriodoRevisionTasaVariable = history.location.state.objSerie.PeriodoRevisionTasaVariable;
    const tasaVariable = history.location.state.objSerie.tasaVariable;
    const formaRepresentacion = history.location.state.objSerie.formaRepresentacion;

    const authorizedSerieStatus = 2;
    const rejectSerieStatus = 0;
    const finalStatus = 3;
    const notifyStatus = 4

    React.useEffect(() => {
        //console.log(tasaVariable)
    }, [])


    const authorizeSerie = async (e) => {
        e.preventDefault();
        const serieAuthorize = await actualizarStatusSerie({ serieId: history.location.state.objSerie.id, estatus: authorizedSerieStatus });
        //console.log(serieAuthorize)
        if (serieAuthorize) {
            setLoading(false)
            if ((isNaN(serieAuthorize.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(serieAuthorize)
            } else {
                if (serieAuthorize?.codigo === "000") {
                    setMensaje("Serie autorizada con código: " + serieAuthorize.mensaje)
                    setProcesoTeminado(true)
                } else {
                    setMensaje("Ocurrio un error")
                    setProcesoTeminado(true)
                }
            }
        }
  
    }

    const rejectSerie = async (e) => {
        e.preventDefault();


        if (motivoRechazo) {
            const serieReject = await actualizarStatusSerie({ serieId: history.location.state.objSerie.id, descripcion: motivoRechazo, estatus: rejectSerieStatus });

            if (serieReject) {
                if ((isNaN(serieReject.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(serieReject)
                } else {

                    setLoading(false)
                    if (serieReject?.codigo === "000") {
                        setMensaje("Serie rechazada")
                        setProcesoTeminado(true);


                    } else {
                        setMensaje("Ocurrio un error")
                        setProcesoTeminado(true);
                    }
                }
            }
        } else {
            //console.log("error")
            setErrorSinMotivoDeRechazo(true)
        }
    }

    const registerSerie = async (e) => {
        e.preventDefault();
        try {
            const serie = await actualizarStatusSerie({ serieId: history.location.state.objSerie.id, estatus: finalStatus });
            if (serie) {
                setLoading(false)
                if ((isNaN(serie.error)) === false) {
                    setErroreshttp(serie)
                } else {
                    if (serie?.codigo === "000") {
                        setMensaje("Notificación enviada")
                        setProcesoTeminado(true)
                    } else {
                        setMensaje("Ocurrio un error")
                        setProcesoTeminado(true)
                    }

                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    const notifyRejectedSerie = async (e) => {
        e.preventDefault();
        try {
            const serie = await actualizarStatusSerie({ serieId: history.location.state.objSerie.id, estatus: notifyStatus });
            if (serie) {
                setLoading(false)
                if ((isNaN(serie.error)) === false) {
                    setErroreshttp(serie)
                } else {
                    if (serie?.codigo === "000") {
                        setMensaje("Notificación enviada")
                        setProcesoTeminado(true)

                    } else {
                        setMensaje("Ocurrio un error")
                        setProcesoTeminado(true)
                    }

                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    const registerSerieBCV = async () => {
        const myObj = {
            codigoSerie: codigoSerie,
            codigoEmision: codigoEmision,
            nombreEmision: nombreEmision,
            serie: nombreSerie,
            codigoIsin: codigoISIN,
            fechaEmision: fechaEmisionSerie,
            fechaVencimiento: fechaVencimiento,
            moneda: monedaSerie,
            monedaLiquidacion: monedaLiquidacion,
            periodicidad: periodicidad,
            formaDeColocacion: formaColocacion,
            tasaInteres: tasaInteresNominal,
            cantidadInstrumentos: instrumentosEmitidos,
            instrumentosDisponibles: instrumentosDisponibles,
            valorNominalDeInstrumentos: valNominalPorTitulo,
            montoDeLaSerie: serieMonto,
            tasaVariable: tasaVariable,
            diferencial: spreadSerie,
            periodoDeRevisionDeTasaVariable: PeriodoRevisionTasaVariable,
            tipoTasaVariable: tipoTasaVariable,
            tipoDeCambio: tipoDeCambio
        }


        const serieValidarBCV = await validarSerieBCV(myObj);

        if (serieValidarBCV) {
            //console.log(serieValidarBCV)
            if ((isNaN(serieValidarBCV.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(serieValidarBCV)
            } else {
                if (serieValidarBCV?.codigo === "003") {
                    const serieRegisterBCV = await registrarSerieBCV(myObj);

                    if (serieRegisterBCV) {
                        //console.log(serieRegisterBCV)
                        if ((isNaN(serieRegisterBCV.error)) === false) {
                            // respuestaincorrecta           
                            setErroreshttp(serieRegisterBCV)
                        } else {
                            setLoading(false)
                            if (serieRegisterBCV?.codigo === "000") {
                                setMensaje("Serie proveniente de la BCV registrada")

                                const removesSerie = history.location.state.objMenu.filter((item) => item.codigoSerie !== codigoSerie);
                                //New array of emitters set to our state
                                setSeriesFiltradas(removesSerie);
                                setSerieRegistradaBCV(true)
                            } else if (serieRegisterBCV?.codigo === "001") {
                                setProcesoTeminado(true)
                                setMensaje(serieRegisterBCV?.mensaje)
                            }
                        }
                    }
                } else {
                    if (serieValidarBCV?.codigo === "002") {


                        if (serieValidarBCV?.mensaje === "El monto de la emisión es igual al acumulado, ya no puedo agregar series" || serieValidarBCV?.mensaje === "El monto de la emisión es menor al acumulado, ya no puedo agregar series") {

                            setMensaje(serieValidarBCV.mensaje)
                            setProcesoTeminadoError(true)
                        }
                        else {

                            setMensaje(serieValidarBCV.mensaje)
                            setDesicionRegistroSerie(true)
                        }



                    } else {
                        setMensaje(serieValidarBCV.mensaje)
                        setProcesoTeminadoError(true)
                    }

                }

            }
        }





    }


    const registerSerieBCVRespuestaPrositiva = async () => {
        const myObj = {
            codigoSerie: codigoSerie,
            codigoEmision: codigoEmision,
            nombreEmision: nombreEmision,
            serie: nombreSerie,
            codigoIsin: codigoISIN,
            fechaEmision: fechaEmisionSerie,
            fechaVencimiento: fechaVencimiento,
            moneda: monedaSerie,
            monedaLiquidacion: monedaLiquidacion,
            periodicidad: periodicidad,
            formaDeColocacion: formaColocacion,
            tasaInteres: tasaInteresNominal,
            cantidadInstrumentos: instrumentosEmitidos,
            instrumentosDisponibles: instrumentosDisponibles,
            valorNominalDeInstrumentos: valNominalPorTitulo,
            montoDeLaSerie: serieMonto,
            tasaVariable: tasaVariable,
            diferencial: spreadSerie,
            periodoDeRevisionDeTasaVariable: PeriodoRevisionTasaVariable,
            tipoTasaVariable: tipoTasaVariable,
            tipoDeCambio: tipoDeCambio
        }

        const serieRegisterBCV = await registrarSerieBCV(myObj);

        if (serieRegisterBCV) {
            //console.log(serieRegisterBCV)
            if ((isNaN(serieRegisterBCV.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(serieRegisterBCV)
            } else {
                setLoading(false)
                if (serieRegisterBCV?.codigo === "000") {
                    setMensaje("Serie proveniente de la BCV registrada")

                    const removesSerie = history.location.state.objMenu.filter((item) => item.codigoIsin !== codigoISIN);
                    //New array of emitters set to our state
                    setSeriesFiltradas(removesSerie);
                    setSerieRegistradaBCV(true)
                } else if (serieRegisterBCV?.codigo === "001") {
                    setProcesoTeminado(true)
                    setMensaje(serieRegisterBCV?.mensaje)
                }
            }
        }

    }



    const irInicio = () => {
        setProcesoTeminado(false)
        history.push({
            pathname: ruta
        });
    }


    const regresarASeriesBCV = () => {
        setSerieRegistradaBCV(false);
        history.push({
            pathname: '/registroSeriesBCV', state: { seriesFiltradas: seriesFiltradas }
        })
    }

    const regresar = () => {
        //console.log(history.location.state.objMenu[0].codigoIsin)


        const removesSerie = history.location.state.objMenu.filter((item) => item.codigoIsin !== codigoISIN);
        history.push({
            pathname: '/registroSeriesBCV',
            state: {
                seriesFiltradas: removesSerie,
            }
        })
    }

    const onHideModal = () => {
    }

    return (
        <>
            <div className="fluid-container">
                {loading &&
                    <PantallaCargando />
                }

                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }

                <Modal show={desicionRegistroSerie} animation={false} centered onHide={onHideModal}>
                    <div className="containerModal">
                        <div className="modal-header">
                            <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                            <button type="button" onClick={() => setDesicionRegistroSerie(false)} class="btn-close" aria-label="Close"></button>
                        </div>
                        <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >{mensaje}</p>
                        <div className="row justify-content-evenly">
                            <button onClick={() => setDesicionRegistroSerie(false)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Cancelar</button>
                            <button onClick={registerSerieBCVRespuestaPrositiva} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Aceptar</button>
                        </div>
                    </div>
                </Modal>


                {procesoTerminado &&
                    <ComponenteModal mostrarModal={procesoTerminado} cerrarModal={irInicio} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
                }

                {procesoTerminadoError &&
                    <ComponenteModal mostrarModal={procesoTerminadoError} cerrarModal={() => setProcesoTeminadoError(false)} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
                }



                {serieRegistradaBCV &&
                    <ComponenteModal mostrarModal={serieRegistradaBCV} cerrarModal={regresarASeriesBCV} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
                }

                {errorsinMotivoRechazo &&
                    <ComponenteModal mostrarModal={errorsinMotivoRechazo} cerrarModal={() => setErrorSinMotivoDeRechazo(false)} titulo="Tienes que agregar un motivo de rechazo" textoBoton="Entendido"></ComponenteModal>
                }

                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                       
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo={titulo}></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <section className="d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded p-3">
                                                <div className="col-12">
                                                    <form className="font-acumin-variable">
                                                        <div className="row mb-3 " >
                                                            <div className="col-12 col-md-12 row justify-content-center mb-5 my-4 ">
                                                                <h3 className="text-center fs-2 text-royal-blue col-10  border-bottom">Serie: {nombreSerie}</h3>
                                                            </div>
                                                            <div className="col-12 col-md-3 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={nombreSerie} title="Serie" className="form-control" name="serie" type="text" placeholder="Series" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="serie">Serie</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-9 mb-3">
                                                                <div className="form-floating">
                                                                    <input disabled value={codigoSerie} title="Código de la serie" className="form-control" name="codigoSerie" type="text" placeholder="Código de serie" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="codigoSerie">Código de serie</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-3 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={codigoEmision} title="Código de la emisión" className="form-control" maxLength={10} name="codigoEmisionSerie" type="text" placeholder="Nombre completo de la emision" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="codigoEmisionSerie">Código de la emisión</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-9 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={nombreEmision} title="Nombre completo de la emisión" className="form-control" name="nombreEmisionSerie" type="text" placeholder="Nombre completo de la emision" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="nombreEmisionSerie">Nombre completo de la emisión</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-3 mb-3">
                                                                <div className="form-floating ">

                                                                    <input disabled value={fechaEmisionSerie} title="Fecha de emisión" className="form-control" type="text" name="fechaEmision" placeholder="Fecha de emisión" required />


                                                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Fecha de emisión</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-3 mb-3">
                                                                <div className="form-floating ">

                                                                    <input disabled value={fechaVencimiento} title="Fecha de vecimiento" className="form-control" type="text" name="fechaVencimiento" placeholder="Fecha de vencimiento" required />


                                                                    <label className="text-start font-acumin-variable" htmlFor="fechaVencimiento">Fecha de vencimiento</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={codigoISIN} itle="Código ISIN" className="form-control" name="codigoISIN" type="text" placeholder="Código ISIN" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="codigoISIN">Código ISIN</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-3 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={monedaSerie} title="Moneda de la serie" className="form-control" type="text" name="monedaSerie" placeholder="Moneda de la serie" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="monedaSerie">Moneda de la serie</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-3 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={monedaLiquidacion} title="Moneda de la liquidación" className="form-control" type="text" name="monedaLiquidacion" placeholder="Moneda de la liquidación" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="monedaLiquidacion">Moneda de liquidación</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-3 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={periodicidad} title="Periodicidad" className="form-control" type="text" name="periodicidad" placeholder="Periodicidad" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="periodicidad">Número de periodos</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-3 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={formaColocacion} title="Forma de colocación" className="form-control" type="text" name="formaColocacion" placeholder="Forma colocación" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="formaColocacion">Forma de colocación</label>
                                                                </div>
                                                            </div>


                                                            <div className="col-12 col-md-3 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={tasaInteresNominal} title="Tasa de interés nominal" className="form-control" type="text" name="tasaInteresNominal" placeholder="Tasa de interés nominal" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="tasaInteresNominal">Tasa de interés nominal </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-3 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={serieMonto} itle="Monto de la serie" className="form-control" type="text" name="montoSerie" placeholder="Monto de la serie" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="montoSerie">Monto de la serie</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-3 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={spreadSerie} axLength={10} title="Spread de tasa" className="form-control" name="rtnEmispreadTasasorSerie" type="text" placeholder="RTN Emisor" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="spreadTasa">Spread de tasa</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-3 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={tasaVariable} itle="Tipo de tasa" className="form-control" type="text" name="tipoTasa" placeholder="Tipo de tasa" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="tipoTasa">Tipo de tasa</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={tipoTasaVariable} title="Tipo de tasa variable" className="form-control" type="text" name="tasaVariable" placeholder="Tasa variable" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="tasaVariable">Tipo de tasa variable</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled maxLength={10} value={PeriodoRevisionTasaVariable} title="Periodo de revision de tasa variable" className="form-control" name="periodoRevision" type="text" placeholder="Periodo Revision Tasa Variable" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="periodoRevision">Periodo de revisión de tasa variable</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={instrumentosEmitidos} title="Cantidad de instrumentos emitidos" className="form-control" type="text" name="cantidadInstrumentosEmitidos" placeholder="Cantidad instrumento emitidos" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="cantidadInstrumentosEmitidos">Cantidad de instrumentos emitidos</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={instrumentosDisponibles} title="Cantidad de instrumentos emitidos" className="form-control" type="text" name="cantidadInstrumentosEmitidos" placeholder="Cantidad instrumento emitidos" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="cantidadInstrumentosEmitidos">Cantidad de instrumentos disponibles</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={valNominalPorTitulo} title="Valor nominal de cada título" className="form-control" type="text" name="valorNominalTitulo" placeholder="Valor nominal de cada título" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="valorNominalTitulo">Valor nominal de cada título</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-4 mb-3">
                                                                <div className="form-floating ">
                                                                    <input disabled value={formaRepresentacion} title="Forma de representación" className="form-control" type="text" name="valorNominalTitulo" placeholder="Valor nominal de cada título" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="valorNominalTitulo">Forma de representación</label>
                                                                </div>
                                                            </div>

                                                            {estatusSerie === 5 &&
                                                                <div className="col-12 col-md-4 ">
                                                                    <div className="form-floating ">
                                                                        <input value={tipoDeCambio} title="Nombre completo de la emisión" className="form-control text-uppercase" onChange={(e) => setTipoDeCambio(e.target.value)} name="nombreEmisionSerie" type="text" placeholder="Nombre completo de la emision" required />
                                                                        <label className="text-start font-acumin-variable" htmlFor="nombreEmisionSerie">Tipo de cambio</label>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {estatusSerie === 0 &&
                                                                <div class="form-floating">
                                                                    <textarea class="form-control" value={descripcion} placeholder="Leave a comment here" id="floatingTextarea2" style={{ height: "7rem" }} required disabled></textarea>
                                                                    <label for="floatingTextarea2" className="ms-2">Motivo del rechazo</label>
                                                                </div>
                                                            }



                                                            <div className="col-12 col-md-12 mb-3 mt-5 row justify-content-center">
                                                                {estatusSerie === 5 ?
                                                                    <>
                                                                        <div className="form-floating mx-5 row col-8 col-md-3 justify-content-center marginButtonAprove">
                                                                            <button className="btn background-royal-blue text-white bg-hover-gold col-12 col-md-12" type="button" onClick={registerSerieBCV}>Registrar</button>
                                                                        </div>

                                                                        <div className="form-floating mx-5 row col-8 col-md-3 justify-content-center">
                                                                            <button className="btn background-red text-f7 col-12 col-md-12" onClick={regresar}>Cancelar</button>

                                                                        </div>
                                                                    </>
                                                                    :

                                                                    <>
                                                                        {estatusSerie === 1 ?

                                                                            <>
                                                                                {desplegarCampoParaTexto ?
                                                                                    <>

                                                                                        <div class="form-floating">
                                                                                            <textarea class="form-control me-2" placeholder="Leave a comment here" onChange={(e) => {
                                                                                                setMotivoRechazo(e.target.value)
                                                                                            }} id="floatingTextarea2" style={{ height: "7rem" }} required></textarea>
                                                                                            <label for="floatingTextarea2" className="ms-2">Escriba el motivo del rechazo</label>
                                                                                        </div>

                                                                                        <div className='contenedorBotonesStteper  '>
                                                                                            <button type="button" onClick={() => { setDesplegarCampoParaTexto(false) }}
                                                                                                className="btn botonFormularioDepositanteRechazar mt-2">Cancelar</button>
                                                                                            <button type="submit" className="btn botonFormularioDepositante  mt-2" onClick={(e) => {
                                                                                                rejectSerie(e)
                                                                                            }}>Enviar</button>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <div className="form-floating mx-5 row col-8 col-md-3 justify-content-center marginButtonAprove">
                                                                                            <button onClick={(e) => {
                                                                                                authorizeSerie(e)
                                                                                                setLoading(true)
                                                                                            }} className="btn background-royal-blue text-white bg-hover-gold col-12 col-md-12" >Autorizar</button>
                                                                                        </div>
                                                                                        <div className="form-floating mx-5 row col-8 col-md-3 justify-content-center">
                                                                                            <button type="button" onClick={() => { setDesplegarCampoParaTexto(true) }}
                                                                                                className="btn background-red text-f7 col-12 col-md-12" >Rechazar</button>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                            </>
                                                                            :

                                                                            <>
                                                                                {estatusSerie === 2 ?

                                                                                    <div className="form-floating mx-5 row col-8 col-md-3 justify-content-center marginButtonAprove">
                                                                                        <button onClick={(e) => {
                                                                                            registerSerie(e)
                                                                                            setLoading(true)
                                                                                        }} className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Notificar Registro</button>
                                                                                    </div>

                                                                                    :
                                                                                    <>
                                                                                        {estatusSerie === 0 ?
                                                                                            <div className="form-floating mx-5 row col-8 col-md-3 justify-content-center marginButtonAprove">
                                                                                                <button onClick={(e) => {
                                                                                                    notifyRejectedSerie(e)
                                                                                                    setLoading(true)
                                                                                                }} className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Notificar rechazo</button>
                                                                                            </div>
                                                                                            :
                                                                                            <div className="form-floating mx-5 row col-8 col-md-3 justify-content-center marginButtonAprove">
                                                                                                <button onClick={(e) => {
                                                                                                    irInicio()
                                                                                                }} className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Cerrar</button>
                                                                                            </div>

                                                                                        }

                                                                                    </>
                                                                                }
                                                                            </>

                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    )
}