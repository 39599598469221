import { useMemo } from "react";

export default function useFondosRemanentes() {
 const columns = useMemo(
   () => [   
     {
       Header:"Código de cliente CEHVAL",
       accessor: "codigoDepositanteCliente"
     },
     {
        Header:"Nombre cliente CEHVAL",
        accessor: "nombreDepositante"
      },
      {
        Header:"Monto remanente",
        accessor: "montoRemanenteStr"
      },
      {
        Header:"Cuenta cliente CEHVAL",
        accessor: "numeroCuentaDepositante"
      },
      {
        Header:"Tipo de cuenta",
        accessor: "tipoCuentaDepositante"
      },
      {
        Header:"Banco",
        accessor: "bancoDepositante"
      },
      {
        Header:"Estatus",
        accessor: "estatusStr"
      },
   ],
   []
 );

 return columns;
}


