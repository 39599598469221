//React
import React, { useState, useEffect } from "react";



//Componentes generales
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../ComponentesGenerales/ManejadorErrores';
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { consultaSolicitudesAsignacionIsin } from "../../../api/GeneracionAsignacionCodigosISIN/consultaSolicitudesAsignacionIsin";
import { actualizarSolicitudCodIsin } from "../../../api/GeneracionAsignacionCodigosISIN/actualizarSolicitudCodIsin";

//Hooks
import useSolicitudesAsignacionCodIsin from '../../../hooks/SolicitudGeneracionCodigoISIN/useSolicitudesAsignacionCodIsin';


export default function NotificarSolicitudesRechazadasISIN({ history }) {


    //Save loaded data
    const [copia, setCopia] = useState();
    const [data, setData] = useState([]);

    //Spinner
    const [spin, setSpin] = useState(true);
    const [loading, setLoading] = useState();

    //States to handle load data when loading page 
    const [erroreshttp, setErroreshttp] = useState();


    const [failLoadData, setFailLoadData] = useState();


    const [statusRequest, setStatusRequest] = useState();


    useEffect(() => {
        getAllApplications();
    }, []);


    //Get data to fill the table
    const getAllApplications = async () => {
        try {
            const response = await consultaSolicitudesAsignacionIsin({ solicitudIsinEstado: 4 });
            if (response) {
                setSpin(false);

                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {

                    if (response.data) {
                        setSpin(false);
                        setData(response.data);
                        setCopia(response.data)
                    } else {
                        setFailLoadData("No se pudo cargar la información");
                    }
                }
            }



        } catch (err) {
            console.error(err);
        }
    }



    //Send email 
    const notifyRequest = async (row) => {

        const rowInfo = row.original;
        const obj = {
            solicitudId: rowInfo.solicitudIsinId,
            solicitudIsinEstado: 0,
        }
        //console.log(obj)

        setLoading(true);
        const response = await actualizarSolicitudCodIsin(obj);
        if (response) {
            setLoading(false);
            //setCargando(false)
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(response)
            } else {
                //console.log(response)
                setLoading(false);

                setStatusRequest(response.data.mensaje);


            }
        }
    }


    const reloadPage = () => {
        window.location.reload();
    }


    const goBack = () => {
        history.push({
            pathname: '/inicioCehval',
        })
    }


    return (
        <>
            {loading &&
                <PantallaCargando></PantallaCargando>
            }

            {failLoadData &&
                <ComponenteModal mostrarModal={failLoadData} cerrarModal={goBack} titulo={failLoadData} textoBoton="Aceptar"></ComponenteModal>
            }
            {statusRequest &&
                <ComponenteModal mostrarModal={statusRequest} cerrarModal={reloadPage} titulo={statusRequest} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="">
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <main className="min-height-40">
                    <div className="row row-wrap g-0">

                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Notificar rechazo de Solicitudes de Generación y Asignación de Código ISIN"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaUsuarios datos={data} displayInformation={notifyRequest} useValidate={useSolicitudesAsignacionCodIsin} textoBoton={"Enviar email"} textTitle="Clic para enviar notificación de rechazo"></TablaUsuarios>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}





