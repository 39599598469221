
import { instance } from '../apiGlobal';

export async function traerDatosDepositanteParaEmisor(obj) {
    //console.log(obj)
    
  try {
    const response = await instance(`/emisor/consulta/depositante/codigo`, {
      method: "post",
      data: obj
    });
    
    return response.data;
  } catch (error) {
    return error
  }
}