import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";

//API's
import { traerValoresPorNumeroCuenta } from "../../../api/cuentaValores/traerValoresPorNumeroCuenta";

//Hooks
import useCuentaValoresTLP from "../../../hooks/useCuentaValoresTLP";

export default function ValoresDepositanteCehval({ history }) {
    if (history.location.state === undefined) {
        history.go(-1)
        //window.location.replace("/misClientesDD");
    }

    const [codigoDepositanteDirecto, setCodigoDepositanteDirecto] = useState();
    const [nombreDepositanteDirecto, setNombreDepositanteDirecto] = useState();
    const [codigoDepositante, setCodigoDepositante] = useState();
    const [nombreDepositante, setNombreDepositante] = useState();
    const [numeroCuenta, setNumeroCuenta] = useState();
    const [monedaCuenta, setMonedaCuenta] = useState();
    const [erroreshttp, setErroreshttp] = React.useState()

    //We'll use the id to do the transaction in the back-end
    //const [idValorRow, setIdValorRow] = useState();

    const [data, setData] = useState([])
    const [copia, setCopia] = useState();

    //States to show modal when regiter is successful or not
    const [resquestFail, setRequestFail] = useState();

   

    //const tipo=history.location.state.datosCliente.tipo;


    useEffect(() => {
        verValores();
    }, []);

    const historyReact = useHistory();

    //Handle the api to get the values for the selected account
    const verValores = async () => {

        //console.log("valores")
        try {

         

                const objetoClienteDD = history.location.state.datosCliente;
                //console.log(objetoClienteDD)
                const { codigo, nombreDepositante} = objetoClienteDD.objCliente;
                const { numeroCuenta, monedaCuenta } = objetoClienteDD;

                setCodigoDepositanteDirecto(objetoClienteDD.objCliente.codigoDepositanteDirecto)
                setNombreDepositanteDirecto(objetoClienteDD.objCliente.nombreDepositanteDirecto)
                setCodigoDepositante(codigo);
                setNombreDepositante(nombreDepositante);
                setNumeroCuenta(numeroCuenta);
                setMonedaCuenta(monedaCuenta);

                //Hacemos la peticióon a la DB para traer todos los valores que hay en esa cuenta
                const response = await traerValoresPorNumeroCuenta({ numeroCuentaValores: numeroCuenta });
                if (response) {
                    //setCargando(false)
                    if ((isNaN(response.error)) === false) {
                        // respuestaincorrecta           
                        setErroreshttp(response)
                    } else {
                        //console.log(response.data)
                        setData(response.data);
                        setCopia(response.data);
                      
                    }
                }
                
        } catch (error) {
            console.error(error);
        }
    }

    //Handle whe user click on "Transferir", and we'll origin account data for transaction with the id
    const transferValue = (row) => {
        //console.log(row.original)

        //const saldoDisponible = row.original.cantidadValores;
        //const isin = row.original.isinSerie;
        //const codSerie = row.original.codigoSerie;
        //const codEmision = row.original.codigoEmision;

        const datosParaFormulario = {
            codigoDepositanteDirecto:codigoDepositanteDirecto,
            nombreDepositanteDirecto:nombreDepositanteDirecto,
            codigoDep: codigoDepositante,
            nombreDep: nombreDepositante,
            numeroCuenta: numeroCuenta,
            moneda: monedaCuenta,
            saldoDisponible: row.original.cantidadValores,
            isinSerie: row.original.isinSerie,
            codSerie: row.original.codigoSerie,
            codEmision: row.original.codigoEmision,
            idValor: row.original.idValor,
        }

        //console.log(datosParaFormulario)

       history.push({
          pathname: "/TLPOperadorCehval",
           state: {
               datosTransferente: datosParaFormulario
        },
        })
    }

    //Handle modal if data for table doesn load
    const goBack = () => {
        setRequestFail(false);
        window.location.replace("/misClientesDD");
    }

    return (
        <>

            {resquestFail &&
                <ComponenteModal mostrarModal={resquestFail} cerrarModal={goBack} titulo={`Serás redirigido a la tabla mis clientes`} textoBoton="Aceptar"></ComponenteModal>
            }
              {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
    

            <div className="fluid-container">

                <main className='container-min-height'>
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Valores del depositante"></BannerTitulo>
                                <div className="row justify-content-center align-items-center table-container g-0">
                                    {copia ?
                                        <div className="container py-5">
                                            <form className="px-3">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <div className="form-floating ">
                                                            <input title="Número de cuenta de origen" value={numeroCuenta} className="form-control text-uppercase" name="numeroCuentaOrigen" type="text" placeholder="Número de cuenta de origen" required disabled />
                                                            <label className="text-start font-acumin-variable text-royal-blue" htmlFor="numeroCuentaOrigen">Número de cuenta</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <div className="form-floating ">
                                                            <input title="Código del depositante" value={codigoDepositante} className="form-control text-uppercase" name="codigoDepTransferente" type="text" placeholder="Código del depositante" required disabled />
                                                            <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoDepTransferente">Código del depositante</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <div className="form-floating ">
                                                            <input title="Código ISIN" value={monedaCuenta} className="form-control text-uppercase" name="monedaTransferente" type="text" placeholder="Código ISIN" required disabled />
                                                            <label className="text-start font-acumin-variable text-royal-blue" htmlFor="monedaTransferente">Moneda</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <div className="form-floating ">
                                                            <input title="Número de cuenta de origen" value={nombreDepositante} className="form-control text-uppercase" name="nombreDepTransferente" type="text" placeholder="Nombre del depositante" required disabled />
                                                            <label className="text-start font-acumin-variable text-royal-blue" htmlFor="nombreDepTransferente">Nombre del depositante</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                          
                                            <TablaUsuarios datos={data} useValidate={useCuentaValoresTLP} displayInformation={transferValue} textoBoton={"Transferir"} ></TablaUsuarios>
                                           
                                            <div className="p-3">
                                                <button className="btn background-royal-blue text-white font-acumin-variable" onClick={() => { historyReact.goBack() }}>Atrás</button>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className="row row-wrap justify-content-center">
                                                <h4 className="col-12 text-center">Cargando...</h4>
                                                <div className="col-12 spinner-border" role="status"></div>
                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </main >

            </div >
        </>
    );
}


