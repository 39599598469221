import React from "react";
import '../../../../styles/stylesheet.css'
import { FormContext } from './form_context';
import { FormContext2 } from './form_context2';
import { traerPuestos } from "../../../../api/catalogos/traerPuestos";
import { traerNacionalidad } from "../../../../api/catalogos/traerNacionalidad";
import { insertarActividadEconomica } from "../../../../api/InsertarACatalogos/insertarActividadEconomica";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";

export default function ActualizarDD5({ tipo }) {
    const [value, setValue] = React.useContext(FormContext);
    const [value2, setValue2] = React.useContext(FormContext2);

    const [erroreshttp, setErroreshttp] = React.useState()
    const [spinSend, setSpinSend] = React.useState(false)
    const [puestos, setPuestos] = React.useState([])
    const [agregarPuesto, setAgregarPuestos] = React.useState()
    const [puestoNuevo, setPuestoNuevo] = React.useState()
    const [tipoFirmaInicio1, setTipoFirmaInicio1] = React.useState(true)
    const [tipoFirmaInicio2, setTipoFirmaInicio2] = React.useState(true)

    // const [fechaLimiteDocumentos, setFechaLimiteDocumentos] = React.useState()

    const guardarDatos = (e) => {
        const { name, value } = e.target;


        setValue2((prev) => {
            const datos = { ...prev.datos, [name]: value };
            return { ...prev, datos };
        });
    }

    const guardarDatosFiltrados = (name, value) => {
        setValue2((prev) => {
            const datos = { ...prev.datos, [name]: value };
            return { ...prev, datos };
        });
    }

    React.useEffect(() => {
    


        guardarDatosFiltrados("nombreCompleto1", value2.datos.nombreCompleto1.trim())
        guardarDatosFiltrados("segundoNombre1", value2.datos.segundoNombre1.trim())
        guardarDatosFiltrados("primerApellido1", value2.datos.primerApellido1.trim())
        guardarDatosFiltrados("segundoApellido1", value2.datos.segundoApellido1.trim())
        guardarDatosFiltrados("correo1", value2.datos.correo1.trim())
        guardarDatosFiltrados("nombreCompleto2", value2.datos.nombreCompleto2.trim())
        guardarDatosFiltrados("segundoNombre2", value2.datos.segundoNombre2.trim())
        guardarDatosFiltrados("primerApellido2", value2.datos.primerApellido2.trim())
        guardarDatosFiltrados("segundoApellido2", value2.datos.segundoApellido2.trim())
        guardarDatosFiltrados("correo2", value2.datos.correo2.trim())




        const nombreCompleto1 = value2.datos.nombreCompleto1 + " " + value2.datos.segundoNombre1 + " " + value2.datos.primerApellido1 + " " + value2.datos.segundoApellido1
        setValue2((prev) => {
            const datos = { ...prev.datos, ["nombreCompleto_1"]: nombreCompleto1 };
            return { ...prev, datos };
        });

        const nombreCompleto2 = value2.datos.nombreCompleto2 + " " + value2.datos.segundoNombre2 + " " + value2.datos.primerApellido2 + " " + value2.datos.segundoApellido2
        setValue2((prev) => {
            const datos = { ...prev.datos, ["nombreCompleto_2"]: nombreCompleto2 };
            return { ...prev, datos };
        });


        traerDatos()

    }, [])

    const traerDatos = async () => {

        //console.log()
        const catalogoPuestos = await traerPuestos()
        //console.log(catalogoPuestos)
        if (catalogoPuestos) {
            //setSpin(false)
            if ((isNaN(catalogoPuestos.error)) === false) {
              
                setErroreshttp(catalogoPuestos)
                
            } else {
                // //console.log(traerInformacion)
                //setActividadEconomica(catalogoNacionalidad)
                setPuestos(catalogoPuestos)
            }
        }
    }

    return (
        <>
            <div className="card-body pt-3">

                <h5 className="text-royal-blue font-acumin-variable mb-4" >V. Firmas autorizadas</h5>
                {tipo === "depositanteDirecto" ? <h6 className="textoTituloFormulario" >1. Usuario administrador *</h6> :
                    <h6 className="textoTituloFormulario" >1. Usuario operador *</h6>
                }
                {value.datos.establecerModificacion ?
                    <>
                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input type="text" className="form-control fs-5 border  " value={value2.datos.nombreCompleto_1} onChange={(e) => guardarDatos(e)} id="nombreCompleto_1" name="nombreCompleto_1" disabled />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Nombre completo </label>
                                </div>
                            </div>

                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    <select className="form-select fs-5 border" value={value2.datos.cargo_1} onChange={(e) => guardarDatos(e)} id="cargo_1" name="cargo_1" required>
                                        <option value=""> </option>
                                        {
                                            puestos.map((item, index) => (
                                                <option key={index} value={item.nombre} >{item.nombre}</option>
                                            ))
                                        }

                                    </select>
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Cargo </label>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3 mb-4">
                                <label>Tipo de firma </label>
                                {tipoFirmaInicio1 ?
                                    <div className="d-flex justify-content-start">
                                        <div className="contenedorBotonesStteper ">
                                            {value2.datos.tipoFirma_1 === "INDIVIDUAL" ?
                                                < input  placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => {
                                                    guardarDatos(e)
                                                    setTipoFirmaInicio1(false)
                                                }} id="individual1" name="tipoFirma_1" checked />
                                                :
                                                < input placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => {
                                                    guardarDatos(e)
                                                    setTipoFirmaInicio1(false)
                                                }} id="individual1" name="tipoFirma_1" required />

                                            }

                                            <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="individual1">Individual</label>
                                        </div>

                                        <div className="contenedorBotonesStteper">
                                            {value2.datos.tipoFirma_1 === "INDIVIDUAL" ?
                                                < input placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => {
                                                    guardarDatos(e)
                                                    setTipoFirmaInicio1(false)
                                                }} id="mancomunada1" name="tipoFirma_1" required />
                                                :
                                                < input placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => {
                                                    guardarDatos(e)
                                                    setTipoFirmaInicio1(false)
                                                }} id="mancomunada1" name="tipoFirma_1" checked />
                                            }
                                            <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="mancomunada1">Mancomunada</label>

                                        </div>
                                    </div>
                                    :
                                    <div className="d-flex justify-content-start">
                                        <div className="contenedorBotonesStteper ">
                                            < input placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => guardarDatos(e)} id="individual1" name="tipoFirma_1" required />
                                            <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="individual1">Individual</label>
                                        </div>

                                        <div className="contenedorBotonesStteper">
                                            < input placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => guardarDatos(e)} id="mancomunada1" name="tipoFirma_1" required />
                                            <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="mancomunada1">Mancomunada</label>

                                        </div>
                                    </div>

                                }

                            </div>
                        </div>
                        <h6 className="textoTituloFormulario" >2. Usuario operador *</h6>
                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input type="text" className="form-control fs-5 border  " value={value2.datos.nombreCompleto_2} onChange={(e) => guardarDatos(e)} id="nombreCompleto_2" name="nombreCompleto_2" disabled />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Nombre completo </label>
                                </div>
                            </div>

                            <div className="col-md-4 form-floating">
                                <div className="form-floating">
                                    <select className="form-select fs-5 border" value={value2.datos.cargo_2} onChange={(e) => guardarDatos(e)} id="cargo_2" name="cargo_2" required>
                                        <option value=""> </option>
                                        {
                                            puestos.map((item, index) => (
                                                <option key={index} value={item.nombre} >{item.nombre}</option>
                                            ))
                                        }

                                    </select>
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Cargo </label>
                                </div>
                            </div>


                            <div className="col-md-4 mt-3">
                                <label>Tipo de firma </label>
                                {tipoFirmaInicio2 ?
                                    <div className="d-flex justify-content-start">
                                        <div className="contenedorBotonesStteper">
                                            {value2.datos.tipoFirma_2 === "INDIVIDUAL" ?
                                                < input  placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => {guardarDatos(e) 
                                                    setTipoFirmaInicio2(false)}} id="individual2" name="tipoFirma_2" checked />
                                                :
                                                < input placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => {guardarDatos(e) 
                                                    setTipoFirmaInicio2(false)}} id="individual2" name="tipoFirma_2" required />
                                            }

                                            <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="individual2">Individual</label>
                                        </div>

                                        <div className="contenedorBotonesStteper">
                                            {value2.datos.tipoFirma_2 === "INDIVIDUAL" ?
                                                < input placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => {guardarDatos(e) 
                                                    setTipoFirmaInicio2(false)}} id="mancomunada2" name="tipoFirma_2" required />
                                                :
                                                < input placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => {guardarDatos(e) 
                                                    setTipoFirmaInicio2(false)}} id="mancomunada2" name="tipoFirma_2" checked />
                                            }

                                            <label className="form-check-label textoFormularioDepositantedirecto  mt-1" htmlFor="mancomunada2">Mancomunada</label>
                                        </div>
                                    </div>
                                    :
                                    <div className="d-flex justify-content-start">

                                        <div className="contenedorBotonesStteper">
                                            < input placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => guardarDatos(e)} id="individual2" name="tipoFirma_2" required />
                                            <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="individual2">Individual</label>
                                        </div>

                                        <div className="contenedorBotonesStteper">
                                            < input placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => guardarDatos(e)} id="mancomunada2" name="tipoFirma_2" required />
                                            <label className="form-check-label textoFormularioDepositantedirecto  mt-1" htmlFor="mancomunada2">Mancomunada</label>
                                        </div>
                                    </div>


                                }

                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5 border" value={value2.datos.lugarRegistro} onChange={(e) => guardarDatos(e)} id="lugarRegistro" name="lugarRegistro" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Lugar de registro </label>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input  type="text" className="form-control fs-5 border  " value={value.datos.nombreCompleto_1} id="nombreCompleto_1" name="nombreCompleto_1" disabled />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Nombre completo </label>
                                </div>
                            </div>

                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input  type="text" className="form-control fs-5 border  " value={value.datos.cargo_1} id="nombreCompleto_1" name="nombreCompleto_1" disabled />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Cargo</label>
                                </div>
                            </div>

                            <div className="col-md-4 mt-3 mb-4">
                                <label>Tipo de firma </label>
                                <div className="d-flex justify-content-start">
                                    <div className="contenedorBotonesStteper ">
                                        {value.datos.tipoFirma_1 === "INDIVIDUAL" ?
                                            < input disabled placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => guardarDatos(e)} id="individual1" name="tipoFirma_1" checked />
                                            :
                                            < input disabled placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => guardarDatos(e)} id="individual1" name="tipoFirma_1" required />

                                        }

                                        <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="individual1">Individual</label>
                                    </div>

                                    <div className="contenedorBotonesStteper">
                                        {value.datos.tipoFirma_1 === "INDIVIDUAL" ?
                                            < input disabled placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => guardarDatos(e)} id="mancomunada1" name="tipoFirma_1" required />
                                            :
                                            < input disabled placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => guardarDatos(e)} id="mancomunada1" name="tipoFirma_1" checked />
                                        }

                                        <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="mancomunada1">Mancomunada</label>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <h6 className="textoTituloFormulario" >2. Usuario operador *</h6>
                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input  type="text" className="form-control fs-5 border  " value={value.datos.nombreCompleto_2} onChange={(e) => guardarDatos(e)} id="nombreCompleto_2" name="nombreCompleto_2" disabled />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Nombre completo </label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input type="text" className="form-control fs-5 border  " value={value.datos.cargo_2} id="nombreCompleto_1" name="nombreCompleto_1" disabled />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Cargo</label>
                                </div>
                            </div>


                            <div className="col-md-4 mt-3">
                                <label>Tipo de firma </label>
                                <div className="d-flex justify-content-start">
                                    <div className="contenedorBotonesStteper">
                                        {value.datos.tipoFirma_2 === "INDIVIDUAL" ?
                                            < input disabled placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => guardarDatos(e)} id="individual2" name="tipoFirma_2" checked />
                                            :
                                            < input disabled placeholder="" type="radio" className="form-check-input me-2" value={"INDIVIDUAL"} onChange={(e) => guardarDatos(e)} id="individual2" name="tipoFirma_2" required />
                                        }

                                        <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="individual2">Individual</label>
                                    </div>

                                    <div className="contenedorBotonesStteper">
                                        {value.datos.tipoFirma_2 === "INDIVIDUAL" ?
                                            < input disabled placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => guardarDatos(e)} id="mancomunada2" name="tipoFirma_2" required />
                                            :
                                            < input disabled placeholder="" type="radio" className="form-check-input me-2" value={"MANCOMUNADA"} onChange={(e) => guardarDatos(e)} id="mancomunada2" name="tipoFirma_2" checked />
                                        }

                                        <label className="form-check-label textoFormularioDepositantedirecto  mt-1" htmlFor="mancomunada2">Mancomunada</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-8 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input disabled  type="text" className="form-control fs-5 border" value={value.datos.lugarRegistro} onChange={(e) => guardarDatos(e)} id="lugarRegistro" name="lugarRegistro" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Lugar de registro </label>
                                </div>
                            </div>
                        </div>
                    </>
                }




            </div>
        </>
    );
}

