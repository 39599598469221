import { instance } from '../apiGlobal';

export async function imprimirTablaFondosConciliadosYEliminadosPDP(obj) {
      try {
        const response = await instance(`/pagoderechos/imprimir/tabla`, {
          method:"post",
          data:obj,
          responseType: 'blob',
        })
        return response.data
      } catch (error) {
        return error
      }
}