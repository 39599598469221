import React, { useState, useEffect } from "react";

//Components
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";
import formatNumber from "../../../../functions/FormatNumber";

//APIs
import { verificarAvisoParaNotificarDeposito } from "../../../../api/tarificacion/Conciliaciones/EmisorYDepositantes/verificarAvisoParaNotificarDeposito";
import { notificarDepositoFondos } from "../../../../api/tarificacion/Conciliaciones/EmisorYDepositantes/notificarDepositoFondos";
import { consultarFondosVirtualesLempira } from "../../../../api/MercadoSecundario/consultarFondosVirtualesLempira";



export default function NotificarDepositoFondosAvisoCobro({ history }) {


    const codigoDepositanteSearch = (JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo;



    const [erroreshttp, setErroreshttp] = useState();


    //Inputs
    const [codigoDepositante, setCodigoDepositante] = useState();
    const [nombreDepositante, setNombreDepositante] = useState("");
    const [folioBCH, setFolioBCH] = useState();
    const [folioAvisoCobro, setFolioAvisoCobro] = useState();
    const [fechaDeposito, setFechaDeposito] = useState();
    const [fechaNotificacion, setFechaNotificacion] = useState();


    //
    const [inputsMonto, setInputsMonto] = useState({
        montoADepositarStr: "",
        montoADepositarInt: "",
    });


    //Monto total a pagar
    const [montoTotalPagar, setMontoTotalPagar] = useState();
    const [montoTotalPagarStr, setMontoTotalPagarStr] = useState();
    const [messageMontoPagar, setMessageMontoPagar] = useState();


    const [statusRequestFolio, setStatusRequestFolio] = useState();
    const [statusRequestNotificar, setStatusRequestNotificar] = useState();


    const [isMontoDepositoMayorMontoRequerido, setIsMontoDepositoMayorMontoRequerido] = useState(false);
    const [avisoNoAsociado, setAvisoNoAsociado] = useState();



    const [isValidFolio, setIsValidFolio] = useState();
    const [loading, setLoading] = useState();



    //Errors and messages    
    const [sesionCaducada, setSesionCaducada] = useState();
    const [mensaje, setMensaje] = useState();



    useEffect(() => {
        loadDate();
    }, []);


    const loadDate = async () => {
        try {

            //Establece la fecha de hoy en el input tipo fechas
            const fecha = new Date()

            const opciones = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const fechaActual = fecha.toLocaleDateString('es-ES', opciones);

            //setFechaLimiteNacimiento(fecha_limite_cumpleaños)
            setFechaNotificacion(fechaActual);

        } catch (error) {
            console.error(error);
        }
    }



    const searchFolioAviso = async (e) => {
        e.preventDefault();
        try {

            const objAviso = {
                folioAvisoCobro: folioAvisoCobro,
                codigoParticipante: codigoDepositanteSearch,

            }
            //console.log(objAviso);

            setLoading(true);
            const response = await verificarAvisoParaNotificarDeposito(objAviso);
            setLoading(false);
            //console.log(response)

            if (isNaN(response.error) === false) {

                if (response.error === 500) {
                    setAvisoNoAsociado(`La factura con folio ${folioAvisoCobro} no esta asociado a alguno de sus avisos o de sus clientes. Por favor, inicie sesión con la cuenta relacionada a este aviso.`)
                } else {
                    setErroreshttp(response);
                }

            } else if (response.data) {

                //El folio si esta asociado al emisor, al depositante directo o a uno de sus clientes
                if (response.data.respuesta.mensaje === null) {

                    setStatusRequestFolio(response.data.respuesta.mensaje);
                    setCodigoDepositante(response.data.avisoCobro.datosParticipante.codigoDepositante);
                    setNombreDepositante(response.data.avisoCobro.datosParticipante.nombreDepositante);
                    setMontoTotalPagar(response.data.avisoCobro.montoTotal);
                    setMontoTotalPagarStr(response.data.avisoCobro.montoTotalStr);
                    setIsValidFolio(true);

                    //El folio no esta asociado a ninguno de sus clientes ni así mismo
                } else {
                    setIsValidFolio(false);
                    setStatusRequestFolio(response.data.respuesta.mensaje);
                }
            }


        } catch (error) {
            //console.log(error);
        }
    }




    const notificarAvisoCobro = async (e) => {
        e.preventDefault();
        //setDobleConfirmacion(false)
        const objAviso = {
            folioBchTr: folioBCH,
            monto: inputsMonto.montoADepositarInt,
            moneda: 1,
            fechaDeposito: fechaDeposito,
            folioAvisoCobro: folioAvisoCobro,
        }
        //console.log(objAviso);

        setLoading(true);
        const response = await notificarDepositoFondos(objAviso);
        setLoading(false);
        //console.log(response)



        if ((isNaN(response.error)) === false) {
            setErroreshttp(response);

        } else {
            setMensaje(response.data.mensaje);
            setStatusRequestNotificar(true);
        }


    }





    const handleInputMonto = (e) => {
        const selectValue = e.target.value;
        const formattedNumber = formatNumber(selectValue);

        //Call fuction to check the amount entered by user and the amount must pay
        checkMontoPagar(montoTotalPagar, formattedNumber.integerNumber);

        setInputsMonto({
            montoADepositarStr: formattedNumber.stringNumber,
            montoADepositarInt: formattedNumber.integerNumber,
        });
    }



    const checkMontoPagar = (totalAPagar, montoADepositar) => {
        if (montoADepositar < totalAPagar) {
            setMessageMontoPagar("El monto a depositar es menor al requerido");
            setIsMontoDepositoMayorMontoRequerido(false);
        } else {
            setMessageMontoPagar(false);
            setIsMontoDepositoMayorMontoRequerido(true);
        }
    }



    const cerrarModal = () => {
        setIsValidFolio(false);
        setStatusRequestFolio(false);
        setSesionCaducada(false);
        setAvisoNoAsociado(false);
        setStatusRequestNotificar(false);
        setCodigoDepositante("");
        setNombreDepositante("");
        setFolioBCH("");
        setFolioAvisoCobro("");
        setFechaDeposito("");
        setFechaNotificacion("");
        setMontoTotalPagar("");
        setMontoTotalPagarStr("");
        setInputsMonto({
            montoADepositarStr: "",
            montoADepositarInt: "",
        });
    }




    return (
        <>


            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {statusRequestFolio &&
                <ComponenteModal mostrarModal={statusRequestFolio} cerrarModal={cerrarModal} titulo={statusRequestFolio} textoBoton="Entendido"></ComponenteModal>
            }
            {avisoNoAsociado &&
                <ComponenteModal mostrarModal={avisoNoAsociado} cerrarModal={cerrarModal} titulo={avisoNoAsociado} textoBoton="Entendido"></ComponenteModal>
            }

            {statusRequestNotificar &&
                <ComponenteModal mostrarModal={statusRequestNotificar} cerrarModal={cerrarModal} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {sesionCaducada &&
                <ComponenteModal mostrarModal={sesionCaducada} cerrarModal={cerrarModal} titulo="Ups! Al parecer la sesión ha caducado. Vuelva a iniciar sesión o revise su conexión a internet" textoBoton="Entendido"></ComponenteModal>
            }


            {loading &&
                <PantallaCargando></PantallaCargando>
            }

            <div className="fluid-container">{/* style={{ backgroundImage: `url(${fondo})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="fluid-container">*/}
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">

                        <div className="col-12 mb-5 main-min-height">
                            <div className="">
                                <BannerTitulo titulo="Notificar depósito de fondos en BCH-TR a la CEHVAL para saldar factura"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <section className="d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded">
                                                <div className="col-12">
                                                    <form onSubmit={searchFolioAviso} className="needs-validation font-acumin-variable text-royal-blue">
                                                        <div className="row justify-content-start align-items-end mt-5 mb-5">
                                                            <div className="col-12 col-md-6 col-lg-6 col-xl-4 ps-3">
                                                                <div className="form-floating ">
                                                                    <input title="Folio del aviso de cobro" className="form-control fs-5" onChange={(e) => setFolioAvisoCobro(e.target.value)} value={folioAvisoCobro} maxLength={25} name="folioAvisoCobro" type="text" placeholder="Folio del aviso de cobro" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="folioAvisoCobro">Folio de la factura</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-lg-3 row align-items-end justify-content-start justify-content-md-start justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                                                                <button className="col-auto px-1 mx-2 btn background-royal-blue text-white font-acumin-variable bg-hover-gold align-end" type="submit" >Buscar factura</button>
                                                            </div>
                                                        </div>
                                                    </ form>
                                                    {isValidFolio &&

                                                        <form onSubmit={notificarAvisoCobro} className="needs-validation font-acumin-variable text-royal-blue">
                                                            <h5 className="font-acumin-variable text-royal-blue">A continuación, ingrese los datos requeridos por el formulario</h5>
                                                            <div className="row mt-4">
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input value={codigoDepositante} title="Código del depositante" className="form-control fs-5" maxLength={25} name="codigoDepositante" type="text" placeholder="codigoDepositante" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="codigoDepositante">Código de depositante</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 form-floating mb-3">
                                                                    <div className="form-floating">
                                                                        <input title="Nombre del depositante" type="text" className="form-control border" value={nombreDepositante} name="nombreDepositante" required disabled />
                                                                        <label htmlFor="nombreDepositante" className="form-label ">Nombre de depositante</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Folio BCH-TR" className="form-control fs-5" onChange={(e) => setFolioBCH(e.target.value)} value={folioBCH} maxLength={25} name="folioBCH" type="text" placeholder="Folio BCH-TR" required />
                                                                        <label className="text-start font-acumin-variable" htmlFor="folioBCH">Folio BCH-TR</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Moneda" className="form-control fs-5" maxLength={25} value={"HNL"} name="moneda" type="text" placeholder="Moneda" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="moneda">Moneda</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Fecha en la que se realizó el deposito" className="form-control text-uppercase" type="date" value={fechaDeposito} onChange={(event) => setFechaDeposito(event.target.value)} name="fechaEmision" placeholder="Fecha de emisión" required />
                                                                        <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Fecha de depósito *</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Fecha en la que se notifica el depósito" className="form-control fs-5" value={fechaNotificacion} name="fecha" type="text" placeholder="fecha" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="fecha">Fecha de notificación del depósito</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Monto total a pagar" className="form-control fs-5" value={montoTotalPagarStr} maxLength={20} name="montoTotalPagar" type="text" placeholder="Monto total a pagar" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="montoTotalPagar">Monto total a pagar</label>
                                                                        {messageMontoPagar &&
                                                                            <span className="text-danger font-acumin-variable mt-1">{messageMontoPagar}</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Monto a depositar" className="form-control fs-5" onInput={(e) => handleInputMonto(e)} value={inputsMonto.montoADepositarStr} maxLength={20} name="montoDepositar" type="text" placeholder="Monto a depositar" required />
                                                                        <label className="text-start font-acumin-variable" htmlFor="montoDepositar">Monto a depositar</label>
                                                                    </div>
                                                                </div>

                                                                {isMontoDepositoMayorMontoRequerido &&
                                                                    <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                        <button type="submit" className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-1 background-royal-blue text-white bg-hover-gold">Notificar</button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </form>
                                                    }

                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

