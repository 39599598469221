import { useMemo } from "react";

export default function useOficioAsignacionISIN () {
  const columns = useMemo(
    () => [
      {
        Header: "Fecha registro",
        accessor: "fechaRegistro"
      },
      {
        Header: "Código emisor",
        accessor: "solicitudIsinCodigoEmisor"
      },
      {
        Header: "Nombre emisor",
        accessor: "solicitudNombreEmisor"
      },
      {
        Header: "Código emisión",
        accessor: "solicitudIsinCodigoEmision"
      },
      {
        Header: "Nombre emisión",
        accessor: "solicitudNombreEmision"
      },
      {
        Header: "Cantidad series",
        accessor: "solicitudIsinCantidadSeries"
      }
    ],
    []
  );

  return columns;
}
