import { useMemo } from "react";

export default function useCuentaValoresTransito() {
  const columns = useMemo(
    () => [
      {
        Header: "Código del propietario de los valores",
        accessor: "codigoDepositantePropietario"
      },
      {
        Header: "Nombre del propietario de los valores",
        accessor: "nombreDepositantePropietario"
      },
      {
        Header: "Nombre de la emisión",
        accessor: "nombreEmision"
      },
      {
        Header: "Serie",
        accessor: "nombreSerie"
      },

      {
        Header: "ISIN",
        accessor: "isinSerie"
      },
      {
        Header: "Moneda",
        accessor: "monedaSerie"
      },

      {
        Header: "Cantidad",
        accessor: "cantidadValores"
      },
      {
        Header: "Representación",
        accessor: "desmaterializados"
      }

    ],
    []
  );

  return columns;
}
