import React from "react";
//import { useLocation } from "react-router-dom";


//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";

//Hooks
import useDepositantesCehval from "../../../hooks/TLPOperador/useDepositantesCehval";

//APIs
import { traerInformacionAllDepositante } from "../../../api/VerInformacionUsuarios/traerInformacionAllDepositantes";


export default function DepositantesCehval({ history }) {
    //const location = useLocation();
    //const tipo = location.state;
    
    const estatus = 5;
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()

    React.useEffect(() => {
        traerDepositantes()
    }, [])

    const traerDepositantes = async () => {
        const usuarios = await traerInformacionAllDepositante(estatus)
        if (usuarios) {
            setSpin(false)
            if ((isNaN(usuarios.error)) === false) {
              
                setErroreshttp(usuarios)
                
            } else {
                var filtrados = usuarios.filter(word => ((word.cehvalDepositanteCodigoCehval.indexOf("DDIRPJ") >= 0) || (word.cehvalDepositanteCodigoCehval.indexOf("DIND") >= 0)))
                setData(filtrados)
                setCopia(filtrados)
            }
        }
    }

    const verCuentas = (row) => {
        
        const objeto = {
          nombreDepositanteDirecto:row.original.cehvalDepositantePersonaIcbiId.cehvalPersonaJurNombre,
          codigoDepositanteDirecto:row.original.cehvalDepositanteCodigoDepositanteCB,//row.original.cehvalDepositantePersonaIcbiId.codigo,
          nombreDepositante:row.original.cehvalDepositanteNombreCompleto,  
          codigo:row.original.cehvalDepositanteCodigoCehval
        }
        //console.log(row.original)

        history.push({
            pathname: '/cuentasDepositantesCehval', state: { objClienteRow: objeto }
        })
    }

    return (
        <>

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <div className="fluid-container">

                <main className='container-min-height' >
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Depositantes"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaMercado datos={data} displayInformation={verCuentas} useValidate={useDepositantesCehval} textoBoton={"Ver cuentas"} textTitle="Click para ver cuentas del depositante"></TablaMercado>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}













