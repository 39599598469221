import { useMemo } from "react";

export default function useFondosNoConciliados () {
  const columns = useMemo(
    () => [
      {
        Header: "Folio BCH-TR",
        accessor: "folioBchTr"
      },
      {
        Header: "Código de depositante comprador",
        accessor: "codigoDepositante"
      },
      {
        Header: "Nombre de depositante comprador",
        accessor: "nombreDepositante"
      },
      {
        Header: "Monto",
        accessor: "montoStr"
      },
      {
        Header: "Moneda",
        accessor: "monedaStr"
      },
      {
        Header: "Estatus",
        accessor: "estatusDesc"
      }        
    ],
    []
  );

  return columns;
}
