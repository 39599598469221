import React from "react";
import '../../../../styles/stylesheet.css'
import { FormContext } from './form_context';
import { FormContext2 } from './form_context2';

import { TraerActividadEconomica } from "../../../../api/catalogos/traerActividadEconomica";
import { traerNacionalidad } from "../../../../api/catalogos/traerNacionalidad";
import { traerEstadosCiviles } from "../../../../api/catalogos/traerEstadosCiviles";
import { traerGeneros } from "../../../../api/catalogos/traerGeneros";
import { traerTiposIdentificacion } from "../../../../api/catalogos/traerTiposIdentificacion";

import { insertarActividadEconomica } from "../../../../api/InsertarACatalogos/insertarActividadEconomica";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";
import SpinnerSend from "../../../../ComponentesGenerales/SpinnerSend";

export default function ActualizarDI1({ history }) {
    const [value,] = React.useContext(FormContext);
    const [value2, setValue2] = React.useContext(FormContext2);
    const [actividadEconomica, setActividadEconomica] = React.useState([])
    const [nacionalidad, setNacionalidad] = React.useState([])
    const [estadosCiviles, setEstadosCiviles] = React.useState([])
    const [generos, setGeneros] = React.useState([])
    const [tiposIdentificacion, setTiposIdentificaion] = React.useState([])
    const [fechaLimiteNacimiento, setFechaLimiteNacimiento] = React.useState()
    const [fechaNacimientoInicio, setFechaNacimientoInicio] = React.useState()
    const [agregarActividadEconomica, setAgregarActividadEconomica] = React.useState()
    const [actividadNueva, setActividadNueva] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [spinSend, setSpinSend] = React.useState(false)

    const guardarDatos = (e) => {
        const { name, value } = e.target;
        if (name === "actividadEconomica") {
            if (value === "OTRO") {
                setAgregarActividadEconomica(true)
            } else {
                setValue2((prev) => {
                    const datos = { ...prev.datos, [name]: value };
                    return { ...prev, datos };
                });
                setAgregarActividadEconomica(false)
            }

        } else {
            setValue2((prev) => {
                const datos = { ...prev.datos, [name]: value };
                return { ...prev, datos };
            });
        }
    }

    React.useEffect(() => {
        establecerFecha()
        traerDatos()
    }, [])

    const establecerFecha = () => {
        
        setFechaLimiteNacimiento(conversionFecha(1, ""))
    

        if (typeof value2.datos.fechaNacimiento == "number") {
            const arrarf = convertirTime(value2.datos.fechaNacimiento).split("/");
            const fechaE = arrarf[1] + "/" + arrarf[0] + "/" + arrarf[2]
            //console.log(conversionFecha(3, fechaE))
            setFechaNacimientoInicio(conversionFecha(3, fechaE))
            //console.log("establecer2")


        }

    }

    const conversionFecha = (condicion, dato) => {
        let fecha;
        let fecha_regresada;
        if (condicion === 1 || condicion === 2) {
            fecha = new Date()
        } else {
            fecha = new Date(dato)
        }
        const añoActual = fecha.getFullYear();
        let diaActual = fecha.getDate();
        let mesActual = fecha.getMonth() + 1;

        if (diaActual < 10) {
            diaActual = "0" + diaActual
        }

        if (mesActual < 10) {
            mesActual = "0" + mesActual
        }

        if (condicion === 1) {
            fecha_regresada = (añoActual - 18) + "-" + mesActual + "-" + diaActual
        } else {
            fecha_regresada = (añoActual) + "-" + mesActual + "-" + (diaActual)
        }
        return fecha_regresada

    }


    const convertirTime = (info) => {
        let date = new Date((info / 1000) * 1000);
        return date.toLocaleDateString("en-GB")
    }

    const traerDatos = async () => {

        const catalogoActividadEconomica = await TraerActividadEconomica()
        if (catalogoActividadEconomica) {
            if ((isNaN(catalogoActividadEconomica.error)) === false) {
              
                setErroreshttp(catalogoActividadEconomica)
                
            } else {
                setActividadEconomica(catalogoActividadEconomica)
            }
        }

        const catalogoNacionalidad = await traerNacionalidad()
        if (catalogoNacionalidad) {
            if ((isNaN(catalogoNacionalidad.error)) === false) {
              
                setErroreshttp(catalogoNacionalidad)
                
            } else {
                setNacionalidad(catalogoNacionalidad)

            }
        }

        const catalogoEstadosCiviles = await traerEstadosCiviles()
        if (catalogoEstadosCiviles) {
            if ((isNaN(catalogoEstadosCiviles.error)) === false) {
                setErroreshttp(catalogoEstadosCiviles)
            } else {
                setEstadosCiviles(catalogoEstadosCiviles)
            }
        }

        const catalogoGeneros = await traerGeneros()
        if (catalogoGeneros) {
            if ((isNaN(catalogoGeneros.error)) === false) {
              
                setErroreshttp(catalogoGeneros)
                
            } else {
                setGeneros(catalogoGeneros)
            }
        }

        const catalogoTiposIdentificacion = await traerTiposIdentificacion()
        if (catalogoTiposIdentificacion) {
            if ((isNaN(catalogoTiposIdentificacion.error)) === false) {
              
                setErroreshttp(catalogoTiposIdentificacion)
                
            } else {
                setTiposIdentificaion(catalogoTiposIdentificacion)
            }
        }


    }

    const checarNumeros = (e,filtrar) => {
        const { name, value } = e.target;
        let out = '';
        let filtro;//Caracteres validos

        if(filtrar===1){
            filtro = '1234567890';
        }else if(filtrar===2){
            filtro = '1234567890-';
        }else{
            filtro = '1234567890-+';
        }

        //Recorrer el texto y verificar si el caracter se encuentra en la lista de validos 
        for (let i = 0; i < value.length; i++)
            if (filtro.indexOf(value.charAt(i)) != -1)
                //Se añaden a la salida los caracteres validos
                out += value.charAt(i);

        setValue2((prev) => {
            const datos = { ...prev.datos, [name]: out };
            return { ...prev, datos };
        });
    }

    const otraActividad = async () => {
        const obj = {
            nombre: actividadNueva.toUpperCase()
        }
        const agregarNuevaActividadEconomica = await insertarActividadEconomica(obj)
        if (agregarNuevaActividadEconomica) {
            setSpinSend(false)
            if ((isNaN(agregarNuevaActividadEconomica.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(agregarNuevaActividadEconomica)
            } else {
                if (agregarNuevaActividadEconomica?.codigo === "000") {

                    //setActividadEconomica(await TraerActividadEconomica())
                    const catalogoActividadEconomica = await TraerActividadEconomica()
                    if (catalogoActividadEconomica) {
                        if ((isNaN(catalogoActividadEconomica.error)) === false) {
                            // respuestaincorrecta           
                            setErroreshttp(catalogoActividadEconomica)
                        } else {
                            setActividadEconomica(catalogoActividadEconomica)
                            setAgregarActividadEconomica(false)
                            const myObj = {
                                target: {
                                    value: (actividadEconomica[actividadEconomica.length - 1].id) + 1,
                                    name: "actividadEconomica"
                                }
                            }
                            guardarDatos(myObj)
                        }
                    }


                }
            }
        }



    }

    return (
        <>
            <div className="card-body pt-3">
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <h5 className="text-royal-blue font-acumin-variable" >I. Identificación del inversionista</h5>



                {value.datos.establecerModificacion ?
                    <>
                        <div className="row mb-3">

                            <div className="col-md-3 col-sm-3 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" value={value2.datos.primerNombre} onChange={(e) => guardarDatos(e)} id="primerNombre" name="primerNombre" type="text" className="form-control fs-5 " required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer nombre *</label>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-3 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" value={value2.datos.segundoNombre} onChange={(e) => guardarDatos(e)} id="segundoNombre" name="segundoNombre" type="text" className="form-control fs-5" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo nombre</label>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-3 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" value={value2.datos.primerApellido} onChange={(e) => guardarDatos(e)} id="primerApellido" name="primerApellido" type="text" className="form-control fs-5 " required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Primer apellido *</label>
                                </div>


                            </div>

                            <div className="col-md-3 col-sm-3 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" type="text" className="form-control fs-5" value={value2.datos.segundoApellido} onChange={(e) => guardarDatos(e)} id="segundoApellido" name="segundoApellido" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo apellido</label>
                                </div>

                            </div>
                        </div>


                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" type="text" className="form-control fs-5" value={value2.datos.apellidoCasada} onChange={(e) => guardarDatos(e)} id="apellidoCasada" name="apellidoCasada" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Apellido de casada (si aplica)</label>
                                </div>

                            </div>

                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    {value2.datos.tipoIdentificacionInicio ?
                                        <select className="form-select fs-5 border" value={value2.datos.tipoIdentidad} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["tipoIdentificacionInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                        }} id="tipoIdentidad" name="tipoIdentidad" required>
                                            <option value={1} >{value2.datos.tipoIdentidad}</option>
                                            {
                                                tiposIdentificacion.filter((item => item.nombre !== value2.datos.tipoIdentidad)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                        </select>
                                        :
                                        <select className="form-select fs-5 border" value={value2.datos.tipoIdentidad} onChange={(e) => guardarDatos(e)} id="tipoIdentidad" name="tipoIdentidad" required>
                                            <option value=""> </option>
                                            {
                                                tiposIdentificacion.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>
                                    }


                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Tipo de identificación *</label>
                                </div>
                            </div>


                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" type="text" maxLength={22} className="form-control fs-5" required value={value2.datos.numeroIdentificacion} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e,2)} id="numeroIdentificacion" name="numeroIdentificacion" />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Número de identificacion *</label>


                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" type="text" maxLength={14} className="form-control fs-5" value={value2.datos.rtn} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e,1)} id="rtn" name="rtn" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto"> RTN (si aplica)</label>
                                    <div id="emailHelp" class="form-text ps-1">Solo números</div>
                                </div>
                             
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    {value2.datos.estadoCivilInicio ?
                                        <select className="form-select fs-5 border " value={value2.datos.estadoCivil} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["estadoCivilInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                        }} id="estadoCivil" name="estadoCivil" required>
                                            <option value={value2.datos.estadoCivil} >{value2.datos.estadoCivil}</option>
                                            {
                                                estadosCiviles.filter((item => item.nombre !== value2.datos.estadoCivil)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>
                                        :
                                        <select className="form-select fs-5 border " value={value2.datos.estadoCivil} onChange={(e) => guardarDatos(e)} id="estadoCivil" name="estadoCivil" required>
                                            <option value=""> </option>
                                            {
                                                estadosCiviles.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                        </select>
                                    }
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Estado civil *</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    {value2.datos.generoInicio ?
                                        <select className="form-select fs-5 border  r" value={value2.datos.genero} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["generoInicio"]: false };
                                                return { ...prev, datos };
                                            });

                                        }} id="genero" name="genero" required>
                                            <option value={value2.datos.genero} >{value2.datos.genero}</option>
                                            {
                                                generos.filter((item => item.nombre !== value2.datos.genero)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>
                                        :
                                        <select className="form-select fs-5 border  r" value={value2.datos.genero} onChange={(e) => guardarDatos(e)} id="genero" name="genero" required>
                                            <option value=""> </option>
                                            {
                                                generos.map((item, index) => (

                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                        </select>
                                    }
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Género *</label>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    {value2.datos.nacionalidadInicio ?
                                        <select className="form-select fs-5 border  r" value={value2.datos.nacionalidad} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["nacionalidadInicio"]: false };
                                                return { ...prev, datos };
                                            });

                                        }} id="nacionalidad" name="nacionalidad" required>
                                            <option value={value2.datos.nacionalidad} >{value2.datos.nacionalidad}</option>
                                            {
                                                nacionalidad.filter((item => item.nombre !== value2.datos.nacionalidad)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>
                                        :
                                        <select className="form-select fs-5 border  r" value={value2.datos.nacionalidad} onChange={(e) => guardarDatos(e)} id="nacionalidad" name="nacionalidad" required>
                                            <option value=""> </option>
                                            {
                                                nacionalidad.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>
                                    }
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Nacionalidad *</label>
                                </div>
                            </div>



                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    {fechaNacimientoInicio ?
                                        < input placeholder="" max={fechaLimiteNacimiento} type="date" className="form-control fs-5 border  r" value={fechaNacimientoInicio} onClick={() => setFechaNacimientoInicio(false)} id="fechaNacimiento" name="fechaNacimiento" required />
                                        :
                                        < input placeholder="" max={fechaLimiteNacimiento} type="date" className="form-control fs-5 border  r" value={value2.datos.fechaNacimiento} onChange={(e) => guardarDatos(e)} id="fechaNacimiento" name="fechaNacimiento" required />
                                    } <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Fecha de nacimiento *</label>
                                </div>
                            </div>

                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" type="text" className="form-control fs-5 " required value={value2.datos.lugarNacimiento} onChange={(e) => guardarDatos(e)} id="lugarNacimiento" name="lugarNacimiento" />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Lugar de nacimiento *</label>
                                </div>

                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    {value2.datos.actividadEconomicaInicio ?

                                        <select className="form-select fs-5 " value={value2.datos.actividadEconomica} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["actividadEconomicaInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                        }} id="actividadEconomica" name="actividadEconomica" required>
                                            <option value={value2.datos.actividadEconomica}>{value2.datos.actividadEconomica} </option>
                                            {
                                                actividadEconomica.filter((item => item.nombre !== value2.datos.actividadEconomica)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                            <option   >OTRO</option>

                                        </select>
                                        :

                                        <select className="form-select fs-5 " value={value2.datos.actividadEconomica} onChange={(e) => guardarDatos(e)} id="actividadEconomica" name="actividadEconomica" required>
                                            <option value=""> </option>
                                            {
                                                actividadEconomica.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                            <option   >OTRO</option>

                                        </select>
                                    }
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Actividad económica *</label>
                                </div>

                            </div>


                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" type="text" maxLength={15} className="form-control fs-5 " required value={value2.datos.telefonoResidencia} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e,3)} id="telefonoResidencia" name="telefonoResidencia" />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Teléfono de residencia *</label>

                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" type="text" maxLength={15} className="form-control fs-5 " value={value2.datos.telefonoCelular} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e,3)} id="telefonoCelular" name="telefonoCelular" />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Teléfono de celular</label>

                                </div>
                            </div>
                        </div>



                        {agregarActividadEconomica &&
                            <> {spinSend ? <div className="row mb-3 d-flex">
                                <div className="col-md-4 text-center">
                                    <SpinnerSend textoSpinner="Enviando..."></SpinnerSend>
                                </div>
                            </div> :
                                <div className="row mb-3 d-flex ">
                                    <div className="col-md-4 ">
                                        <div className="form-floating">
                                            < input placeholder="" type="text" className="form-control fs-5 border  r" onChange={(e) => setActividadNueva(e.target.value)} required />
                                            <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Ingrese actividad económica *</label>
                                        </div>
                                    </div>
                                    <div class="col-md-2  d-flex align-items-end">

                                        <button type="button" onClick={() => {
                                            setSpinSend(true)
                                            otraActividad()
                                        }} className="btn botonFormularioDepositante" >Ingresar</button>
                                    </div>
                                </div>}
                            </>
                        }
                    </>

                    :

                    <>
                        <div className="row mb-3">

                            <div className="col-md-3 col-sm-3 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled value={value.datos.primerNombre} onChange={(e) => guardarDatos(e)} id="primerNombre" name="primerNombre" type="text" className="form-control fs-5 " required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer nombre *</label>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-3 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled value={value.datos.segundoNombre} onChange={(e) => guardarDatos(e)} id="segundoNombre" name="segundoNombre" type="text" className="form-control fs-5" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo nombre</label>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-3 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled value={value.datos.primerApellido} onChange={(e) => guardarDatos(e)} id="primerApellido" name="primerApellido" type="text" className="form-control fs-5 " required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Primer apellido *</label>
                                </div>


                            </div>

                            <div className="col-md-3 col-sm-3 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" className="form-control fs-5" value={value.datos.segundoApellido} onChange={(e) => guardarDatos(e)} id="segundoApellido" name="segundoApellido" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo apellido</label>
                                </div>

                            </div>
                        </div>


                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" className="form-control fs-5" value={value.datos.apellidoCasada} onChange={(e) => guardarDatos(e)} id="apellidoCasada" name="apellidoCasada" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Apellido de casada (si aplica)</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" className="form-control fs-5" value={value.datos.tipoIdentidad} onChange={(e) => guardarDatos(e)} id="apellidoCasada" name="apellidoCasada" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">tipo de identificación</label>
                                </div>
                            </div>


                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" maxLength={22} className="form-control fs-5" required value={value.datos.numeroIdentificacion} onChange={(e) => guardarDatos(e)} id="numeroIdentificacion" name="numeroIdentificacion" />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Número de identificacion *</label>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" maxLength={14} className="form-control fs-5" value={value.datos.rtn} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e)} id="rtn" name="rtn" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto"> RTN (si aplica)</label>

                                </div>

                            </div>

                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" maxLength={22} className="form-control fs-5" required value={value.datos.estadoCivil} onChange={(e) => guardarDatos(e)} id="numeroIdentificacion" name="numeroIdentificacion" />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Estado civil</label>
                                </div>
                            </div>

                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" maxLength={22} className="form-control fs-5" required value={value.datos.genero} onChange={(e) => guardarDatos(e)} id="numeroIdentificacion" name="numeroIdentificacion" />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Género</label>
                                </div>
                            </div>



                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" maxLength={22} className="form-control fs-5" required value={value.datos.nacionalidad} onChange={(e) => guardarDatos(e)} id="numeroIdentificacion" name="numeroIdentificacion" />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Nacionalidad</label>
                                </div>
                            </div>

                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled max={fechaLimiteNacimiento} type="text" className="form-control fs-5 " required value={convertirTime(value.datos.fechaNacimiento)} onChange={(e) => guardarDatos(e)} id="fechaNacimiento" name="fechaNacimiento" />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Fecha de nacimiento *</label>
                                </div>
                            </div>

                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" className="form-control fs-5 " required value={value.datos.lugarNacimiento} onChange={(e) => guardarDatos(e)} id="lugarNacimiento" name="lugarNacimiento" />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Lugar de nacimiento *</label>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" className="form-control fs-5 " required value={value.datos.actividadEconomica} onChange={(e) => guardarDatos(e)} id="lugarNacimiento" name="lugarNacimiento" />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Actividad económica</label>
                                </div>
                            </div>


                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" maxLength={15} className="form-control fs-5 " required value={value.datos.telefonoResidencia} onChange={(e) => guardarDatos(e)} id="telefonoResidencia" name="telefonoResidencia" />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Teléfono de residencia *</label>

                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" maxLength={15} className="form-control fs-5 " value={value.datos.telefonoCelular} onChange={(e) => guardarDatos(e)} id="telefonoCelular" name="telefonoCelular" />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Teléfono de celular</label>

                                </div>
                            </div>
                        </div>




                    </>
                }

            </div>




        </>
    );
}

