import { instance } from '../../apiGlobal';

export async function registrarSerieBCV(obj) {
  try {
    const response = await instance(`/serie/registro/row`, {
      method: "post",
      data: obj
    })
    return response.data;
  } catch (error) {
    return error;
  }
}
