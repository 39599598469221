import { useMemo } from "react";

export default function useSolicitudesConstitucionGarantia() {
  const columns = useMemo(
    () => [
      {
        Header: "Folio",
        accessor: "cehvalSolicitudGarantiaFolio"
      },
      {
        Header: "Código depositante directo solicitante",
        accessor: "cehvalSolicitudGarantiaDepositanteTransferente.cehvalDepositanteCodigoCehval"
      },
      {
        Header: "Beneficiario",
        accessor: "cehvalSolicitudGarantiaNombreBeneficiario"
      },
    ],
    []
  );

  return columns;
}
