import React, { useState, useEffect } from "react";
import HtmlTemplate from "./htmlTemplate";

import html2pdf from 'html2pdf.js';


//Componentes generales
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../ComponentesGenerales/ManejadorErrores';
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import TablaDescargaYEnvioEstadosCuenta from "../../../ComponentesGenerales/TablaDescargaYEnvioEstadosCuenta";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { verTodosEstadosCuentaParaDescargar } from "../../../api/tarificacion/EstadosCuenta/verTodosEstadosCuentaParaDescargar"
import { generarEdoCuentaDepositante } from "../../../api/tarificacion/EstadosCuenta/generarEdoCuentaDepositante";
import { generarEdoCuentaEmisor } from "../../../api/tarificacion/EstadosCuenta/generarEdoCuentaEmisor";
import { enviarPDF } from "../../../api/tarificacion/EstadosCuenta/enviarPDF";
import { descargarEstadoCuenta } from "../../../api/tarificacion/EstadosCuenta/descargarEstadoCuenta"

//Hooks
import useDescargarEdoCuenta from "../../../hooks/Tarificacion/EstadosCuenta/useDescargarEdoCuenta";


export default function DescargaEnvioEdosCuenta({ history }) {


  //User type
  const [isOperadorCehval, setIsOperadorCehval] = useState(false);
  const [isParticipante, setIsParticipante] = useState(false);
  //const [codigoParticipante, setCodigoParticipante] = useState();


  //Save loaded data
  //const [copia, setCopia] = useState();
  const [data, setData] = useState([]);

  //Spinner
  const [spin, setSpin] = useState(false);
  const [isLoading, setIsLoading] = useState();

  //States to handle load data when loading page first time or reload the page
  const [erroreshttp, setErroreshttp] = useState();

  const [requestStatus, setRequestStatus] = useState();

  //Shows modal if the session has expired
  //const [sessionExpired, setSessionExpired] = useState();
  //const [failLoadData, setFailLoadData] = useState();


  useEffect(() => {
    const codigo = (JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo;
    //const codigo = "DDIRPJ-013";
    //const codigo = "CHVEM-0004";


    //Validamos si el codigo en local storage es null, entonces es el operador cehval o alguna otra entidad de CEHVAL la que esta ingresando
    if (codigo === null) {
      //console.log("es operador cehval")
      //Trae todos los estados de cuenta
      getAllAccountStatements();
      setIsParticipante(false);
      setIsOperadorCehval(true);
      //setCodigoParticipante(codigo);

      //SI no es null quiere decir que es un depositante o un emisor
    } else {

      //Verificamos si el codigo es de un emisor o de un depositante
      const splitCodigo = codigo.match(/[a-z]+|[^a-z]+/gi).join(" ").replace(/\s+/g, " ");

      //Es emisor
      if (splitCodigo.includes("CHVEM")) {
        //console.log("es emisor")
        setIsOperadorCehval(false);
        setIsParticipante(true);
        //setCodigoParticipante(codigo);

        //Trae los estados de cuenta de ese emisor
        getEmisorEdosCuenta(codigo);

        //Es depositante directo o indirecto
      } else {
        //console.log("es depositante")
        setIsOperadorCehval(false);
        setIsParticipante(true);
        //setCodigoParticipante(codigo);

        //Trae los estados de cuenta del depositante y si es DDIRPJ trae los estados de cuenta de sus clientes
        getDepositanteEdosCuenta(codigo);
      }
    }

  }, []);




  //Trae todos los estdos de cuenta de todos los participantes
  const getAllAccountStatements = async () => {
    try {
      setSpin(true);
      const response = await verTodosEstadosCuentaParaDescargar();
      //console.log(response)

      if (isNaN(response.error) === false) {
        setErroreshttp(response);
        setSpin(false);


      } else if (response.data) {
        setData(response.data);
        //setCopia(response.data);
        setSpin(false);
      }

    } catch (err) {
      console.error(err);
    }
  }


  //Muestra solo los estados de cuenta del depositante que esta consultando la pantalla
  const getDepositanteEdosCuenta = async (codigoDepositante) => {
    try {
      setSpin(true);
      const response = await generarEdoCuentaDepositante({ codigoParticipante: codigoDepositante });
      //console.log(response)

      if (isNaN(response.error) === false) {
        setErroreshttp(response);
        setSpin(false);


      } else if (response.data) {
        setData(response.data);
        //setCopia(response.data);
        setSpin(false);
      }

    } catch (err) {
      console.error(err);
    }
  }


  //Muestra solo los estados de cuenta del emisor que esta consultando la pantalla
  const getEmisorEdosCuenta = async (codigoEmisor) => {
    try {
      setSpin(true);
      const response = await generarEdoCuentaEmisor({ codigoParticipante: codigoEmisor });
      //console.log(response)

      if (isNaN(response.error) === false) {
        setErroreshttp(response);
        setSpin(false);


      } else if (response.data) {
        setData(response.data);
        //setCopia(response.data);
        setSpin(false);
      }

    } catch (err) {
      console.error(err);
    }
  }


  const sendPDF = async (row) => {

    const rowInfo = row.original;
    const objDescargarPDF = {
      codigoParticipante: rowInfo.codigoParticipante,
      tipoParticipante: rowInfo.tipoParticipante,
      periodo: rowInfo.periodo,
    }


    setIsLoading(true);
    const response = await enviarPDF(objDescargarPDF);
    setIsLoading(false);


    if (isNaN(response.error) === false) {
      setErroreshttp(response);

    } else if (response.request.status === 200) {
      setRequestStatus("Correo enviado");
    }
  }



  const downloadPDF = async (row) => {
    try {

      const rowInfo = row.original;
      const objDescargarPDF = {
        codigoParticipante: rowInfo.codigoParticipante,
        tipoParticipante: rowInfo.tipoParticipante,
        periodo: rowInfo.periodo,
      }

      
      // const html = HtmlTemplate();
      // //console.log(html)
      
      // generatePdf(html)
      // createAndDownloadHTMLFile(htmlString);

      // history.push({
      //     pathname: '/viewPDF',
      //     state: {
      //         html: htmlString
      //     }
      // });

      // //console.log(rowInfo)
      // //console.log(objDescargarPDF)

      setIsLoading(true);
      const response = await descargarEstadoCuenta(objDescargarPDF);
      setIsLoading(false);
      //console.log(response)


      if (isNaN(response.error) === false) {
          setErroreshttp(response);

      } else if (response.data) {
          const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          URL.revokeObjectURL(fileURL);

          //const htmlString = response.data;
      }

    } catch (error) {

    }
  }


  const generatePdf = (htmlString) => {
    const opt = {
      margin:       .50,
      filename: 'EdoCta.pdf',
      image: { type: 'jpeg', quality: 0.50 },
      html2canvas: { scale: 1},
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' }
    };

    html2pdf().set(opt).from(htmlString).save('generated.pdf');
  };


  const createAndDownloadHTMLFile = (htmlString) => {
    //const htmlContent = '<h1>Hello, world!</h1>'; // Contenido HTML que deseas escribir en el archivo

    const blob = new Blob([htmlString], { type: 'text/html' }); // Crear un objeto Blob con el contenido y el tipo MIME

    const url = URL.createObjectURL(blob); // Crear una URL para el objeto Blob

    const link = document.createElement('a'); // Crear un elemento <a> para descargar el archivo
    link.href = url;
    link.download = 'file.html'; // Nombre del archivo que se descargará

    document.body.appendChild(link); // Agregar el enlace al documento
    link.click(); // Hacer clic en el enlace para iniciar la descarga

    URL.revokeObjectURL(url); // Liberar la URL creada
  };



  return (
    <>
      {requestStatus &&
        <ComponenteModal mostrarModal={requestStatus} cerrarModal={() => setRequestStatus(false)} titulo={requestStatus} textoBoton="Aceptar"></ComponenteModal>
      }
      {isLoading &&
        <PantallaCargando />
      }
      <div className="">
        {erroreshttp &&
          <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
        }
        <main className="min-height-40">
          <div className="row row-wrap g-0">
         
            <div className="col-12 mb-5">
              <div className="">
                <BannerTitulo titulo="Descarga y envío de estado de cuenta"></BannerTitulo>
                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                  <div className="d-flex">
                    <div className="container-fluid container-md-container container-lg-none container-xl-none container-xxl-none px-3">
                      {data &&
                        <>
                          <TablaDescargaYEnvioEstadosCuenta
                            datos={data}
                            sendPDF={sendPDF}
                            downloadPDF={downloadPDF}
                            useValidate={useDescargarEdoCuenta}
                            textTitle="Click para ver la información"
                            isParticipante={isParticipante}
                            isOperadorCehval={isOperadorCehval}
                          />
                        </>
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </main >
      </div>
    </>
  );
}