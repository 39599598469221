import React, { useState, useEffect } from "react";

//AWS
import { Auth } from 'aws-amplify';

//Componentes generales
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../ComponentesGenerales/ManejadorErrores';
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
//import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { verEstadosCuentaOperadorCehval } from "../../.:/../../api/tarificacion/EstadosCuenta/verEstadosCuentaOperadorCehval";
import { generarEstadoCuenta } from "../../../api/tarificacion/EstadosCuenta/generarEstadoCuenta";
import { generarTodosEdoCuenta } from "../../../api/tarificacion/EstadosCuenta/generarTodosEdoCuenta";

//Hooks
import useEstadosCuenta from "../../../hooks/Tarificacion/EstadosCuenta/useEstadosCuenta";



// const dataTest = [
//     {
//         codigoParticipante: "CHVEM-0004",
//         facturas: null,
//         facturasDocumento: null,
//         nombreParticipante: "Jalisco Guadalajara",
//         periodo: "05/2023",
//         responsablePagoCodigo: null,
//         responsablePagoNombre: null,
//         tipoParticipante: 2,
//         tipoParticipanteStr: "Emisor",
//         totalPagado: null,
//         totalPagadoStr: null,
//         totalPendientePago: null,
//         totalPendientePagoStr: null,
//     },
//     {
//         codigoParticipante: "CHVEM-0004",
//         facturas: null,
//         facturasDocumento: null,
//         nombreParticipante: "Jalisco Guadalajara",
//         periodo: "06/2023",
//         responsablePagoCodigo: null,
//         responsablePagoNombre: null,
//         tipoParticipante: 2,
//         tipoParticipanteStr: "Emisor",
//         totalPagado: null,
//         totalPagadoStr: null,
//         totalPendientePago: null,
//         totalPendientePagoStr: null,
//     }
// ]


export default function GeneracionEstadosCuenta({ history }) {

    //Save loaded data
    const [data, setData] = useState([]);

    const [hasData, setHasData] = useState();

    //Spinner
    const [spin, setSpin] = useState(false);


    //States to handle load data when loading page first time or reload the page
    const [erroreshttp, setErroreshttp] = useState();

    const [requestAnwser, setRequestAnswer] = useState();


    useEffect(() => {
        getAllAvailableAccountStatus();
    }, []);


    //Get all records available to generate Estados de Cuenta
    const getAllAvailableAccountStatus = async () => {
        try {
            setSpin(true);
            const response = await verEstadosCuentaOperadorCehval();
            //console.log(response)

            if (isNaN(response.error) === false) {
                setErroreshttp(response);
                setSpin(false);


            } else if (response.data.length === 0) {
                setHasData(false);
                //console.log("no tiene data")
                setData(response.data);
                setSpin(false);


            } else if (response.data.length > 0) {
                //console.log("si tiene data")
                setHasData(true);
                setData(response.data);
                setSpin(false);
            }

        } catch (err) {
            console.error(err);
        }
    }


    //Generates the Estado de Cuenta from the selected row
    const generarEdoCuentaFila = async (row) => {
        try {

            const rowInfo = row.original;
            const codigoParticipante = rowInfo.codigoParticipante;
            const periodo = rowInfo.periodo;

            setSpin(true);
            const response = await generarEstadoCuenta(rowInfo);
            //console.log(response)

            if (isNaN(response.error) === false) {
                setErroreshttp(response);

            } else if (response.data.isSuccessful) {

                //Elimina el elemento del array que sea igual al codigoParticipante y periodo de la fila donde se genero el Edo. Cuenta
                const filtereData = removeFacturaGenerada(codigoParticipante, periodo);
                setData(filtereData);
                setSpin(false);
                setRequestAnswer(response.data.mensaje);

            } else if (response.data.isSuccessful === false) {
                setRequestAnswer(response.data.mensaje);
            }

        } catch (error) {
            console.error(error);
        }
    }


    //Removes the element (factura) selected to generate the Estado de Cuenta
    const removeFacturaGenerada = (codigoParticipante, periodo) => {

        //Find the index of the element (factura)
        const indexFactura = data.findIndex(item => item.codigoParticipante === codigoParticipante && item.periodo === periodo);

        //Remove the element (factura) by index from original array
        data.splice(indexFactura, 1);

        return data;
    }



    //Genera todos los estados de cuenta
    const generarTodosEdosCuenta = async (e) => {
        try {
            e.preventDefault();
            //console.log(data);
            setSpin(true);
            const response = await generarTodosEdoCuenta(data);
            //console.log(response)

            if (isNaN(response.error) === false) {
                setErroreshttp(response);
                setSpin(false);


            } else if (response.data.isSuccessful) {

                //Removes all the elementos from array
                let arreglo=data;
                arreglo.splice(0, arreglo.length);
                //Set the empty array to state
                setData(arreglo);
                setSpin(false);
                //Shows message from backend
                setRequestAnswer(response.data.mensaje);

            } else if (response.data.isSuccessful === false) {
                setRequestAnswer(response.data.mensaje);
                setSpin(false);

            }
        } catch (error) {
            console.error(error);
        }
    }


    // const signOut = async () => {
    //     try {
    //         const data = await Auth.signOut();
    //         sessionStorage.clear();
    //         window.location.replace('/login')
    //     } catch (error) {
    //         //console.log('error signing out: ', error);
    //     }
    // }


    const goBack = () => {
        history.push({
            pathname: '/inicioCehval',
        })
    }



    return (
        <>

            {requestAnwser &&
                <ComponenteModal mostrarModal={requestAnwser} cerrarModal={() => setRequestAnswer(false)} titulo={requestAnwser} textoBoton="Aceptar"></ComponenteModal>
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            <div className="">
                <main className="min-height-40">
                    <div className="row row-wrap g-0">
                     
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Generación de estados de cuenta"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container-fluid container-md-container container-lg-none container-xl-none container-xxl-none px-3">
                                            {data &&
                                                <>
                                                    <TablaUsuarios datos={data} displayInformation={generarEdoCuentaFila} useValidate={useEstadosCuenta} textoBoton={"Generar Edo. Cuenta"} textTitle="Click para ver la información"></TablaUsuarios>
                                                    {hasData &&
                                                        < div className="col-12 col-md-12 d-flex justify-content-end mb-3 px-3 mt-5">
                                                            <div className="flex-fill justify-content-start">
                                                                <button type="button" onClick={goBack} className="btn ms-2 background-royal-blue text-white bg-hover-gold">Regresar</button>
                                                            </div>
                                                            <button type="button" onClick={generarTodosEdosCuenta} className="btn ms-2 background-royal-blue text-white bg-hover-gold">Generar todos los estados de cuenta</button>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div >
        </>
    );
}