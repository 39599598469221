//React
import React from "react";
import { Link } from 'react-router-dom';

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";

//Images
import imagenDeposito from '../../../assets/resguardoValoresFisicos/depositoValorFisico.jpg';
import imagenValoresDepositados from '../../../assets/resguardoValoresFisicos/valoresDepositados.jpg';
import imagenNotificacionAutorizada from '../../../assets/resguardoValoresFisicos/notificacionAutorizada.jpg';
import imagenNotificacionRechazadas from '../../../assets/resguardoValoresFisicos/notificacionRechazadas.jpg';
import imagenValoresFisicosRetirados from '../../../assets/resguardoValoresFisicos/retiroValores.jpg';



export default function MenuDepositosValoresFisicos() {


    return (
        <>
            <div className="fluid-container">
                <main className="min-height-40">
                    <div className="row row-wrap g-0">
                        {/*<div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 d-flex flex-column">
                            <BarraLateral2  ></BarraLateral2>
                        </div>*/}
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo className="mb-5" titulo="Menú registro de valores físicos"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container font-acumin-variable py-5 mb-5">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-4">
                                                <Link to="/solicitudDepositoValorFisico" className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="" src={imagenDeposito} alt="imagenFondo" />
                                                        <div className="card-body row align-items-center justity-content-center text-center height-150px">
                                                            <h5 className="card-title text-royal-blue ">Solicitud de depósito de valores físicos</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-4">
                                                <Link to="/valoresFisicosDepositados" className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="" src={imagenValoresDepositados} alt="imagenFondo" />
                                                        <div className="card-body row align-items-center justity-content-center text-center height-150px">
                                                            <h5 className="card-title text-royal-blue">Valores físicos depositados</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-4">
                                                <Link to="/notificarSolicitudesDepositoValFisAutorizadas" className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="" src={imagenNotificacionAutorizada} alt="imagenFondo" />
                                                        <div className="card-body row align-items-center justity-content-center text-center height-150px">
                                                            <h5 className="card-title text-royal-blue">Notificación de solicitudes autorizadas de registro de depósito de valores físicos</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-4">
                                                <Link to="/notificarSolicitudesDepositoValFisRechazadas" className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="" src={imagenNotificacionRechazadas} alt="imagenFondo" />
                                                        <div className="card-body row align-items-center justity-content-center text-center height-150px">
                                                            <h5 className="card-title text-royal-blue">Notificación de solicitudes rechazadas de registro de depósito de valores físicos</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 mb-4">
                                                <Link to="/tablaValoresFisicosRetirados" className="text-decoration-none text-dark">    
                                                    <div className="card" >
                                                        <img className="" src={imagenValoresFisicosRetirados} alt="imagenFondo" />
                                                        <div className="card-body row align-items-center justity-content-center text-center height-150px">
                                                            <h5 className="card-title text-royal-blue">Valores físicos retirados</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}

