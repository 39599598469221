import { instance } from '../apiGlobal';

export async function traerTiposTasaVariable(obj) {
    try {
        const response = await instance.get(`/cat/tipostasavariable`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response.data;
    } catch (error) {
        return error;
    }
}   