import { instanciaConsultaOperaciones } from '../../apiGlobal';

export async function consultaOperacionesTLPTodas (obj) {
      try {
        const response = await instanciaConsultaOperaciones(`/bitacora/consultar/todo/tipo`, {
          method:"post",
          data: obj,
        })
        return response;
      } catch (error) {
        return error;
      }
}