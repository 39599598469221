import { instance } from '../apiGlobal';

export async function traerCuentaValoresTransito() {
  try {
    const response = await instance.get(`/cuentavalores/consultar/transito`, {
      headers: {
        'Content-Type': 'application/json'        
      }
    })
  
    return response.data
  } catch (error) {
    return error
  }

}



  