import React from "react";
import { useHistory } from "react-router-dom";

import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { crearRolConPermisos } from "../../../api/Permisos/crearRolConPermisos";
import { traerPermisos } from "../../../api/Permisos/traerPermisos";

export default function CrearRolConPermisos() {

    const history = useHistory();
    const [rol, setRol] = React.useState()
    const [descripcionRol, setDescripcionRol] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [spinStar, setSpinStart] = React.useState(true)
    const [permisos, setPermisos] = React.useState([])
    const [arregloPermisos, setArregloPermisos] = React.useState([])
    const [rolCreado, setRolCreado] = React.useState()
    const [errorSinPermisos, setErrorSinPermisos] = React.useState()
    const [loading, setLoading] = React.useState(false);
    const [rolRepetido, setRolRepetido] = React.useState()

    //**Inicia variables necesarias de AWS */
    const AWS = require('aws-sdk');

    // Set the AWS Region.
    AWS.config.update({
        region: process.env.REACT_APP_region,
        accessKeyId: process.env.REACT_APP_accessKeyId,
        secretAccessKey: process.env.REACT_APP_secretAccessKey,
    });

    // Initialize CogntioIdentityServiceProvider SDK.
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    //**Termina variables necesarias de AWS */

    React.useEffect(() => {
        traerDatos()
    }, [])


    const traerDatos = async () => {
        //traer catalogo de todos los permisos
        const catalogoPermisos = await traerPermisos()
        //console.log(catalogoPermisos)
        if (catalogoPermisos) {
            setSpinStart(false)
            if ((isNaN(catalogoPermisos.error)) === false) {
                // respuestaincorrecta        
                setErroreshttp(catalogoPermisos)
            } else {
                //respuesta correcta
                // //console.log(catalogoPermisos)
                setPermisos(catalogoPermisos)

            }
        }
    }

    const onSubmitForm = async (e) => {
        e.preventDefault();

        if (arregloPermisos.length > 0) {
            setLoading(true)
            setErrorSinPermisos(false)


            const params = {
                Description: descripcionRol,
                GroupName: rol,
                UserPoolId: process.env.REACT_APP_UserPoolId,
            }

            const crearRolNuevo = await crearRolConPermisos({
                nombreRol: rol,
                descripcionRol: descripcionRol,
                listPermisos: arregloPermisos
            })

            if (crearRolNuevo) {
                //console.log(crearRolNuevo)
                //  setLoading(false)
                if ((isNaN(crearRolNuevo.error)) === false) {
                    // respuestaincorrecta        
                    setErroreshttp(crearRolNuevo)
                } else {
                    if (crearRolNuevo.codigo === "000") {
                        cognitoidentityserviceprovider.createGroup(params, function (err, data) {
                            if (err) {
                                //console.log(err.message); // an error occurred
                                setLoading(false)
                            }
                            else {
                                //console.log(data)
                                setLoading(false)
                                setRolCreado(crearRolNuevo)
                            }
                        });
                    }else{
                    setLoading(false)
                       setRolRepetido(true)
                    }
                }
            }
        }
        else {
            setErrorSinPermisos(true)
        }
    }

    const cerrarModal = () => {
        history.go(-1)
    }


    const guardarArreglo = (numero, id) => {
        let arregloPivote = arregloPermisos
        if (document.getElementById(id).checked) {
            setErrorSinPermisos(false)
            arregloPivote.push(parseInt(numero, 10))
        } else {
            arregloPivote = arregloPermisos.filter((item) => item !== parseInt(numero, 10))
        }
        //console.log(arregloPivote)
        setArregloPermisos(arregloPivote)
    }

    const checkboxFuncion = (id, grupo) => {
        let arregloPivote = arregloPermisos
        if (document.getElementById(grupo).checked) {
            document.querySelectorAll(`#${id} input[type=checkbox]`).forEach(function (checkElement) {
                checkElement.checked = true;
                checkElement.disabled = true;
                arregloPivote.push(parseInt(checkElement.value, 10))
                setErrorSinPermisos(false)
            });
        } else {
            document.querySelectorAll(`#${id} input[type=checkbox]`).forEach(function (checkElement) {
                checkElement.checked = false;
                checkElement.disabled = false;
                arregloPivote = arregloPivote.filter((item) => item !== parseInt(checkElement.value, 10))
            });
        }
        setArregloPermisos(arregloPivote)
    }

    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {rolCreado &&
                <ComponenteModal mostrarModal={rolCreado} cerrarModal={cerrarModal} titulo="El rol se ha creado con éxito" textoBoton="Entendido"></ComponenteModal>
            }
            {rolRepetido &&
                <ComponenteModal mostrarModal={rolRepetido} cerrarModal={() => { setRolRepetido(false) }} titulo="El rol ya existe" textoBoton="Entendido"></ComponenteModal>
            }

            <div className="fluid-container">

                <main>
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="main-min-height">
                                <BannerTitulo titulo="Crear rol"></BannerTitulo>
                                {spinStar ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart>
                                    :
                                    <div className="row mt-3 text-royal-blue" >
                                        <div className="col-lg-12 col-md-12 ">
                                            <div className="px-4">
                                                <div className="row mb-3 justify-content-center">
                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">

                                                        <div className=" card">
                                                            <div className="card-body font-acumin-variable">
                                                                <form onSubmit={(e) => onSubmitForm(e)}>
                                                                    <h6 className="font-acumin-variable text-royal-blue fs-20-r">* Estos campos son obligatorios</h6>
                                                                    <div className="row mb-3">
                                                                        <div className="col-md-12">
                                                                            <div className="form-floating ">
                                                                                <div className="form-floating mb-2">
                                                                                    <input placeholder="" value={rol} onChange={(e) => setRol(e.target.value)} id="rol" name="rol" type="text" className="form-control " required />
                                                                                    <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Nuevo rol *</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mb-3">
                                                                        <div className="col-md-12">
                                                                            <div className="form-floating ">
                                                                                <div className="form-floating mb-2">
                                                                                    <input placeholder="" value={descripcionRol} onChange={(e) => setDescripcionRol(e.target.value)} id="descripcionRol" name="decripcionRol" type="text" className="form-control " required />
                                                                                    <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Descripción *</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <h4 className="mt-5 mb-4 fs-20-r">Selecciona los permisos para el rol</h4>
                                                                    {
                                                                        permisos.map((item1, index1) => (
                                                                            <div className="card mt-3">
                                                                                <div className="row mb-3 px-3 pt-3 g-0">
                                                                                    <div className="col-lg-12">
                                                                                        <div className="form-check p-0">
                                                                                            <input className="form-check-input cursor-pointer m-0" type="checkbox" onChange={() => checkboxFuncion(`formulario${index1}`, item1.grupo)} value="" id={item1.grupo} />
                                                                                            <label className="form-check-label cursor-pointer col-10 fw-bold ms-2 ms-lg-3" for={item1.grupo}>
                                                                                                {item1.grupo} <span className="text-muted fs-x-small"> &#40;Añade todos los permisos&#41;</span>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row row-wrap align-items-center d-none d-lg-flex mb-3 ms-0 ms-sm-1 ms-md-2 ms-lg-3 g-0">
                                                                                    <div className="col-lg-6">
                                                                                        <div className="form-check ms-3">
                                                                                            <p className="m-0 text-gray">&#40;Nombre del permiso&#41;</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6">
                                                                                        <p className="m-0 text-gray">&#40;Acción que permite&#41;</p>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    permisos[index1].permisoModelo.map((item, index) => (
                                                                                        <>

                                                                                            <form name="f1" id={`formulario${index1}`} className="mb-3 border-top pt-3 ps-4 ps-md-3 ps-lg-3 " >
                                                                                                <div className="row row-wrap align-items-center ms-0 ms-sm-1 ms-md-2 ms-lg-3 g-0">
                                                                                                    <div className="col-lg-6">
                                                                                                        <div className="form-check p-0">
                                                                                                            <input className="form-check-input cursor-pointer m-0" value={item.cehvalPermisoId} onChange={(e) => guardarArreglo(e.target.value, item.cehvalPermisoNombre)} type="checkbox" id={item.cehvalPermisoNombre} />
                                                                                                            <label className="form-check-label cursor-pointer col-10 col-lg-8 ms-2 ms-sm-2 ms-md-3 ms-lg-2 ms-xl-3 " for={item.cehvalPermisoNombre}>
                                                                                                                {item.cehvalPermisoNombre}
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-lg-6">
                                                                                                        <p className="m-0 text-gray">{item.cehvalPermisoDescripcion}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </form>

                                                                                        </>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    {errorSinPermisos &&
                                                                        <>
                                                                            <p className="error mt-2">Tiene que seleccionar al menos un permiso</p>
                                                                        </>
                                                                    }

                                                                    <div className="row justify-content-start mt-4">
                                                                        <div className="col-auto ">
                                                                            <button className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable" type="submit">Crear</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

