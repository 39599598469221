import React from "react";
import '../../../styles/stylesheet.css'
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";
import { useHistory } from 'react-router-dom';

//endpoints
import { crearUsuario } from '../../../api/usuarios/crearUsuario'
import { consultarUsuariosDeDepositante } from "../../../api/mantenimientoCargaValores/consultarUsuariosDeDepositante";
import { cambiarEstatusUsuarioReemplazado } from "../../../api/mantenimientoCargaValores/cambiarEstatusUsuarioReemplazado";

export default function ActualizarUsuarios(props) {
    const history = useHistory();
    const [errorUsuarioExistente, setErrorUsuarioExistente] = React.useState()
    const [usuarioRegistrado, setUsuarioRegistrado] = React.useState()
    const [usuarioNoRegistrado, setUsuarioNoRegistrado] = React.useState()
    const [registroCompleto, setRegistroCompleto] = React.useState();

    const [variableComparacion, setVariableComparacion] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [mensaje, setMensaje] = React.useState()
    const [loading, setLoading] = React.useState(true);

    let contador = 0;

    //**Inicia variables necesarias de AWS */
    const AWS = require('aws-sdk');

    // Set the AWS Region.
    AWS.config.update({
        region: process.env.REACT_APP_region,
        accessKeyId: process.env.REACT_APP_accessKeyId,
        secretAccessKey: process.env.REACT_APP_secretAccessKey,
    });

    // Initialize CogntioIdentityServiceProvider SDK.
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    //**Termina variables necesarias de AWS */

    React.useEffect(() => {

        //declaramos una variable para saber cuantos usuarios hay que registrar
        // si es un emisor, solo es un usuario, si es un depositnate puede ser 1 o 2 usuarios
        if (props.tipoParticipante === 4 || props.tipoParticipante === 5) {
            setVariableComparacion(1)
        } else {
            let i = 0
            props.informacion.contactos.map(function () {
                i++;
            })
 

            setVariableComparacion(i);
        }
    }, [])

    React.useEffect(() => {
        //vamos a funcion traer datos hasta que la variable de comparacaion sea mayor a cero
        if (variableComparacion > 0) {
            traerUsuariosDepositantes()
        }
    }, [variableComparacion])


    const traerUsuariosDepositantes = async () => {
        setLoading(true)
        //traer usuarios de un depositante
        const respuestaUsuario = await consultarUsuariosDeDepositante({ cehvalUsuarioUsuario: props.codigo })
        //console.log(respuestaUsuario)
        if (respuestaUsuario) {
            //entra a qui si hay un error http
            if ((isNaN(respuestaUsuario.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(respuestaUsuario)
            } else {
                //inscribimos a los usuarios con su correo, primer nombre,segundo nombre, primer apellido, segundo apellido y codigo.
                //inscribir a emisor automaticamente con rol CONSULTOR_EMISOR
                if (props.tipoParticipante === 4 || props.tipoParticipante === 5) {
                    //solo entra si se cambio la info de representante legal
                    if (props.informacion.nombreCompletoRepreLeg || props.informacion.correoLaboral) {
                        //setSinRepresentante(false)
                        const arregloPivote = props.contactosOriginales.nombreCompletoRepreLeg.split(" ");
                        // //console.log(props.informacion.cehvalEmisorCoreoElecRepreLeg, arregloPivote[0], arregloPivote[1], arregloPivote[2], arregloPivote[3], "CONSULTOR_EMISOR", props.codigo)
                        if (arregloPivote.length === 4) {
                            borrarUsuarioViejo(respuestaUsuario, props.contactosOriginales.correoLaboral, arregloPivote[0], arregloPivote[1], arregloPivote[2], arregloPivote[3], "CONSULTOR_EMISOR", props.codigo, props.contactosValidar.correoLaboral,0)
                        } else if (arregloPivote.length === 3) {
                            borrarUsuarioViejo(respuestaUsuario, props.contactosOriginales.correoLaboral, arregloPivote[0], " ", arregloPivote[1], arregloPivote[2], "CONSULTOR_EMISOR", props.codigo, props.contactosValidar.correoLaboral,0)
                        } else {
                            borrarUsuarioViejo(respuestaUsuario, props.contactosOriginales.correoLaboral, arregloPivote[0], " ", arregloPivote[1], " ", "CONSULTOR_EMISOR", props.codigo, props.contactosValidar.correoLaboral,0)
                        }

                    } else {
                        setLoading(false)
                        //   actualizarEstatus()
                    }
                }
                //inscribir depositantes
                else {
                    //console.log(props.contactosOriginales)
                    props.informacion.contactos.map(function (item, index) {
                        if (item.correo || item.nombre || item.primerApellido) {//si se modifico primer, nombre, primer apellido o correo
                            if (props.tipoParticipante === 1) {//si es depositaante directo registramos ususario admin y operador
                                if (index === 0) {//usuario admin
                                    //console.log("ussuario 0")
                                    borrarUsuarioViejo(respuestaUsuario, props.contactosOriginales.contactos[index].correo, props.contactosOriginales.contactos[index].nombre, props.contactosOriginales.contactos[index].segundoNombre, props.contactosOriginales.contactos[index].primerApellido, props.contactosOriginales.contactos[index].segundoApellido, "ADMINISTRADOR_DD", props.codigo, props.contactosValidar.contactos[index].correo, index)
                                } else {//usuario operador
                                    borrarUsuarioViejo(respuestaUsuario, props.contactosOriginales.contactos[index].correo, props.contactosOriginales.contactos[index].nombre, props.contactosOriginales.contactos[index].segundoNombre, props.contactosOriginales.contactos[index].primerApellido, props.contactosOriginales.contactos[index].segundoApellido, "OPERADOR_DD", props.codigo, props.contactosValidar.contactos[index].correo, index)
                                }
                            } else {//si no depositnate directo sus dos usuarios son depositantes

                                borrarUsuarioViejo(respuestaUsuario, props.contactosOriginales.contactos[index].correo, props.contactosOriginales.contactos[index].nombre, props.contactosOriginales.contactos[index].segundoNombre, props.contactosOriginales.contactos[index].primerApellido, props.contactosOriginales.contactos[index].segundoApellido, "CONSULTOR_DI", props.codigo, props.contactosValidar.contactos[index].correo, index)

                            }
                        }
                    
                    })
                }
            }
        }

    }

    const borrarUsuarioViejo = async (usuarios, correo, primerNombre, segundoNombre, primerApellido, segundoApellido, rol, codigo, correoVAlidacion, index) => {
        // //console.log(usuarios, correo, primerNombre, segundoNombre, primerApellido, segundoApellido, rol, codigo, correoVAlidacion)
    
       let rolAMandar;
        if (usuarios.length > 0) {

            let rolString = "";
            //concatenar los roles 
            for (let i = 0; i < usuarios[0].roles.length; i++) {
                if(rolString){
                    rolString = rolString +","+ usuarios[0].roles[i]
                }else{
                    rolString = usuarios[0].roles[i]
                }

            }
           
        

            //console.log(rolString)

            if ((usuarios.length === 1 && index === 0) || (usuarios.length === 2 && index === 0) || (usuarios.length === 2 && index === 1)) {
              //borramos usuario viejo y creamos uno nuevo
                let usuarioABorrar;
                if (props.tipoParticipante === 4 || props.tipoParticipante === 5) {
                    rolAMandar=rol;
                    usuarioABorrar = usuarios[0].usuario
                } else {
                    rolAMandar=rolString
                    usuarioABorrar = usuarios[index].usuario
                }
                ////console.log(usuarioABorrar)
                //variables para enviar a cognito 
                const params = {
                    Username: usuarioABorrar,
                    UserPoolId: process.env.REACT_APP_UserPoolId,
                }
                //desabilitar usuario anterior en la base
                const respuestaUsuario = await cambiarEstatusUsuarioReemplazado({
                    cehvalUsuarioUsuario: usuarioABorrar,
                    cehvalUsuarioEstado: 0,
                })
                // //console.log(respuestaUsuario)
                if (respuestaUsuario) {
                    //entra a qui si hay un error http
                    if ((isNaN(respuestaUsuario.error)) === false) {
                        // respuestaincorrecta           
                        setErroreshttp(respuestaUsuario)
                    } else {
                     
                        //borrar usuario anterior en cognito
                        cognitoidentityserviceprovider.adminDeleteUser(params, function (err, data) {
                            if (err) {
                                //console.log(err.message); // an error occurred
                                setLoading(false)
                            }
                            else {
                                ////console.log("usuario Borrado")
                                onSubmitForm(correo, primerNombre, segundoNombre, primerApellido, segundoApellido, rolAMandar, codigo)
                            }
                        });
                    }
                }
            } else {
                //no borramos usuario porque no existe
                //solo creamos uno nuevo
                onSubmitForm(correo, primerNombre, segundoNombre, primerApellido, segundoApellido, rol, codigo)
            }

        } else {
            //entra si no tienen usuarios
            //creamos usuario nuevo
            onSubmitForm(correo, primerNombre, segundoNombre, primerApellido, segundoApellido, rol, codigo)
        }

    }

    const onSubmitForm = async (correo, primerNombre, segundoNombre, primerApellido, segundoApellido, rol, codigo) => {
        //console.log(correo, primerNombre, segundoNombre, primerApellido, segundoApellido, rol, codigo)
        setLoading(true)
        //objeto para registrar en la base
        const objetoUsuario = {
            cehvalUsuarioCorreo: correo,
            cehvalUsuarioEstado: 1,
            cehvalUsuarioDescripcion: "Nuevo usuario",
            cehvalUsuarioTipo: "",
            rol: rol,
            codigo: codigo,
            cehvalUsuarioNombreUsuario: primerNombre,
            cehvalUsuarioSegundoNombreUsuario: segundoNombre,
            cehvalUsuarioPrimerApellido: primerApellido,
            cehvalUsuarioSegundoApellido: segundoApellido,
        }

        const nombre = primerNombre + " " + segundoNombre + " " + primerApellido + " " + segundoApellido//nombre completo
        const respuestaUsuario = await crearUsuario(objetoUsuario)
        //console.log(respuestaUsuario)
        if (respuestaUsuario) {
            //entra a qui si hay un error http
            if ((isNaN(respuestaUsuario.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(respuestaUsuario)
            } else {
                //respuesta correcta
                //  setSpin(true)
                if (respuestaUsuario?.codigo === "000") {

                    ////console.log(respuestaUsuario.mensaje) 
                    //console.log(respuestaUsuario.mensaje)
                    guardarCognito(correo, nombre, respuestaUsuario.mensaje, rol)

                } else {
                    setLoading(false)
                    setMensaje("El correo: " + correo + " ya esta registrado")
                    setErrorUsuarioExistente(true)
                }
            }
        }

    }






    const guardarCognito = async (correo, nombre, nombreUsuario, rol) => {
        const params = {
            UserPoolId: process.env.REACT_APP_UserPoolId,
            Username: nombreUsuario,
            DesiredDeliveryMediums: [
                "EMAIL"
            ],

          //  TemporaryPassword: password(4),
            UserAttributes: [
                {
                    Name: "email",
                    Value: correo
                },
                {
                    Name: "name",
                    Value: nombre
                },
                {
                    Name: "email_verified",
                    Value: "true",
                },
            ]
        }
        cognitoidentityserviceprovider.adminCreateUser(params, function (err, data) {
            if (err) {
                //console.log(err.message); // an error occurred

                if (err.message === "User account already exists") {
                    setLoading(false)
                    setMensaje("El correo: " + correo + " ya registrado")
                    setErrorUsuarioExistente(true)
                } else {
                    setLoading(false)
                    setMensaje("El correo: " + correo + " ya esta registrado")
                    setErrorUsuarioExistente(false)
                }
            }

            else {
                //si no hay ningun error inscribimos usuario en base  y agregamos a un grupo en cognito
                agregarGrupo(rol, nombreUsuario)
            }
        });
    }

    //funcion para inscribir en un grupo
    const agregarGrupo = async (rol, nombreUsuario) => {
        const arregloRol=rol.split(",")
        let contadorLocal=0;

        for (let index = 0; index < arregloRol.length; index++) {
            const params = {
                GroupName: arregloRol[index],
                UserPoolId: process.env.REACT_APP_UserPoolId,
                Username: nombreUsuario,
            }
            cognitoidentityserviceprovider.adminAddUserToGroup(params, function (err, data) {
                if (err) {
                    //console.log(err, err.stack); // an error occurred
                    setLoading(false)
                }
                else {
                    //console.log("se agrego a grupo")
                     contadorLocal++;
                }            
            });
        }

        if(contadorLocal===arregloRol.length){
            contador++;
       
            if (contador === variableComparacion) {
                setLoading(false)
                //console.log("se acaba el proceso")
            }
        }
    }


    const imprimirReporte = () => {
        setRegistroCompleto(false)
        setUsuarioRegistrado(false)
        setLoading(true)

    }

    const cancelarModal = () => {
        setUsuarioRegistrado(false)
        history.go()
    }

    return (
        <>
            {loading &&
                <PantallaCargando />
            }

            

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {registroCompleto && !loading &&
                <ComponenteModal mostrarModal={registroCompleto} cerrarModal={imprimirReporte} titulo="Se han registrado a los usuarios que podrán ver las cuentas de valores de este depositante" textoBoton="Imprimir"></ComponenteModal>
            }

            {usuarioNoRegistrado &&
                <ComponenteModal mostrarModal={usuarioNoRegistrado} cerrarModal={() => { setUsuarioNoRegistrado(false) }} titulo="No se pudo registrar a este usuario, revisa los  datos e intentalo de nuevo" textoBoton="Entendido"></ComponenteModal>
            }

          

            {usuarioRegistrado && !loading &&
                <ComponenteModal mostrarModal={usuarioRegistrado} cerrarModal={cancelarModal} titulo="Se registro con éxito a este usuario" textoBoton="Entendido"></ComponenteModal>
            }

            {errorUsuarioExistente &&
                <ComponenteModal mostrarModal={errorUsuarioExistente} cerrarModal={() => {
                    setErrorUsuarioExistente(false)
                    props.resetear()
                }} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }
        </>
    );
}

