import React, { useState } from 'react';
import Stepper from 'react-stepper-horizontal';

//React context
import { FormProvider, FormContext } from './RegistroDINatural/form_context';

//Components
import RegistroDI1 from './RegistroDINatural/RegistroDI1';
import REGISTRO_DI_1_5 from './RegistroDINatural/REGISTRO_DI_1_5';
import RegistroDI2 from './RegistroDINatural/RegistroDI2'
import RegistroDI3 from './RegistroDINatural/RegistroDI3';
import RegistroDI4 from './RegistroDINatural/RegistroDI4';
import RegistroDI5 from './RegistroDINatural/RegistroDI5';
import RegistroDI6 from './RegistroDINatural/RegistroDI6';
import RegistroDI7 from './RegistroDINatural/RegistroDI7'
import BannerTitulo from '../../../ComponentesGenerales/Bannertitulo';
import ComponenteModal from '../../../ComponentesGenerales/ComponenteModal';
import ManejadorErrores from '../../../ComponentesGenerales/ManejadorErrores';
import PantallaCargando from '../../../ComponentesGenerales/PantallaCargando';

//APIs 
import { reporteDIPN } from '../../../api/Documentos/reporteDIPN';
import { EnviarRegistroDepositanteIndirectoNatural } from '../../../api/depositanteDirecto/EnviarRegistroDepositanteIndirectoNatural';


const Form = ({ history }) => {
  const [value] = React.useContext(FormContext);
  const [registroExitoso, setRegistroExitoso] = React.useState()
  const [registroFallido, setRegistroFallido] = React.useState()
  const [token, setToken] = React.useState()
  const [erroreshttp, setErroreshttp] = React.useState()
  const [codigo, setCodigo] = React.useState()
  const [loading, setLoading] = React.useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const sections = [
    { title: 'Identificación del inversionista', onClick: () => setCurrentPage(1) },//, onClick: () => setCurrentPage(1) },
    { title: 'Información domicilio', onClick: () => setCurrentPage(2) },
    { title: 'Datos laborales del inversionista', onClick: () => setCurrentPage(3) },
    { title: 'Procedencia de los recursos', onClick: () => setCurrentPage(4) },//, onClick: () => setCurrentPage(3) },
    { title: 'Instrucciones especiales', onClick: () => setCurrentPage(5) },
    { title: 'Firmas autorizadas', onClick: () => setCurrentPage(6) },
    { title: 'Casa de bolsa intermediaria', onClick: () => setCurrentPage(7) },
    { title: 'Responsable de la solicitud de registro', onClick: () => setCurrentPage(8) }
  ];

  function parseJwt(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  React.useEffect(() => {
    setToken(parseJwt(window.sessionStorage.getItem('accessToken')))
    //console.log(parseJwt(window.sessionStorage.getItem('accessToken')).name)
    // //console.log((JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo)
  }, [])


  const handleSubmit = async (e) => {
    e.preventDefault();
    ////console.log(value.datos.codigoDepositante)
    if (value.datos.enviar === 0) {
      next()
    } else {
      setLoading(true)
      const myObj = {
        actividadEconomica: value.datos.actividadEconomica,
        apellidoCasada: value.datos.apellidoCasada,
        casaBolsa: (JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo,
        codigoDepositante: value.datos.codigoDepositante,
        contactos: [
          {
            nombre: value.datos.nombreCompleto1,
            segundoNombre: value.datos.segundoNombre1,
            primerApellido: value.datos.primerApellido1,
            segundoApellido: value.datos.segundoApellido1,
            correo: value.datos.correo1,
            usuarioAdmin: 0
          },
          {
            nombre: value.datos.nombreCompleto2,
            segundoNombre: value.datos.segundoNombre2,
            primerApellido: value.datos.primerApellido2,
            segundoApellido: value.datos.segundoApellido2,
            correo: value.datos.correo2,
            usuarioAdmin: 0
          },
          /* {
             nombre: value.datos.nombreCompleto3,
             correo: value.datos.nombreCompleto3
           }*/
        ],

        cuentas: [
          {
            numeroCuenta: value.datos.numeroCuentaN,
            moneda: value.datos.monedaN,
            tipoCuenta: value.datos.tipoCuentaN,
            banco: value.datos.bancoN
          },
          {
            numeroCuenta: value.datos.numeroCuentaE,
            moneda: value.datos.monedaE,
            tipoCuenta: value.datos.tipoCuentaE,
            banco: value.datos.bancoE
          }
        ],
        domicilio: {
          pais: value.datos.pais,
          departamento: value.datos.departamento,
          municipio: value.datos.municipio,
          ciudad: value.datos.ciudad,
          colonia: value.datos.colonia,
          calle: value.datos.calle,
          numero: value.datos.edificio,
          cp: value.datos.codigoPostal,
          departamentoManual: value.datos.departamentoManual,
          municipioManual: value.datos.municipioManual,
          ciudadManual: value.datos.ciudadManual,
        },
        empresa: value.datos.empresa,
        estadoCivil: value.datos.estadoCivil,
        fechaNacimiento: value.datos.fechaNacimiento,
        //cual es la fecha de aprobacion
        //no hay fehca ultimamodificacion
        fechaUltimaModificacion: "",

        firmantes: [
          {
            nombre: value.datos.nombreCompleto_1,
            cargo: value.datos.cargo_1,
            tipoFirma: value.datos.tipoFirma_1,
            estatus: value.datos.rutaS3_1,
            usuarioAdmin: 0
          },
          {
            nombre: value.datos.nombreCompleto_2,
            cargo: value.datos.cargo_2,
            tipoFirma: value.datos.tipoFirma_2,
            estatus: value.datos.rutaS3_2,
            usuarioAdmin: 0
          },
        ],

        genero: value.datos.genero,
        lugarNacimiento: value.datos.lugarNacimiento,

        nacionalidad: value.datos.nacionalidad,
        numeroIdentificacion: value.datos.numeroIdentificacion,
        origenRecursos: value.datos.origenRecursos,
        primerApellido: value.datos.primerApellido,
        primerNombre: value.datos.primerNombre,
        puesto: value.datos.puesto,
        rtn: value.datos.rtn,
        segundoApellido: value.datos.segundoApellido,
        segundoNombre: value.datos.segundoNombre,
        telefonoCelular: value.datos.telefonoCelular,
        telefonoLaboral: value.datos.telefonoLaboral,
        telefonoResidencia: value.datos.telefonoResidencia,
        tiempoPuesto: value.datos.tiempoPuesto,
        tipoIdentidad: value.datos.tipoIdentidad,


        lugar: value.datos.lugarRegistro,
        fecha: value.datos.fechaRegistro,

        firmaRepresentante: value.datos.firmanteRepresentante,
        descripcionEstatus: value.datos.descripcionEstatus,
        tipoDepositante: "2",
        cehvalDepositanteCorreoRegistrador: token.email,
        cehvalDepositanteNombreRegistrador: token.name,
        //tipoDepositante:null
        //fechaAprobacion: value.datos.fechaAprobacion,
        //fechaUltimaModificacion: value.datos.fechaUltimaModificacion,
      }

      //console.log(myObj)

      const mandarInformacionDepositante = await EnviarRegistroDepositanteIndirectoNatural(myObj)
      if (mandarInformacionDepositante) {
        setLoading(false)
        if ((isNaN(mandarInformacionDepositante.error)) === false) {
          // respuestaincorrecta           
          setErroreshttp(mandarInformacionDepositante)
        } else {

          if (mandarInformacionDepositante?.codigo === "000") {
            setRegistroExitoso(true)
            setCodigo(mandarInformacionDepositante?.mensaje)
          } else {
            setRegistroFallido(true)
          }
        }
      }
      ////console.log(mandarInformacionDepositante)
    }
  };

  const next = () => setCurrentPage((prev) => prev + 1);
  const prev = () => setCurrentPage((prev) => prev - 1);

  const imprimirReporte = () => {
    setRegistroExitoso(false)
    setLoading(true)
    irInicio()
  }

  const irInicio = async () => {
    const myobj = {
      codigo: codigo,
      estatus: 1
    }

    const response = await reporteDIPN(myobj)
    if (response) {
      //setLoading(false)
      if ((isNaN(response.error)) === false) {
        // respuestaincorrecta           
        setErroreshttp(response)
      } else {
        const file = new Blob(
          [response],
          { type: 'application/pdf' });//Build a URL from the file
        const fileURL = URL.createObjectURL(file);//Open the URL on new Window
        window.open(fileURL);


        setRegistroFallido(false)
        setRegistroExitoso(false)
        history.push({
          pathname: '/inicioCehval'
        })
      }
    }

  }

  return (
    <>
      <div className='fluid-container'>
        {loading &&
          <PantallaCargando />
        }
        
        {erroreshttp &&
          <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
        }
        {registroExitoso &&
          <ComponenteModal mostrarModal={registroExitoso} cerrarModal={imprimirReporte} titulo="El proceso fue exitoso" textoBoton="Imprimir"></ComponenteModal>
        }
        {registroFallido &&
          <ComponenteModal mostrarModal={registroFallido} cerrarModal={irInicio} titulo="El proceso fallo, revise su información e intentelo de nuevo" textoBoton="Entendido"></ComponenteModal>
        }

        <div className="fluid-container">

          <main className="min-height-40" >
            <div className="row row-wrap g-0">
              
              <div className="col-12 mb-5">
                <BannerTitulo titulo="Registro persona natural"></BannerTitulo>
                <div className="d-flex justify-content-center">
                  <div className="container">
                    <div className="row contenedorsteper" id='contenedorsteper' >
                      <div className="col-12 contenedorsteper font-acumin-variable" >
                        <div id="stepperComponent">
                          <Stepper
                            steps={sections}
                            activeStep={currentPage - 1}
                            activeColor="#F4CA85"
                            completeColor="#242848"
                            completeBarColor="#242848"
                            titleFontSize={15}
                            size={30}
                            circleFontSize={15}
                            className="steper"
                          />
                        </div>

                        <div className=" claseSinBorder">
                          <div className="card-body ">
                            <div className="d-grid gap-4 mx-auto ">

                              <form onSubmit={handleSubmit}>
                                <br></br>
                                <h6 className="font-acumin-variable text-royal-blue">* Estos campos son obligatorios</h6>
                                {currentPage === 1 && (
                                  <>
                                    <div id="primeraSeccion"  >
                                      <div className="contenedorFormularioDepositanteDirecto card" >
                                        <RegistroDI1 />
                                        <div className='mb-3 text-end p-3'>
                                          <button type="submit" className="btn botonFormularioDepositante" >Siguiente</button>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {currentPage === 2 && (
                                  <>
                                    <div id="primeraSeccionIntermedia " >
                                      <div className="contenedorFormularioDepositanteDirecto card " >
                                        <REGISTRO_DI_1_5 />
                                        <div className='contenedorBotonesStteper p-3' >
                                          <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                          <button type="submit" className="btn botonFormularioDepositante" >Siguiente</button>
                                        </div>
                                      </div>
                                    </div>

                                  </>
                                )}

                                {currentPage === 3 && (
                                  <>
                                    <div id="segundaSeccion" >
                                      <div className="contenedorFormularioDepositanteDirecto card " >
                                        <RegistroDI2 />
                                        <br></br>
                                        <div className='contenedorBotonesStteper p-3' >
                                          <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                          <button type="submit" className="btn botonFormularioDepositante" >Siguiente</button>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {currentPage === 4 && (
                                  <>
                                    <div id="terceraSeccion" >
                                      <div className="contenedorFormularioDepositanteDirecto card " >
                                        <RegistroDI3 />

                                        <div className='contenedorBotonesStteper p-3'>
                                          <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                          <button type="submit" className="btn botonFormularioDepositante" >Siguiente</button>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {currentPage === 5 && (
                                  <>
                                    <div id="cuartaSeccion" >
                                      <div className="contenedorFormularioDepositanteDirecto card " >
                                        <RegistroDI4 />
                                        <br></br>
                                        <div className='contenedorBotonesStteper p-3'>
                                          <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                          <button type="submit" className="btn botonFormularioDepositante" >Siguiente</button>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {currentPage === 6 && (
                                  <>
                                    <div id="quintaSeccion" >
                                      <div className="card contenedorFormularioDepositanteDirecto card ">
                                        <RegistroDI5 tipo="DepositanteDirecto" />
                                        <br></br>
                                        <div className='contenedorBotonesStteper p-3' >
                                          <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                          <button type="submit" className="btn botonFormularioDepositante" >Siguiente</button>
                                        </div>
                                        <br></br>
                                        <br></br>
                                      </div>
                                    </div>
                                  </>
                                )}

                                {currentPage === 7 && (
                                  <>
                                    <div id="sextaSeccion" >
                                      <div className="card contenedorFormularioDepositanteDirecto card ">
                                        <RegistroDI6 tipo="depositanteIndirecto" />
                                        <br></br>
                                        <div className='contenedorBotonesStteper p-3' >
                                          <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                          <button type="submit" className="btn botonFormularioDepositante" >Siguiente</button>
                                        </div>

                                      </div>
                                    </div>
                                    <br></br>
                                    <br></br>
                                  </>
                                )}

                                {currentPage === 8 && (
                                  <>
                                    <div id="octavaSeccion" >
                                      <div className="card contenedorFormularioDepositanteDirecto card ">
                                        <RegistroDI7 tipo="depositanteIndirecto" nombreCompleto={token.name} correoElectronico={token.email} />
                                        <br></br>
                                        <div className='contenedorBotonesStteper p-3' >
                                          <button type="button" className="btn botonFormularioDepositante" onClick={prev}>Anterior</button>
                                          <button type="submit" className="btn botonFormularioDepositante" >Enviar</button>
                                        </div>
                                      </div>
                                    </div>
                                    <br></br>
                                    <br></br>
                                  </>
                                )}

                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main >

        </div>
      </div>


    </>
  );
};

export default function RegistroDepositanteIndirectoNatural({ history }) {
  return (
    <FormProvider>
      <Form history={history} />
    </FormProvider>
  );
}


