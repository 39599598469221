import React from "react";

//Components
import BannerTitulo from "../../ComponentesGenerales/Bannertitulo";
import TablaUsuarios from "../../ComponentesGenerales/TablaUsuarios";
import SpinnerStart from "../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from "../../ComponentesGenerales/ManejadorErrores";

//APIs
import { traerSeriesPorCodigoEmision } from "../../api/emisor/traerSeriesPorCodigoEmision";

//Hooks
import useSeriesColumns from '../../hooks/useSeriesColumns'

export default function InventarioSeries({ history }) {
    if (history.location.state === undefined) {
        history.go(-1)
    } 
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()

    React.useEffect(() => {
   
        traerSeries()
    }, [])

    const traerSeries = async () => {
        const obj = {
            emisionId: history.location.state.objMenu.codigo
        }
        const response = await traerSeriesPorCodigoEmision(obj);
        //console.log(response)
        if (response) {
            setSpin(false)
            if ((isNaN(response.error)) === false) {
              
                setErroreshttp(response)
                
            } else {
                setData(response)
                setCopia(response)
            }
        }
    }

    const viewSerie = (row) => {
        //console.log( row.original.cehvalSerieValorNomTituloCadena)
        //console.log(row.original.cehvalSerieMontoCadena)
             const serie = {
                 cehvalSerieNombreSerie: row.original.cehvalSerieNombreSerie,
                 cehvalSerieCodigo: row.original.cehvalSerieCodigo,
                 cehvalSerieEstatus: row.original.cehvalSerieEstatus,
                 cehvalSerieNombreEmi: row.original.cehvalSerieNombreEmi,
                 cehvalEmisionCodigo: row.original.cehvalEmisionCodigo.cehvalEmisionCodigo,
                 cehvalSerieCodigoIsin: row.original.cehvalSerieCodigoIsin,
                 cehvalSerieFechaEmi: row.original.cehvalSerieFechaEmiCadena,
                 cehvalSerieFechaVen: row.original.cehvalSerieFechaVenCadena,
                 cehvalSerieTasIntNom: row.original.cehvalSerieTasIntNom,
                 cehvalSerieMonto: row.original.cehvalSerieMontoCadena,
                 cehvalSerieCantInstEmi: row.original.cehvalSerieCantInstEmi,
                 cehvalSerieSpread: row.original.cehvalSerieSpread,
                 cehvalSerieValorNomTitulo: row.original.cehvalSerieValorNomTituloCadena,
                 catFormaColocacionCatForColId: row.original.catFormaColocacionCatForColId.catForColColocacion,
                 cehvalSerieCatMonedaIdSerie: row.original.cehvalSerieCatMonedaIdSerie.nombre,
                 periodicidad: row.original.cehvalSeriePeriodicidadId.catNumPerPeriodicidadNum,
                 cehvalSerieCatMonedaIdLiq: row.original.cehvalSerieCatMonedaIdLiq.nombre,
                 tipoTasaVariable: row.original.catTipoTasaVariableCatTipoTasaVariableId.catTipoTasaVariableTasaRefencia,
                 tasaVariable:row.original.cehvalSerieCatTipTasId.catTipTasTasa, 
                 PeriodoRevisionTasaVariable: row.original.catNumeroPeriocidadCatNumPerId.catNumPerPeriodicidad,
                 cehvalSerieCantInstDisponibles:row.original.cehvalSerieCantInstDis,
                 
                titulo: "Series registradas",
                ruta: "/inventarioEmisiones",
                id: row.original.cehvalSerieId,
                formaRepresentacion:row.original.cehvalEmisionFormaRepresentacion
        
             }
     
     
             //Mandar el objeto de la emision que el usuario desea ver
             history.push({
                 pathname: '/verInfoSerie', state: { objSerie: serie }
             })
         }


    return (
        <>
            <div className="fluid-container">
           {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }     
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <div className="">
                                <br></br>
                                <BannerTitulo titulo="Inventario de series"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                <div className="d-flex">
                                    <div className="container py-5">
                                        {copia &&
                                                <TablaUsuarios datos={data} displayInformation={viewSerie} useValidate={useSeriesColumns} textoBoton={"Abrir"} textTitle="Click para ver la información de la serie"></TablaUsuarios>
                                            }
                                    </div>
                                </div>
}
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    );
}