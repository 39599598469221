import React, { useState, useEffect } from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";

//APIs
import { traerInformacionAllEmisores } from "../../../api/VerInformacionDeEmisor.js/traerInformacionAllEmisores";

//Hooks
import useCuentasCehval from "../../../hooks/TLPOperador/useCuentasCehval";

export default function CuentasCehval({ history }) {
    const obj = {
        estatus: 2
    };
    const estatus = 2;

    const [data, setData] = useState([]);
    const [copia, setCopia] = useState();
    const [spin, setSpin] = useState(true);
    const [erroreshttp, setErroreshttp] = useState();


    const datos = [
        {
            cuenta: "Valores en tránsito",
            id: 1,
            titulo: "Cuenta de valores en tránsito",
        },
        {
            cuenta: "Valores en reserva",
            id: 2,
            titulo: "Cuenta de valores en reserva",
        },
        {
            cuenta: "Valores en reporto",
            id: 3,
            titulo: "Cuenta de valores en reporto",
        }

    ]

    useEffect(() => {
        traerEmisores();
    }, [])

    const traerEmisores = async () => {
        /*const usuarios = await traerInformacionAllEmisores(obj)
        //console.log(usuarios)
        if (usuarios) {
            setSpin(false)
            if ((isNaN(usuarios.error)) === false) {
              
                setErroreshttp(usuarios)
                
            } else {
                setData(datos)
                setCopia(usuarios)
            }
        }*/
        setData(datos)
        setCopia(datos)
        setSpin(false)
    }

    const verCuenta = async (row) => {

        history.push({
            pathname: '/cuentasValoresCehvalTLP', state: { objMenu: row.original }
        })

    }






    return (
        <>
            <div className="fliud-container" >

                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                {/*}  <Header history={history} ></Header>*/}
                <main className='container-min-height'>
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Cuenta CEHVAL"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">

                                            {copia &&
                                                <TablaMercado datos={data} displayInformation={verCuenta} useValidate={useCuentasCehval} textoBoton={"Ver cuenta"} textTitle="Click para ver la cuenta"></TablaMercado>
                                            }

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}