import React from "react";
import '../../../styles/stylesheet.css'
import { Modal } from "react-bootstrap";

//import BarraLateral from "../../../ComponentesGenerales/BarraLateral";
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";
import useFondosConiciliadosYNoconciliados from "../../../hooks/MercadoPrimario/useFondosConciliadosYNoConciliados";
import useSubastasFondosConiciliadosYNoconciliados from "../../../hooks/MercadoPrimario/useSubastasFondosConciliadosYNoConciliados";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";

import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";
//import TablaMercado from "../../../ComponentesGenerales/TablaMercado";
import { useHistory, useLocation } from "react-router-dom";




//import { traerInformacionAllDepositante } from "../../../api/VerInformacionUsuarios/traerInformacionAllDepositantes";
//import { consultarSolicitudesConciliacionFondosEstatus } from "../../../api/Mercadoprimario/consultarSolicitudesConciliacionFondosEstatus";
import { consultarConciliacionesPendientes } from "../../../api/Mercadoprimario/consultarConciliadcionesPendientes";
import { conciliarFondos } from "../../../api/Mercadoprimario/conciliarFondos";
import { cancelarConciliacion } from "../../../api/Mercadoprimario/cancelarConciliacion";
import { consultarEstatusSubastaConciliarFondos } from "../../../api/Mercadoprimario/consultarEstatusSubastaConciliarFondos";
import { fondearMercadoPrimario } from "../../../api/Mercadoprimario/fondearMercadoPrimario";
import { imprimirTablaConciliacion } from "../../../api/Mercadoprimario/imprimirTablaConciliaciones";

export default function FondosConciliadosYNoConciliados({ history }) {
    
    // const location = useLocation();
    //const estatus = 4;
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    const [copia2, setCopia2] = React.useState()
    const [copia3, setCopia3] = React.useState()
    const [copia4, setCopia4] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()
    
    const [subastas, setSubastas] = React.useState()
    //const [tablaCancelar, setTablaCancelar] = React.useState()
    const [fondeoExitoso, setFondeoExitoso] = React.useState()
    const [errorCancelacion, setErrorCancelacion] = React.useState()
    const [mensaje, setMensaje] = React.useState()
    const [validacionAgruparMontos, setValidacionAgruparMontos] = React.useState()
    const [infoRow, setInfoRow] = React.useState()
    const [mensajeValidacion, setMensajeValidacion] = React.useState()
    const [loading, setLoading] = React.useState(false);


    React.useEffect(() => {
        traerInformacion()

    }, [])




    const traerInformacion = async () => {
        const info = await consultarConciliacionesPendientes()

        //console.log(info)
        if (info) {

            if ((isNaN(info.error)) === false) {
              
                setErroreshttp(info)
                
            } else {
                setData(info)
                setCopia(info)
            }
        }

        const infoSubastas = await consultarEstatusSubastaConciliarFondos()
        //console.log("subastas")
        //console.log(infoSubastas)
        if (infoSubastas) {
            setSpin(false)
            if ((isNaN(infoSubastas.error)) === false) {
              
                setErroreshttp(infoSubastas)
                
            } else {
                setSubastas(infoSubastas)
                setCopia3(infoSubastas)
            }
        }
    }

    const conciliarSubasta = async (row) => {


        setLoading(true)

        const mandarConciliacion = await conciliarFondos(data);

        //console.log(mandarConciliacion)
        if (mandarConciliacion) {
            setLoading(false)

            if ((isNaN(mandarConciliacion.error)) === false) {
              
                setErroreshttp(mandarConciliacion)
                
            } else {

                setData(mandarConciliacion)
                if (copia) {
                    setCopia(false)
                    setCopia2(true)
                } else {
                    setCopia(true)
                    setCopia2(false)
                }
            }
        }
    }

    const cancelarConciliar = async (row) => {

        ////console.log(infoRow.folioBchTr)
        const folio = row.original.folioBchTr
        const myObj = {
            folioBchTr: folio,
            tipo: row.original.tipo
        }


        if (row.original.tipo === 7) {
            setMensaje("Este registro ya fue agrupado, no se puede eliminar")
            setErrorCancelacion(true)

        } else {
            setLoading(true)
            const mandarCancelacion = await cancelarConciliacion(myObj);
            //console.log(mandarCancelacion)
            if (mandarCancelacion) {
                setLoading(false)

                if ((isNaN(mandarCancelacion.error)) === false) {
                    // //console.log("erores")
                    ////console.log(response)
                    setErroreshttp(mandarCancelacion)
                    
                } else {



                    setData(mandarCancelacion.conciliaciones)
                    if (copia) {
                        setCopia(false)
                        setCopia2(true)
                    } else {
                        setCopia(true)
                        setCopia2(false)
                    }


                    setSubastas(mandarCancelacion.estadosSubasta)
                    if (copia3) {
                        setCopia3(false)
                        setCopia4(true)
                    } else {
                        setCopia3(true)
                        setCopia4(false)
                    }


                    //setData(mandarConciliacion)
                    //setCopia(false)
                    //setCopia2(true)
                }
            }
        }
    }

    const imprimir = async (row) => {
        //console.log(row.original)
        setLoading(true)
        const myobj = {
            autorizado: "true",
            tipoValor: "CONCILACION_PENDIENTE"
        }

        const response = await imprimirTablaConciliacion(myobj)
        if (response) {
            //setLoading(false)
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta      
                setLoading(false)
                setErroreshttp(response)
            } else {
                //console.log(response)
                const file = new Blob(
                    [response],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
                setLoading(false)
            }
        }



    }

    const AgruparAbrirMontos = async (row) => {

        setValidacionAgruparMontos(false)
        // //console.log(row.original)

        var valorRow;

        if (infoRow) {
            valorRow = infoRow
        } else {
            // //console.log(row.original.estatus)
            valorRow = row.original
        }
        //console.log("valor row")
        //console.log(valorRow.estatus)

        const Obj = {
            codigoSubasta: valorRow.codigoSubasta,
            moneda: valorRow.monedaId
        }

        if (valorRow.estatus === 6) {

            setLoading(true)
            const mandarFondeo = await fondearMercadoPrimario(Obj);
            //console.log(mandarFondeo)
            if (mandarFondeo) {
                setLoading(false)
                if ((isNaN(mandarFondeo.error)) === false) {
                    // //console.log("erores")
                    ////console.log(response)
                    setErroreshttp(mandarFondeo)
                    
                } else {
                    setData(mandarFondeo.conciliaciones)
                    if (copia) {
                        setCopia(false)
                        setCopia2(true)
                    } else {
                        setCopia(true)
                        setCopia2(false)
                    }


                    setSubastas(mandarFondeo.estadosSubasta)
                    if (copia3) {
                        setCopia3(false)
                        setCopia4(true)
                    } else {
                        setCopia3(true)
                        setCopia4(false)
                    }
                    setMensaje("Se generó la tabla de montos por cliente CEHVAL para la subasta " + valorRow.codigoSubasta)
                    setFondeoExitoso(true)

                    // //console.log(mandarFondeo)
                }
            }
            setInfoRow("")
        } else if (valorRow.estatus === 7) {
            const obj1 = {
                codigoSubasta: valorRow.codigoSubasta,
                monedaId: valorRow.monedaId
            }
            history.push({
                pathname: '/montosPorClientesYSubasta', state: { objMenu: obj1 }
            })

        }

    }

    const validacionAgrupar = (row) => {

        if (row.original.estatus === 6) {
            //console.log(row.original)
            //console.log(row.original.estatus)
            setInfoRow(row.original)
            setMensajeValidacion("¿Esta seguro que quiere agrupar la subasta " + row.original.codigoSubasta + " ?")
            setValidacionAgruparMontos(true)
        } else {
            //  setValidacionAgruparMontos(false)
            //console.log("entra else")
            AgruparAbrirMontos(row)
        }

    }

    const onHideModal = () => {
    }

    return (
        <>
            <Modal show={validacionAgruparMontos} animation={false} centered onHide={onHideModal}>
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => {
                            setValidacionAgruparMontos(false)
                            setInfoRow("")
                        }} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >{mensajeValidacion}</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => {setValidacionAgruparMontos(false)
                                                setInfoRow("")}} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Cancelar</button>
                        <button onClick={()=>AgruparAbrirMontos()} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Aceptar</button>
                    </div>
                </div>
            </Modal>

            {fondeoExitoso &&
                <ComponenteModal mostrarModal={fondeoExitoso} cerrarModal={() => { setFondeoExitoso(false) }} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>

            }
            {errorCancelacion &&
                <ComponenteModal mostrarModal={errorCancelacion} cerrarModal={() => { setErrorCancelacion(false) }} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>

            }
            
            {loading &&
                <PantallaCargando />
            }

            

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            <div className="fluid-container">

                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Conciliar fondos"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaMercado datos={data} displayInformation={cancelarConciliar} useValidate={useFondosConiciliadosYNoconciliados} mercadoPrimario={true} funcion1MercadoPrimario={imprimir} funcion2MercadoPrimario={conciliarSubasta} texto1MercadoPrimario="Imprimir" texto2MercadoPrimario="Conciliar fondos" textoBoton={"Eliminar"} textTitle="Click para eliminar" mostrar={true} textoHeader={"Eliminar"}></TablaMercado>

                                            }
                                            {copia2 &&
                                                <TablaMercado datos={data} displayInformation={cancelarConciliar} useValidate={useFondosConiciliadosYNoconciliados} mercadoPrimario={true} funcion1MercadoPrimario={imprimir} funcion2MercadoPrimario={conciliarSubasta} texto1MercadoPrimario="Imprimir" texto2MercadoPrimario="Conciliar fondos" textoBoton={"Eliminar"} textTitle="Click para eliminar" mostrar={true} textoHeader={"Eliminar"}></TablaMercado>
                                            }
                                            {copia3 &&

                                                <TablaMercado datos={subastas} displayInformation={validacionAgrupar} useValidate={useSubastasFondosConiciliadosYNoconciliados} textoBoton={"Agrupar/Abrir montos por cliente CEHVAL"} textTitle="Click para agrupar o abrir" textoHeader={"Acción para la subasta"}></TablaMercado>
                                            }
                                            {copia4 &&

                                                <TablaMercado datos={subastas} displayInformation={validacionAgrupar} useValidate={useSubastasFondosConiciliadosYNoconciliados} textoBoton={"Agrupar/Abrir montos por cliente CEHVAL"} textTitle="Click para agrupar o abrir" textoHeader={"Acción para la subasta"}></TablaMercado>
                                            }
                                            {/*}  {tablaCancelar&&
                                                <TablaUsuarios datos={data} displayInformation={cancelarConciliar} useValidate={useFondosConiciliadosYNoconciliados}  conciliar={true} imprimir={imprimir} conciliarSubasta={conciliarSubasta} textoBoton={"Cancelar"} textTitle="Click para cancelar"></TablaUsuarios>
                                            }*/}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    );
}













