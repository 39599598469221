import { useMemo } from "react";

export default function useSeriesSinFechas() {
  const columns = useMemo(
    () => [
      {
        Header: "Código de serie",
        accessor: "codigoSerie"
      },
      {
        Header: "Serie",
        accessor: "nombreSerie"
      },
      {
        Header: "ISIN",
        accessor: "isin"
      },
      {
        Header: "Nombre de la emisión",
        accessor: "nombreEmision"
      },        
      {
        Header: "Código de la emisión",
        accessor: "codigoEmision"
      },     
    ],
    []
  );

  return columns;
}