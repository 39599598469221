import { useMemo } from "react";

export default function useMovimientosValores() {
  const columns = useMemo(
    () => [
      {
        Header: "Folio",
        accessor: "folio"
      },
      {
        Header: "Fecha/hora",
        accessor: "timestamp"
      },
      {
        Header: "Operación",
        accessor: "operacion"
      },
      {
        Header: "Código de la operación",
        accessor: "codigoOperacion"
      },
      {
        Header: "ISIN",
        accessor: "isin"
      },
      {
        Header: "Moneda",
        accessor: "moneda"
      },
      {
        Header: "Cantidad de instrumentos",
        accessor: "cantidadInstrumentos"
      },
      {
        Header: "Propietario origen",
        accessor: "origenPropietario"
      },
      {
        Header: "Cuenta origen",
        accessor: "origenCuenta"
      },
      {
        Header: "Propietario destino",
        accessor: "destinoPropietario"
      },
      {
        Header: "Cuenta destino",
        accessor: "destinoCuenta"
      },
      {
        Header: "Ejecutor",
        accessor: "ejecutor"
      },
    ],
    []
  );

  return columns;
}
