import { instance } from '../../apiGlobal';

export async function descargarEstadoCuenta (obj) {
      try {
        const response = await instance(`/tarificacion/descargar/estadocuenta`, {
          method:"post",
          data:obj,
          responseType: 'blob',

        })
        return response;
      } catch (error) {
        return error;
      }
}


