import React from "react";
export default function BannerTitulo(props) {
    return (
        <div className="border-bottom mb-5">
            <div className='bannerTitulo row justify-content-center align-items-center g-0'>
                <div id="bannerTextContainer"  className="d-flex justify-content-center">
                    <p id="bannerTexto"  className="textoBannerTitulo font-acumin-variable m-0 col-12 col-sm-11 col-md-10  col-xs-10 p-3 text-royal-blue " >{props.titulo}</p>
                </div>
            </div>
        </div>  
    )
}

