import React, { useState } from "react";

//Componentes

import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";



//Hooks
import useSeriesSinFechas from "../../../hooks/PagoDerechosPatrimoniales/useSeriesSinFechas";



//API's
import { consultarSeriesSinFechasPagos } from "../../../api/PagoInteresesAmorDiv/consultarSeriesSinFechasPagos";



//Muestra las cuentas de un depositante indirecto
export default function AgregarFechasPagoInteresesAmortizaciones({ history }) {


    //If endpoint got the data will be true    


    const [erroreshttp, setErroreshttp] = useState();
    const [spin, setSpin] = React.useState(true)
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()


    React.useEffect(() => {
        traerInformacionSeriesSinFechas()
    }, []);

  


    const traerInformacionSeriesSinFechas = async () => {
      
        const response = await consultarSeriesSinFechasPagos()
        //console.log(response)
        if (response) {
            setSpin(false)
            if ((isNaN(response.error)) === false) {
                setErroreshttp(response)
            } else {
                setData(response)
                setCopia(response)
            }
        }
    }

    const agregarFechas = (row) => {
        //console.log(row.original)
        history.push({
            pathname: '/formularioAgregarFechas', state: { objMenu: row.original }
        })
    }


    return (
        <>
           
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            <div className="fluid-container">
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                  
                        <div className="col-12 mb-5">                        
                                 <BannerTitulo titulo="Series sin fechas de pago registradas"></BannerTitulo>
                                    {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                        <div className="d-flex">
                                            <div className="container py-5">
                                                {copia &&
                                                    <>
                                                        <TablaMercado datos={data}  useValidate={useSeriesSinFechas} displayInformation={agregarFechas}  textoBoton={"Agregar Fechas"} textTitle="Click para agregar fechas" mostrar={true}></TablaMercado>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}

