import React, { useState } from "react";

//Components
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";

//APIs
import { registroSolicitudLiberacionDeGarantia } from '../../../../api/prendasYGarantias/SolicitudesDeLiberacionGarantia/registroSolicitudLiberacionDeGarantia';
import { envioPDFLiberacion } from '../../../../api/prendasYGarantias/SolicitudesDeLiberacionGarantia/envioPDFLiberacion';


export default function SolicitudLiberacionGarantia({ history }) {

    //Inputs
    const [codigoDepositanteReceptor, setCodigoDepositanteReceptor] = useState();
    const [cuentaValoresDepReceptor, setCuentaValoresDepReceptor] = useState();
    const [folioConstitucion, setFolioConstitucion] = useState();
    const [file, setFile] = useState();

    //Stores the generated folio once the user clicks on send
    //const [folio, setFolio] = useState();

    //States to show modal when regiter is successful or not
    const [registroExitoso, setRegistroExitoso] = useState();
    const [registroFallido, setRegistroFallido] = useState();

    //Show modal saying invalid file
    const [invalidFile, setInvalidFile] = useState();

    //State to show loading screen
    const [loading, setLoading] = useState(false);

    //Messages user
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [messageSuccessful, setMessageSuccessful] = useState();
    const [messageFailed, setMessageFailed] = useState();
    const [erroreshttp, setErroreshttp] = useState();

    //Se le asignará automáticamente un folio al oficio de liberación de garantía
    //Se registrará la solicitud de liberación de garantía y el oficio escaneado se guardará en un repositorio con su folio como nombre


    const selectFile = (e) => {
        e.preventDefault();

        //Cleans error message if was one
        setError("");

        //Name of the file to validate if has the correct extension
        const fileName = e.target.files[0].name;

        const fileSize = e.target.files[0].size;

        //Regex to validate the extension of the file
        const extensionsAllow = /(.pdf)$/i;

        ////console.log(pathFile.files)
        //If selected file doesnt have correct extension show user error message
        if (!extensionsAllow.exec(fileName)) {
            setMessage("");

            //Shows an error message under the square to select file
            setError("Selecciona un archivo válido");

            //Clean our states if they had any information
            setFile("");

            document.querySelector("#inputFilePDFLiberacion").value = "";


            return false;

        } else if (fileSize > 10_00_000) {

            setInvalidFile(true);
            setMessage("");

            //Shows an error message 
            setError("El tamaño del archivo debe ser menor a 10 MB");
            //Clean file name if a file was selected before

            //Clean state if a file was selected before
            setFile("");

            document.querySelector("#inputFilePDFLiberacion").value = "";

        } else if (e.target.files && e.target.files[0]) {

            setMessage("Archivo válido")
            //Set FileList [File] to our state
            setFile(e.target.files[0]);
            ////console.log(file)
        }

    }

    const sendLiberacionForm = async (e) => {
        e.preventDefault();
        try {
            const formData = {
                //folio: folioLiberacion,
                folioConstitucion: folioConstitucion,
                codigoDepositanteReceptor: codigoDepositanteReceptor,
                cuentaValoresReceptora: cuentaValoresDepReceptor,
            }
            //console.log(formData)

            const response = await registroSolicitudLiberacionDeGarantia(formData);
            //console.log(response);

            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    if (response.data) {

                        //Request Successful
                        if (response.data.isSuccessful === true) {
                            const folioLiberacion = (response.data.mensaje);

                            //console.log(response);

                            //Call second request
                            sendFile(folioLiberacion);

                            //Request Not successful
                        } else {
                            setLoading(false);
                            //If request returns an status 200
                            if (response.data.mensaje) {
                                setRegistroFallido(true);
                                setMessageFailed(response.data.mensaje);

                                //If request doesnt return an status 200
                            } else if (!response.data.mensaje) {
                                //console.log("no hay mensaje")
                                setMessageFailed("No se obtuvo respuesta del servidor");
                                setRegistroFallido(true);
                            }
                        }

                    } else if (response.error === 401) {
                        setRegistroFallido(true);
                        setMessageFailed("La sesión ha caducado");
                    }
                }
            }




        } catch (error) {
            console.error(error);
            setInvalidFile(true);
            document.querySelector("#inputFilePDFLiberacion").value = "";
        }
    }


    const sendFile = async (folioLiberacion) => {
        try {
            if (file === undefined) {
                setInvalidFile(true);
                ////console.log("el file esta como undefined, no se hizo peticion")
            } else {

                const pdfFile = new FormData();
                pdfFile.append('fileName', folioLiberacion);
                pdfFile.append('file', file);
                ////console.log(pdfFile)

                const response = await envioPDFLiberacion(pdfFile);
                setLoading(false);
                if (response) {
                    //setCargando(false)
                    if ((isNaN(response.error)) === false) {
                        // respuestaincorrecta           
                        setErroreshttp(response)
                    } else {
                        //console.log(response)

                        if (response.data.isSuccesful === true) {
                            setLoading(false);
                            setRegistroExitoso(true);
                            setMessageSuccessful(response.data.respuesta)

                        } else {
                            setLoading(false);
                            setRegistroFallido(true);
                            setMessageFailed("No se pudo cargar el archivo PDF, inténtelo de nuevo")
                        }
                    }
                }




            }

        } catch (error) {
            console.error(error);
            setInvalidFile(true);
            document.querySelector("#inputFilePDFLiberacion").value = "";

        }
    }



    const clearForm = () => {
        setRegistroExitoso(false);
        setRegistroFallido(false);

        document.querySelector("#inputFilePDFLiberacion").value = "";
        setCodigoDepositanteReceptor("");
        setCuentaValoresDepReceptor("");
        setFolioConstitucion("");
        setMessage("");
    }



    return (
        <>
            {invalidFile &&
                <ComponenteModal mostrarModal={invalidFile} cerrarModal={() => setInvalidFile(false)} titulo="Selecciona un archivo PDF y con un peso menor a 10 MB" textoBoton="Aceptar"></ComponenteModal>
            }

            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={clearForm} titulo={`${messageSuccessful}`} textoBoton="Aceptar"></ComponenteModal>
            }

            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={() => setRegistroFallido(false)} titulo={`${messageFailed}`} textoBoton="Aceptar"></ComponenteModal>
            }
            {loading &&
                <PantallaCargando />
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <div className="fluid-container">

                <main className="min-height-40">
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Solicitud de liberación o ejecución de prenda o garantía"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <section className="p-2 d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded p-3">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <form onSubmit={sendLiberacionForm} className="needs-validation font-acumin-variable text-royal-blue">
                                                        <div className="row mt-4">
                                                            <div className="col-12 col-md-12 col-lg-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código depositante transferente" onChange={(e) => setCodigoDepositanteReceptor(e.target.value)} value={codigoDepositanteReceptor} className="form-control fs-5" maxLength={20} name="codigoDepositanteReceptor" type="text" placeholder="Código de depositante transferente" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="codigoDepositanteReceptor">Código de depositante receptor *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-6 mb-3">
                                                                <div className="form-floating">
                                                                    <input title="Cuenta valores del depositante receptor" onChange={(e) => setCuentaValoresDepReceptor(e.target.value)} value={cuentaValoresDepReceptor} className="form-control fs-5" maxLength={20} type="text" name="cuentaValoresDepReceptor" placeholder="CUneta de valores del depositante receptor" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="cuentaValoresDepReceptor">Cuenta de valores del depositante receptor *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Número de folio" onChange={(e) => setFolioConstitucion(e.target.value)} value={folioConstitucion} className="form-control fs-5" maxLength={30} type="text" name="numeroFolio" placeholder="Número de folio" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="numeroFolio">Número de folio de la constitución *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-6 mb-3">
                                                                <input id="inputFilePDFLiberacion" title="Clic para subir archivo" type="file" onChange={(e) => selectFile(e)} className="form-control text-royal-blue fs-5" name="archivoEscaneado" aria-describedby="help-input-file" required />
                                                                <div id="help-input-file" className="form-text">Escanee el contrato y suba en formato <i>.PDF</i> *</div>
                                                                {error &&
                                                                    <p className="text-danger font-acumin-variable fs-5">{error}</p>
                                                                }
                                                                {message &&
                                                                    <p className="text-success font-acumin-variable fs-5">{message}</p>
                                                                }
                                                            </div>
                                                            <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                <button type="submit" className="btn background-royal-blue text-white bg-hover-gold">Enviar</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    )
}