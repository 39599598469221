//React
import React from "react";

//Componentes generales
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";

//APIs
import { traerEmisiones } from "../../../api/emision/traerEmisiones";

//Hooks
import useValidateAuth from '../../../hooks/useEmisionColumns';

export default function SolicitudEmisiones({ history }) {
    const [copia, setCopia] = React.useState();
    const [data, setData] = React.useState([])
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()

    React.useEffect(() => {
        searchEmisions();
    }, [])


    const searchEmisions = async () => {
        
        const emisiones = await traerEmisiones({ estatus: 1 });
        //console.log(emisiones)
       //mandar el id para actualizar

        if (emisiones) {
            setSpin(false)
            if ((isNaN(emisiones.error)) === false) {
                setErroreshttp(emisiones)
            } else {
                setData(emisiones)
                setCopia(emisiones)
            }
        }

    }


    //Catch information form the selected row
    const viewEmision = (row) => {

          //console.log( row.original)

        const emision = {
            cehvalNombreEmision: row.original.cehvalEmisionNomEmi,
            cehvalCodigoEmision: row.original.cehvalEmisionCodigo,
            cehvalEstatus: row.original.cehvalEmisionEstatus,
            cehvalNombreEmisor: row.original.cehvalEmisorCodigo.cehvalEmisorNombreCom,
            cehvalCodigoEmisor: row.original.cehvalEmisorCodigo.cehvalEmisorCodigo,
            cehvalMonto: row.original.cehvalEmisionMontoCadena,
            cehvalEmisionSeries: row.original.cehvalEmisionSeries,
            cehvalEmisionNumeroResol: row.original.cehvalEmisionNumeroResol,
            cehvalEmisionFechaRpmv: row.original.cehvalEmisionFechaRpmvCadena,
            cehvalEmisionRepresentanteComunObl: row.original.cehvalEmisionRepresentanteComunObl,
            cehvalEmisionTipoMoneda: row.original.catMonedaCatMonedaId.catMonedaDivisa,
            cehvalEmisionDesmaterializada: row.original.cehvalEmisionDesmaterializada,
            ruta:"/solicitudEmisiones",
            titulo: "Autorizar emisión de BCV",
            id:row.original.cehvalEmisionId
        }




        //Mandar el objeto de la emision que el usuario desea ver
        history.push({
            pathname: '/verInfoEmision', state: { objMenu: emision }
        })

    }

    return (
        <>

            <div className="fluid-container">
               {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }     
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Emisiones por autorizar"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaUsuarios datos={data} displayInformation={viewEmision} useValidate={useValidateAuth} textoBoton={"Abrir"} textTitle="Click para ver la información de la emisión"></TablaUsuarios>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    );
}