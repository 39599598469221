import React from "react";

//Components
import BannerTitulo from "../../ComponentesGenerales/Bannertitulo";
import TablaUsuarios from "../../ComponentesGenerales/TablaUsuarios";
import SpinnerStart from "../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from "../../ComponentesGenerales/ManejadorErrores";

//APIs
import { traerInfoBitacoraOperaciones } from '../../api/bitacoras/traerInfoBitacoraOperaciones'

//Hooks
import useBitacoraOperaciones from '../../hooks/useBitacoraOperaciones'

export default function BitacoraOperaciones({ history }) {
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState();
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()

    React.useEffect(() => {
        traerBitacoraOperaciones()
    }, [])

    const traerBitacoraOperaciones = async () => {
        const usuarios = await traerInfoBitacoraOperaciones()
        // //console.log(usuarios.error)
        if (usuarios) {
            //console.log(usuarios)
            setSpin(false)
            if ((isNaN(usuarios.error)) === false) {
              
                setErroreshttp(usuarios)
                
            } else {
                //console.log(usuarios)
                setData(usuarios)
                setCopia(usuarios)
            }
        }
    }


    return (
        <>
            <div className="fluid-container">
               {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }     
                <main className="min-height-40">
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Bitácora de operaciones"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaUsuarios datos={data} useValidate={useBitacoraOperaciones} ></TablaUsuarios>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    );
}
















