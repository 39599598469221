import { instance } from '../apiGlobal';

export async function traerFacturasRegistradas() {
    try {
        const response = await instance(`/mttofacturas/obtener/registrados`, {
            method: "get",
        });

        return response;

    } catch (error) {
        return error;
    }
}
