import React, { useState } from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";

//Hooks
import useCuentasValoresCehval from "../../../hooks/TLPOperador/useCuentasValoresCehval";
import useCuentaValoresReporto from "../../../hooks/useCuentaValoresReporto";


//APIs
import { traerCuentaValoresReserva } from "../../../api/CuentaValoresReservas/traerCuentaValoresReserva";
import { traerCuentaValoresTransito } from "../../../api/cuentaValoresTransito/traerCuentaValoresTransito";
//import { traerCuentaValoresReporto } from "../../../api/cuentaValores/traerCuentaValoresReporto";
//import { cuentaValoresReportoTLP } from "../../../api/mantenimientoCargaValores/cuentaValoresReportoTLP";
import { traerCuentaValoresReporto } from "../../../api/cuentaValores/traerCuentaValoresReporto";


export default function CuentasValoresCehvalTLP({ history }) {
    if (history.location.state === undefined) {
        history.go(-1)
    }
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState();
    const [erroreshttp, setErroreshttp] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const idResponse = history.location.state.objMenu.id;


    const [isReporto, setIsReporto] = useState();
    const [isValidForTLP, setIsValidForTLP] = useState(true);

    React.useEffect(() => {
        verCuentaValores()
    }, [])

    const verCuentaValores = async () => {
        // const respuesta = await traerOperaciones()

        var response;


        if (idResponse === 1) {
            response = await traerCuentaValoresTransito();
        } else if (idResponse === 2) {
            response = await traerCuentaValoresReserva();
        } else {
            response = await traerCuentaValoresReporto();
            setIsReporto(true);
        }


        //console.log(response)
        if (response) {
            setSpin(false)
            if ((isNaN(response.error)) === false) {

              
                setErroreshttp(response)
                
            } else {
                // //console.log(respuesta)
                setData(response)
                setCopia(response)
            }
        }
    }

    const abrirFormularioTLP = (row) => {
        //console.log(row.original)
        var moneda;
        if (row.original.monedaSerie === "HNL") {
            moneda = "LEMPIRA"
        } else {
            moneda = "DÓLAR"
        }


        const datosParaFormulario = {
            codigoDepositanteDirecto: row.original.codigoDepositanteDirecto,
            nombreDepositanteDirecto: row.original.nombreDepositanteDirecto,
            codigoEmisor: row.original.codigoEmisor,
            nombreEmisor: row.original.nombreEmisor,
            codigoDep: row.original.codigoDepositantePropietario,
            nombreDep: row.original.nombreDepositantePropietario,
            numeroCuenta: row.original.cuentaOrigen,
            moneda: moneda,
            saldoDisponible: row.original.cantidadValores,
            isinSerie: row.original.isinSerie,
            codSerie: row.original.codigoSerie,
            codEmision: row.original.codigoEmision,
            idValor: row.original.idValor,
        }

        //  //console.log(datosParaFormulario)

        history.push({
            pathname: "/TLPOperadorCehval",
            state: {
                datosTransferente: datosParaFormulario
            },
        })
    }


    //Handle transfer for reporto operations
    const handleTransferirReporto = (row) => {
        const reportoData = row.original;
        const isValidForTLP = reportoData.validoTLP;

        //const statusOperacion = true;
        if (isValidForTLP) {
            setIsValidForTLP(true);

            history.push({
                pathname: "/formTLPReporto",
                state: {
                    rowData: reportoData
                },
            })
        } else {
            setIsValidForTLP(false);
        }

    }

    return (
        <>
            <div className="fluid-container">


                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }

                <main className='container-min-height'>
                    <div className="row row-wrap g-0">
                        <div className="col-12 ">
                            <div className="">
                                <br></br>
                                {idResponse === 1 ?
                                    <BannerTitulo titulo="Cuenta de valores en tránsito"></BannerTitulo>
                                    :
                                    <>
                                        {idResponse === 2 ?
                                            <BannerTitulo titulo="Cuenta de valores en reserva"></BannerTitulo>
                                            :
                                            <BannerTitulo titulo="Cuenta de valores en reporto"></BannerTitulo>

                                        }
                                    </>
                                }

                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart>
                                    :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&

                                                <>

                                                    {!isReporto &&
                                                        <>
                                                            <TablaMercado datos={data} useValidate={useCuentasValoresCehval} displayInformation={abrirFormularioTLP} textoBoton={"Transferir"} textTitle="Clic para abrir formulario para transferir" mostrar={true} ></TablaMercado>
                                                        </>

                                                    }

                                                    {isReporto &&
                                                        <>

                                                            {!isValidForTLP &&
                                                                <h5 className="text-danger font-acumin-varible fw-normal">Los valores de la operación seleccionada no pueden ser transferidos mediante un TLP</h5>
                                                            }
                                                            <TablaMercado datos={data} useValidate={useCuentaValoresReporto} displayInformation={handleTransferirReporto} textoBoton={"Transferir"} textTitle="Clic para abrir formulario para transferir" mostrar={true} ></TablaMercado>
                                                        </>

                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    );
}



