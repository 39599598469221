//React
import React, { useState, useEffect } from "react";


//Componentes generales
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores';
import TablaConceptosCobroAutomaticos from "../../../../ComponentesGenerales/TablaConceptosCobroAutomaticos";
import ModalConfirmAction from "../../../../ComponentesGenerales/ModalConfirmAction";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";

//APIs
import { actualizarConceptoNoPeriodico } from "../../../../api/tarificacion/ConceptosNoPeriodicos/autorizarAvisosCobroNoPeriodicos/actualizarConceptoNoPeriodico";
import { autorizarAvisoCobroAutomatico } from "../../../../api/tarificacion/ConceptosAutomaticos/autorizarAvisos/autorizarAvisoCobroAutomatico";
import { rechazarAvisoCobro } from "../../../../api/tarificacion/ConceptosAutomaticos/rechazarAvisos/rechazarAvisoCobro";
import { verAvisoCobroParaAutorizar } from "../../../../api/tarificacion/ConceptosAutomaticos/autorizarAvisos/verAvisoCobroParaAutorizar";

//Hooks
import useConceptosCobroAutomaticos from "../../../../hooks/Tarificacion/ConceptosAutomaticos/useConceptosCobroAutomaticos";


export default function AutorizarAvisoCobroConceptosAutomaticos({ history }) {



    //Save loaded data
    const [conceptos, setConceptos] = useState();

    //Objeto del aviso de cobro
    const [objAvisoCobro, setObjAvisoCobro] = useState();

    //Id del aviso. Se ocupa para autorizar el aviso o rechazarlo
    const [idAviso, setIdAviso] = useState();

    //Datos del participante. Para pintar datos en front
    const [nombreParticipante, setNombreDepositante] = useState();
    const [codigoParticipante, setCodigoParticipante] = useState();
    const [tipoParticipante, setTipoParticipante] = useState();

    //Responsable pago
    const [nombreResponsablePago, setNombreResponsablePago] = useState();
    const [codigoResponsablePago, setCodigoResponsablePago] = useState();

    //Status del aviso de cobro
    const [statusAvisoStr, setStatusAvisoStr] = useState();
    const [periodo, setPeriodo] = useState();

    //Monto total del aviso de cobro
    const [montoTotalStr, setMontoTotalStr] = useState();


    //Spinner
    const [spin, setSpin] = useState(true);
    const [loading, setLoading] = useState();

    //Message
    const [ingreseValor, setIngreseValor] = useState();

    //Autorizar aviso
    const [autorizar, setAutorizar] = useState();
    //Rechazar aviso
    const [reject, setReject] = useState();

    //States to handle load data when loading page 
    const [erroreshttp, setErroreshttp] = useState();


    //Input reject
    const [reasonReject, setReasonReject] = useState();
    const [isReject, setIsReject] = useState(false);

    const [buttonRejectText, setButtonRejectText] = useState("Rechazar");
    const [buttonReject, setButtonReject] = useState();


    useEffect(() => {

        if (history.location.state === undefined) {
            window.location.replace("/avisosCobroAutomaticosPorAutorizar");
        } else {
            //console.log(history.location.state)
            const idAviso = history.location.state.rowInfo.idAviso;
            setIdAviso(idAviso);
            getAllApplications(idAviso);
        }

    }, []);


    const getAllApplications = async (idAviso) => {
        try {

            const response = await verAvisoCobroParaAutorizar({ idAviso: idAviso })
            //console.log(response)

            if (isNaN(response.error) === false) {
                setErroreshttp(response);
                setSpin(false);


            } else if (response.data) {
                setObjAvisoCobro(response.data);

                //Datos participante
                setCodigoParticipante(response.data.datosParticipante.codigoDepositante);
                setNombreDepositante(response.data.datosParticipante.nombreDepositante);
                setTipoParticipante(response.data.tipoParticipanteStr);

                //Datos responsable pago
                setNombreResponsablePago(response.data.nombreResponsablePago);
                setCodigoResponsablePago(response.data.codigoResponsablePago);

                //Datos de la factura
                setStatusAvisoStr(response.data.estatusStr);
                setPeriodo(response.data.mesCobro);

                //Total a cobrar
                setMontoTotalStr(response.data.montoTotalResumenStr);

                //Conceptos de la factura
                setConceptos(response.data.conceptos);


                setSpin(false);
            }

        } catch (err) {
            console.error(err);
        }
    }


    //Function to update "Base de calculo" or "Monto objetivo"
    const updateMontoObjetivo = async (row) => {

        try {
            setIngreseValor(null);
            //Rrow object
            const objRow = row.original;

            //New values
            const newBaseCalculo = row.updateBaseCalculo;
            const newMontoHNL = row.updateMontoHNL;
            const idConcepto = row.original.idCalculado;

            //Flags to validate if theres a new base calculo o monto HNL
            const flagBaseCalculo = row.flagUpdateBaseCalculo;
            const flagMontoHNL = row.flagUpdateMontoHNL;

            //Obj to send in request
            const newObjRow = {
                concepto: objRow,
                idAviso: idAviso,
            }


            if (flagBaseCalculo !== undefined && flagMontoHNL !== undefined) {
                //console.log("se van a actualizar los dos")

                setIngreseValor(null);
                objRow.nuevaBaseCalculo = newBaseCalculo;
                objRow.nuevoMontoHNL = newMontoHNL;
                objRow.modificado = true;

                setSpin(true);
                const response = await actualizarConceptoNoPeriodico(newObjRow);

                if (isNaN(response.error) === false) {
                    setErroreshttp(response);

                } else if (response.data) {
                    const updatedConcept = response.data;

                    const newConceptsArray = updateConceptsArray(conceptos, idConcepto, updatedConcept);

                    if (objAvisoCobro) {
                        objAvisoCobro.conceptos = newConceptsArray;
                    }



                    //Sum all monto's HNL
                    const totalSum = sumConceptosMontosHNL(newConceptsArray);
                    setConceptos(newConceptsArray);
                    setMontoTotalStr(totalSum);
                    setSpin(false);
                }

                //Solo se va a actualizar la base calculo
            } else if (flagBaseCalculo === undefined && flagMontoHNL !== undefined) {
                setSpin(true);
                //console.log("Se va a actualizar monto HNL")

                objRow.nuevaBaseCalculo = null;
                objRow.nuevoMontoHNL = newMontoHNL;
                objRow.modificado = true;


                setSpin(true);
                const response = await actualizarConceptoNoPeriodico(newObjRow);

                //console.log(response);

                if (isNaN(response.error) === false) {
                    setErroreshttp(response);

                } else if (response.data) {
                    const updatedConcept = response.data;

                    const newConceptsArray = updateConceptsArray(conceptos, idConcepto, updatedConcept);
                    if (objAvisoCobro) {
                        objAvisoCobro.conceptos = newConceptsArray;
                    }


                    //Sum all monto's HNL
                    const totalSum = sumConceptosMontosHNL(newConceptsArray);
                    setConceptos(newConceptsArray);
                    setMontoTotalStr(totalSum);
                    setSpin(false);
                }


                //Solo se va a actualizar el monto HNL
            } else if (flagMontoHNL === undefined && flagBaseCalculo !== undefined) {
                //console.log("se va a actualizar el base calculo")
                setSpin(true);

                objRow.nuevaBaseCalculo = newBaseCalculo;
                objRow.nuevoMontoHNL = null;
                objRow.modificado = true;


                const response = await actualizarConceptoNoPeriodico(newObjRow);
                //console.log(response);

                if (isNaN(response.error) === false) {
                    setErroreshttp(response);

                } else if (response.data) {
                    const updatedConcept = response.data;

                    const newConceptsArray = updateConceptsArray(conceptos, idConcepto, updatedConcept);
                    if (objAvisoCobro) {
                        objAvisoCobro.conceptos = newConceptsArray;
                    }

                    //Sum all monto's HNL
                    const totalSum = sumConceptosMontosHNL(newConceptsArray);

                    setConceptos(newConceptsArray);
                    setMontoTotalStr(totalSum);
                    setSpin(false);
                }

            } else {
                //console.log("no se va a actualizar ninguno")
                setIngreseValor("Para actualizar ingrese un valor");
            }


        } catch (error) {
            console.error(error);
        }
    }


    //Sum each monto HNL from the array of concepts 
    const sumConceptosMontosHNL = (conceptos) => {

        //Sum all montosHNL
        let sum = conceptos.reduce((accumulator, currentValue) => accumulator + currentValue.montoHNL, 0);
        //console.log(sum.toLocaleString())
        return sum.toLocaleString();
    }

    //Create a new array of concepts when user update "Base de calculo" or "Monto HNL"
    const updateConceptsArray = (concepts, idConcept, newConcept) => {
        const newArray = concepts.map((concept) => {
            if (concept.idCalculado === idConcept) {
                return newConcept;
            } else {
                return concept;
            }
        });

        return newArray;
    }


    const authorizeAviso = async (e) => {
        try {
            e.preventDefault();
            //console.log(objAvisoCobro)
            setLoading(true);
            const response = await autorizarAvisoCobroAutomatico(objAvisoCobro);
            setLoading(false);

            //console.log(response)

            if (isNaN(response.error) === false) {
                setErroreshttp(response);

            } else if (response.data.respuesta.isSuccessful) {
                setAutorizar(response.data.respuesta.mensaje);

            }


        } catch (error) {
            console.error(error);
        }
    }


    const rejectAviso = async (e) => {
        try {
            e.preventDefault();

            if (objAvisoCobro) {
                //Adds "Motivo del rechazo" to property decripcion
                objAvisoCobro.descripcion = reasonReject;
            }


            setLoading(true);
            //console.log(objAvisoCobro)
            const response = await rechazarAvisoCobro(objAvisoCobro);
            setLoading(false);

            //console.log(response)

            if (isNaN(response.error) === false) {
                setErroreshttp(response);

            } else if (response.data.respuesta.isSuccessful) {
                setReject(response.data.respuesta.mensaje);

            }
        } catch (error) {
            console.error(error);
        }
    }

    const showRejectField = () => {
        if (isReject === false) {
            setIsReject(true);
            setButtonReject(true);
            setButtonRejectText("Cancelar rechazo");
        } else {
            setIsReject(false);
            setButtonReject(false);
            setButtonRejectText("Rechazar");
        }
    }


    const goBack = () => {
        history.push({
            pathname: '/avisosCobroAutomaticosPorAutorizar',
        });
    }


    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {autorizar &&
                <ComponenteModal mostrarModal={autorizar} cerrarModal={goBack} titulo={autorizar} textoBoton="Aceptar"></ComponenteModal>
            }
            {reject &&
                <ComponenteModal mostrarModal={reject} cerrarModal={goBack} titulo={reject} textoBoton="Aceptar"></ComponenteModal>
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <div className="">
                <main className="min-height-40">
                    <div className="row row-wrap g-0">

                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Factura de conceptos tarificables automáticos por autorizar"></BannerTitulo>

                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex mt-5">
                                        <div className="container-fluid container-md-container container-lg-none container-xl-none container-xxl-none px-3">
                                            {objAvisoCobro &&
                                                <>
                                                    <div className="row  ps-3">
                                                        <div className="col-12 col-md-12 col-lg-4 col-xl-4 mb-3">
                                                            <h5 className="font-acumin-variable text-royal-blue fw-normal">Nombre del participante</h5>
                                                            <p className="font-acumin-variable text-muted">{nombreParticipante}</p>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-4 col-xl-4 mb-3">
                                                            <h5 className="font-acumin-variable text-royal-blue fw-normal">Código del participante</h5>
                                                            <p className="font-acumin-variable text-muted">{codigoParticipante}</p>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-4 col-xl-4 mb-3">
                                                            <h5 className="font-acumin-variable text-royal-blue fw-normal">Tipo de participante</h5>
                                                            <p className="font-acumin-variable text-muted">{tipoParticipante}</p>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-4 col-xl-4 mb-3">
                                                            <h5 className="font-acumin-variable text-royal-blue fw-normal">Estatus de la factura</h5>
                                                            <p className="font-acumin-variable text-muted">{statusAvisoStr}</p>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                            <h5 className="font-acumin-variable text-royal-blue fw-normal">Periodo</h5>
                                                            <p className="font-acumin-variable text-muted">{periodo}</p>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-6 col-xl-4 mb-3">
                                                            <h5 className="font-acumin-variable text-royal-blue fw-normal">Nombre del responsable de pago </h5>
                                                            <p className="font-acumin-variable text-muted">{nombreResponsablePago}</p>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-6 col-xl-4 mb-3">
                                                            <h5 className="font-acumin-variable text-royal-blue fw-normal">Código del responsable de pago </h5>
                                                            <p className="font-acumin-variable text-muted">{codigoResponsablePago}</p>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-6 col-xl-4 mb-3">
                                                            <h5 className="font-acumin-variable text-royal-blue fw-normal">Total a cobrar &#40;HNL&#41;:</h5>
                                                            <p className="font-acumin-variable text-muted"> HNL {montoTotalStr}</p>
                                                        </div>

                                                        {ingreseValor &&
                                                            <div className="col-12 mb-3 ">
                                                                <p className="font-acumin-variable text-royal-blue text-danger fs-4">{ingreseValor}</p>
                                                            </div>
                                                        }
                                                    </div>
                                                    <TablaConceptosCobroAutomaticos datos={conceptos} useValidate={useConceptosCobroAutomaticos} getRowData={(e) => updateMontoObjetivo(e)} textoBoton={"Abrir solicitud"} textTitle="Click para ver la información" isAutorizador={true} />


                                                    <div className="col-12 col-md-12 d-flex justify-content-end mb-3 px-3 mt-5">
                                                        <div className="flex-fill justify-content-start">
                                                            <button type="button" onClick={goBack} className="btn ms-2 background-royal-blue text-white bg-hover-gold">Regresar</button>
                                                        </div>
                                                        <button type="button" onClick={showRejectField} className="btn ms-2 background-royal-blue text-white bg-hover-red">{buttonRejectText}</button>
                                                        {!buttonReject &&
                                                            <button type="button" data-bs-target="#confirmAthorizeAviso" data-bs-toggle="modal" className="btn  ms-2 background-royal-blue text-white bg-hover-gold">Autorizar</button>
                                                        }
                                                    </div>

                                                    {isReject &&
                                                        <>
                                                            <div className="col-12 col-md-12 mb-3 px-3">
                                                                <div className="form-floating ">
                                                                    <textarea title="Motivo de rechazo" onChange={(e) => setReasonReject(e.target.value)} className="form-control fs-5 text-royal-blue rejectionReason" name="motivoRechazo" type="text" placeholder="" required ></textarea>
                                                                    <label className="text-start font-acumin-variable" htmlFor="motivoRechazo">Motivo del rechazo</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 d-flex justify-content-end mb-3 px-3">
                                                                <button type="button" data-bs-target="#confirmRejectAviso" data-bs-toggle="modal" className="btn background-royal-blue text-white bg-hover-gold">Confirmar rechazo</button>
                                                            </div>
                                                        </>
                                                    }

                                                    {/*Modal to confirm authorization*/}
                                                    <ModalConfirmAction confirmFunction={authorizeAviso} idModal="confirmAthorizeAviso" textModal="¿Está seguro que desea autorizar la factura? Esta acción no se puede deshacer" />


                                                    {/*Modal to confirm rejection*/}
                                                    <ModalConfirmAction confirmFunction={rejectAviso} idModal="confirmRejectAviso" textModal="¿Está seguro que desea rechazar la factura? Esta acción no se puede deshacer" />

                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}





