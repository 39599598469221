import { instance } from '../apiGlobal';

export async function hacerTransferenciaLibrePago(obj) {
      try {
        const response = await instance(`/tlp/ejecutar`, {
          method:"post",
          data:obj
        })
        return response
      } catch (error) {
        return error
      }
}