import { useMemo } from "react";

export default function useOperacionesForzadasNoAsociadas () {
  const columns = useMemo(
    () => [
      {
        Header: "Folio de operación",
        accessor: "folio"
      },
      {
        Header: "ISIN",
        accessor: "isin"
      },
      {
        Header: "Valores",
        accessor: "numeroValoresStr"
      },
      {
        Header: "Moneda de liquidación",
        accessor: "monedaSerieStr"
      },
      {
        Header: "Tipo de Operación",
        accessor: "tipoStr"
      }            
    ],
    []
  );

  return columns;
}
