import React from "react";
import { Modal } from "react-bootstrap";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";


//APIs
import { consultarIsinDepositoValores } from "../../../api/mantenimientoCargaValores/consultarIsinDepositoValores";
import { consultarDepositanteDepositoValores } from "../../../api/mantenimientoCargaValores/consultarDepositanteDepositoValores";
import { depositarRetirarValores } from "../../../api/mantenimientoCargaValores/depositarValores";


export default function DepositarRetirarValores({ history }) {


    //const [rtn, setRtn] = React.useState()
    //const [numeroCuentaNacional, setNumeroCuentaNacional] = React.useState()
    //const [numeroCuentaExtranjera, setNumerocuentaExtranjera] = React.useState()



    //const [spinSend, setSpinSend] = React.useState(false)
    const [erroreshttp, setErroreshttp] = React.useState()
    // const [correoContacto, setCorreoContacto] = React.useState()


    //const [errorCodigo, setErrorCodigo] = React.useState()
    const [mensaje, setMensaje] = React.useState()

    const [datosSerie, setDatosSerie] = React.useState();
    const [datosDepositante, setDatosDepositante] = React.useState();
    const [codigoIsin, setCodigoIsin] = React.useState()
    const [numeroCertificacion, setNumeroCertificacion] = React.useState()
    const [codigoDepositante, setCodigoDepositante] = React.useState()
    const [cantidadInstrumentos, setCantidadInstrumentos] = React.useState()
    const [retiroDenegado, setRetiroDenegado] = React.useState()
    const [depositoDenegado, setDepositoDenegado] = React.useState()
    const [errorSinDatos, setErrorSinDatos] = React.useState()
    const [dobleConfirmacionDeposito, setDobleConfirmacionDeposito] = React.useState()
    const [dobleConfirmacionRetiro, setDobleConfirmacionRetiro] = React.useState()
    const [envioExitoso, setEnvioExitoso] = React.useState()
    const [envioFallido, setEnvioFallido] = React.useState()
    const [sinIsin, setSinIsin] = React.useState()
    const [isinInvalido, setIsinInvalido] = React.useState()
    const [codigoDepositanteInvalido, setCodigoDepositanteInvalido] = React.useState()
    const [loading, setLoading] = React.useState(false);



    //const [fechaLimiteDocumentos, setFechaLimiteDocumentos] = React.useState()

    /*  const objtoPrueba1 = {
          codigoSerie: "CHVEM-0001-001-001",
          serie: "A",
          nombreEmision: "Bonoso bonos",
          codigoEmision: "CHEVEM-0001-001",
          formaRepresentacion: "Desmaterializada",
          cantidadInstrumentos: 20,
          cantidadInstrumentosFueraCehval: 20,
          valorNominal: "$40,000.00",
      }
  
      const objtoPrueba2 = {
          codigoDepositante: "DDIRPJ-001",
          tipoDepositante: "Depositante directo",
          rtn: "43434232233444232",
          nombreDepositante: "barclays",
          saldoEnValores: 2,
          nacionalidad: "Estadounidense"
      }*/

    const onSubmitForm = async (e) => {
        e.preventDefault();
        //var hoy = new Date();
        //var hora = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
        ////console.log(lugarRegistro)
        const myObj = {

        }

        //console.log(myObj)
        /* const enviarDatos = await enviarRegistroEmisor(myObj)
         //console.log(enviarDatos)
         if (enviarDatos) {
             setSpinSend(false)
             if ((isNaN(enviarDatos.error)) === false) {
                 // respuestaincorrecta           
                 setErroreshttp(enviarDatos)
             } else {
 
                 if (enviarDatos?.codigo === "000") {
                     setRegistroExitoso(true)
 
                 } else {
                     setMensaje(enviarDatos.mensaje)
                     setRegistroFallido(true)
                 }
             }
         }*/
        ////console.log(enviarDatos)


    }

    const checarNumeros = (e) => {
        const { value, name } = e.target;
        let out = '';
        var filtro = '1234567890';


        for (var i = 0; i < value.length; i++)
            if (filtro.indexOf(value.charAt(i)) !== -1)
                //Se añaden a la salida los caracteres validos
                out += value.charAt(i);

        setCantidadInstrumentos(out)


    }

    React.useEffect(() => {
        //console.log(datosDepositante)
    }, [datosDepositante])


    const mandarCodigoIsin = async (e) => {
        //console.log(e.length)
        if (e.length === 12) {
            setLoading(true)
            const response = await consultarIsinDepositoValores({ codigoIsin: e })
            //console.log(response)
            setLoading(false)
            if (response) {
                if ((isNaN(response.error)) === false) {
                    // //console.log("erores")
                    ////console.log(response)
                    setErroreshttp(response)
                    
                } else {
                    if (response.cantidadInstrumentos) {
                        setDatosSerie(response)
                    } else {
                        setIsinInvalido(true)
                        if (datosSerie) {
                            const objto1 = {
                                codigoSerie: "",
                                serie: "",
                                nombreEmision: "",
                                codigoEmision: "",
                                formaRepresentacion: "",
                                cantidadInstrumentos: "",
                                cantidadInstrumentosFueraCehval: "",
                                valorNominal: "",
                            }
                            setCodigoIsin("")
                            setDatosSerie(objto1)
                        }
                    }
                }
            }
        }
    }

    const mandarCodigoDepositante = async (e) => {
        //console.log(e.length)
        if (e.length === 10) {

            if (codigoIsin) {
                setLoading(true)
                const myObj = {
                    codigoDepositante: e,
                    codigoIsin: codigoIsin
                }
                const response = await consultarDepositanteDepositoValores(myObj)
                setLoading(false)
                //console.log(response)
                if (response) {
                    if ((isNaN(response.error)) === false) {
                        // //console.log("erores")
                        ////console.log(response)
                        setErroreshttp(response)
                        
                    } else {
                        if (response === " ") {
                            //console.log("sindatos")
                        } else {
                            //console.log("condatos")
                        }
                        setDatosDepositante(response)
                        // //console.log(traerInformacion)
                        //setSectores(catalogosSectores)
                    }
                }
            } else {
                setCodigoDepositante("")
                setSinIsin(true)
            }

        }
    }


    const irInicio = () => {
        const objto1 = {
            codigoSerie: "",
            serie: "",
            nombreEmision: "",
            codigoEmision: "",
            formaRepresentacion: "",
            cantidadInstrumentos: "",
            cantidadInstrumentosFueraCehval: "",
            valorNominal: "",
        }

        const objto2 = {
            codigoDepositante: "",
            tipoDepositante: "",
            rtn: "",
            nombreInstitucion: "",
            saldoValores: "",
            nacionalidad: ""
        }

        setEnvioExitoso(false)
        setEnvioFallido(false)
        setCodigoIsin("")
        setDatosSerie(objto1)
        setNumeroCertificacion("")
        setCodigoDepositante("")
        setDatosDepositante(objto2)
        setCantidadInstrumentos("")
        //history.go(-1)
    }

    const depositar = () => {
       // //console.log(cantidadInstrumentos)
      //  //console.log(datosSerie.cantidadInstrumentosFueraCehval)

        if (codigoIsin && cantidadInstrumentos) {
            if (cantidadInstrumentos <= datosSerie.cantidadInstrumentosFueraCehval) {
                setDobleConfirmacionDeposito(true)
            } else {
                setDepositoDenegado(true)
              
            }
        } else {
            setErrorSinDatos(true)
        }

    }

    const retirar = () => {
        if (codigoIsin && cantidadInstrumentos) {
           
            if (cantidadInstrumentos <= datosDepositante.saldoValores) {
                setDobleConfirmacionRetiro(true)
            } else {
                setRetiroDenegado(true)
            }
        } else {
            setErrorSinDatos(true)
        }
    }

    const enviarDepositoRetiro = async (condicion) => {
        setLoading(true)
        var deposito;
        var retiro;
        //uno es deposito 2 es retiro
        if (condicion === 1) {
            deposito = true
            retiro = false
        } else {
            deposito = false
            retiro = true
        }

        const myObj = {
            codigoIsin: codigoIsin,
            codigoSerie: datosSerie?.codigoSerie,
            numeroCertificado: numeroCertificacion,
            serie: datosSerie?.serie,
            nombreEmision: datosSerie?.nombreEmision,
            codigoEmision: datosSerie?.codigoEmision,
            formaRepresentacion: datosSerie?.formaRepresentacion,
            cantidadInstrumentos: datosSerie?.cantidadInstrumentos,
            cantidadInstrumentosFueraCehval: datosSerie?.cantidadInstrumentosFueraCehval,
            valorNominalStr: datosSerie?.valorNominalStr,
            valorNominal: datosSerie?.valorNominal,


            //Datos del depositante
            codigoDepositante: codigoDepositante,
            tipoDepositante: datosDepositante?.tipoDepositante,
            rtn: datosDepositante?.rtn,
            nombreInstitucion: datosDepositante?.nombreInstitucion,
            saldoValores: datosDepositante?.saldoValores,
            nacionalidad: datosDepositante?.nacionalidad,

            //Datos valores
            cantidadValores: cantidadInstrumentos,
            tipoSolicitud: "",

            //Botones
            deposito: deposito,
            retiro: retiro
        }

        setDobleConfirmacionDeposito(false)
        setDobleConfirmacionRetiro(false)
        var response;
        //deposito es 1 y retiro 2
        if (condicion === 1) {
            //envio de deposito 
            response = await depositarRetirarValores(myObj)
            //console.log(response)
            setLoading(false)
            if (response) {
                if ((isNaN(response.error)) === false) {
                    // //console.log("erores")
                    ////console.log(response)
                    setErroreshttp(response)
                    
                } else {
                    if (response.codigo === "000") {
                        setMensaje("Solicitud de depósito de valores enviada")
                        setEnvioExitoso(true)
                    } else {
                        setMensaje("No se ha podido enviar la solicitud de depósito")
                        setEnvioFallido(true)
                    }
                }
            }
        } else {
            //envio de retiro
            response = await depositarRetirarValores(myObj)
            //console.log(response)
            setLoading(false)
            if (response) {
                if ((isNaN(response.error)) === false) {
                    // //console.log("erores")
                    ////console.log(response)
                    setErroreshttp(response)
                    
                } else {
                    if (response.codigo === "000") {
                        setMensaje("Solicitud de retiro de valores enviada")
                        setEnvioExitoso(true)
                    } else {
                        setMensaje("No se ha podido enviar la solicitud de retiro")
                        setEnvioFallido(true)
                    }
                }
            }
        }

    }

    return (
        <div className="fluid-container">
            {loading &&
                <PantallaCargando />
            }
            <Modal show={dobleConfirmacionDeposito} animation={false} centered >
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => setDobleConfirmacionDeposito(false)} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >¿Está seguro que desea depositar {cantidadInstrumentos} valores de la serie "{datosSerie?.serie}" de la emisión {datosSerie?.nombreEmision} a la cuenta del cliente {datosDepositante?.nombreInstitucion}?</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => setDobleConfirmacionDeposito(false)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Cancelar</button>
                        <button onClick={() => enviarDepositoRetiro(1)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Aceptar</button>
                    </div>
                </div>
            </Modal>

            <Modal show={dobleConfirmacionRetiro} animation={false} centered >
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => setDobleConfirmacionRetiro(false)} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >¿Está seguro que desea retirar {cantidadInstrumentos} valores de la serie "{datosSerie?.serie}" de la emisión {datosSerie?.nombreEmision} de la cuenta del cliente {datosDepositante?.nombreInstitucion}?</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => setDobleConfirmacionRetiro(false)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Cancelar</button>
                        <button onClick={() => enviarDepositoRetiro(2)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Aceptar</button>
                    </div>
                </div>
            </Modal>

            {isinInvalido &&
                <ComponenteModal mostrarModal={isinInvalido} cerrarModal={() => { setIsinInvalido(false) }} titulo="Código ISIN inválido" textoBoton="Entendido"></ComponenteModal>
            }

            {sinIsin &&
                <ComponenteModal mostrarModal={sinIsin} cerrarModal={() => { setSinIsin(false) }} titulo="Ingrese primero el código ISIN" textoBoton="Entendido"></ComponenteModal>
            }

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {envioExitoso &&
                <ComponenteModal mostrarModal={envioExitoso} cerrarModal={irInicio} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {envioFallido &&
                <ComponenteModal mostrarModal={envioFallido} cerrarModal={() => { setEnvioFallido(false) }} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {retiroDenegado &&
                <ComponenteModal mostrarModal={retiroDenegado} cerrarModal={() => { setRetiroDenegado(false) }} titulo="No puede retirar valores, la cantidad de valores en la cuenta del depositante es menor a la ingresada" textoBoton="Entendido"></ComponenteModal>
            }

            {depositoDenegado &&
                <ComponenteModal mostrarModal={depositoDenegado} cerrarModal={() => { setDepositoDenegado(false) }} titulo="No puede depositar valores, la cantidad de valores ingresada es mayor a la cantidad que se encuentra fuera de CEHVAL" textoBoton="Entendido"></ComponenteModal>
            }

            {errorSinDatos &&
                <ComponenteModal mostrarModal={errorSinDatos} cerrarModal={() => { setErrorSinDatos(false) }} titulo="Necesita llenar los campos de código ISIN y código de depositante" textoBoton="Entendido"></ComponenteModal>
            }




            <main className='container-min-height' >
                <div className="row row-wrap g-0">

                    


                    <div className="col-12">
                        <div className="">
                            <BannerTitulo titulo="Depositar o retirar valores"></BannerTitulo>
                            <div className="row d-flex">

                                <div className="col-12">
                                    <div className="mb-5">
                                        <div className="card-body ">
                                            <div className="d-grid gap-1 col-10 mx-auto">
                                                <form className="row g-3 needs-validation" novalidate onSubmit={(e) => {
                                                    onSubmitForm(e)

                                                }}>
                                                    <div className="card" style={{ color: "#242848" }}>
                                                        <div className="card-body">
                                                            <h5 className="mt-3 text-royal-blue font-acumin-variable">Datos de la serie</h5>

                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control textoFormularioDepositantedirecto" value={codigoIsin} onChange={(e) => {
                                                                            setCodigoIsin(e.target.value)
                                                                            mandarCodigoIsin(e.target.value)
                                                                        }} id="isin" name="isin" placeholder="" maxLength={13} required />
                                                                        <label htmlFor="cehvalDepositanteNombreConf" className=" textoFormularioDepositantedirecto">Código ISIN *</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input  type="text" className="form-control " disabled value={datosSerie?.codigoSerie} id="codigoSerie" name="codigoSerie" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Código serie</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-lg-3 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control " value={numeroCertificacion} onChange={(e) => setNumeroCertificacion(e.target.value)} id="numeroCertificacion" name="numeroCertificacion" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Número de certificado</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-lg-1 mb-3">
                                                                    <div className="form-floating">
                                                                        <input  disabled type="text" className="form-control " value={datosSerie?.serie} id="serie" name="serie" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Serie</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input  type="text" className="form-control " disabled value={datosSerie?.nombreEmision} id="nombreEmision" name="nombreEmision" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Nombre de emisión</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control " disabled value={datosSerie?.codigoEmision} id="codigoEmision" name="codigoEmision" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Código de emisión</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control " disabled value={datosSerie?.formaRepresentacion} id="formaPresentacion" name="formaPresentacion" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Forma de representación</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control " disabled value={datosSerie?.cantidadInstrumentos} id="cantidadInstrumentos" name="cantidadInstrumentos" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Cantidad de instrumentos emitidos</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control " disabled value={datosSerie?.cantidadInstrumentosFueraCehval} id="cantidadInstrumentosFueraCehval" name="cantidadInstrumentosFueraCehval" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Cantidad de instrumentos fuera de CEHVAL</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control " disabled value={datosSerie?.valorNominal} id="valorNominal" name="valornominal" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Valor nominal de cada titulo</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <h5 className="mt-3 text-royal-blue font-acumin-variable">Datos del depositante</h5>
                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input placeholder="" type="text" className="form-control" value={codigoDepositante} onChange={(e) => {
                                                                            setCodigoDepositante(e.target.value)
                                                                            mandarCodigoDepositante(e.target.value)
                                                                        }} id="codigoDepositante" name="codigoDepositante" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Código depositante</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control " disabled value={datosDepositante?.tipoDepositante} id="tipoDepositante" name="tipoDepositante" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Tipo de depositante</label>
                                                                    </div>
                                                                </div>
                                                                {datosDepositante ?
                                                                    <>
                                                                        {datosDepositante?.rtn ?
                                                                            <div className="col-md-12 col-lg-4 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control " disabled value={datosDepositante?.rtn} id="rtn" name="rtn" required />
                                                                                    <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">RTN</label>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <>
                                                                                {codigoDepositante ?
                                                                                    <div className="col-md-12 col-lg-4 mb-3">
                                                                                        <div className="form-floating">
                                                                                            <input type="text" className="form-control " disabled value="Sin RTN" id="rtn" name="rtn" required />
                                                                                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">RTN</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    :

                                                                                    <div className="col-md-12 col-lg-4 mb-3">
                                                                                        <div className="form-floating">
                                                                                            <input type="text" className="form-control " disabled value={datosDepositante?.rtn} id="rtn" name="rtn" required />
                                                                                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">RTN</label>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </>

                                                                        }
                                                                    </>
                                                                    :
                                                                    <div className="col-md-12 col-lg-4 mb-3">
                                                                        <div className="form-floating">
                                                                            <input type="text" className="form-control " disabled value={datosDepositante?.rtn} id="rtn" name="rtn" required />
                                                                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">RTN</label>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control " disabled value={datosDepositante?.nombreInstitucion} id="nombreDepositante" name="nombreDepositante" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Nombre depositante</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control " disabled value={datosDepositante?.saldoValores} id="saldoEnValores" name="saldoEnValores" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Saldo en valores</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control " disabled value={datosDepositante?.nacionalidad} id="nacionalidad" name="nacionalidad" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Nacionalidad</label>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <h5 className="mt-3 text-royal-blue font-acumin-variable">Datos de los valores</h5>
                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <input placeholder="" type="text" className="form-control" value={cantidadInstrumentos} onChange={(e) => setCantidadInstrumentos(e.target.value)} onKeyUp={(e) => checarNumeros(e)} id="cantidadInstrumentos" name="cantidadInstrumentos" required />
                                                                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Cantidad de instrumentos</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row mb-3">
                                                                <div className="col-md-12 col-lg-4 mb-3">
                                                                    <div className="d-flex  font-acumin-variable">
                                                                        <button title="Depositar valores" type='button' onClick={depositar} className="me-auto p-2 col-8 col-sm-4  col-md-4 col-lg-4 col-xl-4 col-xxl-4 btn background-royal-blue bg-hover-gold text-white  mb-2" >Depositar</button>
                                                                        <button title="Retirar valores" type='button' onClick={retirar} className="p-2 col-8 col-sm-4  col-md-4 col-lg-4 col-xl-4 col-xxl-4 btn background-royal-blue bg-hover-gold text-white  mb-2" >Retirar</button>
                                                                    </div>
                                                                </div>

                                                                {/*}  <div className="col-md-12 col-lg-8 mb-3">
                                                                    <div className="d-flex justify-content-end font-acumin-variable">
                                                                        <button title="Enviar" type='submit' className="col-8 col-sm-4  col-md-4 col-lg-4 col-xl-4 col-xxl-4 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Regresar</button>
                                                                    </div>
                                                                </div>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            </main>

        </div>
    );


}

