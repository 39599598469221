import React from "react";
import '../styles/stylesheet.css'


export default function SpinnerStart({textoSpinner}) {

    return (
        <div className="d-flex  mt-5 main-min-height">
            <div className="container py-5  mt-5">
                <div className="text-center">
                    <div className="spinner-border" role="status" style={{width:"3rem",height:"3rem"}}>
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p className="font-acumin-variable">{textoSpinner}</p>
                </div>
            </div>
        </div>
    );
}
