import React from "react";

//Componentes
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

import useFondosRemanentes from "../../../hooks/MercadoPrimario/useFondosRemanentes";
import useLiquidarSubastaString from "../../../hooks/MercadoPrimario/useLiquidarSubastaString";

import { enviarNotificacionFondosLiquidados } from "../../../api/Mercadoprimario/enviarNotificacionFondosLiquidados";
import { enviarNotificacionFondosLiquidadosEmisor } from "../../../api/Mercadoprimario/enviarNotificacionFondosLiquidadosEmisor";
import { imprimirTablaLiquidacionSubastas } from "../../../api/Mercadoprimario/imprimirTablaLiquidacionSubastas";
import { confirmarLiquidacionSubasta } from "../../../api/Mercadoprimario/confirmarLiquidacionSubasta";

export default function ComponenteInformacionSubasta({ funcion1, textoFuncion1, funcion2, textoFuncion2, subastaData, irDevuelta, variableString, botonExtra, botonEmisor, textBoton, history }) {
    const [objetoSubasta, setObjetoSubasta] = React.useState(subastaData)
    const [actualizarTabla, setActualizarTabla] = React.useState(true)
    const [actualizarTabla2, setActualizarTabla2] = React.useState()
    const [actualizarTabla3, setActualizarTabla3] = React.useState()
    const [nuevaInfoRemanentes, setNuevaInfoRemanentes] = React.useState()
    const [notificacionFondosEnviada, setNotificacionFondosEnviada] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [infoNuevaEmisor, setInfoNuevaEmisor] = React.useState()
    const [infoNuevaEmisor2, setInfoNuevaEmisor2] = React.useState()
    const [mensaje, setMensaje] = React.useState()
    const [validarBotonConfirmar, setValidarBotonConfirmar] = React.useState()
    const [confirmarDesabilitado, setConfirmarDesabilitado] = React.useState()
    const [confirmacionExitosa, setConfirmacionExitosa] = React.useState(true);
    const [confirmacionExitosaEstatusSubasta, setConfirmacionExitosaEstatusSubasta] = React.useState();
    const [confirmacionExitosaModal, setConfirmacionExitosaModal] = React.useState()
    const [validacionEstadoSubasta, setValidacionEstadoSubasta] = React.useState()
    const [loading, setLoading] = React.useState(false);


    React.useEffect(() => {
        var variable1;
        var variable2;
        let contador=0;

        if (objetoSubasta.estatusLiquidacionEmisor === "Liquidado") {
            variable1 = true;
        } else {
            variable1 = false;
        }

        objetoSubasta.remanentes.map((item, index) => {
            if(!item.estatus){
                contador++;
            }
   
        })

        if(contador>0){
           variable2=false;
        }else{
            variable2=true;
        }
        

        if (variable1 && variable2) {
            setValidarBotonConfirmar(true)
            //console.log("valido")

        } else {
            setValidarBotonConfirmar(false)
            //console.log("invalido")
        }


    }, [objetoSubasta]);


    const imprimir = async (row) => {
        //console.log("iiimpresion")
        const myobj =
            { codigoSubasta: objetoSubasta.numeroSubasta, moneda: objetoSubasta.moneda }

        //console.log(myobj)
        setLoading(true)


        const response = await imprimirTablaLiquidacionSubastas(myobj)
        if (response) {
            //setLoading(false)
            if ((isNaN(response.error)) === false) {
                setLoading(false)
                // respuestaincorrecta           
                setErroreshttp(response)
            } else {

                //console.log(response)
                const file = new Blob(
                    [response],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
                setLoading(false)
            }
        }


    }

    const accionMercadoPrimario = async (row) => {
        if (objetoSubasta.estatusSubasta === 3) {
            setValidacionEstadoSubasta(true)
        } else {
            setLoading(true)
            //  //console.log(row.original)
            var estatusSub;
            if (row.original.estatus === 0) {
                estatusSub = 1;
                setMensaje("Fondos liquidados")
            } else {
                setMensaje("Fondos sin liquidar")
                estatusSub = 0
            }

            const Myobj = {
                codigoDepositanteCliente: row.original.codigoDepositanteCliente,
                codigoSubasta: objetoSubasta.numeroSubasta,
                moneda: row.original.moneda,
                estatus: estatusSub

            }
            //console.log(Myobj)

            const mandarLiquidacionFondos = await enviarNotificacionFondosLiquidados(Myobj)
            //console.log(mandarLiquidacionFondos)
            if (mandarLiquidacionFondos) {
                setLoading(false)
                if ((isNaN(mandarLiquidacionFondos.error)) === false) {
                    setErroreshttp(mandarLiquidacionFondos)
                } else {
                    setObjetoSubasta(mandarLiquidacionFondos)
                    //setNuevaInfoRemanentes(mandarLiquidacionFondos)
                    // setNotificacionFondosEnviada(true)

                    if (actualizarTabla2) {
                        setActualizarTabla(false)
                        setActualizarTabla2(false)
                        setActualizarTabla3(true)

                    } else {
                        setActualizarTabla(false)
                        setActualizarTabla2(true)
                        setActualizarTabla3(false)
                    }


                }
            }
        }


    }



    const funcionEmisor = async () => {

        ////console.log(objetoSubasta.montoTotalAPagarEstatus)
        if (objetoSubasta.estatusSubasta === 3) {
            setValidacionEstadoSubasta(true)
        } else {

            setLoading(true)
            var montoTotalaMandar;

            if (objetoSubasta.montoTotalAPagarEstatus === 0) {
                setMensaje("Pago al emisor liquidado")
                montoTotalaMandar = 1;
            } else {
                setMensaje("Pago al emisor sin liquidar")
                montoTotalaMandar = 0;
            }

            const myObj = {
                codigoSubasta: objetoSubasta.numeroSubasta,
                moneda: objetoSubasta.moneda,
                montoTotalAPagarEstatus: montoTotalaMandar
            }

            const mandarNotificacionEmisor = await enviarNotificacionFondosLiquidadosEmisor(myObj)
            //console.log(mandarNotificacionEmisor)
            if (mandarNotificacionEmisor) {
                setLoading(false)
                if ((isNaN(mandarNotificacionEmisor.error)) === false) {
                    setErroreshttp(mandarNotificacionEmisor)
                } else {
                    setObjetoSubasta(mandarNotificacionEmisor)

                    if (infoNuevaEmisor) {
                        setInfoNuevaEmisor(false)
                        setInfoNuevaEmisor2(true)
                    } else {
                        setInfoNuevaEmisor(true)
                        setInfoNuevaEmisor2(false)
                    }
                    setNuevaInfoRemanentes(mandarNotificacionEmisor)
                    //setNotificacionFondosEnviada(true)
                }
            }
        }
    }

    const confirmar = async () => {

        if (objetoSubasta.estatusSubasta === 3) {
            setValidacionEstadoSubasta(true)
        } else {

            if (validarBotonConfirmar) {

                console.log("entro a la validacion")

                setLoading(true)
                const myObj = {
                    codigoSubasta: objetoSubasta.numeroSubasta,
                    moneda: objetoSubasta.moneda,
                    //montoTotalAPagarEstatus: montoTotalaMandar
                }

                const mandarConfirmacionSubasta = await confirmarLiquidacionSubasta(myObj)
                //console.log(mandarConfirmacionSubasta)
                if (mandarConfirmacionSubasta) {
                    setLoading(false)
                    if ((isNaN(mandarConfirmacionSubasta.error)) === false) {
                        setErroreshttp(mandarConfirmacionSubasta)
                    } else {

                        //console.log("confirmando")
                        setObjetoSubasta(mandarConfirmacionSubasta)
                        setConfirmacionExitosaModal(true)
                        setConfirmacionExitosa(false)
                        setConfirmacionExitosaEstatusSubasta(true)

                    }
                }



            } else {
                setConfirmarDesabilitado(true)
            }
        }
    }

    return (
        <>
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {loading &&
                <PantallaCargando />
            }

            {notificacionFondosEnviada &&
                <ComponenteModal mostrarModal={notificacionFondosEnviada} cerrarModal={() => setNotificacionFondosEnviada(false)} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {confirmarDesabilitado &&
                <ComponenteModal mostrarModal={confirmarDesabilitado} cerrarModal={() => setConfirmarDesabilitado(false)} titulo="No se puede confirmar sin haber liquidado todo" textoBoton="Entendido"></ComponenteModal>
            }

            {confirmacionExitosaModal &&
                <ComponenteModal mostrarModal={confirmacionExitosaModal} cerrarModal={() => { history.go() }} titulo="Fondos liquidados" textoBoton="Entendido"></ComponenteModal>
            }

            {validacionEstadoSubasta &&
                <ComponenteModal mostrarModal={validacionEstadoSubasta} cerrarModal={() => setValidacionEstadoSubasta(false)} titulo="No se puede modificar ya que la subasta ya esta liquidada" textoBoton="Entendido"></ComponenteModal>
            }

            <BannerTitulo titulo="Liquidación de la subasta"></BannerTitulo>
            <div className="container py-5 w-100">
                <div className="font-acumin-variable ms-3">
                    <h4>Subasta</h4>

                    <div className="row">
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Número de subasta" value={objetoSubasta.numeroSubasta} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Número de subasta</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Fecha de subasta" value={objetoSubasta.fechaSubasta} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Fecha de subasta</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    {confirmacionExitosa &&
                                        <input title="Estatus de la subasta" value={objetoSubasta.estatusSubastaStr} className="form-control text-uppercase" type="text" name="estatusSubasta" placeholder="Fecha de emisión" required disabled />
                                    }
                                    
                                    {confirmacionExitosaEstatusSubasta &&
                                        <input title="Estatus de la subasta" value={objetoSubasta.estatusSubastaStr} className="form-control text-uppercase" type="text" name="estatusSubasta" placeholder="Fecha de emisión" required disabled />
                                    }

                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Estatus de la subasta</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="font-acumin-variable ms-3">
                    <h4>Emisión</h4>

                    <div className="row">
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Código de emisión" value={objetoSubasta.codigoEmision} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Código de emisión</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Nombre de emisor" value={objetoSubasta.nombreEmisor} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Nombre de emisor</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Cuenta del emisor" value={objetoSubasta.cuentaEmisor} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Cuenta del emisor</label>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="font-acumin-variable ms-3">
                    <h4>Serie</h4>

                    <div className="row">
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Código de serie" value={objetoSubasta.codigoSerie} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Código de serie</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Serie" value={objetoSubasta.nombreSerie} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Serie</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Número ISIN" value={objetoSubasta.isin} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">ISIN</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Cantidad de instrumentos" value={objetoSubasta.cantidadInstrumentosSerie} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Cantidad de instrumentos</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Moneda de liquidación" value={objetoSubasta.monedaLiquidacionSerie} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Moneda de liquidación</label>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="font-acumin-variable ms-3">
                    <h4>Pago al emisor</h4>

                    <div className="row">
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Monto total apagar" value={objetoSubasta.montoTotalAPagarStr} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    {/*{objetoSubasta.montoTotalAPagar === 0 ?
                                        <input title="Fecha de emisión" value={objetoSubasta.montoTotalAPagarStr} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                        :
                                        <input title="Fecha de emisión" value={objetoSubasta.montoTotalAPagar} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    }*/}

                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Monto total a pagar</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    {!infoNuevaEmisor2 && !infoNuevaEmisor &&
                                        <input title="Estatus" value={objetoSubasta.estatusLiquidacionEmisor} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    }
                                    {infoNuevaEmisor && !infoNuevaEmisor2 &&
                                        <input title="Estatus" value={objetoSubasta.estatusLiquidacionEmisor} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />

                                    }
                                    {infoNuevaEmisor2 && !infoNuevaEmisor &&

                                        <input title="Estatus" value={objetoSubasta.estatusLiquidacionEmisor} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />

                                    }
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Estatus</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Cuenta del emisor" value={objetoSubasta.cuentaEmisor} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Cuenta del emisor</label>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Tipo de cuenta" value={objetoSubasta.tipoCuentaEmisor} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Tipo de cuenta del emisor</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Banco del emisor" value={objetoSubasta.bancoEmisor} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Banco del emisor</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {botonEmisor && <div className="col-md-4 ms-3">
                        <div className=" mb-3">
                            <button className="btn botonFormularioDepositante" title="click para notificar de liquidado o sin liquidar" type="button" onClick={() => funcionEmisor()}>Liquidado/Regresar</button>
                        </div>
                    </div>}
                </div>

                <h4 className="font-acumin-variable ms-3 mt-5">Ofertas</h4>
                {/*} {variableString ?
                    
                    :
                    <TablaMercado datos={objetoSubasta.ofertas} useValidate={useLiquidarSubasta} ></TablaMercado>
                }*/}

                {actualizarTabla &&

                    <TablaMercado datos={objetoSubasta.ofertas} useValidate={useLiquidarSubastaString} ></TablaMercado>
                }
                {actualizarTabla2 &&

                    <TablaMercado datos={objetoSubasta.ofertas} useValidate={useLiquidarSubastaString} ></TablaMercado>
                }
                {actualizarTabla3 &&

                    <TablaMercado datos={objetoSubasta.ofertas} useValidate={useLiquidarSubastaString} ></TablaMercado>
                }


                <h4 className="font-acumin-variable ms-3 mt-5">Fondos remanentes</h4>


                {/*Sis tiene  textoFuncion1 quiere decir que la funcion se ejecuta en el componente padre*/}
                {/*Si tiene boton extra quiere decir que habra un boton dentro de la tabla*/}
                {actualizarTabla &&
                    <>
                        {funcion1 ?
                            <>
                                {
                                    botonExtra ?
                                        <>
                                            {funcion2 ?
                                                <TablaMercado displayInformation={accionMercadoPrimario} textTitle={textBoton} textoBoton="Liquidado/Regresar" textoHeader="Acciones" datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={funcion1} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={funcion2} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                                :
                                                <TablaMercado displayInformation={accionMercadoPrimario} textTitle={textBoton} textoBoton="Liquidado/Regresar" textoHeader="Acciones" datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={funcion1} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={confirmar} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                            }
                                        </>
                                        :
                                        <>
                                            {funcion2 ?
                                                <TablaMercado datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={funcion1} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={funcion2} texto2MercadoPrimario={textoFuncion2} ></TablaMercado>
                                                :
                                                <TablaMercado datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={funcion1} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={confirmar} texto2MercadoPrimario={textoFuncion2} ></TablaMercado>
                                            }
                                        </>
                                }
                            </>
                            :
                            <>
                                {
                                    botonExtra ?
                                        <>
                                            {funcion2 ?
                                                <TablaMercado displayInformation={accionMercadoPrimario} textTitle={textBoton} textoBoton="Liquidado/Regresar" textoHeader="Acciones" datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={imprimir} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={funcion2} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                                :
                                                <TablaMercado displayInformation={accionMercadoPrimario} textTitle={textBoton} textoBoton="Liquidado/Regresar" textoHeader="Acciones" datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={imprimir} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={confirmar} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                            }
                                        </>

                                        :
                                        <>
                                            {funcion2 ?
                                                <TablaMercado datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={imprimir} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={funcion2} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                                :
                                                <TablaMercado datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={imprimir} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={confirmar} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                            }
                                        </>
                                }
                            </>
                        }
                    </>


                }

                {actualizarTabla2 &&
                    <>
                        {funcion1 ?
                            <>
                                {
                                    botonExtra ?
                                        <>
                                            {funcion2 ?
                                                <TablaMercado displayInformation={accionMercadoPrimario} textTitle={textBoton} textoBoton="Liquidado/Regresar" textoHeader="Acciones" datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={funcion1} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={funcion2} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                                :
                                                <TablaMercado displayInformation={accionMercadoPrimario} textTitle={textBoton} textoBoton="Liquidado/Regresar" textoHeader="Acciones" datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={funcion1} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={confirmar} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                            }
                                        </>
                                        :
                                        <>
                                            {funcion2 ?
                                                <TablaMercado datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={funcion1} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={funcion2} texto2MercadoPrimario={textoFuncion2} ></TablaMercado>
                                                :
                                                <TablaMercado datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={funcion1} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={confirmar} texto2MercadoPrimario={textoFuncion2} ></TablaMercado>
                                            }
                                        </>
                                }
                            </>
                            :
                            <>
                                {
                                    botonExtra ?
                                        <>
                                            {funcion2 ?
                                                <TablaMercado displayInformation={accionMercadoPrimario} textTitle={textBoton} textoBoton="Liquidado/Regresar" textoHeader="Acciones" datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={imprimir} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={funcion2} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                                :
                                                <TablaMercado displayInformation={accionMercadoPrimario} textTitle={textBoton} textoBoton="Liquidado/Regresar" textoHeader="Acciones" datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={imprimir} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={confirmar} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                            }
                                        </>

                                        :
                                        <>
                                            {funcion2 ?
                                                <TablaMercado datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={imprimir} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={funcion2} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                                :
                                                <TablaMercado datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={imprimir} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={confirmar} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                            }
                                        </>
                                }
                            </>
                        }
                    </>
                }

                {actualizarTabla3 &&
                    <>
                        {funcion1 ?
                            <>
                                {
                                    botonExtra ?
                                        <>
                                            {funcion2 ?
                                                <TablaMercado displayInformation={accionMercadoPrimario} textTitle={textBoton} textoBoton="Liquidado/Regresar" textoHeader="Acciones" datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={funcion1} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={funcion2} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                                :
                                                <TablaMercado displayInformation={accionMercadoPrimario} textTitle={textBoton} textoBoton="Liquidado/Regresar" textoHeader="Acciones" datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={funcion1} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={confirmar} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                            }
                                        </>
                                        :
                                        <>
                                            {funcion2 ?
                                                <TablaMercado datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={funcion1} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={funcion2} texto2MercadoPrimario={textoFuncion2} ></TablaMercado>
                                                :
                                                <TablaMercado datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={funcion1} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={confirmar} texto2MercadoPrimario={textoFuncion2} ></TablaMercado>
                                            }
                                        </>
                                }
                            </>
                            :
                            <>
                                {
                                    botonExtra ?
                                        <>
                                            {funcion2 ?
                                                <TablaMercado displayInformation={accionMercadoPrimario} textTitle={textBoton} textoBoton="Liquidado/Regresar" textoHeader="Acciones" datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={imprimir} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={funcion2} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                                :
                                                <TablaMercado displayInformation={accionMercadoPrimario} textTitle={textBoton} textoBoton="Liquidado/Regresar" textoHeader="Acciones" datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={imprimir} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={confirmar} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                            }
                                        </>

                                        :
                                        <>
                                            {funcion2 ?
                                                <TablaMercado datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={imprimir} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={funcion2} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                                :
                                                <TablaMercado datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={imprimir} texto1MercadoPrimario={textoFuncion1} funcion2MercadoPrimario={confirmar} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>
                                            }
                                        </>
                                }
                            </>
                        }
                    </>
                }




                <div className="col-12 row justify-content-star mt-3 ms-3">
                    <div className="col-6 mt-1 g-0">
                        <button title="Regresar a página anterior" onClick={irDevuelta} className="btn background-royal-blue bg-hover-gold text-white font-acumin-variable">Regresar</button>
                    </div>
                </div>
            </div>
        </>

    )
}