import React, { useState } from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { traerMonedas } from '../../../api/catalogos/traerMonedas';
import { traerDepositantePorCodigo } from "../../../api/Mercadoprimario/traerDepositantePorCodigo";
import { notificarDepositoDeFondos } from "../../../api/Mercadoprimario/notificarDepositoDeFondos";
import { consultarDepositanteMs } from "../../../api/MercadoSecundario/consultarDepositanteMs";


//Functions
import formatNumber from "../../../functions/FormatNumber";

export default function NotificarDepositoFondos({ history }) {

    const [erroreshttp, setErroreshttp] = React.useState()
    const [loading, setLoading] = React.useState(false);

    const [codigoDepositante, setCodigoDepositante] = React.useState()
    const [nombreDepositante, setNombreDepositante] = React.useState()
    const [folio, setFolio] = React.useState()
    const [moneda, setMoneda] = React.useState()
    const [monedas, setMonedas] = React.useState([])
    //Number to send to backend
    const [monto, setMonto] = React.useState()
    //Formated number to show in frontend
    const [montoFront, setMontoFront] = useState();
    const [numeroSubasta, setNumeroSubasta] = React.useState()
    const [procesoExitoso, setProcesoExitoso] = React.useState()
    const [procesoFallido, setProcesoFallido] = React.useState()
    const [mensaje, setMensaje] = React.useState()
    const [errorCodigoDepositante, setErrorCodigoDepositante] = React.useState()
    const [fechaActual, setFechaActual] = React.useState()
    const [fechaDeposito, setFechaDeposito] = React.useState()
    const [depositantNoExists, setDepositantNoExists] = useState();
    const [depositantExists, setDepositantExists] = useState();

    const [errorCodigoDep,setErrorCodigoDep]=React.useState();

    const codigoCasaBolsa=(JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo

    // const codigo = (JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo
    // const codigo = "DDIRPJ-001"

    React.useEffect(() => {
        traerDatos()
        establecerFecha()
    }, [])

    const establecerFecha = () => {
        const fecha = new Date()
        const añoActual = fecha.getFullYear();
        let diaActual = fecha.getDate();
        let mesActual = fecha.getMonth() + 1;
        if (diaActual < 10) {
            diaActual = "0" + diaActual
        }
        if (mesActual < 10) {
            mesActual = "0" + mesActual
        }
        const fecha_limite_documentos = (añoActual) + "-" + mesActual + "-" + (diaActual)
        setFechaActual(fecha_limite_documentos)
    }


    const traerDatos = async () => {

        const catalogoMonedas = await traerMonedas()
        //console.log(catalogoMonedas)
        if (catalogoMonedas) {
            if ((isNaN(catalogoMonedas.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(catalogoMonedas)
            } else {
                setMonedas(catalogoMonedas);
            }
        }


    }


    const onSubmitForm = async (e) => {
        e.preventDefault();

        if(depositantNoExists){
            setErrorCodigoDep(true)
  
          }else{
            setLoading(true)
            const objetoUsuario = {
                codigoDepositante: codigoDepositante,
                nombreDepositante: nombreDepositante,
                folioBchTr: folio,
                moneda: moneda,
                monto: monto,
                codigoSubasta: numeroSubasta,
                fechaDeposito: fechaDeposito
            }
    
    
            //console.log(objetoUsuario)
            const enviarNotificacion = await notificarDepositoDeFondos(objetoUsuario)
            //console.log(enviarNotificacion)
            if (enviarNotificacion) {
                //entra a qui si hay un error http
                if ((isNaN(enviarNotificacion.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(enviarNotificacion)
                } else {
                    //respuesta correcta
                    if (enviarNotificacion?.codigo === "000") {
                        setLoading(false)
                        setMensaje("Depósito de fondos notificado")
                        setProcesoExitoso(true)
                   
                    } else {
                        setLoading(false)
                        setMensaje(enviarNotificacion.mensaje)
                        setProcesoFallido(true)
    
    
                    }
                }
            }
          }

    }





    const checkLenght = (e) => {

        if (e) {
            if (e.length > 9) {
                getDepositantData(e)
               // traerDatosDep(e);
            }
        }

    }

   /* const traerDatosDep = async (e) => {

        //console.log(e)
        const infoDepositante = await traerDepositantePorCodigo({ codigo: e })
        //console.log(infoDepositante)
        if (infoDepositante) {
            if ((isNaN(infoDepositante.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(infoDepositante)
            } else {
                if (infoDepositante.cehvalDepositanteNombreCompleto) {
                    setNombreDepositante(infoDepositante.cehvalDepositanteNombreCompleto)
                    setErrorCodigoDepositante(false)
                } else {
                    setNombreDepositante("")
                    setErrorCodigoDepositante(true)
                }

            }
        }
    }*/

    const getDepositantData = async (e) => {
        setLoading(true);
        //console.log(e)
        //console.log(codigoCasaBolsa)
        const infoDepositante = await consultarDepositanteMs({ codigo: e, codigoCasaBolsa:codigoCasaBolsa })
        //console.log(infoDepositante)
        
        if (infoDepositante) {
            setLoading(false);
            if ((isNaN(infoDepositante.error)) === false) {
             
                // respuestaincorrecta           
                setErroreshttp(infoDepositante)
            } else {
                if (infoDepositante.mensajeError) {
                    setDepositantExists(false);
                    setDepositantNoExists(true);
                   

                } else {
                    setDepositantNoExists(false);
                    setDepositantExists(true);
                    //console.log(infoDepositante.depositante.cehvalDepositanteNombreCompleto)
                    setNombreDepositante(infoDepositante.depositante.cehvalDepositanteNombreCompleto)
                }

            }
        }
    }




    const cerrarModal = () => {
        setProcesoExitoso(false)
        setMoneda("")
        setFolio("")
        setMonto("")
        setMontoFront("")
        setNumeroSubasta("")
        setCodigoDepositante("")
        setNombreDepositante("")
        setFechaDeposito("")
        setDepositantExists("")
        setDepositantNoExists("")
        // history.go(-1)
    }


    const handleInputMonto = (e) => {

        const { value } = e.target;

        const formattedNumber = formatNumber(value);


        // Remove all characters other than digits or decimal points
        // const montoReplaced = value.replace(/[^\d.]/g, '');


        //Setted the amount for the backend
        setMonto(formattedNumber.integerNumber);
        setMontoFront(formattedNumber.stringNumber);

        //Format number for the frontend
        //formatNumber(montoReplaced, setMontoFront);
    }

   



    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {procesoExitoso &&
                <ComponenteModal mostrarModal={procesoExitoso} cerrarModal={cerrarModal} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {procesoFallido &&
                <ComponenteModal mostrarModal={procesoFallido} cerrarModal={() => { setProcesoFallido(false) }} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {errorCodigoDep &&
                <ComponenteModal mostrarModal={errorCodigoDep} cerrarModal={()=>setErrorCodigoDep(false)} titulo="El código del depositantes es inválido, intente con otro." textoBoton="Entendido"></ComponenteModal>
            }

            <div className="fluid-container">
                <main className="mb-5">
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Notificar depósito de fondo en BCH-TR a la CEHVAL"></BannerTitulo>
                                <div className="d-flex mt-4">
                                    <div className="container">
                                        <section className="d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded p-3">
                                                <div className="col-12">
                                                    <form onSubmit={(e) => {
                                                        onSubmitForm(e)
                                                    
                                                    }} className="needs-validation font-acumin-variable text-royal-blue">
                                                        <h6 className="font-acumin-variable text-royal-blue">* Estos campos son obligatorios</h6>

                                                        <div className="row mb-3">
                                                         

                                                            <div className="col-md-6 col-sm-12 mb-2">
                                                                <div className="form-floating">
                                                                    <input title="Código de cliente CEHVAL" type="text" id="email" className="form-control" placeholder="name@example.com" value={codigoDepositante} onChange={(event) => setCodigoDepositante(event.target.value)} onKeyUp={(e) => checkLenght(e.target.value)} required></input>
                                                                    <label  htmlFor="email">Código de cliente CEHVAL *</label>
                                                                    <div className="mt-2">
                                                                    {depositantNoExists &&
                                                                        <span className="text-danger fs-15 font-acumin-variable ms-3 mt-5">El código de depositante es inválido</span>
                                                                    }
                                                                    {depositantExists &&
                                                                        <span className="text-success fs-15 font-acumin-variable ms-3 mt-5">Código de depositante válido</span>
                                                                    }
                                                                    </div>
                                                                  
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 col-sm-12 ">
                                                                <div className="form-floating ">
                                                                    <input title="Nombre de cliente CEHVAL" type="text" id="nombre" className="form-control" placeholder="name@example.com" value={nombreDepositante} disabled required></input>
                                                                    <label htmlFor="nombre">Nombre de cliente CEHVAL *</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       {/*} {errorCodigoDepositante &&
                                                            <>
                                                                <p className="error">No existe este código de depositante</p>
                                                            </>
                                                        }*/}

                                                        <div className="row mb-3">
                                                            <div className="col-md-6 col-sm-12 mb-2">
                                                                <div className="form-floating">
                                                                    <input title="Folio BCH-TR" type="text" id="email" className="form-control" placeholder="name@example.com" value={folio} onChange={(event) => setFolio(event.target.value)} required></input>
                                                                    <label htmlFor="email">Folio BCH-TR *</label>
                                                                </div>
                                                            </div>


                                                            <div className="col-md-6">
                                                                <div className="form-floating ">
                                                                    <select title="Moneda del depósito" className="form-select" value={moneda} onChange={(event) => setMoneda(event.target.value)} id="moneda" name="moneda" required>
                                                                        <option value=""> </option>
                                                                        {
                                                                            monedas.map((item, index) => (
                                                                                <option key={index} value={item.id} >{item.nombre}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    <label htmlFor="contraseña">Moneda *</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-6 col-sm-12 mb-2">
                                                                <div className="form-floating">
                                                                    <input title="Monto del depósito" type="text" id="email" className="form-control" placeholder="name@example.com" onInput={(e) => handleInputMonto(e)} value={montoFront} required />
                                                                    <label htmlFor="email">Monto *</label>
                                                                </div>
                                                            </div>


                                                            <div className="col-md-6 col-sm-12 ">
                                                                <div className="form-floating ">
                                                                    <input title="Número de subasta" type="text" id="nombre" className="form-control" placeholder="name@example.com" value={numeroSubasta} onChange={(event) => setNumeroSubasta(event.target.value)} required></input>
                                                                    <label htmlFor="nombre">Número de subasta *</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mb-3">
                                                            <div className="col-md-6 col-sm-12 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Fecha en la que se notifica el depósito" value={fechaActual} className="form-control text-uppercase" type="date" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Fecha de notificación de depósito *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 col-sm-12 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Fecha en la que se realizó el depósito" className="form-control text-uppercase" type="date" value={fechaDeposito} onChange={(event) => setFechaDeposito(event.target.value)} name="fechaEmision" placeholder="Fecha de emisión" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Fecha de depósito *</label>
                                                                </div>
                                                            </div>




                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-md-12 col-sm-12 mb-2 text-end align-self-end">
                                                                <button className=" btn  botonFormularioDepositanteAprobar bg-hover-blue-royal" type="submit">Notificar</button>
                                                            </div>
                                                        </div>

                                                          
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

