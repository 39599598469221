//React
import React, { useState, useEffect } from "react";

//Componentes generales
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores';
import TablaUsuarios from "../../../../ComponentesGenerales/TablaUsuarios";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";

//APIs
import { traerTodasSolicitudesRegistroDepValFis } from "../../../../api/resguardoValoresFisicos/traerTodasSolicitudesRegistroDepValFis";

//Hooks
import useSolicitudesDepositantesValoresFisicos from '../../../../hooks/ResguardoValoresFisicos/useSolicitudesDepositantesValoresFisicos';


export default function SolicitudesDepValoresFisicosAutorizar({ history }) {


    const [failedLoadData, setFailedLoadData] = useState();

    const [copia, setCopia] = useState();
    const [data, setData] = useState([])
    const [spin, setSpin] = useState(true)
    const [erroreshttp, setErroreshttp] = useState()

    useEffect(() => {
        getAllApplications();
    }, []);

    //Handle api to get all applications to be a "depositante de valores fisicos"
    const getAllApplications = async () => {
        try {
            const response = await traerTodasSolicitudesRegistroDepValFis({ estatus: 1 });
            if (response) {
                setSpin(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    //console.log(response)
                    if (response.status === 200) {
                        setSpin(false);
                        setData(response.data);
                        setCopia(response.data);
                    } else {
                        setFailedLoadData(true);
                    }
                }
            }

         

        } catch (err) {
            console.error(err);
        }
    }


    //Handle when user click "Abrir solicitud"
    const viewRequestConstitucion = (row) => {


        //Filter the seleted row by ID
        const depositanteResValFisId = row.original.depositanteResgvalfisId;
        const selectedRowData = data.filter((row) => row.depositanteResgvalfisId === depositanteResValFisId);

        //Send object of selected row to next screen
        history.push({
            pathname: '/autorizarSolicitudDepositanteValoresFisicos', state: { objRow: selectedRowData }
        });
    }

    //Shows modal when applications doesnt load
    const goHome = () => {
        setFailedLoadData(false);
        window.location.replace("/inicioCehval");
    }

    return (
        <>
            {failedLoadData &&
                <ComponenteModal mostrarModal={failedLoadData} cerrarModal={goHome} titulo={`Ocurrio algún error, será redireccionado al inicio. Revise su conexión o vuelva a iniciar sesión.`} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="">
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        {/*<div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 d-flex flex-column">
                            <BarraLateral2  ></BarraLateral2>
                        </div>*/}
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Solicitudes de depositantes de valores físicos por autorizar"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <>
                                                    <TablaUsuarios datos={data} displayInformation={viewRequestConstitucion} useValidate={useSolicitudesDepositantesValoresFisicos} textoBoton={"Abrir solicitud"} textTitle="Clic para ver la información de la solicitud"></TablaUsuarios>
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    );
}





