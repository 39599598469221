

import { instance } from '../apiGlobal';

export async function actualizarStatusSerie(obj) {
  
  try {
    const response = await instance(`/serie/actualizarEstado`, {
      method: "post",
      data: obj
    })
    return response.data;
  } catch (error) {
    return error;
  }
}
