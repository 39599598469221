//React
import React from "react";


//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";

//APIs
import { traerSeries } from "../../../api/series/traerSeries";


//Hooks
import useSeriesColumns from '../../../hooks/useSeriesColumns';


export default function ConsultarSeriesRegistradas({ history }) {
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()

    React.useEffect(() => {
        searchSeries();
    }, [])


    const searchSeries = async () => {
        try {
            const series = await traerSeries({ estatus: 3 });
            //console.log(series)
            if (series) {
                setSpin(false)
                if ((isNaN(series.error)) === false) {
                    setErroreshttp(series)
                } else {
                    setData(series);
                    setCopia(series)
                }
            }
        } catch (err) {
            console.error(err);
        }
    }






    //Catch information form the selected row
    const viewSerie = (row) => {
   //console.log( row.original.cehvalSerieValorNomTituloCadena)
   //console.log(row.original.cehvalSerieMontoCadena)
        const serie = {
            cehvalSerieNombreSerie: row.original.cehvalSerieNombreSerie,
            cehvalSerieCodigo: row.original.cehvalSerieCodigo,
            cehvalSerieEstatus: row.original.cehvalSerieEstatus,
            cehvalSerieNombreEmi: row.original.cehvalSerieNombreEmi,
            cehvalEmisionCodigo: row.original.cehvalEmisionCodigo.cehvalEmisionCodigo,
            cehvalSerieCodigoIsin: row.original.cehvalSerieCodigoIsin,
            cehvalSerieFechaEmi: row.original.cehvalSerieFechaEmiCadena,
            cehvalSerieFechaVen: row.original.cehvalSerieFechaVenCadena,
            cehvalSerieTasIntNom: row.original.cehvalSerieTasIntNom,
            cehvalSerieMonto: row.original.cehvalSerieMontoCadena,
            cehvalSerieCantInstEmi: row.original.cehvalSerieCantInstEmi,
            cehvalSerieSpread: row.original.cehvalSerieSpread,
            cehvalSerieValorNomTitulo: row.original.cehvalSerieValorNomTituloCadena,
            catFormaColocacionCatForColId: row.original.catFormaColocacionCatForColId.catForColColocacion,
            cehvalSerieCatMonedaIdSerie: row.original.cehvalSerieCatMonedaIdSerie.nombre,
            periodicidad: row.original.cehvalSeriePeriodicidadId.catNumPerPeriodicidadNum,
            cehvalSerieCatMonedaIdLiq: row.original.cehvalSerieCatMonedaIdLiq.nombre,
            tipoTasaVariable: row.original.catTipoTasaVariableCatTipoTasaVariableId.catTipoTasaVariableTasaRefencia,
            tasaVariable:row.original.cehvalSerieCatTipTasId.catTipTasTasa, 
            PeriodoRevisionTasaVariable: row.original.catNumeroPeriocidadCatNumPerId.catNumPerPeriodicidad,
            cehvalSerieCantInstDisponibles:row.original.cehvalSerieCantInstDis,
            
           titulo: "Series registradas",
           ruta: "/seriesRegistradas",
           id: row.original.cehvalSerieId,
           formaRepresentacion:row.original.cehvalEmisionFormaRepresentacion
   
        }


        //Mandar el objeto de la emision que el usuario desea ver
        history.push({
            pathname: '/verInfoSerie', state: { objSerie: serie }
        })
    }

    return (
        <>
            <div className="">
               {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }     
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <br></br>
                                <BannerTitulo titulo="Series registradas"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaUsuarios datos={data} displayInformation={viewSerie} useValidate={useSeriesColumns} textoBoton={"Abrir"} textTitle="Click para ver la información de la serie"></TablaUsuarios>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    );
}





