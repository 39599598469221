//React
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

//Componentes generales

import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../ComponentesGenerales/ManejadorErrores';
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";

import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { consultarConciliacionesPendientes } from "../../../api/MercadoSecundario/consultarConciliacionesPendientes";
import { eliminarRegistroDeFondos } from "../../../api/MercadoSecundario/eliminarRegistroDeFondos";
import { conciliarFondosMS } from "../../../api/MercadoSecundario/conciliarFondosMS";

//Hooks
import useFondosNoConciliados from '../../../hooks/MercadoSecundario/useFondosNoConciliados';



export default function ConciliarFondosMS({ history }) {

    //States
    const [showConfirmDeleteRow, setShowConfirmDeleteRow] = useState();
    const [deleteObjRow, setDeleteObjRow] = useState(null);
    const [deletionSuccess, setDeletionSuccess] = useState();
    const [conciliacionExitosa, setConciliacionExitosa] = useState();
    
    const [validacionEliminar, setValidacionEliminar] = React.useState()
    const [loading, setLoading] = React.useState(false);


    //Data to fill the table
    const [copia, setCopia] = useState();
    const [copia2, setCopia2] = useState();
    const [data, setData] = useState([]);


    //Spinner
    const [spin, setSpin] = useState(true);

    //Show messages to user
    const [message, setMessage] = useState();
    const [messageConciliacion, setMessageConciliacion] = useState();

    //Manage errors 
    const [ErrorLoadData, setErrorLoadData] = useState();
    const [failDeleteRow, setFailDeleteRow] = useState();
    const [erroreshttp, setErroreshttp] = useState();

    useEffect(() => {
        getAllFondosNoConciliados();
    }, []);


    const getAllFondosNoConciliados = async () => {
        try {
            const response = await consultarConciliacionesPendientes();
            if (response) {
                setSpin(false);
                //setLoading(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    //console.log(response.data)
                    if (response.data) {
                        setSpin(false);
                        setData(response.data);
                        setCopia(response.data);

                    } else if (!response.data) {
                        setErrorLoadData(true);
                    }
                }
            }



        } catch (err) {
            console.error(err);
        }
    }


    //Catch information form the selected row
    const deleteRow = async (row) => {
        if (row.original.tipo === 7) {
            setValidacionEliminar(true)
        } else {
            try {
                const objRow = {
                    folioBchTr: row.original.folioBchTr,
                    tipo: row.original.tipo,
                }
                //Show modal to confirm deletion
                setShowConfirmDeleteRow(true);
                //Pass to state object to query the deletion in function confirmDeletingRow
                setDeleteObjRow(objRow);
            } catch (error) {
                console.error(error);
            }
        }
    }

    const confirmDeleteRow = async () => {
        setLoading(true);
        try {
            //console.log(deleteObjRow);
            setShowConfirmDeleteRow(false);
            const response = await eliminarRegistroDeFondos(deleteObjRow);
            if (response) {
                setLoading(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    //console.log(response);

                    //If the row was removed
                    if (response.data.conciliaciones) {

                        setLoading(false);
                        //Give the new array to update table
                        setData(response.data.conciliaciones);
                        if (copia) {
                            setCopia(false)
                            setCopia2(true)
                        } else {
                            setCopia(true)
                            setCopia2(false)
                        }

                        // setCopia(response.data.conciliaciones);

                        //Show modal with message of successful
                        setDeletionSuccess(true);
                        setMessage(response.data.mensaje);



                        //Clear state that stores obj to the query to delete a row
                        setDeleteObjRow(null);

                        //If the row wasnt removed
                    } else if (!response.data.conciliaciones) {
                        setFailDeleteRow(true);
                        setDeleteObjRow(null);
                    }
                }
            }



        } catch (error) {
            console.error(error);
        }
    }

    const conciliarFondos = async () => {
        setLoading(true)
        try {
            //console.log(data)

            const mandarConciliacion = await conciliarFondosMS(data);
            //console.log(mandarConciliacion);
            if (mandarConciliacion) {
                setLoading(false)

                if ((isNaN(mandarConciliacion.error)) === false) {
                    // //console.log("erores")
                    ////console.log(response)
                    setErroreshttp(mandarConciliacion)
                    
                } else {
                    setMessageConciliacion(mandarConciliacion.mensaje)
                    setData(mandarConciliacion.conciliaciones);
                    //setCopia(false);
                    //setCopia2(mandarConciliacion.conciliaciones)
                    setConciliacionExitosa(true)

                    // setData(mandarConciliacion)
                    if (copia) {
                        setCopia(false)
                        setCopia2(true)
                    } else {
                        setCopia(true)
                        setCopia2(false)
                    }
                }
            }


        } catch (error) {
            console.error(error);
        }
    }

    const goInicioCehval = () => {
        window.location.replace("/inicioCehval")
    }
    const onHideModal = () => {
    }

    return (
        <>
            <Modal show={showConfirmDeleteRow} animation={false} centered onHide={onHideModal}>
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => setShowConfirmDeleteRow(false)} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >Esta acción es irreversible. ¿Estas seguro que deseas continuar?</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => setShowConfirmDeleteRow(false)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >No</button>
                        <button onClick={confirmDeleteRow} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Si</button>
                    </div>
                </div>
            </Modal>

            {/*} {showConfirmDeleteRow &&
                <ComponenteModal mostrarModal={showConfirmDeleteRow} cerrarModal={confirmDeleteRow} titulo="Esta acción es irreversible. ¿Estas seguro que deseas continuar?" textoBoton="Entendido"></ComponenteModal>
            }*/}

            {loading &&
                <PantallaCargando />
            }

            


            {validacionEliminar &&
                <ComponenteModal mostrarModal={validacionEliminar} cerrarModal={() => setValidacionEliminar(false)} titulo="Este registro ya esta conciliado, no se puede eliminar" textoBoton="Entendido"></ComponenteModal>
            }

            {ErrorLoadData &&
                <ComponenteModal mostrarModal={ErrorLoadData} cerrarModal={goInicioCehval} titulo="Error al cargar la página. Por favor inténtelo de nuevo o vuelva a iniciar sesión" textoBoton="Entendido"></ComponenteModal>
            }



            {failDeleteRow &&
                <ComponenteModal mostrarModal={failDeleteRow} cerrarModal={() => setFailDeleteRow(false)} titulo="No se pudo eliminar el registro. Inténtelo de nuevo o vuelva a iniciar sesión." textoBoton="Entendido"></ComponenteModal>
            }
            {deletionSuccess &&
                <ComponenteModal mostrarModal={deletionSuccess} cerrarModal={() => setDeletionSuccess(false)} titulo={message} textoBoton="Entendido"></ComponenteModal>
            }

            {conciliacionExitosa &&
                <ComponenteModal mostrarModal={conciliacionExitosa} cerrarModal={() => setConciliacionExitosa(false)} titulo={messageConciliacion} textoBoton="Entendido"></ComponenteModal>
            }


            <div className="">
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <main className="">
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Depósitos de fondos no conciliados mercado secundario"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex container-max-height main-content">
                                        <div className="container py-2 main-min-height main-content">
                                            {copia &&
                                                <>
                                                    <TablaMercado datos={data} displayInformation={deleteRow} useValidate={useFondosNoConciliados} textoBoton={"Eliminar"} textTitle="Clic para eliminar" mercadoPrimario={true} funcion1MercadoPrimario={conciliarFondos} texto1MercadoPrimario="Conciliar fondos"></TablaMercado>
                                                </>
                                            }
                                            {copia2 &&
                                                <>
                                                    <TablaMercado datos={data} displayInformation={deleteRow} useValidate={useFondosNoConciliados} textoBoton={"Eliminar"} textTitle="Clic para eliminar" mercadoPrimario={true} funcion1MercadoPrimario={conciliarFondos} texto1MercadoPrimario="Conciliar fondos"></TablaMercado>
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}





