import { instance } from '../apiGlobal';

export async function actualizarMontoObjetivo (obj) {
      try {

        const response = await instance(`/fondoliquidacion/actualizar/montoobjetivo`, {
          method:"post",
          data:obj
        });

        return response;
        
      } catch (error) {

        return error;
      }
}
