import { useMemo } from "react";

export default function useHistoricoDepositoRetiroValores() {
    const columns = useMemo(
        () => [
            {
                Header: "Folio",
                accessor: "folio"
            },
            {
                Header: "Fecha",
                accessor: "fecha"
            },
            {
                Header: "Código Isin",
                accessor: "codigoIsin"
            },
            {
                Header: "Código de serie",
                accessor: "codigoSerie"
            },
            {
                Header: "Serie",
                accessor: "serie"
            },
            {
                Header: "Nombre de la emisión",
                accessor: "nombreEmision"
            },
          
            {
                Header: "Cantidad de instrumentos depositados o retirados",
                accessor: "cantidadValores"
            },

            {
                Header: "Código de depositante",
                accessor: "codigoDepositante"
            },

            {
                Header: "Nombre de depositante",
                accessor: "nombreInstitucion"
            },
            {
                Header: "No. de certififcado",
                accessor: "numeroCertificado"
            },
            {
                Header: "Tipo de operacion",
                accessor: "tipoSolicitud"
            },
            {
                Header: "Estatus",
                accessor: "estatus"
            },
        ],
        []
    );

    return columns;
}
