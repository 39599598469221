import React from "react";
import '../../styles/stylesheet.css'
import { FormContext } from "./form_context";


export default function RegistroDI1(props) {
    const [, setValue] = React.useContext(FormContext);

    React.useEffect(() => {
        //console.log(props.info.cehvalDepositanteEstatus)
        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 0 };
            return { ...prev, datos };
        });

    }, [])
    const convertirTime = (info) => {
        let date = new Date((info / 1000) * 1000);
        return date.toLocaleDateString("en-GB")
    }

    return (
        <>
            <div className="card-body pt-3">
                <h5 className="text-royal-blue font-acumin-variable" >I. Identificación del inversionista</h5>

                <div className="row mb-3  justify-content-end">

                    {props.info.cehvalDepositanteEstatus > 3 &&
                        <div className="col-md-3">
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto" >Código</label>
                            <input value={props.info.cehvalDepositanteCodigoCehval} disabled id="codigoDepositante" name="codigoDepositante" type="text" className="form-control " required />
                        </div>}
                </div>



                <div className="row mb-3">
                    <div className="col-md-3 col-sm-3">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Primer nombre</label>
                        <input value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatPrimerNom} disabled id="primerNombre" name="primerNombre" type="text" className="form-control   " required />
                    </div>

                    <div className="col-md-3 col-sm-3">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Segundo nombre</label>
                        <input value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatSegundoNom} disabled id="segundoNombre" name="segundoNombre" type="text" className="form-control  " />
                    </div>

                    <div className="col-md-3 col-sm-3">
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Primer apellido</label>
                        <input value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatPrimerApe} disabled id="primerApellido" name="primerApellido" type="text" className="form-control   " required />
                    </div>

                    <div className="col-md-3 col-sm-3">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Segundo apellido</label>
                        <input value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatSegundoApe} type="text" className="form-control  " disabled id="segundoApellido" name="segundoApellido" />
                    </div>
                </div>


                <div className="row mb-3">
                    <div className="col-md-3">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Apellido de casada (si aplica)</label>
                        <input value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatApellidoCas} type="text" className="form-control  " disabled id="apellidoCasada" name="apellidoCasada" />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Tipo de identificación</label>
                        <input value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatCatTipoIdeId.nombre} type="text" className="form-control  " disabled />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Número de identificación</label>
                        <input value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatNumeroIde} type="text" className="form-control   " required disabled id="numeroIdentificacion" name="numeroIdentificacion" />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">RTN (si aplica)</label>
                        <input value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatRtn} type="text" className="form-control  " disabled id="rtn" name="rtn" />
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-3">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto"> Estado civil</label>
                        <input value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatCatEstadoCivilId.nombre} type="text" className="form-control  " disabled />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto"> Género</label>
                        <input value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatCatGeneroId.nombre} type="text" className="form-control  " disabled />
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Nacionalidad</label>
                        <input value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatCatNacionalidadId.nombre} type="text" className="form-control  " disabled />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Fecha de nacimiento</label>
                        <input value={convertirTime(props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatFechaNac)} type="text" className="form-control   " required disabled id="fechaNacimiento" name="fechaNacimiento" />

                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-3">
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Lugar de nacimiento</label>
                        <input value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatLugarNac} type="text" className="form-control   " required disabled id="lugarNacimiento" name="lugarNacimiento" />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Actividad económica</label>
                        <input value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatCatActividadEcoId.nombre} type="text" className="form-control  " disabled />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Teléfono residencia</label>
                        <input value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatTelRes} type="text" className="form-control   " required disabled id="telefonoResidencia" name="telefonoResidencia" />

                    </div>
                    <div className="col-md-3">
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Teléfono celular</label>
                        <input value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatTelCel} type="text" className="form-control   " disabled id="telefonoCelular" name="telefonoCelular" />
                    </div>
                </div>

            </div>






        </>
    );
}

