import React, { useState } from 'react';
import Stepper from 'react-stepper-horizontal';
import { Modal } from "react-bootstrap";

//React context
import { FormProvider, FormContext } from './form_context';
import { FormProvider2, FormContext2 } from './form_context2';
import { FormProvider3, FormContext3 } from './form_context3';
//Components


import ActualizarDD1 from './ActualizarDD1';
import ActualizarDD1_5 from './ActualizarDD1_5';
import ActualizarDD2 from './ActualizarDD2';
import ActualizarDD3 from './ActualizarDD3';
import ActualizarDD4 from './ActualizarDD4';
import ActualizarDD5 from './ActualizarDD5';
import ActualizarDD6 from './ActualizarDD6';

import ActualizarUsuarios from '../ActualizarUsuarios';
import ActualizarCorreo from '../ActualizarCorreo'

import BannerTitulo from '../../../../ComponentesGenerales/Bannertitulo';
import ComponenteModal from '../../../../ComponentesGenerales/ComponenteModal';
//import SpinnerSend from '../../../../ComponentesGenerales/SpinnerSend';
import SpinnerStart from '../../../../ComponentesGenerales/SpinnerStart';
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores';
//import ModalConSpinner from '../../../../ComponentesGenerales/ModalConSpinner';
import PantallaCargando from '../../../../ComponentesGenerales/PantallaCargando';


//APIs
import { traerInformacionDepositnate } from '../../../../api/VerInformacionUsuarios/TraerInformacionDepositante';
import { reporteAutorizadoDDPJ } from '../../../../api/Documentos/ReportesDepositantesAutorizados/reporteAutorizadoDDPJ';
import { reporteAutorizadoDIPJ } from "../../../../api/Documentos/ReportesDepositantesAutorizados/reporteAutorizadoDIPJ";
import { actualiarDDPJ } from '../../../../api/mantenimientoCargaValores/actualizarDDPJ';
import { actualizarDIPJ } from '../../../../api/mantenimientoCargaValores/actualizarDIPJ';
//import { actualizarDIPJ } from '../../../../api/mantenimientoCargaValores/actualizarDIPJ';
//import { ConsoleLogger } from '@aws-amplify/core';

const Form = ({ history }) => {
    const [value, setValue] = React.useContext(FormContext);
    const [value2, setValue2] = React.useContext(FormContext2)
    const [value3, setValue3] = React.useContext(FormContext3)
    //const [registroExitoso, setRegistroExitoso] = React.useState()
    //const [registroFallido, setRegistroFallido] = React.useState()
    //const [spinSend, setSpinSend] = React.useState(false)
    const [erroreshttp, setErroreshttp] = React.useState()
    //const [codigo, setCodigo] = React.useState()
    
    const [sinCambios, setSinCambios] = React.useState()
    const [sinCambiosImpresion, setSinCambiosImpresion] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [confirmacionCancelar, setConfirmacionCancelar] = React.useState()
    const [actualizacionCorrecta, setActualizacionCorrecta] = React.useState()
    const [actualizacionIncorrecta, setActualizacionIncorrecta] = React.useState(false)
    const [currentPage, setCurrentPage] = useState(1);

    const [contador, setContador] = React.useState(0)

    const [condicion, setCondicion] = React.useState()
    const [tipoParticipante, setTipoParticipante] = React.useState()
    const [loading, setLoading] = React.useState(false);
    const [actualizarUsuario, setActualizarUsuario] = React.useState()
    const [informacionUsuario, setInformnacionUsuario] = React.useState()
    const [contactosOriginales, setContactosOriginales] = React.useState()
    const [contactosValidar, setContactosValidar] = React.useState()

    const [generacionUsuario, setGeneracionUsuario] = React.useState()
    const [consultaGeneracionUsusario, setConsultaGeneracionUsusario] = React.useState()
    
    const [modificarCorreo,setModificarCorreo]=React.useState()


    const sections = [
        { title: 'Identificación de la institución', onClick: () => setCurrentPage(1) },//, onClick: () => setCurrentPage(4) },
        { title: 'Información domicilio', onClick: () => setCurrentPage(2) },
        { title: 'Datos del representante legal', onClick: () => setCurrentPage(3) },
        { title: 'Procedencia de los recursos', onClick: () => setCurrentPage(4) },//, onClick: () => setCurrentPage(3) },
        { title: 'Instrucciones especiales', onClick: () => setCurrentPage(5) },
        { title: 'Firmas autorizadas', onClick: () => setCurrentPage(6) },
        { title: 'Casa de bolsa intermediaria', onClick: () => setCurrentPage(7) }
    ];


    React.useEffect(() => {
        traerInfo()
    }, [])

    const traerInfo = async () => {
        ////console.log(history.location.state.objMenu.codigo)
        //traemos la informacion del depositante con el folio y estatus
        const myObj = {
            folio: history.location.state.objMenu.folio,
            estatus: history.location.state.objMenu.estatus,
        }

        const traerInformacion = await traerInformacionDepositnate(myObj);
        //console.log(traerInformacion)
        if (traerInformacion) {
            //setSpin(false)
            if ((isNaN(traerInformacion.error)) === false) {
              
                setErroreshttp(traerInformacion)
                
            } else {
                ////console.log(traerInformacion)
                guardarInformacionFormContext(traerInformacion)
                setTipoParticipante(traerInformacion.tipoParticipante)
                //  setInformnacionUsuario(traerInformacion)
            }
        }
    }

    const guardarInformacionFormContext = (info) => {
        var paisEscogido;
        var idPaisEscogido;
        if (info.cehvalDepositanteDireccionId.datosDireccion.catPaisPais) {
            idPaisEscogido = info.cehvalDepositanteDireccionId?.cehvalDireccionModelo?.cehvalDireccionManualPais
        } else {
            idPaisEscogido = info.cehvalDepositanteDireccionId?.catCiudadCatCiudadId?.catMunicipioCatMunicipioId?.catDepartamentoCatDepartamentoId?.catPaisCatPaisId?.id
        }

        if (info.cehvalDepositanteDireccionId.datosDireccion.catPaisPais) {
            paisEscogido = info.cehvalDepositanteDireccionId?.datosDireccion?.catPaisPais
        } else {
            paisEscogido = info.cehvalDepositanteDireccionId?.catCiudadCatCiudadId?.catMunicipioCatMunicipioId?.catDepartamentoCatDepartamentoId?.catPaisCatPaisId?.nombre
        }

        const objetos = {
            datos: {
                cehvalDepositanteNombreConf: info.cehvalDepositanteNombreConf,
                cehvalDepositanteCorreoConf: info.cehvalDepositanteCorreoConf,
                codigoDepositante: info.cehvalDepositanteCodigoCehval,
                rtn: info.cehvalDepositantePersonaJurId.cehvalPersonaJurRtn,
                rtnRepresentante: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatRtn,
                nombre: info.cehvalDepositantePersonaJurId.cehvalPersonaJurNombre,
                actividadEconomica: "",
                nacionalidad: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatCatNacionalidadId.nombre,
                fechaConstitucion: info.cehvalDepositantePersonaJurId.cehvalPersonaJurFecCon,
                numeroEscritura: info.cehvalDepositantePersonaJurId.cehvalPersonaJurNumeroEscriSocOri,
                tomoFolio: info.cehvalDepositantePersonaJurId.cehvalPersonaJurTomFol,
                fechaUltimaModificacion: info.cehvalDepositantePersonaJurId.cehvalPersonaJurFechaModForm,

                pais: paisEscogido,
                departamento: info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.catMunicipioCatMunicipioId?.catDepartamentoCatDepartamentoId.nombre,
                municipio: info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.catMunicipioCatMunicipioId?.nombre,
                ciudad: info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.nombre,
                colonia: info.cehvalDepositanteDireccionId.cehvalDireccionColonia,
                calle: info.cehvalDepositanteDireccionId.cehvalDireccionCalle,
                edificio: info.cehvalDepositanteDireccionId.cehvalDireccionNumeroCasEdi,
                codigoPostal: info.cehvalDepositanteDireccionId.cehvalDireccionCp,

                departamentoManual: info.cehvalDepositanteDireccionId.datosDireccion.catDepartamentoDepto,
                municipioManual: info.cehvalDepositanteDireccionId.datosDireccion.catMunicipioDescripcion,
                ciudadManual: info.cehvalDepositanteDireccionId.datosDireccion.catCiudadDescripcion,

                paisHonduras: info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.nombre,

                idPais: idPaisEscogido,
                idDepartamento: info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.catMunicipioCatMunicipioId?.catDepartamentoCatDepartamentoId.id,
                idMunicipio: info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.catMunicipioCatMunicipioId?.id,
                idCiudad: info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.id,




                primerNombre: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatPrimerNom,
                segundoNombre: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatSegundoNom,
                primerApellido: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatPrimerApe,
                segundoApellido: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatSegundoApe,
                apellidoCasada: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatApellidoCas,
                tipoIdentificacion: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatCatTipoIdeId.nombre,
                numeroIdentificacion: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatNumeroIde,
                estadoCivil: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatCatEstadoCivilId.nombre,
                genero: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatCatGeneroId.nombre,
                nacionalidadPersonaJuridica: info.cehvalDepositantePersonaJurId.catNacionalidadId.nombre,
                fechaNacimiento: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatFechaNac,
                lugarNacimiento: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatLugarNac,
                puesto: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatCatPuestoId.nombre,
                tiempoPuesto: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatCatTieLabId.nombre,
                telefono: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatTelOfi,
                correoLab: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatCorreoLab,
                fechaRepr: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatFechaRepresentacion,
                telefonoCelular: info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatTelCel,
                telefonoResidencia: "",
                origenRecursos: info.cehvalDepositantePersonaJurId.cehvalPersonaJurRecProc,

                numeroCuentaN: info.cehvalCuentaBanCollection[0]?.cehvalCuentaBanNumCue,
                tipoCuentaN: info.cehvalCuentaBanCollection[0]?.catTipoCuentaCatTipCueId.catTipCueCuenta,
                bancoN: info.cehvalCuentaBanCollection[0]?.catBancoCatBancoId.catBancoNombre,
                monedaN: 1,

                numeroCuentaE: info.cehvalCuentaBanCollection[1]?.cehvalCuentaBanNumCue,
                tipoCuentaE: info.cehvalCuentaBanCollection[1]?.catTipoCuentaCatTipCueId.catTipCueCuenta,
                bancoE: info.cehvalCuentaBanCollection[1]?.catBancoCatBancoId.catBancoNombre,
                monedaE: 2,

                nombreCompleto1: info.cehvalContactoCollection[0].nombre,
                segundoNombre1: info.cehvalContactoCollection[0].segundoNombre,
                primerApellido1: info.cehvalContactoCollection[0].primerApellido,
                segundoApellido1: info.cehvalContactoCollection[0].segundoApellido,
                correo1: info.cehvalContactoCollection[0].correo,

                nombreCompleto2: info.cehvalContactoCollection[1]?.nombre,
                segundoNombre2: info.cehvalContactoCollection[1]?.segundoNombre,
                primerApellido2: info.cehvalContactoCollection[1]?.primerApellido,
                segundoApellido2: info.cehvalContactoCollection[1]?.segundoApellido,
                correo2: info.cehvalContactoCollection[1]?.correo,

                /*nombreCompleto3: "",
                correo3: "",
          
                nombreCompleto4: "",
                correo4: "",
          
                nombreCompleto5: "",
                correo5: "",
          
                nombreCompleto6: "",
                correo6: "",*/


                rutaS3_1: "",
                tipoFirma_1: info.cehvalFirmaAutCollection[0].tipoFirma,
                nombreCompleto_1: info.cehvalFirmaAutCollection[0].nombre,
                cargo_1: info.cehvalFirmaAutCollection[0].cargo,

                rutaS3_2: "",
                tipoFirma_2: info.cehvalFirmaAutCollection[1].tipoFirma,
                nombreCompleto_2: info.cehvalFirmaAutCollection[1].nombre,
                cargo_2: info.cehvalFirmaAutCollection[1].cargo,

                rutaS3_3: "",
                tipoFirma_3: "",
                nombreCompleto_3: "",
                cargo_3: "",

                rutaS3_4: "",
                tipoFirma_4: "",
                nombreCompleto_4: "",
                cargo_4: "",

                rutaS3_5: "",
                tipoFirma_5: "",
                nombreCompleto_5: "",
                cargo_5: "",

                rutaS3_6: "",
                tipoFirma_6: "",
                nombreCompleto_6: "",
                cargo_6: "",

                nombreBolsa: info.cehvalDepositantePersonaIcbiId.cehvalPersonaJurNombre,
                lugarRegistro: info.cehvalDepositanteLugarFecha,
                fechaRegistro: "",

                descripcionEstatus: "",

                actividadEconomicaPersonaJuridica: info.cehvalDepositantePersonaJurId.catActividadEcoId.nombre,
                firmaRepresentante: "",

                exencionInpuestos: info.cehvalDepositantePersonaJurId.cehvalPersonaJurExencionImpuestos,
                enviar: 0,


                //agregados


                condicionPaisMoficiacion: false,

                tipoIdentificacionInicio: true,
                estadoCivilInicio: true,
                generoInicio: true,
                nacionalidadInicio: true,
                puestoInicio: true,
                tiempoLaboralInicio: true,

                bancoNInicio: true,
                bancoEInicio: true,
                tipoCuentaNInicio: true,
                tipoCuentaEInicio: true,
                exencionImpuestosInicio: true,

                paisInicio: true,
                departamentoInicio: true,
                municipioInicio: true,
                ciudadInicio: true,

                casaBolsaInicio: true,

                tipoParticipante: info.tipoParticipante

            }
        }
        //console.log(objetos)

        setValue(objetos)
        setValue2(objetos)
        setSpin(false)
    }

    React.useEffect(() => {
        async function registrarActualizacion() {
            var paisParaMandar;
            var mandarInformacionDepositante;
            if (value3.datos.pais) {
                paisParaMandar = value3.datos.pais
            } else {
                paisParaMandar = value2.datos.idPais
            }

            setLoading(true)
            const myObj = {
                //  actividadEconomica: value.datos.actividadEconomica,//no hay actividad economica de la persona natural
                apellidoCasada: value3.datos.apellidoCasada,
                casaBolsa: value3.datos.nombreBolsa,//(JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo,
                codigoDepositante: value2.datos.codigoDepositante,
                contactos: [
                    {
                        nombre: value3.datos.nombreCompleto1,
                        segundoNombre: value3.datos.segundoNombre1,
                        primerApellido: value3.datos.primerApellido1,
                        segundoApellido: value3.datos.segundoApellido1,
                        correo: value3.datos.correo1,
                        usuarioAdmin: ""
                    },
                    {
                        nombre: value3.datos.nombreCompleto2,
                        segundoNombre: value3.datos.segundoNombre2,
                        primerApellido: value3.datos.primerApellido2,
                        segundoApellido: value3.datos.segundoApellido2,
                        correo: value3.datos.correo2,
                        usuarioAdmin: ""
                    },


                ],
                cuentas: [{
                    numeroCuenta: value3.datos.numeroCuentaN,
                    moneda: value3.datos.monedaN,
                    tipoCuenta: value3.datos.tipoCuentaN,
                    banco: value3.datos.bancoN
                },
                {
                    numeroCuenta: value3.datos.numeroCuentaE,
                    moneda: value3.datos.monedaE,
                    tipoCuenta: value3.datos.tipoCuentaE,
                    banco: value3.datos.bancoE
                }
                ],
                domicilio: {
                    pais: paisParaMandar,
                    departamento: value3.datos.departamento,
                    municipio: value3.datos.municipio,
                    ciudad: value3.datos.ciudad,
                    colonia: value3.datos.colonia,
                    calle: value3.datos.calle,
                    numero: value3.datos.edificio,
                    cp: value3.datos.codigoPostal,
                    departamentoManual: value3.datos.departamentoManual,
                    municipioManual: value3.datos.municipioManual,
                    ciudadManual: value3.datos.ciudadManual,
                },

                empresa: value3.datos.nombre,//estes es empresa?
                //cual es la fecha de aprobacion
                estadoCivil: value3.datos.estadoCivil,
                fechaNacimiento: value3.datos.fechaNacimiento,
                fechaUltimaModificacion: value3.datos.fechaUltimaModificacion,
                firmantes: [
                    {
                        nombre: value3.datos.nombreCompleto_1,
                        cargo: value3.datos.cargo_1,
                        tipoFirma: value3.datos.tipoFirma_1,
                        estatus: value3.datos.rutaS3_1,
                        usuarioAdmin: ""

                    },
                    {
                        nombre: value3.datos.nombreCompleto_2,
                        cargo: value3.datos.cargo_2,
                        tipoFirma: value3.datos.tipoFirma_2,
                        estatus: value3.datos.rutaS3_2,
                        usuarioAdmin: ""
                    }
                ],

                genero: value3.datos.genero,
                lugarNacimiento: value3.datos.lugarNacimiento,
                nacionalidad: value3.datos.nacionalidad,
                numeroIdentificacion: value3.datos.numeroIdentificacion,
                origenRecursos: value3.datos.origenRecursos,
                primerApellido: value3.datos.primerApellido,
                primerNombre: value3.datos.primerNombre,
                puesto: value3.datos.puesto,
                rtn: value3.datos.rtnRepresentante,
                segundoNombre: value3.datos.segundoNombre,
                segundoApellido: value3.datos.segundoApellido,
                telefonoCelular: value3.datos.telefonoCelular,
                telefonoLaboral: value3.datos.telefono,
                telefonoResidencia: value3.datos.telefonoResidencia,
                tiempoPuesto: value3.datos.tiempoPuesto,
                tipoIdentidad: value3.datos.tipoIdentificacion,
                lugar: value3.datos.lugarRegistro,
                firmaRepresentante: value3.datos.firmaRepresentante,
                descripcionEstatus: value3.datos.descripcionEstatus,
                tipoDepositante: "",
                nombre: value3.datos.nombre,
                nacionalidadPersonaJuridica: value3.datos.nacionalidadPersonaJuridica,
                fechaConstitucion: value3.datos.fechaConstitucion,
                numeroEscritura: value3.datos.numeroEscritura,
                tomoFolio: value3.datos.tomoFolio,
                correoLaboral: value3.datos.correoLab,
                fechaRepr: value3.datos.fechaRepr,
                rtnPersonaJuridica: value3.datos.rtn,
                actividadEconomicaPersonaJuridica: value3.datos.actividadEconomicaPersonaJuridica,
                cehvalDepositanteCorreoConf: value3.datos.cehvalDepositanteCorreoConf,
                cehvalDepositanteNombreConf: value3.datos.cehvalDepositanteNombreConf,
                //cehvalDepositanteCorreoRegistrador: token.email,
                //cehvalDepositanteNombreRegistrador: token.name,
                exencionImpuestos: value3.datos.exencionInpuestos

            }

            //contactos modificados
            const myObj2 = {
                contactos: [
                    {
                        nombre: value3.datos.nombreCompleto1,
                        segundoNombre: value3.datos.segundoNombre1,
                        primerApellido: value3.datos.primerApellido1,
                        segundoApellido: value3.datos.segundoApellido1,
                        correo: value3.datos.correo1,
                        usuarioAdmin: ""
                    },
                    {
                        nombre: value3.datos.nombreCompleto2,
                        segundoNombre: value3.datos.segundoNombre2,
                        primerApellido: value3.datos.primerApellido2,
                        segundoApellido: value3.datos.segundoApellido2,
                        correo: value3.datos.correo2,
                        usuarioAdmin: ""
                    },
                ],
            }

            //contactos originales
            const myObj3 = {
                contactos: [
                    {
                        nombre: value2.datos.nombreCompleto1,
                        segundoNombre: value2.datos.segundoNombre1,
                        primerApellido: value2.datos.primerApellido1,
                        segundoApellido: value2.datos.segundoApellido1,
                        correo: value2.datos.correo1,
                        usuarioAdmin: ""
                    },
                    {
                        nombre: value2.datos.nombreCompleto2,
                        segundoNombre: value2.datos.segundoNombre2,
                        primerApellido: value2.datos.primerApellido2,
                        segundoApellido: value2.datos.segundoApellido2,
                        correo: value2.datos.correo2,
                        usuarioAdmin: ""
                    },
                ],
            }

            const myObj4 = {
                contactos: [
                    {
                        nombre: value.datos.nombreCompleto1,
                        segundoNombre: value.datos.segundoNombre1,
                        primerApellido: value.datos.primerApellido1,
                        segundoApellido: value.datos.segundoApellido1,
                        correo: value.datos.correo1,
                        usuarioAdmin: ""
                    },
                    {
                        nombre: value.datos.nombreCompleto2,
                        segundoNombre: value.datos.segundoNombre2,
                        primerApellido: value.datos.primerApellido2,
                        segundoApellido: value.datos.segundoApellido2,
                        correo: value.datos.correo2,
                        usuarioAdmin: ""
                    },
                ],
            }


            //console.log(myObj)

            if (tipoParticipante === 1) {
                mandarInformacionDepositante = await actualiarDDPJ(myObj)
            } else {
                mandarInformacionDepositante = await actualizarDIPJ(myObj)
            }


              //console.log(mandarInformacionDepositante)
            if (mandarInformacionDepositante) {
                setLoading(false)
                // setSpinSend(false)
                if ((isNaN(mandarInformacionDepositante.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(mandarInformacionDepositante)
                } else {
                    setLoading(false)
                    if (mandarInformacionDepositante.codigo === "000") {
                        setInformnacionUsuario(myObj2)
                        setContactosOriginales(myObj3)
                        setContactosValidar(myObj4)

                        //si se modifica algun correo de cualquiera de los dos contactos
                    /*     if ((value.datos.correo1 !== value2.datos.correo1) || (value.datos.correo2 !== value2.datos.correo2)) {
                            setActualizarUsuario(true)
                            setActualizacionCorrecta(true)
                         //si se modifica algun nombre de contacto   
                        } */if ((value.datos.correo1 !== value2.datos.correo1) || (value.datos.correo2 !== value2.datos.correo2)||(value.datos.nombreCompleto1 !== value2.datos.nombreCompleto1) || (value.datos.primerApellido1 !== value2.datos.primerApellido1) || (value.datos.nombreCompleto2 !== value2.datos.nombreCompleto2) || (value.datos.primerApellido2 !== value2.datos.primerApellido2)) {
                            //console.log("actaulizar ususariooo")
                     
                           // setActualizarUsuario(true)
                           setConsultaGeneracionUsusario(true)
                        }else{
                            setActualizacionCorrecta(true)
                        }
                        
                    } else {
                        setActualizacionIncorrecta(true)
                    }
                }
            }
        }

        if (contador > 0) {
            registrarActualizacion(); // Llamas la función

        }
        // //console.log(contador)

    }, [value3])

    const handleSubmit = async (e) => {
        e.preventDefault();
        var condicionNombre1 = false;
        var condicionNombre2 = false;
        var nombreCompleto1;
        var nombreCompleto2;

        if (condicion) {
            if ((value.datos.nombreCompleto1 !== value2.datos.nombreCompleto1) || (value.datos.segundoNombre1 !== value2.datos.segundoNombre1) || (value.datos.primerApellido1 !== value2.datos.primerApellido1) || (value.datos.segundoApellido1 !== value2.datos.segundoApellido1)) {
                condicionNombre1 = true;
                nombreCompleto1 = value2.datos.nombreCompleto1.trim() + " " + value2.datos.segundoNombre1.trim() + " " + value2.datos.primerApellido1.trim() + " " + value2.datos.segundoApellido1.trim()
            }

            if ((value.datos.nombreCompleto2 !== value2.datos.nombreCompleto2) || (value.datos.segundoNombre2 !== value2.datos.segundoNombre2) || (value.datos.primerApellido2 !== value2.datos.primerApellido2) || (value.datos.segundoApellido2 !== value2.datos.segundoApellido2)) {
                condicionNombre2 = true;
                nombreCompleto2 = value2.datos.nombreCompleto2.trim() + " " + value2.datos.segundoNombre2.trim() + " " + value2.datos.primerApellido2.trim() + " " + value2.datos.segundoApellido2.trim()
            }




            ////console.log(e.target.value)

            //  let objs = [value.datos, value2.datos]
            let values1 = Object.values(value.datos)
            let values2 = Object.values(value2.datos)
            let keys1 = Object.keys(value.datos);
            let contadorLocal = 0;

          //  console.log(value2.datos)

            for (let index = 0; index < keys1.length; index++) {
                if ((values1[index] != values2[index]) && (keys1[index] != "establecerModificacion")) {
                    if (condicionNombre1) {
                        setValue3((prev) => {
                            const datos = { ...prev.datos, ["nombreCompleto_1"]: nombreCompleto1 };
                            return { ...prev, datos };
                        });
                        condicionNombre1 = false;
                    }

                    if (condicionNombre2) {
                        setValue3((prev) => {
                            const datos = { ...prev.datos, ["nombreCompleto_2"]: nombreCompleto2 };
                            return { ...prev, datos };
                        });
                        condicionNombre2 = false;
                    }

                    if (!values2[index]) {
                        setValue3((prev) => {
                            const datos = { ...prev.datos, [keys1[index]]: " " };
                            return { ...prev, datos };
                        });
                    } else {
                      //  console.log(keys1[index])
                       // console.log(values2[index])
                       // console.log(values2[index].trim())

                        if(values2[index]===true||values2[index]===false){
                            setValue3((prev) => {
                                const datos = { ...prev.datos, [keys1[index]]: values2[index]};
                                return { ...prev, datos };
                            });
                        }else{
                            setValue3((prev) => {
                                const datos = { ...prev.datos, [keys1[index]]: values2[index].trim() };
                                return { ...prev, datos };
                            });
                        }
                       
                        
                     
                    }
                    contadorLocal++;
                }
            }

            setContador(contadorLocal)

            if (contadorLocal < 1) {
                setSinCambios(true);
            }
        } else {
            next()
        }



    }



    const next = () => setCurrentPage((prev) => prev + 1);
    const prev = () => setCurrentPage((prev) => prev - 1);


    const imprimir = async () => {
        setActualizacionCorrecta(false)
        var response;
        const myobj = {
            codigo: history.location.state.objMenu.folio,
            estatus: 5
        }


        setLoading(true)
        if (tipoParticipante === 1) {
            response = await reporteAutorizadoDDPJ(myobj)
        } else {
            response = await reporteAutorizadoDIPJ(myobj)
        }

        if (response) {
            setLoading(false)
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(response)
            } else {
                const file = new Blob(
                    [response],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
                history.go(-1)
            }
        }

    }

    /*const cancelar = () => {
        if (tipoParticipante == 1) {
            history.push({
                pathname: '/depositantesParaActualizar', state: 1
            })
        }
        else {
            history.push({
                pathname: '/depositantesParaActualizar', state: 2
            })
        }*/

    const cancelar = () => {
        let values1 = Object.values(value.datos)
        let values2 = Object.values(value2.datos)
        let keys1 = Object.keys(value.datos);
        let contadorLocal = 0;

        for (let index = 0; index < keys1.length; index++) {
            if (values1[index] !== values2[index]) {
                contadorLocal++;

            }
        }

        if (contadorLocal > 1) {
            setConfirmacionCancelar(true)
        } else {
            if (tipoParticipante === 1) {
                history.push({
                    pathname: '/depositantesParaActualizar', state: 1
                })
            }
            else {
                history.push({
                    pathname: '/depositantesParaActualizar', state: 2
                })
            }
        }
    }


    const establecerConfirmacion = () => {
        setValue((prev) => {
            const datos = { ...prev.datos, ["establecerModificacion"]: true };
            return { ...prev, datos };
        });
    }

    const resetear = () => {
        setActualizarUsuario(false)
    }

    const modificarCorreoCognito=()=>{
      

        if((value.datos.correo1 !== value2.datos.correo1)|| (value.datos.correo2 !== value2.datos.correo2)){
            //console.log("modifficar correo")
            setModificarCorreo(true)
        }
    }

    return (
        <>
            <div className='fluid-container'>
            {modificarCorreo&&
                <ActualizarCorreo resetear={resetear} informacion={informacionUsuario} contactosOriginales={contactosOriginales} contactosValidar={contactosValidar} codigo={value.datos.codigoDepositante} tipoParticipante={tipoParticipante}></ActualizarCorreo>

                }
                {actualizarUsuario &&
                    <ActualizarUsuarios resetear={resetear} informacion={informacionUsuario} contactosOriginales={contactosOriginales} contactosValidar={contactosValidar} codigo={value.datos.codigoDepositante} tipoParticipante={tipoParticipante} ></ActualizarUsuarios>
                }

<Modal show={consultaGeneracionUsusario} animation={false} centered >
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => { history.go(-1) }} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >Se ha modificado información personal de uno o mas contactos, ¿desea generar nuevos usuarios con estos datos?</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => { setActualizacionCorrecta(true)
                        setActualizarUsuario(true)
                        setConsultaGeneracionUsusario(false)}} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Generar</button>
                      
                        <button onClick={() => { setActualizacionCorrecta(true)
                        setConsultaGeneracionUsusario(false)
                        modificarCorreoCognito()}} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >No generar</button>
                    </div>
                </div>
            </Modal>

                <Modal show={confirmacionCancelar} animation={false} centered >
                    <div className="containerModal">
                        <div className="modal-header">
                            <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                            <button type="button" onClick={() => setConfirmacionCancelar(false)} class="btn-close" aria-label="Close"></button>
                        </div>
                        <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >Se descartarán los cambios hechos ¿Desea continuar?</p>
                        <div className="row justify-content-evenly">
                            <button onClick={() => setConfirmacionCancelar(false)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Cancelar</button>
                            <button onClick={() => { history.go(-1) }} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Aceptar</button>
                        </div>
                    </div>
                </Modal>

                <Modal show={actualizacionCorrecta} animation={false} centered >
                    <div className="containerModal">
                        <div className="modal-header">
                            <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                            <button type="button" onClick={() => { history.go(-1) }} class="btn-close" aria-label="Close"></button>
                        </div>
                        <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >Se ha actualizado la información del depositante</p>
                        <div className="row justify-content-evenly">
                            <button onClick={() => { history.go(-1) }} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Terminar</button>
                            <button onClick={imprimir} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Imprimir</button>
                        </div>
                    </div>
                </Modal>

                {loading &&
                    <PantallaCargando />
                }

                

                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }

                {sinCambios &&
                    <ComponenteModal mostrarModal={sinCambios} cerrarModal={() => { setSinCambios(false) }} titulo="No se realizó ningun cambio, no se puede confirmar" textoBoton="Entendido"></ComponenteModal>
                }

                {sinCambiosImpresion &&
                    <ComponenteModal mostrarModal={sinCambiosImpresion} cerrarModal={() => { setSinCambiosImpresion(false) }} titulo="No se ha confirmado ningun cambio" textoBoton="Entendido"></ComponenteModal>
                }

                {/*{actualizacionCorrecta &&
                    <ComponenteModal mostrarModal={actualizacionCorrecta} cerrarModal={() => { setActualizacionCorrecta(false) }} titulo="Se ha actualizado la información del depositante" textoBoton="Entendido"></ComponenteModal>
                }*/}

                {actualizacionIncorrecta &&
                    <ComponenteModal mostrarModal={actualizacionIncorrecta} cerrarModal={() => { setActualizacionIncorrecta(false) }} titulo="No se pudo actualizar la información del depositante" textoBoton="Entendido"></ComponenteModal>
                }

                <div className="fluid-container">

                    <main className='container-min-height' >
                        <div className="row row-wrap g-0">
                            
                            <div className="col-12 mb-5">
                                <BannerTitulo titulo="Actualizar depositante"></BannerTitulo>
                                <div className="d-flex justify-content-center">
                                    <div className="container">
                                        <div className="row contenedorsteper" id='contenedorsteper'>
                                            <div className=" contenedorsteper font-acumin-variable">
                                                <div id="stepperComponent">
                                                    <Stepper
                                                        steps={sections}
                                                        activeStep={currentPage - 1}
                                                        activeColor="#F4CA85"

                                                        completeColor="#242848"
                                                        completeBarColor="#242848"
                                                        titleFontSize={15}
                                                        size={30}
                                                        circleFontSize={15}
                                                        className="steper"
                                                    />
                                                </div>


                                                <div className="  claseSinBorder">
                                                    <div className="card-body">
                                                        <div className="d-grid gap-4 mx-auto">
                                                            {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                                                <form onSubmit={handleSubmit}>
                                                                    <br></br>
                                                                    <h6 className="font-acumin-variable text-royal-blue">* Estos campos son obligatorios</h6>
                                                                    {currentPage === 1 && (
                                                                        <>
                                                                            <div id="primeraSeccion"  >
                                                                                <div className="contenedorFormularioDepositanteDirecto card" >
                                                                                    <ActualizarDD1 />

                                                                                    {/*  <div className='mb-3 text-end p-3'>
                                                                                    <button type="submit" className="btn botonFormularioDepositante">Siguiente</button>
                                                                                </div>*/}
                                                                                    <div className="col-12 row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end font-acumin-variable">
                                                                                        {value.datos.establecerModificacion ?
                                                                                            <button title="Cancelar modificación" type='button' onClick={cancelar} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white  mb-2" >Cancelar</button>
                                                                                            :
                                                                                            <button title="Modificar información" type='button' onClick={establecerConfirmacion} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Modificar</button>
                                                                                        }

                                                                                        <button title="Confirmar modificación de información" onClick={() => setCondicion(true)} id="botonActualizar1" type='submit' className=" col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Confirmar</button>
                                                                                        {/*<button title="Imprimir" onClick={imprimir} type='button' className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Imprimir</button>*/}
                                                                                        <button title="Siguiente" onClick={() => setCondicion(false)} type='submit' className="col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Siguiente</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}

                                                                    {currentPage === 2 && (
                                                                        <>
                                                                            <div id="primeraSeccionIntermedia " >
                                                                                <div className="contenedorFormularioDepositanteDirecto card " >
                                                                                    <ActualizarDD1_5 />
                                                                                    <div className="col-12 row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end font-acumin-variable">
                                                                                        <button title="Regresar" type="button" onClick={prev} className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white  mb-2">Anterior</button>
                                                                                        {value.datos.establecerModificacion ?
                                                                                            <button title="Cancelar modificación" type='button' onClick={cancelar} className="col-8 col-sm-3  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Cancelar</button>
                                                                                            :
                                                                                            <button title="Modificar información" type='button' onClick={establecerConfirmacion} className="col-8 col-sm-3  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Modificar</button>
                                                                                        }
                                                                                        <button title="Confirmar modificación de información" onClick={() => setCondicion(true)} id="botonActualizar1" type='submit' className=" col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Confirmar</button>
                                                                                        {/*<button title="Imprimir" onClick={imprimir} type='button' className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Imprimir</button>*/}
                                                                                        <button title="Siguiente" onClick={() => setCondicion(false)} type='submit' className="col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Siguiente</button>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}

                                                                    {currentPage === 3 && (
                                                                        <>
                                                                            <div id="segundaSeccion" >
                                                                                <div className="contenedorFormularioDepositanteDirecto card " >
                                                                                    <ActualizarDD2 />
                                                                                    <br></br>
                                                                                    <div className="col-12 row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end font-acumin-variable">
                                                                                        <button title="Regresar" type="button" onClick={prev} className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Anterior</button>
                                                                                        {value.datos.establecerModificacion ?
                                                                                            <button title="Cancelar modificación" type='button' onClick={cancelar} className="col-8 col-sm-3  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Cancelar</button>
                                                                                            :
                                                                                            <button title="Modificar información" type='button' onClick={establecerConfirmacion} className="col-8 col-sm-3  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Modificar</button>
                                                                                        }
                                                                                        <button title="Confirmar modificación de información" onClick={() => setCondicion(true)} id="botonActualizar1" type='submit' className=" col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Confirmar</button>
                                                                                        {/*<button title="Imprimir" onClick={imprimir} type='button' className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Imprimir</button>*/}
                                                                                        <button title="Siguiente" onClick={() => setCondicion(false)} type='submit' className="col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Siguiente</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}

                                                                    {currentPage === 4 && (
                                                                        <>
                                                                            <div id="terceraSeccion" >
                                                                                <div className="contenedorFormularioDepositanteDirecto card " >
                                                                                    <ActualizarDD3 />

                                                                                    <div className="col-12 row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end font-acumin-variable">
                                                                                        <button title="Regresar" type="button" onClick={prev} className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Anterior</button>
                                                                                        {value.datos.establecerModificacion ?
                                                                                            <button title="Cancelar modificación" type='button' onClick={cancelar} className="col-8 col-sm-3  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Cancelar</button>
                                                                                            :
                                                                                            <button title="Modificar información" type='button' onClick={establecerConfirmacion} className="col-8 col-sm-3  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Modificar</button>
                                                                                        }
                                                                                        <button title="Confirmar modificación de información" onClick={() => setCondicion(true)} type='submit' className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Confirmar</button>
                                                                                        {/*<button title="Imprimir" onClick={imprimir} type='button' className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Imprimir</button>*/}
                                                                                        <button title="Siguiente" onClick={next} type='button' className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Siguiente</button>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}

                                                                    {currentPage === 5 && (
                                                                        <>
                                                                            <div id="cuartaSeccion" >
                                                                                <div className="contenedorFormularioDepositanteDirecto card " >
                                                                                    <ActualizarDD4 tipo="depositanteDirecto" />
                                                                                    <br></br>
                                                                                    <div className="col-12 row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end font-acumin-variable">
                                                                                        <button title="Regresar" type="button" onClick={prev} className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Anterior</button>
                                                                                        {value.datos.establecerModificacion ?
                                                                                            <button title="Cancelar modificación" type='button' onClick={cancelar} className="col-8 col-sm-3  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Cancelar</button>
                                                                                            :
                                                                                            <button title="Modificar información" type='button' onClick={establecerConfirmacion} className="col-8 col-sm-3  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Modificar</button>
                                                                                        }
                                                                                        <button title="Confirmar modificación de información" onClick={() => setCondicion(true)} type='submit' className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Confirmar</button>
                                                                                        {/*<button title="Imprimir" onClick={imprimir} type='button' className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Imprimir</button>*/}
                                                                                        <button title="Siguiente" onClick={next} type='button' className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Siguiente</button>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}

                                                                    {currentPage === 6 && (
                                                                        <>
                                                                            <div id="quintaSeccion" >
                                                                                <div className="card contenedorFormularioDepositanteDirecto card ">
                                                                                    <ActualizarDD5 tipo="depositanteDirecto" />
                                                                                    <br></br>
                                                                                    <div className="col-12 row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end font-acumin-variable">
                                                                                        <button title="Regresar" type="button" onClick={prev} className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Anterior</button>
                                                                                        {value.datos.establecerModificacion ?
                                                                                            <button title="Cancelar modificación" type='button' onClick={cancelar} className="col-8 col-sm-3  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Cancelar</button>
                                                                                            :
                                                                                            <button title="Modificar información" type='button' onClick={establecerConfirmacion} className="col-8 col-sm-3  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Modificar</button>
                                                                                        }
                                                                                        <button title="Confirmar modificación de información" type='submit' onClick={() => setCondicion(true)} className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Confirmar</button>
                                                                                        {/*<button title="Imprimir" onClick={imprimir} type='button' className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Imprimir</button>*/}
                                                                                        <button title="Siguiente" onClick={next} type='button' className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Siguiente</button>

                                                                                    </div>
                                                                                    <br></br>
                                                                                    <br></br>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}

                                                                    {currentPage === 7 && (
                                                                        <>
                                                                            <div id="sextaSeccion" >
                                                                                <div className="card contenedorFormularioDepositanteDirecto card ">
                                                                                    <ActualizarDD6 tipo="depositanteDirecto" />
                                                                                    <br></br>
                                                                                    <div className="col-12 row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end font-acumin-variable">
                                                                                        <button title="Regresar" type="button" onClick={prev} className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Anterior</button>
                                                                                        {value.datos.establecerModificacion ?
                                                                                            <button title="Cancelar modificación" type='button' onClick={cancelar} className="col-8 col-sm-3  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Cancelar</button>
                                                                                            :
                                                                                            <button title="Modificar información" type='button' onClick={establecerConfirmacion} className="col-8 col-sm-3  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Modificar</button>
                                                                                        }
                                                                                        <button title="Confirmar modificación de información" type='submit' onClick={() => setCondicion(true)} className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Confirmar</button>
                                                                                        {/*<button title="Imprimir" onClick={imprimir} type='button' className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Imprimir</button>*/}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br></br>
                                                                            <br></br>
                                                                        </>
                                                                    )}





                                                                </form>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </main >

                </div>
            </div>


        </>
    );
};

export default function ActualizarDepositanteJuridico({ history }) {
    return (
        <FormProvider>
            <FormProvider2>
                <FormProvider3>
                    <Form history={history} />
                </FormProvider3>
            </FormProvider2>
        </FormProvider>
    );
}



