import { useMemo } from "react";

export default function useEmisoresBCV() {
  const columns = useMemo(
    () => [
      {
        Header: "Código",
        accessor: "emisorCodigo"
      },
      {
        Header: "Nombre",
        accessor: "emisorNombreCompleto"
      },
      {
        Header: "Representante legal",
        accessor: "emisorNombreCompletoRepreLeg"
      },
      {
        Header: "RTN",
        accessor: "emisorRtn"
      }
    ],
    []
  );

  return columns;
}
