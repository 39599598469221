import React from "react";

//Componentes

import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";
import useFondosRemanentes from "../../../hooks/MercadoPrimario/useFondosRemanentes";
import useSubastasLiquidadasOperadorDD from "../../../hooks/MercadoPrimario/useSubastasLiquidadasOperadorDD";



import { imprmirSubastasLiquidadasOperadorDD } from "../../../api/Mercadoprimario/imprmirSubastasLiquidadasOperadorDD";

export default function InfromacionSubastasOperadorDD({ funcion2, textoFuncion2, subastaData, irDevuelta, history }) {

    const [erroreshttp, setErroreshttp] = React.useState()
    const [errorArchivo, setErrorArchivo] = React.useState()
    const [loading, setLoading] = React.useState(false);


    const objetoSubasta=subastaData
    const imprimir = async (row) => {
        setLoading(true)
        const response = await imprmirSubastasLiquidadasOperadorDD(objetoSubasta)
        console.group(response)
        if (response) {

            setLoading(false)

            if ((isNaN(response.error)) === false) {
                setErroreshttp(response)
            } else {

                if (response.size === 0) {
                    setErrorArchivo(true)
                } else {

                    const file = new Blob(
                        [response],
                        { type: 'application/pdf' });//Build a URL from the file
                    const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                    window.open(fileURL);
                }
            }
        }



    }




    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            

            {errorArchivo &&
                <ComponenteModal mostrarModal={errorArchivo} cerrarModal={() => setErrorArchivo(false)} titulo="No se pudo imprimir el archivo" textoBoton="Aceptar"></ComponenteModal>
            }


            <BannerTitulo titulo="Liquidación de la subasta"></BannerTitulo>
            <div className="container py-5 w-100">
                <div className="font-acumin-variable ms-3">
                    <h4>Subasta</h4>

                    <div className="row">
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Número de subasta" value={objetoSubasta.numeroSubasta} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Número de subasta</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Fecha de subasta" value={objetoSubasta.fechaSubasta} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Fecha de subasta</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="font-acumin-variable ms-3">
                    <h4>Emisión</h4>

                    <div className="row">
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Código de emisión" value={objetoSubasta.codigoEmision} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Código de emisión</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Nombre de emisor" value={objetoSubasta.nombreEmisor} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Nombre de emisor</label>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="font-acumin-variable ms-3">
                    <h4>Serie</h4>

                    <div className="row">
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Código de serie" value={objetoSubasta.codigoSerie} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Código de serie</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Serie" value={objetoSubasta.nombreSerie} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Serie</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Número ISIN" value={objetoSubasta.isin} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">ISIN</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Cantidad de instrumentos" value={objetoSubasta.cantidadInstrumentosSerie} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Cantidad de instrumentos</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className=" mb-3">
                                <div className="form-floating ">
                                    <input title="Moneda de liquidación" value={objetoSubasta.monedaLiquidacionSerie} className="form-control text-uppercase" type="text" name="fechaEmision" placeholder="Fecha de emisión" required disabled />
                                    <label className="text-start font-acumin-variable" htmlFor="fechaEmision">Moneda de liquidación</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h4 className="font-acumin-variable ms-3 mt-5">Ofertas</h4>
                <TablaMercado datos={objetoSubasta.ofertas} useValidate={useSubastasLiquidadasOperadorDD} ></TablaMercado>
                <h4 className="font-acumin-variable ms-3 mt-5">Liquidación de montos remanentes</h4>

                <TablaMercado datos={objetoSubasta.remanentes} useValidate={useFondosRemanentes} mercadoPrimario={true} funcion1MercadoPrimario={imprimir} texto1MercadoPrimario="Imprimir" funcion2MercadoPrimario={funcion2} texto2MercadoPrimario={textoFuncion2}  ></TablaMercado>

            </div>
        </>

    )
}