import { useMemo } from "react";

export default function useEmisionesBCV() {
  const columns = useMemo(
    () => [
      {
        Header: "Código emisión",
        accessor: "codigoEmision"
      },
      {
        Header: "Código emisor",
        accessor: "codigoEmisor"
      },
      {
        Header: "Descripción",
        accessor: "descripcion"
      },
      {
        Header: "Monto de la emisión",
        accessor: "montoDeLaEmision"
      },
      {
        Header: "Representación",
        accessor: "formaDeRepresentacion"
      }
    
    ],
    []
  );

  return columns;
}
