import { useMemo } from "react";

export default function useAvisosCobroAutomaticos () {
  const columns = useMemo(
    () => [
      {
        Header: "Mes de cobro",
        accessor: "mesCobro"
      },
      {
        Header: "Código de participante",
        accessor: "datosParticipante.codigoDepositante"
      },
      {
        Header: "Nombre del participante",
        accessor: "datosParticipante.nombreDepositante"
      },
      {
        Header: "Tipo",
        accessor: "tipoParticipanteStr"
      },
      {
        Header: "Estatus",
        accessor: "estatusStr"
      },
      {
        Header: "Folio de la factura",
        accessor: "folio"
      }   
    ],
    []
  );

  return columns;
}
