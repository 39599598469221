//React
import React, { useState, useEffect } from "react";

//Componentes generales
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores';
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";

//APIs
import { traerTodasSolicitudesValoresFisicos } from "../../../../api/resguardoValoresFisicos/registroValoresFisicos/traerTodasSolicitudesValoresFisicos";
import { actualizarEstatusSolicitudDepositoValFis } from '../../../../api/resguardoValoresFisicos/registroValoresFisicos/actualizarEstatusSolicitudDepositoValFis';

//Hooks
import useSolicitudesDepositosValoresFisicos from '../../../../hooks/ResguardoValoresFisicos/useSolicitudesDepositosValoresFisicos';
import useSolicitudesDepositosValoresFisicosRechazadas from '../../../../hooks/ResguardoValoresFisicos/useSolicitudesDepositosValoresFisicosRechazadas';


//Componnetes de la tabla
import TablaUsuarios from "../../../../ComponentesGenerales/TablaUsuarios";


export default function NotificarDepositoValoresFisicos({ history }) {

    //const [solicitudId, setSolicitudId] = useState();

    const [autorizadas, setAutorizadas] = useState(false);
    const [rechazadas, setRechazadas] = useState(false);
    const [data, setData] = useState([]);
    const [spin, setSpin] = useState(true);
    const [erroreshttp, setErroreshttp] = useState();
    const [notificacionExitosa, setNotificacionExitosa] = useState();
    const [notificacionFallida, setNotificacionFallida] = useState();


    const [tituloNotificaciones, setTituloNotificaciones] = useState();
    const [requestResponse, setRequestResponse] = useState();

    //Shows loading screen
    const [loading, setLoading] = useState();

    useEffect(() => {
        getAllApplications();
    }, [])


    //Get authorized or rejected constitution requests depending on the pathname
    const getAllApplications = async () => {

        try {
            let currentPathname = window.location.pathname;

            if (currentPathname === "/notificarSolicitudesDepositoValFisAutorizadas") {
                setTituloNotificaciones("Notificación de solicitudes autorizadas de depósito de valores físicos");

                //get all Authorized applications
                const response = await traerTodasSolicitudesValoresFisicos({ estatus: 2 });

                //console.log(response)
                if (response) {
                    setSpin(false)
                    if ((isNaN(response.error)) === false) {
                        // //console.log("erores")
                        ////console.log(response)
                        setErroreshttp(response)
                        
                    } else {
                        //console.log("auto")
                        setData(response.data);
                        //setCopia(response.data);
                        setAutorizadas(true);
                    }
                }

            } else if (currentPathname === "/notificarSolicitudesDepositoValFisRechazadas") {
                setTituloNotificaciones("Notificación de solicitudes rechazadas de depósito de valores físicos");


                //get all Rejected applications
                const response = await traerTodasSolicitudesValoresFisicos({ estatus: 0 });

                setLoading(false);
                //console.log(response)

                if (response) {
                    setSpin(false)
                    if ((isNaN(response.error)) === false) {
                        // //console.log("erores")
                        ////console.log(response)
                        setErroreshttp(response)
                        
                    } else {
                        setData(response.data);
                        //setCopia(response.data);
                        setRechazadas(true);
                    }
                }
            }

        } catch (error) {
            console.error(error)
        }
    }

    //Handle notifications 
    const notify = async (row) => {

        try {
            //Notify rejected applications
            if (row.original.depositoValorFisicoEstado === 0) {
                const objNotify = {
                    idSolicitud: row.original.depositoValorFisicoId,
                    estatus: 4,
                }

                //console.log(objNotify)
                setLoading(true);

                const response = await actualizarEstatusSolicitudDepositoValFis(objNotify);
                if (response) {
                    setLoading(false);
                    //setCargando(false)
                    if ((isNaN(response.error)) === false) {
                        // respuestaincorrecta           
                        setErroreshttp(response)
                    } else {
                        //console.log(response)
                        if (response.data.isSuccessful === true) {
                            setLoading(false);
                            setRequestResponse(response.data.mensaje);
                            setNotificacionExitosa(true);
        
                        } else {
                            setLoading(false);
                            setRequestResponse(response.data.mensaje);
                            setNotificacionFallida(true);
                        }
                    }
                }


             

                //Notify authorized applications
            } else if (row.original.depositoValorFisicoEstado === 2) {
                const objNotify = {
                    idSolicitud: row.original.depositoValorFisicoId,
                    estatus: 3,
                }
                //console.log(objNotify)

                setLoading(true);

                const response = await actualizarEstatusSolicitudDepositoValFis(objNotify);
                if (response) {
                    setLoading(false);
                    //setCargando(false)
                    if ((isNaN(response.error)) === false) {
                        // respuestaincorrecta           
                        setErroreshttp(response)
                    } else {
                        //console.log(response)
                        if (response.data.isSuccessful === true) {
                            setLoading(false);
                            setRequestResponse(response.data.mensaje);
                            setNotificacionExitosa(true);
        
                        } else {
                            setLoading(false);
                            setRequestResponse(response.data.mensaje);
                            setNotificacionFallida(true);
                        }
                    }
                }

            
            }

        } catch (error) {
            console.error(error)
        }
    }



    //Handle 
    const confirmModal = () => {
        setNotificacionExitosa(false);
        window.location.reload();
    }

    const goBack = () => {
        history.go(-1)
    }

    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {notificacionExitosa &&
                <ComponenteModal mostrarModal={notificacionExitosa} cerrarModal={() => confirmModal()} titulo={requestResponse} textoBoton="Aceptar"></ComponenteModal>
            }
            {notificacionFallida &&
                <ComponenteModal mostrarModal={notificacionFallida} cerrarModal={() => setNotificacionFallida(false)} titulo={requestResponse} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="">
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        {/*<div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 d-flex flex-column">
                            <BarraLateral2  ></BarraLateral2>
                        </div>*/}
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo={tituloNotificaciones}></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <>
                                        <div className="d-flex">
                                            <div className="container py-5">
                                                {autorizadas &&
                                                    <>
                                                        <TablaUsuarios datos={data} displayInformation={notify} useValidate={useSolicitudesDepositosValoresFisicos} textoBoton={"Notificar"} textTitle="Click para notificar"></TablaUsuarios>
                                                        <div className="col-12 col-md-12 px-3 d-flex justify-content-end justify-content-xl-end justify-content-md-end justify-content-sm-end my-3">
                                                            <button type="button" onClick={goBack} className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1 background-royal-blue text-white bg-hover-gold">Atrás</button>
                                                        </div>
                                                    </>
                                                }

                                                {rechazadas &&
                                                    <>
                                                        <TablaUsuarios datos={data} displayInformation={notify} useValidate={useSolicitudesDepositosValoresFisicosRechazadas} textoBoton={"Notificar"} textTitle="Click para notificar"></TablaUsuarios>
                                                        <div className="col-12 col-md-12 px-3 d-flex justify-content-end justify-content-xl-end justify-content-md-end justify-content-sm-end my-3">
                                                            <button type="button" onClick={goBack} className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1 background-royal-blue text-white bg-hover-gold">Atrás</button>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </>

                                }
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    );
}

