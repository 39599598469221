import React, { useState, useEffect } from "react";

//Components
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";

//APIs
import { actualizarSolicitudRegistroDepValFis } from '../../../../api/resguardoValoresFisicos/actualizarSolicitudRegistroDepValFis';


export default function AutorizarSolicitudDepositanteValoresFisicos({ history }) {



    const [loadDataRowFailed, setLoadDataRowFailed] = useState();
    const [loadDataSuccessful, setLoadDataSuccessful] = useState();

    const [solicitudId, setSolicitudId] = useState();

    //Depositante
    const [nombreInstitucion, setNombreInstitucion] = useState();
    const [nacionalidadDepResValFis, setNacionalidadDepResValFis] = useState();
    const [fechaNacimientoDepResValFis, SetfechaNacimientoDepResValFis] = useState();

    //Domicilio
    const [pais, setPais] = useState();
    const [departamento, setDepartamento] = useState();
    const [municipio, setMunicipio] = useState();
    const [ciudad, setCiudad] = useState();
    const [coloniaBarrio, setColoniaBarrio] = useState();
    const [calleAvenida, setCalleAvenida] = useState();
    const [numeroCasa, setNumeroCasa] = useState();
    const [codigoPostal, setCodigoPostal] = useState();

    //Datos representante legal
    const [nombreRepLegal, setNombreRepLegal] = useState();
    const [correoRepLegal, setCorreoRepLegal] = useState();
    const [nacionalidadRepLegal, setNacionalidadRepLegal] = useState();
    const [tipoIdentificacionRepLegal, setTipoIdentificacionRepLegal] = useState();
    const [fechaNacimientoRepLegal, setFechaNacimientoRepLegal] = useState();
    const [numeroIdenRepLegal, setNumeroIdenRepLegal] = useState();
    const [telefonoRepLegal, setTelefonoRepLegal] = useState();
    const [telCelularRepLegal, setTelCelularRepLegal] = useState();

    //Responsable de la solicitud
    const [nombreResponsableSol, setNombreResponsableSol] = useState();
    const [correoResponsableSol, setCorreoResponsableSol] = useState();

    //Input textarea reject field
    const [reasonReject, setReasonReject] = useState();
    //State to show textarea reject field
    const [showRejectField, setShowRejectField] = useState(false);

    //Loading
    const [loading, setLoading] = useState();

    //States to show modal when register is successful or not
    const [registroExitoso, setRegistroExitoso] = useState();
    const [registroFallido, setRegistroFallido] = useState();
    const [rechazoExitoso, setRechazoExitoso] = useState();
    const [rechazoFallido, setRechazoFallido] = useState();

    const [responseRequest, setResponseRequest] = useState();
    const [erroreshttp, setErroreshttp] = React.useState()


    useEffect(() => {
        loadRowData();
    }, []);

    //Gets all the info from the object to show uin front
    const loadRowData = () => {

        if (history.location.state === undefined || history.location.state.objRow.length === 0) {
            setLoadDataRowFailed(true);
        } else {
            const row = history.location.state.objRow[0];

            setSolicitudId(row.depositanteResgvalfisId);

            //Row values 
            //Identificaciòn del depositante
            setNombreInstitucion(row.depositanteResgvalfisNombreCompleto);
            setNacionalidadDepResValFis(row.depositanteResgvalfisNacionalidadId.nombre);
            SetfechaNacimientoDepResValFis(row.depositanteResgvalfisFechaConsNacimientoCadena);


            //Domicilio
            setColoniaBarrio(row.depositanteResgvalfisDirId.cehvalDireccionColonia);
            setCalleAvenida(row.depositanteResgvalfisDirId.cehvalDireccionCalle);
            setNumeroCasa(row.depositanteResgvalfisDirId.cehvalDireccionNumeroCasEdi);
            setCodigoPostal(row.depositanteResgvalfisDirId.cehvalDireccionCp);

            //When address is from Honduras
            if (row.depositanteResgvalfisDirId.cehvalDireccionModelo === null) {
                //Domicilio
                setPais(row.depositanteResgvalfisDirId.catCiudadCatCiudadId.catMunicipioCatMunicipioId.catDepartamentoCatDepartamentoId.catPaisCatPaisId.nombre)
                setDepartamento(row.depositanteResgvalfisDirId.catCiudadCatCiudadId.catMunicipioCatMunicipioId.catDepartamentoCatDepartamentoId.nombre)
                setMunicipio(row.depositanteResgvalfisDirId.catCiudadCatCiudadId.catMunicipioCatMunicipioId.nombre);
                setCiudad(row.depositanteResgvalfisDirId.catCiudadCatCiudadId.nombre);
            //When address is from another country
            } else {
                setPais(row.depositanteResgvalfisNacionalidadId.nombre);
                setDepartamento(row.depositanteResgvalfisDirId.cehvalDireccionModelo.cehvalDireccionManualDepartamento);
                setMunicipio(row.depositanteResgvalfisDirId.cehvalDireccionModelo.cehvalDireccionManualMunicipio);
                setCiudad(row.depositanteResgvalfisDirId.cehvalDireccionModelo.cehvalDireccionManualCiudad);
            }

            //Representante legal
            setNombreRepLegal(row.depositanteResgvalfisNomRepresLeg);
            setCorreoRepLegal(row.depositanteResgvalfisCorreoResp);
            setNacionalidadRepLegal(row.depositanteResgvalfisNacionalidadRepresLeg.nombre);
            setTipoIdentificacionRepLegal(row.depositanteResgvalfisTipoIdeId.nombre);
            setFechaNacimientoRepLegal(row.depositanteResgvalfisFechaNacimientoRepreLedCadena);
            setNumeroIdenRepLegal(row.depositanteResgvalfisNumIde);
            setTelefonoRepLegal(row.depositanteResgvalfisTelefono);
            setTelCelularRepLegal(row.depositanteResgvalfisTelefonoCel);

            //Responsable solicitud
            setNombreResponsableSol(row.depositanteResgvalfisNomResp);
            setCorreoResponsableSol(row.depositanteResgvalfisCorreoResp);

            setLoadDataSuccessful(true);
        }
    }



    //Handle the athorization of the request
    const authorizeRequest = async (e) => {
        e.preventDefault();

        try {
            const objAuthorization = {
                idSolicitud: solicitudId,
                estatus: 2,
            }
            ////console.log(objAuthorization)
            setLoading(true);
            const response = await actualizarSolicitudRegistroDepValFis(objAuthorization);
            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
           ////console.log(response);
           setLoading(false);

           if (response.data.isSuccessful === true) {
               setRegistroExitoso(true);
               setResponseRequest(response.data.mensaje);
           } else {
               setLoading(false);
               setRegistroFallido(true);
               setResponseRequest(response.data.mensaje);
           }
                }
            }

         
        } catch (error) {
            console.error(error);
        }
    }


    //Handle the rejection of the request
    const rejectRequest = async (e) => {
        e.preventDefault();

        try {
            const objReject = {
                idSolicitud: solicitudId,
                descripcion: reasonReject,
                estatus: 0,
            }
            //console.log(objReject)

            setLoading(true);
            const response = await actualizarSolicitudRegistroDepValFis(objReject);
            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    setLoading(false);
                    //console.log(response)
        
                    if (response.data.isSuccessful === true) {
                        setRechazoExitoso(true);
                        setResponseRequest(response.data.mensaje);
                    } else {
                        setRechazoFallido(true);
                        setResponseRequest(response.data.mensaje);
                    }
                }
            }

       
        } catch (error) {
            console.error(error);
        }
    }


    //Shows the reject field
    const showReject = () => {
        if (showRejectField === false) {
            setShowRejectField(true);
        } else {
            setShowRejectField(false);
        }
    }

    const goToApplicationsTable = () => {
        history.push({
            pathname: 'solicitudesResguardoValoresFisicosAutorizar',
        })
    }

    //Executes when the emitter was successfully registered
    const goToInicioCehval = () => {
        setRegistroExitoso(false)
        history.push({
            pathname: '/inicioCehval',
        })
    }


    return (
        <>
          {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {loading &&
                <PantallaCargando />
            }
            {loadDataRowFailed &&
                <ComponenteModal mostrarModal={loadDataRowFailed} cerrarModal={goToInicioCehval} titulo={`Ocurrio algún error, será redireccionado al inicio. Revise su conexión o vuelva a iniciar sesión.`} textoBoton="Aceptar"></ComponenteModal>
            }

            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={goToApplicationsTable} titulo={responseRequest} textoBoton="Aceptar"></ComponenteModal>
            }
            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={() => { setRegistroFallido(false) }} titulo={responseRequest} textoBoton="Aceptar"></ComponenteModal>
            }
            {rechazoExitoso &&
                <ComponenteModal mostrarModal={rechazoExitoso} cerrarModal={goToApplicationsTable} titulo={responseRequest} textoBoton="Aceptar"></ComponenteModal>
            }
            {rechazoFallido &&
                <ComponenteModal mostrarModal={rechazoFallido} cerrarModal={() => { setRechazoFallido(false) }} titulo={responseRequest} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="fluid-container">
                
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        {/*<div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 d-flex flex-column">
                            <BarraLateral2  ></BarraLateral2>
                        </div>*/}
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Solicitud de registro de depositante de valores físicos por autorizar"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <section className="p-5 d-flex justify-content-center">
                                            <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded p-3">
                                                <div className="col-12">
                                                    {loadDataSuccessful &&
                                                        <form className="needs-validation font-acumin-variable text-royal-blue">
                                                            <div className="row mt-4">

                                                                {/*IDENTIFICACIÓN DEL DEPOSITANTE */}
                                                                <h5 className="text-royal-blue font-acumin-variable">I. Identificación del depositante de resguardo de valores físicos</h5>
                                                                <div className="col-12 col-md-12 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Código depositante transferente" value={nombreInstitucion} className="form-control text-royal-blue" name="codigoDepositanteTransferente" type="text"  required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="codigoDepositanteTransferente">Nombre completo de la institución</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <select className="form-select" id="" name="nacionalidad" required disabled>
                                                                            <option>{nacionalidadDepResValFis}</option>
                                                                        </select>
                                                                        <label htmlFor="validationCustom04" className="form-label ">Nacionalidad </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 form-floating mb-3">
                                                                    <div className="form-floating">
                                                                        <input type="text" value={fechaNacimientoDepResValFis} className="form-control border" name="fechaNacimiento" required disabled />
                                                                        <label htmlFor="fechaNacimiento" className="form-label ">Fecha de constitución/nacimiento </label>
                                                                    </div>
                                                                </div>

                                                                {/*DOMICILIO*/}
                                                                <h5 className="text-royal-blue font-acumin-variable">Dirección completa del domicilio</h5>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating">
                                                                        <select className="form-select" id="" name="pais" required disabled>
                                                                            <option>{pais}</option>
                                                                        </select>
                                                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">País</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Cantidad de valores a transferir" value={coloniaBarrio} className="form-control fs-5" name="coloniaBarrio" type="text" placeholder="Colonia o barrio" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="coloniaBarrio">Colonia o barrio</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Cantidad de valores a transferir" value={departamento} className="form-control fs-5" name="departamento" type="text" placeholder="Departamento" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="departamento">Departamento</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Cantidad de valores a transferir" value={calleAvenida} className="form-control fs-5" name="cantidadValores" type="text" placeholder="Calle, avenida o bloque" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="cantidadValores">Calle, avenida o bloque</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Cantidad de valores a transferir" value={municipio} className="form-control fs-5" name="municipio" type="text" placeholder="Municipio" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="municipio">Municipio </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Cantidad de valores a transferir" value={numeroCasa} className="form-control fs-5" name="numeroCasa" type="text" placeholder="Número de casa" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="cantidadValores">Número de casa</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Cantidad de valores a transferir" value={ciudad} className="form-control fs-5" name="ciudad" type="text" placeholder="Ciudad" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="ciudad">Ciudad</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Cantidad de valores a transferir" value={codigoPostal} className="form-control fs-5" name="cantidadValores" type="text" placeholder="Cantidad de valores" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="cantidadValores">Código postal</label>
                                                                    </div>
                                                                </div>


                                                                {/*REPRESENTANTE LEGAL*/}
                                                                <h5 className="text-royal-blue font-acumin-variable mt-4">II. Datos del representante legal</h5>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Ingrese el nombre completo del representante legal" value={nombreRepLegal} className="form-control text-royal-blue " name="nombreRepLegal" type="text" placeholder="Nombre del representante legal" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="nombreRepLegal">Nombre completo </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Ingrese el correo del representante legal" value={correoRepLegal} className="form-control text-royal-blue " name="correoRepLegal" type="text" placeholder="Correo representalte legal" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="correoRepLegal">Correo electrónico</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating">
                                                                        <select className="form-select" title="Seleccione la nacionalidad del representante legal" id="nacionalidadRepLegal" name="Nacionalidad del representante legal" required disabled>
                                                                            <option value="">{nacionalidadRepLegal}</option>
                                                                        </select>
                                                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Nacionalidad</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 form-floating mb-3">
                                                                    <div className="form-floating">
                                                                        <input value={tipoIdentificacionRepLegal} title="Fecha de nacimiento del representante legal" type="text" className="form-control text-royal-blue border " name="tipoIdentificacionRepLegal"  required disabled />
                                                                        <label htmlFor="tipoIdentificacionRepLegal" className="form-label ">Tipo de identificación</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Ingrese número de identificación del representante legal" value={numeroIdenRepLegal} className="form-control text-royal-blue" name="numIdentificacionRepLegal" type="text"  required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="numIdentificacionRepLegal">Número de identificación </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 form-floating mb-3">
                                                                    <div className="form-floating">
                                                                        <input value={fechaNacimientoRepLegal} title="Fecha de nacimiento del representante legal" type="text" className="form-control text-royal-blue border " name="fechaNacimientoRepLegal" required disabled />
                                                                        <label htmlFor="fechaNacimientoRepLegal" className="form-label ">Fecha de nacimiento</label>
                                                                    </div>
                                                                </div>
                                                              
                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Ingrese el teléfono del representante legal" className="form-control text-royal-blue" value={telefonoRepLegal} name="telefonoRepLegal" type="text"  required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="telefonoRepLegal">Teléfono</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Ingrese el teléfono del representante legal" className="form-control text-royal-blue" value={telCelularRepLegal} name="telCelularRepLegal" type="text" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="telCelularRepLegal">Teléfono celular</label>
                                                                    </div>
                                                                </div>
                                                                <h5 className="text-royal-blue font-acumin-variable mt-4">III. Responsable de la solicitud de registro</h5>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Ingrese el nombre del reponsable de la solicitud de registro" value={nombreResponsableSol} className="form-control text-royal-blue " name="nombreResponsableRegistro" type="text" placeholder="Nombre responsable registro" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="nombreResponsableRegistro">Nombre completo</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-6 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Ingrese el correo del responsable de la solicitud de registro" value={correoResponsableSol} className="form-control text-royal-blue " name="correoResponsableRegistro" type="text" placeholder="Correo del responsable del registro" required disabled />
                                                                        <label className="text-start font-acumin-variable" htmlFor="correoResponsableRegistro">Correo electrónico</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                    <button type="button" onClick={showReject} className="btn col-6 col-sm-3 col-md-3 col-lg-3 col-xl-2 background-royal-blue text-white bg-hover-red me-2">Rechazar</button>
                                                                    <button type="button" onClick={authorizeRequest} className="btn col-6 col-sm-3 col-md-3 col-lg-3 col-xl-2 background-royal-blue text-white bg-hover-gold">Autorizar</button>
                                                                </div>
                                                                {showRejectField &&
                                                                    <>
                                                                        <div className="col-12 col-md-12 mb-3">
                                                                            <div className="form-floating ">
                                                                                <textarea title="Cantidad de valores a transferir" onChange={(e) => setReasonReject(e.target.value)} className="form-control fs-5 text-royal-blue rejectionReason" name="motivoRechazo" type="text" placeholder="" required ></textarea>
                                                                                <label className="text-start font-acumin-variable" htmlFor="motivoRechazo">Motivo del rechazo</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                            <button type="button" onClick={rejectRequest} className="btn background-royal-blue text-white bg-hover-gold" title="Clic para confirmar rechazo de solicitud">Confirmar</button>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </form>
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    )
}