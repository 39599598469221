import React from "react";
import { Link } from 'react-router-dom';

//Images
import dolares from '../../../assets/logosCehval/dolares.jpg'
import lempiras from '../../../assets/logosCehval/lempiras.jpg'

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";


export default function MenuFondosVirtuales({ history }) {

    return (
        <div className="fluid-container">
            
            <main className="min-height-40" >
                <div className="row row-wrap g-0">
                    
                    
               
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Fondos virtuales"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5 mb-5">
                                        <div className="row ">
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                                            <Link  to={{
                                                        pathname: '/consultarFondosVirtuales',
                                                        state: 1
                                                    }}  className="text-decoration-none text-dark">
                                                <div className="card" >
                                                    <img className="" src={lempiras} alt="imagenFondo" />
                                                    <div className="card-body text-center font-acumin-variable">
                                                        <h5 className="card-title">Lempiras</h5>
                                                    </div>
                                                </div>
                                                </Link>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                                            <Link  to={{
                                                        pathname: '/consultarFondosVirtuales',
                                                        state: 2
                                                    }}  className="text-decoration-none text-dark">
                                                <div className="card" >
                                                    <img className="" src={dolares} alt="imagenFondo" />
                                                    <div className="card-body text-center font-acumin-variable">
                                                        <h5 className="card-title">Dólares</h5>
                                                    </div>
                                                </div>
                                                </Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </main >
        </div>
    );

}
