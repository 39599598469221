import { useMemo } from "react";

export default function useEstadosCuenta () {
  const columns = useMemo(
    () => [
      {
        Header: "Periodo",
        accessor: "periodo"
      },
      {
        Header: "Código de participante",
        accessor: "codigoParticipante"
      },
      {
        Header: "Nombre del participante",
        accessor: "nombreParticipante"
      },
      {
        Header: "Tipo de participante",
        accessor: "tipoParticipanteStr"
      }   
    ],
    []
  );

  return columns;
}
