import { useMemo } from "react";

export default function useCuentasCehval() {
    const columns = useMemo(
        () => [
            {
                Header: "Cuenta CEHVAL",
                accessor: "cuenta"
            },
        ],
        []
    );

    return columns;
}
