import { instance } from '../../../apiGlobal';

export async function traerTodosAvisosPendientesPagos() {
  try {
    const response = await instance.get(`/tarificacion/mostrar/avisoscobro/p/pago`, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    return response;
  } catch (error) {
    return error
  }
}

