import { useMemo } from "react";

export default function useSeriesColumns() {
    const columns = useMemo(
        () => [
            {
                Header: "Procedencia",
                accessor: "cehvalSerieProcedenciaBcvStr"
              },
            {
                Header: "Nombre serie",
                accessor: "cehvalSerieNombreSerie"
            },
          /*  {
                Header: "CÓDIGO SERIE",
                accessor: "cehvalSerieCodigo"
            },
         /*   {
                Header: "ESTATUS",
                accessor: "cehvalSerieEstatus"
            },*/
            {
                Header: "Nombre emisión",
                accessor: "cehvalSerieNombreEmi"
            },
            {
                Header: "Código emisión",
                accessor: "cehvalEmisionCodigo.cehvalEmisionCodigo"
            },
            {
                Header: "Código serie",
                accessor: "cehvalSerieCodigo"
            },
            {
                Header: "Código ISIN",
                accessor: "cehvalSerieCodigoIsin"
            },
          /*  {
                Header: "FECHA EMISIÓN",
                accessor: "cehvalSerieFechaEmi"
            },
            {
                Header: "FECHA VENCIMIENTO",
                accessor: "cehvalSerieFechaVen"
            },
            {
                Header: "TASA INTERÉS NOMINAL",
                accessor: "cehvalSerieTasIntNom"
            },*/
            {
                Header: "Monto de la serie",
                accessor: "cehvalSerieMontoCadena"
            },
          /*  {
                Header: "CANTIDAD DE INSTRUMENTOS EMITIDOS",
                accessor: "cehvalSerieCantInstEmi"
            },
            {
                Header: "SPREAD",
                accessor: "cehvalSerieSpread"
            },
            {
                Header: "VALOR NOMINAL DE CADA TÍTULO",
                accessor: "cehvalSerieValorNomTitulo"
            },
           {
                Header: "FORMA DE COLOCACIÓN",
                accessor: "catFormaColocacionCatForColId.catForColColocacion"
            },
            {
                Header: "MONEDA DE LA SERIE",
                accessor: "cehvalSerieCatMonedaIdSerie.nombre"
            },
            {
                Header: "PERIODICIDAD",
                accessor: "catNumeroPeriocidadCatNumPerId.catNumPerPeriodicidad"
            },
            {
                Header: "MONEDA DE LIQUIDACIÓN",
                accessor: "cehvalSerieCatMonedaIdLiq.nombre"
            },
            {
                Header: "TIPO DE TASA",
                accessor: "cehvalSerieCatTipTasId.catTipTasTasa"
            },
            {
                Header: "TIPO TASA VARIABLE",
                accessor: "catTipoTasaVariableCatTipoTasaVariableId.catTipoTasaVariableTasaRefencia"
            },*/
        ],
        []
    );

    return columns;
}
