import { useMemo } from "react";

export default function useColumns() {
 const columns = useMemo(
   () => [  
    {
      Header: "Procedencia",
      accessor: "cehvalEmisorProcedenciaBcvStr"
    },
    {
      Header: "Código del emisor",
      accessor: "cehvalEmisorCodigo"
    },
     {
       Header: "Nombre del emisor",
       accessor: "cehvalEmisorNombreCom"
     },
     {
       Header: "RTN",
       accessor: "cehvalEmisorRtn"
     }, 
     {
      Header: "Nombre completo del representante legal",
      accessor: "cehvalEmisorNombreCompletoRepreLeg"
    }, 
  
    /* {
      Header:"ID registro de",
      accessor: "cehvalEmisorCodigo"
    },*/
   ],
   []
 );

 return columns;
}
