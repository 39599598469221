import { useMemo } from "react";

export default function useConsultarCasasBolsa() {
  const columns = useMemo(
    () => [
      {
        Header: "Nombre de la Casa de Bolsa",
        accessor: "nombreCasaBolsa"
      },
      {
        Header: "Código en CEHVAL",
        accessor: "codigoCehval"
      },
    
      {
        Header: "Código BCV",
        accessor: "codigoBcv"
      },
     
      
    ],
    []
  );

  return columns;
}
