import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";


//Components
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import TablaUsuarios from "../../../../ComponentesGenerales/TablaUsuarios";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";

//APIs
import { traerClientesDD } from "../../../../api/clientesDepositanteDirecto/traerClientesDD";

//Hooks
import useClientesDD from "../../../../hooks/useClientesDD";



//Muestra todos los clientes de un depositante directo
export default function ClientesDD({ history }) {
    const location = useLocation();
    const tipo = location.state;

    //States to show modal when data doesnt load
    const [resquestFail, setRequestFail] = useState();

    const [data, setData] = useState([]);
    const [copia, setCopia] = useState();
    const [erroreshttp, setErroreshttp] = React.useState()

    //Get Depositante Directo code from localstorage to use in query to get clients 
    const codigo = (JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo;

    useEffect(() => {
        getClients();
    }, [])

    const historyReact = useHistory();

    //Hanlde api to get all depositante directo clients
    const getClients = async () => {
        try {

            const response = await traerClientesDD({ codigo: codigo, estatus: 5 });
            if (response) {
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    if (response.status === 200) {
                        let filtrados = response.data.filter(word => word.cehvalDepositanteCodigoCehval != codigo);
                        setData(filtrados);
                        setCopia(filtrados);
        
                    } else {
                        window.location.replace("/inicioCehval");
                    }
                }
            }
            
         

        } catch (error) {
            console.error(error)
        }
    }

    //Handle when user click "Ver cuentas" -  shows all client accounts
    const viewClientAcount = (row) => {

        const nombreDepositanteIndirectoPJ = row.original.cehvalDepositantePersonaJurId.cehvalPersonaJurNombre;
        const nombreDepositanteIndirectoPN = row.original.cehvalDepositanteNombreCompleto;
        const codigoDepositante = row.original.cehvalDepositanteCodigoCehval;


        const splitcodigoDepositante = codigoDepositante.match(/[a-z]+|[^a-z]+/gi).join(" ").replace(/\s+/g, " ");

        //Si elije un depositante indirecto persona juridica
        if (splitcodigoDepositante.includes("DINDPJ")) {
            history.push({
                pathname: '/cuentasClienteDD',
                state: {
                    objClienteRow: {
                        tipo:tipo,
                        nombreDepositanteIndirecto: nombreDepositanteIndirectoPJ,
                        codigoDepositanteIndirecto: codigoDepositante,
                    },
                }
            });

            //Si elije un depositante indirecto persona natural
        } else {
            history.push({
                pathname: '/cuentasClienteDD',
                state: {
                    objClienteRow: {
                        tipo:tipo,
                        nombreDepositanteIndirecto: nombreDepositanteIndirectoPN,
                        codigoDepositanteIndirecto: codigoDepositante,
                    },
                }
            });
        }
    }

    //Handle modal if data for table doesn load
    const goHome = () => {
        setRequestFail(false);
        window.location.replace("/inicioCehval");
    }

    return (
        <>
          {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            
            {resquestFail &&
                <ComponenteModal mostrarModal={resquestFail} cerrarModal={goHome} titulo={`Ocurrio algún error, será redireccionado al inicio. Revise su conexión o vuelva a iniciar sesión.`} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="fluid-container">
                
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                       
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Mis clientes"></BannerTitulo>
                                <div className="row justify-content-center align-items-center table-container g-0">

                                    {copia ?
                                        <div className="container py-5">
                                            <TablaUsuarios datos={data} displayInformation={viewClientAcount} useValidate={useClientesDD} textoBoton={"Ver cuentas"} textTitle="Cuentas de valores de este cliente" ></TablaUsuarios>
                                            <div className="p-3">
                                                <button className="btn background-royal-blue text-white font-acumin-variable" onClick={() => { historyReact.goBack() }}>Atrás</button>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className="row row-wrap justify-content-center">
                                                <h4 className="col-12 text-center">Cargando...</h4>
                                                <div className="col-12 spinner-border" role="status"></div>
                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    );
}

