import { instance } from '../apiGlobal';

  export async function traerGeneros(obj) {
    try {
      const response = await instance.get(`/cat/generos`, {
        headers: {
          'Content-Type': 'application/json'          
        }
      })
      return response.data
    } catch (error) {
      return error
    }
  }
  