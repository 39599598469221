import React from "react";


//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import TablaUsuarios from '../../../ComponentesGenerales/TablaUsuarios'

//Hooks
import useCuentaValores from "../../../hooks/useCuentaValores";

//APIs
import { traerInventarioValores } from "../../../api/cuentaValoresTransito/traerInventarioValores";




export default function InventarioValores({ history }) {
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState();
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()

   // const columns = useCuentaValores();

    React.useEffect(() => {
        verCuentaValores()
    }, [])

    const verCuentaValores = async () => {
        // const respuesta = await traerOperaciones() 
        const response = await traerInventarioValores()
        if (response) {
            setSpin(false)
            if ((isNaN(response.error)) === false) {
              
                setErroreshttp(response)
                
            } else {
                ////console.log(response)
                // //console.log(respuesta)
                setData(response)
                setCopia(response)
            }
        }
 
    }

    return (
        <>


            <div className="fluid-container">

           {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }     
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12">
                            <div className="">
                            <BannerTitulo titulo="Inventario de valores"></BannerTitulo>
                            {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                <div className="d-flex">
                                    <div className="container py-5">
                                        {copia &&
                                            <TablaUsuarios datos={data} useValidate={useCuentaValores} ></TablaUsuarios>
                                        }
                                    </div>
                                </div>
}
                            </div>
                        </div>
                    </div>
                </main >

           
                
            </div>
        </>
    );
}

