//React
import React from "react";
import { Link } from 'react-router-dom';

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";

//Images
import cuentasCehval from "../../../assets/imagenesCuentas/cuentasCehval.jpg";
import cuentasDepositantes from "../../../assets/imagenesCuentas/cuentasDepositantes.jpg";


export default function MenuTLPOperador() {
    return (
        <>
            <div className="fluid-container">
                <main className='container-min-height' >
                    <div className="row row-wrap g-0">
        

                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo className="mb-5" titulo="TLP"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container font-acumin-variable py-5 mb-5">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3 ">
                                                <Link to={{ pathname: "/cuentasCehval" }} className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="imageCard" src={cuentasCehval} alt="imagenFondo" />
                                                        <div className="card-body text-center">
                                                            <h5 className="card-title">Cuentas CEHVAL</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                                                <Link to={{ pathname: "/depositantesCehval" }} className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="imageCard" src={cuentasDepositantes} alt="imagenFondo" />
                                                        <div className="card-body text-center">
                                                            <h5 className="card-title">Depositantes</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}

