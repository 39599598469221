import { useMemo } from "react";

export default function useConfirmacionMontos() {
    const columns = useMemo(
        () => [
            {
                Header: "Nombre de cliente CEHVAL",
                accessor: "nombreClienteCehval"
            },
            {
                Header: "Código de cliente CEHVAL",
                accessor: "codigoClienteCehval"
            },
            {
                Header: "Exento de impuestos",
                accessor: "exentoImpuestos"
            },
            {
                Header: "Cantidad de valores",
                accessor: "cantidadValores"
            },
            {
                Header: "Monto a pagar",
                accessor: "montoAPagarStr"
            },
            {
                Header: "Retenciones",
                accessor: "retencionesPorCehvalStr"
            },
            {
                Header: "Moneda",
                accessor: "moneda"
            },
        ],
        []
    );

    return columns;
}