import React from "react";
import '../../../../styles/stylesheet.css'
import { traerCasasBolsa } from '../../../../api/catalogos/traerCasasBolsa'
import { FormContext } from './form_context';
import { FormContext2 } from './form_context2';
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";

export default function ActualizarDI6({ tipo }) {
    const [value,] = React.useContext(FormContext);
    const [value2, setValue2] = React.useContext(FormContext2);
    const [hayCasaBolsa, setHayCasaBolsa] = React.useState()
    const [casasBolsa, setCasasBolsa] = React.useState([])
    const [codigo, setCodigo] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()


    const guardarDatos = (e) => {
        const { name, value } = e.target;
        if (name === "nombreBolsa") {
            //console.log(value)
            setCodigo(value)
        }
        setValue2((prev) => {
            const datos = { ...prev.datos, [name]: value };
            return { ...prev, datos };
        });
    }

    const definirCasaBolsa = (e) => {
        //console.log(e.target.value)
        if (e.target.value === "SI") {
            setValue2((prev) => {
                const datos = { ...prev.datos, ["nombreBolsa"]: "" };
                return { ...prev, datos };
            });
            setCodigo("")
            setHayCasaBolsa(false)

        } else {
            setHayCasaBolsa(true)
        }
    }

    React.useEffect(() => {
        if (value2.datos.nombre === value2.datos.nombreBolsa) {
            setHayCasaBolsa(false)
        } else {
            setHayCasaBolsa(true)
        }

        traerCasasDeBolsa()
    }, [])

    const traerCasasDeBolsa = async () => {
        const catalogoCasasBolsa = await traerCasasBolsa()
        //console.log(catalogoCasasBolsa)
        if (catalogoCasasBolsa) {
            if ((isNaN(catalogoCasasBolsa.error)) === false) {
              
                setErroreshttp(catalogoCasasBolsa)
                
            } else {
               
               const filtrados = catalogoCasasBolsa.filter(word => (word.cehvalDepositanteCodigoCehval.indexOf("DDIRPJ") >= 0))
                setCasasBolsa(filtrados)
            }
        }
    }


    return (
        <>
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            <div className="card-body pt-3 ">
                <h5 className="text-royal-blue font-acumin-variable " >VI. Casa de bolsa intermediaria</h5>
                {value.datos.establecerModificacion ?
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div className="border rounded p-3" >
                                <p>¿Eres una casa de bolsa?</p>

                                <div className="d-flex justify-content-start">
                                    <div className="contenedorBotonesStteper">
                                        < input disabled type="radio" className="form-check-input me-2" value={"SI"} id="existeCasaBolsa1" name="existeCasaBolsa" required />
                                        <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="existeCasaBolsa1">Si</label>
                                    </div>

                                    <div className="contenedorBotonesStteper">
                                        < input disabled type="radio" className="form-check-input me-2" value={"NO"} id="existeCasaBolsa2" name="existeCasaBolsa" checked />
                                        <label className="form-check-label textoFormularioDepositantedirecto mt-1" htmlFor="existeCasaBolsa2">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {hayCasaBolsa &&
                            <>
                                <div className="col-md-4 form-floating mb-3">
                                    <p className="text-royal-blue font-acumin-variable text-start" >Selecciona la casa de bolsa</p>
                                    <div className="form-floating mb-2">
                                        {value2.datos.casaBolsaInicio ?
                                            <select className="form-select fs-5 border  " value={value2.datos.nombreBolsa} onChange={(e) => {
                                                guardarDatos(e)
                                                guardarDatos(e)
                                                setValue2((prev) => {
                                                    const datos = { ...prev.datos, ["casaBolsaInicio"]: false };
                                                    return { ...prev, datos };
                                                });
                                            }} id="nombreBolsa" name="nombreBolsa" required>
                                                <option value={value2.datos.nombreBolsa}>{value2.datos.nombreBolsa} </option>
                                                {
                                                    casasBolsa.filter((item => item.cehvalDepositanteNombreCompleto !== value2.datos.nombreBolsa)).map((item, index) => (
                                                        <option key={index} value={item.cehvalDepositanteCodigoCehval} >{item.cehvalDepositantePersonaIcbiId.cehvalPersonaJurNombre}</option>
                                                    ))
                                                }
                                            </select>
                                            :
                                            <select className="form-select fs-5 border  " value={value2.datos.nombreBolsa} onChange={(e) => guardarDatos(e)} id="nombreBolsa" name="nombreBolsa" required>
                                                <option value=""> </option>
                                                {
                                                    casasBolsa.map((item, index) => (
                                                        <option key={index} value={item.cehvalDepositanteCodigoCehval} >{item.cehvalDepositantePersonaIcbiId.cehvalPersonaJurNombre}</option>
                                                    ))
                                                }
                                            </select>


                                        }
                                        <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Casa de bolsa intermediaria *</label>
                                    </div>
                                </div>

                                <div className="col-md-2 form-floating d-flex align-items-end mb-3">
                                    <div className="form-floating mb-2">
                                        < input disabled type="text" className="form-control fs-5 border  " value={codigo} id="cargo_6" name="cargo_6" />
                                        <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Código *</label>
                                    </div>
                                </div>
                            </>
                        }
                    </div>


                    :
                    <>

                        <div className="row mb-3">
                            <div className="col-md-6">
                                <div className="border rounded p-3" >
                                    <p>¿Eres una casa de bolsa?</p>
                                    <div className="d-flex justify-content-start">
                                        <div className="contenedorBotonesStteper">
                                            {value.datos.nombre === value.datos.nombreBolsa ?
                                                < input disabled type="radio" className="form-check-input me-2" value={"SI"} onChange={(e) => definirCasaBolsa(e)} id="existeCasaBolsa1" name="existeCasaBolsa" checked />
                                                :
                                                < input disabled type="radio" className="form-check-input me-2" value={"SI"} onChange={(e) => definirCasaBolsa(e)} id="existeCasaBolsa1" name="existeCasaBolsa" required />
                                            }

                                            <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="existeCasaBolsa1">Si</label>
                                        </div>

                                        <div className="contenedorBotonesStteper">
                                            {value.datos.nombre === value.datos.nombreBolsa ?
                                                < input disabled type="radio" className="form-check-input me-2" value={"NO"} onChange={(e) => definirCasaBolsa(e)} id="existeCasaBolsa2" name="existeCasaBolsa" required />
                                                :
                                                < input disabled type="radio" className="form-check-input me-2" value={"NO"} onChange={(e) => definirCasaBolsa(e)} id="existeCasaBolsa2" name="existeCasaBolsa" checked />
                                            }

                                            <label className="form-check-label textoFormularioDepositantedirecto mt-1" htmlFor="existeCasaBolsa2">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                        {value.datos.nombre !== value.datos.nombreBolsa &&
                            <div className="row mb-3">
                                <>
                                    <div className="col-md-4  mb-3">
                                        <div className="form-floating mb-2">
                                            < input disabled type="text" className="form-control fs-5 border  " value={value.datos.nombreBolsa} id="cargo_6" name="cargo_6" />
                                            <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Casa de bolsa intermediaria </label>
                                        </div>
                                    </div>


                                </>
                            </div>
                        }

                    </>

                }

            </div>
        </>
    );
}

