import { Modal } from "react-bootstrap";
import React from 'react';
export default function ComponenteModal({ mostrarModal, cerrarModal, titulo, textoBoton, body }) {

    const onHideModal = () => {
    }

    return (
        <>
            <Modal show={mostrarModal} animation={false} centered onHide={onHideModal}>
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0 ">Mensaje del sistema</h5>
                        <button type="button" onClick={cerrarModal} className="btn-close" aria-label="Close"></button>
                    </div>

                    {body ?
                        body.map((item, index) => (
                            <p className="text-center font-acumin-variable m-0 fs-5 mb-1 " >{item}</p>
                        ))
                        :
                        <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >{titulo}</p>
                    }
                    <div className="row justify-content-center">
                        <button onClick={cerrarModal} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >{textoBoton}</button>
                    </div>
                </div>
            </Modal>
        </>
    )
}