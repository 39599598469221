import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAllowed } from '../authRole/Auth'


export default function PrivateRoute({ component: Component, hasRole: Rol, hasPermission: Permission, ...rest }) {
  let arreglo=[];

  if(JSON.parse(window.sessionStorage.getItem('usuarioRol'))){
     arreglo =   JSON.parse(window.sessionStorage.getItem('usuarioRol')).permisos.map(function (item) {
      return item.nombre;

   })

  }


  const actual = () => JSON.parse(window.sessionStorage.getItem('usuario')) !== null


  return (
    <>

      <Route
        {...rest}
        render={props => (
          (actual() === true) ?//revisa que exista un token, de lo contrario te reedirije al login
            Permission ?//si existe un permiso entra, de lo contrario reenderiza el componente correspondiente
              isAllowed(arreglo, [Permission]) ?//pregunta si tiene permiso para ver la ruta de lo contrario te redirige a componente no autorizado
                <Component {...props} /> :
                <Redirect to='/noAutorizado' />
              :
              <Component {...props} />
            :
            <Redirect to='/login' />//si no existe el token te redirije al login
        )}
      />

    </>
  )
}



