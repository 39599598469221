//React
import React, { useState, useEffect } from "react";

//Componentes generales
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../ComponentesGenerales/ManejadorErrores';

import TablaMercado from "../../../ComponentesGenerales/TablaMercado";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { consultarHistoricoFondosConciliados } from "../../../api/MercadoSecundario/consultarHistoricoFondosConciliados";
import { imprimirTablaMercadoSecundario } from "../../../api/MercadoSecundario/imprimirTablaMercadoSecundario.js";

//Hooks
import useHistoricoDepositoFondosConciliados from '../../../hooks/MercadoSecundario/useHistoricoDepositoFondosConciliados';

export default function HistoricoDepositosFondosConciliados({ history }) {
    const [copia, setCopia] = useState();
    const [data, setData] = useState([]);
    const [spin, setSpin] = useState(true);
    const [erroreshttp, setErroreshttp] = useState();
    //const [cargando, setCargando] = useState()
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        traerHistoricos();
    }, []);

    const traerHistoricos = async () => {
        const response = await consultarHistoricoFondosConciliados()
        //console.log("respuesta")
        //console.log(response)
        if (response) {
            setSpin(false)
            if ((isNaN(response.error)) === false) {
                setErroreshttp(response)
            } else {

                //   var filtrados = numeroSubastas.filter(word => (word.estatusStr==="Liquidada"||word.estatusStr==="Valores liquidados"));

                setData(response)
                setCopia(response)
            }
        }

    }



    const printTable = async () => {
        //console.log("imprimir")
        setLoading(true)
        const myObj = {
            autorizado: true,
            tipoValor: "HISTORICO_DEPOSITO_FONDOS"
        }

        try {

            const response = await imprimirTablaMercadoSecundario(myObj)
            if (response) {
                //setLoading(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta 
                    setLoading(false)
                    setErroreshttp(response)
                } else {
                    //console.log(response)
                    const file = new Blob(
                        [response],
                        { type: 'application/pdf' });//Build a URL from the file
                    const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                    window.open(fileURL);
                    setLoading(false)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }


    return (
        <>
            <div className="">

                {loading &&
                    <PantallaCargando />
                }

                
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Histórico depósitos de fondos conciliados"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-2">
                                            {copia &&
                                                <>
                                                    <TablaMercado datos={data} useValidate={useHistoricoDepositoFondosConciliados} mercadoPrimario={true} funcion1MercadoPrimario={printTable} texto1MercadoPrimario="Imprimir"></TablaMercado>

                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}





