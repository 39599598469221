import React from "react";
import '../../../../styles/stylesheet.css'
import { FormContext } from './form_context';
import { FormContext2 } from "./form_context2";
//import { FormContext2 } from './form_context2';
import { traerNacionalidad } from "../../../../api/catalogos/traerNacionalidad";
import { traerEstadosCiviles } from "../../../../api/catalogos/traerEstadosCiviles";
import { traerGeneros } from "../../../../api/catalogos/traerGeneros";
import { traerTiemposLaborales } from "../../../../api/catalogos/traerTiemposLaborales";
import { traerTiposIdentificacion } from "../../../../api/catalogos/traerTiposIdentificacion";
import { traerPuestos } from "../../../../api/catalogos/traerPuestos";

import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";
import SpinnerSend from "../../../../ComponentesGenerales/SpinnerSend";

import { insertarPuesto } from "../../../../api/InsertarACatalogos/insertarPuesto";
import { FaLessThanEqual } from "react-icons/fa";

export default function ActualizarDD2({ history }) {
    const [value, setValue] = React.useContext(FormContext);
    const [value2, setValue2] = React.useContext(FormContext2);

    const [nacionalidad, setNacionalidad] = React.useState([])
    const [estadosCiviles, setEstadosCiviles] = React.useState([])
    const [generos, setGeneros] = React.useState([])
    const [tiposIdentificacion, setTiposIdentificacion] = React.useState([])
    const [tiemposLaborales, setTiemposLaborales] = React.useState([])
    const [puestos, setPuestos] = React.useState([])
    const [agregarPuesto, setAgregarPuestos] = React.useState()
    const [puestoNuevo, setPuestoNuevo] = React.useState()
    const [fechaLimiteNacimiento, setFechaLimiteNacimiento] = React.useState()
    const [fechaLimiteDocumentos, setFechaLimiteDocumentos] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [spinSend, setSpinSend] = React.useState(false)
    const [errorNumeroIdentificacion, setErrorNumeroIdentificacion] = React.useState()
    const [errorRtn, setErrorRtn] = React.useState()
    const [errorTelefono1, setErrorTelefono1] = React.useState()
    const [errorTelefono2, setErrorTelefono2] = React.useState()



    const [fechaNacimientoInicio, setFechaNacimientoInicio] = React.useState()
    const [fechaRepresentacionInicio, setFechaRepresentacionInicio] = React.useState()



    const guardarDatos = (e) => {
        const { name, value } = e.target;
        ////console.log(name)
        ////console.log(value)

        if (name === "puesto") {
            if (value === "OTRO") {
                setAgregarPuestos(true)
            } else {
                setValue2((prev) => {
                    const datos = { ...prev.datos, [name]: value };
                    return { ...prev, datos };
                });
                setAgregarPuestos(false)
            }

        } else {
            setValue2((prev) => {
                const datos = { ...prev.datos, [name]: value };
                return { ...prev, datos };
            });
        }
    }

    React.useEffect(() => {
        establecerFecha()
        traerDatos()
    }, [])

    const establecerFecha = () => {
        setFechaLimiteNacimiento(conversionFecha(1, ""))
        setFechaLimiteDocumentos(conversionFecha(2, ""))
        //console.log(value.datos.fechaNacimiento)
        //console.log(value2.datos.fechaNacimiento)
        if (typeof value2.datos.fechaNacimiento == "number") {
            //setFechaLimiteDocumentos(conversionFecha(1))
           // setFechaNacimientoInicio(conversionFecha(3, convertirTime(value2.datos.fechaNacimiento)))
           ////console.log(convertirTime(value2.datos.fechaNacimiento))
          
            const arrarf = convertirTime(value2.datos.fechaNacimiento).split("/");
            const fechaE = arrarf[1] + "/" + arrarf[0] + "/" + arrarf[2]
           // //console.log(conversionFecha(3, fechaE))
            setFechaNacimientoInicio(conversionFecha(3, fechaE))
            
        }

        if (typeof value2.datos.fechaRepr == "number") {
            //setFechaRepresentacionInicio(conversionFecha(3, convertirTime(value2.datos.fechaRepr)))
            ////console.log(conversionFecha(3, convertirTime(value2.datos.fechaNacimiento)))
            const arrarf = convertirTime(value2.datos.fechaRepr).split("/");
            const fechaE = arrarf[1] + "/" + arrarf[0] + "/" + arrarf[2]
            setFechaRepresentacionInicio(conversionFecha(3,fechaE))
            
        }
    }

    const conversionFecha = (condicion, dato) => {       
        var fecha;
        var fecha_regresada;
        if (condicion === 1 || condicion === 2) {
            fecha = new Date()
        } else {
            ////console.log("entra")
            fecha = new Date(dato)
            ////console.log(fecha)
        }
        //const fecha = new Date()
        const añoActual = fecha.getFullYear();
        let diaActual = fecha.getDate();
        let mesActual = fecha.getMonth() + 1;

        if (diaActual < 10) {
            diaActual = "0" + diaActual
        }

        if (mesActual < 10) {
            mesActual = "0" + mesActual
        }

        if (condicion === 1) {
            fecha_regresada = (añoActual - 18) + "-" + mesActual + "-" + diaActual
        } else {
            fecha_regresada = (añoActual) + "-" + mesActual + "-" + (diaActual)
        }
        return fecha_regresada

    }


    const convertirTime = (info) => {
        var date = new Date((info / 1000) * 1000);
        return date.toLocaleDateString("en-GB")
    }


    const traerDatos = async () => {
        const catalogoEstadosCiviles = await traerEstadosCiviles()
        if (catalogoEstadosCiviles) {
            //setSpin(false)
            if ((isNaN(catalogoEstadosCiviles.error)) === false) {
              
                setErroreshttp(catalogoEstadosCiviles)
                
            } else {
                // //console.log(traerInformacion)
                setEstadosCiviles(catalogoEstadosCiviles)
            }
        }

        const catalogoNacionalidad = await traerNacionalidad()
        if (catalogoNacionalidad) {
            //setSpin(false)
            if ((isNaN(catalogoNacionalidad.error)) === false) {
              
                setErroreshttp(catalogoNacionalidad)
                
            } else {
                // //console.log(traerInformacion)
                //setActividadEconomica(catalogoNacionalidad)
                setNacionalidad(catalogoNacionalidad)
            }
        }

        const catalogoTiemposLaborales = await traerTiemposLaborales()
        if (catalogoTiemposLaborales) {
            //setSpin(false)
            if ((isNaN(catalogoTiemposLaborales.error)) === false) {
              
                setErroreshttp(catalogoTiemposLaborales)
                
            } else {
                // //console.log(traerInformacion)
                //setActividadEconomica(catalogoNacionalidad)
                setTiemposLaborales(catalogoTiemposLaborales)
            }
        }

        const catalogoTiposIdentificacion = await traerTiposIdentificacion()
        //console.log(catalogoTiposIdentificacion)
        if (catalogoTiposIdentificacion) {
            //setSpin(false)
            if ((isNaN(catalogoTiposIdentificacion.error)) === false) {
              
                setErroreshttp(catalogoTiposIdentificacion)
                
            } else {
                // //console.log(traerInformacion)
                //setActividadEconomica(catalogoNacionalidad)
                setTiposIdentificacion(catalogoTiposIdentificacion)
            }
        }

        const catalogoPuestos = await traerPuestos()
        if (catalogoPuestos) {
            //setSpin(false)
            if ((isNaN(catalogoPuestos.error)) === false) {
              
                setErroreshttp(catalogoPuestos)
                
            } else {
                // //console.log(traerInformacion)
                //setActividadEconomica(catalogoNacionalidad)
                setPuestos(catalogoPuestos)
            }
        }

        const catalogoGeneros = await traerGeneros()
        if (catalogoGeneros) {
            //setSpin(false)
            if ((isNaN(catalogoGeneros.error)) === false) {
              
                setErroreshttp(catalogoGeneros)
                
            } else {
                // //console.log(traerInformacion)
                //setActividadEconomica(catalogoNacionalidad)
                setGeneros(catalogoGeneros)
            }
        }



        // setNacionalidad(await traerNacionalidad())
        //setEstadosCiviles(await traerEstadosCiviles())
        //  setTiemposLaborales(await traerTiemposLaborales())
        // setTiposIdentificacion(await traerTiposIdentificacion())
        // setPuestos(await traerPuestos())
        // setGeneros(await traerGeneros())
    }



    const checarNumeros = (e,filtrar) => {
        const { name, value } = e.target;
        let out = '';
        var filtro;//Caracteres validos

        if(filtrar===1){
            filtro = '1234567890';
        }else if(filtrar===2){
            filtro = '1234567890-';
        }else{
            filtro = '1234567890-+';
        }
        

     /*   if (e.keyCode > 64 && e.keyCode < 91) {
            if (name === "rtnRepresentante") {
                setErrorRtn(true)
                setErrorNumeroIdentificacion(false)
                setErrorTelefono1(false)
                setErrorTelefono2(false)
            } else if (name === "numeroIdentificacion") {
                setErrorRtn(false)
                setErrorNumeroIdentificacion(true)
                setErrorTelefono1(false)
                setErrorTelefono2(false)
            }
            else if (name === "telefono") {
                setErrorRtn(false)
                setErrorNumeroIdentificacion(false)
                setErrorTelefono1(true)
                setErrorTelefono2(false)
            } else {
                setErrorRtn(false)
                setErrorNumeroIdentificacion(false)
                setErrorTelefono1(false)
                setErrorTelefono2(true)
            }
        } else {
            setErrorRtn(false)
            setErrorNumeroIdentificacion(false)
            setErrorTelefono1(false)
            setErrorTelefono2(false)
        }*/

        //Recorrer el texto y verificar si el caracter se encuentra en la lista de validos 
        for (var i = 0; i < value.length; i++)
            if (filtro.indexOf(value.charAt(i)) != -1)
                //Se añaden a la salida los caracteres validos
                out += value.charAt(i);

        setValue2((prev) => {
            const datos = { ...prev.datos, [name]: out };
            return { ...prev, datos };
        });
    }

    const otroPuesto = async () => {
        const obj = {
            nombre: puestoNuevo
        }
        const agregarNuevoPuesto = await insertarPuesto(obj)

        if (agregarNuevoPuesto) {
            setSpinSend(false)
            if ((isNaN(agregarNuevoPuesto.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(agregarNuevoPuesto)
            } else {


                if (agregarNuevoPuesto?.codigo === "000") {

                    //setActividadEconomica(await TraerActividadEconomica())
                    const catalogoPuestos = await traerPuestos()
                    if (catalogoPuestos) {
                        if ((isNaN(catalogoPuestos.error)) === false) {
                            // respuestaincorrecta           
                            setErroreshttp(catalogoPuestos)
                        } else {
                            setPuestos(catalogoPuestos)
                            setAgregarPuestos(false)

                            const myObj = {
                                target: {
                                    value: (puestos[puestos.length - 1].id) + 1,
                                    name: "puesto"
                                }
                            }
                            guardarDatos(myObj)
                        }
                    }


                }
            }
        }

    }

    return (
        <>
            <div className="card-body pt-3" >
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }     <h5 className="text-royal-blue font-acumin-variable " >II. Datos del representante legal</h5>

                {value.datos.establecerModificacion ?
                    <>
                        <div className="row mb-3">
                            <div className="col-md-3 mb-3">
                                <div className="form-floating">
                                    < input placeholder="" value={value2.datos.primerNombre} onChange={(e) => guardarDatos(e)} id="primerNombre" name="primerNombre" type="text" className="form-control fs-5 border" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer nombre </label>
                                </div>
                            </div>

                            <div className="col-md-3 form-floating mb-3">
                                <div className="form-floating">
                                    < input placeholder="" value={value2.datos.segundoNombre} onChange={(e) => guardarDatos(e)} id="segundoNombre" name="segundoNombre" type="text" className="form-control fs-5" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo nombre</label>
                                </div>

                            </div>

                            <div className="col-md-3 form-floating mb-3">
                                <div className="form-floating">
                                    < input placeholder="" value={value2.datos.primerApellido} onChange={(e) => guardarDatos(e)} id="primerApellido" name="primerApellido" type="text" className="form-control fs-5 border  r" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Primer apellido </label>
                                </div>
                            </div>

                            <div className="col-md-3 form-floating mb-3">
                                <div className="form-floating">
                                    < input placeholder="" type="text" className="form-control fs-5" value={value2.datos.segundoApellido} onChange={(e) => guardarDatos(e)} id="segundoApellido" name="segundoApellido" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo apellido</label>
                                </div>

                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input placeholder="" type="text" className="form-control fs-5" value={value2.datos.apellidoCasada} onChange={(e) => guardarDatos(e)} id="apellidoCasada" name="apellidoCasada" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Apellido de casada (si aplica)</label>
                                </div>
                            </div>

                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    {value2.datos.tipoIdentificacionInicio ?
                                        <select className="form-select fs-5 border" value={value2.datos.tipoIdentificacion} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["tipoIdentificacionInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                        }} id="tipoIdentificacion" name="tipoIdentificacion" required>
                                            <option value={1} >{value2.datos.tipoIdentificacion}</option>
                                            {
                                                tiposIdentificacion.filter((item => item.nombre !== value2.datos.tipoIdentificacion)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                        </select>
                                        :
                                        <select className="form-select fs-5 border" value={value2.datos.tipoIdentificacion} onChange={(e) => guardarDatos(e)} id="tipoIdentificacion" name="tipoIdentificacion" required>
                                            <option value=""> </option>
                                            {
                                                tiposIdentificacion.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>
                                    }

                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Tipo de identificación </label>
                                </div>
                            </div>

                            <div className="col-md-4 form-floating">
                                <div className="form-floating">
                                    < input placeholder="" type="text" className="form-control fs-5 border" maxLength={22} value={value2.datos.numeroIdentificacion} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e,2)} id="numeroIdentificacion" name="numeroIdentificacion" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Número de identificación </label>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input placeholder="" type="text" className="form-control fs-5" maxLength={14} value={value2.datos.rtnRepresentante} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e,1)} id="rtnRepresentante" name="rtnRepresentante" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">RTN numérico </label>
                                    <div id="emailHelp" class="ps-1 form-text">Solo números</div>
                                </div>
                                {errorRtn &&
                                    <>
                                        <p className="error">No puede ingresar letras</p>
                                    </>
                                }
                            </div>

                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    {value2.datos.estadoCivilInicio ?
                                        <select className="form-select fs-5 border " value={value2.datos.estadoCivil} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["estadoCivilInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                        }} id="estadoCivil" name="estadoCivil" required>
                                            <option value={value2.datos.estadoCivil} >{value2.datos.estadoCivil}</option>
                                            {
                                                estadosCiviles.filter((item => item.nombre !== value2.datos.estadoCivil)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>
                                        :
                                        <select className="form-select fs-5 border " value={value2.datos.estadoCivil} onChange={(e) => guardarDatos(e)} id="estadoCivil" name="estadoCivil" required>
                                            <option value=""> </option>
                                            {
                                                estadosCiviles.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                        </select>
                                    }

                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Estado civil </label>
                                </div>
                            </div>

                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    {value2.datos.generoInicio ?
                                        <select className="form-select fs-5 border  r" value={value2.datos.genero} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["generoInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                          
                                        }} id="genero" name="genero" required>
                                            <option value={value2.datos.genero} >{value2.datos.genero}</option>
                                            {
                                                generos.filter((item => item.nombre !== value2.datos.genero)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>
                                        :
                                        <select className="form-select fs-5 border  r" value={value2.datos.genero} onChange={(e) => guardarDatos(e)} id="genero" name="genero" required>
                                            <option value=""> </option>
                                            {
                                                generos.map((item, index) => (

                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>


                                    }

                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Género </label>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    {value2.datos.nacionalidadInicio ?
                                        <select className="form-select fs-5 border  r" value={value2.datos.nacionalidad} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["nacionalidadInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                         
                                        }} id="nacionalidad" name="nacionalidad" required>
                                            <option value={value2.datos.nacionalidad} >{value2.datos.nacionalidad}</option>
                                            {
                                                nacionalidad.filter((item => item.nombre !== value2.datos.nacionalidad)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>
                                        :
                                        <select className="form-select fs-5 border  r" value={value2.datos.nacionalidad} onChange={(e) => guardarDatos(e)} id="nacionalidad" name="nacionalidad" required>
                                            <option value=""> </option>
                                            {
                                                nacionalidad.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>
                                    }

                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Nacionalidad </label>
                                </div>
                            </div>

                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    {fechaNacimientoInicio ?
                                        < input placeholder="" max={fechaLimiteNacimiento} type="date" className="form-control fs-5 border  r" value={fechaNacimientoInicio} onClick={() => setFechaNacimientoInicio(false)} id="fechaNacimiento" name="fechaNacimiento" required />
                                        :
                                        < input placeholder="" max={fechaLimiteNacimiento} type="date" className="form-control fs-5 border  r" value={value2.datos.fechaNacimiento} onChange={(e) => guardarDatos(e)} id="fechaNacimiento" name="fechaNacimiento" required />
                                    }

                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Fecha de nacimiento </label>
                                </div>

                            </div>

                            <div className="col-md-4 form-floating">
                                <div className="form-floating">
                                    < input placeholder="" type="text" className="form-control fs-5 border  r" value={value2.datos.lugarNacimiento} onChange={(e) => guardarDatos(e)} id="lugarNacimiento" name="lugarNacimiento" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Lugar de nacimiento </label>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    {value2.datos.puestoInicio ?
                                        <select className="form-select fs-5 border" value={value2.datos.puesto} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["puestoInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                        }} id="puesto" name="puesto" required>
                                            <option value={value2.datos.puesto}>{value2.datos.puesto}</option>
                                            {
                                                puestos.filter((item => item.nombre !== value2.datos.puesto)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                            <option  >OTRO</option>
                                        </select>
                                        :
                                        <select className="form-select fs-5 border" value={value2.datos.puesto} onChange={(e) => guardarDatos(e)} id="puesto" name="puesto" required>
                                            <option value=""> </option>
                                            {
                                                puestos.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                            <option  >OTRO</option>
                                        </select>


                                    }

                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Puesto que desempeña </label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    {value2.datos.tiempoLaboralInicio ?
                                        <select className="form-select fs-5 border  r" value={value2.datos.tiempoPuesto} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["tiempoLaboralInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                        }} id="tiempoPuesto" name="tiempoPuesto" required>
                                            <option value={value2.datos.tiempoPuesto}> {value2.datos.tiempoPuesto}</option>
                                            {
                                                tiemposLaborales.filter((item => item.nombre !== value2.datos.tiempoPuesto)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>
                                        :
                                        <select className="form-select fs-5 border  r" value={value2.datos.tiempoPuesto} onChange={(e) => guardarDatos(e)} id="tiempoPuesto" name="tiempoPuesto" required>
                                            <option value=""> </option>
                                            {
                                                tiemposLaborales.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>

                                    }

                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Tiempo de laborar </label>
                                </div>

                            </div>

                            <div className="col-md-4 form-floating">
                                <div className="form-floating">
                                    < input placeholder="" type="text" maxLength={15} className="form-control fs-5 border  r" value={value2.datos.telefono} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e,3)} id="telefono" name="telefono" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Teléfono </label>
                                </div>

                            </div>
                        </div>

                        {agregarPuesto &&

                            <>
                                {spinSend ? <div className="row mb-3 d-flex ">
                                    <div className="col-md-4 text-center">
                                        <SpinnerSend textoSpinner="Enviando..."></SpinnerSend>
                                    </div>
                                </div> :
                                    <div className="row mb-3">
                                        <div className="col-md-3 form-floating">
                                            <div className="form-floating">
                                                < input placeholder="" type="text" className="form-control fs-5 border  r" onChange={(e) => setPuestoNuevo(e.target.value)} required />
                                                <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Ingrese puesto que desempeña </label>
                                            </div>
                                        </div>

                                        <div className="col-md-1 d-flex align-items-end">
                                            <button type="button" onClick={() => {
                                                setSpinSend(true)
                                                otroPuesto()
                                            }} className="btn botonFormularioDepositante" >Ingresar</button>
                                        </div>
                                    </div>}
                            </>
                        }
                        <div className="row mb-4">
                            <div className="col-md-4 form-floating">
                                <div className="form-floating mb-3">
                                    < input style={{ textTransform: "lowercase" }} placeholder="" type="email" className="form-control fs-5 border  r" value={value2.datos.correoLab} onChange={(e) => guardarDatos(e)} id="correoLab" name="correoLab" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto" >Correo electrónico laboral </label>
                                </div>

                            </div>

                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    {
                                        fechaRepresentacionInicio ?
                                            < input placeholder="" type="date" max={fechaLimiteDocumentos} className="form-control fs-5" value={fechaRepresentacionInicio} onClick={() => setFechaRepresentacionInicio(false)} id="fechaRepr" name="fechaRepr" required />
                                            :
                                            < input placeholder="" type="date" max={fechaLimiteDocumentos} className="form-control fs-5" value={value2.datos.fechaRepr} onChange={(e) => guardarDatos(e)} id="fechaRepr" name="fechaRepr" required />
                                    }

                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Fecha de representación </label>
                                </div>

                            </div>

                            <div className="col-md-4 ">
                                <div className="form-floating">
                                    < input placeholder="" type="text" className="form-control fs-5" maxLength={15} value={value2.datos.telefonoCelular} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e,3)} id="telefonoCelular" name="telefonoCelular" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Teléfono celular </label>

                                </div>

                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="row mb-3">
                            <div className="col-md-3 mb-3">
                                <div className="form-floating">
                                    < input disabled value={value.datos.primerNombre} onChange={(e) => guardarDatos(e)} id="primerNombre" name="primerNombre" type="text" className="form-control fs-5 border" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer nombre </label>
                                </div>
                            </div>

                            <div className="col-md-3 form-floating mb-3">
                                <div className="form-floating">
                                    < input disabled value={value.datos.segundoNombre} onChange={(e) => guardarDatos(e)} id="segundoNombre" name="segundoNombre" type="text" className="form-control fs-5" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo nombre</label>
                                </div>

                            </div>

                            <div className="col-md-3 form-floating mb-3">
                                <div className="form-floating">
                                    < input disabled value={value.datos.primerApellido} onChange={(e) => guardarDatos(e)} id="primerApellido" name="primerApellido" type="text" className="form-control fs-5 border  r" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Primer apellido </label>
                                </div>
                            </div>

                            <div className="col-md-3 form-floating mb-3">
                                <div className="form-floating">
                                    < input disabled type="text" className="form-control fs-5" value={value.datos.segundoApellido} onChange={(e) => guardarDatos(e)} id="segundoApellido" name="segundoApellido" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo apellido</label>
                                </div>

                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input disabled type="text" className="form-control fs-5" value={value.datos.apellidoCasada} onChange={(e) => guardarDatos(e)} id="apellidoCasada" name="apellidoCasada" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Apellido de casada (si aplica)</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input disabled type="text" className="form-control fs-5" value={value.datos.tipoIdentificacion} onChange={(e) => guardarDatos(e)} id="apellidoCasada" name="apellidoCasada" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Tipo de identificación </label>
                                </div>
                            </div>

                            <div className="col-md-4 form-floating">
                                <div className="form-floating">
                                    < input disabled type="text" className="form-control fs-5 border" maxLength={22} value={value.datos.numeroIdentificacion} onChange={(e) => guardarDatos(e)} id="numeroIdentificacion" name="numeroIdentificacion" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Número de identificación </label>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input disabled type="text" className="form-control fs-5" maxLength={14} value={value.datos.rtnRepresentante} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e)} id="rtnRepresentante" name="rtnRepresentante" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">RTN numérico </label>
                                </div>
                            </div>

                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input disabled type="text" className="form-control fs-5" maxLength={14} value={value.datos.estadoCivil} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e)} id="rtnRepresentante" name="rtnRepresentante" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Estado civil</label>
                                </div>
                            </div>

                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input disabled type="text" className="form-control fs-5" maxLength={14} value={value.datos.genero} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e)} id="rtnRepresentante" name="rtnRepresentante" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Género</label>
                                </div>
                            </div>


                        </div>

                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input disabled type="text" className="form-control fs-5" maxLength={14} value={value.datos.nacionalidad} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e)} id="rtnRepresentante" name="rtnRepresentante" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Nacionalidad</label>
                                </div>
                            </div>



                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input disabled max={fechaLimiteNacimiento} type="text" className="form-control fs-5 border  r" value={convertirTime(value.datos.fechaNacimiento)} onChange={(e) => guardarDatos(e)} id="fechaNacimiento" name="fechaNacimiento" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Fecha de nacimiento </label>
                                </div>

                            </div>

                            <div className="col-md-4 form-floating">
                                <div className="form-floating">
                                    < input disabled type="text" className="form-control fs-5 border  r" value={value.datos.lugarNacimiento} onChange={(e) => guardarDatos(e)} id="lugarNacimiento" name="lugarNacimiento" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Lugar de nacimiento </label>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input disabled type="text" className="form-control fs-5" maxLength={14} value={value.datos.puesto} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e)} id="rtnRepresentante" name="rtnRepresentante" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Puesto que desempeña</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input disabled type="text" className="form-control fs-5" maxLength={14} value={value.datos.tiempoPuesto} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e)} id="rtnRepresentante" name="rtnRepresentante" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Tiempo de laborar</label>
                                </div>
                            </div>




                            <div className="col-md-4 form-floating">
                                <div className="form-floating">
                                    < input disabled type="text" maxLength={15} className="form-control fs-5 border  r" value={value.datos.telefono} onChange={(e) => guardarDatos(e)} id="telefono" name="telefono" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Teléfono </label>
                                </div>

                            </div>
                        </div>


                        <div className="row mb-4">
                            <div className="col-md-4 form-floating">
                                <div className="form-floating mb-3">
                                    < input disabled style={{ textTransform: "lowercase" }} placeholder="" type="email" className="form-control fs-5 border  r" value={value.datos.correoLab} onChange={(e) => guardarDatos(e)} id="correoLab" name="correoLab" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto" >Correo electrónico laboral </label>
                                </div>

                            </div>

                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating">
                                    < input disabled type="text" max={fechaLimiteDocumentos} className="form-control fs-5" value={convertirTime(value.datos.fechaRepr)} onChange={(e) => guardarDatos(e)} id="fechaRepr" name="fechaRepr" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Fecha de representación </label>
                                </div>

                            </div>

                            <div className="col-md-4 ">
                                <div className="form-floating">
                                    < input disabled type="text" className="form-control fs-5" maxLength={15} value={value.datos.telefonoCelular} onChange={(e) => guardarDatos(e)} id="telefonoCelular" name="telefonoCelular" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Teléfono celular </label>

                                </div>

                            </div>
                        </div>
                    </>

                }

            </div>
        </>
    );
}

