import React, { useState } from "react";

//Componentes

import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";



//Hooks
import usePagosPendientesConfirmacion from "../../../hooks/PagoDerechosPatrimoniales/usePagosPendientesConfirmacion";


//API's

import { consultarPagosDerechosPatrimoniales } from "../../../api/PagoInteresesAmorDiv/consultarPagosDerechosPatrimoniales";



//Muestra las cuentas de un depositante indirecto
export default function ConfirmarPagosDerechosPartimoniales({ history }) {

    //If endpoint got the data will be true    


    const [erroreshttp, setErroreshttp] = useState();
    const [spin, setSpin] = React.useState(true)
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()




    React.useEffect(() => {
        traerPagosDerechosPatrimoniales()
    }, []);




    const traerPagosDerechosPatrimoniales = async () => {

        const response = await consultarPagosDerechosPatrimoniales()
        //console.log(response)
        if (response) {
            setSpin(false)
            if ((isNaN(response.error)) === false) {
                setErroreshttp(response)
            } else {
                setData(response)
                setCopia(response)
            }
        }
    }

    const confirmarMontos = (row) => {
        ////console.log(row.original.codigoId)
        history.push({
            pathname: '/confirmacionMontosPagosDerechosPatrimoniales', state: { objMenu: row.original }
        })
    }


    return (
        <>

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            <div className="fluid-container">
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 mb-5">
                            <BannerTitulo titulo="Pagos pendientes de confirmación de montos"></BannerTitulo>
                            {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                <div className="d-flex">
                                    <div className="container py-5">
                                        {copia &&
                                            <>
                                                <TablaMercado datos={data} useValidate={usePagosPendientesConfirmacion} displayInformation={confirmarMontos} textoBoton={"Confirmar montos"} textTitle="Click para ver información" ></TablaMercado>
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}

