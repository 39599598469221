import React, { useState, useEffect } from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";




//Hooks
import useSolicitudGeneracionCodigoISIN from "../../../hooks/SolicitudGeneracionCodigoISIN/useSolicitudGeneracionCodigoISIN";

//API's
import { actualizarSolicitudCodIsin } from "../../../api/GeneracionAsignacionCodigosISIN/actualizarSolicitudCodIsin";
import { descargarPDF } from "../../../api/GeneracionAsignacionCodigosISIN/descargarPDF";


//Catalogs





//Muestra las cuentas de un depositante indirecto
export default function AutorizarSolicitudCodIsin({ history }) {

    useEffect(() => {

        if (history.location.state === undefined) {
            history.go(-1)
        } else {
            const applicationData = history.location.state.emisionInfo;
            //console.log(applicationData)
            setCodigoEmisor(applicationData.solicitudIsinCodigoEmisor);
            setNombreEmisor(applicationData.solicitudNombreEmisor);
            setCodigoEmision(applicationData.solicitudIsinCodigoEmision);
            setNombreEmision(applicationData.solicitudNombreEmision);
            setInstrumentoFinanciero({ ...instrumentoFinanciero, tipo: applicationData.cehvalSolicitudIsinInstrumetoFinanciero.tipo, id: applicationData.cehvalSolicitudIsinInstrumetoFinanciero.id });
            setNumeroResRPMV(applicationData.resolucion);

            const series = applicationData.solicitudIsinSeries.split(",");
            createSeriesArray(series);


            setFile(applicationData.solicitudIsinUrlFile);
            setResponsableNombre(applicationData.solicitudIsinResponsableNombre);
            setPuestoResponsable({ ...puestoResponsable, nombre: applicationData.cehvalSolicitudIsinResponsablePuesto.nombre, id: applicationData.cehvalSolicitudIsinResponsablePuesto.id });
            setResponsableCorreo(applicationData.solicitudIsinResponsableCorreo);
            setSolicitudId(applicationData.solicitudIsinId);

        }

    }, []);


    const createSeriesArray = (series) => {
        const arraySeries = series.map((el) => {
            return { serie: el }
        });
        setArraySeries(arraySeries);
    }




    //All series of the selected emision
    const [arraySeries, setArraySeries] = useState();


    //Catch data from history 
    const [nombreEmisor, setNombreEmisor] = useState("");
    const [codigoEmisor, setCodigoEmisor] = useState("");
    const [codigoEmision, setCodigoEmision] = useState();
    const [nombreEmision, setNombreEmision] = useState("");
    const [instrumentoFinanciero, setInstrumentoFinanciero] = useState({
        tipo: "",
        id: "",
    });
    const [numeroResRPMV, setNumeroResRPMV] = useState("");
    const [responsableNombre, setResponsableNombre] = useState();
    const [responsableCorreo, setResponsableCorreo] = useState();
    const [puestoResponsable, setPuestoResponsable] = useState({
        nombre: "",
        id: "",
    });
    const [solicitudId, setSolicitudId] = useState();
    const [file, setFile] = useState();


    //Input motivo rechazo
    const [reasonReject, setReasonReject] = useState(null);


    const [showRejectField, setShowRejectField] = useState(false);
    const [buttonRejectText, setButtonRejectText] = useState("Rechazar");
    const [buttonReject, setButtonReject] = useState();


    //Request messages
    const [downloadFailed, setDownloadFailed] = useState();



    //True or false states
    const [statusRequest, setStatusRequest] = useState();
    const [statusRequestFail, setStatusRequestFail] = useState();


    //Shows the loading screen when is set to true
    const [loading, setLoading] = useState(false);


    const [erroreshttp, setErroreshttp] = useState();




    const downloadPDF = async () => {
        try {

            setLoading(true);
            const response = await descargarPDF({ fileName: file });
            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    if (response.data) {
                        setLoading(false);
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                        URL.revokeObjectURL(fileURL);

                    } else {
                        setLoading(false);
                        setDownloadFailed("No fue posible descargar el archivo");
                    }
                }
            }




        } catch (error) {
            //console.log(error)
        }
    }



    const handleAuthorize = async (e) => {
        try {
            const obj = {
                solicitudId: solicitudId,
                solicitudIsinEstado: 3,  //obligatorio. 
                //solicitudIsinMotivoRechazo: motivoRechazo, //depende el estado
                //solicitudIsinSignatarioNombre: "KARLA ALVARENGA",  //depende el estado
                // solicitudIsinSignatarioPuestoId: 1. //depende el estado
            };


            setLoading(true);
            const response = await actualizarSolicitudCodIsin(obj);
            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    setLoading(false);
                    if (response.data.isSuccessful) {
                        setStatusRequest(response.data.mensaje);

                    } else {
                        setStatusRequestFail(response.data.mensaje);
                    }
                }
            }



        } catch (error) {
            //console.log(error);
        }
    }



    const handleReject = async (e) => {
        e.preventDefault();
        try {
            const obj = {
                solicitudId: solicitudId,
                solicitudIsinEstado: 4,
                solicitudIsinMotivoRechazo: reasonReject,
            };

            //console.log(obj)

            setLoading(true);
            const response = await actualizarSolicitudCodIsin(obj);
            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {


                    if (response.data.isSuccessful) {
                        setStatusRequest(response.data.mensaje);

                    } else {
                        setStatusRequestFail(response.data.mensaje);
                    }

                    //console.log(response);
                }
            }




        } catch (error) {
            //console.log(error);
        }
    }

    //Shows the reject field
    const showReject = () => {
        if (showRejectField === false) {
            setShowRejectField(true);
            setButtonReject(true);
            setButtonRejectText("Cancelar");
        } else {
            setShowRejectField(false);
            setButtonReject(false);
            setButtonRejectText("Rechazar");
        }
    }

    const goBack = () => {
        window.location.replace("/todasSolicitudesCodigoISINPorAutorizar");
    }





    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {statusRequest &&
                <ComponenteModal mostrarModal={statusRequest} cerrarModal={goBack} titulo={statusRequest} textoBoton="Aceptar"></ComponenteModal>
            }
            {statusRequestFail &&
                <ComponenteModal mostrarModal={statusRequestFail} cerrarModal={()=>setStatusRequestFail(false)} titulo={statusRequestFail} textoBoton="Aceptar"></ComponenteModal>
            }


            {downloadFailed &&
                <ComponenteModal mostrarModal={downloadFailed} cerrarModal={() => downloadFailed(false)} titulo={downloadFailed} textoBoton="Aceptar"></ComponenteModal>
            }

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

          

            <div className="fluid-container">
                <main className="mb-5 min-height-40">
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Revisión de Solicitud de Generación y Asignación de Códigos ISIN"></BannerTitulo>
                                <div className="d-flex px-3">
                                    <div className="needs-validation font-acumin-variable text-royal-blue container border rounded">
                                        <form >
                                            <section>
                                                <div className="row mt-4">
                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                        <div className="form-floating">
                                                            <input title="Código del emisor" className="form-control fs-5" value={codigoEmisor} type="text" name="codigoEmisor" placeholder="Codigo del emisor" required disabled />
                                                            <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoEmisor">Código del emisor</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                        <div className="form-floating">
                                                            <input title="Nombre del emisor" className="form-control fs-5" value={nombreEmisor} type="text" name="nombreEmisor" placeholder="Nombre del emisor" required disabled />
                                                            <label className="text-start font-acumin-variable" htmlFor="nombreEmisor">Nombre del emisor</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                        <div className="form-floating">
                                                            <input title="Código de emisión" className="form-control fs-5" value={codigoEmision} type="text" name="codigoEmision" placeholder="Código de emisión" required disabled />
                                                            <label className="text-start font-acumin-variable" htmlFor="codigoEmision">Código emisión</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                        <div className="form-floating">
                                                            <input title="Nombre de la emisión" className="form-control fs-5" value={nombreEmision} type="text" name="nombreEmision" placeholder="Nombre de la emisión" required disabled />
                                                            <label className="text-start font-acumin-variable" htmlFor="nombreEmision">Código emisión</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 d-flex justify-content-center mb-3">
                                                        <div className=" col-12 col-md-12 ">
                                                            <div className="form-floating">
                                                                <select title="Tipo de instrumento financiero" className="form-select font-acumin-variable" name="tipoInstrumentoFinanciero" style={{ height: "56px" }} aria-label="Default select example" disabled required>
                                                                    <option value={instrumentoFinanciero.id}>{instrumentoFinanciero.tipo}</option>
                                                                </select>
                                                                <label className="font-acumin-variable" htmlFor="tipoInstrumentoFinanciero">Tipo de instrumento financiero</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                        <div className="form-floating">
                                                            <input title="No. Resolución RPMV" className="form-control fs-5" value={numeroResRPMV} type="text" name="numeroResolucionRPMV" placeholder="No. Resolución RPMV" required disabled />
                                                            <label className="text-start font-acumin-variable" htmlFor="numeroResolucionRPMV">No. Resolución RPMV</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section>
                                                <div className="d-flex">
                                                    <div className="container py-5">
                                                        {arraySeries &&
                                                            <TablaUsuarios datos={arraySeries} useValidate={useSolicitudGeneracionCodigoISIN}  ></TablaUsuarios>
                                                        }
                                                    </div>
                                                </div>
                                            </section>
                                            <section className="row justify-content-center align-items-center g-0 mt-5 mb-3 p-2">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                                    <div className="col-12 row justify-content-center justity-content-md-start justity-content-lg-start justity-content-xl-start justity-content-xxl-start g-0">
                                                        <h5 className="font-acumin-variable text-royal-blue col-12 col-sm-12 col-md-8 col-lg-8 text-start fs-15">Documento : {file}</h5>
                                                        <div id="fileDropAreaISIN" className="col-12 col-sm-12 col-md-8 col-lg-8 drop-area row row-wrap justify-content-center align-items-center border-dashed py-5 px-2 rounded" >
                                                            <i className="ri-download-2-fill ri-3x text-center text-royal-blue"></i>
                                                            <p id="text-click-uploadfile" onClick={downloadPDF} className="text-center font-acumin-variable text-royal-blue">Descargar archivo</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 font-acumin-variable text-royal-blue mt-5 mt-sm-5 mt-md-5 mt-lg-0 mt-xl-0 mt-xxl-0">
                                                    <div className="row justify-content-center mt-4">
                                                        <h5 className="font-acumin-variable text-royal-blue text-start text-sm-start text-md-start text-lg-center text-xl-center text-xxl-center mb-3 fw-bolder">Solicitante responsable</h5>
                                                        <div className="col-12 col-md-12 col-lg-10 col-xl-10 mb-3">
                                                            <div className="form-floating">
                                                                <input title="Nombre del responsable" className="form-control fs-5" type="text" value={responsableNombre} name="nombreResponsable" placeholder="Nombre del responsable" required disabled />
                                                                <label className="text-start font-acumin-variable" htmlFor="nombreResponsable">Nombre del responsable</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-10 col-xl-10 d-flex justify-content-center mb-3">
                                                            <div className=" col-12 col-md-12 ">
                                                                <div className="form-floating">
                                                                    <select title="Puesto que desempeña" className="form-select font-acumin-variable" name="puestoResponsable" style={{ height: "56px" }} aria-label="Default select example" required disabled>
                                                                        <option className="text-uppercase" value={puestoResponsable.id}>{puestoResponsable.nombre}</option>
                                                                    </select>
                                                                    <label className="font-acumin-variable" htmlFor="puestoResponsable">Puesto que desempeña</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-12 col-lg-10 col-xl-10 mb-3">
                                                            <div className="form-floating">
                                                                <input title="Correo electrónico" className="form-control fs-5" type="email" value={responsableCorreo} name="correoResponsable" placeholder="Correo electrónico" required disabled />
                                                                <label className="text-start font-acumin-variable" htmlFor="correoResponsable">Correo electrónico</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 px-3 px-lg-4 px-xl-5 row align-items-center mt-4 justify-content-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end">
                                                    <button className="col-8 col-sm-3 col-md-3 col-lg-2 col-xl-1 col-xxl-1 px-1 mx-2 mt-2 btn background-royal-blue text-white font-acumin-variable bg-hover-red" type="button" onClick={showReject} >{buttonRejectText}</button>
                                                    {!buttonReject &&
                                                        <button className="col-8 col-sm-3 col-md-3 col-lg-2 col-xl-1 col-xxl-1 px-1 mx-2 mt-2 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" type="button" onClick={handleAuthorize} >Autorizar</button>
                                                    }

                                                </div>
                                            </section>
                                        </form>
                                        {showRejectField &&
                                            <>
                                                <form onSubmit={handleReject}>
                                                    <div className="row justify-content-center">

                                                        <div className="col-11 col-md-11 mb-3">
                                                            <div className="form-floating ">
                                                                <textarea title="Cantidad de valores a transferir" onChange={(e) => setReasonReject(e.target.value)} className="form-control fs-5 text-royal-blue rejectionReason" name="motivoRechazo" type="text" placeholder="" required ></textarea>
                                                                <label className="text-start font-acumin-variable" htmlFor="motivoRechazo">Motivo del rechazo</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-11 col-md-11 d-flex justify-content-end mb-3">
                                                            <button type="submit" className="btn background-royal-blue text-white bg-hover-gold">Confirmar</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}


