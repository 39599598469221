import { useMemo } from "react";

export default function useActualizarVectorPrecios () {
  const columns = useMemo(
    () => [
      {
        Header: "Emisión",
        accessor: "nombreEmision"
      },
      {
        Header: "Serie",
        accessor: "nombreSerie"
      },
      {
        Header: "ISIN",
        accessor: "isin"
      },
      {
        Header: "Valuación anterior",
        accessor: "valuacionAnteriorStr"
      },
      {
        Header: "Moneda",
        accessor: "monedaStr"
      },
      {
        Header: "Valuación actual",
        accessor: "valuacionActualStr"
      }     
    ],
    []
  );

  return columns;
}
