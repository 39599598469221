import { instance } from '../../../apiGlobal';

export async function abrirAvisoPendientePago (obj) {
      try {
        const response = await instance(`/tarificacion/mostrar/avisocobro/p/pago`, {
          method:"post",
          data:obj,
        })
        return response;
      } catch (error) {
        return error;
      }
}


