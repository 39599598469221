import { useMemo } from "react";

export default function useSubastasFondosConiciliadosYNoconciliados() {
 const columns = useMemo(
   () => [   
     {
       Header:"Número de subasta",
       accessor: "codigoSubasta"
     },
    
     {
        Header:"Moneda",
        accessor: "moneda"
      },
      {
        Header:"Estatus de la subasta",
        accessor: "estatusStr"
      },

   ],
   []
 );

 return columns;
}


