//React
import React from "react";
import { Link } from 'react-router-dom';

//Components
import BannerTitulo from "./Bannertitulo";

//Images
import emisiones from '../assets/imagenesEmisoresEmisionesSeries/emisiones.jpg'
import series from '../assets/imagenesEmisoresEmisionesSeries/series.jpg'
import emisores from '../assets/imagenesEmisoresEmisionesSeries/emisores.jpg'


export default function ComponenteMenuEmsoresEmisionesSeries({ titulo, ruta1, ruta2, ruta3 }) {


    return (
        <>
            <div className="fluid-container">
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                    
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo className="mb-5" titulo={titulo}></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container font-acumin-variable py-5 mb-5">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-3 ">
                                                <Link to={{ pathname:ruta1  }} className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="" src={emisores} alt="imagenFondo" />
                                                        <div className="card-body text-center">
                                                            <h5 className="card-title">Emisores</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
                                                <Link to={{ pathname: ruta2 }} className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="" src={emisiones} alt="imagenFondo" />
                                                        <div className="card-body text-center">
                                                            <h5 className="card-title">Emisiones</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
                                                <Link to={{ pathname: ruta3  }} className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="" src={series} alt="imagenFondo" />
                                                        <div className="card-body text-center">
                                                            <h5 className="card-title">Series</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}

