import { instance } from '../apiGlobal';

export async function operacionesForzadasNoAsociadas () {

      try {
        const response = await instance(`/mercadosecundario/mostrar/sinasociar`, {
            headers: {
              method: 'get',
            }
          });
        return response;
      } catch (error) {
        return error;
      }
}