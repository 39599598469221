import { useMemo } from "react";

export default function useSolicitudesLiberacionGarantia () {
  const columns = useMemo(
    () => [
      {
        Header: "Folio liberación o ejecución",
        accessor: "cehvalSolicitudLiberacionGarantiaFolio"
      },
      {
        Header: "Folio constitución",
        accessor: "cehvalSolicitudLiberacionGarantiaFolioConstitucion.cehvalSolicitudGarantiaFolio"
      },
      {
        Header: "ISIN",
        accessor: "serieIsin"
      }      
    ],
    []
  );

  return columns;
}
