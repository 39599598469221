import React from "react";


import '../../../styles/stylesheet.css'
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";

import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";
import { useHistory } from 'react-router-dom';



import { crearUsuario } from '../../../api/usuarios/crearUsuario'

import { cambiarEstatusDepositante } from '../../../api/cambiarEstatus.js/cambiarEstatus';
import { cambiarEstatusEmisor } from "../../../api/VerInformacionDeEmisor.js/cambiarEstatusEmisor";
import { reporteAutorizadoDDPJ } from '../../../api/Documentos/ReportesDepositantesAutorizados/reporteAutorizadoDDPJ'
import { reporteAutorizadoDIPJ } from "../../../api/Documentos/ReportesDepositantesAutorizados/reporteAutorizadoDIPJ";
import { reporteAutorizadoDIPN } from "../../../api/Documentos/ReportesDepositantesAutorizados/reporteAutorizadoDIPN";



export default function RegistroAutomatico(props) {
    const history = useHistory();

    const [errorUsuarioExistente, setErrorUsuarioExistente] = React.useState()

    const [usuarioRegistrado, setUsuarioRegistrado] = React.useState()
    const [usuarioNoRegistrado, setUsuarioNoRegistrado] = React.useState()
    const [registroCompleto, setRegistroCompleto] = React.useState();

    const [variableComparacion, setVariableComparacion] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [sinRepresentante, setSinRepresentante] = React.useState()
    const [mensaje, setMensaje] = React.useState()
    const [loading, setLoading] = React.useState(false);


    let contador = 0;

    //**Inicia variables necesarias de AWS */
    const AWS = require('aws-sdk');

    // Set the AWS Region.
    AWS.config.update({
        region: process.env.REACT_APP_region,
        accessKeyId: process.env.REACT_APP_accessKeyId,
        secretAccessKey: process.env.REACT_APP_secretAccessKey,
    });

    // Initialize CogntioIdentityServiceProvider SDK.
    const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    //**Termina variables necesarias de AWS */

    React.useEffect(() => {
        //declaramos una variable para saber cuantos usuarios hay que registrar
        // si es un emisor, solo es un usuario, si es un depositnate puede ser 1 o 2 usuarios
        if (props.tipoParticipante === 4 || props.tipoParticipante === 5) {
            setVariableComparacion(1)
        } else {
            let i = 0
            props.informacion.cehvalContactoCollection.map(function (item, index) {
                if (item.correo) {
                    i++;
                }
            })
            setVariableComparacion(i);
        }
    }, [])

    React.useEffect(() => {
        //vamos a funcion traer datos hasta que la variable de comparacaion sea mayor a cero
        if (variableComparacion > 0) {
            traerDatos()
        }
    }, [variableComparacion])


    const traerDatos = async () => {
        //inscribimos a los usuarios con su correo, primer nombre,segundo nombre, primer apellido, segundo apellido y codigo.
        //inscribir a emisor automaticamente con rol CONSULTOR_EMISOR
        if (props.tipoParticipante === 4 || props.tipoParticipante === 5) {
            ////console.log("traerDAtos")
          //  //console.log(props.informacion.cehvalEmisorCoreoElecRepreLeg)

            if (props.informacion.cehvalEmisorCoreoElecRepreLeg) {
                
               const arregloPivote = props.informacion.cehvalEmisorNombreCompletoRepreLeg.split(" ");
                if (arregloPivote.length === 4) {
                    onSubmitForm(props.informacion.cehvalEmisorCoreoElecRepreLeg, arregloPivote[0], arregloPivote[1], arregloPivote[2], arregloPivote[3], "CONSULTOR_EMISOR", props.codigo)
                } else if (arregloPivote.length === 3) {
                    onSubmitForm(props.informacion.cehvalEmisorCoreoElecRepreLeg, arregloPivote[0], " ", arregloPivote[1], arregloPivote[1], "CONSULTOR_EMISOR", props.codigo)
                } else {
                    onSubmitForm(props.informacion.cehvalEmisorCoreoElecRepreLeg, arregloPivote[0], " ", arregloPivote[1], " ", "CONSULTOR_EMISOR", props.codigo)
                }

            } else {
               // //console.log(props.informacion.emisorNombreContacto)

                const arregloPivote = props.informacion.emisorNombreContacto.split(" ")
               // //console.log(arregloPivote)

                if (arregloPivote.length === 4) {
                    onSubmitForm(props.informacion.emisorCorreoContacto, arregloPivote[0], arregloPivote[1], arregloPivote[2], arregloPivote[3], "CONSULTOR_EMISOR", props.codigo)
                } else if (arregloPivote.length === 3) {
                    onSubmitForm(props.informacion.emisorCorreoContacto, arregloPivote[0], " ", arregloPivote[1], arregloPivote[1], "CONSULTOR_EMISOR", props.codigo)
                } else {
                    onSubmitForm(props.informacion.emisorCorreoContacto, arregloPivote[0], " ", arregloPivote[1], " ", "CONSULTOR_EMISOR", props.codigo)
                }

               // //console.log(props.informacion.emisorCorreoContacto)
               // //console.log("entra")
                /*actualizarEstatus()*/
            }
            //onSubmitForm(props.informacion.cehvalEmisorCoreoElecRepreLeg, props.informacion.cehvalEmisorPriNombreRepreLeg, props.informacion.cehvalEmisorSegNombreRepreLeg, props.informacion.cehvalEmisorPriApellidoRepreLeg, props.informacion.cehvalEmisorSegApellidoRepreLeg, "CONSULTOR_EMISOR", props.codigo)
        }
        //inscribir depositantes
        else {
            // else if (((props.codigo).indexOf("DDPJ") >= 0)||((props.codigo).indexOf("DIPJ") >= 0)) {
            // //console.log(props.informacion.cehvalContactoCollection.length)
            props.informacion.cehvalContactoCollection.map(function (item, index) {
                if (item.correo) {//si existe el correo
                    if (props.tipoParticipante === 1) {//si es depositaante directo registramos ususario admin y operador
                        if (index === 0) {//usuario admin
                            onSubmitForm(item.correo, item.nombre, item.segundoNombre, item.primerApellido, item.segundoApellido, "ADMINISTRADOR_DD", props.codigo)
                        } else {//usuario operador
                            onSubmitForm(item.correo, item.nombre, item.segundoNombre, item.primerApellido, item.segundoApellido, "OPERADOR_DD", props.codigo)
                        }
                    } else {//si no depositnate directo sus dos usuarios son depositantes
                        onSubmitForm(item.correo, item.nombre, item.segundoNombre, item.primerApellido, item.segundoApellido, "CONSULTOR_DI", props.codigo)
                    }
                }
            
            })
        }
    }




    const onSubmitForm = async (correo, primerNombre, segundoNombre, primerApellido, segundoApellido, rol, codigo) => {
        setLoading(true)
        //objeto para registrar en la base
        const objetoUsuario = {
            cehvalUsuarioCorreo: correo,
            cehvalUsuarioEstado: 1,
            cehvalUsuarioDescripcion: "Nuevo usuario",
            cehvalUsuarioTipo: "",
            rol: rol,
            codigo: codigo,
            cehvalUsuarioNombreUsuario: primerNombre,
            cehvalUsuarioSegundoNombreUsuario: segundoNombre,
            cehvalUsuarioPrimerApellido: primerApellido,
            cehvalUsuarioSegundoApellido: segundoApellido,
        }

        const nombre = primerNombre + " " + segundoNombre + " " + primerApellido + " " + segundoApellido//nombre completo
        const respuestaUsuario = await crearUsuario(objetoUsuario)
        //console.log(respuestaUsuario)
        if (respuestaUsuario) {
            //entra a qui si hay un error http
            if ((isNaN(respuestaUsuario.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(respuestaUsuario)
            } else {
                //respuesta correcta
                //setSpin(true)
                if (respuestaUsuario?.codigo === "000") {
                    ////console.log(respuestaUsuario.mensaje) 
                    //console.log(respuestaUsuario.mensaje)
                    guardarCognito(correo, nombre, respuestaUsuario.mensaje, rol)
                } else {
                    setLoading(false)
                    setMensaje("El correo: " + correo + " ya esta registrado")
                    setErrorUsuarioExistente(true)
                }
            }
        }
    }

    const guardarCognito = async (correo, nombre, nombreUsuario, rol) => {
        
        const params = {
            UserPoolId: process.env.REACT_APP_UserPoolId,
            Username: nombreUsuario,
            DesiredDeliveryMediums: [
                "EMAIL"
            ],

            // TemporaryPassword: password(4),
            UserAttributes: [
                {
                    Name: "email",
                    Value: correo
                },
                {
                    Name: "name",
                    Value: nombre
                },
                {
                    Name: "email_verified",
                    Value: "true",
                },
            ]
        }
        cognitoidentityserviceprovider.adminCreateUser(params, function (err, data) {
            if (err) {
                //console.log(err.message); // an error occurred

                if (err.message === "User account already exists") {
                    setLoading(false)
                    setMensaje("El correo: " + correo + " ya registrado")
                    setErrorUsuarioExistente(true)
                } else {
                    setLoading(false)
                    setMensaje("El correo: " + correo + " ya esta registrado")
                    setErrorUsuarioExistente(false)
                }
            }

            else {
                //si no hay ningun error inscribimos usuario en base  y agregamos a un grupo en cognito
                agregarGrupo(rol, nombreUsuario)
            }
        });
    }

    //funcion para inscribir en un grupo
    const agregarGrupo = async (rol, nombreUsuario) => {

        const params = {
            GroupName: rol,
            UserPoolId: process.env.REACT_APP_UserPoolId,
            Username: nombreUsuario,
        }
        cognitoidentityserviceprovider.adminAddUserToGroup(params, function (err, data) {
            if (err) {
                //console.log(err, err.stack); // an error occurred
                setLoading(false)
            }
            else {
                // //console.log("se agrego a grupo")

                contador++;
                //console.log(contador)
                if (contador === variableComparacion) {
                    //console.log("vamos a actualizar estatus")
                    actualizarEstatus()
                }

            }              // successful response
        });
    }

    const actualizarEstatus = async () => {
        //esta no tiene validacion de errores http
        if (props.tipoParticipante === 4 || props.tipoParticipante === 5) {
            const myObj =
            {
                idEmisor: props.idEmisor,
                estatus: props.estatus + 1,
            }
            const modificarEstatus = await cambiarEstatusEmisor(myObj)
            if (modificarEstatus) {
                setLoading(false)
                //entra a qui si hay un error http
                if ((isNaN(modificarEstatus.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(modificarEstatus)
                } else {
                    //respuesta correcta

                    if (modificarEstatus?.codigo === "000") {
                        setLoading(false)
                        setUsuarioRegistrado(true)
                      /*  if (props.informacion.cehvalEmisorCoreoElecRepreLeg) {
                            setUsuarioRegistrado(true)
                        } else {
                            setSinRepresentante(true)
                        }*/
                    }
                }
            }

        } else {
            const myObj =
            {
                codigo: props.codigo,
                estatus: props.estatus + 1,
            }
            const actualizarEstatus = await cambiarEstatusDepositante(myObj)

            if (actualizarEstatus) {
                setLoading(false)
                //entra a qui si hay un error http
                if ((isNaN(actualizarEstatus.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(actualizarEstatus)
                } else {
                    //respuesta correcta

                    if (actualizarEstatus?.codigo === "000") {
                        setLoading(false)
                        setRegistroCompleto(true)
                    }
                }
            }
        }
    }



    const imprimirReporte = () => {

        setRegistroCompleto(false)
        setUsuarioRegistrado(false)
        setLoading(true)
        irInicio()
    }

    const irInicio = async () => {
        setLoading(true)
        const myobj = {
            codigo: props.folio,
            estatus: 5
        }

        let response;
        if (props.tipoParticipante === 1) {
            response = await reporteAutorizadoDDPJ(myobj)
        } else if (props.tipoParticipante === 2) {
            response = await reporteAutorizadoDIPJ(myobj)
        } else {
            response = await reporteAutorizadoDIPN(myobj)
        }
        if (response) {
            setLoading(false)
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(response)
            } else {
                const file = new Blob(
                    [response],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
                setLoading(false)
                history.go()
            }
        }
    }

    const cancelarModal = () => {
        setUsuarioRegistrado(false)
        history.go()
    }



    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {registroCompleto && !loading &&
                <ComponenteModal mostrarModal={registroCompleto} cerrarModal={imprimirReporte} titulo="Se han registrado a los usuarios que podrán ver las cuentas de valores de este depositante" textoBoton="Imprimir"></ComponenteModal>
            }

            {usuarioNoRegistrado &&
                <ComponenteModal mostrarModal={usuarioNoRegistrado} cerrarModal={() => { setUsuarioNoRegistrado(false) }} titulo="No se pudo registrar a este usuario, revisa los  datos e intentalo de nuevo" textoBoton="Entendido"></ComponenteModal>
            }

            {sinRepresentante &&
                <ComponenteModal mostrarModal={sinRepresentante} cerrarModal={cancelarModal} titulo="Este emisor no tiene representante legal, se ha notificado el registro al depositante directo" textoBoton="Entendido"></ComponenteModal>
            }

            {usuarioRegistrado && !loading &&
                <ComponenteModal mostrarModal={usuarioRegistrado} cerrarModal={cancelarModal} titulo="Se registro con éxito a este usuario" textoBoton="Entendido"></ComponenteModal>
            }

            {errorUsuarioExistente &&
                <ComponenteModal mostrarModal={errorUsuarioExistente} cerrarModal={() => {
                    setErrorUsuarioExistente(false)
                    props.resetear()
                }} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }
        </>
    );
}

