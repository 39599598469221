import { useMemo } from "react";

export default function useFondosPorSubastaYCliente() {
  const columns = useMemo(
    () => [
      {
        Header: "Código cliente CEHVAL",
        accessor: "codigoDepositante"
      },
      {
        Header: "Nombre cliente CEHVAL",
        accessor: "nombreDepositante"
      },
      {
        Header: "Monto",
        accessor: "montoStr"
      },
    ],
    []
  );

  return columns;
}


