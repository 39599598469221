import { useMemo } from "react";

export default function useFondosConciliadosYEliminadosPDP() {
  const columns = useMemo(
    () => [
      {
        Header: "Folio BCH-TR",
        accessor: "folio"
      },
      {
        Header: "fecha",
        accessor: "fechaDePago"
      },
      {
        Header: "Emisor",
        accessor: "emisor"
      },
      {
        Header: "Código de serie",
        accessor: "codigoSerie"
      },        
      {
        Header: "Tipo de pago",
        accessor: "tipoDePago"
      },     
      {
        Header: "Monto",
        accessor: "monto"
      },  
      {
        Header: "Moneda",
        accessor: "moneda"
      },  
    ],
    []
  );

  return columns;
}