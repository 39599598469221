import { useMemo } from "react";

export default function useDepositosYLiquidacionesFondos() {
    const columns = useMemo(
        () => [
         
          {
            Header: "Fecha/hora",
            accessor: "timestamp"
          },
          {
            Header: "Ejecutor",
            accessor: "ejecutor"
          },
          {
            Header: "Folio",
            accessor: "folioBCH"
          },
          {
            Header: "Operación",
            accessor: "operacion"
          },
          {
            Header: "Código de la operación",
            accessor: "codigoOperacion"
          },
          {
            Header: "Depósito/liquidación",
            accessor: "tipoOperacion"
          },
          {
            Header: "Monto",
            accessor: "monto"
          },
          {
            Header: "Moneda",
            accessor: "moneda"
          },
        ],
        []
      );

    return columns;
}
