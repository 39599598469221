//React
import React, { useState, useEffect } from "react";

//Componentes generales
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores';
import TablaUsuarios from "../../../../ComponentesGenerales/TablaUsuarios";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from '../../../../ComponentesGenerales/PantallaCargando'

//APIs
import { traerTodasSolicitudesValoresFisicos } from '../../../../api/resguardoValoresFisicos/registroValoresFisicos/traerTodasSolicitudesValoresFisicos'
import { imprimirValoresFisicosDepositados }  from '../../../../api/impresionValoresFisicos/imprimirValoresFisicosDepositados'

//Hooks
import useSolicitudesDepositosValoresFisicos from '../../../../hooks/ResguardoValoresFisicos/useSolicitudesDepositosValoresFisicos';


export default function ValoresFisicosDepositados({ history }) {

    //const [idValor, setIdValor] = useState();

    const [failedLoadData, setFailedLoadData] = useState();

    const [copia, setCopia] = useState();
    const [data, setData] = useState([]);
    const [spin, setSpin] = useState(true);
    const [erroreshttp, setErroreshttp] = useState();
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        getAllApplications();
    }, []);

    //Handle api to get all applications to be a "depositante de valores fisicos"
    const getAllApplications = async () => {
        try {
            const response = await traerTodasSolicitudesValoresFisicos({ estatus: 3 });
            if (response) {
                setSpin(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    //console.log(response)
                    if (response.status === 200) {
                        setSpin(false);
                        setData(response.data);
                        setCopia(response.data);
                        //setIdValor(response.data.depositoValorFisicoId)
                    } else {
                        setFailedLoadData(true);
                    }
                }
            }

          

        } catch (err) {
            console.error(err);
        }
    }


    //Handle when user click "Abrir solicitud"
    const viewRow = (row) => {

        //console.log("click")
        //Send object of selected row to next screen
        history.push({
            pathname: '/visualizarValorFisico', state: { objRow: row.original }
        });
    }

    //Shows modal when applications doesnt load
    const goHome = () => {
        setFailedLoadData(false);
        window.location.replace("/inicioCehval");
    }

    const goBack = () => {
        history.go(-1)
    }

    const imprimir=async()=>{
        setLoading(true)
        const response = await imprimirValoresFisicosDepositados()
        if (response) {
            //setLoading(false)
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta      
                setLoading(false)
                setErroreshttp(response)
            } else {
                //console.log(response)
                const file = new Blob(
                    [response],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
                setLoading(false)
            }
        }
    }

    return (
        <>
          {loading &&
                <PantallaCargando />
            }

            {failedLoadData &&
                <ComponenteModal mostrarModal={failedLoadData} cerrarModal={goHome} titulo={`Ocurrio algún error, será redireccionado al inicio. Revise su conexión o vuelva a iniciar sesión.`} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="">
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        {/*<div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 d-flex flex-column">
                            <BarraLateral2  ></BarraLateral2>
                        </div>*/}
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Valores físicos depositados"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <>
                                        <div className="d-flex">
                                            <div className="container py-5">
                                                {copia &&
                                                    <>
                                                        <TablaUsuarios datos={data} displayInformation={viewRow} useValidate={useSolicitudesDepositosValoresFisicos} textoBoton={"Abrir solicitud"} textTitle="Clic para ver la información de la solicitud"></TablaUsuarios>
                                                        <div className="col-12 col-md-12 px-3 d-flex justify-content-between justify-content-xl-between justify-content-md-between justify-content-sm-between my-3">
                                                        {data.length>0&&
                                                            <button title="Imprimir valores físicos depositados" type="button" onClick={imprimir} className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1 background-royal-blue text-white bg-hover-gold">Imprimir</button>
                                                        }
                                                            <button title="Regresar" type="button" onClick={goBack} className="btn col-6 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-1 background-royal-blue text-white bg-hover-gold">Atrás</button>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    );
}





