import { useMemo } from "react";

export default function useDesmaterializaciones() {
    const columns = useMemo(
        () => [
            {
                Header: "Código emisor",
                accessor: "solicitudDesmaterializacionCodigoEmisor.cehvalEmisorCodigo"
            },
            {
                Header: "Nombre emisor",
                accessor: "solicitudDesmaterializacionCodigoEmisor.cehvalEmisorNombreCom"
            },
            {
                Header: "Código emisión",
                accessor: "solicitudDesmaterializacionCodigoEmision.cehvalEmisionCodigo"
            },
            {
                Header: "Nombre emisión",
                accessor: "solicitudDesmaterializacionCodigoEmision.cehvalEmisionNomEmi"
            },
        ],
        []
    );

    return columns;
}
