import { instance } from '../apiGlobal';

export async function asociarOperacionForzada (obj) {
      try {

        const response = await instance(`/mercadosecundario/asociar/incumplida`, {
          method:"post",
          data:obj
        });

        return response;
        
      } catch (error) {

        return error;
      }
}