
import { instanciaRegistroPDF } from '../apiGlobal'

export async function descargarPDFPagos(obj) {
      try {
        const response = await instanciaRegistroPDF(`/dividendos/descargar/dictamen`, {
          method: "post",
          data: obj,
          responseType: 'blob',
        })
        
        return response.data
      } catch (error) {
        return error
      }
}