import React from "react";
import '../../../../styles/stylesheet.css'
import { FormContext } from './form_context';
import { FormContext2 } from './form_context2';
import { traerBancos } from "../../../../api/catalogos/traerBancos";
//import { traerMonedas } from "../../../../api/catalogos/traerMonedas";
import { traerTiposCuenta } from "../../../../api/catalogos/traerTiposCuenta";
import { ususarioExistente } from "../../../../api/UsuarioExistente/usuarioExistente";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";
import { ConsoleLogger } from "@aws-amplify/core";

export default function ActualizarDD4({ tipo }) {
    const [value, setValue] = React.useContext(FormContext);
    const [value2, setValue2] = React.useContext(FormContext2);
    const [bancos, setBancos] = React.useState([]);
    const [tiposCuenta, setTiposCuenta] = React.useState([]);
    //const [monedas, setMonedas] = React.useState([]);
    const [errorUsuarioExistente, setErrorUsuarioExistente] = React.useState();
    const [erroreshttp, setErroreshttp] = React.useState()

    const [correo1, setCorreo1] = React.useState(value2.datos.correo1)
    const [correo2, setCorreo2] = React.useState(value2.datos.correo2)
    const [errorCuentaNacional, setErrorCuentaNacional] = React.useState()
    const [errorCuentaExtranjera, setErrorCuentaExtranjera] = React.useState()

    const [exencionImpuestosInicio,setExencionImpuestosInicio]=React.useState(true)



    // const arreglo = [1, 2, 3, 4, 5, 6];


    const guardarDatos = (e) => {
        const { name, value } = e.target;

        if (name.indexOf("correo") >= 0) {
            //validarUsuario(name, value)
            setValue2((prev) => {
                const datos = { ...prev.datos, [name]: value.toLowerCase() };
                return { ...prev, datos };
            });
        } else {
            setValue2((prev) => {
                //const datos = { ...prev.datos, [name]: value };
                const datos = { ...prev.datos, [name]: value };
                return { ...prev, datos };
            });
        }
    }

    const validarUsuario = async (name, value) => {
        //console.log(value)
        if (name === "correo1") {
            if (value != value2.datos.correo1) {
                //console.log(value2.datos.correo1)
                validacionCorreoFinal(name, value)
            }
        } else {
            if (value != value2.datos.correo2) {
                //console.log(value2.datos.correo2)
                validacionCorreoFinal(name, value)
            }
        }
    }

    const validacionCorreoFinal = async (name, value) => {
        value = value.replace(/\s+/g, '')

        const obj = {
            correo: value
        }
        const resultado = await ususarioExistente(obj)
        //console.log(resultado)

        if (resultado) {
            //setSpin(false)
            if ((isNaN(resultado.error)) === false) {
              
                setErroreshttp(resultado)
                
            } else {

                if (resultado?.codigo === "000") {
                    // //console.log(name)
                    if (name === "correo1") {
                        setCorreo1("")
                    } else {
                        setCorreo2("")
                    }
                    document.getElementById(name).value = "";

                    setErrorUsuarioExistente(true)
                } else {

                    if (correoValido(value)) {
                        setValue2((prev) => {
                            const datos = { ...prev.datos, [name]: value.toLowerCase() };
                            return { ...prev, datos };
                        });
                    }

                }
            }
        }
    }

    const correoValido = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    React.useEffect(() => {
        traerDatos()
    }, [])

    const traerDatos = async () => {
        const catalogoBancos = await traerBancos()
        if (catalogoBancos) {
            //setSpin(false)
            if ((isNaN(catalogoBancos.error)) === false) {
              
                setErroreshttp(catalogoBancos)
                
            } else {
                // //console.log(traerInformacion)
                setBancos(catalogoBancos)
            }
        }

        const catalogoTiposCuenta = await traerTiposCuenta()
        if (catalogoTiposCuenta) {
            //setSpin(false)
            if ((isNaN(catalogoTiposCuenta.error)) === false) {
              
                setErroreshttp(catalogoTiposCuenta)
                
            } else {
                // //console.log(traerInformacion)
                //setActividadEconomica(catalogoNacionalidad)

                setTiposCuenta(catalogoTiposCuenta)
            }
        }

        //  setMonedas(await traerMonedas())
    }

    const checarNumeros = (e) => {
        const { name, value } = e.target;
        let out = '';
        var filtro = '1234567890-';//Caracteres validos

       /* if (e.keyCode > 64 && e.keyCode < 91) {
            if (name === "numeroCuentaN") {
                setErrorCuentaNacional(true)
                setErrorCuentaExtranjera(false)
            } else {
                setErrorCuentaNacional(false)
                setErrorCuentaExtranjera(true)
            }
        } else {
            setErrorCuentaNacional(false)
            setErrorCuentaExtranjera(false)
        }*/

        //Recorrer el texto y verificar si el caracter se encuentra en la lista de validos 
        for (var i = 0; i < value.length; i++)
            if (filtro.indexOf(value.charAt(i)) != -1)
                //Se añaden a la salida los caracteres validos
                out += value.charAt(i);

        setValue2((prev) => {
            const datos = { ...prev.datos, [name]: out };
            return { ...prev, datos };
        });
    }




    return (
        <>
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {errorUsuarioExistente &&
                <ComponenteModal mostrarModal={errorUsuarioExistente} cerrarModal={() => { setErrorUsuarioExistente(false) }} titulo="Este correo ya esta registrado, ingresa otro" textoBoton="Entendido"></ComponenteModal>
            }

            <div className="card-body pt-3">
                <h5 className="text-royal-blue font-acumin-variable" >IV. Instrucciones especiales (cuentas bancarias)</h5>
                <h6 className="text-royal-blue font-acumin-variable">Nacional</h6>

                {value.datos.establecerModificacion ?
                    <>
                        <div className="row mb-3">
                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled  type="text" className="form-control fs-5 border  " value={"LEMPIRA"} required />
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Moneda</label>
                                </div>
                            </div>

                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    {value2.datos.bancoNInicio ?
                                        <select className="form-select fs-5 border  " value={value2.datos.bancoN} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["bancoNInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                        }} id="bancoN" name="bancoN">
                                            <option value={value2.datos.bancoN}>{value2.datos.bancoN} </option>
                                            {
                                                bancos.filter((item => item.nombre !== value2.datos.bancoN)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>
                                        :
                                        <select className="form-select fs-5 border  " value={value2.datos.bancoN} onChange={(e) => guardarDatos(e)} id="bancoN" name="bancoN">
                                            <option value=""> </option>
                                            {
                                                bancos.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>

                                    }

                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Bancos</label>
                                </div>

                            </div>


                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    {value2.datos.tipoCuentaNInicio ?
                                        <select className="form-select fs-5 border  " value={value2.datos.tipoCuentaN} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["tipoCuentaNInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                        }} id="tipoCuentaN" name="tipoCuentaN">
                                            <option value={value2.datos.tipoCuentaN}>{value2.datos.tipoCuentaN} </option>
                                            {
                                                tiposCuenta.filter((item => item.nombre !== value2.datos.tipoCuentaN)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                        </select>
                                        :
                                        <select className="form-select fs-5 border  " value={value2.datos.tipoCuentaN} onChange={(e) => guardarDatos(e)} id="tipoCuentaN" name="tipoCuentaN">
                                            <option value=""> </option>
                                            {
                                                tiposCuenta.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                        </select>


                                    }

                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Tipo de cuenta</label>
                                </div>

                            </div>

                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input placeholder="" type="text" maxLength={22} className="form-control fs-5 border  " value={value2.datos.numeroCuentaN} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e)} id="numeroCuentaN" name="numeroCuentaN" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Número de cuenta</label>

                                </div>

                            </div>
                        </div>
                        <h6 className="text-royal-blue font-acumin-variable" >Extranjera</h6>

                        <div className="row mb-3">
                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5 border  " value={"DÓLAR"} />
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Moneda</label>
                                </div>

                            </div>

                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    {value2.datos.bancoEInicio ?
                                        <select className="form-select fs-5 border  " value={value2.datos.bancoE} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["bancoEInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                        }} id="bancoE" name="bancoE">
                                            <option value={value2.datos.bancoE}>{value2.datos.bancoE} </option>
                                            {
                                                bancos.filter((item => item.nombre !== value2.datos.bancoE)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                        </select>
                                        :
                                        <select className="form-select fs-5 border  " value={value2.datos.bancoE} onChange={(e) => guardarDatos(e)} id="bancoE" name="bancoE">
                                            <option value=""> </option>
                                            {
                                                bancos.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                        </select>

                                    }

                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Banco</label>
                                </div>
                            </div>
                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    {value2.datos.tipoCuentaEInicio ?
                                        <select className="form-select fs-5 border  " value={value2.datos.tipoCuentaE} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["tipoCuentaEInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                        }} id="tipoCuentaE" name="tipoCuentaE">
                                            <option value={value2.datos.tipoCuentaE}>{value2.datos.tipoCuentaE} </option>
                                            {
                                                tiposCuenta.filter((item => item.nombre !== value2.datos.tipoCuentaE)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                        </select>
                                        :
                                        <select className="form-select fs-5 border  " value={value2.datos.tipoCuentaE} onChange={(e) => guardarDatos(e)} id="tipoCuentaE" name="tipoCuentaE">
                                            <option value=""> </option>
                                            {
                                                tiposCuenta.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                        </select>
                                    }

                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Tipo de cuenta</label>
                                </div>

                            </div>
                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input placeholder="" type="text" maxLength={22} className="form-control fs-5 border  " value={value2.datos.numeroCuentaE} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e)} id="numeroCuentaE" name="numeroCuentaE" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Número de cuenta</label>
                                </div>
                            </div>
                        </div>

                        <h5 className="text-royal-blue font-acumin-variable " >IV. Instrucciones especiales (exención de impuestos en operaciones con valores)</h5>
                        <div className="col-md-7 mb-5">
                            <label>¿La institución esta eximida de impuestos en operaciones con valores? *</label>
                            {exencionImpuestosInicio ?
                                <div className="d-flex justify-content-start">
                                    <div className="contenedorBotonesStteper">
                                        {value2.datos.exencionInpuestos === 1 ?
                                            < input placeholder="" type="radio" className="form-check-input me-2" value={1} onChange={(e) => {
                                                guardarDatos(e)
                                                setExencionImpuestosInicio(false)
                                            }} id="exencionInpuestosSi" name="exencionInpuestos" required checked />
                                            :
                                            < input placeholder="" type="radio" className="form-check-input  me-2" value={1} onChange={(e) => {
                                                guardarDatos(e)
                                                setExencionImpuestosInicio(false)
                                            }} id="exencionInpuestosSi" name="exencionInpuestos" required />
                                        }
                                        <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="exencionInpuestosSi">Si</label>
                                    </div>

                                    <div className="contenedorBotonesStteper">
                                        {value2.datos.exencionInpuestos === 1 ?
                                            < input placeholder="" type="radio" className="form-check-input  me-2" value={0} onChange={(e) => {
                                                guardarDatos(e)
                                                setExencionImpuestosInicio(false)
                                            }} id="exencionInpuestosNo" name="exencionInpuestos" required />
                                            :
                                            < input placeholder="" type="radio" className="form-check-input me-2" value={0} onChange={(e) => {
                                                guardarDatos(e)
                                                setExencionImpuestosInicio(false)
                                            }} id="exencionInpuestosNo" name="exencionInpuestos" required checked />
                                        }

                                        <label className="form-check-label textoFormularioDepositantedirecto  mt-1" htmlFor="exencionInpuestosNo">No</label>
                                    </div>
                                </div>

                                :

                                <div className="d-flex justify-content-start">
                                    <div className="contenedorBotonesStteper">
                                        < input placeholder="" type="radio" className="form-check-input disabled me-2" value={1} onChange={(e) => guardarDatos(e)} id="exencionInpuestosSi" name="exencionInpuestos" required />
                                        <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="exencionInpuestosSi">Si</label>
                                    </div>

                                    <div className="contenedorBotonesStteper">
                                        < input placeholder="" type="radio" className="form-check-input disabled me-2" value={0} onChange={(e) => guardarDatos(e)} id="exencionInpuestosNo" name="exencionInpuestos" required />
                                        <label className="form-check-label textoFormularioDepositantedirecto  mt-1" htmlFor="exencionInpuestosNo">No</label>
                                    </div>
                                </div>
                            }


                        </div>

                        <h5 className="text-royal-blue font-acumin-variable " >IV. Instrucciones especiales (usuarios)</h5>


                        {tipo === "depositanteDirecto" ? <h6 className="text-royal-blue font-acumin-variable">1. Usuario administrador *</h6>
                            : <h6 className="text-royal-blue font-acumin-variable">1. Usuario operador *</h6>

                        }

                        <div className="row mb-3">

                            <div className=" col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" type="text" className="form-control fs-5" value={value2.datos.nombreCompleto1} onChange={(e) => guardarDatos(e)} id="nombreCompleto1" name="nombreCompleto1" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer Nombre *</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" type="text" className="form-control fs-5" value={value2.datos.segundoNombre1} onChange={(e) => guardarDatos(e)} id="segundoNombre1" name="segundoNombre1" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo Nombre</label>
                                </div>
                            </div>
                            <div className=" col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input placeholder="" type="text" className="form-control fs-5" value={value2.datos.primerApellido1} onChange={(e) => guardarDatos(e)} id="primerApellido1" name="primerApellido1" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer apellido *</label>
                                </div>
                            </div>
                            <div className=" col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" type="text" className="form-control fs-5" value={value2.datos.segundoApellido1} onChange={(e) => guardarDatos(e)} id="segundoApellido1" name="segundoApellido1" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo apellido</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input style={{ textTransform: "lowercase" }} placeholder="" type="email" className="form-control fs-5" value={correo1} onChange={(e) => setCorreo1(e.target.value)} onBlur={(e) => guardarDatos(e)} onMouseOut={(e) => guardarDatos(e)} id="correo1" name="correo1" aria-describedby="emailHelp" required />
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-6 textoFormularioDepositantedirecto">Correo electrónico *</label>
                                </div>
                            </div>

                        </div>
                        <h6 className="text-royal-blue font-acumin-variable">2. Usuario operador *</h6>
                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input placeholder="" type="text" className="form-control fs-5" value={value2.datos.nombreCompleto2} onChange={(e) => guardarDatos(e)} id="nombreCompleto2" name="nombreCompleto2" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer nombre *</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input placeholder="" type="text" className="form-control fs-5" value={value2.datos.segundoNombre2} onChange={(e) => guardarDatos(e)} id="segundoNombre2" name="segundoNombre2" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo nombre</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input placeholder="" type="text" className="form-control fs-5" value={value2.datos.primerApellido2} onChange={(e) => guardarDatos(e)} id="primerApellido2" name="primerApellido2" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer apellido *</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input placeholder="" type="text" className="form-control fs-5" value={value2.datos.segundoApellido2} onChange={(e) => guardarDatos(e)} id="segundoApellido2" name="segundoApellido2" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo apellido</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input style={{ textTransform: "lowercase" }} placeholder="" type="email" className="form-control fs-5 " value={correo2} onChange={(e) => setCorreo2(e.target.value)} onBlur={(e) => guardarDatos(e)} onMouseOut={(e) => guardarDatos(e)} id="correo2" name="correo2" aria-describedby="emailHelp" required />
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-6 textoFormularioDepositantedirecto">Correo electrónico *</label>
                                </div>
                            </div>
                        </div>
                    </>

                    :
                    <>
                        <div className="row mb-3">
                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5 border  " value={"LEMPIRA"} required />
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Moneda</label>
                                </div>
                            </div>

                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5 border  " value={value.datos.bancoN} required />
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Bancos</label>
                                </div>
                            </div>



                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5 border  " value={value.datos.tipoCuentaN} required />
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Tipo de cuenta</label>
                                </div>
                            </div>


                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" maxLength={22} className="form-control fs-5 border  " value={value.datos.numeroCuentaN} id="numeroCuentaN" name="numeroCuentaN" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Número de cuenta</label>
                                </div>
                            </div>
                        </div>
                        <h6 className="text-royal-blue font-acumin-variable" >Extranjera</h6>

                        <div className="row mb-3">
                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5 border  " value={"DÓLAR"} />
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Moneda</label>
                                </div>

                            </div>


                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5 border  " value={value.datos.bancoE} required />
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Bancos</label>
                                </div>
                            </div>

                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5 border  " value={value.datos.tipoCuentaE} />
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">tipoCuentaE</label>
                                </div>
                            </div>


                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" maxLength={22} className="form-control fs-5 border  " value={value.datos.numeroCuentaE} id="numeroCuentaE" name="numeroCuentaE" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Número de cuenta</label>
                                </div>
                            </div>
                        </div>

                        <h5 className="text-royal-blue font-acumin-variable " >IV. Instrucciones especiales (exención de impuestos en operaciones con valores)</h5>
                        <div className="col-md-7 mb-5">
                            <label>¿La institución esta eximida de impuestos en operaciones con valores? *</label>
                            <div className="d-flex justify-content-start">
                                <div className="contenedorBotonesStteper">
                                    {value.datos.exencionInpuestos === 1 ?
                                        < input disabled type="radio" className="form-check-input disabled me-2" value={1} id="exencionInpuestosSi" name="exencionInpuestos" required checked />
                                        :
                                        < input disabled type="radio" className="form-check-input disabled me-2" value={1} id="exencionInpuestosSi" name="exencionInpuestos" required />
                                    }
                                    <label className="form-check-label textoFormularioDepositantedirecto me-5 mt-1" htmlFor="exencionInpuestosSi">Si</label>
                                </div>

                                <div className="contenedorBotonesStteper">
                                    {value.datos.exencionInpuestos === 1 ?
                                        < input disabled type="radio" className="form-check-input disabled me-2" value={0} id="exencionInpuestosNo" name="exencionInpuestos" required />
                                        :
                                        < input disabled type="radio" className="form-check-input disabled me-2" value={0} id="exencionInpuestosNo" name="exencionInpuestos" required checked />
                                    }

                                    <label className="form-check-label textoFormularioDepositantedirecto  mt-1" htmlFor="exencionInpuestosNo">No</label>
                                </div>
                            </div>
                        </div>



                        <h5 className="text-royal-blue font-acumin-variable " >IV. Instrucciones especiales (usuarios)</h5>


                        {tipo === "depositanteDirecto" ? <h6 className="text-royal-blue font-acumin-variable">1. Usuario administrador *</h6>
                            : <h6 className="text-royal-blue font-acumin-variable">1. Usuario operador *</h6>

                        }

                        <div className="row mb-3">

                            <div className=" col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" className="form-control fs-5" value={value.datos.nombreCompleto1} id="nombreCompleto1" name="nombreCompleto1" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer Nombre *</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" className="form-control fs-5" value={value.datos.segundoNombre1} id="segundoNombre1" name="segundoNombre1" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo Nombre</label>
                                </div>
                            </div>
                            <div className=" col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5" value={value.datos.primerApellido1} id="primerApellido1" name="primerApellido1" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer apellido *</label>
                                </div>
                            </div>
                            <div className=" col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" className="form-control fs-5" value={value.datos.segundoApellido1} id="segundoApellido1" name="segundoApellido1" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo apellido</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled style={{ textTransform: "lowercase" }} placeholder="" type="email" className="form-control fs-5" value={correo1} id="correo1" name="correo1" aria-describedby="emailHelp" required />
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-6 textoFormularioDepositantedirecto">Correo electrónico *</label>
                                </div>
                            </div>

                        </div>
                        <h6 className="text-royal-blue font-acumin-variable">2. Usuario operador *</h6>
                        <div className="row mb-3">
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5" value={value.datos.nombreCompleto2} id="nombreCompleto2" name="nombreCompleto2" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer nombre *</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5" value={value.datos.segundoNombre2} id="segundoNombre2" name="segundoNombre2" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo nombre</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5" value={value.datos.primerApellido2} id="primerApellido2" name="primerApellido2" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer apellido *</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5" value={value.datos.segundoApellido2} id="segundoApellido2" name="segundoApellido2" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo apellido</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input disabled style={{ textTransform: "lowercase" }} placeholder="" type="email" className="form-control fs-5 " value={correo2} id="correo2" name="correo2" aria-describedby="emailHelp" required />
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-6 textoFormularioDepositantedirecto">Correo electrónico *</label>
                                </div>
                            </div>
                        </div>
                    </>


                }






            </div>

        </>
    );
}

