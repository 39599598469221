import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import '../../../../styles/stylesheet.css';

//Components
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import TablaUsuarios from "../../../../ComponentesGenerales/TablaUsuarios";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";

//APIs
import { traerValoresPorNumeroCuenta } from "../../../../api/cuentaValores/traerValoresPorNumeroCuenta";

//Hooks
import useCuentaValoresTLP from "../../../../hooks/useCuentaValoresTLP";




export default function ValoresDepDirecto({ history }) {
    if (history.location.state === undefined) {
        history.go(-1)
    }

    const [accountNumber, setAccountNumber] = useState();
    const [accountTypeCurrency, setAccountTypeCUrrency] = useState();


    const [resquestFail, setRequestFail] = useState();


    const [data, setData] = useState([])
    const [copia, setCopia] = useState();
    const [erroreshttp, setErroreshttp] = React.useState()

    const historyReact = useHistory();

    const tipo=history.location.state.objNumCuenta.tipo


    useEffect(() => {
        viewAccountValues();
    }, []);


    //Handle the api to get the values for the selected account
    const viewAccountValues = async () => {

        try {

            if (history.location.state === undefined) {
                window.location.replace("/misCuentasDD");
                //console.log("no hay numero de cuenta")

            } else {
                const getAccountNumber = history.location.state.objNumCuenta.accountNumber;
                setAccountNumber(getAccountNumber);

                const getAccountTypeCurrency = history.location.state.objNumCuenta.currencyType;
                setAccountTypeCUrrency(getAccountTypeCurrency);

                const response = await traerValoresPorNumeroCuenta({ numeroCuentaValores: getAccountNumber });
                if (response) {
                    if ((isNaN(response.error)) === false) {
                        // respuestaincorrecta           
                        setErroreshttp(response)
                    } else {
                        //console.log(response.data)
                        if (response.status === 200) {
                            setData(response.data);
                            setCopia(response.data);
        
                        } else {
                            setRequestFail(true);
                        }
                    }
                }


              
            }

        } catch (error) {
            console.error(error)
        }
    }

    //Handle whe user click on "Transferir", and we'll origin account data for transaction with the id
    const transferValue = (row) => {

        const saldoDisponible = row.original.cantidadValores;
        const isin = row.original.isinSerie;
        const codSerie = row.original.codigoSerie;
        const codEmision = row.original.codigoEmision;


        const datosParaFormulario = {
            numeroCuenta: accountNumber,
            moneda: accountTypeCurrency,
            saldoDisponible: saldoDisponible,
            isinSerie: isin,
            codSerie: codSerie,
            codEmision: codEmision,
            idValor: row.original.idValor,
        }

        history.push({
            pathname: '/transferenciaLibrePagoDD',
            state: {
                datosTransferente: datosParaFormulario
            }
        })

    }

     //Handle modal if data for table doesn load
     const goToBack = () => {
        setRequestFail(false);
        window.location.replace("/misCuentassDD");
    }


    return (
        <>
             {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            
            {resquestFail &&
                <ComponenteModal mostrarModal={resquestFail} cerrarModal={goToBack} titulo={`Serás redirigido a la tabla de tus cuentas`} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="fluid-container">
                
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Valores de la cuenta"></BannerTitulo>
                                <div className="row justify-content-center align-items-center table-container g-0">
                                    {copia ?
                                        <div className="container ">
                                            <form className="px-3">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <div className="form-floating ">
                                                            <input title="Código del depositante" className="form-control text-uppercase font-acumin-variable" value={accountNumber} name="codigoDepTransferente" type="text" placeholder="Código del depositante" required disabled />
                                                            <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoDepTransferente">Número cuenta</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <div className="form-floating ">
                                                            <input title="Número de cuenta de origen" className="form-control text-uppercase font-acumin-variable" value={accountTypeCurrency} name="nombreDepTransferente" type="text" placeholder="Nombre del depositante" required disabled />
                                                            <label className="text-start font-acumin-variable text-royal-blue" htmlFor="nombreDepTransferente">Tipo de moneda</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            {tipo?
                                             <TablaUsuarios datos={data} useValidate={useCuentaValoresTLP} displayInformation={transferValue} textoBoton={"Transferir"} textTitle={"Transferir valores"}></TablaUsuarios>
                                             :
                                             <TablaUsuarios datos={data} useValidate={useCuentaValoresTLP} ></TablaUsuarios>
                                            }
                                            
                                            <div className="p-3">
                                                <button className="btn background-royal-blue text-white font-acumin-variable" onClick={() => { historyReact.goBack() }}>Atrás</button>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className="row row-wrap justify-content-center">
                                                <h4 className="col-12 text-center">Cargando...</h4>
                                                <div className="col-12 spinner-border" role="status"></div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    );
}