
import { instance } from '../apiGlobal';

export async function consultaEmisionesGenerarISIN (obj) {
    
  try {
    const response = await instance(`/isin/consulta/emisor`, {
      method: "post",
      data: obj
    });
    
    return response;
  } catch (error) {
    return error;
  }
}