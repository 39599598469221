import React from 'react';
import ReactDOM from 'react-dom';
import App  from './App';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import 'remixicon/fonts/remixicon.css';
//import './index.css';
//import App  from '../src/pantallasIncode/App';
//import AppPrueba  from '../src/pantallasIncode/AppPrueba';

ReactDOM.render(
    <App />
  , document.getElementById('root'));
