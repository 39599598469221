import { useMemo } from "react";

export default function useColumns() {
 const columns = useMemo(
   () => [   
     {
       Header:"Primer nombre",
       accessor: "cehvalDepositantePersonaNatId.cehvalPersonaNatPrimerNom"
     },
     {
        Header:"Primer apellido",
        accessor: "cehvalDepositantePersonaNatId.cehvalPersonaNatPrimerApe"
      },
     {
       Header: "Tipo",
       accessor: "cehvalDepositanteCatTipDepId.nombre"
     },
    /* {
       Header: "Folio registro",
       accessor: "cehvalDepositanteCodigo"
     }, */
   ],
   []
 );

 return columns;
}
