import { useMemo } from "react";

export default function useBajaAltaUsuarios() {
  const columns = useMemo(
    () => [
      {
        Header: "Nombre",
        accessor: "nombre"
      },
      {
        Header: "Apellido",
        accessor: "primerApellido"
      },
      {
        Header: "User name",
        accessor: "usuario"
      },
      {
        Header: "Rol",
        accessor: "roles[0]"
      },
  
    ],
    []
  );

  return columns;
}

