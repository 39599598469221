import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

//Components
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import TablaUsuarios from "../../../../ComponentesGenerales/TablaUsuarios";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";

//APIs
import { consultarCuentaDepositante } from "../../../../api/transferenciaLibreDePago/consultarCuentaDepositante";

//Hooks
import useVerCuentasDepositanteDirecto from "../../../../hooks/useVerCuentasDepositanteDirecto";



export default function MisCuentasDD({ history }) {
    const location = useLocation();
    const tipo = location.state;

    const codigo = (JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo

    //States to show modal if data doesnt load
    const [resquestFail, setRequestFail] = useState();

    const [data, setData] = useState([]);
    const [copia, setCopia] = useState();
    const [erroreshttp, setErroreshttp] = React.useState()

    useEffect(() => {
        viewAccounts();
    }, []);

    //Handle api to get all depositant accounts
    const viewAccounts = async () => {

        try {
            const response = await consultarCuentaDepositante({ codigo: codigo });
            if (response) {
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    if (response.status === 200) {
                        setData(response.data);
                        setCopia(response.data);
                    } else {
                        setRequestFail(true);
                    }
                }
            }


        } catch (err) {
            console.error(err);
        }
    }

    //Handle when user click "Ver cuentas"
    const viewValues = (row) => {
        const selectedAccountNumber = row.original.cehvalCuentaValNum;
        const currencyType = row.original.catMonedaCatMonedaId.catMonedaDivisa;

        history.push({
            pathname: '/misValoresDD',
            state: {
                objNumCuenta: {
                    tipo: tipo,
                    accountNumber: selectedAccountNumber,
                    currencyType: currencyType,
                }
            }
        });
    }

    //Handle modal if data for table doesn load
    const goHome = () => {
        setRequestFail(false);
        window.location.replace("/inicioCehval");
    }


    return (
        <>
            {resquestFail &&
                <ComponenteModal mostrarModal={resquestFail} cerrarModal={goHome} titulo={`Ocurrio algún error, será redireccionado al inicio. Revise su conexión o vuelva a iniciar sesión.`} textoBoton="Aceptar"></ComponenteModal>
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            <div className="fluid-container">
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Mis cuentas"></BannerTitulo>
                                <div className="row justify-content-center align-items-center table-container g-0">

                                    {copia ?

                                        <div className="container py-5">
                                            <TablaUsuarios datos={data} useValidate={useVerCuentasDepositanteDirecto} displayInformation={viewValues} textoBoton={"Ver cuenta"} textTitle="Ver valores de la cuenta" ></TablaUsuarios>
                                        </div>
                                        :
                                        <>
                                            <div className="row row-wrap justify-content-center">
                                                <h4 className="col-12 text-center">Cargando...</h4>
                                                <div className="col-12 spinner-border" role="status"></div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main >

            </div>

        </>
    );
}


