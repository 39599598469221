import React, { useState, useEffect } from "react";

//AWS
//import { Auth } from 'aws-amplify';

//Componentes generales
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores';
import TablaUsuarios from "../../../../ComponentesGenerales/TablaUsuarios";
//import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";

//APIs
import { verTodosAvisosCobroAutPorAutorizar } from "../../../../api/tarificacion/ConceptosAutomaticos/autorizarAvisos/verTodosAvisosCobroAutPorAutorizar";

//Hooks
import useAvisosCobroAutomaticos from "../../../../hooks/Tarificacion/ConceptosAutomaticos/useAvisosCobroAutomaticos";



export default function AvisosCobroAutomaticosPorAutorizar({ history }) {

    //Save loaded data

    const [data, setData] = useState([]);

    //Spinner
    const [spin, setSpin] = useState(false);

    //States to handle load data when loading page first time or reload the page
    const [erroreshttp, setErroreshttp] = useState();




    useEffect(() => {
        getAllApplications();
    }, []);


    const getAllApplications = async () => {
        try {
            setSpin(true);
            const response = await verTodosAvisosCobroAutPorAutorizar();
            //console.log(response)


            if (isNaN(response.error) === false) {
                setErroreshttp(response);
                setSpin(false);


            } else if (response.data) {
                setData(response.data);

                setSpin(false);
            }

        } catch (err) {
            console.error(err);
        }
    }


    const viewRowInformation = (row) => {
        const rowInfo = row.original;

        //Mandar el objeto de la emision que el usuario desea ver
        history.push({
            pathname: '/autorizarAvisoCobroConceptosAutomaticos', state: { rowInfo: rowInfo }
        });
    }




    return (
        <>
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            <div className="">
                <main className="min-height-40">
                    <div className="row row-wrap g-0">
                     
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Facturas de conceptos tarificables automáticos por autorizar"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container-fluid container-md-container container-lg-none container-xl-none container-xxl-none px-3">
                                            {data &&
                                                <TablaUsuarios datos={data} displayInformation={viewRowInformation} useValidate={useAvisosCobroAutomaticos} textoBoton={"Abrir"} textTitle="Click para ver la información"></TablaUsuarios>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}