//React
import React, { useState, useEffect } from "react";

//AWS
import { Auth } from 'aws-amplify';

//Componentes generales
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../ComponentesGenerales/ManejadorErrores';

import TablaSalvaguardasFinancieras from "../../../ComponentesGenerales/TablaSalvaguardasFinancieras";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { operacionesForzadasNoAsociadas } from "../../../api/SalvaguardasFinancieras/operacionesForzadasNoAsociadas";
import { asociarOperacionForzada } from "../../../api/SalvaguardasFinancieras/asociarOperacionForzada"

//Hooks
import useOperacionesForzadasNoAsociadas from '../../../hooks/SalvaguardasFinancieras/useOperacionesForzadasNoAsociadas';

const objPrueba = [
    {
        asociacionesDisponibles: ["135119", "135120", "135121", "135122"],
        operacionNoAsociada: {
            activa: 1,
            codigoCasaBolsaComprador: "DDIRPJ-001",
            codigoCasaBolsaVendedor: "DDIRPJ-004",
            codigoClienteComprador: "DDIRPJ-001",
            codigoClienteVendedor: "CEHVAL",
            codigoEmision: "EMISION01E",
            codigoSerie: "EMISION01E_01",
            estatusOperacion: 11,
            estatusOperacionStr: "SIN ASOCIAR",
            estatusValidacion: 0,
            estatusValidacionStr: null,
            fechaEjecucionInicio: 1680498000000,
            fechaEjecucionInicioStr: "03/04/2023",
            fechaEjecucionVencimiento: null,
            fechaEjecucionVencimientoStr: null,
            fechaOperacion: 1679032800000,
            fechaOperacionStr: "17/03/2023",
            folio: "100000",
            folioAsociado: null,
            infoLiqBanco: null,
            infoLiqCuentaBCHTR: null,
            infoLiqDiferenciaCv: null,
            infoLiqDireccion: null,
            infoLiqFolioOperacionAsociada: null,
            infoLiqMontoTotalAPagar: null,
            infoLiqNombreClienteCehval: null,
            infoLiqRtn: null,
            infoLiqTipoCuenta: null,
            isin: "HNBACH100855",
            monedaLiquidacion: 2,
            monedaLiquidacionStr: "USD",
            monedaSerie: 2,
            monedaSerieStr: "USD",
            montoTotalPagarInicio: 990000,
            montoTotalPagarInicioStr: "990,000.00",
            montoTotalPagarVencimiento: null,
            montoTotalPagarVencimientoStr: null,
            nombreClienteComprador: "sabritas",
            nombreClienteVendedor: "CEHVAL",
            nombreEmisor: "BANCO DE AMERICA CENTRAL HONDURAS, S.A.",
            nombreSerie: "T",
            numeroValores: 100,
            numeroValoresStr: "100",
            plazoDias: 45,
            plazoDiasStr: "45",
            precioUnitarioInicio: null,
            precioUnitarioInicioStr: null,
            precioUnitarioVencimiento: null,
            precioUnitarioVencimientoStr: null,
            tipo: 5,
            tipoCambio: 1,
            tipoCambioStr: "1.00",
            tipoStr: "COMPRAVENTA FORZADA",
        },
    },
    {
        asociacionesDisponibles: ["136119", "136120", "136121"],
        operacionNoAsociada: {
            activa: 1,
            codigoCasaBolsaComprador: "DDIRPJ-001",
            codigoCasaBolsaVendedor: "DDIRPJ-004",
            codigoClienteComprador: "DDIRPJ-001",
            codigoClienteVendedor: "CEHVAL",
            codigoEmision: "EMISION01E",
            codigoSerie: "EMISION01E_01",
            estatusOperacion: 11,
            estatusOperacionStr: "SIN ASOCIAR",
            estatusValidacion: 0,
            estatusValidacionStr: null,
            fechaEjecucionInicio: 1680498000000,
            fechaEjecucionInicioStr: "03/04/2023",
            fechaEjecucionVencimiento: null,
            fechaEjecucionVencimientoStr: null,
            fechaOperacion: 1679032800000,
            fechaOperacionStr: "17/03/2023",
            folio: "200000",
            folioAsociado: null,
            infoLiqBanco: null,
            infoLiqCuentaBCHTR: null,
            infoLiqDiferenciaCv: null,
            infoLiqDireccion: null,
            infoLiqFolioOperacionAsociada: null,
            infoLiqMontoTotalAPagar: null,
            infoLiqNombreClienteCehval: null,
            infoLiqRtn: null,
            infoLiqTipoCuenta: null,
            isin: "HNBACH100855",
            monedaLiquidacion: 2,
            monedaLiquidacionStr: "USD",
            monedaSerie: 2,
            monedaSerieStr: "USD",
            montoTotalPagarInicio: 990000,
            montoTotalPagarInicioStr: "990,000.00",
            montoTotalPagarVencimiento: null,
            montoTotalPagarVencimientoStr: null,
            nombreClienteComprador: "sabritas",
            nombreClienteVendedor: "CEHVAL",
            nombreEmisor: "BANCO DE AMERICA CENTRAL HONDURAS, S.A.",
            nombreSerie: "T",
            numeroValores: 100,
            numeroValoresStr: "100",
            plazoDias: 45,
            plazoDiasStr: "45",
            precioUnitarioInicio: null,
            precioUnitarioInicioStr: null,
            precioUnitarioVencimiento: null,
            precioUnitarioVencimientoStr: null,
            tipo: 5,
            tipoCambio: 1,
            tipoCambioStr: "1.00",
            tipoStr: "COMPRAVENTA FORZADA",
        }
    },

]

const datosPrueba = [
    {
        folioOperacion: 135453,
        isin: "HN123456789",
        valores: 10,
        monedaLiquidacion: "USD",
        tipoOperacion: "Reporto",
    },
    {
        folioOperacion: 987321,
        isin: "HN123456734",
        valores: 100,
        monedaLiquidacion: "USD",
        tipoOperacion: "Compraventa",
    },
    {
        folioOperacion: 736483,
        isin: "HN123456718",
        valores: 200,
        monedaLiquidacion: "HNL",
        tipoOperacion: "Compraventa",
    },
    {
        folioOperacion: 195846,
        isin: "HN123456756",
        valores: 15,
        monedaLiquidacion: "HNL",
        tipoOperacion: "Reporto",
    }
]



export default function AsociarOperacionesForzadas({ history }) {


    //Save loaded data
    const [copia, setCopia] = useState();


    //Objeto con las operaciones forzadas. Se ocupa para llenar la tabla
    const [operacionesNoAsociadas, setOperacionesNoAsociadas] = useState([]);

    //Array de las operaciones. Se ocupa para llenar los inputs select
    const [ArrayOperacionesForzadas, setArrayOperacionesForzadas] = useState([]);


    const [asociacionExitosa, setAsociacionExitosa] = useState();
    const [asociacionNoExitosa, setAsociacionNoExitosa] = useState();

    //Para pruebas
    const [catCopy, setCatCopy] = useState([]);

    const [optionNoSelected, setOptionNoSelected] = useState(false);

    //Spinner
    const [spin, setSpin] = useState(true);
    const [loading, setLoading] = useState();

    //States to handle load data when loading page 
    const [erroreshttp, setErroreshttp] = useState();

    //Shows modal uf the session has expired
    const [sessionExpired, setSessionExpired] = useState();
    const [failLoadData, setFailLoadData] = useState();


    useEffect(() => {
        getAllApplications();
    }, []);


    const getAllApplications = async () => {
        try {


            setSpin(true);
            const response = await operacionesForzadasNoAsociadas();
            if (response) {
                setSpin(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    //console.log(response)
                    if (response.data) {
        
                        const dataTable = response.data.map((el) => {
                            const operacionNoAsociada = el.operacionNoAsociada;
                            return operacionNoAsociada
        
                        });
        
                        setOperacionesNoAsociadas(dataTable);
                        setArrayOperacionesForzadas(response.data);
                        setCatCopy(objPrueba);
                        setSpin(false);
                        setCopia(true);
        
        
        
                    } else if (response.error === 401) {
                        setSpin(false);
                        setSessionExpired(true);
        
                    } else {
                        setSpin(false);
                        setFailLoadData("No se pudo cargar la información");
                    }
        
                }
            }

        
        } catch (err) {
            console.error(err);
        }
    }






    const asociarOperacion = async (row, e) => {

        const rowOriginal = row.original;
        //console.log(rowOriginal)


        //Validamos si usuario ya seleccionó una opción del select
        if (row.original.folioAsociado === null) {
            setOptionNoSelected(true);

        } else {
            setOptionNoSelected(false);
            const objAsociacionForzada = rowOriginal;
            setLoading(true);
            const response = await asociarOperacionForzada(objAsociacionForzada);
            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    setLoading(false);
                    //console.log(response);
        
                    if (response.data.isSuccessful) {
        
                        setAsociacionExitosa(response.data.mensaje);
        
                        
                        ////INICIO ACTUALIZACIÓN DE DATOS DE LA TABLA
                        
                        //Folio de la fila que asociamos y es la fila que vamos a remover del array
                        const folio = rowOriginal.folio;
                        //Filtrar array para actualizar las opciones select
                        const arrayOperaciones = ArrayOperacionesForzadas;
                        //Removemos la fila por medio del folio
                        const operacionesFiltradas = arrayOperaciones.filter(el => el.operacionNoAsociada.folio !== folio);
                        
                        //Filtrar array para actualizar la información de la fila
                        const dataTable = operacionesFiltradas.map((el) => {
                            const operacionNoAsociada = el.operacionNoAsociada;
                            return operacionNoAsociada
        
                        });
        
                        //Pasamos el array para actualizar la tabla
                        setOperacionesNoAsociadas(dataTable);
        
                        //Pasamos el array para actualizar la informaciones de los input select
                        setArrayOperacionesForzadas(operacionesFiltradas);
        
                        ////FIN ACTUALIZACIÓN DE DATOS DE LA TABLA
        
                        setSpin(true);
        
                    }else if(response.data.isSuccessful === false){
                        setAsociacionNoExitosa(response.data.mensaje);
                        
        
                    } else if ((isNaN(response.error)) === false) {
                        setErroreshttp(response);
                    }
                }
            }

          
        }
    }



    const closeModal = (e) => {
        e.preventDefault();
        setSpin(false);
        setAsociacionExitosa(false);
    }


    const signOut = async () => {
        try {
            const data = await Auth.signOut();
            sessionStorage.clear();
            window.location.replace('/login')
        } catch (error) {
            //console.log('error signing out: ', error);
        }
    }


    const goBack = () => {
        history.push({
            pathname: '/inicioCehval',
        })
    }


    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {asociacionExitosa &&
                <ComponenteModal mostrarModal={asociacionExitosa} cerrarModal={(e) =>  closeModal (e)} titulo={asociacionExitosa} textoBoton="Aceptar"></ComponenteModal>
            }
            
            {asociacionNoExitosa &&
                <ComponenteModal mostrarModal={asociacionNoExitosa} cerrarModal={() => setAsociacionNoExitosa(false)} titulo={asociacionNoExitosa} textoBoton="Aceptar"></ComponenteModal>
            }

           

            {failLoadData &&
                <ComponenteModal mostrarModal={failLoadData} cerrarModal={goBack} titulo={failLoadData} textoBoton="Aceptar"></ComponenteModal>
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <div className="">
                <main className="min-height-40">
                    <div className="row row-wrap g-0">
                     
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Operaciones forzadas no asociadas"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <>
                                                    <TablaSalvaguardasFinancieras datos={operacionesNoAsociadas} getRowData={asociarOperacion} useValidate={useOperacionesForzadasNoAsociadas} textoBoton={"Asociar"} ArrayOperacionesForzadas={ArrayOperacionesForzadas} catCopy={catCopy} optionNoSelected={optionNoSelected} textTitle="Clic para ver la información"></TablaSalvaguardasFinancieras>
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}





