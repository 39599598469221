//React
import React from "react";

//Componentes generales
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import DarRevocarAccesoISINSistInformacion from "../../../ComponentesGenerales/DarRevocarAccesoISINSistInformacion";

//APIs
import { verRolesParaRemover } from "../../../api/tarificacion/permisosISINSistInformacion/verRolesParaRemover"
import { quitarPermiso } from "../../../api/tarificacion/permisosISINSistInformacion/quitarPermiso";



export default function RevocarAccesoISINSistInformacion({ history }) {



    return (
        <>
            <div className="">
                <main className="min-height-40">
                    <div className="row row-wrap g-0">
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Revocar acceso a ISIN y/o sistemas de información"></BannerTitulo>
                                <DarRevocarAccesoISINSistInformacion permisosEndpoint={verRolesParaRemover} giveRemovePermission={quitarPermiso} isRevokePermissions={true} inputText="Revocar acceso" />
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}





