import { instance } from '../apiGlobal';

export async function traerCiudades(obj) {
  try {
    const response = await instance.get(`/cat/ciudades?municipio=${obj}`, {
      headers: {
        'Content-Type': 'application/json'

      }
    })
    return response.data
  } catch (error) {
    return error
  }
}
