import React, { useState } from "react";

import { Modal } from "react-bootstrap";

//Componentes

import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import ComponenteInformacionConciliacion from "./ComponenteInformacionConciliacion";

//Hooks





//API's
import { lecturaArchivoLiquidacionsubastas } from "../../../api/Mercadoprimario/lecturaArchivoLiquidacionSubastas";
import { liquidarValoresMercadoPrimario } from "../../../api/Mercadoprimario/liquidarValoresmercadoPrimario";
import { imprimirTablaLiquidacionSubastas } from "../../../api/Mercadoprimario/imprimirTablaLiquidacionSubastas";


//Muestra las cuentas de un depositante indirecto
export default function CargarResultadoSubasta({ history }) {

    // const dataAlreadyLoaded = history.location.state;

    //If endpoint got the data will be true    
    const [getSubastaData, setGetSubastaData] = useState(false);
    const [getSubastaData2, setGetSubastaData2] = useState(false);
    const [getSubastaData3, setGetSubastaData3] = useState(false);
    const [loading, setLoading] = React.useState(false);

    //const [datosPreviamenteCargados, setDatosPreviamenteCargados] = useState(false);
    //Stores the json with emisors  
    const [subastaData, setSubastaData] = useState([]);

    //The name of the users file
    const [nameFile, setNameFile] = useState();
    //The file
    const [file, setFile] = useState(undefined);
    //Shows the loading screen when y set to true


    const [error, setError] = useState();
    const [invalidFile, setInvalidFile] = useState(false);
    const [erroreshttp, setErroreshttp] = useState();
    
    const [procesoTerminado, setProcesoTerminado] = React.useState()
    const [mensaje, setMensaje] = React.useState()
    const [modalDecision, setModalDecision] = React.useState()
    const [cancelarSubasta, setCancelarSubasta] = React.useState()

    


    React.useEffect(() => {
        // viewAccounts();
    }, []);

    /* const viewAccounts = async () => {
         try {
             const dataAlreadyLoaded = history.location.state;
             //console.log(dataAlreadyLoaded);
 
             if (dataAlreadyLoaded === undefined) {
                 setDatosPreviamenteCargados(false)
             } else if (dataAlreadyLoaded.emisionesFiltradas.length === 0) {
 
                 setDatosPreviamenteCargados(false)
             } else {
 
                 setDatosPreviamenteCargados(true);
                 setSubastaData(dataAlreadyLoaded.emisionesFiltradas);
             }
             setGetSubastaData(false);
         } catch (err) {
             console.error(err);
         }
     }*/

    //Open input file when click on h4 text
    const handleInputFile = (e) => {
        e.preventDefault();
        //Select our input tag 
        const input = document.querySelector("#input-file");
        //Give the event click to our input
        input.click();
    }

    // HANDLE WHEN USER SELECT A FILE WHEN CLICK ON TEXT
    const selectFile = (e) => {
        //Cleans error message if was one
        setError("");
        setGetSubastaData(false);

        const file = e.target.files;
        //Name of the file to validate if has the correct extension
        const fileName = e.target.files[0].name;
        const fileSize = e.target.files[0].size;

        //Regex to validate the extension of the file
        const extensionsAllow = /(.xlsx)$/i;

        ////console.log(pathFile.files)
        //If selected file doesnt have correct extension show user error message
        if (!extensionsAllow.exec(fileName)) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file
            setError("Selecciona un archivo válido");

            //Clean our states if they had any information
            setFile("");
            setNameFile("");
            return false;

        }else if (fileSize > 10_000_000) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file
            setError("El tamaño del archivo debe ser menor a 10 MB");
            //Clean file name if a file was selected before
            setNameFile("");
            //Clean state if a file was selected before
            setFile("");

        } else if (e.target.files && e.target.files[0]) {
            //Shows the name of the selected file to the user
            setNameFile(fileName);
            //Set FileList [File] to our state
            setFile(file);

        }
    }

    // HANDLE USER DROP FILE
    const dropFile = (e) => {
        e.preventDefault();
        //Cleans error message if was one
        setError("");
        setGetSubastaData(false);
        //Removes the active class from container when user drops a file
        document.querySelector(".drop-area").classList.remove("active");
        //Shows the same text before dropping a file
        const inputField = document.querySelector('#text-click-uploadfile');
        inputField.innerHTML = "Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro";

        //Show user the selected file and validates in codition if file has a valid extension 
        const fileName = e.dataTransfer.files[0].name;
        const fileSize = e.dataTransfer.files[0].size;
        //Regex to validate file extension
        const extensionsAllow = /(.xlsx)$/i;

        if (!extensionsAllow.exec(fileName)) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file
            setError("Selecciona un archivo válido");
            setFile("");
            setNameFile("");
            return false;

        }else if (fileSize > 10_000_000) {
            //Shows modal with an error message
            setInvalidFile(true);
            //Shows an error message under the square to select file
            setError("El tamaño del archivo debe ser menor a 10 MB");
            //Clean file name if a file was selected before
            setNameFile("");
            //Clean state if a file was selected before
            setFile("");

        } else if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            //Shows the name of the selected file to the user
            setNameFile(fileName);
            //Set FileList [File] to our state
            setFile(e.dataTransfer.files);
        }
    }

    //Handle when user drag over a file 
    const dragOver = (e) => {
        e.preventDefault();
        //Adds the active class to container to change the background
        const moveOver = document.querySelector(".drop-area");
        moveOver.classList.add("active");
        //Change the text inside box
        const inputField = document.querySelector('#text-click-uploadfile');
        inputField.innerHTML = "Suelte el archivo";
    }

    //Handle when user leaves the drop area whitout drop any file
    const dragLeave = (e) => {
        e.preventDefault();
        //Removes the active class to container to change the background
        const moveLeave = document.querySelector(".drop-area");
        moveLeave.classList.remove("active");
        //Change the text inside box 
        const inputField = document.querySelector('#text-click-uploadfile');
        inputField.innerHTML = "Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro";
    }

    // HANDLE WHEN USER CLICK BUTTON TO READ THE FILE
    const readFile = async (e) => {
        e.preventDefault();
        try {
            //Shows error massage when user doesnt select any file and click on button
            if (file === undefined) {
                setError("Por favor seleccione un archivo");
                return;
            }
            //If file is not the right type of file doesnt allow user to click "leer registros"
            if (file[0] === undefined || file[0] === "" || file[0] === null || file[0] === "string") {
                setError("Por favor seleccione un archivo");
                //console.log("no hay archivo")
            } else {
                //Clean error message if showed 
                setError("");
                //Make the query to data base to read the file and return the json

                //Shows loading screen until get the response
                setLoading(true);

                let formFile = new FormData();
                formFile.append('file', file[0]);
                const response = await lecturaArchivoLiquidacionsubastas(formFile);
                //console.log(response)

                if (response) {

                    if ((isNaN(response.error)) === false) {
                        // //console.log("erores")
                        ////console.log(response)
                        setLoading(false);
                        setErroreshttp(response)
                        
                    } else {
                        //setLoading(false);
                        //console.log(response.data)
                        setSubastaData(response.data)
                        //  setSubastaData(response.data);

                        //When await gets the data hides loading screen
                        setGetSubastaData(true);
                        setLoading(false);
                    }

                }


            }

        } catch (error) {
            console.error(error);
        }
    }

    const liquidarValores = async () => {
        //console.log("liquidar valores")
        setLoading(true)
        const mandarLiquidacion = await liquidarValoresMercadoPrimario(subastaData);
        //console.log(mandarLiquidacion)
        if (mandarLiquidacion) {
            setLoading(false)
            if ((isNaN(mandarLiquidacion.error)) === false) {
              
                setErroreshttp(mandarLiquidacion)
                
            } else {
                //estadoValidacion
                // descripcionValidacion
                if (mandarLiquidacion.decisionOferta === 0) {
                    //procesoExitoso
                    setSubastaData(mandarLiquidacion)
                    if (getSubastaData2) {
                        setGetSubastaData(false)//proceso en el hubo un error//proceso en el hubo un error
                        setGetSubastaData2(false)
                        setGetSubastaData3(true)
                    } else {
                        setGetSubastaData(false)//proceso en el hubo un error//proceso en el hubo un error
                        setGetSubastaData2(true)
                        setGetSubastaData3(false)
                    }

                    setMensaje("Valores liquidados")
                    setProcesoTerminado(true)
                } else if (mandarLiquidacion.decisionOferta === 1) {
                    setSubastaData(mandarLiquidacion)
                    //porceso en el que hay que tomar una desicion
                    if (getSubastaData2) {
                        setGetSubastaData(false)//proceso en el hubo un error//proceso en el hubo un error
                        setGetSubastaData2(false)
                        setGetSubastaData3(true)
                    } else {
                        setGetSubastaData(false)//proceso en el hubo un error//proceso en el hubo un error
                        setGetSubastaData2(true)
                        setGetSubastaData3(false)
                    }
                    setMensaje(mandarLiquidacion.descripcionValidacion)
                    setModalDecision(true)
                } else if (mandarLiquidacion.decisionOferta === 2) {
                    //proceso en el hubo un error
                    setMensaje(mandarLiquidacion.descripcionValidacion)
                    setProcesoTerminado(true)
                } else {
                    //proceso en el hubo un error
                    setMensaje("No se pudieron liquidar los valores")
                    setProcesoTerminado(true)
                    //console.log("no se puede liquidar")
                }
                //setData(mandarLiquidacion)
            }
        }
    }

    const desicionPositiva = async () => {
        setModalDecision(false)
        setLoading(true)

        const mandarLiquidacion = await liquidarValoresMercadoPrimario(subastaData);
        //console.log(mandarLiquidacion)
        if (mandarLiquidacion) {
            setLoading(false)
            if ((isNaN(mandarLiquidacion.error)) === false) {
              
                setErroreshttp(mandarLiquidacion)
                
            } else {
                //estadoValidacion
                // descripcionValidacion
                if (mandarLiquidacion) {
                    //procesoExitoso
                    setSubastaData(mandarLiquidacion)
                    if (getSubastaData2) {
                        setGetSubastaData(false)//proceso en el hubo un error//proceso en el hubo un error
                        setGetSubastaData2(false)
                        setGetSubastaData3(true)
                    } else {
                        setGetSubastaData(false)//proceso en el hubo un error//proceso en el hubo un error
                        setGetSubastaData2(true)
                        setGetSubastaData3(false)
                    }


                    setMensaje("Valores liquidados")
                    setProcesoTerminado(true)
                } else {
                    setMensaje("No se pudieron liqudiar los valores")
                    setProcesoTerminado(true)
                    //console.log("no se puede liquidar")
                }

                //setData(mandarLiquidacion)
            }
        }

    }


    const cancelarLiquidacion = async () => {
        setCancelarSubasta(true)
    }

    const cancelarModalLiquidacion = () => {
        setCancelarSubasta(false)
        setModalDecision(false)
        //console.log("cancelarLiquidacion")
        setNameFile("")
        setFile("")
        setGetSubastaData(false)
        setGetSubastaData2(false)
        setGetSubastaData3(false)
        //  history.go(-1)
    }


    const onHideModal = () => {
    }

    const imprimir = async (row) => {
        console.log("iiimpresionoooo")
        const myobj =
            { codigoSubasta: subastaData.numeroSubasta, moneda: subastaData.moneda }

        //console.log(myobj)
        setLoading(true)


        const response = await imprimirTablaLiquidacionSubastas(myobj)
        console.log(response)
        if (response) {
            //setLoading(false)
            if ((isNaN(response.error)) === false) {
                setLoading(false)
                // respuestaincorrecta           
                setErroreshttp(response)
            } else {

                //console.log(response)
                const file = new Blob(
                    [response],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
                setLoading(false)
            }
        }


    }

    return (
        <>
            <Modal show={modalDecision} animation={false} centered onHide={onHideModal}>
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => cancelarLiquidacion()} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >{mensaje}</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => cancelarLiquidacion()} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Rechazar</button>
                        <button onClick={desicionPositiva} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Aceptar</button>
                    </div>
                </div>
            </Modal>

            {procesoTerminado &&
                <ComponenteModal mostrarModal={procesoTerminado} cerrarModal={() => { setProcesoTerminado(false) }} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {loading &&
                <PantallaCargando />
            }

            

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {invalidFile &&
                <ComponenteModal mostrarModal={invalidFile} cerrarModal={() => { setInvalidFile(false) }} titulo="Archivo inválido. Por favor seleccione un archivo .xlsx y con un peso menor a 10 MB" textoBoton="Aceptar"></ComponenteModal>
            }

            {cancelarSubasta &&
                <ComponenteModal mostrarModal={cancelarSubasta} cerrarModal={() => { cancelarModalLiquidacion() }} titulo="Liquidación cancelada" textoBoton="Entendido"></ComponenteModal>
            }

            <div className="fluid-container">

                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 mb-5">

                            {!getSubastaData && !getSubastaData2 && !getSubastaData3 &&
                                <>
                                    <BannerTitulo titulo="Cargar el resultado de la subasta"></BannerTitulo>
                                    <div className="row justify-content-center align-items-center g-0">
                                        <form>
                                            <div className="col-12 row justify-content-center g-0">
                                                <div id="fileDropArea" onDragOver={dragOver} onDragLeave={dragLeave} onDrop={(e) => dropFile(e)} className="col-11 col-sm-10 col-md-8 col-lg-6 drop-area row row-wrap justify-content-center align-items-center border-dashed py-5 px-2 rounded">
                                                    <i className="ri-upload-2-fill ri-3x text-center text-royal-blue"></i>
                                                    <h4 id="text-click-uploadfile" onClick={handleInputFile} className="text-center font-acumin-variable text-royal-blue">Clic para seleccionar un archivo o arrastre y suelte el archivo dentro del recuadro</h4>
                                                    <input id="input-file" type="file" onChange={(e) => selectFile(e)} hidden required />
                                                </div>
                                            </div>

                                            <div className="col-12 row justify-content-center g-0">
                                                <div id="preview-file" className="col-11 col-sm-10 col-md-8 col-lg-6 row justify-content-center g-0 mt-1">
                                                    <span className="text-royal-blue font-acumin-variable">Seleccione un archivo excel <i>.xlsx</i></span>
                                                    {nameFile &&
                                                        <p className="text-royal-blue text-success font-acumin-variable fs-5">Archivo válido:<i> {nameFile}</i></p>
                                                    }
                                                    {error &&
                                                        <p className="text-danger font-acumin-variable fs-5">{error}</p>
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-12 row justify-content-center g-0">
                                                <div className="col-11 col-sm-10 col-md-8 col-lg-6 mt-1 g-0">
                                                    <button onClick={readFile} className="btn background-royal-blue bg-hover-gold text-white font-acumin-variable">Leer registros</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </>
                            }

                            {getSubastaData &&
                                <ComponenteInformacionConciliacion subastaData={subastaData} funcion1={cancelarLiquidacion} textoFuncion1="Cancelar" funcion2={liquidarValores} textoFuncion2="Liquidar valores" irDevuelta={cancelarModalLiquidacion} variableString={true}></ComponenteInformacionConciliacion>
                            }

                            {getSubastaData2 &&
                                <ComponenteInformacionConciliacion subastaData={subastaData} funcion1={imprimir} textoFuncion1="Imprimir" funcion2={liquidarValores} textoFuncion2="Liquidar valores" irDevuelta={cancelarModalLiquidacion}></ComponenteInformacionConciliacion>
                            }

                            {getSubastaData3 &&
                                <ComponenteInformacionConciliacion subastaData={subastaData} funcion1={imprimir} textoFuncion1="Imprimir" funcion2={liquidarValores} textoFuncion2="Liquidar valores" irDevuelta={cancelarModalLiquidacion}></ComponenteInformacionConciliacion>
                            }

                        </div>
                    </div>
                    {loading &&
                        <PantallaCargando />
                    }
                </main >

            </div>
        </>
    );
}


