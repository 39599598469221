import React, { useState } from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { consultarPagosHechosPDP } from "../../../api/PagoInteresesAmorDiv/consultarPagosHechosPDP";
import { imprimirPagosPDP } from "../../../api/PagoInteresesAmorDiv/imprimirPagosPDP";
import { descargarPDFPagos } from "../../../api/PagoInteresesAmorDiv/decargarPDFPagos";


//Hooks
import useConfirmarPagosLiquidaciones from "../../../hooks/PagoDerechosPatrimoniales/useConfirmarPagosLiquidaciones";

export default function FormularioPagosEncontrados({ history }) {

    const [erroreshttp, setErroreshttp] = React.useState()
    //Shows the loading screen when y set to true
    const [spin, setSpin] = React.useState(true)
    const [loading, setLoading] = React.useState(false);

    const [datos, setDatos] = useState()

    React.useEffect(() => {
        guardarDatos();
    }, []);

    const guardarDatos = async () => {
        if (history.location.state === undefined) {
            history.go(-1)
        } else {
            const response = await consultarPagosHechosPDP({
                codigoId: history.location.state.objMenu.codigoId
            })
            //console.log(response)
            setSpin(false)
            if (response) {
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    setDatos(response)
                }
            }
        }
    }

    const imprimir = async () => {
        setLoading(true)
        const myObj = {
            codigoId: history.location.state.objMenu.codigoId,
            estatus: 8
        }

        const response = await imprimirPagosPDP(myObj)
        if (response) {


            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta
                setLoading(false)
                setErroreshttp(response)
            } else {
                const file = new Blob(
                    [response],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
                setLoading(false)
            }
        }
    }

    const descargarDoc = async () => {

        setLoading(true)
        const myObj = {
            fileName: datos?.folioRuta
        }

        const descargar = await descargarPDFPagos(myObj)

        if (descargar) {
            setLoading(false)
            if ((isNaN(descargar.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(descargar)
            } else {

                //console.log("descargado")
                const file = new Blob(
                    [descargar],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);


            }
        }
    }

    const regresar = () => {
        history.go(-1)
    }

    return (
        <div className="fluid-container">
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {loading &&
                <PantallaCargando />
            }

            <main className="mb-5">
                <div className="row row-wrap g-0">
                    <div className="col-12">
                        <div className="">
                            <div className="row d-flex justify-content-center g-0">
                                <div className="col-12">
                                    <BannerTitulo titulo="Confirmación de pagos"></BannerTitulo>
                                    <div className="px-3 px-lg-4 px-xl-4 px-xxl-5 border-none">
                                        {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                            <div className="card-body ">
                                                <div className="col-12 mx-auto">
                                                    {datos &&
                                                        <>
                                                            <div className="card">

                                                                <div className="card-body">
                                                                    <form className="row g-0 needs-validation" novalidate >
                                                                        {datos.folioRuta &&
                                                                            <div className="row mb-3">
                                                                                <div className="form-floating mx-1  row col-4 col-md-3  marginButtonAprove">
                                                                                    <button type="button" onClick={() => descargarDoc()} className="btn background-royal-blue text-white bg-hover-gold col-12 col-md-12" >Descargar dictamen</button>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                        <div className="row mb-3">
                                                                            <h4 className="text-royal-blue font-acumin-variable mt-3">Información de la serie</h4>
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.nombreDeEmision} id="nombreEmision" name="nombreEmision"  required disabled />
                                                                                    <label htmlFor="nombreEmision" className=" textoFormularioDepositantedirecto">Nombre de la emisión</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.serie} id="nombreSerie" name="nombreSerie"  required disabled />
                                                                                    <label htmlFor="nombreSerie" className=" textoFormularioDepositantedirecto">Nombre de la serie</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.isin} id="isin" name="isin"  required disabled />
                                                                                    <label htmlFor="isin" className=" textoFormularioDepositantedirecto">ISIN</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.montoTotal} id="montoTotal" name="montoTotal"  required disabled />
                                                                                    <label htmlFor="montoTotal" className=" textoFormularioDepositantedirecto">Monto total</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row mb-3">
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.moneda} id="monedaSerie" name="monedaSerie"  required disabled />
                                                                                    <label htmlFor="monedaSerie" className=" textoFormularioDepositantedirecto">Moneda de la serie</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.tipoPago} id="tipoPago" name="tipoPago"  required disabled />
                                                                                    <label htmlFor="tipoPago" className=" textoFormularioDepositantedirecto">Tipo de pago</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.fechaPago} id="fechaPago" name="fechaPago"  required disabled />
                                                                                    <label htmlFor="fechaPago" className=" textoFormularioDepositantedirecto">Fecha de pago</label>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                        <div className="row mb-3">
                                                                            <h4 className="text-royal-blue font-acumin-variable mt-3">Pago de impuestos</h4>
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.totalRetenciones} id="totalRetenciones" name="totalRetenciones"  required disabled />
                                                                                    <label htmlFor="totalRetenciones" className=" textoFormularioDepositantedirecto">Total de retenciones</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.cuentaSar} id="cuentaSar" name="cuentaSar"  required disabled />
                                                                                    <label htmlFor="cuentaSar" className=" textoFormularioDepositantedirecto">Cuenta SAR</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.banco} id="banco" name="banco"  required disabled />
                                                                                    <label htmlFor="banco" className=" textoFormularioDepositantedirecto">Banco</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.tipo_cuenta} id="tipoCuenta" name="tipoCuenta"  required disabled />
                                                                                    <label htmlFor="tipoCuenta" className=" textoFormularioDepositantedirecto">Tipo de cuenta</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row mb-3">
                                                                            <h4 className="text-royal-blue font-acumin-variable mt-3">Información de la serie</h4>
                                                                            <div className="col-md-6 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.direccion} id="direccionSar" name="direccionSar"  required disabled />
                                                                                    <label htmlFor="direccionSar" className=" textoFormularioDepositantedirecto">Dirección de SAR</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.status} id="estatus" name="estatus"  required disabled />
                                                                                    <label htmlFor="estatus" className=" textoFormularioDepositantedirecto">Estatus</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>

                                                                <TablaMercado datos={datos.montoPorDepositante} useValidate={useConfirmarPagosLiquidaciones} mercadoPrimario={true} funcion1MercadoPrimario={regresar} funcion2MercadoPrimario={imprimir} texto1MercadoPrimario="Regresar" texto2MercadoPrimario="Imprimir" ></TablaMercado>




                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main >

        </div >
    );


}

