import React, { useState } from "react";

//Componentes
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";

//Hooks
import useOperacionesActivas from "../../../hooks/MercadoSecundario/useOperacionesActivas";

//API's
import { consultarOperacionesEjecutadas } from "../../../api/MercadoSecundario/consultarOperacionesEjecutadas";

//Muestra las cuentas de un depositante indirecto
export default function OperacionesEjecutadas({ history }) {
   // const dataAlreadyLoaded = history.location.state;

    //If endpoint got the data will be true    

    //Stores the json with emisors  
    //const [subastaData, setSubastaData] = useState([]);
    //const [condicionSubasta, setCondicionSubasta] = useState();

    //const [error, setError] = useState();
    const [erroreshttp, setErroreshttp] = useState();
    
    //const [procesoTerminado, setProcesoTerminado] = React.useState()
    //const [mensaje, setMensaje] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    //const [informacionSubasta, setInformacionSubasta] = React.useState()
    const [vencimientosLiquidados, setVencimientosLiquidados] = React.useState()


    

    React.useEffect(() => {
        traerinformacionOperacionesActivas()
    }, []);

    const traerinformacionOperacionesActivas = async () => {
        const response = await consultarOperacionesEjecutadas()
        //console.log(response)
        if (response) {
            setSpin(false)
            if ((isNaN(response.error)) === false) {
                setErroreshttp(response)
            } else {
                setData(response)
                setCopia(response)
            }
        }
    }
  
    const verInformacion = async (row) => {
        //console.log("ver informacion")
        ////console.log(row.original)
        ////console.log(row.original)
    
        const objetoVariablesBooleanas={
            botones:true,
            componenteExtra:true,
            botonComponenteExtra:false,
            ruta:'/operacionesEjecutadas',
            liquidacionFondos:true
        }

        history.push({
            pathname: '/informacionOperacionMS', state: { objMenu: row.original, operacionesInfo:data,tipoProcedencia:2,objetoBooleano:objetoVariablesBooleanas}

        })

      /*  var monedaSubasta;
        if (row.original.moneda === "LEMPIRA") {
            monedaSubasta = 1;
        } else {
            monedaSubasta = 2
        }
        const infoSubastaLiquidada = await traerInfoSubastaLiquidada({ codigoSubasta: row.original.codigoSubasta, moneda: monedaSubasta })
        //console.log(infoSubastaLiquidada)
        if (infoSubastaLiquidada) {
            setSpin(false)
            if ((isNaN(infoSubastaLiquidada.error)) === false) {
                setErroreshttp(infoSubastaLiquidada)
            } else {
                setInformacionSubasta(infoSubastaLiquidada)
                setCondicionSubasta(true)
                //setData(infoSubastaLiquidada)
                setCopia(false)
            }
        }*/
    }

   
    return (
        <>
            

            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {vencimientosLiquidados &&
                <ComponenteModal mostrarModal={vencimientosLiquidados} cerrarModal={() => { setVencimientosLiquidados(false) }} titulo="Recompras del día liquidadas" textoBoton="Entendido"></ComponenteModal>
            }

            <div className="fluid-container">
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                         
                        <div className="col-12 mb-5">                        
                                 <BannerTitulo titulo="Operaciones ejecutadas"></BannerTitulo>
                                    {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                        <div className="d-flex">
                                            <div className="container py-5">
                                                {copia &&
                                                    <>
                                                        <TablaMercado datos={data} displayInformation={verInformacion} useValidate={useOperacionesActivas} textoBoton={"Ver información"} textTitle="Click para ver información"  ></TablaMercado>
                                                    </>
                                                }            
                                            </div>
                                        </div>
                                    }
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}


