import { useMemo } from "react";

export default function useSolicitudesDepositantesValoresFisicos() {
  const columns = useMemo(
    () => [
      {
        Header: "Nombre del depositante de valores físicos",
        accessor: "depositanteResgvalfisNombreCompleto"
      },
      {
        Header: "Nombre del representante legal",
        accessor: "depositanteResgvalfisNomRepresLeg"
      },
      {
        Header: "Nombre del responsable de la solicitud",
        accessor: "depositanteResgvalfisNomResp"
      },
      {
        Header: "Correo del responsable de la solicitud",
        accessor: "depositanteResgvalfisCorreoResp"
      },
    ],
    []
  );

  return columns;
}
