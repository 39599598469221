import React from "react";
import { FormContext } from "./form_context";

export default function RegistroDI2(props) {
    const [, setValue] = React.useContext(FormContext);
    React.useEffect(()=>{
        //console.log(props.info.cehvalDepositantePersonaJurId)
        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 0 };
            return { ...prev, datos };
        });

    },[])

    const convertirTime=(info)=>{
        let date = new Date((info/1000) * 1000);
        return date.toLocaleDateString("en-GB")
    }
   
    return (
        <>
            
                    <div className="card-body" >
                        <h5 className="textoTituloFormulario" style={{ fontSize: 25 }}>II. Datos del representatante legal</h5>

                        <div className="row mb-3">
                          
                            <div className="col-md-4">
                                <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Primer nombre</label>
                                <input disabled value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatPrimerNom}  id="primerNombre" name="primerNombre" type="text" className="form-control   border" required/>
                              
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Segundo nombre</label>
                                <input disabled value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatSegundoNom}  id="segundoNombre" name="segundoNombre" type="text" className="form-control " />
                             
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Primer apellido</label>
                                <input disabled value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatPrimerApe}  id="primerApellido" name="primerApellido" type="text" className="form-control  border  r" required />
                             
                            </div>

                          
                        </div>

                        <div className="row mb-3">
                        <div className="col-md-4">
                                <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Segundo apellido</label>
                                <input disabled type="text" value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatSegundoApe} className="form-control "  id="segundoApellido" name="segundoApellido" />                               
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Apellido de casada (si aplica)</label>
                                <input disabled type="text" value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatApellidoCas} className="form-control "  id="apellidoCasada" name="apellidoCasada" />
                               
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Tipo de identificación</label>
                                <input disabled type="text" value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatCatTipoIdeId.nombre} className="form-control "  id="tipoIdentificacion" name="tipoIdentificacion" />                               
                            </div>

                           


                          
                        </div>

                        <div className="row mb-3">
                        <div className="col-md-4">
                                <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Número de identificación</label>
                                <input disabled type="text" value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatNumeroIde} className="form-control  border  "  id="numeroIdentificacion" name="numeroIdentificacion" required />                             
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">RTN númerico</label>
                                <input disabled type="text" value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatRtn} className="form-control "  id="rtn" name="rtn" required />
                              
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Estado civil</label>
                                <input disabled type="text" value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatCatEstadoCivilId.nombre} className="form-control  border  "  id="estadoCivil" name="estadoCivil" required />                             
                            </div>

                                                
                        </div>

                        <div className="row mb-3">
                        <div className="col-md-4">
                                <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Género</label>
                                <input disabled type="text" value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatCatGeneroId.nombre} className="form-control  border  "  id="genero" name="genero" required />                             
                            </div>  
                            

                            <div className="col-md-4">
                                <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Nacionalidad</label>
                                <input disabled type="text" value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatCatNacionalidadId.nombre} className="form-control  border "  id="nacionalidad" name="nacionalidad" required />                             
                            </div>  

                            

                            <div className="col-md-4">
                                <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Fecha de nacimiento</label>
                                <input disabled type="text" value={convertirTime(props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatFechaNac)} className="form-control  border "  id="fechaNacimiento" name="fechaNacimiento" required />
                              
                            </div>   
                     
                        </div>


                        <div className="row mb-3">
                                   
                        <div className="col-md-4">
                                <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Lugar de nacimiento</label>
                                <input disabled type="text" value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatLugarNac} className="form-control  border "  id="lugarNacimiento" name="lugarNacimiento" required />
                              
                            </div>
                            <div className="col-md-4"  >
                                <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto" >Puesto que desempeña</label>
                                <input disabled type="text" value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatCatPuestoId.nombre} className="form-control  border  r"  id="puesto" name="puesto" required />
                              
                            </div>
                            <div className="col-md-4"  >
                                <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto" >Tiempo de laborar</label>
                                <input disabled type="text" value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatCatTieLabId.nombre} className="form-control  border  r"  id="tiempoLaborar" name="tiempoLaborar" required />                              
                            </div>

                          

                         
                        </div>   

                        <div className="row mb-3">
                        <div className="col-md-4">
                                <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Teléfono</label>
                                <input disabled type="text" value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatTelOfi} className="form-control  border  r"  id="telefono" name="telefono" required />
                               
                            </div>
                            <div className="col-md-4 "  >
                                <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto" >Correo electrónico laboral</label>
                                <input disabled type="email" value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatCorreoLab} className="form-control  border  r"  id="correoLab" name="correoLab" required />
                              
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Fecha de representación</label>
                                <input disabled type="text" value={convertirTime(props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatFechaRepresentacion)} className="form-control "  id="fechaRepr" name="fechaRepr" required />
                              
                            </div>

                         
                        </div>

                        <div className="row mb-3">
                  

                            <div className="col-md-4">
                                <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Teléfono celular</label>
                                <input disabled type="text" value={props.info.cehvalDepositantePersonaJurId.cehvalPersonaNatRepresentanteLegId.cehvalPersonaNatTelCel} className="form-control "  id="telefonoCelular" name="telefonoCelular" required />                               
                            </div>
                        </div>

                          
                    </div>
              
        </>
    );
}

