import React from "react";
import { Link } from 'react-router-dom';

//Images
import imagenDesmaterializar from '../../../assets/imagenesDesmaterilizacion/Desmaterializar.jpg' 
import imagenNotificarDesmaterializar from '../../../assets/imagenesDesmaterilizacion/notificarDesmaterializacion.jpg'
import imagenNotificarRechazo from '../../../assets/imagenesDesmaterilizacion/notificarRechazo.jpg'
import imagenSolicitarDesmaterializacion from '../../../assets/imagenesDesmaterilizacion/solicitarDesmaterializacion.jpg'

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";


export default function MenuDesmaterializacion({ history }) {

    return (
        <div className="fluid-container">
            
            <main className="min-height-40" >
                <div className="row row-wrap g-0">
                    

                    <div className="col-12 mb-5">
                        <div className="">
                            <BannerTitulo titulo="Desmaterialización de valores"></BannerTitulo>
                            <div className="d-flex">
                                <div className="container font-acumin-variable py-5 mb-5">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 mb-4">
                                            <Link to={{
                                                pathname: '/solicitudDesmaterializacion',
                                                state: 1
                                            }} className="text-decoration-none text-dark">
                                                <div className="card">
                                                    <img className="" style={{height:"20rem",width:"100%"}} src={imagenSolicitarDesmaterializacion} alt="imagenFondo" />
                                                    <div className="card-body text-center font-acumin-variable">
                                                        <h5 className="card-title">Solicitar desmaterialización</h5>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 mb-4">
                                            <Link to={{
                                                pathname: '/desmaterializarValores',                               
                                                state: 2
                                            }} className="text-decoration-none text-dark">
                                                <div className="card" >
                                                    <img className="" style={{height:"20rem",width:"100%"}} src={imagenDesmaterializar} alt="imagenFondo" />
                                                    <div className="card-body text-center font-acumin-variable">
                                                        <h5 className="card-title">Desmaterializar</h5>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 mb-4">
                                            <Link to={{
                                                pathname: '/notificarDesmaterializacion',
                                                state: 3
                                            }} className="text-decoration-none text-dark">
                                                <div className="card" >
                                                    <img className="" style={{height:"20rem",width:"100%"}} src={imagenNotificarDesmaterializar} alt="imagenFondo" />
                                                    <div className="card-body text-center font-acumin-variable">
                                                        <h5 className="card-title">Notificar solicitudes de desmaterialización autorizadas</h5>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 mb-4">
                                            <Link to={{
                                                pathname: '/notificarRechazoDesmaterializacion',
                                                state: 3
                                            }} className="text-decoration-none text-dark">
                                                <div className="card" >
                                                    <img className="" style={{height:"20rem",width:"100%"}} src={imagenNotificarRechazo} alt="imagenFondo" />
                                                    <div className="card-body text-center font-acumin-variable">
                                                        <h5 className="card-title">Notificar solicitudes de desmaterilaización rechazadas</h5>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main >
            
        </div>
    );

}
