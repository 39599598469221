//React
import React from "react";
import { Link } from 'react-router-dom';

//Components
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";


//Images
import solicitudesRechazadas from '../../../../assets/imagenesPrendasGarantias/solicitudesRechazadas.jpg'
import solicitudesAutorizadas from '../../../../assets/imagenesPrendasGarantias/solicitudesAutorizadas.jpg'



export default function MenuNotificarSolicitudesConstitucionGarantia({ titulo, ruta1, ruta2, ruta3 }) {


    return (
        <>
            <div className="fluid-container">
                
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo className="mb-5" titulo="Notificación de solicitudes de constitución de prenda o garantía"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container font-acumin-variable py-5 mb-5">
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                                                <Link to="/notificarConstitucionGarantiaAceptadas" className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="" src={solicitudesAutorizadas} alt="imagenFondo" />
                                                        <div className="card-body text-center">
                                                            <h5 className="card-title text-royal-blue">Solicitudes autorizadas de constitución de prenda o garantía</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-3">
                                                <Link to="/notificarConstitucionGarantiaRechazadas" className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="" src={solicitudesRechazadas} alt="imagenFondo" />
                                                        <div className="card-body text-center">
                                                            <h5 className="card-title text-royal-blue">Solicitudes rechazadas de constitución de prenda o garantía</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    );
}

