
import { instance } from '../apiGlobal';

export async function traerTodoFondoLiquidacion () {

      try {
        const response = await instance(`/fondoliquidacion/mostrar/todo`, {
            headers: {
              method: 'get',
            }
          });
        return response;
      } catch (error) {
        return error;
      }
}