
import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function PublicRoute ({ component: Component, ...rest }){
    React.useEffect(()=>{
   
    },[])
    const actual=()=> JSON.parse(window.sessionStorage.getItem('usuario'))===null
    return(
        <Route
        {...rest}
        render={props => (
          (actual() === true)//||((perfilCompleto===undefined||perfilCompleto===false)))// 
            ? <Component {...props} />
            : <Redirect to='/inicioCehval' />
        )} />
    )
}