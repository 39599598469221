import React from "react";
import '../../../../styles/stylesheet.css'
import { FormContext } from './form_context';
import { FormContext2 } from './form_context2';
//import { TraerActividadEconomica } from "../../../../api/catalogos/traerActividadEconomica";
//import { traerNacionalidad } from "../../../../api/catalogos/traerNacionalidad";
//import { insertarActividadEconomica } from "../../../../api/InsertarACatalogos/insertarActividadEconomica";

import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";
//import SpinnerSend from "../../../../ComponentesGenerales/SpinnerSend";

export default function ActualizarDD1(props) {

    const [value, setValue] = React.useContext(FormContext);
    const [value2, setValue2] = React.useContext(FormContext2);
    //const [actividadEconomica, setActividadEconomica] = React.useState([])
    //const [nacionalidad, setNacionalidad] = React.useState([])
    //const [agregarActividadEconomica, setAgregarActividadEconomica] = React.useState()
    //const [actividadNueva, setActividadNueva] = React.useState()
    const [fechaLimiteDocumentos, setFechaLimiteDocumentos] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    //const [spinSend, setSpinSend] = React.useState(false)
    //const [errorRtn, setErrorRtn] = React.useState()
    //const [errorNumeroEscritura, setErrorNumeroEscritura] = React.useState()
    const [fechaInicial, setFechaInicial] = React.useState()


    const guardarDatos = (e) => {

        const { name, value } = e.target;
        setValue2((prev) => {
            const datos = { ...prev.datos, [name]: value };
            return { ...prev, datos };
        });
    }

    React.useEffect(() => {
        establecerFecha()
        // traerDatos()
    }, [])

    const establecerFecha = () => {
        if (typeof value2.datos.fechaUltimaModificacion == "number") {
            ////console.log("fechafecha")
            setFechaLimiteDocumentos(conversionFecha(1))
            setFechaInicial(conversionFecha(2))
        }
    }

    const conversionFecha = (dato) => {
        var fecha;
        var fecha_limite_documentos;
        if (dato === 1) {
            fecha = new Date()
        } else {
            const arrarf = convertirTime(value2.datos.fechaUltimaModificacion).split("/");
            const fechaE = arrarf[1] + "/" + arrarf[0] + "/" + arrarf[2]
            fecha = new Date(fechaE)
        }
        const añoActual = fecha.getFullYear();
        let diaActual = fecha.getDate();
        let mesActual = fecha.getMonth() + 1;
        if (diaActual < 10) {
            diaActual = "0" + diaActual
        }
        if (mesActual < 10) {
            mesActual = "0" + mesActual
        }
        fecha_limite_documentos = (añoActual) + "-" + mesActual + "-" + (diaActual)
        return fecha_limite_documentos
    }


    const convertirTime = (info) => {
        var date = new Date((info / 1000) * 1000);
        return date.toLocaleDateString("en-GB")
    }


    return (
        <>

            <div className="card-body  pt-3">


                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }





                <h5 className="text-royal-blue  font-acumin-variable" >I. Identificación de la institución</h5>


                {value.datos.establecerModificacion ?
                    <div>
                        <div className="row mb-3v ">
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input disabled type="text" className="form-control fs-5 " value={value2.datos.nombre} id="nombre" name="nombre" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Nombre completo de la institución </label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input disabled type="text" className="form-control fs-5 " value={value2.datos.actividadEconomicaPersonaJuridica} id="nombre" name="nombre" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 fs-6 textoFormularioDepositantedirecto">Actividad económica </label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input disabled type="text" value={value2.datos.rtn} maxLength={14} className="form-control fs-5" id="rtn" name="rtn" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto"> Registro tributario nacional (si aplica)</label>
                                </div>
                            </div>

                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input disabled type="text" value={value2.datos.nacionalidadPersonaJuridica} maxLength={14} className="form-control fs-5" id="rtn" name="rtn" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Nacionalidad</label>
                                </div>
                            </div>
                        </div>


                        <div className="row mb-3">
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input disabled type="text" max={fechaLimiteDocumentos} className="form-control fs-5" value={convertirTime(value2.datos.fechaConstitucion)} id="fechaConstitucion" name="fechaConstitucion" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Fecha de constitución </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-floating">
                                    <input disabled type="texto" className="form-control fs-5" maxLength={30} value={value2.datos.numeroEscritura} id="numeroEscritura" name="numeroEscritura" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Número de escritura original </label>

                                </div>

                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input disabled type="text" className="form-control fs-5" value={value2.datos.tomoFolio} id="tomoFolio" name="tomoFolio" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Tomo/folio </label>

                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-floating">
                                    {fechaInicial ?
                                        <input placeholder="" type="date" className="form-control fs-5" value={fechaInicial} onClick={(e) => {
                                            setFechaInicial(false)
                                        }} id="fechaUltimaModificacion" name="fechaUltimaModificacion" required />
                                        :
                                        <input placeholder="" type="date" className="form-control fs-5" min={fechaLimiteDocumentos} value={value2.datos.fechaUltimaModificacion} onChange={(e) => guardarDatos(e)} id="fechaUltimaModificacion" name="fechaUltimaModificacion" required />
                                    }
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Fecha última modificación </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className="row mb-3">
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input disabled type="text" className="form-control fs-5 " value={value.datos.nombre} onChange={(e) => guardarDatos(e)} id="nombre" name="nombre" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Nombre completo de la institución </label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input disabled type="text" className="form-control fs-5 " value={value.datos.actividadEconomicaPersonaJuridica} onChange={(e) => guardarDatos(e)} id="nombre" name="nombre" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Actividad económica </label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input disabled type="text" value={value.datos.rtn} maxLength={14} className="form-control fs-5" onChange={(e) => guardarDatos(e)} id="rtn" name="rtn" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto"> Registro tributario nacional (si aplica)</label>
                                </div>
                            </div>

                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input disabled type="text" value={value.datos.nacionalidadPersonaJuridica} maxLength={14} className="form-control fs-5" onChange={(e) => guardarDatos(e)} id="rtn" name="rtn" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Nacionalidad</label>
                                </div>
                            </div>
                        </div>


                        <div className="row mb-3">
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input disabled type="text" max={fechaLimiteDocumentos} className="form-control fs-5" value={convertirTime(value.datos.fechaConstitucion)} onChange={(e) => guardarDatos(e)} id="fechaConstitucion" name="fechaConstitucion" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Fecha de constitución </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-floating">
                                    <input disabled type="texto" className="form-control fs-5" maxLength={30} value={value.datos.numeroEscritura} onChange={(e) => guardarDatos(e)} id="numeroEscritura" name="numeroEscritura" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Número de escritura original </label>

                                </div>

                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-md-6 mb-3">
                                <div className="form-floating">
                                    <input disabled type="text" className="form-control fs-5" value={value.datos.tomoFolio} onChange={(e) => guardarDatos(e)} id="tomoFolio" name="tomoFolio" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Tomo/folio </label>

                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-floating">
                                    <input disabled type="text" className="form-control fs-5" value={convertirTime(value.datos.fechaUltimaModificacion)} onChange={(e) => guardarDatos(e)} id="fechaUltimaModificacion" name="fechaUltimaModificacion" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Fecha última modificación </label>
                                </div>
                            </div>
                        </div>
                    </div>
                }



            </div >
        </>
    );
}

