import { useMemo } from "react";

export default function useCuentaValoresTLP() {
  const columns = useMemo(
    () => [
      {
        Header: "ISIN",
        accessor: "isinSerie"
      },
      {
        Header: "Código de emisión",
        accessor: "codigoEmision"
      },
      {
        Header: "Nombre de emisión",
        accessor: "nombreEmision"
      },
      {
        Header: "Código de serie",
        accessor: "codigoSerie"
      },
      {
        Header: "Nombre de la Serie",
        accessor: "nombreSerie"
      },
      {
        Header: "Saldo de valores",
        accessor: "cantidadValores"
      },  
    ],
    []
  );

  return columns;
}
