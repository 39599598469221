import React from "react";
import '../../../../styles/stylesheet.css'
import { FormContext } from './form_context';
import { FormContext2 } from './form_context2';
import { traerTiemposLaborales } from "../../../../api/catalogos/traerTiemposLaborales";
import { traerPuestos } from "../../../../api/catalogos/traerPuestos";
import { insertarPuesto } from "../../../../api/InsertarACatalogos/insertarPuesto";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";
import SpinnerSend from "../../../../ComponentesGenerales/SpinnerSend";


export default function ActualizarDI2({ history }) {
    const [value,] = React.useContext(FormContext);
    const [value2, setValue2] = React.useContext(FormContext2);
    const [puestos, setPuestos] = React.useState([])
    const [timepoLaborales, setTiemposLaborales] = React.useState([])
    const [agregarPuesto, setAgregarPuestos] = React.useState()
    const [puestoNuevo, setPuestoNuevo] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [spinSend, setSpinSend] = React.useState(false)

    const guardarDatos = (e) => {
        const { name, value } = e.target;


        if (name === "puesto") {
            if (value === "OTRO") {
                setAgregarPuestos(true)
            } else {
                setValue2((prev) => {
                    const datos = { ...prev.datos, [name]: value };
                    return { ...prev, datos };
                });
                setAgregarPuestos(false)
            }

        } else {
            setValue2((prev) => {
                const datos = { ...prev.datos, [name]: value };
                return { ...prev, datos };
            });
        }

    }


    React.useEffect(() => {
        traerDatos()
    }, [])

    const traerDatos = async () => {
        const catalogoPuestos = await traerPuestos()
        if (catalogoPuestos) {
            if ((isNaN(catalogoPuestos.error)) === false) {
                setErroreshttp(catalogoPuestos)
            } else {
                setPuestos(catalogoPuestos)
            }
        }

        const catalogoTiemposLaborales = await traerTiemposLaborales()
        if (catalogoTiemposLaborales) {
            if ((isNaN(catalogoTiemposLaborales.error)) === false) {
                setErroreshttp(catalogoTiemposLaborales)
            } else {
                setTiemposLaborales(catalogoTiemposLaborales)
            }
        }
    }

    const checarNumeros = (e) => {
        const { name, value } = e.target;
        let out = '';
        let filtro = '1234567890-+';//Caracteres validos


        //Recorrer el texto y verificar si el caracter se encuentra en la lista de validos 
        for (let i = 0; i < value.length; i++)
            if (filtro.indexOf(value.charAt(i)) != -1)
                //Se añaden a la salida los caracteres validos
                out += value.charAt(i);

        setValue2((prev) => {
            const datos = { ...prev.datos, [name]: out };
            return { ...prev, datos };
        });
    }

    const otroPuesto = async () => {
        const obj = {
            nombre: puestoNuevo
        }
        const agregarNuevoPuesto = await insertarPuesto(obj)

        if (agregarNuevoPuesto) {
            setSpinSend(false)
            if ((isNaN(agregarNuevoPuesto.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(agregarNuevoPuesto)
            } else {


                if (agregarNuevoPuesto?.codigo === "000") {

                    //setActividadEconomica(await TraerActividadEconomica())
                    const catalogoPuestos = await traerPuestos()
                    if (catalogoPuestos) {
                        if ((isNaN(catalogoPuestos.error)) === false) {
                            // respuestaincorrecta           
                            setErroreshttp(catalogoPuestos)
                        } else {
                            setPuestos(catalogoPuestos)
                            setAgregarPuestos(false)

                            const myObj = {
                                target: {
                                    value: (puestos[puestos.length - 1].id) + 1,
                                    name: "puesto"
                                }
                            }
                            guardarDatos(myObj)
                        }
                    }


                }
            }
        }

    }

    return (
        <>
            <div className="card-body pt-3">
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <h5 className="text-royal-blue font-acumin-variable">II. Datos laborales del inversionista</h5>

                {value.datos.establecerModificacion ?
                    <>
                        <div className="row mb-3">
                            <div className="col-md-12 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" type="text" className="form-control fs-5" value={value2.datos.empresa} onChange={(e) => guardarDatos(e)} id="empresa" name="empresa" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Nombre de la empresa , institución o actividad propia *</label>
                                </div>

                            </div>
                        </div>
                        

                        <div className="row mb-3">
                            <div className="col-md-6 form-floating mb-3">
                                <div className="form-floating mb-2">
                                {value2.datos.puestoInicio ?
                                        <select className="form-select fs-5 border" value={value2.datos.puesto} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["puestoInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                        }} id="puesto" name="puesto" required>
                                            <option value={value2.datos.puesto}>{value2.datos.puesto}</option>
                                            {
                                                puestos.filter((item => item.nombre !== value2.datos.puesto)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                            <option  >OTRO</option>
                                        </select>
                                        :
                                        <select className="form-select fs-5 border" value={value2.datos.puesto} onChange={(e) => guardarDatos(e)} id="puesto" name="puesto" required>
                                            <option value=""> </option>
                                            {
                                                puestos.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                            <option  >OTRO</option>
                                        </select>


                                    }
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Puesto que desempeña *</label>
                                </div>

                            </div>


                            <div className="col-md-3 form-floating mb-3">
                                <div className="form-floating mb-2">
                                {value2.datos.tiempoLaboralInicio ?
                                        <select className="form-select fs-5 border  r" value={value2.datos.tiempoPuesto} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["tiempoLaboralInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                        }} id="tiempoPuesto" name="tiempoPuesto" required>
                                            <option value={value2.datos.tiempoPuesto}> {value2.datos.tiempoPuesto}</option>
                                            {
                                                timepoLaborales.filter((item => item.nombre !== value2.datos.tiempoPuesto)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>
                                        :
                                        <select className="form-select fs-5 border  r" value={value2.datos.tiempoPuesto} onChange={(e) => guardarDatos(e)} id="tiempoPuesto" name="tiempoPuesto" required>
                                            <option value=""> </option>
                                            {
                                                timepoLaborales.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>

                                    }
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Tiempo de laborar *</label>
                                </div>

                            </div>

                            <div className="col-md-3 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" type="text" maxLength={15} className="form-control fs-5 " value={value2.datos.telefonoLaboral} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e)} id="telefonoLaboral" name="telefonoLaboral" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Teléfono *</label>

                                </div>
                            </div>
                        </div>

               


                        {agregarPuesto &&

                            <>{spinSend ? <div className="row mb-3 d-flex ">
                                <div className="col-md-6 text-center">
                                    <SpinnerSend textoSpinner="Enviando..."></SpinnerSend>
                                </div>
                            </div> :
                                <div className="row mb-3">
                                    <div className="col-md-6 form-floating">
                                        <div className="form-floating">
                                            < input placeholder="" type="text" className="form-control fs-5 border  r" onChange={(e) => setPuestoNuevo(e.target.value)} required />
                                            <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Ingrese puesto que desempeña *</label>
                                        </div>
                                    </div>

                                    <div className="col-md-1 d-flex align-items-end">
                                        <button type="button" onClick={() => {
                                            setSpinSend(true)
                                            otroPuesto()
                                        }} className="btn botonFormularioDepositante" >Ingresar</button>
                                    </div>
                                </div>}
                            </>
                        }
                    </>
                    :
                    <>
                        <div className="row mb-3">
                            <div className="col-md-12 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" className="form-control fs-5" value={value.datos.empresa} id="empresa" name="empresa" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Nombre de la empresa , institución o actividad propia</label>
                                </div>
                            </div>
                        </div>


                        <div className="row mb-3">
                            <div className="col-md-6 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" className="form-control fs-5" value={value.datos.puesto} id="empresa" name="empresa" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Puesto que desempeña</label>
                                </div>
                            </div>


                            <div className="col-md-3 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" className="form-control fs-5" value={value.datos.tiempoPuesto} id="empresa" name="empresa" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Tiempo de laborar</label>
                                </div>
                            </div>

                            <div className="col-md-3 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" maxLength={15} className="form-control fs-5 " value={value.datos.telefonoLaboral} onChange={(e) => guardarDatos(e)} id="telefonoLaboral" name="telefonoLaboral" required />
                                    <label htmlFor="validationCustom01" className="form-label fs-6 textoFormularioDepositantedirecto">Teléfono *</label>
                                </div>
                            </div>
                        </div>
                    </>
                }



            </div>

        </>
    );
}

