import { instance } from '../../apiGlobal';

export async function traerTodasSolicitudesValoresFisicos (obj) {
      try {
        const response = await instance(`/valoresfisicos/consulta/estatus`, {
          method:"post",
          data:obj
        })
        return response;
      } catch (error) {
        return error;
      }
}