import { instance } from '../../apiGlobal';

  export async function verTodosEstadosCuentaParaDescargar () {
    try {
      const response = await instance.get(`/tarificacion/mostrar/estadoscuenta/generados`, {
        headers: {
          'Content-Type': 'application/json',        
        }
      })
      return response;
    } catch (error) {
      return error
    }
}
 