import React from "react";
import '../../../../styles/stylesheet.css'
import { FormContext } from './form_context';
import { FormContext2 } from './form_context2';
import { traerBancos } from "../../../../api/catalogos/traerBancos";
import { traerTiposCuenta } from "../../../../api/catalogos/traerTiposCuenta";
import { ususarioExistente } from "../../../../api/UsuarioExistente/usuarioExistente";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";

export default function ActualizarDD4({ tipo }) {
    const [value,] = React.useContext(FormContext);
    const [value2, setValue2] = React.useContext(FormContext2);
    const [bancos, setBancos] = React.useState([]);
    const [tiposCuenta, setTiposCuenta] = React.useState([]);
    const [errorUsuarioExistente, setErrorUsuarioExistente] = React.useState();
    const [erroreshttp, setErroreshttp] = React.useState()

    const [correo1, setCorreo1] = React.useState(value2.datos.correo1)
    const [correo2, setCorreo2] = React.useState(value2.datos.correo2)
    const [segundoOperadorObligatorio, setSegundoOperadorObligatorio] = React.useState()


    const guardarDatos = (e) => {
        const { name, value } = e.target;

        if (name.indexOf("correo") >= 0) {
           setValue2((prev) => {
            const datos = { ...prev.datos, [name]: value.toLowerCase() };
            return { ...prev, datos };
        });
        } else {
            setValue2((prev) => {
                const datos = { ...prev.datos, [name]: value };
                return { ...prev, datos };
            });
        }
    }


    React.useEffect(() => {
        traerDatos()
    }, [])

    const traerDatos = async () => {
        const catalogoBancos = await traerBancos()
        if (catalogoBancos) {
            //setSpin(false)
            if ((isNaN(catalogoBancos.error)) === false) {
              
                setErroreshttp(catalogoBancos)
                
            } else {
                // //console.log(traerInformacion)
                setBancos(catalogoBancos)
            }
        }

        const catalogoTiposCuenta = await traerTiposCuenta()
        if (catalogoTiposCuenta) {
            //setSpin(false)
            if ((isNaN(catalogoTiposCuenta.error)) === false) {
              
                setErroreshttp(catalogoTiposCuenta)
                
            } else {
             
                setTiposCuenta(catalogoTiposCuenta)
            }
        }
    }

    const checarNumeros = (e) => {
        const { name, value } = e.target;
        let out = '';
        let filtro = '1234567890-';//Caracteres validos

      
        //Recorrer el texto y verificar si el caracter se encuentra en la lista de validos 
        for (let i = 0; i < value.length; i++)
            if (filtro.indexOf(value.charAt(i)) != -1)
                //Se añaden a la salida los caracteres validos
                out += value.charAt(i);

        setValue2((prev) => {
            const datos = { ...prev.datos, [name]: out };
            return { ...prev, datos };
        });
    }


    const validarSegundoOperador = () => {
        const primerNombre = document.getElementById("nombreCompleto2").value;
        const segundoNombre = document.getElementById("segundoNombre2").value;
        const primerApellido = document.getElementById("primerApellido2").value;
        const segundoApellido = document.getElementById("segundoApellido2").value;
        const correo = document.getElementById("correo2").value;

        if (primerNombre || segundoApellido || segundoNombre || primerApellido || correo) {
            setSegundoOperadorObligatorio(true)
        } else {
            setSegundoOperadorObligatorio(false)
        }
    }

    return (
        <>
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {errorUsuarioExistente &&
                <ComponenteModal mostrarModal={errorUsuarioExistente} cerrarModal={() => { setErrorUsuarioExistente(false) }} titulo="Este correo ya esta registrado, ingresa otro" textoBoton="Entendido"></ComponenteModal>
            }

            <div className="card-body pt-3">
                <h5 className="text-royal-blue font-acumin-variable" >IV. Instrucciones especiales (cuentas bancarias)</h5>
                <h6 className="text-royal-blue font-acumin-variable">Nacional</h6>

                {value.datos.establecerModificacion ?
                    <>
                        <div className="row mb-3">
                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled  type="text" className="form-control fs-5 border  " value={"LEMPIRA"} required />
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Moneda</label>
                                </div>
                            </div>

                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    {value2.datos.bancoNInicio ?
                                        <select className="form-select fs-5 border  " value={value2.datos.bancoN} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["bancoNInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                        }} id="bancoN" name="bancoN">
                                            <option value={value2.datos.bancoN}>{value2.datos.bancoN} </option>
                                            {
                                                bancos.filter((item => item.nombre !== value2.datos.bancoN)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>
                                        :
                                        <select className="form-select fs-5 border  " value={value2.datos.bancoN} onChange={(e) => guardarDatos(e)} id="bancoN" name="bancoN">
                                            <option value=""> </option>
                                            {
                                                bancos.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }

                                        </select>

                                    }

                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Bancos</label>
                                </div>

                            </div>


                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    {value2.datos.tipoCuentaNInicio ?
                                        <select className="form-select fs-5 border  " value={value2.datos.tipoCuentaN} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["tipoCuentaNInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                        }} id="tipoCuentaN" name="tipoCuentaN">
                                            <option value={value2.datos.tipoCuentaN}>{value2.datos.tipoCuentaN} </option>
                                            {
                                                tiposCuenta.filter((item => item.nombre !== value2.datos.tipoCuentaN)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                        </select>
                                        :
                                        <select className="form-select fs-5 border  " value={value2.datos.tipoCuentaN} onChange={(e) => guardarDatos(e)} id="tipoCuentaN" name="tipoCuentaN">
                                            <option value=""> </option>
                                            {
                                                tiposCuenta.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                        </select>


                                    }

                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Tipo de cuenta</label>
                                </div>

                            </div>

                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input placeholder="" type="text" maxLength={22} className="form-control fs-5 border  " value={value2.datos.numeroCuentaN} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e)} id="numeroCuentaN" name="numeroCuentaN" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Número de cuenta</label>

                                </div>

                            </div>
                        </div>
                        <h6 className="text-royal-blue font-acumin-variable" >Extranjera</h6>

                        <div className="row mb-3">
                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5 border  " value={"DÓLAR"} />
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Moneda</label>
                                </div>

                            </div>

                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    {value2.datos.bancoEInicio ?
                                        <select className="form-select fs-5 border  " value={value2.datos.bancoE} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["bancoEInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                        }} id="bancoE" name="bancoE">
                                            <option value={value2.datos.bancoE}>{value2.datos.bancoE} </option>
                                            {
                                                bancos.filter((item => item.nombre !== value2.datos.bancoE)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                        </select>
                                        :
                                        <select className="form-select fs-5 border  " value={value2.datos.bancoE} onChange={(e) => guardarDatos(e)} id="bancoE" name="bancoE">
                                            <option value=""> </option>
                                            {
                                                bancos.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                        </select>

                                    }

                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Banco</label>
                                </div>
                            </div>
                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    {value2.datos.tipoCuentaEInicio ?
                                        <select className="form-select fs-5 border  " value={value2.datos.tipoCuentaE} onChange={(e) => {
                                            guardarDatos(e)
                                            setValue2((prev) => {
                                                const datos = { ...prev.datos, ["tipoCuentaEInicio"]: false };
                                                return { ...prev, datos };
                                            });
                                        }} id="tipoCuentaE" name="tipoCuentaE">
                                            <option value={value2.datos.tipoCuentaE}>{value2.datos.tipoCuentaE} </option>
                                            {
                                                tiposCuenta.filter((item => item.nombre !== value2.datos.tipoCuentaE)).map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                        </select>
                                        :
                                        <select className="form-select fs-5 border  " value={value2.datos.tipoCuentaE} onChange={(e) => guardarDatos(e)} id="tipoCuentaE" name="tipoCuentaE">
                                            <option value=""> </option>
                                            {
                                                tiposCuenta.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.nombre}</option>
                                                ))
                                            }
                                        </select>
                                    }

                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Tipo de cuenta</label>
                                </div>

                            </div>
                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input placeholder="" type="text" maxLength={22} className="form-control fs-5 border  " value={value2.datos.numeroCuentaE} onChange={(e) => guardarDatos(e)} onKeyUp={(e) => checarNumeros(e)} id="numeroCuentaE" name="numeroCuentaE" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Número de cuenta</label>
                                </div>
                            </div>
                        </div>

                        <h5 className="text-royal-blue font-acumin-variable " >IV. Instrucciones especiales (usuarios)</h5>

                        <h6 className="text-royal-blue font-acumin-variable">1. Usuario operador </h6>
                        <div className="row mb-3">

                            <div className=" col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" type="text" className="form-control fs-5" value={value2.datos.nombreCompleto1} onChange={(e) => guardarDatos(e)} id="nombreCompleto1" name="nombreCompleto1" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer Nombre *</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" type="text" className="form-control fs-5" value={value2.datos.segundoNombre1} onChange={(e) => guardarDatos(e)} id="segundoNombre1" name="segundoNombre1" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo Nombre</label>
                                </div>
                            </div>
                            <div className=" col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input placeholder="" type="text" className="form-control fs-5" value={value2.datos.primerApellido1} onChange={(e) => guardarDatos(e)} id="primerApellido1" name="primerApellido1" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer apellido *</label>
                                </div>
                            </div>
                            <div className=" col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input placeholder="" type="text" className="form-control fs-5" value={value2.datos.segundoApellido1} onChange={(e) => guardarDatos(e)} id="segundoApellido1" name="segundoApellido1" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo apellido</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input style={{ textTransform: "lowercase" }} placeholder="" type="email" className="form-control fs-5" value={correo1} onChange={(e) => setCorreo1(e.target.value)} onBlur={(e) => guardarDatos(e)} onMouseOut={(e) => guardarDatos(e)} id="correo1" name="correo1" aria-describedby="emailHelp" required />
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-6 textoFormularioDepositantedirecto">Correo electrónico *</label>
                                </div>
                            </div>

                        </div>


                        <h6 className="text-royal-blue font-acumin-variable">2. Usuario operador </h6>
                        {segundoOperadorObligatorio ?
                            <div className="row mb-3">
                                <div className="col-md-4 form-floating mb-3">
                                    <div className="form-floating mb-2">
                                        < input placeholder="" type="text" className="form-control fs-5" value={value2.datos.nombreCompleto2} onChange={(e) => {
                                            guardarDatos(e)
                                            validarSegundoOperador()
                                        }} id="nombreCompleto2" name="nombreCompleto2" required />
                                        <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer nombre *</label>
                                    </div>
                                </div>
                                <div className="col-md-4 form-floating mb-3">
                                    <div className="form-floating mb-2">
                                        < input placeholder="" type="text" className="form-control fs-5" value={value2.datos.segundoNombre2} onChange={(e) => {
                                            guardarDatos(e)
                                            validarSegundoOperador()
                                        }} id="segundoNombre2" name="segundoNombre2" />
                                        <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo nombre</label>
                                    </div>
                                </div>
                                <div className="col-md-4 form-floating mb-3">
                                    <div className="form-floating mb-2">
                                        < input placeholder="" type="text" className="form-control fs-5" value={value2.datos.primerApellido2} onChange={(e) => {
                                            guardarDatos(e)
                                            validarSegundoOperador()
                                        }} id="primerApellido2" name="primerApellido2" required />
                                        <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer apellido *</label>
                                    </div>
                                </div>
                                <div className="col-md-4 form-floating mb-3">
                                    <div className="form-floating mb-2">
                                        < input placeholder="" type="text" className="form-control fs-5" value={value2.datos.segundoApellido2} onChange={(e) => {
                                            guardarDatos(e)
                                            validarSegundoOperador()
                                        }} id="segundoApellido2" name="segundoApellido2" />
                                        <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo apellido</label>
                                    </div>
                                </div>
                                <div className="col-md-4 form-floating mb-3">
                                    <div className="form-floating mb-2">
                                        <input style={{ textTransform: "lowercase" }} placeholder="" type="email" className="form-control fs-5" value={correo2} onChange={(e) => {
                                            setCorreo2(e.target.value)
                                            validarSegundoOperador()
                                        }} onBlur={(e) => guardarDatos(e)} onMouseOut={(e) => guardarDatos(e)} id="correo2" name="correo2" aria-describedby="emailHelp" required />
                                        <label htmlFor="exampleInputEmail1" className="form-label fs-6 textoFormularioDepositantedirecto">Correo electrónico *</label>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="row mb-3">
                                <div className="col-md-4 form-floating mb-3">
                                    <div className="form-floating mb-2">
                                        < input placeholder="" type="text" className="form-control fs-5" value={value2.datos.nombreCompleto2} onChange={(e) => {
                                            guardarDatos(e)
                                            validarSegundoOperador()
                                        }} id="nombreCompleto2" name="nombreCompleto2" />
                                        <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer nombre *</label>
                                    </div>
                                </div>
                                <div className="col-md-4 form-floating mb-3">
                                    <div className="form-floating mb-2">
                                        < input placeholder="" type="text" className="form-control fs-5" value={value2.datos.segundoNombre2} onChange={(e) => {
                                            guardarDatos(e)
                                            validarSegundoOperador()
                                        }} id="segundoNombre2" name="segundoNombre2" />
                                        <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo nombre</label>
                                    </div>
                                </div>
                                <div className="col-md-4 form-floating mb-3">
                                    <div className="form-floating mb-2">
                                        < input placeholder="" type="text" className="form-control fs-5" value={value2.datos.primerApellido2} onChange={(e) => {
                                            guardarDatos(e)
                                            validarSegundoOperador()
                                        }} id="primerApellido2" name="primerApellido2" />
                                        <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer apellido *</label>
                                    </div>
                                </div>
                                <div className="col-md-4 form-floating mb-3">
                                    <div className="form-floating mb-2">
                                        < input placeholder="" type="text" className="form-control fs-5" value={value2.datos.segundoApellido2} onChange={(e) => {
                                            guardarDatos(e)
                                            validarSegundoOperador()
                                        }} id="segundoApellido2" name="segundoApellido2" />
                                        <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo apellido</label>
                                    </div>
                                </div>
                                <div className="col-md-4 form-floating mb-3">
                                    <div className="form-floating mb-2">
                                        <input style={{ textTransform: "lowercase" }} placeholder="" type="email" className="form-control fs-5" value={correo2} onChange={(e) => {
                                            setCorreo2(e.target.value)
                                            validarSegundoOperador()
                                        }} onBlur={(e) => guardarDatos(e)} onMouseOut={(e) => guardarDatos(e)} id="correo2" name="correo2" aria-describedby="emailHelp" />
                                        <label htmlFor="exampleInputEmail1" className="form-label fs-6 textoFormularioDepositantedirecto">Correo electrónico *</label>
                                    </div>
                                </div>
                            </div>
                        }

                    </>

                    :
                    <>
                        <div className="row mb-3">
                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5 border  " value={"LEMPIRA"} required />
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Moneda</label>
                                </div>
                            </div>

                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5 border  " value={value.datos.bancoN} required />
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Bancos</label>
                                </div>
                            </div>



                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5 border  " value={value.datos.tipoCuentaN} required />
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Tipo de cuenta</label>
                                </div>
                            </div>


                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" maxLength={22} className="form-control fs-5 border  " value={value.datos.numeroCuentaN} id="numeroCuentaN" name="numeroCuentaN" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Número de cuenta</label>
                                </div>
                            </div>
                        </div>
                        <h6 className="text-royal-blue font-acumin-variable" >Extranjera</h6>

                        <div className="row mb-3">
                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5 border  " value={"DÓLAR"} />
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Moneda</label>
                                </div>

                            </div>


                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5 border  " value={value.datos.bancoE} required />
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">Bancos</label>
                                </div>
                            </div>

                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5 border  " value={value.datos.tipoCuentaE} />
                                    <label htmlFor="validationCustom04" className="form-label fs-6 textoFormularioDepositantedirecto">tipoCuentaE</label>
                                </div>
                            </div>


                            <div className="col-md-3 form-floating">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" maxLength={22} className="form-control fs-5 border  " value={value.datos.numeroCuentaE} id="numeroCuentaE" name="numeroCuentaE" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Número de cuenta</label>
                                </div>
                            </div>
                        </div>




                        <h5 className="text-royal-blue font-acumin-variable " >IV. Instrucciones especiales (usuarios)</h5>

                        <h6 className="text-royal-blue font-acumin-variable">1. Usuario operador </h6>



                        <div className="row mb-3">

                            <div className=" col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" className="form-control fs-5" value={value.datos.nombreCompleto1} id="nombreCompleto1" name="nombreCompleto1" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer Nombre *</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" className="form-control fs-5" value={value.datos.segundoNombre1} id="segundoNombre1" name="segundoNombre1" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo Nombre</label>
                                </div>
                            </div>
                            <div className=" col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    < input disabled type="text" className="form-control fs-5" value={value.datos.primerApellido1} id="primerApellido1" name="primerApellido1" required />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer apellido *</label>
                                </div>
                            </div>
                            <div className=" col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled type="text" className="form-control fs-5" value={value.datos.segundoApellido1} id="segundoApellido1" name="segundoApellido1" />
                                    <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo apellido</label>
                                </div>
                            </div>
                            <div className="col-md-4 form-floating mb-3">
                                <div className="form-floating mb-2">
                                    <input disabled style={{ textTransform: "lowercase" }} type="email" className="form-control fs-5" value={correo1} id="correo1" name="correo1" aria-describedby="emailHelp" required />
                                    <label htmlFor="exampleInputEmail1" className="form-label fs-6 textoFormularioDepositantedirecto">Correo electrónico *</label>
                                </div>
                            </div>

                        </div>

                        {value.datos.nombreCompleto2 &&
                            <>
                                <h6 className="text-royal-blue font-acumin-variable">2. Usuario operador *</h6>
                                <div className="row mb-3">
                                    <div className="col-md-4 form-floating mb-3">
                                        <div className="form-floating mb-2">
                                            < input disabled type="text" className="form-control fs-5" value={value.datos.nombreCompleto2} id="nombreCompleto2" name="nombreCompleto2" required />
                                            <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer nombre *</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4 form-floating mb-3">
                                        <div className="form-floating mb-2">
                                            < input disabled type="text" className="form-control fs-5" value={value.datos.segundoNombre2} id="segundoNombre2" name="segundoNombre2" />
                                            <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo nombre</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4 form-floating mb-3">
                                        <div className="form-floating mb-2">
                                            < input disabled type="text" className="form-control fs-5" value={value.datos.primerApellido2} id="primerApellido2" name="primerApellido2" required />
                                            <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Primer apellido *</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4 form-floating mb-3">
                                        <div className="form-floating mb-2">
                                            < input disabled type="text" className="form-control fs-5" value={value.datos.segundoApellido2} id="segundoApellido2" name="segundoApellido2" />
                                            <label htmlFor="validationCustom02" className="form-label fs-6 textoFormularioDepositantedirecto">Segundo apellido</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4 form-floating mb-3">
                                        <div className="form-floating mb-2">
                                            < input disabled style={{ textTransform: "lowercase" }} type="email" className="form-control fs-5 " value={correo2} id="correo2" name="correo2" aria-describedby="emailHelp" required />
                                            <label htmlFor="exampleInputEmail1" className="form-label fs-6 textoFormularioDepositantedirecto">Correo electrónico *</label>
                                        </div>
                                    </div>
                                </div>
                            </>

                        }

                    </>


                }






            </div>

        </>
    );
}

