import React from "react";
import { Link } from "react-router-dom";

//Images
import ddpj from '../../../assets/imagenesDepositantes/ddpj.jpg'
import dipj from '../../../assets/imagenesDepositantes/dipj.jpg'
import dipn from '../../../assets/imagenesDepositantes/dipn.jpg'

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";

export default function MenuSolicitudesAutorizadorCehval({ history }) {
    
    return (
        <>
           <div className="fluid-container">
            
            <main className="main-min-height">
                <div className="row row-wrap g-0">
                    
                 
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Solicitudes pendientes por autorizar"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5 mb-5">
                                        <div className="row ">
                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-3 mb-3">
                                         
                                            <Link to={{
                                                pathname: '/solicitudesDepositantesParaAutorizar',
                                                state: 1
                                            }} className="text-decoration-none text-dark">
                                                <div className="card" >
                                                    <img className="" src={ddpj} alt="imagenFondo" />
                                                    <div className="card-body text-center font-acumin-variable">
                                                        <h5 className="card-title">Depositantes directos</h5>
                                                    </div>
                                                </div>
                                                </Link>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-3 mb-3">
                                            <Link to={{
                                                pathname: '/solicitudesDepositantesParaAutorizar',
                                                state: 2
                                            }} className="text-decoration-none text-dark">
                                                <div className="card" >
                                                    <img className="" src={dipj} alt="imagenFondo" />
                                                    <div className="card-body text-center font-acumin-variable">
                                                        <h5 className="card-title">Depositantes indirectos personas jurídicas</h5>
                                                    </div>
                                                </div>
                                                </Link>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mb-3 mb-3">
                                            <Link to={{
                                                pathname: '/solicitudesDepositantesParaAutorizar',
                                                state: 3
                                            }} className="text-decoration-none text-dark">
                                                <div className="card" >
                                                    <img className="" src={dipn} alt="imagenFondo" />
                                                    <div className="card-body text-center font-acumin-variable">
                                                        <h5 className="card-title">Depositantes indirectos personas naturales</h5>
                                                    </div>
                                                </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </main >
            
        </div>
        </>
    );
}

