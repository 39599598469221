//React
import React from "react";
import ComponenteMenuEmsoresEmisionesSeries from "../../../ComponentesGenerales/ComponenteMenuEmsoresEmisionesSeries";

export default function MenuSeriesEmisionesAutorizadas({ history }) {

    return (
           <ComponenteMenuEmsoresEmisionesSeries titulo="Emisores, emisiones y series autorizadas" ruta1="/emisoresParaAbrirCuentas" ruta2="/emisionesAutorizadas" ruta3="/seriesAutorizadas" ></ComponenteMenuEmsoresEmisionesSeries>
    );
}

