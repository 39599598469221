import { useMemo } from "react";

export default function useConfirmarPagosLiquidaciones() {
    const columns = useMemo(
        () => [
            {
                Header: "Nombre de cliente CEHVAL",
                accessor: "nombreClienteCehval"
            },
            {
                Header: "Código de cliente CEHVAL",
                accessor: "codigoClienteCehvalC"
            },
            {
                Header: "Cantidad de valores",
                accessor: "cantidadValores"
            },
            {
                Header: "Monto a pagar bruto",
                accessor: "montoAPagarBruto"
            },
            {
                Header: "Rentención",
                accessor: "retencion"
            },
            {
                Header: "Monto total a pagar neto",
                accessor: "montoApagarNeto"
            },
            {
                Header: "Moneda",
                accessor: "moneda"
            },
            {
                Header: "Cuenta",
                accessor: "cuenta"
            },
            {
                Header: "Banco",
                accessor: "banco"
            },
            {
                Header: "Tipo de cuenta",
                accessor: "tipoCuenta"
            },
            {
                Header: "Dirección",
                accessor: "direccion"
            },
            {
                Header: "Estatus",
                accessor: "estatus"
            },
           
           
        ],
        []
    );

    return columns;
}