import React, { useState, useEffect } from 'react';

//Components
import BannerTitulo from '../../../ComponentesGenerales/Bannertitulo';
//Form to do TLP
import FormularioTLPOperador from './FormularioTLPOperador';


export default function TLPOperadorCehval({ history }) {

    const [codigoEmisor, setCodigoEmisor] = useState();
    const [nombreEmisor, setNombreEmisor] = useState();
    const [codigoDepositanteDirecto, setCodigoDepositanteDirecto] = useState();
    const [nombreDepositanteDirecto, setNombreDepositanteDirecto] = useState();
    const [codigoDepositante, setCodigoDepositante] = useState();
    const [nombreDepositante, setNombreDepositante] = useState();

    const [monedaCuenta, setMonedaCuenta] = useState();
    const [numeroCuenta, setNumeroCuenta] = useState();
    const [saldo, setSaldo] = useState();
    const [isin, setIsin] = useState();
    const [codSerie, setCodSerie] = useState();
    const [codEmision, setCodEmision] = useState();
    const [idValor, setIdValor] = useState();

    const [loadData, setLoadData] = useState();

    useEffect(() => {
        getTransactionRowInfo();
    }, []);


    const getTransactionRowInfo = async () => {
        try {

            if (history.location.state === undefined) {
                history.go(-1)

            } else {
              
                const datosTransferente = history.location.state.datosTransferente;

                setCodigoEmisor(datosTransferente.codigoEmisor)
                setNombreEmisor(datosTransferente.nombreEmisor)
                setNombreDepositanteDirecto(datosTransferente.nombreDepositanteDirecto)
                setCodigoDepositanteDirecto(datosTransferente.codigoDepositanteDirecto)
                //console.log(datosTransferente.codigoDepositanteDirecto)
                setCodigoDepositante(datosTransferente.codigoDep);
                setNombreDepositante(datosTransferente.nombreDep);
                setMonedaCuenta(datosTransferente.moneda);
                setNumeroCuenta(datosTransferente.numeroCuenta);
                setSaldo(datosTransferente.saldoDisponible);
                setIsin(datosTransferente.isinSerie);
                setCodSerie(datosTransferente.codSerie);
                setCodEmision(datosTransferente.codEmision);
                setIdValor(datosTransferente.idValor);
                setLoadData(true);
                
            }

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <div className="fluid-container">
                
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Traspaso libre de pago"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        {loadData &&
                                             <FormularioTLPOperador
                                             codigoEmisor={codigoEmisor}
                                             nombreEmisor={nombreEmisor}
                                             codDepositante={codigoDepositante}
                                             nombreDepositante={nombreDepositante}
                                             codDepDirecto={codigoDepositanteDirecto}
                                             nomDepDirecto={nombreDepositanteDirecto}
                                             numeroCuenta={numeroCuenta}
                                             monedaCuenta={monedaCuenta}
                                             saldoDisponible={saldo}
                                             isin={isin}
                                             codSerie={codSerie}
                                             codEmision={codEmision}
                                             idValor={idValor}
                                         />
                                        }
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    )
}