//React
import React, { useState, useEffect } from "react";



//Componentes generales
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores';
import TablaUsuarios from "../../../../ComponentesGenerales/TablaUsuarios";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";

//APIs
import { traerSolicitudesDeConstitucion } from "../../../../api/prendasYGarantias/SolicitudesDeConstitucionGarantia/traerSolicitudesDeConstitucion";


//Hooks
import useSolicitudesConstitucionGarantia from '../../../../hooks/PrendasYGarantias/useSolicitudesConstitucionGarantia';


export default function TablaSolicitudesConstitucionDeGarantia({ history }) {


    const [copia, setCopia] = useState();
    const [data, setData] = useState([]);
    const [spin, setSpin] = useState(true);
    const [erroreshttp, setErroreshttp] = useState();

    const [failLoadData, setFailLoadData] = useState();


    useEffect(() => {
        getAllConstitutionRequests();
    }, []);


    const getAllConstitutionRequests = async () => {
        try {
            const response = await traerSolicitudesDeConstitucion({ estatus: 1 });
            if (response) {
                setSpin(false)
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    if (response.data) {
                        setSpin(false)
                        setData(response.data);
                        setCopia(response.data);
        
                    } else {
                        setFailLoadData("No se pudo cargar la información");
                    }
                }
            }

         

        } catch (err) {
            console.error(err);
        }
    }



    //Catch information form the selected row
    const viewRequestConstitucion = (row) => {

        const folio = row.original.cehvalSolicitudGarantiaFolio;

        const constitutionArrayInfo = data.filter((row) => row.cehvalSolicitudGarantiaFolio === folio);

        //Mandar el objeto de la emision que el usuario desea ver
        history.push({
            pathname: '/autorizarConstitucionDeGarantia', state: { objConstitution: constitutionArrayInfo }
        });
    }



    const goBack = () => {
        history.push({
            pathname: '/inicioCehval',
        })
    }

    return (
        <>
           

            {failLoadData &&
                <ComponenteModal mostrarModal={failLoadData} cerrarModal={goBack} titulo={failLoadData} textoBoton="Aceptar"></ComponenteModal>
            }
            <div className="">
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Solicitudes de constitución de prenda o garantía por autorizar"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaUsuarios datos={data} displayInformation={viewRequestConstitucion} useValidate={useSolicitudesConstitucionGarantia} textoBoton={"Abrir solicitud"} textTitle="Click para ver la información de la solicitud"></TablaUsuarios>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    );
}





