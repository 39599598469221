import React from "react";

//Components
import ComponenteModal from "../../ComponentesGenerales/ComponenteModal";

//AWS
import { Auth } from 'aws-amplify';


//Images
import cajaFuerte from './../../assets/logosCehval/caja-fuerte.jpg';

//Iconos
import { IoQrCodeOutline } from "react-icons/io5";
import { RiLockLine } from "react-icons/ri";
import { RiLockFill } from "react-icons/ri";


export default function RecuperarPassword({ history }) {
    if (history.location.state === undefined) {
        history.go(-1)
    }

   // const [isValidEmail, setIsValidEmail] = React.useState(true);
    //const [isValidPassword, setIsValidPassword] = React.useState(true);
    const [codigo, setCodigo] = React.useState();
    const [contrasena, setContrasena] = React.useState();
    const [contrasenasNoCoinciden, setContrasenasNoCoinciden] = React.useState();
    const [confirmarContrasena, setConfirmarContrasena] = React.useState();
    const [errorContrasena, setErrorContrasena] = React.useState();
    //const [contraseñaRecuperada, setContraseñaRecuperada] = React.useState();
    const [errorCodigoEquivocado, setErrorCodigoEquivocado] = React.useState();
    const [errorIntentosMaximos, setErrorIntentosMAximos] = React.useState();
    //const [cancelarModal, setCancelarModal] = React.useState();
    const [contrasenaCambiada, setContrasenaCambiada] = React.useState();


    const onSubmitForm = async (e) => {
        e.preventDefault();


        if (contrasena === confirmarContrasena) {
            setContrasenasNoCoinciden(false)
            try {
                const data = await Auth.forgotPasswordSubmit(history.location.state.objMenu, codigo, contrasena)
                //console.log(data)
                setContrasenaCambiada(true)

            } catch (err) {

                if ((err.message.indexOf("Invalid verification code provided") >= 0) || (err.message.indexOf("Invalid code provided") >= 0)) {
                    setErrorCodigoEquivocado(true)
                } else {
                    setErrorCodigoEquivocado(false)
                }

                if (err.message.indexOf("Password does not conform to policy") >= 0) {
                    setErrorContrasena(true)
                } else {
                    setErrorContrasena(false)
                }

                if (err.message.indexOf("Attempt limit exceeded, please try after some time") >= 0) {
                    setErrorIntentosMAximos(true)
                } else {
                    setErrorIntentosMAximos(false)
                }
                //console.log(err)
            }
        } else {
            setContrasenasNoCoinciden(true)
        }
    }

    const cancelarModalContraseña = () => {
        setContrasenaCambiada(false)
        history.push({
            pathname: '/login'
        })
    }

    return (
        <>
            {contrasenaCambiada &&
                <ComponenteModal mostrarModal={contrasenaCambiada} cerrarModal={cancelarModalContraseña} titulo="El cambio de contraseña fue exitoso" textoBoton="Entendido"></ComponenteModal>
            }

            {errorCodigoEquivocado &&
                <ComponenteModal mostrarModal={errorCodigoEquivocado} cerrarModal={() => { setErrorCodigoEquivocado(false) }} titulo="El código es incorrecto, intente de nuevo" textoBoton="Entendido"></ComponenteModal>
            }

            {contrasenasNoCoinciden &&
                <ComponenteModal mostrarModal={contrasenasNoCoinciden} cerrarModal={() => { setContrasenasNoCoinciden(false) }} titulo="Las contraseñas no coinciden" textoBoton="Entendido"></ComponenteModal>
            }

            {errorIntentosMaximos &&
                <ComponenteModal mostrarModal={errorIntentosMaximos} cerrarModal={() => { setErrorIntentosMAximos(false) }} titulo="Excedió el límite de intentos, inténtelo después" textoBoton="Entendido"></ComponenteModal>
            }

            <main className=" p-0 m-0 g-0">
                <div className="row justify-content-between g-0">
                    <div className="col-12 col-md-12 col-lg-6 p-0 g-0 row m-0">
                        <img alt="cajaFuerte" className="" id="loginImageContainer" src={cajaFuerte} />
                    </div>
                    <div className="col-12  col-md-12  col-lg-6 p-2">
                        <div>
                            <form onSubmit={(e) => onSubmitForm(e)} className="p-4">
                                <div className="row justify-content-left mt-4 p-2 ">
                                    <div className="mb-5">
                                        <h4 className="fs-2  fw-bolder royal-blue-light ">Restablece tu contraseña</h4>
                                        <p className="fs-5  fw-bold royal-blue-light mt-4">Introduce el código que llegó a tu correo y crea una nueva contraseña</p>
                                    </div>

                                    <div className="col-12 col-md-7 col-lg-10 mb-3">
                                        <div className="form-floating mb-3">
                                            <input required type="text" id="codigo" className="form-control" placeholder="name@example.com" value={codigo} onChange={(event) => setCodigo(event.target.value)} />
                                            <label htmlFor="codigo" className=" royal-blue-light font-acumin-variable "><IoQrCodeOutline />&nbsp; Código</label>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-7 col-lg-10 mb-3">
                                        <div className="form-floating ">
                                            <input required type="password" id="contraseña" className="form-control" placeholder="contraseña" value={contrasena} onChange={(event) => setContrasena(event.target.value)} />
                                            <label htmlFor="contraseña" className=" royal-blue-light font-acumin-variable "><RiLockLine />&nbsp; Nueva contraseña</label>

                                            {errorContrasena &&
                                                <>
                                                    <p className="error">Error en el formato de la contraseña, revise las especificaciones de la contraseña.</p>
                                                </>
                                            }

                                            <div id="emailHelp" className="form-text">La contraseña debe tener minimo 8 caracteres, una letra minúscula, una letra mayúscula y un caracter especial como !"·$%&/()=?</div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-7 col-lg-10">
                                        <div className="form-floating ">
                                            <input required type="password" id="confirmarContraseña" className="form-control" placeholder="contraseña" value={confirmarContrasena} onChange={(event) => setConfirmarContrasena(event.target.value)} />
                                            <label htmlFor="confirmarContraseña" className="royal-blue-light font-acumin-variable "><RiLockFill />&nbsp; Confirmar contraseña</label>

                                            {errorContrasena &&
                                                <>
                                                    <p className="error">Error en el formato de la contraseña, revise las especificaciones de la contraseña.</p>
                                                </>
                                            }
                                        </div>
                                    </div>

                                    <div className=" row justify-content-start p-3 mb-3">
                                        <button  className="col-5 col-md-3 col-lg-4 btn  background-royal-blue text-white bg-hover-gold p-2" type="submit">Confirmar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </main>
        </>
    );
}

