import { useMemo } from "react";

export default function useSeriesBCV() {
  const columns = useMemo(
    () => [
      {
        Header: "Código serie",
        accessor: "codigoSerie"
      },
      {
        Header: "Código emisión",
        accessor: "codigoEmision"
      },
      {
        Header: "Monto de la serie",
        accessor: "montoDeLaSerie"
      },
      {
        Header: "Codigo ISIN",
        accessor: "codigoIsin"
      },
      {
        Header: "Serie",
        accessor: "serie"
      }
    ],
    []
  );

  return columns;
}
