import { instance } from '../apiGlobal';

export async function lecturaArchivoOperacionesMS(file) {
    try {
        const response = await instance(`/mercadosecundario/cargar/operaciones`, {
            method: "post",
            data: file,
        })
        return response;
    } catch (error) {
        return error;
    }
}