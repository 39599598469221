import { useMemo } from "react";

export default function useFondosVirtuales() {
  const columns = useMemo(
    () => [
      {
        Header: "Código de cliente CEHVAL",
        accessor: "codigoCliente"
      },
      {
        Header: "Nombre de cliente CEHVAL",
        accessor: "nombreCliente"
      },
      {
        Header: "Fondos disponibles",
        accessor: "fondosDisponiblesStr"
      },
      {
        Header: "Fondos apartados",
        accessor: "fondosApartadosStr"
      },           
    ],
    []
  );

  return columns;
}
