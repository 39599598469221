import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';


//Components
import BannerTitulo from "../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../ComponentesGenerales/PantallaCargando";

//APIs
import { activarDesactivarMantenimiento } from "../../api/MantenimientoFacturas/activarDesactivarMantenimiento";



export default function DesactivarActivarMantenimiento({ history }) {
    const [ objFacturacion, setObjFacturacion] = useState();

    //form inputs
    const [numeroFactura, setNumeroFactura] = useState();
    const [fechaLimite, setFechaLimite] = useState();
    const [rangoAutorizacion, setRangoAutorizacion] = useState();
    const [isv, setIsv] = useState();
    const [cai, setCai] = useState();


    //Responses from backend
    const [requestStatus, setRequestStatus] = useState();

    //Show loading screen
    const [loading, setLoading] = useState();


    //Errors and messages    
    const [erroreshttp, setErroreshttp] = useState();



    useEffect(() => {
        const dataFactura = history.location.state.rowData;
        //console.log(dataFactura)
        if(history.location.state === undefined){
            history.push({
                pathname: "/tablaDatosFacturacion",
            });
        } else {
        setObjFacturacion(dataFactura);
        setNumeroFactura(dataFactura.prefijo);
        setFechaLimite(dataFactura.fechaLimiteStr);
        setRangoAutorizacion(dataFactura.rangoAutorizado);
        setIsv(dataFactura.isvStr);
        setCai(dataFactura.cai);
        }
    }, []);


    //Send form data to endpoint 
    const activarDesactivar = async (e) => {
        try {
            e.preventDefault();
          

            setLoading(true);
            const response = await activarDesactivarMantenimiento(objFacturacion);
            setLoading(false);
            //console.log(response)


            if (isNaN(response.error) === false) {
                setErroreshttp(response);

            } else if (!response.isSuccessful) {
                setRequestStatus(response.mensaje);

            } else if (response.isSuccessful) {
                setRequestStatus(response.mensaje);
            }

        } catch (error) {
            //console.log(error);
        }
    }


    const goToTable = () => {
        history.push({
            pathname: "/tablaDatosFacturacion",
        })
    }



    return (
        <>


            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {requestStatus &&
                <ComponenteModal mostrarModal={requestStatus} cerrarModal={goToTable} titulo={requestStatus} textoBoton="Entendido"></ComponenteModal>
            }

            {loading &&
                <PantallaCargando></PantallaCargando>
            }

            <div className="fluid-container">{/* style={{ backgroundImage: `url(${fondo})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="fluid-container">*/}
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        <div className="col-12 mb-5 main-min-height">
                            <div className="">
                                <BannerTitulo titulo="Activar o desactivar mantenimiento de facturas"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container py-5">
                                        <section className="d-flex justify-content-center">
                                            <form onSubmit={activarDesactivar} className="col-12 col-sm-12 col-md-12 row border rounded text-royal-blue">
                                                <div className="col-12 col-md-6 mb-3 mt-3">
                                                    <div className="form-floating ">
                                                        <input title="Número de factura" value={numeroFactura} className="form-control fs-5" maxLength={12} name="numeroFactura" type="text" placeholder="Número de factura" disabled/>
                                                        <label className="text-start font-acumin-variable" htmlFor="numeroFactura">No. factura &#40;Dígitos comunes&#41;</label>
                                                    </div>
                                                </div>
                                                <h5 className="font-acumin-variable text-royal-blue">Rango autorizado</h5>
                                                <div className="col-12 col-sm-12 col-md-12 mb-3">
                                                    <div className="form-floating ">
                                                        <input title="Rango autorizado" className="form-control fs-5" value={rangoAutorizacion} maxLength={25} name="rangoAutorizado" type="text" placeholder="Rango autorizado inicio" disabled />
                                                        <label className="text-start font-acumin-variable" htmlFor="rangoAutorizado"></label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 mb-3">
                                                    <div className="form-floating">
                                                        <input title="Fecha límite de emisión" type="text" className="form-control border" value={fechaLimite} name="fechaLimiteEmision" placeholder="Fecha límite de emisión" disabled/>
                                                        <label htmlFor="fechaLimiteEmision" className="form-label ">Fecha límite de emisión</label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 form-floating mb-3">
                                                    <div className="form-floating">
                                                        <input title="I.S.V" type="text" className="form-control border"  value={isv} name="ISV" placeholder="I.S.V" disabled/>
                                                        <label htmlFor="ISV" className="form-label ">Factor I.S.V &#40;%&#41;</label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 mb-3">
                                                    <div className="form-floating ">
                                                        <input title="C.A.I" className="form-control fs-5" value={cai} maxLength={50} name="CAI" type="text" placeholder="C.A.I" disabled/>
                                                        <label className="text-start font-acumin-variable" htmlFor="CAI">C.A.I</label>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 d-flex row justify-content-end mb-3 text-wrap">
                                                    <button type="submit" className="btn col-7 col-sm-5 col-md-4 col-lg-3 col-xl-2 background-royal-blue text-white bg-hover-gold ">Activar/Desactivar</button>
                                                </div>
                                            </form>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                </main >
            </div >
        </>
    );
}

