import React, { useState } from 'react';

export const FormContext = React.createContext();

export function FormProvider({ children }) {
  const [formValue, setFormValue] = useState({
    datos: {
    cehvalDocumentoSoporCopiaDni :0,
    cehvalDocumentoSoporCopiaRtn : 0,
    cehvalDocumentoSoporPoderRepreAdmon: 0,
    cehvalDocumentoSoporExpedComp : 0,
    contratoDeDepositante:0,
    revisionListaOfac:0,
    certificacionDebidaDiligenciaRecibida:0,
    errorDocumentacionIncompletaFuncionario:true,
    estatusFirma1:0,
    idFirma1:"",
    estatusFirma2:0,
    idFirma2:"",
    enviar:0,
    }
  });

return (
  <FormContext.Provider value={[formValue, setFormValue]}>
    {children}
  </FormContext.Provider>
);
}
