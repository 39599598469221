import React from "react";


export default function ModalConfirmAction ({idModal, confirmFunction, textModal}) {

    return (
        <>
            <div id={idModal} className="modal" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title font-acumin-variable">Confirmar acción</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" title='cerrar'></button>
                        </div>
                        <div className="modal-body">
                            <p className='font-acumin-variable'>{textModal}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn background-royal-blue text-white bg-hover-red font-acumin-variable" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" onClick={confirmFunction} className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable" data-bs-dismiss="modal">Confirmar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

