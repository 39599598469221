import { instance } from '../apiGlobal';

export async function imprimirEmisorRegistrado(obj) {
    try {
        const response = await instance(`/emisor/imprimir`, {
            method: "post",
            data: obj,
            responseType: 'blob',
         
        })
        return response.data
    } catch (error) {
        return error
    }
}