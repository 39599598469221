import React, { useState } from "react";

//General components
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";

//APIs
import { registroSolicitudConstitucionDeGarantia } from '../../../../api/prendasYGarantias/SolicitudesDeConstitucionGarantia/registroSolicitudConstitucionDeGarantia';
import { envioPDFConstitucion } from '../../../../api/prendasYGarantias/SolicitudesDeConstitucionGarantia/envioPDFConstitucion';


//Functions
import formatNumber from "../../../../functions/FormatNumber"

export default function SolicitudConstitucionDeGarantia({ history }) {

    //Inputs
    const [codigoDepositanteTransferente, setCodigoDepositanteTransferente] = useState();
    const [isin, setIsin] = useState();
    const [cantidadValores, setCantidadValores] = useState();
    const [amountTransfer, setAmountTransfer] = useState();
    const [nombreBeneficiario, setNombreBeneficiario] = useState();
    const [file, setFile] = useState();
    const [erroreshttp, setErroreshttp] = React.useState()
    const [invalidFile, setInvalidFile] = useState(false);




    //States to show modal when regiter is successful or not
    const [registroExitoso, setRegistroExitoso] = useState();
    const [registroFallido, setRegistroFallido] = useState();

    //Shows loading screen when true
    const [loading, setLoading] = useState(false);

    //Messages user
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [messageSuccessful, setMessageSuccessful] = useState();
    const [messageFailed, setMessageFailed] = useState();


    //Handle when user choose a file
    const selectFile = (e) => {
        e.preventDefault();

        //Cleans error message if was one
        setError("");

        //Name of the file to validate if has the correct extension
        const fileName = e.target.files[0].name;

        const fileSize = e.target.files[0].size;

        //Regex to validate the extension of the file
        const extensionsAllow = /(.pdf)$/i;

        //If selected file doesnt have correct extension show user error message
        if (!extensionsAllow.exec(fileName)) {
            setInvalidFile(true);
            setMessage("");

            //Shows an error message under the square to select file
            setError("Selecciona un archivo válido");

            //Clean our states if they had any information
            setFile("");

            document.querySelector("#inputFilePDF").value = "";

            return false;

        } else if (fileSize > 10_000_000) {
            setInvalidFile(true);

            setMessage("");

            //Shows an error message 
            setError("El tamaño del archivo debe ser menor a 10 MB");
            //Clean file name if a file was selected before

            //Clean state if a file was selected before
            setFile("");

            document.querySelector("#inputFilePDF").value = "";

        } else if (e.target.files && e.target.files[0]) {

            setMessage("Archivo válido")
            //Set FileList [File] to our state
            setFile(e.target.files[0]);
            ////console.log(file)
        }

    }

    const sendConstitutionForm = async (e) => {

        e.preventDefault();
        try {
            const formData = {
                //folio: assignedFolio,
                codigoDepositanteTransferente: codigoDepositanteTransferente,
                isin: isin,
                cantidadValores: amountTransfer,
                nombreBeneficiario: nombreBeneficiario,
            }

            setLoading(true);

            //console.log(formData)
            const response = await registroSolicitudConstitucionDeGarantia(formData);
            //console.log(response)
            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    if (response.data) {
                        if (response.data.isSuccessful === true) {
                            const folioConstitucionGenerado = (response.data.mensaje);
                            sendConstitutionFile(folioConstitucionGenerado)


                        } else if (response.data.isSuccessful === false) {
                            setLoading(false);
                            setMessageFailed(response.data.mensaje);
                            setRegistroFallido(true);
                        }

                    } else if (response.error === 401) {
                        setLoading(false);
                        setRegistroFallido(true);
                        setMessageFailed("La sesión ha caducado");
                    }
                }
            }



        } catch (error) {
            console.error(error)
        }
    }


    //If form was succesfully registered we call this function
    const sendConstitutionFile = async (folio) => {

        try {

            //The file we'll send in a first request
            const pdfFile = new FormData();
            pdfFile.append('fileName', folio);
            pdfFile.append('file', file);

            const response = await envioPDFConstitucion(pdfFile);
            if (response) {
                setLoading(false);
                //setCargando(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    //console.log(response)

                    //Get the generated folio
                    //setFolio(reponse.data.respuesta);

                    if (response.data.isSuccesful === true) {
                        setLoading(false);
                        setRegistroExitoso(true);
                        setMessageSuccessful(response.data.respuesta);

                    } else {

                        setLoading(false);
                        setRegistroFallido(true);
                    }
                }
            }




        } catch (error) {
            //console.log(error);
        }
    }

    // Format number and prevent to enter letter, symbols and allow to enter decimals
    const handleCantidadValoresInput = (e) => {

        //Value entered by user
        const { value } = e.target;

        const formattedNumber = formatNumber(value);

        //Number without format that will be sended to the endpoint registroSolicitudConstitucionDeGarantia()
        setAmountTransfer(formattedNumber.integerNumber);
        setCantidadValores(formattedNumber.stringNumber);
    }


    //Executes when the emitter was successfully registered
    const clearForm = () => {
        setRegistroExitoso(false);
        setRegistroFallido(false);

        document.querySelector("#inputFilePDF").value = "";
        setCodigoDepositanteTransferente("");
        setIsin("");
        setCantidadValores("");
        setNombreBeneficiario("");
        setMessage("");
    }

    return (
        <>
            {invalidFile &&
                <ComponenteModal mostrarModal={invalidFile} cerrarModal={() => { setInvalidFile(false) }} titulo="Archivo inválido. Por favor seleccione un archivo PDF y con un peso menor a 10 MB" textoBoton="Aceptar"></ComponenteModal>
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={clearForm} titulo={`${messageSuccessful}`} textoBoton="Aceptar"></ComponenteModal>
            }
            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={() => setRegistroFallido(false)} titulo={`${messageFailed}`} textoBoton="Aceptar"></ComponenteModal>
            }
            {loading &&
                <PantallaCargando />
            }
            <div className="fluid-container">

                <main className="main-min-height mb-5">
                    <div className="row row-wrap g-0">
                        <div className="col-12 col-sm-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Solicitud de constitución de prenda o garantía"></BannerTitulo>
                                <div className="d-flex mt-5 justify-content-center px-3 ">
                                    <div className="col-12 ">
                                        <section className="d-flex justify-content-center">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11 row justify-content-center border rounded">
                                                <div className="col-12">
                                                    <form onSubmit={sendConstitutionForm} className="needs-validation font-acumin-variable text-royal-blue">
                                                        <div className="row mt-4">
                                                            <div className="col-12 col-md-12 col-lg-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código depositante transferente" onChange={(e) => setCodigoDepositanteTransferente(e.target.value)} value={codigoDepositanteTransferente} className="form-control fs-5" maxLength={20} name="codigoDepositanteTransferente" type="text" placeholder="" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="codigoDepositanteTransferente">Código de depositante transferente *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Código ISIN" onChange={(e) => setIsin(e.target.value)} value={isin} className="form-control fs-5" maxLength={13} type="text" name="ISIN" placeholder="ISIN" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="ISIN">ISIN *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Cantidad de valores a transferir" onInput={(e) => handleCantidadValoresInput(e)} value={cantidadValores} className="form-control fs-5" maxLength={40} name="cantidadValores" type="text" placeholder="Cantidad de valores" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="cantidadValores">Cantidad de valores *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-6 mb-3">
                                                                <div className="form-floating ">
                                                                    <input title="Nombre completo del beneficiario" onChange={((e) => setNombreBeneficiario(e.target.value))} value={nombreBeneficiario} className="form-control fs-5" maxLength={60} name="nombreBeneficiario" type="text" placeholder="Nombre del beneficiario" required />
                                                                    <label className="text-start font-acumin-variable" htmlFor="nombreBeneficiario">Nombre del beneficiario *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-6 mb-3">
                                                                <input id="inputFilePDF" title="Clic para seleccionar un archivo" type="file" onChange={(e) => selectFile(e)} className="form-control text-royal-blue fs-5" name="archivoEscaneado" aria-describedby="help-input-file" required />
                                                                <div id="help-input-file" className="form-text">Escanee el contrato y suba en formato <i>.PDF</i> *</div>
                                                                {error &&
                                                                    <p className="text-danger font-acumin-variable fs-5">{error}</p>
                                                                }
                                                                {message &&
                                                                    <p className="text-success font-acumin-variable fs-5">{message}</p>
                                                                }
                                                            </div>
                                                            {/*
                                                            <div className="col-12 col-md-6 mb-3 row">
                                                                <div className="col-1 col-md-1 col-lg-1 ">
                                                                    <input title="Clic para subir archivo" type="checkbox" onChange={(e) => setFileCheck(e.target.checked)} checked={fileCheck} className="form-check-input text-royal-blue fs-5" name="checkFile" />
                                                                </div>
                                                                <div className="col-10 col-md-10 col-lg-10">
                                                                    <label htmlFor="checkFile" className="">Escaneo y carga del contrato de constitución de prenda o garantía</label>
                                                                </div>
                                                            </div>
                                                             */}
                                                            <div className="col-12 col-md-12 d-flex justify-content-end mb-3">
                                                                <button type="submit" className="btn background-royal-blue text-white bg-hover-gold">Enviar</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >

            </div>
        </>
    )
}