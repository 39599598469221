
//React
import React, { useState, useEffect } from "react";


//Componentes generales
import ManejadorErrores from '../ComponentesGenerales/ManejadorErrores';
import ComponenteModal from "../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../ComponentesGenerales/PantallaCargando";



export default function DarRevocarAccesoISINSistInformacion({ permisosEndpoint, giveRemovePermission, inputText, isRevokePermissions, isGivePermissions }) {



    //Inputs where user will enter a value
    const [codigoParticipante, setCodigoParticipante] = useState();
    const [tipoParticipante, setTipoParticipante] = useState();

    //Roles
    const [rolesDisponibles, setRolesDisponibles] = useState();
    const [hasBothPermissions, setHasBothPermissions] = useState(false);

    //Option selected
    const [selectedRol, setSelectedRol] = useState(null);


    //Spinner
    const [loading, setLoading] = useState();
    const [statusResponse, setStatusResponse] = useState();

    //States to handle load data when loading page 
    const [erroreshttp, setErroreshttp] = useState();

    //Check if depositante or emisor exists
    const [depositanteExiste, setDepositanteExiste] = useState();



    useEffect(() => {

    }, []);




    //consult the participant code in the database
    const getParticipante = async (e,) => {
        e.preventDefault();
        try {

            const obj = { codigoDepositante: codigoParticipante }
            //console.log(obj)
            setLoading(true);
            const response = await permisosEndpoint(obj);
            setLoading(false);
            //console.log(response);


            if (isNaN(response.error) === false) {
                setErroreshttp(response);

            } else if (response.data.codigoDepositante === null) {
                setDepositanteExiste(false);

            } else if (response.data) {

                //Cuando el arreglo esta vacio es porque ya no tiene mas permisos para asignar
                if (response.data.rolesIsinInf.length === 0) {
                    setHasBothPermissions(true);
                    setTipoParticipante(response.data.tipoParticipante);
                    setRolesDisponibles(response.data.rolesIsinInf);
                    setDepositanteExiste(true);

                //Si no esta vacio el arreglo entonces si tiene permisos
                } else {
                    setHasBothPermissions(false);
                    setTipoParticipante(response.data.tipoParticipante);
                    setRolesDisponibles(response.data.rolesIsinInf);
                    setDepositanteExiste(true);
                }

            }

        } catch (error) {
            //console.log(error);
        }
    }

    //Function to give or remove permission depending on the path the user enters
    const giveRevokePermission = async (e) => {
        try {
            e.preventDefault();
            const objPermission = {
                codigoDepositante: codigoParticipante,
                tipoParticipante: tipoParticipante,
                rolOperacion: parseInt(selectedRol),
            }
            //console.log(objPermission)
            setLoading(true);
            const response = await giveRemovePermission(objPermission);
            setLoading(false);
            //console.log(response)

            if (isNaN(response.error) === false) {
                setErroreshttp(response);

            } else if (response.data.isSuccessful) {
                setStatusResponse(response.data.mensaje);

            } else if (response.data.isSuccessful === false) {
                setStatusResponse(response.data.mensaje);
            }

        } catch (error) {
            console.error(error);
        }
    }



    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {statusResponse &&
                <ComponenteModal mostrarModal={statusResponse} cerrarModal={() => window.location.reload()} titulo={statusResponse} textoBoton="Aceptar"></ComponenteModal>
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <div className="">
                <div className="d-flex px-3">
                    <div className="needs-validation font-acumin-variable text-royal-blue container border rounded">
                        <section className="d-flex justify-content-center mb-4 px-4">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 row justify-content-center">
                                <div className="p-0">
                                    <div className="needs-validation font-acumin-variable text-royal-blue">
                                        <form onSubmit={getParticipante}>
                                            <div className="row mt-4">
                                                <div className="col-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                                                    <div className="form-floating">
                                                        <input title="Código del depositante/emisor" onChange={(e) => setCodigoParticipante(e.target.value.toUpperCase())} value={codigoParticipante} className="form-control fs-5" type="text" name="codigoDepositanteEmisor" placeholder="Código del depositante/emisor" required />
                                                        <label className="text-start font-acumin-variable text-royal-blue" htmlFor="codigoDepositanteEmisor">Código del depositante/emisor</label>
                                                    
                                                    </div>
                                                </div>
                                                <div className="col-10 d-flex align-items-center">
                                                    <button className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable" type="submit">Consultar</button>
                                                </div>
                                            </div>
                                        </form>

                                        {depositanteExiste &&

                                            <form onSubmit={giveRevokePermission}>
                                                <div className="row mt-5">
                                                    <div className="col-12 col-md-12 col-lg-6 col-xl-6 d-flex justify-content-center mb-3">
                                                        <div className=" col-12 col-md-12 ">
                                                            <div className="form-floating">
                                                                <select title="Otorgar permisos" className="form-select font-acumin-variable" onChange={(e) => setSelectedRol(e.target.value)} name="permisos" style={{ height: "56px" }} aria-label="Default select example" required >
                                                                    <option value=""> </option>
                                                                    {rolesDisponibles &&
                                                                        rolesDisponibles.map((rol, index) => (
                                                                            <option key={rol.idRol} className="text-uppercase" value={rol.idRol}>{rol.nombreRol}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                <label className="font-acumin-variable" htmlFor="permisos">{inputText}</label>
                                                            </div>
                                                            {/*isGivePermissions se manda como prop */}
                                                            {isGivePermissions &&
                                                                <>
                                                                    {hasBothPermissions &&
                                                                        <span className="text-danger font-acumin-variable mt-1">Los permisos ya han sido otorgados</span>
                                                                    }
                                                                </>
                                                            }
                                                            {/* isRevokePermissions se manda como prop */}
                                                            {isRevokePermissions &&
                                                                <>
                                                                    {hasBothPermissions &&
                                                                        <span className="text-danger font-acumin-variable mt-1">No se han otorgado permisos</span>
                                                                    }
                                                                </>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="col-10 d-flex align-items-center">
                                                        <button className="btn background-royal-blue text-white bg-hover-gold font-acumin-variable" type="submit" >Confirmar</button>
                                                    </div>
                                                </div>
                                            </form>

                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}