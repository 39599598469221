import { instance } from '../../apiGlobal';

export async function traerAvisosCobroConceptosAutomaticos() {
  try {
    const response = await instance.get(`/tarificacion/mostrar/avisoscobro/p/revisar`, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    return response;
  } catch (error) {
    return error
  }
}

