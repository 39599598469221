import { useMemo } from "react";

export default function useFondosNoConciliadosPDP() {
  const columns = useMemo(
    () => [
      {
        Header: "Folio BCH-TR",
        accessor: "folio"
      },
      {
        Header: "Código ISIN",
        accessor: "isin"
      },
      {
        Header: "Tipo pago",
        accessor: "tipoDePago"
      },
      {
        Header: "Fecha de pago",
        accessor: "fechaDePago"
      },
      {
        Header: "Monto",
        accessor: "monto"
      },
      {
        Header: "Moneda",
        accessor: "moneda"
      },
      
    ],
    []
  );

  return columns;
}
