import { instance } from '../apiGlobal';

export async function traerInformacionAllDepositante(obj) {
  try {
    const response = await instance.get(`/regdp/all/depositante?estatus=${obj}`, {
      headers: {
        'Content-Type': 'application/json'
        
      }
    })
   
 
    return response.data
  } catch (error) {

    return error
  }
}
  