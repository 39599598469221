import React from "react";
import '../../styles/stylesheet.css'
import { FormContext } from "./form_context";

export default function RegistroDI2(props) {
    const [, setValue] = React.useContext(FormContext);
    
    React.useEffect(()=>{
        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 0};
            return { ...prev, datos };
        });

    },[])

    return (
        <>
            <div className="card-body">
                <h5 className="textoTituloFormulario">II. Datos laborales del inversionista</h5>

                <div className="row mb-">
                    <div className="col-md-6">
                        <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Nombre de la empresa, institución o actividad propia</label>
                        <input disabled value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatEmpresaLab} type="text" className="form-control  " id="empresa" name="empresa" required />
                    </div>
                    <div className="col-md-6 "  >
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto" >Puesto que desempeña</label>
                        <input disabled value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatCatPuestoId.nombre} type="text" className="form-control  " id="puesto" name="puesto" required />
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-6 "  >
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto" >Tiempo de laborar</label>
                        <input disabled value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatCatTieLabId.nombre} type="text" className="form-control  " required />
                    </div>



                    <div className="col-md-6">
                        <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Teléfono</label>
                        <input disabled value={props.info.cehvalDepositantePersonaNatId.cehvalPersonaNatTelOfi} type="text" className="form-control  " id="telefonoLaboral" name="telefonoLaboral" required />
                    </div>
                </div>
            </div>

        </>
    );
}

