//React
import React, { useState, useEffect } from "react";


//Componentes generales
import BannerTitulo from "../../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores';
import TablaConceptosCobroAutomaticos from "../../../../ComponentesGenerales/TablaConceptosCobroAutomaticos";

import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import PantallaCargando from "../../../../ComponentesGenerales/PantallaCargando";

//APIs
import { traerAvisoCobroPorID } from "../../../../api/tarificacion/ConceptosAutomaticos/traerAvisoCobroPorID";
import { traerAvisoPendienteAutorizar } from "../../../../api/tarificacion/ConceptosAutomaticos/AvisosPendientesAutorizar/traerAvisoPendienteAutorizar";
import { confirmarAvisoCobroAut } from "../../../../api/tarificacion/ConceptosAutomaticos/confirmarAvisoCobroAut";

//Hooks
import useConceptosCobroAutomaticos from "../../../../hooks/Tarificacion/ConceptosAutomaticos/useConceptosCobroAutomaticos";


export default function AvisoCobroAutSeleccionado({ history }) {

    //Save loaded data
    const [conceptos, setConceptos] = useState();

    //Objeto del aviso de cobro
    const [objAvisoCobro, setObjAvisoCobro] = useState();

    //Id del aviso. Se ocupa para autorizar el aviso o rechazarlo
    

    //Datos del participante. Para pintar datos en front
    const [nombreParticipante, setNombreDepositante] = useState();
    const [codigoParticipante, setCodigoParticipante] = useState();
    const [tipoParticipante, setTipoParticipante] = useState();
    const [periodo, setPeriodo] = useState();

    //Responsable pago
    const [nombreResponsablePago, setNombreResponsablePago] = useState();
    const [codigoResponsablePago, setCodigoResponsablePago] = useState();

    //Tipo cambio
    const [tipoCambioStr, setTipoCambioStr] = useState();

    const [bannerTitle, setBannerTitle] = useState();

    //Status del aviso de cobro
   
    const [statusAvisoStr, setStatusAvisoStr] = useState();

    //Monto total del aviso de cobro
    const [montoTotalStr, setMontoTotalStr] = useState();


    const [isSinRevisar, SetIsSinRevisar] = useState();


    //Spinner
    const [spin, setSpin] = useState(true);
    const [loading, setLoading] = useState();

    //Autorizar aviso
    const [autorizar, setAutorizar] = useState();
    //Rechazar aviso
    //const [reject, setReject] = useState();

    //States to handle load data when loading page 
    const [erroreshttp, setErroreshttp] = useState();

    //Shows modal if the session has expired
    //const [sessionExpired, setSessionExpired] = useState();
    //const [failLoadData, setFailLoadData] = useState();



    useEffect(() => {

        if (history.location.state === undefined) {
            window.location.replace("/revisarAvisosCobroAutomaticos");
        } else {
            //Veo el estatus que tiene el aviso 
            const statusAviso = history.location.state.rowInfo.estatus;

            //Agarro el ID del aviso para pasarselo a los endpoints y me traiga el objeto completo de ese aviso
            const idAviso = history.location.state.rowInfo.idAviso;

            //Los avisos con estatus 2 están pendientes de autorizar
            if (statusAviso === 2) {    
                //console.log(history.location.state)
                setBannerTitle("Factura confirmada");
                getAvisoPendienteAutorizar(idAviso);
                
            //Todos los que no sean estatus 2 son avisos que apenas va a confirmar el operador CEHVAL
            } else {
                //console.log(history.location.state)
                setBannerTitle("Revisar factura de conceptos tarificables automáticos");
                
                getAllConcepts(idAviso);
            }
        }

    }, []);


    //Trae todos los avisos que apenas va a confirmar el operador CEHVAL
    const getAllConcepts = async (idAviso) => {

        try {

            const response = await traerAvisoCobroPorID({ idAviso: idAviso })
            //console.log(response)

            if (isNaN(response.error) === false) {
                setErroreshttp(response);
                setSpin(false);


            } else if (response.data) {
                setObjAvisoCobro(response.data);
                //Datos participante
                setCodigoParticipante(response.data.datosParticipante.codigoDepositante);
                setNombreDepositante(response.data.datosParticipante.nombreDepositante);
                setTipoParticipante(response.data.tipoParticipanteStr);

                //Datos responsable pago
                setNombreResponsablePago(response.data.nombreResponsablePago);
                setCodigoResponsablePago(response.data.codigoResponsablePago);

                //Datos aviso
                
                setStatusAvisoStr(response.data.estatusStr);
                setMontoTotalStr(response.data.montoTotalResumenStr);
                setPeriodo(response.data.mesCobro);

                //Tipo cambio
                
                setTipoCambioStr(response.data.tipoCambioStr);

                //Conceptos la factura
                setConceptos(response.data.conceptos);
                //Valida el estatus del aviso, si el aviso tiene estatus 1 muestra el boton para confirmar el aviso
                if (response.data.estatus === 1) {
                    SetIsSinRevisar(true)
                } else {
                    SetIsSinRevisar(false);
                }
                setSpin(false);
            }

        } catch (err) {
            console.error(err);
        }
    }

    
    //Muestra un aviso que ya haya sido confirmado y este pendiente por autorizar
    const getAvisoPendienteAutorizar = async (idAviso) => {
        try {

            const response = await traerAvisoPendienteAutorizar({ idAviso: idAviso })
            //console.log(response)

            if (isNaN(response.error) === false) {
                setErroreshttp(response);
                setSpin(false);


            } else if (response.data) {
                setObjAvisoCobro(response.data);
                //Datos participante
                setCodigoParticipante(response.data.datosParticipante.codigoDepositante);
                setNombreDepositante(response.data.datosParticipante.nombreDepositante);
                setTipoParticipante(response.data.tipoParticipanteStr);

                //Datos responsable pago
                setNombreResponsablePago(response.data.nombreResponsablePago);
                setCodigoResponsablePago(response.data.codigoResponsablePago);

                //Tipo cambio
                
                setTipoCambioStr(response.data.tipoCambioStr);

                //Datos de la factura
                setPeriodo(response.data.mesCobro);
                
                setStatusAvisoStr(response.data.estatusStr);

                //Total a cobrar
                setMontoTotalStr(response.data.montoTotalResumenStr);

                //Conceptos
                setConceptos(response.data.conceptos);

                if (response.data.estatus === 1) {
                    SetIsSinRevisar(true)
                } else {
                    SetIsSinRevisar(false);
                }
                setSpin(false);
            }

        } catch (err) {
            console.error(err);
        }

    }




    //Manda la petición para confirmar el aviso
    const confirmAviso = async (e) => {
        try {
            e.preventDefault();
            //console.log(objAvisoCobro)
            setLoading(true);
            const response = await confirmarAvisoCobroAut(objAvisoCobro);
            setLoading(false);

            //console.log(response)

            if (isNaN(response.error) === false) {
                setErroreshttp(response);

            } else if (response.data.respuesta.isSuccessful) {
                setAutorizar(response.data.respuesta.mensaje);

            }
        } catch (error) {
            console.error(error);
        }
    }



    const goBack = () => {
        history.push({
            pathname: '/revisarAvisosCobroAutomaticos',
        });
    }


    return (
        <>
            {loading &&
                <PantallaCargando />
            }
            {autorizar &&
                <ComponenteModal mostrarModal={autorizar} cerrarModal={goBack} titulo={autorizar} textoBoton="Aceptar"></ComponenteModal>
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
            <div className="">
                <main className="min-height-40">
                    <div className="row row-wrap g-0">

                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo={bannerTitle}></BannerTitulo>

                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex mt-5">
                                        <div className="container-fluid container-md-container container-lg-none container-xl-none container-xxl-none px-3">
                                            {objAvisoCobro &&
                                                <>
                                                    <div className="mb-5">
                                                        <section className="row  ps-3">
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-medium">Nombre del participante</h5>
                                                                <p className="font-acumin-variable text-muted">{nombreParticipante}</p>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-medium">Código del participante</h5>
                                                                <p className="font-acumin-variable text-muted">{codigoParticipante}</p>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-medium">Tipo de participante</h5>
                                                                <p className="font-acumin-variable text-muted">{tipoParticipante}</p>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-medium">Estatus de la factura</h5>
                                                                <p className="font-acumin-variable text-muted">{statusAvisoStr}</p>
                                                            </div>
                                                            
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-medium">Nombre del responsable de pago </h5>
                                                                <p className="font-acumin-variable text-muted">{nombreResponsablePago}</p>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-medium">Código del responsable de pago </h5>
                                                                <p className="font-acumin-variable text-muted">{codigoResponsablePago}</p>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-medium">Periodo</h5>
                                                                <p className="font-acumin-variable text-muted">{periodo}</p>
                                                            </div>                                                         
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-medium">Tipo de cambio:</h5>
                                                                <p className="font-acumin-variable text-muted"> HNL {tipoCambioStr}</p>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-3 col-xl-3 mb-3">
                                                                <h5 className="font-acumin-variable text-royal-blue fw-medium">Total a cobrar &#40;HNL&#41;:</h5>
                                                                <p className="font-acumin-variable text-muted"> HNL {montoTotalStr}</p>
                                                            </div>
                                                        </section>
                                                    </div>

                                                    <TablaConceptosCobroAutomaticos datos={conceptos} useValidate={useConceptosCobroAutomaticos} textoBoton={"Abrir solicitud"} textTitle="Click para ver la información" />

                                                    <div className="col-12 col-md-12 d-flex justify-content-end mb-3 px-3 mt-5">
                                                        <div className="flex-fill justify-content-start">
                                                            <button type="button" onClick={goBack} className="btn ms-2 background-royal-blue text-white bg-hover-gold">Regresar</button>
                                                        </div>
                                                        {isSinRevisar &&
                                                            <button type="button" onClick={confirmAviso} className="btn ms-2 background-royal-blue text-white bg-hover-gold">Confirmar</button>
                                                        }
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}





