import React from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import TablaUsuarios from "../../../ComponentesGenerales/TablaUsuarios";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";

//APIs
import { traerSolicitudesDesmaterializacion } from "../../../api/desmaterializar/traerSolicitudesDesmaterializacion";

//Hooks
import useDesmaterializaciones from "../../../hooks/Desmaterializacion/useDesmaterializaciones";

export default function AutorizarDesmaterializacion({ history }) {
    const estado=1;
    const estatus = {
        estado:1
    };


    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()
    const [spin, setSpin] = React.useState(true)
    const [erroreshttp, setErroreshttp] = React.useState()


    React.useEffect(() => {
        traerSolicitudes()
    }, [])

    const traerSolicitudes = async () => {
        const solicitudes = await traerSolicitudesDesmaterializacion(estatus)
        if (solicitudes) {
            setSpin(false)
            if ((isNaN(solicitudes.error)) === false) {
                setErroreshttp(solicitudes)
            } else {
                setData(solicitudes)
                setCopia(solicitudes)
            }
        }
    }


    const continuarOferta = (row) => {
        //console.log(row.original)

      
            const objetoIndirecto = {
                  titulo: "Autorizar solicitud de desmaterialización",
                  codigoEmisor:row.original.solicitudDesmaterializacionCodigoEmisor.cehvalEmisorCodigo,
                  nombreEmisor:row.original.solicitudDesmaterializacionCodigoEmisor.cehvalEmisorNombreCom,
                  codigoEmision:row.original.solicitudDesmaterializacionCodigoEmision.cehvalEmisionCodigo,
                  nombreEmision:row.original.solicitudDesmaterializacionCodigoEmision.cehvalEmisionNomEmi,
                  fechaDoc1:row.original.solicitudDesmaterealizacionFechaDoc1Cadena,
                  fechaDoc2:row.original.solicitudDesmaterializacionFechaDoc2Cadena,
                  lugarRegistro:row.original.solicitudDesmaterializacionLugarReg,
                  nombreDoc1:row.original.solicitudDesmaterializacionDoc1,
                  nombreDoc2:row.original.solicitudDesmaterializacionDoc2,
                  idSolicitud:row.original.solicitudDesmaterealizacionId,
                  estatus: estado,
            }

            //console.log(objetoIndirecto)

            //mandar info depositante indirecto            
           history.push({
               pathname: '/verInfoDesmaterializacion', state: { objMenu: objetoIndirecto }
         })
        
    }


    return (
        <>

            {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
            <div className="fluid-container">
                
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                    <BannerTitulo titulo="Solicitudes de desmaterialización por autorizar"></BannerTitulo>

                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                            
                                              
                                                        <TablaUsuarios datos={data} displayInformation={continuarOferta} useValidate={useDesmaterializaciones} textoBoton={"Abrir"} textTitle="Click para ver la información de la solicitud"></TablaUsuarios>
                                                        
                                            
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    );
}













