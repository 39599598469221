import React from "react";
import '../styles/stylesheet.css'


export default function SpinnerSend({textoSpinner}) {

    return (
        <div className="d-flex  ">
            <div className="container  ">
                <div class="text-center">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <p className="font-acumin-variable">{textoSpinner}</p>
                </div>
            </div>
        </div>
    );
}
