import { useMemo } from "react";

export default function useCuentasValoresCehval() {
  const columns = useMemo(
    () => [
      {
        Header: "Código del propietario de los valores",
        accessor: "codigoDepositantePropietario"
      },
      {
        Header: "Nombre del propietario de los valores",
        accessor: "nombreDepositantePropietario"
      },
      {
        Header: "ISIN",
        accessor: "isinSerie"
      },
      {
        Header: "Código de emisión",
        accessor: "codigoEmision"
      },
      {
        Header: "Nombre de la emisión",
        accessor: "nombreEmision"
      },
      {
        Header: "Código de la serie",
        accessor: "codigoSerie"
      },
      {
        Header: "Nombre de la serie",
        accessor: "nombreSerie"
      },
      {
        Header: "Moneda",
        accessor: "monedaSerie"
      },
      {
        Header: "Cantidad",
        accessor: "cantidadValores"
      },
    ],
    []
  );

  return columns;
}
