//React
import React from "react";

import ComponenteMenuEmsoresEmisionesSeries from "../../../ComponentesGenerales/ComponenteMenuEmsoresEmisionesSeries";

export default function MenuSeriesEmisionesrRechazadas({ history }) {


    return (
        <ComponenteMenuEmsoresEmisionesSeries titulo="Emisores, emisiones y series rechazadas" ruta1="/emisoresRechazados" ruta2="/emisionesRechazadas" ruta3="/seriesRechazadas" ></ComponenteMenuEmsoresEmisionesSeries>
      
    );
}

