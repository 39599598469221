import { instance } from '../apiGlobal';

export async function insertarPuesto(obj) {
  try {
    const response = await instance(`/cat/insertar/puesto`, {
      method:"post",
      data:obj
    })
    return response.data
  } catch (error) {
    return error
  }
}