import { useMemo } from "react";

export default function useCuentaValores() {
 const columns = useMemo(
   () => [
    {
      Header:"Código propietario",
      accessor: "codigoDepositante"
    },
    {
      Header:"Nombre propietario",
      accessor: "nombreDepositante"
    },
     {
       Header:"Nombre de la emisión",
       accessor: "nombreEmision"
     },
     {
       Header: "Serie",
       accessor: "nombreSerie"
     },
     {
       Header: "Moneda",
       accessor: "monedaSerie"
     },
     /*{
       Header: "Monto de la operación",
       accessor: "montoOperacion"
     },*/
     {
      Header: "ISIN",
      accessor: "isinSerie"
    },
    {
      Header: "Cantidad",
      accessor: "cantidadValores"
    },
    {
      Header: "Representación",
      accessor: "desmaterializados"
    }

   ],
   []
 );

 return columns;
}
