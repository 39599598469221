import React from "react";
import '../../../../styles/stylesheet.css'
import { FormContext } from './form_context';

import { traerNacionalidad } from "../../../../api/catalogos/traerNacionalidad";
import { traerEstadosCiviles } from "../../../../api/catalogos/traerEstadosCiviles";
import { traerGeneros } from "../../../../api/catalogos/traerGeneros";
import { traerTiemposLaborales } from "../../../../api/catalogos/traerTiemposLaborales";
import { traerTiposIdentificacion } from "../../../../api/catalogos/traerTiposIdentificacion";
import { traerPuestos } from "../../../../api/catalogos/traerPuestos";

import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";
import SpinnerSend from "../../../../ComponentesGenerales/SpinnerSend";

import { insertarPuesto } from "../../../../api/InsertarACatalogos/insertarPuesto";

export default function RegistroDI2({ history }) {
    const [value, setValue] = React.useContext(FormContext);

    const [nacionalidad, setNacionalidad] = React.useState([])
    const [estadosCiviles, setEstadosCiviles] = React.useState([])
    const [generos, setGeneros] = React.useState([])
    const [tiposIdentificacion, setTiposIdentificacion] = React.useState([])
    const [tiemposLaborales, setTiemposLaborales] = React.useState([])
    const [puestos, setPuestos] = React.useState([])
    const [agregarPuesto, setAgregarPuestos] = React.useState()
    const [puestoNuevo, setPuestoNuevo] = React.useState()
    const [fechaLimiteNacimiento, setFechaLimiteNacimiento] = React.useState()
    const [fechaLimiteDocumentos, setFechaLimiteDocumentos] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [spinSend, setSpinSend] = React.useState(false)

    const guardarDatos = (e) => {


        const { name, value } = e.target;

        if (name === "puesto") {
            if (value === "OTRO") {
                setAgregarPuestos(true)
            } else {
                setValue((prev) => {
                    const datos = { ...prev.datos, [name]: value };
                    return { ...prev, datos };
                });
                setAgregarPuestos(false)
            }

        } else {
            setValue((prev) => {
                const datos = { ...prev.datos, [name]: value };
                return { ...prev, datos };
            });
        }
    }

    React.useEffect(() => {

        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 0 };
            return { ...prev, datos };
        });


        establecerFecha()
        traerDatos()
    }, [])

    const establecerFecha = () => {
        const fecha = new Date()
        const añoActual = fecha.getFullYear();
        let diaActual = fecha.getDate();
        let mesActual = fecha.getMonth() + 1;

        if (diaActual < 10) {
            diaActual = "0" + diaActual
        }

        if (mesActual < 10) {
            mesActual = "0" + mesActual
        }

        const fecha_limite_cumpleaños = (añoActual - 18) + "-" + mesActual + "-" + diaActual
        const fecha_limite_documentos = (añoActual) + "-" + mesActual + "-" + (diaActual)

        setFechaLimiteNacimiento(fecha_limite_cumpleaños)
        setFechaLimiteDocumentos(fecha_limite_documentos)
    }



    const traerDatos = async () => {
        const catalogoEstadosCiviles = await traerEstadosCiviles()
        if (catalogoEstadosCiviles) {
            //setSpin(false)
            if ((isNaN(catalogoEstadosCiviles.error)) === false) {
              
                setErroreshttp(catalogoEstadosCiviles)
                
            } else {
                // //console.log(traerInformacion)
                setEstadosCiviles(catalogoEstadosCiviles)
            }
        }

        const catalogoNacionalidad = await traerNacionalidad()
        if (catalogoNacionalidad) {
            //setSpin(false)
            if ((isNaN(catalogoNacionalidad.error)) === false) {
              
                setErroreshttp(catalogoNacionalidad)
                
            } else {
                setNacionalidad(catalogoNacionalidad)
            }
        }

        const catalogoTiemposLaborales = await traerTiemposLaborales()
        if (catalogoTiemposLaborales) {
            //setSpin(false)
            if ((isNaN(catalogoTiemposLaborales.error)) === false) {
              
                setErroreshttp(catalogoTiemposLaborales)
                
            } else {
                setTiemposLaborales(catalogoTiemposLaborales)
            }
        }

        const catalogoTiposIdentificacion = await traerTiposIdentificacion()
        if (catalogoTiposIdentificacion) {
            //setSpin(false)
            if ((isNaN(catalogoTiposIdentificacion.error)) === false) {
              
                setErroreshttp(catalogoTiposIdentificacion)
                
            } else {
                setTiposIdentificacion(catalogoTiposIdentificacion)
            }
        }

        const catalogoPuestos = await traerPuestos()
        if (catalogoPuestos) {
            if ((isNaN(catalogoPuestos.error)) === false) {
              
                setErroreshttp(catalogoPuestos)
                
            } else {
                setPuestos(catalogoPuestos)
            }
        }

        const catalogoGeneros = await traerGeneros()
        if (catalogoGeneros) {
            //setSpin(false)
            if ((isNaN(catalogoGeneros.error)) === false) {
              
                setErroreshttp(catalogoGeneros)
                
            } else {
                setGeneros(catalogoGeneros)
            }
        }



    }



    const checarNumeros = (e) => {
        const { name, value } = e.target;
        let out = '';
        let filtro
        if(name==="rtnRepresentante"){
            filtro = '1234567890';//Caracteres validos para rtn
        }else if(name==="numeroIdentificacion"){
            filtro = '1234567890-';//Caracteres validos numero de identificacion
        }else{
            filtro = '1234567890-+';//Caracteres validos para telefonos  
        }

 
        //Recorrer el texto y verificar si el caracter se encuentra en la lista de validos 
        for (let i = 0; i < value.length; i++)
            if (filtro.indexOf(value.charAt(i)) !== -1)
                //Se añaden a la salida los caracteres validos
                out += value.charAt(i);

        setValue((prev) => {
            const datos = { ...prev.datos, [name]: out };
            return { ...prev, datos };
        });
    }

    const otroPuesto = async () => {
        const obj = {
            nombre: puestoNuevo
        }
        const agregarNuevoPuesto = await insertarPuesto(obj)

        if (agregarNuevoPuesto) {
            setSpinSend(false)
            if ((isNaN(agregarNuevoPuesto.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(agregarNuevoPuesto)
            } else {


                if (agregarNuevoPuesto?.codigo === "000") {

                    //setActividadEconomica(await TraerActividadEconomica())
                    const catalogoPuestos = await traerPuestos()
                    if (catalogoPuestos) {
                        if ((isNaN(catalogoPuestos.error)) === false) {
                            // respuestaincorrecta           
                            setErroreshttp(catalogoPuestos)
                        } else {
                            setPuestos(catalogoPuestos)
                            setAgregarPuestos(false)

                            const myObj = {
                                target: {
                                    value: (puestos[puestos.length - 1].id) + 1,
                                    name: "puesto"
                                }
                            }
                            guardarDatos(myObj)
                        }
                    }


                }
            }
        }

    }

    return (
        <>
            <div className="card-body pt-3" >
                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }     <h5 className="text-royal-blue font-acumin-variable " >II. Datos del representante legal</h5>

                <div className="row mb-3">
                    <div className="col-md-3 mb-3">
                        <div className="form-floating">
                            < input placeholder="" value={value.datos.primerNombre} onChange={(e) => guardarDatos(e)} id="primerNombre" name="primerNombre" type="text" className="form-control border" required />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Primer nombre *</label>
                        </div>
                    </div>

                    <div className="col-md-3 form-floating mb-3">
                        <div className="form-floating">
                            < input placeholder="" value={value.datos.segundoNombre} onChange={(e) => guardarDatos(e)} id="segundoNombre" name="segundoNombre" type="text" className="form-control" />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Segundo nombre</label>
                        </div>

                    </div>

                    <div className="col-md-3 form-floating mb-3">
                        <div className="form-floating">
                            < input placeholder="" value={value.datos.primerApellido} onChange={(e) => guardarDatos(e)} id="primerApellido" name="primerApellido" type="text" className="form-control border  r" required />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Primer apellido *</label>
                        </div>
                    </div>

                    <div className="col-md-3 form-floating mb-3">
                        <div className="form-floating">
                            < input placeholder="" type="text" className="form-control" value={value.datos.segundoApellido} onChange={(e) => guardarDatos(e)} id="segundoApellido" name="segundoApellido" />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Segundo apellido</label>
                        </div>

                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating">
                            < input placeholder="" type="text" className="form-control" value={value.datos.apellidoCasada} onChange={(e) => guardarDatos(e)} id="apellidoCasada" name="apellidoCasada" />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Apellido de casada (si aplica)</label>
                        </div>
                    </div>

                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating">
                            <select className="form-select border" value={value.datos.tipoIdentificacion} onChange={(e) => guardarDatos(e)} id="tipoIdentificacion" name="tipoIdentificacion" required>
                                <option value=""> </option>
                                {
                                    tiposIdentificacion.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                  
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Tipo de identificación *</label>
                        </div>
                    </div>

                    <div className="col-md-4 form-floating">
                        <div className="form-floating">
                            < input placeholder="" type="text" className="form-control border" maxLength={22} value={value.datos.numeroIdentificacion} onChange={(e) => checarNumeros(e)} id="numeroIdentificacion" name="numeroIdentificacion" required />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Número de identificación *</label>
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating">
                            < input placeholder="" type="text" className="form-control" maxLength={14} value={value.datos.rtnRepresentante}  onChange={(e) => checarNumeros(e)} id="rtnRepresentante" name="rtnRepresentante" />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">RTN numérico </label>
                            <div id="emailHelp" class="emailHelp ps-1 form-text">Solo números</div>
                        </div>
                    </div>

                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating">
                            <select className="form-select border " value={value.datos.estadoCivil} onChange={(e) => guardarDatos(e)} id="estadoCivil" name="estadoCivil" required>
                                <option value=""> </option>
                                {
                                    estadosCiviles.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                      
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Estado civil *</label>
                        </div>
                    </div>

                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating">
                            <select className="form-select border  r" value={value.datos.genero} onChange={(e) => guardarDatos(e)} id="genero" name="genero" required>
                                <option value=""> </option>
                                {
                                    generos.map((item, index) => (

                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                                {/*<option value={true}>FEMENINO</option>
                                    <option value={false}>MASCULINO</option>*/}
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Género *</label>
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating">
                            <select className="form-select border  r" value={value.datos.nacionalidad} onChange={(e) => guardarDatos(e)} id="nacionalidad" name="nacionalidad" required>
                                <option value=""> </option>
                                {
                                    nacionalidad.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                                {/*<option value={1}>1. COSTARRICENCE</option>
                                        <option value={2}>2. ESTADOUNIDENSE</option>
                                        <option value={3}>3. GUATEMALTECA</option>
                                        <option value={4}>4. HONDUREÑA</option>
                                        <option value={5}>5. NICARAGUENSE</option>
                                        <option value={6}>6. PANAMEÑA</option>
                                    <option value={7}>7. SALVADOREÑA</option>*/}
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Nacionalidad *</label>
                        </div>
                    </div>

                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating">
                            < input placeholder="" max={fechaLimiteNacimiento} type="date" className="form-control border  r" value={value.datos.fechaNacimiento} onChange={(e) => guardarDatos(e)} id="fechaNacimiento" name="fechaNacimiento" required />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Fecha de nacimiento *</label>
                        </div>

                    </div>

                    <div className="col-md-4 form-floating">
                        <div className="form-floating">
                            < input placeholder="" type="text" className="form-control border  r" value={value.datos.lugarNacimiento} onChange={(e) => guardarDatos(e)} id="lugarNacimiento" name="lugarNacimiento" required />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Lugar de nacimiento *</label>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating">
                            <select className="form-select border" value={value.datos.puesto} onChange={(e) => guardarDatos(e)} id="puesto" name="puesto" required>
                                <option value=""> </option>
                                {
                                    puestos.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                                <option  >OTRO</option>
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Puesto que desempeña *</label>
                        </div>
                    </div>
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating">
                            <select className="form-select border  r" value={value.datos.tiempoPuesto} onChange={(e) => guardarDatos(e)} id="tiempoPuesto" name="tiempoPuesto" required>
                                <option value=""> </option>
                                {
                                    tiemposLaborales.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                                {/*<option value={1}>MENOS DE 1 AÑO</option>
                                    <option value={2}>1 A 3 AÑOS</option>
                                    <option value={3}>4 A 6 AÑOS</option>
                                    <option value={4}>6 A 10 AÑOS</option>
                                <option value={5}>MÁS DE 10 AÑOS</option>*/}
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Tiempo de laborar *</label>
                        </div>

                    </div>

                    <div className="col-md-4 form-floating">
                        <div className="form-floating">
                            < input placeholder="" type="text" maxLength={15} className="form-control border  r" value={value.datos.telefono} onChange={(e) => checarNumeros(e)} id="telefono" name="telefono" required />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Teléfono *</label>
                        </div>

                    </div>
                </div>

                {agregarPuesto &&

                    <>
                        {spinSend ? <div className="row mb-3 d-flex ">
                            <div className="col-md-4 text-center">
                                <SpinnerSend textoSpinner="Enviando..."></SpinnerSend>
                            </div>
                        </div> :
                            <div className="row mb-3">
                                <div className="col-md-3 form-floating">
                                    <div className="form-floating">
                                        < input placeholder="" type="text" className="form-control border  r" onChange={(e) => setPuestoNuevo(e.target.value)} required />
                                        <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Ingrese puesto que desempeña *</label>
                                    </div>
                                </div>

                                <div className="col-md-1 d-flex align-items-end">
                                    <button type="button" onClick={() => {
                                        setSpinSend(true)
                                        otroPuesto()
                                    }} className="btn botonFormularioDepositante" >Ingresar</button>
                                </div>
                            </div>}
                    </>
                }





                <div className="row mb-4">
                    <div className="col-md-4 form-floating">
                        <div className="form-floating mb-3">
                            < input style={{ textTransform: "lowercase" }} placeholder="" type="email" className="form-control border  r" value={value.datos.correoLab} onChange={(e) => guardarDatos(e)} id="correoLab" name="correoLab" required />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto" >Correo electrónico laboral *</label>
                        </div>

                    </div>

                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating">
                            < input placeholder="" type="date" max={fechaLimiteDocumentos} className="form-control" value={value.datos.fechaRepr} onChange={(e) => guardarDatos(e)} id="fechaRepr" name="fechaRepr" required />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Fecha de representación *</label>
                        </div>

                    </div>

                    <div className="col-md-4 ">
                        <div className="form-floating">
                            < input placeholder="" type="text" className="form-control" maxLength={15} value={value.datos.telefonoCelular} onChange={(e) => checarNumeros(e)} id="telefonoCelular" name="telefonoCelular" required />
                            <label htmlFor="validationCustom01" className="form-label textoFormularioDepositantedirecto">Teléfono celular *</label>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

