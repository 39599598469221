
import React, { useContext } from "react";
import { Link} from "react-router-dom";
//react context
import { UserContext } from '../UserContext';

export default function SubComponenteHeader( {subgrupo, titulo, funcion}) {
    const context = useContext(UserContext);
    const { autorizacion } = context;


    return (
        <>
            {autorizacion.filter(item => item.subGrupo === subgrupo).length > 0 &&
                <div className="py-1 ps-2 cehval-menu-option bg-hover-gold-light">
                    <li className="">
                        <div className="text-decoration-none">
                            <div className="row align-items-center no-close">
                                <div className="col-10 text-royal-blue cehval-menu-option-text">
                                    {titulo}
                                </div>
                                <div className="col-2 row text-royal-blue align-items-center cehval-menu-option-text">
                                    <i className="ri-arrow-right-s-line "></i>
                                </div>
                            </div>
                        </div>

                        <div className="submenu-dropdown border shadow">
                            <div className="container m-0 p-0 px-3 no-close">
                                <div className="row py-1 g-0 no-close">
                                    <div className="col-lg-12 border-bottom no-close">
                                        <span className="text-royal-blue font-acumin-variable no-close">{titulo}</span>
                                    </div>
                                </div>
                                <div className="row align-items-top mt-3">
                                    <div className="col-lg-12">
                                        <div className="row ">
                                            {autorizacion.filter(item => item.subGrupo === subgrupo).map((item, index) => (
                                                <Link key={index} to={item.url} onClick={funcion} className="col-12 text-decoration-none font-acumin-variable text-gray text-hover-gray bg-hover-gray-light py-1">{item.label}</Link>
                                            ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </div>
            }

        </>
    )
}