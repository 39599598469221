import { instance } from '../apiGlobal';

export async function consultarEmisor(obj) {
  
  try {
    const response = await instance(`/emisor/consulta/codigo`, {
      method: "post",
      data: obj
    })
  
    return response;
  } catch (error) {

    return error;
  }
}





