import { instance } from '../../apiGlobal';

export async function reporteAutorizadoDIPJ(obj) {
    try {
        const response = await instance(`/reporte/reporteDipjAuth`, {
            method: "post",
            data: obj,
            responseType: 'blob',
         
        })
        return response.data
    } catch (error) {
        return error
    }
}