import React, { useState } from "react";


//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";

import TablaMercado from "../../../ComponentesGenerales/TablaMercado";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//Hooks
import useBusquedaPagos from "../../../hooks/PagoDerechosPatrimoniales/useBusquedaPagos";

//APIs
import { buscarPagosSeries } from "../../../api/PagoInteresesAmorDiv/buscarPagosSeries";

export default function BuscarPagosPatrimoniales({ history }) {
    const [erroreshttp, setErroreshttp] = useState();
    //Errors and messages
    //const [procesoExitoso, setProcesoExitoso] = useState();
    const [procesoFallido, setProcesoFallido] = useState();
    const [mensaje, setMensaje] = useState();
    
    const [isin, setIsin] = React.useState()
    const [fechaInicio, setFechaInicio] = React.useState()
    const [fechaFin, setFechaFin] = React.useState()
    const [fecha_Fin, setFecha_Fin] = React.useState()
    const [data, setData] = React.useState()
    const [loading, setLoading] = React.useState(false);

    const onSubmitForm = async (e) => {
        e.preventDefault();
        setLoading(true);

        const myObj = {
            isin: isin,
            fechaInicio: fechaInicio,
            fechaFin: fechaFin
        }


        const response = await buscarPagosSeries(myObj);
        //console.log(response)
        setLoading(false);
        if (response) {
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(response);
            } else {
                if (response.length == 0) {

                    setMensaje("No existe el pago, intente cambiando los datos de búsqueda")
                    setProcesoFallido(true)
                } else {
                    setData(response)
                }
            }
        }
    }

    const establecerFechaFin = (e) => {
        var d = new Date(e);
        const fecha = sumarDias(d, 2)

        const añoActual = fecha.getFullYear();
        let diaActual = fecha.getDate();
        let mesActual = fecha.getMonth() + 1;

        //        //console.log(añoActual)
        /* if (añoActual < 1900) {
             setErrorFormatoFecha1(true)
         } else {
             setErrorFormatoFecha1(false)
         }*/

        if (diaActual < 10) {
            diaActual = "0" + diaActual;
        }

        if (mesActual < 10) {
            mesActual = "0" + mesActual;
        }

        const fecha_limite_vencimiento = (añoActual) + "-" + mesActual + "-" + (diaActual);
        setFecha_Fin(fecha_limite_vencimiento)
    }

    const sumarDias = (fecha, dias) => {
        fecha.setDate(fecha.getDate() + dias);
        return fecha;
    }

    const cerrarModal = () => {
        //console.log("entrafondos virtuales")
        history.push({
            pathname: '/agregarFechasPagoInteresesAmortizaciones'
        })

    }

    const verDetalle = (row) => {
        //console.log(row.original)
        history.push({
            pathname: '/formularioPagosEncontrados', state: { objMenu: row.original }
        })

    }
    const regresar = () => {
        //console.log("regresar")
        setData("")
        setFechaFin()
        setFechaInicio()
        setFecha_Fin("")
        setIsin()
    }



    return (
        <>
            {loading &&
                <PantallaCargando />
            }

            


            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {/* {procesoExitoso &&
                <ComponenteModal mostrarModal={procesoExitoso} cerrarModal={cerrarModal} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            } */}

            {procesoFallido &&
                <ComponenteModal mostrarModal={procesoFallido} cerrarModal={() => setProcesoFallido(false)} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            <div className="fluid-container">{/* style={{ backgroundImage: `url(${fondo})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="fluid-container">*/}
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        {data ?
                            <div className="col-12 mb-5">
                                <div className="">
                                    <BannerTitulo titulo="Pagos"></BannerTitulo>
                                    <div className="d-flex container-max-height main-content">
                                        <div className="container py-2 main-min-height main-content">
                                            <TablaMercado datos={data} displayInformation={verDetalle} useValidate={useBusquedaPagos} textoBoton={"Ver detalle"} textTitle="Clic para ver información"></TablaMercado>
                                            <div className="col-12 row justify-content-star mt-3 ms-3">
                                                <div className="col-6 mt-1 g-0">
                                                    <button onClick={regresar} className="btn background-royal-blue bg-hover-gold text-white font-acumin-variable">Regresar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="col-12 mb-5 main-min-height">
                                <div className="">
                                    <BannerTitulo titulo="Buscar pagos"></BannerTitulo>
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            <section className="d-flex justify-content-center">
                                                <div className="emision-register-container col-12 col-sm-12 col-md-12 row justify-content-center border rounded">

                                                    <div className="col-12">
                                                        <form onSubmit={onSubmitForm} className="needs-validation font-acumin-variable text-royal-blue">
                                                            <h4 className="text-royal-blue font-acumin-variable mt-3">Serie</h4>
                                                            <div className="row mt-4">
                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input value={isin} title="ISIN" onChange={(e) => setIsin(e.target.value)} maxLength={13} className="form-control fs-5" name="ISIN" type="text" />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Codigo de serie">ISIN</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <h4 className="text-royal-blue font-acumin-variable mt-3">Rango de búsqueda</h4>
                                                            <div className="row mt-4">
                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        <input title="Fecha inicio" className="form-control fs-5" onChange={(e) => {
                                                                            setFechaInicio(e.target.value)
                                                                            establecerFechaFin(e.target.value)
                                                                        }} value={fechaInicio} name="Fecha inicio" type="date" placeholder="Fecha inicio" required />
                                                                        <label className="text-start font-acumin-variable" htmlFor="Fecha inicio">Fecha inicio *</label>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 col-md-4 mb-3">
                                                                    <div className="form-floating ">
                                                                        {fecha_Fin &&
                                                                            <>
                                                                                <input title="Fecha inicio" className="form-control fs-5" onChange={(e) => {
                                                                                    setFechaFin(e.target.value)
                                                                                }} value={fechaFin} min={fecha_Fin} name="Fecha fin" type="date" placeholder="Fecha fin" />
                                                                                <label className="text-start font-acumin-variable" htmlFor="Fecha fin">Fecha fin</label>
                                                                            </>

                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4 d-flex justify-content-end mb-3">
                                                                    <button type="submit" className="btn col-6 col-sm-6 col-md-10 col-lg-6 col-xl-6 background-royal-blue text-white bg-hover-gold">Buscar</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </main>
            </div>
        </>
    );
}

