import React, { useState } from 'react';
import Stepper from 'react-stepper-horizontal';
import { Modal } from "react-bootstrap";

//React context
import { FormProvider, FormContext } from './form_context';
import { FormProvider2, FormContext2 } from './form_context2';
import { FormProvider3, FormContext3 } from './form_context3';

//Components
import ActualizarDI1 from './ActualizarDI1'
import ActualizarDI1_5 from './ActualizarDI1_5'
import ActualizarDI2 from './ActualizarDI2'
import ActualizarDI3 from './ActualizarDI3'
import ActualizarDI4 from './ActualizarDI4'
import ActualizarDI5 from './ActualizarDI5'
import ActualizarDI6 from './ActualizarDI6'

import ActualizarUsuarios from '../ActualizarUsuarios';
import ActualizarCorreo from '../ActualizarCorreo'

import BannerTitulo from '../../../../ComponentesGenerales/Bannertitulo';
import ComponenteModal from '../../../../ComponentesGenerales/ComponenteModal';
import ManejadorErrores from '../../../../ComponentesGenerales/ManejadorErrores';
import SpinnerStart from '../../../../ComponentesGenerales/SpinnerStart';
import PantallaCargando from '../../../../ComponentesGenerales/PantallaCargando';

//APIs 
import { traerInformacionDepositnate } from '../../../../api/VerInformacionUsuarios/TraerInformacionDepositante';
import { reporteAutorizadoDIPN } from "../../../../api/Documentos/ReportesDepositantesAutorizados/reporteAutorizadoDIPN";
import { actualizarDIPN } from '../../../../api/mantenimientoCargaValores/actualizarDIPN'

const Form = ({ history }) => {
    const [spin, setSpin] = React.useState(true)
    const [value, setValue] = React.useContext(FormContext);
    const [value2, setValue2] = React.useContext(FormContext2)
    const [value3, setValue3] = React.useContext(FormContext3)
    const [erroreshttp, setErroreshttp] = React.useState()
    const [sinCambios, setSinCambios] = React.useState()
    const [sinCambiosImpresion, setSinCambiosImpresion] = React.useState()
    const [confirmacionCancelar, setConfirmacionCancelar] = React.useState()
    const [actualizacionCorrecta, setActualizacionCorrecta] = React.useState(false)
    const [actualizacionIncorrecta, setActualizacionIncorrecta] = React.useState(false)
    const [contador, setContador] = React.useState(0)
    const [condicion, setCondicion] = React.useState()
    const [loading, setLoading] = React.useState(false);
    const [actualizarUsuario, setActualizarUsuario] = React.useState()
    const [informacionUsuario, setInformnacionUsuario] = React.useState()
    const [tipoParticipante, setTipoParticipante] = React.useState()
    const [contactosOriginales, setContactosOriginales] = React.useState()
    const [contactosValidar, setContactosValidar] = React.useState()

    const [generacionUsuario, setGeneracionUsuario] = React.useState()
    const [consultaGeneracionUsusario, setConsultaGeneracionUsusario] = React.useState()

    const [modificarCorreo,setModificarCorreo]=React.useState()
    

    const [currentPage, setCurrentPage] = useState(1);
    const sections = [
        { title: 'Identificación del inversionista', onClick: () => setCurrentPage(1) },//, onClick: () => setCurrentPage(1) },
        { title: 'Información domicilio', onClick: () => setCurrentPage(2) },
        { title: 'Datos laborales del inversionista', onClick: () => setCurrentPage(3) },
        { title: 'Procedencia de los recursos', onClick: () => setCurrentPage(4) },//, onClick: () => setCurrentPage(3) },
        { title: 'Instrucciones especiales', onClick: () => setCurrentPage(5) },
        { title: 'Firmas autorizadas', onClick: () => setCurrentPage(6) },
        { title: 'Casa de bolsa intermediaria', onClick: () => setCurrentPage(7) }
    ];

    React.useEffect(() => {
        traerInfo()
    }, [])

    const traerInfo = async () => {
        const myObj = {
            folio: history.location.state.objMenu.folio,
            estatus: history.location.state.objMenu.estatus,
        }

        const traerInformacion = await traerInformacionDepositnate(myObj);
        //console.log(traerInformacion)
        if (traerInformacion) {
            if ((isNaN(traerInformacion.error)) === false) {
                setErroreshttp(traerInformacion)
            } else {
                setTipoParticipante(traerInformacion.tipoParticipante)
                guardarEnContexto(traerInformacion)
            }
        }
    }

    const guardarEnContexto = (info) => {
        let paisEscogido;
        let idPaisEscogido;
        if (info.cehvalDepositanteDireccionId.datosDireccion.catPaisPais) {
            idPaisEscogido = info.cehvalDepositanteDireccionId?.cehvalDireccionModelo?.cehvalDireccionManualPais
        } else {
            idPaisEscogido = info.cehvalDepositanteDireccionId?.catCiudadCatCiudadId?.catMunicipioCatMunicipioId?.catDepartamentoCatDepartamentoId?.catPaisCatPaisId?.id
        }

        if (info.cehvalDepositanteDireccionId.datosDireccion.catPaisPais) {
            paisEscogido = info.cehvalDepositanteDireccionId?.datosDireccion?.catPaisPais
        } else {
            paisEscogido = info.cehvalDepositanteDireccionId?.catCiudadCatCiudadId?.catMunicipioCatMunicipioId?.catDepartamentoCatDepartamentoId?.catPaisCatPaisId?.nombre
        }

        const objetos = {
            datos: {
                rtn: info.cehvalDepositantePersonaNatId.cehvalPersonaNatRtn,
                codigoDepositante: info.cehvalDepositanteCodigoCehval,
                fechaAprobacion: "",
                fechaUltimaModificacion: "",
                primerNombre: info.cehvalDepositantePersonaNatId.cehvalPersonaNatPrimerNom,
                segundoNombre: info.cehvalDepositantePersonaNatId.cehvalPersonaNatSegundoNom,
                primerApellido: info.cehvalDepositantePersonaNatId.cehvalPersonaNatPrimerApe,
                segundoApellido: info.cehvalDepositantePersonaNatId.cehvalPersonaNatSegundoApe,
                apellidoCasada: info.cehvalDepositantePersonaNatId.cehvalPersonaNatApellidoCas,
                tipoIdentidad: info.cehvalDepositantePersonaNatId.cehvalPersonaNatCatTipoIdeId.nombre,
                numeroIdentificacion: info.cehvalDepositantePersonaNatId.cehvalPersonaNatNumeroIde,
                estadoCivil: info.cehvalDepositantePersonaNatId.cehvalPersonaNatCatEstadoCivilId.nombre,
                genero: info.cehvalDepositantePersonaNatId.cehvalPersonaNatCatGeneroId.nombre,
                nacionalidad: info.cehvalDepositantePersonaNatId.cehvalPersonaNatCatNacionalidadId.nombre,
                fechaNacimiento: info.cehvalDepositantePersonaNatId.cehvalPersonaNatFechaNac,
                lugarNacimiento: info.cehvalDepositantePersonaNatId.cehvalPersonaNatLugarNac,
                actividadEconomica: info.cehvalDepositantePersonaNatId.cehvalPersonaNatCatActividadEcoId.nombre,
                telefonoResidencia: info.cehvalDepositantePersonaNatId.cehvalPersonaNatTelRes,
                telefonoCelular: info.cehvalDepositantePersonaNatId.cehvalPersonaNatTelCel,

                empresa: info.cehvalDepositantePersonaNatId.cehvalPersonaNatEmpresaLab,
                puesto: info.cehvalDepositantePersonaNatId.cehvalPersonaNatCatPuestoId.nombre,
                tiempoPuesto: info.cehvalDepositantePersonaNatId.cehvalPersonaNatCatTieLabId.nombre,
                telefonoLaboral: info.cehvalDepositantePersonaNatId.cehvalPersonaNatTelOfi,
                origenRecursos: info.cehvalDepositantePersonaNatId.cehvalPersonaNatProcedenciaRec,

                pais: paisEscogido,
                departamento: info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.catMunicipioCatMunicipioId?.catDepartamentoCatDepartamentoId.nombre,
                municipio: info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.catMunicipioCatMunicipioId?.nombre,
                ciudad: info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.nombre,
                colonia: info.cehvalDepositanteDireccionId.cehvalDireccionColonia,
                calle: info.cehvalDepositanteDireccionId.cehvalDireccionCalle,
                edificio: info.cehvalDepositanteDireccionId.cehvalDireccionNumeroCasEdi,
                codigoPostal: info.cehvalDepositanteDireccionId.cehvalDireccionCp,

                departamentoManual: info.cehvalDepositanteDireccionId.datosDireccion.catDepartamentoDepto,
                municipioManual: info.cehvalDepositanteDireccionId.datosDireccion.catMunicipioDescripcion,
                ciudadManual: info.cehvalDepositanteDireccionId.datosDireccion.catCiudadDescripcion,

                paisHonduras: info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.nombre,

                idPais: idPaisEscogido,
                idDepartamento: info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.catMunicipioCatMunicipioId?.catDepartamentoCatDepartamentoId.id,
                idMunicipio: info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.catMunicipioCatMunicipioId?.id,
                idCiudad: info.cehvalDepositanteDireccionId.catCiudadCatCiudadId.id,

                numeroCuentaN: info.cehvalCuentaBanCollection[0]?.cehvalCuentaBanNumCue,
                tipoCuentaN: info.cehvalCuentaBanCollection[0]?.catTipoCuentaCatTipCueId.catTipCueCuenta,
                bancoN: info.cehvalCuentaBanCollection[0]?.catBancoCatBancoId.catBancoNombre,
                monedaN: 1,

                numeroCuentaE: info.cehvalCuentaBanCollection[1]?.cehvalCuentaBanNumCue,
                tipoCuentaE: info.cehvalCuentaBanCollection[1]?.catTipoCuentaCatTipCueId.catTipCueCuenta,
                bancoE: info.cehvalCuentaBanCollection[1]?.catBancoCatBancoId.catBancoNombre,
                monedaE: 2,

                nombreCompleto1: info.cehvalContactoCollection[0].nombre,
                segundoNombre1: info.cehvalContactoCollection[0].segundoNombre,
                primerApellido1: info.cehvalContactoCollection[0].primerApellido,
                segundoApellido1: info.cehvalContactoCollection[0].segundoApellido,
                correo1: info.cehvalContactoCollection[0].correo,

                nombreCompleto2: info.cehvalContactoCollection[1]?.nombre,
                segundoNombre2: info.cehvalContactoCollection[1]?.segundoNombre,
                primerApellido2: info.cehvalContactoCollection[1]?.primerApellido,
                segundoApellido2: info.cehvalContactoCollection[1]?.segundoApellido,
                correo2: info.cehvalContactoCollection[1]?.correo,

                rutaS3_1: "",
                tipoFirma_1: info.cehvalFirmaAutCollection[0].tipoFirma,
                nombreCompleto_1: info.cehvalFirmaAutCollection[0].nombre,
                cargo_1: info.cehvalFirmaAutCollection[0].cargo,

                rutaS3_2: "",
                tipoFirma_2: info.cehvalFirmaAutCollection[1].tipoFirma,
                nombreCompleto_2: info.cehvalFirmaAutCollection[1].nombre,
                cargo_2: info.cehvalFirmaAutCollection[1].cargo,



                rutaS3_3: "",
                tipoFirma_3: "",
                nombreCompleto_3: "",
                cargo_3: "",

                rutaS3_4: "",
                tipoFirma_4: "",
                nombreCompleto_4: "",
                cargo_4: "",

                nombreBolsa: info.cehvalDepositantePersonaIcbiId.cehvalPersonaJurNombre,
                lugarRegistro: info.cehvalDepositanteLugarFecha,
                fechaRegistro: "",


                firmanteRepresentante: "",
                descripcionEstatus: "",



                condicionPaisMoficiacion: false,

                tipoIdentificacionInicio: true,
                estadoCivilInicio: true,
                generoInicio: true,
                nacionalidadInicio: true,
                actividadEconomicaInicio: true,
                puestoInicio: true,
                tiempoLaboralInicio: true,

                bancoNInicio: true,
                bancoEInicio: true,
                tipoCuentaNInicio: true,
                tipoCuentaEInicio: true,
                exencionImpuestosInicio: true,

                paisInicio: true,
                departamentoInicio: true,
                municipioInicio: true,
                ciudadInicio: true,
                casaBolsaInicio: true,

                tipoParticipante: info.tipoParticipante
            }
        }


        setValue(objetos)
        setValue2(objetos)
        setSpin(false)
    }

    React.useEffect(() => {
        async function registrarActualizacion() {
            //console.log("actualizacion")
            let paisParaMandar;
            if (value3.datos.pais) {
                paisParaMandar = value3.datos.pais
            } else {
                paisParaMandar = value2.datos.idPais
            }
            setLoading(true)
            const myObj = {
                actividadEconomica: value3.datos.actividadEconomica,
                apellidoCasada: value3.datos.apellidoCasada,
                casaBolsa: value3.datos.nombreBolsa,//(JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo,
                codigoDepositante: value2.datos.codigoDepositante,
                contactos: [
                    {
                        nombre: value3.datos.nombreCompleto1,
                        segundoNombre: value3.datos.segundoNombre1,
                        primerApellido: value3.datos.primerApellido1,
                        segundoApellido: value3.datos.segundoApellido1,
                        correo: value3.datos.correo1,
                        usuarioAdmin: ""
                    },
                    {
                        nombre: value3.datos.nombreCompleto2,
                        segundoNombre: value3.datos.segundoNombre2,
                        primerApellido: value3.datos.primerApellido2,
                        segundoApellido: value3.datos.segundoApellido2,
                        correo: value3.datos.correo2,
                        usuarioAdmin: ""
                    },
                ],

                cuentas: [
                    {
                        numeroCuenta: value3.datos.numeroCuentaN,
                        moneda: value3.datos.monedaN,
                        tipoCuenta: value3.datos.tipoCuentaN,
                        banco: value3.datos.bancoN
                    },
                    {
                        numeroCuenta: value3.datos.numeroCuentaE,
                        moneda: value3.datos.monedaE,
                        tipoCuenta: value3.datos.tipoCuentaE,
                        banco: value3.datos.bancoE
                    }
                ],
                domicilio: {
                    pais: paisParaMandar,
                    departamento: value3.datos.departamento,
                    municipio: value3.datos.municipio,
                    ciudad: value3.datos.ciudad,
                    colonia: value3.datos.colonia,
                    calle: value3.datos.calle,
                    numero: value3.datos.edificio,
                    cp: value3.datos.codigoPostal,
                    departamentoManual: value3.datos.departamentoManual,
                    municipioManual: value3.datos.municipioManual,
                    ciudadManual: value3.datos.ciudadManual,
                },
                empresa: value3.datos.empresa,
                estadoCivil: value3.datos.estadoCivil,
                fechaNacimiento: value3.datos.fechaNacimiento,
                fechaUltimaModificacion: "",

                firmantes: [
                    {
                        nombre: value3.datos.nombreCompleto_1,
                        cargo: value3.datos.cargo_1,
                        tipoFirma: value3.datos.tipoFirma_1,
                        estatus: value3.datos.rutaS3_1,
                        usuarioAdmin: ""
                    },
                    {
                        nombre: value3.datos.nombreCompleto_2,
                        cargo: value3.datos.cargo_2,
                        tipoFirma: value3.datos.tipoFirma_2,
                        estatus: value3.datos.rutaS3_2,
                        usuarioAdmin: ""
                    },
                ],

                genero: value3.datos.genero,
                lugarNacimiento: value3.datos.lugarNacimiento,

                nacionalidad: value3.datos.nacionalidad,
                numeroIdentificacion: value3.datos.numeroIdentificacion,
                origenRecursos: value3.datos.origenRecursos,
                primerApellido: value3.datos.primerApellido,
                primerNombre: value3.datos.primerNombre,
                puesto: value3.datos.puesto,
                rtn: value3.datos.rtn,
                segundoApellido: value3.datos.segundoApellido,
                segundoNombre: value3.datos.segundoNombre,
                telefonoCelular: value3.datos.telefonoCelular,
                telefonoLaboral: value3.datos.telefonoLaboral,
                telefonoResidencia: value3.datos.telefonoResidencia,
                tiempoPuesto: value3.datos.tiempoPuesto,
                tipoIdentidad: value3.datos.tipoIdentidad,


                lugar: value3.datos.lugarRegistro,
                fecha: value3.datos.fechaRegistro,

                firmaRepresentante: value3.datos.firmanteRepresentante,
                descripcionEstatus: value3.datos.descripcionEstatus,
                tipoDepositante: "",
                // cehvalDepositanteCorreoRegistrador: token.email,
                //cehvalDepositanteNombreRegistrador: token.name,

            }
            

            //contactos modificados
            const myObj2 = {
                contactos: [
                    {
                        nombre: value3.datos.nombreCompleto1,
                        segundoNombre: value3.datos.segundoNombre1,
                        primerApellido: value3.datos.primerApellido1,
                        segundoApellido: value3.datos.segundoApellido1,
                        correo: value3.datos.correo1,
                        usuarioAdmin: ""
                    },
                    {
                        nombre: value3.datos.nombreCompleto2,
                        segundoNombre: value3.datos.segundoNombre2,
                        primerApellido: value3.datos.primerApellido2,
                        segundoApellido: value3.datos.segundoApellido2,
                        correo: value3.datos.correo2,
                        usuarioAdmin: ""
                    },
                ],
            }

            //contactos originales
            const myObj3 = {
                contactos: [
                    {
                        nombre: value2.datos.nombreCompleto1,
                        segundoNombre: value2.datos.segundoNombre1,
                        primerApellido: value2.datos.primerApellido1,
                        segundoApellido: value2.datos.segundoApellido1,
                        correo: value2.datos.correo1,
                        usuarioAdmin: ""
                    },
                    {
                        nombre: value2.datos.nombreCompleto2,
                        segundoNombre: value2.datos.segundoNombre2,
                        primerApellido: value2.datos.primerApellido2,
                        segundoApellido: value2.datos.segundoApellido2,
                        correo: value2.datos.correo2,
                        usuarioAdmin: ""
                    },
                ],
            }

            const myObj4 = {
                contactos: [
                    {
                        nombre: value.datos.nombreCompleto1,
                        segundoNombre: value.datos.segundoNombre1,
                        primerApellido: value.datos.primerApellido1,
                        segundoApellido: value.datos.segundoApellido1,
                        correo: value.datos.correo1,
                        usuarioAdmin: ""
                    },
                    {
                        nombre: value.datos.nombreCompleto2,
                        segundoNombre: value.datos.segundoNombre2,
                        primerApellido: value.datos.primerApellido2,
                        segundoApellido: value.datos.segundoApellido2,
                        correo: value.datos.correo2,
                        usuarioAdmin: ""
                    },
                ],
            }

            //console.log(myObj)
            const mandarInformacionDepositante = await actualizarDIPN(myObj)
          //  //console.log(mandarInformacionDepositante)
            if (mandarInformacionDepositante) {
                setLoading(false)
                // setSpinSend(false)
                if ((isNaN(mandarInformacionDepositante.error)) === false) {
                    // respuestaincorrecta    
                    setLoading(false)
                    setErroreshttp(mandarInformacionDepositante)
                } else {
                    setLoading(false)
                    if (mandarInformacionDepositante.codigo === "000") {

                        setInformnacionUsuario(myObj2)
                        setContactosOriginales(myObj3)
                        setContactosValidar(myObj4)
                        
                         //si se modifica algun correo de cualquiera de los dos contactos
                     /*    if ((value.datos.correo1 !== value2.datos.correo1)|| (value.datos.correo2 !== value2.datos.correo2)) {
                            setActualizarUsuario(true)
                            setActualizacionCorrecta(true)
                             //si se modifica algun nombre de contacto  
                        }else if ((value.datos.nombreCompleto1 !== value2.datos.nombreCompleto1) || (value.datos.primerApellido1 !== value2.datos.primerApellido1)||(value.datos.nombreCompleto2 !== value2.datos.nombreCompleto2) || (value.datos.primerApellido2!== value2.datos.primerApellido2) ) {
                          setConsultaGeneracionUsusario(true)
                        } */
                        if ((value.datos.correo1 !== value2.datos.correo1)|| (value.datos.correo2 !== value2.datos.correo2)||(value.datos.nombreCompleto1 !== value2.datos.nombreCompleto1) || (value.datos.primerApellido1 !== value2.datos.primerApellido1)||(value.datos.nombreCompleto2 !== value2.datos.nombreCompleto2) || (value.datos.primerApellido2!== value2.datos.primerApellido2) ) {
                            setConsultaGeneracionUsusario(true)
                        }
                        else{
                            setActualizacionCorrecta(true) 
                        }
                                   
                   
                    } else {
                        setActualizacionIncorrecta(true)
                    }
                }
            }
        }

        if (contador > 0) {
            registrarActualizacion(); // Llamas la función
        }


    }, [value3])

    const handleSubmit = async (e) => {
        let condicionNombre1 = false;
        let condicionNombre2 = false;
        let nombreCompleto1;
        let nombreCompleto2;


        e.preventDefault();
        if (condicion) {

            if ((value.datos.nombreCompleto1 !== value2.datos.nombreCompleto1) || (value.datos.segundoNombre1 !== value2.datos.segundoNombre1) || (value.datos.primerApellido1 !== value2.datos.primerApellido1) || (value.datos.segundoApellido1 !== value2.datos.segundoApellido1)) {
                condicionNombre1 = true;
                nombreCompleto1 = value2.datos.nombreCompleto1.trim() + " " + value2.datos.segundoNombre1.trim() + " " + value2.datos.primerApellido1.trim() + " " + value2.datos.segundoApellido1.trim()
            }

            if ((value.datos.nombreCompleto2 !== value2.datos.nombreCompleto2) || (value.datos.segundoNombre2 !== value2.datos.segundoNombre2) || (value.datos.primerApellido2 !== value2.datos.primerApellido2) || (value.datos.segundoApellido2 !== value2.datos.segundoApellido2)) {
                condicionNombre2 = true;
                nombreCompleto2 = value2.datos.nombreCompleto2.trim() + " " + value2.datos.segundoNombre2.trim() + " " + value2.datos.primerApellido2.trim() + " " + value2.datos.segundoApellido2.trim()
            }
            let values1 = Object.values(value.datos)
            let values2 = Object.values(value2.datos)
            let keys1 = Object.keys(value.datos);
            let contadorLocal = 0;


            for (let index = 0; index < keys1.length; index++) {
                if ((values1[index] !== values2[index]) && (keys1[index] !== "establecerModificacion")) {
                    if (condicionNombre1) {
                        setValue3((prev) => {
                            const datos = { ...prev.datos, ["nombreCompleto_1"]: nombreCompleto1 };
                            return { ...prev, datos };
                        });
                        condicionNombre1 = false;
                    }


                    if (condicionNombre2) {
                        setValue3((prev) => {
                            const datos = { ...prev.datos, ["nombreCompleto_2"]: nombreCompleto2 };
                            return { ...prev, datos };
                        });
                        condicionNombre2 = false;
                    }


                    if (!values2[index]) {
                        setValue3((prev) => {
                            const datos = { ...prev.datos, [keys1[index]]: " " };
                            return { ...prev, datos };
                        });
                    } else {
                        if(values2[index]===true||values2[index]===false){
                            setValue3((prev) => {
                                const datos = { ...prev.datos, [keys1[index]]: values2[index]};
                                return { ...prev, datos };
                            });
                        }else{
                            setValue3((prev) => {
                                const datos = { ...prev.datos, [keys1[index]]: values2[index].trim() };
                                return { ...prev, datos };
                            });
                        }

                      
                    }
                    contadorLocal++;
                }
            }

            setContador(contadorLocal)

            if (contadorLocal < 1) {
                setSinCambios(true);
            }
        } else {
            next()
        }
    }


    const next = () => setCurrentPage((prev) => prev + 1);
    const prev = () => setCurrentPage((prev) => prev - 1);


    const imprimir = async () => {
        setActualizacionCorrecta(false)
        setLoading(true)

        const myobj = {
            codigo: history.location.state.objMenu.folio,
            estatus: 5
        }

        const response = await reporteAutorizadoDIPN(myobj)
        if (response) {
            setLoading(false)
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(response)
            } else {
                const file = new Blob(
                    [response],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
                history.go(-1)
            }
        }

    }



    const cancelar = () => {
        let values1 = Object.values(value.datos)
        let values2 = Object.values(value2.datos)
        let keys1 = Object.keys(value.datos);
        let contadorLocal = 0;

        for (let index = 0; index < keys1.length; index++) {
            if (values1[index] !== values2[index]) {
                contadorLocal++;

            }
        }

        if (contadorLocal > 1) {
            setConfirmacionCancelar(true)
        } else {
            history.push({
                pathname: '/depositantesParaActualizar', state: 3
            })
        }
    }

    const establecerConfirmacion = () => {
        setValue((prev) => {
            const datos = { ...prev.datos, ["establecerModificacion"]: true };
            return { ...prev, datos };
        });
    }

    const resetear = () => {
        setActualizarUsuario(false)
    }

    const modificarCorreoCognito=()=>{
       
        if((value.datos.correo1 !== value2.datos.correo1)|| (value.datos.correo2 !== value2.datos.correo2)){
         //   console.log("modifficar correo")
            setModificarCorreo(true)
        }
    }

    return (
        <>
            <div className='fluid-container'>
                {modificarCorreo&&
                <ActualizarCorreo resetear={resetear} informacion={informacionUsuario} contactosOriginales={contactosOriginales} contactosValidar={contactosValidar} codigo={value.datos.codigoDepositante} tipoParticipante={tipoParticipante}></ActualizarCorreo>

                }
                {actualizarUsuario &&
                    <ActualizarUsuarios resetear={resetear} informacion={informacionUsuario} contactosOriginales={contactosOriginales} contactosValidar={contactosValidar} codigo={value.datos.codigoDepositante} tipoParticipante={tipoParticipante} ></ActualizarUsuarios>
                }
                <Modal show={consultaGeneracionUsusario} animation={false} centered >
                <div className="containerModal">
                    <div className="modal-header">
                        <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                        <button type="button" onClick={() => { history.go(-1) }} class="btn-close" aria-label="Close"></button>
                    </div>
                    <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >Se ha modificado información personal de uno o mas contactos, ¿desea generar nuevos usuarios con estos datos?</p>
                    <div className="row justify-content-evenly">
                        <button onClick={() => { 
                        setActualizacionCorrecta(true)
                        setActualizarUsuario(true)
                        setConsultaGeneracionUsusario(false)}} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Generar</button>
                      
                        <button onClick={() => {
                        setActualizacionCorrecta(true)
                        setConsultaGeneracionUsusario(false)
                        modificarCorreoCognito()
                        }} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >No generar</button>
                    </div>
                </div>
            </Modal>
            
                <Modal show={confirmacionCancelar} animation={false} centered >
                    <div className="containerModal">
                        <div className="modal-header">
                            <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                            <button type="button" onClick={() => setConfirmacionCancelar(false)} class="btn-close" aria-label="Close"></button>
                        </div>
                        <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >Se descartarán los cambios hechos ¿Desea continuar?</p>
                        <div className="row justify-content-evenly">
                            <button onClick={() => setConfirmacionCancelar(false)} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Cancelar</button>
                            <button onClick={() => { history.go(-1) }} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Aceptar</button>
                        </div>
                    </div>
                </Modal>
                <Modal show={actualizacionCorrecta} animation={false} centered >
                    <div className="containerModal">
                        <div className="modal-header">
                            <h5 className="espacioMargin p-3 m-0">Mensaje del sistema</h5>
                            <button type="button" onClick={() => { history.go(-1) }} class="btn-close" aria-label="Close"></button>
                        </div>
                        <p className="text-center font-acumin-variable m-0 fs-5 p-4 mb-3 border-bottom" >Se ha actualizado la información del depositante</p>
                        <div className="row justify-content-evenly">
                            <button onClick={() => { history.go(-1) }} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Terminar</button>
                            <button onClick={imprimir} className="col-5 col-md-3 btn background-royal-blue text-white bg-hover-gold font-acumin-variable" >Imprimir</button>
                        </div>
                    </div>
                </Modal>

                {loading &&
                    <PantallaCargando />
                }

                

                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }

                {sinCambios &&
                    <ComponenteModal mostrarModal={sinCambios} cerrarModal={() => { setSinCambios(false) }} titulo="No se realizó ningun cambio" textoBoton="Entendido"></ComponenteModal>
                }

                {sinCambiosImpresion &&
                    <ComponenteModal mostrarModal={sinCambiosImpresion} cerrarModal={() => { setSinCambiosImpresion(false) }} titulo="No se ha confirmado ningun cambio, no se puede imprimir" textoBoton="Entendido"></ComponenteModal>
                }

          
                {actualizacionIncorrecta &&
                    <ComponenteModal mostrarModal={actualizacionIncorrecta} cerrarModal={() => { setActualizacionIncorrecta(false) }} titulo="No se pudo actualizar la información del depositante" textoBoton="Entendido"></ComponenteModal>
                }


                <div className="fluid-container">

                    <main className='container-min-height' >
                        <div className="row row-wrap g-0">
                            
                            <div className="col-12 mb-5">
                                <BannerTitulo titulo="Actualizar depositante"></BannerTitulo>
                                <div className="d-flex justify-content-center">
                                    <div className="container">
                                        <div className="row contenedorsteper" id='contenedorsteper' >
                                            <div className="col-12 contenedorsteper font-acumin-variable" >
                                                <div id="stepperComponent">
                                                    <Stepper
                                                        steps={sections}
                                                        activeStep={currentPage - 1}
                                                        activeColor="#F4CA85"
                                                        completeColor="#242848"
                                                        completeBarColor="#242848"
                                                        titleFontSize={15}
                                                        size={30}
                                                        circleFontSize={15}
                                                        className="steper"
                                                    />
                                                </div>

                                                <div className=" claseSinBorder">
                                                    <div className="card-body ">
                                                        <div className="d-grid gap-4 mx-auto ">
                                                            {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                                                <form onSubmit={handleSubmit}>
                                                                    <br></br>
                                                                    <h6 className="font-acumin-variable text-royal-blue">* Estos campos son obligatorios</h6>
                                                                    {currentPage === 1 && (
                                                                        <>
                                                                            <div id="primeraSeccion"  >
                                                                                <div className="contenedorFormularioDepositanteDirecto card" >
                                                                                    <ActualizarDI1 />
                                                                                    <div className="col-12 row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end font-acumin-variable">
                                                                                        {value.datos.establecerModificacion ?
                                                                                            <button title="Cancelar modificación" type='button' onClick={cancelar} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white  mb-2" >Cancelar</button>
                                                                                            :
                                                                                            <button title="Modificar información" type='button' onClick={establecerConfirmacion} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Modificar</button>
                                                                                        }

                                                                                        <button title="Confirmar modificación de información" onClick={() => setCondicion(true)} id="botonActualizar1" type='submit' className=" col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Confirmar</button>
                                                                                        {/*} <button title="Imprimir" onClick={imprimir} type='button' className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Imprimir</button>*/}
                                                                                        <button title="Siguiente" onClick={() => setCondicion(false)} type='submit' className="col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Siguiente</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}

                                                                    {currentPage === 2 && (
                                                                        <>
                                                                            <div id="primeraSeccionIntermedia " >
                                                                                <div className="contenedorFormularioDepositanteDirecto card " >
                                                                                    <ActualizarDI1_5 />
                                                                                    <div className="col-12 row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end font-acumin-variable">
                                                                                        <button title="Regresar" type="button" onClick={prev} className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2  mb-2">Anterior</button>
                                                                                        {value.datos.establecerModificacion ?
                                                                                            <button title="Cancelar modificación" type='button' onClick={cancelar} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white  mb-2" >Cancelar</button>
                                                                                            :
                                                                                            <button title="Modificar información" type='button' onClick={establecerConfirmacion} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Modificar</button>
                                                                                        }

                                                                                        <button title="Confirmar modificación de información" onClick={() => setCondicion(true)} id="botonActualizar1" type='submit' className=" col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Confirmar</button>
                                                                     
                                                                                        <button title="Siguiente" onClick={() => setCondicion(false)} type='submit' className="col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Siguiente</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </>
                                                                    )}

                                                                    {currentPage === 3 && (
                                                                        <>
                                                                            <div id="segundaSeccion" >
                                                                                <div className="contenedorFormularioDepositanteDirecto card " >
                                                                                    <ActualizarDI2 />
                                                                                    <br></br>
                                                                                    <div className="col-12 row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end font-acumin-variable">
                                                                                        <button title="Regresar" type="button" onClick={prev} className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Anterior</button>
                                                                                        {value.datos.establecerModificacion ?
                                                                                            <button title="Cancelar modificación" type='button' onClick={cancelar} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white  mb-2" >Cancelar</button>
                                                                                            :
                                                                                            <button title="Modificar información" type='button' onClick={establecerConfirmacion} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Modificar</button>
                                                                                        }

                                                                                        <button title="Confirmar modificación de información" onClick={() => setCondicion(true)} id="botonActualizar1" type='submit' className=" col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Confirmar</button>
                                                                                        <button title="Siguiente" onClick={() => setCondicion(false)} type='submit' className="col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Siguiente</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}

                                                                    {currentPage === 4 && (
                                                                        <>
                                                                            <div id="terceraSeccion" >
                                                                                <div className="contenedorFormularioDepositanteDirecto card " >
                                                                                    <ActualizarDI3 />

                                                                                    <div className="col-12 row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end font-acumin-variable">
                                                                                        <button title="Regresar" type="button" onClick={prev} className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2  mb-2">Anterior</button>
                                                                                        {value.datos.establecerModificacion ?
                                                                                            <button title="Cancelar modificación" type='button' onClick={() => { setConfirmacionCancelar(true) }} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white  mb-2" >Cancelar</button>
                                                                                            :
                                                                                            <button title="Modificar información" type='button' onClick={establecerConfirmacion} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Modificar</button>
                                                                                        }

                                                                                        <button title="Confirmar modificación de información" onClick={() => setCondicion(true)} id="botonActualizar1" type='submit' className=" col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Confirmar</button>
                                                                                        <button title="Siguiente" onClick={() => setCondicion(false)} type='submit' className="col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Siguiente</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}

                                                                    {currentPage === 5 && (
                                                                        <>
                                                                            <div id="cuartaSeccion" >
                                                                                <div className="contenedorFormularioDepositanteDirecto card " >
                                                                                    <ActualizarDI4 />
                                                                                    <br></br>
                                                                                    <div className="col-12 row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end font-acumin-variable">
                                                                                        <button title="Regresar" type="button" onClick={prev} className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2  mb-2">Anterior</button>
                                                                                        {value.datos.establecerModificacion ?
                                                                                            <button title="Cancelar modificación" type='button' onClick={cancelar} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white  mb-2" >Cancelar</button>
                                                                                            :
                                                                                            <button title="Modificar información" type='button' onClick={establecerConfirmacion} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Modificar</button>
                                                                                        }

                                                                                        <button title="Confirmar modificación de información" onClick={() => setCondicion(true)} id="botonActualizar1" type='submit' className=" col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Confirmar</button>
                                                                                        <button title="Siguiente" onClick={() => setCondicion(false)} type='submit' className="col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Siguiente</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}

                                                                    {currentPage === 6 && (
                                                                        <>
                                                                            <div id="quintaSeccion" >
                                                                                <div className="card contenedorFormularioDepositanteDirecto card ">
                                                                                    <ActualizarDI5 tipo="DepositanteDirecto" />
                                                                                    <br></br>
                                                                                    <div className="col-12 row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end font-acumin-variable">
                                                                                        <button title="Regresar" type="button" onClick={prev} className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2  mb-2">Anterior</button>
                                                                                        {value.datos.establecerModificacion ?
                                                                                            <button title="Cancelar modificación" type='button' onClick={cancelar} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white  mb-2" >Cancelar</button>
                                                                                            :
                                                                                            <button title="Modificar información" type='button' onClick={establecerConfirmacion} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Modificar</button>
                                                                                        }

                                                                                        <button title="Confirmar modificación de información" onClick={() => setCondicion(true)} id="botonActualizar1" type='submit' className=" col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Confirmar</button>
                                                                                        <button title="Siguiente" onClick={() => setCondicion(false)} type='submit' className="col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Siguiente</button>
                                                                                    </div>
                                                                                    <br></br>
                                                                                    <br></br>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}

                                                                    {currentPage === 7 && (
                                                                        <>
                                                                            <div id="sextaSeccion" >
                                                                                <div className="card contenedorFormularioDepositanteDirecto card ">
                                                                                    <ActualizarDI6 tipo="depositanteIndirecto" />
                                                                                    <br></br>
                                                                                    <div className="col-12 row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end font-acumin-variable">
                                                                                        <button title="Regresar" type="button" onClick={prev} className="col-8 col-sm-3 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2  mb-2">Anterior</button>
                                                                                        {value.datos.establecerModificacion ?
                                                                                            <button title="Cancelar modificación" type='button' onClick={cancelar} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white  mb-2" >Cancelar</button>
                                                                                            :
                                                                                            <button title="Modificar información" type='button' onClick={establecerConfirmacion} className="col-8 col-sm-4  col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2" >Modificar</button>
                                                                                        }

                                                                                        <button title="Confirmar modificación de información" onClick={() => setCondicion(true)} id="botonActualizar1" type='submit' className=" col-8 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2 btn background-royal-blue bg-hover-gold text-white mx-2 mb-2">Confirmar</button>
                                                                                    </div>


                                                                                </div>
                                                                            </div>
                                                                            <br></br>
                                                                            <br></br>
                                                                        </>
                                                                    )}



                                                                </form>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main >

                </div>
            </div>


        </>
    );
};

export default function ActualizarDepositanteIndirectoNatural({ history }) {
    return (
        <FormProvider>
            <FormProvider2><FormProvider3>
                <Form history={history} />
            </FormProvider3></FormProvider2>
        </FormProvider>
    );
}


