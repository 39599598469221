import React, { useState } from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ComponenteModal from "../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import PantallaCargando from "../../../ComponentesGenerales/PantallaCargando";

//APIs
import { consultarInformacionPagoLiquidacion } from "../../../api/PagoInteresesAmorDiv/consultarInformacionPagoLiquidacion";
import { notificarPagadoLiquidacionDepositante } from "../../../api/PagoInteresesAmorDiv/notificarPagadoLiquidacionDepositante";
import { notificarNoPagadoLiquidacionDepositante } from "../../../api/PagoInteresesAmorDiv/notificarNoPagadoLiquidacionDepositante";
import { confirmarPagosLiquidados } from "../../../api/PagoInteresesAmorDiv/confirmarPagosLiquidados";
import { notificarPagadoLiquidacionSerie } from "../../../api/PagoInteresesAmorDiv/notificarPagadoLiquidacionSerie";
import { imprimirPagosPDP } from "../../../api/PagoInteresesAmorDiv/imprimirPagosPDP";
import { descargarPDFPagos } from "../../../api/PagoInteresesAmorDiv/decargarPDFPagos";

//Hooks
import useConfirmarPagosLiquidaciones from "../../../hooks/PagoDerechosPatrimoniales/useConfirmarPagosLiquidaciones";

export default function FormularioPagosPorLiquidar({ history }) {


    const [registroExitoso, setRegistroExitoso] = React.useState()
    const [registroFallido, setRegistroFallido] = React.useState()
    const [erroreshttp, setErroreshttp] = React.useState()
    const [mensaje, setMensaje] = React.useState()
    //Shows the loading screen when y set to true
    const [loading, setLoading] = React.useState(false);
    const [spin, setSpin] = React.useState(true)

    const [datos, setDatos] = useState()

    const [validarBotonConfirmar, setValidarBotonConfirmar] = React.useState()
    const [confirmarDesabilitado, setConfirmarDesabilitado] = React.useState()
    const [tabla1, setTabla1] = useState(true)
    const [tabla2, setTabla2] = useState()
    const [montoFaltante, setMontoFaltante] = useState()
    const [modificarEstatusDenegado, setModificarEstastusDenegado] = useState()


    React.useEffect(() => {
        guardarDatos();
    }, []);

    React.useEffect(() => {
        //console.log("entra al reactEffect")
        let variable1;
        let variable2;
        if (datos) {

            let contador = datos.montoPorDepositante.length
            //console.log(contador)
            if (datos.status === "SIN PAGAR") {
                variable1 = false;
            } else {
                variable1 = true;
            }

            datos.montoPorDepositante.map((item, index) => {
                if (item.estatus === "SIN PAGAR") {
                    variable2 = false
                    contador--;
                } else {
                    variable2 = true
                }


                if (variable1 && variable2 && (contador == datos.montoPorDepositante.length)) {
                    //console.log("confirmado")
                    setValidarBotonConfirmar(true)
                } else {
                    //console.log("no confirmado")
                    setValidarBotonConfirmar(false)
                }

            })
        }


    }, [datos]);



    const guardarDatos = async () => {
        //console.log("guardarDatos")
        //console.log(history.location.state.objMenu.codigoId)
        if (history.location.state === undefined) {
            history.go(-1)
        } else {
            const response = await consultarInformacionPagoLiquidacion({
                codigoId: history.location.state.objMenu.codigoId
            })
            //  //console.log(response)     
            if (response) {
                setSpin(false)
                //setLoading(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    setDatos(response)
                }
            }
        }
    }


    const confirmar = async (e) => {

        //console.log(validarBotonConfirmar)
        if (validarBotonConfirmar) {
            setLoading(true)
            const response = await confirmarPagosLiquidados(datos)
            //console.log(response)
            setLoading(false)
            if (response) {
                //setLoading(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    setDatos(response)

                    setMensaje(response.mensaje)
                    setRegistroExitoso(true)
                }
            }
        } else {
            setConfirmarDesabilitado(true)
        }



    }


    const pagado = async (row) => {
        if (datos?.status === "PAGADO CONFIRMADO") {
            setModificarEstastusDenegado(true)
        } else {
            setLoading(true)
            let response;
            if (row.original.estatus === "SIN PAGAR") {
                response = await notificarPagadoLiquidacionDepositante({ codigoId: row.original.pagoDerechoDepoId })
            } else {
                response = await notificarNoPagadoLiquidacionDepositante({ codigoId: row.original.pagoDerechoDepoId })
            }

            if (response) {
                setLoading(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    setDatos(response)
                    if (tabla1) {
                        setTabla1(false)
                        setTabla2(true)
                    } else {
                        setTabla1(true)
                        setTabla2(false)
                    }
                }
            }
        }
    }

    const pagadoSerie = async (row) => {
        if (datos?.status === "PAGADO CONFIRMADO") {
            setModificarEstastusDenegado(true)
        } else {
            setLoading(true)
            ////console.log(row.original)
            const response = await notificarPagadoLiquidacionSerie({ codigoId: datos?.codigoId })
            //console.log(response)
            if (response) {
                setLoading(false)
                if ((isNaN(response.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(response)
                } else {
                    setDatos(response)
                    if (tabla1) {
                        setTabla1(false)
                        setTabla2(true)
                    } else {
                        setTabla1(true)
                        setTabla2(false)
                    }
                }
            }
        }
    }


    const imprimir = async (row) => {
        setLoading(true)
        let estatusParaEnviar;
        if (datos?.status === "SIN PAGAR") {
            estatusParaEnviar = 7
        } else {
            estatusParaEnviar = 4
        }
        const myObj = {
            codigoId: history.location.state.objMenu.codigoId,
            estatus: estatusParaEnviar
        }

        const response = await imprimirPagosPDP(myObj)
        if (response) {
            setLoading(false)
            //setLoading(false)
            if ((isNaN(response.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(response)
            } else {
                const file = new Blob(
                    [response],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);
            }
        }
    }

    const descargarDoc = async () => {
        setLoading(true)

        const myObj = {
            fileName: datos?.folioRuta
        }

        const descargar = await descargarPDFPagos(myObj)

        if (descargar) {
            setLoading(false)
            if ((isNaN(descargar.error)) === false) {
                // respuestaincorrecta           
                setErroreshttp(descargar)
            } else {


                // setRegistroExitoso(true)
                //console.log("descargado")
                const file = new Blob(
                    [descargar],
                    { type: 'application/pdf' });//Build a URL from the file
                const fileURL = URL.createObjectURL(file);//Open the URL on new Window
                window.open(fileURL);


            }
        }
    }


    const irInicio = () => {
        setRegistroExitoso(false)
        history.go(-1)
    }

    return (
        <div className="fluid-container">
            {loading &&
                <PantallaCargando />
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            {registroExitoso &&
                <ComponenteModal mostrarModal={registroExitoso} cerrarModal={irInicio} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {registroFallido &&
                <ComponenteModal mostrarModal={registroFallido} cerrarModal={() => { setRegistroFallido(false) }} titulo={mensaje} textoBoton="Entendido"></ComponenteModal>
            }

            {montoFaltante &&
                <ComponenteModal mostrarModal={montoFaltante} cerrarModal={() => { setMontoFaltante(false) }} titulo="Ingrese todos los montos" textoBoton="Entendido"></ComponenteModal>
            }



            {confirmarDesabilitado &&
                <ComponenteModal mostrarModal={confirmarDesabilitado} cerrarModal={() => setConfirmarDesabilitado(false)} titulo="No se puede confirmar, todos los estatus tienen que estar en pagado" textoBoton="Entendido"></ComponenteModal>
            }

            {modificarEstatusDenegado &&
                <ComponenteModal mostrarModal={modificarEstatusDenegado} cerrarModal={() => setModificarEstastusDenegado(false)} titulo="Los pagos han sido confirmados, ya no se pueden modificar. " textoBoton="Entendido"></ComponenteModal>
            }


            <main className="mb-5">
                <div className="row row-wrap g-0">
                    <div className="col-12">
                        <div className="">
                            <div className="row d-flex justify-content-center g-0">
                                <div className="col-12">
                                    <BannerTitulo titulo="Confirmación de pagos"></BannerTitulo>
                                    <div className="px-3 px-lg-4 px-xl-4 px-xxl-5 border-none">
                                        {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                            <div className="card-body ">
                                                <div className="col-12 mx-auto">
                                                    {datos &&
                                                        <>
                                                            <div className="card">

                                                                <div className="card-body">
                                                                    <form className="row g-0 needs-validation" novalidate >

                                                                    {datos.folioRuta &&
                                                                            <div className="row mb-3">
                                                                                <div className="form-floating mx-1  row col-4 col-md-3  marginButtonAprove">
                                                                                    <button type="button" onClick={() => descargarDoc()} className="btn background-royal-blue text-white bg-hover-gold col-12 col-md-12" >Descargar dictamen</button>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                        <div className="row mb-3">
                                                                            <h4 className="text-royal-blue font-acumin-variable mt-3">Información de la serie</h4>
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.nombreDeEmision} id="nombreEmision" name="nombreEmision"  required disabled />
                                                                                    <label htmlFor="nombreEmision" className=" textoFormularioDepositantedirecto">Nombre de la emisión</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.serie} id="nombreSerie" name="nombreSerie"  required disabled />
                                                                                    <label htmlFor="nombreSerie" className=" textoFormularioDepositantedirecto">Nombre de la serie</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.isin} id="isin" name="isin"  required disabled />
                                                                                    <label htmlFor="isin" className=" textoFormularioDepositantedirecto">ISIN</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.montoTotal} id="montoTotal" name="montoTotal"  required disabled />
                                                                                    <label htmlFor="montoTotal" className=" textoFormularioDepositantedirecto">Monto total</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row mb-3">
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.moneda} id="monedaSerie" name="monedaSerie"  required disabled />
                                                                                    <label htmlFor="monedaSerie" className=" textoFormularioDepositantedirecto">Moneda de la serie</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.tipoPago} id="tipoPago" name="tipoPago"  required disabled />
                                                                                    <label htmlFor="tipoPago" className=" textoFormularioDepositantedirecto">Tipo de pago</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.fechaPago} id="fechaPago" name="fechaPago"  required disabled />
                                                                                    <label htmlFor="fechaPago" className=" textoFormularioDepositantedirecto">Fecha de pago</label>
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                        <div className="row mb-3">
                                                                            <h4 className="text-royal-blue font-acumin-variable mt-3">Pago de impuestos</h4>
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.totalRetenciones} id="totalRetenciones" name="totalRetenciones"  required disabled />
                                                                                    <label htmlFor="totalRetenciones" className=" textoFormularioDepositantedirecto">Total de retenciones</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.cuentaSar} id="cuentaSar" name="cuentaSar"  required disabled />
                                                                                    <label htmlFor="cuentaSar" className=" textoFormularioDepositantedirecto">Cuenta SAR</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.banco} id="banco" name="banco"  required disabled />
                                                                                    <label htmlFor="banco" className=" textoFormularioDepositantedirecto">Banco</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.tipo_cuenta} id="tipoCuenta" name="tipoCuenta"  required disabled />
                                                                                    <label htmlFor="tipoCuenta" className=" textoFormularioDepositantedirecto">Tipo de cuenta</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row mb-3">

                                                                            <div className="col-md-6 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.direccion} id="direccionSar" name="direccionSar"  required disabled />
                                                                                    <label htmlFor="direccionSar" className=" textoFormularioDepositantedirecto">Dirección de SAR</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-3 mb-3">
                                                                                <div className="form-floating">
                                                                                    <input type="text" className="form-control textoFormularioDepositantedirecto" value={datos.status} id="estatus" name="estatus"  required disabled />
                                                                                    <label htmlFor="estatus" className=" textoFormularioDepositantedirecto">Estatus</label>
                                                                                </div>
                                                                            </div>


                                                                            <div className="col-md-3 d-flex justify-content-end mb-3">
                                                                                <button type="button" onClick={pagadoSerie} className="btn  col-sm-6 col-md-9 col-lg-6 col-xl-6 background-royal-blue text-white bg-hover-gold">Pagado/No pagado</button>
                                                                            </div>

                                                                        </div>

                                                                 
                                                                    </form>
                                                                </div>
                                                                {tabla1 &&
                                                                    <TablaMercado datos={datos.montoPorDepositante} useValidate={useConfirmarPagosLiquidaciones} displayInformation={pagado} textoBoton={"Pagado/No pagado"} textTitle="Click para cambiar a pagado o no pagado" mercadoPrimario={true} funcion1MercadoPrimario={imprimir} funcion2MercadoPrimario={confirmar} texto1MercadoPrimario="Imprimir" texto2MercadoPrimario="Confirmar" ></TablaMercado>
                                                                }
                                                                {tabla2 &&
                                                                    <TablaMercado datos={datos.montoPorDepositante} useValidate={useConfirmarPagosLiquidaciones} displayInformation={pagado} textoBoton={"Pagado/No pagado"} textTitle="Click para cambiar a pagado o no pagado" mercadoPrimario={true} funcion1MercadoPrimario={imprimir} funcion2MercadoPrimario={confirmar} texto1MercadoPrimario="Imprimir" texto2MercadoPrimario="Confirmar" ></TablaMercado>
                                                                }

                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main >

        </div >
    );


}

