
import React from "react";
import { useHistory } from "react-router-dom";

//Components
import BannerTitulo from "../../ComponentesGenerales/Bannertitulo";
import TablaUsuarios from "../../ComponentesGenerales/TablaUsuarios";
import ManejadorErrores from "../../ComponentesGenerales/ManejadorErrores";

//APIs
import { consultarCuentaDepositante } from "../../api/transferenciaLibreDePago/consultarCuentaDepositante";

//Hooks
import useVerCuentasDepositanteDirecto from "../../hooks/useVerCuentasDepositanteDirecto";

//Muestra las cuentas de un depositante indirecto
export default function CuentaDeValoresDepIndirecto({ history }) {

    const codigo= (JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo

    const [data, setData] = React.useState([]);
    const [copia, setCopia] = React.useState();
    const [erroreshttp, setErroreshttp] = React.useState()

    React.useEffect(() => {
        viewAccounts();
    }, []);

    const viewAccounts = async () => {
        try {
            const getDepDirectoAccounts = await consultarCuentaDepositante({ codigo: codigo });
            if (getDepDirectoAccounts) {
                //setCargando(false)
                if ((isNaN(getDepDirectoAccounts.error)) === false) {
                    // respuestaincorrecta           
                    setErroreshttp(getDepDirectoAccounts)
                } else {
                    //console.log(getDepDirectoAccounts);
                    setData(getDepDirectoAccounts.data);
                    setCopia(getDepDirectoAccounts.data);
                }
            }

          
        } catch (err) {
            console.error(err);
        }
    }

    const viewValues = (row) => {
        const selectedAccountNumber = row.original.cehvalCuentaValNum;
        const currencyType = row.original.catMonedaCatMonedaId.catMonedaDivisa;

        history.push({
            pathname: '/misValoresDD',
            state: {
                objNumCuenta: {
                    accountNumber: selectedAccountNumber,
                    currencyType: currencyType,
                }
            }
        });
    }



    return (
        <>
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            <div className="fluid-container">
                
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Mis cuentas"></BannerTitulo>
                                <div className="row justify-content-center align-items-center table-container g-0">

                                    {copia ?

                                        <div className="container py-5">
                                            <TablaUsuarios datos={data} useValidate={useVerCuentasDepositanteDirecto} displayInformation={viewValues} textoBoton={"Ver cuenta"} textTitle="Ver valores de la cuenta" ></TablaUsuarios>
                                        </div>
                                        :
                                        <>
                                            <div className="row row-wrap justify-content-center">
                                                <h4 className="col-12 text-center">Cargando...</h4>
                                                <div className="col-12 spinner-border" role="status"></div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>

        </>
    );
}




