import React, { useState, useEffect } from "react";

//Components
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";

//APIs
import { consultarDepositosRetirosPorEstatus } from "../../../api/mantenimientoCargaValores/consultarDepositosRetirosPorEstatus";
//Hooks
import useDepositosRetirosPorAutorizar from "../../../hooks/MantenimientoCargaValores/useDepositosRetirosPorAutorizar";



export default function DepositosRetirosValoresPorAutorizar({ history }) {


    const [data, setData] = useState([]);
    const [copia, setCopia] = useState();
    const [spin, setSpin] = useState(true);
    const [erroreshttp, setErroreshttp] = useState();
  

 
    useEffect(() => {
        traerInformacionDepositosRetiros();
    }, [])

    const traerInformacionDepositosRetiros = async () => {
        const response = await consultarDepositosRetirosPorEstatus({status:1})
        //console.log(response)
        if (response) {
            setSpin(false)
            if ((isNaN(response.error)) === false) {
                setErroreshttp(response)
            } else {
                setData(response)
                setCopia(response)
            }
        }
      
    }

    const abrirInformacion = async (row) => {
       // //console.log(row.original)
        history.push({
            pathname: '/autorizarDepositosRetirosValores', state: { objMenu: row.original }
        })
        
    }

    return (
        <>
            <div className="fliud-container" >

                {erroreshttp &&
                    <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
                }
                <main className='container-min-height'>
                    <div className="row row-wrap g-0">
                        <div className="col-12">
                            <div className="">
                                <BannerTitulo titulo="Depósito y retiro de valores por autorizar"></BannerTitulo>
                                {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                    <div className="d-flex">
                                        <div className="container py-5">
                                            {copia &&
                                                <TablaMercado datos={data} displayInformation={abrirInformacion} useValidate={useDepositosRetirosPorAutorizar} textoBoton={"Abrir"} textTitle="Click para ver información"></TablaMercado>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}