
import { instanciaRegistroPDF } from '../apiGlobal'

export async function descargarDocDesmaterializacion(obj) {
      try {
        const response = await instanciaRegistroPDF(`/desmaterializacion/descargar/solicitud`, {
          method: "post",
          data: obj,
          responseType: 'blob',
        })
        
        return response.data
      } catch (error) {
        return error
      }
}