import React from "react";
import '../../../../styles/stylesheet.css'
import { FormContext } from './form_context';
import { traerBancos } from "../../../../api/catalogos/traerBancos";
import { traerTiposCuenta } from "../../../../api/catalogos/traerTiposCuenta";
import ComponenteModal from "../../../../ComponentesGenerales/ComponenteModal";
import ManejadorErrores from "../../../../ComponentesGenerales/ManejadorErrores";

export default function RegistroDI4({ history }) {
    const [value, setValue] = React.useContext(FormContext);
    const [bancos, setBancos] = React.useState([]);
    const [tipoCuenta, setTipoCuenta] = React.useState([]);
    const [errorUsuarioExistente, setErrorUsuarioExistente] = React.useState();
    const [erroreshttp, setErroreshttp] = React.useState()


    const guardarDatos = (e) => {
        const { name, value } = e.target;

        if (name.indexOf("correo") >= 0) {
            //validarUsuario(name, value)
            setValue((prev) => {
                const datos = { ...prev.datos, [name]:  value.toLowerCase()  };
                return { ...prev, datos };
            });
        } else {
            setValue((prev) => {
                const datos = { ...prev.datos, [name]: value };
                return { ...prev, datos };
            });
        }


    }


    React.useEffect(() => {

        setValue((prev) => {
            const datos = { ...prev.datos, ["enviar"]: 0 };
            return { ...prev, datos };
        });
        traerDatos()
    }, [])

    const traerDatos = async () => {
        const catalogoBancos = await traerBancos()
        if (catalogoBancos) {
            //setSpin(false)
            if ((isNaN(catalogoBancos.error)) === false) {
              
                setErroreshttp(catalogoBancos)
                
            } else {
                // //console.log(traerInformacion)
                setBancos(catalogoBancos)
            }
        }

        const catalogoTiposCuenta = await traerTiposCuenta()
        if (catalogoTiposCuenta) {
            //setSpin(false)
            if ((isNaN(catalogoTiposCuenta.error)) === false) {
              
                setErroreshttp(catalogoTiposCuenta)
                
            } else {
       
                setTipoCuenta(catalogoTiposCuenta)
            }
        }

    }


    const checarNumeros = (e) => {
        const { name, value } = e.target;
        let out = '';
        let filtro = '1234567890-';//Caracteres validos


        //Recorrer el texto y verificar si el caracter se encuentra en la lista de validos 
        for (let i = 0; i < value.length; i++)
            if (filtro.indexOf(value.charAt(i)) != -1)
                //Se añaden a la salida los caracteres validos
                out += value.charAt(i);

        setValue((prev) => {
            const datos = { ...prev.datos, [name]: out };
            return { ...prev, datos };
        });
    }

    return (
        <>
            {errorUsuarioExistente &&
                <ComponenteModal mostrarModal={errorUsuarioExistente} cerrarModal={() => { setErrorUsuarioExistente(false) }} titulo="Este correo ya esta registrado, ingresa otro" textoBoton="Entendido"></ComponenteModal>
            }
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }

            <div className="card-body">
                <h5 className="textoTituloFormulario text-center" >IV. Instrucciones especiales (cuentas bancarias)</h5>
                <h6 className="textoTituloFormulario" >Nacional</h6>
                <div className="row mb-3">
                    <div className="col-md-3 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <input type="text" disabled className="form-control border  " value={"LEMPIRA"} />
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Moneda</label>
                        </div>
                    </div>

                    <div className="col-md-3 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <select className="form-select " value={value.datos.bancoN} onChange={(e) => guardarDatos(e)} id="bancoN" name="bancoN">
                                <option value=""> </option>
                                {
                                    bancos.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Banco</label>
                        </div>
                    </div>

                    <div className="col-md-3 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <select className="form-select " value={value.datos.tipoCuentaN} onChange={(e) => guardarDatos(e)} id="tipoCuentaN" name="tipoCuentaN">
                                <option value=""> </option>
                                {
                                    tipoCuenta.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Tipo de cuenta</label>
                        </div>
                    </div>

                    <div className="col-md-3 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <input placeholder="" maxLength={16} type="text" className="form-control " value={value.datos.numeroCuentaN} onChange={(e) => checarNumeros(e)} id="numeroCuentaN" name="numeroCuentaN" />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Número de cuenta</label>
                        </div>
                    </div>

                </div>
                <h6 className="textoTituloFormulario" >Extranjera</h6>
                <div className="row mb-3">
                    <div className="col-md-3 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <input type="text" disabled className="form-control border  " value={"DÓLAR"} />
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Moneda</label>
                        </div>

                    </div>

                    <div className="col-md-3 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <select className="form-select " value={value.datos.bancoE} onChange={(e) => guardarDatos(e)} id="bancoE" name="bancoE">
                                <option value=""> </option>
                                {
                                    bancos.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Banco</label>
                        </div>

                    </div>
                    <div className="col-md-3 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <select className="form-select " value={value.datos.tipoCuentaE} onChange={(e) => guardarDatos(e)} id="tipoCuentaE" name="tipoCuentaE">
                                <option value=""> </option>
                                {
                                    tipoCuenta.map((item, index) => (
                                        <option key={index} value={item.id} >{item.nombre}</option>
                                    ))
                                }
                            </select>
                            <label htmlFor="validationCustom04" className="form-label textoFormularioDepositantedirecto">Tipo de cuenta</label>
                        </div>
                    </div>
                    <div className="col-md-3 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <input placeholder="" maxLength={16} type="text" className="form-control " value={value.datos.numeroCuentaE} onChange={(e) => checarNumeros(e)} id="numeroCuentaE" name="numeroCuentaE" />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Número de cuenta</label>
                        </div>
                    </div>
                </div>

                <h5 className=" textoTituloFormulario text-center" >IV. Instrucciones especiales </h5>
                <h6 className="text-royal-blue font-acumin-variable">1. Usuario operador *</h6>
                <div className="row mb-3">
                    <div className=" col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            < input placeholder="" type="text" className="form-control" value={value.datos.nombreCompleto1} onChange={(e) => guardarDatos(e)} id="nombreCompleto1" name="nombreCompleto1" required />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Primer Nombre *</label>
                        </div>
                    </div>
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            < input placeholder="" type="text" className="form-control" value={value.datos.segundoNombre1} onChange={(e) => guardarDatos(e)} id="segundoNombre1" name="segundoNombre1" />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Segundo Nombre</label>
                        </div>
                    </div>
                    <div className=" col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            < input placeholder="" type="text" className="form-control" value={value.datos.primerApellido1} onChange={(e) => guardarDatos(e)} id="primerApellido1" name="primerApellido1" required />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Primer apellido *</label>
                        </div>
                    </div>
                    <div className=" col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <input placeholder="" type="text" className="form-control" value={value.datos.segundoApellido1} onChange={(e) => guardarDatos(e)} id="segundoApellido1" name="segundoApellido1" />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Segundo apellido</label>
                        </div>
                    </div>
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            <input style={{ textTransform: "lowercase" }} placeholder="" type="email" className="form-control" value={value.datos.correo1} onChange={(e) => guardarDatos(e)}  id="correo1" name="correo1" aria-describedby="emailHelp" required />
                            {/*  <input style={{ textTransform: "lowercase" }} placeholder="" type="email" className="form-control" value={correo1} onChange={(e) => setCorreo1(e.target.value)} onBlur={(e) => guardarDatos(e)} onMouseOut={(e) => guardarDatos(e)} id="correo1" name="correo1" aria-describedby="emailHelp" required />*/}
                            <label htmlFor="exampleInputEmail1" className="form-label textoFormularioDepositantedirecto">Correo electrónico *</label>
                        </div>
                    </div>
                </div>
                <h6 className="text-royal-blue font-acumin-variable">2. Usuario operador</h6>
                <div className="row mb-3">
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            < input placeholder="" type="text" className="form-control" value={value.datos.nombreCompleto2} onChange={(e) => guardarDatos(e)} id="nombreCompleto2" name="nombreCompleto2" />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Primer nombre</label>
                        </div>
                    </div>
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            < input placeholder="" type="text" className="form-control" value={value.datos.segundoNombre2} onChange={(e) => guardarDatos(e)} id="segundoNombre2" name="segundoNombre2" />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Segundo nombre</label>
                        </div>
                    </div>
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            < input placeholder="" type="text" className="form-control" value={value.datos.primerApellido2} onChange={(e) => guardarDatos(e)} id="primerApellido2" name="primerApellido2" />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Primer apellido</label>
                        </div>
                    </div>
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            < input placeholder="" type="text" className="form-control" value={value.datos.segundoApellido2} onChange={(e) => guardarDatos(e)} id="segundoApellido2" name="segundoApellido2" />
                            <label htmlFor="validationCustom02" className="form-label textoFormularioDepositantedirecto">Segundo apellido</label>
                        </div>
                    </div>
                    <div className="col-md-4 form-floating mb-3">
                        <div className="form-floating mb-2">
                            < input style={{ textTransform: "lowercase" }} placeholder="" type="email" className="form-control " value={value.datos.correo2} onChange={(e) => guardarDatos(e)}  id="correo2" name="correo2" aria-describedby="emailHelp" />
                            {/* < input style={{ textTransform: "lowercase" }} placeholder="" type="email" className="form-control " value={correo2} onChange={(e) => setCorreo2(e.target.value)} onBlur={(e) => guardarDatos(e)} onMouseOut={(e) => guardarDatos(e)} id="correo2" name="correo2" aria-describedby="emailHelp" /> */}
                            <label htmlFor="exampleInputEmail1" className="form-label textoFormularioDepositantedirecto">Correo electrónico </label>
                        </div>
                    </div>
                </div>


            </div>

        </>
    );
}

