import { instance } from '../../../apiGlobal';

export async function mostrarVectorPrecios () {

      try {
        const response = await instance(`/tarificacion/mostrar/vectorprecios`, {
          method:"get",
        });

        return response;
      } catch (error) {
        return error
      }
}