import React, { useState } from "react";

//Componentes
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";
import ManejadorErrores from "../../../ComponentesGenerales/ManejadorErrores";
import SpinnerStart from "../../../ComponentesGenerales/SpinnerStart";
import TablaMercado from "../../../ComponentesGenerales/TablaMercado";

//Hooks
import useOperacionesEjecutadasOperadorDD from "../../../hooks/MercadoSecundario/useOperacionesEjecutadasOperadorDD";

//API's
import { consultarOperacionesEjecutadasOperadorDD } from "../../../api/MercadoSecundario/consultarOperacionesEjecutadasOperadorDD";

//Muestra las cuentas de un depositante indirecto
export default function ConsultarOperacionesEjecutadasOperadorDD({ history }) { 
    const [erroreshttp, setErroreshttp] = useState();
    const [spin, setSpin] = React.useState(true)
    const [data, setData] = React.useState([])
    const [copia, setCopia] = React.useState()

    const codigo = (JSON.parse(window.sessionStorage.getItem('usuarioRol'))).codigo

    React.useEffect(() => {
        traerinformacionOperacionesActivas()
    }, []);

    const traerinformacionOperacionesActivas = async () => {
        const response = await consultarOperacionesEjecutadasOperadorDD({codigoDepositante:codigo})
        //console.log(response)
        if (response) {
            setSpin(false)
            if ((isNaN(response.error)) === false) {
                setErroreshttp(response)
            } else {
                setData(response)
                setCopia(response)
            }
        }
    }

    return (
        <>
            {erroreshttp &&
                <ManejadorErrores response={erroreshttp} mostrarModal={erroreshttp} funcionCierre={() => { setErroreshttp(false) }}></ManejadorErrores>
            }
           
            <div className="fluid-container">
                <main className="">
                    <div className="row row-wrap g-0">
                         
                        <div className="col-12 mb-5 container px-5">                        
                                 <BannerTitulo titulo="Operaciones ejecutadas"></BannerTitulo>
                                    {spin ? <SpinnerStart textoSpinner="Cargando..."></SpinnerStart> :
                                        <div className="">
                                            <div className="">
                                                {copia &&
                                                    <>
                                                        <TablaMercado datos={data} useValidate={useOperacionesEjecutadasOperadorDD} mostrar={true} ></TablaMercado>
                                                    </>
                                                }
                                            
                                            </div>
                                        </div>
                                    }
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}


