import { useMemo } from "react";

export default function useFondosConiciliadosYNoconciliados() {
  const columns = useMemo(
    () => [
      {
        Header: "Número de subasta",
        accessor: "codigoSubasta"
      },
      {
        Header: "Folio BCH-TR",
        accessor: "folioBchTr"
      },
      {
        Header: "Fecha deposito",
        accessor: "fechaDepositoStr"
      },
      {
        Header: "Código de depositante",
        accessor: "codigoDepositante"
      },
      {
        Header: "Nombre de depositante",
        accessor: "nombreDepositante"
      },
      {
        Header: "Monto",
        accessor: "monto"
      },
      {
        Header: "Moneda",
        accessor: "monedaStr"
      },
      {
        Header: "Estatus",
        accessor: "estatusDesc"
      },
    ],
    []
  );

  return columns;
}


