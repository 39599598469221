//React
import React from "react";
import { Link } from 'react-router-dom';

//ComponentS
import BannerTitulo from "../../../ComponentesGenerales/Bannertitulo";

//Imagenes
import misCuentas from '../../../assets/imagenesCuentas/misCuentas.jpg'
import misClientes from '../../../assets/imagenesCuentas/misClientes.jpg'


export default function MenuTransferenciaLibreDePago({ history }) {


    return (
        <>
            <div className="fluid-container">
                
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        
                        <div className="col-12 mb-5">
                            <div className="">
                                <BannerTitulo titulo="Menú transferencias libres de pago"></BannerTitulo>
                                <div className="d-flex">
                                    <div className="container font-acumin-variable py-5">
                                        <div className="row ">
                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                <Link to={{ pathname: "/misCuentasDD",
                                              state:1 }} className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="" src={misCuentas} alt="imagenFondo" />
                                                        <div className="card-body text-center">
                                                            <h5 className="card-title">Mis cuentas</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>

                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                                                <Link to={{ pathname: "/misClientesDD",
                                            state:1 }} className="text-decoration-none text-dark">
                                                    <div className="card" >
                                                        <img className="" src={misClientes} alt="imagenFondo" />
                                                        <div className="card-body text-center">
                                                            <h5 className="card-title">Mis clientes</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
                
            </div>
        </>
    );
}

