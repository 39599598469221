
import React, { useEffect, useState, useContext } from "react";

//AWS
import { Auth } from 'aws-amplify';

//Images
import inicio from '../assets/inicioCehval/inicio.jpg';

//Components
import ComponenteModal from "../ComponentesGenerales/ComponenteModal";

//React context
import { UserContext } from "../UserContext";



export default function InicioCehval({ history }) {



    const [sinUsuario, setSinUsuario] = useState();

    //States in context
    const { setUsername, setLogin, setAutorizacion } = useContext(UserContext);


    useEffect(() => {
       
      



        //Renders username in header
        setLogin(true);
        //Set the username to the header
        const username = JSON.parse(window.sessionStorage.getItem('usuario'));
        setUsername(username.username);

        const arregloPermisos = JSON.parse(window.sessionStorage.getItem('usuarioRol'));
        //Fill the dropdown menu
        if (arregloPermisos === null) {
            setSinUsuario(true);
        } else {
            const result = arregloPermisos.permisos.filter(word => word.icon);
            setAutorizacion(result);
        }


    }, []);

    const signOut = async () => {
        try {
            await Auth.signOut();
            sessionStorage.clear();
            window.location.replace('/');
            // window.location.href = '/login'
        } catch (error) {
            //console.log('error signing out: ', error);
        }
    }


    return (
        <>
            {sinUsuario &&
                <ComponenteModal mostrarModal={sinUsuario} cerrarModal={signOut} titulo="Usuario deshabilitado" textoBoton="Entendido"></ComponenteModal>
            }
            <div className="fluid-container">
                <main className="min-height-40" >
                    <div className="row row-wrap g-0">
                        {/*  
                            <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 d-flex flex-column">
                                <BarraLateral></BarraLateral>
                            </div>
                        */}
                        <div className="col-12 mb-5 container-min-height container-max-height">
                            <div className="">
                                <div className="row justify-content-center align-items-center g-0">
                                    <section className='pt-5'>
                                        <div className="d-flex justify-content-center">
                                            <div className="col-8">
                                                <div className="border mb-3 mt-5" >
                                                    <div className="row g-0">
                                                        <div className="col-md-4">
                                                            <img src={inicio} className="w-100 rounded-start h-100" alt="imagen" />
                                                        </div>
                                                        <div className="col-md-8 d-flex align-items-center">
                                                            <div className="card-body">
                                                                <h5 className="card-title p-1">Conoce a CEHVAL</h5>
                                                                <p className="card-text p-2">En Cehval automatizamos procesos operativos logrando mayor precisión, rapidez y mejorando la productividad en los flujos de la operación, aumentamos la satisfacción del cliente y fortalecemos los controles al eliminar procesos manuales. </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            </div>
        </>
    );
}

